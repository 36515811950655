import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import * as React from "react";
import { useTranslation } from "react-i18next";

import { DefaultDialogTransition } from "Components/DialogTransition/DefaultDialogTransition";
import { useUpdateFileMutation, useFileQuery } from "GraphQL/Generated/Apollo";

export interface IRenameFileDialogProps {
  onClose: () => void;
  open: boolean;
  fileId: string;
}

export function RenameFileDialog(props: IRenameFileDialogProps) {
  const { open, fileId } = props;
  const { t } = useTranslation();
  const { data, loading, error } = useFileQuery({ variables: { id: fileId } });
  const [UpdateFile] = useUpdateFileMutation();
  const [errorMessage, setErrorMessage] = React.useState("");
  const [oldName, setOldName] = React.useState("");
  const [name, setName] = React.useState("");
  const [nameFocused, setNameFocused] = React.useState(true);
  const nameRef = React.useRef<HTMLInputElement>(null);

  function onClose() {
    setErrorMessage("");
    setName("");
    props.onClose();
  }

  function onRename() {
    UpdateFile({
      variables: { input: { id: fileId, name: name } },
      refetchQueries: ["File", "Files", "FileBundleView"],
    })
      .then(() => {
        onClose();
      })
      .catch((error) => {
        console.error(error);
        setErrorMessage(t("Components.RenameFileDialog.Error"));
      });
  }

  React.useEffect(() => {
    if (data?.file?.name) {
      setOldName(data.file?.name || "");
      setName(data.file?.name || "");
    }
  }, [data]);

  React.useEffect(() => {
    if (open && nameRef.current) {
      nameRef.current.focus();
    }
  }, [nameRef.current, open]);

  return (
    <>
      <Dialog open={open} onClose={onClose} TransitionComponent={DefaultDialogTransition} fullWidth maxWidth="xs">
        <DialogTitle>{t("Components.RenameFileDialog.Title", { name: oldName })}</DialogTitle>
        <DialogContent>
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
          <TextField
            autoFocus
            margin="dense"
            label={t("Components.RenameFileDialog.NameLabel")}
            type="text"
            fullWidth
            variant="standard"
            value={name}
            onBlur={() => setNameFocused(false)}
            onChange={(e) => setName(e.target.value ?? "")}
            onFocus={() => setNameFocused(true)}
            required
            inputRef={nameRef}
            error={name === "" && !nameFocused}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{t("Global.Action.Close")}</Button>
          <Button onClick={onRename} disabled={name === ""}>
            {t("Components.RenameFileDialog.Rename")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
