import { ShapeDatastoreType } from "@bigpi/cookbook";
import { IDataGridPreferences, IDataGridShape, IDataGridConfig } from "@bigpi/tl-schema";
import { Editor, TLShapeId, useValue } from "@tldraw/tldraw";
import { DependencyList, useEffect, useState } from "react";

import { IAnalysisShapeData, useBoardDatastore } from "BoardComponents/BoardDatastore";
import { useShapeDataQuery, useUpdateShapeDataMutation } from "GraphQL/Generated/Apollo";
import { DataUtils } from "Utils/DataUtils";

export interface IDataGridDataOptions {
  datastoreType: ShapeDatastoreType;
  editor: Editor;
  shapeId: TLShapeId;
  workspaceBoardId: string;
}

export interface IDataGridData {
  isParentDataAvailable: boolean;
  filteredData: Array<Record<string, unknown>> | null;
  isDataLoading: boolean;
  onSelectedIdsChanged: (selectedIds: Array<string>) => void;
  onUpdatePreferences: (newPreferences: IDataGridPreferences) => void;
  onUpdateConfigColumns: (newColumns: IDataGridConfig["columns"]) => void;
  onUpdateRow?: (updatedRow: Record<string, unknown>) => void;
}

const DEFAULT_DATA_GRID_DATA: IDataGridData = {
  isParentDataAvailable: false,
  filteredData: DataUtils.getImmutableEmptyArray<Record<string, any>>(),
  isDataLoading: false,
  onSelectedIdsChanged: () => {},
  onUpdatePreferences: () => {},
  onUpdateConfigColumns: () => {},
};

const useDataFromParentDatastore = (editor: Editor, shapeId: TLShapeId): IDataGridData => {
  const { parentId, props: shapeProps, type: shapeType } = editor.getShape(shapeId) as IDataGridShape;

  const datastore = useBoardDatastore();

  return useValue(
    "parentData",
    () => {
      const datastoreParentData = datastore.state.get()[parentId]?.get() as IAnalysisShapeData<
        Array<Record<string, any>>,
        Record<string, any>,
        Record<string, any>,
        Record<string, any>
      >;

      return {
        isParentDataAvailable: !!datastoreParentData,
        filteredData: datastoreParentData?.filteredData?.get() || DEFAULT_DATA_GRID_DATA.filteredData,
        isDataLoading: datastoreParentData?.dataLoading?.get() || DEFAULT_DATA_GRID_DATA.isDataLoading,
        onSelectedIdsChanged: (selectedIds: Array<string>) => {
          // Update selected IDs in parent datastore
          datastoreParentData?.onSelectedIdsChanged?.get()?.(selectedIds);
        },
        onUpdatePreferences: (newPreferences: IDataGridPreferences) => {
          // Update preferences in parent datastore
          datastoreParentData?.onUpdatePreferences?.get()?.("dataGrid", newPreferences);

          // Update preferences in data grid shape
          editor.updateShapes([
            {
              id: shapeId,
              type: shapeType,
              props: {
                preferences: newPreferences,
              },
            },
          ]);
        },
        onUpdateConfigColumns: (newColumns: IDataGridConfig["columns"]) => {
          // Update config columns in parent datastore
          // TODO: datastoreParentData?.onUpdateConfigColumns?.value?.("dataGrid", newPreferences);

          // Update preferences in data grid shape
          editor.updateShapes([
            {
              id: shapeId,
              type: shapeType,
              props: {
                config: {
                  ...shapeProps.config,
                  columns: newColumns,
                },
              },
            },
          ]);
        },
      };
    },
    [parentId, datastore],
  );
};

export const useDataGridData = (options: IDataGridDataOptions, deps: DependencyList = []): IDataGridData => {
  const { datastoreType, editor, shapeId, workspaceBoardId } = options;

  const dataGridDataFromParentDatastore = useDataFromParentDatastore(editor, shapeId);
  const { loading, data, refetch } = useShapeDataQuery({
    variables: {
      shapeId,
      workspaceBoardId,
    },
    skip: datastoreType !== ShapeDatastoreType.ServerDatastore,
  });
  const [updateShapeData] = useUpdateShapeDataMutation();

  const [dataGridData, setDataGridData] = useState<IDataGridData>(DEFAULT_DATA_GRID_DATA);

  useEffect(() => {
    // Refetch in case of missing data and changed to server datastore
    if (datastoreType === ShapeDatastoreType.ServerDatastore && (!data || !data.shapeData)) {
      refetch();
    }
  }, [datastoreType, data]);

  useEffect(() => {
    if (datastoreType === ShapeDatastoreType.ParentDatastore) {
      setDataGridData(dataGridDataFromParentDatastore);
    } else if (datastoreType === ShapeDatastoreType.ServerDatastore) {
      const filteredData = data?.shapeData?.data
        ? (JSON.parse(data?.shapeData?.data) as Array<Record<string, unknown>>)
        : DEFAULT_DATA_GRID_DATA.filteredData;
      setDataGridData({
        isParentDataAvailable: DEFAULT_DATA_GRID_DATA.isParentDataAvailable,
        filteredData,
        isDataLoading: loading,
        onSelectedIdsChanged: (selectedIds: Array<string>) => {},
        onUpdatePreferences: (newPreferences: IDataGridPreferences) => {
          const shape = editor.getShape(shapeId) as IDataGridShape;
          // Sanity check
          if (!shape || shape.type !== "dataGrid") {
            return;
          }
          const { props: shapeProps, type: shapeType } = shape;

          // Update preferences in data grid shape
          editor.updateShapes([
            {
              id: shapeId,
              type: shapeType,
              props: {
                ...shapeProps,
                preferences: newPreferences,
              },
            },
          ]);
        },
        onUpdateConfigColumns: (newColumns: IDataGridConfig["columns"]) => {
          const shape = editor.getShape(shapeId) as IDataGridShape;
          // Sanity check
          if (!shape || shape.type !== "dataGrid") {
            return;
          }
          const { props: shapeProps, type: shapeType } = shape;

          // Update config columns in data grid shape
          editor.updateShapes([
            {
              id: shapeId,
              type: shapeType,
              props: {
                config: {
                  ...shapeProps.config,
                  columns: newColumns,
                },
              },
            },
          ]);
        },
        onUpdateRow(updatedRow: Record<string, unknown>) {
          if (Array.isArray(filteredData) && filteredData?.length > 0) {
            const rowIndex = filteredData.findIndex((dataItem) => {
              return dataItem.id === updatedRow.id;
            });
            if (rowIndex > -1) {
              const newData = [...filteredData];
              newData[rowIndex] = updatedRow;
              updateShapeData({
                variables: {
                  input: {
                    workspaceBoardId: workspaceBoardId,
                    shapeId,
                    data: JSON.stringify(newData),
                  },
                },
              });
            }
          }
        },
      });
    }
  }, [options, dataGridDataFromParentDatastore, loading, data, ...deps]);

  return dataGridData;
};
