import { CommandOutputType, IBoardSearchShapeResult } from "@bigpi/cookbook";

import { ResponsePlugInBase } from "./ResponsePlugInBase";
import { ICommandResponsePlugInInputs } from "Components/CommandManagers/WorkspaceBoard/WorkspaceBoardCommandManager";

export class BoardSearchResultResponsePlugIn extends ResponsePlugInBase {
  // *********************************************
  // Public properties
  // *********************************************/
  /**
   * @inheritdoc
   */
  name = CommandOutputType.BoardSearchResults;

  // *********************************************
  // Public methods
  // *********************************************/
  /**
   * @inheritdoc
   */
  public async execute(input: ICommandResponsePlugInInputs): Promise<undefined | JSX.Element> {
    const { app, boardSearchExecutor, commandResponse } = input;

    if (!app) {
      console.warn(`No app instance for command response ${commandResponse.requestId}`);
      return;
    }

    if (commandResponse.data) {
      try {
        const searchResults: Array<IBoardSearchShapeResult> = JSON.parse(commandResponse.data);
        // TODO: We need some content in the search box, preferably add an option for search term type to hide the input and allow navigating without search term
        const searchTerm = " ";

        boardSearchExecutor.rotateSessionId();
        const newSessionId = boardSearchExecutor.sessionId;
        boardSearchExecutor.raiseBeforeBoardSearchResponse({
          searchResults: searchResults,
          sessionId: newSessionId,
          searchTerm,
        });

        boardSearchExecutor.raiseBoardSearchResponse({
          searchResults: searchResults,
          sessionId: newSessionId,
          searchTerm,
        });

        boardSearchExecutor.raiseAfterBoardSearchResponse({
          searchResults: searchResults,
          sessionId: newSessionId,
          searchTerm,
        });
      } catch (e) {
        console.error(e);
      }
    } else {
      // TODO: Show somethig to the user
      console.warn("No search result data received.");
    }
  }
}
