import { IDateRange } from "@bigpi/cookbook";

export enum TopicDiscussionInNewsArticleAnalysisFieldsEnum {
  Date = "date",
  DateShortcut = "dateShortcut",
  Topic = "topic",
  Theme = "theme",
}

export interface ITopicDiscussionInNewsArticleAnalysisFacets {
  [TopicDiscussionInNewsArticleAnalysisFieldsEnum.Date]?: IDateRange;
  [TopicDiscussionInNewsArticleAnalysisFieldsEnum.DateShortcut]?: string;
  [TopicDiscussionInNewsArticleAnalysisFieldsEnum.Topic]?: Array<string>;
  [TopicDiscussionInNewsArticleAnalysisFieldsEnum.Theme]?: Array<string>;
}
