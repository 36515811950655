import { defineMigrations } from "@tldraw/store";

import { IHeadlineShape } from "./IHeadlineShape.js";

export const HeadlineShapeVersions = {
  Initial: 0,
  AddCanScroll: 1,
};

export const headlineShapeMigrations = defineMigrations({
  firstVersion: HeadlineShapeVersions.Initial,
  currentVersion: HeadlineShapeVersions.AddCanScroll,
  migrators: {
    [HeadlineShapeVersions.AddCanScroll]: {
      up: (shape: IHeadlineShape) => {
        return {
          ...shape,
          props: { ...shape.props, canScroll: true },
        };
      },
      down: (shape: IHeadlineShape & { canScroll: boolean }) => {
        const { canScroll, ...rest } = shape.props;
        return { ...shape, props: rest };
      },
    },
  },
});
