import { useValue } from "@tldraw/tldraw";

import { IDateRangeOption, IDateRange } from "@bigpi/cookbook";

/**
 * For the given shortcut value, finds the range. If shortcut is not available, then returns the selected date range
 *
 * @param dateShortcuts Shortcut items to be used for selectedDateShortcut
 * @param selectedDateShortcut User selected date shortcut
 * @param selectedDate Selected date with from and to
 * @returns
 */
export function useSelectedDate(
  shortcutItems: Array<IDateRangeOption>,
  selectedDateShortcut: string | undefined,
  selectedDate: IDateRange | undefined,
) {
  const dateRange = useValue(
    "dateRange",
    () => {
      return getSelectedDate(shortcutItems, selectedDateShortcut, selectedDate);
    },
    [shortcutItems, selectedDate, selectedDateShortcut],
  );
  return dateRange;
}

/********************************************
 * Utils
 ********************************************/
/**
 * Identifies the shortcut related range if exists. Otherwise, returns the selected date range
 *
 * @param shortcutItems Shortcut items to be used for selectedDateShortcut
 * @param selectedDateShortcut User selected date shortcut
 * @param selectedDate Selected date range
 * @returns Selected date shortcut related range if exists. Otherwise, returns the selected date range
 */
export function getSelectedDate(
  shortcutItems: Array<IDateRangeOption>,
  selectedDateShortcut: string | undefined,
  selectedDate: IDateRange | undefined,
) {
  if (selectedDateShortcut) {
    const shortcut = shortcutItems.find((s) => s.id === selectedDateShortcut);
    if (shortcut) {
      return {
        from: shortcut.startDate,
        to: shortcut.endDate,
      };
    }
  }
  if (selectedDate) {
    return selectedDate;
  }
  return {};
}
