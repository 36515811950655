import { SortOrder } from "./SortOrder.js";
import { ISortOption } from "./ISortOption.js";

export const UpdatedAtDescSortOptionName = "UpdatedAtDesc";

export const UpdatedAtDescSortOption: ISortOption = {
  field: "updatedAt",
  name: UpdatedAtDescSortOptionName,
  sortOrder: SortOrder.Desc,
};
