import { DefaultColorStyle, DefaultFontStyle, DefaultHorizontalAlignStyle, DefaultSizeStyle, ShapeProps } from "@tldraw/tlschema";
import { T } from "@tldraw/validate";

import { ILockedTextShape } from "./ILockedTextShape.js";

// *********************************************
// Public constants
// *********************************************/
/**
 * Gets the default props for the shape.
 *
 * @returns The default props.
 */
export const getLockedTextShapeDefaultProps = (): ILockedTextShape["props"] => {
  return {
    color: "black",
    size: "m",
    w: 8,
    text: "",
    font: "sans",
    align: "start",
    autoSize: true,
    scale: 1,
  };
};

/**
 * The shape props schema definition.
 */
export const lockedTextShapeProps: ShapeProps<ILockedTextShape> = {
  color: DefaultColorStyle,
  size: DefaultSizeStyle,
  font: DefaultFontStyle,
  align: DefaultHorizontalAlignStyle,
  w: T.nonZeroNumber,
  text: T.string,
  scale: T.nonZeroNumber,
  autoSize: T.boolean,
};
