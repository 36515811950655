import * as Plot from "@observablehq/plot";
import type { Reducer } from "@observablehq/plot";
import * as d3 from "d3";

import { addTooltip } from "Notebooks/Tooltip/Tooltip";
import { on, RenderableMark } from "Notebooks/PlotUtils/PlotEventHandlers";

const PlotWithTooltip = addTooltip(Plot);

/**
 *
 * @param data Data to plot
 * @param metadata All metadata of the plot
 * @param onBubbleClick On click handler for the bubble
 * @param onLabelClick On click handler for the label
 * @param r Bubble radius range { domain: [], range: [] }
 * @param xDomain x-axis domain values
 * @param xField Field to access x axis values from data
 * @param yField Field to access y axis values from data
 * @param groupReducer Reducer to decide the radius of the bubble
 * @param fillReducer Reducer to decide the fill color of the bubble
 *
 * @returns
 */
export function getBubbleChartPlot(
  data: Array<Record<string, unknown>>,
  metadata: {
    grid: boolean;
    height: number;
    insetLeft: number;
    insetRight: number;
    marginBottom: number;
    marginLeft: number;
    marginRight: number;
    marginTop: number;
    padding: number;
    width: number;
    x?: Record<string, unknown>;
    y?: Record<string, unknown>;
  },
  onBubbleClick: (e: PointerEvent, selectedValue: Record<string, unknown>, yField: string) => void,
  onLabelClick: (e: PointerEvent, selectedValue: string, yField: string) => void,
  r: Record<string, unknown>,
  xDomain: Array<string>,
  xField: string,
  yField: string,
  groupReducer: Reducer,
  fillReducer: Reducer,
): HTMLElement {
  const chart = Plot.plot({
    style: {
      backgroundColor: "transparent",
    },
    ...metadata,
    marginLeft: metadata.y?.axis === null ? 0 : metadata.marginLeft,
    r,
    x: {
      axis: null,
      domain: xDomain,
      line: false,
      tickSize: 0,
    },
    y: {
      label: "",
      line: false,
      tickSize: 0,
      ...metadata.y,
    },
    marks: [
      on(
        Plot.dot(
          data,
          Plot.group(
            {
              r: groupReducer,
              fill: fillReducer,
              stroke: fillReducer,
            },
            {
              x: xField,
              y: yField,
              strokeWidth: 4,
            },
          ),
        ) as RenderableMark,
        {
          pointerup: (event: PointerEvent, result: Record<string, any>) => {
            onBubbleClick(event, result, yField);
          },
        },
      ),
    ],
  });

  // Add click handler for the labels
  d3.select(chart)
    .selectAll("text")
    .on("pointerup", function (event: PointerEvent) {
      onLabelClick(event, d3.select(this).text(), yField);
    });

  return chart as HTMLElement;
}
