import { FileSourceType } from "@bigpi/cookbook";
import React from "react";

import { FileItemCompact } from "Components/FileItem/FileItemCompact";
import { ILibraryBundleItemProps, LibraryBundleItem } from "./LibraryBundleItem";

export type ILibraryBundleItemCompactProps = Omit<
  ILibraryBundleItemProps,
  "isSelectable" | "listItem" | "onMouseEnter" | "onMouseLeave" | "onSelectionChange" | "selectedFileIds"
>;

export function LibraryBundleItemCompact(props: ILibraryBundleItemCompactProps) {
  return <LibraryBundleItem {...props} listItem={FileItemCompact} />;
}
