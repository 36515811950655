import { gql, useApolloClient } from "@apollo/client";
import {
  DocumentType,
  parseStorageLocationUrl,
  ShapeDatastoreType,
  workspaceBoardAssetStorageLocationToDownloadUrl,
} from "@bigpi/cookbook";
import { useHashParams } from "@bigpi/cutlery";
import { IDataGridShape, IHtmlDocumentShape } from "@bigpi/tl-schema";
import {
  HistoryEntry,
  TLAsset,
  TLRecord,
  TLShape,
  useValue,
  Editor,
  // @ts-expect-error ZOOMS not exported
  ZOOMS,
} from "@tldraw/tldraw";
import "@tldraw/tldraw/tldraw.css";
import { useAuthUser } from "@frontegg/react";
import { Box } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useDebouncedCallback } from "use-debounce";

import {
  CopyShapeDataDocument,
  CopyShapeDataMutation,
  CopyShapeDataMutationVariables,
  RowState,
  UpdateWorkspaceBoardInput,
  useCreateShapeDataMutation,
  useMarkWorkspaceBoardShapesAsDeletedMutation,
  useMarkWorkspaceBoardTableOfContentsActiveForShapeMutation,
  useMarkWorkspaceBoardTableOfContentsInactiveForShapeMutation,
  usePublishBoardDocumentShapesToDocumentsMutation,
  useUpdateShapeDataMutation,
  useUpdateWorkspaceBoardMutation,
  useWorkspaceBoardQuery,
} from "GraphQL/Generated/Apollo";
import { WORKSPACE_BOARD_USER_PREFERENCES_KEY, WORKSPACE_BOARD_USER_PREFERENCE_QUERY } from "GraphQL/UserPreference";
import { AddToBoardDialog } from "BoardComponents/AddToBoard/AddToBoardDialog";
import { AddToBoardProvider } from "BoardComponents/AddToBoard/useAddToBoard";
import { IAnalysisConfig } from "BoardComponents/Analyses/DataFrameConfigs/IAnalysisConfig";
import { QuestionAnalysisUtil } from "BoardComponents/Analyses/QuestionAnalysisShape/QuestionAnalysisShape";
import { BoardSearchManager } from "BoardComponents/BoardSearchManager/BoardSearchManager";
import { DeprecatedUtil } from "BoardComponents/DeprecatedShape/DeprecatedShape";
import { HeadlineUtil } from "BoardComponents/HeadlineShape/HeadlineShape";
import { HtmlDocumentUtil, HtmlDocumentTool } from "BoardComponents/HtmlDocumentShape/HtmlDocumentShape";
import { InlineFrameUtil } from "BoardComponents/InlineFrameShape/InlineFrameShape";
import { TopicDiscussionAnalysisUtil } from "BoardComponents/Analyses/TopicDiscussionAnalysisShape/TopicDiscussionAnalysisShape";
import { TopicDiscussionSummaryAnalysisUtil } from "BoardComponents/Analyses/TopicDiscussionSummaryAnalysisShape/TopicDiscussionSummaryAnalysisShape";
import { TopicDiscussionInNewsArticleAnalysisUtil } from "BoardComponents/Analyses/TopicDiscussionsInNewsArticlesAnalysisShape/TopicDiscussionsInNewsArticlesAnalysisShape";
import { DataGridShapeUtil } from "BoardComponents/DataGridShape/DataGridShape";
import { FeedUtil } from "BoardComponents/FeedShape/FeedShape";
import { FilePreviewUtil } from "BoardComponents/FilePreviewShape/FilePreviewShape";
import { GroupBubbleChartUtil } from "BoardComponents/Charting/GroupBubbleChartShape/GroupBubbleChartShape";
import { GroupHistogramChartUtil } from "BoardComponents/Charting/GroupHistogramChartShape/GroupHistogramChartShape";
import { LockedTextShapeUtil } from "BoardComponents/LockedTextShape/LockedTextShape";
import { AnalystQuestionAnalysisUtil } from "BoardComponents/Analyses/AnalystQuestionAnalysisShape/AnalystQuestionAnalysisShape";
import { BarChartUtil } from "BoardComponents/Charting/BarChartShape/BarChartShape";
import { HistogramChartUtil } from "BoardComponents/Charting/HistogramChartShape/HistogramChartShape";
import { IAnalysisShapeData, useBoardDatastore } from "BoardComponents/BoardDatastore";
import { InteractTool } from "BoardComponents/Tools";
import { ThumbnailFrameOverlay } from "BoardComponents/Overlays/ThumbnailFrameOverlay";
import { WorkspaceAccessControlListDialog } from "Components/AccessControlList/WorkspaceAccessControlListDialog";
import { ErrorBoundary } from "Components/ErrorBoundary/ErrorBoundary";
import { CommandContext } from "CommandContext";
import { Config } from "Config";
import { IHtmlDocumentShapeDetailsToPublish, PublishToDocumentsDialog } from "Pages/Workspace/PublishToDocumentsDialog";
import { RenameWorkspaceBoardDialog } from "Pages/Workspace/RenameWorkspaceBoardDialog";
import { POSITION_SEARCH_PARAM_NAME, SHAPE_ID_SEARCH_PARAM_NAME } from "Pages/WorkspaceBoard/Constants";
import { getMediaAssetFromFile } from "Utils/BoardAssetUtils";
import { getCanvasToImageBase64 } from "Utils/DomToImageUtils";
import { getUploadWorkspaceBoardAssetFn } from "Utils/UploadUtil";
import { CollaborativeBoardEditor } from "./CollaborativeBoardEditor";
import { CollaborativeBoardEditorContext } from "./useCollaborativeBoardEditor";
import { useYjsStore } from "./useYjsStore";

// *********************************************
// Private constants
// *********************************************/
// Based on the Google doc zooms
const CUSTOM_ZOOMS = [0.1, 0.25, 0.5, 0.67, 0.75, 1, 1.25, 1.5, 2, 4, 6, 8];

export interface EditorUser {
  accessToken: string;
  color: string;
  name: string;
  userId: string;
}

export interface CollaborativeBoardConfig {
  boardId: string;
  editorUser: EditorUser;
  readOnly: boolean;
  runnerHttpUrl: string;
  runnerWsUrl: string;
  workspaceId: string;
}

export interface CollaborativeBoardProps {
  config: CollaborativeBoardConfig;
  onMount: (app: Editor) => void;
}

const customShapeUtils = [
  AnalystQuestionAnalysisUtil,
  BarChartUtil,
  DataGridShapeUtil,
  DeprecatedUtil,
  FeedUtil,
  FilePreviewUtil,
  GroupBubbleChartUtil,
  GroupHistogramChartUtil,
  HeadlineUtil,
  HistogramChartUtil,
  HtmlDocumentUtil,
  InlineFrameUtil,
  LockedTextShapeUtil,
  QuestionAnalysisUtil,
  TopicDiscussionAnalysisUtil,
  TopicDiscussionSummaryAnalysisUtil,
  TopicDiscussionInNewsArticleAnalysisUtil,
];

const customTools = [HtmlDocumentTool, InteractTool];

const FALLBACK_DOCUMENT_NAME = "bigpi.ai";
// Update the workspace thumbnail and preferences every 30 seconds
const UPDATE_WORKSPACE_THUMBNAIL_DELAY = 30 * 1000;
// Grab a thumbnail every 2 seconds
const CAPTURE_THUMBNAIL_DELAY = 2 * 1000;
const SET_SEARCH_PARAMS_DELAY = 250;
const SET_SEARCH_PARAMS_MAX_DELAY = 500;

export const CollaborativeBoard = (props: CollaborativeBoardProps) => {
  const { config } = props;
  const { boardId, editorUser, readOnly, runnerHttpUrl, runnerWsUrl, workspaceId } = config;

  const { t } = useTranslation();

  const apolloClient = useApolloClient();

  const user = useAuthUser();

  const { setHashParam } = useHashParams();

  const [isAuthLoading, setAuthIsLoading] = useState(true);
  const [authError, setAuthError] = useState("");
  const [pageName, setPageName] = useState(FALLBACK_DOCUMENT_NAME);
  const [app, setApp] = useState<Editor>();
  const [aclDialogOpen, setAclDialogOpen] = useState(false);
  const [publishToDocumentsDialogOpen, setPublishToDocumentsDialogOpen] = useState(false);
  const [renameDialogOpen, setRenameDialogOpen] = useState(false);
  const [thumbnailFrameActive, setThumbnailFrameActive] = useState(false);
  const [isEditorMounted, setIsEditorMounted] = useState(false);

  const datastore = useBoardDatastore();

  const isShapeKnowledgeBaseArticle = useCallback((shape: TLShape) => {
    return (
      shape.type === HtmlDocumentUtil.type &&
      (shape as IHtmlDocumentShape).meta.documentType === DocumentType.KnowledgeBaseArticle
    );
  }, []);

  const selectedShapeIds = useValue("collaborativeBoard.selectedShapeIds", () => app?.getSelectedShapeIds(), [app]);
  const selectedKnowledgeBaseArticleShapes = useValue(
    "collaborativeBoard.selectedKnowledgeBaseArticleShapes",
    () => {
      const selectedShapes = app?.getSelectedShapes() || [];
      return selectedShapes.filter(isShapeKnowledgeBaseArticle) as Array<IHtmlDocumentShape>;
    },
    [app, isShapeKnowledgeBaseArticle],
  );
  const allKnowledgeBaseArticleShapes = useValue(
    "collaborativeBoard.allKnowledgeBaseArticleShapes",
    () => {
      const allShapes = app?.getCurrentPageShapes() || [];
      return allShapes.filter(isShapeKnowledgeBaseArticle) as Array<IHtmlDocumentShape>;
    },
    [app, isShapeKnowledgeBaseArticle],
  );

  const { data: workspaceBoardData } = useWorkspaceBoardQuery({ variables: { id: boardId } });
  const [updateWorkspaceBoard] = useUpdateWorkspaceBoardMutation();
  const [updateShapeData] = useUpdateShapeDataMutation();
  const [createShapeDataMutation] = useCreateShapeDataMutation();
  const [markWorkspaceBoardShapesAsDeleted] = useMarkWorkspaceBoardShapesAsDeletedMutation();
  const [markWorkspaceBoardTableOfContentsActiveForShape] = useMarkWorkspaceBoardTableOfContentsActiveForShapeMutation();
  const [markWorkspaceBoardTableOfContentsInactiveForShape] = useMarkWorkspaceBoardTableOfContentsInactiveForShapeMutation();
  const [publishBoardDocumentShapesToDocuments] = usePublishBoardDocumentShapesToDocumentsMutation();

  // This is a hack to get around the "null" state value in useEffect callback
  const [thumbnailBase64, _setThumbnailBase64] = useState<string | null>(null);
  const thumbnailBase64Ref = useRef(thumbnailBase64);
  const setThumbnailBase64 = (data: string | null) => {
    thumbnailBase64Ref.current = data;
    _setThumbnailBase64(data);
  };
  const zoomLevelRef = useRef<number | null>(null);
  const cameraPositionRef = useRef<{ x: number; y: number } | null>(null);

  const getUserToken = useCallback(() => {
    return user.accessToken;
  }, [user]);

  const onCreateAssetFromFile = useCallback(
    async (info: { file: File; type: "file" }): Promise<TLAsset> => {
      const { file } = info;

      // Get the data URL version of the shape using Tldraw's built-in
      const asset = await getMediaAssetFromFile(file);

      if (asset.type === "image" || asset.type === "video") {
        // Upload the asset to the server
        const result = await getUploadWorkspaceBoardAssetFn(Config.apiGatewayHttpUrl, getUserToken, boardId)(file);

        // Change the src property to the URL of the uploaded asset
        asset.props.src = workspaceBoardAssetStorageLocationToDownloadUrl(Config.assetHttpUrl, parseStorageLocationUrl(result));
      }

      return asset;
    },
    [boardId, getUserToken],
  );

  const updateWorkspaceBoardThumbnailAndPreferences = useCallback(
    async (thumbnail: string | null) => {
      try {
        let updateRequired = false;
        const input: UpdateWorkspaceBoardInput = {
          id: boardId,
        };

        // Only save the camera position if we've actually received values from the app
        if (cameraPositionRef.current && zoomLevelRef.current) {
          const center = cameraPositionRef.current;
          input.zoomLevel = zoomLevelRef.current;
          input.cameraPosition = { x: center.x, y: center.y };
          updateRequired = true;

          // Update the GraphQL query cache directly
          const workspaceBoardPreferences = apolloClient.readQuery({
            query: WORKSPACE_BOARD_USER_PREFERENCE_QUERY,
            variables: {
              key: WORKSPACE_BOARD_USER_PREFERENCES_KEY(boardId),
            },
          });

          // NOTE: This only writes to local cache, not server
          const data = {
            ...workspaceBoardPreferences?.userPreference,
            key: WORKSPACE_BOARD_USER_PREFERENCES_KEY(boardId),
            data: {
              ...workspaceBoardPreferences?.userPreference?.data,
              cameraPosition: { x: center.x, y: center.y },
              zoomLevel: zoomLevelRef.current,
            },
          };
          const result = apolloClient.writeQuery({
            query: gql`
              query UpsertWorkspaceBoardUserPreferencesCache($key: String!) {
                userPreference(key: $key) {
                  key
                  data
                }
              }
            `,
            data: {
              userPreference: {
                __typename: "UserPreference",
                ...data,
              },
            },
            variables: {
              key: data.key,
            },
          });
        }

        // Only update the thumbnail if it's not locked and we have one
        if (!workspaceBoardData?.workspaceBoard?.isThumbnailLocked && thumbnail) {
          input.thumbnail = thumbnail;
          input.isThumbnailLocked = false;
          updateRequired = true;
        }

        if (updateRequired) {
          return await updateWorkspaceBoard({
            variables: {
              input,
            },
            // Trigger workspaces list
            refetchQueries: ["Workspaces", "WorkspaceBoards"],
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    [app, workspaceBoardData, boardId],
  );

  const debouncedUpdateWorkspaceThumbnailAndPreferences = useDebouncedCallback(
    updateWorkspaceBoardThumbnailAndPreferences,
    UPDATE_WORKSPACE_THUMBNAIL_DELAY,
  );

  const debouncedOnStopCameraAnimation = useDebouncedCallback(async () => {
    try {
      const minimapElement = document.querySelector("canvas.tlui-minimap__canvas") as HTMLCanvasElement;
      // Create a thumbnail from the minimap
      let imageBase64: string | null = null;

      // Generate a new thumbnail if the workspace thumbnail is not locked
      if (!workspaceBoardData?.workspaceBoard?.isThumbnailLocked) {
        imageBase64 = await getCanvasToImageBase64(minimapElement);
      }

      // Save in state if available
      if (imageBase64) {
        setThumbnailBase64(imageBase64);
      }

      // Update the workspace thumbnail and/or preferences
      await debouncedUpdateWorkspaceThumbnailAndPreferences(imageBase64);
    } catch (error) {
      console.error(error);
    }
  }, CAPTURE_THUMBNAIL_DELAY);

  useEffect(() => {
    const saveViewport = () => {
      if (app) {
        zoomLevelRef.current = app.getZoomLevel();
        cameraPositionRef.current = { x: app.getCamera().x, y: app.getCamera().y };
      }
    };

    app?.on("stop-camera-animation", saveViewport);
    app?.on("stop-camera-animation", debouncedOnStopCameraAnimation);
    return () => {
      app?.off("stop-camera-animation", saveViewport);
      app?.off("stop-camera-animation", debouncedOnStopCameraAnimation);

      // Cancel any debounced callbacks
      debouncedOnStopCameraAnimation.cancel();

      // Manually trigger update thumbnail and/or preferences on unmount
      // The app check above should prevent React StrictMode from triggering on first render
      updateWorkspaceBoardThumbnailAndPreferences(thumbnailBase64Ref.current);
    };
  }, [app]);

  const debouncedSetSelectedShapeIdHashParamInUrl = useDebouncedCallback(
    (shapeId: string) => {
      setHashParam(SHAPE_ID_SEARCH_PARAM_NAME, shapeId);
    },
    SET_SEARCH_PARAMS_DELAY,
    { maxWait: SET_SEARCH_PARAMS_MAX_DELAY },
  );

  useEffect(() => {
    // Skip if the editor is not mounted yet, this is required to prevent
    // the URL from being updated before the editor is ready
    if (!isEditorMounted) {
      return;
    }

    if (Array.isArray(selectedShapeIds) && selectedShapeIds.length === 1) {
      debouncedSetSelectedShapeIdHashParamInUrl(selectedShapeIds[0]);
    } else {
      debouncedSetSelectedShapeIdHashParamInUrl("");
    }
  }, [isEditorMounted, selectedShapeIds, debouncedSetSelectedShapeIdHashParamInUrl]);

  const debouncedSetPositionHashParamInUrl = useDebouncedCallback(
    (position: string) => {
      setHashParam(POSITION_SEARCH_PARAM_NAME, position);
    },
    SET_SEARCH_PARAMS_DELAY,
    { maxWait: SET_SEARCH_PARAMS_MAX_DELAY },
  );

  useEffect(() => {
    // Skip if the editor is not mounted yet, this is required to prevent
    // the URL from being updated before the editor is ready
    if (!isEditorMounted) {
      return;
    }

    if (cameraPositionRef.current && zoomLevelRef.current) {
      debouncedSetPositionHashParamInUrl(`${cameraPositionRef.current.x},${cameraPositionRef.current.y},${zoomLevelRef.current}`);
    }
  }, [cameraPositionRef.current, isEditorMounted, debouncedSetPositionHashParamInUrl, zoomLevelRef.current]);

  const isServerDatastoreIdAvailableForShape = (shape: TLShape | undefined) => {
    return (
      !!shape &&
      !!shape.props &&
      (shape.props as Record<string, unknown>).datastoreType === ShapeDatastoreType.ServerDatastore &&
      !!(shape.props as Record<string, unknown>).datastoreId
    );
  };

  const handleRemovedChanges = useCallback(
    async (_app: Editor, entry: HistoryEntry<TLRecord>) => {
      const allRecords = Object.values(entry.changes.removed);
      const removedShapes = allRecords.filter((record) => record.typeName === "shape");

      const removedShapeIds = removedShapes.map((record) => record.id);
      if (removedShapeIds.length > 0) {
        try {
          await markWorkspaceBoardShapesAsDeleted({
            variables: {
              input: {
                workspaceBoardId: boardId,
                shapeIds: removedShapeIds,
              },
            },
          });
        } catch (error) {
          // Ignore
        }
      }

      // Delete shape data from datastore when shape is deleted
      await Promise.all(
        removedShapes.map(async (record) => {
          datastore.removeShapeData(record.id);

          // Check if we need to update the shape data in the database
          const shape = record as TLShape;
          if (isServerDatastoreIdAvailableForShape(shape)) {
            try {
              await updateShapeData({
                variables: {
                  input: {
                    workspaceBoardId: boardId,
                    shapeId: record.id,

                    rowState: RowState.Deleted,
                  },
                },
              });
            } catch (error) {
              // Ignore
            }
          }

          // Mark workspace board table of contents inactive for the shape
          try {
            await markWorkspaceBoardTableOfContentsInactiveForShape({
              variables: {
                input: {
                  workspaceBoardId: boardId,
                  shapeId: record.id,
                },
              },
            });
          } catch (error) {
            // Ignore
          }
        }),
      );
    },
    [boardId, datastore],
  );

  const handleAddedChanges = useCallback(
    async (app: Editor, entry: HistoryEntry<TLRecord>) => {
      const allRecords = Object.values(entry.changes.added);
      const addedShapes = allRecords.filter((record) => record.typeName === "shape");

      // Mark the shape data active when the shape is added and the data for the
      // shapeId exists in the database, this is required for handling "undo" delete
      await Promise.all(
        addedShapes.map(async (record) => {
          const shape = record as TLShape;
          if (isServerDatastoreIdAvailableForShape(shape)) {
            try {
              await updateShapeData({
                variables: {
                  input: {
                    workspaceBoardId: boardId,
                    shapeId: record.id,

                    rowState: RowState.Active,
                  },
                },
              });
            } catch (e) {
              // Ignore
            }

            try {
              // Duplicate the shape data from the database
              const response = await apolloClient.mutate<CopyShapeDataMutation, CopyShapeDataMutationVariables>({
                mutation: CopyShapeDataDocument,
                variables: {
                  input: {
                    workspaceBoardId: boardId,
                    shapeId: record.id,

                    datastoreId: (shape.props as Record<string, unknown>).datastoreId as string,
                  },
                },
                refetchQueries: ["ShapeData"],
              });
              const newDatastoreId = response.data?.copyShapeData?.id;
              if (newDatastoreId) {
                app.updateShape({
                  id: shape.id,
                  type: shape.type,
                  props: {
                    datastoreId: newDatastoreId,
                  },
                });
              }
            } catch (e) {
              // Ignore
            }
          }

          // Mark workspace board table of contents active for the shape
          try {
            await markWorkspaceBoardTableOfContentsActiveForShape({
              variables: {
                input: {
                  workspaceBoardId: boardId,
                  shapeId: record.id,
                },
              },
            });
          } catch (error) {
            // Ignore
          }

          if (shape && shape.type === DataGridShapeUtil.type) {
            const dataGridShape = shape as IDataGridShape;
            // Takes "datastoreId" related "parentId" & considers data to create datastore
            if (
              dataGridShape &&
              dataGridShape.props.datastoreId &&
              dataGridShape.props.datastoreType === ShapeDatastoreType.ParentDatastore
            ) {
              const parentData = datastore.state.get()[dataGridShape.props.datastoreId].get() as IAnalysisShapeData<
                Array<Record<string, any>>,
                Record<string, any>,
                IAnalysisConfig,
                Record<string, any>
              >;
              const filteredData = parentData.filteredData?.get() || [];
              try {
                const { data } = await createShapeDataMutation({
                  variables: {
                    input: {
                      shapeId: dataGridShape.id,
                      workspaceBoardId: boardId,
                      data: JSON.stringify(filteredData),
                    },
                  },
                });
                // Update the data grid shape datastoreId & datastoreType
                app.updateShape({
                  id: dataGridShape.id,
                  type: dataGridShape.type,
                  props: {
                    datastoreId: data?.createShapeData?.id,
                    datastoreType: ShapeDatastoreType.ServerDatastore,
                  },
                });
              } catch (error) {
                // Ignore
              }
            }

            /* Reparent to "currentPageId" for the following scenarios on UI
             * 1. "Duplicate" menu item is clicked
             * 2. When data grid is copied & the source data grid is still in selectedIds.
             *    In this case while pasting, "editor.putContent" reparenting to ancestor shape id
             */
            if (dataGridShape && dataGridShape.parentId.startsWith("shape:")) {
              const childShapeIds = app.getSortedChildIdsForParent(dataGridShape.parentId);
              const duplicateDataGridId = childShapeIds.find((candidateShapeId) => {
                return app.getShape(candidateShapeId)?.type === DataGridShapeUtil.type && candidateShapeId !== dataGridShape.id;
              });
              if (duplicateDataGridId) {
                app.reparentShapes([dataGridShape.id], app.getCurrentPageId());
              }
            }
          }
        }),
      );
    },
    [boardId, datastore],
  );

  // *********************************************
  // Tldraw events
  // *********************************************/
  const onMount = useCallback(
    (app: Editor) => {
      // Modify ZOOMS
      ZOOMS.length = 0;
      ZOOMS.push(...CUSTOM_ZOOMS);

      // Set workspaceBoardId
      const meta = app.getInstanceState().meta;
      app.updateInstanceState({ meta: { ...meta, workspaceBoardId: boardId } }, { ephemeral: false, squashing: true });

      // Listen for changes in shape records and remove shape mappings from datastore that are deleted
      const cleanupStoreListener = app.store.listen((entry: HistoryEntry<TLRecord>) => {
        handleRemovedChanges(app, entry);
        handleAddedChanges(app, entry);
      });

      // Force settings
      app.updateInstanceState({ isReadonly: readOnly, isGridMode: false }, { ephemeral: true });
      app.user.updateUserPreferences({
        id: editorUser.userId,
        color: editorUser.color,
        isDarkMode: false,
        isSnapMode: true,
        name: editorUser.name,
      });

      const page = app.getCurrentPage();
      // Check if we need to update the page name
      if (page.name !== pageName) {
        app.renamePage(page.id, pageName);
      }

      setApp(app);
      if (props.onMount) {
        props.onMount(app);
      }

      // Adds "parentId" to "datastoreId" of dataGrid shape when copied from analysis shape
      const copy = () => {
        const selectedShapes = app.getSelectedShapes();
        selectedShapes.forEach((selectedShape) => {
          if (selectedShape.type === DataGridShapeUtil.type) {
            const dataGridShape = selectedShape as IDataGridShape;
            if (dataGridShape.props.datastoreType === ShapeDatastoreType.ParentDatastore && !dataGridShape.props.datastoreId) {
              app.updateShapes([
                {
                  id: selectedShape.id,
                  type: selectedShape.type,
                  props: {
                    datastoreId: selectedShape.parentId,
                  },
                },
              ]);
            }
          }
        });
      };
      // Adds copy listener
      document.addEventListener("copy", copy);

      setIsEditorMounted(true);

      return () => {
        setIsEditorMounted(false);
        // Removes app store listener
        cleanupStoreListener();
        // Removes copy listener
        document.removeEventListener("copy", copy);
      };
    },
    [boardId, pageName, readOnly, editorUser, handleRemovedChanges, handleAddedChanges],
  );

  useEffect(() => {
    app?.registerExternalAssetHandler("file", onCreateAssetFromFile);
  }, [app]);

  useEffect(() => {
    if (selectedShapeIds) {
      window.getSelection()?.removeAllRanges();
    }
  }, [selectedShapeIds]);

  // *********************************************
  // Hooks
  // *********************************************/
  // Call authentication endpoint
  useEffect(() => {
    const headers = new Headers({
      authorization: `Bearer ${editorUser.accessToken}`,
    });

    // Make sure we're authenticated
    fetch(`${runnerHttpUrl}/auth`, {
      credentials: "include",
      headers,
    }).then(
      (result) => {
        if (result.ok) {
          setAuthIsLoading(false);
        } else {
          setAuthError(t("Global.Error.AuthenticationError"));
          console.error("Error authenticating", result.status, result.statusText);
        }
      },
      (e) => {
        setAuthError(t("Global.Error.AuthenticationError"));
        console.error("Error authenticating", e);
      },
    );
  }, []);

  useEffect(() => {
    if (workspaceBoardData) {
      const newName = workspaceBoardData.workspaceBoard?.name || FALLBACK_DOCUMENT_NAME;
      if (app) {
        app.renamePage(app.getCurrentPage().id, newName);
      }
      setPageName(newName);
    }
  }, [workspaceBoardData]);

  useEffect(() => {
    if (selectedShapeIds) {
      if (selectedShapeIds.length === 0) {
        CommandContext.patchCommandContext({
          selection: {},
        });
      } else {
        // TODO: Do we want to filter to specific shape types?
        CommandContext.patchCommandContext({
          selection: {
            shapeIds: selectedShapeIds,
          },
        });
      }
    }
  }, [selectedShapeIds]);

  const openRenameDialog = useCallback(() => {
    setRenameDialogOpen(true);
  }, []);

  const openAclDialog = useCallback(() => {
    setAclDialogOpen(true);
  }, []);

  const publishToDocuments = useCallback(() => {
    setPublishToDocumentsDialogOpen(true);
  }, []);

  const activateThumbnailFrame = useCallback(() => {
    setThumbnailFrameActive(true);
  }, []);

  const onPublishKnowledgeBaseArticles = useCallback(
    async (articleDetails: Array<IHtmlDocumentShapeDetailsToPublish>) => {
      // Update the "name" meta property of the shape
      const updatedArticleDetails = articleDetails
        .map((details) => {
          if (!app) {
            return null;
          }

          const shape = app.getShape(details.shapeId);
          if (!shape || shape.type !== HtmlDocumentUtil.type || shape.meta?.documentType !== DocumentType.KnowledgeBaseArticle) {
            // Skip if the shape is not a knowledge base article
            return null;
          }

          // Update the shape name
          app.updateShape({
            id: shape.id,
            type: shape.type,
            meta: {
              ...shape.meta,
              name: details.name,
            },
          });

          return details;
        })
        .filter((details) => !!details) as Array<IHtmlDocumentShapeDetailsToPublish>;

      // Skip if there are no articles to publish
      if (updatedArticleDetails.length === 0) {
        return;
      }

      // Publish articles in documents
      try {
        await publishBoardDocumentShapesToDocuments({
          variables: {
            input: {
              workspaceBoardId: boardId,
              documents: updatedArticleDetails,
            },
          },
          refetchQueries: ["Documents"],
        });

        // Show success message
        toast.success(t("Components.CollaborativeBoard.PublishToDocuments.Success", { count: updatedArticleDetails.length }));

        // Close the dialog
        setPublishToDocumentsDialogOpen(false);
      } catch (error) {
        console.error("Error publishing documents...", error);
        toast.error(t("Components.CollaborativeBoard.PublishToDocuments.Error"));
      }
    },
    [app, boardId, publishBoardDocumentShapesToDocuments, t],
  );

  const syncedStore = useYjsStore({
    roomId: isAuthLoading ? "" : boardId,
    hostUrl: `${runnerWsUrl}/workspaceBoards`,
    customShapeUtils,
  });

  const onFatalBoardError = useCallback(
    (error: Error) => {
      console.error("Fatal board error", error);

      // Bail the connection, if we can, to prevent any broken state from being propagated
      syncedStore?.bail?.();
    },
    [syncedStore],
  );

  const authError2 = syncedStore.status === "error";
  const incorrectVersionError = syncedStore.error?.cause === "invalid-version";
  if (isAuthLoading || authError || syncedStore.status === "error") {
    return (
      <>
        <Box sx={{ display: "flex", flex: 1, m: 3, p: 3 }}>
          <Box sx={{ alignSelf: "center", textAlign: "center", flex: 1 }}>
            {isAuthLoading && !authError && !authError2 && <>{t("Global.Status.Loading")}</>}
            {authError && <span style={{ color: "red" }}>{authError} </span>}
            {authError2 &&
              (incorrectVersionError ? (
                <span style={{ color: "red" }}>{t("Global.Error.IncorrectVersionError")}</span>
              ) : (
                <span style={{ color: "red" }}>{t("Global.Error.UnauthorizedOrError")}</span>
              ))}
          </Box>
        </Box>
      </>
    );
  } else {
    return (
      <ErrorBoundary onError={onFatalBoardError}>
        <CollaborativeBoardEditorContext.Provider value={app || null}>
          <AddToBoardProvider>
            <CollaborativeBoardEditor
              workspaceId={workspaceId}
              customShapeUtils={customShapeUtils}
              customTools={customTools}
              syncedStore={syncedStore}
              onMount={onMount}
              openRenameDialog={openRenameDialog}
              openAclDialog={openAclDialog}
              publishToDocuments={publishToDocuments}
              activateThumbnailFrame={activateThumbnailFrame}
            />

            {aclDialogOpen && (
              <WorkspaceAccessControlListDialog
                workspaceId={workspaceId}
                open={aclDialogOpen}
                onClose={() => setAclDialogOpen(false)}
              />
            )}
            {renameDialogOpen && (
              <RenameWorkspaceBoardDialog
                workspaceBoardId={boardId}
                open={renameDialogOpen}
                onClose={() => setRenameDialogOpen(false)}
              />
            )}
            {thumbnailFrameActive && <ThumbnailFrameOverlay boardId={boardId} onClose={() => setThumbnailFrameActive(false)} />}
            {publishToDocumentsDialogOpen && (
              <PublishToDocumentsDialog
                open={publishToDocumentsDialogOpen}
                onClose={() => setPublishToDocumentsDialogOpen(false)}
                allKnowledgeBaseArticleShapes={allKnowledgeBaseArticleShapes}
                selectedKnowledgeBaseArticleShapes={selectedKnowledgeBaseArticleShapes}
                onPublish={onPublishKnowledgeBaseArticles}
              />
            )}

            <BoardSearchManager />
            <AddToBoardDialog workspaceId={workspaceId} />
          </AddToBoardProvider>
        </CollaborativeBoardEditorContext.Provider>
      </ErrorBoundary>
    );
  }
};
