import { SortOrder } from "./SortOrder.js";
import { ISortOption } from "./ISortOption.js";

export const NameAscSortOptionName = "NameAsc";

export const NameAscSortOption: ISortOption = {
  field: "name",
  name: NameAscSortOptionName,
  sortOrder: SortOrder.Asc,
};
