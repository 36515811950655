import { useApolloClient } from "@apollo/client";
import {
  AnalysisFeedbackDataItemType,
  DataGridActionCategory,
  DataGridActionType,
  FacetDisplaySortType,
  IDataGridAction,
  IDataGridActions,
  StandardHtmlColors,
} from "@bigpi/cookbook";
import {
  getQuestionAnalysisShapeDefaultProps,
  IDataGridColumnDef,
  IDataGridShape,
  IQuestionAnalysisFacets,
  IQuestionAnalysisPreferences,
  IQuestionAnalysisSelection,
  IQuestionAnalysisShape,
  questionAnalysisShapeMigrations,
  questionAnalysisShapeProps,
} from "@bigpi/tl-schema";
import { useAuthUser } from "@frontegg/react";
import { Grid, Checkbox, FormControl, InputLabel, Select, MenuItem, ListItemText } from "@mui/material";
import { atom, createShapeId, HTMLContainer, SVGContainer, TLShape, useValue } from "@tldraw/tldraw";
import * as d3 from "d3";
import { TFunction } from "i18next";
import { useCallback, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { v4 as uuidV4 } from "uuid";

import {
  FieldFacetsManageStateType,
  useApplyBounds,
  useFieldDistinctValuesQuery,
  useFieldFacetsManager,
  useFieldGroupsQuery,
  useIsChildEditing,
  useIsChildSelected,
  useQueryValidFacets,
  useSelectedDate,
  useShortcutRelatedRanges,
} from "BoardComponents/Analyses/Hooks";
import { IQuestionAnalysisConfig } from "BoardComponents/Analyses/DataFrameConfigs";
import { transformToToolbarSelectOptions } from "BoardComponents/Analyses/Utils/AnalysisToolbarDataFormatting";
import { IChartShapeProps } from "BoardComponents/Charting/IChartShapeProps";
import { IAnalysisFacets } from "BoardComponents/Types";
import { ALLOWED_ANALYSES_CHILD_STANDARD_SHAPE_TYPES, DataframeBaseUtil } from "BoardComponents/BaseShapes/DataframeBaseUtil";
import {
  IQuestionAnalysisShapeExternalData,
  IGroupedAnalystQuestionResult,
  useBoardDatastore,
} from "BoardComponents/BoardDatastore";
import { DashedOutlineBox } from "BoardComponents/DashedOutlineBox/DashedOutlineBox";
import { DataframeBackground } from "BoardComponents/DataframeBackground/DataframeBackground";
import { DataGridShapeUtil } from "BoardComponents/DataGridShape/DataGridShape";
import { useIsInteracting, useIsChildInteracting } from "BoardComponents/Tools";
import { useShapeEvents } from "BoardComponents/useShapeEvents";
import { AnalysisToolbar } from "Components/AnalysisToolbar/AnalysisToolbar";
import { AnalysisFeedbackDialog } from "Components/AnalysisFeedbackDialog/AnalysisFeedbackDialog";
import {
  AnalysisPreferencesDialog,
  IAnalysisPreferencesDialogState,
} from "Components/AnalysisPreferencesDialog/AnalysisPreferencesDialog";
import { SplitButton, SplitButtonProps } from "Components/SplitButton/SplitButton";
import { ChartUtils } from "Utils/ChartUtils";
import { DataUtils } from "Utils/DataUtils";
import { AnalysisToolbarActions } from "../AnalysisToolbarActions";
import { getGroupedAnalystQuestionsData, getQuestionAnalysisConfig } from "./groupedAnalystQuestionsDataUtils";
import { questionAnalysisFieldsConfig } from "./QuestionAnalysisFieldsConfig";

// *********************************************
// Public constants
// *********************************************/
export const QUESTION_ANALYSIS_CONFIG_KEY = "question-analysis-config";
export const TOPICS_FIELD_NAME = "topics";
export const FIRM_FIELD_NAME = "firm";
export const FIRM_TYPE_FIELD_NAME = "firmType";
export const SEGMENT_FIELD_NAME = "segment";
export const EVENT_DATE_FIELD_NAME = "eventDate";
export const DATA_GRID = "dataGrid";

// *********************************************
// Private constants
// *********************************************/
const ALLOWED_CHILD_SHAPE_TYPES = [
  ...ALLOWED_ANALYSES_CHILD_STANDARD_SHAPE_TYPES,

  // Custom shapes
  "dataGrid",
  "lockedText",
  "barChart",
  "histogramChart",
];

const GRID_ACTION_GROUP_SIMILAR_QUESTIONS = "groupSimilarQuestions";
const QUESTIONS_CLUSTERING_FIELD = "group";

const DEFAULT_DATA_GRID_WIDTH = 1500;

// Currently these are static, might be dynamic in future
const CHART_POSITIONS: Record<string, { x: number; y: number }> = {
  [EVENT_DATE_FIELD_NAME]: { x: 10, y: 50 },
  [SEGMENT_FIELD_NAME]: { x: 10, y: 500 },
  [FIRM_TYPE_FIELD_NAME]: { x: 10, y: 800 },
  [FIRM_FIELD_NAME]: { x: 10, y: 1100 },
  [TOPICS_FIELD_NAME]: { x: 800, y: 500 },
  [DATA_GRID]: { x: 10, y: 2000 },
};

// *********************************************
// Shape Util
// *********************************************/
/**
 * Generator for QuestionAnalysis shapes.
 */
export class QuestionAnalysisUtil extends DataframeBaseUtil<IQuestionAnalysisShape> {
  // *********************************************
  // Static fields
  // *********************************************/
  static type = "questionAnalysis";

  static props = questionAnalysisShapeProps;

  static migrations = questionAnalysisShapeMigrations;

  // *********************************************
  // Override methods, event handlers
  // *********************************************/
  /**
   * Listens to the onChildrenChange event & adjusts the shape size.
   *
   * @param shape Shape on which the children changed
   */
  override onChildrenChange = (shape: IQuestionAnalysisShape) => {
    const children = this.editor.getSortedChildIdsForParent(shape.id);

    // Remove the data frame if there are no children
    if (children.length === 0) {
      if (this.editor.getFocusedGroupId() === shape.id) {
        this.editor.popFocusedGroupId();
      }
      this.editor.deleteShapes([shape.id]);
    }
  };

  // *********************************************
  // Override methods
  // *********************************************/
  /**
   * @inheritdoc
   */
  override isAspectRatioLocked = (shape: IQuestionAnalysisShape) => false;

  /**
   * @inheritdoc
   */
  override canResize = (shape: IQuestionAnalysisShape) => false;

  /**
   * @inheritdoc
   */
  override canBind = (shape: IQuestionAnalysisShape) => true;

  /**
   * @inheritdoc
   */
  override canEdit = (shape: IQuestionAnalysisShape) => true;

  /**
   * @inheritdoc
   */
  override canReceiveNewChildrenOfType = (_shape: IQuestionAnalysisShape, type: TLShape["type"]) => {
    return ALLOWED_CHILD_SHAPE_TYPES.includes(type);
  };

  /**
   * @inheritdoc
   */
  override canScroll = (shape: IQuestionAnalysisShape) => false;

  /**
   * @inheritdoc
   */
  hideSelectionBoundsBg = () => false;

  /**
   * @inheritdoc
   */
  hideSelectionBoundsFg = () => true;

  /**
   * @inheritdoc
   */
  override getDefaultProps(): IQuestionAnalysisShape["props"] {
    return getQuestionAnalysisShapeDefaultProps();
  }

  /**
   * @inheritdoc
   */
  override component(shape: IQuestionAnalysisShape) {
    const tldrawEditor = this.editor;
    const zoomLevel = tldrawEditor.getZoomLevel();
    const { id, props } = shape;
    const { t } = useTranslation();
    const apolloClient = useApolloClient();
    const selectedFacetValuesProp = props.selectedFacetValues as IQuestionAnalysisFacets;
    const boundsFacetValuesProp = props.boundsFacetValues as IQuestionAnalysisFacets;
    const toolbar = props.toolbar;
    const selection = props.selection;
    const analysisPreferences = props.preferences.analysis;

    const bounds = this.getGeometry(shape).getBounds();
    const isEditing = useValue("isEditing", () => tldrawEditor.getCurrentPageState().editingShapeId === id, [tldrawEditor, id]);
    const isHovered = useValue("isHovered", () => tldrawEditor.getCurrentPageState().hoveredShapeId === id, [tldrawEditor, id]);
    const isSelected = useValue("isSelected", () => tldrawEditor.getCurrentPageState().selectedShapeIds.includes(id), [
      tldrawEditor,
      id,
    ]);
    const isInteracting = useIsInteracting(id);
    const isChildInteracting = useIsChildInteracting(id);
    const isChildSelected = useIsChildSelected(shape.id);
    const isChildEditing = useIsChildEditing(shape.id);

    const { handleInputPointerDown } = useShapeEvents(shape.id);
    const user = useAuthUser();

    const [config, setConfig] = useState({} as IQuestionAnalysisConfig);
    const [configLoading, setConfigLoading] = useState<boolean>(false);

    // Date range shortcuts
    const dateRangeShortcuts = useShortcutRelatedRanges(
      config.dateShortcuts ? config.dateShortcuts[EVENT_DATE_FIELD_NAME] : undefined,
    );

    // Transforms date shortcut
    const selectedFacetValuesSelectedDate = useSelectedDate(
      dateRangeShortcuts,
      selectedFacetValuesProp.eventDateShortcut,
      selectedFacetValuesProp.eventDate,
    );
    const boundsFacetValuesSelectedDate = useSelectedDate(
      dateRangeShortcuts,
      boundsFacetValuesProp.eventDateShortcut,
      boundsFacetValuesProp.eventDate,
    );

    const selectedFacetValues = useValue(
      "selectedFacetValues",
      () => {
        return {
          ...selectedFacetValuesProp,
          eventDate: selectedFacetValuesSelectedDate,
        };
      },
      [selectedFacetValuesSelectedDate, selectedFacetValuesProp],
    );

    const boundsFacetValues = useValue(
      "boundsFacetValues",
      () => {
        return {
          ...boundsFacetValuesProp,
          eventDate: boundsFacetValuesSelectedDate,
        };
      },
      [boundsFacetValuesSelectedDate, boundsFacetValuesProp],
    );

    // Merge boundsFacets with facets
    const mergedFacetValues = useApplyBounds(boundsFacetValues, selectedFacetValues);

    // Validate the facets to fit for query
    const validMergedFacetValues = useQueryValidFacets(mergedFacetValues, questionAnalysisFieldsConfig);
    const validBoundsFacetValues = useQueryValidFacets(boundsFacetValues, questionAnalysisFieldsConfig);

    // Distinct values & field groups queries
    const fieldConfigAllFields = useValue(
      "fieldConfigAllFields",
      () => {
        return questionAnalysisFieldsConfig.map((fieldConfig) => fieldConfig.field);
      },
      [questionAnalysisFieldsConfig],
    );

    // Isolates facets for each field
    const fieldIsolatedFacets = useValue(
      "fieldIsolatedFacets",
      () => {
        const isolatedFacets: Record<string, IQuestionAnalysisFacets> = {};
        fieldConfigAllFields?.forEach((field) => {
          isolatedFacets[field] = {
            ...validMergedFacetValues,
            [field]: validBoundsFacetValues[field],
          };
        });
        return isolatedFacets;
      },
      [fieldConfigAllFields, validBoundsFacetValues, validMergedFacetValues],
    );

    const fetchQuestionAnalysisConfig = useCallback(async () => {
      setConfigLoading(true);

      // Any changes in the process of getting the config must be handled in
      // the `getQuestionAnalysisConfig` function since this function
      // is also used by the `BoardSearchManager` internally to get the filtered analysis data.
      return getQuestionAnalysisConfig(apolloClient, user.tenantId).finally(() => {
        setConfigLoading(false);
      });
    }, [apolloClient, user.tenantId]);

    // Fetch the config and set it in the state
    useEffect(() => {
      fetchQuestionAnalysisConfig().then((questionAnalysisConfig) => {
        setConfig(questionAnalysisConfig);
      });
    }, [fetchQuestionAnalysisConfig]);

    const { data: queryAllDistinctValues } = useFieldDistinctValuesQuery<IQuestionAnalysisFacets>(
      shape.type,
      fieldConfigAllFields,
      {},
      {},
      questionAnalysisFieldsConfig,
    );

    const { data: queryFieldGroupsResult } = useFieldGroupsQuery<IQuestionAnalysisFacets>(
      shape.type,
      fieldConfigAllFields,
      fieldIsolatedFacets,
      questionAnalysisFieldsConfig,
    );

    // Format all distinct values
    const allDistinctValues = useValue(
      "allDistinctValues",
      () => {
        if (!queryAllDistinctValues) {
          return {} as Record<keyof IQuestionAnalysisFacets, any>;
        }
        // We have the specific formatting for segment & topics
        const { segment, topics, ...other } = queryAllDistinctValues;
        const formattedDistinctValues = transformToToolbarSelectOptions(shape.type, other, questionAnalysisFieldsConfig);

        const segmentValues = segment.segment?.map((value: string) => ({ key: value, label: value }));
        const topicsValues = topics.topics?.map((value: string) => ({ key: value, label: value }));

        return {
          ...formattedDistinctValues,
          segment: segmentValues,
          topics: topicsValues,
        };
      },
      [queryAllDistinctValues, shape, questionAnalysisFieldsConfig],
    );

    // Formats bounds distinct values
    const boundsDistinctValues = useValue(
      "boundsDistinctValues",
      () => {
        if (!queryFieldGroupsResult) {
          return {} as Record<keyof IQuestionAnalysisFacets, any>;
        }

        const formattedBoundsDistinctValues = transformToToolbarSelectOptions(
          shape.type,
          queryFieldGroupsResult,
          questionAnalysisFieldsConfig,
        );

        return formattedBoundsDistinctValues;
      },
      [queryFieldGroupsResult, shape, questionAnalysisFieldsConfig],
    );

    // Facets update
    const fieldFacetsManager = useFieldFacetsManager(
      tldrawEditor,
      shape,
      selectedFacetValues,
      this.updateSelectedFacetValues,
      questionAnalysisFieldsConfig,
    );
    const boundsFacetsManager = useFieldFacetsManager(
      tldrawEditor,
      shape,
      boundsFacetValues,
      this.updateBoundsFacetValues,
      questionAnalysisFieldsConfig,
      false,
    );

    // State
    const [isFeedbackDialogOpen, setIsFeedbackDialogOpen] = useState<boolean>(false);
    const [isPreferencesDialogOpen, setIsPreferencesDialogOpen] = useState<boolean>(false);
    const [data, setData] = useState<Array<IGroupedAnalystQuestionResult>>(
      DataUtils.getImmutableEmptyArray<IGroupedAnalystQuestionResult>(),
    );
    // To store the data without event date toolbar facets
    const [eventDateRelatedData, setEventDateRelatedData] = useState<Array<IGroupedAnalystQuestionResult>>(
      DataUtils.getImmutableEmptyArray<IGroupedAnalystQuestionResult>(),
    );
    // To store the data without topics toolbar facets
    const [topicsRelatedData, setTopicsRelatedDate] = useState<Array<IGroupedAnalystQuestionResult>>(
      DataUtils.getImmutableEmptyArray<IGroupedAnalystQuestionResult>(),
    );
    const [dataLoading, setDataLoading] = useState<boolean>(false);
    const [filteredData, setFilteredData] = useState<Array<IGroupedAnalystQuestionResult>>(data);
    const [toolbarContainerRef, setToolbarContainerRef] = useState<HTMLDivElement | null>(null);
    const [isConfigSyncWithShapeProps, setIsConfigSyncWithShapeProps] = useState<boolean>(false);

    const childShapeTypes = useValue(
      "childShapeTypes",
      () => {
        const childIds = this.editor.getSortedChildIdsForParent(shape.id);
        return childIds.map((childId) => {
          const shape = this.editor.getShape(childId);
          const shapeProps = shape?.props as IChartShapeProps;
          return `${shape?.type}${this._appendComponentKey(shapeProps.field)}`;
        });
      },
      [tldrawEditor, id],
    );

    // Make sure we have an entry in the datastore for this shape
    const datastore = useBoardDatastore();

    // Create the datastore state with our values
    useEffect(() => {
      if (!datastore.hasShapeData(shape.id)) {
        datastore.setShapeData(shape.id, {
          // Set the full data in the datastore
          allData: atom(`board.datastore.${shape.id}.allData`, data),
          config: atom(`board.datastore.${shape.id}.config`, config),
          configLoading: atom(`board.datastore.${shape.id}.configLoading`, false),
          dataGridActions: atom(`board.datastore.${shape.id}.dataGridActions`, {}),
          dataGridConfig: atom(`board.datastore.${shape.id}.dataGridConfig`, {}),
          dataGridSelectedIds: atom(`board.datastore.${shape.id}.dataGridSelectedIds`, []),
          dataLoading: atom(`board.datastore.${shape.id}.dataLoading`, false),
          facets: atom(`board.datastore.${shape.id}.facets`, selectedFacetValues),
          filteredData: atom(`board.datastore.${shape.id}.filteredData`, data),
          getToolbar: atom(`board.datastore.${shape.id}.getToolbar`, () => null),
          onFieldFacetManagerFieldChange: atom(`board.datastore.${shape.id}.onFieldFacetManagerFieldChange`, () => null),
          onSelectionChange: atom(`board.datastore.${shape.id}.onSelectionChange`, () => null),
          onSelectedIdsChanged: atom(`board.datastore.${shape.id}.onSelectedIdsChanged`, () => null),
          onUpdatePreferences: atom(`board.datastore.${shape.id}.onUpdatePreferences`, () => null),
          preferences: atom(`board.datastore.${shape.id}.preferences`, props.preferences),
          selection: atom(`board.datastore.${shape.id}.selection`, {}),
          slotsData: atom(`board.datastore.${shape.id}.slotsData`, {}),
          type: "questionAnalysis",
        });
      }
    }, []);

    const fetchGroupedAnalystQuestionsData = useCallback(
      async (facets: IQuestionAnalysisFacets) => {
        setDataLoading(true);

        // Any changes in the process of getting the filtered data must be handled in
        // the `getGroupedAnalystQuestionsData` function since this function
        // is also used by the `BoardSearchManager` to get the filtered analysis data.
        return getGroupedAnalystQuestionsData(apolloClient, facets, user.tenantId, t).finally(() => {
          setDataLoading(false);
        });
      },
      [apolloClient, user.tenantId, t],
    );

    // Fetch the filtered data and set it in the state
    useEffect(() => {
      if (config && Object.keys(config).length > 0 && isConfigSyncWithShapeProps) {
        fetchGroupedAnalystQuestionsData(validMergedFacetValues).then((groupedAnalystQuestionsData) => {
          setData(groupedAnalystQuestionsData);
          setFilteredData(groupedAnalystQuestionsData);
        });
      }
    }, [isConfigSyncWithShapeProps, config, fetchGroupedAnalystQuestionsData, validMergedFacetValues]);

    // Event date facets excluded data
    // If we use filteredData without excluding eventDate facets, that limits the chart to dispally only selected facet value
    useEffect(() => {
      if (config && Object.keys(config).length > 0 && isConfigSyncWithShapeProps) {
        fetchGroupedAnalystQuestionsData(fieldIsolatedFacets[EVENT_DATE_FIELD_NAME]).then((eventDateRelatedData) => {
          setEventDateRelatedData(eventDateRelatedData);
        });
      }
    }, [fieldIsolatedFacets, isConfigSyncWithShapeProps]);

    // Topics faces excluded data
    // This data helps to identify the co-occurrence count for the filtered data
    useEffect(() => {
      if (config && Object.keys(config).length > 0 && isConfigSyncWithShapeProps) {
        fetchGroupedAnalystQuestionsData(fieldIsolatedFacets[TOPICS_FIELD_NAME]).then((topicsRelatedData) => {
          setTopicsRelatedDate(topicsRelatedData);
        });
      }
    }, [fieldIsolatedFacets, isConfigSyncWithShapeProps]);

    // Update the datastore config when it changes
    useEffect(() => {
      const storeData = datastore.state.get()[shape.id].get() as IQuestionAnalysisShapeExternalData;
      storeData.dataGridConfig?.set(config.dataGrid);

      // Insert child shapes
      const childShapes = this.editor.getSortedChildIdsForParent(shape.id);
      if (config && Object.keys(config).length > 0 && childShapes.length === 0) {
        // Uses the initial state to set the preferences and facets
        this.editor.updateShapes([
          {
            id: shape.id,
            type: shape.type,
            props: {
              preferences: {
                ...(config.initialState?.preferences || {}),
              },
              selectedFacetValues: {
                ...(config.initialState?.selectedFacetValues || {}),
                ...selectedFacetValues,
              },
              boundsFacetValues: {
                ...(config.initialState?.boundsFacetValues || {}),
                ...boundsFacetValues,
              },
              toolbar: {
                ...config?.toolbar,
                ...toolbar,
              },
            },
          },
        ]);

        this._initialRenderOfChildren(shape, config, t);
        setIsConfigSyncWithShapeProps(true);
      } else if (config && Object.keys(config).length > 0 && childShapes.length > 0) {
        setIsConfigSyncWithShapeProps(true);
      }
    }, [config, selectedFacetValues, boundsFacetValues, toolbar]);

    // Update the datastore facets when they change
    useEffect(() => {
      const storeData = datastore.state.get()[shape.id].get() as IQuestionAnalysisShapeExternalData;
      storeData.facets.set(selectedFacetValues);
    }, [selectedFacetValues, shape.id]);

    // Update the datastore data when it changes
    useEffect(() => {
      const storeData = datastore.state.get()[shape.id].get() as IQuestionAnalysisShapeExternalData;
      storeData.allData.set(data);
    }, [data, shape.id]);

    useEffect(() => {
      const storeData = datastore.state.get()[shape.id].get() as IQuestionAnalysisShapeExternalData;
      storeData.filteredData?.set(data);
    }, [filteredData, shape.id]);

    useEffect(() => {
      const storeData = datastore.state.get()[shape.id].get() as IQuestionAnalysisShapeExternalData;
      storeData.dataLoading?.set(dataLoading);
    }, [dataLoading, shape.id]);

    useEffect(() => {
      const storeData = datastore.state.get()[shape.id].get() as IQuestionAnalysisShapeExternalData;
      storeData.configLoading?.set(configLoading);
    }, [configLoading, shape.id]);

    useEffect(() => {
      const storeData = datastore.state.get()[shape.id].get() as IQuestionAnalysisShapeExternalData;
      storeData.config.set(this._addComponentFillsToConfig(config));
      storeData.dataGridConfig?.set(config.dataGrid);
    }, [config]);

    const onSelectedIdsChanged = useCallback(
      (selectedIds: Array<string>) => {
        const storeData = datastore.state.get()[shape.id].get() as IQuestionAnalysisShapeExternalData;
        storeData.dataGridSelectedIds?.set(selectedIds);
      },
      [datastore, shape],
    );

    // Update the datastore onSelectedIdsChanged method when it changes
    useEffect(() => {
      const storeData = datastore.state.get()[shape.id].get() as IQuestionAnalysisShapeExternalData;
      storeData.onSelectedIdsChanged?.set(onSelectedIdsChanged);
    }, [datastore, onSelectedIdsChanged]);

    useEffect(() => {
      const storeData = datastore.state.get()[shape.id].get() as IQuestionAnalysisShapeExternalData;
      storeData.slotsData?.set({
        ...boundsDistinctValues,
        // Event date distinct values are derived from the eventDateRelatedData
        [EVENT_DATE_FIELD_NAME]: eventDateRelatedData.map((row: IGroupedAnalystQuestionResult) => {
          return {
            ...row,
            [EVENT_DATE_FIELD_NAME]: new Date(row[EVENT_DATE_FIELD_NAME]),
          };
        }),
        // Topics distinct values are derived from the topicsRelatedData
        // Removing the co-occurrence to make sync with other charts for now
        // [TOPICS_FIELD_NAME]: this._getTopicDistinctValues(topicsRelatedData, selectedFacetValues),
      } as Record<string, any>);
    }, [datastore, boundsDistinctValues, eventDateRelatedData, topicsRelatedData, selectedFacetValues]);

    useEffect(() => {
      const storeData = datastore.state.get()[shape.id].get() as IQuestionAnalysisShapeExternalData;
      storeData.onFieldFacetManagerFieldChange?.set(fieldFacetsManager.onFieldChange);
    }, [fieldFacetsManager]);

    const onSelectionChange = useCallback(
      (selection: IQuestionAnalysisSelection) => {
        tldrawEditor.updateShapes([
          {
            id: shape.id,
            type: shape.type,
            props: {
              selection,
            },
          },
        ]);
      },
      [shape, tldrawEditor],
    );

    useEffect(() => {
      const storeData = datastore.state.get()[shape.id].get() as IQuestionAnalysisShapeExternalData;
      storeData.onSelectionChange?.set(onSelectionChange);
    }, [onSelectionChange]);

    useEffect(() => {
      const storeData = datastore.state.get()[shape.id].get() as IQuestionAnalysisShapeExternalData;
      storeData.selection?.set(selection || {});
    }, [selection]);

    const onUpdatePreferences = useCallback(
      (key: keyof IQuestionAnalysisPreferences, newPreferences: Record<string, any>) => {
        tldrawEditor.updateShapes([
          {
            id: shape.id,
            type: shape.type,
            props: {
              preferences: {
                ...shape.props.preferences,
                [key]: newPreferences,
              },
            },
          },
        ]);
      },
      [shape, tldrawEditor],
    );

    // Update the datastore onUpdatePreferences method when it changes
    useEffect(() => {
      const storeData = datastore.state.get()[shape.id].get() as IQuestionAnalysisShapeExternalData;
      storeData.onUpdatePreferences?.set(onUpdatePreferences);
    }, [datastore, onUpdatePreferences]);

    // Update the datastore preferences when they change
    useEffect(() => {
      const storeData = datastore.state.get()[shape.id].get() as IQuestionAnalysisShapeExternalData;
      storeData.preferences?.set(props.preferences || {});
    }, [datastore, props.preferences]);

    // Group similar questions action handler
    const groupSimilarQuestions = useCallback(() => {
      const storeData = datastore.state.get()[shape.id].get() as IQuestionAnalysisShapeExternalData;
      const dataGridActions: IDataGridActions = storeData.dataGridActions?.get() || {};
      const dataGridShapeId = tldrawEditor.getSortedChildIdsForParent(shape.id).find((id) => {
        return tldrawEditor.getShape(id)?.type === DataGridShapeUtil.type;
      });
      if (dataGridShapeId) {
        const dataGridShape = tldrawEditor.getShape(dataGridShapeId) as IDataGridShape;
        if (dataGridShape) {
          const rowGroupingModel = [...(dataGridShape.props.preferences?.rowGroupingModel || [])];

          // Update the data grid columns to hide the clustering column
          const columns = dataGridShape.props.config?.columns || {};
          const newColumns = columns.map((column: IDataGridColumnDef) => {
            const otherProps: Partial<IDataGridColumnDef> = {};
            if (column.field === QUESTIONS_CLUSTERING_FIELD) {
              otherProps["isVisible"] = false;
            }
            return {
              ...column,
              ...otherProps,
            };
          });

          // Update the row grouping model
          const clusterIndex = rowGroupingModel.findIndex((group) => group === QUESTIONS_CLUSTERING_FIELD);
          if (clusterIndex > -1) {
            rowGroupingModel.splice(clusterIndex, 1);
          } else {
            rowGroupingModel.splice(0, 0, QUESTIONS_CLUSTERING_FIELD);
          }

          tldrawEditor.updateShapes([
            {
              id: dataGridShape.id,
              type: dataGridShape.type,
              props: {
                config: {
                  ...dataGridShape.props.config,
                  columns: newColumns,
                },
                preferences: {
                  ...dataGridShape.props.preferences,
                  rowGroupingModel,
                },
              },
            },
          ]);

          // Update datastore actions state
          dataGridActions?.[DataGridActionCategory.Manage]?.forEach((action: IDataGridAction) => {
            if (action.key === GRID_ACTION_GROUP_SIMILAR_QUESTIONS) {
              action.isSelected = rowGroupingModel.includes(QUESTIONS_CLUSTERING_FIELD);
            }
          });
          storeData.dataGridActions?.set(dataGridActions);
        }
      }
    }, [shape, tldrawEditor]);

    // Add data grid actions to the datastore
    useEffect(() => {
      const storeData = datastore.state.get()[shape.id].get() as IQuestionAnalysisShapeExternalData;
      const dataGridShapeId = tldrawEditor.getSortedChildIdsForParent(shape.id).find((id) => {
        return tldrawEditor.getShape(id)?.type === DataGridShapeUtil.type;
      });
      const dataGridShape = dataGridShapeId ? (tldrawEditor.getShape(dataGridShapeId) as IDataGridShape) : undefined;
      storeData.dataGridActions?.set({
        [DataGridActionCategory.Manage]: [
          {
            key: GRID_ACTION_GROUP_SIMILAR_QUESTIONS,
            title: t("Components.Analyses.QuestionAnalysis.DataGrid.Actions.GroupSimilarQuestions"),
            onAction: groupSimilarQuestions,
            type: DataGridActionType.Checkbox,
            isSelected: dataGridShape?.props.preferences?.rowGroupingModel?.includes(QUESTIONS_CLUSTERING_FIELD),
          },
        ],
      });
    }, [datastore, t, groupSimilarQuestions, shape]);

    const onShowFeedbackDialog = useCallback(() => {
      setIsFeedbackDialogOpen(true);
    }, []);

    const onCloseFeedbackDialog = useCallback(() => {
      setIsFeedbackDialogOpen(false);
    }, []);

    const onShowPreferencesDialog = useCallback(() => {
      setIsPreferencesDialogOpen(true);
    }, []);

    const onClosePreferencesDialog = useCallback(() => {
      setIsPreferencesDialogOpen(false);
    }, []);

    // Create a callback to get the toolbar for child shapes to show
    const getToolbar = useCallback(() => {
      if (toolbarContainerRef === null) {
        return null;
      }

      // Take anlaysis preferences & facet sort
      const facetSort = analysisPreferences?.facetSort || [];
      const facetSortMap =
        facetSort.reduce(
          (acc, sort) => {
            if (sort && sort.field && sort.sort) {
              acc[sort.field] = sort.sort;
            }
            return acc;
          },
          {} as Record<string, FacetDisplaySortType>,
        ) || {};

      // Takes data store
      const storeData = datastore.state.get()[shape.id].get() as IQuestionAnalysisShapeExternalData;
      const dataGridSelectedIds = storeData.dataGridSelectedIds?.get() || [];

      // Split button options
      const options: SplitButtonProps["options"] = [
        {
          value: AnalysisToolbarActions.Reset,
          label: t("Components.Charts.Reset"),
          disabled: !selectedFacetValues || Object.keys(selectedFacetValues).length === 0,
        },
        {
          value: AnalysisToolbarActions.DownloadCsv,
          label: t("Components.Charts.DownloadCsv", { count: dataGridSelectedIds.length || data.length }),
        },
      ];
      const addToDocument = config.dataGrid?.addToDocument;
      if (addToDocument) {
        options.push({
          value: AnalysisToolbarActions.AddToDocument,
          label: t("Components.Charts.AddItems", { count: dataGridSelectedIds.length || data.length }),
        });
      }

      options.push(
        {
          value: AnalysisToolbarActions.ProvideFeedback,
          label: t("Components.Charts.ProvideFeedback"),
          disabled: dataGridSelectedIds.length === 0 || data.length === 0,
        },
        {
          value: AnalysisToolbarActions.Preferences,
          label: `${t("Components.Analyses.Common.PreferencesDialog.Preferences")}...`,
        },
      );

      return createPortal(
        <AnalysisToolbar<IQuestionAnalysisFacets>
          fields={toolbar?.availableFields || []}
          selectedFacetValues={selectedFacetValues}
          distinctValues={boundsDistinctValues as Record<keyof IQuestionAnalysisFacets, any>}
          dateRangeShortcuts={dateRangeShortcuts}
          fieldsConfig={questionAnalysisFieldsConfig}
          fieldsSort={facetSortMap}
          onFieldChange={fieldFacetsManager.onFieldChange}
          onSortChange={_onFacetSortChange.bind(undefined, analysisPreferences)}
        >
          {/* DATA SHAPES */}
          {analysisPreferences?.showDataToDisplayInToolbar && (
            <Grid item sx={{ mr: 6, padding: "10px", width: 150 }}>
              <FormControl sx={{ width: 140 }}>
                <InputLabel id={`available-shapes-${shape.id}`}>{t("Components.Charts.DataToDisplay")}</InputLabel>
                <Select
                  sx={{ width: 150 }}
                  id={`available-shapes-${shape.id}`}
                  label={t("Components.Charts.DataToDisplay")}
                  multiple
                  value={childShapeTypes || DataUtils.getImmutableEmptyArray<string>()}
                  onChange={(event) => {
                    this._onAvailableShapesChange(
                      t,
                      shape,
                      event.target.value as Array<string>,
                      childShapeTypes,
                      config,
                      data.length,
                      dataGridSelectedIds.length,
                    );
                  }}
                  renderValue={(selected) =>
                    t("Components.Charts.SelectedOfTotal", {
                      selected: selected.length,
                      total: config.allowedChildShapes?.length,
                    })
                  }
                  MenuProps={{
                    classes: { paper: "analysis-select-menu" },
                    MenuListProps: { classes: { root: "analysis-select-menu-list" } },
                  }}
                >
                  {config.allowedChildShapes?.map((allowedShape) => {
                    return (
                      <MenuItem
                        key={`${allowedShape.type}${this._appendComponentKey(allowedShape.componentKey)}`}
                        value={`${allowedShape.type}${this._appendComponentKey(allowedShape.componentKey)}`}
                        classes={{ selected: "analysis-select-menu-item" }}
                      >
                        <Checkbox
                          checked={childShapeTypes.includes(
                            `${allowedShape.type}${this._appendComponentKey(allowedShape.componentKey)}`,
                          )}
                        />
                        <ListItemText primary={allowedShape.componentLabel} />
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid item sx={{ padding: "10px" }}>
            <SplitButton
              options={options}
              handleClick={(option: string) => {
                if (AnalysisToolbarActions.Preferences === option) {
                  onShowPreferencesDialog();
                } else if (AnalysisToolbarActions.Reset === option) {
                  fieldFacetsManager.onResetFacets();
                } else {
                  this.onSplitButtonClick(
                    shape,
                    config,
                    data,
                    selectedFacetValues,
                    dataGridSelectedIds,
                    t,
                    apolloClient,
                    option,
                    onShowFeedbackDialog,
                  );
                }
              }}
            />
          </Grid>
        </AnalysisToolbar>,
        toolbarContainerRef,
      );
    }, [
      analysisPreferences,
      apolloClient,
      boundsDistinctValues,
      childShapeTypes,
      config,
      data,
      datastore,
      dateRangeShortcuts,
      onShowPreferencesDialog,
      props,
      selectedFacetValues,
      toolbar,
      toolbarContainerRef,
    ]);

    // Update the datastore with the getToolbar callback
    useEffect(() => {
      const storeData = datastore.state.get()[shape.id].get() as IQuestionAnalysisShapeExternalData;
      storeData.getToolbar.set(getToolbar);
    }, [getToolbar]);

    // These are used by the feedback dialog
    const storeData = datastore?.state.get()[shape.id]?.get() as IQuestionAnalysisShapeExternalData;
    const dataGridSelectedIds = storeData?.dataGridSelectedIds?.get() || [];

    useEffect(() => {
      this._updateTitleLabel(t, shape, data.length, dataGridSelectedIds.length);
    }, [data, dataGridSelectedIds]);

    return (
      <>
        {isEditing || isSelected || isChildSelected ? (
          <SVGContainer>
            <DashedOutlineBox className="tl-group" bounds={bounds} zoomLevel={zoomLevel} />
          </SVGContainer>
        ) : null}
        <DataframeBackground
          enableBackgroundColor={!!props.enableBackground}
          enableBorder={!isHovered && !isEditing && !isSelected && !isChildSelected && !!props.enableBackground}
          bounds={bounds}
        />
        <HTMLContainer
          className="topic-discussion-analysis-shape"
          style={{
            background: "transparent",
            pointerEvents: "all",
          }}
        >
          <div onPointerDown={handleInputPointerDown}>
            <div
              ref={setToolbarContainerRef}
              style={{
                marginTop: -100,
                display: "flex",
                justifyContent: "center",
                position: "absolute",
                top: bounds.y,
                left: bounds.midX,
              }}
            />
            {(isEditing || isInteracting || isChildEditing || isChildInteracting) && getToolbar()}
            <AnalysisFeedbackDialog
              dataItemIds={dataGridSelectedIds}
              dataItemType={AnalysisFeedbackDataItemType.AnalystQuestion}
              onClose={onCloseFeedbackDialog}
              open={isFeedbackDialogOpen}
            />
            {isPreferencesDialogOpen && (
              <AnalysisPreferencesDialog<IQuestionAnalysisFacets>
                dateRangeShortcuts={dateRangeShortcuts}
                distinctValues={allDistinctValues as Record<keyof IQuestionAnalysisFacets, any>}
                selectedFacetValues={boundsFacetsManager.finalFacets}
                fields={props.selectedBoundsFields || ([] as Array<keyof IAnalysisFacets>)}
                fieldsConfig={questionAnalysisFieldsConfig}
                onCancelPreferences={() => {
                  onClosePreferencesDialog();
                  boundsFacetsManager.onCancelFacets();
                }}
                onFieldChange={boundsFacetsManager.onFieldChange}
                open={isPreferencesDialogOpen}
                onClose={onClosePreferencesDialog}
                onSavePreferences={(toolbarPreferences) => {
                  onClosePreferencesDialog();
                  boundsFacetsManager.onSaveFacets();
                  this._saveToolbarPreferences(shape, toolbarPreferences, fieldFacetsManager);
                }}
                toolbarFields={toolbar?.availableFields || []}
                toolbarFieldFacets={selectedFacetValues}
                // Options props
                isBackgroundEnabled={props.enableBackground || false}
                isDataToDisplayInToolbarEnabled={analysisPreferences?.showDataToDisplayInToolbar || false}
                hideStartColor={true}
                hideSubItems={true}
              />
            )}
          </div>
        </HTMLContainer>
      </>
    );

    // *********************************************
    // Render private methods
    // *********************************************/
    /**
     * Updates facet sort preferences for the given field.
     *
     * @param analysisPreferences Analysis preferences stored on shape props
     * @param fieldId Field id to update the facet sort updates
     * @param sort Latest sort
     */
    function _onFacetSortChange(
      analysisPreferences: IQuestionAnalysisPreferences["analysis"],
      fieldId: string,
      sort: FacetDisplaySortType,
    ) {
      const facetSort = [...(analysisPreferences?.facetSort || [])];
      let fieldIndex = facetSort.findIndex((sortOption) => sortOption.field === fieldId);
      if (fieldIndex > -1) {
        facetSort.splice(fieldIndex, 1, {
          field: fieldId,
          sort,
        });
      } else {
        facetSort.push({
          field: fieldId,
          sort,
        });
      }
      onUpdatePreferences("analysis", {
        ...analysisPreferences,
        facetSort,
      });
    }
  }

  // *********************************************
  // Private methods, event handlers
  // *********************************************/
  /**
   * Checks the existing child shapes & alters (delete/create) based on the latest user selection.
   *
   * @param t The translation function
   * @param shape Current shape.
   * @param value The latest available shapes slected from the dropdown.
   * @param childShapeTypes The current existing child shape types.
   * @param facets Current facets.
   * @param config The analysis configuration.
   * @param totalCount The total number of data rows.
   * @param selectedCount The number of rows that are currently selected.
   */
  private _onAvailableShapesChange(
    t: TFunction<"translation", undefined>,
    shape: IQuestionAnalysisShape,
    value: Array<string>,
    childShapeTypes: Array<string>,
    config: IQuestionAnalysisConfig,
    totalCount: number,
    selectedCount: number,
  ) {
    let shapeTypesToCreate: Array<string> = [];
    let shapeTypesToDelete: Array<string> = [];
    if (value.length === 0) {
      shapeTypesToDelete = childShapeTypes;
    } else if (childShapeTypes.length === 0) {
      shapeTypesToCreate = value;
    } else {
      value.forEach((v) => {
        if (!childShapeTypes.includes(v)) {
          shapeTypesToCreate.push(v);
        }
      });
      childShapeTypes.forEach((v) => {
        if (!value.includes(v)) {
          shapeTypesToDelete.push(v);
        }
      });
    }

    if (shapeTypesToDelete.length > 0) {
      const shapesToDelete = this.editor.getSortedChildIdsForParent(shape.id).filter((child) => {
        const childShape = this.editor.getShape(child);
        if (!childShape) {
          return false;
        }
        const childShapeProps = childShape.props as IChartShapeProps;
        return shapeTypesToDelete.includes(`${childShape.type}${this._appendComponentKey(childShapeProps.field)}`);
      });

      this.editor.deleteShapes(shapesToDelete);
    }

    if (shapeTypesToCreate.length > 0) {
      this._createChildShapes(shape, shapeTypesToCreate, config, t);
    }
    if (shapeTypesToCreate.includes("lockedText")) {
      this._updateTitleLabel(t, shape, totalCount, selectedCount);
    }
  }

  // *********************************************
  // Private methods
  // *********************************************/
  /**
   * Initial creation of child shapes based on the config.
   *
   * @param shape Current shape
   * @param config Config to use
   * @param t Translation function
   */
  private _initialRenderOfChildren(
    shape: IQuestionAnalysisShape,
    config: IQuestionAnalysisConfig,
    t: TFunction<"translation", undefined>,
  ) {
    const allShapes = config.allowedChildShapes.map(
      (allowedShape) => `${allowedShape.type}${this._appendComponentKey(allowedShape.componentKey)}`,
    );
    this._createChildShapes(shape, config.initialShapes || allShapes, config, t);
  }

  /**
   * Creates the child shapes based on the config allowedChildShapes order
   *
   * @param shape Current shape
   * @param shapesToCreate Shapes to create
   * @param config Config for the current shape
   * @param t Translation function
   */
  private _createChildShapes(
    shape: IQuestionAnalysisShape,
    shapesToCreate: Array<string>,
    config: IQuestionAnalysisConfig,
    t: TFunction<"translation", undefined>,
  ) {
    const { id } = shape;
    const tldrawEditor = this.editor;
    const latestShape = tldrawEditor.getShape(id) as IQuestionAnalysisShape;
    // Takes latest props
    const preferences = latestShape?.props?.preferences;

    // Follows the order as per given config.allowedChildShapes
    const readyToCreateShapes = shapesToCreate.map((shapeToCreate) => {
      const shapeType = shapeToCreate.split(":")[0];
      const field = shapeToCreate.split(":")[1];

      const props: Record<string, any> = {};
      if (shapeType === "dataGrid") {
        props["w"] = 1500;
        props["config"] = {
          ...config.dataGrid,
          fontSize: config.fontSize,
          autoResize: true,
        };
        props["preferences"] = { ...preferences?.dataGrid };
      }
      if (!["dataGrid", "lockedText"].includes(shapeType)) {
        props["field"] = field;
      }

      const shapeId = createShapeId(uuidV4());
      const chartPosition = CHART_POSITIONS[field || shapeType];
      return {
        id: shapeId,
        parentId: id,
        type: shapeType,
        props,
        ...chartPosition,
      };
    });

    this.editor.createShapes(readyToCreateShapes);
  }

  /**
   * Uses counts to update the data frame title label.
   *
   * @param t The translation function
   * @param shape Current parent data frame shape.
   * @param totalCount The total number of items.
   * @param selectedCount The number of selected items.
   */
  private _updateTitleLabel(
    t: TFunction<"translation", undefined>,
    shape: IQuestionAnalysisShape,
    totalCount: number,
    selectedCount: number,
  ) {
    const lockedTextShapeId = this.editor.getSortedChildIdsForParent(shape.id).find((childId) => {
      const childShape = this.editor.getShape(childId);
      if (childShape) {
        return childShape.type === "lockedText";
      }
      return false;
    });

    if (!lockedTextShapeId) {
      return;
    }
    const lockedTextShape = this.editor.getShape(lockedTextShapeId);
    const textLabel = t(
      selectedCount === 0
        ? "Components.Analyses.QuestionAnalysis.LabelTemplateAll"
        : "Components.Analyses.QuestionAnalysis.LabelTemplate",
      {
        count: totalCount,
        selectedCount,
      },
    );

    if (!lockedTextShape) {
      return;
    }

    this.editor.updateShapes([
      {
        id: lockedTextShape.id,
        type: lockedTextShape.type,
        props: {
          text: textLabel,
        },
      },
    ]);
  }

  /**
   * Takes preferences from the dialog & updates to shape props.
   *
   * @param shape Shape to update
   * @param prefereces Selected preferences
   * @param fieldFacetsManager Field facet manager which helps to change the facet values
   */
  private _saveToolbarPreferences(
    shape: IQuestionAnalysisShape,
    prefereces: IAnalysisPreferencesDialogState,
    fieldFacetsManager: FieldFacetsManageStateType<IQuestionAnalysisFacets>,
  ) {
    const oldToolbarFields = shape.props.toolbar?.availableFields;
    const removedFields = oldToolbarFields?.filter((field) => !prefereces.toolbarFields.includes(field));
    this.editor.updateShapes([
      {
        id: shape.id,
        type: shape.type,
        props: {
          enableBackground: prefereces.isBackgroundEnabled,
          selectedBoundsFields: prefereces.toolbarBoundsFields,
          toolbar: {
            availableFields: prefereces.toolbarFields,
          },
          preferences: {
            ...shape.props.preferences,
            analysis: {
              ...shape.props.preferences.analysis,
              showDataToDisplayInToolbar: prefereces.isDataToDisplayInToolbarEnabled,
            },
          },
        },
      },
    ]);

    fieldFacetsManager.onClearSelectedFacetValues(removedFields as Array<keyof IQuestionAnalysisFacets>);
  }

  /**
   * Updates the config with latest fills
   *
   * @param config Config to update
   * @returns Updated config with latest fill colors
   */
  private _addComponentFillsToConfig(config: IQuestionAnalysisConfig): IQuestionAnalysisConfig {
    const components = config.components;
    if (!components) {
      return config;
    }
    const latestComponenents: Record<string, any> = {};
    Object.keys(components).forEach((componentKey) => {
      const componentConfig = components[componentKey].config;
      const markOptions = componentConfig.markOptions || componentConfig.barMarkOptions;
      if (
        componentConfig &&
        typeof markOptions.fill === "string" &&
        [SEGMENT_FIELD_NAME, FIRM_TYPE_FIELD_NAME, FIRM_FIELD_NAME].includes(
          componentKey as typeof SEGMENT_FIELD_NAME | typeof FIRM_TYPE_FIELD_NAME | typeof FIRM_FIELD_NAME,
        )
      ) {
        componentConfig.barMarkOptions.fill = this._analystQuestionBarFill(componentKey, componentConfig.barMarkOptions.fill);
      } else if (componentConfig && typeof markOptions.fill === "string" && componentKey === TOPICS_FIELD_NAME) {
        componentConfig.barMarkOptions.fill = this._analystQuestionBarFillTopics(
          componentKey,
          componentConfig.barMarkOptions.fill,
        );
      } else if (componentConfig && typeof markOptions.fill === "string" && componentKey === EVENT_DATE_FIELD_NAME) {
        componentConfig.markOptions.fill = this._analystQuestionHistogramFill(componentKey, componentConfig.markOptions.fill);
      }
      latestComponenents[componentKey] = {
        ...components[componentKey],
        config: componentConfig,
      };
    });
    config.components = latestComponenents;
    return config as IQuestionAnalysisConfig;
  }

  /**
   * Fill the bar with color if the facet field is selected
   *
   * @param facetField Facet field name
   * @param color Color to fill the bar
   * @returns Based on the selected values, returns the color to fill the bar
   */
  private _analystQuestionBarFill(facetField: string, color: string) {
    return (facets: IQuestionAnalysisFacets, dataItem: { key: string; count: number }) => {
      const selectedValues =
        facets[facetField as typeof FIRM_FIELD_NAME | typeof FIRM_TYPE_FIELD_NAME | typeof SEGMENT_FIELD_NAME];
      if (!selectedValues || selectedValues.length == 0 || selectedValues.includes(dataItem.key)) {
        return color;
      } else {
        return StandardHtmlColors.gray30;
      }
    };
  }

  /**
   * Fill the bar with color if the facet field is selected
   * @param facetField Facet field name
   * @param color Color to fill the bar
   * @returns Based on the selected values, returns the color to fill the bar
   */
  private _analystQuestionBarFillTopics(facetField: typeof TOPICS_FIELD_NAME, color: string) {
    return this._analystQuestionBarFill(facetField, color);
    // Removing the co-occurrence to make sync with other charts for now
    // return (facets: IQuestionAnalysisFacets, dataItem: { key: string; count: number; label: string }) => {
    //   const selectedValues = facets[facetField] || [];
    //   if (dataItem.label && dataItem.label === "direct" && selectedValues.length > 0) {
    //     return `${StandardHtmlColors.gray30}80`;
    //   }
    //   if (dataItem.label && dataItem.label === "cooccurrence" && selectedValues.includes(dataItem.key)) {
    //     return `${color}CC`;
    //   }
    //   if (dataItem.label && dataItem.label === "cooccurrence" && selectedValues.length > 0) {
    //     return `${color}66`;
    //   }
    //   return color;
    // };
  }

  /**
   * Fill the rects with color if the facet field is selected
   * @param facetField Facet field name
   * @param color Color to fill the bar
   * @returns Based on the selected values, returns the color to fill the bar
   */
  private _analystQuestionHistogramFill(facetField: typeof EVENT_DATE_FIELD_NAME, color: string) {
    return (facets: IQuestionAnalysisFacets, dataItem: Record<string, any>) => {
      const selectedValues = facets[facetField];
      if (selectedValues && (selectedValues.from || selectedValues.to)) {
        const inRange = ChartUtils.isInRange(
          `${selectedValues?.from || ""}#${selectedValues?.to || ""}`,
          dataItem[EVENT_DATE_FIELD_NAME],
        );
        return inRange ? color : StandardHtmlColors.gray30;
      }
      return color;
    };
  }

  /**
   * Returns the key to be appended to the shapeType
   *
   * @param key Key to be appended
   * @returns Appended key when exists, if not empty string
   */
  private _appendComponentKey(key: string) {
    return key ? `:${key}` : "";
  }

  /**
   * Checks the selected topics against
   *
   * @param data Data without topics toolbar facets
   * @param facets Applied facet values
   * @returns Topics with co-occurrence count
   */
  private _getTopicDistinctValues(data: Array<IGroupedAnalystQuestionResult>, facets: IQuestionAnalysisFacets) {
    const selectedTopics = facets[TOPICS_FIELD_NAME];
    const topics: Array<string> = [];
    data.forEach((row: any) => {
      const topic = row[TOPICS_FIELD_NAME];
      topics.push(...topic);
    });
    const topicsMap = d3.rollups(
      topics,
      (v) => v.length,
      (d) => d,
    );
    const topicsArray: Array<Record<string, any>> = [];
    topicsMap.forEach((topic) => {
      const topicText = topic[0];
      const topicCount = topic[1];
      let cooccurrenceTopicsCount = 0;
      if (selectedTopics && selectedTopics.length > 0) {
        cooccurrenceTopicsCount = this._getCooccurrenceTopicQuestions(data, [...selectedTopics, topicText]);
        if (cooccurrenceTopicsCount > 0) {
          topicsArray.push({
            key: topicText,
            count: cooccurrenceTopicsCount,
            label: "cooccurrence",
            text: cooccurrenceTopicsCount > 0 ? `${cooccurrenceTopicsCount}/${topicCount}` : topicCount,
          });
        }
      }
      topicsArray.push({
        key: topicText,
        count: topicCount - cooccurrenceTopicsCount,
        label: "direct",
        text: cooccurrenceTopicsCount > 0 ? "" : topicCount,
      });
    });
    return topicsArray;
  }

  /**
   * Checks for the exact match of given topics in each data item & returns the matched count
   *
   * @param data Data to check
   * @param topics Topics to check for co-occurrence
   * @returns Count of data items where given topics has exact match
   */
  private _getCooccurrenceTopicQuestions(data: Array<IGroupedAnalystQuestionResult>, topics: Array<string>) {
    const relatedQuestions = data.filter((item) => {
      const exists = topics.map((topic) => {
        const itemTopics = item[TOPICS_FIELD_NAME].map((v) => v.trim());
        const existingDataTopics = itemTopics.filter((itemTopic) => itemTopic === topic);
        return existingDataTopics.length > 0;
      });
      return exists.length > 0 && !exists.includes(false);
    });
    return relatedQuestions.length;
  }
}
