import { useIsPageVisible, useIsUserActive } from "@bigpi/cutlery";
import { useCallback, useEffect } from "react";

import { useImageTokenQuery } from "GraphQL/Generated/Apollo";

// Keeping poll interval to 5 minutes, so that the user has at least 10 minutes
// for pasting the content
const DEFAULT_IMAGE_TOKEN_POLLING_TIME = 5 * 60000;

/**
 * Custom hook to poll for image token every 5 minutes and stop polling when the user is inactive.
 */
export const useImageTokenPolling = () => {
  const {
    refetch: refetchImageToken,
    startPolling: startImageTokenPolling,
    stopPolling: stopImageTokenPolling,
  } = useImageTokenQuery({
    // We want to get the latest value from server every time the call is made
    fetchPolicy: "network-only",
  });

  const { isPageVisible } = useIsPageVisible();
  const { isUserActive } = useIsUserActive();

  const refetchTokenAndStartPolling = useCallback(() => {
    refetchImageToken();
    startImageTokenPolling(DEFAULT_IMAGE_TOKEN_POLLING_TIME);
  }, [refetchImageToken, startImageTokenPolling]);

  useEffect(() => {
    // Start polling for image token only when the page is visible and the user is active
    if (isPageVisible && isUserActive) {
      refetchTokenAndStartPolling();
    }

    // Stop polling on unmount
    return () => {
      stopImageTokenPolling();
    };
  }, [isPageVisible, isUserActive, refetchTokenAndStartPolling, stopImageTokenPolling]);
};
