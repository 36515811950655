// @ts-expect-error
const serverData = window.SERVER_DATA || {};

export const Config = {
  apiGatewayHttpUrl: serverData.REACT_APP_API_GW_HTTP_URL || process.env.REACT_APP_API_GW_HTTP_URL,
  assetHttpUrl: serverData.REACT_APP_ASSET_HTTP_URL || process.env.REACT_APP_ASSET_HTTP_URL,
  fronteggBaseUrl: serverData.REACT_APP_FRONTEGG_BASE_URL || process.env.REACT_APP_FRONTEGG_BASE_URL,
  fronteggClientId: serverData.REACT_APP_FRONTEGG_CLIENT_ID || process.env.REACT_APP_FRONTEGG_CLIENT_ID,
  muiXPremiumLicense: serverData.REACT_APP_MUI_X_PREMIUM_LICENSE || process.env.REACT_APP_MUI_X_PREMIUM_LICENSE,
  pantryPublicWsUrl: serverData.REACT_APP_PANTRY_PUBLIC_WS_URL || process.env.REACT_APP_PANTRY_PUBLIC_WS_URL,
  patronHttpUrl: serverData.REACT_APP_PATRON_HTTP_URL || process.env.REACT_APP_PATRON_HTTP_URL,
  previewHttpUrl: serverData.REACT_APP_PREVIEW_HTTP_URL || process.env.REACT_APP_PREVIEW_HTTP_URL,
  runnerHttpUrl: serverData.REACT_APP_RUNNER_HTTP_URL || process.env.REACT_APP_RUNNER_HTTP_URL,
  runnerWsUrl: serverData.REACT_APP_RUNNER_WS_URL || process.env.REACT_APP_RUNNER_WS_URL,
  sentryDsn: serverData.REACT_APP_SENTRY_DSN || process.env.REACT_APP_SENTRY_DSN,
  sentryEnvironment: serverData.REACT_APP_SENTRY_ENVIRONMENT || process.env.REACT_APP_SENTRY_ENVIRONMENT,
  sentryRelease: serverData.REACT_APP_SENTRY_RELEASE || process.env.REACT_APP_SENTRY_RELEASE,
  sentryTracesSampleRate: serverData.REACT_APP_SENTRY_TRACES_SAMPLE_RATE
    ? Number.parseFloat(serverData.REACT_APP_SENTRY_TRACES_SAMPLE_RATE)
    : process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE
      ? Number.parseFloat(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE)
      : 0,
  sentryTracingOrigins: serverData.REACT_APP_SENTRY_TRACING_ORIGINS || process.env.REACT_APP_SENTRY_TRACING_ORIGINS,
  waiterHttpUrl: serverData.REACT_APP_WAITER_HTTP_URL || process.env.REACT_APP_WAITER_HTTP_URL,
  waiterWsUrl: serverData.REACT_APP_WAITER_WS_URL || process.env.REACT_APP_WAITER_WS_URL,
};
