import { mergeAttributes } from "@tiptap/core";
import { TextStyle as TipTapTextStyle } from "@tiptap/extension-text-style";

import { getClassAttribute } from "../../Utils/ExtensionUtil.js";
import { DefaultHighlightColors, HighlightColors } from "./TextHighlightExtension.js";
import { setTextColor } from "./SetTextColorCommand.js";
import { unsetTextColor } from "./UnsetTextColorCommand.js";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    customTextStyleCommands: {
      /**
       * Set the text color
       */
      setTextColor: (color: TextColors) => ReturnType;
      /**
       * Unset the text color
       */
      unsetTextColor: () => ReturnType;
    };
  }
}

export enum TextColors {
  YELLOW = "yellow",
  RED = "red",
  WHITE = "white",
  SILVER = "silver",
}

export const DefaultTextColors: Array<TextColors> = [TextColors.YELLOW, TextColors.RED, TextColors.WHITE, TextColors.SILVER];

export const TextStyle = TipTapTextStyle.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      class: getClassAttribute(),
    };
  },

  parseHTML() {
    return [
      {
        tag: "span",
        getAttrs: (node) => {
          const highlightClasses = DefaultHighlightColors.map((color: HighlightColors) => `highlight-${color}`);
          const includesHighlightClass = highlightClasses.some((c) => (node as HTMLElement).className.includes(c));

          // We don't want to parse the node if it has a highlight class, the TextHighlightExtension
          // will handle it
          return !includesHighlightClass && null;
        },
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["span", mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },

  addCommands() {
    return {
      ...this.parent?.(),
      setTextColor,
      unsetTextColor,
    };
  },
});
