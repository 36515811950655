import { Extension } from "@tiptap/core";

import { exportToWord } from "Editor/Extensions/ExportToWord/ExportToWordCommand";

export const ExportToWord = Extension.create({
  name: "exportToWord",

  addCommands() {
    return {
      exportToWord,
    };
  },
});
