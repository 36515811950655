import { gql } from "@apollo/client";

export const CREATE_USER_GROUP_MEMBER = gql`
  mutation CreateUserGroupMember($input: CreateUserGroupMemberInput!) {
    createUserGroupMember(input: $input) {
      id
      userGroup {
        id
        name
      }
      user {
        id
        name
      }

      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const UPDATE_USER_GROUP_MEMBER = gql`
  mutation UpdateUserGroupMember($input: UpdateUserGroupMemberInput!) {
    updateUserGroupMember(input: $input) {
      id
      userGroup {
        id
        name
      }
      user {
        id
        name
      }

      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const DELETE_USER_GROUP_MEMBER = gql`
  mutation DeleteUserGroupMember($input: DeleteUserGroupMemberInput!) {
    deleteUserGroupMember(input: $input) {
      id
    }
  }
`;
