import { Box, useTheme } from "@mui/system";

import { RightSidebarTitleComponent, useRightSidebarSx } from "Hooks/useAppRightSidebarComponents";

/***********************************
 * Component
 ***********************************/
export function SidebarHeader() {
  const theme = useTheme();
  const rightSidebarSx = useRightSidebarSx();

  return (
    <Box sx={{ height: "40px", borderBottom: `1px solid ${theme.palette.divider}`, ...rightSidebarSx }}>
      <RightSidebarTitleComponent />
    </Box>
  );
}
