import { Dialog, DialogContent, DialogTitle, DialogActions, Button, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";

import { FileAccessControlListEditor } from "Components/AccessControlList/FileAccessControlListEditor";
import { useFileQuery } from "GraphQL/Generated/Apollo";

export interface FileAccessControlListDialogProps {
  open: boolean;
  onClose: () => void;
  fileId: string;
}

export function FileAccessControlListDialog(props: FileAccessControlListDialogProps) {
  const { t } = useTranslation();
  const { onClose, open, fileId } = props;
  const { data, loading, error } = useFileQuery({ variables: { id: fileId } });
  const name = data?.file?.name || "";

  return (
    <Dialog onClose={onClose} open={open} maxWidth="md" fullWidth={true} disableEscapeKeyDown={false}>
      <DialogTitle variant="h5">
        <Trans i18nKey="Components.FileAccessControlListDialog.Title">Share "{{ name }}"</Trans>
      </DialogTitle>
      <DialogContent>
        <FileAccessControlListEditor fileId={fileId} />
      </DialogContent>
      <DialogActions sx={{ mb: 2, mr: 2 }}>
        <Button onClick={onClose}>{t("Global.Action.Close")}</Button>
      </DialogActions>
    </Dialog>
  );
}
