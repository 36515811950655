import { User } from "@bigpi/permission";

export type ChatUser = Pick<User, "name" | "id" | "email" | "profilePictureUrl">;

// latinAccentChars:
// Copyright 2018 Twitter, Inc.
// Licensed under the Apache License, Version 2.0
// http://www.apache.org/licenses/LICENSE-2.0
// const latinAccentChars = /\xC0-\xD6\xD8-\xF6\xF8-\xFF\u0100-\u024F\u0253\u0254\u0256\u0257\u0259\u025B\u0263\u0268\u026F\u0272\u0289\u028B\u02BB\u0300-\u036F\u1E00-\u1EFF/;
// Allow mention candidates with a maximum of five spaces within: "@sir de' la first middle last-name"
export const getMentionsMatchRegexp = () =>
  /(?<!@)@(?:[a-zA-Z0-9-.'\xC0-\xD6\xD8-\xF6\xF8-\xFF\u0100-\u024F\u0253\u0254\u0256\u0257\u0259\u025B\u0263\u0268\u026F\u0272\u0289\u028B\u02BB\u0300-\u036F\u1E00-\u1EFF]+[\s]*){1,5}/g;

export const getPrivateMentionsMatchRegexp = () =>
  /@@(?:[a-zA-Z0-9-.'\xC0-\xD6\xD8-\xF6\xF8-\xFF\u0100-\u024F\u0253\u0254\u0256\u0257\u0259\u025B\u0263\u0268\u026F\u0272\u0289\u028B\u02BB\u0300-\u036F\u1E00-\u1EFF]+[\s]*){1,5}/g;

/**
 * Finds candidates with "@" in the given message, maps them to users.
 *
 * @param message Chat message as plain text or html text to extract user mentions from
 * @param users Chat users to match against candidates in the message
 *
 * @returns The candidate users found in the message
 */
export function extractChatMessageCandidateUsers(message: string, users: Array<ChatUser>): Array<ChatUser> {
  const candidates = extractUserNameCandidates(message, getMentionsMatchRegexp());
  return mapCandidatesToUsers(candidates, users).mappedUsers;
}

/**
 * Finds the candidates with "@@" in the given message, maps them to users.
 *
 * @param message Chat message as plain text or html text to extract user mentions from
 * @param users Chat users to match against candidates in the message
 *
 * @returns The private candidate users found int he message
 */
export function extractChatMessagePrivateCandidateUsers(message: string, users: Array<ChatUser>): Array<ChatUser> {
  const candidates = extractUserNameCandidates(message, getPrivateMentionsMatchRegexp());
  return mapCandidatesToUsers(candidates, users).mappedUsers;
}

/**
 * Get the mentions which not mapped to any users.
 *
 * @param message Chat message as plain text or html text to extract missing user mentions from
 * @param users Chat users to match against candidates in the message
 *
 * @returns
 */
export function getChatMessageMissingCandidateUsers(message: string, users: Array<ChatUser>): Array<string> {
  const candidates = extractUserNameCandidates(message, getMentionsMatchRegexp());
  return mapCandidatesToUsers(candidates, users).invalidCandidates;
}

/**
 * Get the private mentions which not mapped to any users.
 *
 * @param message Chat message as plain text or html text to extract missing user mentions from
 * @param users Chat users to match against candidates in the message
 *
 * @returns
 */
export function getChatMessageMissingPrivateCandidateUsers(message: string, users: Array<ChatUser>): Array<string> {
  const candidates = extractUserNameCandidates(message, getPrivateMentionsMatchRegexp());
  return mapCandidatesToUsers(candidates, users).invalidCandidates;
}

/**
 * Highlights the missing user names in the message with given className.
 *
 * @param message Message to replace missing user names with mentions
 * @param missingUsers Missing users list of user names
 * @param mentionIdentifier Mention identifier to identify the mention (@/@@)
 * @param className Class name to replace with
 *
 * @returns Replaced message
 */
export function highlightChatMessageMissingUser(
  message: string,
  missingUsers: Array<string>,
  mentionIdentifier: string,
  className: string,
) {
  let resultMessage = message;
  for (const user of missingUsers) {
    // Create a regex to match the candidate username with an @ prefix
    const mentionRegex = new RegExp(`${mentionIdentifier}${user}`, "gi");

    // Replace all occurrences of the candidate with the <span> tag, if not already enclosed in a mention tag
    resultMessage = resultMessage.replace(mentionRegex, `<span class="${className}">${mentionIdentifier}${user}</span>`);
  }

  return resultMessage;
}

/**
 * Removes the given class name from the given message.
 *
 * @param message Message to sanitize
 * @param className Class name to sanitize
 *
 * @returns
 */
export function removeExistingMentions(message: string, className: string) {
  const sanitizeRegex = new RegExp(`<span[^>]*class=["']?${className}["']?[^>]*>(.*?)<\/span>`, "gi");
  return message.replace(sanitizeRegex, "$1");
}

/**
 * Removes existing mentions & replaces user names with mentions in the message.
 *
 * @param message Message in which the users will be replaced
 * @param candidateUsers Candidate users who are extracted from message & mapped to actual users
 * @param className Class name to replace with
 *
 * @returns Message with mentions in place of matched users
 */
export function replaceChatMessageUserNamesWithMentions(message: string, candidateUsers: Array<ChatUser>, className: string) {
  const cleanedMessage = removeExistingMentions(message, className);

  let resultMessage: string = cleanedMessage;

  for (const user of candidateUsers) {
    // Create a regex to match the candidate username with an @ prefix
    const mentionRegex = new RegExp(`@${user.name}`, "gi");

    // Replace all occurrences of the candidate with the <span> tag, if not already enclosed in a mention tag
    resultMessage = resultMessage.replace(mentionRegex, `<span class="${className}">@${user.name}</span>`);
  }

  return resultMessage;
}

/**
 * Removes existing private mentions & replaces user names with private mentions in the message.
 *
 * @param message Message in which the users will be replaced
 * @param candidateUsers Candidate users who are extracted from message & mapped to actual users
 * @param className Class name to replace with
 *
 * @returns Message with private metnions in place of matched users
 */
export function replaceChatMessageUserNamesWithPrivateMentions(
  message: string,
  candidateUsers: Array<ChatUser>,
  className: string,
) {
  const cleanedMessage = removeExistingMentions(message, className);
  let resultMessage: string = cleanedMessage;

  for (const user of candidateUsers) {
    // Create a regex to match the candidate username with an @@ prefix
    const privateMentionRegex = new RegExp(`@@${user.name}`, "gi");

    // Replace all occurrences of the candidate with the <span> tag, if not already enclosed in a private mention tag
    resultMessage = resultMessage.replace(privateMentionRegex, `<span class="${className}">@@${user.name}</span>`);
  }

  return resultMessage;
}

/**
 * Extracts user name candidates from the message.
 *
 * Each candidate is an array of strings that represent the user name with variations of spaces with the most specific first.
 * For example a single candidate might be ["sir de' la first middle last-name", "sir de' la first middle", "sir de' la first", "sir de' la"]
 *
 * Exact duplicate candidates are removed, but variations (up to the maximum allowed spaces within candidate names) are kept.
 * For example, if the message contains `@sir de' la` and `@sir de' la first`, both are kept since they are different.
 *
 * @param message The plain text version of the message.
 *
 * @returns The user name candidates or an empty array if none are found.
 */
export function extractUserNameCandidates(message: string, mentionRegex: RegExp): Array<Array<string>> {
  const result: Array<Array<string>> = [];
  const candidates: Array<Array<string>> = [];

  // Use a regular expression to find all the potential user names from the text version of the message
  const matchCandidates = message.match(mentionRegex);
  // Loop through each match and create candidates that contain the variations with spaces
  if (matchCandidates) {
    for (let match of matchCandidates) {
      const candidate: Array<string> = [];

      // Remove the @ or "." at the end, from the match. Ex: "@sir de' la." => "sir de' la"
      // There may be possibility that candidate name at the end of sentence & having ".", if we don't remove name will not match with user name
      match = match.replaceAll(/@|\.$/g, "").trim();

      // Add the full match (without @)
      candidate.push(match);

      // Find the last space in the match
      let lastSpaceIndex = match.lastIndexOf(" ");

      // Add the variations with spaces
      while (lastSpaceIndex !== -1) {
        candidate.push(match.substring(0, lastSpaceIndex));
        lastSpaceIndex = match.lastIndexOf(" ", lastSpaceIndex - 1);
      }

      if (candidate.length > 0) {
        candidates.push(candidate);
      }
    }
  }

  // Remove duplicates
  const uniqueCandidates: Array<string> = [];
  for (const candidate of candidates) {
    const mergedCandidate = candidate.join("|");
    if (!uniqueCandidates.includes(mergedCandidate)) {
      uniqueCandidates.push(mergedCandidate);
      result.push(candidate);
    }
  }

  return result;
}

/**
 * Maps the user name candidates to matching available user names.
 *
 * @param candidates The candidate names to match. Note that the most specific candidate must be first in each array.
 * @param users The available users to match against.
 *
 * @returns The matching user names or an empty array if none are found.
 */
export function mapCandidatesToUsers(candidates: Array<Array<string>>, users: Array<ChatUser>) {
  const mappedUsers: Array<ChatUser> = [];
  const invalidCandidates: Array<string> = [];

  for (const candidate of candidates) {
    let found = false;
    for (const userName of candidate) {
      // Check most specific first and move to next candidate if we find a match
      const user = users.find((user) => user.name.toLowerCase() === userName.toLowerCase());

      if (user) {
        // Don't add duplicates
        if (!mappedUsers.includes(user)) {
          mappedUsers.push(user);
        }

        // Move to next full candidate
        found = true;
        break;
      }
    }

    if (!found) {
      invalidCandidates.push(candidate[candidate.length - 1]);
    }
  }

  return { mappedUsers, invalidCandidates };
}
