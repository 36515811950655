import { ContentTypes, ONE_MB, WORKSPACE_FILE_UPLOAD_MAX_FILE_SIZE } from "@bigpi/cookbook";
import { Add, CheckBox, CheckBoxOutlineBlank, FileUpload, Folder, ViewCozy } from "@mui/icons-material";
import { TabContext, TabPanel } from "@mui/lab";
import { Box, Button, IconButton, styled, Tab, Tabs } from "@mui/material";
import { JSXElementConstructor, ReactElement, SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDropzone, Accept, FileRejection, ErrorCode as ReactDropzoneErrorCode } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { v4 as uuidV4 } from "uuid";

import { IFileWithId } from "Components/Upload/FilesList";
import { WorkspaceFileUploadDialog } from "Components/Workspace/WorkspaceFileUploadDialog";
import { useWorkspaceFileAggregateQuery, useWorkspaceBoardAggregateQuery, WorkspaceFilesQuery } from "GraphQL/Generated/Apollo";
import { NewWorkspaceBoardDialog } from "./NewWorkspaceBoardDialog";
import { WorkspaceBoardView } from "./WorkspaceBoardView";
import { WorkspaceFileMultiSelectActionButton } from "./WorkspaceFileMultiSelectActionButton";
import { WorkspaceFileView } from "./WorkspaceFileView";

// *********************************************
// Types/Interfaces/Constants
// *********************************************/
enum WorkspaceContentViewTabs {
  Boards = "Boards",
  Files = "Files",
}

export type WorkspaceContentGridSize = "small" | "medium" | "large";

export interface IWorkspaceContentViewProps {
  gridSize: WorkspaceContentGridSize;
  onGridSizeChange: (gridSize: WorkspaceContentGridSize) => void;
  filterTags: Array<string>;
  workspaceId: string;
  includeUntagged: boolean;
  viewType: "list" | "grid";
  searchValue: string;
}

const WORKSPACE_FILE_UPLOAD_ACCEPTED_MIMETYPE: Accept = {
  [ContentTypes.PDF]: [".pdf"],
  [ContentTypes.DOC]: [".doc"],
  [ContentTypes.DOCX]: [".docx"],
  [ContentTypes.RTF]: [".rtf"],
  [ContentTypes.RTF_TEXT]: [".rtf"],
  [ContentTypes.RTF_X]: [".rtf"],
  [ContentTypes.RTF_RICHTEXT]: [".rtf"],
  [ContentTypes.PLAIN_TEXT]: [".txt"],
  [ContentTypes.HTML]: [".html"],
};

const WORKSPACE_FILE_UPLOAD_MAX_FILES = 50;

// *********************************************
// Component
// *********************************************/
export function WorkspaceContentView(props: IWorkspaceContentViewProps) {
  const { gridSize, onGridSizeChange, filterTags, includeUntagged, workspaceId, viewType, searchValue } = props;

  // State
  const [filesToUpload, setFilesToUpload] = useState<Array<IFileWithId>>([]);
  const [isDroppingFiles, setIsDroppingFiles] = useState(false);
  const [isSelectionEnabled, setIsSelectionEnabled] = useState(false);
  const [newWorkspaceDialogOpen, setNewWorkspaceDialogOpen] = useState(false);
  const [selectedWorkspaceFiles, setSelectedWorkspaceFiles] = useState<Array<WorkspaceFilesQuery["workspaceFiles"][number]>>([]);
  const [tabValue, setTabValue] = useState(WorkspaceContentViewTabs.Boards);

  // Memo
  const selectedIds = useMemo(
    () => selectedWorkspaceFiles.filter((file) => file !== null && file !== undefined).map((file) => file.id),
    [selectedWorkspaceFiles],
  );

  // Ref
  const inputRef = useRef<HTMLInputElement>(null);

  const { t } = useTranslation();

  const { data: workspaceBoardsData } = useWorkspaceBoardAggregateQuery({
    variables: {
      workspaceId,
      filters: {
        tags: filterTags,
        includeUntagged,
        searchTerm: searchValue,
      },
    },
  });
  const boardCount = workspaceBoardsData?.workspaceBoardAggregate.count || 0;

  const { data: workspaceFilesData } = useWorkspaceFileAggregateQuery({
    variables: {
      workspaceId,
      filters: {
        searchTerm: searchValue,
      },
    },
  });
  const fileCount = workspaceFilesData?.workspaceFileAggregate.count || 0;

  const handleTabChange = useCallback((_event: SyntheticEvent, newValue: WorkspaceContentViewTabs) => {
    setTabValue(newValue);
  }, []);

  // Dropzone
  const { getRootProps, getInputProps } = useDropzone({
    // Allow click to open file dialog
    noClick: true,
    noKeyboard: true,

    // Accepted file types
    accept: WORKSPACE_FILE_UPLOAD_ACCEPTED_MIMETYPE,

    // Maximum file size allowed
    maxSize: WORKSPACE_FILE_UPLOAD_MAX_FILE_SIZE,

    // Maximum number of files allowed
    maxFiles: WORKSPACE_FILE_UPLOAD_MAX_FILES,

    // Called when files are dropped/ selected
    onDrop: (acceptedFiles: Array<File>) => {
      setFilesToUpload((prev) => {
        const newFiles = acceptedFiles.map((file) => {
          return {
            id: uuidV4(),
            file,
          };
        });

        return [...prev, ...newFiles];
      });
      setIsDroppingFiles(false);
    },

    onDragEnter: () => {
      setIsDroppingFiles(true);
    },
    onDragLeave: () => {
      setIsDroppingFiles(false);
    },

    // Called when files are rejected
    onDropRejected: (fileRejections: Array<FileRejection>) => {
      const messages: Set<string> = new Set();
      fileRejections.forEach((fileRejection) => {
        fileRejection.errors.forEach((error) => {
          switch (error.code) {
            case ReactDropzoneErrorCode.FileInvalidType:
              messages.add(t("Components.WorkspaceFileUploadDialog.Errors.FileInvalidType"));
              break;
            case ReactDropzoneErrorCode.FileTooLarge:
              messages.add(
                t("Components.WorkspaceFileUploadDialog.Errors.FileTooLarge", {
                  maxInMBs: WORKSPACE_FILE_UPLOAD_MAX_FILE_SIZE / ONE_MB,
                }),
              );
              break;
            default:
              messages.add(
                t("Components.WorkspaceFileUploadDialog.Errors.TooManyFiles", {
                  maxFiles: WORKSPACE_FILE_UPLOAD_MAX_FILES,
                }),
              );
              break;
          }
        });
      });

      toast.error(Array.from(messages).join("; "));
    },

    // Allow multiple files to be uploaded
    multiple: true,
  });

  const { ref, ...rootProps } = getRootProps();

  const onWorkspaceFileSelectionChange = useCallback((row: WorkspaceFilesQuery["workspaceFiles"][number]) => {
    setSelectedWorkspaceFiles((prev) => {
      if (prev.includes(row)) {
        return prev.filter((i) => i.id !== row.id);
      } else {
        return [...prev, row];
      }
    });
  }, []);

  const TABS = useMemo<
    Array<{
      label: string;
      value: WorkspaceContentViewTabs;
      icon: ReactElement<any, string | JSXElementConstructor<any>>;
      component: ReactElement<any, string | JSXElementConstructor<any>>;
    }>
  >(
    () => [
      {
        label: t("Components.WorkspaceContentView.Tabs.Boards", { boardCount }),
        value: WorkspaceContentViewTabs.Boards,
        icon: <ViewCozy />,
        component: (
          <WorkspaceBoardView
            gridSize={gridSize}
            onGridSizeChange={onGridSizeChange}
            filterTags={filterTags}
            includeUntagged={includeUntagged}
            viewType={viewType}
            workspaceId={workspaceId}
            searchValue={searchValue}
          />
        ),
      },
      {
        label: t("Components.WorkspaceContentView.Tabs.Files", { fileCount: fileCount }),
        value: WorkspaceContentViewTabs.Files,
        icon: <Folder />,
        component: (
          <Box
            {...rootProps}
            ref={ref}
            sx={{
              backgroundColor: isDroppingFiles ? "#e6efff" : "transparent",
            }}
          >
            <WorkspaceFileView
              gridSize={gridSize}
              isSelectionEnabled={isSelectionEnabled}
              onGridSizeChange={onGridSizeChange}
              onSelectionChange={onWorkspaceFileSelectionChange}
              searchValue={searchValue}
              selectedIds={selectedIds}
              viewType={viewType}
              workspaceId={workspaceId}
            />
          </Box>
        ),
      },
    ],
    [
      boardCount,
      fileCount,
      filterTags,
      gridSize,
      includeUntagged,
      isDroppingFiles,
      isSelectionEnabled,
      onGridSizeChange,
      onWorkspaceFileSelectionChange,
      searchValue,
      selectedWorkspaceFiles,
      t,
      viewType,
      workspaceId,
    ],
  );

  useEffect(() => {
    if (filesToUpload.length === 0 && inputRef.current) {
      // When selected files are cleared, reset the input value to allow the same file to be uploaded again
      inputRef.current.value = "";
    }
  }, [filesToUpload]);

  return (
    <Box sx={{ px: 3 }}>
      <Box
        sx={{ borderBottom: 1, borderColor: "divider", display: "flex", justifyContent: "space-between", alignItems: "center" }}
      >
        <Tabs value={tabValue} onChange={handleTabChange}>
          {TABS.map((tab) => {
            return <Tab key={tab.value} icon={tab.icon} iconPosition="start" label={tab.label} value={tab.value} />;
          })}
        </Tabs>

        <Box sx={{ display: "flex" }}>
          {tabValue === WorkspaceContentViewTabs.Boards ? (
            <Button
              sx={{ width: "120px" }}
              startIcon={<Add />}
              variant="contained"
              onClick={() => setNewWorkspaceDialogOpen(true)}
            >
              {t("Components.WorkspaceContentView.New")}
            </Button>
          ) : (
            <>
              <IconButton size="medium" sx={{ mr: 1 }} onClick={() => setIsSelectionEnabled(!isSelectionEnabled)}>
                {isSelectionEnabled ? <CheckBox /> : <CheckBoxOutlineBlank />}
              </IconButton>

              {isSelectionEnabled && selectedWorkspaceFiles.length > 0 ? (
                <WorkspaceFileMultiSelectActionButton selectedFiles={selectedWorkspaceFiles} workspaceId={workspaceId} />
              ) : (
                <Button
                  component="label"
                  role={undefined}
                  variant="contained"
                  startIcon={<FileUpload />}
                  sx={{ width: "120px", height: "fit-content" }}
                >
                  {t("Components.WorkspaceContentView.Upload")}
                  <VisuallyHiddenInput {...getInputProps()} ref={inputRef} />
                </Button>
              )}
            </>
          )}
        </Box>
      </Box>

      <TabContext value={tabValue}>
        {TABS.map((tab) => {
          return (
            <TabPanel key={tab.value} value={tab.value} sx={{ px: 0 }}>
              {tab.component}
            </TabPanel>
          );
        })}
      </TabContext>

      <NewWorkspaceBoardDialog
        open={newWorkspaceDialogOpen}
        onClose={() => setNewWorkspaceDialogOpen(false)}
        workspaceId={workspaceId}
      />

      {filesToUpload.length > 0 && (
        <Box {...rootProps} ref={ref}>
          <WorkspaceFileUploadDialog
            onClose={_onSelectedFilesDialogClose}
            selectedFiles={filesToUpload}
            workspaceId={workspaceId}
            onRemoveFile={_onRemoveFile}
            sx={{
              backgroundColor: isDroppingFiles ? "#e6efff" : "transparent",
            }}
          />
        </Box>
      )}
    </Box>
  );

  /**
   * Removes the id related file from the selected files list
   *
   * @param id Selected file id to remove from the list
   */
  function _onRemoveFile(id: string) {
    setFilesToUpload((files) => files.filter((file) => file.id !== id));
  }

  /**
   * Clear the selected files when the selected files dialog closes
   */
  function _onSelectedFilesDialogClose() {
    setFilesToUpload([]);
  }
}

/**
 * Styled input file component to hide the default input file button
 */
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
