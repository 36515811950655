import { AnalysisFieldTypeEnum, IAnalysisFieldConfig } from "@bigpi/cookbook";
import { gql } from "@apollo/client";

import { FieldQueryGeneratorBase } from "./FieldQueryGeneratorBase";

/**
 * Topic discussion summary analysis query generator for field group
 */
export class TopicDiscussionSummaryAnalysisFieldGroupsQuery extends FieldQueryGeneratorBase {
  constructor(fields: Array<string>, fieldsConfig: Array<IAnalysisFieldConfig>) {
    super(fields, fieldsConfig);
    this.validFieldTypes = [AnalysisFieldTypeEnum.String, AnalysisFieldTypeEnum.Date];
  }

  protected generateStringQuery(field: string) {
    return `
        ${field}: topicDiscussionSummaryExampleFieldGroup(
          field: "${field}"
          facets: $${field}Facets
        ) {
          ${field}
          count
        }
      `;
  }

  protected generateDateQuery(field: string) {
    return `
        ${field}: topicDiscussionSummaryExampleAggregate(
          field: "${field}"
          facets: $${field}Facets
        ) {
          min
          max
        }
      `;
  }

  protected generateQuery(fieldsQuery: string) {
    return gql`
        query TopicDiscussionSummaryExampleFieldGroups(
          ${this.validFields()
            .map((field) => `$${field}Facets: TopicDiscussionSummaryExampleFacetsInput!`)
            .join(", ")}
        ) {
          ${fieldsQuery}
        }
      `;
  }
}

/**
 * Question analysis query generator for field group
 */
export class QuestionAnalysisFieldGroupsQuery extends FieldQueryGeneratorBase {
  constructor(fields: Array<string>, fieldsConfig: Array<IAnalysisFieldConfig>) {
    super(fields, fieldsConfig);
    this.validFieldTypes = [AnalysisFieldTypeEnum.String, AnalysisFieldTypeEnum.ArrayOfStrings, AnalysisFieldTypeEnum.Date];
  }

  protected generateStringQuery(field: string) {
    return `
        ${field}: analystQuestionFieldGroup(
          field: "${field}"
          facets: $${field}Facets
        ) {
          ${field}
          count
        }
      `;
  }

  protected generateArrayOfStringsQuery(field: string) {
    return `
        ${field}: analystQuestionArrayFieldGroup(
          field: "${field}"
          facets: $${field}Facets
        ) {
          ${field}
          count
        }
      `;
  }

  protected generateDateQuery(field: string) {
    return `
          ${field}: analystQuestionAggregate(
              field: "${field}"
              facets: $${field}Facets
          ) {
              min
              max
          }
        `;
  }

  protected generateQuery(fieldsQuery: string) {
    return gql`
        query AnalystQuestionFieldGroups(
          ${this.validFields()
            .map((field) => `$${field}Facets: AnalystQuestionFacetsInput!`)
            .join(", ")}
        ) {
          ${fieldsQuery}
        }
      `;
  }
}

/**
 * Topic discussion in news article analysis query generator for field group
 */
export class TopicDiscussionInNewsArticleAnalysisFieldGroupsQuery extends FieldQueryGeneratorBase {
  constructor(fields: Array<string>, fieldsConfig: Array<IAnalysisFieldConfig>) {
    super(fields, fieldsConfig);
    this.validFieldTypes = [AnalysisFieldTypeEnum.String, AnalysisFieldTypeEnum.Date];
  }

  protected generateStringQuery(field: string) {
    return `
        ${field}: topicDiscussionInNewsArticleFieldGroup(
          field: "${field}"
          facets: $${field}Facets
        ) {
          ${field}
          count
        }
      `;
  }

  protected generateDateQuery(field: string) {
    return `
        ${field}: topicDiscussionInNewsArticleAggregate(
          field: "${field}"
          facets: $${field}Facets
        ) {
          min
          max
        }
      `;
  }

  protected generateQuery(fieldsQuery: string) {
    return gql`
        query TopicDiscussionInNewsArticleFieldGroups(
          ${this.validFields()
            .map((field) => `$${field}Facets: TopicDiscussionInNewsArticleFacetsInput!`)
            .join(", ")}
        ) {
          ${fieldsQuery}
        }
      `;
  }
}

/**
 * Topic discussion analysis query generator for field group
 */
export class TopicDiscussionAnalysisFieldGroupsQuery extends FieldQueryGeneratorBase {
  constructor(fields: Array<string>, fieldsConfig: Array<IAnalysisFieldConfig>) {
    super(fields, fieldsConfig);
    this.validFieldTypes = [AnalysisFieldTypeEnum.String, AnalysisFieldTypeEnum.Date];
  }

  protected generateStringQuery(field: string) {
    return `
        ${field}: topicDiscussionExampleFieldGroup(
          field: "${field}"
          facets: $${field}Facets
        ) {
          ${field}
          count
        }
      `;
  }

  protected generateDateQuery(field: string) {
    return `
        ${field}: topicDiscussionExampleAggregate(
          field: "${field}"
          facets: $${field}Facets
        ) {
          min
          max
        }
      `;
  }

  protected generateQuery(fieldsQuery: string) {
    return gql`
        query TopicDiscussionExampleFieldGroups(
          ${this.validFields()
            .map((field) => `$${field}Facets: TopicDiscussionExampleFacetsInput!`)
            .join(", ")}
        ) {
          ${fieldsQuery}
        }
      `;
  }
}
