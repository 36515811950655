import { Permissions } from "@bigpi/permission";
import {
  ClipboardMenuGroup,
  DefaultMainMenu,
  MiscMenuGroup,
  SelectAllMenuItem,
  TldrawUiMenuGroup,
  TldrawUiMenuSubmenu,
  UndoRedoGroup,
  ViewSubmenu,
  useEditor,
  useValue,
  ToggleLockMenuItem,
  UnlockAllMenuItem,
  TldrawUiMenuItem,
  useActions,
} from "@tldraw/tldraw";
import { memo } from "react";

import { HasPermission } from "Components/HasPermission/HasPermission";
import { HasWorkspaceRole } from "Components/HasWorkspaceRole/HasWorkspaceRole";
import { ObjectRole } from "GraphQL/Generated/Apollo";

interface IFileSubmenuProps {
  workspaceId: string;
  activateThumbnailFrame: () => void;
  openAclDialog: () => void;
  openRenameDialog: () => void;
  publishToDocuments: () => void;
}

function FileSubmenu(props: IFileSubmenuProps) {
  return (
    <TldrawUiMenuSubmenu id="file" label="menu.file" disabled={false}>
      <TldrawUiMenuItem id="share" label="action.share" readonlyOk={true} onSelect={props.openAclDialog} />

      <HasWorkspaceRole workspaceId={props.workspaceId} roles={[ObjectRole.Manager, ObjectRole.Owner]}>
        <TldrawUiMenuItem id="rename" label="action.rename" readonlyOk={false} onSelect={props.openRenameDialog} />
        <TldrawUiMenuItem
          id="captureThumbnail"
          label="action.captureThumbnail"
          readonlyOk={false}
          onSelect={props.activateThumbnailFrame}
        />
      </HasWorkspaceRole>

      <HasPermission permission={Permissions.PatronDocumentsWrite}>
        <TldrawUiMenuItem
          id="publishToDocuments"
          label="action.publishToDocuments"
          readonlyOk={false}
          onSelect={props.publishToDocuments}
        />
      </HasPermission>
    </TldrawUiMenuSubmenu>
  );
}

/**
 * Cloned from https://github.com/tldraw/tldraw/blob/41b5fffa2ef17ff852c1efc227a5ad5c37dc5c7a/packages/tldraw/src/lib/ui/components/MainMenu/DefaultMainMenuContent.tsx#L109
 * without any modifications.
 */
function LockGroup() {
  return (
    <TldrawUiMenuGroup id="lock">
      <ToggleLockMenuItem />
      <UnlockAllMenuItem />
    </TldrawUiMenuGroup>
  );
}

function FindInBoardMenuItem() {
  const actions = useActions();
  const editor = useEditor();
  const atLeastOneShapeOnPage = useValue("atLeastOneShapeOnPage", () => editor.getCurrentPageShapeIds().size > 0, [editor]);

  return <TldrawUiMenuItem {...actions["find-in-board"]} disabled={!atLeastOneShapeOnPage} />;
}

/**
 * Cloned from https://github.com/tldraw/tldraw/blob/41b5fffa2ef17ff852c1efc227a5ad5c37dc5c7a/packages/tldraw/src/lib/ui/components/MainMenu/DefaultMainMenuContent.tsx#L69
 * and made some modifications. Changes -
 *
 * - Removed `ConversionsMenuGroup` from the main menu.
 */
function EditSubmenu() {
  const editor = useEditor();

  const selectToolActive = useValue("isSelectToolActive", () => editor.getCurrentToolId() === "select", [editor]);

  return (
    <TldrawUiMenuSubmenu id="edit" label="menu.edit" disabled={!selectToolActive}>
      <UndoRedoGroup />
      <ClipboardMenuGroup />

      {/* Modified by BigPi - Removed `ConversionsMenuGroup` from the main menu.
			<ConversionsMenuGroup /> */}

      <MiscMenuGroup />
      <LockGroup />
      <TldrawUiMenuGroup id="select-all">
        <SelectAllMenuItem />
      </TldrawUiMenuGroup>

      {/* Added by BigPi - find in board menu item */}
      <TldrawUiMenuGroup id="find-in-board-group">
        <FindInBoardMenuItem />
      </TldrawUiMenuGroup>
    </TldrawUiMenuSubmenu>
  );
}

interface IMainMenuContentProps {
  workspaceId: string;
  activateThumbnailFrame: () => void;
  openAclDialog: () => void;
  openRenameDialog: () => void;
  publishToDocuments: () => void;
}

/**
 * Cloned from https://github.com/tldraw/tldraw/blob/41b5fffa2ef17ff852c1efc227a5ad5c37dc5c7a/packages/tldraw/src/lib/ui/components/MainMenu/DefaultMainMenuContent.tsx#L38
 * and made some modifications. Changes -
 *
 * - Removed `ExportFileContentSubMenu` from the main menu.
 * - Removed `ExtrasGroup` from the main menu.
 * - Removed `PreferencesGroup` from the main menu.
 */
function MainMenuContent(props: IMainMenuContentProps) {
  return (
    <>
      <FileSubmenu
        workspaceId={props.workspaceId}
        activateThumbnailFrame={props.activateThumbnailFrame}
        openAclDialog={props.openAclDialog}
        openRenameDialog={props.openRenameDialog}
        publishToDocuments={props.publishToDocuments}
      />
      <EditSubmenu />
      <ViewSubmenu />

      {/* Modified by BigPi - Removed `ExportFileContentSubMenu`, `ExtrasGroup` and `PreferencesGroup` from the main menu.
      <ExportFileContentSubMenu />
      <ExtrasGroup />
      <PreferencesGroup /> */}
    </>
  );
}

export interface IMainMenuProps {
  workspaceId: string;
  activateThumbnailFrame: () => void;
  openAclDialog: () => void;
  openRenameDialog: () => void;
  publishToDocuments: () => void;
}

export const MainMenu = memo(function CustomMainMenu(props: IMainMenuProps) {
  return (
    <DefaultMainMenu>
      <MainMenuContent
        workspaceId={props.workspaceId}
        activateThumbnailFrame={props.activateThumbnailFrame}
        openAclDialog={props.openAclDialog}
        openRenameDialog={props.openRenameDialog}
        publishToDocuments={props.publishToDocuments}
      />
    </DefaultMainMenu>
  );
});
