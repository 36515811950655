import { Editor as TipTapEditor } from "@tiptap/core";
import { EditorState } from "@tiptap/pm/state";
import { EditorView } from "@tiptap/pm/view";
import { FloatingMenu as TiptapFloatingToolbar } from "@tiptap/react";
import React, { PropsWithChildren } from "react";
import { roundArrow } from "tippy.js";
import "tippy.js/themes/light.css";
import "tippy.js/dist/border.css";
import "tippy.js/dist/svg-arrow.css";

export interface FloatingToolbarProps extends PropsWithChildren {
  editor: TipTapEditor | null;
}

export const FloatingToolbar = (props: FloatingToolbarProps) => {
  const { editor, children } = props;

  if (!editor) return <></>;

  const shouldShowFloatingToolbar = (shouldShowProps: {
    editor: TipTapEditor;
    view: EditorView;
    state: EditorState;
    oldState?: EditorState;
  }) => {
    const { editor, view, state } = shouldShowProps;

    const { selection } = state;
    const { $anchor, empty: emptySelection } = selection;
    const isRootDepth = $anchor.depth === 1;
    const startsWithSlash =
      $anchor.parent.isTextblock && !$anchor.parent.type.spec.code && /^\/$/.test($anchor.parent.textContent);

    if (!view.hasFocus() || !emptySelection || !isRootDepth || !startsWithSlash || !editor.isEditable) {
      return false;
    }

    return true;
  };

  return (
    editor && (
      <TiptapFloatingToolbar
        editor={editor}
        tippyOptions={{
          duration: 100,
          arrow: roundArrow,
          theme: "light",
        }}
        shouldShow={shouldShowFloatingToolbar}
      >
        {children}
      </TiptapFloatingToolbar>
    )
  );
};

export default FloatingToolbar;
