import { TableViewOutlined } from "@mui/icons-material";
import type { Editor } from "@tiptap/react";
import React from "react";
import { useTranslation } from "react-i18next";

import type { IEditorConfig } from "../../Editor/index.js";
import { getEditorToolbarButtonTooltip } from "../../Utils/ToolbarItemUtils.js";
import { ToolbarItemBase } from "../../Toolbars/ToolbarItemBase.js";
import { ToolbarButton } from "../../Toolbars/ToolbarButton.js";

const ITEM_NAME = "table";

/**
 * A toolbar button that inserts a table.
 */
export class InsertTableButtonItem extends ToolbarItemBase {
  // *********************************************
  // Public properties
  // *********************************************/
  /**
   * @inheritdoc
   */
  readonly name = ITEM_NAME;

  // *********************************************
  // Public methods
  // *********************************************/
  /**
   * @inheritdoc
   */
  create(editor: Editor, config: IEditorConfig) {
    const { toolbarOptions } = config;
    return (
      <InsertTableButton
        key={ITEM_NAME}
        onClick={() => editor.chain().focus().insertTable().run()}
        toolbarOptions={toolbarOptions}
      />
    );
  }
}

/**
 * Props for InsertTableButton.
 */
export interface InsertTableButtonProps {
  onClick: () => void;
  toolbarOptions?: IEditorConfig["toolbarOptions"];
}

/**
 * A toolbar button for the (insert) `table` command.
 */
export const InsertTableButton = (props: InsertTableButtonProps) => {
  const { onClick, toolbarOptions } = props;
  const tooltipPlacement = toolbarOptions?.tooltipPlacement;

  const { t } = useTranslation();

  return (
    <ToolbarButton
      Icon={TableViewOutlined}
      tooltip={getEditorToolbarButtonTooltip(t, ITEM_NAME)}
      onClick={onClick}
      tooltipPlacement={tooltipPlacement}
    />
  );
};
