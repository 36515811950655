import { mergeAttributes } from "@tiptap/core";
import { Heading as TipTapHeading, HeadingOptions as TipTapHeadingOptions, Level } from "@tiptap/extension-heading";

import { getBaseClassWithoutIndent, getBaseClassWithoutTextAlignment } from "../../Utils/CommandUtil.js";
import { setHeading } from "./SetHeadingCommand.js";
import { toggleHeading } from "./ToggleHeadingCommand.js";

export interface HeadingOptions extends TipTapHeadingOptions {
  defaultHeadingLevel: number;
}

export const Heading = TipTapHeading.extend<HeadingOptions>({
  addOptions() {
    return {
      ...this.parent?.(),
      defaultHeadingLevel: 1,
    };
  },

  addAttributes() {
    return {
      level: {
        default: this.options.defaultHeadingLevel,
        parseHTML: (element) => {
          const className = element.hasAttribute("class") ? element.getAttribute("class") : "";
          const headingClass = className?.split(/\s/).find((c) => c.startsWith("heading-"));
          if (!headingClass) {
            return this.options.defaultHeadingLevel;
          }

          return Number(headingClass.split("-")[1]);
        },
        renderHTML: (attributes) => ({}),
      },
      class: {
        default: null,
        renderHTML(attributes) {
          return {
            class: attributes.class,
          };
        },
        parseHTML: (element: HTMLElement) => {
          const classAttr = element.hasAttribute("class") ? element.getAttribute("class") : null;

          // Indent is handled by the IndentExtension
          const baseClassWithoutIndent = getBaseClassWithoutIndent(classAttr);

          // Text alignment is handled by the TextAlignExtension
          const baseClassWithoutTextAlignmentAndIndent = getBaseClassWithoutTextAlignment(baseClassWithoutIndent);

          return baseClassWithoutTextAlignmentAndIndent;
        },
      },
    };
  },

  parseHTML() {
    // Heading node = A paragraph tag with `heading-${LEVEL}` class, for e.g., <p class="heading-1">Heading 1</p>
    return this.options.levels.map((level: Level) => ({
      tag: `p`,
      getAttrs: (node) => (node as HTMLElement).className.includes(`heading-${level}`) && null,
    }));
  },

  renderHTML({ node, HTMLAttributes }) {
    const headingClasses = this.options.levels.map((level) => `heading-${level}`);
    const hasLevel = this.options.levels.includes(node.attrs.level);
    const level = hasLevel ? node.attrs.level : this.options.levels[0];

    if (HTMLAttributes.class && !headingClasses.some((c) => HTMLAttributes.class.includes(c))) {
      HTMLAttributes.class += ` heading-${level}`;
    } else if (!HTMLAttributes.class) {
      HTMLAttributes.class = `heading-${level}`;
    }

    return [`p`, mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },

  addCommands() {
    return {
      ...this.parent?.(),
      setHeading,
      toggleHeading,
    };
  },
});
