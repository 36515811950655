import { ContentTypes } from "@bigpi/cookbook";
import { Box, CardActions, Checkbox } from "@mui/material";
import { Card, CardHeader, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { FileIcon } from "Components/FileIcon/FileIcon";
import { WorkspaceFilesQuery } from "GraphQL/Generated/Apollo";
import { formatWorkspaceUpdatedAt } from "Utils/WorkspaceUtils";
import { WorkspaceContentGridSize } from "./WorkspaceContentView";
import { WorkspaceFileListMenu } from "./WorkspaceFileListMenu";

export interface IWorkspaceFileGridItemProps {
  checked: boolean;
  isSelectionEnabled: boolean;
  onClick: (fileId: string) => void;
  onSelectionChange: (id: string) => void;
  size: WorkspaceContentGridSize;
  workspaceFileDetails: WorkspaceFilesQuery["workspaceFiles"][number];
}

export function WorkspaceFileGridItem(props: IWorkspaceFileGridItemProps) {
  const { checked, isSelectionEnabled, onClick, onSelectionChange, size, workspaceFileDetails } = props;

  const { t } = useTranslation();

  let actionComponent: JSX.Element | null = null;
  if (isSelectionEnabled) {
    actionComponent = <Checkbox checked={checked} onChange={() => onSelectionChange(workspaceFileDetails.id)} />;
  } else {
    actionComponent = (
      <WorkspaceFileListMenu
        workspaceFileFilename={workspaceFileDetails.filename}
        workspaceFileId={workspaceFileDetails.id}
        workspaceId={workspaceFileDetails.workspaceId}
        workspaceFileMimeType={workspaceFileDetails.mimeType as ContentTypes}
      />
    );
  }

  return (
    <Grid
      item
      xl={size === "small" ? 3 : size === "medium" ? 4 : 6}
      lg={size === "small" ? 2 : size === "medium" ? 3 : 4}
      md={size === "small" ? 3 : size === "medium" ? 4 : 6}
      xs={size === "small" ? 6 : size === "medium" ? 6 : 12}
      sx={{ display: "grid" }}
    >
      <Card
        variant="outlined"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          borderWidth: "2px",
          backgroundColor: "#e8eaeb",
          ":hover": {
            backgroundColor: "#d8dadb",
          },
        }}
      >
        <CardHeader
          avatar={<FileIcon mimetype={workspaceFileDetails.mimeType} />}
          title={
            <Typography
              sx={{
                cursor: "pointer",
                textAlign: "start",
                fontSize: size === "small" ? "1rem" : size === "medium" ? "1.1rem" : "1.2rem",
                fontWeight: "500",
                wordBreak: "break-word",
              }}
              onClick={() => onClick(workspaceFileDetails.id)}
            >
              {workspaceFileDetails.name}
            </Typography>
          }
          action={actionComponent}
        />

        <CardActions sx={{ display: "block", bottom: 0 }}>
          <Box sx={{ mt: 1 }}>
            <Typography variant="caption" color="text.secondary">
              {formatWorkspaceUpdatedAt(workspaceFileDetails.updatedAt, t)}
            </Typography>
          </Box>
        </CardActions>
      </Card>
    </Grid>
  );
}
