import * as Plot from "@observablehq/plot";
import * as d3 from "d3";
import React from "react";

class Tooltip extends Plot.Mark {
  r: any;
  fill: any;
  onclick: any;

  constructor(data: any, { x, y, z, fill = "none", r = 4, onclick, ...options }: any = {}) {
    super(
      // @ts-expect-error TS2554: Expected 0 arguments, but got 3. constructor types are missing from Mark class
      data,
      {
        x: { name: "x", value: x, scale: "x", optional: true },
        y: { name: "y", value: y, scale: "y", optional: true },
        z: { name: "z", value: z, optional: true },
      },
      options,
    );
    this.r = r;
    this.fill = fill;
    this.onclick = onclick;
  }

  render(
    index: any,
    scales: any,
    { x: X, y: Y, z: Z }: any,
    { width, height, marginTop, marginRight, marginBottom, marginLeft }: any,
  ) {
    width = width - marginLeft - marginRight;
    const { onclick } = this;
    const x = X ? (i: any) => X[i] : constant((marginLeft + width - marginRight) / 2);
    const y = Y ? (i: any) => Y[i] : constant((marginTop + height - marginBottom) / 2);

    const quadtree = d3
      .quadtree()
      .x(x)
      .y(y)
      .addAll(index.filter((i: any) => x(i) !== undefined && y(i) !== undefined));

    const g = d3.create("svg:g");

    const catcher = g
      .append("rect")
      .attr("height", height)
      .attr("width", width)
      .style("fill", "none")
      .attr("pointer-events", "all")
      .attr("x", marginLeft)
      .attr("y", 0)
      .attr("class", "plot-tooltip-mark")
      .on("pointerenter", () => {});

    catcher.on("pointerup", (event: PointerEvent) => {
      const i = find(event);
      if (i && typeof onclick === "function" && Number(i) >= 0) {
        onclick(event, i, g.node());
      }
    });

    function find(event: any) {
      const p = d3.pointers(event)[0],
        i = quadtree.find(...p);
      if (Math.hypot(p[0] - x(i), p[1] - y(i)) < 30) return i;
      return -1;
    }

    return g.node();
  }
}

export const tooltip = () => {
  return (data: any, options: any) => new Tooltip(data, options);
};

function constant(x: any) {
  return () => x;
}

export const addTooltip = (Plot: any) => ((Plot.tooltip = tooltip()), Plot);
