import { gql } from "@apollo/client";

export const UPDATE_TOPIC_DISCUSSION_SUMMARY_EXAMPLES = gql`
  mutation UpdateTopicDiscussionSummaryExample($input: UpdateTopicDiscussionSummaryExampleInput!) {
    updateTopicDiscussionSummaryExample(input: $input) {
      id
      topic
      questions
      questionMentionCounts
      detail
      topicMentionCount
      event
      eventType
      ticker
      category
      eventDate
      speakers {
        fullName
        role
      }
      section
      rating
      score
      summary
      analysisName
    }
  }
`;
