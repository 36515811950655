import { T } from "@tldraw/validate";
import { ShapeProps } from "@tldraw/tlschema";

import { IFeedShape } from "./IFeedShape.js";

export const feedShapeProps: ShapeProps<IFeedShape> = {
  h: T.nonZeroNumber,
  w: T.nonZeroNumber,
  startDate: T.string,
  endDate: T.string,
  feedItems: T.arrayOf(
    T.object({
      id: T.string,
      title: T.string,
      date: T.string,
      ticker: T.string,
    }),
  ),

  canScroll: T.boolean,
};
