import { atom, TLShapeId } from "@tldraw/tldraw";
import EventEmitter from "emittery";
import { useMemo } from "react";

import { ICompanion } from "./ICompanion";
import { ICompanionOptions } from "./ICompanionOptions";

// *********************************************
// Event types
// *********************************************/
export type CompanionManagerEvents = {
  companionClosed: undefined;
  companionOpened: CompanionOpenedEventArgs;
};

export type CompanionOpenedEventArgs = {
  companion: ICompanion;
  shapeId: TLShapeId;
  options?: ICompanionOptions;
};

export type OnCompanionOpenedHandler = (event: CompanionOpenedEventArgs) => void;

/**
 * Manages companion execution and response lifecycle events between components.
 */
export class CompanionManager extends EventEmitter<CompanionManagerEvents> {
  private _isOpen = atom("Companion.isOpen", false);

  public get isOpen() {
    return this._isOpen.get();
  }

  // *********************************************
  // Public static methods
  // *********************************************/
  public static useNewCompanionManager = () => {
    const result = useMemo(() => new CompanionManager(), []);

    return result;
  };

  // *********************************************
  // Public methods
  // *********************************************/
  public showCompanion(shapeId: TLShapeId, companion: ICompanion, options?: ICompanionOptions): void {
    this.raiseCompanionOpened({ shapeId, companion, options });
    this._isOpen.set(true);
  }

  public async raiseCompanionOpened(event: CompanionOpenedEventArgs) {
    await this.emitSerial("companionOpened", event);
  }

  public async raiseCompanionClosed() {
    this._isOpen.set(false);
    await this.emitSerial("companionClosed");
  }
}
