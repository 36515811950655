export const getExportHtmlToWordFn = (documentName: string, apiGwUrl: string, getAccessToken: () => string) => {
  return async (htmlContent: string) =>
    fetch(`${apiGwUrl}/export-to-word`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/docx",
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify({ documentName, htmlContent }),
    }).then(async (res) => {
      if (res.status !== 200) {
        throw res;
      }
      return { documentName, blob: await res.blob() };
    });
};
