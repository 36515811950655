import React from "react";

import { Editor, EditorContent } from "@tiptap/react";

import { PageMargin, PageMarginToClassName } from "./PageMargin.js";
import { PageOrientation, PageOrientationToClassName } from "./PageOrientation.js";
import { PageSize, PageSizeToClassName } from "./PageSize.js";

export const DEFAULT_DOCUMENT_CLASS_NAME = "astra document";

export function getPageClassNames(
  pageSize: PageSize,
  pageOrientation: PageOrientation,
  pageMargin: PageMargin,
  documentClassName: string | undefined,
) {
  const pageMarginClassName = PageMarginToClassName(pageMargin);
  const pageOrientationClassName = PageOrientationToClassName(pageOrientation);
  const pageSizeClassName = PageSizeToClassName(pageSize);

  return `${
    documentClassName || DEFAULT_DOCUMENT_CLASS_NAME
  } ${pageSizeClassName} ${pageOrientationClassName} ${pageMarginClassName}`;
}

export interface EditorBaseProps {
  documentClassName?: string;
  editor: Editor | null;
  pageMargin: PageMargin;
  pageOrientation: PageOrientation;
  pageSize: PageSize;
}

export const EditorBase = (props: EditorBaseProps) => {
  const { editor, documentClassName, pageMargin, pageOrientation, pageSize } = props;
  const pageMarginRef = React.useRef<PageMargin | null>(null);

  React.useEffect(() => {
    if (editor && !editor.isDestroyed) {
      // Animate margin changes after the first/initial margin set
      let animateClass = "";
      if (pageMarginRef.current !== null && pageMarginRef.current !== pageMargin) {
        animateClass = "animate-margin";
      }
      editor.setOptions({
        editorProps: {
          attributes: {
            class: getPageClassNames(pageSize, pageOrientation, pageMargin, documentClassName) + ` ${animateClass}`,
          },
        },
      });
    }

    pageMarginRef.current = pageMargin;
  }, [editor, pageSize, pageOrientation, pageMargin, documentClassName]);

  if (!editor || editor.isDestroyed) {
    return <></>;
  }

  return <EditorContent editor={editor} style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center" }} />;
};

export default EditorBase;
