import { TLShapeId, useValue, useEditor } from "@tldraw/tldraw";

export const useIsChildSelected = (shapeId: TLShapeId) => {
  const editor = useEditor();

  return useValue(
    "isChildSelected",
    () => {
      const childShapeIds = editor.getSortedChildIdsForParent(shapeId);
      return childShapeIds.some((childShapeId) => editor.getCurrentPageState().selectedShapeIds.includes(childShapeId));
    },
    [editor, shapeId],
  );
};
