import { TLShape, react } from "@tldraw/tldraw";
import { useCallback, useEffect, useRef } from "react";

import { useCollaborativeBoardEditor } from "TldrawBoard/useCollaborativeBoardEditor";

/**
 * Keeps track of the changes in the rendering shapes and invokes the callback with the culled and restored shapes
 * when there is a change in the rendering shapes.
 * https://discord.com/channels/859816885297741824/859816885801713728/1218230942607478835
 */
export function useRenderingShapesChange(cb: (info: { culled: Array<TLShape>; restored: Array<TLShape> }) => void) {
  const editor = useCollaborativeBoardEditor();
  const rPrevShapes = useRef(editor?.getRenderingShapes() || []);

  const getRenderingShapes = useCallback(() => editor?.getRenderingShapes() || [], [editor]);

  useEffect(() => {
    return react("when rendering shapes change", () => {
      const after = getRenderingShapes();
      const before = rPrevShapes.current;

      const culled: Array<TLShape> = [];
      const restored: Array<TLShape> = [];

      const beforeToVisit = new Set(before);

      for (const afterInfo of after) {
        const beforeInfo = before.find((s) => s.id === afterInfo.id);
        if (!beforeInfo) {
          continue;
        } else {
          if (afterInfo.isCulled && !beforeInfo.isCulled) {
            culled.push(afterInfo.shape);
          } else if (!afterInfo.isCulled && beforeInfo.isCulled) {
            restored.push(afterInfo.shape);
          }

          beforeToVisit.delete(beforeInfo);
        }
      }

      rPrevShapes.current = after;

      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          cb({
            culled,
            restored,
          });
        });
      });
    });
  }, [cb, getRenderingShapes]);
}
