// Generated by ts-to-zod
import { z } from "zod";

export const analystQuestionAnalysisToolbarZodSchema = z.object({
  availableFields: z.array(z.any()).optional().describe("The fields that are available for the user to apply facets."),
});

export const analystQuestionAnalysisSelectionZodSchema = z.object({
  selectedTimeScale: z
    .string()
    .optional()
    .describe('The selected time scale for the analysis, such as "utcDay", "utcWeek", "utcMonth", or "utcYear".'),
});

const dataGridPreferencesZodSchema = z.any();

const analysisPreferencesZodSchema = z.any();

const analystQuestionAnalysisFacetsZodSchema = z.any();

export const analystQuestionAnalysisPreferencesZodSchema = z.object({
  dataGrid: dataGridPreferencesZodSchema.optional(),
  analysis: analysisPreferencesZodSchema
    .and(
      z.object({
        showDataToDisplayInToolbar: z.boolean().optional(),
      }),
    )
    .optional(),
});

export const analystQuestionAnalysisShapePropsZodSchema = z.object({
  h: z.number().optional().describe("The width of the HTML document."),
  w: z.number().optional().describe("The height of the HTML document."),
  preferences: analystQuestionAnalysisPreferencesZodSchema.describe("The preferences for the analysis."),
  enableBackground: z.boolean().optional().describe("A boolean value indicating whether the background should be enabled."),
  selectedFacetValues: analystQuestionAnalysisFacetsZodSchema.describe("The currently selected facet values for the analysis."),
  toolbar: analystQuestionAnalysisToolbarZodSchema.optional().describe("An optional toolbar configuration for the analysis."),
  selection: analystQuestionAnalysisSelectionZodSchema.optional().describe("The selection for the analysis."),
});
