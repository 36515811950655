import { IPlugIn } from "@bigpi/cookbook";

import { IWorkspaceBoardChatActionPlugInInputs } from "./IWorkspaceBoardChatActionPlugInInputs";
import { SetChatMessagePlugIn } from "Chat/ChatActionManagers/Common/PlugIns/SetChatMessagePlugIn";
import { AddFilePreviewToBoardPlugIn } from "./AddFilePreviewToBoardPlugIn";
import { AddToBoardPlugIn } from "./AddToBoardPlugIn";
import { CancelCommandPlugIn } from "./CancelCommandPlugIn";

/**
 * Creates the plug-ins.
 *
 * @param options Options for the plug-ins.
 *
 * @returns An array of plug-ins to be registered.
 */
export function createChatActionPlugIns(): IPlugIn<IWorkspaceBoardChatActionPlugInInputs, undefined | JSX.Element>[] {
  return [new AddFilePreviewToBoardPlugIn(), new AddToBoardPlugIn(), new CancelCommandPlugIn(), new SetChatMessagePlugIn()];
}
