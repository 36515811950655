import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Box, IconButton } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

interface IContentActionBarProps {
  children: React.ReactNode;
  count: number;
  onClose: () => void;
}

export function ContentActionBar(props: IContentActionBarProps) {
  const { children, count, onClose } = props;
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        alignItems: "center",
        backgroundColor: "var(--astra-color-gray20)",
        borderRadius: 2,
        display: "flex",
        height: "34px",
      }}
    >
      <IconButton onClick={onClose} size="small">
        <CloseOutlinedIcon fontSize="small" />
      </IconButton>
      {t("Components.ContentActionBar.Selected", { count })}
      {children}
    </Box>
  );
}
