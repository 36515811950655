import * as React from "react";

import { ApolloClient, ApolloProvider, createHttpLink, from, makeVar, split } from "@apollo/client";
import { RetryLink } from "@apollo/client/link/retry";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { setContext } from "@apollo/client/link/context";
import { getMainDefinition } from "@apollo/client/utilities";
import { createClient } from "graphql-ws";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import { useAuthUser } from "@frontegg/react";

import { Config } from "Config";
import { cache } from "GraphQL/ApolloCache";

export const CommandConnectionActiveVar = makeVar<boolean>(false);

/**
 * AuthenticatedApolloProvider props.
 */
type AuthenticatedApolloProviderProps = {
  children?: React.ReactNode;
};

/**
 * Provides an authenticated Apollo client to the application.
 * @param props The component props.
 *
 * @returns The ApolloProvider component.
 */
export function AuthenticatedApolloProvider(props: AuthenticatedApolloProviderProps) {
  const { children } = props;
  const user = useAuthUser();
  const token = user.accessToken;
  const commandOperations = ["CancelCommand", "CommandStatus", "ExecuteCommand"];
  const uploadOperations = ["UploadDocument", "UploadFile", "UploadWorkspaceFiles", "UploadFiles"];
  const pantryUploadWSOperations = ["UploadWorkspaceFiles", "OnWorkspaceFileAdded", "UploadFiles", "OnFileAdded"];
  const pantryWSOperations = ["OnChatMessagesAdded", "OnChatMessagesDeleted", "OnChatMessagesUpdated"];
  const waiterOperations = ["CommandPing", "OnCommandResponse", "OnCommandStatusChanged"];
  const webSocketOperations = [...waiterOperations];

  const httpLink = createHttpLink({
    uri: `${Config.apiGatewayHttpUrl}/graphql`,
  });

  const pantryWSLink = new GraphQLWsLink(
    createClient({
      connectionParams: {
        authToken: token,
      },
      lazy: false,
      url: `${Config.pantryPublicWsUrl}/graphql-ws`,
    }),
  );

  const pantryUploadWSLink = new GraphQLWsLink(
    createClient({
      connectionParams: {
        authToken: token,
      },
      lazy: true,
      url: `${Config.pantryPublicWsUrl}/pantry-upload-ws`,
    }),
  );

  const uploadLink = createUploadLink({
    uri: `${Config.apiGatewayHttpUrl}/pantry-upload`,
  });

  const waiterHttpLink = createHttpLink({
    uri: `${Config.waiterHttpUrl}/graphql`,
  });

  const waiterWSLink = new GraphQLWsLink(
    createClient({
      connectionParams: {
        authToken: token,
      },
      lazy: false,
      url: `${Config.waiterWsUrl}/graphql`,
      on: {
        connected: () => {
          CommandConnectionActiveVar(true);
        },
        closed: () => {
          CommandConnectionActiveVar(false);
        },
        // error: (error) => {
        //   console.error("waiterWSLink, error", error);
        // },
      },
      // onNonLazyError: (error) => {
      //   console.error("waiterWSLink, onNonLazyError", error);
      // }
    }),
  );

  // Send upload request to "uploadLink", all other requests will got to the "httpLink"
  // Here "operationName" comes from "./Upload/Mutation.ts"
  const directionalLink = split(
    (operation) => {
      // Check for upload operations
      return uploadOperations.includes(operation.operationName);
    },
    uploadLink,
    // Check for command operations vs regular api-gw proxied operations
    split(
      (operation) => {
        return commandOperations.includes(operation.operationName);
      },
      waiterHttpLink,
      httpLink,
    ),
  );

  // Split subscriptions between waiter, pantry and pantry (upload)
  const wsLink = split(
    (operation) => {
      // Check for waiter operations
      return waiterOperations.includes(operation.operationName);
    },
    waiterWSLink,
    split(
      (operation) => {
        return pantryWSOperations.includes(operation.operationName);
      },
      pantryWSLink,
      split((operation) => {
        return pantryUploadWSOperations.includes(operation.operationName);
      }, pantryUploadWSLink),
    ),
  );

  // Split by destination protocol to determine which link to use:
  // * Subscriptions requests and ping commands will go to "waiterWSLink"
  // * All other requests will go to the "httpLink" or "uploadLink" based on the "directionalLink"
  const protocolLink = split(
    (operation) => {
      const { query } = operation;
      // Check for websocket operations
      if (webSocketOperations.includes(operation.operationName)) {
        return true;
      }
      const definition = getMainDefinition(query);
      return definition.kind === "OperationDefinition" && definition.operation === "subscription";
    },
    wsLink,
    directionalLink,
  );

  const authLink = setContext((_, { headers }) => {
    // Return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });

  const client = new ApolloClient({
    link: from([new RetryLink(), authLink, protocolLink]),
    cache,
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
