import type { Editor } from "@tiptap/react";

import type { IEditorConfig } from "../Editor/index.js";
import type { ActionMenuItem } from "./ActionMenuItem.js";

export abstract class ActionMenuItemBase implements ActionMenuItem {
  // *********************************************
  // Public properties
  // *********************************************/
  /**
   * @inheritdoc
   */
  isExternallyTranslated: boolean = false;

  /**
   * @inheritdoc
   */
  name = "NOT_IMPLEMENTED";

  // *********************************************
  // Public methods
  // *********************************************/
  /**
   * @inheritdoc
   */
  create(editor: Editor, config: IEditorConfig): React.ReactElement {
    throw new Error("Not implemented");
  }
}
