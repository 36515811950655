import type { Editor } from "@tiptap/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { FormatListBulletedOutlined } from "@mui/icons-material";

import type { IEditorConfig } from "../../Editor/index.js";
import { getEditorToolbarButtonTooltip } from "../../Utils/ToolbarItemUtils.js";
import { ToolbarItemBase } from "../../Toolbars/ToolbarItemBase.js";
import { ToolbarButton } from "../../Toolbars/ToolbarButton.js";

const ITEM_NAME = "bulletList";

/**
 * A toolbar button that displays and toggles a bullet list.
 */
export class BulletListButtonItem extends ToolbarItemBase {
  // *********************************************
  // Public properties
  // *********************************************/
  /**
   * @inheritdoc
   */
  readonly name = ITEM_NAME;

  // *********************************************
  // Public methods
  // *********************************************/
  /**
   * @inheritdoc
   */
  create(editor: Editor, config: IEditorConfig) {
    const { toolbarOptions } = config;
    return (
      <BulletListButton
        key={ITEM_NAME}
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        isActive={() => editor.isActive(ITEM_NAME)}
        toolbarOptions={toolbarOptions}
      />
    );
  }
}

/**
 * Props for BulletListButton.
 */
export interface BulletListButtonProps {
  isActive: (command: typeof ITEM_NAME) => boolean;
  onClick: () => void;
  toolbarOptions?: IEditorConfig["toolbarOptions"];
}

/**
 * A toolbar button for the `bulletList` command.
 */
export const BulletListButton = (props: BulletListButtonProps) => {
  const { onClick: toggle, isActive, toolbarOptions } = props;
  const tooltipPlacement = toolbarOptions?.tooltipPlacement;

  const { t } = useTranslation();

  return (
    <ToolbarButton
      Icon={FormatListBulletedOutlined}
      tooltip={getEditorToolbarButtonTooltip(t, ITEM_NAME)}
      onClick={toggle}
      isActive={isActive(ITEM_NAME)}
      tooltipPlacement={tooltipPlacement}
    />
  );
};
