import { Link } from "@mui/material";
import { textContent } from "domutils";
import { HTMLReactParserOptions, Element, domToReact, DOMNode, default as parse } from "html-react-parser";
import { Parser } from "htmlparser2";

import { ChatActionExecutor } from "Chat/ChatActionManagers/ChatActionExecutor";
import { ChatMessageAction } from "Chat/ChatActionManagers/ChatMessageActions";
import { ChatMessageChannelType } from "GraphQL/Generated/Apollo";
import { ShowCard1 } from "./ShowCard1";

export function parseChatMessageAction(
  chatActionExecutor: ChatActionExecutor,
  message: string,
  channelType: ChatMessageChannelType,
  channelId: string,
) {
  // Get the associated JSON data from the message
  const messageData = parseMessageData(message);

  // Create the HTML parser
  const options: HTMLReactParserOptions = {
    replace(domNode) {
      // Currently we just use the client action as a trigger. We may have custom tags in the future.
      if (domNode instanceof Element && domNode.attribs && domNode.attribs["data-client-action"]) {
        const action = domNode.attribs["data-client-action"];
        const dataReferenceId = domNode.attribs["data-ref-id"];
        let nodeData: Record<string, any> | undefined = undefined;
        if (dataReferenceId) {
          nodeData = dataReferenceId in messageData ? messageData[dataReferenceId] : undefined;
        }

        switch (action) {
          case ChatMessageAction.AddToBoard:
            // For now, we only support A tags, but could expand to check if we should create something else, like a span
            return (
              <Link
                component="button"
                sx={{ textAlign: "left" }}
                disabled={!nodeData}
                onClick={(e) => {
                  if (nodeData) {
                    chatActionExecutor.raiseChatAction({ action, data: nodeData });
                  }
                  e.preventDefault();
                }}
              >
                {domToReact(domNode.children as Array<DOMNode>, options)}
              </Link>
            );

          case ChatMessageAction.CancelCommand:
            const objectUrn = domNode.attribs["data-object-urn"];
            if (objectUrn) {
              return (
                <Link
                  component="button"
                  sx={{ textAlign: "left" }}
                  onClick={(e) => {
                    chatActionExecutor.raiseChatAction({ action, data: objectUrn });
                    e.preventDefault();
                  }}
                >
                  {domToReact(domNode.children as Array<DOMNode>, options)}
                </Link>
              );
            }
            break;

          case ChatMessageAction.SetChatMessage:
            const childrenAsText = textContent(domNode).trim();
            // For now, we only support A tags, but could expand to check if we should create something else, like a span
            return (
              <Link
                component="button"
                sx={{ textAlign: "left" }}
                disabled={!childrenAsText}
                onClick={(e) => {
                  chatActionExecutor.raiseChatAction({ action, data: childrenAsText });
                  e.preventDefault();
                }}
              >
                {domToReact(domNode.children as Array<DOMNode>, options)}
              </Link>
            );

          case ChatMessageAction.ShowCard1:
            return (
              <ShowCard1
                dataReferenceId={dataReferenceId}
                domNode={domNode}
                nodeData={nodeData}
                options={options}
                channelType={channelType}
                channelId={channelId}
                chatActionExecutor={chatActionExecutor}
              />
            );
        }
      }
    },
  };

  // Check if we have a hanging opening tag and remove it temporarily
  if (message.endsWith("<")) {
    message = message.slice(0, -1);
  }

  return parse(message, options);
}

/**
 * Attempts to extract JSON data from the message HTML comments.
 *
 * @param message The received HTML format chat message.
 */
export function parseMessageData(message: string): Record<string, any> {
  let result: Record<string, any> = {};

  let currentComment = "";
  let comments: Array<string> = [];
  const parser = new Parser({
    oncomment(data) {
      currentComment += data;
    },
    oncommentend() {
      comments.push(currentComment);
      currentComment = "";
    },
  });

  parser.write(message);
  parser.end();

  // Loop through each comment we found and merge with result data
  comments.forEach((comment) => {
    if (comment && comment.length > 0) {
      try {
        const commentData = JSON.parse(comment);
        // Merge the JSON data into the result
        result = {
          ...result,
          ...commentData,
        };
      } catch (e) {
        // console.warn("Failed to parse JSON data from message.", e);
        // Ignore
      }
    }
  });

  return result;
}
