import React, { useEffect, useState } from "react";

// *********************************************
// Types/Interfaces
// *********************************************/
export interface AppRightSidebarProviderProps {
  children: React.ReactNode;
}

export interface IAppRightSidebarComponents {
  rightSidebarSx: {
    backgroundColor: string;
  } | null;
  RightSidebarTitleComponent: React.ReactNode;
  setRightSidebarSx: (sx: IAppRightSidebarComponents["rightSidebarSx"]) => void;
  setRightSidebarTitleComponent: (component: React.ReactNode) => void;
}

export interface RightSidebarTitleComponentProps {
  children: React.ReactNode;
  sx?: IAppRightSidebarComponents["rightSidebarSx"];
}

// *********************************************
// Context
// *********************************************/
const AppRightSidebarComponents = React.createContext<IAppRightSidebarComponents>({
  rightSidebarSx: null,
  RightSidebarTitleComponent: null,
  setRightSidebarSx: () => null,
  setRightSidebarTitleComponent: () => null,
});

export function AppRightSidebarProvider(props: AppRightSidebarProviderProps) {
  const { children } = props;
  const [RightSidebarTitleComponent, setRightSidebarTitleComponent] = useState<React.ReactNode>();
  const [rightSidebarSx, setRightSidebarSx] = useState<IAppRightSidebarComponents["rightSidebarSx"]>(null);

  return (
    <AppRightSidebarComponents.Provider
      value={{ RightSidebarTitleComponent, setRightSidebarTitleComponent, rightSidebarSx, setRightSidebarSx }}
    >
      {children}
    </AppRightSidebarComponents.Provider>
  );
}

export function useAppRightSidebarComponents() {
  return React.useContext(AppRightSidebarComponents);
}

// *********************************************
// Components
// *********************************************/
/************ Sidebar Component ***********/
export function RightSidebarTitle(props: RightSidebarTitleComponentProps) {
  const { children, sx } = props;
  const { setRightSidebarTitleComponent, setRightSidebarSx } = useAppRightSidebarComponents();

  useEffect(() => {
    setRightSidebarTitleComponent(children);
  }, [children, setRightSidebarTitleComponent]);

  useEffect(() => {
    setRightSidebarSx(sx || null);
  }, [sx, setRightSidebarSx]);

  return null;
}

export function RightSidebarTitleComponent() {
  const { RightSidebarTitleComponent } = useAppRightSidebarComponents();
  return RightSidebarTitleComponent ? RightSidebarTitleComponent : null;
}

export function useRightSidebarSx() {
  const { rightSidebarSx } = useAppRightSidebarComponents();
  return rightSidebarSx;
}
