import { IPlugIn } from "./IPlugIn.js";

export class PlugInManager<TInput, TOutput, TPlugIn extends IPlugIn<TInput, TOutput>> {
  // *********************************************
  // Public properties
  // *********************************************/
  /**
   * Gets the plug-ins registered with the plug-in manager.
   */
  public get plugIns(): Record<string, TPlugIn> {
    return { ...this._plugIns };
  }

  // *********************************************
  // Private properties
  // *********************************************/
  private _plugIns: Record<string, TPlugIn> = {};

  // *********************************************
  // Public methods
  // *********************************************/
  /**
   * Adds an array of plug-ins to the plug-in manager.
   *
   * @param plugIns The plug-ins to register.
   */
  public registerPlugIns(plugIns: Array<TPlugIn>): void {
    plugIns.forEach((plugIn) => this.registerPlugIn(plugIn));
  }

  /**
   * Adds a single plug-in to the plug-in manager.
   *
   * @param plugIn The plug-in to register.
   */
  public registerPlugIn(plugIn: TPlugIn): void {
    // Sanity check on input and current state
    if (!plugIn) {
      throw new Error("plugIn is required");
    }

    if (this._plugIns[plugIn.name.toLowerCase()] !== undefined) {
      throw new Error(`plugIn "${plugIn.name}" as "${plugIn.name.toLowerCase()}" already registered`);
    }

    // Register the plug-in
    this._plugIns[plugIn.name.toLowerCase()] = plugIn;
  }

  /**
   * Executes the plug-in logic with the given name and with the given input.
   *
   * @param plugInName The plug-in name that should execute.
   * @param input The input for the plug-in.
   */
  public async execute(plugInName: string, input: TInput): Promise<TOutput> {
    if (this._plugIns[plugInName.toLowerCase()] === undefined) {
      throw new Error(`plugIn "${plugInName}" as "${plugInName.toLowerCase()}" not registered`);
    }

    return await this._plugIns[plugInName.toLowerCase()].execute(input);
  }

  /**
   * Executes the plug-in logic with the given name and with the given input if the plug-in is registered.
   *
   * @param plugInName The plug-in name that should execute.
   * @param input The input for the plug-in.
   */
  public async executeIfRegistered(plugInName: string, input: TInput): Promise<TOutput | undefined> {
    if (this._plugIns[plugInName.toLowerCase()] === undefined) {
      return undefined;
    }

    return await this._plugIns[plugInName.toLowerCase()].execute(input);
  }

  /**
   * Determines if the plug-in manager has a plug-in with the given name.
   *
   * @param plugInName The plug-in name to check.
   *
   * @returns `true` if the plug-in is registered; otherwise, `false`.
   */
  public hasPlugIn(plugInName: string): boolean {
    return this._plugIns[plugInName.toLowerCase()] !== undefined;
  }
}
