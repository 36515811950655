import { StyledComponent } from "@emotion/styled";
import { Tabs, TabsProps } from "@mui/material";
import { styled } from "@mui/material/styles";

export const CompanionTabs: StyledComponent<TabsProps> = styled((props: TabsProps) => <Tabs {...props} TabIndicatorProps={{}} />)(
  {
    "&.MuiTabs-root": {
      borderBottom: "1px solid #e9e9e9",
      height: "48px",
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
  },
);
