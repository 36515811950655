import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import { Box, ButtonBase, Card, CardContent, CardMedia, Typography, alpha } from "@mui/material";
import { Grid } from "@mui/material";

import { Theme } from "Theme";

export interface IWorkspaceBlankGridItemProps {
  selectedTemplateId: string | null;
  onSelectTemplate: (templateId: string | null) => void;
}

export function WorkspaceBlankGridItem(props: IWorkspaceBlankGridItemProps) {
  const { selectedTemplateId, onSelectTemplate } = props;

  const { t } = useTranslation();

  return (
    <Grid item xs={1} sm={2} md={3} key="blank-template">
      <ButtonBase
        sx={{ height: "100%", width: "100%" }}
        onClick={() => {
          onSelectTemplate(null);
        }}
        disableRipple
      >
        <Card
          variant="outlined"
          sx={{
            height: "100%",
            width: "100%",
            borderColor: selectedTemplateId === null ? "primary.main" : "default.main",
            borderWidth: "2px",
            backgroundColor: selectedTemplateId === null ? alpha(Theme.palette.primary.main, 0.1) : "#fff",
          }}
        >
          <CardMedia sx={{ width: "100%", minHeight: "100px", display: "flex", flexWrap: "wrap", alignContent: "center" }}>
            <Box sx={{ width: "100%" }}>
              <WorkspacesIcon sx={{ color: "rgba(0, 0, 0, 0.54)" }} />
            </Box>
          </CardMedia>
          <CardContent sx={{ display: "flex", flexDirection: "column" }}>
            <Typography gutterBottom variant="body1" component="span">
              {t("Components.WorkspaceBlankGridItem.Title")}
            </Typography>
            <span>
              <AddIcon />
            </span>
          </CardContent>
        </Card>
      </ButtonBase>
    </Grid>
  );
}
