import * as changeCase from "change-case";
import { search } from "fast-fuzzy";

import { OccupationTitle } from "../Schema/OccupationTitle.js";

export const COMMON_OCCUPATION_TITLE_MAPPINGS: Record<string, OccupationTitle> = {
  CAO: OccupationTitle.ChiefAdministrativeOfficer,
  CDO: OccupationTitle.ChiefDiversityOfficer,
  CEO: OccupationTitle.ChiefExecutiveOfficer,
  CFO: OccupationTitle.ChiefFinancialOfficer,
  CIO: OccupationTitle.ChiefInformationOfficer,
  CISO: OccupationTitle.ChiefInformationSecurityOfficer,
  CLO: OccupationTitle.ChiefLegalOfficer,
  CMO: OccupationTitle.ChiefMarketingOfficer,
  CNO: OccupationTitle.ChiefNursingOfficer,
  COO: OccupationTitle.ChiefOperatingOfficer,
  CPO: OccupationTitle.ChiefProductOfficer,
  CRO: OccupationTitle.ChiefRiskOfficer,
  CSO: OccupationTitle.ChiefSecurityOfficer,
  CTO: OccupationTitle.ChiefTechnologyOfficer,
  GC: OccupationTitle.GeneralCounsel,

  SVP: OccupationTitle.SeniorVicePresident,

  EVP: OccupationTitle.ExecutiveVicePresident,
};

export const SORTED_OCCUPATION_TITLES: Array<OccupationTitle> = [
  OccupationTitle.ChiefAdministrativeOfficer,
  OccupationTitle.ChiefDiversityOfficer,
  OccupationTitle.ChiefExecutiveOfficer,
  OccupationTitle.ChiefFinancialOfficer,
  OccupationTitle.ChiefInformationOfficer,
  OccupationTitle.ChiefInformationSecurityOfficer,
  OccupationTitle.ChiefInnovationOfficer,
  OccupationTitle.GeneralCounsel,
  OccupationTitle.ChiefLegalOfficer,
  OccupationTitle.ChiefMarketingOfficer,
  OccupationTitle.ChiefNursingOfficer,
  OccupationTitle.ChiefOperatingOfficer,
  OccupationTitle.ChiefProductOfficer,
  OccupationTitle.ChiefRiskOfficer,
  OccupationTitle.ChiefSecurityOfficer,
  OccupationTitle.ChiefSustainabilityOfficer,
  OccupationTitle.ChiefTechnologyOfficer,
  OccupationTitle.Chairman,
  OccupationTitle.President,
  OccupationTitle.SeniorVicePresident,
  OccupationTitle.ExecutiveVicePresident,
  OccupationTitle.FinanceVicePresident,
  OccupationTitle.InvestorRelationsVicePresident,
  OccupationTitle.OperationsVicePresident,
  OccupationTitle.SalesVicePresident,
  OccupationTitle.SalesAndMarketingVicePresident,
  OccupationTitle.VicePresident,
  OccupationTitle.AdvertisingVicePresident,
  OccupationTitle.LogisticsVicePresident,
  OccupationTitle.MarketingVicePresident,
  OccupationTitle.Other,
];

/**
 * Get the sorted list of occupation titles.
 *
 * @returns Sorted list of given occupation titles.
 */
export function getSortedOccupationTitles(inputOccupationTitles: Array<OccupationTitle>): Array<OccupationTitle> {
  return [...inputOccupationTitles].sort((a, b) => SORTED_OCCUPATION_TITLES.indexOf(a) - SORTED_OCCUPATION_TITLES.indexOf(b));
}

/**
 * Get the primary occupation title from a list of occupation titles.
 *
 * @param occupationTitles List of occupation titles to search.
 * @returns
 */
export function getPrimaryOccupationTitle(inputOccupationTitles: Array<OccupationTitle>): OccupationTitle {
  return getSortedOccupationTitles(inputOccupationTitles)[0];
}

/**
 * Attempts to normalize the occupation title.
 *
 * @param title A string that may contain one or more role or job titles.
 * @param addUnknownOther A boolean indicating whether to add an "Other" title if no other titles matched.
 *
 * @returns An array of normalized occupation titles. If no titles were matched, an empty array or array with "Other" will be returned.
 */
export function getOccupationTitles(title: string, addUnknownOther: boolean): Array<OccupationTitle> {
  let candidates: Array<string> = [];
  let occupations: Array<{ capitalCased: string; value: OccupationTitle }> = [];
  let result: Array<OccupationTitle> = [];

  // Sanity checks / optimization
  if (!title) {
    if (addUnknownOther) {
      result.push(OccupationTitle.Other);
    }

    return result;
  }

  // Minor initial standardization
  title = title.replace(/(^|, )VP, /, "Vice President of ");
  title = title.replace(/(^|, )VP of /, "Vice President of ");
  title = title.replace(/(^|, )Vice President, /g, "Vice President of ");

  // Create Vice President variations
  Object.values(OccupationTitle).forEach((occupation) => {
    if (occupation.includes("VicePresident") && !occupation.includes("Senior") && !occupation.includes("Executive")) {
      const vp = `Vice President of ${changeCase.capitalCase(occupation.replace("VicePresident", ""))}`;
      occupations.push({
        capitalCased: vp,
        value: occupation,
      });
    }
  });

  Object.values(OccupationTitle).forEach((occupation) => {
    occupations.push({
      capitalCased: changeCase.capitalCase(occupation),
      value: occupation,
    });
  });

  // Attempt to split by common separators
  candidates = title.split(/(?:[&,;\/|-]| and )/g).map((candidate) => candidate.trim());

  // Attempt to match the occupation titles enumeration values
  candidates.forEach((candidate) => {
    // Check for exact matches from mappings first
    if (COMMON_OCCUPATION_TITLE_MAPPINGS[candidate.toUpperCase()]) {
      result.push(COMMON_OCCUPATION_TITLE_MAPPINGS[candidate]);
    } else {
      const match = search(candidate, occupations, {
        threshold: 1.0,
        useSellers: false,
        keySelector: (item) => item.capitalCased,
      });

      if (match) {
        result.push(...match.map((item) => item.value));
      }
    }
  });

  // Cover other common failure cases
  if (result.length === 0) {
    if (title.includes("Executive Vice President")) {
      result.push(OccupationTitle.ExecutiveVicePresident);
    } else if (title.includes("Senior Vice President")) {
      result.push(OccupationTitle.SeniorVicePresident);
    } else if (title.includes("Vice President")) {
      result.push(OccupationTitle.VicePresident);
    }
  }

  if (addUnknownOther && result.length === 0) {
    result.push(OccupationTitle.Other);
  }

  return result;
}
