import { ICommandResponseStatus } from "@bigpi/cookbook";
import { getShapeCoordinates, IHtmlDocumentShape } from "@bigpi/tl-schema";
import { Box, createShapeId, Mat, TLShapeId, type TLArrowShapeProps, Editor, TLShapePartial, TLBaseShape } from "@tldraw/tldraw";
import { TFunction } from "i18next";
import { v4 as uuidV4 } from "uuid";

import { RequestPlugInBase } from "./RequestPlugInBase";
import { getEmptyPoint } from "BoardComponents/Utils/CreateShapeUtils";

// TODO: These should be in a shared location
const DEFAULT_SHAPE_MARGIN_X = 100;
const DEFAULT_SHAPE_MARGIN_Y = 100;

export interface IBindOptions {
  arrowProps: Partial<Pick<TLArrowShapeProps, "arrowheadEnd" | "arrowheadStart" | "color" | "bend" | "dash">>;
}

export interface IHtmlDocumentUpsertData {
  bindOptions?: IBindOptions;
  html: string;
  shapeId: TLShapeId;
  sourceIds?: Array<TLShapeId>;
  status: ICommandResponseStatus;
}

export abstract class HtmlDocumentRequestPlugInBase extends RequestPlugInBase {
  /**
   * Adds or updates the given shape with optional arrows drawn for new shapes.
   *
   * @param editor The Tldraw app editor instance.
   * @param data The data to use to add or update the shape.
   */
  protected upsertShape(editor: Editor, t: TFunction<"translation", undefined>, data: IHtmlDocumentUpsertData): void {
    const { bindOptions, html, shapeId, sourceIds, status } = data;

    // Get the target shape, if it exists
    let targetShape = editor.getShape(shapeId);

    // Get the lock status
    const asyncUpdateLock = !(status === "success" || status === "failure");

    // Check the status to determine if we need to create a new shape or update an existing one
    if (!targetShape) {
      const shape: TLShapePartial<IHtmlDocumentShape> = {
        id: shapeId,
        type: "htmlDocument",
        props: {
          ...editor.shapeUtils.htmlDocument?.getDefaultProps(),
          asyncUpdateLock,
          html: html || "",
        },
      };

      // If source IDs are provided, position the shape next to the source shapes
      if (Array.isArray(sourceIds) && sourceIds.length) {
        const allChildPoints = sourceIds.flatMap((sourceId) => {
          const shape = editor.getShape(sourceId)!;
          return editor
            .getShapeGeometry(sourceId)
            .getVertices()
            .map((point) => Mat.applyToPoint(editor.getShapeLocalTransform(shape), point));
        });
        const selectedBounds = Box.FromPoints(allChildPoints);
        shape.x = selectedBounds.maxX + DEFAULT_SHAPE_MARGIN_X;
        shape.y = selectedBounds.minY + DEFAULT_SHAPE_MARGIN_Y;
      } else {
        const shapePosition = getEmptyPoint(editor, shape, null, "horizontal", DEFAULT_SHAPE_MARGIN_X);
        shape.x = shapePosition.x;
        shape.y = shapePosition.y;
      }

      editor.createShapes([shape]);

      // Get the shape we just created
      targetShape = editor.getShape(shapeId);

      // Check if we have binding options
      if (Array.isArray(sourceIds) && sourceIds.length > 0 && bindOptions && bindOptions.arrowProps) {
        // Create arrows for each source
        const newShapes = sourceIds.map((sourceId) => {
          const sourceShape = editor.getShape(sourceId);

          // Get its coordinates
          const coordinates = getShapeCoordinates(sourceShape! as TLBaseShape<any, any>);
          const { center } = coordinates;

          return {
            id: createShapeId(uuidV4()),
            type: "arrow",
            x: center.x,
            y: center.y,
            props: {
              // Default values
              size: "m",
              fill: "none",
              labelColor: "black",
              arrowheadStart: "none",
              arrowheadEnd: "arrow",
              text: "",
              labelPosition: 0.5,
              font: "draw",

              // Overrides
              dash: "dashed",
              color: "orange",
              bend: -100,

              // Allow overriding of the default binding options
              ...bindOptions.arrowProps,

              // Bind start and end
              start: { type: "binding", boundShapeId: sourceId, normalizedAnchor: { x: 0.5, y: 0.5 }, isExact: false },
              end: {
                type: "binding",
                boundShapeId: shapeId,
                normalizedAnchor: { x: 0.5, y: 0.5 },
                isExact: false,
              },
            },
          };
        });

        editor.createShapes(newShapes);
      }

      // Animate to the document shape
      editor.animateToShape(shapeId, { duration: 2000 });
    } else {
      // Update the shape
      editor.updateShapes([
        {
          id: shapeId,
          type: "htmlDocument",
          props: {
            asyncUpdateLock,
            html: html || t("Components.WorkspaceBoardCommandResult.NoData"),
          },
        },
      ]);
    }
  }
}
