import * as Plot from "@observablehq/plot";
import type { Reducer } from "@observablehq/plot";
import * as d3 from "d3";

import { on, RenderableMark } from "Notebooks/PlotUtils/PlotEventHandlers";

/*  These are the default dimensions followed by Plot internally
 *   See: https://observablehq.com/plot/features/plots#layout-options
 *   TODO for future: Need to see if any functions exported by Plot can be used to get these values
 */
const DEFAULT_DIMENSIONS = {
  marginBottom: 30,
  marginLeft: 40,
  marginRight: 20,
  marginTop: 20,
  width: 640,
};

/**
 * Mainly suits for distribution of continuous data
 * @param direction Direction of the rect
 * @param data Data to be plotted
 * @param facets Facets to be applied
 * @param metadata Metadata to be used for plot
 * @param xField x-axis field
 * @param yField y-axis field
 * @param groupReducer Group reducer passed to bin transformation
 * @param markOptions Mark options like fill, insetLeft, insetRight, interval(thresholds)
 * @param onBrushSelection On brush selection handler
 * @param onRectClick On rect click handler
 * @param onLabelClick On label click handler
 */
export function getHistogramChartPlot(
  direction: "horizontal" | "vertical" | "",
  data: Array<Record<string, any>>,
  metadata: {
    className?: string;
    grid?: boolean;
    height?: number;
    insetLeft?: number;
    insetRight?: number;
    marginBottom?: number;
    marginLeft?: number;
    marginRight?: number;
    marginTop?: number;
    padding?: number;
    width: number;
    x?: Record<string, any>;
    y?: Record<string, any>;
    fx?: Record<string, any>;
    fy?: Record<string, any>;
    color?: Record<string, any>;
  },
  xField: string,
  yField: string,
  binReducer: Reducer | string | undefined,
  fillReducer: Reducer | string | undefined,
  markOptions: {
    fill?: string | ((dataItem: any) => string);
    insetTop?: number;
    insetLeft?: number;
    insetRight?: number;
    insetBottom?: number;
    interval?: string;
    fy?: string;
    fx?: string;
    tip?: boolean;
  },
  onBrushSelection?: (e: PointerEvent, selectedValue: string) => void,
  onRectClick?: (e: PointerEvent, selectedValue: Record<string, any>, yField: string) => void,
  onLabelClick?: (e: PointerEvent, selectedValue: string, yField: string) => void,
): HTMLElement {
  let rectType: "rect" | "rectX" | "rectY" = "rect";
  if (direction === "horizontal") {
    rectType = "rectX";
  } else if (direction === "vertical") {
    rectType = "rectY";
  }

  let mark;

  if (onRectClick) {
    mark = on(
      Plot[rectType](
        data,
        getRectChartOptions(binReducer, fillReducer, xField, yField, direction, markOptions),
      ) as RenderableMark,
      {
        pointerup: (e: PointerEvent, d: any) => {
          onRectClick(e, d, yField);
        },
      },
    );
  } else {
    mark = Plot[rectType](data, getRectChartOptions(binReducer, fillReducer, xField, yField, direction, markOptions));
  }

  const chart = Plot.plot({
    style: {
      backgroundColor: "transparent",
    },
    ...metadata,
    x: {
      ...metadata.x,
    },
    y: {
      ...metadata.y,
    },
    marks: [mark],
  });

  const width = metadata.width || parseInt(chart.getAttribute("width") || "0");
  const height = metadata.height || parseInt(chart.getAttribute("height") || "0");
  const marginLeft = metadata.marginLeft || DEFAULT_DIMENSIONS.marginLeft;
  const marginRight = metadata.marginRight || DEFAULT_DIMENSIONS.marginRight;
  const marginTop = metadata.marginTop || DEFAULT_DIMENSIONS.marginTop;
  const marginBottom = metadata.marginBottom || DEFAULT_DIMENSIONS.marginBottom;

  if (onBrushSelection) {
    /* Getting the scale of what plot intenally creates with data, so based on the selection we get the range
     * TODO for future: Need to see if any functions exported by Plot can be used to get this scale
     */
    const sortedData = data.map((d) => d[xField]).sort((a, b) => d3.descending(b, a));
    const xScale = d3.scaleUtc([sortedData[0], sortedData[sortedData.length - 1]], [marginLeft, width - marginRight]);
    // Brush
    const brush = d3
      .brushX()
      .extent([
        [marginLeft, marginTop],
        [width - marginRight, height - marginBottom],
      ])
      .on("end", (params) => {
        if (params.type === "end" && params.selection) {
          const startDate = xScale.invert(params.selection[0]);
          const endDate = xScale.invert(params.selection[1]);
          onBrushSelection(params.sourceEvent, `${startDate.toISOString()}#${endDate.toISOString()}`);
        }
      })
      .touchable(true);

    d3.select(chart).append("g").call(brush);
  }

  if (onLabelClick) {
    // Add click handler for the labels
    d3.select(chart)
      .selectAll("text")
      .on("pointerup", function (event: PointerEvent) {
        onLabelClick(event, d3.select(this).text(), yField);
      });
  }

  return chart as HTMLElement;
}

/**
 *
 * @param binReducer Bin reducer passed to bin transformation
 * @param xField x-axis field
 * @param yField y-axis field
 * @param direction Direction of the rect
 * @param markOptions Mark options like fill, insetLeft, insetRight, interval(thresholds)
 * @returns
 */
function getRectChartOptions(
  binReducer: Reducer | string | undefined,
  fillReducer: Reducer | string | undefined,
  xField: string,
  yField: string,
  direction: "horizontal" | "vertical" | "",
  markOptions: {
    fill?: string | ((dataItem: any) => string);
    insetTop?: number;
    insetLeft?: number;
    insetRight?: number;
    insetBottom?: number;
    interval?: string;
    fy?: string;
    fx?: string;
    tip?: boolean;
  },
) {
  const { interval, ...otherMarkOptions } = markOptions;
  const options: Record<string, any> = {
    ...otherMarkOptions,
  };
  if (markOptions.interval) {
    // @ts-ignore TODO: issue: interval uncompatability
    options["thresholds"] = d3[interval || "utcDay"];
  }

  if (binReducer || fillReducer) {
    // While grouping based on the direction of the chart, the other axis is used as the key
    if (direction === "horizontal" && yField) {
      options["y"] = yField;
    } else if (xField) {
      options["x"] = xField;
    }

    const reducerOptions: Record<string, any> = {};

    if (binReducer) {
      reducerOptions[direction === "horizontal" ? "x" : "y"] = binReducer;
    }
    if (fillReducer) {
      reducerOptions["fill"] = fillReducer;
    }

    return Plot[direction === "horizontal" ? "binY" : "binX"](reducerOptions, options);
  }

  if (xField) {
    options["x"] = xField;
  }
  if (yField) {
    options["y"] = yField;
  }

  return options;
}
