export enum BistroService {
  ApiGateway = "ApiGateway",
  Doubtfire = "Doubtfire",
  Eltz = "Eltz",
  Linecook = "Linecook",
  Maitred = "Maitred",
  Pantry = "Pantry",
  Runner = "Runner",
  Sushi = "Sushi",
  Waiter = "Waiter",
}
