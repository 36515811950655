import { ApolloClient } from "@apollo/client";
import { IQuestionAnalysisFacets } from "@bigpi/tl-schema";
import { TFunction } from "i18next";

import { IGroupedAnalystQuestionResult } from "BoardComponents/BoardDatastore";
import { GROUPED_ANALYST_QUESTIONS_QUERY } from "GraphQL/AnalystQuestion";
import { GET_CONFIG_DATA } from "GraphQL/Configs/Query";
import { GetConfigDataQuery, GroupedAnalystQuestionsQuery } from "GraphQL/Generated/Apollo";
import { DataUtils } from "Utils/DataUtils";
import { IQuestionAnalysisConfig } from "../DataFrameConfigs";
import { QUESTION_ANALYSIS_CONFIG_KEY } from "./QuestionAnalysisShape";

/**
 * Fetches the question analysis config
 *
 * @param apolloClient - The Apollo client instance.
 * @param organizationId - The organization ID.
 *
 * @returns The question analysis config.
 */
export const getQuestionAnalysisConfig = async (
  apolloClient: ApolloClient<object>,
  organizationId: string,
): Promise<IQuestionAnalysisConfig> => {
  const apiResponse = await apolloClient.query<GetConfigDataQuery>({
    query: GET_CONFIG_DATA,
    variables: {
      key: QUESTION_ANALYSIS_CONFIG_KEY,
      organizationId,
    },
  });

  return JSON.parse(apiResponse.data?.Config?.data || "{}") as IQuestionAnalysisConfig;
};

/**
 * Fetches the data for the grouped analyst questions based on the selected facets.
 *
 * @param apolloClient - The Apollo client instance.
 * @param facets - The facets used for filtering.
 * @param organizationId - The organization ID.
 * @param t - The translation function.
 *
 * @returns The array of grouped analyst question results.
 */
export const getGroupedAnalystQuestionsData = async (
  apolloClient: ApolloClient<object>,
  facets: IQuestionAnalysisFacets,
  organizationId: string,
  t: TFunction,
): Promise<Array<IGroupedAnalystQuestionResult>> => {
  const config = await getQuestionAnalysisConfig(apolloClient, organizationId);
  if (!config.clusterAssignmentVersion) {
    return [];
  }

  const apiResponse = await apolloClient.query<GroupedAnalystQuestionsQuery>({
    query: GROUPED_ANALYST_QUESTIONS_QUERY,
    variables: {
      facets,
      numberOfClusters: config.numberOfClusters || 10,
      otherGroupLabel: t("Components.Analyses.QuestionAnalysis.OtherGroup"),
      version: config.clusterAssignmentVersion || "",
    },
  });

  return apiResponse.data?.groupedAnalystQuestions || DataUtils.getImmutableEmptyArray<IGroupedAnalystQuestionResult>();
};
