import { T } from "@tldraw/validate";
import { ShapeProps } from "@tldraw/tlschema";

import { dataGridPreferencesValidatable } from "../DataGrid/index.js";
import { IQuestionAnalysisShape } from "./IQuestionAnalysisShape.js";
import { analysisPreferencesFacetSortValidatable } from "../../Common.js";
import { QuestionAnalysisFacetsTypeEnum } from "./IQuestionAnalysisFacets.js";

// *********************************************
// Private constants
// *********************************************/
/**
 * The default width of the shape.
 */
const DEFAULT_WIDTH = 1500;

/**
 * The default height of the shape.
 */
const DEFAULT_HEIGHT = 1000;

// *********************************************
// Public constants
// *********************************************/
/**
 * Gets the default props for the shape.
 *
 * @returns The default props.
 */
export const getQuestionAnalysisShapeDefaultProps = (): IQuestionAnalysisShape["props"] => {
  return {
    boundsFacetValues: {},
    enableBackground: true,
    selectedFacetValues: {},
    h: DEFAULT_HEIGHT,
    preferences: {},
    w: DEFAULT_WIDTH,
  };
};

/**
 * The shape props schema definition.
 */
export const questionAnalysisShapeProps: ShapeProps<IQuestionAnalysisShape> = {
  enableBackground: T.boolean.optional(),
  boundsFacetValues: T.object({
    eventDate: T.object({
      from: T.string.optional(),
      to: T.string.optional(),
    }).optional(),
    eventDateShortcut: T.string.optional(),
    firm: T.arrayOf(T.string).optional(),
    firmType: T.arrayOf(T.string).optional(),
    segment: T.arrayOf(T.string).optional(),
    topics: T.arrayOf(T.string).optional(),
  }),
  selectedFacetValues: T.object({
    eventDate: T.object({
      from: T.string.optional(),
      to: T.string.optional(),
    }).optional(),
    eventDateShortcut: T.string.optional(),
    firm: T.arrayOf(T.string).optional(),
    firmType: T.arrayOf(T.string).optional(),
    segment: T.arrayOf(T.string).optional(),
    topics: T.arrayOf(T.string).optional(),
  }),
  h: T.nonZeroInteger.optional(),
  preferences: T.object({
    analysis: T.object({
      facetSort: analysisPreferencesFacetSortValidatable,
      showDataToDisplayInToolbar: T.boolean.optional(),
    }).optional(),
    dataGrid: dataGridPreferencesValidatable,
  }),
  selection: T.object({
    selectedTimeScale: T.string.optional(),
  }).optional(),
  selectedBoundsFields: T.arrayOf(
    T.setEnum(
      new Set([
        QuestionAnalysisFacetsTypeEnum.EventDate,
        QuestionAnalysisFacetsTypeEnum.EventDateShortcut,
        QuestionAnalysisFacetsTypeEnum.Firm,
        QuestionAnalysisFacetsTypeEnum.FirmType,
        QuestionAnalysisFacetsTypeEnum.Segment,
        QuestionAnalysisFacetsTypeEnum.Topics,
      ]),
    ),
  ).optional(),
  toolbar: T.object({
    availableFields: T.arrayOf(
      T.setEnum(
        new Set([
          QuestionAnalysisFacetsTypeEnum.EventDate,
          QuestionAnalysisFacetsTypeEnum.EventDateShortcut,
          QuestionAnalysisFacetsTypeEnum.Firm,
          QuestionAnalysisFacetsTypeEnum.FirmType,
          QuestionAnalysisFacetsTypeEnum.Segment,
          QuestionAnalysisFacetsTypeEnum.Topics,
        ]),
      ),
    ).optional(),
  }).optional(),
  w: T.nonZeroInteger.optional(),
};
