/**
 * @description Represents the dash styles for drawing lines.
 */
export enum TLDashStyle {
  /**
   * @description A drawn line style.
   */
  Draw = "draw",

  /**
   * @description A solid line style.
   */
  Solid = "solid",

  /**
   * @description A dashed line style.
   */
  Dashed = "dashed",

  /**
   * @description A dotted line style.
   */
  Dotted = "dotted",
}
