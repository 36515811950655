export enum IndexDocumentType {
  /**
   * Workspace board shape.
   */
  BoardShape = "BoardShape",

  /**
   * Documents (e.g. knowledge base article etc.)
   */
  Document = "Document",

  /**
   * Content library file.
   */
  File = "File",

  /**
   * Transcript.
   */
  Transcript = "Transcript",

  /**
   * Workspace file.
   */
  WorkspaceFile = "WorkspaceFile",
}
