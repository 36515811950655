import EventEmitter from "emittery";
import { useMemo } from "react";

export type OnShapeReadyEventArgs = {
  shapeId: string;
};

export type ShapeLifecycleEvents = {
  beforeShapeReady: OnShapeReadyEventArgs;
  shapeReady: OnShapeReadyEventArgs;
  afterShapeReady: OnShapeReadyEventArgs;
};

export type OnBeforeShapeReadyHandler = (event: OnShapeReadyEventArgs) => void;
export type OnShapeReadyHandler = (event: OnShapeReadyEventArgs) => void;
export type OnAfterShapeReadyHandler = (event: OnShapeReadyEventArgs) => void;

/**
 * Handles the lifecycle events of a shape.
 */
export class ShapeLifecycleEventEmitter extends EventEmitter<ShapeLifecycleEvents> {
  // *********************************************
  // Public static methods
  // *********************************************/
  public static useNewShapeLifecycleEventEmitter = () => {
    const result = useMemo(() => new ShapeLifecycleEventEmitter(), []);

    return result;
  };

  // *********************************************
  // Public methods
  // *********************************************/
  public async raiseBeforeShapeReady(event: OnShapeReadyEventArgs) {
    await this.emitSerial("beforeShapeReady", event);
  }

  public async raiseShapeReady(event: OnShapeReadyEventArgs) {
    await this.emitSerial("shapeReady", event);
  }

  public async raiseAfterShapeReady(event: OnShapeReadyEventArgs) {
    await this.emitSerial("afterShapeReady", event);
  }
}
