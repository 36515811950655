import { gql, useQuery } from "@apollo/client";
import { IAnalysisFieldConfig } from "@bigpi/cookbook";
import { useValue } from "@tldraw/tldraw";

import { IAnalysisType, AnalysisTypeEnum } from "BoardComponents/Types";

import {
  QuestionAnalysisDistinctValuesQuery,
  TopicDiscussionAnalysisDistinctValuesQuery,
  TopicDiscussionInNewsArticleAnalysisDistinctValuesQuery,
  TopicDiscussionSummaryAnalysisDistinctValuesQuery,
} from "./FieldQueryGenerators";

const EMPTY_QUERY = gql`
  query EmptyQuery {
    # Placeholder operation to avoid syntax error
    __typename
  }
`;

/**
 * Query to fetch distinct values for the fields by generating dynamic GraphQL query
 *
 * @param analysisType Analysis type to decide the dynamic query
 * @param fields Fields to query for distinct values
 * @param facets All applied facets
 * @param fieldFacets Each field specific facets.
 * @param fieldsConfig Field related config to check for type & have specific query
 * @returns Runs the dynamic GraphQL query & returns the query result
 */
export function useFieldDistinctValuesQuery<TFacets>(
  analysisType: IAnalysisType,
  fields: Array<string> | undefined = [],
  facets: TFacets,
  fieldFacets: Record<string, TFacets>,
  fieldsConfig: Array<IAnalysisFieldConfig>,
) {
  // Analysis specific query
  const query = useValue(
    "query",
    () => {
      if (!fields || (Array.isArray(fields) && fields.length === 0)) {
        return EMPTY_QUERY;
      }
      return getQuery(analysisType, fields, fieldsConfig);
    },
    [fields, fieldsConfig],
  );

  // Runs the analysis type specific dynamic query to get distinct values for the fields
  const queryResult = useQuery(query || EMPTY_QUERY, {
    variables: {
      ...fields?.reduce(
        (acc, field) => {
          acc[`${field}Facets`] = fieldFacets[field] || ({} as TFacets);
          return acc;
        },
        {} as Record<string, TFacets>,
      ),
    },
  });

  return queryResult;
}

/**
 * Generates the query to fetch distinct values for the fields based on the analysis type
 *
 * @param analysisType Analysis type to determine the query
 * @param fields Fields to query for distinct values
 * @param fieldsConfig Fields config used to identify the field type
 * @returns Analysis type specific query to fetch distinct values for the fields
 */
function getQuery(analysisType: IAnalysisType, fields: Array<string>, fieldsConfig: Array<IAnalysisFieldConfig>) {
  switch (analysisType) {
    case AnalysisTypeEnum.TopicDiscussionSummaryAnalysis:
      return new TopicDiscussionSummaryAnalysisDistinctValuesQuery(fields, fieldsConfig).getQuery();
    case AnalysisTypeEnum.QuestionAnalysis:
      return new QuestionAnalysisDistinctValuesQuery(fields, fieldsConfig).getQuery();
    case AnalysisTypeEnum.TopicDiscussionInNewsArticleAnalysis:
      return new TopicDiscussionInNewsArticleAnalysisDistinctValuesQuery(fields, fieldsConfig).getQuery();
    case AnalysisTypeEnum.TopicDiscussionAnalysis:
      return new TopicDiscussionAnalysisDistinctValuesQuery(fields, fieldsConfig).getQuery();
    default:
      return EMPTY_QUERY;
  }
}
