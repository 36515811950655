import { IPlugIn } from "@bigpi/cookbook";

import {
  GenericClientActionRequestPlugIn,
  HeadlineSimulatorRequestPlugIn,
  HtmlPrompterRequestPlugIn,
  ProcessTextRequestPlugIn,
} from ".";
import { ICommandRequestPlugInInputs } from "Components/CommandManagers/WorkspaceBoard/WorkspaceBoardCommandManager";

/**
 * Creates the plug-ins.
 *
 * @param options Options for the plug-ins.
 *
 * @returns An array of plug-ins to be registered.
 */
export function createCommandRequestPlugIns(): IPlugIn<ICommandRequestPlugInInputs, void>[] {
  return [
    new GenericClientActionRequestPlugIn(),
    new HeadlineSimulatorRequestPlugIn(),
    new HtmlPrompterRequestPlugIn(),
    new ProcessTextRequestPlugIn(),
  ];
}
