export enum ContentTypes {
  APNG = "image/apng",
  AVIF = "image/avif",
  BINARY = "application/octet-stream",
  CSS = "text/css; charset=utf-8",
  CSV = "text/csv",
  DOC = "application/msword",
  DOCX = "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  GIF = "image/gif",
  HTML = "text/html",
  JPEG = "image/jpeg",
  JSON = "application/json",
  PDF = "application/pdf",
  PLAIN_TEXT = "text/plain",
  PNG = "image/png",
  PPT = "application/vnd.ms-powerpoint",
  PPTX = "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  RTF = "application/rtf",
  RTF_TEXT = "text/rtf",
  RTF_X = "application/x-rtf",
  RTF_RICHTEXT = "text/richtext",
  SVG = "image/svg+xml",
  URL_ENCODED_FORM = "application/x-www-form-urlencoded",
  WEBP = "image/webp",
  XLS = "application/vnd.ms-excel",
  XLSX = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  XML = "application/xml",
  YAML = "application/yaml",
  ZIP = "application/zip",

  // Unused suggestions by copilot
  // AAC = "audio/aac",
  // ASF = "video/x-ms-asf",
  // AVI = "video/x-msvideo",
  // BZ2 = "application/x-bzip2",
  // EOT = "application/vnd.ms-fontobject",
  // FLV = "video/x-flv",
  // GZIP = "application/gzip",
  // HEIC = "image/heic",
  // HEICS = "image/heic-sequence",
  // HEIF = "image/heif",
  // HEIFS = "image/heif-sequence",
  // ICO = "image/x-icon",
  // M4A = "audio/x-m4a",
  // M4V = "video/x-m4v",
  // MID = "audio/midi",
  // MIDI = "audio/midi",
  // MKV = "video/x-matroska",
  // MOV = "video/quicktime",
  // MP3 = "audio/mpeg",
  // MP4 = "video/mp4",
  // ODB = "application/vnd.oasis.opendocument.database",
  // ODF = "application/vnd.oasis.opendocument.formula",
  // ODG = "application/vnd.oasis.opendocument.graphics",
  // ODI = "application/vnd.oasis.opendocument.image",
  // ODM = "application/vnd.oasis.opendocument.text-master",
  // ODP = "application/vnd.oasis.opendocument.presentation",
  // ODS = "application/vnd.oasis.opendocument.spreadsheet",
  // ODT = "application/vnd.oasis.opendocument.text",
  // OGG = "audio/ogg",
  // OTF = "font/otf",
  // OTH = "application/vnd.oasis.opendocument.text-web",
  // QT = "video/quicktime",
  // RAR = "application/vnd.rar",
  // TAR = "application/x-tar",
  // TSV = "text/tab-separated-values",
  // TTF = "font/ttf",
  // WAV = "audio/wav",
  // WEBM = "video/webm",
  // WEBM_AUDIO = "audio/webm",
  // WEBM_VIDEO = "video/webm",
  // WMV = "video/x-ms-wmv",
  // WOFF = "font/woff",
  // WOFF2 = "font/woff2",
}
