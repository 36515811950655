import { Box, useTheme } from "@mui/material";
import { SuggestionProps, SuggestionKeyDownProps } from "@tiptap/suggestion";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";

export default forwardRef((props: SuggestionProps, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const theme = useTheme();

  const selectItem = (index: number) => {
    const item = props.items[index];

    if (item) {
      props.command({ id: item });
    }
  };

  const upHandler = () => {
    setSelectedIndex((selectedIndex + props.items.length - 1) % props.items.length);
  };

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % props.items.length);
  };

  const enterHandler = () => {
    selectItem(selectedIndex);
  };

  useEffect(() => setSelectedIndex(0), [props.items]);

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }: SuggestionKeyDownProps) => {
      if (event.key === "ArrowUp") {
        upHandler();
        return true;
      }

      if (event.key === "ArrowDown") {
        downHandler();
        return true;
      }

      if (event.key === "Enter") {
        enterHandler();
        return true;
      }

      return false;
    },
  }));

  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        borderRadius: 1,
        position: "relative",
        padding: 1,
        overflow: "hidden",
        fontSize: "12px",
        border: "1px solid #ccc",
      }}
    >
      {props.items.length ? (
        props.items.map((item, index) => (
          <span
            key={index}
            onClick={() => selectItem(index)}
            style={{
              backgroundColor: index === selectedIndex ? theme.palette.primary.light : "transparent",
              borderRadius: 3,
              display: "block",
              margin: 0,
              padding: "4px 8px",
              textAlign: "left",
              width: "100%",
              cursor: "pointer",
            }}
          >
            {item}
          </span>
        ))
      ) : (
        <Box className="item">No result</Box>
      )}
    </Box>
  );
});
