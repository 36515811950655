import React, { useEffect, useState } from "react";

// @ts-expect-error type module on npm is not found for this: https://github.com/observablehq/runtime/issues/326
import { Runtime, Inspector } from "@observablehq/runtime";

/**
 *
 * @param variable Dependent variable
 * @param ref Element to mount the callback returned plot
 * @param callback Function which returns the element to append to ref
 */
export function useInspector(variableName: string, variableValue: any, ref: React.RefObject<HTMLElement>, callback: Function) {
  const [module, setModule] = useState<any>();

  useEffect(() => {
    // This helps to append/remove the plot chart to html element
    const runtime = new Runtime({ [variableName]: variableValue });
    const module = runtime.module();
    const inspector = new Inspector(ref.current);
    // This creates dependency between element & variable
    module.variable(inspector).define([variableName], callback);
    setModule(module);
  }, []);
  return module;
}
