import { Button, ButtonProps } from "@mui/material";

export interface ITagsSelectFilterButtonProps extends ButtonProps {}

export function TagsSelectFilterButton(props: ITagsSelectFilterButtonProps) {
  const { onClick, children } = props;

  return (
    <Button
      variant="text"
      sx={{
        ml: 2.5,
        "&.MuiButtonBase-root:hover": {
          bgcolor: "transparent",
        },
        textTransform: "none",
      }}
      onClick={(e) => {
        onClick?.(e);
        e.stopPropagation();
      }}
    >
      {children}
    </Button>
  );
}
