import { Editor, Mat, TLShapeId, toDomPrecision } from "@tldraw/tldraw";

export const INTERACTIVE_SHAPES = [
  "analystQuestionAnalysis",
  "dataGrid",
  "feed",
  "filePreview",
  "headline",
  "htmlDocument",
  "inlineFrame",
  "questionAnalysis",
  "topicDiscussionAnalysis",
  "topicDiscussionSummaryAnalysis",
  "topicDiscussionInNewsArticleAnalysis",
];

export const getShapeWidthAndHeight = (shapeId: TLShapeId | null, editor: Editor) => {
  if (!shapeId) {
    return;
  }

  // We get shape's actual width and height using `getBoundsById`
  const shapeBounds = editor.getShapeGeometry(shapeId).getBounds();
  if (!shapeBounds) {
    return;
  }

  const width = toDomPrecision(Math.max(1, shapeBounds.width));
  const height = toDomPrecision(Math.max(1, shapeBounds.height));

  return {
    width,
    height,
  };
};

/**
 * Gives the hovered shape transform
 *
 * @param hoveredId Shape hovered id
 * @param editor Tldraw editor
 * @returns Hovered shape transform
 */
export function getHoverShapeTransform(hoveredId: TLShapeId | null, editor: Editor) {
  if (!hoveredId) {
    return;
  }

  const bounds = editor.getShapeGeometry(editor.getShape(hoveredId)!).getBounds().clone();
  bounds.point = Mat.applyToPoint(editor.getShapeLocalTransform(hoveredId)!, bounds.point);

  return Mat.toCssString(
    Mat.Compose(Mat.Translate(bounds?.minX || 0, bounds?.minY || 0), Mat.Rotate(editor.getShape(hoveredId)?.rotation || 0)),
  );
}
