import { defineMigrations } from "@tldraw/store";

import { IHistogramChartShape } from "./IHistogramChartShape.js";

export const HistogramChartShapeVersions = {
  Initial: 0,
  // Removes `BarChart` from the field
  UpdateFieldProp: 1,
};

export const histogramChartShapeMigrations = defineMigrations({
  firstVersion: HistogramChartShapeVersions.Initial,
  currentVersion: HistogramChartShapeVersions.UpdateFieldProp,
  migrators: {
    [HistogramChartShapeVersions.UpdateFieldProp]: {
      up: (shape: IHistogramChartShape) => {
        const { field, ...rest } = shape.props;
        const formattedField = (field || "").replace("BarChart", "");
        return {
          ...shape,
          props: {
            ...rest,
            field: formattedField,
          },
        };
      },
      down: (shape: IHistogramChartShape) => {
        const { field, ...rest } = shape.props;

        return {
          ...shape,
          props: {
            ...rest,
            field: `${field || ""}BarChart`,
          },
        };
      },
    },
  },
});
