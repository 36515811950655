import * as React from "react";

import { ObjectRole } from "GraphQL/Generated/Apollo";
import { useHasWorkspaceRole } from "Hooks/useHasWorkspaceRole";

export interface IHasWorkspaceRoleProps {
  children: React.ReactNode;
  roles: Array<ObjectRole>;
  workspaceId: string;
}

export const HasWorkspaceRole = (props: IHasWorkspaceRoleProps) => {
  const { children, roles, workspaceId } = props;
  const hasWorkspaceRole = useHasWorkspaceRole(workspaceId, roles);

  if (hasWorkspaceRole) {
    return <>{children}</>;
  }
  return <></>;
};
