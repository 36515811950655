import { useTranslation } from "react-i18next";

import AddWorkspaceIcon from "Assets/Icons/add-workspace.svg";

export const ADD_WORKSPACE_ICON_HEIGHT = 25;
export const ADD_WORKSPACE_ICON_WIDTH = 25;

export function AddToWorkspaceIcon() {
  const { t } = useTranslation();

  return (
    <img
      src={AddWorkspaceIcon}
      height={ADD_WORKSPACE_ICON_HEIGHT}
      width={ADD_WORKSPACE_ICON_WIDTH}
      alt={t("Global.Action.AddToWorkspace")}
    />
  );
}
