/**
 * Standard, platform roles available to users.
 *
 * Note: Not all roles are available under all organizations/tenants.
 * Primarily platform admin related roles are only linked to the BigPi organization.
 */
export enum Roles {
  AuditRead = "bigpi.role.audit.read",

  PlatformAdmin = "bigpi.role.platform.admin",

  PlatformDataAdmin = "bigpi.role.platform.data.admin",

  SsoAdmin = "bigpi.role.sso.admin",
}
