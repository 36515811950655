/**
 * Represents the style of a geometric shape.
 *
 * @createZodSchema
 */
export enum GeoShapeGeoStyle {
  /**
   * @description A cloud shape.
   */
  Cloud = "cloud",

  /**
   * @description A rectangle shape.
   */
  Rectangle = "rectangle",

  /**
   * @description An ellipse shape.
   */
  Ellipse = "ellipse",

  /**
   * @description A triangle shape.
   */
  Triangle = "triangle",

  /**
   * @description A diamond shape.
   */
  Diamond = "diamond",

  /**
   * @description A pentagon shape.
   */
  Pentagon = "pentagon",

  /**
   * @description A hexagon shape.
   */
  Hexagon = "hexagon",

  /**
   * @description An octagon shape.
   */
  Octagon = "octagon",

  /**
   * @description A star shape.
   */
  Star = "star",

  /**
   * @description A rhombus shape.
   */
  Rhombus = "rhombus",

  /**
   * @description A second type of rhombus shape.
   */
  Rhombus2 = "rhombus-2",

  /**
   * @description An oval shape.
   */
  Oval = "oval",

  /**
   * @description A trapezoid shape.
   */
  Trapezoid = "trapezoid",

  /**
   * @description A right arrow shape.
   */
  ArrowRight = "arrow-right",

  /**
   * @description A left arrow shape.
   */
  ArrowLeft = "arrow-left",

  /**
   * @description An up arrow shape.
   */
  ArrowUp = "arrow-up",

  /**
   * @description A down arrow shape.
   */
  ArrowDown = "arrow-down",

  /**
   * @description A box shape with an X inside it.
   */
  XBox = "x-box",

  /**
   * @description A checkbox shape.
   */
  Checkbox = "check-box",
}
