import { IconButton, IconButtonProps, SxProps, Theme, Tooltip, TooltipProps } from "@mui/material";
import React from "react";

interface ToolbarButtonProps extends IconButtonProps {
  Icon: any;
  tooltip?: string;
  tooltipPlacement?: TooltipProps["placement"];
  isActive?: boolean;
}

export const ToolbarButton = (props: ToolbarButtonProps) => {
  const { Icon, tooltip, tooltipPlacement, isActive, ...rest } = props;

  const sx: SxProps<Theme> = isActive
    ? {
        width: "30px",
        height: "30px",
        marginRight: "4px",
        color: "#2f80ed",
        borderRadius: "4px",
        backgroundColor: "rgba(47, 128, 237, 0.2)",
        ":hover": {
          backgroundColor: "rgba(47, 128, 237, 0.3)",
        },
      }
    : {
        color: "#444",
        width: "30px",
        height: "30px",
        marginRight: "4px",
        borderRadius: "4px",
        ":hover": {
          backgroundColor: "rgba(0, 0, 0, 0.035)",
        },
      };

  return (
    <Tooltip title={tooltip} placement={tooltipPlacement}>
      <span>
        <IconButton {...rest} color="inherit" size="small" disableRipple sx={sx}>
          <Icon />
        </IconButton>
      </span>
    </Tooltip>
  );
};
