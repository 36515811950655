import { Paper, SxProps, Theme, Toolbar } from "@mui/material";
import React, { PropsWithChildren } from "react";

export interface FixedToolbarProps extends PropsWithChildren {
  sx?: SxProps<Theme>;
  toolbarProps?: any;
}

export const FixedToolbar = (props: FixedToolbarProps) => {
  const { children, sx = {}, toolbarProps = {} } = props;

  return (
    <Paper elevation={3} sx={{ position: "fixed", borderRadius: "12px", zIndex: 1100, ...sx }}>
      <Toolbar sx={{ display: "flex" }} {...toolbarProps}>
        {children}
      </Toolbar>
    </Paper>
  );
};

export default FixedToolbar;
