import { DateRangeShortcut } from "@bigpi/cookbook";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import weekOfYear from "dayjs/plugin/weekOfYear";

// Extend dayjs with utc plugin
dayjs.extend(utc);
dayjs.extend(weekOfYear);

/**
 * Converts the date range sortcut to actual dates range
 *
 * @param dateShortcut String of date shortcut. Ex: This week, This month, Last month
 */
export function getDateRangeFromShortcut(dateShortcut: keyof typeof DateRangeShortcut) {
  const today = dayjs.utc();
  switch (dateShortcut) {
    case DateRangeShortcut.CurrentYear:
      return [today.startOf("year").toISOString(), today.endOf("year").toISOString()];
    case DateRangeShortcut.PreviousYear:
      const prevYear = today.subtract(1, "year");
      return [prevYear.startOf("year").toISOString(), prevYear.endOf("year").toISOString()];
    case DateRangeShortcut.CurrentWeek:
      return [today.startOf("week").toISOString(), today.endOf("week").toISOString()];
    case DateRangeShortcut.PreviousWeek:
      const prevWeek = today.subtract(7, "day");
      return [prevWeek.startOf("week").toISOString(), prevWeek.endOf("week").toISOString()];
    case DateRangeShortcut.NextWeek:
      const nextWeek = today.add(7, "day");
      return [nextWeek.startOf("week").toISOString(), nextWeek.endOf("week").toISOString()];
    case DateRangeShortcut.Last7Days:
      return [today.subtract(7, "day").toISOString(), today.toISOString()];
    case DateRangeShortcut.CurrentMonth:
      return [today.startOf("month").toISOString(), today.endOf("month").toISOString()];
    case DateRangeShortcut.NextMonth:
      const startOfNextMonth = today.endOf("month").add(1, "day");
      return [startOfNextMonth.startOf("month").toISOString(), startOfNextMonth.endOf("month").toISOString()];
    case DateRangeShortcut.PreviousMonth:
      const startOfLastMonth = today.startOf("month").subtract(1, "month");
      return [startOfLastMonth.startOf("month").toISOString(), startOfLastMonth.endOf("month").toISOString()];
    case DateRangeShortcut.Last30Days:
      return [today.subtract(30, "day").toISOString(), today.toISOString()];
    case DateRangeShortcut.CalendarCurrentQuarter:
      return getCurrentQuarterDateRange(today);
    case DateRangeShortcut.CalendarPreviousQuarter:
      return getPreviousQuarterDateRange(today);
    case DateRangeShortcut.CalendarQ1:
      return [dayjs.utc(`${today.year()}-01-01`).toISOString(), dayjs.utc(`${today.year()}-03-31`).toISOString()];
    case DateRangeShortcut.CalendarQ2:
      return [dayjs.utc(`${today.year()}-04-01`).toISOString(), dayjs.utc(`${today.year()}-06-30`).toISOString()];
    case DateRangeShortcut.CalendarQ3:
      return [dayjs.utc(`${today.year()}-07-01`).toISOString(), dayjs.utc(`${today.year()}-09-30`).toISOString()];
    case DateRangeShortcut.CalendarQ4:
      return [dayjs.utc(`${today.year()}-10-01`).toISOString(), dayjs.utc(`${today.year()}-12-31`).toISOString()];
    default:
      return undefined;
  }
}

function getQuarter(date: Dayjs) {
  const month = date.month();
  if (month < 3) {
    return 1;
  } else if (month < 6) {
    return 2;
  } else if (month < 9) {
    return 3;
  } else {
    return 4;
  }
}

export function getCurrentQuarterDateRange(date: Dayjs) {
  const quarter = getQuarter(date);
  const year = date.year();
  let result;

  if (quarter === 1) {
    result = [dayjs.utc(`${year}-01-01`).toISOString(), dayjs.utc(`${year}-03-31`).toISOString()];
  } else if (quarter === 2) {
    result = [dayjs.utc(`${year}-04-01`).toISOString(), dayjs.utc(`${year}-06-30`).toISOString()];
  } else if (quarter === 3) {
    result = [dayjs.utc(`${year}-07-01`).toISOString(), dayjs.utc(`${year}-09-30`).toISOString()];
  } else {
    result = [dayjs.utc(`${year}-10-01`).toISOString(), dayjs.utc(`${year}-12-31`).toISOString()];
  }

  return result;
}

export function getPreviousQuarterDateRange(date: Dayjs) {
  const currentQuarter = getQuarter(date);
  const year = date.year();
  let result;

  if (currentQuarter === 1) {
    result = [dayjs.utc(`${year - 1}-10-01`).toISOString(), dayjs.utc(`${year - 1}-12-31`).toISOString()];
  } else if (currentQuarter === 2) {
    result = [dayjs.utc(`${year}-01-01`).toISOString(), dayjs.utc(`${year}-03-31`).toISOString()];
  } else if (currentQuarter === 3) {
    result = [dayjs.utc(`${year}-04-01`).toISOString(), dayjs.utc(`${year}-06-30`).toISOString()];
  } else {
    result = [dayjs.utc(`${year}-07-01`).toISOString(), dayjs.utc(`${year}-09-30`).toISOString()];
  }

  return result;
}

/**
 * Converts the given date string to UTC value.
 *
 * @param date Input date string or Date object
 * @returns
 */
export function getUTCValue(date: string | Date) {
  if (date instanceof Date) {
    return Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
  }
  const inputDate = new Date(date);
  return Date.UTC(inputDate.getUTCFullYear(), inputDate.getUTCMonth(), inputDate.getUTCDate());
}

/**
 * Formats the given date string to the given format.
 *
 * @param date Input date string or Date object
 * @param format Format to be used for the output date
 * @returns
 */
export function getFormattedDate(date: string | Date, format: string = "YYYY-MM-DD") {
  return dayjs.utc(date).format(format);
}

/**
 * Gives the week number of the given date.
 *
 * @param date Date string or Date object
 * @returns
 */
export function getWeekNumber(date: string | Date) {
  return dayjs.utc(date).week();
}

/**
 * Checks if the given date is in the given range.
 * Note: Considers only the date part in the given date.
 *
 * @param rangeDate The date range in the format YYYY-MM-DD#YYYY-MM-DD.
 * @param dateCheck The date to check.
 *
 * @returns `true` if the date is in the range, `false` otherwise.
 */
export function isInRange(rangeDate: string, dateCheck: string | Date) {
  if (!dateCheck) {
    return false;
  }
  const dateToVerify = getUTCValue(dateCheck);
  const splitDate = rangeDate.split("#");
  const from = splitDate[0] ? getUTCValue(splitDate[0]) : "";
  const to = splitDate[1] ? getUTCValue(splitDate[1]) : "";
  if (from && to) {
    return dateToVerify >= from && dateToVerify <= to;
  } else if (from) {
    return dateToVerify >= from;
  } else if (to) {
    return dateToVerify <= to;
  }
}

/**
 * Checks which date is earlier/before between the two given dates and returns it.
 * If any one of them is empty, then other date is returned.
 * If both are equal then second date is returned.
 *
 * @param date1 First date string
 * @param date2 Second date string
 * @returns The earlier date of the two input dates.
 */
export function getEarliestDate(date1: string, date2: string) {
  if (!date1 || !date2) {
    return date1 || date2;
  }

  const dayjsDate1 = dayjs.utc(date1);
  const dayjsDate2 = dayjs.utc(date2);
  if (dayjsDate1.isBefore(dayjsDate2)) {
    return dayjsDate1.toISOString();
  } else {
    return dayjsDate2.toISOString();
  }
}

/**
 * Checks which date is later/after between the two given dates and returns it.
 * If any one of them is empty, then other date is returned.
 * If both are equal then second date is returned.
 *
 * @param date1 First date string
 * @param date2 Second date string
 * @returns The oldest date
 */
export function getOldestDate(date1: string, date2: string) {
  if (!date1 || !date2) {
    return date1 || date2;
  }
  const dayjsDate1 = dayjs.utc(date1);
  const dayjsDate2 = dayjs.utc(date2);
  if (dayjsDate1.isAfter(dayjsDate2)) {
    return dayjsDate1.toISOString();
  } else {
    return dayjsDate2.toISOString();
  }
}
