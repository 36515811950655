export enum DateRangeShortcut {
  // Year
  CurrentYear = "CurrentYear",
  PreviousYear = "PreviousYear",
  // Quarter
  CalendarCurrentQuarter = "CalendarCurrentQuarter",
  CalendarPreviousQuarter = "CalendarPreviousQuarter",
  // Month
  CurrentMonth = "CurrentMonth",
  PreviousMonth = "PreviousMonth",
  NextMonth = "NextMonth",
  // Calandar Quarter
  CalendarQ1 = "CalendarQ1",
  CalendarQ2 = "CalendarQ2",
  CalendarQ3 = "CalendarQ3",
  CalendarQ4 = "CalendarQ4",
  // Week
  CurrentWeek = "CurrentWeek",
  PreviousWeek = "PreviousWeek",
  NextWeek = "NextWeek",
  // Days
  Last7Days = "Last7Days",
  Last30Days = "Last30Days",
}
