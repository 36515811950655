export const getNumericValueAttribute = (attributeName: string, defaultValue: number | null) => {
  return {
    default: defaultValue,
    parseHTML: (element: HTMLElement) => {
      return element.hasAttribute(attributeName) ? parseInt(element.getAttribute(attributeName) || "", 10) : defaultValue;
    },
  };
};

export const getStringValueAttribute = (attributeName: string, defaultValue: string | null) => {
  return {
    default: defaultValue,
    parseHTML: (element: HTMLElement) => {
      return element.hasAttribute(attributeName) ? element.getAttribute(attributeName) : defaultValue;
    },
  };
};

export const getStyleAttribute = () => {
  return getStringValueAttribute("style", null);
};

export const getClassAttribute = () => {
  return getStringValueAttribute("class", null);
};
