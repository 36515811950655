import { IPlugIn } from "@bigpi/cookbook";

import { ICompanionTab } from "BoardComponents/Companion/ICompanionTab";
import { IHtmlDocumentCompanionPlugInInputs } from "./HtmlDocumentCompanionPlugInBase";
import { QuestionnaireCompanionPlugIn } from "./QuestionnaireCompanionPlugIn";

/**
 * Creates the plug-ins.
 *
 * @param options Options for the plug-ins.
 *
 * @returns An array of plug-ins to be registered.
 */
export function createHtmlDocumentCompanionPlugIns(): Array<IPlugIn<IHtmlDocumentCompanionPlugInInputs, Array<ICompanionTab>>> {
  return [new QuestionnaireCompanionPlugIn()];
}
