import { PropsWithChildren } from "react";
import { FixedToolbar } from "@bigpi/cutlery";
import { SxProps, Theme } from "@mui/material";

/**
 * Props for the ShapeToolbar component.
 */
interface ShapeToolbarProps extends PropsWithChildren {
  sx?: SxProps<Theme>;
  toolbarProps?: any;
}

/**
 * A toolbar for shapes.
 *
 * @param props The props for the component.
 *
 * @returns The rendered component.
 */
export function ShapeToolbar(props: ShapeToolbarProps) {
  const { children, sx, toolbarProps } = props;
  return (
    <FixedToolbar sx={sx} toolbarProps={{ onPointerMove: (e: any) => e.stopPropagation(), ...toolbarProps }}>
      {children}
    </FixedToolbar>
  );
}
