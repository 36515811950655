import { RawCommands } from "@tiptap/core";

import { getBaseClassWithoutHeadingAndTitle } from "../../Utils/CommandUtil.js";
import { ListItem } from "../ListItem/ListItemExtension.js";
import { Heading } from "./HeadingExtension.js";

// This command is used to set a heading node.
export const setHeading: RawCommands["setHeading"] =
  (attributes) =>
  ({ chain, state }) => {
    if (!Heading.options.levels.includes(attributes.level)) {
      return false;
    }

    const { $from } = state.selection;
    // "listItem" node represents the "li" tag which is present on
    // "-1" depth from the current selection i.e. the heading node.
    //
    // <ol>
    //   <li>
    //     <p class="heading-3">text</p>
    //   </li>
    // </ol>
    const listItem = $from.node(-1);
    if (listItem.type.name === ListItem.name) {
      const classAttr = getBaseClassWithoutHeadingAndTitle(listItem.attrs.class);
      const headingClass = `heading-${attributes.level}`;
      const newClassAttr = classAttr ? `${classAttr} ${headingClass}`.trim() : headingClass;

      // This is required to apply the correct text style for the
      // list item marker.
      return chain()
        .setNode(Heading.name, attributes)
        .selectParentNode()
        .updateAttributes(ListItem.name, { class: newClassAttr })
        .run();
    }

    return chain().setNode(Heading.name, attributes).run();
  };
