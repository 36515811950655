import { ApolloError } from "@apollo/client";
import { FileSourceType } from "@bigpi/cookbook";
import { List, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { FileItemStandard } from "Components/FileItem/FileItemStandard";
import { LibraryBundleItemStandard } from "Components/LibraryBundleItem/LibraryBundleItemStandard";
import { FileBundleViewQuery, FileBundleViewType } from "GraphQL/Generated/Apollo";
import { LibraryListMenu } from "./LibraryListMenu";

interface ILibraryListProps {
  loading: boolean;
  rows: FileBundleViewQuery["fileBundleView"];
  error?: ApolloError;
  selectedFileIds: Array<string>;
  onSelectionChange: (type: FileBundleViewType, id: string) => void;
}

export function LibraryList(props: ILibraryListProps) {
  const { error, loading, rows, selectedFileIds, onSelectionChange } = props;
  const { t } = useTranslation();

  return (
    <>
      {loading && <Typography variant="caption">{t("Global.Status.Loading")}</Typography>}
      {!loading && rows.length > 0 && (
        <List
          component="nav"
          aria-label={t("Pages.Library.List.AvailableFiles")}
          sx={{ display: "flex", flexDirection: "column", flex: 1 }}
        >
          {rows.map((row) => {
            if (row.createdAt && row.id && row.name && row.type === FileBundleViewType.Bundle) {
              return (
                <LibraryBundleItemStandard
                  bundleCreatedAt={row.createdAt}
                  bundleId={row.id}
                  bundleName={row.name}
                  key={row.id}
                  onSelectionChange={(type, id) => onSelectionChange(type, id)}
                  selectedFileIds={selectedFileIds}
                  listItemProps={{
                    secondaryAction: getSecondaryAction,
                  }}
                />
              );
            } else if (row.createdAt && row.id && row.name && row.mimeType && row.type === FileBundleViewType.File) {
              return (
                <FileItemStandard
                  key={row.id}
                  checked={selectedFileIds.includes(row.id)}
                  onSelectionChange={(id) => onSelectionChange(row.type as FileBundleViewType, id)}
                  fileCreatedAt={row.createdAt}
                  fileId={row.id}
                  fileName={row.name}
                  fileMimeType={row.mimeType}
                  fileSourceType={FileSourceType.Library}
                  secondaryAction={getSecondaryAction}
                />
              );
            }
          })}
        </List>
      )}
      {!loading && !error && rows.length === 0 && (
        <Typography variant="caption" color="textSecondary">
          {t("Pages.Library.List.NoItems")}
        </Typography>
      )}
      {error && (
        <Typography variant="caption" color="error.main">
          {t("Pages.Library.List.LoadError")}
        </Typography>
      )}
    </>
  );

  function getSecondaryAction(id: string) {
    return <LibraryListMenu fileId={id} />;
  }
}
