export enum PageMargin {
  Custom = "Custom",
  Moderate = "Moderate",
  Narrow = "Narrow",
  Normal = "Normal",
  Wide = "Wide",
}

export function PageMarginToClassName(pageMargin: PageMargin): string {
  switch (pageMargin) {
    case PageMargin.Custom:
      return "";
    case PageMargin.Moderate:
      return "margin-moderate";
    case PageMargin.Narrow:
      return "margin-narrow";
    case PageMargin.Normal:
      return "margin-normal";
    case PageMargin.Wide:
      return "margin-wide";
  }
}
