import { TLShapeId, useEditor, useReadonly, useValue } from "@tldraw/tldraw";

export const useIsInteracting = (shapeId: TLShapeId) => {
  const editor = useEditor();
  const isReadonly = useReadonly();

  return useValue(
    "isInteracting",
    () =>
      !isReadonly &&
      editor.getCurrentPageState().hoveredShapeId === shapeId &&
      editor.isInAny("interact.idle", "interact.pointing_shape"),
    [editor, shapeId, isReadonly],
  );
};
