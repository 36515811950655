import { Alert, Button } from "@mui/material";
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { DefaultDialogTransition } from "Components/DialogTransition/DefaultDialogTransition";

export interface IEditInWorkspaceDialogProps {
  errorMessage: string;
  open: boolean;
  onClose: () => void;
  onCreate: (name: string) => void;
}

export function EditInWorkspaceDialog(props: IEditInWorkspaceDialogProps) {
  const { errorMessage, open } = props;

  const { t } = useTranslation();

  const [name, setName] = useState("");
  const [nameFocused, setNameFocused] = useState(true);
  const nameRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (open && nameRef.current) {
      nameRef.current.focus();
    }
  }, [nameRef.current, open]);

  function onClose() {
    setName("");
    props.onClose();
  }

  function onCreate() {
    props.onCreate(name);
  }

  return (
    <Dialog open={open} onClose={onClose} TransitionComponent={DefaultDialogTransition} fullWidth maxWidth="md">
      <DialogTitle variant="h5">{t("Components.EditInWorkspaceDialog.Title")}</DialogTitle>
      <DialogContent>
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        <TextField
          autoFocus={true}
          margin="dense"
          label={t("Components.EditInWorkspaceDialog.NameLabel")}
          type="text"
          fullWidth
          variant="standard"
          value={name}
          onBlur={() => setNameFocused(false)}
          onChange={(e) => setName(e.target.value ?? "")}
          onFocus={() => setNameFocused(true)}
          required
          inputRef={nameRef}
          error={name === "" && !nameFocused}
        />
        <DialogContentText variant="caption">{t("Components.EditInWorkspaceDialog.Tip")}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
          <Button onClick={onClose}>{t("Global.Action.Close")}</Button>
          <Button onClick={onCreate} disabled={name.trim().length === 0}>
            {t("Components.EditInWorkspaceDialog.Create")}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
