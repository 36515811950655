// Generated by ts-to-zod
import { z } from "zod";

import { tLColorStyleSchema } from "./TLColorStyle.zod.js";
import { tLHorizontalAlignStyleSchema } from "./TLHorizontalAlignStyle.zod.js";
import { tLSizeStyleSchema } from "./TLSizeStyle.zod.js";

export const textShapePropsZodSchema = z.object({
  color: tLColorStyleSchema.describe("The text color."),
  size: tLSizeStyleSchema.describe("The text size."),
  align: tLHorizontalAlignStyleSchema.describe("The horizontal alignment of the text."),
  w: z.number().describe("The width of the text shape."),
  text: z.string().describe("The text to display."),
  scale: z
    .number()
    .describe("The scale of the HTML document shape on the canvas. A number between 0 and 1 where 1 is 100% scale."),
  autoSize: z
    .boolean()
    .describe("A boolean value indicating whether the text shape should automatically size itself to fit the text."),
});
