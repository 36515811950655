/**
 * Enum for the different types of objects that can be represented by a URN.
 *
 * @remarks Remember to add new URN object types to the `parseUrn` function in `UrnUtils.ts` and to the service specific permission checks in Drivethru when adding new types.
 */
export enum UrnObjectType {
  Chat = "urn:chat:",

  Document = "urn:document:",

  File = "urn:file:",

  // This is currently only used in command templating and thus is a bit of an outlier.
  Template = "urn:template:",

  User = "urn:user:",

  UserGroup = "urn:user-group:",

  Workspace = "urn:workspace:",

  WorkspaceBoard = "urn:workspace-board:",

  WorkspaceBoardShape = "urn:workspace-board-shape:",

  WorkspaceFile = "urn:workspace-file:",
}
