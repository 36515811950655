import { TopicDiscussionAnalysisRecordTypes } from "@bigpi/cookbook";
import { defineMigrations } from "@tldraw/store";

import { IDataGridPreferences } from "../DataGrid/index.js";

import { ITopicDiscussionAnalysisShape } from "./ITopicDiscussionAnalysisShape.js";

export const TopicDiscussionAnalysisShapeVersions = {
  Initial: 0,
  AddAnalysisTypeField: 1,
  AddToolbarField: 2,
  AddEnableBackgroundField: 3,
  AddPreferencesField: 4,
  RemoveDataGridPreferencesColumnVisibility: 5,
  AddPreferencesFacetSortModel: 6,
  AddStandardisedFacetsAndBounds: 7,
};

export const topicDiscussionAnalysisShapeMigrations = defineMigrations({
  firstVersion: TopicDiscussionAnalysisShapeVersions.Initial,
  currentVersion: TopicDiscussionAnalysisShapeVersions.AddStandardisedFacetsAndBounds,
  migrators: {
    [TopicDiscussionAnalysisShapeVersions.AddAnalysisTypeField]: {
      up: (shape: ITopicDiscussionAnalysisShape) => {
        return {
          ...shape,
          props: { ...shape.props, analysisType: TopicDiscussionAnalysisRecordTypes.Transcript },
        };
      },
      down: (shape: ITopicDiscussionAnalysisShape & { props: { analysisType: string } }) => {
        const { analysisType, ...rest } = shape.props;
        return { ...shape, props: rest };
      },
    },
    [TopicDiscussionAnalysisShapeVersions.AddToolbarField]: {
      up: (shape: ITopicDiscussionAnalysisShape) => {
        return {
          ...shape,
          props: { ...shape.props, toolbar: {} },
        };
      },
      down: (shape: ITopicDiscussionAnalysisShape & { analysisType: string }) => {
        const { toolbar, ...rest } = shape.props;
        return { ...shape, props: rest };
      },
    },
    [TopicDiscussionAnalysisShapeVersions.AddEnableBackgroundField]: {
      up: (shape: ITopicDiscussionAnalysisShape) => {
        return {
          ...shape,
          props: { ...shape.props, enableBackground: false },
        };
      },
      down: (shape: ITopicDiscussionAnalysisShape & { analysisType: string }) => {
        const { enableBackground, ...rest } = shape.props;
        return { ...shape, props: rest };
      },
    },
    [TopicDiscussionAnalysisShapeVersions.AddPreferencesField]: {
      up: (shape: ITopicDiscussionAnalysisShape) => {
        return {
          ...shape,
          props: { ...shape.props, preferences: {} },
        };
      },
      down: (shape: ITopicDiscussionAnalysisShape) => {
        const { preferences, ...rest } = shape.props;
        return { ...shape, props: rest };
      },
    },
    [TopicDiscussionAnalysisShapeVersions.RemoveDataGridPreferencesColumnVisibility]: {
      up: (
        shape: ITopicDiscussionAnalysisShape & { preferences: { dataGrid: { columnVisibility: Record<string, boolean> } } },
      ) => {
        const { dataGrid, ...rest } = shape.props.preferences;
        const dataGridPreferences = dataGrid as IDataGridPreferences & { columnVisibility?: Record<string, boolean> };
        if (dataGridPreferences) {
          delete dataGridPreferences.columnVisibility;
        }

        return {
          ...shape,
          props: {
            ...shape.props,
            preferences: {
              ...shape.props.preferences,
              dataGrid: dataGridPreferences,
            },
          },
        };
      },
      down: (shape: ITopicDiscussionAnalysisShape) => {
        return {
          ...shape,
          props: {
            ...shape.props,
            preferences: {
              ...shape.props.preferences,
              dataGrid: {
                ...shape.props.preferences.dataGrid,
                columnVisibility: {},
              },
            },
          },
        };
      },
    },
    [TopicDiscussionAnalysisShapeVersions.AddPreferencesFacetSortModel]: {
      up: (shape: ITopicDiscussionAnalysisShape) => {
        const { preferences, ...rest } = shape.props;
        const analysisPreferences = preferences.analysis || {};
        analysisPreferences.facetSort = [];
        return {
          ...shape,
          props: {
            ...rest,
            preferences: {
              ...preferences,
              analysis: analysisPreferences,
            },
          },
        };
      },
      down: (shape: ITopicDiscussionAnalysisShape) => {
        const { preferences, ...rest } = shape.props;
        delete preferences.analysis;
        return {
          ...shape,
          props: {
            ...rest,
            preferences,
          },
        };
      },
    },
    [TopicDiscussionAnalysisShapeVersions.AddStandardisedFacetsAndBounds]: {
      up: (
        shape: ITopicDiscussionAnalysisShape & {
          props: { facets: Record<string, any>; toolbar: Record<string, any>; analysisType: string };
        },
      ) => {
        const { analysisType, facets, toolbar, preferences, ...rest } = shape.props;

        let selectedFacetValues: ITopicDiscussionAnalysisShape["props"]["selectedFacetValues"] = {};
        let boundsFacetValues: ITopicDiscussionAnalysisShape["props"]["boundsFacetValues"] = {};
        let selectedBoundsFields;
        let selection: ITopicDiscussionAnalysisShape["props"]["selection"] = {};
        let toolbarProps = {};
        let preferencesProps = { ...(preferences || {}) };

        // Transforms facets to latest
        if (facets) {
          if (facets.selectedCategories) {
            selectedFacetValues.category = facets.selectedCategories;
          }

          if (facets.selectedDate) {
            selectedFacetValues.eventDate = facets.selectedDate;
          }

          if (facets.selectedSections) {
            selectedFacetValues.section = facets.selectedSections;
          }

          if (facets.selectedTickers) {
            selectedFacetValues.ticker = facets.selectedTickers;
          }

          if (facets.selectedTopics) {
            selectedFacetValues.topic = facets.selectedTopics;
          }

          if (facets.expandedGroups) {
            selection.expandedGroups = facets.expandedGroups;
          }

          if (facets.selectedGroup) {
            selection.selectedGroup = facets.selectedGroup;
          }

          if (facets.selectedItem) {
            selection.selectedItem = facets.selectedItem;
          }

          if (facets.selectedXAxis) {
            selection.selectedXAxis = facets.selectedXAxis;
          }
        }

        if (analysisType) {
          boundsFacetValues.type = [analysisType];
          selectedBoundsFields = ["type"];
        }

        if (facets && facets.includeItems !== undefined) {
          preferencesProps.analysis = {
            ...(preferencesProps.analysis || {}),
            showQuestions: facets.includeItems,
          };
        }

        if (toolbar && toolbar.filterFields && toolbar.filterFields.length > 0) {
          toolbarProps = {
            availableFields: toolbar.filterFields,
          };
        } else {
          toolbarProps = {
            availableFields: ["eventDate", "category", "ticker", "topic", "section"],
          };
        }

        return {
          ...shape,
          props: {
            ...rest,
            selectedFacetValues,
            boundsFacetValues,
            selectedBoundsFields,
            selection,
            toolbar: toolbarProps,
            preferences: preferencesProps,
          },
        };
      },
      down: (shape: ITopicDiscussionAnalysisShape) => {
        const { preferences, boundsFacetValues, selectedBoundsFields, selectedFacetValues, toolbar, selection, ...rest } =
          shape.props;

        let facets: Record<string, any> = {};
        let toolbarProps = {};
        let analysisType = "";
        // Reverse the latest selectedFacetValues to old facets
        if (selectedFacetValues) {
          if (selectedFacetValues.category) {
            facets.selectedCategories = selectedFacetValues.category;
          }

          if (selectedFacetValues.eventDate) {
            facets.selectedDate = selectedFacetValues.eventDate;
          }

          if (selectedFacetValues.section) {
            facets.selectedSections = selectedFacetValues.section;
          }

          if (selectedFacetValues.ticker) {
            facets.selectedTickers = selectedFacetValues.ticker;
          }

          if (selectedFacetValues.topic) {
            facets.selectedTopics = selectedFacetValues.topic;
          }
        }

        if (selection) {
          if (selection.expandedGroups) {
            facets.expandedGroups = selection.expandedGroups;
          }

          if (selection.selectedGroup) {
            facets.selectedGroup = selection.selectedGroup;
          }

          if (selection.selectedItem) {
            facets.selectedItem = selection.selectedItem;
          }

          if (selection.selectedXAxis) {
            facets.selectedXAxis = selection.selectedXAxis;
          }
        }

        if (preferences && preferences.analysis && preferences.analysis.showQuestions !== undefined) {
          facets = {
            ...facets,
            includeItems: preferences.analysis.showQuestions,
          };

          delete preferences.analysis.showQuestions;
        }

        if (preferences && preferences.analysis && preferences.analysis.startColor !== undefined) {
          delete preferences.analysis.startColor;
        }

        if (preferences && preferences.analysis && preferences.analysis.showDataToDisplayInToolbar !== undefined) {
          delete preferences.analysis.showDataToDisplayInToolbar;
        }

        if (toolbar && toolbar.availableFields) {
          toolbarProps = {
            filterFields: toolbar.availableFields,
          };
        }
        // Converts bounds facet values to type
        if (boundsFacetValues && boundsFacetValues.type) {
          analysisType = boundsFacetValues.type[0];
        }

        return {
          ...shape,
          props: {
            ...rest,
            analysisType,
            facets,
            toolbar: toolbarProps,
            preferences,
          },
        };
      },
    },
  },
});
