import { Editor } from "@tiptap/core";

export const scrollEditorNodeIntoView = (editor: Editor, pos: number) => {
  try {
    const { node: domNode } = editor.view.domAtPos(pos);
    if (domNode) {
      (domNode as any).scrollIntoView?.({ behavior: "smooth", block: "center", inline: "nearest" });
    }
  } catch (error) {
    // No-op
  }
};
