import * as Plot from "@observablehq/plot";
import * as d3 from "d3";

// These are width of characters relative to the font size on DOM
// Source: https://gist.github.com/tophtucker/62f93a4658387bb61e4510c37e2e97cf
export const characterWidths = [
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.2796875, 0.2765625, 0.3546875,
  0.5546875, 0.5546875, 0.8890625, 0.665625, 0.190625, 0.3328125, 0.3328125, 0.3890625, 0.5828125, 0.2765625, 0.3328125,
  0.2765625, 0.3015625, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875,
  0.5546875, 0.2765625, 0.2765625, 0.584375, 0.5828125, 0.584375, 0.5546875, 1.0140625, 0.665625, 0.665625, 0.721875, 0.721875,
  0.665625, 0.609375, 0.7765625, 0.721875, 0.2765625, 0.5, 0.665625, 0.5546875, 0.8328125, 0.721875, 0.7765625, 0.665625,
  0.7765625, 0.721875, 0.665625, 0.609375, 0.721875, 0.665625, 0.94375, 0.665625, 0.665625, 0.609375, 0.2765625, 0.3546875,
  0.2765625, 0.4765625, 0.5546875, 0.3328125, 0.5546875, 0.5546875, 0.5, 0.5546875, 0.5546875, 0.2765625, 0.5546875, 0.5546875,
  0.221875, 0.240625, 0.5, 0.221875, 0.8328125, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.3328125, 0.5, 0.2765625, 0.5546875,
  0.5, 0.721875, 0.5, 0.5, 0.5, 0.3546875, 0.259375, 0.353125, 0.5890625,
];

const avg = d3.mean(characterWidths.filter((d) => d));

const tickLabelPadding = 9 + 10;

export function measureText(str: string, fontSize = 10) {
  return d3.sum(str, (cur: any) => characterWidths[cur.charCodeAt(0)] ?? avg) * fontSize;
}

export function autoMargin(data: any, accessor: any, fontSize: number) {
  const values: number[] | undefined = Plot.valueof(data, accessor)?.map((d) => measureText(d, fontSize));
  return values && values.length > 0 ? (d3.max(values) || 0) + tickLabelPadding : 0;
}
