import { Checkbox } from "@mui/material";
import { useCallback, useState } from "react";

import { IDocumentListItemProps, DocumentListItem } from "./DocumentListItem";

export type IDocumentListItemStandardProps = {
  onSelectionChange: (id: string) => void;
  secondaryAction?: (id: string) => IDocumentListItemProps["secondaryAction"];
} & Omit<IDocumentListItemProps, "listItemIcon" | "onMouseEnter" | "onMouseLeave" | "secondaryAction">;

export function DocumentListItemStandard(props: IDocumentListItemStandardProps) {
  const { checked, onSelectionChange, row, secondaryAction } = props;
  const [ele, setEle] = useState<HTMLElement | null>(null);

  const handleMouseEnter = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setEle(event.currentTarget);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setEle(null);
  }, []);

  const isSelectionEnabled = Boolean(ele);

  return (
    <DocumentListItem
      {...props}
      listItemIcon={
        (isSelectionEnabled || checked) && (
          <Checkbox checked={checked} onChange={() => onSelectionChange(row.id)} sx={{ padding: 0 }} />
        )
      }
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      secondaryAction={secondaryAction ? secondaryAction(row.id) : undefined}
    />
  );
}
