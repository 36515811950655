export enum StandardHtmlColors {
  // Misc
  berry = "#942958",
  cayenne = "#d9582e",
  coral = "#cb3b43",
  heather = "#d293be",
  jade = "#3d746f",
  midnight = "#1d2d36",
  pacific = "#006c87",
  patina = "#50ada4",
  pine = "#3c9169",
  saffron = "#f7ba44",
  sky = "#00b1cc",
  willow = "#aacc6b",
  wisteria = "#7e5597",
  // Blue
  blue10 = "#e6efff",
  blue20 = "#b8c9e6",
  blue40 = "#7a9acc",
  blue60 = "#4770b3",
  blue80 = "#1e4d99",
  blue100 = "#003180",
  // Gray
  gray20 = "#e8eaeb",
  gray30 = "#d2d5d7",
  gray40 = "#bbc0c3",
  gray60 = "#8e969b",
  gray100 = "#34424a",
  // Green
  green10 = "#f0f7e6",
  green20 = "#d4e8a3",
  green40 = "#96b347",
  green60 = "#b4ca79",
  green80 = "#7daa3d",
  green100 = "#4d7e0f",
  // Organge
  orange10 = "#fff2e6",
  orange20 = "#ffcc99",
  orange40 = "#ff9933",
  orange60 = "#ff8000",
  orange80 = "#e65c00",
  orange100 = "#cc4400",
  // Purple
  purple10 = "#f2e6ff",
  purple20 = "#cc99ff",
  purple40 = "#9933ff",
  purple60 = "#8000ff",
  purple80 = "#5c00e6",
  purple100 = "#4400cc",
  // Red
  red10 = "#f9eaea",
  red100 = "#8f1e0a",
  red20 = "#e9d1ce",
  red40 = "#d1a3a0",
  red60 = "#bf6c60",
  red80 = "#a84232",
}

export type StandardHtmlColorsType = keyof typeof StandardHtmlColors;

export const StandardHtmlColorValues = Object.values(StandardHtmlColors);
