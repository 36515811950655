import { Check, Clear, Delete, PanoramaFishEye, Replay, WarningAmberRounded } from "@mui/icons-material";
import {
  CircularProgress,
  Typography,
  List,
  LinearProgressProps,
  ListItem,
  Stack,
  IconButton,
  Tooltip,
  ListItemText,
} from "@mui/material";
import { Box } from "@mui/system";
import { filesize } from "filesize";
import { useState } from "react";
import { useTranslation } from "react-i18next";

// *********************************************
// Types/Interfaces/Constants
// *********************************************/
export enum FileStatus {
  Uploading = "Uploading",
  Success = "Success",
  Failed = "Failed",
  Cancelled = "Cancelled",
}

export interface IFileWithId {
  id: string;
  file: File;
  failureReason?: string;
}

interface FilesListProps {
  files: Array<IFileWithId>;
  filesStatusMap: Record<string, FileStatus>;
  progressingFiles: Record<string, number>;
  onRetry: (file: IFileWithId) => void;
  onRemove: (fileId: string) => void;
  onAbort: (fileId: string) => void;
}

// *********************************************
// Component
// *********************************************/
export function FilesList(props: FilesListProps) {
  const { files, filesStatusMap, onAbort, onRemove, onRetry, progressingFiles } = props;
  const { t } = useTranslation();

  // State
  const [activeItem, setActiveItem] = useState<number | undefined>(undefined);

  return (
    <Box
      sx={{
        alignItems: "flex-start",
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <Typography variant="caption" color="text.secondary">
        {t("Components.Upload.SelectedFiles", { count: files.length })}
      </Typography>
      <List dense={true} sx={{ border: 1, borderColor: "lightgray", borderRadius: 1, width: "100%" }}>
        {files.map((selectedFile, index) => {
          return (
            <ListItem
              sx={{
                paddingLeft: "16px",
                paddingRight: "50px",
              }}
              onMouseEnter={() => setActiveItem(index)}
              onMouseLeave={() => setActiveItem(undefined)}
              key={index}
              secondaryAction={<Box>{getSecondaryAction(selectedFile, index)}</Box>}
              disableGutters
            >
              <ListItemText primary={`${selectedFile.file.name} (${filesize(selectedFile.file.size)})`} />
            </ListItem>
          );
        })}
      </List>
    </Box>
  );

  /**
   * Get the suitable secondary action for the file
   *
   * @param selectedFile Selected file to get secondary action for
   * @param index Index in the list of displayed files
   * @returns Action to display in the secondary action of the list item
   */
  function getSecondaryAction(selectedFile: IFileWithId, index: number) {
    const fileId = selectedFile.id;
    // Already uploaded
    if (filesStatusMap[fileId] === FileStatus.Success) {
      return <Check color="success" />;
      // Currently uploading & received progress from subscription
    } else if (progressingFiles[fileId] !== undefined) {
      return <CircularProgressWithLabel value={progressingFiles[fileId]} />;
      // Uploading & user hovered over the item
    } else if (filesStatusMap[fileId] === FileStatus.Uploading && activeItem === index) {
      return (
        <IconButton onClick={() => onAbort(fileId)} sx={{ right: "-5px" }}>
          <Clear fontSize="small" />
        </IconButton>
      );
      // Uploading
    } else if (filesStatusMap[fileId] === FileStatus.Uploading) {
      return <PanoramaFishEye fontSize="small" color="disabled" />;
      // Cancelled by the user
    } else if (filesStatusMap[fileId] === FileStatus.Cancelled) {
      return (
        <IconButton onClick={() => onRetry(selectedFile)} sx={{ right: "-5px" }}>
          <Replay />
        </IconButton>
      );
      // Failed to upload in the server
    } else if (filesStatusMap[fileId] === FileStatus.Failed) {
      return (
        <Stack direction="row" alignItems="center">
          <Tooltip title={selectedFile.failureReason}>
            <WarningAmberRounded color="warning" />
          </Tooltip>
          <IconButton onClick={() => onRetry(selectedFile)} sx={{ right: "-5px" }}>
            <Replay />
          </IconButton>
        </Stack>
      );
      // User hovered over the item
    } else if (activeItem === index) {
      return (
        <IconButton onClick={() => onRemove(selectedFile.id)} sx={{ right: "-5px" }}>
          <Delete />
        </IconButton>
      );
    }
  }
}

function CircularProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" value={props.value * 100} size={20} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary" sx={{ fontSize: 9 }}>{`${Math.round(
          props.value * 100,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}
