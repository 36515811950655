import { IFileItemProps, FileItem } from "./FileItem";

export type IFileItemCompactProps = {
  secondaryAction?: (id: string) => IFileItemProps["secondaryAction"];
} & Omit<IFileItemProps, "checked" | "listItemIcon" | "onMouseEnter" | "onMouseLeave" | "secondaryAction">;

export function FileItemCompact(props: IFileItemCompactProps) {
  const { fileId, secondaryAction } = props;

  return <FileItem {...props} checked={false} secondaryAction={secondaryAction ? secondaryAction(fileId) : undefined} />;
}
