import {
  AnalysisFieldTypeEnum,
  IAnalysisFieldConfig,
  IDateRange,
  IDateRangeLimits,
  ISelectInputFieldOption,
} from "@bigpi/cookbook";
import { Editor, useValue, TLShape } from "@tldraw/tldraw";
import { useState, useEffect, useRef } from "react";
import isEqual from "lodash.isequal";

import { IAnalysisFacets } from "BoardComponents/Types";

/******************************************
 * Type
 ******************************************/
export interface FieldFacetsManageStateType<TFacets> {
  finalFacets: TFacets;
  onCancelFacets: () => void;
  onClearSelectedFacetValues: (clearFields: Array<keyof TFacets>) => void;
  onFieldChange: (
    field: string,
    value: Array<string> | string | { dateRange: IDateRange; shortcut?: string },
    options?: Array<ISelectInputFieldOption> | IDateRangeLimits,
  ) => void;
  onResetFacets: () => void;
  onSaveFacets: () => void;
}

/******************************************
 * Hook to update analysis facets
 ******************************************/
/**
 * Gives the ability to maintain local facets state & provides callback to modify, save to shape props & cancel the changes
 *
 * @param editor Editor instance
 * @param shape Shape instance
 * @param facets The current applied facets to start the local state
 * @param updateFacets Callback to update the local state facets
 * @param fieldsConfig Fields config of the analysis, used to check field type & update facets accordingly
 * @param autoSave To indiate whether to save the changes automatically or not
 * @returns Gives callbacks (to update local state facets) & final facets state
 */
export function useFieldFacetsManager<T extends TLShape, TFacets extends IAnalysisFacets>(
  editor: Editor,
  shape: T,
  facets: TFacets,
  updateFacets: (editor: Editor, shape: T, facets: TFacets) => void,
  fieldsConfig: Array<IAnalysisFieldConfig>,
  autoSave: boolean = true,
) {
  // State
  const [finalFacets, setFinalFacets] = useState<TFacets>(facets);
  const [facetsChangeOptions, setFacetsChangeOptions] = useState<FieldFacetsManageStateType<TFacets>>({
    finalFacets,
    onCancelFacets: () => {},
    onClearSelectedFacetValues: () => {},
    onFieldChange: () => {},
    onResetFacets: () => {},
    onSaveFacets: () => {},
  });
  // Refs
  // Save finalFacets to ref before saving to shape props
  const facetsRef = useRef<TFacets>(facets);
  const finalFacetsRef = useRef<TFacets>(finalFacets);

  const onFieldChange = useValue(
    "onFieldChange",
    () => {
      return (
        field: string,
        value: Array<string> | string | { dateRange: IDateRange; shortcut?: string },
        options?: Array<ISelectInputFieldOption> | IDateRangeLimits,
      ) => {
        const fieldConfig = fieldsConfig.find((config) => config.field === field);
        if (fieldConfig) {
          switch (fieldConfig.type) {
            // For date fields
            case AnalysisFieldTypeEnum.Date:
              const { dateRange, shortcut } = value as { dateRange: IDateRange; shortcut?: string };
              const newFacets = {
                ...finalFacetsRef.current,
                [field]: dateRange,
                [`${field}Shortcut`]: shortcut,
              };

              if (!isEqual(newFacets, finalFacetsRef.current)) {
                setFinalFacets(newFacets);
              }
              break;
            // For string fields
            case AnalysisFieldTypeEnum.ArrayOfStrings:
            case AnalysisFieldTypeEnum.String:
              if (typeof value === "string" || Array.isArray(value)) {
                let isSelectAll;

                if (typeof value === "string" && value === "all") {
                  isSelectAll = true;
                } else if (Array.isArray(value) && value.includes("all")) {
                  isSelectAll = true;
                }

                let selectedValues: Array<string> = [];

                if (isSelectAll && Array.isArray(options)) {
                  if (((finalFacetsRef.current[field as keyof TFacets] || []) as Array<string>).length === options.length) {
                    selectedValues = [];
                  } else {
                    selectedValues = options?.map((option) => option.key) || [];
                  }
                } else {
                  selectedValues = Array.isArray(value) ? value : [value];
                }

                const newFacets = {
                  ...finalFacetsRef.current,
                  [field]: selectedValues,
                };

                if (!isEqual(newFacets, finalFacetsRef.current)) {
                  setFinalFacets(newFacets);
                }
              }
              break;
          }
        }
      };
    },
    [fieldsConfig],
  );

  useEffect(() => {
    // Sync facets with local state
    if (!isEqual(facets, facetsRef.current)) {
      setFinalFacets(facets);
      facetsRef.current = facets;
    }
  }, [facets]);

  useEffect(() => {
    finalFacetsRef.current = finalFacets;
  }, [finalFacets]);

  // To save facets automatically when previous state is different from current state
  useEffect(() => {
    if (autoSave && !isEqual(facetsRef.current, finalFacets)) {
      updateFacets(editor, shape, finalFacets);
      facetsRef.current = finalFacets;
    }
  }, [editor, shape.id, shape.type, finalFacets, autoSave]);

  // To save facets manually
  const onSaveFacets = useValue(
    "onSaveFacets",
    () => {
      return () => {
        if (!isEqual(facetsRef.current, finalFacets)) {
          updateFacets(editor, shape, finalFacets);
          facetsRef.current = finalFacets;
        }
      };
    },
    [editor, shape, finalFacets],
  );

  // To reset the facets to the original state
  const onCancelFacets = useValue(
    "onCancelFacets",
    () => {
      return () => {
        setFinalFacets(facets);
      };
    },
    [facets],
  );

  // To reset to empty facets
  const onResetFacets = useValue(
    "onResetFacets",
    () => {
      return () => {
        setFinalFacets({} as TFacets);
      };
    },
    [],
  );

  // To clear specific field related facet values from final facets
  const onClearSelectedFacetValues = useValue(
    "onClearFieldFacetValues",
    () => {
      return (clearFields: Array<keyof TFacets>) => {
        const newFacets: typeof finalFacets = { ...finalFacets };
        clearFields.forEach((field) => {
          const fieldConfig = fieldsConfig.find((config) => config.field === field);
          if (fieldConfig) {
            if (fieldConfig.type === AnalysisFieldTypeEnum.Date) {
              newFacets[field] = {} as TFacets[typeof field];
              newFacets[`${String(field)}Shortcut` as keyof TFacets] = "" as TFacets[typeof field];
            } else if (
              fieldConfig.type === AnalysisFieldTypeEnum.ArrayOfStrings ||
              fieldConfig.type === AnalysisFieldTypeEnum.String
            ) {
              newFacets[field] = [] as TFacets[typeof field];
            }
          }
        });

        if (!isEqual(newFacets, finalFacets)) {
          setFinalFacets(newFacets);
        }
      };
    },
    [fieldsConfig, finalFacets],
  );

  useEffect(() => {
    setFacetsChangeOptions({
      onFieldChange,
      onSaveFacets,
      onCancelFacets,
      onClearSelectedFacetValues,
      onResetFacets,
      finalFacets,
    });
  }, [onFieldChange, onSaveFacets, onCancelFacets, finalFacets]);

  return facetsChangeOptions;
}
