import { Editor, HIT_TEST_MARGIN, TLShape } from "@tldraw/tldraw";

/**
 * Cloned from https://github.com/tldraw/tldraw/blob/main/packages/tldraw/src/lib/tools/selection-logic/getHitShapeOnCanvasPointerDown.ts
 * with minor modifications related to version compatibility.
 */
export function getHitShapeOnCanvasPointerDown(editor: Editor): TLShape | undefined {
  const zoomLevel = editor.getZoomLevel();
  const {
    inputs: { currentPagePoint },
  } = editor;

  return (
    // hovered shape at point
    editor.getShapeAtPoint(currentPagePoint, {
      hitInside: false,
      hitLabels: false,
      margin: HIT_TEST_MARGIN / zoomLevel,
      renderingOnly: true,
    }) ??
    // selected shape at point
    editor.getSelectedShapeAtPoint(currentPagePoint)
  );
}
