import { ListItem, ListItemProps, ListItemIcon, ListItemButton, ListItemText } from "@mui/material";
import { useState } from "react";

import { DocumentIcon } from "Components/DocumentIcon/DocumentIcon";
import { DocumentPreviewDialog } from "Components/DocumentPreviewDialog/DocumentPreviewDialog";
import { DocumentsQuery } from "GraphQL/Generated/Apollo";

export interface IDocumentListItemProps {
  checked: boolean;
  listItemIcon?: React.ReactNode;
  onMouseEnter?: (event: React.MouseEvent<HTMLElement>) => void;
  onMouseLeave?: () => void;
  row: Omit<DocumentsQuery["documents"][0], "documentAccessControlList">;
  secondaryAction?: ListItemProps["secondaryAction"];
}

export function DocumentListItem(props: IDocumentListItemProps) {
  const { checked, listItemIcon, onMouseEnter, onMouseLeave, row, secondaryAction } = props;

  // State
  const [openPreview, setOpenPreview] = useState(false);

  return (
    <ListItem
      disablePadding
      key={row.id}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      secondaryAction={secondaryAction}
      sx={{
        borderRadius: 1,
        mt: 1,
        mb: 1,
        ":hover": {
          backgroundColor: "var(--astra-color-gray15)",
        },
      }}
    >
      <ListItemButton
        sx={{
          backgroundColor: checked ? "var(--astra-color-blue15)" : "var(--astra-color-gray7)",
          borderRadius: 1,
          cursor: "default",
        }}
        disableRipple
      >
        <ListItemIcon>{listItemIcon || <DocumentIcon />}</ListItemIcon>

        <ListItemText
          onClick={() => setOpenPreview(true)}
          primary={row.name}
          primaryTypographyProps={{ fontWeight: "500" }}
          sx={{ cursor: "pointer" }}
        />
      </ListItemButton>

      {openPreview && <DocumentPreviewDialog docId={row.id} onClose={() => setOpenPreview(false)} />}
    </ListItem>
  );
}
