import { Box } from "@mui/material";
import type { Reducer } from "@observablehq/plot";
import { useEffect, useRef } from "react";

import { getHistogramChartPlot } from "Components/Charting/Elements/HistogramChartPlot";

interface HistogramChartProps {
  direction: "horizontal" | "vertical";
  data: Array<Record<string, any>>;
  facets: { selectedValues?: Array<string> };
  metadata: any;
  xField: string;
  yField: string;
  binReducer?: Reducer | string | undefined;
  markOptions: any;
  onBrushSelection?: (e: MouseEvent, selectedValue: string) => void;
  onRectClick?: (e: MouseEvent, selectedValue: Record<string, any>, yField: string) => void;
}

export function HistogramChart(props: HistogramChartProps) {
  const { direction, data, facets, metadata, xField, yField, binReducer, markOptions, onBrushSelection, onRectClick } = props;
  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (chartRef.current && chartRef.current.innerHTML) {
      chartRef.current.innerHTML = "";
    }
    chartRef.current?.appendChild(_generateHistogramChart());
  }, [facets, metadata, data, markOptions]);

  return (
    <Box>
      <div ref={chartRef} className="histogram-chart"></div>
    </Box>
  );

  // *********************************************
  // Private functions
  // *********************************************/
  /**
   * Gives the histogram chart plot element
   * @param facets Latest facets
   * @returns
   */
  function _generateHistogramChart(): HTMLElement {
    const chart = getHistogramChartPlot(
      direction,
      data,
      metadata,
      xField,
      yField,
      binReducer,
      undefined,
      markOptions,
      onBrushSelection,
      onRectClick,
    );
    return chart as HTMLElement;
  }
}
