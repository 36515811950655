import { FacetDisplaySortType } from "@bigpi/cookbook";
import { T } from "@tldraw/validate";

export interface IAnalysisPreferences {
  facetSort?: Array<{
    field?: string;
    sort?: FacetDisplaySortType;
  }>;
}

export const analysisPreferencesFacetSortValidatable: T.Validatable<IAnalysisPreferences["facetSort"]> = T.arrayOf(
  T.object({
    field: T.string.optional(),
    sort: T.setEnum(new Set([FacetDisplaySortType.Alphabetical, FacetDisplaySortType.Count])).optional(),
  }),
).optional();

export const analysisPreferencesValidatable: T.Validatable<IAnalysisPreferences | undefined> = T.object({
  facetSort: analysisPreferencesFacetSortValidatable,
}).optional();
