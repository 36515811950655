import { AddOutlined, RemoveOutlined, ZoomOutOutlined } from "@mui/icons-material";
import { Box, Grid, IconButton } from "@mui/material";

import { WorkspaceFilesQuery } from "GraphQL/Generated/Apollo";
import { WorkspaceFileGridItem } from "./WorkspaceFileGridItem";
import { WorkspaceContentGridSize } from "./WorkspaceContentView";

export interface IWorkspaceFileGridProps {
  gridSize: WorkspaceContentGridSize;
  isSelectionEnabled: boolean;
  loading: boolean;
  onClick: (fileId: string) => void;
  onGridSizeChange: (gridSize: WorkspaceContentGridSize) => void;
  onSelectionChange: (file: WorkspaceFilesQuery["workspaceFiles"][number]) => void;
  rows: WorkspaceFilesQuery["workspaceFiles"];
  selectedIds: Array<string>;
}

export function WorkspaceFileGrid(props: IWorkspaceFileGridProps) {
  const { gridSize, isSelectionEnabled, loading, onClick, onGridSizeChange, onSelectionChange, rows, selectedIds } = props;

  const increaseGridSize = () => {
    const newGridSize = gridSize === "small" ? "medium" : gridSize === "medium" ? "large" : "large";
    onGridSizeChange(newGridSize);
  };

  const decreaseGridSize = () => {
    const newGridSize = gridSize === "large" ? "medium" : gridSize === "medium" ? "small" : "small";
    onGridSizeChange(newGridSize);
  };

  return (
    <>
      {!loading && rows.length > 0 && (
        <>
          <Grid
            component="nav"
            container
            spacing={gridSize === "small" ? 1 : gridSize === "medium" ? 2 : 3}
            alignItems="stretch"
            columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 24 }}
          >
            {rows.map((row) => {
              return (
                <WorkspaceFileGridItem
                  checked={selectedIds.includes(row.id)}
                  isSelectionEnabled={isSelectionEnabled}
                  key={row.id}
                  onClick={onClick}
                  onSelectionChange={() => onSelectionChange(row)}
                  size={gridSize}
                  workspaceFileDetails={row}
                />
              );
            })}
          </Grid>
          {rows.length > 0 && (
            <Box
              sx={{
                backgroundColor: "rgba(0,0,0,0.12)",
                border: "1px solid rgba(0,0,0,0.24)",
                display: "flex",
                alignItems: "center",
                position: "fixed",
                bottom: "2rem",
                left: "50%",
                transform: "translateX(-50%)",
                borderRadius: "2rem",
              }}
            >
              <IconButton color="primary" disabled={gridSize === "small"} onClick={decreaseGridSize}>
                <RemoveOutlined />
              </IconButton>
              <ZoomOutOutlined sx={{ color: "rgba(0,0,0,0.54)" }} />
              <IconButton color="primary" disabled={gridSize === "large"} onClick={increaseGridSize}>
                <AddOutlined />
              </IconButton>
            </Box>
          )}
        </>
      )}
    </>
  );
}
