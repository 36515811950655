import { CommandActionType } from "../CommandActionType.js";
import { CommandOutputType } from "../CommandOutputType.js";
import { ICommand } from "../ICommand.js";
import { DataType, EntityType } from "../ICommandInput.js";

export const COMMAND_GENERATE_THEME_DISCUSSION_ANALYSIS_TEMPLATE: ICommand = {
  id: "c1129559-c324-4e90-9a24-e8c48f4c0713",
  name: "Generate Theme Discussion Analysis",
  description: "",
  action: {
    actionType: CommandActionType.GenerateThemeDiscussionAnalysisAction,
    data: {},
  },
  inputs: [
    {
      dataType: {
        dataType: DataType.String,
        isArray: false,
        validation: "",
      },
      fieldName: "transcriptId",
      fieldNameAliases: [],
      entityType: EntityType.Transcript,
      defaultValue: "",
      label: "",
      visible: false,
      required: true,
    },
  ],
  inputTemplate: `{ "transcriptId": "{{ transcriptId }}" }`,
  isBlocking: false,
  outputTypes: [CommandOutputType.None],
};
