export enum LinecookTaskType {
  /**
   * A request to execute an asynchronous command.
   */
  CommandRequest = "CommandRequest",

  /**
   * A response to an asynchronous command request, either final or intermediate response.
   */
  CommandResponse = "CommandResponse",

  /**
   * Convert content library file to HTML.
   */
  ConvertFileToHtml = "ConvertFileToHtml",

  /**
   * Convert document to HTML.
   */
  ConvertToHtml = "ConvertToHtml",

  /**
   * Convert workspace file to HTML.
   */
  ConvertWorkspaceFileToHtml = "ConvertWorkspaceFileToHtml",

  /**
   * Generate a preview of a file.
   */
  GenerateFilePreview = "GenerateFilePreview",

  /**
   * Index documents (e.g. knowledge base article etc.) for search.
   */
  IndexDocumentForSearch = "IndexDocumentForSearch",

  /**
   * Index content library file for search.
   */
  IndexFileForSearch = "IndexFileForSearch",

  /**
   * Index workspace file for search.
   */
  IndexWorkspaceFileForSearch = "IndexWorkspaceFileForSearch",

  /**
   * Index workspace board content for search.
   */
  IndexWorkspaceBoardContentForSearch = "IndexWorkspaceBoardContentForSearch",

  /**
   * Ingest content library file HTML document.
   */
  IngestFileHtml = "IngestFileHtml",

  /**
   * Ingest an HTML document into Pantry.
   */
  IngestHtml = "IngestHtml",

  /**
   * Ingest workspace file HTML document.
   */
  IngestWorkspaceFileHtml = "IngestWorkspaceFileHtml",

  /**
   * Ingest a PDF file and index in Elasticsearch.
   */
  // TODO: 2023-11-17 (TB): Disabled due to pipeline not taking file permissions into account
  // IngestPdfFile = "IngestPdfFile",

  /**
   * Ingest a content from subscribed source into Elasticsearch.
   */
  IngestSubscriptionContent = "IngestSubscriptionContent",

  /**
   * Ingest a PressRelease document into Elasticsearch.
   */
  IngestPressRelease = "IngestPressRelease",

  /**
   * Ingest a Transcript document into Elasticsearch.
   */
  IngestTranscript = "IngestTranscript",
}
