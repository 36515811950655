import { gql } from "@apollo/client";

export const TOPIC_DISCUSSION_IN_NEWS_ARTICLE_QUERY = gql`
  query TopicDiscussionInNewsArticle($id: ID!) {
    topicDiscussionInNewsArticle(id: $id) {
      id
      theme
      topicDiscussions {
        topic
        summary
      }
      segmentId
      segmentType
      sourceType
      newSources {
        name
        url
      }
      date
      headline
      paragraph
    }
  }
`;

export const TOPIC_DISCUSSION_IN_NEWS_ARTICLES_QUERY = gql`
  query TopicDiscussionInNewsArticles($facets: TopicDiscussionInNewsArticleFacetsInput!) {
    topicDiscussionInNewsArticles(facets: $facets) {
      id
      theme
      topicDiscussions {
        topic
        summary
      }
      segmentId
      segmentType
      sourceType
      newSources {
        name
        url
      }
      date
      headline
      paragraph
      articleCount
    }
  }
`;
