export const PUBLIC_ORGANIZATION_ID = "00000000-0000-0000-0000-000000000000";
export const EVERYONE_USER_ID = "00000000-0000-0000-0000-000000000000";
export const AI_USER_ID = "11111111-1111-1111-1111-111111111111";
// NOTE! If the name is ever changed to including regex special characters, make sure to update the regex in the AI utils
export const AI_USER_FULL_NAME = "Astra Intelligence";
export const AI_USER_EMAIL = "astra-intelligence@";
export const LIBRARY_OWNERS_USER_GROUP_ID = "44444444-4444-4444-4444-444444444444";
export const DEFAULT_LIBRARY_OWNER_USER_GROUP_FULL_NAME = "Library Owners";
export const DOCUMENT_OWNERS_USER_GROUP_ID = "55555555-5555-5555-5555-555555555555";
export const DEFAULT_DOCUMENT_OWNER_USER_GROUP_FULL_NAME = "Document Owners";
