import { defineMigrations } from "@tldraw/store";

import { IDataGridPreferences } from "../DataGrid/index.js";
import { IQuestionAnalysisShape } from "./IQuestionAnalysisShape.js";
import { IQuestionAnalysisFacets } from "./IQuestionAnalysisFacets.js";

export const QuestionAnalysisShapeVersions = {
  Initial: 0,
  RemoveDataGridPreferencesColumnVisibility: 1,
  AddPreferencesFacetSortModel: 2,
  AddStandardisedFacetsAndBounds: 3,
  AddSelection: 4,
};

export const questionAnalysisShapeMigrations = defineMigrations({
  firstVersion: QuestionAnalysisShapeVersions.Initial,
  currentVersion: QuestionAnalysisShapeVersions.AddSelection,
  migrators: {
    [QuestionAnalysisShapeVersions.RemoveDataGridPreferencesColumnVisibility]: {
      up: (shape: IQuestionAnalysisShape & { preferences: { dataGrid: { columnVisibility: Record<string, boolean> } } }) => {
        const { dataGrid, ...rest } = shape.props.preferences;
        const dataGridPreferences = dataGrid as IDataGridPreferences & { columnVisibility?: Record<string, boolean> };
        if (dataGridPreferences) {
          delete dataGridPreferences.columnVisibility;
        }

        return {
          ...shape,
          props: {
            ...shape.props,
            preferences: {
              ...shape.props.preferences,
              dataGrid: dataGridPreferences,
            },
          },
        };
      },
      down: (shape: IQuestionAnalysisShape) => {
        return {
          ...shape,
          props: {
            ...shape.props,
            preferences: {
              ...shape.props.preferences,
              dataGrid: {
                ...shape.props.preferences.dataGrid,
                columnVisibility: {},
              },
            },
          },
        };
      },
    },
    [QuestionAnalysisShapeVersions.AddPreferencesFacetSortModel]: {
      up: (shape: IQuestionAnalysisShape) => {
        const { preferences, ...rest } = shape.props;
        const analysisPreferences = preferences.analysis || {};
        analysisPreferences.facetSort = [];
        return {
          ...shape,
          props: {
            ...rest,
            preferences: {
              ...preferences,
              analysis: analysisPreferences,
            },
          },
        };
      },
      down: (shape: IQuestionAnalysisShape) => {
        const { preferences, ...rest } = shape.props;
        delete preferences.analysis;
        return {
          ...shape,
          props: {
            ...rest,
            preferences,
          },
        };
      },
    },
    [QuestionAnalysisShapeVersions.AddStandardisedFacetsAndBounds]: {
      up: (
        shape: IQuestionAnalysisShape & {
          props: {
            facets: {
              eventDate?: { from?: string; to?: string };
              selectedFirms?: Array<string>;
              selectedFirmTypes?: Array<string>;
              selectedSegments?: Array<string>;
              selectedTopics?: Array<string>;
            };
          };
        },
      ) => {
        const { facets, ...rest } = shape.props;

        let selectedFacetValues: IQuestionAnalysisFacets = {};
        let boundsFacetValues = {};

        if (facets) {
          if (facets.eventDate) {
            selectedFacetValues.eventDate = facets.eventDate;
          }

          if (facets.selectedFirms) {
            selectedFacetValues.firm = facets.selectedFirms;
          }

          if (facets.selectedFirmTypes) {
            selectedFacetValues.firmType = facets.selectedFirmTypes;
          }

          if (facets.selectedSegments) {
            selectedFacetValues.segment = facets.selectedSegments;
          }

          if (facets.selectedTopics) {
            selectedFacetValues.topics = facets.selectedTopics;
          }
        }

        return {
          ...shape,
          props: {
            ...rest,
            selectedFacetValues,
            boundsFacetValues,
            toolbar: {
              availableFields: ["eventDate", "firm", "firmType", "segment", "topics"],
            },
          },
        };
      },
      down: (shape: IQuestionAnalysisShape) => {
        const { boundsFacetValues, preferences, selectedBoundsFields, selectedFacetValues, toolbar, ...rest } = shape.props;

        let facets: {
          eventDate?: { from?: string; to?: string };
          selectedFirms?: Array<string>;
          selectedFirmTypes?: Array<string>;
          selectedSegments?: Array<string>;
          selectedTopics?: Array<string>;
        } = {};

        // Reverse the latest selectedFacetValues to old facets
        if (selectedFacetValues) {
          if (selectedFacetValues.eventDate) {
            facets.eventDate = selectedFacetValues.eventDate;
          }

          if (selectedFacetValues.topics) {
            facets.selectedTopics = selectedFacetValues.topics;
          }

          if (selectedFacetValues.firm) {
            facets.selectedFirms = selectedFacetValues.firm;
          }

          if (selectedFacetValues.firmType) {
            facets.selectedFirmTypes = selectedFacetValues.firmType;
          }

          if (selectedFacetValues.segment) {
            facets.selectedSegments = selectedFacetValues.segment;
          }
        }

        if (preferences && preferences.analysis && preferences.analysis.showDataToDisplayInToolbar !== undefined) {
          delete preferences.analysis.showDataToDisplayInToolbar;
        }

        return {
          ...shape,
          props: {
            ...rest,
            facets,
            preferences,
          },
        };
      },
    },
    [QuestionAnalysisShapeVersions.AddSelection]: {
      up: (shape: IQuestionAnalysisShape) => {
        return shape;
      },
      down: (shape: IQuestionAnalysisShape) => {
        const { selection, ...rest } = shape.props;
        return {
          ...shape,
          props: {
            ...rest,
          },
        };
      },
    },
  },
});
