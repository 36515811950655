import { CommandOutputType } from "@bigpi/cookbook";
import Addchart from "@mui/icons-material/Addchart";
import NewspaperAdd from "@mui/icons-material/Newspaper";
import PostAdd from "@mui/icons-material/PostAdd";
import Search from "@mui/icons-material/Search";
import Summarize from "@mui/icons-material/Summarize";
import Compare from "@mui/icons-material/Compare";
import Feed from "@mui/icons-material/Feed";
import TableChart from "@mui/icons-material/TableChart";

/**
 * Gives the material icon based on the command output type
 *
 * @param outputType Command output type
 * @returns Material Icon based on the output type
 */
export function getOutputTypeIcon(outputType: CommandOutputType, otherSx?: Record<string, any>) {
  const sx = { color: "text.secondary", ...otherSx };

  switch (outputType) {
    case CommandOutputType.AnalystQuestionAnalysis:
      return <Addchart sx={sx} />;
    case CommandOutputType.Feed:
      return <Feed sx={sx} />;
    case CommandOutputType.HeadlineSimulator:
      return <NewspaperAdd sx={sx} />;
    case CommandOutputType.Html:
      return <PostAdd sx={sx} />;
    case CommandOutputType.QuestionAnalysis:
      return <TableChart sx={sx} />;
    case CommandOutputType.SearchResults:
      return <Search sx={sx} />;
    case CommandOutputType.TopicDiscussionAnalysis:
      return <Addchart sx={sx} />;
    case CommandOutputType.TopicDiscussionSummaryAnalysis:
      return <Addchart sx={sx} />;
    case CommandOutputType.TopicDiscussionInNewsArticleAnalysis:
      return <Addchart sx={sx} />;
    case CommandOutputType.Table:
      return <TableChart sx={sx} />;
    case CommandOutputType.Summarize:
      return <Summarize sx={sx} />;
    case CommandOutputType.Compare:
      return <Compare sx={sx} />;
    default:
      return <></>;
  }
}
