import { TypePlaceholder } from "@bigpi/editor-tiptap";
import { TFunction } from "i18next";

export function getPlaceholderFn(t: TFunction): TypePlaceholder {
  return ({ node }) => {
    if (node.type.name === "detailsSummary") {
      return t("Editor.CollapsibleBlock.SummaryPlaceholder");
    }

    return "";
  };
}
