import { Config } from "Config";

/**
 * Get the URL to download the workspace file.
 *
 * @param workspaceId The workspace id.
 * @param workspaceFileId The workspace file id.
 * @param filename The file name.
 *
 * @returns The URL to download the workspace file.
 */
export function getWorkspaceFileDownloadUrl(workspaceId: string, workspaceFileId: string, filename: string): string {
  return `${Config.assetHttpUrl}/workspace-file/workspaces/${workspaceId}/files/${workspaceFileId}/${filename}`;
}

/**
 * Get the URL to download the workspace file preview.
 *
 * @param organizationId The organization id.
 * @param workspaceId The workspace id.
 * @param workspaceFileId The workspace file id.
 * @param fileName File name to download.
 *
 * @returns File download URL.
 */
export function getWorkspaceFilePreviewDownloadUrl(
  organizationId: string,
  workspaceId: string,
  workspaceFileId: string,
  fileName: string,
) {
  return `${Config.previewHttpUrl}/workspace-file/preview/${organizationId}/workspaces/${workspaceId}/files/${workspaceFileId}/${fileName}`;
}
