import { gql } from "@apollo/client";

export const USER_GROUP_MEMBER_QUERY = gql`
  query UserGroupMember($id: ID, $userGroupId: ID, $userId: ID) {
    userGroupMember(id: $id, userGroupId: $userGroupId, userId: $userId) {
      id
      userGroup {
        id
        name
      }
      user {
        id
        name
      }
    }
  }
`;

export const USER_GROUP_MEMBERS_QUERY = gql`
  query UserGroupMembers($userGroupId: ID, $userId: ID, $offset: Int, $limit: Int, $orderBy: UserGroupMemberOrderBy) {
    userGroupMembers(userGroupId: $userGroupId, userId: $userId, offset: $offset, limit: $limit, orderBy: $orderBy) {
      id
      userGroup {
        id
        name
      }
      user {
        id
        name
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
    userGroupMemberAggregate(userGroupId: $userGroupId, userId: $userId) {
      count
    }
  }
`;
