import * as d3 from "d3";

export const IMMUTABLE_EMPTY_OBJECT = Object.freeze({});
export const IMMUTABLE_EMPTY_ARRAY = Object.freeze([]);

/**
 * Utility methods for working and manipulating data.
 */
export class DataUtils {
  /**
   * Gets an immutable empty object that can be used as a default value that avoids unnecessary re-renders.
   *
   * @returns An immutable empty object.
   */
  public static getImmutableEmptyObject<T>(): T {
    return IMMUTABLE_EMPTY_OBJECT as unknown as T;
  }

  /**
   * Gets an immutable empty array that can be used as a default value that avoids unnecessary re-renders.
   *
   * @returns An immutable empty array.
   */
  public static getImmutableEmptyArray<T>(): Array<T> {
    return IMMUTABLE_EMPTY_ARRAY as unknown as Array<T>;
  }

  /**
   * Just idea: we can also use d3 library
   * We can get that when there are more usecases like this
   */
  /**
   * Group data by given field.
   *
   * @param data Array of objects.
   * @param field Grouping field.
   *
   * @returns
   */
  public static groupData(data: Array<Record<string, any>>, field: string) {
    const groupedItems: Record<string, any> = {};
    data.forEach((dataItem) => {
      const value = dataItem[field];
      if (groupedItems[value]) {
        groupedItems[value] = [...groupedItems[value], dataItem];
      } else {
        groupedItems[value] = [dataItem];
      }
    });
    return groupedItems;
  }

  /**
   * Returns grouped data by given key. Ex: 'test': [{}, {}]
   *
   * @param data Data array.
   * @param key String key.
   *
   * @returns Data grouped by given key.
   */
  public static getGroupedItems<T>(data: Array<T>, key?: keyof T) {
    return d3.group(data, (item: any) => (key ? item[key] : item));
  }

  /**
   * Returns keys of the grouped data. Ex: ['test', 'test2']
   *
   * @param data Data array.
   * @param key String key.
   *
   * @returns The keys of the grouped data.
   */
  public static getGroupedKeys<T>(data: Array<T>, key?: keyof T): Array<string> {
    return Array.from(DataUtils.getGroupedItems<T>(data, key).keys());
  }

  /**
   * Gets the minimum and maximum date from the given generic array of data objects.
   *
   * @param data The array of data to search.
   * @param key The key (property name) on the data objects to treat as a date.
   *
   * @returns An object with the minimum and maximum dates as ISO strings.
   */
  public static getDateRange<T>(data: Array<T>, key: keyof T) {
    const dates = data.map((item) => new Date(item[key] as any));
    if (dates.length > 0) {
      return {
        min: d3.min(dates)?.toISOString(),
        max: d3.max(dates)?.toISOString(),
      };
    }
    return {
      min: "",
      max: "",
    };
  }

  /**
   * Gives the unique values of the given key in the given data array.
   *
   * @param data Data array
   * @param key Key of the data
   * @returns
   */
  public static getUniqueValues<T>(data: Array<T>, key: keyof T) {
    const groupedValues = DataUtils.getGroupedItems(data, key);
    return Array.from(groupedValues.values()).map((value) => value[0]);
  }
}
