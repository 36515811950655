import { Fade, Paper } from "@mui/material";
import { Box, TLShapeId, useValue } from "@tldraw/tldraw";
import { useCallback, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import Draggable, { DraggableData, DraggableEvent, DraggableEventHandler } from "react-draggable";

import { useCollaborativeBoardEditor } from "TldrawBoard/useCollaborativeBoardEditor";
import "./CompanionWindow.css";

// *********************************************
// Public constants
// *********************************************/
export const DEFAULT_WIDTH = 1000;
export const DEFAULT_HEIGHT = 600;
export const MAX_HEIGHT = 1056;
export const COMPANION_OVERLAY_ITEM_CLASS = "companion-overlay__item";

const MARGIN_X = 10;
const OVERLAY_PARENT_QUERY_SELECTOR = ".tl-overlays .tl-html-layer";

export interface ICompanionProps {
  children: React.ReactNode;
  isVisible: boolean;
  parentShapeId: TLShapeId;
  relativePosition?: { x: number; y: number };
}

export const CompanionWindow = (props: ICompanionProps) => {
  const { children, isVisible, parentShapeId, relativePosition } = props;
  const editor = useCollaborativeBoardEditor();
  const parentShapeBounds = useValue(
    "parentShapeBounds",
    () => {
      let result = new Box(0, 0, 0, 0);
      if (parentShapeId) {
        const shapeBounds = editor?.getShapePageBounds(parentShapeId);
        if (shapeBounds) {
          result = shapeBounds;
        }
      }
      return result;
    },
    [editor, parentShapeId],
  );

  // Current zoom level. Used to adjust dragging to match zoom level
  const zoomLevel = useValue("zoom", () => editor?.getZoomLevel(), [editor]);

  // The delta between the parent shape and the window
  const [windowDelta, setWindowDelta] = useState<Record<string, { x: number; y: number }>>(
    relativePosition ? { [parentShapeId]: relativePosition } : {},
  );

  // Reference to the window element
  const companionWindowRef = useRef<HTMLDivElement | null>(null);

  // The parent element to render the overlay into
  const overlayParent = document.querySelector(OVERLAY_PARENT_QUERY_SELECTOR);

  // Make sure we have an initial position
  useEffect(() => {
    if (parentShapeId) {
      if (!windowDelta[parentShapeId]) {
        setWindowDelta({
          ...windowDelta,
          [parentShapeId]: { x: parentShapeBounds.width + MARGIN_X, y: 0 },
        });
      }
    }
  }, [parentShapeId]);

  useEffect(() => {
    if (relativePosition) {
      setWindowDelta({
        ...windowDelta,
        [parentShapeId]: relativePosition,
      });
    }
  }, [relativePosition]);

  // Update the state when the window is dragged
  const onDrag: DraggableEventHandler = useCallback(
    (e: DraggableEvent, position: DraggableData) => {
      // Update delta from parent shape
      if (parentShapeId) {
        setWindowDelta({
          ...windowDelta,
          [parentShapeId]: { x: position.x - parentShapeBounds.x, y: position.y - parentShapeBounds.y },
        });
      }
    },
    [parentShapeBounds],
  );

  let delta = { x: 0, y: 0 };
  if (parentShapeId && windowDelta[parentShapeId]) {
    delta = windowDelta[parentShapeId];
  }
  const newPosition = {
    x: parentShapeBounds.x + delta.x,
    y: parentShapeBounds.y + delta.y,
  };

  useEffect(() => {
    const handleWheel = (event: WheelEvent) => {
      // Check if ctrl key is pressed (or simulated pressed. This is the case for pinch-to-zoom on touchpads), i.e. zooming
      if (event.ctrlKey) {
        event.preventDefault();
      }
    };

    if (companionWindowRef.current) {
      const companionWindowRefCurrent = companionWindowRef.current;
      companionWindowRefCurrent.addEventListener("wheel", handleWheel, { passive: false });
      return () => {
        companionWindowRefCurrent.removeEventListener("wheel", handleWheel);
      };
    }
  }, [companionWindowRef]);

  return (
    <>
      {overlayParent &&
        createPortal(
          <Draggable
            defaultClassName={`tl-overlays__item ${COMPANION_OVERLAY_ITEM_CLASS}`}
            data-testid="companion-overlay"
            handle="#companion-title"
            onDrag={onDrag}
            nodeRef={companionWindowRef}
            position={newPosition}
            scale={zoomLevel}
          >
            <Fade in={isVisible}>
              <Paper
                ref={companionWindowRef}
                elevation={24}
                square
                style={{
                  display: "flex",
                  flexDirection: "column",
                  maxHeight: MAX_HEIGHT,
                  width: DEFAULT_WIDTH,
                  position: "absolute",
                }}
              >
                {children}
              </Paper>
            </Fade>
          </Draggable>,
          overlayParent,
        )}
    </>
  );
};
