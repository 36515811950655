import { SelectableText } from "@bigpi/cutlery";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import { Box, IconButton } from "@mui/material";
import { useState } from "react";

import { TranscriptItemView } from "./TranscriptItemView";
import { DistilledTranscriptSegment } from "GraphQL/Generated/Apollo";

export interface IDistilledTranscriptSessionProps {
  title: string;
  distilledSegments?: Array<DistilledTranscriptSegment>;
}

export function DistilledTranscriptSession(props: IDistilledTranscriptSessionProps) {
  const { title, distilledSegments } = props;

  const [expandedSegmentIds, setExpandedSegmentIds] = useState<Array<number>>([]);
  const [hoveredSegmentId, setHoveredSegmentId] = useState<number | null>(null);

  if (!distilledSegments || distilledSegments.length === 0) {
    return <></>;
  }

  const expandTranscriptSegment = (segmentIndex: number) => {
    if (expandedSegmentIds.includes(segmentIndex)) {
      return;
    }

    setExpandedSegmentIds([...expandedSegmentIds, segmentIndex]);
  };

  const collapseTranscriptSegment = (segmentIndex: number) => {
    setExpandedSegmentIds(expandedSegmentIds.filter((id) => id !== segmentIndex));
  };

  return (
    <>
      <SelectableText variant="h6" mt={2} mb={1}>
        {title}
      </SelectableText>
      {distilledSegments.map((distilledSegment) => {
        const isSegmentExpanded = expandedSegmentIds.includes(distilledSegment.segmentIndex);

        return (
          <Box
            key={distilledSegment.segmentIndex}
            sx={{
              border: "2px dashed transparent",
              ":hover": {
                borderColor: "black",
              },
              p: 1,
              pt: 0,
              backgroundColor: isSegmentExpanded ? "var(--astra-color-gray20)" : "transparent",
            }}
            onMouseOver={() => {
              setHoveredSegmentId(distilledSegment.segmentIndex);
            }}
            onMouseOut={() => {
              setHoveredSegmentId(null);
            }}
          >
            {hoveredSegmentId === distilledSegment.segmentIndex && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {isSegmentExpanded ? (
                  <IconButton
                    sx={{ color: "primary.main", position: "static", mb: -6 }}
                    disableRipple
                    onClick={() => collapseTranscriptSegment(distilledSegment.segmentIndex)}
                  >
                    <CloseFullscreenIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    sx={{ position: "static", mb: -6 }}
                    disableRipple
                    onClick={() => expandTranscriptSegment(distilledSegment.segmentIndex)}
                  >
                    <OpenInFullIcon />
                  </IconButton>
                )}
              </Box>
            )}
            {isSegmentExpanded
              ? distilledSegment.originalTranscriptItems.map((originalTranscriptItem) => {
                  return <TranscriptItemView key={originalTranscriptItem.itemIndex} transcriptItem={originalTranscriptItem} />;
                })
              : distilledSegment.distilledTranscriptItems.map((distilledTranscriptItem) => {
                  return <TranscriptItemView key={distilledTranscriptItem.itemIndex} transcriptItem={distilledTranscriptItem} />;
                })}
          </Box>
        );
      })}
    </>
  );
}
