import { gql } from "@apollo/client";

export const LATEST_CONFIG_DATA_BY_PREFIX = gql`
  query getLatestConfigsByPrefix($keyPrefix: String!, $organizationId: ID!) {
    LatestConfigsByPrefix(keyPrefix: $keyPrefix, organizationId: $organizationId) {
      data
    }
  }
`;

export const GET_CONFIG_DATA = gql`
  query getConfigData($key: ID!, $organizationId: ID!) {
    Config(key: $key, organizationId: $organizationId) {
      data
    }
  }
`;

export const GET_APP_CONFIG_DATA = gql`
  query getAppConfigData(
    $organizationId: ID!
    $baseDocumentStylesheetKey: ID!
    $organizationDocumentStylesheetKey: ID!
    $organizationPreferencesKey: ID!
  ) {
    BaseDocumentStylesheet: Config(key: $baseDocumentStylesheetKey, organizationId: $organizationId) {
      data
    }
    OrganizationDocumentStylesheet: Config(key: $organizationDocumentStylesheetKey, organizationId: $organizationId) {
      data
    }
    OrganizationPreferences: Config(key: $organizationPreferencesKey, organizationId: $organizationId) {
      data
    }
  }
`;
