import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: { input: any; output: any; }
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any; }
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: { input: any; output: any; }
  Tag: { input: any; output: any; }
  /** A field whose value is a generic Universally Unique Identifier: https://en.wikipedia.org/wiki/Universally_unique_identifier. */
  UUID: { input: any; output: any; }
  /** The `Upload` scalar type represents a file upload. */
  Upload: { input: any; output: any; }
};

export type AnalysisFeedback = {
  __typename?: 'AnalysisFeedback';
  createdAt: Scalars['Date']['output'];
  createdBy: Scalars['ID']['output'];
  dataItemId: Scalars['String']['output'];
  dataItemType: Scalars['String']['output'];
  feedbackContent: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isHidden: Scalars['Boolean']['output'];
  updatedAt: Scalars['Date']['output'];
  updatedBy: Scalars['ID']['output'];
};

export type AnalysisResultOverrideType = {
  bodyStyleMatch?: InputMaybe<ScoreItemOverrideType>;
  disabledPlugIns?: InputMaybe<Array<Scalars['String']['input']>>;
  h1StyleMatch?: InputMaybe<ScoreItemOverrideType>;
  h2StyleMatch?: InputMaybe<ScoreItemOverrideType>;
  h3StyleMatch?: InputMaybe<ScoreItemOverrideType>;
  h4StyleMatch?: InputMaybe<ScoreItemOverrideType>;
  h5StyleMatch?: InputMaybe<ScoreItemOverrideType>;
  h6StyleMatch?: InputMaybe<ScoreItemOverrideType>;
  hierarchy?: InputMaybe<HierarchyResultType>;
  indent?: InputMaybe<IndentResultType>;
  titleStyleMatch?: InputMaybe<ScoreItemOverrideType>;
};

export type AnalystQuestion = {
  __typename?: 'AnalystQuestion';
  analystQuestionClusterAssignment: Array<AnalystQuestionClusterAssignment>;
  createdAt: Scalars['Date']['output'];
  createdBy: Scalars['ID']['output'];
  eventDate: Scalars['Date']['output'];
  firm: Scalars['String']['output'];
  firmType: Scalars['String']['output'];
  /** Group */
  group?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isHidden: Scalars['Boolean']['output'];
  question: Scalars['String']['output'];
  segment: Array<Scalars['String']['output']>;
  topics: Array<Scalars['String']['output']>;
  updatedAt: Scalars['Date']['output'];
  updatedBy: Scalars['ID']['output'];
};

export type AnalystQuestionAggregate = {
  __typename?: 'AnalystQuestionAggregate';
  max?: Maybe<Scalars['Date']['output']>;
  min?: Maybe<Scalars['Date']['output']>;
};

export type AnalystQuestionArrayFieldAggregate = {
  __typename?: 'AnalystQuestionArrayFieldAggregate';
  segment?: Maybe<Array<Scalars['String']['output']>>;
  topics?: Maybe<Array<Scalars['String']['output']>>;
};

export type AnalystQuestionArrayFieldGroupType = {
  __typename?: 'AnalystQuestionArrayFieldGroupType';
  count: Scalars['Int']['output'];
  segment?: Maybe<Scalars['String']['output']>;
  topics?: Maybe<Scalars['String']['output']>;
};

export type AnalystQuestionCluster = {
  __typename?: 'AnalystQuestionCluster';
  createdAt: Scalars['Date']['output'];
  createdBy: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  idealizedQuestion: Scalars['String']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
  updatedBy: Scalars['ID']['output'];
};

export type AnalystQuestionClusterAssignment = {
  __typename?: 'AnalystQuestionClusterAssignment';
  analystQuestion: AnalystQuestion;
  analystQuestionCluster: AnalystQuestionCluster;
  analystQuestionClusterId: Scalars['ID']['output'];
  analystQuestionId: Scalars['ID']['output'];
  createdAt: Scalars['Date']['output'];
  createdBy: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  score: Scalars['Int']['output'];
  updatedAt: Scalars['Date']['output'];
  updatedBy: Scalars['ID']['output'];
};

export type AnalystQuestionFacetsInput = {
  eventDate?: InputMaybe<DateRangeInput>;
  firm?: InputMaybe<Array<Scalars['String']['input']>>;
  firmType?: InputMaybe<Array<Scalars['String']['input']>>;
  segment?: InputMaybe<Array<Scalars['String']['input']>>;
  topics?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type AnalystQuestionFieldGroupType = {
  __typename?: 'AnalystQuestionFieldGroupType';
  count: Scalars['Int']['output'];
  firm?: Maybe<Scalars['String']['output']>;
  firmType?: Maybe<Scalars['String']['output']>;
};

export type BulkDeleteBundleInput = {
  ids: Array<Scalars['ID']['input']>;
};

export type BulkDeleteDocumentInput = {
  ids: Array<Scalars['ID']['input']>;
};

export type BulkDeleteFileInput = {
  ids: Array<Scalars['ID']['input']>;
};

export type BulkDeleteResult = {
  __typename?: 'BulkDeleteResult';
  count: Scalars['Int']['output'];
};

export type BulkUpdateResult = {
  __typename?: 'BulkUpdateResult';
  count: Scalars['Int']['output'];
};

export type Bundle = {
  __typename?: 'Bundle';
  bundleFile: Array<BundleFile>;
  createdAt: Scalars['Date']['output'];
  createdBy: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
  updatedBy: Scalars['ID']['output'];
};

export type BundleFile = {
  __typename?: 'BundleFile';
  bundle: Bundle;
  bundleId: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  createdBy: Scalars['ID']['output'];
  file: File;
  fileId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['Date']['output'];
  updatedBy: Scalars['ID']['output'];
};

export enum BundleType {
  /** Input */
  Input = 'Input',
  /** Output */
  Output = 'Output'
}

export type CancelCommandInput = {
  id: Scalars['ID']['input'];
};

export type ChatCommandContext = {
  application: Scalars['JSONObject']['input'];
  organization: Scalars['JSONObject']['input'];
  selection: Scalars['JSONObject']['input'];
  session: Array<Scalars['JSONObject']['input']>;
  user: Scalars['JSONObject']['input'];
};

export type ChatMessage = {
  __typename?: 'ChatMessage';
  attachments: Array<ChatMessageAttachment>;
  channelId: Scalars['ID']['output'];
  channelType: ChatMessageChannelType;
  childCount: Scalars['Int']['output'];
  children?: Maybe<Array<ChatMessage>>;
  createdAt: Scalars['Date']['output'];
  createdBy: Scalars['ID']['output'];
  createdByUser?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Date']['output']>;
  deletedBy?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  mentions: Array<ChatMessageMention>;
  message: Scalars['String']['output'];
  messageType: ChatMessageType;
  metadata?: Maybe<Scalars['JSONObject']['output']>;
  parent?: Maybe<ChatMessage>;
  parentId?: Maybe<Scalars['ID']['output']>;
  pinned: Scalars['Boolean']['output'];
  pinnedAt?: Maybe<Scalars['Date']['output']>;
  pinnedBy?: Maybe<Scalars['ID']['output']>;
  privateRecipientIds?: Maybe<Array<Scalars['ID']['output']>>;
  reactions: Array<ChatMessageReaction>;
  updateCount: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['ID']['output']>;
};

export type ChatMessageAttachment = {
  __typename?: 'ChatMessageAttachment';
  mimeType: Scalars['String']['output'];
  name: Scalars['String']['output'];
  storageLocationUrl: Scalars['String']['output'];
};

export enum ChatMessageChannelType {
  /** A generic chat channel */
  Channel = 'Channel',
  /** A direct message channel */
  Direct = 'Direct',
  /** Workspace linked chat */
  Workspace = 'Workspace'
}

export type ChatMessageMention = {
  __typename?: 'ChatMessageMention';
  mentionType: ChatMessageMentionType;
  name: Scalars['String']['output'];
  targetId: Scalars['String']['output'];
};

export enum ChatMessageMentionType {
  /** A bot was mentioned */
  Bot = 'Bot',
  /** A channel was mentioned */
  Channel = 'Channel',
  /** A dynamic mention, such as @here or @everyone */
  Dynamic = 'Dynamic',
  /** A specific user was mentioned */
  User = 'User'
}

export type ChatMessageReaction = {
  __typename?: 'ChatMessageReaction';
  reaction: Scalars['String']['output'];
  userIds: Array<Scalars['ID']['output']>;
};

export enum ChatMessageType {
  /** Message originated from an AI bot */
  Ai = 'Ai',
  /** Message originated from a function */
  Function = 'Function',
  /** Message originated from a human */
  Human = 'Human',
  /** Message originated from a system process */
  System = 'System',
  /** Message originated from a tool */
  Tool = 'Tool'
}

export type CommandContext = {
  application: Scalars['JSON']['input'];
  organization: Scalars['JSON']['input'];
  selection: Scalars['JSON']['input'];
  session: Array<Scalars['JSON']['input']>;
  user: Scalars['JSON']['input'];
};

export type CommandQueueStatus = {
  __typename?: 'CommandQueueStatus';
  commandId: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  createdBy: Scalars['UUID']['output'];
  deduplicationId?: Maybe<Scalars['String']['output']>;
  organizationId: Scalars['UUID']['output'];
  queueTtl?: Maybe<Scalars['Int']['output']>;
  requestId: Scalars['UUID']['output'];
  sessionId: Scalars['UUID']['output'];
  status: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
};

export type CommandResponse = {
  __typename?: 'CommandResponse';
  activeCommandContext: Scalars['JSON']['output'];
  callerData?: Maybe<Scalars['String']['output']>;
  data?: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  requestId: Scalars['UUID']['output'];
  sessionId: Scalars['UUID']['output'];
  status: Scalars['String']['output'];
};

export type ConfigsAggregate = {
  __typename?: 'ConfigsAggregate';
  count: Scalars['Int']['output'];
};

export type ConfigsOrderBy = {
  key?: InputMaybe<SortOrder>;
  mimeType?: InputMaybe<SortOrder>;
  organizationId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CopyShapeDataInput = {
  datastoreId: Scalars['ID']['input'];
  shapeId: Scalars['String']['input'];
  workspaceBoardId: Scalars['String']['input'];
};

export type CopyWorkspaceInput = {
  copyBoardPermissions: Scalars['Boolean']['input'];
  copyFiles: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  workspaceBoard: CopyWorkspaceWorkspaceBoardSubInput;
  workspaceId: Scalars['ID']['input'];
};

export type CopyWorkspaceWorkspaceBoardSubInput = {
  name: Scalars['String']['input'];
};

export type CreateAnalysisFeedbackInput = {
  dataItemId: Scalars['String']['input'];
  dataItemType: Scalars['String']['input'];
  feedbackContent: Scalars['String']['input'];
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateAnalystQuestionClusterAssignmentInput = {
  analystQuestionClusterId: Scalars['ID']['input'];
  analystQuestionId: Scalars['ID']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  score: Scalars['Int']['input'];
  version: Scalars['String']['input'];
};

export type CreateAnalystQuestionClusterInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  idealizedQuestion: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateAnalystQuestionInput = {
  eventDate: Scalars['Date']['input'];
  firm: Scalars['String']['input'];
  firmType: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  question: Scalars['String']['input'];
  segment: Array<Scalars['String']['input']>;
  topics: Array<Scalars['String']['input']>;
};

export type CreateBundleFileInput = {
  bundleId: Scalars['String']['input'];
  fileId: Scalars['String']['input'];
};

export type CreateBundleInput = {
  name: Scalars['String']['input'];
  type: BundleType;
};

export type CreateChatMessageInput = {
  channelId: Scalars['ID']['input'];
  channelType: ChatMessageChannelType;
  commandContext?: InputMaybe<ChatCommandContext>;
  message: Scalars['String']['input'];
  metadata?: InputMaybe<Scalars['JSONObject']['input']>;
  parentId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateDocumentAccessControlListInput = {
  documentId: Scalars['ID']['input'];
  role: ObjectRole;
  userGroupId?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateDocumentChangeLogInput = {
  description: Scalars['String']['input'];
  documentId: Scalars['ID']['input'];
  fromSnapshotId?: InputMaybe<Scalars['ID']['input']>;
  toSnapshotId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateDocumentContentInput = {
  content: Scalars['String']['input'];
  contentType: Scalars['String']['input'];
  documentId: Scalars['ID']['input'];
};

export type CreateDocumentContentSubInput = {
  content: Scalars['String']['input'];
  contentType: Scalars['String']['input'];
};

export type CreateDocumentInput = {
  content: CreateDocumentContentSubInput;
  name: Scalars['String']['input'];
};

export type CreateDocumentSnapshotContentInput = {
  content: Scalars['String']['input'];
  contentType: Scalars['String']['input'];
  documentSnapshotId: Scalars['ID']['input'];
};

export type CreateDocumentSnapshotContentSubInput = {
  content: Scalars['String']['input'];
  contentType: Scalars['String']['input'];
};

export type CreateDocumentSnapshotInput = {
  content: CreateDocumentSnapshotContentSubInput;
  documentId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type CreateFileAccessControlListInput = {
  fileId: Scalars['ID']['input'];
  role: ObjectRole;
  userGroupId?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateFileInput = {
  filename: Scalars['String']['input'];
  mimeType: Scalars['String']['input'];
  name: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type CreateShapeDataInput = {
  data: Scalars['String']['input'];
  shapeId: Scalars['String']['input'];
  workspaceBoardId: Scalars['String']['input'];
};

export type CreateTopicDiscussionExampleInput = {
  category?: InputMaybe<Scalars['String']['input']>;
  detail: Scalars['String']['input'];
  event: Scalars['String']['input'];
  eventDate: Scalars['Date']['input'];
  eventType?: InputMaybe<Scalars['String']['input']>;
  explanation?: InputMaybe<Scalars['String']['input']>;
  firm?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  period?: InputMaybe<Scalars['String']['input']>;
  quarter?: InputMaybe<Scalars['Int']['input']>;
  question: Scalars['String']['input'];
  rating?: InputMaybe<Scalars['Float']['input']>;
  reportTitle?: InputMaybe<Scalars['String']['input']>;
  score?: InputMaybe<Scalars['Float']['input']>;
  section?: InputMaybe<Scalars['String']['input']>;
  segmentId?: InputMaybe<Scalars['String']['input']>;
  segmentType?: InputMaybe<Scalars['String']['input']>;
  sourceFormat?: InputMaybe<Scalars['String']['input']>;
  sourceId?: InputMaybe<Scalars['String']['input']>;
  sourceType?: InputMaybe<Scalars['String']['input']>;
  sourceUrl?: InputMaybe<Scalars['String']['input']>;
  /** Array of speaker info */
  speakers: Array<SpeakerInput>;
  summary: Scalars['String']['input'];
  ticker: Scalars['String']['input'];
  topic: Scalars['String']['input'];
  turn?: InputMaybe<Scalars['String']['input']>;
  type?: Scalars['String']['input'];
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateTopicDiscussionInNewsArticleInput = {
  articleCount: Scalars['Int']['input'];
  date: Scalars['Date']['input'];
  headline: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of new source info */
  newSources: Array<NewSourceInput>;
  paragraph: Scalars['String']['input'];
  segmentId: Scalars['String']['input'];
  segmentType: Scalars['String']['input'];
  sourceType: Scalars['String']['input'];
  theme: Scalars['String']['input'];
  /** Array of topic discussion info */
  topicDiscussions: Array<TopicDiscussionInput>;
};

export type CreateTopicDiscussionSummaryExampleInput = {
  analysisName: Scalars['String']['input'];
  category: Scalars['String']['input'];
  detail: Scalars['String']['input'];
  event: Scalars['String']['input'];
  eventDate: Scalars['Date']['input'];
  eventType: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  period?: InputMaybe<Scalars['String']['input']>;
  quarter?: InputMaybe<Scalars['Int']['input']>;
  questionMentionCounts: Array<Scalars['Int']['input']>;
  questions: Array<Scalars['String']['input']>;
  rating?: InputMaybe<Scalars['Float']['input']>;
  score?: InputMaybe<Scalars['Float']['input']>;
  section: Scalars['String']['input'];
  sourceFormat: Scalars['String']['input'];
  sourceId: Scalars['String']['input'];
  sourceUrl: Scalars['String']['input'];
  /** Array of speaker info */
  speakers: Array<SpeakerInput>;
  summary: Scalars['String']['input'];
  ticker: Scalars['String']['input'];
  topic: Scalars['String']['input'];
  topicMentionCount: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};

export type CreateUserGroupInput = {
  name: Scalars['String']['input'];
};

export type CreateUserGroupMemberInput = {
  userGroupId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type CreateUserPreferenceInput = {
  data: Scalars['JSONObject']['input'];
  key: Scalars['String']['input'];
  organizationId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateWorkspaceAccessControlListInput = {
  role: ObjectRole;
  userGroupId?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
  workspaceId: Scalars['ID']['input'];
};

export type CreateWorkspaceBoardInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type CreateWorkspaceFileInput = {
  filename: Scalars['String']['input'];
  mimeType: Scalars['String']['input'];
  name: Scalars['String']['input'];
  url: Scalars['String']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type CreateWorkspaceInput = {
  name: Scalars['String']['input'];
  workspaceBoard: CreateWorkspaceWorkspaceBoardSubInput;
};

export type CreateWorkspaceWorkspaceBoardSubInput = {
  name: Scalars['String']['input'];
};

export type DateRangeInput = {
  from?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['String']['input']>;
};

export type DeleteAnalysisFeedbackInput = {
  id: Scalars['ID']['input'];
};

export type DeleteAnalystQuestionClusterAssignmentInput = {
  id: Scalars['ID']['input'];
};

export type DeleteAnalystQuestionClusterInput = {
  id: Scalars['ID']['input'];
};

export type DeleteAnalystQuestionInput = {
  id: Scalars['ID']['input'];
};

export type DeleteChatMessageInput = {
  channelType: ChatMessageChannelType;
  id: Scalars['ID']['input'];
};

export type DeleteConfigKeyInput = {
  key: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};

export type DeleteDocumentAccessControlListInput = {
  id: Scalars['ID']['input'];
};

export type DeleteDocumentInput = {
  id: Scalars['ID']['input'];
};

export type DeleteFileAccessControlListInput = {
  id: Scalars['ID']['input'];
};

export type DeleteFileInput = {
  id: Scalars['ID']['input'];
};

export type DeleteInactiveWorkspaceBoardShapeDataInput = {
  workspaceBoardId: Scalars['String']['input'];
};

export type DeleteInactiveWorkspaceBoardTableOfContentsInput = {
  workspaceBoardId: Scalars['ID']['input'];
};

export type DeleteResult = {
  __typename?: 'DeleteResult';
  id: Scalars['ID']['output'];
};

export type DeleteTopicDiscussionExampleInput = {
  id: Scalars['ID']['input'];
};

export type DeleteTopicDiscussionInNewsArticleInput = {
  id: Scalars['ID']['input'];
};

export type DeleteTopicDiscussionSummaryExampleInput = {
  id: Scalars['ID']['input'];
};

export type DeleteUserGroupInput = {
  id: Scalars['ID']['input'];
};

export type DeleteUserGroupMemberInput = {
  id: Scalars['ID']['input'];
};

export type DeleteUserPreferenceInput = {
  key: Scalars['String']['input'];
  organizationId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type DeleteWorkspaceAccessControlListInput = {
  id: Scalars['ID']['input'];
};

export type DeleteWorkspaceBoardInput = {
  id: Scalars['ID']['input'];
};

export type DeleteWorkspaceBoardTableOfContentsReactionInput = {
  workspaceBoardTableOfContentsReactionId: Scalars['ID']['input'];
};

export type DeleteWorkspaceFileInput = {
  id: Scalars['ID']['input'];
};

export type DeleteWorkspaceInput = {
  id: Scalars['ID']['input'];
};

export enum DiscussionNature {
  FinancialDetails = 'FinancialDetails',
  ForwardLooking = 'ForwardLooking',
  Initiatives = 'Initiatives',
  Investments = 'Investments',
  QuantitativeMetrics = 'QuantitativeMetrics',
  StateOfMarket = 'StateOfMarket'
}

export type DistilledTranscript = {
  __typename?: 'DistilledTranscript';
  audioUrl: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  createdBy: Scalars['ID']['output'];
  displaySymbol: Scalars['String']['output'];
  distilledSegments: Array<DistilledTranscriptSegment>;
  eventType: TranscriptEventType;
  id: Scalars['ID']['output'];
  itemDate: Scalars['Date']['output'];
  participants: Array<TranscriptParticipant>;
  quarter: Scalars['Int']['output'];
  symbols: Array<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  transcriptId: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
  updatedBy: Scalars['ID']['output'];
  year: Scalars['Int']['output'];
};

export type DistilledTranscriptAggregate = {
  __typename?: 'DistilledTranscriptAggregate';
  maxItemDate?: Maybe<Scalars['String']['output']>;
  minItemDate?: Maybe<Scalars['String']['output']>;
};

export type DistilledTranscriptFilters = {
  endDate?: InputMaybe<Scalars['String']['input']>;
  eventType?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
  symbols?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DistilledTranscriptOrderBy = {
  displaySymbol?: InputMaybe<SortOrder>;
  itemDate?: InputMaybe<SortOrder>;
  quarter?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  year?: InputMaybe<SortOrder>;
};

export type DistilledTranscriptSearchResponse = {
  __typename?: 'DistilledTranscriptSearchResponse';
  errors?: Maybe<Array<SushiCustomError>>;
  results?: Maybe<Array<DistilledTranscript>>;
  status: Scalars['String']['output'];
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type DistilledTranscriptSegment = {
  __typename?: 'DistilledTranscriptSegment';
  distilledTranscriptItems: Array<TranscriptItem>;
  originalTranscriptItems: Array<TranscriptItem>;
  segmentIndex: Scalars['Int']['output'];
  session: Scalars['String']['output'];
};

export type Document = {
  __typename?: 'Document';
  changeLogs: Array<DocumentChangeLog>;
  content: DocumentContent;
  createdAt: Scalars['Date']['output'];
  createdBy: Scalars['ID']['output'];
  documentAccessControlList: Array<DocumentAccessControlList>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  snapshots: Array<DocumentSnapshot>;
  updatedAt: Scalars['Date']['output'];
  updatedBy: Scalars['ID']['output'];
};

export type DocumentAccessControlList = {
  __typename?: 'DocumentAccessControlList';
  createdAt: Scalars['Date']['output'];
  createdBy: Scalars['ID']['output'];
  documentId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  role: ObjectRole;
  updatedAt: Scalars['Date']['output'];
  updatedBy: Scalars['ID']['output'];
  user?: Maybe<User>;
  userGroup?: Maybe<UserGroup>;
  userId?: Maybe<Scalars['ID']['output']>;
};

export type DocumentAccessControlListAggregate = {
  __typename?: 'DocumentAccessControlListAggregate';
  count: Scalars['Int']['output'];
};

export type DocumentAccessControlListOrderBy = {
  createdAt?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type DocumentAccessControlListUserRoles = {
  __typename?: 'DocumentAccessControlListUserRoles';
  objectId: Scalars['String']['output'];
  roles: Array<ObjectRole>;
  userId: Scalars['String']['output'];
};

export type DocumentAggregate = {
  __typename?: 'DocumentAggregate';
  count: Scalars['Int']['output'];
};

export type DocumentChangeLog = {
  __typename?: 'DocumentChangeLog';
  createdAt: Scalars['Date']['output'];
  createdBy: Scalars['ID']['output'];
  description: Scalars['String']['output'];
  fromSnapshot?: Maybe<DocumentSnapshot>;
  id: Scalars['ID']['output'];
  toSnapshot?: Maybe<DocumentSnapshot>;
  updatedAt: Scalars['Date']['output'];
  updatedBy: Scalars['ID']['output'];
};

export type DocumentChangeLogAggregate = {
  __typename?: 'DocumentChangeLogAggregate';
  count: Scalars['Int']['output'];
};

export type DocumentContent = {
  __typename?: 'DocumentContent';
  content: Scalars['String']['output'];
  contentType: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  createdBy: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['Date']['output'];
  updatedBy: Scalars['ID']['output'];
};

export type DocumentFile = {
  __typename?: 'DocumentFile';
  document: DocumentType;
  filename: Scalars['String']['output'];
  mimetype: Scalars['String']['output'];
  originalFilename: Scalars['String']['output'];
  storageLocation: Scalars['String']['output'];
};

export type DocumentFilters = {
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};

export type DocumentImageFile = {
  __typename?: 'DocumentImageFile';
  apiDownloadUrl: Scalars['String']['output'];
  assetDownloadUrl: Scalars['String']['output'];
  filename: Scalars['String']['output'];
  mimetype: Scalars['String']['output'];
  originalFilename: Scalars['String']['output'];
  storageLocation: Scalars['String']['output'];
};

export type DocumentOrderBy = {
  createdAt?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type DocumentSnapshot = {
  __typename?: 'DocumentSnapshot';
  content: DocumentSnapshotContent;
  createdAt: Scalars['Date']['output'];
  createdBy: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
  updatedBy: Scalars['ID']['output'];
};

export type DocumentSnapshotAggregate = {
  __typename?: 'DocumentSnapshotAggregate';
  count: Scalars['Int']['output'];
};

export type DocumentSnapshotContent = {
  __typename?: 'DocumentSnapshotContent';
  content: Scalars['String']['output'];
  contentType: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  createdBy: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['Date']['output'];
  updatedBy: Scalars['ID']['output'];
};

export type DocumentType = {
  __typename?: 'DocumentType';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  organizationId: Scalars['String']['output'];
};

export type EditDocumentsInWorkspaceInput = {
  documentIds: Array<Scalars['ID']['input']>;
  workspaceBoardName: Scalars['String']['input'];
  workspaceName: Scalars['String']['input'];
};

export type EditFilesInWorkspaceInput = {
  fileIds: Array<Scalars['ID']['input']>;
  workspaceBoardName: Scalars['String']['input'];
  workspaceName: Scalars['String']['input'];
};

export type ExecuteCommandInput = {
  callerData?: InputMaybe<Scalars['String']['input']>;
  commandContext: CommandContext;
  commandId: Scalars['String']['input'];
  deduplicationId?: InputMaybe<Scalars['String']['input']>;
  outputTemplate: Scalars['String']['input'];
  requestId: Scalars['String']['input'];
  sessionId: Scalars['String']['input'];
};

export type File = {
  __typename?: 'File';
  bundleFile: Array<BundleFile>;
  createdAt: Scalars['Date']['output'];
  createdBy: Scalars['ID']['output'];
  fileAccessControlList: Array<FileAccessControlList>;
  filename: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  mimeType: Scalars['String']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
  updatedBy: Scalars['ID']['output'];
  url: Scalars['String']['output'];
};

export type FileAccessControlList = {
  __typename?: 'FileAccessControlList';
  createdAt: Scalars['Date']['output'];
  createdBy: Scalars['ID']['output'];
  fileId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  role: ObjectRole;
  updatedAt: Scalars['Date']['output'];
  updatedBy: Scalars['ID']['output'];
  user?: Maybe<User>;
  userGroup?: Maybe<UserGroup>;
  userId?: Maybe<Scalars['ID']['output']>;
};

export type FileAccessControlListAggregate = {
  __typename?: 'FileAccessControlListAggregate';
  count: Scalars['Int']['output'];
};

export type FileAccessControlListOrderBy = {
  createdAt?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type FileAccessControlListUserRoles = {
  __typename?: 'FileAccessControlListUserRoles';
  objectId: Scalars['String']['output'];
  roles: Array<ObjectRole>;
  userId: Scalars['String']['output'];
};

export type FileAggregate = {
  __typename?: 'FileAggregate';
  count: Scalars['Int']['output'];
};

export type FileBundleView = {
  __typename?: 'FileBundleView';
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  mimeType?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  type: FileBundleViewType;
};

export type FileBundleViewAggregate = {
  __typename?: 'FileBundleViewAggregate';
  count: Scalars['Int']['output'];
};

export type FileBundleViewFilters = {
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};

export type FileBundleViewOrderBy = {
  createdAt?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
};

export enum FileBundleViewType {
  /** Bundle */
  Bundle = 'Bundle',
  /** File */
  File = 'File'
}

export type FileData = {
  __typename?: 'FileData';
  apiDownloadUrl: Scalars['String']['output'];
  assetDownloadUrl: Scalars['String']['output'];
  filename: Scalars['String']['output'];
  id: Scalars['String']['output'];
  mimetype: Scalars['String']['output'];
  originalFilename: Scalars['String']['output'];
  storageLocation: Scalars['String']['output'];
};

export type FileFailedData = {
  __typename?: 'FileFailedData';
  apiDownloadUrl: Scalars['String']['output'];
  assetDownloadUrl: Scalars['String']['output'];
  filename: Scalars['String']['output'];
  id: Scalars['String']['output'];
  mimetype: Scalars['String']['output'];
  originalFilename: Scalars['String']['output'];
  reason: Scalars['String']['output'];
  storageLocation: Scalars['String']['output'];
};

export type FileFilters = {
  bundleId?: InputMaybe<Scalars['ID']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};

export type FileOrderBy = {
  createdAt?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type FileUploadStatus = {
  __typename?: 'FileUploadStatus';
  error: Scalars['String']['output'];
  fileName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  mimeType: Scalars['String']['output'];
  totalSize: Scalars['Int']['output'];
  uploadStatus: Scalars['String']['output'];
  uploadedSize: Scalars['Int']['output'];
  userId: Scalars['ID']['output'];
};

export type GroupedAnalystQuestion = {
  __typename?: 'GroupedAnalystQuestion';
  createdAt: Scalars['Date']['output'];
  createdBy: Scalars['ID']['output'];
  eventDate: Scalars['Date']['output'];
  firm: Scalars['String']['output'];
  firmType: Scalars['String']['output'];
  group: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isHidden: Scalars['Boolean']['output'];
  question: Scalars['String']['output'];
  segment: Array<Scalars['String']['output']>;
  topics: Array<Scalars['String']['output']>;
  updatedAt: Scalars['Date']['output'];
  updatedBy: Scalars['ID']['output'];
};

export type HierarchyResultType = {
  maxDepth: Scalars['Int']['input'];
  textLength: Scalars['Int']['input'];
};

export type ITopicDiscussion = {
  __typename?: 'ITopicDiscussion';
  summary: Scalars['String']['output'];
  topic: Scalars['String']['output'];
};

export type ImageTokenResultType = {
  __typename?: 'ImageTokenResultType';
  token: Scalars['String']['output'];
};

export type IndentResultType = {
  indentLevels: Scalars['JSON']['input'];
  indentValues: Array<Scalars['Int']['input']>;
  maxIndent: Scalars['Int']['input'];
};

export type LinkPropsType = {
  __typename?: 'LinkPropsType';
  isEmbeddable: Scalars['Boolean']['output'];
};

export type MarkWorkspaceBoardShapeAsDeletedInput = {
  shapeIds: Array<Scalars['String']['input']>;
  workspaceBoardId: Scalars['ID']['input'];
};

export type MarkWorkspaceBoardTableOfContentsActiveForShapeInput = {
  shapeId: Scalars['String']['input'];
  workspaceBoardId: Scalars['ID']['input'];
};

export type MarkWorkspaceBoardTableOfContentsInactiveForShapeInput = {
  shapeId: Scalars['String']['input'];
  workspaceBoardId: Scalars['ID']['input'];
};

export type MinMaxAggregate = {
  __typename?: 'MinMaxAggregate';
  max?: Maybe<Scalars['Int']['output']>;
  min?: Maybe<Scalars['Int']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  bulkDeleteBundle: BulkDeleteResult;
  bulkDeleteDocument: BulkDeleteResult;
  bulkDeleteFile: BulkDeleteResult;
  cancelCommand?: Maybe<CommandQueueStatus>;
  copyShapeData?: Maybe<ShapeData>;
  copyWorkspace?: Maybe<Workspace>;
  createAnalysisFeedback?: Maybe<AnalysisFeedback>;
  createAnalystQuestion?: Maybe<AnalystQuestion>;
  createAnalystQuestionCluster?: Maybe<AnalystQuestionCluster>;
  createAnalystQuestionClusterAssignment?: Maybe<AnalystQuestionClusterAssignment>;
  createBundle?: Maybe<Bundle>;
  createBundleFile?: Maybe<BundleFile>;
  createChatMessage: ChatMessage;
  createDocument?: Maybe<Document>;
  createDocumentAccessControlList?: Maybe<DocumentAccessControlList>;
  createDocumentChangeLog?: Maybe<DocumentChangeLog>;
  createDocumentContent?: Maybe<DocumentContent>;
  createDocumentSnapshot?: Maybe<DocumentSnapshot>;
  createDocumentSnapshotContent?: Maybe<DocumentSnapshotContent>;
  createFile?: Maybe<File>;
  createFileAccessControlList?: Maybe<FileAccessControlList>;
  createNewConfigKey: Configs;
  createShapeData?: Maybe<ShapeData>;
  createTopicDiscussionExample?: Maybe<TopicDiscussionExample>;
  createTopicDiscussionInNewsArticle?: Maybe<TopicDiscussionInNewsArticle>;
  createTopicDiscussionSummaryExample?: Maybe<TopicDiscussionSummaryExample>;
  createUserGroup?: Maybe<UserGroup>;
  createUserGroupMember?: Maybe<UserGroupMember>;
  createUserPreference: UserPreference;
  createWorkspace?: Maybe<Workspace>;
  createWorkspaceAccessControlList?: Maybe<WorkspaceAccessControlList>;
  createWorkspaceBoard: WorkspaceBoard;
  createWorkspaceFile: WorkspaceFile;
  deleteAnalysisFeedback: DeleteResult;
  deleteAnalystQuestion: DeleteResult;
  deleteAnalystQuestionCluster: DeleteResult;
  deleteAnalystQuestionClusterAssignment: DeleteResult;
  deleteBulkAnalystQuestionClusterAssignments: BulkDeleteResult;
  deleteBulkAnalystQuestionClusters: BulkDeleteResult;
  deleteBulkAnalystQuestions: BulkDeleteResult;
  deleteBulkTopicDiscussionExamples: BulkDeleteResult;
  deleteBulkTopicDiscussionInNewsArticles: BulkDeleteResult;
  deleteBulkTopicDiscussionSummaryExamples: BulkDeleteResult;
  deleteConfigKey: Array<Configs>;
  deleteDocument: DeleteResult;
  deleteDocumentAccessControlList: DeleteResult;
  deleteFile: DeleteResult;
  deleteFileAccessControlList: DeleteResult;
  deleteInactiveWorkspaceBoardShapeData: BulkDeleteResult;
  deleteInactiveWorkspaceBoardTableOfContents: BulkDeleteResult;
  deleteTopicDiscussionExample: DeleteResult;
  deleteTopicDiscussionInNewsArticle: DeleteResult;
  deleteTopicDiscussionSummaryExample: DeleteResult;
  deleteUserGroup: DeleteResult;
  deleteUserGroupMember: DeleteResult;
  deleteUserPreference: UserPreference;
  deleteWorkspace: DeleteResult;
  deleteWorkspaceAccessControlList: DeleteResult;
  deleteWorkspaceBoard: DeleteResult;
  deleteWorkspaceBoardTableOfContentsReaction: DeleteResult;
  deleteWorkspaceFile: DeleteResult;
  editDocumentsInWorkspace?: Maybe<Workspace>;
  editFilesInWorkspace?: Maybe<Workspace>;
  executeCommand?: Maybe<CommandResponse>;
  executePingCommand?: Maybe<CommandResponse>;
  markWorkspaceBoardShapesAsDeleted: Array<Scalars['String']['output']>;
  markWorkspaceBoardTableOfContentsActiveForShape: BulkUpdateResult;
  markWorkspaceBoardTableOfContentsInactiveForShape: BulkUpdateResult;
  processHtml: ProcessHtmlResponseType;
  publishBoardDocumentShapesToDocuments: Array<Document>;
  publishWorkspaceFilesToLibrary: Array<File>;
  reindexDocuments: ReindexDocumentsResult;
  reindexFiles: ReindexFilesResult;
  reindexWorkspace: ReindexWorkspaceResult;
  replaceWorkspaceBoardTableOfContentsForShape: Scalars['Boolean']['output'];
  softOrHardDeleteChatMessage: DeleteResult;
  updateAnalystQuestion: AnalystQuestion;
  updateAnalystQuestionCluster: AnalystQuestionCluster;
  updateAnalystQuestionClusterAssignment: AnalystQuestionClusterAssignment;
  updateChatMessage: ChatMessage;
  updateConfigKey: Configs;
  updateDocument: Document;
  updateDocumentAccessControlList: DocumentAccessControlList;
  updateDocumentContent: DocumentContent;
  updateFile: File;
  updateFileAccessControlList: FileAccessControlList;
  updateShapeData?: Maybe<ShapeData>;
  updateTopicDiscussionExample?: Maybe<TopicDiscussionExample>;
  updateTopicDiscussionInNewsArticle?: Maybe<TopicDiscussionInNewsArticle>;
  updateTopicDiscussionSummaryExample?: Maybe<TopicDiscussionSummaryExample>;
  updateUserGroup: UserGroup;
  updateUserGroupMember: UserGroupMember;
  updateWorkspace: Workspace;
  updateWorkspaceAccessControlList: WorkspaceAccessControlList;
  updateWorkspaceBoard: WorkspaceBoard;
  updateWorkspaceFile: WorkspaceFile;
  uploadDocument?: Maybe<DocumentFile>;
  uploadDocumentImage?: Maybe<DocumentImageFile>;
  uploadFile?: Maybe<FileData>;
  uploadFiles?: Maybe<UploadFilesResponse>;
  uploadWorkspaceBoardAsset?: Maybe<WorkspaceBoardAssetFile>;
  uploadWorkspaceFiles?: Maybe<UploadWorkspaceFileResponse>;
  upsertUserPreference: UserPreference;
  upsertWorkspaceBoardTableOfContentsReaction: WorkspaceBoardTableOfContentsReaction;
};


export type MutationBulkDeleteBundleArgs = {
  input: BulkDeleteBundleInput;
};


export type MutationBulkDeleteDocumentArgs = {
  input: BulkDeleteDocumentInput;
};


export type MutationBulkDeleteFileArgs = {
  input: BulkDeleteFileInput;
};


export type MutationCancelCommandArgs = {
  input: CancelCommandInput;
};


export type MutationCopyShapeDataArgs = {
  input: CopyShapeDataInput;
};


export type MutationCopyWorkspaceArgs = {
  input: CopyWorkspaceInput;
};


export type MutationCreateAnalysisFeedbackArgs = {
  input: CreateAnalysisFeedbackInput;
};


export type MutationCreateAnalystQuestionArgs = {
  input: CreateAnalystQuestionInput;
};


export type MutationCreateAnalystQuestionClusterArgs = {
  input: CreateAnalystQuestionClusterInput;
};


export type MutationCreateAnalystQuestionClusterAssignmentArgs = {
  input: CreateAnalystQuestionClusterAssignmentInput;
};


export type MutationCreateBundleArgs = {
  input: CreateBundleInput;
};


export type MutationCreateBundleFileArgs = {
  input: CreateBundleFileInput;
};


export type MutationCreateChatMessageArgs = {
  input: CreateChatMessageInput;
};


export type MutationCreateDocumentArgs = {
  input: CreateDocumentInput;
};


export type MutationCreateDocumentAccessControlListArgs = {
  input: CreateDocumentAccessControlListInput;
};


export type MutationCreateDocumentChangeLogArgs = {
  input: CreateDocumentChangeLogInput;
};


export type MutationCreateDocumentContentArgs = {
  input: CreateDocumentContentInput;
};


export type MutationCreateDocumentSnapshotArgs = {
  input: CreateDocumentSnapshotInput;
};


export type MutationCreateDocumentSnapshotContentArgs = {
  input: CreateDocumentSnapshotContentInput;
};


export type MutationCreateFileArgs = {
  input: CreateFileInput;
};


export type MutationCreateFileAccessControlListArgs = {
  input: CreateFileAccessControlListInput;
};


export type MutationCreateNewConfigKeyArgs = {
  input: NewConfigKeyInput;
};


export type MutationCreateShapeDataArgs = {
  input: CreateShapeDataInput;
};


export type MutationCreateTopicDiscussionExampleArgs = {
  input: CreateTopicDiscussionExampleInput;
};


export type MutationCreateTopicDiscussionInNewsArticleArgs = {
  input: CreateTopicDiscussionInNewsArticleInput;
};


export type MutationCreateTopicDiscussionSummaryExampleArgs = {
  input: CreateTopicDiscussionSummaryExampleInput;
};


export type MutationCreateUserGroupArgs = {
  input: CreateUserGroupInput;
};


export type MutationCreateUserGroupMemberArgs = {
  input: CreateUserGroupMemberInput;
};


export type MutationCreateUserPreferenceArgs = {
  input: CreateUserPreferenceInput;
};


export type MutationCreateWorkspaceArgs = {
  input: CreateWorkspaceInput;
};


export type MutationCreateWorkspaceAccessControlListArgs = {
  input: CreateWorkspaceAccessControlListInput;
};


export type MutationCreateWorkspaceBoardArgs = {
  input: CreateWorkspaceBoardInput;
};


export type MutationCreateWorkspaceFileArgs = {
  input: CreateWorkspaceFileInput;
};


export type MutationDeleteAnalysisFeedbackArgs = {
  input: DeleteAnalysisFeedbackInput;
};


export type MutationDeleteAnalystQuestionArgs = {
  input: DeleteAnalystQuestionInput;
};


export type MutationDeleteAnalystQuestionClusterArgs = {
  input: DeleteAnalystQuestionClusterInput;
};


export type MutationDeleteAnalystQuestionClusterAssignmentArgs = {
  input: DeleteAnalystQuestionClusterAssignmentInput;
};


export type MutationDeleteConfigKeyArgs = {
  input: DeleteConfigKeyInput;
};


export type MutationDeleteDocumentArgs = {
  input: DeleteDocumentInput;
};


export type MutationDeleteDocumentAccessControlListArgs = {
  input: DeleteDocumentAccessControlListInput;
};


export type MutationDeleteFileArgs = {
  input: DeleteFileInput;
};


export type MutationDeleteFileAccessControlListArgs = {
  input: DeleteFileAccessControlListInput;
};


export type MutationDeleteInactiveWorkspaceBoardShapeDataArgs = {
  input: DeleteInactiveWorkspaceBoardShapeDataInput;
};


export type MutationDeleteInactiveWorkspaceBoardTableOfContentsArgs = {
  input: DeleteInactiveWorkspaceBoardTableOfContentsInput;
};


export type MutationDeleteTopicDiscussionExampleArgs = {
  input: DeleteTopicDiscussionExampleInput;
};


export type MutationDeleteTopicDiscussionInNewsArticleArgs = {
  input: DeleteTopicDiscussionInNewsArticleInput;
};


export type MutationDeleteTopicDiscussionSummaryExampleArgs = {
  input: DeleteTopicDiscussionSummaryExampleInput;
};


export type MutationDeleteUserGroupArgs = {
  input: DeleteUserGroupInput;
};


export type MutationDeleteUserGroupMemberArgs = {
  input: DeleteUserGroupMemberInput;
};


export type MutationDeleteUserPreferenceArgs = {
  input: DeleteUserPreferenceInput;
};


export type MutationDeleteWorkspaceArgs = {
  input: DeleteWorkspaceInput;
};


export type MutationDeleteWorkspaceAccessControlListArgs = {
  input: DeleteWorkspaceAccessControlListInput;
};


export type MutationDeleteWorkspaceBoardArgs = {
  input: DeleteWorkspaceBoardInput;
};


export type MutationDeleteWorkspaceBoardTableOfContentsReactionArgs = {
  input: DeleteWorkspaceBoardTableOfContentsReactionInput;
};


export type MutationDeleteWorkspaceFileArgs = {
  input: DeleteWorkspaceFileInput;
};


export type MutationEditDocumentsInWorkspaceArgs = {
  input: EditDocumentsInWorkspaceInput;
};


export type MutationEditFilesInWorkspaceArgs = {
  input: EditFilesInWorkspaceInput;
};


export type MutationExecuteCommandArgs = {
  input: ExecuteCommandInput;
};


export type MutationExecutePingCommandArgs = {
  input: ExecuteCommandInput;
};


export type MutationMarkWorkspaceBoardShapesAsDeletedArgs = {
  input: MarkWorkspaceBoardShapeAsDeletedInput;
};


export type MutationMarkWorkspaceBoardTableOfContentsActiveForShapeArgs = {
  input: MarkWorkspaceBoardTableOfContentsActiveForShapeInput;
};


export type MutationMarkWorkspaceBoardTableOfContentsInactiveForShapeArgs = {
  input: MarkWorkspaceBoardTableOfContentsInactiveForShapeInput;
};


export type MutationProcessHtmlArgs = {
  analysisOverride?: InputMaybe<AnalysisResultOverrideType>;
  analysisOverrideConfig?: InputMaybe<Scalars['String']['input']>;
  preset?: InputMaybe<Scalars['String']['input']>;
  sourceDocument: Scalars['String']['input'];
};


export type MutationPublishBoardDocumentShapesToDocumentsArgs = {
  input: PublishBoardDocumentShapesToDocumentsInput;
};


export type MutationPublishWorkspaceFilesToLibraryArgs = {
  input: PublishWorkspaceFilesToLibraryInput;
};


export type MutationReindexDocumentsArgs = {
  input: ReindexDocumentsInput;
};


export type MutationReindexFilesArgs = {
  input: ReindexFilesInput;
};


export type MutationReindexWorkspaceArgs = {
  input: ReindexWorkspaceInput;
};


export type MutationReplaceWorkspaceBoardTableOfContentsForShapeArgs = {
  input: ReplaceWorkspaceBoardTableOfContentsForShapeInput;
};


export type MutationSoftOrHardDeleteChatMessageArgs = {
  input: DeleteChatMessageInput;
};


export type MutationUpdateAnalystQuestionArgs = {
  input: UpdateAnalystQuestionInput;
};


export type MutationUpdateAnalystQuestionClusterArgs = {
  input: UpdateAnalystQuestionClusterInput;
};


export type MutationUpdateAnalystQuestionClusterAssignmentArgs = {
  input: UpdateAnalystQuestionClusterAssignmentInput;
};


export type MutationUpdateChatMessageArgs = {
  input: UpdateChatMessageInput;
};


export type MutationUpdateConfigKeyArgs = {
  input: UpdateConfigKeyInput;
};


export type MutationUpdateDocumentArgs = {
  input: UpdateDocumentInput;
};


export type MutationUpdateDocumentAccessControlListArgs = {
  input: UpdateDocumentAccessControlListInput;
};


export type MutationUpdateDocumentContentArgs = {
  input: UpdateDocumentContentInput;
};


export type MutationUpdateFileArgs = {
  input: UpdateFileInput;
};


export type MutationUpdateFileAccessControlListArgs = {
  input: UpdateFileAccessControlListInput;
};


export type MutationUpdateShapeDataArgs = {
  input: UpdateShapeDataInput;
};


export type MutationUpdateTopicDiscussionExampleArgs = {
  input: UpdateTopicDiscussionExampleInput;
};


export type MutationUpdateTopicDiscussionInNewsArticleArgs = {
  input: UpdateTopicDiscussionInNewsArticleInput;
};


export type MutationUpdateTopicDiscussionSummaryExampleArgs = {
  input: UpdateTopicDiscussionSummaryExampleInput;
};


export type MutationUpdateUserGroupArgs = {
  input: UpdateUserGroupInput;
};


export type MutationUpdateUserGroupMemberArgs = {
  input: UpdateUserGroupMemberInput;
};


export type MutationUpdateWorkspaceArgs = {
  input: UpdateWorkspaceInput;
};


export type MutationUpdateWorkspaceAccessControlListArgs = {
  input: UpdateWorkspaceAccessControlListInput;
};


export type MutationUpdateWorkspaceBoardArgs = {
  input: UpdateWorkspaceBoardInput;
};


export type MutationUpdateWorkspaceFileArgs = {
  input: UpdateWorkspaceFileInput;
};


export type MutationUploadDocumentArgs = {
  file: Scalars['Upload']['input'];
};


export type MutationUploadDocumentImageArgs = {
  documentId: Scalars['UUID']['input'];
  file: Scalars['Upload']['input'];
};


export type MutationUploadFileArgs = {
  file: Scalars['Upload']['input'];
};


export type MutationUploadFilesArgs = {
  input: UploadFilesInput;
};


export type MutationUploadWorkspaceBoardAssetArgs = {
  file: Scalars['Upload']['input'];
  workspaceBoardId: Scalars['UUID']['input'];
};


export type MutationUploadWorkspaceFilesArgs = {
  input: UploadWorkspaceFilesInput;
};


export type MutationUpsertUserPreferenceArgs = {
  input: UpsertUserPreferenceInput;
};


export type MutationUpsertWorkspaceBoardTableOfContentsReactionArgs = {
  input: UpsertWorkspaceBoardTableOfContentsReactionInput;
};

export type NewConfigKeyInput = {
  data?: InputMaybe<Scalars['String']['input']>;
  key: Scalars['String']['input'];
  mimeType: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};

export type NewSource = {
  __typename?: 'NewSource';
  name: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type NewSourceInput = {
  name: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type NewsSummary = {
  __typename?: 'NewsSummary';
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  documentSource?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  sourceNames?: Maybe<Array<Scalars['String']['output']>>;
  sourceUrls?: Maybe<Array<Scalars['String']['output']>>;
  title?: Maybe<Scalars['String']['output']>;
};

export type NewsSummarySearchResponse = {
  __typename?: 'NewsSummarySearchResponse';
  errors?: Maybe<Array<SushiCustomError>>;
  results?: Maybe<Array<NewsSummary>>;
  status: Scalars['String']['output'];
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export enum ObjectRole {
  /** View and comment */
  Commenter = 'Commenter',
  /** Add, edit, and delete objects */
  ContentManager = 'ContentManager',
  /** Add and edit objects */
  Contributor = 'Contributor',
  /** Manage content, people, and settings */
  Manager = 'Manager',
  /** Full permissions */
  Owner = 'Owner',
  /** View (read) only */
  Viewer = 'Viewer'
}

export enum OccupationTitle {
  AdvertisingVicePresident = 'AdvertisingVicePresident',
  Chairman = 'Chairman',
  ChiefAdministrativeOfficer = 'ChiefAdministrativeOfficer',
  ChiefDiversityOfficer = 'ChiefDiversityOfficer',
  ChiefExecutiveOfficer = 'ChiefExecutiveOfficer',
  ChiefFinancialOfficer = 'ChiefFinancialOfficer',
  ChiefInformationOfficer = 'ChiefInformationOfficer',
  ChiefInformationSecurityOfficer = 'ChiefInformationSecurityOfficer',
  ChiefInnovationOfficer = 'ChiefInnovationOfficer',
  ChiefLegalOfficer = 'ChiefLegalOfficer',
  ChiefMarketingOfficer = 'ChiefMarketingOfficer',
  ChiefNursingOfficer = 'ChiefNursingOfficer',
  ChiefOperatingOfficer = 'ChiefOperatingOfficer',
  ChiefProductOfficer = 'ChiefProductOfficer',
  ChiefRiskOfficer = 'ChiefRiskOfficer',
  ChiefSecurityOfficer = 'ChiefSecurityOfficer',
  ChiefSustainabilityOfficer = 'ChiefSustainabilityOfficer',
  ChiefTechnologyOfficer = 'ChiefTechnologyOfficer',
  ExecutiveVicePresident = 'ExecutiveVicePresident',
  FinanceVicePresident = 'FinanceVicePresident',
  GeneralCounsel = 'GeneralCounsel',
  InvestorRelationsVicePresident = 'InvestorRelationsVicePresident',
  LogisticsVicePresident = 'LogisticsVicePresident',
  MarketingVicePresident = 'MarketingVicePresident',
  OperationsVicePresident = 'OperationsVicePresident',
  Other = 'Other',
  President = 'President',
  SalesAndMarketingVicePresident = 'SalesAndMarketingVicePresident',
  SalesVicePresident = 'SalesVicePresident',
  SeniorVicePresident = 'SeniorVicePresident',
  VicePresident = 'VicePresident'
}

export type Organization = {
  __typename?: 'Organization';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type OrganizationOrderBy = {
  createdAt?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PressRelease = {
  __typename?: 'PressRelease';
  fullText: Scalars['String']['output'];
  fullTextHtml: Scalars['String']['output'];
  fullTextHtmlUrl: Scalars['String']['output'];
  headline: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  itemDate: Scalars['Date']['output'];
  symbols: Array<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

export type PressReleaseSearchResponse = {
  __typename?: 'PressReleaseSearchResponse';
  errors?: Maybe<Array<SushiCustomError>>;
  results?: Maybe<Array<PressRelease>>;
  status: Scalars['String']['output'];
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type ProcessHtmlResponseType = {
  __typename?: 'ProcessHtmlResponseType';
  resultDocument: Scalars['String']['output'];
};

export enum PubSubMutationType {
  Create = 'Create',
  Delete = 'Delete',
  Update = 'Update'
}

export type PublishBoardDocumentShapesToDocumentsDocumentSubInput = {
  changeDescription: Scalars['String']['input'];
  name: Scalars['String']['input'];
  shapeId: Scalars['String']['input'];
};

export type PublishBoardDocumentShapesToDocumentsInput = {
  documents: Array<PublishBoardDocumentShapesToDocumentsDocumentSubInput>;
  workspaceBoardId: Scalars['ID']['input'];
};

export type PublishWorkspaceFilesToLibraryBundleDetailsSubInput = {
  name: Scalars['String']['input'];
  type: BundleType;
};

export type PublishWorkspaceFilesToLibraryInput = {
  bundleDetails?: InputMaybe<PublishWorkspaceFilesToLibraryBundleDetailsSubInput>;
  createBundle: Scalars['Boolean']['input'];
  workspaceFileIds: Array<Scalars['ID']['input']>;
  workspaceId: Scalars['ID']['input'];
};

export type Query = {
  __typename?: 'Query';
  AllConfigVersions?: Maybe<Array<Configs>>;
  AllConfigs: Array<Configs>;
  Config?: Maybe<Configs>;
  ConfigsAggregate: ConfigsAggregate;
  ConfigsDistinctValues: Array<Configs>;
  LatestConfig?: Maybe<Configs>;
  LatestConfigsByPrefix?: Maybe<Array<Configs>>;
  analysisFeedback?: Maybe<AnalysisFeedback>;
  analysisFeedbacks: Array<AnalysisFeedback>;
  analystQuestion?: Maybe<AnalystQuestion>;
  analystQuestionAggregate: AnalystQuestionAggregate;
  analystQuestionArrayFieldDistinctValues?: Maybe<AnalystQuestionArrayFieldAggregate>;
  analystQuestionArrayFieldGroup?: Maybe<Array<AnalystQuestionArrayFieldGroupType>>;
  analystQuestionCluster?: Maybe<AnalystQuestionCluster>;
  analystQuestionClusterAssignment?: Maybe<AnalystQuestionClusterAssignment>;
  analystQuestionClusterAssignments: Array<AnalystQuestionClusterAssignment>;
  analystQuestionClusters: Array<AnalystQuestionCluster>;
  analystQuestionFieldDistinctValues?: Maybe<Array<AnalystQuestion>>;
  analystQuestionFieldGroup?: Maybe<Array<AnalystQuestionFieldGroupType>>;
  analystQuestions: Array<AnalystQuestion>;
  bundle?: Maybe<Bundle>;
  bundleFile?: Maybe<BundleFile>;
  bundleFiles: Array<BundleFile>;
  bundles: Array<Bundle>;
  bundlesByIds?: Maybe<Array<Bundle>>;
  chatMessage?: Maybe<ChatMessage>;
  chatMessages: Array<ChatMessage>;
  commandStatus?: Maybe<CommandQueueStatus>;
  distilledTranscript?: Maybe<DistilledTranscript>;
  distilledTranscriptAggregate: DistilledTranscriptAggregate;
  distilledTranscripts?: Maybe<DistilledTranscriptSearchResponse>;
  document?: Maybe<Document>;
  documentAccessControlList?: Maybe<DocumentAccessControlList>;
  documentAccessControlListByObject: Array<DocumentAccessControlList>;
  documentAccessControlListByObjectAggregate: DocumentAccessControlListAggregate;
  documentAccessControlListUserRoles: DocumentAccessControlListUserRoles;
  documentAggregate: DocumentAggregate;
  documentChangeLog?: Maybe<DocumentChangeLog>;
  documentChangeLogAggregate: DocumentChangeLogAggregate;
  documentChangeLogs: Array<DocumentChangeLog>;
  documentContent?: Maybe<DocumentContent>;
  documentSnapshot?: Maybe<DocumentSnapshot>;
  documentSnapshotAggregate: DocumentSnapshotAggregate;
  documentSnapshotContent?: Maybe<DocumentSnapshotContent>;
  documentSnapshots: Array<DocumentSnapshot>;
  documents: Array<Document>;
  documentsByIds?: Maybe<Array<Document>>;
  file?: Maybe<File>;
  fileAccessControlList?: Maybe<FileAccessControlList>;
  fileAccessControlListByObject: Array<FileAccessControlList>;
  fileAccessControlListByObjectAggregate: FileAccessControlListAggregate;
  fileAccessControlListUserRoles: FileAccessControlListUserRoles;
  fileAggregate: FileAggregate;
  fileBundleView: Array<FileBundleView>;
  fileBundleViewAggregate: FileBundleViewAggregate;
  files: Array<File>;
  filesByIds?: Maybe<Array<File>>;
  groupedAnalystQuestions: Array<GroupedAnalystQuestion>;
  hasChatMessageRole: Scalars['Boolean']['output'];
  hasDocumentRole: Scalars['Boolean']['output'];
  hasFileRole: Scalars['Boolean']['output'];
  hasRoleForDocuments: Scalars['Boolean']['output'];
  hasRoleForFiles: Scalars['Boolean']['output'];
  hasWorkspaceRole: Scalars['Boolean']['output'];
  imageToken: ImageTokenResultType;
  linkProps?: Maybe<LinkPropsType>;
  newsSummary?: Maybe<NewsSummarySearchResponse>;
  organization?: Maybe<Organization>;
  organizations?: Maybe<Array<Organization>>;
  pressRelease?: Maybe<PressRelease>;
  shapeData?: Maybe<ShapeData>;
  testFilter: TestFilter;
  theme?: Maybe<Theme>;
  themeDiscussionAnalyses?: Maybe<ThemeDiscussionAnalysisSearchResponse>;
  themeDiscussionAnalysis?: Maybe<ThemeDiscussionAnalysis>;
  themes?: Maybe<ThemeSearchResponse>;
  topicDiscussionExample?: Maybe<TopicDiscussionExample>;
  topicDiscussionExampleAggregate: TopicDiscussionExampleAggregate;
  topicDiscussionExampleEventAggregate: MinMaxAggregate;
  topicDiscussionExampleFieldDistinctValues?: Maybe<Array<TopicDiscussionExample>>;
  topicDiscussionExampleFieldGroup?: Maybe<Array<TopicDiscussionExampleFieldGroupType>>;
  topicDiscussionExampleQuestionCountsAggregate: MinMaxAggregate;
  topicDiscussionExamples: Array<TopicDiscussionExample>;
  topicDiscussionInNewsArticle?: Maybe<TopicDiscussionInNewsArticle>;
  topicDiscussionInNewsArticleAggregate: TopicDiscussionInNewsArticleAggregate;
  topicDiscussionInNewsArticleFieldDistinctValues?: Maybe<Array<TopicDiscussionInNewsArticle>>;
  topicDiscussionInNewsArticleFieldGroup?: Maybe<Array<TopicDiscussionInNewsArticleFieldGroupType>>;
  topicDiscussionInNewsArticles: Array<TopicDiscussionInNewsArticle>;
  topicDiscussionSummaryExample?: Maybe<TopicDiscussionSummaryExample>;
  topicDiscussionSummaryExampleAggregate: TopicDiscussionSummaryExampleAggregate;
  topicDiscussionSummaryExampleEventAggregate: MinMaxAggregate;
  topicDiscussionSummaryExampleFieldDistinctValues?: Maybe<Array<TopicDiscussionSummaryExample>>;
  topicDiscussionSummaryExampleFieldGroup?: Maybe<Array<TopicDiscussionSummaryExampleFieldGroupType>>;
  topicDiscussionSummaryExampleQuestionMentionCountsAggregate: MinMaxAggregate;
  topicDiscussionSummaryExamples: Array<TopicDiscussionSummaryExample>;
  transcript?: Maybe<Transcript>;
  transcriptAggregate: TranscriptAggregate;
  transcripts?: Maybe<TranscriptSearchResponse>;
  user?: Maybe<User>;
  userGroup?: Maybe<UserGroup>;
  userGroupAggregate: UserGroupAggregate;
  userGroupMember?: Maybe<UserGroupMember>;
  userGroupMemberAggregate: UserGroupMemberAggregate;
  userGroupMembers: Array<UserGroupMember>;
  userGroups: Array<UserGroup>;
  userPreference?: Maybe<UserPreference>;
  users?: Maybe<Array<User>>;
  workspace?: Maybe<Workspace>;
  workspaceAccessControlList?: Maybe<WorkspaceAccessControlList>;
  workspaceAccessControlListByObject: Array<WorkspaceAccessControlList>;
  workspaceAccessControlListByObjectAggregate: WorkspaceAccessControlListAggregate;
  workspaceAccessControlListUserRoles: WorkspaceAccessControlListUserRoles;
  workspaceAggregate: WorkspaceAggregate;
  workspaceBoard?: Maybe<WorkspaceBoard>;
  workspaceBoardAccessControlListUserRoles: WorkspaceAccessControlListUserRoles;
  workspaceBoardAggregate: WorkspaceBoardAggregate;
  workspaceBoardShape?: Maybe<Scalars['JSONObject']['output']>;
  workspaceBoardShapes: Array<Maybe<Scalars['JSONObject']['output']>>;
  workspaceBoardTableOfContents?: Maybe<Array<WorkspaceBoardTableOfContents>>;
  workspaceBoardTableOfContentsReactions?: Maybe<Array<WorkspaceBoardTableOfContentsReaction>>;
  workspaceBoardTags: Array<Scalars['String']['output']>;
  workspaceBoards: Array<WorkspaceBoard>;
  workspaceFile?: Maybe<WorkspaceFile>;
  workspaceFileAggregate: WorkspaceFileAggregate;
  workspaceFiles: Array<WorkspaceFile>;
  workspaceTags: Array<Scalars['String']['output']>;
  workspaces: Array<Workspace>;
};


export type QueryAllConfigVersionsArgs = {
  key: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};


export type QueryAllConfigsArgs = {
  key?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ConfigsOrderBy>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryConfigArgs = {
  key: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  version?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryConfigsAggregateArgs = {
  key?: InputMaybe<Scalars['ID']['input']>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryConfigsDistinctValuesArgs = {
  field: Scalars['String']['input'];
  key?: InputMaybe<Scalars['ID']['input']>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryLatestConfigArgs = {
  key: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};


export type QueryLatestConfigsByPrefixArgs = {
  keyPrefix: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};


export type QueryAnalysisFeedbackArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAnalystQuestionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAnalystQuestionAggregateArgs = {
  facets?: InputMaybe<AnalystQuestionFacetsInput>;
  field: Scalars['String']['input'];
};


export type QueryAnalystQuestionArrayFieldDistinctValuesArgs = {
  facets?: InputMaybe<AnalystQuestionFacetsInput>;
  field: Scalars['String']['input'];
};


export type QueryAnalystQuestionArrayFieldGroupArgs = {
  facets?: InputMaybe<AnalystQuestionFacetsInput>;
  field: Scalars['String']['input'];
};


export type QueryAnalystQuestionClusterArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAnalystQuestionClusterAssignmentArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAnalystQuestionClusterAssignmentsArgs = {
  analystQuestionClusterId?: InputMaybe<Scalars['ID']['input']>;
  analystQuestionId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryAnalystQuestionFieldDistinctValuesArgs = {
  facets?: InputMaybe<AnalystQuestionFacetsInput>;
  field: Scalars['String']['input'];
};


export type QueryAnalystQuestionFieldGroupArgs = {
  facets?: InputMaybe<AnalystQuestionFacetsInput>;
  field: Scalars['String']['input'];
};


export type QueryAnalystQuestionsArgs = {
  facets?: InputMaybe<AnalystQuestionFacetsInput>;
  numberOfClusters?: InputMaybe<Scalars['Int']['input']>;
  otherGroupLabel?: InputMaybe<Scalars['String']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};


export type QueryBundleArgs = {
  id: Scalars['ID']['input'];
};


export type QueryBundleFileArgs = {
  id: Scalars['ID']['input'];
};


export type QueryBundlesByIdsArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type QueryChatMessageArgs = {
  channelType: ChatMessageChannelType;
  id: Scalars['ID']['input'];
};


export type QueryChatMessagesArgs = {
  channelId: Scalars['ID']['input'];
  channelType: ChatMessageChannelType;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  parentId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryCommandStatusArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDistilledTranscriptArgs = {
  transcriptId: Scalars['ID']['input'];
};


export type QueryDistilledTranscriptsArgs = {
  filters?: InputMaybe<DistilledTranscriptFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<DistilledTranscriptOrderBy>>>;
};


export type QueryDocumentArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDocumentAccessControlListArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDocumentAccessControlListByObjectArgs = {
  documentId: Scalars['ID']['input'];
};


export type QueryDocumentAccessControlListByObjectAggregateArgs = {
  documentId: Scalars['ID']['input'];
};


export type QueryDocumentAccessControlListUserRolesArgs = {
  documentId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type QueryDocumentAggregateArgs = {
  filters?: InputMaybe<DocumentFilters>;
};


export type QueryDocumentChangeLogArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDocumentChangeLogAggregateArgs = {
  documentId: Scalars['ID']['input'];
};


export type QueryDocumentChangeLogsArgs = {
  documentId: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryDocumentContentArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDocumentSnapshotArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDocumentSnapshotAggregateArgs = {
  documentId: Scalars['ID']['input'];
};


export type QueryDocumentSnapshotContentArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDocumentSnapshotsArgs = {
  documentId: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryDocumentsArgs = {
  filters?: InputMaybe<DocumentFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<DocumentOrderBy>;
};


export type QueryDocumentsByIdsArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type QueryFileArgs = {
  id: Scalars['ID']['input'];
};


export type QueryFileAccessControlListArgs = {
  id: Scalars['ID']['input'];
};


export type QueryFileAccessControlListByObjectArgs = {
  fileId: Scalars['ID']['input'];
};


export type QueryFileAccessControlListByObjectAggregateArgs = {
  fileId: Scalars['ID']['input'];
};


export type QueryFileAccessControlListUserRolesArgs = {
  fileId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type QueryFileAggregateArgs = {
  filters?: InputMaybe<FileFilters>;
};


export type QueryFileBundleViewArgs = {
  filters?: InputMaybe<FileBundleViewFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<FileBundleViewOrderBy>;
};


export type QueryFileBundleViewAggregateArgs = {
  filters?: InputMaybe<FileBundleViewFilters>;
};


export type QueryFilesArgs = {
  filters?: InputMaybe<FileFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<FileOrderBy>;
};


export type QueryFilesByIdsArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type QueryGroupedAnalystQuestionsArgs = {
  facets?: InputMaybe<AnalystQuestionFacetsInput>;
  numberOfClusters?: InputMaybe<Scalars['Int']['input']>;
  otherGroupLabel?: InputMaybe<Scalars['String']['input']>;
  version: Scalars['String']['input'];
};


export type QueryHasChatMessageRoleArgs = {
  channelId: Scalars['ID']['input'];
  channelType: ChatMessageChannelType;
  messageId: Scalars['ID']['input'];
  roles: Array<ObjectRole>;
};


export type QueryHasDocumentRoleArgs = {
  documentId: Scalars['ID']['input'];
  roles: Array<ObjectRole>;
};


export type QueryHasFileRoleArgs = {
  fileId: Scalars['ID']['input'];
  roles: Array<ObjectRole>;
};


export type QueryHasRoleForDocumentsArgs = {
  documentIds: Array<Scalars['ID']['input']>;
  roles: Array<ObjectRole>;
};


export type QueryHasRoleForFilesArgs = {
  fileIds: Array<Scalars['ID']['input']>;
  roles: Array<ObjectRole>;
};


export type QueryHasWorkspaceRoleArgs = {
  roles: Array<ObjectRole>;
  workspaceId: Scalars['ID']['input'];
};


export type QueryLinkPropsArgs = {
  url: Scalars['String']['input'];
};


export type QueryNewsSummaryArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ResultsOrderBy>;
  queryText: Scalars['String']['input'];
};


export type QueryOrganizationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOrganizationsArgs = {
  fetchFromAstra?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OrganizationOrderBy>;
};


export type QueryPressReleaseArgs = {
  id: Scalars['ID']['input'];
};


export type QueryShapeDataArgs = {
  shapeId: Scalars['String']['input'];
  workspaceBoardId: Scalars['String']['input'];
};


export type QueryThemeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryThemeDiscussionAnalysesArgs = {
  filters?: InputMaybe<ThemeDiscussionAnalysisFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<ThemeDiscussionAnalysisOrderBy>>>;
};


export type QueryThemeDiscussionAnalysisArgs = {
  id: Scalars['ID']['input'];
};


export type QueryThemesArgs = {
  filters?: InputMaybe<ThemeFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<ThemeOrderBy>>>;
};


export type QueryTopicDiscussionExampleArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTopicDiscussionExampleAggregateArgs = {
  facets?: InputMaybe<TopicDiscussionExampleFacetsInput>;
  field: Scalars['String']['input'];
};


export type QueryTopicDiscussionExampleEventAggregateArgs = {
  facets?: InputMaybe<TopicDiscussionExampleFacetsInput>;
};


export type QueryTopicDiscussionExampleFieldDistinctValuesArgs = {
  facets?: InputMaybe<TopicDiscussionExampleFacetsInput>;
  field: Scalars['String']['input'];
};


export type QueryTopicDiscussionExampleFieldGroupArgs = {
  facets?: InputMaybe<TopicDiscussionExampleFacetsInput>;
  field: Scalars['String']['input'];
};


export type QueryTopicDiscussionExampleQuestionCountsAggregateArgs = {
  facets?: InputMaybe<TopicDiscussionExampleFacetsInput>;
};


export type QueryTopicDiscussionExamplesArgs = {
  facets?: InputMaybe<TopicDiscussionExampleFacetsInput>;
};


export type QueryTopicDiscussionInNewsArticleArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTopicDiscussionInNewsArticleAggregateArgs = {
  facets?: InputMaybe<TopicDiscussionInNewsArticleFacetsInput>;
  field: Scalars['String']['input'];
};


export type QueryTopicDiscussionInNewsArticleFieldDistinctValuesArgs = {
  facets?: InputMaybe<TopicDiscussionInNewsArticleFacetsInput>;
  field: Scalars['String']['input'];
};


export type QueryTopicDiscussionInNewsArticleFieldGroupArgs = {
  facets?: InputMaybe<TopicDiscussionInNewsArticleFacetsInput>;
  field: Scalars['String']['input'];
};


export type QueryTopicDiscussionInNewsArticlesArgs = {
  facets?: InputMaybe<TopicDiscussionInNewsArticleFacetsInput>;
};


export type QueryTopicDiscussionSummaryExampleArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTopicDiscussionSummaryExampleAggregateArgs = {
  facets?: InputMaybe<TopicDiscussionSummaryExampleFacetsInput>;
  field: Scalars['String']['input'];
};


export type QueryTopicDiscussionSummaryExampleEventAggregateArgs = {
  facets?: InputMaybe<TopicDiscussionSummaryExampleFacetsInput>;
};


export type QueryTopicDiscussionSummaryExampleFieldDistinctValuesArgs = {
  facets?: InputMaybe<TopicDiscussionSummaryExampleFacetsInput>;
  field: Scalars['String']['input'];
};


export type QueryTopicDiscussionSummaryExampleFieldGroupArgs = {
  facets?: InputMaybe<TopicDiscussionSummaryExampleFacetsInput>;
  field: Scalars['String']['input'];
};


export type QueryTopicDiscussionSummaryExampleQuestionMentionCountsAggregateArgs = {
  facets?: InputMaybe<TopicDiscussionSummaryExampleFacetsInput>;
};


export type QueryTopicDiscussionSummaryExamplesArgs = {
  facets?: InputMaybe<TopicDiscussionSummaryExampleFacetsInput>;
};


export type QueryTranscriptArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTranscriptsArgs = {
  filters?: InputMaybe<TranscriptFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<TranscriptOrderBy>>>;
};


export type QueryUserArgs = {
  id: Scalars['ID']['input'];
};


export type QueryUserGroupArgs = {
  id: Scalars['ID']['input'];
};


export type QueryUserGroupMemberArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  userGroupId?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryUserGroupMemberAggregateArgs = {
  userGroupId?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryUserGroupMembersArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserGroupMemberOrderBy>;
  userGroupId?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryUserGroupsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserGroupOrderBy>;
};


export type QueryUserPreferenceArgs = {
  key: Scalars['String']['input'];
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryUsersArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserOrderBy>;
};


export type QueryWorkspaceArgs = {
  id: Scalars['ID']['input'];
};


export type QueryWorkspaceAccessControlListArgs = {
  id: Scalars['ID']['input'];
};


export type QueryWorkspaceAccessControlListByObjectArgs = {
  workspaceId: Scalars['ID']['input'];
};


export type QueryWorkspaceAccessControlListByObjectAggregateArgs = {
  workspaceId: Scalars['ID']['input'];
};


export type QueryWorkspaceAccessControlListUserRolesArgs = {
  userId: Scalars['ID']['input'];
  workspaceId: Scalars['ID']['input'];
};


export type QueryWorkspaceAggregateArgs = {
  filters?: InputMaybe<WorkspaceFilters>;
};


export type QueryWorkspaceBoardArgs = {
  id: Scalars['ID']['input'];
};


export type QueryWorkspaceBoardAccessControlListUserRolesArgs = {
  userId: Scalars['ID']['input'];
  workspaceBoardId: Scalars['ID']['input'];
};


export type QueryWorkspaceBoardAggregateArgs = {
  filters?: InputMaybe<WorkspaceBoardFilters>;
  workspaceId: Scalars['ID']['input'];
};


export type QueryWorkspaceBoardShapeArgs = {
  shapeId: Scalars['String']['input'];
  workspaceBoardId: Scalars['ID']['input'];
};


export type QueryWorkspaceBoardShapesArgs = {
  shapeIds: Array<Scalars['String']['input']>;
  workspaceBoardId: Scalars['ID']['input'];
};


export type QueryWorkspaceBoardTableOfContentsArgs = {
  input: WorkspaceBoardTableOfContentsInput;
};


export type QueryWorkspaceBoardTableOfContentsReactionsArgs = {
  input: WorkspaceBoardTableOfContentsReactionsInput;
};


export type QueryWorkspaceBoardTagsArgs = {
  workspaceId: Scalars['ID']['input'];
};


export type QueryWorkspaceBoardsArgs = {
  filters?: InputMaybe<WorkspaceBoardFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<WorkspaceBoardOrderBy>;
  workspaceId: Scalars['ID']['input'];
};


export type QueryWorkspaceFileArgs = {
  id: Scalars['ID']['input'];
};


export type QueryWorkspaceFileAggregateArgs = {
  filters?: InputMaybe<WorkspaceFileFilters>;
  workspaceId: Scalars['ID']['input'];
};


export type QueryWorkspaceFilesArgs = {
  filters?: InputMaybe<WorkspaceFileFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<WorkspaceFileOrderBy>;
  workspaceId: Scalars['ID']['input'];
};


export type QueryWorkspacesArgs = {
  filters?: InputMaybe<WorkspaceFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<WorkspaceOrderBy>;
};

export type ReindexDocumentsInput = {
  documentIds: Array<Scalars['ID']['input']>;
};

export type ReindexDocumentsResult = {
  __typename?: 'ReindexDocumentsResult';
  /** The IDs of the documents that have been requested for re-indexing. */
  documentIds: Array<Scalars['ID']['output']>;
};

export type ReindexFilesInput = {
  fileIds: Array<Scalars['ID']['input']>;
};

export type ReindexFilesResult = {
  __typename?: 'ReindexFilesResult';
  /** The IDs of the files that have been requested for re-indexing. */
  fileIds: Array<Scalars['ID']['output']>;
};

export type ReindexWorkspaceInput = {
  id: Scalars['ID']['input'];
};

export type ReindexWorkspaceResult = {
  __typename?: 'ReindexWorkspaceResult';
  id: Scalars['ID']['output'];
};

export type ReplaceWorkspaceBoardTableOfContentsForShapeInput = {
  shapeId: Scalars['String']['input'];
  workspaceBoardId: Scalars['ID']['input'];
  workspaceBoardTableOfContents: Array<WorkspaceBoardTableOfContentsSubInput>;
};

export type ResultsOrderBy = {
  createdAt?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
};

export enum RowState {
  /** Shape data is in active use by the shape */
  Active = 'Active',
  /** Shape data is no longer in use */
  Deleted = 'Deleted'
}

export type ScoreItemOverrideElementType = {
  ancestorNames?: InputMaybe<Array<Scalars['String']['input']>>;
  distance?: InputMaybe<Scalars['Int']['input']>;
  indent?: InputMaybe<Scalars['Int']['input']>;
  isAllUppercase?: InputMaybe<Scalars['Boolean']['input']>;
  isBold?: InputMaybe<Scalars['Boolean']['input']>;
  isItalic?: InputMaybe<Scalars['Boolean']['input']>;
  isUnderline?: InputMaybe<Scalars['Boolean']['input']>;
  styles: Scalars['JSON']['input'];
  textLength?: InputMaybe<Scalars['Int']['input']>;
  textStyle?: InputMaybe<Scalars['String']['input']>;
};

export type ScoreItemOverrideType = {
  element: ScoreItemOverrideElementType;
  score: Scalars['Int']['input'];
};

export type ShapeData = {
  __typename?: 'ShapeData';
  createdAt: Scalars['Date']['output'];
  createdBy: Scalars['ID']['output'];
  data: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  rowState: RowState;
  shapeId: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
  updatedBy: Scalars['ID']['output'];
  workspaceBoardId: Scalars['ID']['output'];
};

export enum SortOrder {
  /** Ascending order */
  Asc = 'asc',
  /** Descending order */
  Desc = 'desc'
}

export type Speaker = {
  __typename?: 'Speaker';
  fullName: Scalars['String']['output'];
  role: Scalars['String']['output'];
};

export type SpeakerInput = {
  fullName?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  commandResponse?: Maybe<CommandResponse>;
  onChatMessageAdded?: Maybe<ChatMessage>;
  onChatMessageDeleted?: Maybe<DeleteResult>;
  onChatMessageUpdated?: Maybe<ChatMessage>;
  onCommandStatusChanged?: Maybe<CommandQueueStatus>;
  onFileAdded?: Maybe<FileUploadStatus>;
  onWorkspaceFileAdded?: Maybe<WorkspaceFileUploadStatus>;
};


export type SubscriptionCommandResponseArgs = {
  sessionId: Scalars['String']['input'];
};


export type SubscriptionOnChatMessageAddedArgs = {
  channelId: Scalars['ID']['input'];
  channelType: ChatMessageChannelType;
  parentId?: InputMaybe<Scalars['ID']['input']>;
};


export type SubscriptionOnChatMessageDeletedArgs = {
  channelId: Scalars['ID']['input'];
  channelType: ChatMessageChannelType;
  parentId?: InputMaybe<Scalars['ID']['input']>;
};


export type SubscriptionOnChatMessageUpdatedArgs = {
  channelId: Scalars['ID']['input'];
  channelType: ChatMessageChannelType;
  parentId?: InputMaybe<Scalars['ID']['input']>;
};


export type SubscriptionOnCommandStatusChangedArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionOnWorkspaceFileAddedArgs = {
  workspaceId: Scalars['ID']['input'];
};

export type SubscriptionChatMessageMutationEvent = SubscriptionEvent & {
  __typename?: 'SubscriptionChatMessageMutationEvent';
  chatMessage?: Maybe<ChatMessage>;
  mutationType: PubSubMutationType;
};

export type SubscriptionEvent = {
  mutationType: PubSubMutationType;
};

export type SushiCustomError = {
  __typename?: 'SushiCustomError';
  message?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Scalars['String']['output']>;
};

export type TestFilter = {
  __typename?: 'TestFilter';
  displayName: Scalars['String']['output'];
};

export type Theme = {
  __typename?: 'Theme';
  createdAt: Scalars['Date']['output'];
  createdBy: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
  updatedBy: Scalars['ID']['output'];
};

export type ThemeDiscussionAnalysis = {
  __typename?: 'ThemeDiscussionAnalysis';
  createdAt: Scalars['Date']['output'];
  createdBy: Scalars['ID']['output'];
  discussionDepth: Scalars['Float']['output'];
  discussionNature: Array<DiscussionNature>;
  displaySymbol: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  itemDate: Scalars['Date']['output'];
  itemId: Scalars['String']['output'];
  itemTitle: Scalars['String']['output'];
  participants: Array<TranscriptParticipant>;
  section: Scalars['String']['output'];
  summaries: Array<Scalars['String']['output']>;
  symbols: Array<Scalars['String']['output']>;
  themeId: Scalars['String']['output'];
  themeName: Scalars['String']['output'];
  topics: Array<Scalars['String']['output']>;
  topicsAnalysisItems: Array<TopicsAnalysis>;
  updatedAt: Scalars['Date']['output'];
  updatedBy: Scalars['ID']['output'];
};

export type ThemeDiscussionAnalysisFilters = {
  endDate?: InputMaybe<Scalars['String']['input']>;
  eventType?: InputMaybe<Scalars['String']['input']>;
  itemId?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
  symbols?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ThemeDiscussionAnalysisOrderBy = {
  itemDate?: InputMaybe<SortOrder>;
  itemTitle?: InputMaybe<SortOrder>;
  symbol?: InputMaybe<SortOrder>;
};

export type ThemeDiscussionAnalysisSearchResponse = {
  __typename?: 'ThemeDiscussionAnalysisSearchResponse';
  errors?: Maybe<Array<SushiCustomError>>;
  results?: Maybe<Array<ThemeDiscussionAnalysis>>;
  status: Scalars['String']['output'];
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type ThemeFilters = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type ThemeOrderBy = {
  name?: InputMaybe<SortOrder>;
};

export type ThemeSearchResponse = {
  __typename?: 'ThemeSearchResponse';
  errors?: Maybe<Array<SushiCustomError>>;
  results?: Maybe<Array<Theme>>;
  status: Scalars['String']['output'];
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type TopicDiscussionExample = {
  __typename?: 'TopicDiscussionExample';
  category?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  createdBy: Scalars['ID']['output'];
  detail: Scalars['String']['output'];
  event: Scalars['String']['output'];
  eventDate: Scalars['Date']['output'];
  eventType?: Maybe<Scalars['String']['output']>;
  explanation?: Maybe<Scalars['String']['output']>;
  firm?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isHidden: Scalars['Boolean']['output'];
  period?: Maybe<Scalars['String']['output']>;
  quarter?: Maybe<Scalars['Int']['output']>;
  question: Scalars['String']['output'];
  rating?: Maybe<Scalars['Float']['output']>;
  reportTitle?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
  section?: Maybe<Scalars['String']['output']>;
  segmentId?: Maybe<Scalars['String']['output']>;
  segmentType?: Maybe<Scalars['String']['output']>;
  sourceFormat?: Maybe<Scalars['String']['output']>;
  sourceId?: Maybe<Scalars['String']['output']>;
  sourceType?: Maybe<Scalars['String']['output']>;
  sourceUrl?: Maybe<Scalars['String']['output']>;
  /** Array of speaker info */
  speakers?: Maybe<Array<Speaker>>;
  summary: Scalars['String']['output'];
  ticker: Scalars['String']['output'];
  topic: Scalars['String']['output'];
  turn?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
  updatedBy: Scalars['ID']['output'];
  year?: Maybe<Scalars['Int']['output']>;
};

export type TopicDiscussionExampleAggregate = {
  __typename?: 'TopicDiscussionExampleAggregate';
  max?: Maybe<Scalars['Date']['output']>;
  min?: Maybe<Scalars['Date']['output']>;
};

export type TopicDiscussionExampleFacetsInput = {
  category?: InputMaybe<Array<Scalars['String']['input']>>;
  eventDate?: InputMaybe<DateRangeInput>;
  section?: InputMaybe<Array<Scalars['String']['input']>>;
  ticker?: InputMaybe<Array<Scalars['String']['input']>>;
  topic?: InputMaybe<Array<Scalars['String']['input']>>;
  type?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type TopicDiscussionExampleFieldGroupType = {
  __typename?: 'TopicDiscussionExampleFieldGroupType';
  category?: Maybe<Scalars['String']['output']>;
  count: Scalars['Int']['output'];
  section?: Maybe<Scalars['String']['output']>;
  ticker?: Maybe<Scalars['String']['output']>;
  topic?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type TopicDiscussionInNewsArticle = {
  __typename?: 'TopicDiscussionInNewsArticle';
  articleCount: Scalars['Int']['output'];
  createdAt: Scalars['Date']['output'];
  createdBy: Scalars['ID']['output'];
  date: Scalars['Date']['output'];
  headline: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isHidden: Scalars['Boolean']['output'];
  /** Array of new source info */
  newSources: Array<NewSource>;
  paragraph: Scalars['String']['output'];
  segmentId: Scalars['String']['output'];
  segmentType: Scalars['String']['output'];
  sourceType: Scalars['String']['output'];
  theme: Scalars['String']['output'];
  /** Array of topic discussion info */
  topicDiscussions: Array<ITopicDiscussion>;
  updatedAt: Scalars['Date']['output'];
  updatedBy: Scalars['ID']['output'];
};

export type TopicDiscussionInNewsArticleAggregate = {
  __typename?: 'TopicDiscussionInNewsArticleAggregate';
  max?: Maybe<Scalars['Date']['output']>;
  min?: Maybe<Scalars['Date']['output']>;
};

export type TopicDiscussionInNewsArticleFacetsInput = {
  date?: InputMaybe<DateRangeInput>;
  theme?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type TopicDiscussionInNewsArticleFieldGroupType = {
  __typename?: 'TopicDiscussionInNewsArticleFieldGroupType';
  count: Scalars['Int']['output'];
  theme?: Maybe<Scalars['String']['output']>;
};

export type TopicDiscussionInput = {
  summary: Scalars['String']['input'];
  topic: Scalars['String']['input'];
};

export type TopicDiscussionSummaryExample = {
  __typename?: 'TopicDiscussionSummaryExample';
  analysisName: Scalars['String']['output'];
  category: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  createdBy: Scalars['ID']['output'];
  detail: Scalars['String']['output'];
  event: Scalars['String']['output'];
  eventDate: Scalars['Date']['output'];
  eventType: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isHidden: Scalars['Boolean']['output'];
  period?: Maybe<Scalars['String']['output']>;
  quarter?: Maybe<Scalars['Int']['output']>;
  questionMentionCounts: Array<Scalars['Int']['output']>;
  questions: Array<Scalars['String']['output']>;
  rating?: Maybe<Scalars['Float']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
  section: Scalars['String']['output'];
  sourceFormat: Scalars['String']['output'];
  sourceId: Scalars['String']['output'];
  sourceUrl: Scalars['String']['output'];
  /** Array of speaker info */
  speakers: Array<Speaker>;
  summary: Scalars['String']['output'];
  ticker: Scalars['String']['output'];
  topic: Scalars['String']['output'];
  topicMentionCount: Scalars['Int']['output'];
  updatedAt: Scalars['Date']['output'];
  updatedBy: Scalars['ID']['output'];
  year: Scalars['Int']['output'];
};

export type TopicDiscussionSummaryExampleAggregate = {
  __typename?: 'TopicDiscussionSummaryExampleAggregate';
  max?: Maybe<Scalars['Date']['output']>;
  min?: Maybe<Scalars['Date']['output']>;
};

export type TopicDiscussionSummaryExampleFacetsInput = {
  analysisName?: InputMaybe<Array<Scalars['String']['input']>>;
  category?: InputMaybe<Array<Scalars['String']['input']>>;
  event?: InputMaybe<Array<Scalars['String']['input']>>;
  eventDate?: InputMaybe<DateRangeInput>;
  eventType?: InputMaybe<Array<Scalars['String']['input']>>;
  period?: InputMaybe<Array<Scalars['String']['input']>>;
  quarter?: InputMaybe<Array<Scalars['Int']['input']>>;
  rating?: InputMaybe<Array<Scalars['Float']['input']>>;
  section?: InputMaybe<Array<Scalars['String']['input']>>;
  ticker?: InputMaybe<Array<Scalars['String']['input']>>;
  topic?: InputMaybe<Array<Scalars['String']['input']>>;
  year?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type TopicDiscussionSummaryExampleFieldGroupType = {
  __typename?: 'TopicDiscussionSummaryExampleFieldGroupType';
  analysisName?: Maybe<Scalars['String']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  count: Scalars['Int']['output'];
  event?: Maybe<Scalars['String']['output']>;
  eventType?: Maybe<Scalars['String']['output']>;
  period?: Maybe<Scalars['String']['output']>;
  quarter?: Maybe<Scalars['Int']['output']>;
  rating?: Maybe<Scalars['Float']['output']>;
  section?: Maybe<Scalars['String']['output']>;
  ticker?: Maybe<Scalars['String']['output']>;
  topic?: Maybe<Scalars['String']['output']>;
  year?: Maybe<Scalars['Int']['output']>;
};

export type TopicsAnalysis = {
  __typename?: 'TopicsAnalysis';
  discussionDepth: Scalars['Float']['output'];
  discussionNature: Array<DiscussionNature>;
  participants: Array<TranscriptParticipant>;
  topic: Scalars['String']['output'];
};

export type Transcript = {
  __typename?: 'Transcript';
  audioUrl: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  createdBy: Scalars['ID']['output'];
  deduplicationId: Scalars['String']['output'];
  displaySymbol: Scalars['String']['output'];
  eventType: TranscriptEventType;
  id: Scalars['ID']['output'];
  importSourceDeduplicationId: Scalars['String']['output'];
  itemDate: Scalars['Date']['output'];
  participants: Array<TranscriptParticipant>;
  quarter: Scalars['Int']['output'];
  symbols: Array<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  transcriptItems: Array<TranscriptItem>;
  updatedAt: Scalars['Date']['output'];
  updatedBy: Scalars['ID']['output'];
  year: Scalars['Int']['output'];
};

export type TranscriptAggregate = {
  __typename?: 'TranscriptAggregate';
  maxItemDate?: Maybe<Scalars['String']['output']>;
  minItemDate?: Maybe<Scalars['String']['output']>;
};

export enum TranscriptEventType {
  Conference = 'Conference',
  EarningsCall = 'EarningsCall',
  Other = 'Other',
  ShareholderMeeting = 'ShareholderMeeting'
}

export type TranscriptFilters = {
  deduplicationId?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  eventType?: InputMaybe<Scalars['String']['input']>;
  importSourceDeduplicationId?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
  symbols?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type TranscriptItem = {
  __typename?: 'TranscriptItem';
  fullName: Scalars['String']['output'];
  itemIndex: Scalars['Int']['output'];
  occupationTitles: Array<OccupationTitle>;
  role: TranscriptRole;
  session: TranscriptSession;
  speech: Array<Scalars['String']['output']>;
};

export type TranscriptOrderBy = {
  displaySymbol?: InputMaybe<SortOrder>;
  itemDate?: InputMaybe<SortOrder>;
  quarter?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  year?: InputMaybe<SortOrder>;
};

export type TranscriptParticipant = {
  __typename?: 'TranscriptParticipant';
  description: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  occupationTitles: Array<OccupationTitle>;
  role: TranscriptRole;
};

export enum TranscriptRole {
  Analyst = 'Analyst',
  Executive = 'Executive',
  Operator = 'Operator',
  Other = 'Other'
}

export type TranscriptSearchResponse = {
  __typename?: 'TranscriptSearchResponse';
  errors?: Maybe<Array<SushiCustomError>>;
  results?: Maybe<Array<Transcript>>;
  status: Scalars['String']['output'];
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export enum TranscriptSession {
  ManagementDiscussion = 'ManagementDiscussion',
  Other = 'Other',
  QuestionAnswer = 'QuestionAnswer'
}

export type UpdateAnalystQuestionClusterAssignmentInput = {
  analystQuestionClusterId?: InputMaybe<Scalars['ID']['input']>;
  analystQuestionId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  score?: InputMaybe<Scalars['Int']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAnalystQuestionClusterInput = {
  id: Scalars['ID']['input'];
  idealizedQuestion?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAnalystQuestionInput = {
  eventDate?: InputMaybe<Scalars['Date']['input']>;
  firm?: InputMaybe<Scalars['String']['input']>;
  firmType?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  question?: InputMaybe<Scalars['String']['input']>;
  segment?: InputMaybe<Array<Scalars['String']['input']>>;
  topics?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateChatMessageInput = {
  channelType: ChatMessageChannelType;
  id: Scalars['ID']['input'];
  message: Scalars['String']['input'];
  metadata?: InputMaybe<Scalars['JSONObject']['input']>;
};

export type UpdateConfigKeyInput = {
  data: Scalars['String']['input'];
  key: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};

export type UpdateDocumentAccessControlListInput = {
  id: Scalars['ID']['input'];
  role: ObjectRole;
};

export type UpdateDocumentContentInput = {
  content: Scalars['String']['input'];
  contentType?: InputMaybe<Scalars['String']['input']>;
  documentId: Scalars['ID']['input'];
};

export type UpdateDocumentInput = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type UpdateFileAccessControlListInput = {
  id: Scalars['ID']['input'];
  role: ObjectRole;
};

export type UpdateFileInput = {
  filename?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  mimeType?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateShapeDataInput = {
  data?: InputMaybe<Scalars['String']['input']>;
  rowState?: InputMaybe<RowState>;
  shapeId: Scalars['String']['input'];
  workspaceBoardId: Scalars['String']['input'];
};

export type UpdateTopicDiscussionExampleInput = {
  category?: InputMaybe<Scalars['String']['input']>;
  detail?: InputMaybe<Scalars['String']['input']>;
  event?: InputMaybe<Scalars['String']['input']>;
  eventDate?: InputMaybe<Scalars['Date']['input']>;
  eventType?: InputMaybe<Scalars['String']['input']>;
  explanation?: InputMaybe<Scalars['String']['input']>;
  firm?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  period?: InputMaybe<Scalars['String']['input']>;
  quarter?: InputMaybe<Scalars['Int']['input']>;
  question?: InputMaybe<Scalars['String']['input']>;
  rating?: InputMaybe<Scalars['Float']['input']>;
  reportTitle?: InputMaybe<Scalars['String']['input']>;
  score?: InputMaybe<Scalars['Float']['input']>;
  section?: InputMaybe<Scalars['String']['input']>;
  segmentId?: InputMaybe<Scalars['String']['input']>;
  segmentType?: InputMaybe<Scalars['String']['input']>;
  sourceFormat?: InputMaybe<Scalars['String']['input']>;
  sourceId?: InputMaybe<Scalars['String']['input']>;
  sourceType?: InputMaybe<Scalars['String']['input']>;
  sourceUrl?: InputMaybe<Scalars['String']['input']>;
  /** Array of speaker info */
  speakers?: InputMaybe<Array<SpeakerInput>>;
  summary?: InputMaybe<Scalars['String']['input']>;
  ticker?: InputMaybe<Scalars['String']['input']>;
  topic?: InputMaybe<Scalars['String']['input']>;
  turn?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateTopicDiscussionInNewsArticleInput = {
  articleCount?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['Date']['input']>;
  headline?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of new source info */
  newSources?: InputMaybe<Array<NewSourceInput>>;
  paragraph?: InputMaybe<Scalars['String']['input']>;
  segmentId?: InputMaybe<Scalars['String']['input']>;
  segmentType?: InputMaybe<Scalars['String']['input']>;
  sourceType?: InputMaybe<Scalars['String']['input']>;
  theme?: InputMaybe<Scalars['String']['input']>;
  /** Array of topic discussion info */
  topicDiscussions?: InputMaybe<Array<TopicDiscussionInput>>;
};

export type UpdateTopicDiscussionSummaryExampleInput = {
  analysisName?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  detail?: InputMaybe<Scalars['String']['input']>;
  event?: InputMaybe<Scalars['String']['input']>;
  eventDate?: InputMaybe<Scalars['Date']['input']>;
  eventType?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  period?: InputMaybe<Scalars['String']['input']>;
  quarter?: InputMaybe<Scalars['Int']['input']>;
  questionMentionCounts?: InputMaybe<Array<Scalars['Int']['input']>>;
  questions?: InputMaybe<Array<Scalars['String']['input']>>;
  rating?: InputMaybe<Scalars['Float']['input']>;
  score?: InputMaybe<Scalars['Float']['input']>;
  section?: InputMaybe<Scalars['String']['input']>;
  sourceFormat?: InputMaybe<Scalars['String']['input']>;
  sourceId?: InputMaybe<Scalars['String']['input']>;
  sourceUrl?: InputMaybe<Scalars['String']['input']>;
  /** Array of speaker info */
  speakers?: InputMaybe<Array<SpeakerInput>>;
  summary?: InputMaybe<Scalars['String']['input']>;
  ticker?: InputMaybe<Scalars['String']['input']>;
  topic?: InputMaybe<Scalars['String']['input']>;
  topicMentionCount?: InputMaybe<Scalars['Int']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateUserGroupInput = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type UpdateUserGroupMemberInput = {
  id: Scalars['ID']['input'];
  userGroupId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type UpdateWorkspaceAccessControlListInput = {
  id: Scalars['ID']['input'];
  role: ObjectRole;
};

export type UpdateWorkspaceBoardCameraPositionSubInput = {
  x: Scalars['Float']['input'];
  y: Scalars['Float']['input'];
};

export type UpdateWorkspaceBoardInput = {
  cameraPosition?: InputMaybe<UpdateWorkspaceBoardCameraPositionSubInput>;
  content?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  isThumbnailLocked?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  schema?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<Scalars['Tag']['input']>>;
  thumbnail?: InputMaybe<Scalars['String']['input']>;
  zoomLevel?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateWorkspaceFileInput = {
  filename?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  mimeType?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateWorkspaceInput = {
  defaultWorkspaceBoardId?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<Scalars['Tag']['input']>>;
};

export type UploadFilesInput = {
  bundleId?: InputMaybe<Scalars['ID']['input']>;
  files: Array<UploadInput>;
};

export type UploadFilesResponse = {
  __typename?: 'UploadFilesResponse';
  failed: Array<FileFailedData>;
  succeeded: Array<FileData>;
};

export type UploadInput = {
  file: Scalars['Upload']['input'];
  fileSize: Scalars['Int']['input'];
  id: Scalars['ID']['input'];
};

export type UploadWorkspaceFileResponse = {
  __typename?: 'UploadWorkspaceFileResponse';
  failed: Array<WorkspaceFileFailedFile>;
  succeeded: Array<WorkspaceFileData>;
};

export type UploadWorkspaceFilesInput = {
  files: Array<UploadInput>;
  workspaceId: Scalars['ID']['input'];
};

export type UpsertUserPreferenceInput = {
  data: Scalars['JSONObject']['input'];
  key: Scalars['String']['input'];
  organizationId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertWorkspaceBoardTableOfContentsReactionInput = {
  reaction: Scalars['String']['input'];
  workspaceBoardTableOfContentsId: Scalars['ID']['input'];
};

export type User = {
  __typename?: 'User';
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  profilePictureUrl?: Maybe<Scalars['String']['output']>;
};

export type UserGroup = {
  __typename?: 'UserGroup';
  createdAt: Scalars['Date']['output'];
  createdBy: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  isSystem: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
  updatedBy: Scalars['ID']['output'];
};

export type UserGroupAggregate = {
  __typename?: 'UserGroupAggregate';
  count: Scalars['Int']['output'];
};

export type UserGroupMember = {
  __typename?: 'UserGroupMember';
  createdAt: Scalars['Date']['output'];
  createdBy: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['Date']['output'];
  updatedBy: Scalars['ID']['output'];
  user?: Maybe<User>;
  userGroup: UserGroup;
  userId: Scalars['ID']['output'];
};

export type UserGroupMemberAggregate = {
  __typename?: 'UserGroupMemberAggregate';
  count: Scalars['Int']['output'];
};

export type UserGroupMemberOrderBy = {
  createdAt?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type UserGroupOrderBy = {
  createdAt?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type UserOrderBy = {
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type UserPreference = {
  __typename?: 'UserPreference';
  createdAt: Scalars['Date']['output'];
  createdBy: Scalars['ID']['output'];
  data: Scalars['JSONObject']['output'];
  key: Scalars['ID']['output'];
  organizationId: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
  updatedBy: Scalars['ID']['output'];
  userId: Scalars['String']['output'];
};

export type Workspace = {
  __typename?: 'Workspace';
  createdAt: Scalars['Date']['output'];
  createdBy: Scalars['ID']['output'];
  defaultWorkspaceBoard?: Maybe<WorkspaceBoard>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  tags: Array<Scalars['String']['output']>;
  updatedAt: Scalars['Date']['output'];
  updatedBy: Scalars['ID']['output'];
  workspaceAccessControlList: Array<WorkspaceAccessControlList>;
  workspaceBoards: Array<WorkspaceBoard>;
  workspaceFiles: Array<WorkspaceFile>;
};

export type WorkspaceAccessControlList = {
  __typename?: 'WorkspaceAccessControlList';
  createdAt: Scalars['Date']['output'];
  createdBy: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  role: ObjectRole;
  updatedAt: Scalars['Date']['output'];
  updatedBy: Scalars['ID']['output'];
  user?: Maybe<User>;
  userGroup?: Maybe<UserGroup>;
  userId?: Maybe<Scalars['ID']['output']>;
  workspaceId: Scalars['ID']['output'];
};

export type WorkspaceAccessControlListAggregate = {
  __typename?: 'WorkspaceAccessControlListAggregate';
  count: Scalars['Int']['output'];
};

export type WorkspaceAccessControlListOrderBy = {
  createdAt?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type WorkspaceAccessControlListUserRoles = {
  __typename?: 'WorkspaceAccessControlListUserRoles';
  objectId: Scalars['String']['output'];
  roles: Array<ObjectRole>;
  userId: Scalars['String']['output'];
};

export type WorkspaceAggregate = {
  __typename?: 'WorkspaceAggregate';
  count: Scalars['Int']['output'];
};

export type WorkspaceBoard = {
  __typename?: 'WorkspaceBoard';
  content?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  createdBy: Scalars['ID']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isThumbnailLocked: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  schema?: Maybe<Scalars['String']['output']>;
  tags: Array<Scalars['String']['output']>;
  thumbnail?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Date']['output'];
  updatedBy: Scalars['ID']['output'];
  workspaceId: Scalars['ID']['output'];
};

export type WorkspaceBoardAggregate = {
  __typename?: 'WorkspaceBoardAggregate';
  count: Scalars['Int']['output'];
};

export type WorkspaceBoardAssetFile = {
  __typename?: 'WorkspaceBoardAssetFile';
  apiDownloadUrl: Scalars['String']['output'];
  assetDownloadUrl: Scalars['String']['output'];
  filename: Scalars['String']['output'];
  mimetype: Scalars['String']['output'];
  originalFilename: Scalars['String']['output'];
  storageLocationUrl: Scalars['String']['output'];
};

export type WorkspaceBoardFilters = {
  includeUntagged?: InputMaybe<Scalars['Boolean']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<Scalars['Tag']['input']>>;
};

export type WorkspaceBoardOrderBy = {
  createdAt?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type WorkspaceBoardTableOfContents = {
  __typename?: 'WorkspaceBoardTableOfContents';
  blockId: Scalars['String']['output'];
  children: Array<WorkspaceBoardTableOfContents>;
  createdAt: Scalars['Date']['output'];
  createdBy: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  reactions: Array<WorkspaceBoardTableOfContentsReaction>;
  shapeId: Scalars['String']['output'];
  sortOrder: Scalars['Int']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
  updatedBy: Scalars['ID']['output'];
  workspaceBoardId: Scalars['String']['output'];
};

export type WorkspaceBoardTableOfContentsInput = {
  shapeId: Scalars['String']['input'];
  workspaceBoardId: Scalars['ID']['input'];
};

export type WorkspaceBoardTableOfContentsReaction = {
  __typename?: 'WorkspaceBoardTableOfContentsReaction';
  createdAt: Scalars['Date']['output'];
  createdBy: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  reaction: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
  updatedBy: Scalars['ID']['output'];
  userId: Scalars['String']['output'];
};

export type WorkspaceBoardTableOfContentsReactionsInput = {
  workspaceBoardTableOfContentsId: Scalars['ID']['input'];
};

export type WorkspaceBoardTableOfContentsSubInput = {
  blockId: Scalars['String']['input'];
  parentBlockId?: InputMaybe<Scalars['ID']['input']>;
  sortOrder: Scalars['Int']['input'];
  title: Scalars['String']['input'];
};

export type WorkspaceFile = {
  __typename?: 'WorkspaceFile';
  createdAt: Scalars['Date']['output'];
  createdBy: Scalars['ID']['output'];
  filename: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  mimeType: Scalars['String']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
  updatedBy: Scalars['ID']['output'];
  url: Scalars['String']['output'];
  workspaceId: Scalars['ID']['output'];
};

export type WorkspaceFileAggregate = {
  __typename?: 'WorkspaceFileAggregate';
  count: Scalars['Int']['output'];
};

export type WorkspaceFileData = {
  __typename?: 'WorkspaceFileData';
  filename: Scalars['String']['output'];
  id: Scalars['String']['output'];
  mimetype: Scalars['String']['output'];
  originalFilename: Scalars['String']['output'];
  storageLocation: Scalars['String']['output'];
};

export type WorkspaceFileFailedFile = {
  __typename?: 'WorkspaceFileFailedFile';
  filename: Scalars['String']['output'];
  id: Scalars['String']['output'];
  mimetype: Scalars['String']['output'];
  originalFilename: Scalars['String']['output'];
  reason: Scalars['String']['output'];
  storageLocation: Scalars['String']['output'];
};

export type WorkspaceFileFilters = {
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};

export type WorkspaceFileOrderBy = {
  createdAt?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type WorkspaceFileUploadStatus = {
  __typename?: 'WorkspaceFileUploadStatus';
  error: Scalars['String']['output'];
  fileName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  mimeType: Scalars['String']['output'];
  totalSize: Scalars['Int']['output'];
  uploadStatus: Scalars['String']['output'];
  uploadedSize: Scalars['Int']['output'];
  userId: Scalars['ID']['output'];
  workspaceId: Scalars['ID']['output'];
};

export type WorkspaceFilters = {
  includeUntagged?: InputMaybe<Scalars['Boolean']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<Scalars['Tag']['input']>>;
};

export type WorkspaceOrderBy = {
  createdAt?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type Configs = {
  __typename?: 'configs';
  createdAt: Scalars['Date']['output'];
  data: Scalars['String']['output'];
  key: Scalars['String']['output'];
  mimeType: Scalars['String']['output'];
  organizationId: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
  version: Scalars['Int']['output'];
};

export type FilesAndDocumentsQueryVariables = Exact<{
  workspaceId: Scalars['ID']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  fileBundleViewOrderBy?: InputMaybe<FileBundleViewOrderBy>;
  workspaceFileOrderBy?: InputMaybe<WorkspaceFileOrderBy>;
  documentOrderBy?: InputMaybe<DocumentOrderBy>;
  fileBundleViewFilters?: InputMaybe<FileBundleViewFilters>;
  workspaceFileFilters?: InputMaybe<WorkspaceFileFilters>;
  documentFilters?: InputMaybe<DocumentFilters>;
}>;


export type FilesAndDocumentsQuery = { __typename?: 'Query', fileBundleView: Array<{ __typename?: 'FileBundleView', createdAt: any, id: string, mimeType?: string | null, name: string, type: FileBundleViewType }>, documents: Array<{ __typename?: 'Document', id: string, name: string }>, workspaceFiles: Array<{ __typename?: 'WorkspaceFile', createdAt: any, id: string, mimeType: string, name: string }> };

export type EmptyQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type EmptyQueryQuery = { __typename: 'Query' };

export type FeedTranscriptsTranscriptQueryVariables = Exact<{
  filters?: InputMaybe<TranscriptFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<TranscriptOrderBy>> | InputMaybe<TranscriptOrderBy>>;
}>;


export type FeedTranscriptsTranscriptQuery = { __typename?: 'Query', transcripts?: { __typename?: 'TranscriptSearchResponse', status: string, totalCount?: number | null, results?: Array<{ __typename?: 'Transcript', id: string, displaySymbol: string, eventType: TranscriptEventType, itemDate: any, title: string }> | null } | null, transcriptAggregate: { __typename?: 'TranscriptAggregate', minItemDate?: string | null, maxItemDate?: string | null } };

export type NewChatMessageFragment = { __typename?: 'ChatMessage', id: string, channelId: string, parentId?: string | null, messageType: ChatMessageType, message: string, privateRecipientIds?: Array<string> | null, createdAt: any, createdBy: string, updatedAt?: any | null, updatedBy?: string | null, deletedAt?: any | null, deletedBy?: string | null, childCount: number, attachments: Array<{ __typename?: 'ChatMessageAttachment', mimeType: string, name: string, storageLocationUrl: string }>, mentions: Array<{ __typename?: 'ChatMessageMention', targetId: string, name: string, mentionType: ChatMessageMentionType }>, reactions: Array<{ __typename?: 'ChatMessageReaction', reaction: string, userIds: Array<string> }> };

export type ThemeDiscussionAnalysisParentTranscriptQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ThemeDiscussionAnalysisParentTranscriptQuery = { __typename?: 'Query', transcript?: { __typename?: 'Transcript', id: string, displaySymbol: string, eventType: TranscriptEventType, itemDate: any } | null };

export type ThemeDiscussionAnalysisParentTranscriptsQueryVariables = Exact<{
  filters?: InputMaybe<TranscriptFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<TranscriptOrderBy>> | InputMaybe<TranscriptOrderBy>>;
}>;


export type ThemeDiscussionAnalysisParentTranscriptsQuery = { __typename?: 'Query', transcripts?: { __typename?: 'TranscriptSearchResponse', results?: Array<{ __typename?: 'Transcript', id: string, displaySymbol: string, eventType: TranscriptEventType, itemDate: any }> | null } | null };

export type CreateAnalysisFeedbackMutationVariables = Exact<{
  input: CreateAnalysisFeedbackInput;
}>;


export type CreateAnalysisFeedbackMutation = { __typename?: 'Mutation', createAnalysisFeedback?: { __typename?: 'AnalysisFeedback', id: string } | null };

export type UpdateAnalystQuestionMutationVariables = Exact<{
  input: UpdateAnalystQuestionInput;
}>;


export type UpdateAnalystQuestionMutation = { __typename?: 'Mutation', updateAnalystQuestion: { __typename?: 'AnalystQuestion', id: string, eventDate: any, firm: string, firmType: string, question: string, segment: Array<string>, topics: Array<string> } };

export type AnalystQuestionQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type AnalystQuestionQuery = { __typename?: 'Query', analystQuestion?: { __typename?: 'AnalystQuestion', id: string, eventDate: any, firm: string, firmType: string, question: string, segment: Array<string>, topics: Array<string> } | null };

export type AnalystQuestionsQueryVariables = Exact<{
  facets: AnalystQuestionFacetsInput;
  numberOfClusters?: InputMaybe<Scalars['Int']['input']>;
  otherGroupLabel: Scalars['String']['input'];
  version?: InputMaybe<Scalars['String']['input']>;
}>;


export type AnalystQuestionsQuery = { __typename?: 'Query', analystQuestions: Array<{ __typename?: 'AnalystQuestion', id: string, eventDate: any, firm: string, firmType: string, question: string, segment: Array<string>, topics: Array<string>, group?: string | null, analystQuestionClusterAssignment: Array<{ __typename?: 'AnalystQuestionClusterAssignment', analystQuestionCluster: { __typename?: 'AnalystQuestionCluster', name: string } }> }> };

export type GroupedAnalystQuestionsQueryVariables = Exact<{
  facets: AnalystQuestionFacetsInput;
  numberOfClusters: Scalars['Int']['input'];
  otherGroupLabel: Scalars['String']['input'];
  version: Scalars['String']['input'];
}>;


export type GroupedAnalystQuestionsQuery = { __typename?: 'Query', groupedAnalystQuestions: Array<{ __typename?: 'GroupedAnalystQuestion', id: string, eventDate: any, firm: string, firmType: string, question: string, segment: Array<string>, topics: Array<string>, group: string }> };

export type CreateBundleMutationVariables = Exact<{
  input: CreateBundleInput;
}>;


export type CreateBundleMutation = { __typename?: 'Mutation', createBundle?: { __typename?: 'Bundle', id: string, name: string, type: string } | null };

export type BulkDeleteBundleMutationVariables = Exact<{
  input: BulkDeleteBundleInput;
}>;


export type BulkDeleteBundleMutation = { __typename?: 'Mutation', bulkDeleteBundle: { __typename?: 'BulkDeleteResult', count: number } };

export type BundlesByIdsQueryVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;


export type BundlesByIdsQuery = { __typename?: 'Query', bundlesByIds?: Array<{ __typename?: 'Bundle', name: string, bundleFile: Array<{ __typename?: 'BundleFile', file: { __typename?: 'File', name: string } }> }> | null };

export type CreateChatMessageMutationVariables = Exact<{
  input: CreateChatMessageInput;
}>;


export type CreateChatMessageMutation = { __typename?: 'Mutation', createChatMessage: { __typename?: 'ChatMessage', id: string, channelId: string, messageType: ChatMessageType, message: string, privateRecipientIds?: Array<string> | null, createdAt: any, createdBy: string, updatedAt?: any | null, updatedBy?: string | null, attachments: Array<{ __typename?: 'ChatMessageAttachment', mimeType: string, name: string, storageLocationUrl: string }>, mentions: Array<{ __typename?: 'ChatMessageMention', targetId: string, name: string, mentionType: ChatMessageMentionType }>, reactions: Array<{ __typename?: 'ChatMessageReaction', reaction: string, userIds: Array<string> }> } };

export type UpdateChatMessageMutationVariables = Exact<{
  input: UpdateChatMessageInput;
}>;


export type UpdateChatMessageMutation = { __typename?: 'Mutation', updateChatMessage: { __typename?: 'ChatMessage', id: string, message: string, createdAt: any, createdBy: string, updatedAt?: any | null, updatedBy?: string | null, updateCount: number } };

export type DeleteChatMessageMutationVariables = Exact<{
  input: DeleteChatMessageInput;
}>;


export type DeleteChatMessageMutation = { __typename?: 'Mutation', softOrHardDeleteChatMessage: { __typename?: 'DeleteResult', id: string } };

export type ChatMessageQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  channelType: ChatMessageChannelType;
}>;


export type ChatMessageQuery = { __typename?: 'Query', chatMessage?: { __typename?: 'ChatMessage', id: string, channelId: string, parentId?: string | null, messageType: ChatMessageType, message: string, privateRecipientIds?: Array<string> | null, createdAt: any, createdBy: string, updatedAt?: any | null, updatedBy?: string | null, deletedAt?: any | null, deletedBy?: string | null, childCount: number, attachments: Array<{ __typename?: 'ChatMessageAttachment', mimeType: string, name: string, storageLocationUrl: string }>, mentions: Array<{ __typename?: 'ChatMessageMention', targetId: string, name: string, mentionType: ChatMessageMentionType }>, reactions: Array<{ __typename?: 'ChatMessageReaction', reaction: string, userIds: Array<string> }> } | null };

export type ChatMessagesQueryVariables = Exact<{
  channelId: Scalars['ID']['input'];
  channelType: ChatMessageChannelType;
  parentId?: InputMaybe<Scalars['ID']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;


export type ChatMessagesQuery = { __typename?: 'Query', chatMessages: Array<{ __typename?: 'ChatMessage', id: string, channelId: string, parentId?: string | null, messageType: ChatMessageType, message: string, privateRecipientIds?: Array<string> | null, createdAt: any, createdBy: string, updatedAt?: any | null, updatedBy?: string | null, deletedAt?: any | null, deletedBy?: string | null, childCount: number, attachments: Array<{ __typename?: 'ChatMessageAttachment', mimeType: string, name: string, storageLocationUrl: string }>, mentions: Array<{ __typename?: 'ChatMessageMention', targetId: string, name: string, mentionType: ChatMessageMentionType }>, reactions: Array<{ __typename?: 'ChatMessageReaction', reaction: string, userIds: Array<string> }> }> };

export type OnChatMessagesAddedSubscriptionVariables = Exact<{
  channelId: Scalars['ID']['input'];
  channelType: ChatMessageChannelType;
  parentId?: InputMaybe<Scalars['ID']['input']>;
}>;


export type OnChatMessagesAddedSubscription = { __typename?: 'Subscription', onChatMessageAdded?: { __typename?: 'ChatMessage', id: string, channelId: string, parentId?: string | null, messageType: ChatMessageType, message: string, privateRecipientIds?: Array<string> | null, createdAt: any, createdBy: string, updatedAt?: any | null, updatedBy?: string | null, deletedAt?: any | null, deletedBy?: string | null, childCount: number, attachments: Array<{ __typename?: 'ChatMessageAttachment', mimeType: string, name: string, storageLocationUrl: string }>, mentions: Array<{ __typename?: 'ChatMessageMention', targetId: string, name: string, mentionType: ChatMessageMentionType }>, reactions: Array<{ __typename?: 'ChatMessageReaction', reaction: string, userIds: Array<string> }> } | null };

export type OnChatMessagesUpdatedSubscriptionVariables = Exact<{
  channelId: Scalars['ID']['input'];
  channelType: ChatMessageChannelType;
  parentId?: InputMaybe<Scalars['ID']['input']>;
}>;


export type OnChatMessagesUpdatedSubscription = { __typename?: 'Subscription', onChatMessageUpdated?: { __typename?: 'ChatMessage', id: string, channelId: string, parentId?: string | null, messageType: ChatMessageType, message: string, privateRecipientIds?: Array<string> | null, createdAt: any, createdBy: string, updatedAt?: any | null, updatedBy?: string | null, deletedAt?: any | null, deletedBy?: string | null, childCount: number, attachments: Array<{ __typename?: 'ChatMessageAttachment', mimeType: string, name: string, storageLocationUrl: string }>, mentions: Array<{ __typename?: 'ChatMessageMention', targetId: string, name: string, mentionType: ChatMessageMentionType }>, reactions: Array<{ __typename?: 'ChatMessageReaction', reaction: string, userIds: Array<string> }> } | null };

export type OnChatMessagesDeletedSubscriptionVariables = Exact<{
  channelId: Scalars['ID']['input'];
  channelType: ChatMessageChannelType;
  parentId?: InputMaybe<Scalars['ID']['input']>;
}>;


export type OnChatMessagesDeletedSubscription = { __typename?: 'Subscription', onChatMessageDeleted?: { __typename?: 'DeleteResult', id: string } | null };

export type ExecuteCommandMutationVariables = Exact<{
  input: ExecuteCommandInput;
}>;


export type ExecuteCommandMutation = { __typename?: 'Mutation', executeCommand?: { __typename?: 'CommandResponse', data?: string | null, errors: Array<string>, requestId: any, sessionId: any, status: string } | null };

export type CommandPingMutationVariables = Exact<{
  input: ExecuteCommandInput;
}>;


export type CommandPingMutation = { __typename?: 'Mutation', executePingCommand?: { __typename?: 'CommandResponse', data?: string | null, errors: Array<string>, requestId: any, sessionId: any, status: string } | null };

export type OnCommandResponseSubscriptionVariables = Exact<{
  sessionId: Scalars['String']['input'];
}>;


export type OnCommandResponseSubscription = { __typename?: 'Subscription', commandResponse?: { __typename?: 'CommandResponse', activeCommandContext: any, callerData?: string | null, data?: string | null, errors: Array<string>, requestId: any, status: string, sessionId: any } | null };

export type CancelCommandMutationVariables = Exact<{
  input: CancelCommandInput;
}>;


export type CancelCommandMutation = { __typename?: 'Mutation', cancelCommand?: { __typename?: 'CommandQueueStatus', status: string } | null };

export type CommandStatusQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type CommandStatusQuery = { __typename?: 'Query', commandStatus?: { __typename?: 'CommandQueueStatus', commandId: string, requestId: any, status: string } | null };

export type OnCommandStatusChangedSubscriptionVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type OnCommandStatusChangedSubscription = { __typename?: 'Subscription', onCommandStatusChanged?: { __typename?: 'CommandQueueStatus', commandId: string, requestId: any, status: string } | null };

export type GetLatestConfigsByPrefixQueryVariables = Exact<{
  keyPrefix: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
}>;


export type GetLatestConfigsByPrefixQuery = { __typename?: 'Query', LatestConfigsByPrefix?: Array<{ __typename?: 'configs', data: string }> | null };

export type GetConfigDataQueryVariables = Exact<{
  key: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
}>;


export type GetConfigDataQuery = { __typename?: 'Query', Config?: { __typename?: 'configs', data: string } | null };

export type GetAppConfigDataQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
  baseDocumentStylesheetKey: Scalars['ID']['input'];
  organizationDocumentStylesheetKey: Scalars['ID']['input'];
  organizationPreferencesKey: Scalars['ID']['input'];
}>;


export type GetAppConfigDataQuery = { __typename?: 'Query', BaseDocumentStylesheet?: { __typename?: 'configs', data: string } | null, OrganizationDocumentStylesheet?: { __typename?: 'configs', data: string } | null, OrganizationPreferences?: { __typename?: 'configs', data: string } | null };

export type DistilledTranscriptQueryVariables = Exact<{
  transcriptId: Scalars['ID']['input'];
}>;


export type DistilledTranscriptQuery = { __typename?: 'Query', distilledTranscript?: { __typename?: 'DistilledTranscript', id: string, displaySymbol: string, eventType: TranscriptEventType, itemDate: any, title: string, distilledSegments: Array<{ __typename?: 'DistilledTranscriptSegment', segmentIndex: number, session: string, distilledTranscriptItems: Array<{ __typename?: 'TranscriptItem', itemIndex: number, fullName: string, occupationTitles: Array<OccupationTitle>, role: TranscriptRole, session: TranscriptSession, speech: Array<string> }>, originalTranscriptItems: Array<{ __typename?: 'TranscriptItem', itemIndex: number, fullName: string, occupationTitles: Array<OccupationTitle>, role: TranscriptRole, session: TranscriptSession, speech: Array<string> }> }> } | null };

export type CreateDocumentMutationVariables = Exact<{
  input: CreateDocumentInput;
}>;


export type CreateDocumentMutation = { __typename?: 'Mutation', createDocument?: { __typename?: 'Document', id: string, name: string, createdAt: any, createdBy: string, updatedAt: any, updatedBy: string, content: { __typename?: 'DocumentContent', id: string, content: string, contentType: string } } | null };

export type UpdateDocumentMutationVariables = Exact<{
  input: UpdateDocumentInput;
}>;


export type UpdateDocumentMutation = { __typename?: 'Mutation', updateDocument: { __typename?: 'Document', id: string, name: string, createdAt: any, createdBy: string, updatedAt: any, updatedBy: string } };

export type DeleteDocumentMutationVariables = Exact<{
  input: DeleteDocumentInput;
}>;


export type DeleteDocumentMutation = { __typename?: 'Mutation', deleteDocument: { __typename?: 'DeleteResult', id: string } };

export type BulkDeleteDocumentMutationVariables = Exact<{
  input: BulkDeleteDocumentInput;
}>;


export type BulkDeleteDocumentMutation = { __typename?: 'Mutation', bulkDeleteDocument: { __typename?: 'BulkDeleteResult', count: number } };

export type EditDocumentsInWorkspaceMutationVariables = Exact<{
  input: EditDocumentsInWorkspaceInput;
}>;


export type EditDocumentsInWorkspaceMutation = { __typename?: 'Mutation', editDocumentsInWorkspace?: { __typename?: 'Workspace', id: string, description?: string | null, name: string, tags: Array<string>, createdAt: any, createdBy: string, updatedAt: any, updatedBy: string, defaultWorkspaceBoard?: { __typename?: 'WorkspaceBoard', id: string, name: string, tags: Array<string>, thumbnail?: string | null } | null } | null };

export type ReindexDocumentsMutationVariables = Exact<{
  input: ReindexDocumentsInput;
}>;


export type ReindexDocumentsMutation = { __typename?: 'Mutation', reindexDocuments: { __typename?: 'ReindexDocumentsResult', documentIds: Array<string> } };

export type DocumentQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DocumentQuery = { __typename?: 'Query', document?: { __typename?: 'Document', id: string, name: string } | null };

export type DocumentsQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<DocumentOrderBy>;
  filters?: InputMaybe<DocumentFilters>;
}>;


export type DocumentsQuery = { __typename?: 'Query', documents: Array<{ __typename?: 'Document', id: string, name: string, documentAccessControlList: Array<{ __typename?: 'DocumentAccessControlList', userGroup?: { __typename?: 'UserGroup', name: string } | null, user?: { __typename?: 'User', id: string, name: string } | null }> }>, documentAggregate: { __typename?: 'DocumentAggregate', count: number } };

export type DocumentNamesByIdsQueryVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;


export type DocumentNamesByIdsQuery = { __typename?: 'Query', documentsByIds?: Array<{ __typename?: 'Document', name: string }> | null };

export type DocumentContentQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DocumentContentQuery = { __typename?: 'Query', document?: { __typename?: 'Document', content: { __typename?: 'DocumentContent', content: string } } | null };

export type CreateDocumentAccessControlListMutationVariables = Exact<{
  input: CreateDocumentAccessControlListInput;
}>;


export type CreateDocumentAccessControlListMutation = { __typename?: 'Mutation', createDocumentAccessControlList?: { __typename?: 'DocumentAccessControlList', id: string, documentId: string, role: ObjectRole, createdAt: any, createdBy: string, updatedAt: any, updatedBy: string, userGroup?: { __typename?: 'UserGroup', id: string } | null, user?: { __typename?: 'User', id: string, name: string, profilePictureUrl?: string | null } | null } | null };

export type UpdateDocumentAccessControlListMutationVariables = Exact<{
  input: UpdateDocumentAccessControlListInput;
}>;


export type UpdateDocumentAccessControlListMutation = { __typename?: 'Mutation', updateDocumentAccessControlList: { __typename?: 'DocumentAccessControlList', id: string, documentId: string, role: ObjectRole, createdAt: any, createdBy: string, updatedAt: any, updatedBy: string, userGroup?: { __typename?: 'UserGroup', id: string } | null, user?: { __typename?: 'User', id: string, name: string, profilePictureUrl?: string | null } | null } };

export type DeleteDocumentAccessControlListMutationVariables = Exact<{
  input: DeleteDocumentAccessControlListInput;
}>;


export type DeleteDocumentAccessControlListMutation = { __typename?: 'Mutation', deleteDocumentAccessControlList: { __typename?: 'DeleteResult', id: string } };

export type DocumentAccessControlListHasRoleQueryVariables = Exact<{
  documentIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  roles: Array<ObjectRole> | ObjectRole;
}>;


export type DocumentAccessControlListHasRoleQuery = { __typename?: 'Query', hasRoleForDocuments: boolean };

export type DocumentAccessControlListQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DocumentAccessControlListQuery = { __typename?: 'Query', documentAccessControlList?: { __typename?: 'DocumentAccessControlList', id: string, documentId: string, role: ObjectRole, createdAt: any, createdBy: string, updatedAt: any, updatedBy: string, userGroup?: { __typename?: 'UserGroup', id: string, name: string, isSystem: boolean } | null, user?: { __typename?: 'User', id: string, name: string, profilePictureUrl?: string | null } | null } | null };

export type DocumentAccessControlListByObjectQueryVariables = Exact<{
  documentId: Scalars['ID']['input'];
}>;


export type DocumentAccessControlListByObjectQuery = { __typename?: 'Query', documentAccessControlListByObject: Array<{ __typename?: 'DocumentAccessControlList', id: string, documentId: string, role: ObjectRole, createdAt: any, createdBy: string, updatedAt: any, updatedBy: string, userGroup?: { __typename?: 'UserGroup', id: string, name: string, isSystem: boolean } | null, user?: { __typename?: 'User', id: string, name: string, profilePictureUrl?: string | null } | null }>, documentAccessControlListByObjectAggregate: { __typename?: 'DocumentAccessControlListAggregate', count: number } };

export type DocumentAccessControlListUserRolesQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
  documentId: Scalars['ID']['input'];
}>;


export type DocumentAccessControlListUserRolesQuery = { __typename?: 'Query', documentAccessControlListUserRoles: { __typename?: 'DocumentAccessControlListUserRoles', userId: string, objectId: string, roles: Array<ObjectRole> } };

export type UpdateDocumentContentMutationVariables = Exact<{
  input: UpdateDocumentContentInput;
}>;


export type UpdateDocumentContentMutation = { __typename?: 'Mutation', updateDocumentContent: { __typename?: 'DocumentContent', id: string } };

export type CreateFileMutationVariables = Exact<{
  input: CreateFileInput;
}>;


export type CreateFileMutation = { __typename?: 'Mutation', createFile?: { __typename?: 'File', id: string, name: string, url: string, filename: string, mimeType: string, createdAt: any, createdBy: string, updatedAt: any, updatedBy: string } | null };

export type UpdateFileMutationVariables = Exact<{
  input: UpdateFileInput;
}>;


export type UpdateFileMutation = { __typename?: 'Mutation', updateFile: { __typename?: 'File', id: string, name: string, createdAt: any, createdBy: string, updatedAt: any, updatedBy: string } };

export type DeleteFileMutationVariables = Exact<{
  input: DeleteFileInput;
}>;


export type DeleteFileMutation = { __typename?: 'Mutation', deleteFile: { __typename?: 'DeleteResult', id: string } };

export type BulkDeleteFileMutationVariables = Exact<{
  input: BulkDeleteFileInput;
}>;


export type BulkDeleteFileMutation = { __typename?: 'Mutation', bulkDeleteFile: { __typename?: 'BulkDeleteResult', count: number } };

export type EditFilesInWorkspaceMutationVariables = Exact<{
  input: EditFilesInWorkspaceInput;
}>;


export type EditFilesInWorkspaceMutation = { __typename?: 'Mutation', editFilesInWorkspace?: { __typename?: 'Workspace', id: string, description?: string | null, name: string, tags: Array<string>, createdAt: any, createdBy: string, updatedAt: any, updatedBy: string, defaultWorkspaceBoard?: { __typename?: 'WorkspaceBoard', id: string, name: string, tags: Array<string>, thumbnail?: string | null } | null } | null };

export type ReindexFilesMutationVariables = Exact<{
  input: ReindexFilesInput;
}>;


export type ReindexFilesMutation = { __typename?: 'Mutation', reindexFiles: { __typename?: 'ReindexFilesResult', fileIds: Array<string> } };

export type FileQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type FileQuery = { __typename?: 'Query', file?: { __typename?: 'File', id: string, name: string, createdAt: any } | null };

export type FilesQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<FileOrderBy>;
  filters?: InputMaybe<FileFilters>;
}>;


export type FilesQuery = { __typename?: 'Query', files: Array<{ __typename?: 'File', id: string, name: string, url: string, filename: string, mimeType: string, createdAt: any, fileAccessControlList: Array<{ __typename?: 'FileAccessControlList', userGroup?: { __typename?: 'UserGroup', name: string } | null, user?: { __typename?: 'User', id: string, name: string } | null }>, bundleFile: Array<{ __typename?: 'BundleFile', bundle: { __typename?: 'Bundle', name: string } }> }>, fileAggregate: { __typename?: 'FileAggregate', count: number } };

export type FilesByIdsQueryVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;


export type FilesByIdsQuery = { __typename?: 'Query', filesByIds?: Array<{ __typename?: 'File', id: string, name: string, bundleFile: Array<{ __typename?: 'BundleFile', bundleId: string }> }> | null };

export type CreateFileAccessControlListMutationVariables = Exact<{
  input: CreateFileAccessControlListInput;
}>;


export type CreateFileAccessControlListMutation = { __typename?: 'Mutation', createFileAccessControlList?: { __typename?: 'FileAccessControlList', id: string, fileId: string, role: ObjectRole, userId?: string | null, createdAt: any, createdBy: string, updatedAt: any, updatedBy: string, userGroup?: { __typename?: 'UserGroup', id: string } | null } | null };

export type UpdateFileAccessControlListMutationVariables = Exact<{
  input: UpdateFileAccessControlListInput;
}>;


export type UpdateFileAccessControlListMutation = { __typename?: 'Mutation', updateFileAccessControlList: { __typename?: 'FileAccessControlList', id: string, fileId: string, role: ObjectRole, userId?: string | null, createdAt: any, createdBy: string, updatedAt: any, updatedBy: string, userGroup?: { __typename?: 'UserGroup', id: string } | null } };

export type DeleteFileAccessControlListMutationVariables = Exact<{
  input: DeleteFileAccessControlListInput;
}>;


export type DeleteFileAccessControlListMutation = { __typename?: 'Mutation', deleteFileAccessControlList: { __typename?: 'DeleteResult', id: string } };

export type FileAccessControlListHasRoleQueryVariables = Exact<{
  fileIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  roles: Array<ObjectRole> | ObjectRole;
}>;


export type FileAccessControlListHasRoleQuery = { __typename?: 'Query', hasRoleForFiles: boolean };

export type FileAccessControlListQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type FileAccessControlListQuery = { __typename?: 'Query', fileAccessControlList?: { __typename?: 'FileAccessControlList', id: string, fileId: string, role: ObjectRole, createdAt: any, createdBy: string, updatedAt: any, updatedBy: string, userGroup?: { __typename?: 'UserGroup', id: string, name: string, isSystem: boolean } | null, user?: { __typename?: 'User', id: string, name: string, profilePictureUrl?: string | null } | null } | null };

export type FileAccessControlListByObjectQueryVariables = Exact<{
  fileId: Scalars['ID']['input'];
}>;


export type FileAccessControlListByObjectQuery = { __typename?: 'Query', fileAccessControlListByObject: Array<{ __typename?: 'FileAccessControlList', id: string, fileId: string, role: ObjectRole, createdAt: any, createdBy: string, updatedAt: any, updatedBy: string, userGroup?: { __typename?: 'UserGroup', id: string, name: string, isSystem: boolean } | null, user?: { __typename?: 'User', id: string, name: string, profilePictureUrl?: string | null } | null }>, fileAccessControlListByObjectAggregate: { __typename?: 'FileAccessControlListAggregate', count: number } };

export type FileAccessControlListUserRolesQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
  fileId: Scalars['ID']['input'];
}>;


export type FileAccessControlListUserRolesQuery = { __typename?: 'Query', fileAccessControlListUserRoles: { __typename?: 'FileAccessControlListUserRoles', userId: string, objectId: string, roles: Array<ObjectRole> } };

export type FileBundleViewQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<FileBundleViewOrderBy>;
  filters?: InputMaybe<FileBundleViewFilters>;
}>;


export type FileBundleViewQuery = { __typename?: 'Query', fileBundleView: Array<{ __typename?: 'FileBundleView', createdAt: any, id: string, mimeType?: string | null, name: string, type: FileBundleViewType }>, fileBundleViewAggregate: { __typename?: 'FileBundleViewAggregate', count: number } };

export type LinkPropsQueryVariables = Exact<{
  url: Scalars['String']['input'];
}>;


export type LinkPropsQuery = { __typename?: 'Query', linkProps?: { __typename?: 'LinkPropsType', isEmbeddable: boolean } | null };

export type OrganizationQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type OrganizationQuery = { __typename?: 'Query', organization?: { __typename?: 'Organization', id: string, name: string } | null };

export type OrganizationsQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OrganizationOrderBy>;
}>;


export type OrganizationsQuery = { __typename?: 'Query', organizations?: Array<{ __typename?: 'Organization', id: string, name: string }> | null };

export type ProcessHtmlMutationVariables = Exact<{
  sourceDocument: Scalars['String']['input'];
  preset?: InputMaybe<Scalars['String']['input']>;
  analysisOverride?: InputMaybe<AnalysisResultOverrideType>;
  analysisOverrideConfig?: InputMaybe<Scalars['String']['input']>;
}>;


export type ProcessHtmlMutation = { __typename?: 'Mutation', processHtml: { __typename?: 'ProcessHtmlResponseType', resultDocument: string } };

export type CreateShapeDataMutationVariables = Exact<{
  input: CreateShapeDataInput;
}>;


export type CreateShapeDataMutation = { __typename?: 'Mutation', createShapeData?: { __typename?: 'ShapeData', id: string, data: string, rowState: RowState, shapeId: string, workspaceBoardId: string } | null };

export type CopyShapeDataMutationVariables = Exact<{
  input: CopyShapeDataInput;
}>;


export type CopyShapeDataMutation = { __typename?: 'Mutation', copyShapeData?: { __typename?: 'ShapeData', id: string, data: string, rowState: RowState, shapeId: string, workspaceBoardId: string } | null };

export type UpdateShapeDataMutationVariables = Exact<{
  input: UpdateShapeDataInput;
}>;


export type UpdateShapeDataMutation = { __typename?: 'Mutation', updateShapeData?: { __typename?: 'ShapeData', id: string, data: string, rowState: RowState, shapeId: string, workspaceBoardId: string } | null };

export type ShapeDataQueryVariables = Exact<{
  shapeId: Scalars['String']['input'];
  workspaceBoardId: Scalars['String']['input'];
}>;


export type ShapeDataQuery = { __typename?: 'Query', shapeData?: { __typename?: 'ShapeData', id: string, data: string, rowState: RowState, shapeId: string, workspaceBoardId: string } | null };

export type ThemeDiscussionAnalysesQueryVariables = Exact<{
  filters?: InputMaybe<ThemeDiscussionAnalysisFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<ThemeDiscussionAnalysisOrderBy>> | InputMaybe<ThemeDiscussionAnalysisOrderBy>>;
}>;


export type ThemeDiscussionAnalysesQuery = { __typename?: 'Query', themeDiscussionAnalyses?: { __typename?: 'ThemeDiscussionAnalysisSearchResponse', status: string, totalCount?: number | null, results?: Array<{ __typename?: 'ThemeDiscussionAnalysis', id: string, discussionDepth: number, discussionNature: Array<DiscussionNature>, displaySymbol: string, itemDate: any, itemId: string, itemTitle: string, section: string, summaries: Array<string>, symbols: Array<string>, themeId: string, themeName: string, topics: Array<string>, participants: Array<{ __typename?: 'TranscriptParticipant', description: string, fullName: string, occupationTitles: Array<OccupationTitle>, role: TranscriptRole }>, topicsAnalysisItems: Array<{ __typename?: 'TopicsAnalysis', topic: string, discussionDepth: number, discussionNature: Array<DiscussionNature>, participants: Array<{ __typename?: 'TranscriptParticipant', description: string, fullName: string, occupationTitles: Array<OccupationTitle>, role: TranscriptRole }> }> }> | null } | null };

export type ThemeDiscussionAnalysisQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ThemeDiscussionAnalysisQuery = { __typename?: 'Query', themeDiscussionAnalysis?: { __typename?: 'ThemeDiscussionAnalysis', id: string, discussionDepth: number, discussionNature: Array<DiscussionNature>, displaySymbol: string, itemDate: any, itemId: string, itemTitle: string, section: string, summaries: Array<string>, symbols: Array<string>, themeId: string, themeName: string, topics: Array<string>, participants: Array<{ __typename?: 'TranscriptParticipant', description: string, fullName: string, occupationTitles: Array<OccupationTitle>, role: TranscriptRole }>, topicsAnalysisItems: Array<{ __typename?: 'TopicsAnalysis', topic: string, discussionDepth: number, discussionNature: Array<DiscussionNature>, participants: Array<{ __typename?: 'TranscriptParticipant', description: string, fullName: string, occupationTitles: Array<OccupationTitle>, role: TranscriptRole }> }> } | null };

export type ImageTokenQueryVariables = Exact<{ [key: string]: never; }>;


export type ImageTokenQuery = { __typename?: 'Query', imageToken: { __typename?: 'ImageTokenResultType', token: string } };

export type UpdateTopicDiscussionExampleMutationVariables = Exact<{
  input: UpdateTopicDiscussionExampleInput;
}>;


export type UpdateTopicDiscussionExampleMutation = { __typename?: 'Mutation', updateTopicDiscussionExample?: { __typename?: 'TopicDiscussionExample', id: string, topic: string, question: string, detail: string, turn?: string | null, event: string, eventType?: string | null, ticker: string, category?: string | null, eventDate: any, segmentId?: string | null, section?: string | null, rating?: number | null, score?: number | null, explanation?: string | null, summary: string, firm?: string | null, reportTitle?: string | null, type: string, speakers?: Array<{ __typename?: 'Speaker', fullName: string, role: string }> | null } | null };

export type TopicDiscussionExampleQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type TopicDiscussionExampleQuery = { __typename?: 'Query', topicDiscussionExample?: { __typename?: 'TopicDiscussionExample', id: string, topic: string, question: string, detail: string } | null };

export type TopicDiscussionExamplesQueryVariables = Exact<{
  facets: TopicDiscussionExampleFacetsInput;
}>;


export type TopicDiscussionExamplesQuery = { __typename?: 'Query', topicDiscussionExamples: Array<{ __typename?: 'TopicDiscussionExample', id: string, topic: string, question: string, detail: string, turn?: string | null, event: string, eventType?: string | null, ticker: string, category?: string | null, eventDate: any, segmentId?: string | null, section?: string | null, rating?: number | null, score?: number | null, explanation?: string | null, summary: string, firm?: string | null, reportTitle?: string | null, type: string, speakers?: Array<{ __typename?: 'Speaker', fullName: string, role: string }> | null }> };

export type TopicDiscussionExampleAggregateQueryVariables = Exact<{
  facets: TopicDiscussionExampleFacetsInput;
}>;


export type TopicDiscussionExampleAggregateQuery = { __typename?: 'Query', bubbleChartScale: { __typename?: 'MinMaxAggregate', min?: number | null, max?: number | null }, barChartScale: { __typename?: 'MinMaxAggregate', min?: number | null, max?: number | null } };

export type UpdateTopicDiscussionInNewsArticleMutationVariables = Exact<{
  input: UpdateTopicDiscussionInNewsArticleInput;
}>;


export type UpdateTopicDiscussionInNewsArticleMutation = { __typename?: 'Mutation', updateTopicDiscussionInNewsArticle?: { __typename?: 'TopicDiscussionInNewsArticle', id: string, theme: string, segmentId: string, segmentType: string, sourceType: string, date: any, headline: string, paragraph: string, articleCount: number, topicDiscussions: Array<{ __typename?: 'ITopicDiscussion', topic: string, summary: string }>, newSources: Array<{ __typename?: 'NewSource', name: string, url: string }> } | null };

export type TopicDiscussionInNewsArticleQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type TopicDiscussionInNewsArticleQuery = { __typename?: 'Query', topicDiscussionInNewsArticle?: { __typename?: 'TopicDiscussionInNewsArticle', id: string, theme: string, segmentId: string, segmentType: string, sourceType: string, date: any, headline: string, paragraph: string, topicDiscussions: Array<{ __typename?: 'ITopicDiscussion', topic: string, summary: string }>, newSources: Array<{ __typename?: 'NewSource', name: string, url: string }> } | null };

export type TopicDiscussionInNewsArticlesQueryVariables = Exact<{
  facets: TopicDiscussionInNewsArticleFacetsInput;
}>;


export type TopicDiscussionInNewsArticlesQuery = { __typename?: 'Query', topicDiscussionInNewsArticles: Array<{ __typename?: 'TopicDiscussionInNewsArticle', id: string, theme: string, segmentId: string, segmentType: string, sourceType: string, date: any, headline: string, paragraph: string, articleCount: number, topicDiscussions: Array<{ __typename?: 'ITopicDiscussion', topic: string, summary: string }>, newSources: Array<{ __typename?: 'NewSource', name: string, url: string }> }> };

export type UpdateTopicDiscussionSummaryExampleMutationVariables = Exact<{
  input: UpdateTopicDiscussionSummaryExampleInput;
}>;


export type UpdateTopicDiscussionSummaryExampleMutation = { __typename?: 'Mutation', updateTopicDiscussionSummaryExample?: { __typename?: 'TopicDiscussionSummaryExample', id: string, topic: string, questions: Array<string>, questionMentionCounts: Array<number>, detail: string, topicMentionCount: number, event: string, eventType: string, ticker: string, category: string, eventDate: any, section: string, rating?: number | null, score?: number | null, summary: string, analysisName: string, speakers: Array<{ __typename?: 'Speaker', fullName: string, role: string }> } | null };

export type TopicDiscussionSummaryExampleQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type TopicDiscussionSummaryExampleQuery = { __typename?: 'Query', topicDiscussionSummaryExample?: { __typename?: 'TopicDiscussionSummaryExample', id: string, topic: string, questions: Array<string>, event: string, summary: string, detail: string } | null };

export type TopicDiscussionSummaryExamplesQueryVariables = Exact<{
  facets: TopicDiscussionSummaryExampleFacetsInput;
}>;


export type TopicDiscussionSummaryExamplesQuery = { __typename?: 'Query', topicDiscussionSummaryExamples: Array<{ __typename?: 'TopicDiscussionSummaryExample', id: string, topic: string, questions: Array<string>, questionMentionCounts: Array<number>, detail: string, topicMentionCount: number, event: string, eventType: string, ticker: string, category: string, eventDate: any, section: string, rating?: number | null, score?: number | null, summary: string, analysisName: string, speakers: Array<{ __typename?: 'Speaker', fullName: string, role: string }> }> };

export type TopicDiscussionSummaryExampleAggregateQueryVariables = Exact<{
  facets: TopicDiscussionSummaryExampleFacetsInput;
}>;


export type TopicDiscussionSummaryExampleAggregateQuery = { __typename?: 'Query', bubbleChartScale: { __typename?: 'MinMaxAggregate', min?: number | null, max?: number | null }, barChartScale: { __typename?: 'MinMaxAggregate', min?: number | null, max?: number | null } };

export type TranscriptsQueryVariables = Exact<{
  filters?: InputMaybe<TranscriptFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<TranscriptOrderBy>> | InputMaybe<TranscriptOrderBy>>;
}>;


export type TranscriptsQuery = { __typename?: 'Query', transcripts?: { __typename?: 'TranscriptSearchResponse', status: string, totalCount?: number | null, results?: Array<{ __typename?: 'Transcript', id: string, displaySymbol: string, eventType: TranscriptEventType, itemDate: any, title: string }> | null } | null, transcriptAggregate: { __typename?: 'TranscriptAggregate', minItemDate?: string | null, maxItemDate?: string | null } };

export type TranscriptQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type TranscriptQuery = { __typename?: 'Query', transcript?: { __typename?: 'Transcript', id: string, displaySymbol: string, eventType: TranscriptEventType, itemDate: any, title: string, symbols: Array<string>, transcriptItems: Array<{ __typename?: 'TranscriptItem', itemIndex: number, fullName: string, occupationTitles: Array<OccupationTitle>, role: TranscriptRole, speech: Array<string>, session: TranscriptSession }> } | null };

export type UploadFileMutationVariables = Exact<{
  file: Scalars['Upload']['input'];
}>;


export type UploadFileMutation = { __typename?: 'Mutation', uploadFile?: { __typename?: 'FileData', filename: string, originalFilename: string, apiDownloadUrl: string, storageLocation: string, mimetype: string } | null };

export type UploadFilesMutationVariables = Exact<{
  input: UploadFilesInput;
}>;


export type UploadFilesMutation = { __typename?: 'Mutation', uploadFiles?: { __typename?: 'UploadFilesResponse', succeeded: Array<{ __typename?: 'FileData', filename: string, id: string, originalFilename: string, storageLocation: string, mimetype: string }>, failed: Array<{ __typename?: 'FileFailedData', filename: string, id: string, originalFilename: string, storageLocation: string, mimetype: string, reason: string }> } | null };

export type UploadWorkspaceFilesMutationVariables = Exact<{
  input: UploadWorkspaceFilesInput;
}>;


export type UploadWorkspaceFilesMutation = { __typename?: 'Mutation', uploadWorkspaceFiles?: { __typename?: 'UploadWorkspaceFileResponse', succeeded: Array<{ __typename?: 'WorkspaceFileData', filename: string, id: string, originalFilename: string, storageLocation: string, mimetype: string }>, failed: Array<{ __typename?: 'WorkspaceFileFailedFile', filename: string, id: string, originalFilename: string, storageLocation: string, mimetype: string, reason: string }> } | null };

export type UploadDocumentMutationVariables = Exact<{
  file: Scalars['Upload']['input'];
}>;


export type UploadDocumentMutation = { __typename?: 'Mutation', uploadDocument?: { __typename?: 'DocumentFile', filename: string, originalFilename: string, document: { __typename?: 'DocumentType', id: string, name: string, organizationId: string } } | null };

export type UploadDocumentImageMutationVariables = Exact<{
  file: Scalars['Upload']['input'];
  documentId: Scalars['UUID']['input'];
}>;


export type UploadDocumentImageMutation = { __typename?: 'Mutation', uploadDocumentImage?: { __typename?: 'DocumentImageFile', assetDownloadUrl: string } | null };

export type UploadWorkspaceBoardAssetMutationVariables = Exact<{
  file: Scalars['Upload']['input'];
  workspaceBoardId: Scalars['UUID']['input'];
}>;


export type UploadWorkspaceBoardAssetMutation = { __typename?: 'Mutation', uploadWorkspaceBoardAsset?: { __typename?: 'WorkspaceBoardAssetFile', storageLocationUrl: string } | null };

export type OnFileAddedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnFileAddedSubscription = { __typename?: 'Subscription', onFileAdded?: { __typename?: 'FileUploadStatus', id: string, uploadedSize: number, totalSize: number, fileName: string, mimeType: string, uploadStatus: string, error: string } | null };

export type OnWorkspaceFileAddedSubscriptionVariables = Exact<{
  workspaceId: Scalars['ID']['input'];
}>;


export type OnWorkspaceFileAddedSubscription = { __typename?: 'Subscription', onWorkspaceFileAdded?: { __typename?: 'WorkspaceFileUploadStatus', id: string, uploadedSize: number, totalSize: number, fileName: string, mimeType: string, workspaceId: string, uploadStatus: string, error: string } | null };

export type UserQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type UserQuery = { __typename?: 'Query', user?: { __typename?: 'User', id: string, name: string, email: string, profilePictureUrl?: string | null } | null };

export type UsersQueryVariables = Exact<{
  orderBy?: InputMaybe<UserOrderBy>;
}>;


export type UsersQuery = { __typename?: 'Query', users?: Array<{ __typename?: 'User', id: string, name: string, email: string, profilePictureUrl?: string | null }> | null };

export type CreateUserGroupMutationVariables = Exact<{
  input: CreateUserGroupInput;
}>;


export type CreateUserGroupMutation = { __typename?: 'Mutation', createUserGroup?: { __typename?: 'UserGroup', id: string, name: string, createdAt: any, createdBy: string, updatedAt: any, updatedBy: string } | null };

export type UpdateUserGroupMutationVariables = Exact<{
  input: UpdateUserGroupInput;
}>;


export type UpdateUserGroupMutation = { __typename?: 'Mutation', updateUserGroup: { __typename?: 'UserGroup', id: string, name: string, createdAt: any, createdBy: string, updatedAt: any, updatedBy: string } };

export type DeleteUserGroupMutationVariables = Exact<{
  input: DeleteUserGroupInput;
}>;


export type DeleteUserGroupMutation = { __typename?: 'Mutation', deleteUserGroup: { __typename?: 'DeleteResult', id: string } };

export type UserGroupQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type UserGroupQuery = { __typename?: 'Query', userGroup?: { __typename?: 'UserGroup', id: string, name: string, isSystem: boolean } | null };

export type UserGroupsQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserGroupOrderBy>;
}>;


export type UserGroupsQuery = { __typename?: 'Query', userGroups: Array<{ __typename?: 'UserGroup', id: string, name: string, isSystem: boolean, createdAt: any, createdBy: string, updatedAt: any, updatedBy: string }>, userGroupAggregate: { __typename?: 'UserGroupAggregate', count: number } };

export type CreateUserGroupMemberMutationVariables = Exact<{
  input: CreateUserGroupMemberInput;
}>;


export type CreateUserGroupMemberMutation = { __typename?: 'Mutation', createUserGroupMember?: { __typename?: 'UserGroupMember', id: string, createdAt: any, createdBy: string, updatedAt: any, updatedBy: string, userGroup: { __typename?: 'UserGroup', id: string, name: string }, user?: { __typename?: 'User', id: string, name: string } | null } | null };

export type UpdateUserGroupMemberMutationVariables = Exact<{
  input: UpdateUserGroupMemberInput;
}>;


export type UpdateUserGroupMemberMutation = { __typename?: 'Mutation', updateUserGroupMember: { __typename?: 'UserGroupMember', id: string, createdAt: any, createdBy: string, updatedAt: any, updatedBy: string, userGroup: { __typename?: 'UserGroup', id: string, name: string }, user?: { __typename?: 'User', id: string, name: string } | null } };

export type DeleteUserGroupMemberMutationVariables = Exact<{
  input: DeleteUserGroupMemberInput;
}>;


export type DeleteUserGroupMemberMutation = { __typename?: 'Mutation', deleteUserGroupMember: { __typename?: 'DeleteResult', id: string } };

export type UserGroupMemberQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
  userGroupId?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
}>;


export type UserGroupMemberQuery = { __typename?: 'Query', userGroupMember?: { __typename?: 'UserGroupMember', id: string, userGroup: { __typename?: 'UserGroup', id: string, name: string }, user?: { __typename?: 'User', id: string, name: string } | null } | null };

export type UserGroupMembersQueryVariables = Exact<{
  userGroupId?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserGroupMemberOrderBy>;
}>;


export type UserGroupMembersQuery = { __typename?: 'Query', userGroupMembers: Array<{ __typename?: 'UserGroupMember', id: string, createdAt: any, createdBy: string, updatedAt: any, updatedBy: string, userGroup: { __typename?: 'UserGroup', id: string, name: string }, user?: { __typename?: 'User', id: string, name: string } | null }>, userGroupMemberAggregate: { __typename?: 'UserGroupMemberAggregate', count: number } };

export type UpsertDocumentsPageUserPreferencesMutationVariables = Exact<{
  input: UpsertUserPreferenceInput;
}>;


export type UpsertDocumentsPageUserPreferencesMutation = { __typename?: 'Mutation', upsertUserPreference: { __typename?: 'UserPreference', key: string, data: any } };

export type UpsertLibraryPageUserPreferencesMutationVariables = Exact<{
  input: UpsertUserPreferenceInput;
}>;


export type UpsertLibraryPageUserPreferencesMutation = { __typename?: 'Mutation', upsertUserPreference: { __typename?: 'UserPreference', key: string, data: any } };

export type UpsertWorkspaceUserPreferencesMutationVariables = Exact<{
  input: UpsertUserPreferenceInput;
}>;


export type UpsertWorkspaceUserPreferencesMutation = { __typename?: 'Mutation', upsertUserPreference: { __typename?: 'UserPreference', key: string, data: any } };

export type UpsertWorkspacesPageUserPreferencesMutationVariables = Exact<{
  input: UpsertUserPreferenceInput;
}>;


export type UpsertWorkspacesPageUserPreferencesMutation = { __typename?: 'Mutation', upsertUserPreference: { __typename?: 'UserPreference', key: string, data: any } };

export type UpsertWorkspacePageUserPreferencesMutationVariables = Exact<{
  input: UpsertUserPreferenceInput;
}>;


export type UpsertWorkspacePageUserPreferencesMutation = { __typename?: 'Mutation', upsertUserPreference: { __typename?: 'UserPreference', key: string, data: any } };

export type DocumentsPageUserPreferencesQueryVariables = Exact<{
  key: Scalars['String']['input'];
}>;


export type DocumentsPageUserPreferencesQuery = { __typename?: 'Query', userPreference?: { __typename?: 'UserPreference', key: string, data: any } | null };

export type LibraryPageUserPreferencesQueryVariables = Exact<{
  key: Scalars['String']['input'];
}>;


export type LibraryPageUserPreferencesQuery = { __typename?: 'Query', userPreference?: { __typename?: 'UserPreference', key: string, data: any } | null };

export type WorkspaceBoardUserPreferencesQueryVariables = Exact<{
  key: Scalars['String']['input'];
}>;


export type WorkspaceBoardUserPreferencesQuery = { __typename?: 'Query', userPreference?: { __typename?: 'UserPreference', key: string, data: any } | null };

export type WorkspacesPageUserPreferencesQueryVariables = Exact<{
  key: Scalars['String']['input'];
}>;


export type WorkspacesPageUserPreferencesQuery = { __typename?: 'Query', userPreference?: { __typename?: 'UserPreference', key: string, data: any } | null };

export type WorkspacePageUserPreferencesQueryVariables = Exact<{
  key: Scalars['String']['input'];
}>;


export type WorkspacePageUserPreferencesQuery = { __typename?: 'Query', userPreference?: { __typename?: 'UserPreference', key: string, data: any } | null };

export type WorkspaceUserPreferencesQueryVariables = Exact<{
  key: Scalars['String']['input'];
}>;


export type WorkspaceUserPreferencesQuery = { __typename?: 'Query', userPreference?: { __typename?: 'UserPreference', key: string, data: any } | null };

export type CreateWorkspaceMutationVariables = Exact<{
  input: CreateWorkspaceInput;
}>;


export type CreateWorkspaceMutation = { __typename?: 'Mutation', createWorkspace?: { __typename?: 'Workspace', id: string, description?: string | null, name: string, tags: Array<string>, createdAt: any, createdBy: string, updatedAt: any, updatedBy: string, defaultWorkspaceBoard?: { __typename?: 'WorkspaceBoard', id: string, name: string, tags: Array<string>, thumbnail?: string | null } | null } | null };

export type UpdateWorkspaceMutationVariables = Exact<{
  input: UpdateWorkspaceInput;
}>;


export type UpdateWorkspaceMutation = { __typename?: 'Mutation', updateWorkspace: { __typename?: 'Workspace', id: string, description?: string | null, name: string, tags: Array<string>, createdAt: any, createdBy: string, updatedAt: any, updatedBy: string } };

export type DeleteWorkspaceMutationVariables = Exact<{
  input: DeleteWorkspaceInput;
}>;


export type DeleteWorkspaceMutation = { __typename?: 'Mutation', deleteWorkspace: { __typename?: 'DeleteResult', id: string } };

export type CopyWorkspaceMutationVariables = Exact<{
  input: CopyWorkspaceInput;
}>;


export type CopyWorkspaceMutation = { __typename?: 'Mutation', copyWorkspace?: { __typename?: 'Workspace', id: string, description?: string | null, name: string, tags: Array<string>, createdAt: any, createdBy: string, updatedAt: any, updatedBy: string, defaultWorkspaceBoard?: { __typename?: 'WorkspaceBoard', id: string, name: string, tags: Array<string>, thumbnail?: string | null } | null } | null };

export type ReindexWorkspaceMutationVariables = Exact<{
  input: ReindexWorkspaceInput;
}>;


export type ReindexWorkspaceMutation = { __typename?: 'Mutation', reindexWorkspace: { __typename?: 'ReindexWorkspaceResult', id: string } };

export type WorkspaceQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type WorkspaceQuery = { __typename?: 'Query', workspace?: { __typename?: 'Workspace', id: string, description?: string | null, name: string, tags: Array<string>, defaultWorkspaceBoard?: { __typename?: 'WorkspaceBoard', id: string, thumbnail?: string | null } | null } | null };

export type WorkspacesQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<WorkspaceOrderBy>;
  filters?: InputMaybe<WorkspaceFilters>;
}>;


export type WorkspacesQuery = { __typename?: 'Query', workspaces: Array<{ __typename?: 'Workspace', id: string, description?: string | null, name: string, tags: Array<string>, updatedAt: any, defaultWorkspaceBoard?: { __typename?: 'WorkspaceBoard', id: string, thumbnail?: string | null } | null, workspaceAccessControlList: Array<{ __typename?: 'WorkspaceAccessControlList', userGroup?: { __typename?: 'UserGroup', name: string } | null, user?: { __typename?: 'User', id: string, name: string } | null }> }>, workspaceAggregate: { __typename?: 'WorkspaceAggregate', count: number } };

export type WorkspaceTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type WorkspaceTagsQuery = { __typename?: 'Query', workspaceTags: Array<string> };

export type CreateWorkspaceAccessControlListMutationVariables = Exact<{
  input: CreateWorkspaceAccessControlListInput;
}>;


export type CreateWorkspaceAccessControlListMutation = { __typename?: 'Mutation', createWorkspaceAccessControlList?: { __typename?: 'WorkspaceAccessControlList', id: string, workspaceId: string, role: ObjectRole, createdAt: any, createdBy: string, updatedAt: any, updatedBy: string, userGroup?: { __typename?: 'UserGroup', id: string, name: string } | null, user?: { __typename?: 'User', id: string, name: string, profilePictureUrl?: string | null } | null } | null };

export type UpdateWorkspaceAccessControlListMutationVariables = Exact<{
  input: UpdateWorkspaceAccessControlListInput;
}>;


export type UpdateWorkspaceAccessControlListMutation = { __typename?: 'Mutation', updateWorkspaceAccessControlList: { __typename?: 'WorkspaceAccessControlList', id: string, workspaceId: string, role: ObjectRole, createdAt: any, createdBy: string, updatedAt: any, updatedBy: string, userGroup?: { __typename?: 'UserGroup', id: string, name: string } | null, user?: { __typename?: 'User', id: string, name: string, profilePictureUrl?: string | null } | null } };

export type DeleteWorkspaceAccessControlListMutationVariables = Exact<{
  input: DeleteWorkspaceAccessControlListInput;
}>;


export type DeleteWorkspaceAccessControlListMutation = { __typename?: 'Mutation', deleteWorkspaceAccessControlList: { __typename?: 'DeleteResult', id: string } };

export type WorkspaceAccessControlListQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type WorkspaceAccessControlListQuery = { __typename?: 'Query', workspaceAccessControlList?: { __typename?: 'WorkspaceAccessControlList', id: string, workspaceId: string, role: ObjectRole, createdAt: any, createdBy: string, updatedAt: any, updatedBy: string, userGroup?: { __typename?: 'UserGroup', id: string, name: string, isSystem: boolean } | null, user?: { __typename?: 'User', id: string, name: string, profilePictureUrl?: string | null } | null } | null };

export type WorkspaceAccessControlListByObjectQueryVariables = Exact<{
  workspaceId: Scalars['ID']['input'];
}>;


export type WorkspaceAccessControlListByObjectQuery = { __typename?: 'Query', workspaceAccessControlListByObject: Array<{ __typename?: 'WorkspaceAccessControlList', id: string, workspaceId: string, role: ObjectRole, createdAt: any, createdBy: string, updatedAt: any, updatedBy: string, userGroup?: { __typename?: 'UserGroup', id: string, name: string, isSystem: boolean } | null, user?: { __typename?: 'User', id: string, name: string, profilePictureUrl?: string | null } | null }>, workspaceAccessControlListByObjectAggregate: { __typename?: 'WorkspaceAccessControlListAggregate', count: number } };

export type WorkspaceAccessControlListUserRolesQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
  workspaceId: Scalars['ID']['input'];
}>;


export type WorkspaceAccessControlListUserRolesQuery = { __typename?: 'Query', workspaceAccessControlListUserRoles: { __typename?: 'WorkspaceAccessControlListUserRoles', userId: string, objectId: string, roles: Array<ObjectRole> } };

export type WorkspaceBoardAccessControlListUserRolesQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
  workspaceBoardId: Scalars['ID']['input'];
}>;


export type WorkspaceBoardAccessControlListUserRolesQuery = { __typename?: 'Query', workspaceBoardAccessControlListUserRoles: { __typename?: 'WorkspaceAccessControlListUserRoles', userId: string, objectId: string, roles: Array<ObjectRole> } };

export type CreateWorkspaceBoardMutationVariables = Exact<{
  input: CreateWorkspaceBoardInput;
}>;


export type CreateWorkspaceBoardMutation = { __typename?: 'Mutation', createWorkspaceBoard: { __typename?: 'WorkspaceBoard', id: string, content?: string | null, description?: string | null, name: string, createdAt: any, createdBy: string, updatedAt: any, updatedBy: string } };

export type DeleteWorkspaceBoardMutationVariables = Exact<{
  input: DeleteWorkspaceBoardInput;
}>;


export type DeleteWorkspaceBoardMutation = { __typename?: 'Mutation', deleteWorkspaceBoard: { __typename?: 'DeleteResult', id: string } };

export type UpdateWorkspaceBoardMutationVariables = Exact<{
  input: UpdateWorkspaceBoardInput;
}>;


export type UpdateWorkspaceBoardMutation = { __typename?: 'Mutation', updateWorkspaceBoard: { __typename?: 'WorkspaceBoard', content?: string | null, description?: string | null, id: string, isThumbnailLocked: boolean, name: string, tags: Array<string>, thumbnail?: string | null, createdAt: any, createdBy: string, updatedAt: any, updatedBy: string } };

export type MarkWorkspaceBoardShapesAsDeletedMutationVariables = Exact<{
  input: MarkWorkspaceBoardShapeAsDeletedInput;
}>;


export type MarkWorkspaceBoardShapesAsDeletedMutation = { __typename?: 'Mutation', markWorkspaceBoardShapesAsDeleted: Array<string> };

export type PublishBoardDocumentShapesToDocumentsMutationVariables = Exact<{
  input: PublishBoardDocumentShapesToDocumentsInput;
}>;


export type PublishBoardDocumentShapesToDocumentsMutation = { __typename?: 'Mutation', publishBoardDocumentShapesToDocuments: Array<{ __typename?: 'Document', id: string, name: string, createdAt: any, createdBy: string, updatedAt: any, updatedBy: string, content: { __typename?: 'DocumentContent', id: string, content: string, contentType: string } }> };

export type WorkspaceBoardQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type WorkspaceBoardQuery = { __typename?: 'Query', workspaceBoard?: { __typename?: 'WorkspaceBoard', content?: string | null, description?: string | null, id: string, isThumbnailLocked: boolean, name: string, tags: Array<string>, thumbnail?: string | null, workspaceId: string, createdAt: any, createdBy: string, updatedAt: any, updatedBy: string } | null };

export type WorkspaceBoardsQueryVariables = Exact<{
  workspaceId: Scalars['ID']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<WorkspaceBoardOrderBy>;
  filters?: InputMaybe<WorkspaceBoardFilters>;
}>;


export type WorkspaceBoardsQuery = { __typename?: 'Query', workspaceBoards: Array<{ __typename?: 'WorkspaceBoard', content?: string | null, description?: string | null, id: string, isThumbnailLocked: boolean, name: string, tags: Array<string>, thumbnail?: string | null, workspaceId: string, createdAt: any, createdBy: string, updatedAt: any, updatedBy: string }>, workspaceBoardAggregate: { __typename?: 'WorkspaceBoardAggregate', count: number } };

export type WorkspaceBoardAggregateQueryVariables = Exact<{
  workspaceId: Scalars['ID']['input'];
  filters?: InputMaybe<WorkspaceBoardFilters>;
}>;


export type WorkspaceBoardAggregateQuery = { __typename?: 'Query', workspaceBoardAggregate: { __typename?: 'WorkspaceBoardAggregate', count: number } };

export type WorkspaceBoardTagsQueryVariables = Exact<{
  workspaceId: Scalars['ID']['input'];
}>;


export type WorkspaceBoardTagsQuery = { __typename?: 'Query', workspaceBoardTags: Array<string> };

export type MarkWorkspaceBoardTableOfContentsActiveForShapeMutationVariables = Exact<{
  input: MarkWorkspaceBoardTableOfContentsActiveForShapeInput;
}>;


export type MarkWorkspaceBoardTableOfContentsActiveForShapeMutation = { __typename?: 'Mutation', markWorkspaceBoardTableOfContentsActiveForShape: { __typename?: 'BulkUpdateResult', count: number } };

export type MarkWorkspaceBoardTableOfContentsInactiveForShapeMutationVariables = Exact<{
  input: MarkWorkspaceBoardTableOfContentsInactiveForShapeInput;
}>;


export type MarkWorkspaceBoardTableOfContentsInactiveForShapeMutation = { __typename?: 'Mutation', markWorkspaceBoardTableOfContentsInactiveForShape: { __typename?: 'BulkUpdateResult', count: number } };

export type CreateWorkspaceFileMutationVariables = Exact<{
  input: CreateWorkspaceFileInput;
}>;


export type CreateWorkspaceFileMutation = { __typename?: 'Mutation', createWorkspaceFile: { __typename?: 'WorkspaceFile', id: string, name: string, url: string, filename: string, mimeType: string, createdAt: any, createdBy: string, updatedAt: any, updatedBy: string } };

export type UpdateWorkspaceFileMutationVariables = Exact<{
  input: UpdateWorkspaceFileInput;
}>;


export type UpdateWorkspaceFileMutation = { __typename?: 'Mutation', updateWorkspaceFile: { __typename?: 'WorkspaceFile', id: string, name: string, createdAt: any, createdBy: string, updatedAt: any, updatedBy: string } };

export type DeleteWorkspaceFileMutationVariables = Exact<{
  input: DeleteWorkspaceFileInput;
}>;


export type DeleteWorkspaceFileMutation = { __typename?: 'Mutation', deleteWorkspaceFile: { __typename?: 'DeleteResult', id: string } };

export type PublishWorkspaceFilesToLibraryMutationVariables = Exact<{
  input: PublishWorkspaceFilesToLibraryInput;
}>;


export type PublishWorkspaceFilesToLibraryMutation = { __typename?: 'Mutation', publishWorkspaceFilesToLibrary: Array<{ __typename?: 'File', id: string, name: string, url: string, filename: string, mimeType: string, createdAt: any, createdBy: string, updatedAt: any, updatedBy: string }> };

export type WorkspaceFileQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type WorkspaceFileQuery = { __typename?: 'Query', workspaceFile?: { __typename?: 'WorkspaceFile', id: string, name: string, filename: string, workspaceId: string } | null };

export type WorkspaceFilesQueryVariables = Exact<{
  workspaceId: Scalars['ID']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<WorkspaceFileOrderBy>;
  filters?: InputMaybe<WorkspaceFileFilters>;
}>;


export type WorkspaceFilesQuery = { __typename?: 'Query', workspaceFiles: Array<{ __typename?: 'WorkspaceFile', id: string, name: string, url: string, filename: string, mimeType: string, workspaceId: string, updatedAt: any }>, workspaceFileAggregate: { __typename?: 'WorkspaceFileAggregate', count: number } };

export type WorkspaceFileAggregateQueryVariables = Exact<{
  workspaceId: Scalars['ID']['input'];
  filters?: InputMaybe<WorkspaceFileFilters>;
}>;


export type WorkspaceFileAggregateQuery = { __typename?: 'Query', workspaceFileAggregate: { __typename?: 'WorkspaceFileAggregate', count: number } };

export type UpsertWorkspaceBoardUserPreferencesCacheQueryVariables = Exact<{
  key: Scalars['String']['input'];
}>;


export type UpsertWorkspaceBoardUserPreferencesCacheQuery = { __typename?: 'Query', userPreference?: { __typename?: 'UserPreference', key: string, data: any } | null };

export const NewChatMessageFragmentDoc = gql`
    fragment NewChatMessage on ChatMessage {
  id
  channelId
  parentId
  messageType
  message
  privateRecipientIds
  attachments {
    mimeType
    name
    storageLocationUrl
  }
  mentions {
    targetId
    name
    mentionType
  }
  reactions {
    reaction
    userIds
  }
  createdAt
  createdBy
  updatedAt
  updatedBy
  deletedAt
  deletedBy
  childCount
}
    `;
export const FilesAndDocumentsDocument = gql`
    query FilesAndDocuments($workspaceId: ID!, $offset: Int, $limit: Int, $fileBundleViewOrderBy: FileBundleViewOrderBy, $workspaceFileOrderBy: WorkspaceFileOrderBy, $documentOrderBy: DocumentOrderBy, $fileBundleViewFilters: FileBundleViewFilters, $workspaceFileFilters: WorkspaceFileFilters, $documentFilters: DocumentFilters) {
  fileBundleView(
    offset: $offset
    limit: $limit
    orderBy: $fileBundleViewOrderBy
    filters: $fileBundleViewFilters
  ) {
    createdAt
    id
    mimeType
    name
    type
  }
  documents(
    offset: $offset
    limit: $limit
    orderBy: $documentOrderBy
    filters: $documentFilters
  ) {
    id
    name
  }
  workspaceFiles(
    workspaceId: $workspaceId
    offset: $offset
    limit: $limit
    orderBy: $workspaceFileOrderBy
    filters: $workspaceFileFilters
  ) {
    createdAt
    id
    mimeType
    name
  }
}
    `;

/**
 * __useFilesAndDocumentsQuery__
 *
 * To run a query within a React component, call `useFilesAndDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilesAndDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilesAndDocumentsQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      fileBundleViewOrderBy: // value for 'fileBundleViewOrderBy'
 *      workspaceFileOrderBy: // value for 'workspaceFileOrderBy'
 *      documentOrderBy: // value for 'documentOrderBy'
 *      fileBundleViewFilters: // value for 'fileBundleViewFilters'
 *      workspaceFileFilters: // value for 'workspaceFileFilters'
 *      documentFilters: // value for 'documentFilters'
 *   },
 * });
 */
export function useFilesAndDocumentsQuery(baseOptions: Apollo.QueryHookOptions<FilesAndDocumentsQuery, FilesAndDocumentsQueryVariables> & ({ variables: FilesAndDocumentsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FilesAndDocumentsQuery, FilesAndDocumentsQueryVariables>(FilesAndDocumentsDocument, options);
      }
export function useFilesAndDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FilesAndDocumentsQuery, FilesAndDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FilesAndDocumentsQuery, FilesAndDocumentsQueryVariables>(FilesAndDocumentsDocument, options);
        }
export function useFilesAndDocumentsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FilesAndDocumentsQuery, FilesAndDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FilesAndDocumentsQuery, FilesAndDocumentsQueryVariables>(FilesAndDocumentsDocument, options);
        }
export type FilesAndDocumentsQueryHookResult = ReturnType<typeof useFilesAndDocumentsQuery>;
export type FilesAndDocumentsLazyQueryHookResult = ReturnType<typeof useFilesAndDocumentsLazyQuery>;
export type FilesAndDocumentsSuspenseQueryHookResult = ReturnType<typeof useFilesAndDocumentsSuspenseQuery>;
export type FilesAndDocumentsQueryResult = Apollo.QueryResult<FilesAndDocumentsQuery, FilesAndDocumentsQueryVariables>;
export const EmptyQueryDocument = gql`
    query EmptyQuery {
  __typename
}
    `;

/**
 * __useEmptyQueryQuery__
 *
 * To run a query within a React component, call `useEmptyQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmptyQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmptyQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useEmptyQueryQuery(baseOptions?: Apollo.QueryHookOptions<EmptyQueryQuery, EmptyQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmptyQueryQuery, EmptyQueryQueryVariables>(EmptyQueryDocument, options);
      }
export function useEmptyQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmptyQueryQuery, EmptyQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmptyQueryQuery, EmptyQueryQueryVariables>(EmptyQueryDocument, options);
        }
export function useEmptyQuerySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<EmptyQueryQuery, EmptyQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<EmptyQueryQuery, EmptyQueryQueryVariables>(EmptyQueryDocument, options);
        }
export type EmptyQueryQueryHookResult = ReturnType<typeof useEmptyQueryQuery>;
export type EmptyQueryLazyQueryHookResult = ReturnType<typeof useEmptyQueryLazyQuery>;
export type EmptyQuerySuspenseQueryHookResult = ReturnType<typeof useEmptyQuerySuspenseQuery>;
export type EmptyQueryQueryResult = Apollo.QueryResult<EmptyQueryQuery, EmptyQueryQueryVariables>;
export const FeedTranscriptsTranscriptDocument = gql`
    query FeedTranscriptsTranscript($filters: TranscriptFilters, $limit: Int, $offset: Int, $orderBy: [TranscriptOrderBy]) {
  transcripts(
    offset: $offset
    limit: $limit
    orderBy: $orderBy
    filters: $filters
  ) {
    results {
      id
      displaySymbol
      eventType
      itemDate
      title
    }
    status
    totalCount
  }
  transcriptAggregate {
    minItemDate
    maxItemDate
  }
}
    `;

/**
 * __useFeedTranscriptsTranscriptQuery__
 *
 * To run a query within a React component, call `useFeedTranscriptsTranscriptQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeedTranscriptsTranscriptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeedTranscriptsTranscriptQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useFeedTranscriptsTranscriptQuery(baseOptions?: Apollo.QueryHookOptions<FeedTranscriptsTranscriptQuery, FeedTranscriptsTranscriptQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FeedTranscriptsTranscriptQuery, FeedTranscriptsTranscriptQueryVariables>(FeedTranscriptsTranscriptDocument, options);
      }
export function useFeedTranscriptsTranscriptLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FeedTranscriptsTranscriptQuery, FeedTranscriptsTranscriptQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FeedTranscriptsTranscriptQuery, FeedTranscriptsTranscriptQueryVariables>(FeedTranscriptsTranscriptDocument, options);
        }
export function useFeedTranscriptsTranscriptSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FeedTranscriptsTranscriptQuery, FeedTranscriptsTranscriptQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FeedTranscriptsTranscriptQuery, FeedTranscriptsTranscriptQueryVariables>(FeedTranscriptsTranscriptDocument, options);
        }
export type FeedTranscriptsTranscriptQueryHookResult = ReturnType<typeof useFeedTranscriptsTranscriptQuery>;
export type FeedTranscriptsTranscriptLazyQueryHookResult = ReturnType<typeof useFeedTranscriptsTranscriptLazyQuery>;
export type FeedTranscriptsTranscriptSuspenseQueryHookResult = ReturnType<typeof useFeedTranscriptsTranscriptSuspenseQuery>;
export type FeedTranscriptsTranscriptQueryResult = Apollo.QueryResult<FeedTranscriptsTranscriptQuery, FeedTranscriptsTranscriptQueryVariables>;
export const ThemeDiscussionAnalysisParentTranscriptDocument = gql`
    query ThemeDiscussionAnalysisParentTranscript($id: ID!) {
  transcript(id: $id) {
    id
    displaySymbol
    eventType
    itemDate
  }
}
    `;

/**
 * __useThemeDiscussionAnalysisParentTranscriptQuery__
 *
 * To run a query within a React component, call `useThemeDiscussionAnalysisParentTranscriptQuery` and pass it any options that fit your needs.
 * When your component renders, `useThemeDiscussionAnalysisParentTranscriptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThemeDiscussionAnalysisParentTranscriptQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useThemeDiscussionAnalysisParentTranscriptQuery(baseOptions: Apollo.QueryHookOptions<ThemeDiscussionAnalysisParentTranscriptQuery, ThemeDiscussionAnalysisParentTranscriptQueryVariables> & ({ variables: ThemeDiscussionAnalysisParentTranscriptQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ThemeDiscussionAnalysisParentTranscriptQuery, ThemeDiscussionAnalysisParentTranscriptQueryVariables>(ThemeDiscussionAnalysisParentTranscriptDocument, options);
      }
export function useThemeDiscussionAnalysisParentTranscriptLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ThemeDiscussionAnalysisParentTranscriptQuery, ThemeDiscussionAnalysisParentTranscriptQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ThemeDiscussionAnalysisParentTranscriptQuery, ThemeDiscussionAnalysisParentTranscriptQueryVariables>(ThemeDiscussionAnalysisParentTranscriptDocument, options);
        }
export function useThemeDiscussionAnalysisParentTranscriptSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ThemeDiscussionAnalysisParentTranscriptQuery, ThemeDiscussionAnalysisParentTranscriptQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ThemeDiscussionAnalysisParentTranscriptQuery, ThemeDiscussionAnalysisParentTranscriptQueryVariables>(ThemeDiscussionAnalysisParentTranscriptDocument, options);
        }
export type ThemeDiscussionAnalysisParentTranscriptQueryHookResult = ReturnType<typeof useThemeDiscussionAnalysisParentTranscriptQuery>;
export type ThemeDiscussionAnalysisParentTranscriptLazyQueryHookResult = ReturnType<typeof useThemeDiscussionAnalysisParentTranscriptLazyQuery>;
export type ThemeDiscussionAnalysisParentTranscriptSuspenseQueryHookResult = ReturnType<typeof useThemeDiscussionAnalysisParentTranscriptSuspenseQuery>;
export type ThemeDiscussionAnalysisParentTranscriptQueryResult = Apollo.QueryResult<ThemeDiscussionAnalysisParentTranscriptQuery, ThemeDiscussionAnalysisParentTranscriptQueryVariables>;
export const ThemeDiscussionAnalysisParentTranscriptsDocument = gql`
    query ThemeDiscussionAnalysisParentTranscripts($filters: TranscriptFilters, $limit: Int, $offset: Int, $orderBy: [TranscriptOrderBy]) {
  transcripts(
    offset: $offset
    limit: $limit
    orderBy: $orderBy
    filters: $filters
  ) {
    results {
      id
      displaySymbol
      eventType
      itemDate
    }
  }
}
    `;

/**
 * __useThemeDiscussionAnalysisParentTranscriptsQuery__
 *
 * To run a query within a React component, call `useThemeDiscussionAnalysisParentTranscriptsQuery` and pass it any options that fit your needs.
 * When your component renders, `useThemeDiscussionAnalysisParentTranscriptsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThemeDiscussionAnalysisParentTranscriptsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useThemeDiscussionAnalysisParentTranscriptsQuery(baseOptions?: Apollo.QueryHookOptions<ThemeDiscussionAnalysisParentTranscriptsQuery, ThemeDiscussionAnalysisParentTranscriptsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ThemeDiscussionAnalysisParentTranscriptsQuery, ThemeDiscussionAnalysisParentTranscriptsQueryVariables>(ThemeDiscussionAnalysisParentTranscriptsDocument, options);
      }
export function useThemeDiscussionAnalysisParentTranscriptsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ThemeDiscussionAnalysisParentTranscriptsQuery, ThemeDiscussionAnalysisParentTranscriptsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ThemeDiscussionAnalysisParentTranscriptsQuery, ThemeDiscussionAnalysisParentTranscriptsQueryVariables>(ThemeDiscussionAnalysisParentTranscriptsDocument, options);
        }
export function useThemeDiscussionAnalysisParentTranscriptsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ThemeDiscussionAnalysisParentTranscriptsQuery, ThemeDiscussionAnalysisParentTranscriptsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ThemeDiscussionAnalysisParentTranscriptsQuery, ThemeDiscussionAnalysisParentTranscriptsQueryVariables>(ThemeDiscussionAnalysisParentTranscriptsDocument, options);
        }
export type ThemeDiscussionAnalysisParentTranscriptsQueryHookResult = ReturnType<typeof useThemeDiscussionAnalysisParentTranscriptsQuery>;
export type ThemeDiscussionAnalysisParentTranscriptsLazyQueryHookResult = ReturnType<typeof useThemeDiscussionAnalysisParentTranscriptsLazyQuery>;
export type ThemeDiscussionAnalysisParentTranscriptsSuspenseQueryHookResult = ReturnType<typeof useThemeDiscussionAnalysisParentTranscriptsSuspenseQuery>;
export type ThemeDiscussionAnalysisParentTranscriptsQueryResult = Apollo.QueryResult<ThemeDiscussionAnalysisParentTranscriptsQuery, ThemeDiscussionAnalysisParentTranscriptsQueryVariables>;
export const CreateAnalysisFeedbackDocument = gql`
    mutation CreateAnalysisFeedback($input: CreateAnalysisFeedbackInput!) {
  createAnalysisFeedback(input: $input) {
    id
  }
}
    `;
export type CreateAnalysisFeedbackMutationFn = Apollo.MutationFunction<CreateAnalysisFeedbackMutation, CreateAnalysisFeedbackMutationVariables>;

/**
 * __useCreateAnalysisFeedbackMutation__
 *
 * To run a mutation, you first call `useCreateAnalysisFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAnalysisFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAnalysisFeedbackMutation, { data, loading, error }] = useCreateAnalysisFeedbackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAnalysisFeedbackMutation(baseOptions?: Apollo.MutationHookOptions<CreateAnalysisFeedbackMutation, CreateAnalysisFeedbackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAnalysisFeedbackMutation, CreateAnalysisFeedbackMutationVariables>(CreateAnalysisFeedbackDocument, options);
      }
export type CreateAnalysisFeedbackMutationHookResult = ReturnType<typeof useCreateAnalysisFeedbackMutation>;
export type CreateAnalysisFeedbackMutationResult = Apollo.MutationResult<CreateAnalysisFeedbackMutation>;
export type CreateAnalysisFeedbackMutationOptions = Apollo.BaseMutationOptions<CreateAnalysisFeedbackMutation, CreateAnalysisFeedbackMutationVariables>;
export const UpdateAnalystQuestionDocument = gql`
    mutation UpdateAnalystQuestion($input: UpdateAnalystQuestionInput!) {
  updateAnalystQuestion(input: $input) {
    id
    eventDate
    firm
    firmType
    question
    segment
    topics
  }
}
    `;
export type UpdateAnalystQuestionMutationFn = Apollo.MutationFunction<UpdateAnalystQuestionMutation, UpdateAnalystQuestionMutationVariables>;

/**
 * __useUpdateAnalystQuestionMutation__
 *
 * To run a mutation, you first call `useUpdateAnalystQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAnalystQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAnalystQuestionMutation, { data, loading, error }] = useUpdateAnalystQuestionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAnalystQuestionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAnalystQuestionMutation, UpdateAnalystQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAnalystQuestionMutation, UpdateAnalystQuestionMutationVariables>(UpdateAnalystQuestionDocument, options);
      }
export type UpdateAnalystQuestionMutationHookResult = ReturnType<typeof useUpdateAnalystQuestionMutation>;
export type UpdateAnalystQuestionMutationResult = Apollo.MutationResult<UpdateAnalystQuestionMutation>;
export type UpdateAnalystQuestionMutationOptions = Apollo.BaseMutationOptions<UpdateAnalystQuestionMutation, UpdateAnalystQuestionMutationVariables>;
export const AnalystQuestionDocument = gql`
    query AnalystQuestion($id: ID!) {
  analystQuestion(id: $id) {
    id
    eventDate
    firm
    firmType
    question
    segment
    topics
  }
}
    `;

/**
 * __useAnalystQuestionQuery__
 *
 * To run a query within a React component, call `useAnalystQuestionQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalystQuestionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalystQuestionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAnalystQuestionQuery(baseOptions: Apollo.QueryHookOptions<AnalystQuestionQuery, AnalystQuestionQueryVariables> & ({ variables: AnalystQuestionQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AnalystQuestionQuery, AnalystQuestionQueryVariables>(AnalystQuestionDocument, options);
      }
export function useAnalystQuestionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnalystQuestionQuery, AnalystQuestionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AnalystQuestionQuery, AnalystQuestionQueryVariables>(AnalystQuestionDocument, options);
        }
export function useAnalystQuestionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AnalystQuestionQuery, AnalystQuestionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AnalystQuestionQuery, AnalystQuestionQueryVariables>(AnalystQuestionDocument, options);
        }
export type AnalystQuestionQueryHookResult = ReturnType<typeof useAnalystQuestionQuery>;
export type AnalystQuestionLazyQueryHookResult = ReturnType<typeof useAnalystQuestionLazyQuery>;
export type AnalystQuestionSuspenseQueryHookResult = ReturnType<typeof useAnalystQuestionSuspenseQuery>;
export type AnalystQuestionQueryResult = Apollo.QueryResult<AnalystQuestionQuery, AnalystQuestionQueryVariables>;
export const AnalystQuestionsDocument = gql`
    query AnalystQuestions($facets: AnalystQuestionFacetsInput!, $numberOfClusters: Int, $otherGroupLabel: String!, $version: String) {
  analystQuestions(
    facets: $facets
    numberOfClusters: $numberOfClusters
    otherGroupLabel: $otherGroupLabel
    version: $version
  ) {
    id
    eventDate
    firm
    firmType
    question
    segment
    topics
    group
    analystQuestionClusterAssignment {
      analystQuestionCluster {
        name
      }
    }
  }
}
    `;

/**
 * __useAnalystQuestionsQuery__
 *
 * To run a query within a React component, call `useAnalystQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalystQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalystQuestionsQuery({
 *   variables: {
 *      facets: // value for 'facets'
 *      numberOfClusters: // value for 'numberOfClusters'
 *      otherGroupLabel: // value for 'otherGroupLabel'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useAnalystQuestionsQuery(baseOptions: Apollo.QueryHookOptions<AnalystQuestionsQuery, AnalystQuestionsQueryVariables> & ({ variables: AnalystQuestionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AnalystQuestionsQuery, AnalystQuestionsQueryVariables>(AnalystQuestionsDocument, options);
      }
export function useAnalystQuestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnalystQuestionsQuery, AnalystQuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AnalystQuestionsQuery, AnalystQuestionsQueryVariables>(AnalystQuestionsDocument, options);
        }
export function useAnalystQuestionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AnalystQuestionsQuery, AnalystQuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AnalystQuestionsQuery, AnalystQuestionsQueryVariables>(AnalystQuestionsDocument, options);
        }
export type AnalystQuestionsQueryHookResult = ReturnType<typeof useAnalystQuestionsQuery>;
export type AnalystQuestionsLazyQueryHookResult = ReturnType<typeof useAnalystQuestionsLazyQuery>;
export type AnalystQuestionsSuspenseQueryHookResult = ReturnType<typeof useAnalystQuestionsSuspenseQuery>;
export type AnalystQuestionsQueryResult = Apollo.QueryResult<AnalystQuestionsQuery, AnalystQuestionsQueryVariables>;
export const GroupedAnalystQuestionsDocument = gql`
    query GroupedAnalystQuestions($facets: AnalystQuestionFacetsInput!, $numberOfClusters: Int!, $otherGroupLabel: String!, $version: String!) {
  groupedAnalystQuestions(
    facets: $facets
    numberOfClusters: $numberOfClusters
    otherGroupLabel: $otherGroupLabel
    version: $version
  ) {
    id
    eventDate
    firm
    firmType
    question
    segment
    topics
    group
  }
}
    `;

/**
 * __useGroupedAnalystQuestionsQuery__
 *
 * To run a query within a React component, call `useGroupedAnalystQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupedAnalystQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupedAnalystQuestionsQuery({
 *   variables: {
 *      facets: // value for 'facets'
 *      numberOfClusters: // value for 'numberOfClusters'
 *      otherGroupLabel: // value for 'otherGroupLabel'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useGroupedAnalystQuestionsQuery(baseOptions: Apollo.QueryHookOptions<GroupedAnalystQuestionsQuery, GroupedAnalystQuestionsQueryVariables> & ({ variables: GroupedAnalystQuestionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GroupedAnalystQuestionsQuery, GroupedAnalystQuestionsQueryVariables>(GroupedAnalystQuestionsDocument, options);
      }
export function useGroupedAnalystQuestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroupedAnalystQuestionsQuery, GroupedAnalystQuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GroupedAnalystQuestionsQuery, GroupedAnalystQuestionsQueryVariables>(GroupedAnalystQuestionsDocument, options);
        }
export function useGroupedAnalystQuestionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GroupedAnalystQuestionsQuery, GroupedAnalystQuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GroupedAnalystQuestionsQuery, GroupedAnalystQuestionsQueryVariables>(GroupedAnalystQuestionsDocument, options);
        }
export type GroupedAnalystQuestionsQueryHookResult = ReturnType<typeof useGroupedAnalystQuestionsQuery>;
export type GroupedAnalystQuestionsLazyQueryHookResult = ReturnType<typeof useGroupedAnalystQuestionsLazyQuery>;
export type GroupedAnalystQuestionsSuspenseQueryHookResult = ReturnType<typeof useGroupedAnalystQuestionsSuspenseQuery>;
export type GroupedAnalystQuestionsQueryResult = Apollo.QueryResult<GroupedAnalystQuestionsQuery, GroupedAnalystQuestionsQueryVariables>;
export const CreateBundleDocument = gql`
    mutation CreateBundle($input: CreateBundleInput!) {
  createBundle(input: $input) {
    id
    name
    type
  }
}
    `;
export type CreateBundleMutationFn = Apollo.MutationFunction<CreateBundleMutation, CreateBundleMutationVariables>;

/**
 * __useCreateBundleMutation__
 *
 * To run a mutation, you first call `useCreateBundleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBundleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBundleMutation, { data, loading, error }] = useCreateBundleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBundleMutation(baseOptions?: Apollo.MutationHookOptions<CreateBundleMutation, CreateBundleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBundleMutation, CreateBundleMutationVariables>(CreateBundleDocument, options);
      }
export type CreateBundleMutationHookResult = ReturnType<typeof useCreateBundleMutation>;
export type CreateBundleMutationResult = Apollo.MutationResult<CreateBundleMutation>;
export type CreateBundleMutationOptions = Apollo.BaseMutationOptions<CreateBundleMutation, CreateBundleMutationVariables>;
export const BulkDeleteBundleDocument = gql`
    mutation BulkDeleteBundle($input: BulkDeleteBundleInput!) {
  bulkDeleteBundle(input: $input) {
    count
  }
}
    `;
export type BulkDeleteBundleMutationFn = Apollo.MutationFunction<BulkDeleteBundleMutation, BulkDeleteBundleMutationVariables>;

/**
 * __useBulkDeleteBundleMutation__
 *
 * To run a mutation, you first call `useBulkDeleteBundleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkDeleteBundleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkDeleteBundleMutation, { data, loading, error }] = useBulkDeleteBundleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkDeleteBundleMutation(baseOptions?: Apollo.MutationHookOptions<BulkDeleteBundleMutation, BulkDeleteBundleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkDeleteBundleMutation, BulkDeleteBundleMutationVariables>(BulkDeleteBundleDocument, options);
      }
export type BulkDeleteBundleMutationHookResult = ReturnType<typeof useBulkDeleteBundleMutation>;
export type BulkDeleteBundleMutationResult = Apollo.MutationResult<BulkDeleteBundleMutation>;
export type BulkDeleteBundleMutationOptions = Apollo.BaseMutationOptions<BulkDeleteBundleMutation, BulkDeleteBundleMutationVariables>;
export const BundlesByIdsDocument = gql`
    query BundlesByIds($ids: [ID!]!) {
  bundlesByIds(ids: $ids) {
    name
    bundleFile {
      file {
        name
      }
    }
  }
}
    `;

/**
 * __useBundlesByIdsQuery__
 *
 * To run a query within a React component, call `useBundlesByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBundlesByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBundlesByIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useBundlesByIdsQuery(baseOptions: Apollo.QueryHookOptions<BundlesByIdsQuery, BundlesByIdsQueryVariables> & ({ variables: BundlesByIdsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BundlesByIdsQuery, BundlesByIdsQueryVariables>(BundlesByIdsDocument, options);
      }
export function useBundlesByIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BundlesByIdsQuery, BundlesByIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BundlesByIdsQuery, BundlesByIdsQueryVariables>(BundlesByIdsDocument, options);
        }
export function useBundlesByIdsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BundlesByIdsQuery, BundlesByIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BundlesByIdsQuery, BundlesByIdsQueryVariables>(BundlesByIdsDocument, options);
        }
export type BundlesByIdsQueryHookResult = ReturnType<typeof useBundlesByIdsQuery>;
export type BundlesByIdsLazyQueryHookResult = ReturnType<typeof useBundlesByIdsLazyQuery>;
export type BundlesByIdsSuspenseQueryHookResult = ReturnType<typeof useBundlesByIdsSuspenseQuery>;
export type BundlesByIdsQueryResult = Apollo.QueryResult<BundlesByIdsQuery, BundlesByIdsQueryVariables>;
export const CreateChatMessageDocument = gql`
    mutation CreateChatMessage($input: CreateChatMessageInput!) {
  createChatMessage(input: $input) {
    id
    channelId
    messageType
    message
    privateRecipientIds
    attachments {
      mimeType
      name
      storageLocationUrl
    }
    mentions {
      targetId
      name
      mentionType
    }
    reactions {
      reaction
      userIds
    }
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
    `;
export type CreateChatMessageMutationFn = Apollo.MutationFunction<CreateChatMessageMutation, CreateChatMessageMutationVariables>;

/**
 * __useCreateChatMessageMutation__
 *
 * To run a mutation, you first call `useCreateChatMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChatMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChatMessageMutation, { data, loading, error }] = useCreateChatMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateChatMessageMutation(baseOptions?: Apollo.MutationHookOptions<CreateChatMessageMutation, CreateChatMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateChatMessageMutation, CreateChatMessageMutationVariables>(CreateChatMessageDocument, options);
      }
export type CreateChatMessageMutationHookResult = ReturnType<typeof useCreateChatMessageMutation>;
export type CreateChatMessageMutationResult = Apollo.MutationResult<CreateChatMessageMutation>;
export type CreateChatMessageMutationOptions = Apollo.BaseMutationOptions<CreateChatMessageMutation, CreateChatMessageMutationVariables>;
export const UpdateChatMessageDocument = gql`
    mutation UpdateChatMessage($input: UpdateChatMessageInput!) {
  updateChatMessage(input: $input) {
    id
    message
    createdAt
    createdBy
    updatedAt
    updatedBy
    updateCount
  }
}
    `;
export type UpdateChatMessageMutationFn = Apollo.MutationFunction<UpdateChatMessageMutation, UpdateChatMessageMutationVariables>;

/**
 * __useUpdateChatMessageMutation__
 *
 * To run a mutation, you first call `useUpdateChatMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChatMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChatMessageMutation, { data, loading, error }] = useUpdateChatMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateChatMessageMutation(baseOptions?: Apollo.MutationHookOptions<UpdateChatMessageMutation, UpdateChatMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateChatMessageMutation, UpdateChatMessageMutationVariables>(UpdateChatMessageDocument, options);
      }
export type UpdateChatMessageMutationHookResult = ReturnType<typeof useUpdateChatMessageMutation>;
export type UpdateChatMessageMutationResult = Apollo.MutationResult<UpdateChatMessageMutation>;
export type UpdateChatMessageMutationOptions = Apollo.BaseMutationOptions<UpdateChatMessageMutation, UpdateChatMessageMutationVariables>;
export const DeleteChatMessageDocument = gql`
    mutation DeleteChatMessage($input: DeleteChatMessageInput!) {
  softOrHardDeleteChatMessage(input: $input) {
    id
  }
}
    `;
export type DeleteChatMessageMutationFn = Apollo.MutationFunction<DeleteChatMessageMutation, DeleteChatMessageMutationVariables>;

/**
 * __useDeleteChatMessageMutation__
 *
 * To run a mutation, you first call `useDeleteChatMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteChatMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChatMessageMutation, { data, loading, error }] = useDeleteChatMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteChatMessageMutation(baseOptions?: Apollo.MutationHookOptions<DeleteChatMessageMutation, DeleteChatMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteChatMessageMutation, DeleteChatMessageMutationVariables>(DeleteChatMessageDocument, options);
      }
export type DeleteChatMessageMutationHookResult = ReturnType<typeof useDeleteChatMessageMutation>;
export type DeleteChatMessageMutationResult = Apollo.MutationResult<DeleteChatMessageMutation>;
export type DeleteChatMessageMutationOptions = Apollo.BaseMutationOptions<DeleteChatMessageMutation, DeleteChatMessageMutationVariables>;
export const ChatMessageDocument = gql`
    query ChatMessage($id: ID!, $channelType: ChatMessageChannelType!) {
  chatMessage(id: $id, channelType: $channelType) {
    id
    channelId
    parentId
    messageType
    message
    privateRecipientIds
    attachments {
      mimeType
      name
      storageLocationUrl
    }
    mentions {
      targetId
      name
      mentionType
    }
    reactions {
      reaction
      userIds
    }
    createdAt
    createdBy
    updatedAt
    updatedBy
    deletedAt
    deletedBy
    childCount
  }
}
    `;

/**
 * __useChatMessageQuery__
 *
 * To run a query within a React component, call `useChatMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatMessageQuery({
 *   variables: {
 *      id: // value for 'id'
 *      channelType: // value for 'channelType'
 *   },
 * });
 */
export function useChatMessageQuery(baseOptions: Apollo.QueryHookOptions<ChatMessageQuery, ChatMessageQueryVariables> & ({ variables: ChatMessageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChatMessageQuery, ChatMessageQueryVariables>(ChatMessageDocument, options);
      }
export function useChatMessageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChatMessageQuery, ChatMessageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChatMessageQuery, ChatMessageQueryVariables>(ChatMessageDocument, options);
        }
export function useChatMessageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ChatMessageQuery, ChatMessageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ChatMessageQuery, ChatMessageQueryVariables>(ChatMessageDocument, options);
        }
export type ChatMessageQueryHookResult = ReturnType<typeof useChatMessageQuery>;
export type ChatMessageLazyQueryHookResult = ReturnType<typeof useChatMessageLazyQuery>;
export type ChatMessageSuspenseQueryHookResult = ReturnType<typeof useChatMessageSuspenseQuery>;
export type ChatMessageQueryResult = Apollo.QueryResult<ChatMessageQuery, ChatMessageQueryVariables>;
export const ChatMessagesDocument = gql`
    query ChatMessages($channelId: ID!, $channelType: ChatMessageChannelType!, $parentId: ID, $offset: Int, $limit: Int) {
  chatMessages(
    channelId: $channelId
    channelType: $channelType
    parentId: $parentId
    offset: $offset
    limit: $limit
  ) {
    id
    channelId
    parentId
    messageType
    message
    privateRecipientIds
    attachments {
      mimeType
      name
      storageLocationUrl
    }
    mentions {
      targetId
      name
      mentionType
    }
    reactions {
      reaction
      userIds
    }
    createdAt
    createdBy
    updatedAt
    updatedBy
    deletedAt
    deletedBy
    childCount
  }
}
    `;

/**
 * __useChatMessagesQuery__
 *
 * To run a query within a React component, call `useChatMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatMessagesQuery({
 *   variables: {
 *      channelId: // value for 'channelId'
 *      channelType: // value for 'channelType'
 *      parentId: // value for 'parentId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useChatMessagesQuery(baseOptions: Apollo.QueryHookOptions<ChatMessagesQuery, ChatMessagesQueryVariables> & ({ variables: ChatMessagesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChatMessagesQuery, ChatMessagesQueryVariables>(ChatMessagesDocument, options);
      }
export function useChatMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChatMessagesQuery, ChatMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChatMessagesQuery, ChatMessagesQueryVariables>(ChatMessagesDocument, options);
        }
export function useChatMessagesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ChatMessagesQuery, ChatMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ChatMessagesQuery, ChatMessagesQueryVariables>(ChatMessagesDocument, options);
        }
export type ChatMessagesQueryHookResult = ReturnType<typeof useChatMessagesQuery>;
export type ChatMessagesLazyQueryHookResult = ReturnType<typeof useChatMessagesLazyQuery>;
export type ChatMessagesSuspenseQueryHookResult = ReturnType<typeof useChatMessagesSuspenseQuery>;
export type ChatMessagesQueryResult = Apollo.QueryResult<ChatMessagesQuery, ChatMessagesQueryVariables>;
export const OnChatMessagesAddedDocument = gql`
    subscription OnChatMessagesAdded($channelId: ID!, $channelType: ChatMessageChannelType!, $parentId: ID) {
  onChatMessageAdded(
    channelId: $channelId
    channelType: $channelType
    parentId: $parentId
  ) {
    id
    channelId
    parentId
    messageType
    message
    privateRecipientIds
    attachments {
      mimeType
      name
      storageLocationUrl
    }
    mentions {
      targetId
      name
      mentionType
    }
    reactions {
      reaction
      userIds
    }
    createdAt
    createdBy
    updatedAt
    updatedBy
    deletedAt
    deletedBy
    childCount
  }
}
    `;

/**
 * __useOnChatMessagesAddedSubscription__
 *
 * To run a query within a React component, call `useOnChatMessagesAddedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnChatMessagesAddedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnChatMessagesAddedSubscription({
 *   variables: {
 *      channelId: // value for 'channelId'
 *      channelType: // value for 'channelType'
 *      parentId: // value for 'parentId'
 *   },
 * });
 */
export function useOnChatMessagesAddedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnChatMessagesAddedSubscription, OnChatMessagesAddedSubscriptionVariables> & ({ variables: OnChatMessagesAddedSubscriptionVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnChatMessagesAddedSubscription, OnChatMessagesAddedSubscriptionVariables>(OnChatMessagesAddedDocument, options);
      }
export type OnChatMessagesAddedSubscriptionHookResult = ReturnType<typeof useOnChatMessagesAddedSubscription>;
export type OnChatMessagesAddedSubscriptionResult = Apollo.SubscriptionResult<OnChatMessagesAddedSubscription>;
export const OnChatMessagesUpdatedDocument = gql`
    subscription OnChatMessagesUpdated($channelId: ID!, $channelType: ChatMessageChannelType!, $parentId: ID) {
  onChatMessageUpdated(
    channelId: $channelId
    channelType: $channelType
    parentId: $parentId
  ) {
    id
    channelId
    parentId
    messageType
    message
    privateRecipientIds
    attachments {
      mimeType
      name
      storageLocationUrl
    }
    mentions {
      targetId
      name
      mentionType
    }
    reactions {
      reaction
      userIds
    }
    createdAt
    createdBy
    updatedAt
    updatedBy
    deletedAt
    deletedBy
    childCount
  }
}
    `;

/**
 * __useOnChatMessagesUpdatedSubscription__
 *
 * To run a query within a React component, call `useOnChatMessagesUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnChatMessagesUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnChatMessagesUpdatedSubscription({
 *   variables: {
 *      channelId: // value for 'channelId'
 *      channelType: // value for 'channelType'
 *      parentId: // value for 'parentId'
 *   },
 * });
 */
export function useOnChatMessagesUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnChatMessagesUpdatedSubscription, OnChatMessagesUpdatedSubscriptionVariables> & ({ variables: OnChatMessagesUpdatedSubscriptionVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnChatMessagesUpdatedSubscription, OnChatMessagesUpdatedSubscriptionVariables>(OnChatMessagesUpdatedDocument, options);
      }
export type OnChatMessagesUpdatedSubscriptionHookResult = ReturnType<typeof useOnChatMessagesUpdatedSubscription>;
export type OnChatMessagesUpdatedSubscriptionResult = Apollo.SubscriptionResult<OnChatMessagesUpdatedSubscription>;
export const OnChatMessagesDeletedDocument = gql`
    subscription OnChatMessagesDeleted($channelId: ID!, $channelType: ChatMessageChannelType!, $parentId: ID) {
  onChatMessageDeleted(
    channelId: $channelId
    channelType: $channelType
    parentId: $parentId
  ) {
    id
  }
}
    `;

/**
 * __useOnChatMessagesDeletedSubscription__
 *
 * To run a query within a React component, call `useOnChatMessagesDeletedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnChatMessagesDeletedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnChatMessagesDeletedSubscription({
 *   variables: {
 *      channelId: // value for 'channelId'
 *      channelType: // value for 'channelType'
 *      parentId: // value for 'parentId'
 *   },
 * });
 */
export function useOnChatMessagesDeletedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnChatMessagesDeletedSubscription, OnChatMessagesDeletedSubscriptionVariables> & ({ variables: OnChatMessagesDeletedSubscriptionVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnChatMessagesDeletedSubscription, OnChatMessagesDeletedSubscriptionVariables>(OnChatMessagesDeletedDocument, options);
      }
export type OnChatMessagesDeletedSubscriptionHookResult = ReturnType<typeof useOnChatMessagesDeletedSubscription>;
export type OnChatMessagesDeletedSubscriptionResult = Apollo.SubscriptionResult<OnChatMessagesDeletedSubscription>;
export const ExecuteCommandDocument = gql`
    mutation ExecuteCommand($input: ExecuteCommandInput!) {
  executeCommand(input: $input) {
    data
    errors
    requestId
    sessionId
    status
  }
}
    `;
export type ExecuteCommandMutationFn = Apollo.MutationFunction<ExecuteCommandMutation, ExecuteCommandMutationVariables>;

/**
 * __useExecuteCommandMutation__
 *
 * To run a mutation, you first call `useExecuteCommandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExecuteCommandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [executeCommandMutation, { data, loading, error }] = useExecuteCommandMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExecuteCommandMutation(baseOptions?: Apollo.MutationHookOptions<ExecuteCommandMutation, ExecuteCommandMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExecuteCommandMutation, ExecuteCommandMutationVariables>(ExecuteCommandDocument, options);
      }
export type ExecuteCommandMutationHookResult = ReturnType<typeof useExecuteCommandMutation>;
export type ExecuteCommandMutationResult = Apollo.MutationResult<ExecuteCommandMutation>;
export type ExecuteCommandMutationOptions = Apollo.BaseMutationOptions<ExecuteCommandMutation, ExecuteCommandMutationVariables>;
export const CommandPingDocument = gql`
    mutation CommandPing($input: ExecuteCommandInput!) {
  executePingCommand(input: $input) {
    data
    errors
    requestId
    sessionId
    status
  }
}
    `;
export type CommandPingMutationFn = Apollo.MutationFunction<CommandPingMutation, CommandPingMutationVariables>;

/**
 * __useCommandPingMutation__
 *
 * To run a mutation, you first call `useCommandPingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCommandPingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [commandPingMutation, { data, loading, error }] = useCommandPingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCommandPingMutation(baseOptions?: Apollo.MutationHookOptions<CommandPingMutation, CommandPingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CommandPingMutation, CommandPingMutationVariables>(CommandPingDocument, options);
      }
export type CommandPingMutationHookResult = ReturnType<typeof useCommandPingMutation>;
export type CommandPingMutationResult = Apollo.MutationResult<CommandPingMutation>;
export type CommandPingMutationOptions = Apollo.BaseMutationOptions<CommandPingMutation, CommandPingMutationVariables>;
export const OnCommandResponseDocument = gql`
    subscription OnCommandResponse($sessionId: String!) {
  commandResponse(sessionId: $sessionId) {
    activeCommandContext
    callerData
    data
    errors
    requestId
    status
    sessionId
  }
}
    `;

/**
 * __useOnCommandResponseSubscription__
 *
 * To run a query within a React component, call `useOnCommandResponseSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnCommandResponseSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnCommandResponseSubscription({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useOnCommandResponseSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnCommandResponseSubscription, OnCommandResponseSubscriptionVariables> & ({ variables: OnCommandResponseSubscriptionVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnCommandResponseSubscription, OnCommandResponseSubscriptionVariables>(OnCommandResponseDocument, options);
      }
export type OnCommandResponseSubscriptionHookResult = ReturnType<typeof useOnCommandResponseSubscription>;
export type OnCommandResponseSubscriptionResult = Apollo.SubscriptionResult<OnCommandResponseSubscription>;
export const CancelCommandDocument = gql`
    mutation CancelCommand($input: CancelCommandInput!) {
  cancelCommand(input: $input) {
    status
  }
}
    `;
export type CancelCommandMutationFn = Apollo.MutationFunction<CancelCommandMutation, CancelCommandMutationVariables>;

/**
 * __useCancelCommandMutation__
 *
 * To run a mutation, you first call `useCancelCommandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelCommandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelCommandMutation, { data, loading, error }] = useCancelCommandMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelCommandMutation(baseOptions?: Apollo.MutationHookOptions<CancelCommandMutation, CancelCommandMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelCommandMutation, CancelCommandMutationVariables>(CancelCommandDocument, options);
      }
export type CancelCommandMutationHookResult = ReturnType<typeof useCancelCommandMutation>;
export type CancelCommandMutationResult = Apollo.MutationResult<CancelCommandMutation>;
export type CancelCommandMutationOptions = Apollo.BaseMutationOptions<CancelCommandMutation, CancelCommandMutationVariables>;
export const CommandStatusDocument = gql`
    query CommandStatus($id: ID!) {
  commandStatus(id: $id) {
    commandId
    requestId
    status
  }
}
    `;

/**
 * __useCommandStatusQuery__
 *
 * To run a query within a React component, call `useCommandStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommandStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommandStatusQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCommandStatusQuery(baseOptions: Apollo.QueryHookOptions<CommandStatusQuery, CommandStatusQueryVariables> & ({ variables: CommandStatusQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CommandStatusQuery, CommandStatusQueryVariables>(CommandStatusDocument, options);
      }
export function useCommandStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommandStatusQuery, CommandStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CommandStatusQuery, CommandStatusQueryVariables>(CommandStatusDocument, options);
        }
export function useCommandStatusSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CommandStatusQuery, CommandStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CommandStatusQuery, CommandStatusQueryVariables>(CommandStatusDocument, options);
        }
export type CommandStatusQueryHookResult = ReturnType<typeof useCommandStatusQuery>;
export type CommandStatusLazyQueryHookResult = ReturnType<typeof useCommandStatusLazyQuery>;
export type CommandStatusSuspenseQueryHookResult = ReturnType<typeof useCommandStatusSuspenseQuery>;
export type CommandStatusQueryResult = Apollo.QueryResult<CommandStatusQuery, CommandStatusQueryVariables>;
export const OnCommandStatusChangedDocument = gql`
    subscription OnCommandStatusChanged($id: ID!) {
  onCommandStatusChanged(id: $id) {
    commandId
    requestId
    status
  }
}
    `;

/**
 * __useOnCommandStatusChangedSubscription__
 *
 * To run a query within a React component, call `useOnCommandStatusChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnCommandStatusChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnCommandStatusChangedSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOnCommandStatusChangedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnCommandStatusChangedSubscription, OnCommandStatusChangedSubscriptionVariables> & ({ variables: OnCommandStatusChangedSubscriptionVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnCommandStatusChangedSubscription, OnCommandStatusChangedSubscriptionVariables>(OnCommandStatusChangedDocument, options);
      }
export type OnCommandStatusChangedSubscriptionHookResult = ReturnType<typeof useOnCommandStatusChangedSubscription>;
export type OnCommandStatusChangedSubscriptionResult = Apollo.SubscriptionResult<OnCommandStatusChangedSubscription>;
export const GetLatestConfigsByPrefixDocument = gql`
    query getLatestConfigsByPrefix($keyPrefix: String!, $organizationId: ID!) {
  LatestConfigsByPrefix(keyPrefix: $keyPrefix, organizationId: $organizationId) {
    data
  }
}
    `;

/**
 * __useGetLatestConfigsByPrefixQuery__
 *
 * To run a query within a React component, call `useGetLatestConfigsByPrefixQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestConfigsByPrefixQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestConfigsByPrefixQuery({
 *   variables: {
 *      keyPrefix: // value for 'keyPrefix'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetLatestConfigsByPrefixQuery(baseOptions: Apollo.QueryHookOptions<GetLatestConfigsByPrefixQuery, GetLatestConfigsByPrefixQueryVariables> & ({ variables: GetLatestConfigsByPrefixQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLatestConfigsByPrefixQuery, GetLatestConfigsByPrefixQueryVariables>(GetLatestConfigsByPrefixDocument, options);
      }
export function useGetLatestConfigsByPrefixLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLatestConfigsByPrefixQuery, GetLatestConfigsByPrefixQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLatestConfigsByPrefixQuery, GetLatestConfigsByPrefixQueryVariables>(GetLatestConfigsByPrefixDocument, options);
        }
export function useGetLatestConfigsByPrefixSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetLatestConfigsByPrefixQuery, GetLatestConfigsByPrefixQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetLatestConfigsByPrefixQuery, GetLatestConfigsByPrefixQueryVariables>(GetLatestConfigsByPrefixDocument, options);
        }
export type GetLatestConfigsByPrefixQueryHookResult = ReturnType<typeof useGetLatestConfigsByPrefixQuery>;
export type GetLatestConfigsByPrefixLazyQueryHookResult = ReturnType<typeof useGetLatestConfigsByPrefixLazyQuery>;
export type GetLatestConfigsByPrefixSuspenseQueryHookResult = ReturnType<typeof useGetLatestConfigsByPrefixSuspenseQuery>;
export type GetLatestConfigsByPrefixQueryResult = Apollo.QueryResult<GetLatestConfigsByPrefixQuery, GetLatestConfigsByPrefixQueryVariables>;
export const GetConfigDataDocument = gql`
    query getConfigData($key: ID!, $organizationId: ID!) {
  Config(key: $key, organizationId: $organizationId) {
    data
  }
}
    `;

/**
 * __useGetConfigDataQuery__
 *
 * To run a query within a React component, call `useGetConfigDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConfigDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConfigDataQuery({
 *   variables: {
 *      key: // value for 'key'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetConfigDataQuery(baseOptions: Apollo.QueryHookOptions<GetConfigDataQuery, GetConfigDataQueryVariables> & ({ variables: GetConfigDataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetConfigDataQuery, GetConfigDataQueryVariables>(GetConfigDataDocument, options);
      }
export function useGetConfigDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConfigDataQuery, GetConfigDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetConfigDataQuery, GetConfigDataQueryVariables>(GetConfigDataDocument, options);
        }
export function useGetConfigDataSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetConfigDataQuery, GetConfigDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetConfigDataQuery, GetConfigDataQueryVariables>(GetConfigDataDocument, options);
        }
export type GetConfigDataQueryHookResult = ReturnType<typeof useGetConfigDataQuery>;
export type GetConfigDataLazyQueryHookResult = ReturnType<typeof useGetConfigDataLazyQuery>;
export type GetConfigDataSuspenseQueryHookResult = ReturnType<typeof useGetConfigDataSuspenseQuery>;
export type GetConfigDataQueryResult = Apollo.QueryResult<GetConfigDataQuery, GetConfigDataQueryVariables>;
export const GetAppConfigDataDocument = gql`
    query getAppConfigData($organizationId: ID!, $baseDocumentStylesheetKey: ID!, $organizationDocumentStylesheetKey: ID!, $organizationPreferencesKey: ID!) {
  BaseDocumentStylesheet: Config(
    key: $baseDocumentStylesheetKey
    organizationId: $organizationId
  ) {
    data
  }
  OrganizationDocumentStylesheet: Config(
    key: $organizationDocumentStylesheetKey
    organizationId: $organizationId
  ) {
    data
  }
  OrganizationPreferences: Config(
    key: $organizationPreferencesKey
    organizationId: $organizationId
  ) {
    data
  }
}
    `;

/**
 * __useGetAppConfigDataQuery__
 *
 * To run a query within a React component, call `useGetAppConfigDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppConfigDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppConfigDataQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      baseDocumentStylesheetKey: // value for 'baseDocumentStylesheetKey'
 *      organizationDocumentStylesheetKey: // value for 'organizationDocumentStylesheetKey'
 *      organizationPreferencesKey: // value for 'organizationPreferencesKey'
 *   },
 * });
 */
export function useGetAppConfigDataQuery(baseOptions: Apollo.QueryHookOptions<GetAppConfigDataQuery, GetAppConfigDataQueryVariables> & ({ variables: GetAppConfigDataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAppConfigDataQuery, GetAppConfigDataQueryVariables>(GetAppConfigDataDocument, options);
      }
export function useGetAppConfigDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAppConfigDataQuery, GetAppConfigDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAppConfigDataQuery, GetAppConfigDataQueryVariables>(GetAppConfigDataDocument, options);
        }
export function useGetAppConfigDataSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAppConfigDataQuery, GetAppConfigDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAppConfigDataQuery, GetAppConfigDataQueryVariables>(GetAppConfigDataDocument, options);
        }
export type GetAppConfigDataQueryHookResult = ReturnType<typeof useGetAppConfigDataQuery>;
export type GetAppConfigDataLazyQueryHookResult = ReturnType<typeof useGetAppConfigDataLazyQuery>;
export type GetAppConfigDataSuspenseQueryHookResult = ReturnType<typeof useGetAppConfigDataSuspenseQuery>;
export type GetAppConfigDataQueryResult = Apollo.QueryResult<GetAppConfigDataQuery, GetAppConfigDataQueryVariables>;
export const DistilledTranscriptDocument = gql`
    query DistilledTranscript($transcriptId: ID!) {
  distilledTranscript(transcriptId: $transcriptId) {
    id
    displaySymbol
    distilledSegments {
      segmentIndex
      session
      distilledTranscriptItems {
        itemIndex
        fullName
        occupationTitles
        role
        session
        speech
      }
      originalTranscriptItems {
        itemIndex
        fullName
        occupationTitles
        role
        session
        speech
      }
    }
    eventType
    itemDate
    title
  }
}
    `;

/**
 * __useDistilledTranscriptQuery__
 *
 * To run a query within a React component, call `useDistilledTranscriptQuery` and pass it any options that fit your needs.
 * When your component renders, `useDistilledTranscriptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDistilledTranscriptQuery({
 *   variables: {
 *      transcriptId: // value for 'transcriptId'
 *   },
 * });
 */
export function useDistilledTranscriptQuery(baseOptions: Apollo.QueryHookOptions<DistilledTranscriptQuery, DistilledTranscriptQueryVariables> & ({ variables: DistilledTranscriptQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DistilledTranscriptQuery, DistilledTranscriptQueryVariables>(DistilledTranscriptDocument, options);
      }
export function useDistilledTranscriptLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DistilledTranscriptQuery, DistilledTranscriptQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DistilledTranscriptQuery, DistilledTranscriptQueryVariables>(DistilledTranscriptDocument, options);
        }
export function useDistilledTranscriptSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DistilledTranscriptQuery, DistilledTranscriptQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DistilledTranscriptQuery, DistilledTranscriptQueryVariables>(DistilledTranscriptDocument, options);
        }
export type DistilledTranscriptQueryHookResult = ReturnType<typeof useDistilledTranscriptQuery>;
export type DistilledTranscriptLazyQueryHookResult = ReturnType<typeof useDistilledTranscriptLazyQuery>;
export type DistilledTranscriptSuspenseQueryHookResult = ReturnType<typeof useDistilledTranscriptSuspenseQuery>;
export type DistilledTranscriptQueryResult = Apollo.QueryResult<DistilledTranscriptQuery, DistilledTranscriptQueryVariables>;
export const CreateDocumentDocument = gql`
    mutation CreateDocument($input: CreateDocumentInput!) {
  createDocument(input: $input) {
    id
    name
    content {
      id
      content
      contentType
    }
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
    `;
export type CreateDocumentMutationFn = Apollo.MutationFunction<CreateDocumentMutation, CreateDocumentMutationVariables>;

/**
 * __useCreateDocumentMutation__
 *
 * To run a mutation, you first call `useCreateDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDocumentMutation, { data, loading, error }] = useCreateDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDocumentMutation(baseOptions?: Apollo.MutationHookOptions<CreateDocumentMutation, CreateDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDocumentMutation, CreateDocumentMutationVariables>(CreateDocumentDocument, options);
      }
export type CreateDocumentMutationHookResult = ReturnType<typeof useCreateDocumentMutation>;
export type CreateDocumentMutationResult = Apollo.MutationResult<CreateDocumentMutation>;
export type CreateDocumentMutationOptions = Apollo.BaseMutationOptions<CreateDocumentMutation, CreateDocumentMutationVariables>;
export const UpdateDocumentDocument = gql`
    mutation UpdateDocument($input: UpdateDocumentInput!) {
  updateDocument(input: $input) {
    id
    name
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
    `;
export type UpdateDocumentMutationFn = Apollo.MutationFunction<UpdateDocumentMutation, UpdateDocumentMutationVariables>;

/**
 * __useUpdateDocumentMutation__
 *
 * To run a mutation, you first call `useUpdateDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocumentMutation, { data, loading, error }] = useUpdateDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDocumentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDocumentMutation, UpdateDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDocumentMutation, UpdateDocumentMutationVariables>(UpdateDocumentDocument, options);
      }
export type UpdateDocumentMutationHookResult = ReturnType<typeof useUpdateDocumentMutation>;
export type UpdateDocumentMutationResult = Apollo.MutationResult<UpdateDocumentMutation>;
export type UpdateDocumentMutationOptions = Apollo.BaseMutationOptions<UpdateDocumentMutation, UpdateDocumentMutationVariables>;
export const DeleteDocumentDocument = gql`
    mutation DeleteDocument($input: DeleteDocumentInput!) {
  deleteDocument(input: $input) {
    id
  }
}
    `;
export type DeleteDocumentMutationFn = Apollo.MutationFunction<DeleteDocumentMutation, DeleteDocumentMutationVariables>;

/**
 * __useDeleteDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDocumentMutation, { data, loading, error }] = useDeleteDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteDocumentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDocumentMutation, DeleteDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDocumentMutation, DeleteDocumentMutationVariables>(DeleteDocumentDocument, options);
      }
export type DeleteDocumentMutationHookResult = ReturnType<typeof useDeleteDocumentMutation>;
export type DeleteDocumentMutationResult = Apollo.MutationResult<DeleteDocumentMutation>;
export type DeleteDocumentMutationOptions = Apollo.BaseMutationOptions<DeleteDocumentMutation, DeleteDocumentMutationVariables>;
export const BulkDeleteDocumentDocument = gql`
    mutation BulkDeleteDocument($input: BulkDeleteDocumentInput!) {
  bulkDeleteDocument(input: $input) {
    count
  }
}
    `;
export type BulkDeleteDocumentMutationFn = Apollo.MutationFunction<BulkDeleteDocumentMutation, BulkDeleteDocumentMutationVariables>;

/**
 * __useBulkDeleteDocumentMutation__
 *
 * To run a mutation, you first call `useBulkDeleteDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkDeleteDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkDeleteDocumentMutation, { data, loading, error }] = useBulkDeleteDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkDeleteDocumentMutation(baseOptions?: Apollo.MutationHookOptions<BulkDeleteDocumentMutation, BulkDeleteDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkDeleteDocumentMutation, BulkDeleteDocumentMutationVariables>(BulkDeleteDocumentDocument, options);
      }
export type BulkDeleteDocumentMutationHookResult = ReturnType<typeof useBulkDeleteDocumentMutation>;
export type BulkDeleteDocumentMutationResult = Apollo.MutationResult<BulkDeleteDocumentMutation>;
export type BulkDeleteDocumentMutationOptions = Apollo.BaseMutationOptions<BulkDeleteDocumentMutation, BulkDeleteDocumentMutationVariables>;
export const EditDocumentsInWorkspaceDocument = gql`
    mutation EditDocumentsInWorkspace($input: EditDocumentsInWorkspaceInput!) {
  editDocumentsInWorkspace(input: $input) {
    id
    description
    name
    tags
    defaultWorkspaceBoard {
      id
      name
      tags
      thumbnail
    }
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
    `;
export type EditDocumentsInWorkspaceMutationFn = Apollo.MutationFunction<EditDocumentsInWorkspaceMutation, EditDocumentsInWorkspaceMutationVariables>;

/**
 * __useEditDocumentsInWorkspaceMutation__
 *
 * To run a mutation, you first call `useEditDocumentsInWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditDocumentsInWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editDocumentsInWorkspaceMutation, { data, loading, error }] = useEditDocumentsInWorkspaceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditDocumentsInWorkspaceMutation(baseOptions?: Apollo.MutationHookOptions<EditDocumentsInWorkspaceMutation, EditDocumentsInWorkspaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditDocumentsInWorkspaceMutation, EditDocumentsInWorkspaceMutationVariables>(EditDocumentsInWorkspaceDocument, options);
      }
export type EditDocumentsInWorkspaceMutationHookResult = ReturnType<typeof useEditDocumentsInWorkspaceMutation>;
export type EditDocumentsInWorkspaceMutationResult = Apollo.MutationResult<EditDocumentsInWorkspaceMutation>;
export type EditDocumentsInWorkspaceMutationOptions = Apollo.BaseMutationOptions<EditDocumentsInWorkspaceMutation, EditDocumentsInWorkspaceMutationVariables>;
export const ReindexDocumentsDocument = gql`
    mutation ReindexDocuments($input: ReindexDocumentsInput!) {
  reindexDocuments(input: $input) {
    documentIds
  }
}
    `;
export type ReindexDocumentsMutationFn = Apollo.MutationFunction<ReindexDocumentsMutation, ReindexDocumentsMutationVariables>;

/**
 * __useReindexDocumentsMutation__
 *
 * To run a mutation, you first call `useReindexDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReindexDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reindexDocumentsMutation, { data, loading, error }] = useReindexDocumentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReindexDocumentsMutation(baseOptions?: Apollo.MutationHookOptions<ReindexDocumentsMutation, ReindexDocumentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReindexDocumentsMutation, ReindexDocumentsMutationVariables>(ReindexDocumentsDocument, options);
      }
export type ReindexDocumentsMutationHookResult = ReturnType<typeof useReindexDocumentsMutation>;
export type ReindexDocumentsMutationResult = Apollo.MutationResult<ReindexDocumentsMutation>;
export type ReindexDocumentsMutationOptions = Apollo.BaseMutationOptions<ReindexDocumentsMutation, ReindexDocumentsMutationVariables>;
export const DocumentDocument = gql`
    query Document($id: ID!) {
  document(id: $id) {
    id
    name
  }
}
    `;

/**
 * __useDocumentQuery__
 *
 * To run a query within a React component, call `useDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDocumentQuery(baseOptions: Apollo.QueryHookOptions<DocumentQuery, DocumentQueryVariables> & ({ variables: DocumentQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocumentQuery, DocumentQueryVariables>(DocumentDocument, options);
      }
export function useDocumentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentQuery, DocumentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocumentQuery, DocumentQueryVariables>(DocumentDocument, options);
        }
export function useDocumentSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DocumentQuery, DocumentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DocumentQuery, DocumentQueryVariables>(DocumentDocument, options);
        }
export type DocumentQueryHookResult = ReturnType<typeof useDocumentQuery>;
export type DocumentLazyQueryHookResult = ReturnType<typeof useDocumentLazyQuery>;
export type DocumentSuspenseQueryHookResult = ReturnType<typeof useDocumentSuspenseQuery>;
export type DocumentQueryResult = Apollo.QueryResult<DocumentQuery, DocumentQueryVariables>;
export const DocumentsDocument = gql`
    query Documents($offset: Int, $limit: Int, $orderBy: DocumentOrderBy, $filters: DocumentFilters) {
  documents(offset: $offset, limit: $limit, orderBy: $orderBy, filters: $filters) {
    id
    name
    documentAccessControlList {
      userGroup {
        name
      }
      user {
        id
        name
      }
    }
  }
  documentAggregate(filters: $filters) {
    count
  }
}
    `;

/**
 * __useDocumentsQuery__
 *
 * To run a query within a React component, call `useDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      orderBy: // value for 'orderBy'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useDocumentsQuery(baseOptions?: Apollo.QueryHookOptions<DocumentsQuery, DocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocumentsQuery, DocumentsQueryVariables>(DocumentsDocument, options);
      }
export function useDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentsQuery, DocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocumentsQuery, DocumentsQueryVariables>(DocumentsDocument, options);
        }
export function useDocumentsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DocumentsQuery, DocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DocumentsQuery, DocumentsQueryVariables>(DocumentsDocument, options);
        }
export type DocumentsQueryHookResult = ReturnType<typeof useDocumentsQuery>;
export type DocumentsLazyQueryHookResult = ReturnType<typeof useDocumentsLazyQuery>;
export type DocumentsSuspenseQueryHookResult = ReturnType<typeof useDocumentsSuspenseQuery>;
export type DocumentsQueryResult = Apollo.QueryResult<DocumentsQuery, DocumentsQueryVariables>;
export const DocumentNamesByIdsDocument = gql`
    query DocumentNamesByIds($ids: [ID!]!) {
  documentsByIds(ids: $ids) {
    name
  }
}
    `;

/**
 * __useDocumentNamesByIdsQuery__
 *
 * To run a query within a React component, call `useDocumentNamesByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentNamesByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentNamesByIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDocumentNamesByIdsQuery(baseOptions: Apollo.QueryHookOptions<DocumentNamesByIdsQuery, DocumentNamesByIdsQueryVariables> & ({ variables: DocumentNamesByIdsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocumentNamesByIdsQuery, DocumentNamesByIdsQueryVariables>(DocumentNamesByIdsDocument, options);
      }
export function useDocumentNamesByIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentNamesByIdsQuery, DocumentNamesByIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocumentNamesByIdsQuery, DocumentNamesByIdsQueryVariables>(DocumentNamesByIdsDocument, options);
        }
export function useDocumentNamesByIdsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DocumentNamesByIdsQuery, DocumentNamesByIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DocumentNamesByIdsQuery, DocumentNamesByIdsQueryVariables>(DocumentNamesByIdsDocument, options);
        }
export type DocumentNamesByIdsQueryHookResult = ReturnType<typeof useDocumentNamesByIdsQuery>;
export type DocumentNamesByIdsLazyQueryHookResult = ReturnType<typeof useDocumentNamesByIdsLazyQuery>;
export type DocumentNamesByIdsSuspenseQueryHookResult = ReturnType<typeof useDocumentNamesByIdsSuspenseQuery>;
export type DocumentNamesByIdsQueryResult = Apollo.QueryResult<DocumentNamesByIdsQuery, DocumentNamesByIdsQueryVariables>;
export const DocumentContentDocument = gql`
    query DocumentContent($id: ID!) {
  document(id: $id) {
    content {
      content
    }
  }
}
    `;

/**
 * __useDocumentContentQuery__
 *
 * To run a query within a React component, call `useDocumentContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentContentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDocumentContentQuery(baseOptions: Apollo.QueryHookOptions<DocumentContentQuery, DocumentContentQueryVariables> & ({ variables: DocumentContentQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocumentContentQuery, DocumentContentQueryVariables>(DocumentContentDocument, options);
      }
export function useDocumentContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentContentQuery, DocumentContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocumentContentQuery, DocumentContentQueryVariables>(DocumentContentDocument, options);
        }
export function useDocumentContentSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DocumentContentQuery, DocumentContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DocumentContentQuery, DocumentContentQueryVariables>(DocumentContentDocument, options);
        }
export type DocumentContentQueryHookResult = ReturnType<typeof useDocumentContentQuery>;
export type DocumentContentLazyQueryHookResult = ReturnType<typeof useDocumentContentLazyQuery>;
export type DocumentContentSuspenseQueryHookResult = ReturnType<typeof useDocumentContentSuspenseQuery>;
export type DocumentContentQueryResult = Apollo.QueryResult<DocumentContentQuery, DocumentContentQueryVariables>;
export const CreateDocumentAccessControlListDocument = gql`
    mutation CreateDocumentAccessControlList($input: CreateDocumentAccessControlListInput!) {
  createDocumentAccessControlList(input: $input) {
    id
    documentId
    role
    userGroup {
      id
    }
    user {
      id
      name
      profilePictureUrl
    }
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
    `;
export type CreateDocumentAccessControlListMutationFn = Apollo.MutationFunction<CreateDocumentAccessControlListMutation, CreateDocumentAccessControlListMutationVariables>;

/**
 * __useCreateDocumentAccessControlListMutation__
 *
 * To run a mutation, you first call `useCreateDocumentAccessControlListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDocumentAccessControlListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDocumentAccessControlListMutation, { data, loading, error }] = useCreateDocumentAccessControlListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDocumentAccessControlListMutation(baseOptions?: Apollo.MutationHookOptions<CreateDocumentAccessControlListMutation, CreateDocumentAccessControlListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDocumentAccessControlListMutation, CreateDocumentAccessControlListMutationVariables>(CreateDocumentAccessControlListDocument, options);
      }
export type CreateDocumentAccessControlListMutationHookResult = ReturnType<typeof useCreateDocumentAccessControlListMutation>;
export type CreateDocumentAccessControlListMutationResult = Apollo.MutationResult<CreateDocumentAccessControlListMutation>;
export type CreateDocumentAccessControlListMutationOptions = Apollo.BaseMutationOptions<CreateDocumentAccessControlListMutation, CreateDocumentAccessControlListMutationVariables>;
export const UpdateDocumentAccessControlListDocument = gql`
    mutation UpdateDocumentAccessControlList($input: UpdateDocumentAccessControlListInput!) {
  updateDocumentAccessControlList(input: $input) {
    id
    documentId
    role
    userGroup {
      id
    }
    user {
      id
      name
      profilePictureUrl
    }
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
    `;
export type UpdateDocumentAccessControlListMutationFn = Apollo.MutationFunction<UpdateDocumentAccessControlListMutation, UpdateDocumentAccessControlListMutationVariables>;

/**
 * __useUpdateDocumentAccessControlListMutation__
 *
 * To run a mutation, you first call `useUpdateDocumentAccessControlListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocumentAccessControlListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocumentAccessControlListMutation, { data, loading, error }] = useUpdateDocumentAccessControlListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDocumentAccessControlListMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDocumentAccessControlListMutation, UpdateDocumentAccessControlListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDocumentAccessControlListMutation, UpdateDocumentAccessControlListMutationVariables>(UpdateDocumentAccessControlListDocument, options);
      }
export type UpdateDocumentAccessControlListMutationHookResult = ReturnType<typeof useUpdateDocumentAccessControlListMutation>;
export type UpdateDocumentAccessControlListMutationResult = Apollo.MutationResult<UpdateDocumentAccessControlListMutation>;
export type UpdateDocumentAccessControlListMutationOptions = Apollo.BaseMutationOptions<UpdateDocumentAccessControlListMutation, UpdateDocumentAccessControlListMutationVariables>;
export const DeleteDocumentAccessControlListDocument = gql`
    mutation DeleteDocumentAccessControlList($input: DeleteDocumentAccessControlListInput!) {
  deleteDocumentAccessControlList(input: $input) {
    id
  }
}
    `;
export type DeleteDocumentAccessControlListMutationFn = Apollo.MutationFunction<DeleteDocumentAccessControlListMutation, DeleteDocumentAccessControlListMutationVariables>;

/**
 * __useDeleteDocumentAccessControlListMutation__
 *
 * To run a mutation, you first call `useDeleteDocumentAccessControlListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDocumentAccessControlListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDocumentAccessControlListMutation, { data, loading, error }] = useDeleteDocumentAccessControlListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteDocumentAccessControlListMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDocumentAccessControlListMutation, DeleteDocumentAccessControlListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDocumentAccessControlListMutation, DeleteDocumentAccessControlListMutationVariables>(DeleteDocumentAccessControlListDocument, options);
      }
export type DeleteDocumentAccessControlListMutationHookResult = ReturnType<typeof useDeleteDocumentAccessControlListMutation>;
export type DeleteDocumentAccessControlListMutationResult = Apollo.MutationResult<DeleteDocumentAccessControlListMutation>;
export type DeleteDocumentAccessControlListMutationOptions = Apollo.BaseMutationOptions<DeleteDocumentAccessControlListMutation, DeleteDocumentAccessControlListMutationVariables>;
export const DocumentAccessControlListHasRoleDocument = gql`
    query DocumentAccessControlListHasRole($documentIds: [ID!]!, $roles: [ObjectRole!]!) {
  hasRoleForDocuments(documentIds: $documentIds, roles: $roles)
}
    `;

/**
 * __useDocumentAccessControlListHasRoleQuery__
 *
 * To run a query within a React component, call `useDocumentAccessControlListHasRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentAccessControlListHasRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentAccessControlListHasRoleQuery({
 *   variables: {
 *      documentIds: // value for 'documentIds'
 *      roles: // value for 'roles'
 *   },
 * });
 */
export function useDocumentAccessControlListHasRoleQuery(baseOptions: Apollo.QueryHookOptions<DocumentAccessControlListHasRoleQuery, DocumentAccessControlListHasRoleQueryVariables> & ({ variables: DocumentAccessControlListHasRoleQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocumentAccessControlListHasRoleQuery, DocumentAccessControlListHasRoleQueryVariables>(DocumentAccessControlListHasRoleDocument, options);
      }
export function useDocumentAccessControlListHasRoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentAccessControlListHasRoleQuery, DocumentAccessControlListHasRoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocumentAccessControlListHasRoleQuery, DocumentAccessControlListHasRoleQueryVariables>(DocumentAccessControlListHasRoleDocument, options);
        }
export function useDocumentAccessControlListHasRoleSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DocumentAccessControlListHasRoleQuery, DocumentAccessControlListHasRoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DocumentAccessControlListHasRoleQuery, DocumentAccessControlListHasRoleQueryVariables>(DocumentAccessControlListHasRoleDocument, options);
        }
export type DocumentAccessControlListHasRoleQueryHookResult = ReturnType<typeof useDocumentAccessControlListHasRoleQuery>;
export type DocumentAccessControlListHasRoleLazyQueryHookResult = ReturnType<typeof useDocumentAccessControlListHasRoleLazyQuery>;
export type DocumentAccessControlListHasRoleSuspenseQueryHookResult = ReturnType<typeof useDocumentAccessControlListHasRoleSuspenseQuery>;
export type DocumentAccessControlListHasRoleQueryResult = Apollo.QueryResult<DocumentAccessControlListHasRoleQuery, DocumentAccessControlListHasRoleQueryVariables>;
export const DocumentAccessControlListDocument = gql`
    query DocumentAccessControlList($id: ID!) {
  documentAccessControlList(id: $id) {
    id
    documentId
    role
    userGroup {
      id
      name
      isSystem
    }
    user {
      id
      name
      profilePictureUrl
    }
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
    `;

/**
 * __useDocumentAccessControlListQuery__
 *
 * To run a query within a React component, call `useDocumentAccessControlListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentAccessControlListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentAccessControlListQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDocumentAccessControlListQuery(baseOptions: Apollo.QueryHookOptions<DocumentAccessControlListQuery, DocumentAccessControlListQueryVariables> & ({ variables: DocumentAccessControlListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocumentAccessControlListQuery, DocumentAccessControlListQueryVariables>(DocumentAccessControlListDocument, options);
      }
export function useDocumentAccessControlListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentAccessControlListQuery, DocumentAccessControlListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocumentAccessControlListQuery, DocumentAccessControlListQueryVariables>(DocumentAccessControlListDocument, options);
        }
export function useDocumentAccessControlListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DocumentAccessControlListQuery, DocumentAccessControlListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DocumentAccessControlListQuery, DocumentAccessControlListQueryVariables>(DocumentAccessControlListDocument, options);
        }
export type DocumentAccessControlListQueryHookResult = ReturnType<typeof useDocumentAccessControlListQuery>;
export type DocumentAccessControlListLazyQueryHookResult = ReturnType<typeof useDocumentAccessControlListLazyQuery>;
export type DocumentAccessControlListSuspenseQueryHookResult = ReturnType<typeof useDocumentAccessControlListSuspenseQuery>;
export type DocumentAccessControlListQueryResult = Apollo.QueryResult<DocumentAccessControlListQuery, DocumentAccessControlListQueryVariables>;
export const DocumentAccessControlListByObjectDocument = gql`
    query DocumentAccessControlListByObject($documentId: ID!) {
  documentAccessControlListByObject(documentId: $documentId) {
    id
    documentId
    role
    userGroup {
      id
      name
      isSystem
    }
    user {
      id
      name
      profilePictureUrl
    }
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
  documentAccessControlListByObjectAggregate(documentId: $documentId) {
    count
  }
}
    `;

/**
 * __useDocumentAccessControlListByObjectQuery__
 *
 * To run a query within a React component, call `useDocumentAccessControlListByObjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentAccessControlListByObjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentAccessControlListByObjectQuery({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useDocumentAccessControlListByObjectQuery(baseOptions: Apollo.QueryHookOptions<DocumentAccessControlListByObjectQuery, DocumentAccessControlListByObjectQueryVariables> & ({ variables: DocumentAccessControlListByObjectQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocumentAccessControlListByObjectQuery, DocumentAccessControlListByObjectQueryVariables>(DocumentAccessControlListByObjectDocument, options);
      }
export function useDocumentAccessControlListByObjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentAccessControlListByObjectQuery, DocumentAccessControlListByObjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocumentAccessControlListByObjectQuery, DocumentAccessControlListByObjectQueryVariables>(DocumentAccessControlListByObjectDocument, options);
        }
export function useDocumentAccessControlListByObjectSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DocumentAccessControlListByObjectQuery, DocumentAccessControlListByObjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DocumentAccessControlListByObjectQuery, DocumentAccessControlListByObjectQueryVariables>(DocumentAccessControlListByObjectDocument, options);
        }
export type DocumentAccessControlListByObjectQueryHookResult = ReturnType<typeof useDocumentAccessControlListByObjectQuery>;
export type DocumentAccessControlListByObjectLazyQueryHookResult = ReturnType<typeof useDocumentAccessControlListByObjectLazyQuery>;
export type DocumentAccessControlListByObjectSuspenseQueryHookResult = ReturnType<typeof useDocumentAccessControlListByObjectSuspenseQuery>;
export type DocumentAccessControlListByObjectQueryResult = Apollo.QueryResult<DocumentAccessControlListByObjectQuery, DocumentAccessControlListByObjectQueryVariables>;
export const DocumentAccessControlListUserRolesDocument = gql`
    query DocumentAccessControlListUserRoles($userId: ID!, $documentId: ID!) {
  documentAccessControlListUserRoles(userId: $userId, documentId: $documentId) {
    userId
    objectId
    roles
  }
}
    `;

/**
 * __useDocumentAccessControlListUserRolesQuery__
 *
 * To run a query within a React component, call `useDocumentAccessControlListUserRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentAccessControlListUserRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentAccessControlListUserRolesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useDocumentAccessControlListUserRolesQuery(baseOptions: Apollo.QueryHookOptions<DocumentAccessControlListUserRolesQuery, DocumentAccessControlListUserRolesQueryVariables> & ({ variables: DocumentAccessControlListUserRolesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocumentAccessControlListUserRolesQuery, DocumentAccessControlListUserRolesQueryVariables>(DocumentAccessControlListUserRolesDocument, options);
      }
export function useDocumentAccessControlListUserRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentAccessControlListUserRolesQuery, DocumentAccessControlListUserRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocumentAccessControlListUserRolesQuery, DocumentAccessControlListUserRolesQueryVariables>(DocumentAccessControlListUserRolesDocument, options);
        }
export function useDocumentAccessControlListUserRolesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DocumentAccessControlListUserRolesQuery, DocumentAccessControlListUserRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DocumentAccessControlListUserRolesQuery, DocumentAccessControlListUserRolesQueryVariables>(DocumentAccessControlListUserRolesDocument, options);
        }
export type DocumentAccessControlListUserRolesQueryHookResult = ReturnType<typeof useDocumentAccessControlListUserRolesQuery>;
export type DocumentAccessControlListUserRolesLazyQueryHookResult = ReturnType<typeof useDocumentAccessControlListUserRolesLazyQuery>;
export type DocumentAccessControlListUserRolesSuspenseQueryHookResult = ReturnType<typeof useDocumentAccessControlListUserRolesSuspenseQuery>;
export type DocumentAccessControlListUserRolesQueryResult = Apollo.QueryResult<DocumentAccessControlListUserRolesQuery, DocumentAccessControlListUserRolesQueryVariables>;
export const UpdateDocumentContentDocument = gql`
    mutation UpdateDocumentContent($input: UpdateDocumentContentInput!) {
  updateDocumentContent(input: $input) {
    id
  }
}
    `;
export type UpdateDocumentContentMutationFn = Apollo.MutationFunction<UpdateDocumentContentMutation, UpdateDocumentContentMutationVariables>;

/**
 * __useUpdateDocumentContentMutation__
 *
 * To run a mutation, you first call `useUpdateDocumentContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocumentContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocumentContentMutation, { data, loading, error }] = useUpdateDocumentContentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDocumentContentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDocumentContentMutation, UpdateDocumentContentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDocumentContentMutation, UpdateDocumentContentMutationVariables>(UpdateDocumentContentDocument, options);
      }
export type UpdateDocumentContentMutationHookResult = ReturnType<typeof useUpdateDocumentContentMutation>;
export type UpdateDocumentContentMutationResult = Apollo.MutationResult<UpdateDocumentContentMutation>;
export type UpdateDocumentContentMutationOptions = Apollo.BaseMutationOptions<UpdateDocumentContentMutation, UpdateDocumentContentMutationVariables>;
export const CreateFileDocument = gql`
    mutation CreateFile($input: CreateFileInput!) {
  createFile(input: $input) {
    id
    name
    url
    filename
    mimeType
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
    `;
export type CreateFileMutationFn = Apollo.MutationFunction<CreateFileMutation, CreateFileMutationVariables>;

/**
 * __useCreateFileMutation__
 *
 * To run a mutation, you first call `useCreateFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFileMutation, { data, loading, error }] = useCreateFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFileMutation(baseOptions?: Apollo.MutationHookOptions<CreateFileMutation, CreateFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFileMutation, CreateFileMutationVariables>(CreateFileDocument, options);
      }
export type CreateFileMutationHookResult = ReturnType<typeof useCreateFileMutation>;
export type CreateFileMutationResult = Apollo.MutationResult<CreateFileMutation>;
export type CreateFileMutationOptions = Apollo.BaseMutationOptions<CreateFileMutation, CreateFileMutationVariables>;
export const UpdateFileDocument = gql`
    mutation UpdateFile($input: UpdateFileInput!) {
  updateFile(input: $input) {
    id
    name
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
    `;
export type UpdateFileMutationFn = Apollo.MutationFunction<UpdateFileMutation, UpdateFileMutationVariables>;

/**
 * __useUpdateFileMutation__
 *
 * To run a mutation, you first call `useUpdateFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFileMutation, { data, loading, error }] = useUpdateFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFileMutation, UpdateFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFileMutation, UpdateFileMutationVariables>(UpdateFileDocument, options);
      }
export type UpdateFileMutationHookResult = ReturnType<typeof useUpdateFileMutation>;
export type UpdateFileMutationResult = Apollo.MutationResult<UpdateFileMutation>;
export type UpdateFileMutationOptions = Apollo.BaseMutationOptions<UpdateFileMutation, UpdateFileMutationVariables>;
export const DeleteFileDocument = gql`
    mutation DeleteFile($input: DeleteFileInput!) {
  deleteFile(input: $input) {
    id
  }
}
    `;
export type DeleteFileMutationFn = Apollo.MutationFunction<DeleteFileMutation, DeleteFileMutationVariables>;

/**
 * __useDeleteFileMutation__
 *
 * To run a mutation, you first call `useDeleteFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFileMutation, { data, loading, error }] = useDeleteFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteFileMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFileMutation, DeleteFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFileMutation, DeleteFileMutationVariables>(DeleteFileDocument, options);
      }
export type DeleteFileMutationHookResult = ReturnType<typeof useDeleteFileMutation>;
export type DeleteFileMutationResult = Apollo.MutationResult<DeleteFileMutation>;
export type DeleteFileMutationOptions = Apollo.BaseMutationOptions<DeleteFileMutation, DeleteFileMutationVariables>;
export const BulkDeleteFileDocument = gql`
    mutation BulkDeleteFile($input: BulkDeleteFileInput!) {
  bulkDeleteFile(input: $input) {
    count
  }
}
    `;
export type BulkDeleteFileMutationFn = Apollo.MutationFunction<BulkDeleteFileMutation, BulkDeleteFileMutationVariables>;

/**
 * __useBulkDeleteFileMutation__
 *
 * To run a mutation, you first call `useBulkDeleteFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkDeleteFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkDeleteFileMutation, { data, loading, error }] = useBulkDeleteFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkDeleteFileMutation(baseOptions?: Apollo.MutationHookOptions<BulkDeleteFileMutation, BulkDeleteFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkDeleteFileMutation, BulkDeleteFileMutationVariables>(BulkDeleteFileDocument, options);
      }
export type BulkDeleteFileMutationHookResult = ReturnType<typeof useBulkDeleteFileMutation>;
export type BulkDeleteFileMutationResult = Apollo.MutationResult<BulkDeleteFileMutation>;
export type BulkDeleteFileMutationOptions = Apollo.BaseMutationOptions<BulkDeleteFileMutation, BulkDeleteFileMutationVariables>;
export const EditFilesInWorkspaceDocument = gql`
    mutation EditFilesInWorkspace($input: EditFilesInWorkspaceInput!) {
  editFilesInWorkspace(input: $input) {
    id
    description
    name
    tags
    defaultWorkspaceBoard {
      id
      name
      tags
      thumbnail
    }
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
    `;
export type EditFilesInWorkspaceMutationFn = Apollo.MutationFunction<EditFilesInWorkspaceMutation, EditFilesInWorkspaceMutationVariables>;

/**
 * __useEditFilesInWorkspaceMutation__
 *
 * To run a mutation, you first call `useEditFilesInWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditFilesInWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editFilesInWorkspaceMutation, { data, loading, error }] = useEditFilesInWorkspaceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditFilesInWorkspaceMutation(baseOptions?: Apollo.MutationHookOptions<EditFilesInWorkspaceMutation, EditFilesInWorkspaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditFilesInWorkspaceMutation, EditFilesInWorkspaceMutationVariables>(EditFilesInWorkspaceDocument, options);
      }
export type EditFilesInWorkspaceMutationHookResult = ReturnType<typeof useEditFilesInWorkspaceMutation>;
export type EditFilesInWorkspaceMutationResult = Apollo.MutationResult<EditFilesInWorkspaceMutation>;
export type EditFilesInWorkspaceMutationOptions = Apollo.BaseMutationOptions<EditFilesInWorkspaceMutation, EditFilesInWorkspaceMutationVariables>;
export const ReindexFilesDocument = gql`
    mutation ReindexFiles($input: ReindexFilesInput!) {
  reindexFiles(input: $input) {
    fileIds
  }
}
    `;
export type ReindexFilesMutationFn = Apollo.MutationFunction<ReindexFilesMutation, ReindexFilesMutationVariables>;

/**
 * __useReindexFilesMutation__
 *
 * To run a mutation, you first call `useReindexFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReindexFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reindexFilesMutation, { data, loading, error }] = useReindexFilesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReindexFilesMutation(baseOptions?: Apollo.MutationHookOptions<ReindexFilesMutation, ReindexFilesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReindexFilesMutation, ReindexFilesMutationVariables>(ReindexFilesDocument, options);
      }
export type ReindexFilesMutationHookResult = ReturnType<typeof useReindexFilesMutation>;
export type ReindexFilesMutationResult = Apollo.MutationResult<ReindexFilesMutation>;
export type ReindexFilesMutationOptions = Apollo.BaseMutationOptions<ReindexFilesMutation, ReindexFilesMutationVariables>;
export const FileDocument = gql`
    query File($id: ID!) {
  file(id: $id) {
    id
    name
    createdAt
  }
}
    `;

/**
 * __useFileQuery__
 *
 * To run a query within a React component, call `useFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFileQuery(baseOptions: Apollo.QueryHookOptions<FileQuery, FileQueryVariables> & ({ variables: FileQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FileQuery, FileQueryVariables>(FileDocument, options);
      }
export function useFileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FileQuery, FileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FileQuery, FileQueryVariables>(FileDocument, options);
        }
export function useFileSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FileQuery, FileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FileQuery, FileQueryVariables>(FileDocument, options);
        }
export type FileQueryHookResult = ReturnType<typeof useFileQuery>;
export type FileLazyQueryHookResult = ReturnType<typeof useFileLazyQuery>;
export type FileSuspenseQueryHookResult = ReturnType<typeof useFileSuspenseQuery>;
export type FileQueryResult = Apollo.QueryResult<FileQuery, FileQueryVariables>;
export const FilesDocument = gql`
    query Files($offset: Int, $limit: Int, $orderBy: FileOrderBy, $filters: FileFilters) {
  files(offset: $offset, limit: $limit, orderBy: $orderBy, filters: $filters) {
    id
    name
    url
    filename
    mimeType
    fileAccessControlList {
      userGroup {
        name
      }
      user {
        id
        name
      }
    }
    bundleFile {
      bundle {
        name
      }
    }
    createdAt
  }
  fileAggregate(filters: $filters) {
    count
  }
}
    `;

/**
 * __useFilesQuery__
 *
 * To run a query within a React component, call `useFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilesQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      orderBy: // value for 'orderBy'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useFilesQuery(baseOptions?: Apollo.QueryHookOptions<FilesQuery, FilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FilesQuery, FilesQueryVariables>(FilesDocument, options);
      }
export function useFilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FilesQuery, FilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FilesQuery, FilesQueryVariables>(FilesDocument, options);
        }
export function useFilesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FilesQuery, FilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FilesQuery, FilesQueryVariables>(FilesDocument, options);
        }
export type FilesQueryHookResult = ReturnType<typeof useFilesQuery>;
export type FilesLazyQueryHookResult = ReturnType<typeof useFilesLazyQuery>;
export type FilesSuspenseQueryHookResult = ReturnType<typeof useFilesSuspenseQuery>;
export type FilesQueryResult = Apollo.QueryResult<FilesQuery, FilesQueryVariables>;
export const FilesByIdsDocument = gql`
    query FilesByIds($ids: [ID!]!) {
  filesByIds(ids: $ids) {
    id
    name
    bundleFile {
      bundleId
    }
  }
}
    `;

/**
 * __useFilesByIdsQuery__
 *
 * To run a query within a React component, call `useFilesByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilesByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilesByIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useFilesByIdsQuery(baseOptions: Apollo.QueryHookOptions<FilesByIdsQuery, FilesByIdsQueryVariables> & ({ variables: FilesByIdsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FilesByIdsQuery, FilesByIdsQueryVariables>(FilesByIdsDocument, options);
      }
export function useFilesByIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FilesByIdsQuery, FilesByIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FilesByIdsQuery, FilesByIdsQueryVariables>(FilesByIdsDocument, options);
        }
export function useFilesByIdsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FilesByIdsQuery, FilesByIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FilesByIdsQuery, FilesByIdsQueryVariables>(FilesByIdsDocument, options);
        }
export type FilesByIdsQueryHookResult = ReturnType<typeof useFilesByIdsQuery>;
export type FilesByIdsLazyQueryHookResult = ReturnType<typeof useFilesByIdsLazyQuery>;
export type FilesByIdsSuspenseQueryHookResult = ReturnType<typeof useFilesByIdsSuspenseQuery>;
export type FilesByIdsQueryResult = Apollo.QueryResult<FilesByIdsQuery, FilesByIdsQueryVariables>;
export const CreateFileAccessControlListDocument = gql`
    mutation CreateFileAccessControlList($input: CreateFileAccessControlListInput!) {
  createFileAccessControlList(input: $input) {
    id
    fileId
    role
    userGroup {
      id
    }
    userId
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
    `;
export type CreateFileAccessControlListMutationFn = Apollo.MutationFunction<CreateFileAccessControlListMutation, CreateFileAccessControlListMutationVariables>;

/**
 * __useCreateFileAccessControlListMutation__
 *
 * To run a mutation, you first call `useCreateFileAccessControlListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFileAccessControlListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFileAccessControlListMutation, { data, loading, error }] = useCreateFileAccessControlListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFileAccessControlListMutation(baseOptions?: Apollo.MutationHookOptions<CreateFileAccessControlListMutation, CreateFileAccessControlListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFileAccessControlListMutation, CreateFileAccessControlListMutationVariables>(CreateFileAccessControlListDocument, options);
      }
export type CreateFileAccessControlListMutationHookResult = ReturnType<typeof useCreateFileAccessControlListMutation>;
export type CreateFileAccessControlListMutationResult = Apollo.MutationResult<CreateFileAccessControlListMutation>;
export type CreateFileAccessControlListMutationOptions = Apollo.BaseMutationOptions<CreateFileAccessControlListMutation, CreateFileAccessControlListMutationVariables>;
export const UpdateFileAccessControlListDocument = gql`
    mutation UpdateFileAccessControlList($input: UpdateFileAccessControlListInput!) {
  updateFileAccessControlList(input: $input) {
    id
    fileId
    role
    userGroup {
      id
    }
    userId
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
    `;
export type UpdateFileAccessControlListMutationFn = Apollo.MutationFunction<UpdateFileAccessControlListMutation, UpdateFileAccessControlListMutationVariables>;

/**
 * __useUpdateFileAccessControlListMutation__
 *
 * To run a mutation, you first call `useUpdateFileAccessControlListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFileAccessControlListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFileAccessControlListMutation, { data, loading, error }] = useUpdateFileAccessControlListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFileAccessControlListMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFileAccessControlListMutation, UpdateFileAccessControlListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFileAccessControlListMutation, UpdateFileAccessControlListMutationVariables>(UpdateFileAccessControlListDocument, options);
      }
export type UpdateFileAccessControlListMutationHookResult = ReturnType<typeof useUpdateFileAccessControlListMutation>;
export type UpdateFileAccessControlListMutationResult = Apollo.MutationResult<UpdateFileAccessControlListMutation>;
export type UpdateFileAccessControlListMutationOptions = Apollo.BaseMutationOptions<UpdateFileAccessControlListMutation, UpdateFileAccessControlListMutationVariables>;
export const DeleteFileAccessControlListDocument = gql`
    mutation DeleteFileAccessControlList($input: DeleteFileAccessControlListInput!) {
  deleteFileAccessControlList(input: $input) {
    id
  }
}
    `;
export type DeleteFileAccessControlListMutationFn = Apollo.MutationFunction<DeleteFileAccessControlListMutation, DeleteFileAccessControlListMutationVariables>;

/**
 * __useDeleteFileAccessControlListMutation__
 *
 * To run a mutation, you first call `useDeleteFileAccessControlListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFileAccessControlListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFileAccessControlListMutation, { data, loading, error }] = useDeleteFileAccessControlListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteFileAccessControlListMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFileAccessControlListMutation, DeleteFileAccessControlListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFileAccessControlListMutation, DeleteFileAccessControlListMutationVariables>(DeleteFileAccessControlListDocument, options);
      }
export type DeleteFileAccessControlListMutationHookResult = ReturnType<typeof useDeleteFileAccessControlListMutation>;
export type DeleteFileAccessControlListMutationResult = Apollo.MutationResult<DeleteFileAccessControlListMutation>;
export type DeleteFileAccessControlListMutationOptions = Apollo.BaseMutationOptions<DeleteFileAccessControlListMutation, DeleteFileAccessControlListMutationVariables>;
export const FileAccessControlListHasRoleDocument = gql`
    query FileAccessControlListHasRole($fileIds: [ID!]!, $roles: [ObjectRole!]!) {
  hasRoleForFiles(fileIds: $fileIds, roles: $roles)
}
    `;

/**
 * __useFileAccessControlListHasRoleQuery__
 *
 * To run a query within a React component, call `useFileAccessControlListHasRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useFileAccessControlListHasRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFileAccessControlListHasRoleQuery({
 *   variables: {
 *      fileIds: // value for 'fileIds'
 *      roles: // value for 'roles'
 *   },
 * });
 */
export function useFileAccessControlListHasRoleQuery(baseOptions: Apollo.QueryHookOptions<FileAccessControlListHasRoleQuery, FileAccessControlListHasRoleQueryVariables> & ({ variables: FileAccessControlListHasRoleQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FileAccessControlListHasRoleQuery, FileAccessControlListHasRoleQueryVariables>(FileAccessControlListHasRoleDocument, options);
      }
export function useFileAccessControlListHasRoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FileAccessControlListHasRoleQuery, FileAccessControlListHasRoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FileAccessControlListHasRoleQuery, FileAccessControlListHasRoleQueryVariables>(FileAccessControlListHasRoleDocument, options);
        }
export function useFileAccessControlListHasRoleSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FileAccessControlListHasRoleQuery, FileAccessControlListHasRoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FileAccessControlListHasRoleQuery, FileAccessControlListHasRoleQueryVariables>(FileAccessControlListHasRoleDocument, options);
        }
export type FileAccessControlListHasRoleQueryHookResult = ReturnType<typeof useFileAccessControlListHasRoleQuery>;
export type FileAccessControlListHasRoleLazyQueryHookResult = ReturnType<typeof useFileAccessControlListHasRoleLazyQuery>;
export type FileAccessControlListHasRoleSuspenseQueryHookResult = ReturnType<typeof useFileAccessControlListHasRoleSuspenseQuery>;
export type FileAccessControlListHasRoleQueryResult = Apollo.QueryResult<FileAccessControlListHasRoleQuery, FileAccessControlListHasRoleQueryVariables>;
export const FileAccessControlListDocument = gql`
    query FileAccessControlList($id: ID!) {
  fileAccessControlList(id: $id) {
    id
    fileId
    role
    userGroup {
      id
      name
      isSystem
    }
    user {
      id
      name
      profilePictureUrl
    }
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
    `;

/**
 * __useFileAccessControlListQuery__
 *
 * To run a query within a React component, call `useFileAccessControlListQuery` and pass it any options that fit your needs.
 * When your component renders, `useFileAccessControlListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFileAccessControlListQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFileAccessControlListQuery(baseOptions: Apollo.QueryHookOptions<FileAccessControlListQuery, FileAccessControlListQueryVariables> & ({ variables: FileAccessControlListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FileAccessControlListQuery, FileAccessControlListQueryVariables>(FileAccessControlListDocument, options);
      }
export function useFileAccessControlListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FileAccessControlListQuery, FileAccessControlListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FileAccessControlListQuery, FileAccessControlListQueryVariables>(FileAccessControlListDocument, options);
        }
export function useFileAccessControlListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FileAccessControlListQuery, FileAccessControlListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FileAccessControlListQuery, FileAccessControlListQueryVariables>(FileAccessControlListDocument, options);
        }
export type FileAccessControlListQueryHookResult = ReturnType<typeof useFileAccessControlListQuery>;
export type FileAccessControlListLazyQueryHookResult = ReturnType<typeof useFileAccessControlListLazyQuery>;
export type FileAccessControlListSuspenseQueryHookResult = ReturnType<typeof useFileAccessControlListSuspenseQuery>;
export type FileAccessControlListQueryResult = Apollo.QueryResult<FileAccessControlListQuery, FileAccessControlListQueryVariables>;
export const FileAccessControlListByObjectDocument = gql`
    query FileAccessControlListByObject($fileId: ID!) {
  fileAccessControlListByObject(fileId: $fileId) {
    id
    fileId
    role
    userGroup {
      id
      name
      isSystem
    }
    user {
      id
      name
      profilePictureUrl
    }
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
  fileAccessControlListByObjectAggregate(fileId: $fileId) {
    count
  }
}
    `;

/**
 * __useFileAccessControlListByObjectQuery__
 *
 * To run a query within a React component, call `useFileAccessControlListByObjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useFileAccessControlListByObjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFileAccessControlListByObjectQuery({
 *   variables: {
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useFileAccessControlListByObjectQuery(baseOptions: Apollo.QueryHookOptions<FileAccessControlListByObjectQuery, FileAccessControlListByObjectQueryVariables> & ({ variables: FileAccessControlListByObjectQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FileAccessControlListByObjectQuery, FileAccessControlListByObjectQueryVariables>(FileAccessControlListByObjectDocument, options);
      }
export function useFileAccessControlListByObjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FileAccessControlListByObjectQuery, FileAccessControlListByObjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FileAccessControlListByObjectQuery, FileAccessControlListByObjectQueryVariables>(FileAccessControlListByObjectDocument, options);
        }
export function useFileAccessControlListByObjectSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FileAccessControlListByObjectQuery, FileAccessControlListByObjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FileAccessControlListByObjectQuery, FileAccessControlListByObjectQueryVariables>(FileAccessControlListByObjectDocument, options);
        }
export type FileAccessControlListByObjectQueryHookResult = ReturnType<typeof useFileAccessControlListByObjectQuery>;
export type FileAccessControlListByObjectLazyQueryHookResult = ReturnType<typeof useFileAccessControlListByObjectLazyQuery>;
export type FileAccessControlListByObjectSuspenseQueryHookResult = ReturnType<typeof useFileAccessControlListByObjectSuspenseQuery>;
export type FileAccessControlListByObjectQueryResult = Apollo.QueryResult<FileAccessControlListByObjectQuery, FileAccessControlListByObjectQueryVariables>;
export const FileAccessControlListUserRolesDocument = gql`
    query FileAccessControlListUserRoles($userId: ID!, $fileId: ID!) {
  fileAccessControlListUserRoles(userId: $userId, fileId: $fileId) {
    userId
    objectId
    roles
  }
}
    `;

/**
 * __useFileAccessControlListUserRolesQuery__
 *
 * To run a query within a React component, call `useFileAccessControlListUserRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFileAccessControlListUserRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFileAccessControlListUserRolesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useFileAccessControlListUserRolesQuery(baseOptions: Apollo.QueryHookOptions<FileAccessControlListUserRolesQuery, FileAccessControlListUserRolesQueryVariables> & ({ variables: FileAccessControlListUserRolesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FileAccessControlListUserRolesQuery, FileAccessControlListUserRolesQueryVariables>(FileAccessControlListUserRolesDocument, options);
      }
export function useFileAccessControlListUserRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FileAccessControlListUserRolesQuery, FileAccessControlListUserRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FileAccessControlListUserRolesQuery, FileAccessControlListUserRolesQueryVariables>(FileAccessControlListUserRolesDocument, options);
        }
export function useFileAccessControlListUserRolesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FileAccessControlListUserRolesQuery, FileAccessControlListUserRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FileAccessControlListUserRolesQuery, FileAccessControlListUserRolesQueryVariables>(FileAccessControlListUserRolesDocument, options);
        }
export type FileAccessControlListUserRolesQueryHookResult = ReturnType<typeof useFileAccessControlListUserRolesQuery>;
export type FileAccessControlListUserRolesLazyQueryHookResult = ReturnType<typeof useFileAccessControlListUserRolesLazyQuery>;
export type FileAccessControlListUserRolesSuspenseQueryHookResult = ReturnType<typeof useFileAccessControlListUserRolesSuspenseQuery>;
export type FileAccessControlListUserRolesQueryResult = Apollo.QueryResult<FileAccessControlListUserRolesQuery, FileAccessControlListUserRolesQueryVariables>;
export const FileBundleViewDocument = gql`
    query FileBundleView($offset: Int, $limit: Int, $orderBy: FileBundleViewOrderBy, $filters: FileBundleViewFilters) {
  fileBundleView(
    offset: $offset
    limit: $limit
    orderBy: $orderBy
    filters: $filters
  ) {
    createdAt
    id
    mimeType
    name
    type
  }
  fileBundleViewAggregate(filters: $filters) {
    count
  }
}
    `;

/**
 * __useFileBundleViewQuery__
 *
 * To run a query within a React component, call `useFileBundleViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useFileBundleViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFileBundleViewQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      orderBy: // value for 'orderBy'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useFileBundleViewQuery(baseOptions?: Apollo.QueryHookOptions<FileBundleViewQuery, FileBundleViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FileBundleViewQuery, FileBundleViewQueryVariables>(FileBundleViewDocument, options);
      }
export function useFileBundleViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FileBundleViewQuery, FileBundleViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FileBundleViewQuery, FileBundleViewQueryVariables>(FileBundleViewDocument, options);
        }
export function useFileBundleViewSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FileBundleViewQuery, FileBundleViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FileBundleViewQuery, FileBundleViewQueryVariables>(FileBundleViewDocument, options);
        }
export type FileBundleViewQueryHookResult = ReturnType<typeof useFileBundleViewQuery>;
export type FileBundleViewLazyQueryHookResult = ReturnType<typeof useFileBundleViewLazyQuery>;
export type FileBundleViewSuspenseQueryHookResult = ReturnType<typeof useFileBundleViewSuspenseQuery>;
export type FileBundleViewQueryResult = Apollo.QueryResult<FileBundleViewQuery, FileBundleViewQueryVariables>;
export const LinkPropsDocument = gql`
    query LinkProps($url: String!) {
  linkProps(url: $url) {
    isEmbeddable
  }
}
    `;

/**
 * __useLinkPropsQuery__
 *
 * To run a query within a React component, call `useLinkPropsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinkPropsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinkPropsQuery({
 *   variables: {
 *      url: // value for 'url'
 *   },
 * });
 */
export function useLinkPropsQuery(baseOptions: Apollo.QueryHookOptions<LinkPropsQuery, LinkPropsQueryVariables> & ({ variables: LinkPropsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LinkPropsQuery, LinkPropsQueryVariables>(LinkPropsDocument, options);
      }
export function useLinkPropsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LinkPropsQuery, LinkPropsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LinkPropsQuery, LinkPropsQueryVariables>(LinkPropsDocument, options);
        }
export function useLinkPropsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LinkPropsQuery, LinkPropsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LinkPropsQuery, LinkPropsQueryVariables>(LinkPropsDocument, options);
        }
export type LinkPropsQueryHookResult = ReturnType<typeof useLinkPropsQuery>;
export type LinkPropsLazyQueryHookResult = ReturnType<typeof useLinkPropsLazyQuery>;
export type LinkPropsSuspenseQueryHookResult = ReturnType<typeof useLinkPropsSuspenseQuery>;
export type LinkPropsQueryResult = Apollo.QueryResult<LinkPropsQuery, LinkPropsQueryVariables>;
export const OrganizationDocument = gql`
    query Organization($id: ID!) {
  organization(id: $id) {
    id
    name
  }
}
    `;

/**
 * __useOrganizationQuery__
 *
 * To run a query within a React component, call `useOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganizationQuery(baseOptions: Apollo.QueryHookOptions<OrganizationQuery, OrganizationQueryVariables> & ({ variables: OrganizationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationQuery, OrganizationQueryVariables>(OrganizationDocument, options);
      }
export function useOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationQuery, OrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationQuery, OrganizationQueryVariables>(OrganizationDocument, options);
        }
export function useOrganizationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OrganizationQuery, OrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrganizationQuery, OrganizationQueryVariables>(OrganizationDocument, options);
        }
export type OrganizationQueryHookResult = ReturnType<typeof useOrganizationQuery>;
export type OrganizationLazyQueryHookResult = ReturnType<typeof useOrganizationLazyQuery>;
export type OrganizationSuspenseQueryHookResult = ReturnType<typeof useOrganizationSuspenseQuery>;
export type OrganizationQueryResult = Apollo.QueryResult<OrganizationQuery, OrganizationQueryVariables>;
export const OrganizationsDocument = gql`
    query Organizations($offset: Int, $limit: Int, $orderBy: OrganizationOrderBy) {
  organizations(offset: $offset, limit: $limit, orderBy: $orderBy) {
    id
    name
  }
}
    `;

/**
 * __useOrganizationsQuery__
 *
 * To run a query within a React component, call `useOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useOrganizationsQuery(baseOptions?: Apollo.QueryHookOptions<OrganizationsQuery, OrganizationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationsQuery, OrganizationsQueryVariables>(OrganizationsDocument, options);
      }
export function useOrganizationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationsQuery, OrganizationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationsQuery, OrganizationsQueryVariables>(OrganizationsDocument, options);
        }
export function useOrganizationsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OrganizationsQuery, OrganizationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrganizationsQuery, OrganizationsQueryVariables>(OrganizationsDocument, options);
        }
export type OrganizationsQueryHookResult = ReturnType<typeof useOrganizationsQuery>;
export type OrganizationsLazyQueryHookResult = ReturnType<typeof useOrganizationsLazyQuery>;
export type OrganizationsSuspenseQueryHookResult = ReturnType<typeof useOrganizationsSuspenseQuery>;
export type OrganizationsQueryResult = Apollo.QueryResult<OrganizationsQuery, OrganizationsQueryVariables>;
export const ProcessHtmlDocument = gql`
    mutation ProcessHtml($sourceDocument: String!, $preset: String, $analysisOverride: AnalysisResultOverrideType, $analysisOverrideConfig: String) {
  processHtml(
    sourceDocument: $sourceDocument
    preset: $preset
    analysisOverride: $analysisOverride
    analysisOverrideConfig: $analysisOverrideConfig
  ) {
    resultDocument
  }
}
    `;
export type ProcessHtmlMutationFn = Apollo.MutationFunction<ProcessHtmlMutation, ProcessHtmlMutationVariables>;

/**
 * __useProcessHtmlMutation__
 *
 * To run a mutation, you first call `useProcessHtmlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessHtmlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processHtmlMutation, { data, loading, error }] = useProcessHtmlMutation({
 *   variables: {
 *      sourceDocument: // value for 'sourceDocument'
 *      preset: // value for 'preset'
 *      analysisOverride: // value for 'analysisOverride'
 *      analysisOverrideConfig: // value for 'analysisOverrideConfig'
 *   },
 * });
 */
export function useProcessHtmlMutation(baseOptions?: Apollo.MutationHookOptions<ProcessHtmlMutation, ProcessHtmlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProcessHtmlMutation, ProcessHtmlMutationVariables>(ProcessHtmlDocument, options);
      }
export type ProcessHtmlMutationHookResult = ReturnType<typeof useProcessHtmlMutation>;
export type ProcessHtmlMutationResult = Apollo.MutationResult<ProcessHtmlMutation>;
export type ProcessHtmlMutationOptions = Apollo.BaseMutationOptions<ProcessHtmlMutation, ProcessHtmlMutationVariables>;
export const CreateShapeDataDocument = gql`
    mutation CreateShapeData($input: CreateShapeDataInput!) {
  createShapeData(input: $input) {
    id
    data
    rowState
    shapeId
    workspaceBoardId
  }
}
    `;
export type CreateShapeDataMutationFn = Apollo.MutationFunction<CreateShapeDataMutation, CreateShapeDataMutationVariables>;

/**
 * __useCreateShapeDataMutation__
 *
 * To run a mutation, you first call `useCreateShapeDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateShapeDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createShapeDataMutation, { data, loading, error }] = useCreateShapeDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateShapeDataMutation(baseOptions?: Apollo.MutationHookOptions<CreateShapeDataMutation, CreateShapeDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateShapeDataMutation, CreateShapeDataMutationVariables>(CreateShapeDataDocument, options);
      }
export type CreateShapeDataMutationHookResult = ReturnType<typeof useCreateShapeDataMutation>;
export type CreateShapeDataMutationResult = Apollo.MutationResult<CreateShapeDataMutation>;
export type CreateShapeDataMutationOptions = Apollo.BaseMutationOptions<CreateShapeDataMutation, CreateShapeDataMutationVariables>;
export const CopyShapeDataDocument = gql`
    mutation CopyShapeData($input: CopyShapeDataInput!) {
  copyShapeData(input: $input) {
    id
    data
    rowState
    shapeId
    workspaceBoardId
  }
}
    `;
export type CopyShapeDataMutationFn = Apollo.MutationFunction<CopyShapeDataMutation, CopyShapeDataMutationVariables>;

/**
 * __useCopyShapeDataMutation__
 *
 * To run a mutation, you first call `useCopyShapeDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyShapeDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyShapeDataMutation, { data, loading, error }] = useCopyShapeDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCopyShapeDataMutation(baseOptions?: Apollo.MutationHookOptions<CopyShapeDataMutation, CopyShapeDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CopyShapeDataMutation, CopyShapeDataMutationVariables>(CopyShapeDataDocument, options);
      }
export type CopyShapeDataMutationHookResult = ReturnType<typeof useCopyShapeDataMutation>;
export type CopyShapeDataMutationResult = Apollo.MutationResult<CopyShapeDataMutation>;
export type CopyShapeDataMutationOptions = Apollo.BaseMutationOptions<CopyShapeDataMutation, CopyShapeDataMutationVariables>;
export const UpdateShapeDataDocument = gql`
    mutation UpdateShapeData($input: UpdateShapeDataInput!) {
  updateShapeData(input: $input) {
    id
    data
    rowState
    shapeId
    workspaceBoardId
  }
}
    `;
export type UpdateShapeDataMutationFn = Apollo.MutationFunction<UpdateShapeDataMutation, UpdateShapeDataMutationVariables>;

/**
 * __useUpdateShapeDataMutation__
 *
 * To run a mutation, you first call `useUpdateShapeDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShapeDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShapeDataMutation, { data, loading, error }] = useUpdateShapeDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateShapeDataMutation(baseOptions?: Apollo.MutationHookOptions<UpdateShapeDataMutation, UpdateShapeDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateShapeDataMutation, UpdateShapeDataMutationVariables>(UpdateShapeDataDocument, options);
      }
export type UpdateShapeDataMutationHookResult = ReturnType<typeof useUpdateShapeDataMutation>;
export type UpdateShapeDataMutationResult = Apollo.MutationResult<UpdateShapeDataMutation>;
export type UpdateShapeDataMutationOptions = Apollo.BaseMutationOptions<UpdateShapeDataMutation, UpdateShapeDataMutationVariables>;
export const ShapeDataDocument = gql`
    query ShapeData($shapeId: String!, $workspaceBoardId: String!) {
  shapeData(shapeId: $shapeId, workspaceBoardId: $workspaceBoardId) {
    id
    data
    rowState
    shapeId
    workspaceBoardId
  }
}
    `;

/**
 * __useShapeDataQuery__
 *
 * To run a query within a React component, call `useShapeDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useShapeDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShapeDataQuery({
 *   variables: {
 *      shapeId: // value for 'shapeId'
 *      workspaceBoardId: // value for 'workspaceBoardId'
 *   },
 * });
 */
export function useShapeDataQuery(baseOptions: Apollo.QueryHookOptions<ShapeDataQuery, ShapeDataQueryVariables> & ({ variables: ShapeDataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShapeDataQuery, ShapeDataQueryVariables>(ShapeDataDocument, options);
      }
export function useShapeDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShapeDataQuery, ShapeDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShapeDataQuery, ShapeDataQueryVariables>(ShapeDataDocument, options);
        }
export function useShapeDataSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ShapeDataQuery, ShapeDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ShapeDataQuery, ShapeDataQueryVariables>(ShapeDataDocument, options);
        }
export type ShapeDataQueryHookResult = ReturnType<typeof useShapeDataQuery>;
export type ShapeDataLazyQueryHookResult = ReturnType<typeof useShapeDataLazyQuery>;
export type ShapeDataSuspenseQueryHookResult = ReturnType<typeof useShapeDataSuspenseQuery>;
export type ShapeDataQueryResult = Apollo.QueryResult<ShapeDataQuery, ShapeDataQueryVariables>;
export const ThemeDiscussionAnalysesDocument = gql`
    query ThemeDiscussionAnalyses($filters: ThemeDiscussionAnalysisFilters, $limit: Int, $offset: Int, $orderBy: [ThemeDiscussionAnalysisOrderBy]) {
  themeDiscussionAnalyses(
    offset: $offset
    limit: $limit
    orderBy: $orderBy
    filters: $filters
  ) {
    results {
      id
      discussionDepth
      discussionNature
      displaySymbol
      itemDate
      itemId
      itemTitle
      participants {
        description
        fullName
        occupationTitles
        role
      }
      section
      summaries
      symbols
      themeId
      themeName
      topics
      topicsAnalysisItems {
        topic
        discussionDepth
        discussionNature
        participants {
          description
          fullName
          occupationTitles
          role
        }
      }
    }
    status
    totalCount
  }
}
    `;

/**
 * __useThemeDiscussionAnalysesQuery__
 *
 * To run a query within a React component, call `useThemeDiscussionAnalysesQuery` and pass it any options that fit your needs.
 * When your component renders, `useThemeDiscussionAnalysesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThemeDiscussionAnalysesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useThemeDiscussionAnalysesQuery(baseOptions?: Apollo.QueryHookOptions<ThemeDiscussionAnalysesQuery, ThemeDiscussionAnalysesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ThemeDiscussionAnalysesQuery, ThemeDiscussionAnalysesQueryVariables>(ThemeDiscussionAnalysesDocument, options);
      }
export function useThemeDiscussionAnalysesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ThemeDiscussionAnalysesQuery, ThemeDiscussionAnalysesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ThemeDiscussionAnalysesQuery, ThemeDiscussionAnalysesQueryVariables>(ThemeDiscussionAnalysesDocument, options);
        }
export function useThemeDiscussionAnalysesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ThemeDiscussionAnalysesQuery, ThemeDiscussionAnalysesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ThemeDiscussionAnalysesQuery, ThemeDiscussionAnalysesQueryVariables>(ThemeDiscussionAnalysesDocument, options);
        }
export type ThemeDiscussionAnalysesQueryHookResult = ReturnType<typeof useThemeDiscussionAnalysesQuery>;
export type ThemeDiscussionAnalysesLazyQueryHookResult = ReturnType<typeof useThemeDiscussionAnalysesLazyQuery>;
export type ThemeDiscussionAnalysesSuspenseQueryHookResult = ReturnType<typeof useThemeDiscussionAnalysesSuspenseQuery>;
export type ThemeDiscussionAnalysesQueryResult = Apollo.QueryResult<ThemeDiscussionAnalysesQuery, ThemeDiscussionAnalysesQueryVariables>;
export const ThemeDiscussionAnalysisDocument = gql`
    query ThemeDiscussionAnalysis($id: ID!) {
  themeDiscussionAnalysis(id: $id) {
    id
    discussionDepth
    discussionNature
    displaySymbol
    itemDate
    itemId
    itemTitle
    participants {
      description
      fullName
      occupationTitles
      role
    }
    section
    summaries
    symbols
    themeId
    themeName
    topics
    topicsAnalysisItems {
      topic
      discussionDepth
      discussionNature
      participants {
        description
        fullName
        occupationTitles
        role
      }
    }
  }
}
    `;

/**
 * __useThemeDiscussionAnalysisQuery__
 *
 * To run a query within a React component, call `useThemeDiscussionAnalysisQuery` and pass it any options that fit your needs.
 * When your component renders, `useThemeDiscussionAnalysisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThemeDiscussionAnalysisQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useThemeDiscussionAnalysisQuery(baseOptions: Apollo.QueryHookOptions<ThemeDiscussionAnalysisQuery, ThemeDiscussionAnalysisQueryVariables> & ({ variables: ThemeDiscussionAnalysisQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ThemeDiscussionAnalysisQuery, ThemeDiscussionAnalysisQueryVariables>(ThemeDiscussionAnalysisDocument, options);
      }
export function useThemeDiscussionAnalysisLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ThemeDiscussionAnalysisQuery, ThemeDiscussionAnalysisQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ThemeDiscussionAnalysisQuery, ThemeDiscussionAnalysisQueryVariables>(ThemeDiscussionAnalysisDocument, options);
        }
export function useThemeDiscussionAnalysisSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ThemeDiscussionAnalysisQuery, ThemeDiscussionAnalysisQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ThemeDiscussionAnalysisQuery, ThemeDiscussionAnalysisQueryVariables>(ThemeDiscussionAnalysisDocument, options);
        }
export type ThemeDiscussionAnalysisQueryHookResult = ReturnType<typeof useThemeDiscussionAnalysisQuery>;
export type ThemeDiscussionAnalysisLazyQueryHookResult = ReturnType<typeof useThemeDiscussionAnalysisLazyQuery>;
export type ThemeDiscussionAnalysisSuspenseQueryHookResult = ReturnType<typeof useThemeDiscussionAnalysisSuspenseQuery>;
export type ThemeDiscussionAnalysisQueryResult = Apollo.QueryResult<ThemeDiscussionAnalysisQuery, ThemeDiscussionAnalysisQueryVariables>;
export const ImageTokenDocument = gql`
    query ImageToken {
  imageToken {
    token
  }
}
    `;

/**
 * __useImageTokenQuery__
 *
 * To run a query within a React component, call `useImageTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useImageTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImageTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useImageTokenQuery(baseOptions?: Apollo.QueryHookOptions<ImageTokenQuery, ImageTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ImageTokenQuery, ImageTokenQueryVariables>(ImageTokenDocument, options);
      }
export function useImageTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ImageTokenQuery, ImageTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ImageTokenQuery, ImageTokenQueryVariables>(ImageTokenDocument, options);
        }
export function useImageTokenSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ImageTokenQuery, ImageTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ImageTokenQuery, ImageTokenQueryVariables>(ImageTokenDocument, options);
        }
export type ImageTokenQueryHookResult = ReturnType<typeof useImageTokenQuery>;
export type ImageTokenLazyQueryHookResult = ReturnType<typeof useImageTokenLazyQuery>;
export type ImageTokenSuspenseQueryHookResult = ReturnType<typeof useImageTokenSuspenseQuery>;
export type ImageTokenQueryResult = Apollo.QueryResult<ImageTokenQuery, ImageTokenQueryVariables>;
export const UpdateTopicDiscussionExampleDocument = gql`
    mutation UpdateTopicDiscussionExample($input: UpdateTopicDiscussionExampleInput!) {
  updateTopicDiscussionExample(input: $input) {
    id
    topic
    question
    detail
    turn
    event
    eventType
    ticker
    category
    eventDate
    speakers {
      fullName
      role
    }
    segmentId
    section
    rating
    score
    explanation
    summary
    firm
    reportTitle
    type
  }
}
    `;
export type UpdateTopicDiscussionExampleMutationFn = Apollo.MutationFunction<UpdateTopicDiscussionExampleMutation, UpdateTopicDiscussionExampleMutationVariables>;

/**
 * __useUpdateTopicDiscussionExampleMutation__
 *
 * To run a mutation, you first call `useUpdateTopicDiscussionExampleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTopicDiscussionExampleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTopicDiscussionExampleMutation, { data, loading, error }] = useUpdateTopicDiscussionExampleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTopicDiscussionExampleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTopicDiscussionExampleMutation, UpdateTopicDiscussionExampleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTopicDiscussionExampleMutation, UpdateTopicDiscussionExampleMutationVariables>(UpdateTopicDiscussionExampleDocument, options);
      }
export type UpdateTopicDiscussionExampleMutationHookResult = ReturnType<typeof useUpdateTopicDiscussionExampleMutation>;
export type UpdateTopicDiscussionExampleMutationResult = Apollo.MutationResult<UpdateTopicDiscussionExampleMutation>;
export type UpdateTopicDiscussionExampleMutationOptions = Apollo.BaseMutationOptions<UpdateTopicDiscussionExampleMutation, UpdateTopicDiscussionExampleMutationVariables>;
export const TopicDiscussionExampleDocument = gql`
    query TopicDiscussionExample($id: ID!) {
  topicDiscussionExample(id: $id) {
    id
    topic
    question
    detail
  }
}
    `;

/**
 * __useTopicDiscussionExampleQuery__
 *
 * To run a query within a React component, call `useTopicDiscussionExampleQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopicDiscussionExampleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopicDiscussionExampleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTopicDiscussionExampleQuery(baseOptions: Apollo.QueryHookOptions<TopicDiscussionExampleQuery, TopicDiscussionExampleQueryVariables> & ({ variables: TopicDiscussionExampleQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TopicDiscussionExampleQuery, TopicDiscussionExampleQueryVariables>(TopicDiscussionExampleDocument, options);
      }
export function useTopicDiscussionExampleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TopicDiscussionExampleQuery, TopicDiscussionExampleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TopicDiscussionExampleQuery, TopicDiscussionExampleQueryVariables>(TopicDiscussionExampleDocument, options);
        }
export function useTopicDiscussionExampleSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TopicDiscussionExampleQuery, TopicDiscussionExampleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TopicDiscussionExampleQuery, TopicDiscussionExampleQueryVariables>(TopicDiscussionExampleDocument, options);
        }
export type TopicDiscussionExampleQueryHookResult = ReturnType<typeof useTopicDiscussionExampleQuery>;
export type TopicDiscussionExampleLazyQueryHookResult = ReturnType<typeof useTopicDiscussionExampleLazyQuery>;
export type TopicDiscussionExampleSuspenseQueryHookResult = ReturnType<typeof useTopicDiscussionExampleSuspenseQuery>;
export type TopicDiscussionExampleQueryResult = Apollo.QueryResult<TopicDiscussionExampleQuery, TopicDiscussionExampleQueryVariables>;
export const TopicDiscussionExamplesDocument = gql`
    query TopicDiscussionExamples($facets: TopicDiscussionExampleFacetsInput!) {
  topicDiscussionExamples(facets: $facets) {
    id
    topic
    question
    detail
    turn
    event
    eventType
    ticker
    category
    eventDate
    speakers {
      fullName
      role
    }
    segmentId
    section
    rating
    score
    explanation
    summary
    firm
    reportTitle
    type
  }
}
    `;

/**
 * __useTopicDiscussionExamplesQuery__
 *
 * To run a query within a React component, call `useTopicDiscussionExamplesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopicDiscussionExamplesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopicDiscussionExamplesQuery({
 *   variables: {
 *      facets: // value for 'facets'
 *   },
 * });
 */
export function useTopicDiscussionExamplesQuery(baseOptions: Apollo.QueryHookOptions<TopicDiscussionExamplesQuery, TopicDiscussionExamplesQueryVariables> & ({ variables: TopicDiscussionExamplesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TopicDiscussionExamplesQuery, TopicDiscussionExamplesQueryVariables>(TopicDiscussionExamplesDocument, options);
      }
export function useTopicDiscussionExamplesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TopicDiscussionExamplesQuery, TopicDiscussionExamplesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TopicDiscussionExamplesQuery, TopicDiscussionExamplesQueryVariables>(TopicDiscussionExamplesDocument, options);
        }
export function useTopicDiscussionExamplesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TopicDiscussionExamplesQuery, TopicDiscussionExamplesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TopicDiscussionExamplesQuery, TopicDiscussionExamplesQueryVariables>(TopicDiscussionExamplesDocument, options);
        }
export type TopicDiscussionExamplesQueryHookResult = ReturnType<typeof useTopicDiscussionExamplesQuery>;
export type TopicDiscussionExamplesLazyQueryHookResult = ReturnType<typeof useTopicDiscussionExamplesLazyQuery>;
export type TopicDiscussionExamplesSuspenseQueryHookResult = ReturnType<typeof useTopicDiscussionExamplesSuspenseQuery>;
export type TopicDiscussionExamplesQueryResult = Apollo.QueryResult<TopicDiscussionExamplesQuery, TopicDiscussionExamplesQueryVariables>;
export const TopicDiscussionExampleAggregateDocument = gql`
    query TopicDiscussionExampleAggregate($facets: TopicDiscussionExampleFacetsInput!) {
  bubbleChartScale: topicDiscussionExampleQuestionCountsAggregate(facets: $facets) {
    min
    max
  }
  barChartScale: topicDiscussionExampleEventAggregate(facets: $facets) {
    min
    max
  }
}
    `;

/**
 * __useTopicDiscussionExampleAggregateQuery__
 *
 * To run a query within a React component, call `useTopicDiscussionExampleAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopicDiscussionExampleAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopicDiscussionExampleAggregateQuery({
 *   variables: {
 *      facets: // value for 'facets'
 *   },
 * });
 */
export function useTopicDiscussionExampleAggregateQuery(baseOptions: Apollo.QueryHookOptions<TopicDiscussionExampleAggregateQuery, TopicDiscussionExampleAggregateQueryVariables> & ({ variables: TopicDiscussionExampleAggregateQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TopicDiscussionExampleAggregateQuery, TopicDiscussionExampleAggregateQueryVariables>(TopicDiscussionExampleAggregateDocument, options);
      }
export function useTopicDiscussionExampleAggregateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TopicDiscussionExampleAggregateQuery, TopicDiscussionExampleAggregateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TopicDiscussionExampleAggregateQuery, TopicDiscussionExampleAggregateQueryVariables>(TopicDiscussionExampleAggregateDocument, options);
        }
export function useTopicDiscussionExampleAggregateSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TopicDiscussionExampleAggregateQuery, TopicDiscussionExampleAggregateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TopicDiscussionExampleAggregateQuery, TopicDiscussionExampleAggregateQueryVariables>(TopicDiscussionExampleAggregateDocument, options);
        }
export type TopicDiscussionExampleAggregateQueryHookResult = ReturnType<typeof useTopicDiscussionExampleAggregateQuery>;
export type TopicDiscussionExampleAggregateLazyQueryHookResult = ReturnType<typeof useTopicDiscussionExampleAggregateLazyQuery>;
export type TopicDiscussionExampleAggregateSuspenseQueryHookResult = ReturnType<typeof useTopicDiscussionExampleAggregateSuspenseQuery>;
export type TopicDiscussionExampleAggregateQueryResult = Apollo.QueryResult<TopicDiscussionExampleAggregateQuery, TopicDiscussionExampleAggregateQueryVariables>;
export const UpdateTopicDiscussionInNewsArticleDocument = gql`
    mutation UpdateTopicDiscussionInNewsArticle($input: UpdateTopicDiscussionInNewsArticleInput!) {
  updateTopicDiscussionInNewsArticle(input: $input) {
    id
    theme
    topicDiscussions {
      topic
      summary
    }
    segmentId
    segmentType
    sourceType
    newSources {
      name
      url
    }
    date
    headline
    paragraph
    articleCount
  }
}
    `;
export type UpdateTopicDiscussionInNewsArticleMutationFn = Apollo.MutationFunction<UpdateTopicDiscussionInNewsArticleMutation, UpdateTopicDiscussionInNewsArticleMutationVariables>;

/**
 * __useUpdateTopicDiscussionInNewsArticleMutation__
 *
 * To run a mutation, you first call `useUpdateTopicDiscussionInNewsArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTopicDiscussionInNewsArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTopicDiscussionInNewsArticleMutation, { data, loading, error }] = useUpdateTopicDiscussionInNewsArticleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTopicDiscussionInNewsArticleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTopicDiscussionInNewsArticleMutation, UpdateTopicDiscussionInNewsArticleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTopicDiscussionInNewsArticleMutation, UpdateTopicDiscussionInNewsArticleMutationVariables>(UpdateTopicDiscussionInNewsArticleDocument, options);
      }
export type UpdateTopicDiscussionInNewsArticleMutationHookResult = ReturnType<typeof useUpdateTopicDiscussionInNewsArticleMutation>;
export type UpdateTopicDiscussionInNewsArticleMutationResult = Apollo.MutationResult<UpdateTopicDiscussionInNewsArticleMutation>;
export type UpdateTopicDiscussionInNewsArticleMutationOptions = Apollo.BaseMutationOptions<UpdateTopicDiscussionInNewsArticleMutation, UpdateTopicDiscussionInNewsArticleMutationVariables>;
export const TopicDiscussionInNewsArticleDocument = gql`
    query TopicDiscussionInNewsArticle($id: ID!) {
  topicDiscussionInNewsArticle(id: $id) {
    id
    theme
    topicDiscussions {
      topic
      summary
    }
    segmentId
    segmentType
    sourceType
    newSources {
      name
      url
    }
    date
    headline
    paragraph
  }
}
    `;

/**
 * __useTopicDiscussionInNewsArticleQuery__
 *
 * To run a query within a React component, call `useTopicDiscussionInNewsArticleQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopicDiscussionInNewsArticleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopicDiscussionInNewsArticleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTopicDiscussionInNewsArticleQuery(baseOptions: Apollo.QueryHookOptions<TopicDiscussionInNewsArticleQuery, TopicDiscussionInNewsArticleQueryVariables> & ({ variables: TopicDiscussionInNewsArticleQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TopicDiscussionInNewsArticleQuery, TopicDiscussionInNewsArticleQueryVariables>(TopicDiscussionInNewsArticleDocument, options);
      }
export function useTopicDiscussionInNewsArticleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TopicDiscussionInNewsArticleQuery, TopicDiscussionInNewsArticleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TopicDiscussionInNewsArticleQuery, TopicDiscussionInNewsArticleQueryVariables>(TopicDiscussionInNewsArticleDocument, options);
        }
export function useTopicDiscussionInNewsArticleSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TopicDiscussionInNewsArticleQuery, TopicDiscussionInNewsArticleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TopicDiscussionInNewsArticleQuery, TopicDiscussionInNewsArticleQueryVariables>(TopicDiscussionInNewsArticleDocument, options);
        }
export type TopicDiscussionInNewsArticleQueryHookResult = ReturnType<typeof useTopicDiscussionInNewsArticleQuery>;
export type TopicDiscussionInNewsArticleLazyQueryHookResult = ReturnType<typeof useTopicDiscussionInNewsArticleLazyQuery>;
export type TopicDiscussionInNewsArticleSuspenseQueryHookResult = ReturnType<typeof useTopicDiscussionInNewsArticleSuspenseQuery>;
export type TopicDiscussionInNewsArticleQueryResult = Apollo.QueryResult<TopicDiscussionInNewsArticleQuery, TopicDiscussionInNewsArticleQueryVariables>;
export const TopicDiscussionInNewsArticlesDocument = gql`
    query TopicDiscussionInNewsArticles($facets: TopicDiscussionInNewsArticleFacetsInput!) {
  topicDiscussionInNewsArticles(facets: $facets) {
    id
    theme
    topicDiscussions {
      topic
      summary
    }
    segmentId
    segmentType
    sourceType
    newSources {
      name
      url
    }
    date
    headline
    paragraph
    articleCount
  }
}
    `;

/**
 * __useTopicDiscussionInNewsArticlesQuery__
 *
 * To run a query within a React component, call `useTopicDiscussionInNewsArticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopicDiscussionInNewsArticlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopicDiscussionInNewsArticlesQuery({
 *   variables: {
 *      facets: // value for 'facets'
 *   },
 * });
 */
export function useTopicDiscussionInNewsArticlesQuery(baseOptions: Apollo.QueryHookOptions<TopicDiscussionInNewsArticlesQuery, TopicDiscussionInNewsArticlesQueryVariables> & ({ variables: TopicDiscussionInNewsArticlesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TopicDiscussionInNewsArticlesQuery, TopicDiscussionInNewsArticlesQueryVariables>(TopicDiscussionInNewsArticlesDocument, options);
      }
export function useTopicDiscussionInNewsArticlesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TopicDiscussionInNewsArticlesQuery, TopicDiscussionInNewsArticlesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TopicDiscussionInNewsArticlesQuery, TopicDiscussionInNewsArticlesQueryVariables>(TopicDiscussionInNewsArticlesDocument, options);
        }
export function useTopicDiscussionInNewsArticlesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TopicDiscussionInNewsArticlesQuery, TopicDiscussionInNewsArticlesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TopicDiscussionInNewsArticlesQuery, TopicDiscussionInNewsArticlesQueryVariables>(TopicDiscussionInNewsArticlesDocument, options);
        }
export type TopicDiscussionInNewsArticlesQueryHookResult = ReturnType<typeof useTopicDiscussionInNewsArticlesQuery>;
export type TopicDiscussionInNewsArticlesLazyQueryHookResult = ReturnType<typeof useTopicDiscussionInNewsArticlesLazyQuery>;
export type TopicDiscussionInNewsArticlesSuspenseQueryHookResult = ReturnType<typeof useTopicDiscussionInNewsArticlesSuspenseQuery>;
export type TopicDiscussionInNewsArticlesQueryResult = Apollo.QueryResult<TopicDiscussionInNewsArticlesQuery, TopicDiscussionInNewsArticlesQueryVariables>;
export const UpdateTopicDiscussionSummaryExampleDocument = gql`
    mutation UpdateTopicDiscussionSummaryExample($input: UpdateTopicDiscussionSummaryExampleInput!) {
  updateTopicDiscussionSummaryExample(input: $input) {
    id
    topic
    questions
    questionMentionCounts
    detail
    topicMentionCount
    event
    eventType
    ticker
    category
    eventDate
    speakers {
      fullName
      role
    }
    section
    rating
    score
    summary
    analysisName
  }
}
    `;
export type UpdateTopicDiscussionSummaryExampleMutationFn = Apollo.MutationFunction<UpdateTopicDiscussionSummaryExampleMutation, UpdateTopicDiscussionSummaryExampleMutationVariables>;

/**
 * __useUpdateTopicDiscussionSummaryExampleMutation__
 *
 * To run a mutation, you first call `useUpdateTopicDiscussionSummaryExampleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTopicDiscussionSummaryExampleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTopicDiscussionSummaryExampleMutation, { data, loading, error }] = useUpdateTopicDiscussionSummaryExampleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTopicDiscussionSummaryExampleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTopicDiscussionSummaryExampleMutation, UpdateTopicDiscussionSummaryExampleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTopicDiscussionSummaryExampleMutation, UpdateTopicDiscussionSummaryExampleMutationVariables>(UpdateTopicDiscussionSummaryExampleDocument, options);
      }
export type UpdateTopicDiscussionSummaryExampleMutationHookResult = ReturnType<typeof useUpdateTopicDiscussionSummaryExampleMutation>;
export type UpdateTopicDiscussionSummaryExampleMutationResult = Apollo.MutationResult<UpdateTopicDiscussionSummaryExampleMutation>;
export type UpdateTopicDiscussionSummaryExampleMutationOptions = Apollo.BaseMutationOptions<UpdateTopicDiscussionSummaryExampleMutation, UpdateTopicDiscussionSummaryExampleMutationVariables>;
export const TopicDiscussionSummaryExampleDocument = gql`
    query TopicDiscussionSummaryExample($id: ID!) {
  topicDiscussionSummaryExample(id: $id) {
    id
    topic
    questions
    event
    summary
    detail
  }
}
    `;

/**
 * __useTopicDiscussionSummaryExampleQuery__
 *
 * To run a query within a React component, call `useTopicDiscussionSummaryExampleQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopicDiscussionSummaryExampleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopicDiscussionSummaryExampleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTopicDiscussionSummaryExampleQuery(baseOptions: Apollo.QueryHookOptions<TopicDiscussionSummaryExampleQuery, TopicDiscussionSummaryExampleQueryVariables> & ({ variables: TopicDiscussionSummaryExampleQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TopicDiscussionSummaryExampleQuery, TopicDiscussionSummaryExampleQueryVariables>(TopicDiscussionSummaryExampleDocument, options);
      }
export function useTopicDiscussionSummaryExampleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TopicDiscussionSummaryExampleQuery, TopicDiscussionSummaryExampleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TopicDiscussionSummaryExampleQuery, TopicDiscussionSummaryExampleQueryVariables>(TopicDiscussionSummaryExampleDocument, options);
        }
export function useTopicDiscussionSummaryExampleSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TopicDiscussionSummaryExampleQuery, TopicDiscussionSummaryExampleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TopicDiscussionSummaryExampleQuery, TopicDiscussionSummaryExampleQueryVariables>(TopicDiscussionSummaryExampleDocument, options);
        }
export type TopicDiscussionSummaryExampleQueryHookResult = ReturnType<typeof useTopicDiscussionSummaryExampleQuery>;
export type TopicDiscussionSummaryExampleLazyQueryHookResult = ReturnType<typeof useTopicDiscussionSummaryExampleLazyQuery>;
export type TopicDiscussionSummaryExampleSuspenseQueryHookResult = ReturnType<typeof useTopicDiscussionSummaryExampleSuspenseQuery>;
export type TopicDiscussionSummaryExampleQueryResult = Apollo.QueryResult<TopicDiscussionSummaryExampleQuery, TopicDiscussionSummaryExampleQueryVariables>;
export const TopicDiscussionSummaryExamplesDocument = gql`
    query TopicDiscussionSummaryExamples($facets: TopicDiscussionSummaryExampleFacetsInput!) {
  topicDiscussionSummaryExamples(facets: $facets) {
    id
    topic
    questions
    questionMentionCounts
    detail
    topicMentionCount
    event
    eventType
    ticker
    category
    eventDate
    speakers {
      fullName
      role
    }
    section
    rating
    score
    summary
    analysisName
  }
}
    `;

/**
 * __useTopicDiscussionSummaryExamplesQuery__
 *
 * To run a query within a React component, call `useTopicDiscussionSummaryExamplesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopicDiscussionSummaryExamplesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopicDiscussionSummaryExamplesQuery({
 *   variables: {
 *      facets: // value for 'facets'
 *   },
 * });
 */
export function useTopicDiscussionSummaryExamplesQuery(baseOptions: Apollo.QueryHookOptions<TopicDiscussionSummaryExamplesQuery, TopicDiscussionSummaryExamplesQueryVariables> & ({ variables: TopicDiscussionSummaryExamplesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TopicDiscussionSummaryExamplesQuery, TopicDiscussionSummaryExamplesQueryVariables>(TopicDiscussionSummaryExamplesDocument, options);
      }
export function useTopicDiscussionSummaryExamplesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TopicDiscussionSummaryExamplesQuery, TopicDiscussionSummaryExamplesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TopicDiscussionSummaryExamplesQuery, TopicDiscussionSummaryExamplesQueryVariables>(TopicDiscussionSummaryExamplesDocument, options);
        }
export function useTopicDiscussionSummaryExamplesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TopicDiscussionSummaryExamplesQuery, TopicDiscussionSummaryExamplesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TopicDiscussionSummaryExamplesQuery, TopicDiscussionSummaryExamplesQueryVariables>(TopicDiscussionSummaryExamplesDocument, options);
        }
export type TopicDiscussionSummaryExamplesQueryHookResult = ReturnType<typeof useTopicDiscussionSummaryExamplesQuery>;
export type TopicDiscussionSummaryExamplesLazyQueryHookResult = ReturnType<typeof useTopicDiscussionSummaryExamplesLazyQuery>;
export type TopicDiscussionSummaryExamplesSuspenseQueryHookResult = ReturnType<typeof useTopicDiscussionSummaryExamplesSuspenseQuery>;
export type TopicDiscussionSummaryExamplesQueryResult = Apollo.QueryResult<TopicDiscussionSummaryExamplesQuery, TopicDiscussionSummaryExamplesQueryVariables>;
export const TopicDiscussionSummaryExampleAggregateDocument = gql`
    query TopicDiscussionSummaryExampleAggregate($facets: TopicDiscussionSummaryExampleFacetsInput!) {
  bubbleChartScale: topicDiscussionSummaryExampleQuestionMentionCountsAggregate(
    facets: $facets
  ) {
    min
    max
  }
  barChartScale: topicDiscussionSummaryExampleEventAggregate(facets: $facets) {
    min
    max
  }
}
    `;

/**
 * __useTopicDiscussionSummaryExampleAggregateQuery__
 *
 * To run a query within a React component, call `useTopicDiscussionSummaryExampleAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopicDiscussionSummaryExampleAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopicDiscussionSummaryExampleAggregateQuery({
 *   variables: {
 *      facets: // value for 'facets'
 *   },
 * });
 */
export function useTopicDiscussionSummaryExampleAggregateQuery(baseOptions: Apollo.QueryHookOptions<TopicDiscussionSummaryExampleAggregateQuery, TopicDiscussionSummaryExampleAggregateQueryVariables> & ({ variables: TopicDiscussionSummaryExampleAggregateQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TopicDiscussionSummaryExampleAggregateQuery, TopicDiscussionSummaryExampleAggregateQueryVariables>(TopicDiscussionSummaryExampleAggregateDocument, options);
      }
export function useTopicDiscussionSummaryExampleAggregateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TopicDiscussionSummaryExampleAggregateQuery, TopicDiscussionSummaryExampleAggregateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TopicDiscussionSummaryExampleAggregateQuery, TopicDiscussionSummaryExampleAggregateQueryVariables>(TopicDiscussionSummaryExampleAggregateDocument, options);
        }
export function useTopicDiscussionSummaryExampleAggregateSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TopicDiscussionSummaryExampleAggregateQuery, TopicDiscussionSummaryExampleAggregateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TopicDiscussionSummaryExampleAggregateQuery, TopicDiscussionSummaryExampleAggregateQueryVariables>(TopicDiscussionSummaryExampleAggregateDocument, options);
        }
export type TopicDiscussionSummaryExampleAggregateQueryHookResult = ReturnType<typeof useTopicDiscussionSummaryExampleAggregateQuery>;
export type TopicDiscussionSummaryExampleAggregateLazyQueryHookResult = ReturnType<typeof useTopicDiscussionSummaryExampleAggregateLazyQuery>;
export type TopicDiscussionSummaryExampleAggregateSuspenseQueryHookResult = ReturnType<typeof useTopicDiscussionSummaryExampleAggregateSuspenseQuery>;
export type TopicDiscussionSummaryExampleAggregateQueryResult = Apollo.QueryResult<TopicDiscussionSummaryExampleAggregateQuery, TopicDiscussionSummaryExampleAggregateQueryVariables>;
export const TranscriptsDocument = gql`
    query Transcripts($filters: TranscriptFilters, $limit: Int, $offset: Int, $orderBy: [TranscriptOrderBy]) {
  transcripts(
    offset: $offset
    limit: $limit
    orderBy: $orderBy
    filters: $filters
  ) {
    results {
      id
      displaySymbol
      eventType
      itemDate
      title
    }
    status
    totalCount
  }
  transcriptAggregate {
    minItemDate
    maxItemDate
  }
}
    `;

/**
 * __useTranscriptsQuery__
 *
 * To run a query within a React component, call `useTranscriptsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTranscriptsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTranscriptsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useTranscriptsQuery(baseOptions?: Apollo.QueryHookOptions<TranscriptsQuery, TranscriptsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TranscriptsQuery, TranscriptsQueryVariables>(TranscriptsDocument, options);
      }
export function useTranscriptsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TranscriptsQuery, TranscriptsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TranscriptsQuery, TranscriptsQueryVariables>(TranscriptsDocument, options);
        }
export function useTranscriptsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TranscriptsQuery, TranscriptsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TranscriptsQuery, TranscriptsQueryVariables>(TranscriptsDocument, options);
        }
export type TranscriptsQueryHookResult = ReturnType<typeof useTranscriptsQuery>;
export type TranscriptsLazyQueryHookResult = ReturnType<typeof useTranscriptsLazyQuery>;
export type TranscriptsSuspenseQueryHookResult = ReturnType<typeof useTranscriptsSuspenseQuery>;
export type TranscriptsQueryResult = Apollo.QueryResult<TranscriptsQuery, TranscriptsQueryVariables>;
export const TranscriptDocument = gql`
    query Transcript($id: ID!) {
  transcript(id: $id) {
    id
    displaySymbol
    eventType
    itemDate
    title
    symbols
    transcriptItems {
      itemIndex
      fullName
      occupationTitles
      role
      speech
      session
    }
  }
}
    `;

/**
 * __useTranscriptQuery__
 *
 * To run a query within a React component, call `useTranscriptQuery` and pass it any options that fit your needs.
 * When your component renders, `useTranscriptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTranscriptQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTranscriptQuery(baseOptions: Apollo.QueryHookOptions<TranscriptQuery, TranscriptQueryVariables> & ({ variables: TranscriptQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TranscriptQuery, TranscriptQueryVariables>(TranscriptDocument, options);
      }
export function useTranscriptLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TranscriptQuery, TranscriptQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TranscriptQuery, TranscriptQueryVariables>(TranscriptDocument, options);
        }
export function useTranscriptSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TranscriptQuery, TranscriptQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TranscriptQuery, TranscriptQueryVariables>(TranscriptDocument, options);
        }
export type TranscriptQueryHookResult = ReturnType<typeof useTranscriptQuery>;
export type TranscriptLazyQueryHookResult = ReturnType<typeof useTranscriptLazyQuery>;
export type TranscriptSuspenseQueryHookResult = ReturnType<typeof useTranscriptSuspenseQuery>;
export type TranscriptQueryResult = Apollo.QueryResult<TranscriptQuery, TranscriptQueryVariables>;
export const UploadFileDocument = gql`
    mutation UploadFile($file: Upload!) {
  uploadFile(file: $file) {
    filename
    originalFilename
    apiDownloadUrl
    storageLocation
    mimetype
  }
}
    `;
export type UploadFileMutationFn = Apollo.MutationFunction<UploadFileMutation, UploadFileMutationVariables>;

/**
 * __useUploadFileMutation__
 *
 * To run a mutation, you first call `useUploadFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFileMutation, { data, loading, error }] = useUploadFileMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadFileMutation(baseOptions?: Apollo.MutationHookOptions<UploadFileMutation, UploadFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadFileMutation, UploadFileMutationVariables>(UploadFileDocument, options);
      }
export type UploadFileMutationHookResult = ReturnType<typeof useUploadFileMutation>;
export type UploadFileMutationResult = Apollo.MutationResult<UploadFileMutation>;
export type UploadFileMutationOptions = Apollo.BaseMutationOptions<UploadFileMutation, UploadFileMutationVariables>;
export const UploadFilesDocument = gql`
    mutation UploadFiles($input: UploadFilesInput!) {
  uploadFiles(input: $input) {
    succeeded {
      filename
      id
      originalFilename
      storageLocation
      mimetype
    }
    failed {
      filename
      id
      originalFilename
      storageLocation
      mimetype
      reason
    }
  }
}
    `;
export type UploadFilesMutationFn = Apollo.MutationFunction<UploadFilesMutation, UploadFilesMutationVariables>;

/**
 * __useUploadFilesMutation__
 *
 * To run a mutation, you first call `useUploadFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFilesMutation, { data, loading, error }] = useUploadFilesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadFilesMutation(baseOptions?: Apollo.MutationHookOptions<UploadFilesMutation, UploadFilesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadFilesMutation, UploadFilesMutationVariables>(UploadFilesDocument, options);
      }
export type UploadFilesMutationHookResult = ReturnType<typeof useUploadFilesMutation>;
export type UploadFilesMutationResult = Apollo.MutationResult<UploadFilesMutation>;
export type UploadFilesMutationOptions = Apollo.BaseMutationOptions<UploadFilesMutation, UploadFilesMutationVariables>;
export const UploadWorkspaceFilesDocument = gql`
    mutation UploadWorkspaceFiles($input: UploadWorkspaceFilesInput!) {
  uploadWorkspaceFiles(input: $input) {
    succeeded {
      filename
      id
      originalFilename
      storageLocation
      mimetype
    }
    failed {
      filename
      id
      originalFilename
      storageLocation
      mimetype
      reason
    }
  }
}
    `;
export type UploadWorkspaceFilesMutationFn = Apollo.MutationFunction<UploadWorkspaceFilesMutation, UploadWorkspaceFilesMutationVariables>;

/**
 * __useUploadWorkspaceFilesMutation__
 *
 * To run a mutation, you first call `useUploadWorkspaceFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadWorkspaceFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadWorkspaceFilesMutation, { data, loading, error }] = useUploadWorkspaceFilesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadWorkspaceFilesMutation(baseOptions?: Apollo.MutationHookOptions<UploadWorkspaceFilesMutation, UploadWorkspaceFilesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadWorkspaceFilesMutation, UploadWorkspaceFilesMutationVariables>(UploadWorkspaceFilesDocument, options);
      }
export type UploadWorkspaceFilesMutationHookResult = ReturnType<typeof useUploadWorkspaceFilesMutation>;
export type UploadWorkspaceFilesMutationResult = Apollo.MutationResult<UploadWorkspaceFilesMutation>;
export type UploadWorkspaceFilesMutationOptions = Apollo.BaseMutationOptions<UploadWorkspaceFilesMutation, UploadWorkspaceFilesMutationVariables>;
export const UploadDocumentDocument = gql`
    mutation UploadDocument($file: Upload!) {
  uploadDocument(file: $file) {
    filename
    originalFilename
    document {
      id
      name
      organizationId
    }
  }
}
    `;
export type UploadDocumentMutationFn = Apollo.MutationFunction<UploadDocumentMutation, UploadDocumentMutationVariables>;

/**
 * __useUploadDocumentMutation__
 *
 * To run a mutation, you first call `useUploadDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadDocumentMutation, { data, loading, error }] = useUploadDocumentMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadDocumentMutation(baseOptions?: Apollo.MutationHookOptions<UploadDocumentMutation, UploadDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadDocumentMutation, UploadDocumentMutationVariables>(UploadDocumentDocument, options);
      }
export type UploadDocumentMutationHookResult = ReturnType<typeof useUploadDocumentMutation>;
export type UploadDocumentMutationResult = Apollo.MutationResult<UploadDocumentMutation>;
export type UploadDocumentMutationOptions = Apollo.BaseMutationOptions<UploadDocumentMutation, UploadDocumentMutationVariables>;
export const UploadDocumentImageDocument = gql`
    mutation UploadDocumentImage($file: Upload!, $documentId: UUID!) {
  uploadDocumentImage(file: $file, documentId: $documentId) {
    assetDownloadUrl
  }
}
    `;
export type UploadDocumentImageMutationFn = Apollo.MutationFunction<UploadDocumentImageMutation, UploadDocumentImageMutationVariables>;

/**
 * __useUploadDocumentImageMutation__
 *
 * To run a mutation, you first call `useUploadDocumentImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadDocumentImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadDocumentImageMutation, { data, loading, error }] = useUploadDocumentImageMutation({
 *   variables: {
 *      file: // value for 'file'
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useUploadDocumentImageMutation(baseOptions?: Apollo.MutationHookOptions<UploadDocumentImageMutation, UploadDocumentImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadDocumentImageMutation, UploadDocumentImageMutationVariables>(UploadDocumentImageDocument, options);
      }
export type UploadDocumentImageMutationHookResult = ReturnType<typeof useUploadDocumentImageMutation>;
export type UploadDocumentImageMutationResult = Apollo.MutationResult<UploadDocumentImageMutation>;
export type UploadDocumentImageMutationOptions = Apollo.BaseMutationOptions<UploadDocumentImageMutation, UploadDocumentImageMutationVariables>;
export const UploadWorkspaceBoardAssetDocument = gql`
    mutation UploadWorkspaceBoardAsset($file: Upload!, $workspaceBoardId: UUID!) {
  uploadWorkspaceBoardAsset(file: $file, workspaceBoardId: $workspaceBoardId) {
    storageLocationUrl
  }
}
    `;
export type UploadWorkspaceBoardAssetMutationFn = Apollo.MutationFunction<UploadWorkspaceBoardAssetMutation, UploadWorkspaceBoardAssetMutationVariables>;

/**
 * __useUploadWorkspaceBoardAssetMutation__
 *
 * To run a mutation, you first call `useUploadWorkspaceBoardAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadWorkspaceBoardAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadWorkspaceBoardAssetMutation, { data, loading, error }] = useUploadWorkspaceBoardAssetMutation({
 *   variables: {
 *      file: // value for 'file'
 *      workspaceBoardId: // value for 'workspaceBoardId'
 *   },
 * });
 */
export function useUploadWorkspaceBoardAssetMutation(baseOptions?: Apollo.MutationHookOptions<UploadWorkspaceBoardAssetMutation, UploadWorkspaceBoardAssetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadWorkspaceBoardAssetMutation, UploadWorkspaceBoardAssetMutationVariables>(UploadWorkspaceBoardAssetDocument, options);
      }
export type UploadWorkspaceBoardAssetMutationHookResult = ReturnType<typeof useUploadWorkspaceBoardAssetMutation>;
export type UploadWorkspaceBoardAssetMutationResult = Apollo.MutationResult<UploadWorkspaceBoardAssetMutation>;
export type UploadWorkspaceBoardAssetMutationOptions = Apollo.BaseMutationOptions<UploadWorkspaceBoardAssetMutation, UploadWorkspaceBoardAssetMutationVariables>;
export const OnFileAddedDocument = gql`
    subscription OnFileAdded {
  onFileAdded {
    id
    uploadedSize
    totalSize
    fileName
    mimeType
    uploadStatus
    error
  }
}
    `;

/**
 * __useOnFileAddedSubscription__
 *
 * To run a query within a React component, call `useOnFileAddedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnFileAddedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnFileAddedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnFileAddedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnFileAddedSubscription, OnFileAddedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnFileAddedSubscription, OnFileAddedSubscriptionVariables>(OnFileAddedDocument, options);
      }
export type OnFileAddedSubscriptionHookResult = ReturnType<typeof useOnFileAddedSubscription>;
export type OnFileAddedSubscriptionResult = Apollo.SubscriptionResult<OnFileAddedSubscription>;
export const OnWorkspaceFileAddedDocument = gql`
    subscription OnWorkspaceFileAdded($workspaceId: ID!) {
  onWorkspaceFileAdded(workspaceId: $workspaceId) {
    id
    uploadedSize
    totalSize
    fileName
    mimeType
    workspaceId
    uploadStatus
    error
  }
}
    `;

/**
 * __useOnWorkspaceFileAddedSubscription__
 *
 * To run a query within a React component, call `useOnWorkspaceFileAddedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnWorkspaceFileAddedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnWorkspaceFileAddedSubscription({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useOnWorkspaceFileAddedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnWorkspaceFileAddedSubscription, OnWorkspaceFileAddedSubscriptionVariables> & ({ variables: OnWorkspaceFileAddedSubscriptionVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnWorkspaceFileAddedSubscription, OnWorkspaceFileAddedSubscriptionVariables>(OnWorkspaceFileAddedDocument, options);
      }
export type OnWorkspaceFileAddedSubscriptionHookResult = ReturnType<typeof useOnWorkspaceFileAddedSubscription>;
export type OnWorkspaceFileAddedSubscriptionResult = Apollo.SubscriptionResult<OnWorkspaceFileAddedSubscription>;
export const UserDocument = gql`
    query User($id: ID!) {
  user(id: $id) {
    id
    name
    email
    profilePictureUrl
  }
}
    `;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables> & ({ variables: UserQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export function useUserSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserSuspenseQueryHookResult = ReturnType<typeof useUserSuspenseQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const UsersDocument = gql`
    query Users($orderBy: UserOrderBy) {
  users(orderBy: $orderBy) {
    id
    name
    email
    profilePictureUrl
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export function useUsersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersSuspenseQueryHookResult = ReturnType<typeof useUsersSuspenseQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const CreateUserGroupDocument = gql`
    mutation CreateUserGroup($input: CreateUserGroupInput!) {
  createUserGroup(input: $input) {
    id
    name
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
    `;
export type CreateUserGroupMutationFn = Apollo.MutationFunction<CreateUserGroupMutation, CreateUserGroupMutationVariables>;

/**
 * __useCreateUserGroupMutation__
 *
 * To run a mutation, you first call `useCreateUserGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserGroupMutation, { data, loading, error }] = useCreateUserGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserGroupMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserGroupMutation, CreateUserGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserGroupMutation, CreateUserGroupMutationVariables>(CreateUserGroupDocument, options);
      }
export type CreateUserGroupMutationHookResult = ReturnType<typeof useCreateUserGroupMutation>;
export type CreateUserGroupMutationResult = Apollo.MutationResult<CreateUserGroupMutation>;
export type CreateUserGroupMutationOptions = Apollo.BaseMutationOptions<CreateUserGroupMutation, CreateUserGroupMutationVariables>;
export const UpdateUserGroupDocument = gql`
    mutation UpdateUserGroup($input: UpdateUserGroupInput!) {
  updateUserGroup(input: $input) {
    id
    name
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
    `;
export type UpdateUserGroupMutationFn = Apollo.MutationFunction<UpdateUserGroupMutation, UpdateUserGroupMutationVariables>;

/**
 * __useUpdateUserGroupMutation__
 *
 * To run a mutation, you first call `useUpdateUserGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserGroupMutation, { data, loading, error }] = useUpdateUserGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserGroupMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserGroupMutation, UpdateUserGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserGroupMutation, UpdateUserGroupMutationVariables>(UpdateUserGroupDocument, options);
      }
export type UpdateUserGroupMutationHookResult = ReturnType<typeof useUpdateUserGroupMutation>;
export type UpdateUserGroupMutationResult = Apollo.MutationResult<UpdateUserGroupMutation>;
export type UpdateUserGroupMutationOptions = Apollo.BaseMutationOptions<UpdateUserGroupMutation, UpdateUserGroupMutationVariables>;
export const DeleteUserGroupDocument = gql`
    mutation DeleteUserGroup($input: DeleteUserGroupInput!) {
  deleteUserGroup(input: $input) {
    id
  }
}
    `;
export type DeleteUserGroupMutationFn = Apollo.MutationFunction<DeleteUserGroupMutation, DeleteUserGroupMutationVariables>;

/**
 * __useDeleteUserGroupMutation__
 *
 * To run a mutation, you first call `useDeleteUserGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserGroupMutation, { data, loading, error }] = useDeleteUserGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteUserGroupMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserGroupMutation, DeleteUserGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserGroupMutation, DeleteUserGroupMutationVariables>(DeleteUserGroupDocument, options);
      }
export type DeleteUserGroupMutationHookResult = ReturnType<typeof useDeleteUserGroupMutation>;
export type DeleteUserGroupMutationResult = Apollo.MutationResult<DeleteUserGroupMutation>;
export type DeleteUserGroupMutationOptions = Apollo.BaseMutationOptions<DeleteUserGroupMutation, DeleteUserGroupMutationVariables>;
export const UserGroupDocument = gql`
    query UserGroup($id: ID!) {
  userGroup(id: $id) {
    id
    name
    isSystem
  }
}
    `;

/**
 * __useUserGroupQuery__
 *
 * To run a query within a React component, call `useUserGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserGroupQuery(baseOptions: Apollo.QueryHookOptions<UserGroupQuery, UserGroupQueryVariables> & ({ variables: UserGroupQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserGroupQuery, UserGroupQueryVariables>(UserGroupDocument, options);
      }
export function useUserGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserGroupQuery, UserGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserGroupQuery, UserGroupQueryVariables>(UserGroupDocument, options);
        }
export function useUserGroupSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserGroupQuery, UserGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserGroupQuery, UserGroupQueryVariables>(UserGroupDocument, options);
        }
export type UserGroupQueryHookResult = ReturnType<typeof useUserGroupQuery>;
export type UserGroupLazyQueryHookResult = ReturnType<typeof useUserGroupLazyQuery>;
export type UserGroupSuspenseQueryHookResult = ReturnType<typeof useUserGroupSuspenseQuery>;
export type UserGroupQueryResult = Apollo.QueryResult<UserGroupQuery, UserGroupQueryVariables>;
export const UserGroupsDocument = gql`
    query UserGroups($offset: Int, $limit: Int, $orderBy: UserGroupOrderBy) {
  userGroups(offset: $offset, limit: $limit, orderBy: $orderBy) {
    id
    name
    isSystem
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
  userGroupAggregate {
    count
  }
}
    `;

/**
 * __useUserGroupsQuery__
 *
 * To run a query within a React component, call `useUserGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserGroupsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useUserGroupsQuery(baseOptions?: Apollo.QueryHookOptions<UserGroupsQuery, UserGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserGroupsQuery, UserGroupsQueryVariables>(UserGroupsDocument, options);
      }
export function useUserGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserGroupsQuery, UserGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserGroupsQuery, UserGroupsQueryVariables>(UserGroupsDocument, options);
        }
export function useUserGroupsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserGroupsQuery, UserGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserGroupsQuery, UserGroupsQueryVariables>(UserGroupsDocument, options);
        }
export type UserGroupsQueryHookResult = ReturnType<typeof useUserGroupsQuery>;
export type UserGroupsLazyQueryHookResult = ReturnType<typeof useUserGroupsLazyQuery>;
export type UserGroupsSuspenseQueryHookResult = ReturnType<typeof useUserGroupsSuspenseQuery>;
export type UserGroupsQueryResult = Apollo.QueryResult<UserGroupsQuery, UserGroupsQueryVariables>;
export const CreateUserGroupMemberDocument = gql`
    mutation CreateUserGroupMember($input: CreateUserGroupMemberInput!) {
  createUserGroupMember(input: $input) {
    id
    userGroup {
      id
      name
    }
    user {
      id
      name
    }
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
    `;
export type CreateUserGroupMemberMutationFn = Apollo.MutationFunction<CreateUserGroupMemberMutation, CreateUserGroupMemberMutationVariables>;

/**
 * __useCreateUserGroupMemberMutation__
 *
 * To run a mutation, you first call `useCreateUserGroupMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserGroupMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserGroupMemberMutation, { data, loading, error }] = useCreateUserGroupMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserGroupMemberMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserGroupMemberMutation, CreateUserGroupMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserGroupMemberMutation, CreateUserGroupMemberMutationVariables>(CreateUserGroupMemberDocument, options);
      }
export type CreateUserGroupMemberMutationHookResult = ReturnType<typeof useCreateUserGroupMemberMutation>;
export type CreateUserGroupMemberMutationResult = Apollo.MutationResult<CreateUserGroupMemberMutation>;
export type CreateUserGroupMemberMutationOptions = Apollo.BaseMutationOptions<CreateUserGroupMemberMutation, CreateUserGroupMemberMutationVariables>;
export const UpdateUserGroupMemberDocument = gql`
    mutation UpdateUserGroupMember($input: UpdateUserGroupMemberInput!) {
  updateUserGroupMember(input: $input) {
    id
    userGroup {
      id
      name
    }
    user {
      id
      name
    }
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
    `;
export type UpdateUserGroupMemberMutationFn = Apollo.MutationFunction<UpdateUserGroupMemberMutation, UpdateUserGroupMemberMutationVariables>;

/**
 * __useUpdateUserGroupMemberMutation__
 *
 * To run a mutation, you first call `useUpdateUserGroupMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserGroupMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserGroupMemberMutation, { data, loading, error }] = useUpdateUserGroupMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserGroupMemberMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserGroupMemberMutation, UpdateUserGroupMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserGroupMemberMutation, UpdateUserGroupMemberMutationVariables>(UpdateUserGroupMemberDocument, options);
      }
export type UpdateUserGroupMemberMutationHookResult = ReturnType<typeof useUpdateUserGroupMemberMutation>;
export type UpdateUserGroupMemberMutationResult = Apollo.MutationResult<UpdateUserGroupMemberMutation>;
export type UpdateUserGroupMemberMutationOptions = Apollo.BaseMutationOptions<UpdateUserGroupMemberMutation, UpdateUserGroupMemberMutationVariables>;
export const DeleteUserGroupMemberDocument = gql`
    mutation DeleteUserGroupMember($input: DeleteUserGroupMemberInput!) {
  deleteUserGroupMember(input: $input) {
    id
  }
}
    `;
export type DeleteUserGroupMemberMutationFn = Apollo.MutationFunction<DeleteUserGroupMemberMutation, DeleteUserGroupMemberMutationVariables>;

/**
 * __useDeleteUserGroupMemberMutation__
 *
 * To run a mutation, you first call `useDeleteUserGroupMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserGroupMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserGroupMemberMutation, { data, loading, error }] = useDeleteUserGroupMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteUserGroupMemberMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserGroupMemberMutation, DeleteUserGroupMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserGroupMemberMutation, DeleteUserGroupMemberMutationVariables>(DeleteUserGroupMemberDocument, options);
      }
export type DeleteUserGroupMemberMutationHookResult = ReturnType<typeof useDeleteUserGroupMemberMutation>;
export type DeleteUserGroupMemberMutationResult = Apollo.MutationResult<DeleteUserGroupMemberMutation>;
export type DeleteUserGroupMemberMutationOptions = Apollo.BaseMutationOptions<DeleteUserGroupMemberMutation, DeleteUserGroupMemberMutationVariables>;
export const UserGroupMemberDocument = gql`
    query UserGroupMember($id: ID, $userGroupId: ID, $userId: ID) {
  userGroupMember(id: $id, userGroupId: $userGroupId, userId: $userId) {
    id
    userGroup {
      id
      name
    }
    user {
      id
      name
    }
  }
}
    `;

/**
 * __useUserGroupMemberQuery__
 *
 * To run a query within a React component, call `useUserGroupMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserGroupMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserGroupMemberQuery({
 *   variables: {
 *      id: // value for 'id'
 *      userGroupId: // value for 'userGroupId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserGroupMemberQuery(baseOptions?: Apollo.QueryHookOptions<UserGroupMemberQuery, UserGroupMemberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserGroupMemberQuery, UserGroupMemberQueryVariables>(UserGroupMemberDocument, options);
      }
export function useUserGroupMemberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserGroupMemberQuery, UserGroupMemberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserGroupMemberQuery, UserGroupMemberQueryVariables>(UserGroupMemberDocument, options);
        }
export function useUserGroupMemberSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserGroupMemberQuery, UserGroupMemberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserGroupMemberQuery, UserGroupMemberQueryVariables>(UserGroupMemberDocument, options);
        }
export type UserGroupMemberQueryHookResult = ReturnType<typeof useUserGroupMemberQuery>;
export type UserGroupMemberLazyQueryHookResult = ReturnType<typeof useUserGroupMemberLazyQuery>;
export type UserGroupMemberSuspenseQueryHookResult = ReturnType<typeof useUserGroupMemberSuspenseQuery>;
export type UserGroupMemberQueryResult = Apollo.QueryResult<UserGroupMemberQuery, UserGroupMemberQueryVariables>;
export const UserGroupMembersDocument = gql`
    query UserGroupMembers($userGroupId: ID, $userId: ID, $offset: Int, $limit: Int, $orderBy: UserGroupMemberOrderBy) {
  userGroupMembers(
    userGroupId: $userGroupId
    userId: $userId
    offset: $offset
    limit: $limit
    orderBy: $orderBy
  ) {
    id
    userGroup {
      id
      name
    }
    user {
      id
      name
    }
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
  userGroupMemberAggregate(userGroupId: $userGroupId, userId: $userId) {
    count
  }
}
    `;

/**
 * __useUserGroupMembersQuery__
 *
 * To run a query within a React component, call `useUserGroupMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserGroupMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserGroupMembersQuery({
 *   variables: {
 *      userGroupId: // value for 'userGroupId'
 *      userId: // value for 'userId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useUserGroupMembersQuery(baseOptions?: Apollo.QueryHookOptions<UserGroupMembersQuery, UserGroupMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserGroupMembersQuery, UserGroupMembersQueryVariables>(UserGroupMembersDocument, options);
      }
export function useUserGroupMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserGroupMembersQuery, UserGroupMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserGroupMembersQuery, UserGroupMembersQueryVariables>(UserGroupMembersDocument, options);
        }
export function useUserGroupMembersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserGroupMembersQuery, UserGroupMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserGroupMembersQuery, UserGroupMembersQueryVariables>(UserGroupMembersDocument, options);
        }
export type UserGroupMembersQueryHookResult = ReturnType<typeof useUserGroupMembersQuery>;
export type UserGroupMembersLazyQueryHookResult = ReturnType<typeof useUserGroupMembersLazyQuery>;
export type UserGroupMembersSuspenseQueryHookResult = ReturnType<typeof useUserGroupMembersSuspenseQuery>;
export type UserGroupMembersQueryResult = Apollo.QueryResult<UserGroupMembersQuery, UserGroupMembersQueryVariables>;
export const UpsertDocumentsPageUserPreferencesDocument = gql`
    mutation UpsertDocumentsPageUserPreferences($input: UpsertUserPreferenceInput!) {
  upsertUserPreference(input: $input) {
    key
    data
  }
}
    `;
export type UpsertDocumentsPageUserPreferencesMutationFn = Apollo.MutationFunction<UpsertDocumentsPageUserPreferencesMutation, UpsertDocumentsPageUserPreferencesMutationVariables>;

/**
 * __useUpsertDocumentsPageUserPreferencesMutation__
 *
 * To run a mutation, you first call `useUpsertDocumentsPageUserPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertDocumentsPageUserPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertDocumentsPageUserPreferencesMutation, { data, loading, error }] = useUpsertDocumentsPageUserPreferencesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertDocumentsPageUserPreferencesMutation(baseOptions?: Apollo.MutationHookOptions<UpsertDocumentsPageUserPreferencesMutation, UpsertDocumentsPageUserPreferencesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertDocumentsPageUserPreferencesMutation, UpsertDocumentsPageUserPreferencesMutationVariables>(UpsertDocumentsPageUserPreferencesDocument, options);
      }
export type UpsertDocumentsPageUserPreferencesMutationHookResult = ReturnType<typeof useUpsertDocumentsPageUserPreferencesMutation>;
export type UpsertDocumentsPageUserPreferencesMutationResult = Apollo.MutationResult<UpsertDocumentsPageUserPreferencesMutation>;
export type UpsertDocumentsPageUserPreferencesMutationOptions = Apollo.BaseMutationOptions<UpsertDocumentsPageUserPreferencesMutation, UpsertDocumentsPageUserPreferencesMutationVariables>;
export const UpsertLibraryPageUserPreferencesDocument = gql`
    mutation UpsertLibraryPageUserPreferences($input: UpsertUserPreferenceInput!) {
  upsertUserPreference(input: $input) {
    key
    data
  }
}
    `;
export type UpsertLibraryPageUserPreferencesMutationFn = Apollo.MutationFunction<UpsertLibraryPageUserPreferencesMutation, UpsertLibraryPageUserPreferencesMutationVariables>;

/**
 * __useUpsertLibraryPageUserPreferencesMutation__
 *
 * To run a mutation, you first call `useUpsertLibraryPageUserPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertLibraryPageUserPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertLibraryPageUserPreferencesMutation, { data, loading, error }] = useUpsertLibraryPageUserPreferencesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertLibraryPageUserPreferencesMutation(baseOptions?: Apollo.MutationHookOptions<UpsertLibraryPageUserPreferencesMutation, UpsertLibraryPageUserPreferencesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertLibraryPageUserPreferencesMutation, UpsertLibraryPageUserPreferencesMutationVariables>(UpsertLibraryPageUserPreferencesDocument, options);
      }
export type UpsertLibraryPageUserPreferencesMutationHookResult = ReturnType<typeof useUpsertLibraryPageUserPreferencesMutation>;
export type UpsertLibraryPageUserPreferencesMutationResult = Apollo.MutationResult<UpsertLibraryPageUserPreferencesMutation>;
export type UpsertLibraryPageUserPreferencesMutationOptions = Apollo.BaseMutationOptions<UpsertLibraryPageUserPreferencesMutation, UpsertLibraryPageUserPreferencesMutationVariables>;
export const UpsertWorkspaceUserPreferencesDocument = gql`
    mutation UpsertWorkspaceUserPreferences($input: UpsertUserPreferenceInput!) {
  upsertUserPreference(input: $input) {
    key
    data
  }
}
    `;
export type UpsertWorkspaceUserPreferencesMutationFn = Apollo.MutationFunction<UpsertWorkspaceUserPreferencesMutation, UpsertWorkspaceUserPreferencesMutationVariables>;

/**
 * __useUpsertWorkspaceUserPreferencesMutation__
 *
 * To run a mutation, you first call `useUpsertWorkspaceUserPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertWorkspaceUserPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertWorkspaceUserPreferencesMutation, { data, loading, error }] = useUpsertWorkspaceUserPreferencesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertWorkspaceUserPreferencesMutation(baseOptions?: Apollo.MutationHookOptions<UpsertWorkspaceUserPreferencesMutation, UpsertWorkspaceUserPreferencesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertWorkspaceUserPreferencesMutation, UpsertWorkspaceUserPreferencesMutationVariables>(UpsertWorkspaceUserPreferencesDocument, options);
      }
export type UpsertWorkspaceUserPreferencesMutationHookResult = ReturnType<typeof useUpsertWorkspaceUserPreferencesMutation>;
export type UpsertWorkspaceUserPreferencesMutationResult = Apollo.MutationResult<UpsertWorkspaceUserPreferencesMutation>;
export type UpsertWorkspaceUserPreferencesMutationOptions = Apollo.BaseMutationOptions<UpsertWorkspaceUserPreferencesMutation, UpsertWorkspaceUserPreferencesMutationVariables>;
export const UpsertWorkspacesPageUserPreferencesDocument = gql`
    mutation UpsertWorkspacesPageUserPreferences($input: UpsertUserPreferenceInput!) {
  upsertUserPreference(input: $input) {
    key
    data
  }
}
    `;
export type UpsertWorkspacesPageUserPreferencesMutationFn = Apollo.MutationFunction<UpsertWorkspacesPageUserPreferencesMutation, UpsertWorkspacesPageUserPreferencesMutationVariables>;

/**
 * __useUpsertWorkspacesPageUserPreferencesMutation__
 *
 * To run a mutation, you first call `useUpsertWorkspacesPageUserPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertWorkspacesPageUserPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertWorkspacesPageUserPreferencesMutation, { data, loading, error }] = useUpsertWorkspacesPageUserPreferencesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertWorkspacesPageUserPreferencesMutation(baseOptions?: Apollo.MutationHookOptions<UpsertWorkspacesPageUserPreferencesMutation, UpsertWorkspacesPageUserPreferencesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertWorkspacesPageUserPreferencesMutation, UpsertWorkspacesPageUserPreferencesMutationVariables>(UpsertWorkspacesPageUserPreferencesDocument, options);
      }
export type UpsertWorkspacesPageUserPreferencesMutationHookResult = ReturnType<typeof useUpsertWorkspacesPageUserPreferencesMutation>;
export type UpsertWorkspacesPageUserPreferencesMutationResult = Apollo.MutationResult<UpsertWorkspacesPageUserPreferencesMutation>;
export type UpsertWorkspacesPageUserPreferencesMutationOptions = Apollo.BaseMutationOptions<UpsertWorkspacesPageUserPreferencesMutation, UpsertWorkspacesPageUserPreferencesMutationVariables>;
export const UpsertWorkspacePageUserPreferencesDocument = gql`
    mutation UpsertWorkspacePageUserPreferences($input: UpsertUserPreferenceInput!) {
  upsertUserPreference(input: $input) {
    key
    data
  }
}
    `;
export type UpsertWorkspacePageUserPreferencesMutationFn = Apollo.MutationFunction<UpsertWorkspacePageUserPreferencesMutation, UpsertWorkspacePageUserPreferencesMutationVariables>;

/**
 * __useUpsertWorkspacePageUserPreferencesMutation__
 *
 * To run a mutation, you first call `useUpsertWorkspacePageUserPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertWorkspacePageUserPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertWorkspacePageUserPreferencesMutation, { data, loading, error }] = useUpsertWorkspacePageUserPreferencesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertWorkspacePageUserPreferencesMutation(baseOptions?: Apollo.MutationHookOptions<UpsertWorkspacePageUserPreferencesMutation, UpsertWorkspacePageUserPreferencesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertWorkspacePageUserPreferencesMutation, UpsertWorkspacePageUserPreferencesMutationVariables>(UpsertWorkspacePageUserPreferencesDocument, options);
      }
export type UpsertWorkspacePageUserPreferencesMutationHookResult = ReturnType<typeof useUpsertWorkspacePageUserPreferencesMutation>;
export type UpsertWorkspacePageUserPreferencesMutationResult = Apollo.MutationResult<UpsertWorkspacePageUserPreferencesMutation>;
export type UpsertWorkspacePageUserPreferencesMutationOptions = Apollo.BaseMutationOptions<UpsertWorkspacePageUserPreferencesMutation, UpsertWorkspacePageUserPreferencesMutationVariables>;
export const DocumentsPageUserPreferencesDocument = gql`
    query DocumentsPageUserPreferences($key: String!) {
  userPreference(key: $key) {
    key
    data
  }
}
    `;

/**
 * __useDocumentsPageUserPreferencesQuery__
 *
 * To run a query within a React component, call `useDocumentsPageUserPreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentsPageUserPreferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentsPageUserPreferencesQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useDocumentsPageUserPreferencesQuery(baseOptions: Apollo.QueryHookOptions<DocumentsPageUserPreferencesQuery, DocumentsPageUserPreferencesQueryVariables> & ({ variables: DocumentsPageUserPreferencesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocumentsPageUserPreferencesQuery, DocumentsPageUserPreferencesQueryVariables>(DocumentsPageUserPreferencesDocument, options);
      }
export function useDocumentsPageUserPreferencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentsPageUserPreferencesQuery, DocumentsPageUserPreferencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocumentsPageUserPreferencesQuery, DocumentsPageUserPreferencesQueryVariables>(DocumentsPageUserPreferencesDocument, options);
        }
export function useDocumentsPageUserPreferencesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DocumentsPageUserPreferencesQuery, DocumentsPageUserPreferencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DocumentsPageUserPreferencesQuery, DocumentsPageUserPreferencesQueryVariables>(DocumentsPageUserPreferencesDocument, options);
        }
export type DocumentsPageUserPreferencesQueryHookResult = ReturnType<typeof useDocumentsPageUserPreferencesQuery>;
export type DocumentsPageUserPreferencesLazyQueryHookResult = ReturnType<typeof useDocumentsPageUserPreferencesLazyQuery>;
export type DocumentsPageUserPreferencesSuspenseQueryHookResult = ReturnType<typeof useDocumentsPageUserPreferencesSuspenseQuery>;
export type DocumentsPageUserPreferencesQueryResult = Apollo.QueryResult<DocumentsPageUserPreferencesQuery, DocumentsPageUserPreferencesQueryVariables>;
export const LibraryPageUserPreferencesDocument = gql`
    query LibraryPageUserPreferences($key: String!) {
  userPreference(key: $key) {
    key
    data
  }
}
    `;

/**
 * __useLibraryPageUserPreferencesQuery__
 *
 * To run a query within a React component, call `useLibraryPageUserPreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLibraryPageUserPreferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLibraryPageUserPreferencesQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useLibraryPageUserPreferencesQuery(baseOptions: Apollo.QueryHookOptions<LibraryPageUserPreferencesQuery, LibraryPageUserPreferencesQueryVariables> & ({ variables: LibraryPageUserPreferencesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LibraryPageUserPreferencesQuery, LibraryPageUserPreferencesQueryVariables>(LibraryPageUserPreferencesDocument, options);
      }
export function useLibraryPageUserPreferencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LibraryPageUserPreferencesQuery, LibraryPageUserPreferencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LibraryPageUserPreferencesQuery, LibraryPageUserPreferencesQueryVariables>(LibraryPageUserPreferencesDocument, options);
        }
export function useLibraryPageUserPreferencesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LibraryPageUserPreferencesQuery, LibraryPageUserPreferencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LibraryPageUserPreferencesQuery, LibraryPageUserPreferencesQueryVariables>(LibraryPageUserPreferencesDocument, options);
        }
export type LibraryPageUserPreferencesQueryHookResult = ReturnType<typeof useLibraryPageUserPreferencesQuery>;
export type LibraryPageUserPreferencesLazyQueryHookResult = ReturnType<typeof useLibraryPageUserPreferencesLazyQuery>;
export type LibraryPageUserPreferencesSuspenseQueryHookResult = ReturnType<typeof useLibraryPageUserPreferencesSuspenseQuery>;
export type LibraryPageUserPreferencesQueryResult = Apollo.QueryResult<LibraryPageUserPreferencesQuery, LibraryPageUserPreferencesQueryVariables>;
export const WorkspaceBoardUserPreferencesDocument = gql`
    query WorkspaceBoardUserPreferences($key: String!) {
  userPreference(key: $key) {
    key
    data
  }
}
    `;

/**
 * __useWorkspaceBoardUserPreferencesQuery__
 *
 * To run a query within a React component, call `useWorkspaceBoardUserPreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceBoardUserPreferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceBoardUserPreferencesQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useWorkspaceBoardUserPreferencesQuery(baseOptions: Apollo.QueryHookOptions<WorkspaceBoardUserPreferencesQuery, WorkspaceBoardUserPreferencesQueryVariables> & ({ variables: WorkspaceBoardUserPreferencesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkspaceBoardUserPreferencesQuery, WorkspaceBoardUserPreferencesQueryVariables>(WorkspaceBoardUserPreferencesDocument, options);
      }
export function useWorkspaceBoardUserPreferencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkspaceBoardUserPreferencesQuery, WorkspaceBoardUserPreferencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkspaceBoardUserPreferencesQuery, WorkspaceBoardUserPreferencesQueryVariables>(WorkspaceBoardUserPreferencesDocument, options);
        }
export function useWorkspaceBoardUserPreferencesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WorkspaceBoardUserPreferencesQuery, WorkspaceBoardUserPreferencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WorkspaceBoardUserPreferencesQuery, WorkspaceBoardUserPreferencesQueryVariables>(WorkspaceBoardUserPreferencesDocument, options);
        }
export type WorkspaceBoardUserPreferencesQueryHookResult = ReturnType<typeof useWorkspaceBoardUserPreferencesQuery>;
export type WorkspaceBoardUserPreferencesLazyQueryHookResult = ReturnType<typeof useWorkspaceBoardUserPreferencesLazyQuery>;
export type WorkspaceBoardUserPreferencesSuspenseQueryHookResult = ReturnType<typeof useWorkspaceBoardUserPreferencesSuspenseQuery>;
export type WorkspaceBoardUserPreferencesQueryResult = Apollo.QueryResult<WorkspaceBoardUserPreferencesQuery, WorkspaceBoardUserPreferencesQueryVariables>;
export const WorkspacesPageUserPreferencesDocument = gql`
    query WorkspacesPageUserPreferences($key: String!) {
  userPreference(key: $key) {
    key
    data
  }
}
    `;

/**
 * __useWorkspacesPageUserPreferencesQuery__
 *
 * To run a query within a React component, call `useWorkspacesPageUserPreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspacesPageUserPreferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspacesPageUserPreferencesQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useWorkspacesPageUserPreferencesQuery(baseOptions: Apollo.QueryHookOptions<WorkspacesPageUserPreferencesQuery, WorkspacesPageUserPreferencesQueryVariables> & ({ variables: WorkspacesPageUserPreferencesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkspacesPageUserPreferencesQuery, WorkspacesPageUserPreferencesQueryVariables>(WorkspacesPageUserPreferencesDocument, options);
      }
export function useWorkspacesPageUserPreferencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkspacesPageUserPreferencesQuery, WorkspacesPageUserPreferencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkspacesPageUserPreferencesQuery, WorkspacesPageUserPreferencesQueryVariables>(WorkspacesPageUserPreferencesDocument, options);
        }
export function useWorkspacesPageUserPreferencesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WorkspacesPageUserPreferencesQuery, WorkspacesPageUserPreferencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WorkspacesPageUserPreferencesQuery, WorkspacesPageUserPreferencesQueryVariables>(WorkspacesPageUserPreferencesDocument, options);
        }
export type WorkspacesPageUserPreferencesQueryHookResult = ReturnType<typeof useWorkspacesPageUserPreferencesQuery>;
export type WorkspacesPageUserPreferencesLazyQueryHookResult = ReturnType<typeof useWorkspacesPageUserPreferencesLazyQuery>;
export type WorkspacesPageUserPreferencesSuspenseQueryHookResult = ReturnType<typeof useWorkspacesPageUserPreferencesSuspenseQuery>;
export type WorkspacesPageUserPreferencesQueryResult = Apollo.QueryResult<WorkspacesPageUserPreferencesQuery, WorkspacesPageUserPreferencesQueryVariables>;
export const WorkspacePageUserPreferencesDocument = gql`
    query WorkspacePageUserPreferences($key: String!) {
  userPreference(key: $key) {
    key
    data
  }
}
    `;

/**
 * __useWorkspacePageUserPreferencesQuery__
 *
 * To run a query within a React component, call `useWorkspacePageUserPreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspacePageUserPreferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspacePageUserPreferencesQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useWorkspacePageUserPreferencesQuery(baseOptions: Apollo.QueryHookOptions<WorkspacePageUserPreferencesQuery, WorkspacePageUserPreferencesQueryVariables> & ({ variables: WorkspacePageUserPreferencesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkspacePageUserPreferencesQuery, WorkspacePageUserPreferencesQueryVariables>(WorkspacePageUserPreferencesDocument, options);
      }
export function useWorkspacePageUserPreferencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkspacePageUserPreferencesQuery, WorkspacePageUserPreferencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkspacePageUserPreferencesQuery, WorkspacePageUserPreferencesQueryVariables>(WorkspacePageUserPreferencesDocument, options);
        }
export function useWorkspacePageUserPreferencesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WorkspacePageUserPreferencesQuery, WorkspacePageUserPreferencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WorkspacePageUserPreferencesQuery, WorkspacePageUserPreferencesQueryVariables>(WorkspacePageUserPreferencesDocument, options);
        }
export type WorkspacePageUserPreferencesQueryHookResult = ReturnType<typeof useWorkspacePageUserPreferencesQuery>;
export type WorkspacePageUserPreferencesLazyQueryHookResult = ReturnType<typeof useWorkspacePageUserPreferencesLazyQuery>;
export type WorkspacePageUserPreferencesSuspenseQueryHookResult = ReturnType<typeof useWorkspacePageUserPreferencesSuspenseQuery>;
export type WorkspacePageUserPreferencesQueryResult = Apollo.QueryResult<WorkspacePageUserPreferencesQuery, WorkspacePageUserPreferencesQueryVariables>;
export const WorkspaceUserPreferencesDocument = gql`
    query WorkspaceUserPreferences($key: String!) {
  userPreference(key: $key) {
    key
    data
  }
}
    `;

/**
 * __useWorkspaceUserPreferencesQuery__
 *
 * To run a query within a React component, call `useWorkspaceUserPreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceUserPreferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceUserPreferencesQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useWorkspaceUserPreferencesQuery(baseOptions: Apollo.QueryHookOptions<WorkspaceUserPreferencesQuery, WorkspaceUserPreferencesQueryVariables> & ({ variables: WorkspaceUserPreferencesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkspaceUserPreferencesQuery, WorkspaceUserPreferencesQueryVariables>(WorkspaceUserPreferencesDocument, options);
      }
export function useWorkspaceUserPreferencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkspaceUserPreferencesQuery, WorkspaceUserPreferencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkspaceUserPreferencesQuery, WorkspaceUserPreferencesQueryVariables>(WorkspaceUserPreferencesDocument, options);
        }
export function useWorkspaceUserPreferencesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WorkspaceUserPreferencesQuery, WorkspaceUserPreferencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WorkspaceUserPreferencesQuery, WorkspaceUserPreferencesQueryVariables>(WorkspaceUserPreferencesDocument, options);
        }
export type WorkspaceUserPreferencesQueryHookResult = ReturnType<typeof useWorkspaceUserPreferencesQuery>;
export type WorkspaceUserPreferencesLazyQueryHookResult = ReturnType<typeof useWorkspaceUserPreferencesLazyQuery>;
export type WorkspaceUserPreferencesSuspenseQueryHookResult = ReturnType<typeof useWorkspaceUserPreferencesSuspenseQuery>;
export type WorkspaceUserPreferencesQueryResult = Apollo.QueryResult<WorkspaceUserPreferencesQuery, WorkspaceUserPreferencesQueryVariables>;
export const CreateWorkspaceDocument = gql`
    mutation CreateWorkspace($input: CreateWorkspaceInput!) {
  createWorkspace(input: $input) {
    id
    description
    name
    tags
    defaultWorkspaceBoard {
      id
      name
      tags
      thumbnail
    }
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
    `;
export type CreateWorkspaceMutationFn = Apollo.MutationFunction<CreateWorkspaceMutation, CreateWorkspaceMutationVariables>;

/**
 * __useCreateWorkspaceMutation__
 *
 * To run a mutation, you first call `useCreateWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkspaceMutation, { data, loading, error }] = useCreateWorkspaceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWorkspaceMutation(baseOptions?: Apollo.MutationHookOptions<CreateWorkspaceMutation, CreateWorkspaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWorkspaceMutation, CreateWorkspaceMutationVariables>(CreateWorkspaceDocument, options);
      }
export type CreateWorkspaceMutationHookResult = ReturnType<typeof useCreateWorkspaceMutation>;
export type CreateWorkspaceMutationResult = Apollo.MutationResult<CreateWorkspaceMutation>;
export type CreateWorkspaceMutationOptions = Apollo.BaseMutationOptions<CreateWorkspaceMutation, CreateWorkspaceMutationVariables>;
export const UpdateWorkspaceDocument = gql`
    mutation UpdateWorkspace($input: UpdateWorkspaceInput!) {
  updateWorkspace(input: $input) {
    id
    description
    name
    tags
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
    `;
export type UpdateWorkspaceMutationFn = Apollo.MutationFunction<UpdateWorkspaceMutation, UpdateWorkspaceMutationVariables>;

/**
 * __useUpdateWorkspaceMutation__
 *
 * To run a mutation, you first call `useUpdateWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkspaceMutation, { data, loading, error }] = useUpdateWorkspaceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWorkspaceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWorkspaceMutation, UpdateWorkspaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWorkspaceMutation, UpdateWorkspaceMutationVariables>(UpdateWorkspaceDocument, options);
      }
export type UpdateWorkspaceMutationHookResult = ReturnType<typeof useUpdateWorkspaceMutation>;
export type UpdateWorkspaceMutationResult = Apollo.MutationResult<UpdateWorkspaceMutation>;
export type UpdateWorkspaceMutationOptions = Apollo.BaseMutationOptions<UpdateWorkspaceMutation, UpdateWorkspaceMutationVariables>;
export const DeleteWorkspaceDocument = gql`
    mutation DeleteWorkspace($input: DeleteWorkspaceInput!) {
  deleteWorkspace(input: $input) {
    id
  }
}
    `;
export type DeleteWorkspaceMutationFn = Apollo.MutationFunction<DeleteWorkspaceMutation, DeleteWorkspaceMutationVariables>;

/**
 * __useDeleteWorkspaceMutation__
 *
 * To run a mutation, you first call `useDeleteWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWorkspaceMutation, { data, loading, error }] = useDeleteWorkspaceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteWorkspaceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteWorkspaceMutation, DeleteWorkspaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteWorkspaceMutation, DeleteWorkspaceMutationVariables>(DeleteWorkspaceDocument, options);
      }
export type DeleteWorkspaceMutationHookResult = ReturnType<typeof useDeleteWorkspaceMutation>;
export type DeleteWorkspaceMutationResult = Apollo.MutationResult<DeleteWorkspaceMutation>;
export type DeleteWorkspaceMutationOptions = Apollo.BaseMutationOptions<DeleteWorkspaceMutation, DeleteWorkspaceMutationVariables>;
export const CopyWorkspaceDocument = gql`
    mutation CopyWorkspace($input: CopyWorkspaceInput!) {
  copyWorkspace(input: $input) {
    id
    description
    name
    tags
    defaultWorkspaceBoard {
      id
      name
      tags
      thumbnail
    }
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
    `;
export type CopyWorkspaceMutationFn = Apollo.MutationFunction<CopyWorkspaceMutation, CopyWorkspaceMutationVariables>;

/**
 * __useCopyWorkspaceMutation__
 *
 * To run a mutation, you first call `useCopyWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyWorkspaceMutation, { data, loading, error }] = useCopyWorkspaceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCopyWorkspaceMutation(baseOptions?: Apollo.MutationHookOptions<CopyWorkspaceMutation, CopyWorkspaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CopyWorkspaceMutation, CopyWorkspaceMutationVariables>(CopyWorkspaceDocument, options);
      }
export type CopyWorkspaceMutationHookResult = ReturnType<typeof useCopyWorkspaceMutation>;
export type CopyWorkspaceMutationResult = Apollo.MutationResult<CopyWorkspaceMutation>;
export type CopyWorkspaceMutationOptions = Apollo.BaseMutationOptions<CopyWorkspaceMutation, CopyWorkspaceMutationVariables>;
export const ReindexWorkspaceDocument = gql`
    mutation ReindexWorkspace($input: ReindexWorkspaceInput!) {
  reindexWorkspace(input: $input) {
    id
  }
}
    `;
export type ReindexWorkspaceMutationFn = Apollo.MutationFunction<ReindexWorkspaceMutation, ReindexWorkspaceMutationVariables>;

/**
 * __useReindexWorkspaceMutation__
 *
 * To run a mutation, you first call `useReindexWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReindexWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reindexWorkspaceMutation, { data, loading, error }] = useReindexWorkspaceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReindexWorkspaceMutation(baseOptions?: Apollo.MutationHookOptions<ReindexWorkspaceMutation, ReindexWorkspaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReindexWorkspaceMutation, ReindexWorkspaceMutationVariables>(ReindexWorkspaceDocument, options);
      }
export type ReindexWorkspaceMutationHookResult = ReturnType<typeof useReindexWorkspaceMutation>;
export type ReindexWorkspaceMutationResult = Apollo.MutationResult<ReindexWorkspaceMutation>;
export type ReindexWorkspaceMutationOptions = Apollo.BaseMutationOptions<ReindexWorkspaceMutation, ReindexWorkspaceMutationVariables>;
export const WorkspaceDocument = gql`
    query Workspace($id: ID!) {
  workspace(id: $id) {
    id
    defaultWorkspaceBoard {
      id
      thumbnail
    }
    description
    name
    tags
  }
}
    `;

/**
 * __useWorkspaceQuery__
 *
 * To run a query within a React component, call `useWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWorkspaceQuery(baseOptions: Apollo.QueryHookOptions<WorkspaceQuery, WorkspaceQueryVariables> & ({ variables: WorkspaceQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkspaceQuery, WorkspaceQueryVariables>(WorkspaceDocument, options);
      }
export function useWorkspaceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkspaceQuery, WorkspaceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkspaceQuery, WorkspaceQueryVariables>(WorkspaceDocument, options);
        }
export function useWorkspaceSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WorkspaceQuery, WorkspaceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WorkspaceQuery, WorkspaceQueryVariables>(WorkspaceDocument, options);
        }
export type WorkspaceQueryHookResult = ReturnType<typeof useWorkspaceQuery>;
export type WorkspaceLazyQueryHookResult = ReturnType<typeof useWorkspaceLazyQuery>;
export type WorkspaceSuspenseQueryHookResult = ReturnType<typeof useWorkspaceSuspenseQuery>;
export type WorkspaceQueryResult = Apollo.QueryResult<WorkspaceQuery, WorkspaceQueryVariables>;
export const WorkspacesDocument = gql`
    query Workspaces($offset: Int, $limit: Int, $orderBy: WorkspaceOrderBy, $filters: WorkspaceFilters) {
  workspaces(offset: $offset, limit: $limit, orderBy: $orderBy, filters: $filters) {
    id
    defaultWorkspaceBoard {
      id
      thumbnail
    }
    description
    name
    tags
    updatedAt
    workspaceAccessControlList {
      userGroup {
        name
      }
      user {
        id
        name
      }
    }
  }
  workspaceAggregate(filters: $filters) {
    count
  }
}
    `;

/**
 * __useWorkspacesQuery__
 *
 * To run a query within a React component, call `useWorkspacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspacesQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      orderBy: // value for 'orderBy'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useWorkspacesQuery(baseOptions?: Apollo.QueryHookOptions<WorkspacesQuery, WorkspacesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkspacesQuery, WorkspacesQueryVariables>(WorkspacesDocument, options);
      }
export function useWorkspacesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkspacesQuery, WorkspacesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkspacesQuery, WorkspacesQueryVariables>(WorkspacesDocument, options);
        }
export function useWorkspacesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WorkspacesQuery, WorkspacesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WorkspacesQuery, WorkspacesQueryVariables>(WorkspacesDocument, options);
        }
export type WorkspacesQueryHookResult = ReturnType<typeof useWorkspacesQuery>;
export type WorkspacesLazyQueryHookResult = ReturnType<typeof useWorkspacesLazyQuery>;
export type WorkspacesSuspenseQueryHookResult = ReturnType<typeof useWorkspacesSuspenseQuery>;
export type WorkspacesQueryResult = Apollo.QueryResult<WorkspacesQuery, WorkspacesQueryVariables>;
export const WorkspaceTagsDocument = gql`
    query WorkspaceTags {
  workspaceTags
}
    `;

/**
 * __useWorkspaceTagsQuery__
 *
 * To run a query within a React component, call `useWorkspaceTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useWorkspaceTagsQuery(baseOptions?: Apollo.QueryHookOptions<WorkspaceTagsQuery, WorkspaceTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkspaceTagsQuery, WorkspaceTagsQueryVariables>(WorkspaceTagsDocument, options);
      }
export function useWorkspaceTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkspaceTagsQuery, WorkspaceTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkspaceTagsQuery, WorkspaceTagsQueryVariables>(WorkspaceTagsDocument, options);
        }
export function useWorkspaceTagsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WorkspaceTagsQuery, WorkspaceTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WorkspaceTagsQuery, WorkspaceTagsQueryVariables>(WorkspaceTagsDocument, options);
        }
export type WorkspaceTagsQueryHookResult = ReturnType<typeof useWorkspaceTagsQuery>;
export type WorkspaceTagsLazyQueryHookResult = ReturnType<typeof useWorkspaceTagsLazyQuery>;
export type WorkspaceTagsSuspenseQueryHookResult = ReturnType<typeof useWorkspaceTagsSuspenseQuery>;
export type WorkspaceTagsQueryResult = Apollo.QueryResult<WorkspaceTagsQuery, WorkspaceTagsQueryVariables>;
export const CreateWorkspaceAccessControlListDocument = gql`
    mutation CreateWorkspaceAccessControlList($input: CreateWorkspaceAccessControlListInput!) {
  createWorkspaceAccessControlList(input: $input) {
    id
    workspaceId
    role
    userGroup {
      id
      name
    }
    user {
      id
      name
      profilePictureUrl
    }
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
    `;
export type CreateWorkspaceAccessControlListMutationFn = Apollo.MutationFunction<CreateWorkspaceAccessControlListMutation, CreateWorkspaceAccessControlListMutationVariables>;

/**
 * __useCreateWorkspaceAccessControlListMutation__
 *
 * To run a mutation, you first call `useCreateWorkspaceAccessControlListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkspaceAccessControlListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkspaceAccessControlListMutation, { data, loading, error }] = useCreateWorkspaceAccessControlListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWorkspaceAccessControlListMutation(baseOptions?: Apollo.MutationHookOptions<CreateWorkspaceAccessControlListMutation, CreateWorkspaceAccessControlListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWorkspaceAccessControlListMutation, CreateWorkspaceAccessControlListMutationVariables>(CreateWorkspaceAccessControlListDocument, options);
      }
export type CreateWorkspaceAccessControlListMutationHookResult = ReturnType<typeof useCreateWorkspaceAccessControlListMutation>;
export type CreateWorkspaceAccessControlListMutationResult = Apollo.MutationResult<CreateWorkspaceAccessControlListMutation>;
export type CreateWorkspaceAccessControlListMutationOptions = Apollo.BaseMutationOptions<CreateWorkspaceAccessControlListMutation, CreateWorkspaceAccessControlListMutationVariables>;
export const UpdateWorkspaceAccessControlListDocument = gql`
    mutation UpdateWorkspaceAccessControlList($input: UpdateWorkspaceAccessControlListInput!) {
  updateWorkspaceAccessControlList(input: $input) {
    id
    workspaceId
    role
    userGroup {
      id
      name
    }
    user {
      id
      name
      profilePictureUrl
    }
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
    `;
export type UpdateWorkspaceAccessControlListMutationFn = Apollo.MutationFunction<UpdateWorkspaceAccessControlListMutation, UpdateWorkspaceAccessControlListMutationVariables>;

/**
 * __useUpdateWorkspaceAccessControlListMutation__
 *
 * To run a mutation, you first call `useUpdateWorkspaceAccessControlListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkspaceAccessControlListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkspaceAccessControlListMutation, { data, loading, error }] = useUpdateWorkspaceAccessControlListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWorkspaceAccessControlListMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWorkspaceAccessControlListMutation, UpdateWorkspaceAccessControlListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWorkspaceAccessControlListMutation, UpdateWorkspaceAccessControlListMutationVariables>(UpdateWorkspaceAccessControlListDocument, options);
      }
export type UpdateWorkspaceAccessControlListMutationHookResult = ReturnType<typeof useUpdateWorkspaceAccessControlListMutation>;
export type UpdateWorkspaceAccessControlListMutationResult = Apollo.MutationResult<UpdateWorkspaceAccessControlListMutation>;
export type UpdateWorkspaceAccessControlListMutationOptions = Apollo.BaseMutationOptions<UpdateWorkspaceAccessControlListMutation, UpdateWorkspaceAccessControlListMutationVariables>;
export const DeleteWorkspaceAccessControlListDocument = gql`
    mutation DeleteWorkspaceAccessControlList($input: DeleteWorkspaceAccessControlListInput!) {
  deleteWorkspaceAccessControlList(input: $input) {
    id
  }
}
    `;
export type DeleteWorkspaceAccessControlListMutationFn = Apollo.MutationFunction<DeleteWorkspaceAccessControlListMutation, DeleteWorkspaceAccessControlListMutationVariables>;

/**
 * __useDeleteWorkspaceAccessControlListMutation__
 *
 * To run a mutation, you first call `useDeleteWorkspaceAccessControlListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorkspaceAccessControlListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWorkspaceAccessControlListMutation, { data, loading, error }] = useDeleteWorkspaceAccessControlListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteWorkspaceAccessControlListMutation(baseOptions?: Apollo.MutationHookOptions<DeleteWorkspaceAccessControlListMutation, DeleteWorkspaceAccessControlListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteWorkspaceAccessControlListMutation, DeleteWorkspaceAccessControlListMutationVariables>(DeleteWorkspaceAccessControlListDocument, options);
      }
export type DeleteWorkspaceAccessControlListMutationHookResult = ReturnType<typeof useDeleteWorkspaceAccessControlListMutation>;
export type DeleteWorkspaceAccessControlListMutationResult = Apollo.MutationResult<DeleteWorkspaceAccessControlListMutation>;
export type DeleteWorkspaceAccessControlListMutationOptions = Apollo.BaseMutationOptions<DeleteWorkspaceAccessControlListMutation, DeleteWorkspaceAccessControlListMutationVariables>;
export const WorkspaceAccessControlListDocument = gql`
    query WorkspaceAccessControlList($id: ID!) {
  workspaceAccessControlList(id: $id) {
    id
    workspaceId
    role
    userGroup {
      id
      name
      isSystem
    }
    user {
      id
      name
      profilePictureUrl
    }
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
    `;

/**
 * __useWorkspaceAccessControlListQuery__
 *
 * To run a query within a React component, call `useWorkspaceAccessControlListQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceAccessControlListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceAccessControlListQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWorkspaceAccessControlListQuery(baseOptions: Apollo.QueryHookOptions<WorkspaceAccessControlListQuery, WorkspaceAccessControlListQueryVariables> & ({ variables: WorkspaceAccessControlListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkspaceAccessControlListQuery, WorkspaceAccessControlListQueryVariables>(WorkspaceAccessControlListDocument, options);
      }
export function useWorkspaceAccessControlListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkspaceAccessControlListQuery, WorkspaceAccessControlListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkspaceAccessControlListQuery, WorkspaceAccessControlListQueryVariables>(WorkspaceAccessControlListDocument, options);
        }
export function useWorkspaceAccessControlListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WorkspaceAccessControlListQuery, WorkspaceAccessControlListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WorkspaceAccessControlListQuery, WorkspaceAccessControlListQueryVariables>(WorkspaceAccessControlListDocument, options);
        }
export type WorkspaceAccessControlListQueryHookResult = ReturnType<typeof useWorkspaceAccessControlListQuery>;
export type WorkspaceAccessControlListLazyQueryHookResult = ReturnType<typeof useWorkspaceAccessControlListLazyQuery>;
export type WorkspaceAccessControlListSuspenseQueryHookResult = ReturnType<typeof useWorkspaceAccessControlListSuspenseQuery>;
export type WorkspaceAccessControlListQueryResult = Apollo.QueryResult<WorkspaceAccessControlListQuery, WorkspaceAccessControlListQueryVariables>;
export const WorkspaceAccessControlListByObjectDocument = gql`
    query WorkspaceAccessControlListByObject($workspaceId: ID!) {
  workspaceAccessControlListByObject(workspaceId: $workspaceId) {
    id
    workspaceId
    role
    userGroup {
      id
      name
      isSystem
    }
    user {
      id
      name
      profilePictureUrl
    }
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
  workspaceAccessControlListByObjectAggregate(workspaceId: $workspaceId) {
    count
  }
}
    `;

/**
 * __useWorkspaceAccessControlListByObjectQuery__
 *
 * To run a query within a React component, call `useWorkspaceAccessControlListByObjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceAccessControlListByObjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceAccessControlListByObjectQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useWorkspaceAccessControlListByObjectQuery(baseOptions: Apollo.QueryHookOptions<WorkspaceAccessControlListByObjectQuery, WorkspaceAccessControlListByObjectQueryVariables> & ({ variables: WorkspaceAccessControlListByObjectQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkspaceAccessControlListByObjectQuery, WorkspaceAccessControlListByObjectQueryVariables>(WorkspaceAccessControlListByObjectDocument, options);
      }
export function useWorkspaceAccessControlListByObjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkspaceAccessControlListByObjectQuery, WorkspaceAccessControlListByObjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkspaceAccessControlListByObjectQuery, WorkspaceAccessControlListByObjectQueryVariables>(WorkspaceAccessControlListByObjectDocument, options);
        }
export function useWorkspaceAccessControlListByObjectSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WorkspaceAccessControlListByObjectQuery, WorkspaceAccessControlListByObjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WorkspaceAccessControlListByObjectQuery, WorkspaceAccessControlListByObjectQueryVariables>(WorkspaceAccessControlListByObjectDocument, options);
        }
export type WorkspaceAccessControlListByObjectQueryHookResult = ReturnType<typeof useWorkspaceAccessControlListByObjectQuery>;
export type WorkspaceAccessControlListByObjectLazyQueryHookResult = ReturnType<typeof useWorkspaceAccessControlListByObjectLazyQuery>;
export type WorkspaceAccessControlListByObjectSuspenseQueryHookResult = ReturnType<typeof useWorkspaceAccessControlListByObjectSuspenseQuery>;
export type WorkspaceAccessControlListByObjectQueryResult = Apollo.QueryResult<WorkspaceAccessControlListByObjectQuery, WorkspaceAccessControlListByObjectQueryVariables>;
export const WorkspaceAccessControlListUserRolesDocument = gql`
    query WorkspaceAccessControlListUserRoles($userId: ID!, $workspaceId: ID!) {
  workspaceAccessControlListUserRoles(userId: $userId, workspaceId: $workspaceId) {
    userId
    objectId
    roles
  }
}
    `;

/**
 * __useWorkspaceAccessControlListUserRolesQuery__
 *
 * To run a query within a React component, call `useWorkspaceAccessControlListUserRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceAccessControlListUserRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceAccessControlListUserRolesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useWorkspaceAccessControlListUserRolesQuery(baseOptions: Apollo.QueryHookOptions<WorkspaceAccessControlListUserRolesQuery, WorkspaceAccessControlListUserRolesQueryVariables> & ({ variables: WorkspaceAccessControlListUserRolesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkspaceAccessControlListUserRolesQuery, WorkspaceAccessControlListUserRolesQueryVariables>(WorkspaceAccessControlListUserRolesDocument, options);
      }
export function useWorkspaceAccessControlListUserRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkspaceAccessControlListUserRolesQuery, WorkspaceAccessControlListUserRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkspaceAccessControlListUserRolesQuery, WorkspaceAccessControlListUserRolesQueryVariables>(WorkspaceAccessControlListUserRolesDocument, options);
        }
export function useWorkspaceAccessControlListUserRolesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WorkspaceAccessControlListUserRolesQuery, WorkspaceAccessControlListUserRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WorkspaceAccessControlListUserRolesQuery, WorkspaceAccessControlListUserRolesQueryVariables>(WorkspaceAccessControlListUserRolesDocument, options);
        }
export type WorkspaceAccessControlListUserRolesQueryHookResult = ReturnType<typeof useWorkspaceAccessControlListUserRolesQuery>;
export type WorkspaceAccessControlListUserRolesLazyQueryHookResult = ReturnType<typeof useWorkspaceAccessControlListUserRolesLazyQuery>;
export type WorkspaceAccessControlListUserRolesSuspenseQueryHookResult = ReturnType<typeof useWorkspaceAccessControlListUserRolesSuspenseQuery>;
export type WorkspaceAccessControlListUserRolesQueryResult = Apollo.QueryResult<WorkspaceAccessControlListUserRolesQuery, WorkspaceAccessControlListUserRolesQueryVariables>;
export const WorkspaceBoardAccessControlListUserRolesDocument = gql`
    query WorkspaceBoardAccessControlListUserRoles($userId: ID!, $workspaceBoardId: ID!) {
  workspaceBoardAccessControlListUserRoles(
    userId: $userId
    workspaceBoardId: $workspaceBoardId
  ) {
    userId
    objectId
    roles
  }
}
    `;

/**
 * __useWorkspaceBoardAccessControlListUserRolesQuery__
 *
 * To run a query within a React component, call `useWorkspaceBoardAccessControlListUserRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceBoardAccessControlListUserRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceBoardAccessControlListUserRolesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      workspaceBoardId: // value for 'workspaceBoardId'
 *   },
 * });
 */
export function useWorkspaceBoardAccessControlListUserRolesQuery(baseOptions: Apollo.QueryHookOptions<WorkspaceBoardAccessControlListUserRolesQuery, WorkspaceBoardAccessControlListUserRolesQueryVariables> & ({ variables: WorkspaceBoardAccessControlListUserRolesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkspaceBoardAccessControlListUserRolesQuery, WorkspaceBoardAccessControlListUserRolesQueryVariables>(WorkspaceBoardAccessControlListUserRolesDocument, options);
      }
export function useWorkspaceBoardAccessControlListUserRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkspaceBoardAccessControlListUserRolesQuery, WorkspaceBoardAccessControlListUserRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkspaceBoardAccessControlListUserRolesQuery, WorkspaceBoardAccessControlListUserRolesQueryVariables>(WorkspaceBoardAccessControlListUserRolesDocument, options);
        }
export function useWorkspaceBoardAccessControlListUserRolesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WorkspaceBoardAccessControlListUserRolesQuery, WorkspaceBoardAccessControlListUserRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WorkspaceBoardAccessControlListUserRolesQuery, WorkspaceBoardAccessControlListUserRolesQueryVariables>(WorkspaceBoardAccessControlListUserRolesDocument, options);
        }
export type WorkspaceBoardAccessControlListUserRolesQueryHookResult = ReturnType<typeof useWorkspaceBoardAccessControlListUserRolesQuery>;
export type WorkspaceBoardAccessControlListUserRolesLazyQueryHookResult = ReturnType<typeof useWorkspaceBoardAccessControlListUserRolesLazyQuery>;
export type WorkspaceBoardAccessControlListUserRolesSuspenseQueryHookResult = ReturnType<typeof useWorkspaceBoardAccessControlListUserRolesSuspenseQuery>;
export type WorkspaceBoardAccessControlListUserRolesQueryResult = Apollo.QueryResult<WorkspaceBoardAccessControlListUserRolesQuery, WorkspaceBoardAccessControlListUserRolesQueryVariables>;
export const CreateWorkspaceBoardDocument = gql`
    mutation CreateWorkspaceBoard($input: CreateWorkspaceBoardInput!) {
  createWorkspaceBoard(input: $input) {
    id
    content
    description
    name
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
    `;
export type CreateWorkspaceBoardMutationFn = Apollo.MutationFunction<CreateWorkspaceBoardMutation, CreateWorkspaceBoardMutationVariables>;

/**
 * __useCreateWorkspaceBoardMutation__
 *
 * To run a mutation, you first call `useCreateWorkspaceBoardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkspaceBoardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkspaceBoardMutation, { data, loading, error }] = useCreateWorkspaceBoardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWorkspaceBoardMutation(baseOptions?: Apollo.MutationHookOptions<CreateWorkspaceBoardMutation, CreateWorkspaceBoardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWorkspaceBoardMutation, CreateWorkspaceBoardMutationVariables>(CreateWorkspaceBoardDocument, options);
      }
export type CreateWorkspaceBoardMutationHookResult = ReturnType<typeof useCreateWorkspaceBoardMutation>;
export type CreateWorkspaceBoardMutationResult = Apollo.MutationResult<CreateWorkspaceBoardMutation>;
export type CreateWorkspaceBoardMutationOptions = Apollo.BaseMutationOptions<CreateWorkspaceBoardMutation, CreateWorkspaceBoardMutationVariables>;
export const DeleteWorkspaceBoardDocument = gql`
    mutation DeleteWorkspaceBoard($input: DeleteWorkspaceBoardInput!) {
  deleteWorkspaceBoard(input: $input) {
    id
  }
}
    `;
export type DeleteWorkspaceBoardMutationFn = Apollo.MutationFunction<DeleteWorkspaceBoardMutation, DeleteWorkspaceBoardMutationVariables>;

/**
 * __useDeleteWorkspaceBoardMutation__
 *
 * To run a mutation, you first call `useDeleteWorkspaceBoardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorkspaceBoardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWorkspaceBoardMutation, { data, loading, error }] = useDeleteWorkspaceBoardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteWorkspaceBoardMutation(baseOptions?: Apollo.MutationHookOptions<DeleteWorkspaceBoardMutation, DeleteWorkspaceBoardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteWorkspaceBoardMutation, DeleteWorkspaceBoardMutationVariables>(DeleteWorkspaceBoardDocument, options);
      }
export type DeleteWorkspaceBoardMutationHookResult = ReturnType<typeof useDeleteWorkspaceBoardMutation>;
export type DeleteWorkspaceBoardMutationResult = Apollo.MutationResult<DeleteWorkspaceBoardMutation>;
export type DeleteWorkspaceBoardMutationOptions = Apollo.BaseMutationOptions<DeleteWorkspaceBoardMutation, DeleteWorkspaceBoardMutationVariables>;
export const UpdateWorkspaceBoardDocument = gql`
    mutation UpdateWorkspaceBoard($input: UpdateWorkspaceBoardInput!) {
  updateWorkspaceBoard(input: $input) {
    content
    description
    id
    isThumbnailLocked
    name
    tags
    thumbnail
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
    `;
export type UpdateWorkspaceBoardMutationFn = Apollo.MutationFunction<UpdateWorkspaceBoardMutation, UpdateWorkspaceBoardMutationVariables>;

/**
 * __useUpdateWorkspaceBoardMutation__
 *
 * To run a mutation, you first call `useUpdateWorkspaceBoardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkspaceBoardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkspaceBoardMutation, { data, loading, error }] = useUpdateWorkspaceBoardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWorkspaceBoardMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWorkspaceBoardMutation, UpdateWorkspaceBoardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWorkspaceBoardMutation, UpdateWorkspaceBoardMutationVariables>(UpdateWorkspaceBoardDocument, options);
      }
export type UpdateWorkspaceBoardMutationHookResult = ReturnType<typeof useUpdateWorkspaceBoardMutation>;
export type UpdateWorkspaceBoardMutationResult = Apollo.MutationResult<UpdateWorkspaceBoardMutation>;
export type UpdateWorkspaceBoardMutationOptions = Apollo.BaseMutationOptions<UpdateWorkspaceBoardMutation, UpdateWorkspaceBoardMutationVariables>;
export const MarkWorkspaceBoardShapesAsDeletedDocument = gql`
    mutation MarkWorkspaceBoardShapesAsDeleted($input: MarkWorkspaceBoardShapeAsDeletedInput!) {
  markWorkspaceBoardShapesAsDeleted(input: $input)
}
    `;
export type MarkWorkspaceBoardShapesAsDeletedMutationFn = Apollo.MutationFunction<MarkWorkspaceBoardShapesAsDeletedMutation, MarkWorkspaceBoardShapesAsDeletedMutationVariables>;

/**
 * __useMarkWorkspaceBoardShapesAsDeletedMutation__
 *
 * To run a mutation, you first call `useMarkWorkspaceBoardShapesAsDeletedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkWorkspaceBoardShapesAsDeletedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markWorkspaceBoardShapesAsDeletedMutation, { data, loading, error }] = useMarkWorkspaceBoardShapesAsDeletedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkWorkspaceBoardShapesAsDeletedMutation(baseOptions?: Apollo.MutationHookOptions<MarkWorkspaceBoardShapesAsDeletedMutation, MarkWorkspaceBoardShapesAsDeletedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkWorkspaceBoardShapesAsDeletedMutation, MarkWorkspaceBoardShapesAsDeletedMutationVariables>(MarkWorkspaceBoardShapesAsDeletedDocument, options);
      }
export type MarkWorkspaceBoardShapesAsDeletedMutationHookResult = ReturnType<typeof useMarkWorkspaceBoardShapesAsDeletedMutation>;
export type MarkWorkspaceBoardShapesAsDeletedMutationResult = Apollo.MutationResult<MarkWorkspaceBoardShapesAsDeletedMutation>;
export type MarkWorkspaceBoardShapesAsDeletedMutationOptions = Apollo.BaseMutationOptions<MarkWorkspaceBoardShapesAsDeletedMutation, MarkWorkspaceBoardShapesAsDeletedMutationVariables>;
export const PublishBoardDocumentShapesToDocumentsDocument = gql`
    mutation PublishBoardDocumentShapesToDocuments($input: PublishBoardDocumentShapesToDocumentsInput!) {
  publishBoardDocumentShapesToDocuments(input: $input) {
    id
    name
    content {
      id
      content
      contentType
    }
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
    `;
export type PublishBoardDocumentShapesToDocumentsMutationFn = Apollo.MutationFunction<PublishBoardDocumentShapesToDocumentsMutation, PublishBoardDocumentShapesToDocumentsMutationVariables>;

/**
 * __usePublishBoardDocumentShapesToDocumentsMutation__
 *
 * To run a mutation, you first call `usePublishBoardDocumentShapesToDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishBoardDocumentShapesToDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishBoardDocumentShapesToDocumentsMutation, { data, loading, error }] = usePublishBoardDocumentShapesToDocumentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublishBoardDocumentShapesToDocumentsMutation(baseOptions?: Apollo.MutationHookOptions<PublishBoardDocumentShapesToDocumentsMutation, PublishBoardDocumentShapesToDocumentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PublishBoardDocumentShapesToDocumentsMutation, PublishBoardDocumentShapesToDocumentsMutationVariables>(PublishBoardDocumentShapesToDocumentsDocument, options);
      }
export type PublishBoardDocumentShapesToDocumentsMutationHookResult = ReturnType<typeof usePublishBoardDocumentShapesToDocumentsMutation>;
export type PublishBoardDocumentShapesToDocumentsMutationResult = Apollo.MutationResult<PublishBoardDocumentShapesToDocumentsMutation>;
export type PublishBoardDocumentShapesToDocumentsMutationOptions = Apollo.BaseMutationOptions<PublishBoardDocumentShapesToDocumentsMutation, PublishBoardDocumentShapesToDocumentsMutationVariables>;
export const WorkspaceBoardDocument = gql`
    query WorkspaceBoard($id: ID!) {
  workspaceBoard(id: $id) {
    content
    description
    id
    isThumbnailLocked
    name
    tags
    thumbnail
    workspaceId
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
    `;

/**
 * __useWorkspaceBoardQuery__
 *
 * To run a query within a React component, call `useWorkspaceBoardQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceBoardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceBoardQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWorkspaceBoardQuery(baseOptions: Apollo.QueryHookOptions<WorkspaceBoardQuery, WorkspaceBoardQueryVariables> & ({ variables: WorkspaceBoardQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkspaceBoardQuery, WorkspaceBoardQueryVariables>(WorkspaceBoardDocument, options);
      }
export function useWorkspaceBoardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkspaceBoardQuery, WorkspaceBoardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkspaceBoardQuery, WorkspaceBoardQueryVariables>(WorkspaceBoardDocument, options);
        }
export function useWorkspaceBoardSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WorkspaceBoardQuery, WorkspaceBoardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WorkspaceBoardQuery, WorkspaceBoardQueryVariables>(WorkspaceBoardDocument, options);
        }
export type WorkspaceBoardQueryHookResult = ReturnType<typeof useWorkspaceBoardQuery>;
export type WorkspaceBoardLazyQueryHookResult = ReturnType<typeof useWorkspaceBoardLazyQuery>;
export type WorkspaceBoardSuspenseQueryHookResult = ReturnType<typeof useWorkspaceBoardSuspenseQuery>;
export type WorkspaceBoardQueryResult = Apollo.QueryResult<WorkspaceBoardQuery, WorkspaceBoardQueryVariables>;
export const WorkspaceBoardsDocument = gql`
    query WorkspaceBoards($workspaceId: ID!, $offset: Int, $limit: Int, $orderBy: WorkspaceBoardOrderBy, $filters: WorkspaceBoardFilters) {
  workspaceBoards(
    workspaceId: $workspaceId
    offset: $offset
    limit: $limit
    orderBy: $orderBy
    filters: $filters
  ) {
    content
    description
    id
    isThumbnailLocked
    name
    tags
    thumbnail
    workspaceId
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
  workspaceBoardAggregate(workspaceId: $workspaceId, filters: $filters) {
    count
  }
}
    `;

/**
 * __useWorkspaceBoardsQuery__
 *
 * To run a query within a React component, call `useWorkspaceBoardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceBoardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceBoardsQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      orderBy: // value for 'orderBy'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useWorkspaceBoardsQuery(baseOptions: Apollo.QueryHookOptions<WorkspaceBoardsQuery, WorkspaceBoardsQueryVariables> & ({ variables: WorkspaceBoardsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkspaceBoardsQuery, WorkspaceBoardsQueryVariables>(WorkspaceBoardsDocument, options);
      }
export function useWorkspaceBoardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkspaceBoardsQuery, WorkspaceBoardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkspaceBoardsQuery, WorkspaceBoardsQueryVariables>(WorkspaceBoardsDocument, options);
        }
export function useWorkspaceBoardsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WorkspaceBoardsQuery, WorkspaceBoardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WorkspaceBoardsQuery, WorkspaceBoardsQueryVariables>(WorkspaceBoardsDocument, options);
        }
export type WorkspaceBoardsQueryHookResult = ReturnType<typeof useWorkspaceBoardsQuery>;
export type WorkspaceBoardsLazyQueryHookResult = ReturnType<typeof useWorkspaceBoardsLazyQuery>;
export type WorkspaceBoardsSuspenseQueryHookResult = ReturnType<typeof useWorkspaceBoardsSuspenseQuery>;
export type WorkspaceBoardsQueryResult = Apollo.QueryResult<WorkspaceBoardsQuery, WorkspaceBoardsQueryVariables>;
export const WorkspaceBoardAggregateDocument = gql`
    query WorkspaceBoardAggregate($workspaceId: ID!, $filters: WorkspaceBoardFilters) {
  workspaceBoardAggregate(workspaceId: $workspaceId, filters: $filters) {
    count
  }
}
    `;

/**
 * __useWorkspaceBoardAggregateQuery__
 *
 * To run a query within a React component, call `useWorkspaceBoardAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceBoardAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceBoardAggregateQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useWorkspaceBoardAggregateQuery(baseOptions: Apollo.QueryHookOptions<WorkspaceBoardAggregateQuery, WorkspaceBoardAggregateQueryVariables> & ({ variables: WorkspaceBoardAggregateQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkspaceBoardAggregateQuery, WorkspaceBoardAggregateQueryVariables>(WorkspaceBoardAggregateDocument, options);
      }
export function useWorkspaceBoardAggregateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkspaceBoardAggregateQuery, WorkspaceBoardAggregateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkspaceBoardAggregateQuery, WorkspaceBoardAggregateQueryVariables>(WorkspaceBoardAggregateDocument, options);
        }
export function useWorkspaceBoardAggregateSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WorkspaceBoardAggregateQuery, WorkspaceBoardAggregateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WorkspaceBoardAggregateQuery, WorkspaceBoardAggregateQueryVariables>(WorkspaceBoardAggregateDocument, options);
        }
export type WorkspaceBoardAggregateQueryHookResult = ReturnType<typeof useWorkspaceBoardAggregateQuery>;
export type WorkspaceBoardAggregateLazyQueryHookResult = ReturnType<typeof useWorkspaceBoardAggregateLazyQuery>;
export type WorkspaceBoardAggregateSuspenseQueryHookResult = ReturnType<typeof useWorkspaceBoardAggregateSuspenseQuery>;
export type WorkspaceBoardAggregateQueryResult = Apollo.QueryResult<WorkspaceBoardAggregateQuery, WorkspaceBoardAggregateQueryVariables>;
export const WorkspaceBoardTagsDocument = gql`
    query WorkspaceBoardTags($workspaceId: ID!) {
  workspaceBoardTags(workspaceId: $workspaceId)
}
    `;

/**
 * __useWorkspaceBoardTagsQuery__
 *
 * To run a query within a React component, call `useWorkspaceBoardTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceBoardTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceBoardTagsQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useWorkspaceBoardTagsQuery(baseOptions: Apollo.QueryHookOptions<WorkspaceBoardTagsQuery, WorkspaceBoardTagsQueryVariables> & ({ variables: WorkspaceBoardTagsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkspaceBoardTagsQuery, WorkspaceBoardTagsQueryVariables>(WorkspaceBoardTagsDocument, options);
      }
export function useWorkspaceBoardTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkspaceBoardTagsQuery, WorkspaceBoardTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkspaceBoardTagsQuery, WorkspaceBoardTagsQueryVariables>(WorkspaceBoardTagsDocument, options);
        }
export function useWorkspaceBoardTagsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WorkspaceBoardTagsQuery, WorkspaceBoardTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WorkspaceBoardTagsQuery, WorkspaceBoardTagsQueryVariables>(WorkspaceBoardTagsDocument, options);
        }
export type WorkspaceBoardTagsQueryHookResult = ReturnType<typeof useWorkspaceBoardTagsQuery>;
export type WorkspaceBoardTagsLazyQueryHookResult = ReturnType<typeof useWorkspaceBoardTagsLazyQuery>;
export type WorkspaceBoardTagsSuspenseQueryHookResult = ReturnType<typeof useWorkspaceBoardTagsSuspenseQuery>;
export type WorkspaceBoardTagsQueryResult = Apollo.QueryResult<WorkspaceBoardTagsQuery, WorkspaceBoardTagsQueryVariables>;
export const MarkWorkspaceBoardTableOfContentsActiveForShapeDocument = gql`
    mutation MarkWorkspaceBoardTableOfContentsActiveForShape($input: MarkWorkspaceBoardTableOfContentsActiveForShapeInput!) {
  markWorkspaceBoardTableOfContentsActiveForShape(input: $input) {
    count
  }
}
    `;
export type MarkWorkspaceBoardTableOfContentsActiveForShapeMutationFn = Apollo.MutationFunction<MarkWorkspaceBoardTableOfContentsActiveForShapeMutation, MarkWorkspaceBoardTableOfContentsActiveForShapeMutationVariables>;

/**
 * __useMarkWorkspaceBoardTableOfContentsActiveForShapeMutation__
 *
 * To run a mutation, you first call `useMarkWorkspaceBoardTableOfContentsActiveForShapeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkWorkspaceBoardTableOfContentsActiveForShapeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markWorkspaceBoardTableOfContentsActiveForShapeMutation, { data, loading, error }] = useMarkWorkspaceBoardTableOfContentsActiveForShapeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkWorkspaceBoardTableOfContentsActiveForShapeMutation(baseOptions?: Apollo.MutationHookOptions<MarkWorkspaceBoardTableOfContentsActiveForShapeMutation, MarkWorkspaceBoardTableOfContentsActiveForShapeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkWorkspaceBoardTableOfContentsActiveForShapeMutation, MarkWorkspaceBoardTableOfContentsActiveForShapeMutationVariables>(MarkWorkspaceBoardTableOfContentsActiveForShapeDocument, options);
      }
export type MarkWorkspaceBoardTableOfContentsActiveForShapeMutationHookResult = ReturnType<typeof useMarkWorkspaceBoardTableOfContentsActiveForShapeMutation>;
export type MarkWorkspaceBoardTableOfContentsActiveForShapeMutationResult = Apollo.MutationResult<MarkWorkspaceBoardTableOfContentsActiveForShapeMutation>;
export type MarkWorkspaceBoardTableOfContentsActiveForShapeMutationOptions = Apollo.BaseMutationOptions<MarkWorkspaceBoardTableOfContentsActiveForShapeMutation, MarkWorkspaceBoardTableOfContentsActiveForShapeMutationVariables>;
export const MarkWorkspaceBoardTableOfContentsInactiveForShapeDocument = gql`
    mutation MarkWorkspaceBoardTableOfContentsInactiveForShape($input: MarkWorkspaceBoardTableOfContentsInactiveForShapeInput!) {
  markWorkspaceBoardTableOfContentsInactiveForShape(input: $input) {
    count
  }
}
    `;
export type MarkWorkspaceBoardTableOfContentsInactiveForShapeMutationFn = Apollo.MutationFunction<MarkWorkspaceBoardTableOfContentsInactiveForShapeMutation, MarkWorkspaceBoardTableOfContentsInactiveForShapeMutationVariables>;

/**
 * __useMarkWorkspaceBoardTableOfContentsInactiveForShapeMutation__
 *
 * To run a mutation, you first call `useMarkWorkspaceBoardTableOfContentsInactiveForShapeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkWorkspaceBoardTableOfContentsInactiveForShapeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markWorkspaceBoardTableOfContentsInactiveForShapeMutation, { data, loading, error }] = useMarkWorkspaceBoardTableOfContentsInactiveForShapeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkWorkspaceBoardTableOfContentsInactiveForShapeMutation(baseOptions?: Apollo.MutationHookOptions<MarkWorkspaceBoardTableOfContentsInactiveForShapeMutation, MarkWorkspaceBoardTableOfContentsInactiveForShapeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkWorkspaceBoardTableOfContentsInactiveForShapeMutation, MarkWorkspaceBoardTableOfContentsInactiveForShapeMutationVariables>(MarkWorkspaceBoardTableOfContentsInactiveForShapeDocument, options);
      }
export type MarkWorkspaceBoardTableOfContentsInactiveForShapeMutationHookResult = ReturnType<typeof useMarkWorkspaceBoardTableOfContentsInactiveForShapeMutation>;
export type MarkWorkspaceBoardTableOfContentsInactiveForShapeMutationResult = Apollo.MutationResult<MarkWorkspaceBoardTableOfContentsInactiveForShapeMutation>;
export type MarkWorkspaceBoardTableOfContentsInactiveForShapeMutationOptions = Apollo.BaseMutationOptions<MarkWorkspaceBoardTableOfContentsInactiveForShapeMutation, MarkWorkspaceBoardTableOfContentsInactiveForShapeMutationVariables>;
export const CreateWorkspaceFileDocument = gql`
    mutation CreateWorkspaceFile($input: CreateWorkspaceFileInput!) {
  createWorkspaceFile(input: $input) {
    id
    name
    url
    filename
    mimeType
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
    `;
export type CreateWorkspaceFileMutationFn = Apollo.MutationFunction<CreateWorkspaceFileMutation, CreateWorkspaceFileMutationVariables>;

/**
 * __useCreateWorkspaceFileMutation__
 *
 * To run a mutation, you first call `useCreateWorkspaceFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkspaceFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkspaceFileMutation, { data, loading, error }] = useCreateWorkspaceFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWorkspaceFileMutation(baseOptions?: Apollo.MutationHookOptions<CreateWorkspaceFileMutation, CreateWorkspaceFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWorkspaceFileMutation, CreateWorkspaceFileMutationVariables>(CreateWorkspaceFileDocument, options);
      }
export type CreateWorkspaceFileMutationHookResult = ReturnType<typeof useCreateWorkspaceFileMutation>;
export type CreateWorkspaceFileMutationResult = Apollo.MutationResult<CreateWorkspaceFileMutation>;
export type CreateWorkspaceFileMutationOptions = Apollo.BaseMutationOptions<CreateWorkspaceFileMutation, CreateWorkspaceFileMutationVariables>;
export const UpdateWorkspaceFileDocument = gql`
    mutation UpdateWorkspaceFile($input: UpdateWorkspaceFileInput!) {
  updateWorkspaceFile(input: $input) {
    id
    name
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
    `;
export type UpdateWorkspaceFileMutationFn = Apollo.MutationFunction<UpdateWorkspaceFileMutation, UpdateWorkspaceFileMutationVariables>;

/**
 * __useUpdateWorkspaceFileMutation__
 *
 * To run a mutation, you first call `useUpdateWorkspaceFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkspaceFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkspaceFileMutation, { data, loading, error }] = useUpdateWorkspaceFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWorkspaceFileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWorkspaceFileMutation, UpdateWorkspaceFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWorkspaceFileMutation, UpdateWorkspaceFileMutationVariables>(UpdateWorkspaceFileDocument, options);
      }
export type UpdateWorkspaceFileMutationHookResult = ReturnType<typeof useUpdateWorkspaceFileMutation>;
export type UpdateWorkspaceFileMutationResult = Apollo.MutationResult<UpdateWorkspaceFileMutation>;
export type UpdateWorkspaceFileMutationOptions = Apollo.BaseMutationOptions<UpdateWorkspaceFileMutation, UpdateWorkspaceFileMutationVariables>;
export const DeleteWorkspaceFileDocument = gql`
    mutation DeleteWorkspaceFile($input: DeleteWorkspaceFileInput!) {
  deleteWorkspaceFile(input: $input) {
    id
  }
}
    `;
export type DeleteWorkspaceFileMutationFn = Apollo.MutationFunction<DeleteWorkspaceFileMutation, DeleteWorkspaceFileMutationVariables>;

/**
 * __useDeleteWorkspaceFileMutation__
 *
 * To run a mutation, you first call `useDeleteWorkspaceFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorkspaceFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWorkspaceFileMutation, { data, loading, error }] = useDeleteWorkspaceFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteWorkspaceFileMutation(baseOptions?: Apollo.MutationHookOptions<DeleteWorkspaceFileMutation, DeleteWorkspaceFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteWorkspaceFileMutation, DeleteWorkspaceFileMutationVariables>(DeleteWorkspaceFileDocument, options);
      }
export type DeleteWorkspaceFileMutationHookResult = ReturnType<typeof useDeleteWorkspaceFileMutation>;
export type DeleteWorkspaceFileMutationResult = Apollo.MutationResult<DeleteWorkspaceFileMutation>;
export type DeleteWorkspaceFileMutationOptions = Apollo.BaseMutationOptions<DeleteWorkspaceFileMutation, DeleteWorkspaceFileMutationVariables>;
export const PublishWorkspaceFilesToLibraryDocument = gql`
    mutation PublishWorkspaceFilesToLibrary($input: PublishWorkspaceFilesToLibraryInput!) {
  publishWorkspaceFilesToLibrary(input: $input) {
    id
    name
    url
    filename
    mimeType
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
    `;
export type PublishWorkspaceFilesToLibraryMutationFn = Apollo.MutationFunction<PublishWorkspaceFilesToLibraryMutation, PublishWorkspaceFilesToLibraryMutationVariables>;

/**
 * __usePublishWorkspaceFilesToLibraryMutation__
 *
 * To run a mutation, you first call `usePublishWorkspaceFilesToLibraryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishWorkspaceFilesToLibraryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishWorkspaceFilesToLibraryMutation, { data, loading, error }] = usePublishWorkspaceFilesToLibraryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublishWorkspaceFilesToLibraryMutation(baseOptions?: Apollo.MutationHookOptions<PublishWorkspaceFilesToLibraryMutation, PublishWorkspaceFilesToLibraryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PublishWorkspaceFilesToLibraryMutation, PublishWorkspaceFilesToLibraryMutationVariables>(PublishWorkspaceFilesToLibraryDocument, options);
      }
export type PublishWorkspaceFilesToLibraryMutationHookResult = ReturnType<typeof usePublishWorkspaceFilesToLibraryMutation>;
export type PublishWorkspaceFilesToLibraryMutationResult = Apollo.MutationResult<PublishWorkspaceFilesToLibraryMutation>;
export type PublishWorkspaceFilesToLibraryMutationOptions = Apollo.BaseMutationOptions<PublishWorkspaceFilesToLibraryMutation, PublishWorkspaceFilesToLibraryMutationVariables>;
export const WorkspaceFileDocument = gql`
    query WorkspaceFile($id: ID!) {
  workspaceFile(id: $id) {
    id
    name
    filename
    workspaceId
  }
}
    `;

/**
 * __useWorkspaceFileQuery__
 *
 * To run a query within a React component, call `useWorkspaceFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceFileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWorkspaceFileQuery(baseOptions: Apollo.QueryHookOptions<WorkspaceFileQuery, WorkspaceFileQueryVariables> & ({ variables: WorkspaceFileQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkspaceFileQuery, WorkspaceFileQueryVariables>(WorkspaceFileDocument, options);
      }
export function useWorkspaceFileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkspaceFileQuery, WorkspaceFileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkspaceFileQuery, WorkspaceFileQueryVariables>(WorkspaceFileDocument, options);
        }
export function useWorkspaceFileSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WorkspaceFileQuery, WorkspaceFileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WorkspaceFileQuery, WorkspaceFileQueryVariables>(WorkspaceFileDocument, options);
        }
export type WorkspaceFileQueryHookResult = ReturnType<typeof useWorkspaceFileQuery>;
export type WorkspaceFileLazyQueryHookResult = ReturnType<typeof useWorkspaceFileLazyQuery>;
export type WorkspaceFileSuspenseQueryHookResult = ReturnType<typeof useWorkspaceFileSuspenseQuery>;
export type WorkspaceFileQueryResult = Apollo.QueryResult<WorkspaceFileQuery, WorkspaceFileQueryVariables>;
export const WorkspaceFilesDocument = gql`
    query WorkspaceFiles($workspaceId: ID!, $offset: Int, $limit: Int, $orderBy: WorkspaceFileOrderBy, $filters: WorkspaceFileFilters) {
  workspaceFiles(
    workspaceId: $workspaceId
    offset: $offset
    limit: $limit
    orderBy: $orderBy
    filters: $filters
  ) {
    id
    name
    url
    filename
    mimeType
    workspaceId
    updatedAt
  }
  workspaceFileAggregate(workspaceId: $workspaceId, filters: $filters) {
    count
  }
}
    `;

/**
 * __useWorkspaceFilesQuery__
 *
 * To run a query within a React component, call `useWorkspaceFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceFilesQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      orderBy: // value for 'orderBy'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useWorkspaceFilesQuery(baseOptions: Apollo.QueryHookOptions<WorkspaceFilesQuery, WorkspaceFilesQueryVariables> & ({ variables: WorkspaceFilesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkspaceFilesQuery, WorkspaceFilesQueryVariables>(WorkspaceFilesDocument, options);
      }
export function useWorkspaceFilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkspaceFilesQuery, WorkspaceFilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkspaceFilesQuery, WorkspaceFilesQueryVariables>(WorkspaceFilesDocument, options);
        }
export function useWorkspaceFilesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WorkspaceFilesQuery, WorkspaceFilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WorkspaceFilesQuery, WorkspaceFilesQueryVariables>(WorkspaceFilesDocument, options);
        }
export type WorkspaceFilesQueryHookResult = ReturnType<typeof useWorkspaceFilesQuery>;
export type WorkspaceFilesLazyQueryHookResult = ReturnType<typeof useWorkspaceFilesLazyQuery>;
export type WorkspaceFilesSuspenseQueryHookResult = ReturnType<typeof useWorkspaceFilesSuspenseQuery>;
export type WorkspaceFilesQueryResult = Apollo.QueryResult<WorkspaceFilesQuery, WorkspaceFilesQueryVariables>;
export const WorkspaceFileAggregateDocument = gql`
    query WorkspaceFileAggregate($workspaceId: ID!, $filters: WorkspaceFileFilters) {
  workspaceFileAggregate(workspaceId: $workspaceId, filters: $filters) {
    count
  }
}
    `;

/**
 * __useWorkspaceFileAggregateQuery__
 *
 * To run a query within a React component, call `useWorkspaceFileAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceFileAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceFileAggregateQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useWorkspaceFileAggregateQuery(baseOptions: Apollo.QueryHookOptions<WorkspaceFileAggregateQuery, WorkspaceFileAggregateQueryVariables> & ({ variables: WorkspaceFileAggregateQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkspaceFileAggregateQuery, WorkspaceFileAggregateQueryVariables>(WorkspaceFileAggregateDocument, options);
      }
export function useWorkspaceFileAggregateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkspaceFileAggregateQuery, WorkspaceFileAggregateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkspaceFileAggregateQuery, WorkspaceFileAggregateQueryVariables>(WorkspaceFileAggregateDocument, options);
        }
export function useWorkspaceFileAggregateSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WorkspaceFileAggregateQuery, WorkspaceFileAggregateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WorkspaceFileAggregateQuery, WorkspaceFileAggregateQueryVariables>(WorkspaceFileAggregateDocument, options);
        }
export type WorkspaceFileAggregateQueryHookResult = ReturnType<typeof useWorkspaceFileAggregateQuery>;
export type WorkspaceFileAggregateLazyQueryHookResult = ReturnType<typeof useWorkspaceFileAggregateLazyQuery>;
export type WorkspaceFileAggregateSuspenseQueryHookResult = ReturnType<typeof useWorkspaceFileAggregateSuspenseQuery>;
export type WorkspaceFileAggregateQueryResult = Apollo.QueryResult<WorkspaceFileAggregateQuery, WorkspaceFileAggregateQueryVariables>;
export const UpsertWorkspaceBoardUserPreferencesCacheDocument = gql`
    query UpsertWorkspaceBoardUserPreferencesCache($key: String!) {
  userPreference(key: $key) {
    key
    data
  }
}
    `;

/**
 * __useUpsertWorkspaceBoardUserPreferencesCacheQuery__
 *
 * To run a query within a React component, call `useUpsertWorkspaceBoardUserPreferencesCacheQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpsertWorkspaceBoardUserPreferencesCacheQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpsertWorkspaceBoardUserPreferencesCacheQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useUpsertWorkspaceBoardUserPreferencesCacheQuery(baseOptions: Apollo.QueryHookOptions<UpsertWorkspaceBoardUserPreferencesCacheQuery, UpsertWorkspaceBoardUserPreferencesCacheQueryVariables> & ({ variables: UpsertWorkspaceBoardUserPreferencesCacheQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UpsertWorkspaceBoardUserPreferencesCacheQuery, UpsertWorkspaceBoardUserPreferencesCacheQueryVariables>(UpsertWorkspaceBoardUserPreferencesCacheDocument, options);
      }
export function useUpsertWorkspaceBoardUserPreferencesCacheLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UpsertWorkspaceBoardUserPreferencesCacheQuery, UpsertWorkspaceBoardUserPreferencesCacheQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UpsertWorkspaceBoardUserPreferencesCacheQuery, UpsertWorkspaceBoardUserPreferencesCacheQueryVariables>(UpsertWorkspaceBoardUserPreferencesCacheDocument, options);
        }
export function useUpsertWorkspaceBoardUserPreferencesCacheSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UpsertWorkspaceBoardUserPreferencesCacheQuery, UpsertWorkspaceBoardUserPreferencesCacheQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UpsertWorkspaceBoardUserPreferencesCacheQuery, UpsertWorkspaceBoardUserPreferencesCacheQueryVariables>(UpsertWorkspaceBoardUserPreferencesCacheDocument, options);
        }
export type UpsertWorkspaceBoardUserPreferencesCacheQueryHookResult = ReturnType<typeof useUpsertWorkspaceBoardUserPreferencesCacheQuery>;
export type UpsertWorkspaceBoardUserPreferencesCacheLazyQueryHookResult = ReturnType<typeof useUpsertWorkspaceBoardUserPreferencesCacheLazyQuery>;
export type UpsertWorkspaceBoardUserPreferencesCacheSuspenseQueryHookResult = ReturnType<typeof useUpsertWorkspaceBoardUserPreferencesCacheSuspenseQuery>;
export type UpsertWorkspaceBoardUserPreferencesCacheQueryResult = Apollo.QueryResult<UpsertWorkspaceBoardUserPreferencesCacheQuery, UpsertWorkspaceBoardUserPreferencesCacheQueryVariables>;