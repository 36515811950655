import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { Trans, useTranslation } from "react-i18next";

export function NotFoundPage() {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t("Pages.Errors.NotFound.Title")}</title>
      </Helmet>
      <Typography variant="h3">{t("Pages.Errors.NotFound.Title")}</Typography>
      <Typography variant="h6">{t("Pages.Errors.NotFound.ErrorMessage")}</Typography>
      <p>{t("Pages.Errors.NotFound.Suggestions.Heading")}</p>
      <ul>
        <li>{t("Pages.Errors.NotFound.Suggestions.CheckAddress")}</li>
        <li>
          <Trans i18nKey="Pages.Errors.General.GoToHomePage">
            <Link to="/">Go to the home page</Link>
          </Trans>
        </li>
      </ul>
    </>
  );
}
