import { Box, BoxProps, Grid, Typography, Skeleton } from "@mui/material";

import { customSeededRandomArray } from "Utils/MathUtils";

interface IHistogramChartLoaderProps {
  withLabel?: boolean;
  sx?: BoxProps["sx"];
}

const randomNumbers = customSeededRandomArray(12345, 20, 230);

/**
 * Gives a skeleton loader for a histogram chart
 *
 * @returns Box with skeleton loaders
 */
export function HistogramChartLoader(props: IHistogramChartLoaderProps) {
  const { withLabel = true, sx } = props;
  return (
    <Box sx={sx}>
      {withLabel && <Skeleton sx={{ width: 100 }} />}
      <Grid container sx={{ padding: 4, flexWrap: "nowrap" }}>
        {Array.from({ length: 20 }).map((v, i) => {
          return (
            <Grid item container direction="column" xs={1} sx={{ padding: 1 }} key={i}>
              <Grid item height="230px" sx={{ display: "flex", alignItems: "flex-end" }}>
                <Skeleton variant="rounded" animation={false} sx={{ height: randomNumbers[i], width: 40 }} />
              </Grid>
              <Grid container item direction="column">
                <Skeleton animation={false} width="40px" />
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
