import { CommandActionType, IPlugIn } from "@bigpi/cookbook";

import { ICommandRequestPlugInInputs } from "Components/CommandManagers/WorkspaceBoard/WorkspaceBoardCommandManager";

export abstract class RequestPlugInBase implements IPlugIn<ICommandRequestPlugInInputs, void> {
  // *********************************************
  // Public properties
  // *********************************************/
  /**
   * @inheritdoc
   */
  abstract name: CommandActionType;

  // *********************************************
  // Public methods
  // *********************************************/
  /**
   * @inheritdoc
   */
  public abstract execute(input: ICommandRequestPlugInInputs): Promise<void>;

  /**
   * @inheritdoc
   */
  public async destroy(): Promise<void> {
    // Do nothing in base class
  }
}
