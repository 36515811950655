import { RawCommands } from "@tiptap/core";
import { Node } from "@tiptap/pm/model";
import { v4 as uuidv4 } from "uuid";

import { getBaseClassWithoutIndent, getIndentClass, getIndentLevelByIndentClass } from "../../Utils/CommandUtil.js";

interface ListAttributes {
  previousListStart: number | null;
  previousListId: string | null;
  previousListType: "orderedList" | "bulletList" | null;
  previousListIndent: number;
}

// Calculates "start" value for the orderedList node.
const getStartAttributeForList = (node: Node) => {
  if (node.type.name !== "orderedList") {
    return;
  }

  // Check if "value" attribute is present on the "li" tag.
  const listItemNodeMarker = node.firstChild?.attrs.value;
  const listStartValue = node.attrs.start;

  // Prefer li["value"] attribute over ol["start"] attribute if li["value"] attribute value
  // is greater than 1 and ol["start"] has a default value i.e. 1. Example below -
  // <ol start='1'><li class='list-style-type-decimal heading-1' value='3'>third</li></ol>
  const listHasDefaultStartValue = !listStartValue || listStartValue === 1;
  if (listItemNodeMarker && listHasDefaultStartValue && listItemNodeMarker > 1) {
    return listItemNodeMarker;
  }

  // Use ol["start"] attribute value in all other cases.
  return listStartValue || null;
};

// Calculates indent class for an orderedList or a bulletList node.
const getIndentClassForList = (node: Node): [string | null | undefined, string | null | undefined] => {
  if (node.type.name !== "orderedList" && node.type.name !== "bulletList") {
    return [null, null];
  }

  // Check if indent class is present on the "ol"/ "ul" tag
  const listClasses: string | null = node.attrs.class;
  let indentClass = listClasses ? getIndentClass(listClasses) : null;

  // If indent class is not present on the "ol"/ "ul" tag then check if it's present on the "li" tag.
  if (!indentClass && node.firstChild?.attrs.class) {
    indentClass = getIndentClass(node.firstChild?.attrs.class);
  }

  // Base class is the class without indent class.
  const baseClass = getBaseClassWithoutIndent(listClasses);
  const newClass = baseClass || indentClass ? `${baseClass ?? ""} ${indentClass ?? ""}`.trim() : null;

  return [newClass, indentClass];
};

// Calculates "data-list-id" for the current selection.
const getIdForList = (node: Node, currentIndent: number, newStart: number | null, previousListAttributes: ListAttributes) => {
  const { previousListId, previousListStart, previousListIndent, previousListType } = previousListAttributes;
  const uniqueId = uuidv4();
  const nodeDataListIdAttr = node.attrs["data-list-id"];

  if (currentIndent > previousListIndent && newStart === 1) {
    // This is a child ordered list
    return previousListId;
  } else if (currentIndent < previousListIndent && (newStart || 1) > 1) {
    // Ordered list is lifting from here.
    return previousListId;
  } else if (!previousListType || previousListType !== node.type.name) {
    // List type (ordered or bullet) is different from the last list node type.
    return nodeDataListIdAttr || uniqueId;
  } else if (node.type.name === "bulletList" && previousListType === "bulletList") {
    // Both last and current list nodes are part of a bullet list.
    return previousListId;
  } else if (!newStart || (!!previousListStart && newStart - previousListStart === 1)) {
    // New ordered list item in the same list.
    return previousListId;
  } else {
    // Default case.
    return nodeDataListIdAttr || uniqueId;
  }
};

// This command is run when the document is getting rendered, we want
// to change the list items to the format which conforms to our schema.
//
// 1. "li"'s "value" attribute will be moved on to "ol"'s "start" attribute.
// 2. If "li"'s "class" attribute has indent class then that will be moved on to "ol"'s "class" attribute.
// 3. "ol" will have "data-list-id" attribute to identify different lists.
// 4. Point #2 and #3 will be applied to "ul" as well.
export const listRendering: RawCommands["listRendering"] =
  () =>
  ({ tr }) => {
    let newStart: number | null = null;

    // Attributes for the previous list item, be it "ol" or "ul".
    const previousListAttributes: ListAttributes = {
      previousListStart: null,
      previousListId: null,
      previousListType: null,
      previousListIndent: 0,
    };

    // Go through each node 1 by 1 and make changes if the node belongs to a list.
    tr.doc.forEach((node, offset) => {
      if (node.type.name === "orderedList") {
        newStart = getStartAttributeForList(node);

        if (newStart) {
          // Change "start" attribute for the current selection if it's an ordered list.
          tr.setNodeAttribute(offset, "start", newStart);

          // Set "value" attribute to null for the listItem node.
          tr.setNodeAttribute(offset + 1, "value", null);
        }
      }

      if (node.type.name === "orderedList" || node.type.name === "bulletList") {
        const listItemNode = node.firstChild;
        if (!listItemNode || listItemNode.type.name !== "listItem") {
          return false;
        }

        const [newClass, indentClass] = getIndentClassForList(node);

        /// Change "class" attribute for the current selection.
        tr.setNodeAttribute(offset, "class", newClass);

        // Remove indent class from the "li" tag.
        const listItemNewClass = getBaseClassWithoutIndent(listItemNode.attrs.class);
        tr.setNodeAttribute(offset + 1, "class", listItemNewClass);

        const currentIndent = getIndentLevelByIndentClass(indentClass);
        const newListId = getIdForList(node, currentIndent, newStart, previousListAttributes);

        // Add "data-list-id" attribute for the current selection.
        tr.setNodeAttribute(offset, "data-list-id", newListId);

        // Save current selection's attribute for the next node's processing.
        previousListAttributes.previousListId = newListId;
        previousListAttributes.previousListStart = newStart || 1;
        previousListAttributes.previousListType = node.type.name;
        previousListAttributes.previousListIndent = currentIndent;
      }
    });

    return true;
  };
