import { WebSocketProvider } from "@bigpi/y-websocket";
import { useMemo } from "react";
import * as Y from "yjs";

/**
 * Provides access to the WebSocket provider and the Yjs document for the board.
 */
export class BoardProvider {
  // *********************************************
  // Private fields
  // *********************************************/
  private _provider: WebSocketProvider | null = null;
  private _yDocument: Y.Doc | null = null;

  // *********************************************
  // Public static methods
  // *********************************************/
  public static useNewBoardProvider = () => {
    const result = useMemo(() => new BoardProvider(), []);

    return result;
  };

  // *********************************************
  // Public properties
  // *********************************************/
  public get provider() {
    return this._provider;
  }

  public set provider(value: WebSocketProvider | null) {
    this._provider = value;
  }

  public get yDocument() {
    return this._yDocument;
  }

  public set yDocument(value: Y.Doc | null) {
    this._yDocument = value;
  }
}
