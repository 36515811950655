const allowedCommonStyles: Array<string> = ["line-height", "margin-bottom", "margin-top"];

const allowedTextStyles: Array<string> = [
  "font-family",
  "font-size",
  "font-style",
  "font-weight",
  "text-transform",
  "text-decoration",
  // "text-decoration-thickness",
  // "text-decoration-style",
  // "text-decoration-color",
];

const allowedHorizontalAlignmentStyles: Array<string> = ["text-align"];

const allowedHighlightStyles: Array<string> = ["background-color"];

const allowedImageStyles: Array<string> = [
  // width and height are disabled since they'll get set to 0 if the image hasn't loaded
  // "height",
  // "width",
];

const allowedIndentStyles: Array<string> = ["margin-left"];

const allowedListStyles: Array<string> = ["list-style-type", "padding"];

const allowedListItemStyles: Array<string> = ["list-style-type"];

const allowedTableStyles: Array<string> = [
  "background-color",
  // This causes word to not show the border on table at all: "border-collapse",
  "border-collapse",
  "border-bottom-color",
  "border-bottom-style",
  "border-bottom-width",
  "border-left-color",
  "border-left-style",
  "border-left-width",
  "border-right-color",
  "border-right-style",
  "border-right-width",
  "border-top-color",
  "border-top-style",
  "border-top-width",
  "border-spacing",
  "color",
  "display",
  "height",
  "margin-right",
  "min-height",
  "min-width",
  "padding-bottom",
  "padding-left",
  "padding-right",
  "padding-top",
  "vertical-align",
  "width",
];

const allowedTableContentStyles: Array<string> = [
  "background-color",
  "border-collapse",
  "border-bottom-color",
  "border-bottom-style",
  "border-bottom-width",
  "border-left-color",
  "border-left-style",
  "border-left-width",
  "border-right-color",
  "border-right-style",
  "border-right-width",
  "border-top-color",
  "border-top-style",
  "border-top-width",
  "border-spacing",
  "color",
  "display",
  "height",
  "min-height",
  "min-width",
  "padding-bottom",
  "padding-left",
  "padding-right",
  "padding-top",
  "vertical-align",
  "width",
];

const allowedTextColorStyles: Array<string> = ["color"];

const allowedBreakStyles: Array<string> = ["page-break-before", "break-before"];

/* eslint-disable sort-keys */
export const AllowedStyles: Record<string, Array<string>> = {
  // Headings
  h1: [
    ...allowedCommonStyles,
    ...allowedTextStyles,
    ...allowedHorizontalAlignmentStyles,
    ...allowedIndentStyles,
    ...allowedTextColorStyles,
    ...allowedHighlightStyles,
  ],
  h2: [
    ...allowedCommonStyles,
    ...allowedTextStyles,
    ...allowedHorizontalAlignmentStyles,
    ...allowedIndentStyles,
    ...allowedTextColorStyles,
    ...allowedHighlightStyles,
  ],
  h3: [
    ...allowedCommonStyles,
    ...allowedTextStyles,
    ...allowedHorizontalAlignmentStyles,
    ...allowedIndentStyles,
    ...allowedTextColorStyles,
    ...allowedHighlightStyles,
  ],
  h4: [
    ...allowedCommonStyles,
    ...allowedTextStyles,
    ...allowedHorizontalAlignmentStyles,
    ...allowedIndentStyles,
    ...allowedTextColorStyles,
    ...allowedHighlightStyles,
  ],
  h5: [
    ...allowedCommonStyles,
    ...allowedTextStyles,
    ...allowedHorizontalAlignmentStyles,
    ...allowedIndentStyles,
    ...allowedTextColorStyles,
    ...allowedHighlightStyles,
  ],
  h6: [
    ...allowedCommonStyles,
    ...allowedTextStyles,
    ...allowedHorizontalAlignmentStyles,
    ...allowedIndentStyles,
    ...allowedTextColorStyles,
    ...allowedHighlightStyles,
  ],

  // Misc
  img: [...allowedCommonStyles, ...allowedHorizontalAlignmentStyles, ...allowedImageStyles],
  mark: [...allowedCommonStyles, ...allowedHighlightStyles, ...allowedTextStyles],

  // Lists
  ol: [...allowedCommonStyles, ...allowedIndentStyles, ...allowedListStyles],
  ul: [...allowedCommonStyles, ...allowedIndentStyles, ...allowedListStyles],
  li: [...allowedCommonStyles, ...allowedTextStyles, ...allowedIndentStyles, ...allowedHighlightStyles, ...allowedListItemStyles],

  // Text
  p: [
    ...allowedCommonStyles,
    ...allowedTextStyles,
    ...allowedHorizontalAlignmentStyles,
    ...allowedIndentStyles,
    ...allowedTextColorStyles,
    ...allowedHighlightStyles,
  ],
  span: [
    ...allowedCommonStyles,
    ...allowedTextStyles,
    ...allowedHorizontalAlignmentStyles,
    ...allowedTextColorStyles,
    ...allowedHighlightStyles,
  ],

  // Table
  table: [...allowedCommonStyles, ...allowedTableStyles, ...allowedHorizontalAlignmentStyles],
  thead: [...allowedCommonStyles, ...allowedTableContentStyles, ...allowedHorizontalAlignmentStyles],
  tbody: [...allowedCommonStyles, ...allowedTableContentStyles, ...allowedHorizontalAlignmentStyles],
  tfoot: [...allowedCommonStyles, ...allowedTableContentStyles, ...allowedHorizontalAlignmentStyles],
  tr: [...allowedCommonStyles, ...allowedTableContentStyles, ...allowedTextStyles, ...allowedHorizontalAlignmentStyles],
  th: [...allowedCommonStyles, ...allowedTableContentStyles, ...allowedTextStyles, ...allowedHorizontalAlignmentStyles],
  td: [...allowedCommonStyles, ...allowedTableContentStyles, ...allowedTextStyles, ...allowedHorizontalAlignmentStyles],
  colgroup: [...allowedCommonStyles, ...allowedTableContentStyles, ...allowedHorizontalAlignmentStyles],
  col: [...allowedCommonStyles, ...allowedTableContentStyles, ...allowedHorizontalAlignmentStyles],

  // Fillers
  br: [...allowedBreakStyles],
};

export const AllowedClasses: Array<string> = [
  // These are disabled as we don't want them to leak to Word via Clipboard
  // "astra-variable", "astra-variable-*"
  //
  // This is a dummy value so we don't end up with an empty RegExp
  "ignoreme-never-gonna-give-you-up",
];

export const AllowedClassesRegExp: RegExp = new RegExp(AllowedClasses.join("|").replace("*", ".*"));

export type TagConversion = {
  from: string;
  to: string;
  classMatches: Array<string>;
};

export const TagConversions: Array<TagConversion> = [
  {
    from: "mark",
    to: "span",
    classMatches: [],
  },
  {
    from: "p",
    to: "h1",
    classMatches: ["heading-1"],
  },
  {
    from: "p",
    to: "h2",
    classMatches: ["heading-2"],
  },
  {
    from: "p",
    to: "h3",
    classMatches: ["heading-3"],
  },
  {
    from: "p",
    to: "h4",
    classMatches: ["heading-4"],
  },
  {
    from: "p",
    to: "h5",
    classMatches: ["heading-5"],
  },
  {
    from: "p",
    to: "h6",
    classMatches: ["heading-6"],
  },
];
