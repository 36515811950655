import {
  DataGridPremium,
  GridRowModel,
  GridRowSelectionModel,
  GridRenderCellParams,
  GridColDef,
  DataGridPremiumProps,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  GridRowId,
  GridGroupNode,
  GridCallbackDetails,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import * as React from "react";
import { useTranslation } from "react-i18next";

import { IFlatHeadline } from "./IFlatHeadline";
import { HeadlineDataGridMenu } from "./HeadlineDataGridMenu";
import "./HeadlineDataGrid.css";

export interface HeadlineDataGridProps {
  expandedRowIds: Array<GridRowId>;
  headlines: Array<IFlatHeadline>;
  onRowExpansionChange: (id: GridRowId, expanded: boolean) => void;
  onRowSelectionModelChange: (params: GridRowSelectionModel) => void;
  onRowDelete: (id: string) => void;
}

export const HeadlineDataGrid = (props: HeadlineDataGridProps) => {
  const { expandedRowIds, headlines, onRowExpansionChange, onRowSelectionModelChange } = props;
  const apiRef = useGridApiRef();
  const { t } = useTranslation();
  const [contextMenuPosition, setContextMenuPosition] = React.useState<{ top: number; left: number } | null>(null);
  const [contextMenuRowId, setContextMenuRowId] = React.useState<string | null>(null);

  const getDataGridColdDef = React.useCallback(
    (): Array<GridColDef<IFlatHeadline>> => [
      {
        field: "likelihood",
        headerName: t("Components.Headline.Grid.LikelihoodHeader"),
        minWidth: 125,
        flex: 1.25,
        sortable: true,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => {
          return `${(params.value * 100).toFixed(2)}%`;
        },
      },
      {
        field: "sentiment",
        headerName: t("Components.Headline.Grid.SentimentHeader"),
        minWidth: 125,
        flex: 1.25,
        sortable: true,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => {
          return params.value;
        },
      },
    ],
    [t],
  );

  const getGroupingColdDef = React.useCallback(
    (): DataGridPremiumProps["groupingColDef"] => ({
      headerName: t("Components.Headline.Grid.ExamplesHeader"),
      minWidth: 350,
      flex: 4.75,
    }),
    [t],
  );

  const getRowId = React.useCallback((row: GridRowModel<IFlatHeadline>) => row.id, []);

  const getTreeDataPath = React.useCallback((row: GridRowModel<IFlatHeadline>) => row.path, []);

  const onRowContextMenu = React.useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();

    if (!event.currentTarget) {
      return;
    }

    // Set the position of the context menu
    setContextMenuPosition(contextMenuPosition === null ? { left: event.clientX - 2, top: event.clientY - 4 } : null);

    // Save the row ID
    setContextMenuRowId(event.currentTarget.getAttribute("data-id"));
  }, []);

  const onRowDelete = React.useCallback(() => {
    if (contextMenuRowId) {
      props.onRowDelete(contextMenuRowId);
    }
  }, [props.onRowDelete, contextMenuRowId]);

  const onMenuClose = React.useCallback(() => {
    setContextMenuPosition(null);
  }, []);

  const isGroupExpandedByDefault = React.useCallback(
    (node: GridGroupNode) => {
      return expandedRowIds.includes(node.id);
    },
    [expandedRowIds],
  );

  React.useEffect(() => {
    apiRef.current.subscribeEvent(
      "rowExpansionChange",
      (params: GridGroupNode, event: { defaultMuiPrevented?: boolean | undefined }, details: GridCallbackDetails<any>) => {
        onRowExpansionChange(params.id, params.childrenExpanded ?? false);
      },
    );
  }, [apiRef, onRowExpansionChange]);

  return (
    <>
      <HeadlineDataGridMenu
        open={contextMenuPosition !== null}
        anchorPosition={contextMenuPosition ?? undefined}
        onClose={onMenuClose}
        onDelete={onRowDelete}
      />
      <DataGridPremium
        apiRef={apiRef}
        classes={{
          cell: "headline-shape-datagrid__cell",
          root: "headline-shape-datagrid",
          row: "headline-shape-datagrid__row",
        }}
        autoHeight
        checkboxSelection
        disableRowSelectionOnClick
        loading={false}
        rows={headlines}
        columns={getDataGridColdDef()}
        onRowSelectionModelChange={onRowSelectionModelChange}
        getRowId={getRowId}
        getRowHeight={() => "auto"}
        isGroupExpandedByDefault={isGroupExpandedByDefault}
        initialState={{
          sorting: {
            sortModel: [{ field: "likelihood", sort: "desc" }],
          },
          filter: {
            filterModel: {
              items: [],
              quickFilterExcludeHiddenColumns: true,
            },
          },
        }}
        columnVisibilityModel={{
          likelihood: false,
        }}
        treeData
        getTreeDataPath={getTreeDataPath}
        groupingColDef={getGroupingColdDef()}
        // Quick filter on values
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          row: {
            onContextMenu: (e) => onRowContextMenu(e),
            style: { cursor: "context-menu" },
          },
        }}
      />
    </>
  );
};

function GridToolbar() {
  return (
    <GridToolbarContainer sx={{ padding: "8px 16px 8px", justifyContent: "right" }}>
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );
}
