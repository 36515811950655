import { Constants, User } from "@bigpi/permission";

/**
 * Expands the selected user list to all users if the Everyone user is present.
 *
 * @param selectedUsers List of users selected and to be expanded.
 * @param allUsers All users in the system.
 *
 * @returns All users if Everyone user is present in selected users, otherwise the selected users.
 */
export function expandUsersIfEveryone<T extends Pick<User, "id">>(
  selectedUsers: Array<T> | undefined | null,
  allUsers: Array<T> | undefined | null,
): Array<T> {
  if (!Array.isArray(selectedUsers) || selectedUsers.length === 0) {
    return [];
  }

  // Check if selectedUsers contains the Everyone user
  const everyone = selectedUsers?.find((user) => user.id === Constants.EVERYONE_USER_ID);

  // If we have an everyone user, return all users except the everyone user
  if (everyone && Array.isArray(allUsers) && allUsers.length > 0) {
    return allUsers.filter((user) => user.id !== Constants.EVERYONE_USER_ID);
  }
  return selectedUsers;
}
