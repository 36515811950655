import { gql } from "@apollo/client";

export const TOPIC_DISCUSSION_SUMMARY_EXAMPLE_QUERY = gql`
  query TopicDiscussionSummaryExample($id: ID!) {
    topicDiscussionSummaryExample(id: $id) {
      id
      topic
      questions
      event
      summary
      detail
    }
  }
`;

export const TOPIC_DISCUSSION_SUMMARY_EXAMPLES_QUERY = gql`
  query TopicDiscussionSummaryExamples($facets: TopicDiscussionSummaryExampleFacetsInput!) {
    topicDiscussionSummaryExamples(facets: $facets) {
      id
      topic
      questions
      questionMentionCounts
      detail
      topicMentionCount
      event
      eventType
      ticker
      category
      eventDate
      speakers {
        fullName
        role
      }
      section
      rating
      score
      summary
      analysisName
    }
  }
`;

export const TOPIC_DISCUSSION_SUMMARY_EXAMPLE_AGGREGATE_QUERY = gql`
  query TopicDiscussionSummaryExampleAggregate($facets: TopicDiscussionSummaryExampleFacetsInput!) {
    bubbleChartScale: topicDiscussionSummaryExampleQuestionMentionCountsAggregate(facets: $facets) {
      min
      max
    }
    barChartScale: topicDiscussionSummaryExampleEventAggregate(facets: $facets) {
      min
      max
    }
  }
`;
