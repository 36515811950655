import { StandardHtmlColors } from "@bigpi/cookbook";
import { Box } from "@mui/material";
import * as d3 from "d3";
import React, { useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { getBarChartPlot } from "Components/Charting/Elements/BarChartPlot";
import { getBubbleChartPlot } from "Components/Charting/Elements/BubbleChartPlot";
import { DataUtils } from "Utils/DataUtils";
import { SvgUtils } from "Utils/SvgUtils";

// css
import "./GroupBubbleChart.css";

// *********************************************
// Props interface
// *********************************************/
interface GroupBubbleChartProps {
  barChartData: Array<Record<string, any>>;
  barChartGroupReducerField: keyof Record<string, any>;
  bubbleChartGroupReducerField: keyof Record<string, any>;
  colorOpacityField?: keyof Record<string, any>;
  colors: Array<string>;
  data: Array<Record<string, any>>;
  dataFormatters?: Record<string, any>;
  facetFields: Record<string, any>;
  facets: Record<string, any>;
  fillReducer?: (colorScale: d3.ScaleOrdinal<string, any, never>) => () => string;
  groupReducers?: Record<string, any>;
  marginLeft: number;
  onAxisSelection: (axisSelection: { xAxis?: string; yGroup?: string; yItem?: string }) => void;
  onExpandedGroupsChange: (expandedGroups: Array<string>) => void;
  plotWidth: number;
  scales: Record<string, any>;
  xDomainValues: Array<string>;
  xField: keyof Record<string, any>;
  yAxisTickFormat?: (value: string) => string;
  yGroupField: keyof Record<string, any>;
  yItemField?: keyof Record<string, any>;
}

// *********************************************
// Common data
// *********************************************/
const groupPlotHeight = 100;
const singleItemHeight = 80;
const marginRight = 10;
const insetLeft = 40;
const insetRight = 40;
const padding = 0.05;
// This decides the bubble sizes
const bubbleRadiusRange = [4, 28];

export const GroupBubbleChart: React.FC<GroupBubbleChartProps> = (props) => {
  const {
    barChartData,
    barChartGroupReducerField,
    bubbleChartGroupReducerField,
    colorOpacityField,
    colors,
    data,
    dataFormatters,
    facetFields,
    facets,
    fillReducer,
    groupReducers,
    marginLeft,
    onAxisSelection,
    onExpandedGroupsChange,
    plotWidth,
    scales,
    xDomainValues,
    xField,
    yAxisTickFormat = (value: string) => value,
    yGroupField,
    yItemField,
  } = props;
  const chartRef = useRef<HTMLDivElement>(null);
  const facetsRef = useRef(facets);
  const facetFieldsRef = useRef(facetFields);
  const xFieldRef = useRef(xField);
  const yGroupFieldRef = useRef(yGroupField);
  const yItemFieldRef = useRef(yItemField);
  const barChartDataRef = useRef(barChartData);
  const dataRef = useRef(data);
  const xDomainValuesRef = useRef(xDomainValues);
  const plotWidthRef = useRef(plotWidth);
  const scalesRef = useRef(scales);
  const fillReducerRef = useRef(fillReducer);
  const marginLeftRef = useRef(marginLeft);
  const bubbleChartGroupReducerFieldRef = useRef(bubbleChartGroupReducerField);
  const barChartGroupReducerFieldRef = useRef(barChartGroupReducerField);

  const { t } = useTranslation();

  useEffect(() => {
    facetsRef.current = facets;
    facetFieldsRef.current = facetFields;
    xFieldRef.current = xField;
    yGroupFieldRef.current = yGroupField;
    yItemFieldRef.current = yItemField;
    xDomainValuesRef.current = xDomainValues;
    barChartDataRef.current = barChartData;
    dataRef.current = data;
    plotWidthRef.current = plotWidth;
    scalesRef.current = scales;
    fillReducerRef.current = fillReducer;
    marginLeftRef.current = marginLeft;
    bubbleChartGroupReducerFieldRef.current = bubbleChartGroupReducerField;
    barChartGroupReducerFieldRef.current = barChartGroupReducerField;
  }, [
    module,
    facets,
    facetFields,
    yItemField,
    xDomainValues,
    data,
    barChartDataRef,
    plotWidth,
    scales,
    xField,
    yGroupField,
    fillReducer,
    marginLeft,
    bubbleChartGroupReducerField,
    barChartGroupReducerField,
  ]);

  useEffect(() => {
    if (chartRef.current && chartRef.current.innerHTML) {
      chartRef.current.innerHTML = "";
    }
    chartRef.current?.appendChild(generatePlotElements());
  }, [data, xDomainValues, facets, scales, yItemField, colors]);

  // *********************************************
  // Render
  // *********************************************/
  return (
    <Box>
      <div ref={chartRef} className="group-bubble-chart"></div>
    </Box>
  );

  // *********************************************
  // Callbacks
  // *********************************************/
  function generatePlotElements() {
    // Sort by group field
    const charts: Array<HTMLElement> = [];
    const sortedData = [...dataRef.current].sort((a: Record<string, any>, b: Record<string, any>) =>
      d3.ascending(a[yGroupFieldRef.current], b[yGroupFieldRef.current]),
    );

    // Bar chart
    const barChartElement = generateBarChart();
    charts.push(barChartElement);

    // Color scale for Group elements
    const groups = DataUtils.getGroupedItems(sortedData, yGroupFieldRef.current);
    const groupValues = [...groups.keys()];
    const groupColorScale = d3.scaleOrdinal(groupValues).range(colors);

    // Generates group elements
    if (yItemFieldRef.current) {
      for (let [groupKey, groupValue] of groups) {
        // Group chart element
        const groupChart = getBubbleChartPlot(
          groupValue,
          {
            grid: false,
            height: groupPlotHeight,
            insetLeft,
            insetRight,
            marginBottom: 50,
            marginLeft: marginLeftRef.current,
            marginRight,
            marginTop: 50,
            padding,
            width: plotWidthRef.current,
            y: {
              tickFormat: yAxisTickFormat,
            },
          },
          onBubbleClick,
          onYAxisLabelClick,
          {
            domain: scalesRef.current.bubbleChartScale,
            range: bubbleRadiusRange,
          },
          xDomainValuesRef.current,
          xFieldRef.current,
          yGroupFieldRef.current,
          groupReducers?.bubbleChartGroupReducer || getPlotGroupReducer,
          fillReducerRef.current ? fillReducerRef.current(groupColorScale) : getFillReducer(groupColorScale),
        );

        // Item chart element
        groupValue = dataFormatters && dataFormatters.itemsFormatter ? dataFormatters.itemsFormatter(groupValue) : groupValue;
        const itemCollection = DataUtils.getGroupedItems(groupValue, yItemFieldRef.current);
        const itemsCount = [...itemCollection.keys()].length;
        const itemsChart = getBubbleChartPlot(
          groupValue,
          {
            grid: false,
            height: singleItemHeight * itemsCount,
            insetLeft,
            insetRight,
            marginBottom: 50,
            marginLeft: marginLeftRef.current,
            marginRight,
            marginTop: 50,
            padding,
            width: plotWidthRef.current,
            y: {
              tickFormat: yAxisTickFormat,
            },
          },
          onBubbleClick,
          onYAxisLabelClick,
          {
            domain: scalesRef.current.bubbleChartScale,
            range: bubbleRadiusRange,
          },
          xDomainValuesRef.current,
          xFieldRef.current,
          yItemFieldRef.current,
          groupReducers?.bubbleChartGroupReducer || getPlotGroupReducer,
          fillReducerRef.current ? fillReducerRef.current(groupColorScale) : getFillReducer(groupColorScale),
        );

        // Add group & item chart elements
        const open = (facetsRef.current[facetFieldsRef.current.expandedGroupsFacetField] || []).includes(groupKey);
        const details = document.createElement("div");
        details.classList.add("details");
        if (open) {
          details.setAttribute("open", "");
        }

        const summary = document.createElement("div");
        summary.classList.add("summary");
        summary.addEventListener("pointerup", (e) => onGroupClick(e, groupKey));
        summary.append(groupChart);

        if (open) {
          const div = document.createElement("div");
          div.append(itemsChart);

          details.append(summary, div);
        } else {
          details.append(summary);
        }

        charts.push(details);
      }
    } else {
      const groupPlotElement = generateGroupPlot(sortedData, groupColorScale, groupValues.length);
      charts.push(groupPlotElement);
    }

    const div = document.createElement("div");
    charts.forEach((element) => {
      if (element) {
        div.append(element);
      }
    });

    return div;
  }

  /**
   * Generates bar chart plot element.
   *
   * @returns Bar chart plot element
   */
  function generateBarChart(): HTMLElement {
    const chart = getBarChartPlot(
      "vertical",
      barChartDataRef.current,
      {
        selectedValues: [facetsRef.current[facetFieldsRef.current.xAxisFacetField]],
      },
      {
        className: "group-bubble-chart__bar-chart-plot",
        grid: false,
        height: 300,
        marginBottom: 40,
        marginLeft: marginLeftRef.current,
        insetLeft: 10,
        width: plotWidthRef.current,
        x: {
          label: "",
          domain: xDomainValuesRef.current,
          tickPadding: 20,
          tickSize: 0,
        },
        y: {
          ticks: 2,
          label: t("Components.Charts.Events"),
          domain: scalesRef.current.barChartScale,
        },
      },
      xFieldRef.current,
      "",
      "",
      groupReducers?.barChartGroupReducer || getBarChartPlotGroupReducer,
      undefined,
      {
        fill: getBarChartFill,
        insetLeft: 10,
        insetRight: 10,
      },
      {},
    );
    // Handles click on X axis label
    d3.select(chart).on("pointerup", onXAxisLabelClick).style("cursor", "default");
    return chart as HTMLElement;
  }

  function getBarChartFill(dataItem: Record<string, any>) {
    const selectedValues = facetsRef.current[facetFieldsRef.current.xAxisFacetField];
    if (!selectedValues || selectedValues === dataItem[xFieldRef.current]) {
      return StandardHtmlColors.patina;
    }

    return StandardHtmlColors.gray20;
  }

  /**
   * Generates plot elements
   *
   * @param plotData Plot data
   * @param groupColorScale Group color scale
   * @param groupCount
   * @returns
   */
  function generateGroupPlot(plotData: Array<Record<string, any>>, groupColorScale: any, groupCount: number): HTMLElement {
    return getBubbleChartPlot(
      plotData,
      {
        grid: false,
        height: groupCount * groupPlotHeight,
        insetLeft,
        insetRight,
        marginBottom: 50,
        marginLeft: marginLeftRef.current,
        marginRight,
        marginTop: 50,
        padding,
        width: plotWidthRef.current,
        y: {
          tickFormat: yAxisTickFormat,
        },
      },
      onBubbleClick,
      onYAxisLabelClick,
      {
        domain: scalesRef.current.bubbleChartScale,
        range: bubbleRadiusRange,
      },
      xDomainValuesRef.current,
      xFieldRef.current,
      yGroupFieldRef.current,
      groupReducers?.bubbleChartGroupReducer || getPlotGroupReducer,
      fillReducerRef.current ? fillReducerRef.current(groupColorScale) : getFillReducer(groupColorScale),
    );
  }

  /**
   * Fill reducer for the plot, which enables to get the accurate color derived from the group of the data.
   *
   * @param colorScale Color scale which gives the color for the group
   * @returns
   */
  function getFillReducer(colorScale: d3.ScaleOrdinal<string, any, never>) {
    return (channelData: Array<any>) => {
      const xFacetValue = facetsRef.current[facetFieldsRef.current.xAxisFacetField];
      const yGroupFacetValue = facetsRef.current[facetFieldsRef.current.yGroupFacetField];
      const yItemFacetValue = facetsRef.current[facetFieldsRef.current.yItemFacetField];
      const groupValue = channelData[0][yGroupFieldRef.current];
      const groupColor = colorScale(groupValue);
      const score = colorOpacityField ? d3.max(channelData, (d) => d[colorOpacityField]) : 10;
      const getColorWithOpacity = getColorOpacityScale(groupColor);
      const colorWithOpacity = getColorWithOpacity(score);

      if (xFacetValue && yGroupFacetValue && yItemFacetValue) {
        return isXAxisSelected(channelData) && isYGroupSelected(channelData) && isYItemSelected(channelData)
          ? colorWithOpacity
          : StandardHtmlColors.gray20;
      } else if (xFacetValue && !yGroupFacetValue && !yItemFacetValue) {
        return isXAxisSelected(channelData) ? colorWithOpacity : StandardHtmlColors.gray20;
      } else if (xFacetValue && yGroupFacetValue && !yItemFacetValue) {
        return isXAxisSelected(channelData) && isYGroupSelected(channelData) ? colorWithOpacity : StandardHtmlColors.gray20;
      } else if (!xFacetValue && yGroupFacetValue) {
        return isYGroupSelected(channelData) ? colorWithOpacity : StandardHtmlColors.gray20;
      } else if (!xFacetValue && yItemFacetValue) {
        // This highlights the item level & group level bubbles when the item is selected
        return isYItemSelected(channelData) ? colorWithOpacity : StandardHtmlColors.gray20;
      }
      return colorWithOpacity;
    };
  }

  /**
   * Checks whether x-axis is selected
   *
   * @param channelData Data to check for x-axis selection
   * @returns
   */
  function isXAxisSelected(channelData: Array<Record<string, any>>) {
    const isSelected = channelData.find(
      (dataItem) => dataItem[xFieldRef.current] === facetsRef.current[facetFieldsRef.current.xAxisFacetField],
    );
    return !!isSelected;
  }

  /**
   * Checkes whether yGroup is selected
   *
   * @param channelData Data to check for yGroup selection
   * @returns
   */
  function isYGroupSelected(channelData: Array<Record<string, any>>) {
    const isSelected = channelData.find(
      (dataItem) => dataItem[yGroupFieldRef.current] === facetsRef.current[facetFieldsRef.current.yGroupFacetField],
    );
    return !!isSelected;
  }

  /**
   * Checkes whether yItem is selected
   *
   * @param channelData Data to check for yItem selection
   * @returns
   */
  function isYItemSelected(channelData: Array<Record<string, any>>) {
    const isSelected = channelData.find(
      (dataItem) => dataItem[yItemFieldRef.current as string] === facetsRef.current[facetFieldsRef.current.yItemFacetField],
    );
    return !!isSelected;
  }

  /**
   * Gives the function to get the color with opacity
   *
   * @param color Color for which fills with opacity
   * @returns
   */
  function getColorOpacityScale(color: string) {
    return (rating: number) =>
      d3
        .color(color)
        ?.copy({ opacity: Math.round(rating) / 10 })
        .toString();
  }

  // *********************************************
  // Handlers
  // *********************************************/
  /**
   * Handler for the x-axis label click
   *
   * @param e Event object
   */
  function onXAxisLabelClick(e: PointerEvent) {
    const value = SvgUtils.getTextFromEventTarget(e.target) ?? undefined;

    onAxisSelection({
      xAxis: value,
      yGroup: "",
      yItem: "",
    });
  }

  /**
   * Handler for the group to expand/collapse
   *
   * @param e Event object
   * @param groupKey Group key
   * @returns
   */
  function onGroupClick(e: PointerEvent, groupKey: string) {
    if (!(e.target instanceof HTMLDivElement)) {
      return;
    }
    const expandedGroups = [...(facetsRef.current[facetFieldsRef.current.expandedGroupsFacetField] || [])];
    if (expandedGroups.includes(groupKey)) {
      expandedGroups.splice(expandedGroups.indexOf(groupKey), 1);
    } else {
      expandedGroups.push(groupKey);
    }

    onExpandedGroupsChange(expandedGroups);
  }

  /**
   * Handles bubble click
   *
   * @param e Event object
   * @param result Result object
   * @param yField y-axis field
   * @returns
   */
  function onBubbleClick(e: PointerEvent, result: Record<string, unknown>, yField: string) {
    const xValue = result.x;
    const yValue = result.y;
    const dataItem = dataRef.current.find((dataItem) => dataItem[xFieldRef.current] === xValue && dataItem[yField] === yValue);
    if (!xValue || !yValue || !dataItem) {
      return;
    }
    const selectedValues = {
      xAxis: dataItem[xFieldRef.current],
      yGroup: dataItem[yGroupFieldRef.current],
      yItem: "",
    };

    // Verifies whether the bubble is from item level
    if (yField === yItemFieldRef.current) {
      selectedValues.yItem = dataItem[yItemFieldRef.current];
    }
    onAxisSelection(selectedValues);

    e.stopPropagation();
    e.preventDefault();
  }

  /**
   * Handles the click event on the y-axis labels.
   *
   * @param event Click event
   * @param label Label where the click event was triggered
   * @param yField Y-axis field to identify group or item
   */
  function onYAxisLabelClick(event: PointerEvent, label: string, yField: string) {
    const selectedValues = {
      xAxis: "",
      yGroup: "",
      yItem: "",
    };

    // Verifies whether the bubble is from item level
    if (yField === yItemFieldRef.current) {
      selectedValues.yItem = label;
    } else {
      selectedValues.yGroup = label;
    }

    onAxisSelection(selectedValues);

    event.stopPropagation();
    event.preventDefault();
  }

  /**
   * Reduces the data to be data count/groupReducerField count
   * @returns Reduced value
   */
  function getPlotGroupReducer(channelData: Array<any>) {
    if (channelData.length === 0) {
      return 0;
    }
    const groupCount = [...DataUtils.getGroupedItems(channelData, bubbleChartGroupReducerFieldRef.current).keys()].length;
    return channelData.length / groupCount;
  }

  /**
   * Reduces the data to the length of the barChartGroupReducerField field
   *
   * @returns Reduced value
   */
  function getBarChartPlotGroupReducer(channelData: Array<any>) {
    if (channelData.length === 0) {
      return 0;
    }
    return [...DataUtils.getGroupedItems(channelData, barChartGroupReducerFieldRef.current).keys()].length;
  }
};
