import { IDateRangeOption } from "../DateRange/IDateRangeOption.js";

export const CONFIG_KEY_BASE_DOCUMENT_STYLESHEET: string = "bistro-document-stylesheet";
export const CONFIG_KEY_ORGANIZATION_DOCUMENT_STYLESHEET: string = "org-document-stylesheet";
export const CONFIG_KEY_ORGANIZATION_PREFERENCES: string = "bistro-preferences";
export const CONFIG_KEY_ORGANIZATION_THEMES: string = "bistro-default-themes";
export const CONFIG_KEY_ORGANIZATION_TRANSLATIONS_PREFIX: string = "bistro-translations";

export interface IOrganizationPreferences {
  ontologyName: string;
  tickerSymbol?: string;
  customDateRanges?: Array<IDateRangeOption>;
  isChatEnabled?: boolean;
}
