import { SelectableText } from "@bigpi/cutlery";
import { Box, BoxProps } from "@mui/material";
import { useTranslation } from "react-i18next";

import { getNameWithOccupationTitle } from "BoardComponents/Utils/TranscriptUtils";
import { TranscriptItem } from "GraphQL/Generated/Apollo";

export interface ITranscriptItemViewProps extends BoxProps {
  transcriptItem: TranscriptItem;
}

export function TranscriptItemView(props: ITranscriptItemViewProps) {
  const { transcriptItem, ...rest } = props;

  const { t } = useTranslation();

  return (
    <Box {...rest}>
      <SelectableText variant="body1" mt={2} fontWeight="500">
        {getNameWithOccupationTitle(t, transcriptItem.fullName, transcriptItem.occupationTitles)}
      </SelectableText>
      {transcriptItem.speech.map((speechItem, index) => {
        return (
          <SelectableText key={index} variant="body2" sx={{ mb: 1 }}>
            {speechItem}
          </SelectableText>
        );
      })}
    </Box>
  );
}
