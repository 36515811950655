import { HIT_TEST_MARGIN, StateNode, TLEventHandlers } from "@tldraw/tldraw";

import { INTERACTIVE_SHAPES } from "../Utils/InteractToolUtils";
import { getHitShapeOnCanvasPointerDown } from "../Utils/getHitShapeOnCanvasPointerDown";

export class Idle extends StateNode {
  static override id = "idle";

  onEnter = () => {
    this.editor.setHoveredShape(null);
    this.editor.updateInstanceState({ cursor: { type: "default", rotation: 0 } }, { ephemeral: true });
  };

  onPointerMove: TLEventHandlers["onPointerMove"] = () => {
    this._setHoveredShape();
  };

  onPointerDown: TLEventHandlers["onPointerDown"] = (info) => {
    switch (info.target) {
      case "canvas": {
        // Check to see if we hit any shape under the pointer; if so,
        // handle this as a pointer down on the shape instead of the canvas
        const hitShape = getHitShapeOnCanvasPointerDown(this.editor);
        if (hitShape && !hitShape.isLocked) {
          this.onPointerDown({
            ...info,
            shape: hitShape,
            target: "shape",
          });
          return;
        }

        this.parent.transition("pointing_canvas", info);
        break;
      }
      case "shape": {
        if (this.editor.isShapeOrAncestorLocked(info.shape)) {
          this.parent.transition("pointing_canvas", info);
          break;
        }

        this.parent.transition("pointing_shape", info);
        break;
      }
    }
  };

  private _setHoveredShape = () => {
    const zoomLevel = this.editor.getZoomLevel();
    const hitShape = this.editor.getShapeAtPoint(this.editor.inputs.currentPagePoint, {
      hitInside: false,
      margin: HIT_TEST_MARGIN / zoomLevel,
    });

    if (!hitShape) {
      return;
    }

    let parentShape;
    if (hitShape.parentId) {
      parentShape = this.editor.getShape(hitShape.parentId);
    }

    // Verify that the shape/parent shape is interactive
    if (INTERACTIVE_SHAPES.includes(hitShape.type) || (parentShape && INTERACTIVE_SHAPES.includes(parentShape?.type))) {
      return this.editor.setHoveredShape(hitShape.id);
    } else {
      return this.editor.setHoveredShape(null);
    }
  };
}
