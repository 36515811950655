import {
  extractChatMessageCandidateUsers,
  replaceChatMessageUserNamesWithMentions,
  ChatUser,
  MENTION_CLASS_NAME,
} from "@bigpi/cookbook";
import { generateJSON } from "@tiptap/core";
import { DOMSerializer, Slice, Node } from "@tiptap/pm/model";
import { Plugin, PluginKey } from "@tiptap/pm/state";

import { getCommonEditorExtensions } from "../EditorExtensions.js";

export const ExtractMentionsPluginKey = new PluginKey("extractMentions");

export const extractMentionsPlugin = (users: Array<ChatUser>) => {
  return new Plugin({
    key: ExtractMentionsPluginKey,
    props: {
      handlePaste: (view, _event, slice) => {
        const { state, dispatch } = view;
        const { schema, tr } = state;

        const serializer = DOMSerializer.fromSchema(schema);
        const fragment = serializer.serializeFragment(slice.content);
        const temporaryDiv = document.createElement("div");
        temporaryDiv.appendChild(fragment);

        // Take clipboard content
        const clipboardHtml = temporaryDiv.innerHTML;
        let clipboardTextContent = temporaryDiv.textContent;

        let resultMessage = clipboardHtml;

        // Extract mentions from the clipboard content
        if (clipboardTextContent) {
          // Replace non-breaking spaces with regular spaces
          clipboardTextContent = clipboardTextContent.replaceAll("\u00a0", " ");

          const candidateUsers = extractChatMessageCandidateUsers(clipboardTextContent, users);
          resultMessage = replaceChatMessageUserNamesWithMentions(clipboardHtml, candidateUsers, MENTION_CLASS_NAME);
        }

        // Generate editor JSON content
        const jsonContent = generateJSON(resultMessage, getCommonEditorExtensions());

        // Create nodes from the extracted content
        const content = jsonContent.content;
        const nodes = content.map((node: Node) => schema.nodeFromJSON(node));

        // Create a new transaction to insert the nodes
        const fragmentToInsert = schema.nodeFromJSON({
          type: "doc",
          content: nodes.map((node: Node) => node.toJSON()),
        }).content;

        const sliceToInsert = new Slice(fragmentToInsert, 0, 0);

        // Replace the selection with the new node
        tr.replaceSelection(sliceToInsert);
        dispatch(tr);
        return true;
      },
    },
  });
};
