import { FileSourceType } from "@bigpi/cookbook";
import {
  Badge,
  Box,
  Checkbox,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from "@mui/material";
import { FolderCopy, ExpandMore, ExpandLess } from "@mui/icons-material";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { FileItemCompact } from "Components/FileItem/FileItemCompact";
import { FileItemStandard } from "Components/FileItem/FileItemStandard";
import { formatFileCreatedAt } from "Utils/FileUtils";
import { FileBundleViewType, useFilesQuery } from "GraphQL/Generated/Apollo";
import { useValue } from "@tldraw/tldraw";

export interface ILibraryBundleItemProps {
  bundleCreatedAt: string;
  bundleId: string;
  bundleName: string;
  isSelectable?: boolean;
  listItem: typeof FileItemStandard | typeof FileItemCompact;
  listItemProps?: {
    secondaryAction: (fileId: string) => React.ReactNode;
  };
  onMouseEnter?: (event: React.MouseEvent<HTMLElement>) => void;
  onMouseLeave?: () => void;
  onSelectionChange?: (type: FileBundleViewType, id: string) => void;
  selectedFileIds?: Array<string>;
}

export function LibraryBundleItem(props: ILibraryBundleItemProps) {
  const {
    bundleCreatedAt,
    bundleId,
    bundleName,
    isSelectable,
    listItem: ListItemComponent,
    listItemProps,
    onMouseEnter,
    onMouseLeave,
    onSelectionChange,
    selectedFileIds = [],
  } = props;
  const [isBundleExpanded, setIsBundleExpanded] = useState<boolean>(false);
  const { t } = useTranslation();
  const theme = useTheme();

  const { data: bundleFiles } = useFilesQuery({
    variables: {
      limit: 100,
      filters: {
        bundleId,
      },
    },
  });

  const bundleFileIds = useValue(
    "bundleFileIds",
    () => {
      return bundleFiles?.files?.map((file) => file.id) || [];
    },
    [bundleFiles],
  );

  const isChecked = useValue(
    "isChecked",
    () => {
      return bundleFileIds.length > 0 && bundleFileIds.every((fileId) => selectedFileIds.includes(fileId));
    },
    [selectedFileIds, bundleFileIds],
  );

  const onBundleSelectionChange = useCallback(() => {
    if (onSelectionChange) {
      onSelectionChange(FileBundleViewType.Bundle, bundleId);
      bundleFileIds.forEach((fileId) => onSelectionChange(FileBundleViewType.File, fileId));
    }
  }, [bundleId, bundleFileIds, onSelectionChange]);

  return (
    <ListItem
      disablePadding
      key={bundleId}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      sx={{
        alignItems: "flex-start",
        border: isBundleExpanded ? `solid 1px ${theme.palette.divider}` : "",
        borderRadius: 1,
        flexDirection: "column",
        mb: 1,
        mt: 1,
        ":hover": {
          backgroundColor: "var(--astra-color-gray15)",
        },
      }}
    >
      <ListItemButton
        sx={{
          backgroundColor: isChecked ? "var(--astra-color-blue15)" : "var(--astra-color-gray7)",
          borderRadius: 1,
          cursor: "default",
          width: "100%",
        }}
        disableRipple
      >
        <ListItemIcon>
          {isSelectable || isChecked ? (
            <Checkbox checked={isChecked} onChange={onBundleSelectionChange} sx={{ padding: 0 }} />
          ) : (
            <IconButton sx={{ padding: 0 }}>
              <Badge
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                badgeContent={bundleFiles?.fileAggregate.count}
                color="primary"
              >
                <FolderCopy />
              </Badge>
            </IconButton>
          )}
        </ListItemIcon>

        <ListItemText
          onClick={() => setIsBundleExpanded(!isBundleExpanded)}
          primary={bundleName}
          primaryTypographyProps={{ fontWeight: "500" }}
          secondary={
            <Box sx={{ display: "flex", flexDirection: "column" }} component="span">
              <Box sx={{ display: "flex", flexWrap: "wrap" }} component="span">
                {formatFileCreatedAt(bundleCreatedAt, t)}
              </Box>
            </Box>
          }
          sx={{ cursor: "pointer" }}
        />
        <IconButton onClick={() => setIsBundleExpanded(!isBundleExpanded)}>
          {isBundleExpanded ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </ListItemButton>
      {isBundleExpanded && bundleFiles?.files && (
        <List sx={{ width: "100%", pl: "60px" }}>
          {bundleFiles?.files.map((bundleFile) => {
            return (
              <ListItemComponent
                checked={selectedFileIds.includes(bundleFile.id) || isChecked}
                fileCreatedAt={bundleFile.createdAt}
                fileId={bundleFile.id}
                fileMimeType={bundleFile.mimeType}
                fileName={bundleFile.name}
                fileSourceType={FileSourceType.Library}
                key={bundleFile.id}
                onSelectionChange={(id) => onSelectionChange && onSelectionChange(FileBundleViewType.File, bundleFile.id)}
                {...listItemProps}
              />
            );
          })}
        </List>
      )}
    </ListItem>
  );
}
