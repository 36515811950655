// @ts-expect-error @types/path-data not found
import * as PathData from "path-data";

import { ChartIcons } from "./ChartIcons";

import { path as ChartLineUpDownPath } from "./ChartLineUpDownPath";
import { path as ChartSimpleSolidPath } from "./ChartSimpleSolidPath";
import { path as DollarSignSolidPath } from "./DollarSignSolidPath";
import { path as QuantativePath } from "./QuantativePath";
import { path as SackDollarSolidPath } from "./SackDollarSolidPath";
import { path as ScrollSolidPath } from "./ScrollSolidPath";

const paths: Record<string, string> = {
  [ChartIcons.ChartLineUpDown]: ChartLineUpDownPath,
  [ChartIcons.ChartSimpleSolid]: ChartSimpleSolidPath,
  [ChartIcons.DollarSignSolid]: DollarSignSolidPath,
  [ChartIcons.Quantative]: QuantativePath,
  [ChartIcons.SackDollarSolid]: SackDollarSolidPath,
  [ChartIcons.ScrollSolid]: ScrollSolidPath,
};

const type: Record<string, string> = { M: "moveTo", L: "lineTo", C: "bezierCurveTo", Z: "closePath" };

export function getChartIcon(pathName: string) {
  return {
    // Using any here because the type of context is not known
    draw(context: any) {
      const segments = PathData.parse(paths[pathName], { normalize: true });
      segments.forEach((segment: any) => {
        context[type[segment.type]](...segment.values);
      });
    },
  };
}
