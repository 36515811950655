import { useContext } from "react";

import { ShapeLifecycleEventEmitterContext } from "./ShapeLifecycleEventEmitterContext";

export const useShapeLifecycleEventEmitter = () => {
  const result = useContext(ShapeLifecycleEventEmitterContext);
  if (!result) {
    throw new Error("No ShapeLifecycleEventEmitter found in context");
  }
  return result;
};
