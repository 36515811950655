import { IDateRange } from "@bigpi/cookbook";
import { useEffect, useState } from "react";

import { ITopicDiscussionSummaryExampleResult, ITopicDiscussionExampleResult } from "BoardComponents/BoardDatastore";
import { DataUtils } from "Utils/DataUtils";

import { ThemeOverviewChartProps, TopicDiscussionSummaryOverviewChart } from "./TopicDiscussionSummaryOverviewChart";

export type TopicDiscussionSummaryOverviewChartContainerProps = {
  // TODO: fix this specific type
  fetchData: any;
  selectedTheme: string;
  selectedTicker: string;
  selectedDate: IDateRange | undefined;
} & Omit<ThemeOverviewChartProps, "priorEvents">;

const GROUP_FIELD = "eventDate";

export function TopicDiscussionSummaryOverviewChartContainer(props: TopicDiscussionSummaryOverviewChartContainerProps) {
  const { fetchData, selectedDate, selectedTheme, selectedTicker, data, ...rest } = props;

  const [latestData, setLatestData] = useState<Array<ITopicDiscussionSummaryExampleResult | ITopicDiscussionExampleResult>>(data);
  const [priorEvents, setPriorEvents] = useState<Array<string>>([]);

  useEffect(() => {
    const groupKeys = DataUtils.getGroupedKeys(data, GROUP_FIELD);
    // Target is to show upto 8 evnets. If the data has less than that, we fetch prior events upto the selected end date
    if (groupKeys.length < 8 && selectedDate && (selectedDate.from || selectedDate.to)) {
      fetchData({
        variables: {
          facets: {
            // Loads prior events upto the selected end date
            eventDate: { from: "", to: selectedDate.to },
            topic: selectedTheme ? [selectedTheme] : undefined,
            ticker: selectedTicker ? [selectedTicker] : [],
            category: undefined,
            section: undefined,
          },
        },
      }).then((response: any) => {
        const responseData = response.data.topicDiscussionSummaryExamples || response.data.topicDiscussionExamples;
        const responseDataGroupKeys = DataUtils.getGroupedKeys(responseData, GROUP_FIELD);
        // Take the prior events
        const priorEvents = responseDataGroupKeys.filter((key) => !groupKeys.includes(key));
        setPriorEvents(priorEvents);
        setLatestData(responseData);
      });
    } else {
      setPriorEvents([]);
      setLatestData(data);
    }
  }, [data, selectedTheme, selectedTicker, selectedDate]);

  return <TopicDiscussionSummaryOverviewChart data={latestData} {...rest} priorEvents={priorEvents} />;
}
