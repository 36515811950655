import { mergeAttributes } from "@tiptap/react";
import { ListItem as TipTapListItem } from "@tiptap/extension-list-item";

import { getClassAttribute, getNumericValueAttribute } from "../../Utils/ExtensionUtil.js";
import { clearListNodes } from "./ClearListNodesCommand.js";
import { liftListItem } from "./LiftListItemCommand.js";
import { listBackspace } from "./ListBackspaceCommand.js";
import { listRendering } from "./ListRenderingCommand.js";
import { sinkListItem } from "./SinkListItemCommand.js";
import { splitListItem } from "./SplitListItemCommand.js";
import { toggleList } from "./ToggleListCommand.js";
import { wrapInList } from "./WrapInListCommand.js";

export const ListItem = TipTapListItem.extend({
  // content must include exactly one node of type "paragraph", "title" or "heading"
  content: "(paragraph|title|heading)",

  addAttributes() {
    return {
      ...this.parent?.(),
      class: getClassAttribute(),
      value: getNumericValueAttribute("value", null),
    };
  },

  // "onCreate" runs when the document rendering is completed,
  // we would want to convert all the list items as per our
  // custom schema at this point. This takes care of of both
  // ordered lists and bullet lists.
  onCreate() {
    this.editor.chain().listRendering().run();
  },

  renderHTML({ HTMLAttributes, node }) {
    // We are adding the "title" and "heading" classes to the "li" tag
    // to apply the correct text style for the list item marker.
    let textStyleClass = null;
    if (node.firstChild?.type.name === "title") {
      textStyleClass = "title";
    } else if (node.firstChild?.type.name === "heading") {
      textStyleClass = `heading-${node.firstChild.attrs.level}`;
    }

    // We are moving the indent class on the "ol" tag from "li"
    // tag while rendering lists, so we don't need the same class
    // on the "li" tag.
    const newClass =
      HTMLAttributes.class && HTMLAttributes.class.length
        ? HTMLAttributes.class
            .split(" ")
            .concat(textStyleClass)
            .filter((c: string) => c !== null && !c.startsWith("indent-"))
            .join(" ")
        : textStyleClass;

    // "value" attribute is required till here for ordered list extension's
    // render logic to work, we need to remove it once that is done as it
    // would mess up the list update commands
    HTMLAttributes.value = null;
    HTMLAttributes.class = newClass;

    return ["li", mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },

  addCommands() {
    // Registering our custom commands for "listItem". These commands
    // are now callable from everywhere.
    return {
      ...this.parent?.(),
      clearListNodes,
      liftListItem,
      listBackspace,
      listRendering,
      sinkListItem,
      splitListItem,
      toggleList,
      wrapInList,
    };
  },

  addKeyboardShortcuts() {
    // Custom key bindings for "listItem".
    return {
      ...this.parent?.(),
      Enter: () => this.editor.commands.splitListItem(this.name),
      Tab: () => this.editor.commands.sinkListItem(this.name),
      "Shift-Tab": () => this.editor.commands.liftListItem(this.name),
      Backspace: () => this.editor.commands.listBackspace(this.name),
    };
  },
});
