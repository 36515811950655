import { IAnalysisFieldConfig, AnalysisFieldTypeEnum } from "@bigpi/cookbook";

export const topicDiscussionSummaryAnalysisFieldsConfig: Array<IAnalysisFieldConfig> = [
  {
    field: "category",
    label: "Components.Analyses.TopicDiscussionSummaryAnalysis.Fields.Category",
    type: AnalysisFieldTypeEnum.String,
    isSortEnabled: true,
    isSelectAllEnabled: true,
  },
  {
    field: "eventDate",
    label: "Components.Analyses.TopicDiscussionSummaryAnalysis.Fields.EventDate",
    type: AnalysisFieldTypeEnum.Date,
  },
  {
    field: "eventType",
    label: "Components.Analyses.TopicDiscussionSummaryAnalysis.Fields.EventType",
    type: AnalysisFieldTypeEnum.String,
    isSortEnabled: true,
    isSelectAllEnabled: true,
  },
  {
    field: "section",
    label: "Components.Analyses.TopicDiscussionSummaryAnalysis.Fields.Section",
    type: AnalysisFieldTypeEnum.String,
    isSortEnabled: true,
    isSelectAllEnabled: true,
  },
  {
    field: "ticker",
    label: "Components.Analyses.TopicDiscussionSummaryAnalysis.Fields.Ticker",
    type: AnalysisFieldTypeEnum.String,
    isSortEnabled: true,
    isSelectAllEnabled: true,
  },
  {
    field: "topic",
    label: "Components.Analyses.TopicDiscussionSummaryAnalysis.Fields.Topic",
    type: AnalysisFieldTypeEnum.String,
    isSortEnabled: true,
    isSelectAllEnabled: true,
  },
];
