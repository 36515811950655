export enum OccupationTitle {
  Other = "Other",

  // C-suite
  ChiefAdministrativeOfficer = "ChiefAdministrativeOfficer",
  ChiefDiversityOfficer = "ChiefDiversityOfficer",
  ChiefExecutiveOfficer = "ChiefExecutiveOfficer",
  ChiefFinancialOfficer = "ChiefFinancialOfficer",
  ChiefInformationOfficer = "ChiefInformationOfficer",
  ChiefInformationSecurityOfficer = "ChiefInformationSecurityOfficer",
  ChiefInnovationOfficer = "ChiefInnovationOfficer",
  ChiefLegalOfficer = "ChiefLegalOfficer",
  ChiefMarketingOfficer = "ChiefMarketingOfficer",
  ChiefNursingOfficer = "ChiefNursingOfficer",
  ChiefOperatingOfficer = "ChiefOperatingOfficer",
  ChiefProductOfficer = "ChiefProductOfficer",
  ChiefRiskOfficer = "ChiefRiskOfficer",
  ChiefSecurityOfficer = "ChiefSecurityOfficer",
  ChiefSustainabilityOfficer = "ChiefSustainabilityOfficer",
  ChiefTechnologyOfficer = "ChiefTechnologyOfficer",

  Chairman = "Chairman",
  President = "President",
  GeneralCounsel = "GeneralCounsel",

  // EVP
  ExecutiveVicePresident = "ExecutiveVicePresident",

  // SVP
  SeniorVicePresident = "SeniorVicePresident",

  // VP
  VicePresident = "VicePresident",

  AdvertisingVicePresident = "AdvertisingVicePresident",
  FinanceVicePresident = "FinanceVicePresident",
  InvestorRelationsVicePresident = "InvestorRelationsVicePresident",
  LogisticsVicePresident = "LogisticsVicePresident",
  MarketingVicePresident = "MarketingVicePresident",
  OperationsVicePresident = "OperationsVicePresident",
  SalesVicePresident = "SalesVicePresident",
  SalesAndMarketingVicePresident = "SalesAndMarketingVicePresident",

  // Director

  // General Manager

  // Manager
}
