import React from "react";

import { SvgIcon, SvgIconProps } from "@mui/material";

export const ImageStyleInlineIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path d="M 3 20 L 21 20 L 21 18 L 3 18 Z M 13 16 L 21 16 L 21 14 L 13 14 Z M 3 2 L 3 4 L 21 4 L 21 2 Z" />
    <path d="M 4 15 L 10 15 L 10 7 L 4 7 Z" />
  </SvgIcon>
);
