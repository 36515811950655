import { getObjectHash } from "@bigpi/cookbook";
import { WebSocketProvider } from "@bigpi/y-websocket";
import {
  DocumentRecordType,
  InstancePresenceRecordType,
  PageRecordType,
  TLAnyShapeUtilConstructor,
  TLDocument,
  TLInstancePresence,
  TLPageId,
  TLRecord,
  TLStoreWithStatus,
  computed,
  createPresenceStateDerivation,
  createTLStore,
  getUserPreferences,
  react,
  RecordId,
  transact,
  IndexKey,
  defaultUserPreferences,
  defaultShapeUtils,
} from "@tldraw/tldraw";
import debounce from "lodash.debounce";
import { useEffect, useRef, useState } from "react";
import * as Y from "yjs";

const PRESENCE_DEBOUNCE_MS = 200;
const PRESENCE_MAX_WAIT_MS = 300;
const PRESENCE_DEBOUNCE_ENABLED = false;

export function compareTlPresence(a: TLInstancePresence | undefined | null, b: TLInstancePresence | undefined | null) {
  let result = true;

  // Sanity check - fails if both are null or both are undefined
  if (!a || !b) {
    return false;
  }

  for (const key of Object.keys(a)) {
    switch (key) {
      case "lastActivityTimestamp":
      case "cursor":
        // NO-OP: Ignored changes
        break;
      case "camera":
        if (a.camera.x !== b.camera.x || a.camera.y !== b.camera.y || a.camera.z !== b.camera.z) {
          result = false;
        }
        break;
      case "meta":
        // Note: This may give wrong results if the keys are not in the same order
        if (JSON.stringify(a.meta) !== JSON.stringify(b.meta)) {
          result = false;
        }
        break;
      default:
        if ((a as any)[key] !== (b as any)[key]) {
          result = false;
          break;
        }
    }

    if (!result) {
      break;
    }
  }

  return result;
}

export type IYjsStoreWithStatus = TLStoreWithStatus & {
  bail?: () => void;
  provider?: WebSocketProvider | null;
  yDocument?: Y.Doc | null;
};

export function useYjsStore({
  roomId = "",
  hostUrl = "",
  customShapeUtils = [],
}: Partial<{
  hostUrl: string;
  roomId: string;
  version: number;
  customShapeUtils: TLAnyShapeUtilConstructor[];
}>) {
  const [store, setStore] = useState(() => createTLStore({ shapeUtils: [...defaultShapeUtils, ...customShapeUtils] }));
  const [storeWithStatus, setStoreWithStatus] = useState<IYjsStoreWithStatus>({ status: "loading" });
  const [yDocument, setYDocument] = useState<Y.Doc>(new Y.Doc({ gc: true }));
  const [provider, setProvider] = useState<WebSocketProvider | null>(null);
  const [yRecords, setYRecords] = useState<Y.Map<TLRecord> | null>(null);
  const lastPresence = useRef<TLInstancePresence | null>(null);

  useEffect(() => {
    if (roomId) {
      const newYDocument = new Y.Doc({ gc: true });
      setYDocument(newYDocument);
      setYRecords(newYDocument.getMap<TLRecord>(roomId));
      const provider = new WebSocketProvider(hostUrl, roomId, newYDocument, {
        connect: true,
        params: {
          schemaHash: getObjectHash(store.schema.serialize()),
        },
      });
      setProvider(provider);
    }
    return () => {
      try {
        provider?.destroy();
        setStore(() => createTLStore({ shapeUtils: [...defaultShapeUtils, ...customShapeUtils] }));
        setStoreWithStatus({ status: "loading" });
        setYDocument(new Y.Doc({ gc: true }));
        setProvider(null);
        setYRecords(null);
      } catch (error) {
        console.error(error);
      }
    };
  }, [hostUrl, roomId]);

  useEffect(() => {
    // Don't do anything if we don't have a room yet
    if (!provider) {
      return;
    }

    const unsubs: (() => void)[] = [];

    // We'll use this flag to prevent repeating subscriptions if our connection drops and reconnects.
    let didConnect = false;

    const bail = () => {
      console.error("Bailing...");

      // We need to immediately prevent YJS from updating so it doesn't push invalid state to server (including empty state)
      provider?.disconnect();

      // Remove event subscriptions to prevent any additional processing
      unsubs.forEach((fn) => fn());
      unsubs.length = 0;

      setStoreWithStatus({
        status: "error",
        error: new Error("Remote error", {
          cause: "remote-error",
        }),
        provider,
      });
    };

    const onConnectionError = (errorEvent: ErrorEvent) => {
      setStoreWithStatus({
        status: "error",
        error: new Error("Connection error", {
          cause: "connection-error",
        }),
      });
    };
    provider.on("connection-error", onConnectionError);
    unsubs.push(() => provider.off("connection-error", onConnectionError));

    const onRemoteError = (errorEvent: ErrorEvent) => {
      setStoreWithStatus({
        status: "error",
        error: new Error("Remote error", {
          cause: "remote-error",
        }),
        provider,
      });
    };
    provider.on("error", onRemoteError);
    unsubs.push(() => provider.off("error", onRemoteError));

    const onIncorrectVersionError = (errorEvent: ErrorEvent) => {
      setStoreWithStatus({
        status: "error",
        error: new Error("Invalid version", {
          cause: "invalid-version",
        }),
        provider,
      });
    };
    provider.on("invalid-version", onIncorrectVersionError);
    unsubs.push(() => provider.off("invalid-version", onIncorrectVersionError));

    const onStatusChanged = ({ status }: { status: "connecting" | "disconnected" | "connected" }) => {
      // If we're disconnected, set the store status to "synced-remote" and the connection status to "offline"
      if (status === "connecting" || status === "disconnected") {
        setStoreWithStatus({
          store,
          status: "synced-remote",
          connectionStatus: "offline",
          provider,
        });
        return;
      }

      if (status !== "connected") {
        return;
      }

      if (provider.synced) {
        setStoreWithStatus({
          bail,
          store,
          status: "synced-remote",
          connectionStatus: "online",
          provider,
          yDocument,
        });

        return;
      }
    };

    provider.on("status", onStatusChanged);
    unsubs.push(() => provider.off("status", onStatusChanged));

    const onSyncedChange = () => {
      if (!yRecords || !provider.synced) {
        console.warn(`We're connected, but not ready. yRecords: ${!yRecords}, synced: ${provider.synced}`);
        return;
      }

      if (didConnect) {
        console.warn("We're already connected. Ignoring onSyncedChange.");

        if (provider.synced) {
          setStoreWithStatus({
            bail,
            store,
            status: "synced-remote",
            connectionStatus: "online",
            yDocument,
            provider,
          });

          return;
        }
      }

      // Ok, we're connecting for the first time. Let's get started!
      didConnect = true;

      // Initialize the store with the yjs doc records—or, if the yjs doc
      // is empty, initialize the yjs doc with the default store records.
      if (yRecords.size === 0) {
        // Create the initial store records
        transact(() => {
          store.clear();
          store.put([
            DocumentRecordType.create({
              id: "document:document" as RecordId<TLDocument>,
            }),
            PageRecordType.create({
              id: "page:page" as TLPageId,
              name: "Page 1",
              index: "a1" as IndexKey,
            }),
          ]);
        });
      } else {
        // Replace the store records with the yjs doc records
        try {
          store.mergeRemoteChanges(() => {
            store.clear();
            store.put([...yRecords.values()]);
          });
        } catch (e) {
          bail();

          // Important: We need to return so the rest of the handlers created within this method aren't attached
          return;
        }
      }

      /* -------------------- Document -------------------- */

      // Sync store changes to the YJS document
      unsubs.push(
        store.listen(
          function syncStoreChangesToYjsDoc({ changes, source }) {
            // Create a YDocument transaction with the store as the origin
            // YDocument changes can come from several sources: local change by user -> TLDraw store or WebSocket updates -> YJS document
            yDocument.transact(() => {
              Object.values(changes.added).forEach((record) => {
                yRecords.set(record.id, record);
              });

              Object.values(changes.updated).forEach(([_, record]) => {
                yRecords.set(record.id, record);
              });

              Object.values(changes.removed).forEach((record) => {
                yRecords.delete(record.id);
              });
            }, "local");
          },
          { source: "user", scope: "document" }, // only sync user's document changes
        ),
      );

      // Sync the YJS document changes to the store
      const handleYDocumentChange = (events: Y.YEvent<any>[], transaction: Y.Transaction) => {
        // We need to update the TLDraw store when changes come from the Websocket provider, but not from TLDraw itself
        if (transaction.origin === "local") {
          return;
        }

        const toRemove: TLRecord["id"][] = [];
        const toPut: TLRecord[] = [];

        events.forEach((event) => {
          event.changes.keys.forEach((change, id) => {
            switch (change.action) {
              case "add":
              case "update": {
                toPut.push(yRecords.get(id)!);
                break;
              }
              case "delete": {
                toRemove.push(id as TLRecord["id"]);
                break;
              }
            }
          });
        });

        // Put / Remove the records in the store
        try {
          store.mergeRemoteChanges(() => {
            try {
              if (toRemove.length) {
                store.remove(toRemove);
              }
              if (toPut.length) {
                store.put(toPut);
              }
            } catch (e) {
              console.error("handleYDocumentChange(): 1 - Error merging remote changes", e);
              bail();
            }
          });
        } catch (e) {
          console.error("handleYDocumentChange(): 2 - Error merging remote changes", e);
          bail();
        }
      };

      yRecords.observeDeep(handleYDocumentChange);
      unsubs.push(() => yRecords.unobserveDeep(handleYDocumentChange));

      /* -------------------- Awareness ------------------- */

      // Create the instance presence derivation
      const yClientId = provider.awareness.clientID.toString();
      const presenceId = InstancePresenceRecordType.createId(yClientId);
      const userPreferencesComputed = computed<{ id: string; color: string; name: string }>("ok", () => {
        const user = getUserPreferences();
        return {
          id: user.id,
          color: user.color ?? defaultUserPreferences.color,
          name: user.name ?? defaultUserPreferences.name,
        };
      });
      const presenceDerivation = createPresenceStateDerivation(userPreferencesComputed, presenceId)(store);

      // Set our initial presence from the derivation's current value
      provider.awareness.setLocalStateField("presence", presenceDerivation.get());

      let presenceUpdate = (room: WebSocketProvider, presence: TLInstancePresence) => {
        room.awareness.setLocalStateField("presence", presence);
      };
      let debouncedSetLocalStateField = presenceUpdate;

      if (PRESENCE_DEBOUNCE_ENABLED) {
        debouncedSetLocalStateField = debounce(presenceUpdate, PRESENCE_DEBOUNCE_MS, { maxWait: PRESENCE_MAX_WAIT_MS });
      }

      // When the derivation change, sync presence to to YJS awareness
      unsubs.push(
        react("when presence changes", () => {
          const presence = presenceDerivation.get();
          // Reduce the number of calls during initialization
          if (presence && presence.lastActivityTimestamp !== 0) {
            // Debounce if only cursor has changed
            if (compareTlPresence(lastPresence.current, presence)) {
              lastPresence.current = presence;
              debouncedSetLocalStateField(provider, presence);
            } else {
              requestAnimationFrame(() => {
                lastPresence.current = presence;
                provider.awareness.setLocalStateField("presence", presence);
              });
            }
          }
        }),
      );

      // Sync YJS awareness changes to the store
      const handleAwarenessUpdate = (update: { added: number[]; updated: number[]; removed: number[] }) => {
        const states = provider.awareness.getStates() as Map<number, { presence: TLInstancePresence }>;

        const toRemove: TLInstancePresence["id"][] = [];
        const toPut: TLInstancePresence[] = [];

        // Connect records to put / remove
        for (const clientId of update.added) {
          const state = states.get(clientId);
          if (state?.presence && state.presence.id !== presenceId) {
            toPut.push(state.presence);
          }
        }

        for (const clientId of update.updated) {
          const state = states.get(clientId);
          if (state?.presence && state.presence.id !== presenceId) {
            toPut.push(state.presence);
          }
        }

        for (const clientId of update.removed) {
          toRemove.push(InstancePresenceRecordType.createId(clientId.toString()));
        }

        // Update the records in the store outside of current render loop
        requestAnimationFrame(() => {
          store.mergeRemoteChanges(() => {
            if (toRemove.length) {
              store.remove(toRemove);
            }
            if (toPut.length) {
              store.put(toPut);
            }
          });
        });
      };

      provider.awareness.on("update", handleAwarenessUpdate);
      unsubs.push(() => provider.awareness.off("update", handleAwarenessUpdate));

      // And we're done!
      setStoreWithStatus({
        store,
        status: "synced-remote",
        connectionStatus: "online",
        yDocument,
        provider,
      });
    };

    provider.on("sync", onSyncedChange);
    unsubs.push(() => provider.off("sync", onSyncedChange));

    return () => {
      // Remove event subscriptions
      unsubs.forEach((fn) => fn());
      unsubs.length = 0;
    };
  }, [provider, roomId, yDocument, store, yRecords]);

  useEffect(() => {
    return () => {
      const theRoom = provider;
      // Close the room connection
      try {
        theRoom?.destroy();
      } catch (error) {
        console.error(error);
      }
    };
  }, [provider]);

  return storeWithStatus;
}
