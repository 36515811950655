import React from "react";

interface IErrorBoundaryProps extends React.PropsWithChildren {
  onError: (error: Error) => void;
}

interface IErrorBoundaryState {
  error: Error | undefined;
  errorInfo: React.ErrorInfo | undefined;
}

/**
 * ErrorBoundary is a React component that catches errors in its children and allows
 * components to wrap children in order to handle errors within them that can't otherwise
 * be handled.
 */
export class ErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryState> {
  // *********************************************
  // React methods
  // *********************************************/
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    // Try to convert incorrectly constructed errors to Error objects
    if (typeof error === "string") {
      error = new Error(error);
    }
    this.setState({
      error,
      errorInfo,
    });

    this.props.onError(error);
  }

  render() {
    return this.props.children;
  }
}
