import {
  ISource,
  ITranscriptItem,
  DataGridColumnFormat,
  DataGridColumnTypeEnum,
  DataGridDetailsPanelFormat,
  SetPropertyBehavior,
} from "@bigpi/cookbook";
import { IDataGridConfig, IDataGridColumnDef } from "@bigpi/tl-schema";
import { Box, Typography, InputBase, InputBaseProps } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import {
  GridRenderCellParams,
  GridValueFormatterParams,
  GridValueGetterParams,
  useGridApiContext,
  GridRenderEditCellParams,
  GridGroupingValueGetterParams,
} from "@mui/x-data-grid-premium";
import dayjs from "dayjs";
import { TFunction } from "i18next";
import React, { useState, useCallback, useLayoutEffect, useRef } from "react";

import { ITopicDiscussionSummaryExampleResult } from "BoardComponents/BoardDatastore";
import { getPlainTextFromJsxElement } from "Utils/BoardSearchUtils";
import { getFormattedDate } from "Utils/DateUtils";

import "./DataFormatting.css";

/*********************************
 * Constants
 *********************************/
export const SLASH_PLACEHOLDER_FOR_ROW_GROUPING = "__SLASH__";

// Format to type mapping in case config miss type field value
const ColumnFormatToTypeMapping = {
  [DataGridColumnFormat.Date]: DataGridColumnTypeEnum.Date,
  [DataGridColumnFormat.DateRange]: DataGridColumnTypeEnum.Date,
  [DataGridColumnFormat.Decimal]: DataGridColumnTypeEnum.Number,
  [DataGridColumnFormat.Integer]: DataGridColumnTypeEnum.Number,
  [DataGridColumnFormat.Boolean]: DataGridColumnTypeEnum.Boolean,
  [DataGridColumnFormat.String]: DataGridColumnTypeEnum.String,
};

export type IDataGridColumnFormatMapping = {
  [key in DataGridColumnFormat]: (
    value: any,
    t: TFunction<"translation", undefined>,
    row?: Record<string, any>,
  ) => string | JSX.Element;
};

export type IDataGridDetailsPanelFormatMapping = {
  [key in DataGridDetailsPanelFormat]: (row: Record<string, any>, baseClass?: string) => JSX.Element;
};

/**
 * Data grid column rendering function for generic field types.
 */
const GenericDataGridColumnFormatMapping = {
  [DataGridColumnFormat.Date]: (value: any, t: TFunction<"translation", undefined>) => {
    return value ? getFormattedDate(value, t("Formatting.ShortDate")) : "";
  },
  [DataGridColumnFormat.DateRange]: (dateRange: { from: string; to: string }, t: TFunction<"translation", undefined>) => {
    if (!dateRange) {
      return "";
    }
    const format = t("Components.Charts.LabelDateFormat");
    if (dateRange.from && !dateRange.to) {
      return t("Components.Charts.AfterDate", { date: getFormattedDate(dateRange.from, format) });
    } else if (!dateRange.from && dateRange.to) {
      return t("Components.Charts.BeforeDate", { date: getFormattedDate(dateRange.to, format) });
    } else if (dateRange.from && dateRange.to) {
      return t("Components.Charts.BetweenDate", {
        from: getFormattedDate(dateRange.from, format),
        to: getFormattedDate(dateRange.to, format),
      });
    }
    return "";
  },
  [DataGridColumnFormat.Decimal]: (value: string) => (value ? parseFloat(value).toFixed(2) : ""),
  [DataGridColumnFormat.Integer]: (value: string) => (value ? parseInt(value).toFixed(0) : ""),
  [DataGridColumnFormat.String]: (value: string) => value,
  [DataGridColumnFormat.ArrayOfStrings]: (value: Array<string> = []) => value.join(", "),
  [DataGridColumnFormat.BulletList]: (value: string | Array<string> = []) => {
    if (Array.isArray(value)) {
      if (value.length === 1) {
        return value[0];
      }
      return (
        <ul className="data-grid-column-formatting__bulleted-list">
          {value.map((item: string, index: number) => {
            return <li key={index}>{item}</li>;
          })}
        </ul>
      );
    } else if (value) {
      return value;
    } else {
      return "";
    }
  },
  [DataGridColumnFormat.Speakers]: (value: Array<ITranscriptItem> = []) => {
    if (Array.isArray(value)) {
      return value
        .map((speaker: Record<string, any>) => {
          // TODO: This should probably pull the title from .occupationTitles, formatted via translations
          return `${speaker.fullName} (${speaker.role})`;
        })
        .join(", ");
    } else if (value) {
      return value;
    } else {
      return "";
    }
  },
  [DataGridColumnFormat.Boolean]: (value: any, t: TFunction<"translation", undefined>) => {
    return value ? t("Components.DataGrid.Yes") : t("Components.DataGrid.No");
  },
};

/**
 * Data grid column rendering function for all field types.
 */
export const DataGridColumnFormatMapping: IDataGridColumnFormatMapping = {
  ...GenericDataGridColumnFormatMapping,
  [DataGridColumnFormat.HtmlString]: (value: string) => {
    return <div dangerouslySetInnerHTML={{ __html: value }} className="data-grid-column-formatting__html-string"></div>;
  },
  [DataGridColumnFormat.Sources]: (value: Array<ISource> = []) => {
    if (Array.isArray(value)) {
      return (
        <>
          {value.map((item, index) => {
            return (
              <a href={item.url} target="_blank" key={index}>
                {item.name}
              </a>
            );
          })}
        </>
      );
    } else if (value) {
      return value;
    } else {
      return "";
    }
  },
};

/**
 * Config column format to CSV column format mapping for download CSV functionality.
 */
export const DownloadCsvColumnFormatMapping: IDataGridColumnFormatMapping = {
  ...GenericDataGridColumnFormatMapping,
  [DataGridColumnFormat.HtmlString]: (value: string) => {
    const div = document.createElement("div");
    div.innerHTML = value;
    return div.innerText;
  },
  [DataGridColumnFormat.BulletList]: (value: string | Array<string> = []) => {
    if (Array.isArray(value)) {
      if (value.length === 1) {
        return value[0];
      }
      return value.map((item: string) => ` - ${item}`).join("\n");
    } else if (value) {
      return value;
    } else {
      return "";
    }
  },
  [DataGridColumnFormat.Sources]: (value: Array<ISource> = []) => {
    if (Array.isArray(value)) {
      return value
        .map((item, index) => {
          return `${item.name} (${item.url})`;
        })
        .join("\n");
    } else if (value) {
      return value;
    } else {
      return "";
    }
  },
};

/**
 * Add items column format mapping.
 *
 * Note: For now using the same DataGridColumnFormatMapping.
 */
export const AddItemsColumnFormatMapping: IDataGridColumnFormatMapping = {
  ...DataGridColumnFormatMapping,
  [DataGridColumnFormat.BulletList]: (value: string | Array<string>) => {
    if (Array.isArray(value)) {
      if (value && value.length === 1) {
        return value[0];
      }
      return `<ul>${value.map((item: string, index: number) => `<li key=${index}>${item}</li>`).join("")}</ul>`;
    } else if (value) {
      return value;
    } else {
      return "";
    }
  },
};

export const DataGridDetailsPanelMapping: IDataGridDetailsPanelFormatMapping = {
  [DataGridDetailsPanelFormat.TopicDiscussionSummaryMasterDetails]: (row: Record<string, any>, baseClass?: string) => {
    return (
      <Box
        className={`topic-discussion-summary-master-details-datagrid__panel-content ${baseClass ?? ""}`.trim()}
        sx={{ flex: 1, mx: "auto", p: 2, pl: 6, backgroundColor: "var(--astra-color-gray10)" }}
      >
        <Typography variant="body1" gutterBottom fontWeight="500">
          {(row as ITopicDiscussionSummaryExampleResult).event}
        </Typography>
        <Box component="div" dangerouslySetInnerHTML={{ __html: (row as ITopicDiscussionSummaryExampleResult).summary }} />
      </Box>
    );
  },
};

/**
 * Generates columns for the data grid from the config.
 *
 * @param t Translation function.
 * @param columns Column definition config for the data grid.
 * @param isEditing Whether the grid is in edit mode.
 * @param isInteracting Whether the grid is in interact mode.
 *
 * @returns The column definition for the data grid.
 */
export function generateColumnsFromConfig(
  t: TFunction<"translation", undefined>,
  columns: IDataGridConfig["columns"],
  isEditing: boolean,
  isInteracting: boolean,
) {
  if (!columns) {
    return [];
  }

  return columns.map((column: IDataGridColumnDef) => {
    const {
      isColumnMenuEnabled,
      isColumnPinEnabled,
      isEditEnabled,
      isExportEnabled,
      isGroupEnabled,
      isHideEnabled,
      isReorderEnabled,
      isResizeEnabled,
      isSortEnabled,
      showSortIcons,
      ...rest
    } = column;
    const otherProps: Record<string, boolean> = {};
    setPropertyIfDefined(otherProps, "disableColumnMenu", isColumnMenuEnabled, SetPropertyBehavior.InverseBoolean);
    setPropertyIfDefined(otherProps, "disableExport", isExportEnabled, SetPropertyBehavior.InverseBoolean);
    setPropertyIfDefined(otherProps, "disableReorder", isReorderEnabled, SetPropertyBehavior.InverseBoolean);
    setPropertyIfDefined(otherProps, "groupable", isGroupEnabled);
    setPropertyIfDefined(otherProps, "editable", isEditEnabled);
    setPropertyIfDefined(otherProps, "hideable", isHideEnabled);
    setPropertyIfDefined(otherProps, "hideSortIcons", showSortIcons, SetPropertyBehavior.InverseBoolean);
    setPropertyIfDefined(otherProps, "pinnable", isColumnPinEnabled);
    setPropertyIfDefined(otherProps, "resizable", isResizeEnabled);
    setPropertyIfDefined(otherProps, "sortable", isSortEnabled);

    const renderEditCellProps: Record<string, (params: any) => React.JSX.Element> = {};
    if (column.format === DataGridColumnFormat.HtmlString) {
      renderEditCellProps["renderEditCell"] = (params) => <HtmlInputComponent {...params} />;
    }

    if (column.format === DataGridColumnFormat.BulletList || column.format === DataGridColumnFormat.ArrayOfStrings) {
      renderEditCellProps["renderEditCell"] = (params) => {
        // Enables array as bullet list by formatting as html
        const formattedValue = "<ul>" + (params.value || []).map((item: string) => `<li>${item}</li>`).join("") + "</ul>";
        return <HtmlInputComponent {...params} value={formattedValue} />;
      };
    }

    if (column.format === DataGridColumnFormat.String) {
      renderEditCellProps["renderEditCell"] = (params) => {
        return <EditTextarea {...params} />;
      };
    }

    if (column.format === DataGridColumnFormat.Date) {
      renderEditCellProps["renderEditCell"] = (params) => {
        return <CustomDateEditCell {...params} />;
      };
    }

    return {
      ...otherProps,
      ...renderEditCellProps,
      type: ColumnFormatToTypeMapping[column.format as keyof typeof ColumnFormatToTypeMapping],
      ...rest,
      headerAlign: undefined,
      valueFormatter: (params: GridValueFormatterParams) => {
        // Attempt to map supported values to native grid data types
        switch (column.format) {
          case DataGridColumnFormat.Date:
            if (typeof params.value === "string") {
              return new Date(params.value);
            }
            return params.value;
          case DataGridColumnFormat.Decimal:
            return parseFloat(params.value);
          case DataGridColumnFormat.Integer:
            return parseInt(params.value, 10);
          default:
            const stringFormatter = DownloadCsvColumnFormatMapping[column.format as keyof typeof DataGridColumnFormatMapping];
            return stringFormatter ? stringFormatter(params.value, t) : params.value;
        }
      },
      valueGetter: (params: GridValueGetterParams) => {
        switch (column.format) {
          case DataGridColumnFormat.Date:
            if (typeof params.value === "string") {
              return new Date(params.value);
            }
            return params.value;
          default:
            return params.value;
        }
      },
      valueParser: (value: any) => {
        if (column.format === DataGridColumnFormat.BulletList || column.format === DataGridColumnFormat.ArrayOfStrings) {
          // Parse the html content to get the list of values
          const divElement = document.createElement("div");
          divElement.innerHTML = value;
          const listElements = divElement.getElementsByTagName("li");

          const values = [];

          for (let i = 0; i < listElements.length; i++) {
            values.push(listElements[i].textContent);
          }

          if (listElements.length === 0) {
            values.push(value);
          }
          return values;
        }
        return value;
      },
      renderCell: (params: GridRenderCellParams) => {
        const formatter = DataGridColumnFormatMapping[column.format as keyof typeof DataGridColumnFormatMapping];
        return (
          <div
            style={{
              maxHeight: 300,
              overflow: isEditing || isInteracting ? "auto" : "hidden",
              scrollbarGutter: "stable",
              userSelect: "text",
              cursor: "text",
            }}
          >
            {formatter ? formatter(params.value, t) : params.value}
          </div>
        );
      },
      groupingValueGetter: (params: GridGroupingValueGetterParams) => {
        const formatter = DataGridColumnFormatMapping[column.format as keyof typeof DataGridColumnFormatMapping];
        let formattedCellData = formatter ? formatter(params.value, t) : params.value;

        // If the cell data is a JSX element, get the plain text from it.
        if (typeof formattedCellData !== "string") {
          formattedCellData = getPlainTextFromJsxElement(formattedCellData);
        }

        // Replace slashes with SLASH_PLACEHOLDER_FOR_ROW_GROUPING to avoid issues with grouping
        // as the grid uses slashes to separate the group values.
        formattedCellData = (formattedCellData as string).replaceAll("/", SLASH_PLACEHOLDER_FOR_ROW_GROUPING);

        return formattedCellData;
      },
    };
  });
}

/**
 * Sets the property on the object if the value is defined.
 *
 * @param obj Object to set property on
 * @param key Key to set
 * @param value Value to set
 * @param inverseBoolean Whether to inverse the boolean value
 */
function setPropertyIfDefined<T>(obj: T, key: keyof T, value: T[keyof T] | undefined, propertyBehavior?: SetPropertyBehavior) {
  if (value !== undefined) {
    obj[key] = propertyBehavior === SetPropertyBehavior.InverseBoolean ? (!value as T[keyof T]) : value;
  }
}

/****************************************
 * Cell Edit Components
 ****************************************/
/**
 * Html input component to edit html content
 *
 * @param props GridRenderEditCellParams
 * @returns
 */
function HtmlInputComponent(props: GridRenderEditCellParams<any, string>) {
  const { id, value, field } = props;
  const apiRef = useGridApiContext();

  const ref = useRef<HTMLDivElement>(document.createElement("div"));

  const onBlur = useCallback(() => {
    apiRef.current.setEditCellValue({ id, field, value: ref.current?.innerHTML });
  }, [ref, id, field, value]);

  const onKeyDown = useCallback((event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      event.stopPropagation();
    }
  }, []);

  return (
    <div
      ref={ref}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      contentEditable="true"
      dangerouslySetInnerHTML={{ __html: value || "" }}
      style={{ width: "100%" }}
    ></div>
  );
}

/**
 * To edit text with multi line
 *
 * @param props GridRenderEditCellParams
 * @returns
 */
function EditTextarea(props: GridRenderEditCellParams<any, string>) {
  const { id, field, value, colDef, hasFocus } = props;
  const [valueState, setValueState] = useState(value);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>();
  const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null);
  const apiRef = useGridApiContext();

  useLayoutEffect(() => {
    if (hasFocus && inputRef) {
      inputRef.focus();
    }
  }, [hasFocus, inputRef]);

  const handleRef = useCallback((el: HTMLElement | null) => {
    setAnchorEl(el);
  }, []);

  const onChange = useCallback<NonNullable<InputBaseProps["onChange"]>>(
    (event) => {
      const newValue = event.target.value;
      setValueState(newValue);
      apiRef.current.setEditCellValue({ id, field, value: newValue, debounceMs: 200 }, event);
    },
    [apiRef, field, id],
  );

  const onKeyDown = useCallback((event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      event.stopPropagation();
    }
  }, []);

  return (
    <div style={{ position: "relative", alignSelf: "flex-start" }}>
      <div
        ref={handleRef}
        style={{
          height: 1,
          width: colDef.computedWidth,
          display: "block",
          position: "absolute",
          top: 0,
        }}
      />
      {anchorEl && (
        <Box sx={{ p: 1, minWidth: colDef.computedWidth }}>
          <InputBase
            multiline
            value={valueState}
            sx={{ textarea: { resize: "both" }, width: "100%" }}
            onChange={onChange}
            onKeyDown={onKeyDown}
            inputRef={(ref) => setInputRef(ref)}
          />
        </Box>
      )}
    </div>
  );
}

/**
 * The default date edit cell uses browser default date picker (input type="date").
 * That uses timezone of the browser, we don't have any way to change using props.
 * But in our application we are using UTC dates, so we need to use a custom date picker.
 *
 * @param props Edit cell params
 * @returns
 */
function CustomDateEditCell(props: GridRenderEditCellParams<any, string>) {
  const { id, field, value } = props;
  const apiRef = useGridApiContext();

  const onDateChange = useCallback(
    (newValue: any) => {
      const latestDate = newValue.toISOString();
      apiRef.current.setEditCellValue({ id, field, value: latestDate });
    },
    [apiRef],
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        value={dayjs.utc(value)}
        onChange={onDateChange}
        sx={{
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
        }}
      />
    </LocalizationProvider>
  );
}
