import { CloseOutlined, KeyboardArrowDownOutlined, KeyboardArrowUpOutlined, SearchOutlined } from "@mui/icons-material";
import { Box, Card, IconButton, InputAdornment, Popper, TextField, Tooltip } from "@mui/material";
import type { Editor } from "@tiptap/react";
import React, { MouseEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import type { IEditorConfig } from "../../Editor/index.js";
import { ToolbarItemBase } from "../../Toolbars/ToolbarItemBase.js";
import { ToolbarButton } from "../../Toolbars/ToolbarButton.js";
import { capitalizeFirstLetter, getEditorToolbarButtonTooltip } from "../../Utils/ToolbarItemUtils.js";
import { Search, SearchStorage } from "./SearchExtension.js";

const ITEM_NAME = "search";

/**
 * A toolbar button that enables text search in the document.
 */
export class SearchButtonItem extends ToolbarItemBase {
  // *********************************************
  // Public properties
  // *********************************************/
  /**
   * @inheritdoc
   */
  readonly name = ITEM_NAME;

  // *********************************************
  // Public methods
  // *********************************************/
  /**
   * @inheritdoc
   */
  create(editor: Editor, config: IEditorConfig) {
    const { toolbarOptions } = config;
    const searchStorage: SearchStorage = editor.storage[Search.name];

    return (
      <SearchButton
        key={ITEM_NAME}
        search={(searchTerm: string) => editor.chain().search(searchTerm).navigateToMatchIndex(0).run()}
        navigateSearchMatches={(direction: "forward" | "backward") => editor.chain().navigateSearchMatches(direction).run()}
        clearSearch={() => editor.chain().clearSearch().run()}
        totalMatches={searchStorage.matchingRanges.length}
        currentNavigationIndex={searchStorage.currentNavigationIndex}
        toolbarOptions={toolbarOptions}
      />
    );
  }
}

/**
 * Props for SearchButton.
 */
export interface SearchButtonProps {
  search: (searchTerm: string) => void;
  navigateSearchMatches: (direction: "forward" | "backward") => void;
  clearSearch: () => void;
  totalMatches: number;
  currentNavigationIndex: number;
  toolbarOptions?: IEditorConfig["toolbarOptions"];
}

/**
 * A toolbar button for the `search` command.
 */
export const SearchButton = (props: SearchButtonProps) => {
  const { search, navigateSearchMatches, clearSearch, totalMatches, currentNavigationIndex, toolbarOptions } = props;
  const tooltipPlacement = toolbarOptions?.tooltipPlacement;

  const { t } = useTranslation();

  const inputRef = useRef<HTMLInputElement | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (searchTerm && searchTerm.length > 0) {
      search(searchTerm);
    } else {
      clearSearch();
    }
  }, [searchTerm]);

  const openSearchPopper = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);

    // Focus the input field when the popper opens
    setTimeout(() => inputRef.current?.focus(), 0);
  };

  const closeSearchPopper = () => {
    // Clear the search when the popper is closed
    clearSearch();

    // Reset the search term and close the popper
    setSearchTerm("");
    setAnchorEl(null);
    setOpen(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      // "Enter" will navigate to the next match.
      navigateSearchMatches("forward");
    } else if (e.keyCode === 13 && e.shiftKey === true) {
      // "Shift + Enter" will navigate to the previous match.
      navigateSearchMatches("backward");
    }
  };

  return (
    <>
      <Box component="span">
        <ToolbarButton
          Icon={SearchOutlined}
          tooltip={getEditorToolbarButtonTooltip(t, ITEM_NAME)}
          onClick={(e) => (open ? closeSearchPopper() : openSearchPopper(e))}
          tooltipPlacement={tooltipPlacement}
        />
      </Box>

      <Popper id={open ? "search-popover" : undefined} open={open} anchorEl={anchorEl}>
        <Card sx={{ m: 1, p: 1 }}>
          <TextField
            id="search-term"
            variant="outlined"
            placeholder={t(`Editor.${capitalizeFirstLetter(ITEM_NAME)}.SearchPlaceholder`, { ns: "editor" })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {t(`Editor.${capitalizeFirstLetter(ITEM_NAME)}.NavigationText`, {
                    ns: "editor",
                    currentIndex: totalMatches > 0 ? currentNavigationIndex + 1 : 0,
                    totalMatches: totalMatches,
                  })}
                </InputAdornment>
              ),
            }}
            size="small"
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            inputRef={inputRef}
            onKeyDown={handleKeyDown}
            autoComplete="off"
          />

          <IconButton onClick={() => navigateSearchMatches("backward")} disabled={totalMatches <= 1} disableRipple>
            <Tooltip title={t(`Editor.${capitalizeFirstLetter(ITEM_NAME)}.NavigationTooltip.Previous`, { ns: "editor" })}>
              <KeyboardArrowUpOutlined />
            </Tooltip>
          </IconButton>

          <IconButton onClick={() => navigateSearchMatches("forward")} disabled={totalMatches <= 1} disableRipple>
            <Tooltip title={t(`Editor.${capitalizeFirstLetter(ITEM_NAME)}.NavigationTooltip.Next`, { ns: "editor" })}>
              <KeyboardArrowDownOutlined />
            </Tooltip>
          </IconButton>

          <IconButton onClick={() => closeSearchPopper()} disableRipple>
            <Tooltip title={t(`Editor.${capitalizeFirstLetter(ITEM_NAME)}.CloseTooltip`, { ns: "editor" })}>
              <CloseOutlined />
            </Tooltip>
          </IconButton>
        </Card>
      </Popper>
    </>
  );
};
