import React from "react";
import { FormatBoldOutlined } from "@mui/icons-material";
import type { Editor } from "@tiptap/react";
import { useTranslation } from "react-i18next";

import type { IEditorConfig } from "../../Editor/index.js";
import { getEditorToolbarButtonTooltip } from "../../Utils/ToolbarItemUtils.js";
import { ToolbarItemBase } from "../../Toolbars/ToolbarItemBase.js";
import { ToolbarButton } from "../../Toolbars/ToolbarButton.js";

const ITEM_NAME = "bold";

/**
 * A toolbar button that displays and toggles current Bold status.
 */
export class BoldButtonItem extends ToolbarItemBase {
  // *********************************************
  // Public properties
  // *********************************************/
  /**
   * @inheritdoc
   */
  readonly name = ITEM_NAME;

  // *********************************************
  // Public methods
  // *********************************************/
  /**
   * @inheritdoc
   */
  create(editor: Editor, config: IEditorConfig) {
    const { toolbarOptions } = config;
    return (
      <BoldButton
        key={ITEM_NAME}
        onClick={() => editor.chain().focus().toggleBold().run()}
        isActive={() => editor.isActive(ITEM_NAME)}
        toolbarOptions={toolbarOptions}
      />
    );
  }
}

/**
 * Props for BoldButton.
 */
export interface BoldButtonProps {
  isActive: (command: typeof ITEM_NAME) => boolean;
  onClick: () => void;
  toolbarOptions?: IEditorConfig["toolbarOptions"];
}

/**
 * A toolbar button for the `bold` command.
 */
export const BoldButton = (props: BoldButtonProps) => {
  const { onClick: toggle, isActive, toolbarOptions } = props;
  const tooltipPlacement = toolbarOptions?.tooltipPlacement;

  const { t } = useTranslation();

  return (
    <ToolbarButton
      Icon={FormatBoldOutlined}
      tooltip={getEditorToolbarButtonTooltip(t, ITEM_NAME)}
      onClick={toggle}
      isActive={isActive(ITEM_NAME)}
      tooltipPlacement={tooltipPlacement}
    />
  );
};
