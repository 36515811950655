import { Node, mergeAttributes } from "@tiptap/core";

import { getBaseClassWithoutImageAlignment, getImageAlignment } from "../../Utils/CommandUtil.js";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    imageAlign: {
      /**
       * Set the image align attribute
       */
      setImageAlign: (alignment: ImageAlignmentType) => ReturnType;
      /**
       * Unset the image align attribute
       */
      unsetImageAlign: () => ReturnType;
    };
  }
}

export enum ImageAlignmentType {
  ALIGN_LEFT = "align-left",
  ALIGN_RIGHT = "align-right",
  BLOCK_ALIGN_LEFT = "block-align-left",
  BLOCK_ALIGN_RIGHT = "block-align-right",
  BLOCK_ALIGN_CENTER = "block-align-center",
  SIDE = "side",
}

export interface FigureOptions {
  HTMLAttributes: Record<string, any>;
  alignments: Array<ImageAlignmentType>;
  defaultAlignment: ImageAlignmentType;
}

export const Figure = Node.create<FigureOptions>({
  name: "figure",

  content() {
    return "image";
  },

  draggable: false,

  selectable: true,

  group: "block",

  atom: true,

  addOptions() {
    return {
      HTMLAttributes: {
        class: "image",
      },
      alignments: [
        ImageAlignmentType.ALIGN_LEFT,
        ImageAlignmentType.ALIGN_RIGHT,
        ImageAlignmentType.BLOCK_ALIGN_LEFT,
        ImageAlignmentType.BLOCK_ALIGN_RIGHT,
        ImageAlignmentType.BLOCK_ALIGN_CENTER,
        ImageAlignmentType.SIDE,
      ],
      defaultAlignment: ImageAlignmentType.BLOCK_ALIGN_CENTER,
    };
  },

  addAttributes() {
    return {
      imageAlign: {
        default: this.options.defaultAlignment,
        parseHTML: (element) => {
          // Class name are in pattern of "image-style-{alignment}"
          const imageAlign = getImageAlignment(element.className);
          return imageAlign || this.options.defaultAlignment;
        },
        renderHTML: (attributes) => {
          if (attributes.imageAlign === this.options.defaultAlignment) {
            return {};
          }

          return { class: `image-style-${attributes.imageAlign}` };
        },
      },
      class: {
        default: null,
        parseHTML: (element: HTMLElement) => {
          const classAttr = element.hasAttribute("class") ? element.getAttribute("class") : null;
          // Keep all classes except for those that start with "image-style-"
          return getBaseClassWithoutImageAlignment(classAttr);
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "figure",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["figure", mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },

  addCommands() {
    return {
      setImageAlign:
        (alignment: ImageAlignmentType) =>
        ({ commands }) => {
          if (!this.options.alignments.includes(alignment)) {
            return false;
          }

          return commands.updateAttributes(this.name, { imageAlign: alignment });
        },

      unsetImageAlign:
        () =>
        ({ commands }) => {
          return commands.resetAttributes(this.name, "imageAlign");
        },
    };
  },
});
