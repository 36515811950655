import { useContext } from "react";

import { ChatActionExecutorContext } from "./ChatActionExecutorContext";

export const useChatActionExecutor = () => {
  const result = useContext(ChatActionExecutorContext);
  if (!result) {
    throw new Error("No ChatActionExecutor found in context");
  }
  return result;
};
