import { ApolloClient } from "@apollo/client";
import { IAnalystQuestionAnalysisFacets } from "@bigpi/tl-schema";
import { TFunction } from "i18next";

import { IAnalystQuestionResult } from "BoardComponents/BoardDatastore";
import { ANALYST_QUESTIONS_QUERY } from "GraphQL/AnalystQuestion";
import { AnalystQuestionsQuery } from "GraphQL/Generated/Apollo";
import { ChartUtils } from "Utils/ChartUtils";
import { DataUtils } from "Utils/DataUtils";
import {
  EVENT_DATE_FIELD_NAME,
  FIRM_FIELD_NAME,
  FIRM_TYPE_FIELD_NAME,
  SEGMENT_FIELD_NAME,
  TOPICS_FIELD_NAME,
} from "./AnalystQuestionAnalysisShape";

/**
 * Fetches the data for the analyst question analysis
 *
 * @param apolloClient - The Apollo client instance.
 * @param t - The translation function.
 *
 * @returns The array of analyst question results.
 */
export const getAnalystQuestionsData = async (
  apolloClient: ApolloClient<object>,
  t: TFunction,
): Promise<Array<IAnalystQuestionResult>> => {
  const apiResponse = await apolloClient.query<AnalystQuestionsQuery>({
    query: ANALYST_QUESTIONS_QUERY,
    variables: {
      facets: {
        firm: undefined,
        eventDate: { from: undefined, to: undefined },
        firmType: undefined,
        segment: undefined,
        topics: undefined,
      },
      numberOfClusters: 100,
      otherGroupLabel: t("Components.Analyses.AnalystQuestionAnalysis.OtherGroup"),
    },
  });

  return (
    apiResponse.data?.analystQuestions.map((dataItem) => {
      return {
        ...dataItem,

        // Move group name to top level
        group: dataItem.analystQuestionClusterAssignment?.[0]?.analystQuestionCluster?.name || "",
      };
    }) || DataUtils.getImmutableEmptyArray<IAnalystQuestionResult>()
  );
};

/**
 * Filters the data based on the facets
 *
 * @param data The array of analyst question results
 * @param facets The facets used for filtering
 *
 * @returns The filtered array of analyst question results
 */
export const applyFacetFilterOnAnalystQuestionsData = (
  data: Array<IAnalystQuestionResult>,
  facets: IAnalystQuestionAnalysisFacets,
) => {
  const topics = facets[TOPICS_FIELD_NAME];
  const firmTypes = facets[FIRM_TYPE_FIELD_NAME];
  const firms = facets[FIRM_FIELD_NAME];
  const segments = facets[SEGMENT_FIELD_NAME];
  const eventDate = facets[EVENT_DATE_FIELD_NAME];

  return data.filter((dataItem) => {
    const validArray = [];

    // Check topics
    if (topics && topics.length > 0) {
      let valid = false;
      const exists = topics.map((topic: string) => {
        const dataTopics = dataItem[TOPICS_FIELD_NAME].map((value: string) => value.trim());
        const existingDataTopics = dataTopics.filter((dataTopic: string) => dataTopic === topic);
        return existingDataTopics.length > 0;
      });
      if (exists.length > 0 && !exists.includes(false)) {
        valid = true;
      }
      validArray.push(valid);
    }

    // Check firm type
    if (firmTypes && firmTypes.length > 0 && !firmTypes.includes(dataItem[FIRM_TYPE_FIELD_NAME])) {
      validArray.push(false);
    }

    // Check firm
    if (firms && firms.length > 0 && !firms.includes(dataItem[FIRM_FIELD_NAME])) {
      validArray.push(false);
    }

    // Check map
    if (segments && segments.length > 0) {
      let valid = false;
      segments.forEach((segment: string) => {
        if (dataItem[SEGMENT_FIELD_NAME].includes(segment)) {
          valid = true;
        }
      });
      validArray.push(valid);
    }

    // Check event date
    if (eventDate && (eventDate.from || eventDate.to)) {
      let valid = false;
      const inRange = ChartUtils.isInRange(`${eventDate?.from || ""}#${eventDate?.to || ""}`, dataItem[EVENT_DATE_FIELD_NAME]);
      if (inRange) {
        valid = true;
      }
      validArray.push(valid);
    }

    if (validArray.length > 0 && validArray.includes(false)) {
      return false;
    } else {
      return true;
    }
  });
};

/**
 * Fetches the filtered data for the analyst question analysis based on the selected facets
 *
 * @param apolloClient - The Apollo client instance.
 * @param facets - The facets used for filtering.
 * @param t - The translation function.
 *
 * @returns The array of filtered analyst question results.
 */
export const getFilteredAnalystQuestionsData = async (
  apolloClient: ApolloClient<object>,
  facets: IAnalystQuestionAnalysisFacets,
  t: TFunction,
): Promise<Array<IAnalystQuestionResult>> => {
  const data = await getAnalystQuestionsData(apolloClient, t);
  return applyFacetFilterOnAnalystQuestionsData(data, facets);
};
