/**
 * Generates fixed number of random values based on the seed
 *
 * @param seed Any seed number
 * @param length Length of the array to be generated
 * @param max Max value within the array
 * @returns
 */
export function customSeededRandomArray(seed: number, length: number, max: number) {
  let randomArray = [];

  // Initialize the seed value
  let seedValue = seed % 2147483647;
  if (seedValue <= 0) {
    seedValue += 2147483646;
  }

  // Custom pseudo-random number generator
  for (let i = 0; i < length; i++) {
    seedValue = (seedValue * 16807) % 2147483647;
    // Ensure that the generated number is less than requested value
    const randomNumber = seedValue % max;
    randomArray.push(randomNumber);
  }

  return randomArray;
}
