import { Permissions } from "../Enums/Permissions.js";
import { UnauthorizedError } from "../Errors/index.js";

// NOTE: The following is copied from the Frontegg SDK, but not imported to avoid problematic dependency
// import { IRolePermission } from "@frontegg/rest-api";
export interface IRolePermission {
  // id: string;
  key: string;
  // name: string;
  // description?: string;
  // categoryId: string;
  // fePermission: boolean;
  // createdAt: Date;
  // updatedAt: Date;
}

export function hasPermission(userPermissions: Array<string>, permission: Permissions) {
  // This is currently a simple string match, but may support wildcards etc. in the future
  return Array.isArray(userPermissions) && permission && userPermissions.includes(permission);
}

/***
 * Checks if the given array of Frontegg RolePermissions contains the requested permission.
 */
export function hasRolePermission(userRolePermissions: Array<IRolePermission>, permission: Permissions) {
  let result = false;

  if (Array.isArray(userRolePermissions) && permission) {
    for (const rolePermission of userRolePermissions) {
      if (rolePermission.key === permission) {
        result = true;
        break;
      }
    }
  }

  return result;
}

export function assertHasPermission(userPermissions: Array<string>, permission: Permissions) {
  if (hasPermission(userPermissions, permission) !== true) {
    throw new UnauthorizedError(UnauthorizedError.message);
  }
}

export function assertHasRolePermission(userRolePermissions: Array<IRolePermission>, permission: Permissions) {
  if (hasRolePermission(userRolePermissions, permission) !== true) {
    throw new UnauthorizedError(UnauthorizedError.message);
  }
}
