import { Card, Grid, CardHeader, ButtonBase, Typography, Checkbox } from "@mui/material";
import { useCallback, useState } from "react";

import { DocumentIcon } from "Components/DocumentIcon/DocumentIcon";
import { DocumentPreviewDialog } from "Components/DocumentPreviewDialog/DocumentPreviewDialog";
import { DocumentsListMenu } from "./DocumentsListMenu";
import { DocumentsQuery } from "GraphQL/Generated/Apollo";

interface IDocumentsGridItemProps {
  document: DocumentsQuery["documents"][0];
  size: "small" | "medium" | "large";
  checked: boolean;
  onSelectionChange: (id: string) => void;
}

export function DocumentsGridItem(props: IDocumentsGridItemProps) {
  const { document, checked, onSelectionChange, size } = props;
  const [activeGridElement, setActiveGridElement] = useState<HTMLElement | null>(null);

  // State
  const [openPreview, setOpenPreview] = useState(false);

  let itemImageComponent: JSX.Element | null = null;

  const handleMouseEnter = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setActiveGridElement(event.currentTarget);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setActiveGridElement(null);
  }, []);

  const isSelectionEnabled = Boolean(activeGridElement);

  if (isSelectionEnabled || checked) {
    itemImageComponent = <Checkbox checked={checked} onChange={() => onSelectionChange(document.id)} sx={{ padding: 0 }} />;
  } else {
    itemImageComponent = <DocumentIcon />;
  }

  return (
    <Grid
      item
      xl={size === "small" ? 3 : size === "medium" ? 4 : 6}
      lg={size === "small" ? 2 : size === "medium" ? 3 : 4}
      md={size === "small" ? 3 : size === "medium" ? 4 : 6}
      xs={size === "small" ? 6 : size === "medium" ? 6 : 12}
      sx={{ display: "grid" }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Card
        variant="outlined"
        sx={{
          borderRadius: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          backgroundColor: checked ? "var(--astra-color-blue15)" : "var(--astra-color-gray7)",
          border: "none",
          ":hover": {
            backgroundColor: "var(--astra-color-gray15)",
          },
        }}
      >
        <CardHeader
          avatar={itemImageComponent}
          title={
            <ButtonBase sx={{ textAlign: "start" }} disableRipple onClick={() => setOpenPreview(true)}>
              <Typography
                sx={{
                  textAlign: "start",
                  fontSize: size === "small" ? "1rem" : size === "medium" ? "1.1rem" : "1.2rem",
                  fontWeight: "500",
                  wordBreak: "break-word",
                }}
              >
                {document.name}
              </Typography>
            </ButtonBase>
          }
          action={<DocumentsListMenu documentId={document.id} />}
        />
      </Card>
      {openPreview && <DocumentPreviewDialog docId={document.id} onClose={() => setOpenPreview(false)} />}
    </Grid>
  );
}
