import React from "react";
import { MenuOpenOutlined } from "@mui/icons-material";
import type { Editor } from "@tiptap/react";
import { useTranslation } from "react-i18next";

import type { IEditorConfig } from "../../Editor/index.js";
import { getEditorToolbarButtonTooltip } from "../../Utils/ToolbarItemUtils.js";
import { ToolbarItemBase } from "../../Toolbars/ToolbarItemBase.js";
import { ToolbarButton } from "../../Toolbars/ToolbarButton.js";

const ITEM_NAME = "collapsibleBlock";

/**
 * A toolbar button that toggles collapsible block state from expanded to collapsed and vice versa.
 */
export class CollapsibleBlockButtonItem extends ToolbarItemBase {
  // *********************************************
  // Public properties
  // *********************************************/
  /**
   * @inheritdoc
   */
  readonly name = ITEM_NAME;

  // *********************************************
  // Public methods
  // *********************************************/
  /**
   * @inheritdoc
   */
  create(editor: Editor, config: IEditorConfig) {
    const { toolbarOptions } = config;
    return (
      <CollapsibleBlockButton
        key={ITEM_NAME}
        onClick={() =>
          editor.can().setDetails() ? editor.chain().focus().setDetails().run() : editor.chain().focus().unsetDetails().run()
        }
        canSetCollapsibleBlock={() => editor.can().setDetails()}
        canUnsetCollapsibleBlock={() => editor.can().unsetDetails()}
        toolbarOptions={toolbarOptions}
      />
    );
  }
}

/**
 * Props for CollapsibleBlockButtonProps.
 */
export interface CollapsibleBlockButtonProps {
  canSetCollapsibleBlock: () => boolean;
  canUnsetCollapsibleBlock: () => boolean;
  onClick: () => void;
  toolbarOptions?: IEditorConfig["toolbarOptions"];
}

/**
 * A toolbar button for the setting and unsetting collapsible blocks.
 */
export const CollapsibleBlockButton = (props: CollapsibleBlockButtonProps) => {
  const { onClick: toggle, canSetCollapsibleBlock, canUnsetCollapsibleBlock, toolbarOptions } = props;
  const tooltipPlacement = toolbarOptions?.tooltipPlacement;

  const { t } = useTranslation();

  return (
    <ToolbarButton
      Icon={MenuOpenOutlined}
      tooltip={getEditorToolbarButtonTooltip(t, ITEM_NAME)}
      onClick={toggle}
      isActive={!canSetCollapsibleBlock()}
      disabled={!canSetCollapsibleBlock() && !canUnsetCollapsibleBlock()}
      tooltipPlacement={tooltipPlacement}
    />
  );
};
