import { useApolloClient } from "@apollo/client";
import {
  AnalysisFeedbackDataItemType,
  DataGridActionCategory,
  DataGridActionType,
  FacetDisplaySortType,
  IDataGridAction,
  IDataGridActions,
  StandardHtmlColors,
} from "@bigpi/cookbook";
import {
  analystQuestionAnalysisShapeMigrations,
  analystQuestionAnalysisShapeProps,
  getAnalystQuestionAnalysisShapeDefaultProps,
  IAnalystQuestionAnalysisFacets,
  IAnalystQuestionAnalysisPreferences,
  IAnalystQuestionAnalysisSelection,
  IAnalystQuestionAnalysisShape,
  IDataGridColumnDef,
  IDataGridShape,
} from "@bigpi/tl-schema";
import { useAuthUser } from "@frontegg/react";
import { Grid, Checkbox, FormControl, InputLabel, Select, MenuItem, ListItemText } from "@mui/material";
import { atom, createShapeId, HTMLContainer, Migrations, SVGContainer, TLShape, useValue } from "@tldraw/tldraw";
import * as d3 from "d3";
import { TFunction } from "i18next";
import { useCallback, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { v4 as uuidV4 } from "uuid";

import {
  FieldFacetsManageStateType,
  useFieldFacetsManager,
  useIsChildEditing,
  useIsChildSelected,
  useSelectedDate,
  useShortcutRelatedRanges,
} from "BoardComponents/Analyses/Hooks";
import { IAnalystQuestionAnalysisConfig } from "BoardComponents/Analyses/DataFrameConfigs";
import { ALLOWED_ANALYSES_CHILD_STANDARD_SHAPE_TYPES, DataframeBaseUtil } from "BoardComponents/BaseShapes/DataframeBaseUtil";
import {
  IAnalystQuestionAnalysisShapeExternalData,
  IAnalystQuestionResult,
  useBoardDatastore,
} from "BoardComponents/BoardDatastore";
import { IChartShapeProps } from "BoardComponents/Charting/IChartShapeProps";
import { DashedOutlineBox } from "BoardComponents/DashedOutlineBox/DashedOutlineBox";
import { DataframeBackground } from "BoardComponents/DataframeBackground/DataframeBackground";
import { DataGridShapeUtil } from "BoardComponents/DataGridShape/DataGridShape";
import { useIsInteracting, useIsChildInteracting } from "BoardComponents/Tools";
import {
  AnalysisPreferencesDialog,
  IAnalysisPreferencesDialogState,
} from "Components/AnalysisPreferencesDialog/AnalysisPreferencesDialog";
import { AnalysisToolbar } from "Components/AnalysisToolbar/AnalysisToolbar";
import { useShapeEvents } from "BoardComponents/useShapeEvents";
import { AnalysisFeedbackDialog } from "Components/AnalysisFeedbackDialog/AnalysisFeedbackDialog";
import { SplitButton, SplitButtonProps } from "Components/SplitButton/SplitButton";
import { useGetConfigDataQuery } from "GraphQL/Generated/Apollo";
import { ChartUtils } from "Utils/ChartUtils";
import { DataUtils } from "Utils/DataUtils";
import { AnalysisToolbarActions } from "../AnalysisToolbarActions";
import { analystQuestionAnalysisFieldsConfig } from "./AnalystQuestionAnalysisFieldsConfig";
import {
  getAnalystQuestionsData,
  applyFacetFilterOnAnalystQuestionsData,
  getFilteredAnalystQuestionsData,
} from "./analystQuestionsDataUtils";

// *********************************************
// Public constants
// *********************************************/
// This might be temporary, if any better way, should be replaced to take from config
export const TOPICS_FIELD_NAME = "topics";
export const FIRM_FIELD_NAME = "firm";
export const FIRM_TYPE_FIELD_NAME = "firmType";
export const SEGMENT_FIELD_NAME = "segment";
export const EVENT_DATE_FIELD_NAME = "eventDate";
export const DATA_GRID = "dataGrid";

const DEFAULT_ANALYST_QUESTION_ANALYSIS_FIELDS = [
  TOPICS_FIELD_NAME,
  FIRM_FIELD_NAME,
  FIRM_TYPE_FIELD_NAME,
  SEGMENT_FIELD_NAME,
  EVENT_DATE_FIELD_NAME,
];

// *********************************************
// Private constants
// *********************************************/
const ALLOWED_CHILD_SHAPE_TYPES = [
  ...ALLOWED_ANALYSES_CHILD_STANDARD_SHAPE_TYPES,

  // Custom shapes
  "dataGrid",
  "barChart",
];

// Currently these are static, might be dynamic in future
const CHART_POSITIONS: Record<string, { x: number; y: number }> = {
  [EVENT_DATE_FIELD_NAME]: { x: 10, y: 50 },
  [SEGMENT_FIELD_NAME]: { x: 10, y: 500 },
  [FIRM_TYPE_FIELD_NAME]: { x: 10, y: 800 },
  [FIRM_FIELD_NAME]: { x: 10, y: 1100 },
  [TOPICS_FIELD_NAME]: { x: 800, y: 500 },
  [DATA_GRID]: { x: 10, y: 2000 },
};

const CONFIG_KEY = "analyst-question-analysis-config";

const GRID_ACTION_GROUP_SIMILAR_QUESTIONS = "groupSimilarQuestions";
const QUESTIONS_CLUSTERING_FIELD = "group";

// *********************************************
// Shape Util
// *********************************************/
/**
 * Generator for AnalystQuestionAnalysis shapes.
 */
export class AnalystQuestionAnalysisUtil extends DataframeBaseUtil<IAnalystQuestionAnalysisShape> {
  // *********************************************
  // Static fields
  // *********************************************/
  static type = "analystQuestionAnalysis";

  static props = analystQuestionAnalysisShapeProps;

  static migrations: Migrations = analystQuestionAnalysisShapeMigrations;

  // *********************************************
  // Override methods, event handlers
  // *********************************************/
  /**
   * Listens the children change event & adjusts the shape size.
   *
   * @param shape Shape on which the children changed
   */
  override onChildrenChange = (shape: IAnalystQuestionAnalysisShape) => {
    const children = this.editor.getSortedChildIdsForParent(shape.id);

    // Remove the data frame if there are no children
    if (children.length === 0) {
      if (this.editor.getFocusedGroupId() === shape.id) {
        this.editor.popFocusedGroupId();
      }
      this.editor.deleteShapes([shape.id]);
    }
  };

  // *********************************************
  // Override methods
  // *********************************************/
  /**
   * @inheritdoc
   */
  override isAspectRatioLocked = (shape: IAnalystQuestionAnalysisShape) => false;

  /**
   * @inheritdoc
   */
  override canResize = (shape: IAnalystQuestionAnalysisShape) => false;

  /**
   * @inheritdoc
   */
  override canBind = (shape: IAnalystQuestionAnalysisShape) => true;

  /**
   * @inheritdoc
   */
  override canEdit = (shape: IAnalystQuestionAnalysisShape) => true;

  /**
   * @inheritdoc
   */
  override canReceiveNewChildrenOfType = (_shape: IAnalystQuestionAnalysisShape, type: TLShape["type"]) => {
    return ALLOWED_CHILD_SHAPE_TYPES.includes(type);
  };

  /**
   * @inheritdoc
   */
  override canScroll = (shape: IAnalystQuestionAnalysisShape) => false;

  /**
   * @inheritdoc
   */
  hideSelectionBoundsBg = () => false;

  /**
   * @inheritdoc
   */
  hideSelectionBoundsFg = () => true;

  /**
   * @inheritdoc
   */
  override getDefaultProps(): IAnalystQuestionAnalysisShape["props"] {
    return getAnalystQuestionAnalysisShapeDefaultProps();
  }

  /**
   * @inheritdoc
   */
  override component(shape: IAnalystQuestionAnalysisShape) {
    const tldrawEditor = this.editor;
    const zoomLevel = tldrawEditor.getZoomLevel();
    const { id, props } = shape;
    const { t } = useTranslation();
    const apolloClient = useApolloClient();
    const selectedFacetValuesProp = props.selectedFacetValues;
    const toolbar = props.toolbar;
    const analysisPreferences = props.preferences.analysis;
    const selection = props.selection;

    const bounds = this.getGeometry(shape).getBounds();
    const isEditing = useValue("isEditing", () => tldrawEditor.getCurrentPageState().editingShapeId === id, [tldrawEditor, id]);
    const isHovered = useValue("isHovered", () => tldrawEditor.getCurrentPageState().hoveredShapeId === id, [tldrawEditor, id]);
    const isSelected = useValue("isSelected", () => tldrawEditor.getCurrentPageState().selectedShapeIds.includes(id), [
      tldrawEditor,
      id,
    ]);
    const isInteracting = useIsInteracting(id);
    const isChildInteracting = useIsChildInteracting(id);
    const isChildSelected = useIsChildSelected(shape.id);
    const isChildEditing = useIsChildEditing(shape.id);

    const { handleInputPointerDown } = useShapeEvents(shape.id);

    const [config, setConfig] = useState({} as IAnalystQuestionAnalysisConfig);

    // Date range shortcuts
    const dateRangeShortcuts = useShortcutRelatedRanges(
      config.dateShortcuts ? config.dateShortcuts[EVENT_DATE_FIELD_NAME] : undefined,
    );

    // Transform date shortcut
    const selectedFacetValuesSelectedDate = useSelectedDate(
      dateRangeShortcuts,
      selectedFacetValuesProp.eventDateShortcut,
      selectedFacetValuesProp.eventDate,
    );

    const selectedFacetValues = useValue(
      "selectedFacetValues",
      () => {
        return {
          ...selectedFacetValuesProp,
          eventDate: selectedFacetValuesSelectedDate,
        };
      },
      [selectedFacetValuesSelectedDate, selectedFacetValuesProp],
    );

    // Facets update
    const fieldFacetsManager = useFieldFacetsManager(
      tldrawEditor,
      shape,
      selectedFacetValues,
      this.updateSelectedFacetValues,
      analystQuestionAnalysisFieldsConfig,
    );

    // State
    const [isFeedbackDialogOpen, setIsFeedbackDialogOpen] = useState<boolean>(false);
    const [isPreferencesDialogOpen, setIsPreferencesDialogOpen] = useState<boolean>(false);
    const [data, setData] = useState<Array<IAnalystQuestionResult>>(DataUtils.getImmutableEmptyArray<IAnalystQuestionResult>());
    const [dataLoading, setDataLoading] = useState<boolean>(false);
    const [filteredData, setFilteredData] = useState<Array<IAnalystQuestionResult>>(data);
    const [slotsData, setSlotsData] = useState<Record<string, any>>([]);
    const [toolbarContainerRef, setToolbarContainerRef] = useState<HTMLDivElement | null>(null);
    const [distinctValues, setDistinctValues] = useState<Record<Partial<keyof IAnalystQuestionAnalysisFacets>, any>>(
      DataUtils.getImmutableEmptyObject(),
    );
    const [isConfigSyncWithShapeProps, setIsConfigSyncWithShapeProps] = useState<boolean>(false);

    const user = useAuthUser();
    const { data: configData, loading: configLoading } = useGetConfigDataQuery({
      variables: {
        key: CONFIG_KEY,
        organizationId: user?.tenantId,
      },
    });

    // This stores with the combination of shape.type & shapeProps.field
    const childShapeTypes = useValue(
      "childShapeTypes",
      () => {
        const childIds = this.editor.getSortedChildIdsForParent(shape.id);
        return childIds.map((childId) => {
          const shape = this.editor.getShape(childId);
          const shapeProps = shape?.props as IChartShapeProps;
          return `${shape?.type}${this._appendComponentKey(shapeProps.field)}`;
        });
      },
      [tldrawEditor, id],
    );

    // Make sure we have an entry in the datastore for this shape
    const datastore = useBoardDatastore();

    // Create the datastore state with our values
    useEffect(() => {
      if (!datastore.hasShapeData(shape.id)) {
        datastore.setShapeData(shape.id, {
          // Set the full data in the datastore
          allData: atom(`board.datastore.${shape.id}.allData`, data),
          config: atom(`board.datastore.${shape.id}.config`, config),
          configLoading: atom(`board.datastore.${shape.id}.configLoading`, false),
          dataGridSelectedIds: atom(`board.datastore.${shape.id}.dataGridSelectedIds`, []),
          dataGridActions: atom(`board.datastore.${shape.id}.dataGridActions`, {}),
          dataGridConfig: atom(`board.datastore.${shape.id}.dataGridConfig`, {}),
          dataLoading: atom(`board.datastore.${shape.id}.dataLoading`, false),
          facets: atom(`board.datastore.${shape.id}.facets`, selectedFacetValues),
          filteredData: atom(`board.datastore.${shape.id}.filteredData`, data),
          getToolbar: atom(`board.datastore.${shape.id}.getToolbar`, () => null),
          onFieldFacetManagerFieldChange: atom(`board.datastore.${shape.id}.onFieldFacetManagerFieldChange`, () => null),
          onSelectionChange: atom(`board.datastore.${shape.id}.onSelectionChange`, () => null),
          onSelectedIdsChanged: atom(`board.datastore.${shape.id}.onSelectedIdsChanged`, () => null),
          onUpdatePreferences: atom(`board.datastore.${shape.id}.onUpdatePreferences`, () => null),
          preferences: atom(`board.datastore.${shape.id}.preferences`, props.preferences),
          selection: atom(`board.datastore.${shape.id}.selection`, selection || {}),
          slotsData: atom(`board.datastore.${shape.id}.slotsData`, []),
          yAxisTickFormat: atom(`board.datastore.${shape.id}.yAxisTickFormat`, () => ""),
          type: "analystQuestionAnalysis",
        });
      }
    }, []);

    // Load data
    const fetchAnalystQuestionsData = useCallback(async () => {
      setDataLoading(true);

      // Any changes in the process of getting the data must be handled in
      // the `getAnalystQuestionsData` function since this function
      // is also used by the `BoardSearchManager` internally to get the filtered analysis data.
      return getAnalystQuestionsData(apolloClient, t).finally(() => {
        setDataLoading(false);
      });
    }, [apolloClient, t]);

    // Fetch the data and set it in the state
    useEffect(() => {
      if (config && Object.keys(config).length > 0 && isConfigSyncWithShapeProps) {
        fetchAnalystQuestionsData().then((analystQuestionsData) => {
          setData(analystQuestionsData);
        });
      }
    }, [isConfigSyncWithShapeProps, config, fetchAnalystQuestionsData]);

    // Set config data to local state
    useEffect(() => {
      if (configData?.Config?.data) {
        setConfig(JSON.parse(configData?.Config?.data || "{}") as IAnalystQuestionAnalysisConfig);
      }
    }, [configData]);

    // Update the datastore config when it changes
    useEffect(() => {
      const storeData = datastore.state.get()[shape.id].get() as IAnalystQuestionAnalysisShapeExternalData;
      storeData.config.set(this._addComponentFillsToConfig(config));
      storeData.dataGridConfig?.set(config.dataGrid);

      // Insert child shapes
      const childShapes = this.editor.getSortedChildIdsForParent(shape.id);
      if (config && Object.keys(config).length > 0 && childShapes.length === 0) {
        this.editor.updateShapes([
          {
            id: shape.id,
            type: shape.type,
            props: {
              preferences: {
                ...(config.initialState?.preferences || {}),
              },
              selectedFacetValues: {
                ...(config.initialState?.selectedFacetValues || {}),
                ...selectedFacetValues,
              },
              toolbar: {
                ...config?.toolbar,
                ...toolbar,
              },
            },
          },
        ]);
        this._initialRenderOfChildren(shape, config, t);
        setIsConfigSyncWithShapeProps(true);
      } else if (config && Object.keys(config).length > 0 && childShapes.length > 0) {
        setIsConfigSyncWithShapeProps(true);
      }
    }, [config, selectedFacetValues, toolbar]);

    // Update the datastore facets when they change
    useEffect(() => {
      const storeData = datastore.state.get()[shape.id].get() as IAnalystQuestionAnalysisShapeExternalData;
      storeData.facets.set(selectedFacetValues || {});
    }, [selectedFacetValues]);

    // Update the datastore data when it changes
    useEffect(() => {
      const storeData = datastore.state.get()[shape.id].get() as IAnalystQuestionAnalysisShapeExternalData;
      storeData.allData.set(data);
      storeData.filteredData?.set(data);
    }, [data]);

    useEffect(() => {
      const storeData = datastore.state.get()[shape.id].get() as IAnalystQuestionAnalysisShapeExternalData;
      storeData.dataLoading?.set(dataLoading);
    }, [dataLoading]);

    useEffect(() => {
      const storeData = datastore.state.get()[shape.id].get() as IAnalystQuestionAnalysisShapeExternalData;
      storeData.configLoading?.set(configLoading);
    }, [configLoading]);

    useEffect(() => {
      const storeData = datastore.state.get()[shape.id].get() as IAnalystQuestionAnalysisShapeExternalData;
      storeData.onFieldFacetManagerFieldChange?.set(fieldFacetsManager.onFieldChange);
    }, [fieldFacetsManager]);

    const onSelectionChange = useCallback(
      (selection: IAnalystQuestionAnalysisSelection) => {
        tldrawEditor.updateShapes([
          {
            id: shape.id,
            type: shape.type,
            props: {
              selection,
            },
          },
        ]);
      },
      [shape, tldrawEditor],
    );

    useEffect(() => {
      const storeData = datastore.state.get()[shape.id].get() as IAnalystQuestionAnalysisShapeExternalData;
      storeData.onSelectionChange?.set(onSelectionChange);
    }, [onSelectionChange]);

    useEffect(() => {
      const storeData = datastore.state.get()[shape.id].get() as IAnalystQuestionAnalysisShapeExternalData;
      storeData.selection?.set(selection || {});
    }, [selection]);

    useEffect(() => {
      const storeData = datastore.state.get()[shape.id].get() as IAnalystQuestionAnalysisShapeExternalData;
      storeData.slotsData?.set(slotsData);
    }, [slotsData]);

    useEffect(() => {
      const storeData = datastore.state.get()[shape.id].get() as IAnalystQuestionAnalysisShapeExternalData;
      storeData.filteredData?.set(filteredData);
    }, [filteredData]);

    useEffect(() => {
      this._updateTitleLabel(t, shape, data.length, filteredData.length);
    }, [t, shape, data, filteredData]);

    const fetchFilteredAnalystQuestionsData = useCallback(async () => {
      // Any changes in the process of getting the filtered data must be handled in
      // the `getFilteredAnalystQuestionsData` function since this function
      // is also used by the `BoardSearchManager` to get the filtered analysis data.
      return getFilteredAnalystQuestionsData(apolloClient, selectedFacetValues, t);
    }, [apolloClient, selectedFacetValues, t]);

    // Fetch the filtered data and set it in the state
    useEffect(() => {
      fetchFilteredAnalystQuestionsData().then((filteredAnalystQuestionsData) => {
        setFilteredData(filteredAnalystQuestionsData);
      });
    }, [fetchFilteredAnalystQuestionsData]);

    // Sets the slots data in the datastore
    useEffect(() => {
      const fieldsFilteredData: Record<string, any> = {};
      const fieldsData: Record<string, any> = {};
      DEFAULT_ANALYST_QUESTION_ANALYSIS_FIELDS.forEach((field: string) => {
        const fieldFilteredData = this._getFieldFilteredData(data, selectedFacetValues, field);
        fieldsFilteredData[field] = this._getFieldValues(fieldFilteredData, field, selectedFacetValues);

        fieldsData[field] = this._getFieldValues(data, field, selectedFacetValues);
      });
      setSlotsData(fieldsFilteredData);

      // Get all the unique values for each field
      const filteredFirmData = fieldsFilteredData[FIRM_FIELD_NAME] || [];
      const filteredFirmTypeData = fieldsFilteredData[FIRM_TYPE_FIELD_NAME] || [];
      const filteredTopicsData = fieldsFilteredData[TOPICS_FIELD_NAME] || [];
      const filteredSegmentData = fieldsFilteredData[SEGMENT_FIELD_NAME] || [];
      const filteredEventDateData = fieldsFilteredData[EVENT_DATE_FIELD_NAME] || [];

      let firmsList = fieldsData[FIRM_FIELD_NAME] || [];
      let firmTypesList = fieldsData[FIRM_TYPE_FIELD_NAME] || [];
      let segmentsList = fieldsData[SEGMENT_FIELD_NAME] || [];
      let topicsList = DataUtils.getUniqueValues(fieldsData[TOPICS_FIELD_NAME], "name") || [];
      // Rollups filtered related data
      const firmDataMap = d3.rollup(
        filteredFirmData,
        (d) => d3.sum(d, (d) => d.count),
        (d: any) => d.name,
      );
      const firmTypeDataMap = d3.rollup(
        filteredFirmTypeData,
        (d) => d3.sum(d, (d) => d.count),
        (d: any) => d.name,
      );
      const segmentDataMap = d3.rollup(
        filteredSegmentData,
        (d) => d3.sum(d, (d) => d.count),
        (d: any) => d.name,
      );
      const topicsDataMap = d3.rollup(
        filteredTopicsData,
        (v) => d3.sum(v, (d) => d.count),
        (d: { name: string; count: number; label: string }) => d.name,
      );

      const allFirms = firmsList.map(({ name }: { name: string }) => {
        return {
          key: name,
          count: firmDataMap.get(name) || 0,
        };
      });

      const allFirmTypes = firmTypesList.map(({ name }: { name: string }) => {
        return {
          key: name,
          count: firmTypeDataMap.get(name) || 0,
        };
      });

      const allSegments = segmentsList.map(({ name }: { name: string }) => {
        return {
          key: name,
          count: segmentDataMap.get(name) || 0,
        };
      });

      const allTopics = topicsList.map(({ name }: { name: string }) => {
        return {
          key: name,
          count: topicsDataMap.get(name) || 0,
        };
      });

      const eventDateRange = DataUtils.getDateRange(filteredEventDateData, EVENT_DATE_FIELD_NAME);

      // @ts-expect-error TODO: Fix this
      setDistinctValues({
        [FIRM_FIELD_NAME as keyof IAnalystQuestionAnalysisFacets]: allFirms,
        [FIRM_TYPE_FIELD_NAME as keyof IAnalystQuestionAnalysisFacets]: allFirmTypes,
        [SEGMENT_FIELD_NAME as keyof IAnalystQuestionAnalysisFacets]: allSegments,
        [TOPICS_FIELD_NAME as keyof IAnalystQuestionAnalysisFacets]: allTopics,
        [EVENT_DATE_FIELD_NAME as keyof IAnalystQuestionAnalysisFacets]: eventDateRange,
      });
    }, [data, selectedFacetValues]);

    const onSelectedIdsChanged = useCallback(
      (selectedIds: Array<string>) => {
        const storeData = datastore.state.get()[shape.id].get() as IAnalystQuestionAnalysisShapeExternalData;
        storeData.dataGridSelectedIds?.set(selectedIds);
      },
      [datastore, shape],
    );

    // Update the datastore onSelectedIdsChanged method when it changes
    useEffect(() => {
      const storeData = datastore.state.get()[shape.id].get() as IAnalystQuestionAnalysisShapeExternalData;
      storeData.onSelectedIdsChanged?.set(onSelectedIdsChanged);
    }, [datastore, onSelectedIdsChanged]);

    const onUpdatePreferences = useCallback(
      (key: keyof IAnalystQuestionAnalysisPreferences, newPreferences: Record<string, any>) => {
        tldrawEditor.updateShapes([
          {
            id: shape.id,
            type: shape.type,
            props: {
              preferences: {
                ...shape.props.preferences,
                [key]: newPreferences,
              },
            },
          },
        ]);
      },
      [shape, tldrawEditor],
    );

    // Update the datastore onUpdatePreferences method when it changes
    useEffect(() => {
      const storeData = datastore.state.get()[shape.id].get() as IAnalystQuestionAnalysisShapeExternalData;
      storeData.onUpdatePreferences?.set(onUpdatePreferences);
    }, [datastore, onUpdatePreferences]);

    // Update the datastore preferences when they change
    useEffect(() => {
      const storeData = datastore.state.get()[shape.id].get() as IAnalystQuestionAnalysisShapeExternalData;
      storeData.preferences?.set(props.preferences || {});
    }, [datastore, props.preferences]);

    // Group similar questions action handler
    const groupSimilarQuestions = useCallback(() => {
      const storeData = datastore.state.get()[shape.id].get() as IAnalystQuestionAnalysisShapeExternalData;
      const dataGridActions: IDataGridActions = storeData.dataGridActions?.get() || {};
      const dataGridShapeId = tldrawEditor.getSortedChildIdsForParent(shape.id).find((id) => {
        return tldrawEditor.getShape(id)?.type === DataGridShapeUtil.type;
      });
      if (dataGridShapeId) {
        const dataGridShape = tldrawEditor.getShape(dataGridShapeId) as IDataGridShape;
        if (dataGridShape) {
          const rowGroupingModel = [...(dataGridShape.props.preferences?.rowGroupingModel || [])];

          // Update the data grid columns to hide the clustering column
          const columns = dataGridShape.props.config?.columns || {};
          const newColumns = columns.map((column: IDataGridColumnDef) => {
            const otherProps: Partial<IDataGridColumnDef> = {};
            // Force the clustering column to be hidden while enabling/disabling group similar questions
            if (column.field === QUESTIONS_CLUSTERING_FIELD) {
              otherProps["isVisible"] = false;
            }
            return {
              ...column,
              ...otherProps,
            };
          });

          // Update the row grouping model
          const clusterIndex = rowGroupingModel.findIndex((group) => group === QUESTIONS_CLUSTERING_FIELD);
          if (clusterIndex > -1) {
            rowGroupingModel.splice(clusterIndex, 1);
          } else {
            rowGroupingModel.splice(0, 0, QUESTIONS_CLUSTERING_FIELD);
          }

          tldrawEditor.updateShapes([
            {
              id: dataGridShape.id,
              type: dataGridShape.type,
              props: {
                config: {
                  ...dataGridShape.props.config,
                  columns: newColumns,
                },
                preferences: {
                  ...dataGridShape.props.preferences,
                  rowGroupingModel,
                },
              },
            },
          ]);

          // Update datastore actions state
          dataGridActions?.[DataGridActionCategory.Manage]?.forEach((action: IDataGridAction) => {
            if (action.key === GRID_ACTION_GROUP_SIMILAR_QUESTIONS) {
              action.isSelected = rowGroupingModel.includes(QUESTIONS_CLUSTERING_FIELD);
            }
          });
          storeData.dataGridActions?.set(dataGridActions);
        }
      }
    }, [shape, tldrawEditor]);

    // Add data grid actions to the datastore
    useEffect(() => {
      const storeData = datastore.state.get()[shape.id].get() as IAnalystQuestionAnalysisShapeExternalData;
      const dataGridShapeId = tldrawEditor.getSortedChildIdsForParent(shape.id).find((id) => {
        return tldrawEditor.getShape(id)?.type === DataGridShapeUtil.type;
      });
      const dataGridShape = dataGridShapeId ? (tldrawEditor.getShape(dataGridShapeId) as IDataGridShape) : undefined;
      storeData.dataGridActions?.set({
        [DataGridActionCategory.Manage]: [
          {
            key: GRID_ACTION_GROUP_SIMILAR_QUESTIONS,
            title: t("Components.Analyses.AnalystQuestionAnalysis.DataGrid.Actions.GroupSimilarQuestions"),
            onAction: groupSimilarQuestions,
            type: DataGridActionType.Checkbox,
            isSelected: dataGridShape?.props.preferences?.rowGroupingModel?.includes(QUESTIONS_CLUSTERING_FIELD),
          },
        ],
      });
    }, [datastore, t, groupSimilarQuestions, shape]);

    const onShowFeedbackDialog = useCallback(() => {
      setIsFeedbackDialogOpen(true);
    }, []);

    const onCloseFeedbackDialog = useCallback(() => {
      setIsFeedbackDialogOpen(false);
    }, []);

    const onShowPreferencesDialog = useCallback(() => {
      setIsPreferencesDialogOpen(true);
    }, []);

    const onClosePreferencesDialog = useCallback(() => {
      setIsPreferencesDialogOpen(false);
    }, []);

    // Create a callback to get the toolbar for child shapes to show
    const getToolbar = useCallback(() => {
      if (toolbarContainerRef === null) {
        return null;
      }

      // Take anlaysis preferences & facet sort
      const facetSort = analysisPreferences?.facetSort || [];
      const facetSortMap =
        facetSort.reduce(
          (acc, sort) => {
            if (sort && sort.field && sort.sort) {
              acc[sort.field] = sort.sort;
            }
            return acc;
          },
          {} as Record<string, FacetDisplaySortType>,
        ) || {};

      // Takes data store
      const storeData = datastore.state.get()[shape.id].get() as IAnalystQuestionAnalysisShapeExternalData;
      const dataGridSelectedIds = storeData.dataGridSelectedIds?.get() || [];
      const addToDocument = config.dataGrid?.addToDocument;

      // Split button options
      const options: SplitButtonProps["options"] = [
        {
          value: AnalysisToolbarActions.Reset,
          label: t("Components.Charts.Reset"),
          disabled: !selectedFacetValues || Object.keys(selectedFacetValues).length === 0,
        },
        {
          value: AnalysisToolbarActions.DownloadCsv,
          label: t("Components.Charts.DownloadCsv", { count: dataGridSelectedIds.length || filteredData.length }),
        },
      ];

      if (addToDocument) {
        options.push({
          value: AnalysisToolbarActions.AddToDocument,
          label: t("Components.Charts.AddItems", { count: dataGridSelectedIds.length || filteredData.length }),
        });
      }

      options.push(
        {
          value: AnalysisToolbarActions.ProvideFeedback,
          label: t("Components.Charts.ProvideFeedback"),
          disabled: dataGridSelectedIds.length === 0 || filteredData.length === 0,
        },
        {
          value: AnalysisToolbarActions.Preferences,
          label: `${t("Components.Analyses.Common.PreferencesDialog.Preferences")}...`,
        },
      );

      return createPortal(
        <AnalysisToolbar<IAnalystQuestionAnalysisFacets>
          fields={toolbar?.availableFields || []}
          selectedFacetValues={selectedFacetValues}
          distinctValues={distinctValues as Record<keyof IAnalystQuestionAnalysisFacets, any>}
          dateRangeShortcuts={dateRangeShortcuts}
          fieldsConfig={analystQuestionAnalysisFieldsConfig}
          fieldsSort={facetSortMap}
          onFieldChange={fieldFacetsManager.onFieldChange}
          onSortChange={_onFacetSortChange.bind(undefined, analysisPreferences)}
        >
          {/* DATA SHAPES */}
          {analysisPreferences?.showDataToDisplayInToolbar && (
            <Grid item sx={{ mr: 6, padding: "10px", width: 150 }}>
              <FormControl sx={{ width: 140 }}>
                <InputLabel id={`available-shapes-${shape.id}`}>{t("Components.Charts.DataToDisplay")}</InputLabel>
                <Select
                  sx={{ width: 150 }}
                  id={`available-shapes-${shape.id}`}
                  label={t("Components.Charts.DataToDisplay")}
                  multiple
                  value={childShapeTypes || DataUtils.getImmutableEmptyArray<string>()}
                  onChange={(event) => {
                    this._onAvailableShapesChange(
                      t,
                      shape,
                      event.target.value as Array<string>,
                      childShapeTypes,
                      config,
                      data.length,
                      filteredData.length,
                    );
                  }}
                  renderValue={(selected) =>
                    t("Components.Charts.SelectedOfTotal", {
                      selected: selected.length,
                      total: config.allowedChildShapes?.length,
                    })
                  }
                  MenuProps={{
                    classes: { paper: "analysis-select-menu" },
                    MenuListProps: { classes: { root: "analysis-select-menu-list" } },
                  }}
                >
                  {config.allowedChildShapes?.map((allowedShape) => {
                    return (
                      <MenuItem
                        key={`${allowedShape.type}${this._appendComponentKey(allowedShape.componentKey)}`}
                        value={`${allowedShape.type}${this._appendComponentKey(allowedShape.componentKey)}`}
                        classes={{ selected: "analysis-select-menu-item" }}
                      >
                        <Checkbox
                          checked={childShapeTypes.includes(
                            `${allowedShape.type}${this._appendComponentKey(allowedShape.componentKey)}`,
                          )}
                        />
                        <ListItemText primary={allowedShape.componentLabel} />
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid item sx={{ padding: "10px" }}>
            <SplitButton
              options={options}
              handleClick={(option: string) => {
                if (AnalysisToolbarActions.Preferences === option) {
                  onShowPreferencesDialog();
                } else if (AnalysisToolbarActions.Reset === option) {
                  fieldFacetsManager.onResetFacets();
                } else {
                  this.onSplitButtonClick(
                    shape,
                    config,
                    filteredData,
                    selectedFacetValues,
                    dataGridSelectedIds,
                    t,
                    apolloClient,
                    option,
                    onShowFeedbackDialog,
                  );
                }
              }}
            />
          </Grid>
        </AnalysisToolbar>,
        toolbarContainerRef,
      );
    }, [
      analysisPreferences,
      apolloClient,
      childShapeTypes,
      config,
      data,
      datastore,
      dateRangeShortcuts,
      distinctValues,
      fieldFacetsManager.onFieldChange,
      filteredData,
      props,
      selectedFacetValues,
      toolbar,
      toolbarContainerRef,
    ]);

    // Update the datastore with the getToolbar callback
    useEffect(() => {
      const storeData = datastore.state.get()[shape.id].get() as IAnalystQuestionAnalysisShapeExternalData;
      storeData.getToolbar.set(getToolbar);
    }, [getToolbar]);

    // Y axis tick format
    const yAxisTickFormat = useCallback(
      (label: string) => {
        return ChartUtils.formatOverflowAxisLabel(label, config.fontSize, config.marginLeft, "ellipsis");
      },
      [config.fontSize],
    );

    // Update the yAxisTickFormat callback
    useEffect(() => {
      const storeData = datastore.state.get()[shape.id].get() as IAnalystQuestionAnalysisShapeExternalData;
      storeData.yAxisTickFormat?.set(yAxisTickFormat);
    }, [yAxisTickFormat]);

    // These are used by the feedback dialog
    const storeData = datastore?.state.get()[shape.id]?.get() as IAnalystQuestionAnalysisShapeExternalData;
    const dataGridSelectedIds = storeData?.dataGridSelectedIds?.get() || [];

    return (
      <>
        {isEditing || isSelected || isChildSelected ? (
          <SVGContainer>
            <DashedOutlineBox className="tl-group" bounds={bounds} zoomLevel={zoomLevel} />
          </SVGContainer>
        ) : null}
        <DataframeBackground
          enableBackgroundColor={!!props.enableBackground}
          enableBorder={!isHovered && !isEditing && !isSelected && !isChildSelected && !!props.enableBackground}
          bounds={bounds}
        />
        <HTMLContainer
          className="topic-discussion-analysis-shape"
          style={{
            background: "transparent",
            pointerEvents: "all",
          }}
        >
          <div onPointerDown={handleInputPointerDown}>
            <div
              ref={setToolbarContainerRef}
              style={{
                marginTop: -100,
                display: "flex",
                justifyContent: "center",
                position: "absolute",
                top: bounds.y,
                left: bounds.midX,
              }}
            />
            {(isEditing || isInteracting || isChildEditing || isChildInteracting) && getToolbar()}
            <AnalysisFeedbackDialog
              dataItemIds={dataGridSelectedIds}
              dataItemType={AnalysisFeedbackDataItemType.AnalystQuestion}
              onClose={onCloseFeedbackDialog}
              open={isFeedbackDialogOpen || false}
            />
            {isPreferencesDialogOpen && (
              <AnalysisPreferencesDialog<IAnalystQuestionAnalysisFacets>
                dateRangeShortcuts={dateRangeShortcuts}
                distinctValues={DataUtils.getImmutableEmptyObject()}
                selectedFacetValues={DataUtils.getImmutableEmptyObject()}
                fields={DataUtils.getImmutableEmptyArray()}
                fieldsConfig={analystQuestionAnalysisFieldsConfig}
                onCancelPreferences={() => {
                  onClosePreferencesDialog();
                }}
                onFieldChange={fieldFacetsManager.onFieldChange}
                open={isPreferencesDialogOpen || false}
                onClose={onClosePreferencesDialog}
                onSavePreferences={(toolbarPreferences) => {
                  onClosePreferencesDialog();
                  this._saveToolbarPreferences(shape, toolbarPreferences, fieldFacetsManager);
                }}
                toolbarFields={toolbar?.availableFields || DataUtils.getImmutableEmptyArray()}
                toolbarFieldFacets={selectedFacetValues}
                // Options props
                isBackgroundEnabled={props.enableBackground || false}
                isDataToDisplayInToolbarEnabled={analysisPreferences?.showDataToDisplayInToolbar || false}
                hideStartColor={true}
                hideSubItems={true}
              />
            )}
          </div>
        </HTMLContainer>
      </>
    );

    // *********************************************
    // Render private methods
    // *********************************************/
    /**
     * Updates facet sort preferences for the given field.
     *
     * @param analysisPreferences Analysis preferences stored on shape props
     * @param fieldId Field id to update the facet sort updates
     * @param sort Latest sort
     */
    function _onFacetSortChange(
      analysisPreferences: IAnalystQuestionAnalysisPreferences["analysis"],
      fieldId: string,
      sort: FacetDisplaySortType,
    ) {
      const facetSort = [...(analysisPreferences?.facetSort || [])];
      let fieldIndex = facetSort.findIndex((sortOption) => sortOption.field === fieldId);
      if (fieldIndex > -1) {
        facetSort.splice(fieldIndex, 1, {
          field: fieldId,
          sort,
        });
      } else {
        facetSort.push({
          field: fieldId,
          sort,
        });
      }
      onUpdatePreferences("analysis", {
        ...analysisPreferences,
        facetSort,
      });
    }
  }

  // *********************************************
  // Private methods, event handlers
  // *********************************************/
  /**
   * Checks the existing child shapes & alters (delete/create) based on the latest user selection.
   * @param t
   * @param shape
   * @param selectedComponentLabels
   * @param childShapeTypes
   * @param facets
   * @param config
   */
  private _onAvailableShapesChange(
    t: TFunction<"translation", undefined>,
    shape: IAnalystQuestionAnalysisShape,
    value: Array<string>,
    childShapeTypes: Array<string>,
    config: IAnalystQuestionAnalysisConfig,
    allQuestionsCount: number,
    selectedQuestionsCount: number,
  ) {
    let shapeTypesToCreate: Array<string> = [];
    let shapeTypesToDelete: Array<string> = [];
    if (value.length === 0) {
      shapeTypesToDelete = childShapeTypes;
    } else if (childShapeTypes.length === 0) {
      shapeTypesToCreate = value;
    } else {
      value.forEach((v) => {
        if (!childShapeTypes.includes(v)) {
          shapeTypesToCreate.push(v);
        }
      });
      childShapeTypes.forEach((v) => {
        if (!value.includes(v)) {
          shapeTypesToDelete.push(v);
        }
      });
    }

    if (shapeTypesToDelete.length > 0) {
      const shapesToDelete = this.editor.getSortedChildIdsForParent(shape.id).filter((child) => {
        const childShape = this.editor.getShape(child);
        if (!childShape) {
          return false;
        }
        const childShapeProps = childShape.props as IChartShapeProps;
        return shapeTypesToDelete.includes(`${childShape.type}${this._appendComponentKey(childShapeProps.field)}`);
      });

      this.editor.deleteShapes(shapesToDelete);
    }
    if (shapeTypesToCreate.length > 0) {
      this._createChildShapes(shape, shapeTypesToCreate, config, t);
    }
    if (shapeTypesToCreate.includes("lockedText")) {
      this._updateTitleLabel(t, shape, allQuestionsCount, selectedQuestionsCount);
    }
  }

  // *********************************************
  // Private methods
  // *********************************************/
  /**
   * Initial creation of child shapes based on the config.
   *
   * @param shape Current shape
   * @param config Config to use
   * @param t Translation function
   */
  private _initialRenderOfChildren(
    shape: IAnalystQuestionAnalysisShape,
    config: IAnalystQuestionAnalysisConfig,
    t: TFunction<"translation", undefined>,
  ) {
    const allShapes = config.allowedChildShapes.map(
      (allowedShape) => `${allowedShape.type}${this._appendComponentKey(allowedShape.componentKey)}`,
    );
    this._createChildShapes(shape, config.initialShapes || allShapes, config, t);
  }

  /**
   * Filters the data based on the facets & ignores the given field
   *
   * @param data Data to filter
   * @param facets Facets to filter by
   * @param field Field for which the filtered data using, so that is ignored in the filtering
   */
  private _getFieldFilteredData(data: Array<IAnalystQuestionResult>, facets: Record<string, any>, facetField: string) {
    return applyFacetFilterOnAnalystQuestionsData(data, {
      ...facets,
      [facetField]: undefined, // Ignores the field related facets
    });
  }

  /**
   * Extracts the field values from the data
   *
   * @param data Data from which to extract the field values
   * @param field Field to extract
   * @returns
   */
  private _getFieldValues(data: Array<IAnalystQuestionResult>, field: string, facets: IAnalystQuestionAnalysisFacets) {
    // Formatting for Topics which identifies the cooccurrence
    if (field === TOPICS_FIELD_NAME) {
      const selectedTopics = facets[TOPICS_FIELD_NAME];
      const topics: Array<string> = [];
      data.forEach((row: any) => {
        const topic = row[field];
        topics.push(...topic);
      });
      const topicsMap = d3.rollups(
        topics,
        (v) => v.length,
        (d) => d,
      );
      const topicsArray: Array<Record<string, any>> = [];
      topicsMap.forEach((topic) => {
        const topicText = topic[0];
        const topicCount = topic[1];
        let cooccurrenceTopicsCount = 0;
        if (selectedTopics && selectedTopics.length > 0) {
          cooccurrenceTopicsCount = this._getCooccurrenceTopicQuestions(data, [...selectedTopics, topicText]);
          if (cooccurrenceTopicsCount > 0) {
            topicsArray.push({
              name: topicText,
              count: cooccurrenceTopicsCount,
              label: "cooccurrence",
              text: cooccurrenceTopicsCount > 0 ? `${cooccurrenceTopicsCount}/${topicCount}` : topicCount,
            });
          }
        }
        topicsArray.push({
          name: topicText,
          count: topicCount - cooccurrenceTopicsCount,
          label: "direct",
          text: cooccurrenceTopicsCount > 0 ? "" : topicCount,
        });
      });
      return topicsArray;
    } else if (field === EVENT_DATE_FIELD_NAME) {
      return data.map((row: any) => {
        return {
          ...row,
          [field]: new Date(row[field]),
        };
      });
    } else if (field === SEGMENT_FIELD_NAME) {
      const segments = data.map((row: any) => row[field]);
      const segmentsMap = d3.rollups(
        segments.flat(),
        (v) => v.length,
        (d) => d,
      );
      return segmentsMap.map((segment) => {
        return {
          name: segment[0],
          count: segment[1],
        };
      });
    }
    const fieldValues = data.map((row: any) => row[field]);
    const fieldValuesMap = d3.rollups(
      fieldValues,
      (v) => v.length,
      (d) => d,
    );
    return fieldValuesMap.map((fieldValue) => {
      return {
        name: fieldValue[0],
        count: fieldValue[1],
      };
    });
  }

  /**
   * Gives the count of the questions, where the given topics co-occur
   * @param data Data to check
   * @param topics Topics to check for co-occurrence
   * @returns
   */
  private _getCooccurrenceTopicQuestions(data: Array<IAnalystQuestionResult>, topics: Array<string>) {
    const relatedQuestions = data.filter((item) => {
      const exists = topics.map((topic) => {
        const itemTopics = item[TOPICS_FIELD_NAME].map((v) => v.trim());
        const existingDataTopics = itemTopics.filter((itemTopic) => itemTopic === topic);
        return existingDataTopics.length > 0;
      });
      return exists.length > 0 && !exists.includes(false);
    });
    return relatedQuestions.length;
  }

  /**
   * Creates the child shapes based on the config allowedChildShapes order
   *
   * @param shape Current shape
   * @param shapesToCreate Shapes to create
   * @param config Config for the current shape
   * @param t Translation function
   */
  private _createChildShapes(
    shape: IAnalystQuestionAnalysisShape,
    shapesToCreate: Array<string>,
    config: IAnalystQuestionAnalysisConfig,
    t: TFunction<"translation", undefined>,
  ) {
    const { id } = shape;
    const tldrawEditor = this.editor;
    const latestShape = tldrawEditor.getShape(id) as IAnalystQuestionAnalysisShape;
    // Takes latest props
    const preferences = latestShape?.props?.preferences;

    // Follows the order as per given config.allowedChildShapes
    const readyToCreateShapes = shapesToCreate.map((shapeToCreate) => {
      const shapeType = shapeToCreate.split(":")[0];
      const field = shapeToCreate.split(":")[1];

      const props: Record<string, any> = {};
      if (shapeType === "dataGrid") {
        props["w"] = 1500;
        props["config"] = {
          ...config.dataGrid,
          fontSize: config.fontSize,
          autoResize: true,
        };
        props["preferences"] = { ...preferences?.dataGrid };
      }
      if (!["dataGrid", "lockedText"].includes(shapeType)) {
        props["field"] = field;
      }

      const shapeId = createShapeId(uuidV4());
      const chartPosition = CHART_POSITIONS[field || shapeType];
      return {
        id: shapeId,
        parentId: id,
        type: shapeType,
        props,
        ...chartPosition,
      };
    });

    this.editor.createShapes(readyToCreateShapes);
  }

  /**
   * Uses facets data to update the data frame title label.
   *
   * @param shape Current parent data frame shape.
   * @param facets Facets that changed.
   * @returns
   */
  private _updateTitleLabel(
    t: TFunction<"translation", undefined>,
    shape: IAnalystQuestionAnalysisShape,
    allQuestionsCount: number,
    selectedQuestionsCount: number,
  ) {
    const lockedTextShapeId = this.editor.getSortedChildIdsForParent(shape.id).find((childId) => {
      const childShape = this.editor.getShape(childId);
      if (childShape) {
        return childShape.type === "lockedText";
      }
      return false;
    });

    if (!lockedTextShapeId) {
      return;
    }
    const lockedTextShape = this.editor.getShape(lockedTextShapeId);
    const textLabel = t("Components.Analyses.AnalystQuestionAnalysis.LabelTemplate", {
      allQuestions: allQuestionsCount,
      selectedQuestions: selectedQuestionsCount,
    });

    if (!lockedTextShape) {
      return;
    }

    this.editor.updateShapes([
      {
        id: lockedTextShape.id,
        type: lockedTextShape.type,
        props: {
          text: textLabel,
        },
      },
    ]);
  }

  /**
   * Takes preferences from the dialog & updates to shape props.
   *
   * @param shape Shape to update
   * @param prefereces Selected preferences
   * @param fieldFacetsManager Field facet manager which helps to change the facet values
   */
  private _saveToolbarPreferences(
    shape: IAnalystQuestionAnalysisShape,
    prefereces: IAnalysisPreferencesDialogState,
    fieldFacetsManager: FieldFacetsManageStateType<IAnalystQuestionAnalysisFacets>,
  ) {
    const oldToolbarFields = shape.props.toolbar?.availableFields;
    const removedFields = oldToolbarFields?.filter((field) => !prefereces.toolbarFields.includes(field));
    this.editor.updateShapes([
      {
        id: shape.id,
        type: shape.type,
        props: {
          enableBackground: prefereces.isBackgroundEnabled,
          toolbar: {
            availableFields: prefereces.toolbarFields,
          },
          preferences: {
            ...shape.props.preferences,
            analysis: {
              ...shape.props.preferences.analysis,
              showDataToDisplayInToolbar: prefereces.isDataToDisplayInToolbarEnabled,
            },
          },
        },
      },
    ]);

    fieldFacetsManager.onClearSelectedFacetValues(removedFields as Array<keyof IAnalystQuestionAnalysisFacets>);
  }

  /**
   * Updates the config with latest fills
   *
   * @param config Config to update
   * @returns
   */
  private _addComponentFillsToConfig(config: IAnalystQuestionAnalysisConfig): IAnalystQuestionAnalysisConfig {
    const components = config.components;
    if (!components) {
      return config;
    }
    const latestComponenents: Record<string, any> = {};
    Object.keys(components).forEach((componentKey) => {
      const componentConfig = components[componentKey].config;
      const markOptions = componentConfig.markOptions || componentConfig.barMarkOptions;
      if (
        componentConfig &&
        typeof markOptions.fill === "string" &&
        [SEGMENT_FIELD_NAME, FIRM_TYPE_FIELD_NAME, FIRM_FIELD_NAME].includes(
          componentKey as typeof SEGMENT_FIELD_NAME | typeof FIRM_TYPE_FIELD_NAME | typeof FIRM_FIELD_NAME,
        )
      ) {
        componentConfig.barMarkOptions.fill = this._analystQuestionBarFill(componentKey, componentConfig.barMarkOptions.fill);
      } else if (componentConfig && typeof markOptions.fill === "string" && componentKey === TOPICS_FIELD_NAME) {
        componentConfig.barMarkOptions.fill = this._analystQuestionBarFillTopics(
          componentKey,
          componentConfig.barMarkOptions.fill,
        );
      } else if (componentConfig && typeof markOptions.fill === "string" && componentKey === EVENT_DATE_FIELD_NAME) {
        componentConfig.markOptions.fill = this._analystQuestionHistogramFill(componentKey, componentConfig.markOptions.fill);
      }
      latestComponenents[componentKey] = {
        ...components[componentKey],
        config: componentConfig,
      };
    });
    config.components = latestComponenents;
    return config as IAnalystQuestionAnalysisConfig;
  }

  /**
   * Fill the bar with color if the facet field is selected
   * @param facetField Facet field name
   * @param color Color to fill the bar
   * @returns
   */
  private _analystQuestionBarFill(facetField: string, color: string) {
    return (facets: IAnalystQuestionAnalysisFacets, dataItem: { name: string; count: number }) => {
      const selectedValues =
        facets[facetField as typeof FIRM_FIELD_NAME | typeof FIRM_TYPE_FIELD_NAME | typeof SEGMENT_FIELD_NAME];
      if (!selectedValues || selectedValues.length == 0 || selectedValues.includes(dataItem.name)) {
        return color;
      } else {
        return StandardHtmlColors.gray30;
      }
    };
  }

  /**
   * Fill the bar with color if the facet field is selected
   * @param facetField Facet field name
   * @param color Color to fill the bar
   * @returns
   */
  private _analystQuestionBarFillTopics(facetField: typeof TOPICS_FIELD_NAME, color: string) {
    return (facets: IAnalystQuestionAnalysisFacets, dataItem: { name: string; count: number; label: string }) => {
      const selectedValues = facets[facetField] || [];
      if (dataItem.label && dataItem.label === "direct" && selectedValues.length > 0) {
        return `${StandardHtmlColors.gray30}80`;
      }
      if (dataItem.label && dataItem.label === "cooccurrence" && selectedValues.includes(dataItem.name)) {
        return `${color}CC`;
      }
      if (dataItem.label && dataItem.label === "cooccurrence" && selectedValues.length > 0) {
        return `${color}66`;
      }
      return color;
    };
  }

  /**
   * Fill the rects with color if the facet field is selected
   * @param facetField Facet field name
   * @param color Color to fill the bar
   * @returns
   */
  private _analystQuestionHistogramFill(facetField: typeof EVENT_DATE_FIELD_NAME, color: string) {
    return (facets: IAnalystQuestionAnalysisFacets, dataItem: Record<string, any>) => {
      const selectedValues = facets[facetField];
      if (selectedValues && (selectedValues.from || selectedValues.to)) {
        const inRange = ChartUtils.isInRange(
          `${selectedValues?.from || ""}#${selectedValues?.to || ""}`,
          dataItem[EVENT_DATE_FIELD_NAME],
        );
        return inRange ? color : StandardHtmlColors.gray30;
      }
      return color;
    };
  }

  /**
   * Returns the key to be appended to the shapeType
   *
   * @param key Key to be appended
   * @returns
   */
  private _appendComponentKey(key: string) {
    return key ? `:${key}` : "";
  }
}
