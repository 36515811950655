import { gql } from "@apollo/client";

export const DOCUMENTS_PAGE_USER_PREFERENCE_QUERY = gql`
  query DocumentsPageUserPreferences($key: String!) {
    userPreference(key: $key) {
      key
      data
    }
  }
`;

export const LIBRARY_PAGE_USER_PREFERENCE_QUERY = gql`
  query LibraryPageUserPreferences($key: String!) {
    userPreference(key: $key) {
      key
      data
    }
  }
`;

export const WORKSPACE_BOARD_USER_PREFERENCE_QUERY = gql`
  query WorkspaceBoardUserPreferences($key: String!) {
    userPreference(key: $key) {
      key
      data
    }
  }
`;

export const WORKSPACES_PAGE_USER_PREFERENCE_QUERY = gql`
  query WorkspacesPageUserPreferences($key: String!) {
    userPreference(key: $key) {
      key
      data
    }
  }
`;

export const WORKSPACE_PAGE_USER_PREFERENCE_QUERY = gql`
  query WorkspacePageUserPreferences($key: String!) {
    userPreference(key: $key) {
      key
      data
    }
  }
`;

export const WORKSPACE_USER_PREFERENCE_QUERY = gql`
  query WorkspaceUserPreferences($key: String!) {
    userPreference(key: $key) {
      key
      data
    }
  }
`;
