import { FileSourceType } from "@bigpi/cookbook";
import { Box, Card, Grid, CardHeader, ButtonBase, Typography, Checkbox, CardActions } from "@mui/material";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { FileIcon } from "Components/FileIcon/FileIcon";
import { FilePreviewDialog } from "Components/FilePreviewDialog/FilePreviewDialog";
import { formatFileCreatedAt } from "Utils/FileUtils";
import { LibraryListMenu } from "./LibraryListMenu";

interface ILibraryGridItemProps {
  checked: boolean;
  fileId: string;
  fileMimeType: string;
  fileName: string;
  fileCreatedAt: string;
  onSelectionChange: (id: string) => void;
  size: "small" | "medium" | "large";
}

export function LibraryGridItem(props: ILibraryGridItemProps) {
  const { checked, fileCreatedAt, fileId, fileMimeType, fileName, onSelectionChange, size } = props;
  const { t } = useTranslation();

  // State
  const [activeGridElement, setActiveGridElement] = useState<HTMLElement | null>(null);
  const [openPreview, setOpenPreview] = useState(false);

  let itemImageComponent: JSX.Element | null = null;

  const handleMouseEnter = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setActiveGridElement(event.currentTarget);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setActiveGridElement(null);
  }, []);

  const isSelectionEnabled = Boolean(activeGridElement);

  if (isSelectionEnabled || checked) {
    itemImageComponent = <Checkbox checked={checked} onChange={() => onSelectionChange(fileId)} sx={{ padding: 0 }} />;
  } else {
    itemImageComponent = <FileIcon mimetype={fileMimeType} />;
  }

  return (
    <Grid
      item
      xl={size === "small" ? 3 : size === "medium" ? 4 : 6}
      lg={size === "small" ? 2 : size === "medium" ? 3 : 4}
      md={size === "small" ? 3 : size === "medium" ? 4 : 6}
      xs={size === "small" ? 6 : size === "medium" ? 6 : 12}
      sx={{ display: "grid" }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Card
        variant="outlined"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          backgroundColor: checked ? "var(--astra-color-blue15)" : "var(--astra-color-gray7)",
          border: "none",
          ":hover": {
            backgroundColor: "var(--astra-color-gray15)",
          },
        }}
      >
        <CardHeader
          avatar={itemImageComponent}
          title={
            <ButtonBase sx={{ textAlign: "start" }} disableRipple onClick={() => setOpenPreview(true)}>
              <Typography
                sx={{
                  textAlign: "start",
                  fontSize: size === "small" ? "1rem" : size === "medium" ? "1.1rem" : "1.2rem",
                  fontWeight: "500",
                  wordBreak: "break-word",
                }}
              >
                {fileName}
              </Typography>
            </ButtonBase>
          }
          action={<LibraryListMenu fileId={fileId} />}
        />
        <CardActions sx={{ display: "block", bottom: 0 }}>
          <Box sx={{ mt: 1 }}>
            <Typography variant="caption" color="text.secondary">
              {formatFileCreatedAt(fileCreatedAt, t)}
            </Typography>
          </Box>
        </CardActions>
      </Card>
      {openPreview && (
        <FilePreviewDialog fileId={fileId} fileType={FileSourceType.Library} onClose={() => setOpenPreview(false)} />
      )}
    </Grid>
  );
}
