import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { DefaultDialogTransition } from "Components/DialogTransition/DefaultDialogTransition";
import { useUpdateWorkspaceMutation, useWorkspaceQuery } from "GraphQL/Generated/Apollo";

export interface IEditWorkspaceDescriptionDialogProps {
  workspaceId: string;
  open: boolean;
  onClose: () => void;
}

export function EditWorkspaceDescriptionDialog(props: IEditWorkspaceDescriptionDialogProps) {
  const { workspaceId, open } = props;

  const { t } = useTranslation();

  const [description, setDescription] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [descriptionFocused, setDescriptionFocused] = useState(true);

  const descriptionInputRef = useRef<HTMLInputElement>(null);

  const { data, loading, error } = useWorkspaceQuery({ variables: { id: workspaceId } });
  const [updateWorkspace] = useUpdateWorkspaceMutation();

  function onClose() {
    setErrorMessage("");
    props.onClose();
  }

  const onSave = () => {
    updateWorkspace({
      variables: {
        input: {
          id: workspaceId,
          description,
        },
      },
      refetchQueries: ["Workspace", "Workspaces"],
    })
      .then(() => {
        onClose();
      })
      .catch((error) => {
        console.error(error);
        setErrorMessage(t("Components.EditWorkspaceDescriptionDialog.Error"));
      });
  };

  useEffect(() => {
    if (data?.workspace?.description) {
      setDescription(data.workspace?.description || "");
    }
  }, [data]);

  useEffect(() => {
    if (open && descriptionInputRef.current) {
      descriptionInputRef.current.focus();
    }
  }, [descriptionInputRef.current, open]);

  return (
    <Dialog open={open} onClose={onClose} TransitionComponent={DefaultDialogTransition} fullWidth maxWidth="xs">
      <DialogTitle variant="h5">
        {t("Components.EditWorkspaceDescriptionDialog.Title", { name: data?.workspace?.name || "" })}
      </DialogTitle>
      <DialogContent>
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        <TextField
          autoFocus
          margin="dense"
          label={t("Components.EditWorkspaceDescriptionDialog.DescriptionLabel")}
          type="text"
          fullWidth
          variant="standard"
          value={description}
          onBlur={() => setDescriptionFocused(false)}
          onChange={(e) => setDescription(e.target.value ?? "")}
          onFocus={() => setDescriptionFocused(true)}
          required
          inputRef={descriptionInputRef}
          error={description === "" && !descriptionFocused}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("Global.Action.Close")}</Button>
        <Button onClick={onSave} disabled={description === ""}>
          {t("Global.Action.Save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
