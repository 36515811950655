import { ReactRenderer, SuggestionProps, SuggestionKeyDownProps } from "@bigpi/editor-tiptap";
import tippy, { Instance, GetReferenceClientRect } from "tippy.js";

// *********************************************
// Types/Interfaces/Constants
// *********************************************/
declare type ComponentType<R, P> =
  | React.ComponentClass<P>
  | React.FunctionComponent<P>
  | React.ForwardRefExoticComponent<React.PropsWithoutRef<P> & React.RefAttributes<R>>;

interface BistromCommandConfig {
  component: ComponentType<any, any>;
}

// *********************************************
// Component
// *********************************************/
export function getBistroCommandSuggestionOptions(config: BistromCommandConfig) {
  if (!config) {
    return {};
  }
  return {
    render: () => {
      let component: ReactRenderer;
      let popup: Instance[];

      return {
        onStart: (props: SuggestionProps) => {
          component = new ReactRenderer(config.component, {
            props,
            editor: props.editor,
          });

          if (!props.clientRect) {
            return;
          }

          popup = tippy("body", {
            getReferenceClientRect: props.clientRect as GetReferenceClientRect,
            appendTo: () => document.body,
            content: component.element,
            showOnCreate: true,
            interactive: true,
            trigger: "manual",
            placement: "top-start",
          });
        },

        onUpdate(props: SuggestionProps) {
          component.updateProps(props);

          if (!props.clientRect) {
            return;
          }

          popup[0].setProps({
            getReferenceClientRect: props.clientRect as GetReferenceClientRect,
          });
        },

        onKeyDown(props: SuggestionKeyDownProps) {
          if (props.event.key === "Escape") {
            popup[0].hide();

            return true;
          }
          // @ts-expect-error TODO: fix this
          return component.ref?.onKeyDown(props);
        },

        onExit() {
          if (!popup[0].state.isDestroyed) {
            popup[0].destroy();
          }

          if (component) {
            component.destroy();
          }
        },
      };
    },
  };
}
