import { IDateRange } from "@bigpi/cookbook";

export enum TopicDiscussionSummaryAnalysisFieldTypeEnum {
  AnalysisName = "analysisName",
  Category = "category",
  Event = "event",
  EventDateShortcut = "eventDateShortcut",
  EventDate = "eventDate",
  EventType = "eventType",
  Period = "period",
  Section = "section",
  Ticker = "ticker",
  Topic = "topic",
}

export interface ITopicDiscussionSummaryAnalysisFieldFacets {
  [TopicDiscussionSummaryAnalysisFieldTypeEnum.AnalysisName]?: Array<string>;
  [TopicDiscussionSummaryAnalysisFieldTypeEnum.Category]?: Array<string>;
  [TopicDiscussionSummaryAnalysisFieldTypeEnum.Event]?: Array<string>;
  [TopicDiscussionSummaryAnalysisFieldTypeEnum.EventDateShortcut]?: string;
  [TopicDiscussionSummaryAnalysisFieldTypeEnum.EventDate]?: IDateRange;
  [TopicDiscussionSummaryAnalysisFieldTypeEnum.EventType]?: Array<string>;
  [TopicDiscussionSummaryAnalysisFieldTypeEnum.Period]?: Array<string>;
  [TopicDiscussionSummaryAnalysisFieldTypeEnum.Section]?: Array<string>;
  [TopicDiscussionSummaryAnalysisFieldTypeEnum.Ticker]?: Array<string>;
  [TopicDiscussionSummaryAnalysisFieldTypeEnum.Topic]?: Array<string>;
}
