import backend from "i18next-http-backend";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { use } from "i18next";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";

use(detector)
  .use(backend)
  .use(initReactI18next)
  .init({
    // Prefixes the namespace to the returned key when using `lng: "cimode"`
    appendNamespaceToCIMode: true,

    // Use this language if requested language is not found in our `supportedLngs` list
    fallbackLng: "en",

    interpolation: {
      // Not needed for React as it escapes by default
      escapeValue: false,
    },

    // Allows "en-US" and "en-UK" to be implcitly supported when "en" is supported
    nonExplicitSupportedLngs: false,

    // Languages that can be loaded
    supportedLngs: ["en"],
  });

// TODO: Need to add more locales / make dynamic
TimeAgo.addDefaultLocale(en);
