import { Box, useTheme } from "@mui/system";
import {
  TldrawUiButton,
  TldrawUiButtonCheck,
  TldrawUiButtonIcon,
  TldrawUiButtonLabel,
  TldrawUiPopover,
  TldrawUiPopoverContent,
  TldrawUiPopoverTrigger,
  useValue,
} from "@tldraw/tldraw";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";

import { useWorkspaceBoardsQuery, SortOrder } from "GraphQL/Generated/Apollo";
import { linkToId } from "RoutePaths";

/**
 * Component to display in place of PageMenu, to render the workspace board navigation
 * Follows same pattern of DefaultPageMenu
 * Source: https://github.com/tldraw/tldraw/blob/main/packages/tldraw/src/lib/ui/components/PageMenu/DefaultPageMenu.tsx
 */
export function CustomPageMenu() {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const { t } = useTranslation();
  const theme = useTheme();

  const workspaceId = params.workspaceId || "";
  const workspaceBoardId = params.workspaceBoardId || "";

  const { data: workspaceBoardsData } = useWorkspaceBoardsQuery({
    variables: {
      workspaceId,
      orderBy: { updatedAt: SortOrder.Desc },
    },
  });

  const currentBoard = useValue(
    "",
    () => {
      return workspaceBoardsData?.workspaceBoards.find((board) => board.id === workspaceBoardId);
    },
    [workspaceBoardId, workspaceBoardsData],
  );

  return (
    <TldrawUiPopover id="pages" onOpenChange={onOpenChange} open={isOpen}>
      <TldrawUiPopoverTrigger>
        <TldrawUiButton type="menu" title={currentBoard?.name} className="tlui-page-menu__trigger">
          <div className="tlui-page-menu__name">{currentBoard?.name}</div>
          <TldrawUiButtonIcon icon="chevron-down" small />
        </TldrawUiButton>
      </TldrawUiPopoverTrigger>
      <TldrawUiPopoverContent side="bottom" align="start" sideOffset={6}>
        <Box className="tlui-page-menu__wrapper">
          <Box className="tlui-page-menu__list tlui-menu__group">
            {workspaceBoardsData?.workspaceBoards.map((board, index) => {
              return (
                <div className="tlui-page-menu__item" key={index}>
                  <TldrawUiButton
                    type="normal"
                    className="tlui-page-menu__item__button"
                    onClick={() => onBoardChange(board.id)}
                    title={"Replace me"}
                  >
                    <TldrawUiButtonLabel>{board.name}</TldrawUiButtonLabel>
                    <TldrawUiButtonCheck checked={board.id === workspaceBoardId} />
                  </TldrawUiButton>
                </div>
              );
            })}
            <Box
              className="tlui-page-menu__item"
              key={workspaceBoardsData?.workspaceBoards.length}
              sx={{ borderTop: `solid 1px ${theme.palette.divider}` }}
            >
              <TldrawUiButton
                type="normal"
                className="tlui-page-menu__item__button"
                onClick={() => onBoardChange("workspace")}
                title={"Replace me"}
              >
                <TldrawUiButtonLabel>{t("Global.Menu.BackToWorkspace")}</TldrawUiButtonLabel>
              </TldrawUiButton>
            </Box>
          </Box>
        </Box>
      </TldrawUiPopoverContent>
    </TldrawUiPopover>
  );

  /**
   * Navigates to selected board or workspace
   *
   * @param boardId Selected board id
   */
  function onBoardChange(boardId: string) {
    if (boardId === "workspace") {
      navigate(linkToId("workspace", workspaceId));
    } else {
      navigate(`/workspaces/${workspaceId}/boards/${boardId}`);
    }
  }

  /**
   * Handles open state change of the popover
   *
   * @param open Open state of the popover
   */
  function onOpenChange(open: boolean) {
    setIsOpen(open);
  }
}
