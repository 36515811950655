import { FileSourceType } from "@bigpi/cookbook";
import { ShapeProps } from "@tldraw/tlschema";
import { T } from "@tldraw/validate";

import { IFilePreviewShape } from "./IFilePreviewShape.js";

// *********************************************
// Private constants
// *********************************************/
/**
 * The default width of the shape.
 */
const DEFAULT_WIDTH = 816;

/**
 * The default height of the shape.
 */
const DEFAULT_HEIGHT = 1056;

// *********************************************
// Public constants
// *********************************************/
/**
 * Gets the default props for the shape.
 *
 * @returns The default props.
 */
export const getFilePreviewShapeDefaultProps = (): IFilePreviewShape["props"] => {
  return {
    w: DEFAULT_WIDTH,
    h: DEFAULT_HEIGHT,
    scale: 1,
    canScroll: false,

    fileId: "",
    fileSourceType: FileSourceType.Library,
  };
};

/**
 * The shape props schema definition.
 */
export const filePreviewShapeProps: ShapeProps<IFilePreviewShape> = {
  canScroll: T.boolean,
  h: T.nonZeroNumber,
  scale: T.nonZeroNumber,
  w: T.nonZeroNumber,

  fileId: T.string,
  fileSourceType: T.setEnum(new Set([FileSourceType.Library, FileSourceType.WorkspaceFile])),
};
