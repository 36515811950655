import * as Plot from "@observablehq/plot";
import type { Reducer } from "@observablehq/plot";

import { on, RenderableMark } from "Notebooks/PlotUtils/PlotEventHandlers";

/**
 * Generic bar chart plot, which includes basic genaralization, this can be improved further
 * @param direction Direction of the bar chart horizontal/vertical
 * @param data Data to plot
 * @param facets Selected bars(highlights the selected bars)
 * @param metadata Metadata of the plot which includes height, width, padding, margin, etc
 * @param xDomain Y-axis domain values
 * @param xField Y-axis field
 * @param yField Y-axis field
 * @param textField Text field to show on the bar
 * @param groupReducer Group reducer to decide the height of the bar
 * @param textReducer Text reducer to decide the text on the bar
 * @param barMarkOptions Bar mark options like fill, insetLeft, insetRight
 * @param textMarkOptions text mark options like fill, dx
 * @param onBarClick On click handler for the bar
 * @returns
 */
export function getBarChartPlot(
  direction: "horizontal" | "vertical",
  data: Array<Record<string, any>>,
  facets: { selectedValues?: Array<string> },
  metadata: {
    className?: string;
    grid?: boolean;
    height?: number;
    insetLeft?: number;
    insetRight?: number;
    marginBottom?: number;
    marginLeft?: number;
    marginRight?: number;
    marginTop?: number;
    padding?: number;
    width: number;
    x?: Record<string, any>;
    y?: Record<string, any>;
  },
  xField: string,
  yField: string | number,
  textField: string | undefined,
  groupReducer: Reducer | string | undefined,
  textReducer: Reducer | string | undefined,
  barMarkOptions: {
    fill: string | ((dataItem: any) => string);
    insetLeft?: number;
    insetRight?: number;
  },
  textMarkOptions:
    | {
        fill?: string;
        dx?: number;
      }
    | undefined,
  onBarClick?: (e: PointerEvent, result: Record<string, any>) => void,
) {
  const marks = [];
  // Wraps with "on" only when onBarClick is defined
  if (onBarClick) {
    marks.push(
      on(
        Plot[direction === "horizontal" ? "barX" : "barY"](
          data,
          getBarChartOptions(groupReducer, xField, yField, direction, barMarkOptions),
        ) as RenderableMark,
        {
          pointerup: (event: PointerEvent, result: Record<string, any>) => {
            onBarClick(event, result);
          },
        },
      ),
    );
  } else {
    marks.push(
      Plot[direction === "horizontal" ? "barX" : "barY"](
        data,
        getBarChartOptions(groupReducer, xField, yField, direction, barMarkOptions),
      ),
    );
  }
  if (textField || textReducer) {
    marks.push(Plot.text(data, getTextOptions(textReducer, xField, yField, textField, direction, textMarkOptions)));
  }
  const chart = Plot.plot({
    style: {
      backgroundColor: "transparent",
    },
    ...metadata,
    x: {
      ...metadata.x,
    },
    y: {
      ...metadata.y,
    },
    marks,
  });

  return chart;
}

/**
 * Gives the bar chart options based on the grouping option.
 *
 * @param groupReducer Group reducer applied to plot.
 * @param xField X-axis field.
 * @param yField Y-axis field.
 * @param direction Direction of the bar chart horizontal/vertical.
 * @param markOptions Plot mark options like fill, insetLeft, insetRight.
 *
 * @returns
 */
function getBarChartOptions(
  // TODO: this specific type
  groupReducer: any,
  xField: string,
  yField: string | number,
  direction: "horizontal" | "vertical",
  markOptions: { fill: string | ((dataItem: any) => string); insetLeft?: number; insetRight?: number },
) {
  const options: Record<string, any> = {
    ...markOptions,
  };

  if (groupReducer) {
    // While grouping based on the direction of the chart, the other axis is used as the key
    if (direction === "horizontal" && yField) {
      options["y"] = yField;
    } else if (xField) {
      options["x"] = xField;
    }

    return Plot[direction === "horizontal" ? "groupY" : "groupX"](
      {
        [direction === "horizontal" ? "x" : "y"]: groupReducer,
      },
      options,
    );
  }

  if (xField) {
    options["x"] = xField;
  }
  if (yField) {
    options["y"] = yField;
  }

  return options;
}

/**
 * Gives the text options based on the grouping option.
 *
 * @param textReducer Text reducer
 * @param xField X-axis field
 * @param yField Y-axis field
 * @param textField Text field
 * @param direction Direction of the bar chart horizontal/vertical
 * @param markOptions Text mark options like fill, dx
 * @returns
 */
function getTextOptions(
  // TODO: fix this specific type
  textReducer: any,
  xField: string,
  yField: string | number,
  textField: string | undefined,
  direction: "horizontal" | "vertical",
  markOptions: { fill?: string; dx?: number; dy?: number } | undefined,
) {
  const options: Record<string, any> = {
    ...markOptions,
  };

  if (textReducer) {
    // While grouping based on the direction of the chart, the other axis is used as the key
    if (direction === "horizontal" && yField) {
      options["y"] = yField;
    } else if (xField) {
      options["x"] = xField;
    }

    return Plot[direction === "horizontal" ? "groupY" : "groupX"](
      {
        text: textReducer as Reducer,
        [direction === "horizontal" ? "x" : "y"]: textReducer,
      },
      options,
    );
  }

  if (xField) {
    options["x"] = xField;
  }
  if (yField) {
    options["y"] = yField;
  }
  if (textField) {
    options["text"] = textField;
  }

  return options;
}
