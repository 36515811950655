import { IDateRange } from "@bigpi/cookbook";

export enum IAnalystQuestionAnalysisFieldsEnum {
  EventDate = "eventDate",
  EventDateShortcut = "eventDateShortcut",
  Firm = "firm",
  FirmType = "firmType",
  Segment = "segment",
  Topics = "topics",
}

export interface IAnalystQuestionAnalysisFacets {
  [IAnalystQuestionAnalysisFieldsEnum.EventDate]?: IDateRange;
  [IAnalystQuestionAnalysisFieldsEnum.EventDateShortcut]?: string;
  [IAnalystQuestionAnalysisFieldsEnum.Firm]?: Array<string>;
  [IAnalystQuestionAnalysisFieldsEnum.FirmType]?: Array<string>;
  [IAnalystQuestionAnalysisFieldsEnum.Segment]?: Array<string>;
  [IAnalystQuestionAnalysisFieldsEnum.Topics]?: Array<string>;
}
