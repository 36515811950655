import { useAuthUser } from "@frontegg/react";
import * as React from "react";

import { ObjectRole, useFileAccessControlListUserRolesQuery } from "GraphQL/Generated/Apollo";

export interface HasFileRoleProps {
  children: React.ReactNode;
  roles: Array<ObjectRole>;
  fileId: string;
}

export const HasFileRole = (props: HasFileRoleProps) => {
  const { children, roles, fileId } = props;
  const user = useAuthUser();
  const { data, error, loading } = useFileAccessControlListUserRolesQuery({
    variables: {
      userId: user.id,
      fileId,
    },
  });

  const userRoles = data?.fileAccessControlListUserRoles.roles.some((assignedRole) => roles.includes(assignedRole as ObjectRole));

  if (userRoles) {
    return <>{children}</>;
  }
  return <></>;
};
