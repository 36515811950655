import { useApolloClient } from "@apollo/client";
import { AnalysisFeedbackDataItemType, FacetDisplaySortType, StandardHtmlColors } from "@bigpi/cookbook";
import {
  getTopicDiscussionInNewsArticleAnalysisShapeDefaultProps,
  ITopicDiscussionInNewsArticleAnalysisFacets,
  ITopicDiscussionInNewsArticleAnalysisPreferences,
  ITopicDiscussionInNewsArticleAnalysisSelection,
  ITopicDiscussionInNewsArticleAnalysisShape,
  lockedTextShapeProps,
  topicDiscussionInNewsArticleAnalysisMigrations,
  topicDiscussionInNewsArticleAnalysisShapeProps,
} from "@bigpi/tl-schema";
import { useAuthUser } from "@frontegg/react";
import { Grid, Checkbox, FormControl, InputLabel, Select, MenuItem, ListItemText } from "@mui/material";
import { atom, SVGContainer, Migrations, TLShape, HTMLContainer, TLShapeProps, createShapeId, useValue } from "@tldraw/tldraw";
import * as d3 from "d3";
import { TFunction } from "i18next";
import { useCallback, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { v4 as uuidV4 } from "uuid";

import {
  FieldFacetsManageStateType,
  useApplyBounds,
  useFieldDistinctValuesQuery,
  useFieldFacetsManager,
  useFieldGroupsQuery,
  useIsChildEditing,
  useIsChildSelected,
  useQueryValidFacets,
  useQueryValidFields,
  useSelectedDate,
  useShortcutRelatedRanges,
} from "BoardComponents/Analyses/Hooks";
import { IAnalysisFacets } from "BoardComponents/Types";
import { ITopicDiscussionInNewsArticleAnalysisConfig } from "BoardComponents/Analyses/DataFrameConfigs";
import { transformToToolbarSelectOptions } from "BoardComponents/Analyses/Utils/AnalysisToolbarDataFormatting";
import { ALLOWED_ANALYSES_CHILD_STANDARD_SHAPE_TYPES, DataframeBaseUtil } from "BoardComponents/BaseShapes/DataframeBaseUtil";
import {
  ITopicDiscussionInNewsArticleAnalysisShapeExternalData,
  ITopicDiscussionInNewsArticleResult,
  useBoardDatastore,
} from "BoardComponents/BoardDatastore";
import { DashedOutlineBox } from "BoardComponents/DashedOutlineBox/DashedOutlineBox";
import { DataframeBackground } from "BoardComponents/DataframeBackground/DataframeBackground";
import { useIsInteracting, useIsChildInteracting } from "BoardComponents/Tools";
import { useShapeEvents } from "BoardComponents/useShapeEvents";
import { AnalysisToolbar } from "Components/AnalysisToolbar/AnalysisToolbar";
import {
  AnalysisPreferencesDialog,
  IAnalysisPreferencesDialogState,
} from "Components/AnalysisPreferencesDialog/AnalysisPreferencesDialog";
import { AnalysisFeedbackDialog } from "Components/AnalysisFeedbackDialog/AnalysisFeedbackDialog";
import { SplitButton, SplitButtonProps } from "Components/SplitButton/SplitButton";
import { ChartUtils } from "Utils/ChartUtils";
import { DataUtils } from "Utils/DataUtils";
import { getFormattedDate, getWeekNumber } from "Utils/DateUtils";
import { AnalysisToolbarActions } from "../AnalysisToolbarActions";
import {
  getFilteredTopicDiscussionInNewsArticlesData,
  getFormattedTopicDiscussionInNewsArticlesData,
  getMultiFacetFilteredTopicDiscussionInNewsArticlesData,
  getTopicDiscussionInNewsArticleAnalysisConfig,
  isXAxisSelected,
  isYGroupSelected,
  isYItemSelected,
  revertDataFormat,
} from "./topicDiscussionInNewsArticlesDataUtils";
import { topicDiscussionInNewsArticlesAnalysisConfig } from "./TopicDiscussionInNewsArticlesAnalysisFieldsConfig";

// *********************************************
// Private constants
// *********************************************/
const ALLOWED_CHILD_SHAPE_TYPES = [
  ...ALLOWED_ANALYSES_CHILD_STANDARD_SHAPE_TYPES,

  // Custom shapes
  "dataGrid",
  "groupHistogramChart",
  "lockedText",
];
const DEFAULT_SELECTED_TIME_SCALE = "utcDay";
const TITLE_LABEL_ID = "lockedText:start";
const RECORDS_COUNT_LOCKED_TEXT_ID = "lockedText:end";
const TITLE_LABEL_WIDTH = 1200;
const DATE_FIELD_NAME = "date";
const TOPIC_FIELD_NAME = "topic";

const getAvailableColors = (t: TFunction<"translation", undefined>) => [
  { label: t("Components.Charts.Colors.Blue"), key: "interpolateBlues", color: StandardHtmlColors.blue100 },
  { label: t("Components.Charts.Colors.Green"), key: "interpolateGreens", color: StandardHtmlColors.green100 },
  { label: t("Components.Charts.Colors.Grey"), key: "interpolateGreys", color: StandardHtmlColors.gray100 },
  { label: t("Components.Charts.Colors.Orange"), key: "interpolateOranges", color: StandardHtmlColors.orange100 },
  { label: t("Components.Charts.Colors.Purple"), key: "interpolatePurples", color: StandardHtmlColors.purple100 },
  { label: t("Components.Charts.Colors.Red"), key: "interpolateReds", color: StandardHtmlColors.red100 },
];

// *********************************************
// Public constants
// *********************************************/
export const TOPIC_DISCUSSION_IN_NEWS_ARTICLE_ANALYSIS_CONFIG_KEY = "topic-discussion-in-news-article-analysis-config";

// *********************************************
// Shape Util
// *********************************************/
/**
 * Generator for TopicDiscussionInNewsArticleAnalysis shapes.
 */
export class TopicDiscussionInNewsArticleAnalysisUtil extends DataframeBaseUtil<ITopicDiscussionInNewsArticleAnalysisShape> {
  // *********************************************
  // Static fields
  // *********************************************/
  static type = "topicDiscussionInNewsArticleAnalysis";

  static props = topicDiscussionInNewsArticleAnalysisShapeProps;

  static migrations: Migrations = topicDiscussionInNewsArticleAnalysisMigrations;

  // *********************************************
  // Override methods, event handlers
  // *********************************************/
  /**
   * Listens the children change event & adjusts the shape size.
   *
   * @param shape Shape on which the children changed
   */
  override onChildrenChange = (shape: ITopicDiscussionInNewsArticleAnalysisShape) => {
    const children = this.editor.getSortedChildIdsForParent(shape.id);

    // Remove the data frame if there are no children
    if (children.length === 0) {
      if (this.editor.getFocusedGroupId() === shape.id) {
        this.editor.popFocusedGroupId();
      }
      this.editor.deleteShapes([shape.id]);
    }
  };

  // *********************************************
  // Override methods
  // *********************************************/
  /**
   * @inheritdoc
   */
  override isAspectRatioLocked = (shape: ITopicDiscussionInNewsArticleAnalysisShape) => false;

  /**
   * @inheritdoc
   */
  override canResize = (shape: ITopicDiscussionInNewsArticleAnalysisShape) => false;

  /**
   * @inheritdoc
   */
  override canBind = (shape: ITopicDiscussionInNewsArticleAnalysisShape) => true;

  /**
   * @inheritdoc
   */
  override canEdit = (shape: ITopicDiscussionInNewsArticleAnalysisShape) => true;

  /**
   * @inheritdoc
   */
  override canReceiveNewChildrenOfType = (_shape: ITopicDiscussionInNewsArticleAnalysisShape, type: TLShape["type"]) => {
    return ALLOWED_CHILD_SHAPE_TYPES.includes(type);
  };

  /**
   * @inheritdoc
   */
  override canScroll = (shape: ITopicDiscussionInNewsArticleAnalysisShape) => false;

  /**
   * @inheritdoc
   */
  hideSelectionBoundsBg = () => false;

  /**
   * @inheritdoc
   */
  hideSelectionBoundsFg = () => true;

  /**
   * @inheritdoc
   */
  override getDefaultProps(): ITopicDiscussionInNewsArticleAnalysisShape["props"] {
    return getTopicDiscussionInNewsArticleAnalysisShapeDefaultProps();
  }

  /**
   * @inheritdoc
   */
  override component(shape: ITopicDiscussionInNewsArticleAnalysisShape) {
    const tldrawEditor = this.editor;
    const zoomLevel = tldrawEditor.getZoomLevel();
    const { id, props } = shape;
    const { t } = useTranslation();
    const apolloClient = useApolloClient();
    const selectedFacetValuesProp = props.selectedFacetValues;
    const boundsFacetValuesProp = props.boundsFacetValues;
    const toolbar = props.toolbar;
    const analysisPreferences = props.preferences.analysis;
    const selection = props.selection;
    const availableColors = getAvailableColors(t);

    const bounds = this.getGeometry(shape).getBounds();
    const isEditing = useValue("isEditing", () => tldrawEditor.getCurrentPageState().editingShapeId === id, [tldrawEditor, id]);
    const isHovered = useValue("isHovered", () => tldrawEditor.getCurrentPageState().hoveredShapeId === id, [tldrawEditor, id]);
    const isInteracting = useIsInteracting(id);
    const isChildInteracting = useIsChildInteracting(id);
    const isSelected = useValue("isSelected", () => tldrawEditor.getCurrentPageState().selectedShapeIds.includes(id), [
      tldrawEditor,
      id,
    ]);
    const isChildSelected = useIsChildSelected(shape.id);
    const isChildEditing = useIsChildEditing(shape.id);

    const { handleInputPointerDown } = useShapeEvents(shape.id);

    const [config, setConfig] = useState({} as ITopicDiscussionInNewsArticleAnalysisConfig);

    // Date range shortcuts
    const dateRangeShortcuts = useShortcutRelatedRanges(config.dateShortcuts ? config.dateShortcuts[DATE_FIELD_NAME] : undefined);

    // Transforms date shortcut
    const selectedFacetValuesSelectedDate = useSelectedDate(
      dateRangeShortcuts,
      selectedFacetValuesProp.dateShortcut,
      selectedFacetValuesProp.date,
    );
    const boundsFacetValuesSelectedDate = useSelectedDate(
      dateRangeShortcuts,
      boundsFacetValuesProp.dateShortcut,
      boundsFacetValuesProp.date,
    );

    const selectedFacetValues = useValue(
      "selectedFacetValues",
      () => {
        return {
          ...selectedFacetValuesProp,
          date: selectedFacetValuesSelectedDate,
        };
      },
      [selectedFacetValuesSelectedDate, selectedFacetValuesProp],
    );

    const boundsFacetValues = useValue(
      "boundsFacetValues",
      () => {
        return {
          ...boundsFacetValuesProp,
          date: boundsFacetValuesSelectedDate,
        };
      },
      [boundsFacetValuesSelectedDate, boundsFacetValuesProp],
    );

    // Merge boundsFacets with facets
    const mergedFacets = useApplyBounds(boundsFacetValues, selectedFacetValues);

    // Validate the facets to fit for query
    const validMergedFacets = useQueryValidFacets(mergedFacets, topicDiscussionInNewsArticlesAnalysisConfig);
    const validBoundsFacets = useQueryValidFacets(boundsFacetValues, topicDiscussionInNewsArticlesAnalysisConfig);

    const validToolbarAvailableFields = useQueryValidFields(
      toolbar?.availableFields || [],
      topicDiscussionInNewsArticlesAnalysisConfig,
    );

    // Isolates facets for each field
    const fieldIsolatedFacets = useValue(
      "fieldIsolatedFacets",
      () => {
        const isolatedFacets: Record<string, ITopicDiscussionInNewsArticleAnalysisFacets> = {};
        const validFields = [...Object.keys(validBoundsFacets), ...Object.keys(validMergedFacets)];
        validFields.forEach((field) => {
          isolatedFacets[field] = {
            ...validMergedFacets,
            [field]: validBoundsFacets[field],
          };
        });
        return isolatedFacets;
      },
      [validMergedFacets, validBoundsFacets],
    );

    // Distinct values & field groups queries
    const fieldConfigAllFields = useValue(
      "fieldConfigAllFields",
      () => {
        return topicDiscussionInNewsArticlesAnalysisConfig.map((fieldConfig) => fieldConfig.field);
      },
      [topicDiscussionInNewsArticlesAnalysisConfig],
    );

    const validFieldConfigAllFields = useQueryValidFields(fieldConfigAllFields, topicDiscussionInNewsArticlesAnalysisConfig);

    const { data: queryAllDistinctValues } = useFieldDistinctValuesQuery<ITopicDiscussionInNewsArticleAnalysisFacets>(
      shape.type,
      validFieldConfigAllFields,
      {},
      {},
      topicDiscussionInNewsArticlesAnalysisConfig,
    );

    const { data: queryFieldGroupsResult } = useFieldGroupsQuery<ITopicDiscussionInNewsArticleAnalysisFacets>(
      shape.type,
      validToolbarAvailableFields,
      fieldIsolatedFacets,
      topicDiscussionInNewsArticlesAnalysisConfig,
    );

    // Format all distinct values
    const allDistinctValuesFormatted = useValue(
      "allDistinctValues",
      () => {
        if (!queryAllDistinctValues) {
          return {} as Record<keyof ITopicDiscussionInNewsArticleAnalysisFacets, any>;
        }
        const formattedDistinctValues = transformToToolbarSelectOptions(
          shape.type,
          queryAllDistinctValues,
          topicDiscussionInNewsArticlesAnalysisConfig,
        );

        return formattedDistinctValues;
      },
      [queryAllDistinctValues, shape, topicDiscussionInNewsArticlesAnalysisConfig],
    );

    // Formats bounds distinct values
    const boundsDistinctValuesFormatted = useValue(
      "boundsDistinctValues",
      () => {
        if (!queryFieldGroupsResult) {
          return {} as Record<keyof ITopicDiscussionInNewsArticleAnalysisFacets, any>;
        }

        const formattedBoundsDistinctValues = transformToToolbarSelectOptions(
          shape.type,
          queryFieldGroupsResult,
          topicDiscussionInNewsArticlesAnalysisConfig,
        );

        return formattedBoundsDistinctValues;
      },
      [queryFieldGroupsResult, shape, topicDiscussionInNewsArticlesAnalysisConfig],
    );

    // Config & data queries
    const user = useAuthUser();

    // Facets update
    const fieldFacetsManager = useFieldFacetsManager(
      tldrawEditor,
      shape,
      selectedFacetValues,
      this.updateSelectedFacetValues,
      topicDiscussionInNewsArticlesAnalysisConfig,
    );
    const boundsFacetsManager = useFieldFacetsManager(
      tldrawEditor,
      shape,
      boundsFacetValues,
      this.updateBoundsFacetValues,
      topicDiscussionInNewsArticlesAnalysisConfig,
      false,
    );

    // State
    const [isFeedbackDialogOpen, setIsFeedbackDialogOpen] = useState<boolean>(false);
    const [isPreferencesDialogOpen, setIsPreferencesDialogOpen] = useState<boolean>(false);
    const [data, setData] = useState<Array<ITopicDiscussionInNewsArticleResult>>(
      DataUtils.getImmutableEmptyArray<ITopicDiscussionInNewsArticleResult>(),
    );
    const [dataLoading, setDataLoading] = useState<boolean>(false);
    const [configLoading, setConfigLoading] = useState<boolean>(false);
    const [filteredData, setFilteredData] = useState<Array<ITopicDiscussionInNewsArticleResult>>(data);
    const [multiFacetFilteredData, setMultiFacetFilteredData] = useState<Array<ITopicDiscussionInNewsArticleResult>>(data);
    const [recordsCountLabel, setRecordsCountLabel] = useState<string>("");
    const [titleText, setTitleText] = useState<string>("");
    const [toolbarContainerRef, setToolbarContainerRef] = useState<HTMLDivElement | null>(null);
    const [allDistinctValues, setAllDistinctValues] = useState<Record<keyof ITopicDiscussionInNewsArticleAnalysisFacets, any>>();
    const [boundsDistinctValues, setBoundsDistinctValues] = useState<
      Record<keyof ITopicDiscussionInNewsArticleAnalysisFacets, any>
    >(DataUtils.getImmutableEmptyObject());
    const [allTopics, setAllTopics] = useState<Array<{ key: string; label: string; count: number }>>([]);
    const [isConfigSyncWithShapeProps, setIsConfigSyncWithShapeProps] = useState<boolean>(false);

    const childShapeTypes = useValue(
      "childShapeTypes",
      () => {
        const childIds = this.editor.getSortedChildIdsForParent(shape.id);
        return childIds.map((childId) => {
          const childShape = this.editor.getShape(childId);
          const childProps = childShape?.props as Partial<typeof lockedTextShapeProps>;
          return `${childShape?.type}${childShape?.type === "lockedText" ? `:${childProps.align}` : ""}`;
        });
      },
      [tldrawEditor, id],
    );

    // Make sure we have an entry in the datastore for this shape
    const datastore = useBoardDatastore();

    const fetchTopicDiscussionInNewsArticleAnalysisConfig = useCallback(async () => {
      setConfigLoading(true);

      // Any changes in the process of getting the config must be handled in
      // the `getTopicDiscussionInNewsArticleAnalysisConfig` function since this function
      // is also used by the `BoardSearchManager` internally to get the filtered analysis data.
      return getTopicDiscussionInNewsArticleAnalysisConfig(apolloClient, user.tenantId).finally(() => {
        setConfigLoading(false);
      });
    }, [apolloClient, user.tenantId]);

    // Set config data to local state
    useEffect(() => {
      fetchTopicDiscussionInNewsArticleAnalysisConfig().then((topicDiscussionInNewsArticleAnalysisConfig) => {
        setConfig(topicDiscussionInNewsArticleAnalysisConfig);
      });
    }, [fetchTopicDiscussionInNewsArticleAnalysisConfig]);

    // Update allTopics if data changes
    useEffect(() => {
      const topicsList: Array<{ key: string; label: string; count: number }> = [];
      const groupedItems = DataUtils.getGroupedItems<ITopicDiscussionInNewsArticleResult>(data, "topic");
      Array.from(groupedItems).forEach(([key, value]) => {
        topicsList.push({
          key,
          label: key,
          count: value.length,
        });
      });
      setAllTopics(topicsList);
    }, [data]);

    useEffect(() => {
      setAllDistinctValues({
        ...(allDistinctValuesFormatted as Record<keyof ITopicDiscussionInNewsArticleAnalysisFacets, any>),
        [TOPIC_FIELD_NAME]: allTopics.map((topic) => {
          return { key: topic.key, label: topic.label };
        }),
      });
    }, [allTopics, allDistinctValuesFormatted]);

    useEffect(() => {
      setBoundsDistinctValues({
        ...(boundsDistinctValuesFormatted as Record<keyof ITopicDiscussionInNewsArticleAnalysisFacets, any>),
        [TOPIC_FIELD_NAME]: allTopics,
      });
    }, [allTopics, boundsDistinctValuesFormatted]);

    // Create the datastore state with our values
    useEffect(() => {
      if (!datastore.hasShapeData(shape.id)) {
        datastore.setShapeData(shape.id, {
          // Set the full data in the datastore
          allData: atom(`board.datastore.${shape.id}.allData`, data),
          config: atom(`board.datastore.${shape.id}.config`, config),
          configLoading: atom(`board.datastore.${shape.id}.configLoading`, configLoading),
          dataGridSelectedIds: atom(`board.datastore.${shape.id}.dataGridSelectedIds`, []),
          dataLoading: atom(`board.datastore.${shape.id}.dataLoading`, false),
          facets: atom(`board.datastore.${shape.id}.facets`, selectedFacetValues),
          filteredData: atom(`board.datastore.${shape.id}.filteredData`, data),
          getToolbar: atom(`board.datastore.${shape.id}.getToolbar`, () => null),
          multiFacetFilteredData: atom(`board.datastore.${shape.id}.multiFacetFilteredData`, data),
          onSelectionChange: atom(`board.datastore.${shape.id}.onSelectionChange`, () => null),
          onSelectedIdsChanged: atom(`board.datastore.${shape.id}.onSelectedIdsChanged`, () => null),
          onUpdatePreferences: atom(`board.datastore.${shape.id}.onUpdatePreferences`, () => null),
          plotReducers: atom(`board.datastore.${shape.id}.plotReducers`, {}),
          preferences: atom(`board.datastore.${shape.id}.preferences`, props.preferences),
          selection: atom(`board.datastore.${shape.id}.selection`, selection || {}),
          scales: atom(`board.datastore.${shape.id}.scales`, {}),
          yAxisTickFormat: atom(`board.datastore.${shape.id}.yAxisTickFormat`, () => ""),
          type: "topicDiscussionInNewsArticleAnalysis",
        });
      }
    }, []);

    const fetchTopicDiscussionInNewsArticlesData = useCallback(async () => {
      setDataLoading(true);

      // Any changes in the process of getting the data must be handled in
      // the `getFormattedTopicDiscussionInNewsArticlesData` function since this function
      // is also used by the `BoardSearchManager` internally to get the filtered analysis data.
      return getFormattedTopicDiscussionInNewsArticlesData(apolloClient, validMergedFacets).finally(() => {
        setDataLoading(false);
      });
    }, [apolloClient, validMergedFacets]);

    useEffect(() => {
      if (config && Object.keys(config).length > 0 && isConfigSyncWithShapeProps) {
        fetchTopicDiscussionInNewsArticlesData().then((topicDiscussionInNewsArticlesData) => {
          setData(topicDiscussionInNewsArticlesData);
        });
      }
    }, [isConfigSyncWithShapeProps, config, fetchTopicDiscussionInNewsArticlesData]);

    const fetchMultiFacetTopicDiscussionInNewsArticlesData = useCallback(async () => {
      // Any changes in the process of getting the multi facet data must be handled in
      // the `getMultiFacetFilteredTopicDiscussionInNewsArticlesData` function since this function
      // is also used by the `BoardSearchManager` internally to get the filtered analysis data.
      return getMultiFacetFilteredTopicDiscussionInNewsArticlesData(apolloClient, validMergedFacets);
    }, [apolloClient, validMergedFacets]);

    // Fetch multi-facet filtered data and update the state
    useEffect(() => {
      if (config && Object.keys(config).length > 0 && isConfigSyncWithShapeProps) {
        fetchMultiFacetTopicDiscussionInNewsArticlesData().then((multiFacetFilteredTopicDiscussionInNewsArticlesData) => {
          setMultiFacetFilteredData(multiFacetFilteredTopicDiscussionInNewsArticlesData);
        });
      }
    }, [isConfigSyncWithShapeProps, config, fetchMultiFacetTopicDiscussionInNewsArticlesData]);

    const fetchFilteredTopicDiscussionInNewsArticlesData = useCallback(async () => {
      // Any changes in the process of getting the filtered data must be handled in
      // the `getMultiFacetFilteredTopicDiscussionInNewsArticlesData` function since this function
      // is also used by the `BoardSearchManager` to get the filtered analysis data.
      return getFilteredTopicDiscussionInNewsArticlesData(
        apolloClient,
        user.tenantId,
        validMergedFacets,
        selection || DataUtils.getImmutableEmptyObject(),
      );
    }, [apolloClient, validMergedFacets, selection, user]);

    // Fetch filtered data and update the state
    useEffect(() => {
      if (config && Object.keys(config).length > 0 && isConfigSyncWithShapeProps) {
        fetchFilteredTopicDiscussionInNewsArticlesData().then((filteredTopicDiscussionInNewsArticlesData) => {
          setFilteredData(filteredTopicDiscussionInNewsArticlesData);
        });
      }
    }, [isConfigSyncWithShapeProps, config, fetchFilteredTopicDiscussionInNewsArticlesData]);

    useEffect(() => {
      const { groupHistogramChart } = config;
      const { fields } = groupHistogramChart || {};

      const multiFacetOriginalData = revertDataFormat(multiFacetFilteredData, fields);
      const recordsCountLabel = t("Components.Analyses.TopicDiscussionInNewsArticleAnalysis.ArticlesCount", {
        selected: filteredData.length,
        total: multiFacetOriginalData.length,
      });
      setRecordsCountLabel(recordsCountLabel);

      this._updateRecordsCountLabel(shape, recordsCountLabel);
    }, [filteredData, multiFacetFilteredData, validMergedFacets, config]);

    useEffect(() => {
      const themes = boundsFacetValues.theme || [];
      const titleText = t("Components.Analyses.TopicDiscussionInNewsArticleAnalysis.DefaultLabel", { type: themes.join(", ") });

      setTitleText(titleText);
      this._updateTitleLabel(shape, titleText);
    }, [boundsFacetValues]);

    // Update the datastore config when it changes
    useEffect(() => {
      const storeData = datastore.state.get()[shape.id].get() as ITopicDiscussionInNewsArticleAnalysisShapeExternalData;
      storeData.config.set(config);

      // Insert child shapes
      const childShapes = this.editor.getSortedChildIdsForParent(shape.id);
      if (config && Object.keys(config).length > 0 && childShapes.length === 0) {
        // Set initial state from the config
        this.editor.updateShapes([
          {
            id: shape.id,
            type: shape.type,
            props: {
              preferences: {
                ...(config.initialState?.preferences || {}),
              },
              selectedFacetValues: {
                ...(config.initialState?.selectedFacetValues || {}),
                ...selectedFacetValues,
              },
              boundsFacetValues: {
                ...(config.initialState?.boundsFacetValues || {}),
                ...boundsFacetValues,
              },
              toolbar: {
                ...config?.toolbar,
                ...toolbar,
              },
            },
          },
        ]);
        this._initialRenderOfChildren(shape, config, t);
        setIsConfigSyncWithShapeProps(true);
      } else if (config && Object.keys(config).length > 0 && childShapes.length > 0) {
        setIsConfigSyncWithShapeProps(true);
      }
    }, [config, selectedFacetValues, boundsFacetValues, toolbar]);

    // Update the datastore facets when they change
    useEffect(() => {
      const storeData = datastore.state.get()[shape.id].get() as ITopicDiscussionInNewsArticleAnalysisShapeExternalData;
      storeData.facets.set(selectedFacetValues);
    }, [selectedFacetValues]);

    // Update the datastore data when it changes
    useEffect(() => {
      const storeData = datastore.state.get()[shape.id].get() as ITopicDiscussionInNewsArticleAnalysisShapeExternalData;
      storeData.allData.set(data);
    }, [data]);

    // Update the datastore dataLoading when it changes
    useEffect(() => {
      const storeData = datastore.state.get()[shape.id].get() as ITopicDiscussionInNewsArticleAnalysisShapeExternalData;
      storeData.dataLoading?.set(dataLoading);
    }, [dataLoading]);

    // Update the datastore configLoading when it changes
    useEffect(() => {
      const storeData = datastore.state.get()[shape.id].get() as ITopicDiscussionInNewsArticleAnalysisShapeExternalData;
      storeData.configLoading?.set(configLoading);
    }, [configLoading]);

    // Update the datastore scales when data/config change
    useEffect(() => {
      const fields = config.groupHistogramChart?.fields;
      const storeData = datastore.state.get()[shape.id].get() as ITopicDiscussionInNewsArticleAnalysisShapeExternalData;
      storeData.scales?.set({
        histogramChartScale: {
          groupScale: TopicDiscussionInNewsArticleAnalysisUtil._getHistogramChartScale(
            data,
            fields?.xField,
            fields?.yGroupField,
            selection?.selectedTimeScale || DEFAULT_SELECTED_TIME_SCALE,
          ),
          itemsScale: TopicDiscussionInNewsArticleAnalysisUtil._getHistogramChartScale(
            data,
            fields?.xField,
            fields?.yItemField,
            selection?.selectedTimeScale || DEFAULT_SELECTED_TIME_SCALE,
          ),
        },
      });
    }, [data, config, selection]);

    // Update the datastore filtered data when it changes
    useEffect(() => {
      const storeData = datastore.state.get()[shape.id].get() as ITopicDiscussionInNewsArticleAnalysisShapeExternalData;
      storeData.filteredData?.set(filteredData);
      storeData.multiFacetFilteredData?.set(multiFacetFilteredData);
    }, [filteredData, multiFacetFilteredData]);

    const onSelectedIdsChanged = useCallback(
      (selectedIds: Array<string>) => {
        const storeData = datastore.state.get()[shape.id].get() as ITopicDiscussionInNewsArticleAnalysisShapeExternalData;
        storeData.dataGridSelectedIds?.set(selectedIds);
      },
      [datastore, shape],
    );

    // Update the datastore onSelectedIdsChanged method when it changes
    useEffect(() => {
      const storeData = datastore.state.get()[shape.id].get() as ITopicDiscussionInNewsArticleAnalysisShapeExternalData;
      storeData.onSelectedIdsChanged?.set(onSelectedIdsChanged);
    }, [datastore, onSelectedIdsChanged]);

    const onUpdatePreferences = useCallback(
      (key: string, newPreferences: Record<string, any>) => {
        tldrawEditor.updateShapes([
          {
            id: shape.id,
            type: shape.type,
            props: {
              preferences: {
                ...shape.props.preferences,
                [key]: newPreferences,
              },
            },
          },
        ]);
      },
      [shape, tldrawEditor],
    );

    // Update the datastore onUpdatePreferences method when it changes
    useEffect(() => {
      const storeData = datastore.state.get()[shape.id].get() as ITopicDiscussionInNewsArticleAnalysisShapeExternalData;
      storeData.onUpdatePreferences?.set(onUpdatePreferences);
    }, [datastore, onUpdatePreferences]);

    // Update the datastore preferences when they change
    useEffect(() => {
      const storeData = datastore.state.get()[shape.id].get() as ITopicDiscussionInNewsArticleAnalysisShapeExternalData;
      storeData.preferences?.set(props.preferences || {});
    }, [datastore, props.preferences]);

    useEffect(() => {
      const storeData = datastore.state.get()[shape.id].get() as ITopicDiscussionInNewsArticleAnalysisShapeExternalData;
      storeData.selection?.set(selection || {});
    }, [datastore, selection]);

    const onSelectionChange = useCallback(
      (selection: ITopicDiscussionInNewsArticleAnalysisSelection) => {
        tldrawEditor.updateShapes([
          {
            id: shape.id,
            type: shape.type,
            props: {
              selection,
            },
          },
        ]);
      },
      [shape, tldrawEditor],
    );

    useEffect(() => {
      const storeData = datastore.state.get()[shape.id].get() as ITopicDiscussionInNewsArticleAnalysisShapeExternalData;
      storeData.onSelectionChange?.set(onSelectionChange);
    }, [datastore, onSelectionChange]);

    const onShowFeedbackDialog = useCallback(() => {
      setIsFeedbackDialogOpen(true);
    }, []);

    const onCloseFeedbackDialog = useCallback(() => {
      setIsFeedbackDialogOpen(false);
    }, []);

    const onShowPreferencesDialog = useCallback(() => {
      setIsPreferencesDialogOpen(true);
    }, []);

    const onClosePreferencesDialog = useCallback(() => {
      setIsPreferencesDialogOpen(false);
    }, []);

    // Create a callback to get the toolbar for child shapes to show
    const getToolbar = useCallback(() => {
      if (toolbarContainerRef === null) {
        return null;
      }

      // Take anlaysis preferences & facet sort
      const facetSort = analysisPreferences?.facetSort || [];
      const facetSortMap =
        facetSort.reduce(
          (acc, sort) => {
            if (sort && sort.field && sort.sort) {
              acc[sort.field] = sort.sort;
            }
            return acc;
          },
          {} as Record<string, FacetDisplaySortType>,
        ) || {};

      // Takes data store
      const storeData = datastore.state.get()[shape.id].get() as ITopicDiscussionInNewsArticleAnalysisShapeExternalData;
      const dataGridSelectedIds = storeData.dataGridSelectedIds?.get() || [];

      // Split button options
      const options: SplitButtonProps["options"] = [
        {
          value: AnalysisToolbarActions.Reset,
          label: t("Components.Charts.Reset"),
          disabled: !selection?.axisSelection || selection.axisSelection.length === 0,
        },
        {
          value: AnalysisToolbarActions.DownloadCsv,
          label: t("Components.Charts.DownloadCsv", { count: dataGridSelectedIds.length || filteredData.length }),
        },
      ];
      const addToDocument = config.dataGrid?.addToDocument;
      if (addToDocument) {
        options.push({
          value: AnalysisToolbarActions.AddToDocument,
          label: t("Components.Charts.AddItems", { count: dataGridSelectedIds.length || filteredData.length }),
        });
      }

      options.push(
        {
          value: AnalysisToolbarActions.ProvideFeedback,
          label: t("Components.Charts.ProvideFeedback"),
          disabled: dataGridSelectedIds.length === 0 || filteredData.length === 0,
        },
        {
          value: AnalysisToolbarActions.Preferences,
          label: `${t("Components.Analyses.Common.PreferencesDialog.Preferences")}...`,
        },
      );

      const timeScaleList = [
        { name: "utcDay", label: "Day" },
        { name: "utcWeek", label: "Week" },
        { name: "utcMonth", label: "Month" },
        { name: "utcYear", label: "Year" },
      ];
      const timeScaleMap: Record<string, string> = timeScaleList.reduce(
        (acc, curr) => {
          acc[curr.name] = curr.label;
          return acc;
        },
        {} as Record<string, string>,
      );

      return createPortal(
        <AnalysisToolbar<ITopicDiscussionInNewsArticleAnalysisFacets>
          fields={toolbar?.availableFields || []}
          selectedFacetValues={selectedFacetValues}
          distinctValues={boundsDistinctValues as Record<keyof ITopicDiscussionInNewsArticleAnalysisFacets, any>}
          dateRangeShortcuts={dateRangeShortcuts}
          fieldsConfig={topicDiscussionInNewsArticlesAnalysisConfig}
          fieldsSort={facetSortMap}
          onFieldChange={fieldFacetsManager.onFieldChange}
          onSortChange={_onFacetSortChange.bind(undefined, analysisPreferences)}
        >
          <Grid item sx={{ mr: 3, padding: "10px", width: 150 }}>
            <FormControl sx={{ width: 140 }}>
              <InputLabel id={`timescale-${shape.id}`}>{t("Components.Charts.TimeScale")}</InputLabel>
              <Select
                sx={{ width: 150 }}
                id={`timescale-${shape.id}`}
                label={t("Components.Charts.TimeScale")}
                value={selection?.selectedTimeScale || DEFAULT_SELECTED_TIME_SCALE}
                multiple={false}
                onChange={(event) => {
                  this._onTimeScaleChange(shape, event.target.value);
                }}
                inputProps={{
                  name: "timescale",
                  id: "timescale",
                }}
                renderValue={(selected) => timeScaleMap[selected || DEFAULT_SELECTED_TIME_SCALE]}
                MenuProps={{
                  classes: { paper: "analysis-select-menu" },
                  MenuListProps: { classes: { root: "analysis-select-menu-list" } },
                }}
              >
                {timeScaleList.map((timeScale: { name: string; label: string }) => {
                  return (
                    <MenuItem key={timeScale.name} value={timeScale.name} classes={{ selected: "analysis-select-menu-item" }}>
                      <ListItemText primary={timeScale.label} />
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          {analysisPreferences?.showDataToDisplayInToolbar && (
            <Grid item sx={{ mr: 6, padding: "10px", width: 150 }}>
              <FormControl sx={{ width: 140 }}>
                <InputLabel id={`available-shapes-${shape.id}`}>{t("Components.Charts.DataToDisplay")}</InputLabel>
                <Select
                  sx={{ width: 150 }}
                  id={`available-shapes-${shape.id}`}
                  label={t("Components.Charts.DataToDisplay")}
                  multiple
                  value={childShapeTypes || DataUtils.getImmutableEmptyArray<string>()}
                  onChange={(event) => {
                    this._onAvailableShapesChange(
                      t,
                      shape,
                      event.target.value as Array<string>,
                      childShapeTypes,
                      selectedFacetValues,
                      config,
                      recordsCountLabel,
                      titleText,
                    );
                  }}
                  renderValue={(selected) =>
                    t("Components.Charts.SelectedOfTotal", {
                      selected: selected.length,
                      total: config.allowedChildShapes?.length,
                    })
                  }
                  MenuProps={{
                    classes: { paper: "analysis-select-menu" },
                    MenuListProps: { classes: { root: "analysis-select-menu-list" } },
                  }}
                >
                  {config?.allowedChildShapes?.map((allowedShape) => {
                    return (
                      <MenuItem
                        key={allowedShape.label}
                        value={`${allowedShape.type}${allowedShape.key ? `:${allowedShape.key}` : ""}`}
                        classes={{ selected: "analysis-select-menu-item" }}
                      >
                        <Checkbox
                          checked={childShapeTypes.includes(
                            `${allowedShape.type}${allowedShape.key ? `:${allowedShape.key}` : ""}`,
                          )}
                        />
                        <ListItemText primary={t(`Components.ChartNames.${allowedShape.label}`)} />
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid item sx={{ padding: "10px" }}>
            <SplitButton
              options={options}
              handleClick={(option: string) => {
                if (AnalysisToolbarActions.Preferences === option) {
                  onShowPreferencesDialog();
                } else {
                  this.onSplitButtonClick(
                    shape,
                    config,
                    filteredData,
                    selectedFacetValues,
                    dataGridSelectedIds,
                    t,
                    apolloClient,
                    option,
                    onShowFeedbackDialog,
                  );
                }
              }}
            />
          </Grid>
        </AnalysisToolbar>,
        toolbarContainerRef,
      );
    }, [
      allTopics,
      analysisPreferences,
      apolloClient,
      boundsDistinctValues,
      childShapeTypes,
      config,
      datastore,
      dateRangeShortcuts,
      filteredData,
      onShowPreferencesDialog,
      props,
      recordsCountLabel,
      selectedFacetValues,
      selection,
      toolbarContainerRef,
    ]);

    // Update the datastore with the getToolbar callback
    useEffect(() => {
      const storeData = datastore.state.get()[shape.id].get() as ITopicDiscussionInNewsArticleAnalysisShapeExternalData;
      storeData.getToolbar.set(getToolbar);
    }, [getToolbar]);

    const yAxisTickFormat = useCallback(
      (label: string) => {
        return ChartUtils.formatOverflowAxisLabel(label, config.fontSize, config.analysis?.approximateMarginLeft);
      },
      [config.fontSize],
    );

    // Update the yAxisTickFormat callback
    useEffect(() => {
      const storeData = datastore.state.get()[shape.id].get() as ITopicDiscussionInNewsArticleAnalysisShapeExternalData;
      storeData.yAxisTickFormat?.set(yAxisTickFormat);
    }, [yAxisTickFormat]);

    // Fill reducer for the bubble chart plot
    const getFillReducer = useCallback(
      (colorScale: d3.ScaleDiverging<string, never>) => {
        return (channelData: Array<ITopicDiscussionInNewsArticleResult>) => {
          const { groupHistogramChart } = config;
          const fields = groupHistogramChart?.fields;
          let selected = true;
          const axisSelection = selection?.axisSelection;
          if (axisSelection && axisSelection.length > 0) {
            let facetMatch = false;
            axisSelection.forEach((selection) => {
              const xAxisSelected = isXAxisSelected(channelData, selection.selectedXAxis, fields.xField);
              const yGroupSelected = isYGroupSelected(channelData, selection.selectedYGroup, fields.yGroupField);
              const yItemSelected = isYItemSelected(channelData, selection.selectedYItem, fields.yItemField);
              if (xAxisSelected && yGroupSelected && yItemSelected) {
                facetMatch = true;
              }
            });
            selected = facetMatch;
          }
          if (selected) {
            return colorScale(channelData.length);
          } else {
            return `${StandardHtmlColors.gray20}80`;
          }
        };
      },
      [config, selection],
    );

    useEffect(() => {
      const storeData = datastore.state.get()[shape.id].get() as ITopicDiscussionInNewsArticleAnalysisShapeExternalData;
      storeData.plotReducers?.set({
        fillReducer: getFillReducer,
      });
    }, [getFillReducer]);

    // These are used by the feedback dialog
    const storeData = datastore?.state.get()[shape.id]?.get() as ITopicDiscussionInNewsArticleAnalysisShapeExternalData;
    const dataGridSelectedIds = storeData?.dataGridSelectedIds?.get() || [];
    return (
      <>
        {isEditing || isSelected || isChildSelected ? (
          <SVGContainer>
            <DashedOutlineBox className="tl-group" bounds={bounds} zoomLevel={zoomLevel} />
          </SVGContainer>
        ) : null}
        <DataframeBackground
          enableBackgroundColor={!!props.enableBackground}
          enableBorder={!isHovered && !isEditing && !isSelected && !isChildSelected && !!props.enableBackground}
          bounds={bounds}
        />
        <HTMLContainer
          className="topic-discussion-in-news-article-analysis-shape"
          style={{
            background: "transparent",
            pointerEvents: "all",
          }}
        >
          <div onPointerDown={handleInputPointerDown}>
            <div
              ref={setToolbarContainerRef}
              style={{
                marginTop: -100,
                display: "flex",
                justifyContent: "center",
                position: "absolute",
                top: bounds.y,
                left: bounds.midX,
              }}
            />
            {(isEditing || isInteracting || isChildEditing || isChildInteracting) && getToolbar()}
            <AnalysisFeedbackDialog
              dataItemIds={dataGridSelectedIds}
              dataItemType={AnalysisFeedbackDataItemType.TopicDiscussionInNewsArticle}
              onClose={onCloseFeedbackDialog}
              open={isFeedbackDialogOpen}
            />
            {isPreferencesDialogOpen && (
              <AnalysisPreferencesDialog<ITopicDiscussionInNewsArticleAnalysisFacets>
                dateRangeShortcuts={dateRangeShortcuts}
                distinctValues={allDistinctValues as Record<keyof ITopicDiscussionInNewsArticleAnalysisFacets, any>}
                selectedFacetValues={boundsFacetsManager.finalFacets}
                fields={props.selectedBoundsFields || ([] as Array<keyof IAnalysisFacets>)}
                fieldsConfig={topicDiscussionInNewsArticlesAnalysisConfig}
                onCancelPreferences={() => {
                  onClosePreferencesDialog();
                  boundsFacetsManager.onCancelFacets();
                }}
                onFieldChange={boundsFacetsManager.onFieldChange}
                open={isPreferencesDialogOpen}
                onClose={onClosePreferencesDialog}
                onSavePreferences={(toolbarPreferences) => {
                  onClosePreferencesDialog();
                  boundsFacetsManager.onSaveFacets();
                  this._saveToolbarPreferences(shape, toolbarPreferences, fieldFacetsManager);
                }}
                toolbarFields={toolbar?.availableFields || []}
                toolbarFieldFacets={selectedFacetValues}
                // Options props
                availableColors={availableColors}
                isBackgroundEnabled={props.enableBackground || false}
                isDataToDisplayInToolbarEnabled={analysisPreferences?.showDataToDisplayInToolbar || false}
                isSubItemsEnabled={analysisPreferences?.showTopics || false}
                startColor={analysisPreferences?.startColor}
                subItemsLabel={t("Components.Charts.ShowSubTopics")}
              />
            )}
          </div>
        </HTMLContainer>
      </>
    );

    // *********************************************
    // Render private methods
    // *********************************************/
    /**
     * Updates facet sort preferences for the given field.
     *
     * @param analysisPreferences Analysis preferences stored on shape props
     * @param fieldId Field id to update the facet sort updates
     * @param sort Latest sort
     */
    function _onFacetSortChange(
      analysisPreferences: ITopicDiscussionInNewsArticleAnalysisPreferences["analysis"],
      fieldId: string,
      sort: FacetDisplaySortType,
    ) {
      const facetSort = [...(analysisPreferences?.facetSort || [])];
      let fieldIndex = facetSort.findIndex((sortOption) => sortOption.field === fieldId);
      if (fieldIndex > -1) {
        facetSort.splice(fieldIndex, 1, {
          field: fieldId,
          sort,
        });
      } else {
        facetSort.push({
          field: fieldId,
          sort,
        });
      }
      onUpdatePreferences("analysis", {
        ...analysisPreferences,
        facetSort,
      });
    }
  }

  // *********************************************
  // Private static methods
  // *********************************************/
  /**
   * Generates min & max scales for bubble chart
   * @param data Data generate the scales
   * @param fields Fields to use
   * @returns
   */
  private static _getHistogramChartScale(
    data: Array<ITopicDiscussionInNewsArticleResult>,
    xField: string | undefined,
    yField: string | undefined,
    selectedTimeScale: string,
  ) {
    if (!xField || !yField) {
      return [];
    }
    const timeScale = selectedTimeScale || DEFAULT_SELECTED_TIME_SCALE;
    // Groups the data based on the interval to mimic the histogram chart plot thresholds
    const rollupData = d3.rollups(
      data,
      (channelData: Array<Record<string, any>>) => channelData.length,
      (dataItem: Record<string, any>) => {
        if (timeScale === "utcDay") {
          return getFormattedDate(dataItem[xField], "YYYY-MM-DD");
        } else if (timeScale === "utcWeek") {
          return getFormattedDate(dataItem[xField], "YYYY") + "-" + getWeekNumber(dataItem[xField]);
        } else if (timeScale === "utcMonth") {
          return getFormattedDate(dataItem[xField], "YYYY-MM");
        } else if (timeScale === "utcYear") {
          return getFormattedDate(dataItem[xField], "YYYY");
        }
      },
      (dataItem: Record<string, any>) => dataItem[yField],
    );
    const countList: Array<number> = rollupData
      .flat()
      .flat()
      .flat()
      .filter((d: string | number) => typeof d === "number") as Array<number>;
    const groupFieldMaxValue = d3.max(countList) || 0;
    return [0, groupFieldMaxValue + 1];
  }

  // *********************************************
  // Protected methods, event handlers
  // *********************************************/
  /**
   * Clears the facets from axis(x/y) selection.
   *
   * @param shape The shape to update.
   */
  protected onClearSelection(shape: ITopicDiscussionInNewsArticleAnalysisShape) {
    const selection = shape.props.selection;
    const newSelection: ITopicDiscussionInNewsArticleAnalysisSelection = {
      ...selection,
      ...this.clearSelectionAttributes(),
    };

    this._updateSelection(shape, newSelection);
  }

  // *********************************************
  // Private methods, event handlers
  // *********************************************/
  /**
   * Initial creation of child shapes based on the config.
   *
   * @param shape Current shape
   * @param config Config to use
   * @param t Translation function
   */
  private _initialRenderOfChildren(
    shape: ITopicDiscussionInNewsArticleAnalysisShape,
    config: ITopicDiscussionInNewsArticleAnalysisConfig,
    t: TFunction<"translation", undefined>,
  ) {
    const allShapes = config.allowedChildShapes.map((allowedShape) => allowedShape.type);
    this._createChildShapes(shape, config.initialShapes || allShapes, config, t);
  }

  /**
   * Handles the timescale change & updates the facets with the selected timescale.
   *
   * @param shape Shape to update the field facet values.
   * @param value Selected value for the field.
   */
  private _onTimeScaleChange(shape: ITopicDiscussionInNewsArticleAnalysisShape, value: string) {
    this._updateSelection(shape, {
      ...shape.props.selection,
      selectedTimeScale: value,
    });
  }

  /**
   * Checks the existing child shapes & alters (delete/create) based on the latest user selection.
   *
   * @param shape Current shape.
   * @param value The latest available shapes slected from the dropdown.
   * @param childShapeTypes The current existing child shape types.
   */
  private _onAvailableShapesChange(
    t: TFunction<"translation", undefined>,
    shape: ITopicDiscussionInNewsArticleAnalysisShape,
    value: Array<string>,
    childShapeTypes: Array<string>,
    facets: ITopicDiscussionInNewsArticleAnalysisFacets,
    config: ITopicDiscussionInNewsArticleAnalysisConfig,
    recordsCountLabel: string,
    titleText: string,
  ) {
    let shapeTypesToCreate: Array<string> = [];
    let shapeTypesToDelete: Array<string> = [];
    if (value.length === 0) {
      shapeTypesToDelete = childShapeTypes;
    } else if (childShapeTypes.length === 0) {
      shapeTypesToCreate = value;
    } else {
      value.forEach((v) => {
        if (!childShapeTypes.includes(v)) {
          shapeTypesToCreate.push(v);
        }
      });
      childShapeTypes.forEach((v) => {
        if (!value.includes(v)) {
          shapeTypesToDelete.push(v);
        }
      });
    }
    const shapeIdsToDelete = this.editor.getSortedChildIdsForParent(shape.id).filter((child) => {
      const childShape = this.editor.getShape(child);
      if (!childShape) {
        return false;
      }
      const childShapeProps = childShape.props as Partial<TLShapeProps>;
      return shapeTypesToDelete.includes(`${childShape?.type}${childShapeProps.align ? `:${childShapeProps.align}` : ""}`);
    });
    this._createChildShapes(shape, shapeTypesToCreate, config, t);
    this.editor.deleteShapes(shapeIdsToDelete);

    if (shapeTypesToCreate.includes(RECORDS_COUNT_LOCKED_TEXT_ID)) {
      this._updateRecordsCountLabel(shape, recordsCountLabel);
    }

    if (shapeTypesToCreate.includes(TITLE_LABEL_ID)) {
      this._updateTitleLabel(shape, titleText);
    }
  }

  // *********************************************
  // Private methods
  // *********************************************/
  /**
   * Creates shapes for the given shape types.
   *
   * @param shape Current shape.
   * @param shapeTypes Shape types to create.
   */
  private _createChildShapes(
    shape: ITopicDiscussionInNewsArticleAnalysisShape,
    shapeTypes: Array<string>,
    config: ITopicDiscussionInNewsArticleAnalysisConfig,
    t: TFunction<"translation", undefined>,
  ) {
    const { id } = shape;
    const tldrawEditor = this.editor;
    const latestShape = tldrawEditor.getShape(id) as ITopicDiscussionInNewsArticleAnalysisShape;
    // Takes latest props
    const preferences = latestShape?.props?.preferences;
    const themes = latestShape.props.boundsFacetValues.theme || [];

    const bounds = this.getGeometry(shape).getBounds();
    const childShapeIds = this.editor.getSortedChildIdsForParent(id);
    const childShapes = childShapeIds.map((childShapeId) => this.editor.getShape(childShapeId));
    const allowedShapesInOrder = config.allowedChildShapes?.map((allowedShape) => allowedShape.type) || [];
    const betweenSpacing = 50;
    // Follows the order as per given config.allowedChildShapes
    const shapesToCreate = shapeTypes.map((shapeType) => {
      let y = bounds.y;
      if (childShapes.length > 0) {
        const shapeIndex = allowedShapesInOrder.indexOf(shapeType);
        const previousShape = childShapes.find((shape) => shape?.type === allowedShapesInOrder[shapeIndex - 1]);
        const nextShape = childShapes.find((shape) => shape?.type === allowedShapesInOrder[shapeIndex + 1]);

        if (previousShape) {
          const previousShapeProps = previousShape.props as Partial<TLShapeProps>;
          y = previousShape.y + (previousShapeProps.h || 0) + betweenSpacing;
        } else if (nextShape) {
          const nextShapeProps = nextShape.props as Partial<TLShapeProps>;
          y = nextShape.y - (nextShapeProps.h || 0) - betweenSpacing;
        }
      }

      const shapeId = createShapeId(uuidV4());

      let props: Record<string, any> = {};
      let shapeTypeCopy,
        shapeXPadding = 0;
      if (shapeType.includes("lockedText")) {
        shapeTypeCopy = "lockedText";
        const shapeTypeKey = shapeType.split(":")[1];
        // Additional x padding for lockedText with align end
        shapeXPadding = shapeTypeKey === "start" ? 0 : (bounds.x + bounds.w || TITLE_LABEL_WIDTH) - 200;
        props = {
          text:
            shapeTypeKey === "start"
              ? t("Components.Analyses.TopicDiscussionInNewsArticleAnalysis.DefaultLabel", { type: themes.join(", ") })
              : "",
          align: shapeTypeKey,
        };
      } else {
        shapeTypeCopy = shapeType;
      }

      if (shapeType === "dataGrid") {
        props["config"] = {
          ...config.dataGrid,
          fontSize: config.fontSize,
          autoResize: true,
        };
        props["preferences"] = { ...preferences.dataGrid };
      }

      return {
        id: shapeId,
        parentId: id,
        type: shapeTypeCopy,
        props,
        x: bounds.x + shapeXPadding,
        y: y,
      };
    });

    if (childShapeIds.length > 0) {
      this.editor.createShapes(shapesToCreate);
    } else {
      let previousShape: TLShape | undefined;
      // Create child shapes with y position based on previous shape
      shapesToCreate.forEach((shape) => {
        const y = previousShape ? previousShape.y + betweenSpacing : 0;
        const latestApp = this.editor.createShapes([
          {
            ...shape,
            y: shape?.type === "lockedText" ? previousShape?.y : y,
          },
        ]);

        previousShape = latestApp.getShape(shape.id);
      });
    }
  }

  /**
   * Updates records count label for the lockedText:end shape.
   *
   * @param shape Parent shape
   * @param recordsCountLabel Records count label to update
   * @returns
   */
  private _updateRecordsCountLabel(shape: ITopicDiscussionInNewsArticleAnalysisShape, recordsCountLabel: string) {
    const recordsCountShapeId = this.editor.getSortedChildIdsForParent(shape.id).find((childId) => {
      const childShape = this.editor.getShape(childId);
      const childShapeProps = childShape?.props as Partial<TLShapeProps>;
      if (childShape && childShape.type === "lockedText" && childShapeProps.align === "end") {
        return true;
      }
      return false;
    });

    if (!recordsCountShapeId) {
      return;
    }
    const recordsCountShape = this.editor.getShape(recordsCountShapeId);
    if (!recordsCountShape) {
      return;
    }

    this.editor.updateShapes([
      {
        id: recordsCountShape.id,
        type: recordsCountShape.type,
        props: {
          text: recordsCountLabel,
        },
      },
    ]);
  }

  /**
   * Updates the title text shape with given title.
   *
   * @param shape Analysis shape
   * @param title Title text to update on the title shape
   * @returns
   */
  private _updateTitleLabel(shape: ITopicDiscussionInNewsArticleAnalysisShape, title: string) {
    const titleShapeId = this.editor.getSortedChildIdsForParent(shape.id).find((childId) => {
      const childShape = this.editor.getShape(childId);
      const childShapeProps = childShape?.props as Partial<TLShapeProps>;
      if (childShape && childShape.type === "lockedText" && childShapeProps.align === "start") {
        return true;
      }
      return false;
    });

    if (!titleShapeId) {
      return;
    }

    const titleShape = this.editor.getShape(titleShapeId);
    if (!titleShape) {
      return;
    }

    this.editor.updateShapes([
      {
        id: titleShape.id,
        type: titleShape.type,
        props: {
          text: title,
        },
      },
    ]);
  }

  /**
   * Updates selection for the given shape.
   *
   * @param shape Shape to update
   * @param selection New selection to update
   */
  private _updateSelection(
    shape: ITopicDiscussionInNewsArticleAnalysisShape,
    selection: ITopicDiscussionInNewsArticleAnalysisSelection,
  ) {
    this.editor.updateShapes([
      {
        id: shape.id,
        type: shape.type,
        props: {
          selection,
        },
      },
    ]);
  }

  /**
   * Attributes of selection with empty values
   *
   * @returns Selection attributes with empty values
   */
  private clearSelectionAttributes() {
    return {
      axisSelection: [],
    };
  }

  /**
   * Takes preferences from the dialog & updates to shape props.
   *
   * @param shape Shape to update
   * @param prefereces Selected preferences
   * @param fieldFacetsManager Field facet manager which helps to change the facet values
   */
  private _saveToolbarPreferences(
    shape: ITopicDiscussionInNewsArticleAnalysisShape,
    prefereces: IAnalysisPreferencesDialogState,
    fieldFacetsManager: FieldFacetsManageStateType<ITopicDiscussionInNewsArticleAnalysisFacets>,
  ) {
    const oldToolbarFields = shape.props.toolbar?.availableFields;
    const removedFields = oldToolbarFields?.filter((field) => !prefereces.toolbarFields.includes(field));
    this.editor.updateShapes([
      {
        id: shape.id,
        type: shape.type,
        props: {
          enableBackground: prefereces.isBackgroundEnabled,
          selectedBoundsFields: prefereces.toolbarBoundsFields,
          toolbar: {
            availableFields: prefereces.toolbarFields,
          },
          preferences: {
            ...shape.props.preferences,
            analysis: {
              ...shape.props.preferences.analysis,
              startColor: prefereces.startColor,
              showTopics: prefereces.isSubItemsEnabled,
              showDataToDisplayInToolbar: prefereces.isDataToDisplayInToolbarEnabled,
            },
          },
        },
      },
    ]);

    fieldFacetsManager.onClearSelectedFacetValues(removedFields as Array<keyof ITopicDiscussionInNewsArticleAnalysisFacets>);
  }

  /**
   * Overrides & gives the template attributes.
   *
   * @param t Translation function
   * @param items Selected items
   * @param facets Selected facets
   * @returns
   */
  getTemplateDataContext(
    config: ITopicDiscussionInNewsArticleAnalysisConfig,
    t: TFunction<"translation", undefined>,
    items: Record<string, any>[],
    facets: Record<string, any>,
  ) {
    const title = items.length > 0 ? items[0].theme : t("Components.Charts.News");
    const dateRange =
      facets.selectedDate && (facets.selectedDate.from || facets.selectedDate.to) ? facets.selectedDate : undefined;
    return {
      items,
      facets,
      title,
      dateRange,
    };
  }
}
