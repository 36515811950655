import { RawCommands } from "@tiptap/core";

import { Heading } from "./HeadingExtension.js";

// This command is used to toggle heading node.
export const toggleHeading: RawCommands["toggleHeading"] =
  (attributes) =>
  ({ commands, state }) => {
    if (!Heading.options.levels.includes(attributes.level)) {
      return false;
    }

    const { $from } = state.selection;
    const selectedNode = $from.node();
    if (selectedNode.type.name === Heading.name) {
      return commands.setParagraph();
    }

    return commands.setHeading(attributes);
  };
