import { MENTION_CLASS_NAME, PRIVATE_MENTION_CLASS_NAME } from "@bigpi/cookbook";
import {
  AnyExtension,
  Bold,
  BulletList,
  Document,
  Heading,
  History,
  IExtensionOptions,
  Italic,
  Link,
  ListItem,
  Mention,
  OrderedList,
  Paragraph,
  PrivateMention,
  Strike,
  Text,
  TextStyle,
  Title,
  Underline,
  UserSuggestions,
} from "@bigpi/editor-tiptap";

// Extensions used specifically for chat
export const getEditorExtensionsForChat = (
  extensionOptions: IExtensionOptions = {},
  excludeExtensions: Array<string> = [],
): Array<AnyExtension> => {
  let result: Array<AnyExtension> = [
    BulletList,
    Heading,
    History,
    Link.configure({
      protocols: ["mailto"],
      autolink: true,
      LinkActions: extensionOptions.Link?.LinkActions,
    }),
    ListItem,
    Mention.configure({
      HTMLAttributes: {
        class: MENTION_CLASS_NAME,
      },
      suggestion: {
        ...UserSuggestions,
        items: extensionOptions.Mention?.items,
      },
    }),
    PrivateMention.configure({
      HTMLAttributes: {
        class: PRIVATE_MENTION_CLASS_NAME,
      },
      suggestion: {
        ...UserSuggestions,
        items: extensionOptions.Mention?.items,
      },
    }),
    OrderedList,
    Paragraph,
    Title,

    Bold,
    Document,
    Italic,
    Strike,
    Text,
    TextStyle,
    Underline,
  ];

  // Filter out any extensions that are in the exclude list by name
  result = result.filter((extension) => !excludeExtensions.includes(extension.name));

  return result;
};
