import { Edit, Share } from "@mui/icons-material";
import { TabContext, TabPanel } from "@mui/lab";
import { Box, Button, Dialog, DialogContent, DialogTitle, Tab, Tabs } from "@mui/material";
import { JSXElementConstructor, ReactElement, SyntheticEvent, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { EditWorkspaceMetadata } from "./EditWorkspaceMetadata";
import { WorkspaceAccessControlListEditor } from "Components/AccessControlList/WorkspaceAccessControlListEditor";
import { DefaultDialogTransition } from "Components/DialogTransition/DefaultDialogTransition";

enum ManageWorkspaceTabs {
  EditMetadata = "EditMetadata",
  ShareAccess = "ShareAccess",
}

export interface IManageWorkspaceDialogProps {
  onClose: () => void;
  open: boolean;
  workspaceId: string;
}

export function ManageWorkspaceDialog(props: IManageWorkspaceDialogProps) {
  const { onClose, open, workspaceId } = props;

  const { t } = useTranslation();

  const [tabValue, setTabValue] = useState(ManageWorkspaceTabs.EditMetadata);

  const handleTabChange = (_event: SyntheticEvent, newValue: ManageWorkspaceTabs) => {
    setTabValue(newValue);
  };

  const TABS = useMemo<
    Array<{
      label: string;
      value: ManageWorkspaceTabs;
      icon: ReactElement<any, string | JSXElementConstructor<any>>;
      component: ReactElement<any, string | JSXElementConstructor<any>>;
    }>
  >(
    () => [
      {
        label: t("Components.ManageWorkspaceDialog.Tabs.EditMetadata"),
        value: ManageWorkspaceTabs.EditMetadata,
        icon: <Edit />,
        component: <EditWorkspaceMetadata workspaceId={workspaceId} onClose={onClose} />,
      },
      {
        label: t("Components.ManageWorkspaceDialog.Tabs.ShareAccess"),
        value: ManageWorkspaceTabs.ShareAccess,
        icon: <Share />,
        component: (
          <>
            <WorkspaceAccessControlListEditor workspaceId={workspaceId} />
            <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
              <Button onClick={onClose}>{t("Global.Action.Close")}</Button>
            </Box>
          </>
        ),
      },
    ],
    [t, workspaceId],
  );

  return (
    <Dialog open={open} onClose={onClose} TransitionComponent={DefaultDialogTransition} fullWidth maxWidth="md">
      <DialogTitle variant="h5">{t("Components.ManageWorkspaceDialog.Title")}</DialogTitle>
      <DialogContent>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={tabValue} onChange={handleTabChange}>
            {TABS.map((tab) => {
              return <Tab key={tab.value} icon={tab.icon} iconPosition="start" label={tab.label} value={tab.value} />;
            })}
          </Tabs>
        </Box>

        <TabContext value={tabValue}>
          {TABS.map((tab) => {
            return (
              <TabPanel key={tab.value} value={tab.value} sx={{ px: 0 }}>
                {tab.component}
              </TabPanel>
            );
          })}
        </TabContext>
      </DialogContent>
    </Dialog>
  );
}
