import { TableCell as TipTapTableCell } from "@tiptap/extension-table-cell";

import { getStyleAttribute } from "../../Utils/ExtensionUtil.js";

export const TableCell = TipTapTableCell.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      style: getStyleAttribute(),
    };
  },
});
