import { ApolloClient, useApolloClient } from "@apollo/client";
import { Editor, IEditorConfig, ToolbarButton, ToolbarItemBase } from "@bigpi/editor-tiptap";
import { FileDownloadOutlined } from "@mui/icons-material";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";

const ITEM_NAME = "exportToWord";

/**
 * A toolbar button that exports editor content to a Word file.
 */
export class ExportToWordButtonItem extends ToolbarItemBase {
  // *********************************************
  // Public properties
  // *********************************************/
  /**
   * @inheritdoc
   */
  isExternallyTranslated: boolean = true;

  /**
   * @inheritdoc
   */
  readonly name = ITEM_NAME;

  // *********************************************
  // Public methods
  // *********************************************/
  /**
   * @inheritdoc
   */
  create(editor: Editor, config: IEditorConfig) {
    const { toolbarOptions } = config;
    return (
      <ExportToWordButton
        key={ITEM_NAME}
        exportToWord={(t, apolloClient) =>
          editor.chain().exportToWord(t, apolloClient, config.extensionOptions?.ExportToWord?.exportHtmlToWord).run()
        }
        toolbarOptions={toolbarOptions}
      />
    );
  }
}

/**
 * Props for ExportToWordButton.
 */
export interface ExportToWordButtonProps {
  exportToWord: (t: TFunction, apolloClient: ApolloClient<object>) => void;
  toolbarOptions?: IEditorConfig["toolbarOptions"];
}

/**
 * A toolbar button for the `exportToWord` command.
 */
export const ExportToWordButton = (props: ExportToWordButtonProps) => {
  const { exportToWord, toolbarOptions } = props;
  const tooltipPlacement = toolbarOptions?.tooltipPlacement;

  const { t } = useTranslation();
  const apolloClient = useApolloClient();

  return (
    <ToolbarButton
      Icon={FileDownloadOutlined}
      tooltip={t("Editor.ExportToWord.Tooltip")}
      onClick={() => exportToWord(t, apolloClient)}
      tooltipPlacement={tooltipPlacement}
    />
  );
};
