import * as uuid from "uuid";

import { BistroService } from "../Enums/BistroService.js";
import { UUID_REGEX_STRING } from "../Validation/UuidValidator.js";
import { IChatUrnObject } from "./IChatUrnObject.js";
import { IDocumentUrnObject } from "./IDocumentUrnObject.js";
import { IUserUrnObject } from "./IUserUrnObject.js";
import { IWorkspaceBoardShapeUrnObject } from "./IWorkspaceBoardShapeUrnObject.js";
import { IWorkspaceBoardUrnObject } from "./IWorkspaceBoardUrnObject.js";
import { IWorkspaceUrnObject } from "./IWorkspaceUrnObject.js";
import { UrnObjectType } from "./UrnObjectType.js";
import { IFileUrnObject } from "./IFileUrnObject.js";
import { IWorkspaceFileUrnObject } from "./IWorkspaceFileUrnObject.js";

/**
 * Creates a URN for a chat message.
 *
 * @param organizationId The organization ID.
 * @param channelType The channel type.
 * @param channelId The channel ID.
 * @param messageId The message ID. This is the ID of the first or sub-message in a thread.
 *
 * @returns The chat URN.
 */
export function createChatUrn(organizationId: string, channelType: string, channelId: string, messageId: string): string {
  // Validate the input
  if (uuid.validate(organizationId) === false) {
    throw new Error(`Invalid organizationId: ${organizationId}`);
  }

  if (!channelType) {
    throw new Error("channelType is required.");
  }

  if (!channelId) {
    throw new Error("channelId is required.");
  }

  if (uuid.validate(messageId) === false) {
    throw new Error(`Invalid messageId: ${messageId}`);
  }

  return `${UrnObjectType.Chat}${organizationId}/${channelType}/${channelId}/${messageId}`;
}

/**
 * Creates a URN for a document.
 *
 * @param organizationId The organization ID.
 * @param documentId The document ID.
 * @param blockId The optional block ID.
 *
 * @returns The document URN.
 */
export function createDocumentUrn(organizationId: string, documentId: string, blockId?: string): string {
  // Validate the input
  if (uuid.validate(organizationId) === false) {
    throw new Error(`Invalid organizationId: ${organizationId}`);
  }

  if (uuid.validate(documentId) === false) {
    throw new Error(`Invalid documentId: ${documentId}`);
  }

  if (blockId && uuid.validate(blockId) === false) {
    throw new Error(`Invalid blockId: ${blockId}`);
  }

  return `${UrnObjectType.Document}${organizationId}/${documentId}${blockId ? `#${blockId}` : ""}`;
}

/**
 * Creates a URN for a file.
 *
 * @param organizationId The organization ID.
 * @param fileId The file ID.
 *
 * @returns The file URN.
 */
export function createFileUrn(organizationId: string, fileId: string): string {
  // Validate the input
  if (uuid.validate(organizationId) === false) {
    throw new Error(`Invalid organizationId: ${organizationId}`);
  }

  if (uuid.validate(fileId) === false) {
    throw new Error(`Invalid fileId: ${fileId}`);
  }

  return `${UrnObjectType.File}${organizationId}/${fileId}`;
}

/**
 * Creates a URN for a user.
 *
 * @param userId The user ID.
 *
 * @returns The user URN.
 */
export function createUserUrn(userId: string): string {
  // Validate the input
  if (uuid.validate(userId) === false) {
    throw new Error(`Invalid userId: ${userId}`);
  }

  return `${UrnObjectType.User}${userId}`;
}

/**
 * Creates a URN for a workspace.
 *
 * @param organizationId The organization ID.
 * @param workspaceId The workspace ID.
 *
 * @returns The workspace URN.
 */
export function createWorkspaceUrn(organizationId: string, workspaceId: string): string {
  // Validate the input
  if (uuid.validate(organizationId) === false) {
    throw new Error(`Invalid organizationId: ${organizationId}`);
  }

  if (uuid.validate(workspaceId) === false) {
    throw new Error(`Invalid workspaceId: ${workspaceId}`);
  }

  return `${UrnObjectType.Workspace}${organizationId}/${workspaceId}`;
}

/**
 * Creates a URN for a workspace board.
 *
 * @param organizationId The organization ID.
 * @param workspaceId The workspace ID.
 * @param workspaceBoardId The workspace board ID.
 *
 * @returns The workspace board URN.
 */
export function createWorkspaceBoardUrn(organizationId: string, workspaceId: string, workspaceBoardId: string): string {
  // Validate the input
  if (uuid.validate(organizationId) === false) {
    throw new Error(`Invalid organizationId: ${organizationId}`);
  }

  if (uuid.validate(workspaceId) === false) {
    throw new Error(`Invalid workspaceId: ${workspaceId}`);
  }

  if (uuid.validate(workspaceBoardId) === false) {
    throw new Error(`Invalid workspaceBoardId: ${workspaceBoardId}`);
  }

  return `${UrnObjectType.WorkspaceBoard}${organizationId}/${workspaceId}/${workspaceBoardId}`;
}

/**
 * Creates a URN for a workspace board shape.
 *
 * @param organizationId The organization ID.
 * @param workspaceId The workspace ID.
 * @param workspaceBoardId The workspace board ID.
 * @param shapeId The shape ID.
 * @param blockId The optional block ID.
 *
 * @returns The workspace board shape URN.
 */
export function createWorkspaceBoardShapeUrn(
  organizationId: string,
  workspaceId: string,
  workspaceBoardId: string,
  shapeId: string,
  blockId?: string,
): string {
  // Validate the input
  if (uuid.validate(organizationId) === false) {
    throw new Error(`Invalid organizationId: ${organizationId}`);
  }

  if (uuid.validate(workspaceId) === false) {
    throw new Error(`Invalid workspaceId: ${workspaceId}`);
  }

  if (uuid.validate(workspaceBoardId) === false) {
    throw new Error(`Invalid workspaceBoardId: ${workspaceBoardId}`);
  }

  if (!shapeId) {
    throw new Error("shapeId is required.");
  }

  if (blockId && uuid.validate(blockId) === false) {
    throw new Error(`Invalid blockId: ${blockId}`);
  }

  return `${UrnObjectType.WorkspaceBoardShape}${organizationId}/${workspaceId}/${workspaceBoardId}/${shapeId}${
    blockId ? `#${blockId}` : ""
  }`;
}

/**
 * Creates a URN for a workspace file.
 *
 * @param organizationId The organization ID.
 * @param workspaceId The workspace ID.
 * @param workspaceFileId The workspace file ID.
 *
 * @returns The workspace file URN.
 */
export function createWorkspaceFileUrn(organizationId: string, workspaceId: string, workspaceFileId: string): string {
  // Validate the input
  if (uuid.validate(organizationId) === false) {
    throw new Error(`Invalid organizationId: ${organizationId}`);
  }

  if (uuid.validate(workspaceId) === false) {
    throw new Error(`Invalid workspaceId: ${workspaceId}`);
  }

  if (uuid.validate(workspaceFileId) === false) {
    throw new Error(`Invalid workspaceFileId: ${workspaceFileId}`);
  }

  return `${UrnObjectType.WorkspaceFile}${organizationId}/${workspaceId}/${workspaceFileId}`;
}

/**
 * Parses a URN string into a strongly typed object.
 *
 * @param urn The URN string.
 *
 * @returns The parsed type specific object.
 */
export function parseUrn(urn: string) {
  if (!urn) {
    throw new Error("urn is required.");
  }

  // Get the URN type from the beginning of the string up to second colon.
  const type = urn.substring(0, urn.indexOf(":", urn.indexOf(":") + 1) + 1);

  switch (type) {
    case UrnObjectType.Chat:
      return parseChatUrn(urn);

    case UrnObjectType.Document:
      return parseDocumentUrn(urn);

    case UrnObjectType.File:
      return parseFileUrn(urn);

    case UrnObjectType.User:
      return parseUserUrn(urn);

    case UrnObjectType.Workspace:
      return parseWorkspaceUrn(urn);

    case UrnObjectType.WorkspaceBoard:
      return parseWorkspaceBoardUrn(urn);

    case UrnObjectType.WorkspaceBoardShape:
      return parseWorkspaceBoardShapeUrn(urn);

    case UrnObjectType.WorkspaceFile:
      return parseWorkspaceFileUrn(urn);

    default:
      throw new Error(`Invalid or unsupported URN: ${urn}`);
  }
}

export function parseChatUrn(urn: string): IChatUrnObject {
  // Format: urn:chat:<organizationId>/<channelType>/<channelId>/<parentId>/<messageId>
  const parser = new RegExp(`^${UrnObjectType.Chat}(${UUID_REGEX_STRING})\/([^/]+)\/([^/]+)\/(${UUID_REGEX_STRING})$`);
  const matches = urn.match(parser);
  if (!matches) {
    throw new Error(`Invalid chat URN: ${urn}`);
  }

  const result: IChatUrnObject = {
    objectType: UrnObjectType.Chat,
    service: BistroService.Pantry,

    organizationId: matches[1],
    channelType: matches[2],
    channelId: matches[3],
    messageId: matches[4],
  };

  return result;
}

export function parseDocumentUrn(urn: string): IDocumentUrnObject {
  // Format: urn:document:<organizationId>/<documentId>#<blockId>
  const parser = new RegExp(
    `^${UrnObjectType.Document}(${UUID_REGEX_STRING})\/(${UUID_REGEX_STRING})(?:#(${UUID_REGEX_STRING})?)?$`,
  );
  const matches = urn.match(parser);
  if (!matches) {
    throw new Error(`Invalid document URN: ${urn}`);
  }

  const result: IDocumentUrnObject = {
    objectType: UrnObjectType.Document,
    service: BistroService.Pantry,

    organizationId: matches[1],
    documentId: matches[2],
    blockId: matches[3],
  };

  return result;
}

export function parseFileUrn(urn: string): IFileUrnObject {
  // Format: urn:file:<organizationId>/<fileId>
  const parser = new RegExp(`^${UrnObjectType.File}(${UUID_REGEX_STRING})\/(${UUID_REGEX_STRING})$`);
  const matches = urn.match(parser);
  if (!matches) {
    throw new Error(`Invalid file URN: ${urn}`);
  }

  const result: IFileUrnObject = {
    objectType: UrnObjectType.File,
    service: BistroService.Pantry,

    organizationId: matches[1],
    fileId: matches[2],
  };

  return result;
}

export function parseUserUrn(urn: string): IUserUrnObject {
  // Format: urn:user:<userId>
  const parser = new RegExp(`^${UrnObjectType.User}(${UUID_REGEX_STRING})$`);
  const matches = urn.match(parser);
  if (!matches) {
    throw new Error(`Invalid user URN: ${urn}`);
  }

  const result: IUserUrnObject = {
    objectType: UrnObjectType.User,
    service: BistroService.Pantry,

    // NOTE: Organization ID is not included in the user URN.
    organizationId: "",
    userId: matches[1],
  };

  return result;
}

export function parseWorkspaceUrn(urn: string): IWorkspaceUrnObject {
  // Format: urn:workspace:<organizationId>/<workspaceId>
  const parser = new RegExp(`^${UrnObjectType.Workspace}(${UUID_REGEX_STRING})\/(${UUID_REGEX_STRING})$`);
  const matches = urn.match(parser);
  if (!matches) {
    throw new Error(`Invalid workspace URN: ${urn}`);
  }

  const result: IWorkspaceUrnObject = {
    objectType: UrnObjectType.Workspace,
    service: BistroService.Pantry,

    organizationId: matches[1],
    workspaceId: matches[2],
  };

  return result;
}

export function parseWorkspaceBoardUrn(urn: string): IWorkspaceBoardUrnObject {
  // Format: urn:workspace-board:<organizationId>/<workspaceId>/<workspaceBoardId>
  const parser = new RegExp(
    `^${UrnObjectType.WorkspaceBoard}(${UUID_REGEX_STRING})\/(${UUID_REGEX_STRING})\/(${UUID_REGEX_STRING})$`,
  );
  const matches = urn.match(parser);
  if (!matches) {
    throw new Error(`Invalid workspace board URN: ${urn}`);
  }

  const result: IWorkspaceBoardUrnObject = {
    objectType: UrnObjectType.WorkspaceBoard,
    service: BistroService.Pantry,

    organizationId: matches[1],
    workspaceId: matches[2],
    workspaceBoardId: matches[3],
  };

  return result;
}

export function parseWorkspaceBoardShapeUrn(urn: string): IWorkspaceBoardShapeUrnObject {
  // Format: urn:workspace-board-shape:<organizationId>/<workspaceId>/<workspaceBoardId>/<shapeId>
  const parser = new RegExp(
    `^${UrnObjectType.WorkspaceBoardShape}(${UUID_REGEX_STRING})\/(${UUID_REGEX_STRING})\/(${UUID_REGEX_STRING})\/([^/#]+)(?:#(${UUID_REGEX_STRING})?)?$`,
  );
  const matches = urn.match(parser);
  if (!matches) {
    throw new Error(`Invalid workspace board shape URN: ${urn}`);
  }

  const result: IWorkspaceBoardShapeUrnObject = {
    objectType: UrnObjectType.WorkspaceBoardShape,
    service: BistroService.Pantry,

    organizationId: matches[1],
    workspaceId: matches[2],
    workspaceBoardId: matches[3],
    shapeId: matches[4],
    blockId: matches[5] || undefined,
  };

  return result;
}

export function parseWorkspaceFileUrn(urn: string): IWorkspaceFileUrnObject {
  // Format: urn:workspace-file:<organizationId>/<workspaceId>/<workspaceFileId>
  const parser = new RegExp(
    `^${UrnObjectType.WorkspaceFile}(${UUID_REGEX_STRING})\/(${UUID_REGEX_STRING})\/(${UUID_REGEX_STRING})$`,
  );
  const matches = urn.match(parser);
  if (!matches) {
    throw new Error(`Invalid workspace file URN: ${urn}`);
  }

  const result: IWorkspaceFileUrnObject = {
    objectType: UrnObjectType.WorkspaceFile,
    service: BistroService.Pantry,

    organizationId: matches[1],
    workspaceId: matches[2],
    workspaceFileId: matches[3],
  };

  return result;
}
