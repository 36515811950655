import { findParentNode, getNodeType, isList, RawCommands } from "@tiptap/core";

// This command is run when a new list is created using the editor
// menu button (using command), we have cloned "toggleList" command
// logic in this file, only change we have made in this file is
// adding "data-list-id" on new list item.
export const toggleList: RawCommands["toggleList"] =
  (listTypeOrName, itemTypeOrName, keepMarks, attributes: Record<string, any> | undefined = {}) =>
  ({ editor, tr, state, dispatch, chain, commands, can }) => {
    const { extensions, splittableMarks } = editor.extensionManager;
    const listType = getNodeType(listTypeOrName, state.schema);
    const { selection, storedMarks } = state;
    const marks = storedMarks || (selection.$to.parentOffset && selection.$from.marks());

    const parentList = findParentNode((node) => isList(node.type.name, extensions))(selection);
    if (parentList && parentList.node.type === listType) {
      return commands.clearListNodes();
    }

    return (
      chain()
        // try to convert node to default node if needed
        .command(() => {
          if (keepMarks && marks && dispatch) {
            const filteredMarks = marks.filter((mark) => splittableMarks.includes(mark.type.name));
            tr.ensureMarks(filteredMarks);
          }

          const canWrapInList = can().wrapInList(listType, attributes);
          if (canWrapInList) {
            return true;
          }

          return commands.clearListNodes();
        })
        .wrapInList(listType, attributes)
        .run()
    );
  };
