import { CommandOutputType } from "@bigpi/cookbook";
import { createShapeId } from "@tldraw/tldraw";
import { v4 as uuidV4 } from "uuid";

import { ResponsePlugInBase } from "./ResponsePlugInBase";
import { SearchResultDialog } from "Components/SearchResultDialog/SearchResultDialog";
import { ICommandResponsePlugInInputs } from "Components/CommandManagers/WorkspaceBoard/WorkspaceBoardCommandManager";

export class SearchResultResponsePlugIn extends ResponsePlugInBase {
  // *********************************************
  // Public properties
  // *********************************************/
  /**
   * @inheritdoc
   */
  name = CommandOutputType.SearchResults;

  // *********************************************
  // Public methods
  // *********************************************/
  /**
   * @inheritdoc
   */
  public async execute(input: ICommandResponsePlugInInputs): Promise<undefined | JSX.Element> {
    const { app, commandResponse, onClose } = input;

    if (!app) {
      console.warn(`No app instance for command response ${commandResponse.requestId}`);
      return;
    }

    function onAdd(selectedData: Array<any>) {
      if (app && selectedData.length > 0) {
        // TODO: Insert data into board based on real template
        const html: string = selectedData?.map((row: any) => row.content).join("<br /><br />") || "";
        app.createShapes([
          {
            id: createShapeId(uuidV4()),
            type: "htmlDocument",
            props: {
              html,
            },
          },
        ]);

        onClose();
      }
    }

    return <SearchResultDialog open={true} data={input.commandResponse.data} onAdd={onAdd} onCancel={onClose} />;
  }
}
