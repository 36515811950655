import { RawCommands } from "@tiptap/core";
import { liftTarget, Transform, ReplaceAroundStep } from "@tiptap/pm/transform";
import { Fragment, NodeRange, Slice } from "@tiptap/pm/model";

import { getBaseClassWithoutIndent, getIndentClass, getIndentLevelByIndentClass } from "../../Utils/CommandUtil.js";

// We have cloned "lift" function from "prosemirror-transform" (https://github.com/ProseMirror/prosemirror-transform/blob/master/src/structure.ts)
// and changed it to keep the indentation on the paragraph which is transformed
// from a list.
//
// For example -
//
// 1. first
// 2. second
//   a. third
//     i. fourth
//   b. fifth
// 3. sixth
//
//
// Should change to -
//
// first
// second
//   third
//     fourth
//   fifth
// sixth
function lift(tr: Transform, range: NodeRange, target: number) {
  const { $from, $to, depth } = range;

  const listNode = $from.node(depth - 1);
  const paragraphPos = $from.before(depth) + 1;
  const gapStart = $from.before(depth + 1);
  const gapEnd = $to.after(depth + 1);
  let start = gapStart;
  let end = gapEnd;

  let before = Fragment.empty;
  let openStart = 0;
  for (let d = depth, splitting = false; d > target; d--) {
    if (splitting || $from.index(d) > 0) {
      splitting = true;
      before = Fragment.from($from.node(d).copy(before));
      openStart++;
    } else {
      start--;
    }
  }

  let after = Fragment.empty;
  let openEnd = 0;
  for (let d = depth, splitting = false; d > target; d--) {
    if (splitting || $to.after(d + 1) < $to.end(d)) {
      splitting = true;
      after = Fragment.from($to.node(d).copy(after));
      openEnd++;
    } else {
      end++;
    }
  }

  const indentClass = getIndentClass(listNode?.attrs.class);
  const indentLevel = getIndentLevelByIndentClass(indentClass);

  // Apply indent class from list node to the new paragraph node
  tr.setNodeAttribute(paragraphPos, "class", getBaseClassWithoutIndent(listNode?.attrs.class));
  tr.setNodeAttribute(paragraphPos, "indent", indentLevel);

  // Lifting list to paragraph
  tr.step(
    new ReplaceAroundStep(
      start,
      end,
      gapStart,
      gapEnd,
      new Slice(before.append(after), openStart, openEnd),
      before.size - openStart,
      true,
    ),
  );
}

// This command is used for clearing list items exclusively, we
// have cloned "clearNodes" command logic in this file which is
// more generic version used for clearing/ stripping nodes to change
// them to basic paragraph nodes, only change we have made in this
// file is adding using custom "lift" logic.
export const clearListNodes: RawCommands["clearListNodes"] =
  () =>
  ({ state, tr, dispatch }) => {
    const { selection } = tr;
    const { ranges } = selection;

    if (!dispatch) {
      return true;
    }

    ranges.forEach(({ $from, $to }) => {
      state.doc.nodesBetween($from.pos, $to.pos, (node, pos) => {
        if (node.type.isText) {
          return;
        }

        const { doc, mapping } = tr;
        const $mappedFrom = doc.resolve(mapping.map(pos));
        const $mappedTo = doc.resolve(mapping.map(pos + node.nodeSize));
        const nodeRange = $mappedFrom.blockRange($mappedTo);

        if (!nodeRange) {
          return;
        }

        const targetLiftDepth = liftTarget(nodeRange);
        if (node.type.isTextblock) {
          const { defaultType } = $mappedFrom.parent.contentMatchAt($mappedFrom.index());
          tr.setNodeMarkup(nodeRange.start, defaultType);
        }

        if (targetLiftDepth || targetLiftDepth === 0) {
          // Using custom lift logic here
          lift(tr, nodeRange, targetLiftDepth);
        }
      });
    });

    return true;
  };
