import React from "react";

import { SvgIcon, SvgIconProps } from "@mui/material";

export const ImageStyleWrapAlignRightIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path d="M 21 20 L 3 20 L 3 18 L 21 18 Z M 11 16 L 3 16 L 3 14 L 11 14 Z M 21 2 L 21 4 L 3 4 L 3 2 Z M 11 8 L 3 8 L 3 6 L 11 6 Z M 11 12 L 3 12 L 3 10 L 11 10 Z" />
    <path d="M 20 15 L 14 15 L 14 7 L 20 7 Z" />
  </SvgIcon>
);
