import { ICommand } from "@bigpi/cookbook";
import { useAuthUser } from "@frontegg/react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import * as React from "react";
import { useTranslation } from "react-i18next";

import { useGetLatestConfigsByPrefixQuery } from "GraphQL/Generated/Apollo";
import { getOutputTypeIcon } from "./Utils";

interface ICommandAutocompleteProps {
  onCommandSelected: (command: ICommand) => void;
}

export function CommandAutocomplete(props: ICommandAutocompleteProps) {
  const { onCommandSelected } = props;
  const { t } = useTranslation();
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [value, setValue] = React.useState<ICommand | null>(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState<readonly ICommand[]>([]);
  const user = useAuthUser();
  const { data, loading, error } = useGetLatestConfigsByPrefixQuery({
    variables: {
      keyPrefix: "bistro-command-",
      organizationId: user.tenantId,
    },
  });

  React.useEffect(() => {
    if (data?.LatestConfigsByPrefix) {
      const commands: Array<ICommand> = [];
      data.LatestConfigsByPrefix.forEach((config) => {
        // Handles in case of empty command
        const configData = config.data ? JSON.parse(config.data) : undefined;
        if (configData && !configData.disabled) {
          commands.push(configData);
        }
      });

      // Sort the commands by name
      commands.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
      setOptions(commands);
    }
  }, [data]);

  React.useEffect(() => {
    // HACK: Should wait for transition to finish instead
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 350);
  }, [inputRef.current]);

  return (
    <Autocomplete
      sx={{ mt: 1 }}
      getOptionLabel={(option) => (typeof option === "string" ? option : option.name)}
      options={options}
      autoComplete
      openOnFocus
      includeInputInList
      filterSelectedOptions
      value={value}
      noOptionsText={t("Components.CommandAutocomplete.NoCommands")}
      isOptionEqualToValue={(option, value) => option.id == value.id}
      onChange={(event: any, newValue: ICommand | null) => {
        setValue(newValue);
        if (newValue) {
          onCommandSelected(newValue);
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} inputRef={inputRef} label={t("Components.CommandAutocomplete.SelectCommand")} fullWidth />
      )}
      renderOption={(props, option) => {
        const matches = match(option.name, inputValue, { insideWords: true });

        const parts = parse(option.name, matches);

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: "flex", width: 44 }}>
                {getOutputTypeIcon(option.outputTypes[0])}
              </Grid>
              <Grid item sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}>
                {parts.map((part, index) => (
                  <Box key={index} component="span" sx={{ fontWeight: part.highlight ? "bold" : "regular" }}>
                    {part.text}
                  </Box>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option.description}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}
