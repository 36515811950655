import { ContentTypes } from "@bigpi/cookbook";

export function getImageBase64FromImageSrc(imageSrc: string) {
  return imageSrc.replace(`data:${ContentTypes.PNG};base64,`, "");
}

export function getImageSrcFromImageBase64(imageBase64: string) {
  return `data:${ContentTypes.PNG};base64,${imageBase64}`;
}

export async function getCanvasToImageBase64(canvasElement: HTMLCanvasElement | null): Promise<string | null> {
  if (canvasElement) {
    try {
      const thumbnailBase64 = canvasElement.toDataURL(ContentTypes.PNG);
      return getImageBase64FromImageSrc(thumbnailBase64);
    } catch (e) {
      console.error(e);
    }
  }
  return null;
}

/**
 * Gives the data url of the svg element
 *
 * @param svgElement SVG DOM Element
 * @returns
 */
export function getSvgElementDataUrl(svgElement: SVGElement) {
  const svgString = new XMLSerializer().serializeToString(svgElement);
  return `data:${ContentTypes.SVG};base64,${btoa(svgString)}`;
}
