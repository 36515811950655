import React from "react";
import { FormatIndentDecreaseOutlined } from "@mui/icons-material";
import type { Editor } from "@tiptap/react";
import { useTranslation } from "react-i18next";

import type { IEditorConfig } from "../../Editor/index.js";
import { getEditorToolbarButtonTooltip } from "../../Utils/ToolbarItemUtils.js";
import { ToolbarItemBase } from "../../Toolbars/ToolbarItemBase.js";
import { ToolbarButton } from "../../Toolbars/ToolbarButton.js";

const ITEM_NAME = "outdent";

/**
 * A toolbar button that outdents at the current cursor position.
 */
export class OutdentButtonItem extends ToolbarItemBase {
  /**
   * @inheritdoc
   */
  readonly name = ITEM_NAME;

  /**
   * @inheritdoc
   */
  create(editor: Editor, config: IEditorConfig) {
    const { toolbarOptions } = config;
    return (
      <OutdentButton key={ITEM_NAME} onClick={() => editor.chain().focus().outdent().run()} toolbarOptions={toolbarOptions} />
    );
  }
}

/**
 * Props for OutdentButton.
 */
export interface OutdentButtonProps {
  onClick: () => void;
  toolbarOptions?: IEditorConfig["toolbarOptions"];
}

/**
 * A toolbar button for the `outdent` command.
 */
export const OutdentButton = (props: OutdentButtonProps) => {
  const { onClick: toggle, toolbarOptions } = props;
  const tooltipPlacement = toolbarOptions?.tooltipPlacement;

  const { t } = useTranslation();

  return (
    <ToolbarButton
      Icon={FormatIndentDecreaseOutlined}
      tooltip={getEditorToolbarButtonTooltip(t, ITEM_NAME)}
      onClick={toggle}
      tooltipPlacement={tooltipPlacement}
    />
  );
};
