import { ApolloClient } from "@apollo/client";
import { IDataGridShape, IDataGridColumnDef } from "@bigpi/tl-schema";
import { Box, Editor, Geometry2d, Mat, Rectangle2d, ShapeUtil, TLOnDoubleClickHandler, TLShape, Vec } from "@tldraw/tldraw";
import { TFunction } from "i18next";

import { IAnalysisConfig } from "BoardComponents/Analyses/DataFrameConfigs/IAnalysisConfig";
import { DataGridShapeUtil } from "BoardComponents/DataGridShape/DataGridShape";
import { IChartShapeProps } from "BoardComponents/Charting/IChartShapeProps";
import { DashedOutlineBox } from "BoardComponents/DashedOutlineBox/DashedOutlineBox";
import { AnalysisToolbarActions } from "BoardComponents/Analyses/AnalysisToolbarActions";
import { addTemplatedDocumentToBoard } from "BoardComponents/Utils/AddToBoardUtils";
import { downloadCsv } from "BoardComponents/Utils/DataDownloadUtils";
import { IAnalysisFacets } from "BoardComponents/Types";

// *********************************************
// Public constants
// *********************************************/
export const ALLOWED_ANALYSES_CHILD_STANDARD_SHAPE_TYPES = [
  // Standard shapes
  "text",
];

// *********************************************
// Private constants
// *********************************************/
const INNER_PADDING = 50;

/**
 * A base class for dataframe shapes that encapsulates common, default behavior.
 */
export abstract class DataframeBaseUtil<T extends TLShape, TFacets extends IAnalysisFacets = {}> extends ShapeUtil<T> {
  // *********************************************
  // Protected fields
  // *********************************************/
  protected disableDefaultDoubleClick = true;

  // *********************************************
  // Method overrides, event handlers
  // *********************************************/
  /**
   * Doesn't allow to drop shapes into itself
   * @param frame TLShape Analysis shape
   * @param shapes Shapes which are dragged over
   * @returns
   */
  onDragShapesOver = (frame: TLShape, shapes: Array<TLShape>) => {
    return { shouldHint: false };
  };

  /**
   * We don't have any simple way to restrict the children to drag out
   * So reparent children shapes with analysis shape
   *
   * @param _shape Analysis shape
   * @param shapes Shapes tried to drag out
   */
  onDragShapesOut = (_shape: TLShape, shapes: Array<TLShape>) => {
    this.editor.reparentShapes(
      shapes.map((shape) => shape.id),
      _shape.id,
    );
  };

  /**
   * @inheritdoc
   */
  canDropShapes = (shape: TLShape, shapes: Array<TLShape>): boolean => {
    return shapes.every((candidateShape) => ALLOWED_ANALYSES_CHILD_STANDARD_SHAPE_TYPES.includes(candidateShape.type));
  };

  /**
   * Overrides double-click behavior to prevent zooming and centering.
   *
   * @param shape The shape that was double-clicked.
   *
   * @returns A change to apply to the shape, or void.
   */
  onDoubleClick: TLOnDoubleClickHandler<T> | undefined = (shape: T) => {
    if (this.disableDefaultDoubleClick) {
      // Prevent Tldraw's default zoom and center behavior
      // this.editor.setPageState({ editingId: shape.id, hoveredId: null }, false);
      this.editor.setEditingShape(shape.id);
      this.editor.setHoveredShape(null);
      this.editor.mark("editing shape");
      this.editor.setCurrentTool("select.editing_shape", {
        target: "shape",
        shape,
      });

      return;
    } else {
      // @ts-expect-error TODO: This is invalid and will be flagged by newer TypeScript versions
      super.onDoubleClick?.(shape);
    }
  };

  /**
   * Gives the shape a default geometry
   *
   * @param shape Shape to get the geometry for
   * @returns
   */
  getGeometry(shape: TLShape): Geometry2d {
    let boundingBox: Box | null = null;

    const children = this.editor.getSortedChildIdsForParent(shape.id);
    if (children.length === 0) {
      boundingBox = new Box();
    }

    const allChildPoints = children.flatMap((childId) => {
      const shape = this.editor.getShape(childId)!;
      return this.editor
        .getShapeGeometry(childId)
        .getVertices()
        .map((point) => Mat.applyToPoint(this.editor.getShapeLocalTransform(shape), point));
    });

    boundingBox = Box.FromPoints(allChildPoints);
    // Adds padding to the bounding box
    boundingBox.point = new Vec(boundingBox.x - INNER_PADDING, boundingBox.y - INNER_PADDING);

    return new Rectangle2d({
      x: boundingBox.x,
      y: boundingBox.y,
      width: boundingBox.w + INNER_PADDING * 2,
      height: boundingBox.h + INNER_PADDING * 2,
      isFilled: true,
    });
  }

  // *********************************************
  // Method overrides
  // *********************************************/
  /**
   * @inheritdoc
   */
  override indicator(shape: T) {
    const zoomLevel = this.editor.getZoomLevel();
    const bounds = this.getGeometry(shape).getBounds();

    return <DashedOutlineBox bounds={bounds} zoomLevel={zoomLevel} />;
  }

  // *********************************************
  // Public methods
  // *********************************************/
  /**
   *
   * @param shape Current shape.
   * @param config Config to use.
   * @param data Data to use.
   * @param facets Applied facets.
   * @param selectedIds Selected IDs from the grid.
   * @param t Translation function.
   * @param apolloClient Apollo client to use.
   * @param option Selected option.
   */
  public onSplitButtonClick(
    shape: TLShape,
    config: IAnalysisConfig,
    data: Array<Record<string, any>>,
    facets: Record<string, any>,
    selectedIds: Array<string>,
    t: TFunction<"translation", undefined>,
    apolloClient: ApolloClient<object>,
    option: string,
    onShowFeedbackDialog: () => void,
  ) {
    if (option === AnalysisToolbarActions.AddToDocument) {
      this.onAddItemsToBoard(this.editor, shape, data, selectedIds, config, facets, t, apolloClient);
    } else if (option === AnalysisToolbarActions.DownloadCsv) {
      DataframeBaseUtil.onDownloadCsv(this.editor, shape, config, data, selectedIds, t);
    } else if (option === AnalysisToolbarActions.ProvideFeedback) {
      onShowFeedbackDialog();
    } else if (option === AnalysisToolbarActions.Reset) {
      this.onClearSelection(shape, facets);
    } else if (option === AnalysisToolbarActions.EnableBackground) {
      this.onBackgroundClick(shape);
    }
  }

  /**
   * Adds an HTML document to the board.
   *
   * @param shape Shape details.
   * @param data Data to use.
   * @param selectedIds SelectedIds from the grid.
   * @param config Config to use.
   * @param facets Facets to use.
   * @param t Translation function.
   * @param apolloClient Apollo client to use.
   */
  public async onAddItemsToBoard(
    editor: Editor,
    shape: TLShape,
    data: Array<Record<string, any>>,
    selectedIds: Array<string>,
    config: IAnalysisConfig,
    facets: Record<string, any>,
    t: TFunction<"translation", undefined>,
    apolloClient: ApolloClient<object>,
  ) {
    if (shape) {
      const selectedItems =
        selectedIds.length > 0 ? data.filter((item: Record<string, any>) => selectedIds.includes(item.id)) : data;
      // TODO: Should this use the standard placement method, or do we always want the document to be placed below the (grid) shape?
      const bounds = editor.getShapePageBounds(shape);
      const documentPosition = bounds?.getHandlePoint("bottom_left") || new Vec();
      documentPosition?.add({ x: 0, y: 50 });

      // Get template from grid config
      const template = config.dataGrid.documentItemTemplate;

      // Get the data context to use for templating
      const dataContext = this.getTemplateDataContext(config, t, selectedItems, facets);

      // Add the document
      await addTemplatedDocumentToBoard(editor, template, dataContext, documentPosition, t, apolloClient);
    }
  }

  /**
   * Returns a function that can be used to download the data grid as a CSV.
   *
   * @param editor TLDraw editor instance.
   * @param shape The data frame shape.
   * @param config Config for the analysis.
   * @param data Data to download.
   * @param selectedIds Selected IDs from the grid. If empty, all data will be downloaded.
   * @param t The translation function.
   */
  public static onDownloadCsv(
    editor: Editor,
    shape: TLShape,
    config: IAnalysisConfig,
    data: Array<Record<string, any>>,
    selectedIds: Array<string>,
    t: TFunction<"translation", undefined>,
  ) {
    // Get current data grid shape preferences if a child data grid exists
    const dataGridPreferences = DataframeBaseUtil.getFirstDataGridShapePreferences(editor, shape);

    // Get the column visibility from the preferences, if available
    const groupedColumns = dataGridPreferences?.rowGroupingModel || [];

    // Create an array of the columns we want to include in the CSV
    let dataGridColumns = config.dataGrid.columns;

    // Filter out any columns that are hidden
    const includeColumns: Array<string> = [];

    // Loop through the CSV configuration and add the columns that are visible
    dataGridColumns.forEach((column: IDataGridColumnDef) => {
      const fieldKey = column.field;
      const isExportEnabled = column.isExportEnabled ?? true;
      const isVisible = column.isVisible ?? true;

      if (isExportEnabled && (isVisible || groupedColumns.includes(fieldKey))) {
        // Add the column key to the list of columns to include
        includeColumns.push(fieldKey);
      }
    });

    downloadCsv(includeColumns, config.dataGrid.columns, data, selectedIds, t("Components.Analyses.Common.DefaultFilename"), t);
  }

  /**
   * Toggles the background color of the shape.
   *
   * @param shape
   */
  public onBackgroundClick(shape: TLShape) {
    const shapeProps = (shape.props || {}) as IChartShapeProps;
    this.editor.updateShapes([
      {
        id: shape.id,
        type: shape.type,
        props: {
          enableBackground: !shapeProps.enableBackground,
        },
      },
    ]);
  }

  /**
   * Returns the attributes to be used in the template.
   * Helps to override by analysis shapes.
   *
   * @param items Data items
   * @param facets Facets applied
   * @param title Title of the analysis
   * @returns
   */
  public getTemplateDataContext(
    config: IAnalysisConfig,
    t: TFunction<"translation", undefined>,
    items: Array<Record<string, any>>,
    facets: Record<string, any>,
  ) {
    return {
      items,
      facets,
    };
  }

  /**
   * Gets the first data grid shape for the given data frame if one exists.
   *
   * @param dataframeShape The parent data frame shape.
   *
   * @returns The first child data grid shape, or undefined.
   */
  public static getFirstDataGridShape(editor: Editor, dataframeShape: TLShape) {
    // Get the first grid shape in the data frame
    return editor.getSortedChildIdsForParent(dataframeShape).find((childId) => {
      const childShape = editor.getShape(childId);
      return childShape?.type === DataGridShapeUtil.type;
    });
  }

  /**
   * Gets the data grid shape preferences for the given shape.
   *
   * @param dataframeShape Shape to get the data grid shape for
   * @returns
   */
  public static getFirstDataGridShapePreferences(editor: Editor, dataframeShape: TLShape) {
    // Get the first grid shape in the data frame
    const dataGridShapeId = DataframeBaseUtil.getFirstDataGridShape(editor, dataframeShape);

    if (dataGridShapeId) {
      const dataGridShape = editor.getShape(dataGridShapeId) as IDataGridShape;
      return dataGridShape?.props.preferences;
    }
    return undefined;
  }

  // *********************************************
  // Facets methods
  // *********************************************/
  public updateSelectedFacetValues(editor: Editor, shape: T, selectedFacetValues: TFacets) {
    editor.updateShapes([
      {
        id: shape.id,
        type: shape.type,
        props: {
          selectedFacetValues,
        },
      },
    ]);
  }

  public updateBoundsFacetValues(editor: Editor, shape: T, boundsFacetValues: TFacets) {
    editor.updateShapes([
      {
        id: shape.id,
        type: shape.type,
        props: {
          boundsFacetValues,
        },
      },
    ]);
  }

  public updateToolbarFields(editor: Editor, shape: T, toolbarFields: Array<string>) {
    editor.updateShapes([
      {
        id: shape.id,
        type: shape.type,
        props: {
          toolbar: {
            availableFields: toolbarFields,
          },
        },
      },
    ]);
  }

  // *********************************************
  // Protected methods
  // *********************************************/
  /**
   * This can be specific to the analysis.
   *
   * @param shape Shape details
   * @param facets Facets to use
   */
  protected onClearSelection(shape: TLShape, facets: Record<string, any>) {}
}
