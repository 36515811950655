import { gql } from "@apollo/client";

export const UPDATE_ANALYST_QUESTION = gql`
  mutation UpdateAnalystQuestion($input: UpdateAnalystQuestionInput!) {
    updateAnalystQuestion(input: $input) {
      id
      eventDate
      firm
      firmType
      question
      segment
      topics
    }
  }
`;
