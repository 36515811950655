import {
  NameAscSortOptionName,
  NameDescSortOptionName,
  SortKeys,
  UpdatedAtAscSortOptionName,
  UpdatedAtDescSortOptionName,
  ViewType,
  getSortOption,
} from "@bigpi/cookbook";
import { Box, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

import { CommandContext } from "CommandContext";
import { ContentActionBar } from "Components/ContentActionBar/ContentActionBar";
import { ContentFilterPanel } from "Components/ContentFilterPanel/ContentFilterPanel";
import { useUpsertDocumentsPageUserPreferencesMutation, useDocumentsPageUserPreferencesQuery } from "GraphQL/Generated/Apollo";
import { DOCUMENTS_PAGE_USER_PREFERENCES_KEY } from "GraphQL/UserPreference";
import { DocumentsGridSize } from "Pages/Documents/DocumentsGrid";
import { DocumentsView } from "Pages/Documents/DocumentsView";
import { DocumentActionBarButtons } from "./DocumentActionBarButtons";

// *********************************************
// Types/Interfaces/Constants
// *********************************************/
interface IDocumentsPageUserPreferences {
  gridSize: DocumentsGridSize;
  pageSize: number;
  sortOption: SortKeys;
  viewType: ViewType;
}

const DEFAULT_PAGE_SIZE = 50;

// *********************************************
// Component
// *********************************************/
export function DocumentsPage() {
  const { t } = useTranslation();

  // State
  const [preferences, setPreferences] = useState<IDocumentsPageUserPreferences>({
    gridSize: "medium",
    pageSize: DEFAULT_PAGE_SIZE,
    viewType: ViewType.List,
    sortOption: UpdatedAtDescSortOptionName,
  });
  const [searchValue, setSearchValue] = useState("");
  const [selectedIds, setSelectedIds] = useState<Array<string>>([]);

  // Files query
  const { data: persistedPreferences } = useDocumentsPageUserPreferencesQuery({
    variables: {
      key: DOCUMENTS_PAGE_USER_PREFERENCES_KEY,
    },
  });

  useEffect(() => {
    // Set current application session context
    CommandContext.replaceSessionContext([]);
  }, []);

  // User preferences update mutation
  const [upsertUserPreference] = useUpsertDocumentsPageUserPreferencesMutation({
    // Update cache directly since the server will not return the fully cascaded data, just the user portion
    // This ensures any default and org-level preferences are not lost
    update: (cache, data) => {
      cache.modify({
        fields: {
          userPreference(existing = {}) {
            return {
              ...existing,
              data: {
                ...existing.data,
                ...data.data?.upsertUserPreference.data,
              },
            };
          },
        },
      });
    },
  });

  // Hooks
  const updatePreferences = useCallback(
    (preferences: Partial<IDocumentsPageUserPreferences>) => {
      setPreferences((current) => {
        return { ...current, ...preferences };
      });
      setSelectedIds([]);
      upsertUserPreference({
        variables: {
          input: {
            key: DOCUMENTS_PAGE_USER_PREFERENCES_KEY,
            data: preferences,
          },
        },
      });
    },
    [upsertUserPreference],
  );

  useEffect(() => {
    // The preferences have changed, update the state
    setPreferences({
      gridSize: persistedPreferences?.userPreference?.data?.gridSize || "medium",
      viewType: persistedPreferences?.userPreference?.data?.viewType || ViewType.List,
      sortOption: persistedPreferences?.userPreference?.data?.sortOption || UpdatedAtDescSortOptionName,
      pageSize: persistedPreferences?.userPreference?.data?.pageSize || DEFAULT_PAGE_SIZE,
    });
  }, [persistedPreferences]);

  const onSelectionChange = useCallback((id: string) => {
    setSelectedIds((prev) => {
      if (prev.includes(id)) {
        return prev.filter((i) => i !== id);
      } else {
        return [...prev, id];
      }
    });
  }, []);

  const onSearchValueChange = useCallback((value: string) => {
    setSearchValue(value);
    setSelectedIds([]);
  }, []);

  return (
    <>
      <Helmet>
        <title>{t("Pages.Documents.Title")}</title>
      </Helmet>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          m: 3,
          mb: 0,
          alignSelf: "center",
          width: "90%",
          maxWidth: "1920px",
          minWidth: "400px",
        }}
      >
        <Typography variant="h5">{t("Pages.Documents.HeadingLabel")}</Typography>
      </Box>

      <Box
        sx={{
          alignSelf: "center",
          border: `1px solid #f1f1f1`,
          borderRadius: "16px",
          display: "flex",
          flexDirection: "column",
          m: 3,
          maxWidth: "1920px",
          mb: 10,
          minWidth: "400px",
          p: 3,
          width: "90%",
        }}
      >
        <ContentFilterPanel
          isViewTypeEnabled={true}
          onPaginationValueChange={(pageSize) => updatePreferences({ pageSize })}
          onSearchValueChange={onSearchValueChange}
          onSortOptionChange={(sortOption) => updatePreferences({ sortOption })}
          onTagsChange={() => {}}
          onViewTypeChange={(viewType) => updatePreferences({ viewType })}
          pageSize={preferences.pageSize}
          searchLabel={t("Pages.Documents.FindLabel")}
          selectedSortOption={preferences.sortOption}
          selectedTags={[]}
          sortOptions={[UpdatedAtDescSortOptionName, UpdatedAtAscSortOptionName, NameAscSortOptionName, NameDescSortOptionName]}
          tags={[]}
          viewType={preferences.viewType}
        />

        <Box sx={{ height: "35px", position: "sticky", top: "0px", zIndex: 2 }}>
          {selectedIds.length > 0 && (
            <ContentActionBar count={selectedIds.length} onClose={() => setSelectedIds([])}>
              <DocumentActionBarButtons selectedIds={selectedIds} onClose={() => setSelectedIds([])} />
            </ContentActionBar>
          )}
        </Box>

        <DocumentsView
          gridSize={preferences.gridSize}
          onGridSizeChange={(gridSize) => updatePreferences({ gridSize })}
          onSelectionChange={onSelectionChange}
          pageSize={preferences.pageSize}
          searchValue={searchValue}
          selectedIds={selectedIds}
          selectedSortOption={getSortOption(preferences.sortOption)}
          viewType={preferences.viewType}
        />
      </Box>
    </>
  );
}
