import { gql } from "@apollo/client";

export const TOPIC_DISCUSSION_EXAMPLE_QUERY = gql`
  query TopicDiscussionExample($id: ID!) {
    topicDiscussionExample(id: $id) {
      id
      topic
      question
      detail
    }
  }
`;

export const TOPIC_DISCUSSION_EXAMPLES_QUERY = gql`
  query TopicDiscussionExamples($facets: TopicDiscussionExampleFacetsInput!) {
    topicDiscussionExamples(facets: $facets) {
      id
      topic
      question
      detail
      turn
      event
      eventType
      ticker
      category
      eventDate
      speakers {
        fullName
        role
      }
      segmentId
      section
      rating
      score
      explanation
      summary
      firm
      reportTitle
      type
    }
  }
`;

export const TOPIC_DISCUSSION_SUMMARY_EXAMPLE_AGGREGATE_QUERY = gql`
  query TopicDiscussionExampleAggregate($facets: TopicDiscussionExampleFacetsInput!) {
    bubbleChartScale: topicDiscussionExampleQuestionCountsAggregate(facets: $facets) {
      min
      max
    }
    barChartScale: topicDiscussionExampleEventAggregate(facets: $facets) {
      min
      max
    }
  }
`;
