import { RawCommands } from "@tiptap/core";
import { TextSelection } from "@tiptap/pm/state";

import { ITextContentDetails } from "../../Utils/CommandUtil.js";

export const setLinkWithText: RawCommands["setLinkWithText"] =
  (textContentDetails: ITextContentDetails | null, link: string) =>
  ({ chain }) => {
    if (link.length === 0) {
      return false;
    }

    return chain()
      .command(({ tr, editor }) => {
        const { from, empty } = tr.selection;

        let text = textContentDetails?.textContent || "";
        if (text === "") {
          text = link;
        }

        if (empty) {
          tr.insertText(text, from);
          tr.setSelection(TextSelection.create(tr.doc, from, from + text.length));
        } else if (textContentDetails && textContentDetails.startPos && textContentDetails.endPos) {
          tr.replaceWith(textContentDetails.startPos, textContentDetails.endPos, editor.schema.text(text));
          tr.setSelection(TextSelection.create(tr.doc, from, from + text.length));
        }

        return true;
      })
      .extendMarkRange("link")
      .setLink({ href: link })
      .run();
  };
