import { gql } from "@apollo/client";

export const USER_GROUP_QUERY = gql`
  query UserGroup($id: ID!) {
    userGroup(id: $id) {
      id
      name
      isSystem
    }
  }
`;

export const USER_GROUPS_QUERY = gql`
  query UserGroups($offset: Int, $limit: Int, $orderBy: UserGroupOrderBy) {
    userGroups(offset: $offset, limit: $limit, orderBy: $orderBy) {
      id
      name
      isSystem
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
    userGroupAggregate {
      count
    }
  }
`;
