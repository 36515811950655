import type { Editor } from "@tiptap/react";
import { Colorize, ColorizeOutlined, FormatColorResetOutlined } from "@mui/icons-material";
import { Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import type { IEditorConfig } from "../../Editor/index.js";
import { capitalizeFirstLetter, getEditorToolbarButtonTooltip } from "../../Utils/ToolbarItemUtils.js";
import { ToolbarItemBase } from "../../Toolbars/ToolbarItemBase.js";
import { ToolbarButton } from "../../Toolbars/ToolbarButton.js";
import { DefaultHighlightColors, HighlightColors } from "./TextHighlightExtension.js";

const ITEM_NAME = "highlight";

/**
 * A toolbar button for highlighting text.
 */
export class HighlightButtonItem extends ToolbarItemBase {
  // *********************************************
  // Public properties
  // *********************************************/
  /**
   * @inheritdoc
   */
  readonly name = ITEM_NAME;

  // *********************************************
  // Public methods
  // *********************************************/
  /**
   * @inheritdoc
   */
  create(editor: Editor, config: IEditorConfig) {
    const { toolbarOptions } = config;
    return (
      <HighlightButton
        key={ITEM_NAME}
        setHighlightColor={(color: HighlightColors) => editor.chain().focus().setHighlightColor(color).run()}
        unsetHighlightColor={() => editor.chain().focus().unsetHighlightColor().run()}
        toolbarOptions={toolbarOptions}
      />
    );
  }
}

/**
 * Props for HighlightButton.
 */
export interface HighlightButtonProps {
  setHighlightColor: (color: HighlightColors) => void;
  unsetHighlightColor: () => void;
  toolbarOptions: IEditorConfig["toolbarOptions"];
}

/**
 * A toolbar button for the `highlight` command.
 */
export const HighlightButton = (props: HighlightButtonProps) => {
  const { setHighlightColor, unsetHighlightColor, toolbarOptions } = props;
  const tooltipPlacement = toolbarOptions?.tooltipPlacement;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { t } = useTranslation();

  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <ToolbarButton
        Icon={ColorizeOutlined}
        tooltip={getEditorToolbarButtonTooltip(t, ITEM_NAME)}
        onClick={openMenu}
        tooltipPlacement={tooltipPlacement}
      />

      <Menu
        anchorEl={anchorEl}
        id="highlight-menu"
        open={!!anchorEl}
        onClose={closeMenu}
        onClick={closeMenu}
        PaperProps={{
          elevation: 0,
          sx: {
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
          },
        }}
        MenuListProps={{
          style: { display: "inline-flex", padding: 0 },
        }}
      >
        {DefaultHighlightColors.map((color) => {
          return (
            <MenuItem
              key={`highlight-${color}-menu-button`}
              onClick={() => {
                setHighlightColor(color);
              }}
              sx={{ padding: "6px" }}
              disableRipple
            >
              <ToolbarButton
                Icon={Colorize}
                className={`editor toolbar highlight-${color}`}
                style={{ border: "1px solid rgba(0,0,0,0.32)" }}
                tooltip={t(`Editor.${capitalizeFirstLetter(ITEM_NAME)}.ColorMenuTooltip.${capitalizeFirstLetter(color)}`)}
                tooltipPlacement={tooltipPlacement}
              />
            </MenuItem>
          );
        })}

        <MenuItem key={`highlight-reset-menu-button`} onClick={unsetHighlightColor} sx={{ padding: "6px" }} disableRipple>
          <ToolbarButton
            Icon={FormatColorResetOutlined}
            tooltip={t(`Editor.${capitalizeFirstLetter(ITEM_NAME)}.ClearTooltip`)}
            tooltipPlacement={tooltipPlacement}
          />
        </MenuItem>
      </Menu>
    </>
  );
};
