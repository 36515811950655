import { Button, Tooltip } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/system";
import { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";

import AppSidebarIcon from "Assets/Icons/app-sidebar-icon.svg";
import { ResizePanel } from "Components/Resizer/ResizePanel";
import { useUpsertWorkspacesPageUserPreferencesMutation, useWorkspacesPageUserPreferencesQuery } from "GraphQL/Generated/Apollo";
import { SidebarHeader } from "./RightSidebarHeader";

/***********************************
 * Types/Interface
 ***********************************/
interface AppSidebarProps {
  children: React.ReactNode;
}

export const APP_SIDEBAR_ICON_HEIGHT = 25;
export const APP_SIDEBAR_ICON_WIDTH = 25;
const DEFAULT_WIDTH = 490;
const MIN_WIDTH = 200;
const MAX_WIDTH = 720;

/***********************************
 * Component
 ***********************************/
export function AppRightSidebar(props: AppSidebarProps) {
  const { children } = props;
  const location = useLocation();
  const theme = useTheme();
  const { t } = useTranslation();

  const { data: sidebarPreferences } = useWorkspacesPageUserPreferencesQuery({
    variables: {
      key: location.pathname,
    },
  });
  const [upsertUserPreference] = useUpsertWorkspacesPageUserPreferencesMutation({
    // Update cache directly since the server will not return the fully cascaded data, just the user portion
    // This ensures any default and org-level preferences are not lost
    update: (cache, data) => {
      cache.modify({
        fields: {
          userPreference(existing = {}) {
            return {
              ...existing,
              data: {
                ...existing.data,
                ...data.data?.upsertUserPreference.data,
              },
            };
          },
        },
      });
    },
  });

  // Persists the user preferences. NOTE: This upserts with a patch, so we don't need to send all the preferences
  const updatePreferences = useCallback(
    (open: boolean, width: number) => {
      upsertUserPreference({
        variables: {
          input: {
            key: location.pathname,
            data: {
              open,
              sidebarWidth: width,
            },
          },
        },
      });
    },
    [upsertUserPreference, location.key],
  );

  // State
  const [childrenWidth, setChildrenWidth] = useState<number>(
    sidebarPreferences?.userPreference?.data?.sidebarWidth ?? DEFAULT_WIDTH,
  );
  const [open, setOpen] = useState(sidebarPreferences?.userPreference?.data?.open ?? false);

  const tooltipTitle = open ? t("Components.AppSidebar.Collapse") : t("Components.AppSidebar.Expand");

  const debouncedSetChildrenWidth = useDebouncedCallback((width: number) => {
    setChildrenWidth(width);
    updatePreferences(open, width);
  }, 50);

  useEffect(() => {
    setOpen(sidebarPreferences?.userPreference?.data?.open ?? false);
    setChildrenWidth(sidebarPreferences?.userPreference?.data?.sidebarWidth ?? DEFAULT_WIDTH);
  }, [sidebarPreferences]);

  return (
    <Box>
      {open && (
        <ResizePanel
          defaultWidth={DEFAULT_WIDTH}
          minWidth={MIN_WIDTH}
          maxWidth={MAX_WIDTH}
          width={childrenWidth}
          onWidthChange={debouncedSetChildrenWidth}
        >
          <Box
            sx={{ borderLeft: `1px solid ${theme.palette.divider}`, display: "flex", flexDirection: "column", width: "100%" }}
            component="aside"
          >
            <SidebarHeader />
            {children}
          </Box>
        </ResizePanel>
      )}
      <Tooltip title={tooltipTitle} placement="left-start">
        <Button
          onClick={onToggleSidebar}
          sx={{ position: "fixed", top: "0.1%", right: "0.1%", zIndex: "var(--layer-sidebar-toggle-icon)" }}
        >
          <img
            src={AppSidebarIcon}
            height={APP_SIDEBAR_ICON_HEIGHT}
            width={APP_SIDEBAR_ICON_WIDTH}
            alt={tooltipTitle}
            style={{ filter: "invert(0.4)" }}
          />
        </Button>
      </Tooltip>
    </Box>
  );

  function onToggleSidebar() {
    setOpen(!open);
    updatePreferences(!open, DEFAULT_WIDTH);
  }
}
