// Generated by ts-to-zod
import { z } from "zod";

export const dataGridSortModelZodSchema = z.object({
  field: z.string().optional().describe("The field to sort by."),
  sort: z.string().optional().describe('The sort direction, either "asc" or "desc".'),
});

export const dataGridPinnedColumnsZodSchema = z.object({
  left: z.array(z.string()).optional().describe("The columns to pin to the left side of the data grid."),
  right: z.array(z.string()).optional().describe("The columns to pin to the right side of the data grid."),
});

export const dataGridFilterItemZodSchema = z.object({
  field: z.string().optional().describe("The field to filter by."),
  fromInput: z.string().optional(),
  id: z.number().optional(),
  operator: z.string().optional(),
  value: z.string().optional(),
});

export const dataGridFilterModelZodSchema = z.object({
  items: z.array(dataGridFilterItemZodSchema).optional().describe("The items to filter data in the grid."),
});

export const dataGridPreferencesZodSchema = z.object({
  sortModel: z.array(dataGridSortModelZodSchema).optional().describe("The sort model for the data grid."),
  pinnedColumns: dataGridPinnedColumnsZodSchema.optional().describe("The pinned columns for the data grid."),
  filterModel: dataGridFilterModelZodSchema.optional().describe("The filters to apply to the data grid."),
  rowGroupingModel: z.array(z.string()).optional().describe("The rows to group in the data grid."),
  rowsPerPage: z.number().optional().describe("The number of rows to display per page when pagination is enabled."),
});

const dataGridColumnAlignmentZodSchema = z.any();

const dataGridColumnFormatZodSchema = z.any();

const dataGridDescendentCountFormatZodSchema = z.any();

const dataGridDetailsPanelFormatZodSchema = z.any();

const shapeDatastoreTypeZodSchema = z.any();

export const dataGridColumnDefZodSchema = z.object({
  align: dataGridColumnAlignmentZodSchema.optional(),
  field: z.string(),
  flex: z.number().optional(),
  format: dataGridColumnFormatZodSchema,
  headerAlign: dataGridColumnAlignmentZodSchema.optional(),
  headerName: z.string(),
  isColumnMenuEnabled: z.boolean().optional(),
  isColumnPinEnabled: z.boolean().optional(),
  isEditEnabled: z.boolean().optional(),
  isExportEnabled: z.boolean().optional(),
  isGroupEnabled: z.boolean().optional(),
  isHideEnabled: z.boolean().optional(),
  isReorderEnabled: z.boolean().optional(),
  isResizeEnabled: z.boolean().optional(),
  isSortEnabled: z.boolean().optional(),
  isVisible: z.boolean().optional(),
  maxWidth: z.number().optional(),
  minWidth: z.number().optional(),
  showSortIcons: z.boolean().optional(),
  type: z.string().optional(),
  width: z.number().optional(),
});

export const dataGridConfigZodSchema = z.object({
  addToDocument: z.boolean().describe('A boolean value indicating whether the "Add to Document" option should be displayed.'),
  autoResize: z.boolean().describe("A boolean value indicating whether the data grid should automatically resize."),
  checkboxSelection: z
    .boolean()
    .describe("A boolean value indicating whether the data grid should allow user selection of rows."),
  columns: z.array(dataGridColumnDefZodSchema).describe("The columns in the data grid."),
  descendentCountFormat: dataGridDescendentCountFormatZodSchema.optional().describe("The display format for sub-row counts."),
  detailsPanelFormat: dataGridDetailsPanelFormatZodSchema.optional().describe("The display format for the details panel."),
  documentItemTemplate: z.string().describe("The template to use when adding rows to a document."),
  fontSize: z.number().describe("The font size for the data grid."),
  isExportCsvEnabled: z.boolean().optional().describe("A boolean value indicating whether exporting to CSV is enabled."),
  isExportExcelEnabled: z.boolean().optional().describe("A boolean value indicating whether exporting to Excel is enabled."),
  isManageColumnsEnabled: z
    .boolean()
    .optional()
    .describe("A boolean value indicating whether the column management options are available."),
  isPaginationEnabled: z.boolean().optional().describe("A boolean value indicating whether pagination is enabled."),
  maxHeight: z.string().optional().describe("The maximum height of the data grid."),
  minHeight: z.string().optional().describe("The minimum height of the data grid."),
});

export const dataGridShapePropsZodSchema = z.object({
  h: z.number().describe("The width of the HTML document."),
  w: z.number().describe("The height of the HTML document."),
  config: dataGridConfigZodSchema.describe("The configuration for the data grid."),
  datastoreId: z.string().optional().describe("The ID in the datastore for the data grid's data."),
  datastoreType: shapeDatastoreTypeZodSchema.describe("The type of datastore for the data grid's data."),
  preferences: dataGridPreferencesZodSchema.optional().describe("The preferences for the data grid."),
  selectedIds: z.array(z.string()).describe("The selected IDs of rows in the data grid."),
});
