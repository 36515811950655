import { useCallback, useState } from "react";
import { FetchResult } from "@apollo/client";

import { IFileWithId, FileStatus } from "../FilesList";
import { UploadFilesMutation, UploadWorkspaceFilesMutation } from "GraphQL/Generated/Apollo";

/**
 * Hook to upload a batch of files. Also tracks uploading, cancelled files & ability to abort the upload.
 *
 * @returns Object with abortUpload, uploadingFiles, and uploadFilesBatch
 */
export function useUploadFilesBatch(
  onUpdateFilesStatus: (filesToUpdate: Record<string, FileStatus>) => void,
  uploadFiles: (variables: any) => Promise<FetchResult<UploadFilesMutation> | FetchResult<UploadWorkspaceFilesMutation>>,
  refetchQueries: Array<string>,
) {
  const [abortControllers, setAbortControllers] = useState<Record<string, AbortController>>({});

  // Callback to upload a batch of files
  const uploadBatch = useCallback(
    async (batch: Array<IFileWithId>, extraVariables?: { workspaceId?: string; bundleId?: string }) => {
      const controllers: Record<string, AbortController> = {};
      // Add files to uploadingFiles list
      onUpdateFilesStatus(batch.reduce((acc, file) => ({ ...acc, [file.id]: FileStatus.Uploading }), {}));

      const uploadPromises = batch.map((file) => {
        // AbortController to cancel the upload
        const controller = new AbortController();
        controllers[file.id] = controller;
        // Mutation to upload the file
        return uploadFiles({
          variables: {
            input: {
              files: [
                {
                  id: file.id,
                  file: file.file,
                  fileSize: file.file.size,
                },
              ],
              ...extraVariables,
            },
          },
          context: {
            fetchOptions: {
              signal: controller.signal,
            },
          },
          refetchQueries,
        })
          .then((response) => {
            onUpdateFilesStatus({ [file.id]: FileStatus.Success });

            // Handle failed files
            const failedFiles: Record<string, string> = {};
            let uploadFiles;
            if (response.data) {
              if ("uploadFiles" in response.data) {
                uploadFiles = response.data.uploadFiles;
              } else if ("uploadWorkspaceFiles" in response.data) {
                uploadFiles = response.data.uploadWorkspaceFiles;
              }
            }

            uploadFiles?.failed.forEach((failedFile) => {
              failedFiles[failedFile.id] = failedFile.reason;
            });

            if (Object.keys(failedFiles).length > 0) {
              onUpdateFilesStatus(
                Object.keys(failedFiles).reduce((acc, fileId) => ({ ...acc, [fileId]: FileStatus.Failed }), {}),
              );
            }
          })
          .catch((error) => {
            if (error.name !== "AbortError" && controller.signal.aborted === false) {
              console.error("Error uploading file:", file.id, error);
            }

            onUpdateFilesStatus({ [file.id]: FileStatus.Cancelled });
          })
          .finally(() => {
            // TODO: Should we handle anything here?
          });
      });

      setAbortControllers((prev) => ({ ...prev, ...controllers }));
      // Wait for all files to be uploaded
      await Promise.all(uploadPromises);
    },
    [],
  );

  // Aborts current running upload (cancels the netowrk request)
  const abortUpload = useCallback(
    (fileId: string) => {
      const controller = abortControllers[fileId];
      controller?.abort();
    },
    [abortControllers],
  );

  return {
    abortUpload,
    uploadFilesBatch: uploadBatch,
  };
}
