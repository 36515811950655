import { makeVar } from "@apollo/client";
import { ICommandContext } from "@bigpi/cookbook";

export class CommandContext {
  // *********************************************
  // Private static properties
  // *********************************************/
  private static _commandContext: ICommandContext = {
    application: {},
    organization: {},
    user: {},
    session: [],
    selection: {},
  };

  // *********************************************
  // Public static properties
  // *********************************************/
  public static getCommandContext = makeVar(CommandContext._commandContext);

  // *********************************************
  // Public static methods
  // *********************************************/
  /**
   * Updates the current command context with the provided patch.
   * It is the responsibility of the caller to ensure that the patch is valid and manages any required deep copy.
   *
   * @param patch The patch to apply to the current command context.
   * @returns The updated, non-live command context.
   */
  public static patchCommandContext(patch: Partial<ICommandContext>): ICommandContext {
    const commandContext = CommandContext.getCommandContext();

    return CommandContext.getCommandContext({
      ...commandContext,
      ...patch,
    });
  }

  /**
   * Replaces the current command session context with the provided one.
   *
   * @param session The new session context.
   */
  public static replaceSessionContext(session: ICommandContext["session"]) {
    const commandContext = CommandContext.getCommandContext();
    CommandContext.getCommandContext({
      ...commandContext,
      session: Array.isArray(session) ? [...session] : [],
    });
  }
}
