import * as React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { EditInWorkspaceDialog } from "Components/EditInWorkspaceDialog/EditInWorkspaceDialog";
import { useEditFilesInWorkspaceMutation } from "GraphQL/Generated/Apollo";

export interface IEditFilesInWorkspaceDialog {
  fileIds: Array<string>;
  onClose: () => void;
  open: boolean;
}

export function EditFilesInWorkspaceDialog(props: IEditFilesInWorkspaceDialog) {
  const { fileIds, open } = props;

  const { t } = useTranslation();

  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = React.useState("");

  const [editFilesInWorkspace] = useEditFilesInWorkspaceMutation();

  function onClose() {
    setErrorMessage("");
    props.onClose();
  }

  async function onCreate(name: string) {
    try {
      const result = await editFilesInWorkspace({
        variables: {
          input: {
            fileIds,
            workspaceName: name,
            workspaceBoardName: t("Pages.WorkspaceBoard.DefaultName"),
          },
        },
        refetchQueries: ["Workspaces"],
      });

      // Open the default board of the new workspace
      const newWorkspaceId = result.data?.editFilesInWorkspace?.id;
      if (newWorkspaceId) {
        navigate(`/workspaces/${newWorkspaceId}`, { replace: true });
      }
    } catch (error) {
      console.error(error);
      setErrorMessage(t("Components.EditFilesInWorkspaceDialog.Error"));
    }
  }

  return <EditInWorkspaceDialog errorMessage={errorMessage} open={open} onClose={onClose} onCreate={onCreate} />;
}
