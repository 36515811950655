import {
  getHeadlineShapeDefaultProps,
  headlineShapeProps,
  IHeadlineShape,
  IHeadline,
  headlineShapeMigrations,
} from "@bigpi/tl-schema";
import { KeyboardArrowDownOutlined, KeyboardArrowUpOutlined } from "@mui/icons-material";
import { Box, CircularProgress, IconButton, Tooltip, Typography } from "@mui/material";
import { GridRowId, GridRowSelectionModel } from "@mui/x-data-grid-premium";
import { HTMLContainer, TLShapeId, useEditor, useValue } from "@tldraw/tldraw";
import * as React from "react";
import { useTranslation } from "react-i18next";

import { IHtmlDocumentShapeExternalData, useBoardDatastore } from "BoardComponents/BoardDatastore";
import { BoxBaseUtil } from "BoardComponents/BaseShapes/BoxBaseUtil";
import { useIsInteracting } from "BoardComponents/Tools";
import { useShapeEvents } from "BoardComponents/useShapeEvents";
import { HeadlineDataGrid } from "./HeadlineDataGrid";
import { IFlatHeadline } from "./IFlatHeadline";
import "./HeadlineShape.css";

// *********************************************
// Shape Util
// *********************************************/
export class HeadlineUtil extends BoxBaseUtil<IHeadlineShape> {
  // *********************************************
  // Static fields
  // *********************************************/
  static type = "headline";

  static props = headlineShapeProps;

  static migrations = headlineShapeMigrations;

  // *********************************************
  // Protected fields
  // *********************************************/
  /**
   * The minimum height of the shape.
   */
  protected minHeight = 192;

  /**
   * The minimum width of the shape.
   */
  protected minWidth = 192;

  /**
   * The maximum height of the shape.
   */
  protected maxHeight = Infinity;

  /**
   * The maximum width of the shape.
   */
  protected maxWidth = Infinity;

  // *********************************************
  // Override methods
  // *********************************************/
  /**
   * @inheritdoc
   */
  override isAspectRatioLocked = (shape: IHeadlineShape) => false;

  /**
   * @inheritdoc
   */
  override canScroll = (shape: IHeadlineShape) => {
    return shape.props.canScroll;
  };

  /**
   * @inheritdoc
   */
  override canResize = (shape: IHeadlineShape) => true;

  /**
   * @inheritdoc
   */
  override canBind = (shape: IHeadlineShape) => true;

  /**
   * @inheritdoc
   */
  override canEdit = (shape: IHeadlineShape) => true;

  /**
   * @inheritdoc
   */
  override getDefaultProps(): IHeadlineShape["props"] {
    return getHeadlineShapeDefaultProps();
  }

  // *********************************************
  // Private methods, event handlers
  // *********************************************/
  /**
   * @inheritdoc
   */
  component(shape: IHeadlineShape) {
    const { id, props, type } = shape;

    const tldrawEditor = useEditor();
    const { t } = useTranslation();
    const [headlines, setHeadlines] = React.useState<Array<IFlatHeadline>>([]);
    const [checkedHeadlineIds, setCheckedHeadlineIds] = React.useState<Array<string>>([]);
    const [activeBookmarkIds, setActiveBookmarkIds] = React.useState<Array<string>>([]);
    const [currentBookmarkIndex, setCurrentBookmarkIndex] = React.useState<number>(-1);
    const [orderedBookmarkDetails, setOrderedBookmarkDetails] = React.useState<Array<{ bookmarkId: string; index: number }>>([]);
    const [headlineDetailPanelExpandedRowIds, setHeadlineDetailPanelExpandedRowIds] = React.useState<GridRowId[]>([]);

    const contentRef = React.useRef<HTMLDivElement | null>(null);

    const isEditing = useValue("isEditing", () => tldrawEditor.getCurrentPageState().editingShapeId === id, [tldrawEditor, id]);
    const isInteracting = useIsInteracting(id);
    const isSelected = useValue("isSelected", () => tldrawEditor.getCurrentPageState().selectedShapeIds.includes(id), [
      tldrawEditor,
      id,
    ]);

    React.useEffect(() => {
      let canScroll = true;

      // Check if our content fits. If it does, then return false so canvas moves for scroll events
      if (contentRef.current) {
        const { clientHeight, scrollHeight } = contentRef.current;

        // From MDN: "scrollTop is a non-rounded number, while scrollHeight and clientHeight are rounded" so need an extra pixel
        if (clientHeight >= scrollHeight) {
          canScroll = false;
        }
      }

      // Only update if the value has changed
      if (props.canScroll !== canScroll) {
        tldrawEditor.updateShapes([{ id, type, props: { canScroll } }]);
      }
    }, [
      tldrawEditor,
      id,
      type,
      // We should recalculate "canScroll" when the content height changes
      contentRef.current?.scrollHeight,
      contentRef.current?.clientHeight,
    ]);

    // Datastore to get the editor instances of the target shapes
    const datastore = useBoardDatastore();
    const targetDocumentShapeProps =
      props.targetDocumentShapeId.length > 0
        ? (tldrawEditor.getShape(props.targetDocumentShapeId as TLShapeId)?.props as
            | {
                html: string;
              }
            | undefined)
        : null;
    const targetDocumentShapeContentHtml = targetDocumentShapeProps ? targetDocumentShapeProps.html : null;

    const getMatchingBookmarkIdsFromDocument = React.useCallback(
      (bookmarkIds: Array<string>) => {
        if (targetDocumentShapeContentHtml && bookmarkIds.length > 0) {
          return targetDocumentShapeContentHtml.match(new RegExp(`(${bookmarkIds.join("|")})`, "g")) || ([] as Array<string>);
        }

        return [] as Array<string>;
      },
      [targetDocumentShapeContentHtml],
    );

    React.useEffect(() => {
      const matchingBookmarkIds = getMatchingBookmarkIdsFromDocument(activeBookmarkIds);
      const newOrderedBookmarkDetails: Array<{ bookmarkId: string; index: number }> = [];

      matchingBookmarkIds.forEach((bookmarkId) => {
        let lastMatchingBookmarkId = null;
        for (let i = newOrderedBookmarkDetails.length - 1; i >= 0; i--) {
          if (newOrderedBookmarkDetails[i].bookmarkId === bookmarkId) {
            lastMatchingBookmarkId = newOrderedBookmarkDetails[i];
            break;
          }
        }

        newOrderedBookmarkDetails.push({
          bookmarkId,
          index: lastMatchingBookmarkId ? lastMatchingBookmarkId.index + 1 : 0,
        });
      });

      setOrderedBookmarkDetails(newOrderedBookmarkDetails);
      if (newOrderedBookmarkDetails.length === 0) {
        setCurrentBookmarkIndex(-1);
      } else {
        setCurrentBookmarkIndex(0);
      }
    }, [activeBookmarkIds, targetDocumentShapeContentHtml]);

    React.useEffect(() => {
      const targetShapeId = props.targetDocumentShapeId;
      const storeData = datastore.state.get()[targetShapeId]?.get() as IHtmlDocumentShapeExternalData;
      if (storeData?.activeBookmarkIds) {
        storeData?.activeBookmarkIds.set(activeBookmarkIds);
      }
    }, [activeBookmarkIds, datastore, props.targetDocumentShapeId]);

    React.useEffect(() => {
      const newActiveBookmarkIds = new Set<string>();
      checkedHeadlineIds.forEach((checkedHeadlineId) => {
        const headline = headlines.find((h) => h.id === checkedHeadlineId);
        if (headline && headline.bookmarkIds.length > 0)
          headline.bookmarkIds.forEach((bookmarkId) => {
            newActiveBookmarkIds.add(bookmarkId);
          });
      });

      setActiveBookmarkIds(Array.from(newActiveBookmarkIds));
    }, [checkedHeadlineIds, headlines]);

    const getTreeDataForHeadlines = React.useCallback((headlines: Array<IHeadline>, path: Array<string> = []) => {
      const flatHeadlines: Array<IFlatHeadline> = [];
      headlines.forEach((headline) => {
        const newPath = [...path, headline.headline];
        flatHeadlines.push({ ...headline, path: newPath });
        if (headline.results && headline.results.length > 1) {
          flatHeadlines.push(...getTreeDataForHeadlines(headline.results, newPath));
        } else if (headline.results && headline.results.length === 1) {
          // Check if the headline and result title matches, if not, then add the child
          if (headline.headline !== headline.results[0].headline) {
            flatHeadlines.push(...getTreeDataForHeadlines(headline.results, newPath));
          }
        }
      });
      return flatHeadlines;
    }, []);

    React.useEffect(() => {
      setHeadlines(getTreeDataForHeadlines(props.headlines));
      setCheckedHeadlineIds([]);
    }, [props.headlines, getTreeDataForHeadlines]);

    const scrollToBookmark = React.useCallback(
      (bookmarkId: string, index?: number) => {
        const targetShapeId = props.targetDocumentShapeId;
        const storeData = datastore.state.get()[targetShapeId]?.get() as IHtmlDocumentShapeExternalData;
        const scrollBookmarkIntoView = storeData?.scrollBookmarkIntoView.get();

        scrollBookmarkIntoView?.(bookmarkId, index);
      },
      [props.targetDocumentShapeId, datastore],
    );

    const onHeadlineRowSelectionModelChange = React.useCallback(
      (data: GridRowSelectionModel) => {
        const addedHeadlineIds = data.filter((id) => !checkedHeadlineIds.includes(id as string));
        if (targetDocumentShapeContentHtml && addedHeadlineIds.length > 0) {
          const newBookmarkIds = new Set<string>();
          addedHeadlineIds.forEach((addedHeadlineId) => {
            const headline = headlines.find((h) => h.id === addedHeadlineId);
            if (headline && headline.bookmarkIds.length > 0)
              headline.bookmarkIds.forEach((bookmarkId) => {
                newBookmarkIds.add(bookmarkId);
              });
          });

          // Scroll to the first matching bookmark
          const matchingBookmarkIds = getMatchingBookmarkIdsFromDocument(Array.from(newBookmarkIds));
          if (matchingBookmarkIds.length > 0) {
            scrollToBookmark(matchingBookmarkIds[0]);
          }
        }

        // Update the checked headline IDs
        setCheckedHeadlineIds(data as Array<string>);
      },
      [checkedHeadlineIds, headlines, scrollToBookmark],
    );

    const onHeadlineRowExpansionChange = React.useCallback(
      (rowId: GridRowId, expanded: boolean) => {
        const newIds = expanded
          ? [...headlineDetailPanelExpandedRowIds, rowId]
          : headlineDetailPanelExpandedRowIds.filter((id) => id !== rowId);
        setHeadlineDetailPanelExpandedRowIds(newIds);
      },
      [headlineDetailPanelExpandedRowIds],
    );

    const navigateToNextBookmark = React.useCallback(
      (direction: "forward" | "backward") => {
        if (orderedBookmarkDetails.length === 0) {
          return;
        }

        let newIndex = -1;
        if (direction === "backward" && currentBookmarkIndex > 0) {
          newIndex = currentBookmarkIndex - 1;
        } else if (direction === "backward" && currentBookmarkIndex === 0) {
          newIndex = orderedBookmarkDetails.length - 1;
        } else if (direction === "forward" && currentBookmarkIndex < orderedBookmarkDetails.length - 1) {
          newIndex = currentBookmarkIndex + 1;
        } else if (direction === "forward" && currentBookmarkIndex === orderedBookmarkDetails.length - 1) {
          newIndex = 0;
        }

        // If the new index is -1, it means that there is no "next" bookmark
        if (newIndex === -1) {
          return;
        }

        scrollToBookmark(orderedBookmarkDetails[newIndex].bookmarkId, orderedBookmarkDetails[newIndex].index);
        setCurrentBookmarkIndex(newIndex);
      },
      [currentBookmarkIndex, orderedBookmarkDetails, scrollToBookmark],
    );

    const { handleInputPointerDown } = useShapeEvents(shape.id);

    const onHeadlineDelete = React.useCallback(
      (id: string) => {
        const newHeadlines: Array<IHeadline> = [];

        props.headlines.forEach((headline) => {
          const newHeadline = {
            ...headline,
          };

          if (newHeadline.results) {
            newHeadline.results = newHeadline.results.filter((result) => result.id !== id);
          }

          // Only retain the headline if ID didn't match and it still has children
          if (newHeadline.id !== id && (headline.results ? headline.results.length > 0 : false)) {
            newHeadlines.push(newHeadline);
          }
        });
        const newExpandedRowIds = headlineDetailPanelExpandedRowIds.filter((rowId) => rowId !== id);
        setHeadlineDetailPanelExpandedRowIds(newExpandedRowIds);

        setCheckedHeadlineIds([]);
        setCurrentBookmarkIndex(-1);

        // Update the shape
        tldrawEditor.updateShape({
          ...shape,
          props: {
            ...shape.props,
            headlines: newHeadlines,
          },
        });
      },
      [shape, props.headlines, tldrawEditor, headlineDetailPanelExpandedRowIds],
    );

    return (
      <HTMLContainer
        id={shape.id}
        style={{
          background: "#fff",
          border: `1px solid ${isEditing ? "transparent" : "black"}`,
          display: "flex",
          pointerEvents: "all",
          justifyContent: "center",
        }}
      >
        {props.status !== "success" && props.status !== "failure" && (
          <Box
            sx={{
              position: "fixed",
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        )}
        <div
          ref={contentRef}
          style={{
            display: "flex",
            flex: 1,
            overflow: isSelected || isInteracting ? "hidden auto" : "hidden",
            pointerEvents: isSelected || isInteracting ? "auto" : "none",
            padding: "0.75in",
            flexDirection: "column",
            scrollbarGutter: "stable",
          }}
        >
          <div
            style={{
              pointerEvents: isInteracting || isEditing ? "auto" : "none",
            }}
            onPointerDown={handleInputPointerDown}
          >
            {props.status === "failure" ? (
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="h6" gutterBottom>
                  {t("Components.Headline.SimulationFailureMessage")}
                </Typography>
              </Box>
            ) : (
              <>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography variant="h4" gutterBottom>
                    {t("Components.Headline.Title")}
                  </Typography>

                  {orderedBookmarkDetails.length > 0 && (
                    <Box sx={{ display: "flex" }}>
                      <Typography sx={{ display: "inline-flex", alignItems: "center", marginRight: "1em" }} variant="body2">
                        {t("Components.Headline.NavigationTitle", {
                          currentIndex: currentBookmarkIndex + 1,
                          totalBookmarks: orderedBookmarkDetails.length,
                        })}
                      </Typography>

                      <IconButton onClick={() => navigateToNextBookmark("backward")} disableRipple>
                        <Tooltip title={t("Components.Headline.Tooltip.PreviousExcerpt")}>
                          <KeyboardArrowUpOutlined />
                        </Tooltip>
                      </IconButton>

                      <IconButton onClick={() => navigateToNextBookmark("forward")} disableRipple>
                        <Tooltip title={t("Components.Headline.Tooltip.NextExcerpt")}>
                          <KeyboardArrowDownOutlined />
                        </Tooltip>
                      </IconButton>
                    </Box>
                  )}
                </Box>

                {headlines.length > 0 && (
                  <HeadlineDataGrid
                    expandedRowIds={headlineDetailPanelExpandedRowIds}
                    headlines={headlines}
                    onRowDelete={onHeadlineDelete}
                    onRowExpansionChange={onHeadlineRowExpansionChange}
                    onRowSelectionModelChange={onHeadlineRowSelectionModelChange}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </HTMLContainer>
    );
  }
}
