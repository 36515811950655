import { IDateRange, IDateRangeLimits } from "@bigpi/cookbook";
import * as d3 from "d3";

import { autoMargin, measureText } from "Notebooks/PlotUtils/AutoMargins";
import { isInRange } from "./DateUtils";

/**
 * Utility methods for charts.
 */
export class ChartUtils {
  /**
   * Checks if the given date is in the given range.
   * Note: Considers only the date part in the given date.
   *
   * @param rangeDate The date range in the format YYYY-MM-DD#YYYY-MM-DD.
   * @param dateCheck The date to check.
   *
   * @returns `true` if the date is in the range, `false` otherwise.
   */
  public static isInRange = isInRange;

  /**
   * Stringifies the date range in the format YYYY-MM-DD#YYYY-MM-DD.
   * @param rangeDate Date range in the format { from: string, to: string }
   * @returns
   */
  public static stringifyDateRange(rangeDate: IDateRange) {
    if (rangeDate.from || rangeDate.to) {
      return `${rangeDate.from || ""}#${rangeDate.to || ""}`;
    }
    return "";
  }

  /**
   * Parses the date range string in the format { from: string, to: string }
   * @param rangeDate Date range in the format YYYY-MM-DD#YYYY-MM-DD
   * @returns
   */
  public static parseStringToDateRange(rangeDate: string) {
    return {
      from: rangeDate.split("#")[0] || "",
      to: rangeDate.split("#")[1] || "",
    };
  }

  /**
   * Slices the Analysis data within the date range.
   *
   * @param data Analysis data.
   * @param facets Applied facets.
   * @param dateRanges Date min & max ranges within the overall data.
   *
   * @returns Data filtered by date range.
   */
  public static filterDataByDateRange<TData>(
    data: Array<TData>,
    dateField: keyof TData,
    dateRange: IDateRange | undefined,
    dateRangeLimit: IDateRangeLimits,
  ): Array<TData> {
    let filteredData = data;
    if (dateRange && (dateRange?.from || dateRange?.to)) {
      filteredData = data.filter((item) => {
        return ChartUtils.isInRange(
          `${dateRange!.from || dateRangeLimit.min}#${dateRange.to || dateRangeLimit.max}`,
          item[dateField] as any,
        );
      });
    }
    return filteredData as Array<TData>;
  }

  /**
   * Gets all category values.
   *
   * @param data Analysis data.
   *
   * @returns All category values.
   */
  public static getAllCategories(data: Array<Record<string, any>>, sortField: string) {
    return [...d3.group(data, (d: any) => d[sortField]).keys()];
  }

  /**
   * Gets dynamic margin left based on the text & font size.
   *
   * @param data Analysis data.
   *
   * @return The left margin value.
   */
  public static getMarginLeft(
    data: Array<Record<string, any>>,
    yGroupField: string,
    yItemField: string,
    approximateMarginLeft: number,
    fontSize: number,
  ) {
    const groupMarginLeft: number = autoMargin(data, yGroupField, fontSize);
    const groupItemMarginLeft: number = autoMargin(data, yItemField, fontSize);
    const marginLeft = d3.max([groupMarginLeft, groupItemMarginLeft, approximateMarginLeft]) || approximateMarginLeft;

    return marginLeft;
  }

  /**
   * Generates single/multi line formatted label based on the width of the container.
   *
   * @param label Label to be formatted
   * @param fontSize Font size of the label
   * @param width Width of the container
   * @returns
   */
  public static formatOverflowAxisLabel(label: string, fontSize: number, width: number, textOverflow?: "ellipsis" | "wrap") {
    const actualWidth = measureText(label, fontSize);
    const bufferWidth = 30;
    if (actualWidth > width) {
      const words = label.split(" ");
      let latestWidth = actualWidth;
      const poppedWords: any = [];
      while (latestWidth > width - bufferWidth) {
        const poppedWord = words.pop();
        poppedWords.splice(0, 0, poppedWord);
        latestWidth = measureText(words.join(" "), fontSize);
      }

      let suffix = "";
      if (textOverflow === "ellipsis") {
        suffix = "...";
      } else {
        suffix = "\n " + poppedWords.join(" ");
      }
      return words.join(" ") + suffix;
    }
    return label;
  }

  /**
   * Gets the plot width.
   *
   * @param marginLeft Calculated margin left.
   * @param allTickers All tickers that gives each ticker fixed space.
   *
   * @returns The plot width.
   */
  public static getPlotWidth(marginLeft: number, allTickers: Array<string>, xAxisSingleLabelWidth: number) {
    return allTickers.length * xAxisSingleLabelWidth + marginLeft;
  }
}

export const CHART_DEFAULT_DIMENSIONS = {
  marginBottom: 30,
  marginLeft: 40,
  marginRight: 20,
  marginTop: 20,
  width: 640,
};
