import { defineMigrations } from "@tldraw/store";

import { IDataGridPreferences } from "../DataGrid/index.js";

import { ITopicDiscussionInNewsArticleAnalysisShape } from "./ITopicDiscussionInNewsArticleAnalysisShape.js";
import { ITopicDiscussionInNewsArticleAnalysisFacets } from "./ITopicDiscussionInNewsArticleAnalysisFacets.js";

export const TopicDiscussionInNewsArticleAnalysisShapeVersions = {
  Initial: 0,
  AddBackgroundColorField: 1,
  AddPreferencesField: 2,
  RemoveDataGridPreferencesColumnVisibility: 3,
  AddPreferencesFacetSortModel: 4,
  AddStandardisedFacetsAndBounds: 5,
};

export const topicDiscussionInNewsArticleAnalysisMigrations = defineMigrations({
  firstVersion: TopicDiscussionInNewsArticleAnalysisShapeVersions.Initial,
  currentVersion: TopicDiscussionInNewsArticleAnalysisShapeVersions.AddStandardisedFacetsAndBounds,
  migrators: {
    [TopicDiscussionInNewsArticleAnalysisShapeVersions.AddBackgroundColorField]: {
      up: (shape: ITopicDiscussionInNewsArticleAnalysisShape) => {
        return {
          ...shape,
          props: { ...shape.props, enableBackground: false },
        };
      },
      down: (shape: ITopicDiscussionInNewsArticleAnalysisShape & { analysisType: string }) => {
        const { enableBackground, ...rest } = shape.props;
        return { ...shape, props: rest };
      },
    },
    [TopicDiscussionInNewsArticleAnalysisShapeVersions.AddPreferencesField]: {
      up: (shape: ITopicDiscussionInNewsArticleAnalysisShape) => {
        return {
          ...shape,
          props: { ...shape.props, preferences: {} },
        };
      },
      down: (shape: ITopicDiscussionInNewsArticleAnalysisShape) => {
        const { preferences, ...rest } = shape.props;
        return { ...shape, props: rest };
      },
    },
    [TopicDiscussionInNewsArticleAnalysisShapeVersions.RemoveDataGridPreferencesColumnVisibility]: {
      up: (
        shape: ITopicDiscussionInNewsArticleAnalysisShape & {
          preferences: { dataGrid: { columnVisibility: Record<string, boolean> } };
        },
      ) => {
        const { dataGrid, ...rest } = shape.props.preferences;
        const dataGridPreferences = dataGrid as IDataGridPreferences & { columnVisibility?: Record<string, boolean> };
        if (dataGridPreferences) {
          delete dataGridPreferences.columnVisibility;
        }

        return {
          ...shape,
          props: {
            ...shape.props,
            preferences: {
              ...shape.props.preferences,
              dataGrid: dataGridPreferences,
            },
          },
        };
      },
      down: (shape: ITopicDiscussionInNewsArticleAnalysisShape) => {
        return {
          ...shape,
          props: {
            ...shape.props,
            preferences: {
              ...shape.props.preferences,
              dataGrid: {
                ...shape.props.preferences.dataGrid,
                columnVisibility: {},
              },
            },
          },
        };
      },
    },
    [TopicDiscussionInNewsArticleAnalysisShapeVersions.AddPreferencesFacetSortModel]: {
      up: (shape: ITopicDiscussionInNewsArticleAnalysisShape) => {
        const { preferences, ...rest } = shape.props;
        const analysisPreferences = preferences.analysis || {};
        analysisPreferences.facetSort = [];
        return {
          ...shape,
          props: {
            ...rest,
            preferences: {
              ...preferences,
              analysis: analysisPreferences,
            },
          },
        };
      },
      down: (shape: ITopicDiscussionInNewsArticleAnalysisShape) => {
        const { preferences, ...rest } = shape.props;
        delete preferences.analysis;
        return {
          ...shape,
          props: {
            ...rest,
            preferences,
          },
        };
      },
    },
    [TopicDiscussionInNewsArticleAnalysisShapeVersions.AddStandardisedFacetsAndBounds]: {
      up: (
        shape: ITopicDiscussionInNewsArticleAnalysisShape & { props: { facets: Record<string, any>; analysisType: string } },
      ) => {
        const { facets, analysisType, preferences, ...rest } = shape.props;

        let selectedFacetValues: ITopicDiscussionInNewsArticleAnalysisFacets = {};
        let selection: ITopicDiscussionInNewsArticleAnalysisShape["props"]["selection"] = {};
        let boundsFacetValues: ITopicDiscussionInNewsArticleAnalysisFacets = {};
        let preferencesProps = { ...(preferences || {}) };
        let selectedBoundsFields: Array<string> = [];

        // Transforms facets to latest
        if (facets) {
          if (facets.selectedDate) {
            selectedFacetValues.date = facets.selectedDate;
          }

          if (facets.selectedTopics) {
            selectedFacetValues.topic = facets.selectedTopics;
          }

          if (facets.expandedGroups) {
            selection.expandedGroups = facets.expandedGroups;
          }

          if (facets.selectedTimeScale) {
            selection.selectedTimeScale = facets.selectedTimeScale;
          }

          if (facets.axisSelection) {
            selection.axisSelection = facets.axisSelection;
          }
        }

        if (facets && facets.includeItems !== undefined) {
          preferencesProps.analysis = {
            ...(preferencesProps.analysis || {}),
            showTopics: facets.includeItems,
          };
        }

        if (analysisType) {
          boundsFacetValues.theme = [analysisType];
          selectedBoundsFields = ["theme"];
        }

        return {
          ...shape,
          props: {
            ...rest,
            selectedFacetValues,
            boundsFacetValues,
            selection,
            preferences: preferencesProps,
            selectedBoundsFields,
            toolbar: {
              availableFields: ["date", "topic"],
            },
          },
        };
      },
      down: (shape: ITopicDiscussionInNewsArticleAnalysisShape) => {
        const { preferences, boundsFacetValues, selectedBoundsFields, selectedFacetValues, selection, toolbar, ...rest } =
          shape.props;

        let facets: Record<string, any> = {};
        let analysisType;
        // Reverse the latest selectedFacetValues to old facets
        if (selectedFacetValues) {
          if (selectedFacetValues.date) {
            facets.selectedDate = selectedFacetValues.date;
          }

          if (selectedFacetValues.topic) {
            facets.selectedTopics = selectedFacetValues.topic;
          }
        }

        if (selection) {
          if (selection.expandedGroups) {
            facets.expandedGroups = selection.expandedGroups;
          }

          if (selection.selectedTimeScale) {
            facets.selectedTimeScale = selection.selectedTimeScale;
          }

          if (selection.axisSelection) {
            facets.axisSelection = selection.axisSelection;
          }
        }

        if (preferences && preferences.analysis && preferences.analysis.showTopics !== undefined) {
          facets = {
            ...facets,
            includeItems: preferences.analysis.showTopics,
          };

          delete preferences.analysis.showTopics;
        }

        if (preferences && preferences.analysis && preferences.analysis.startColor !== undefined) {
          delete preferences.analysis.startColor;
        }

        if (preferences && preferences.analysis && preferences.analysis.showDataToDisplayInToolbar !== undefined) {
          delete preferences.analysis.showDataToDisplayInToolbar;
        }

        if (boundsFacetValues && boundsFacetValues.theme) {
          analysisType = boundsFacetValues.theme[0];
        }

        return {
          ...shape,
          props: {
            ...rest,
            facets,
            preferences,
            analysisType,
          },
        };
      },
    },
  },
});
