import { Permissions } from "@bigpi/permission";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import RefreshIcon from "@mui/icons-material/Refresh";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import MoreIcon from "@mui/icons-material/MoreVert";
import ShareIcon from "@mui/icons-material/Share";
import { Box, IconButton, Menu, MenuItem, ListItemIcon, ListItemText, Tooltip, Divider } from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { DocumentAccessControlListDialog } from "Components/AccessControlList/DocumentAccessControlListDialog";
import { HasDocumentRole } from "Components/HasDocumentRole/HasDocumentRole";
import { HasPermission } from "Components/HasPermission/HasPermission";
import { DeleteDocumentDialog } from "Components/DeleteDocumentDialog/DeleteDocumentDialog";
import { RenameDocumentDialog } from "Components/RenameDocumentDialog/RenameDocumentDialog";
import { ObjectRole, useReindexDocumentsMutation } from "GraphQL/Generated/Apollo";
import { EditDocumentsInWorkspaceDialog } from "./EditDocumentsInWorkspaceDialog";

export interface IDocumentsListMenu {
  documentId: string;
}

export function DocumentsListMenu(props: IDocumentsListMenu) {
  const { documentId } = props;
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [aclDialogOpen, setAclDialogOpen] = React.useState(false);
  const [editInWorkspaceOpen, setEditInWorkspaceOpen] = React.useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [renameDialogOpen, setRenameDialogOpen] = React.useState(false);
  const [reindexEnabled, setReindexEnabled] = React.useState(true);

  const [ReindexDocuments] = useReindexDocumentsMutation();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const reindexDocument = async () => {
    // Disable the button to prevent multiple clicks
    setReindexEnabled(false);

    try {
      const response = await ReindexDocuments({
        variables: {
          input: {
            documentIds: [documentId],
          },
        },
      });

      const documentIds = response.data?.reindexDocuments.documentIds || [];
      if (documentIds.length > 0) {
        // Show success message
        toast.success(t("Components.DocumentsListMenu.Reindex.Success"));
      } else {
        // Show error message
        toast.error(t("Components.DocumentsListMenu.Reindex.Error"));
      }
    } catch (error) {
      console.error(error);

      // Show error message
      toast.error(t("Components.DocumentsListMenu.Reindex.Error"));

      // Re-enable the button on failures, but not success
      setReindexEnabled(true);
    }
  };

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title={t("Global.Action.Actions") || ""}>
          <IconButton
            onClick={handleClick}
            size="small"
            aria-controls={open ? "document-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <MoreIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 24,
              height: 24,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {/* Limit sharing dialog to platform admins for now */}
        <HasPermission permission={Permissions.PlatformDataWrite}>
          <MenuItem onClick={() => setAclDialogOpen(true)}>
            <ListItemIcon>
              <ShareIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={t("Global.Action.Share")} />
          </MenuItem>
        </HasPermission>

        <MenuItem onClick={() => setEditInWorkspaceOpen(true)}>
          <ListItemIcon>
            <CreateIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={t("Global.Action.NewWorkspaceFrom")} />
        </MenuItem>

        <HasDocumentRole documentId={documentId} roles={[ObjectRole.Manager, ObjectRole.Owner]}>
          <MenuItem onClick={() => setRenameDialogOpen(true)}>
            <ListItemIcon>
              <DriveFileRenameOutlineIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={t("Global.Action.Rename")} />
          </MenuItem>

          <Divider />

          <MenuItem onClick={() => setDeleteDialogOpen(true)}>
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ color: "danger.main" }} primary={t("Global.Action.Delete")} />
          </MenuItem>
        </HasDocumentRole>

        <HasPermission permission={Permissions.PlatformDataWrite}>
          <Divider />
          <MenuItem disabled={!reindexEnabled} onClick={reindexDocument}>
            <ListItemIcon>
              <RefreshIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={t("Global.Action.ReindexDocuments", { count: 1 })} />
          </MenuItem>
        </HasPermission>
      </Menu>
      {aclDialogOpen && (
        <DocumentAccessControlListDialog documentId={documentId} open={aclDialogOpen} onClose={() => setAclDialogOpen(false)} />
      )}
      {deleteDialogOpen && (
        <DeleteDocumentDialog documentId={documentId} open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)} />
      )}
      {renameDialogOpen && (
        <RenameDocumentDialog documentId={documentId} open={renameDialogOpen} onClose={() => setRenameDialogOpen(false)} />
      )}
      {editInWorkspaceOpen && (
        <EditDocumentsInWorkspaceDialog
          open={editInWorkspaceOpen}
          onClose={() => setEditInWorkspaceOpen(false)}
          documentIds={[documentId]}
        />
      )}
    </React.Fragment>
  );
}
