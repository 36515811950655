export enum PageSize {
  A2 = "A2",
  A3 = "A3",
  A4 = "A4",
  A5 = "A5",
  B5 = "B5",
  Custom = "Custom",
  Executive = "Executive",
  Letter = "Letter",
  Legal = "Legal",
  Tabloid = "Tabloid",
}

export function PageSizeToClassName(pageSize: PageSize): string {
  switch (pageSize) {
    case PageSize.A2:
      return "page-a2";
    case PageSize.A3:
      return "page-a3";
    case PageSize.A4:
      return "page-a4";
    case PageSize.A5:
      return "page-a5";
    case PageSize.B5:
      return "page-b5";
    case PageSize.Custom:
      return "";
    case PageSize.Executive:
      return "page-executive";
    case PageSize.Letter:
      return "page-letter";
    case PageSize.Legal:
      return "page-legal";
    case PageSize.Tabloid:
      return "page-tabloid";
  }
}
