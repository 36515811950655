import {
  TopicDiscussionAnalysisType,
  TopicDiscussionSummaryAnalysisType,
  TopicDiscussionInNewsArticleAnalysisType,
  AnalystQuestionAnalysisType,
  QuestionAnalysisType,
} from "@bigpi/tl-schema";

export enum AnalysisTypeEnum {
  AnalystQuestionAnalysis = "analystQuestionAnalysis",
  QuestionAnalysis = "questionAnalysis",
  TopicDiscussionAnalysis = "topicDiscussionAnalysis",
  TopicDiscussionInNewsArticleAnalysis = "topicDiscussionInNewsArticleAnalysis",
  TopicDiscussionSummaryAnalysis = "topicDiscussionSummaryAnalysis",
}

export type IAnalysisType =
  | AnalystQuestionAnalysisType
  | QuestionAnalysisType
  | TopicDiscussionAnalysisType
  | TopicDiscussionInNewsArticleAnalysisType
  | TopicDiscussionSummaryAnalysisType;
