import { IAnalysisFieldConfig, AnalysisFieldTypeEnum } from "@bigpi/cookbook";

/**
 * Query generator base class to generate dynamic query for the fields & fieldsConfig
 */
export class FieldQueryGeneratorBase {
  fields: Array<string>;
  fieldsConfig: Array<IAnalysisFieldConfig>;
  validFieldTypes: Array<AnalysisFieldTypeEnum> = [];

  constructor(fields: Array<string>, fieldsConfig: Array<IAnalysisFieldConfig>) {
    this.fieldsConfig = fieldsConfig;
    this.fields = fields;
  }

  validFields() {
    return this.fields.filter((field) => {
      return this.fieldsConfig.find((config) => config.field === field && this.validFieldTypes.includes(config.type));
    });
  }

  protected generateStringQuery(field: string) {}

  protected generateDateQuery(field: string) {}

  protected generateArrayOfStringsQuery(fied: string) {}

  protected generateNumberQuery(field: string) {
    return "";
  }

  generateFieldsQuery() {
    return this.validFields()
      .map((field) => {
        const fieldConfig = this.fieldsConfig.find((config) => config.field === field);
        if (!fieldConfig) {
          return "";
        }

        switch (fieldConfig.type) {
          case AnalysisFieldTypeEnum.String:
            return this.generateStringQuery(field);
          case AnalysisFieldTypeEnum.ArrayOfStrings:
            return this.generateArrayOfStringsQuery(field);
          case AnalysisFieldTypeEnum.Date:
            return this.generateDateQuery(field);
          case AnalysisFieldTypeEnum.Number:
            return this.generateNumberQuery(field);
          default:
            return "";
        }
      })
      .join("\n");
  }

  protected generateQuery(fieldsQuery: string) {}

  public getQuery() {
    const fieldsQuery = this.generateFieldsQuery();
    return this.generateQuery(fieldsQuery);
  }
}
