import { Box } from "@mui/system";
import * as Plot from "@observablehq/plot";
import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import { useTranslation } from "react-i18next";

import {
  ITopicDiscussionSummaryExampleExtendedResult,
  ITopicDiscussionSummaryExampleResult,
  ITopicDiscussionExampleResult,
} from "BoardComponents/BoardDatastore";

import { ChartUtils } from "Utils/ChartUtils";
import { DataUtils } from "Utils/DataUtils";
import { getFormattedDate } from "Utils/DateUtils";

import { StandardHtmlColors } from "@bigpi/cookbook";
// css
import "./TopicDiscussionSummaryOverviewChart.css";

// *********************************************
// Private constants
// *********************************************/
interface ConfigType {
  xField: string;
  fyField?: string;
  yField?: string;
  groupReducer?: (channelData: Array<Record<string, any>>) => any;
  dataFormatter?: (
    data: Array<ITopicDiscussionSummaryExampleResult | ITopicDiscussionExampleResult>,
  ) => Array<ITopicDiscussionSummaryExampleExtendedResult | ITopicDiscussionExampleResult>;
}

export interface ThemeOverviewChartProps {
  config: {
    speakerChartConfig: ConfigType;
    themeChartConfig: ConfigType;
    topicChartConfig: ConfigType;
  };
  data: Array<ITopicDiscussionSummaryExampleResult | ITopicDiscussionExampleResult>;
  priorEvents: Array<string>;
  plotRefs: {
    themePlotRef: React.MutableRefObject<HTMLDivElement | null>;
    topicPlotRef: React.MutableRefObject<HTMLDivElement | null>;
    speakersPlotRef: React.MutableRefObject<HTMLDivElement | null>;
  };
}

const PLOT_WIDTH_OFFSET = 100;
const INSET_LEFT = 20;
const MARGIN_LEFT = 400;
const MARGIN_LEFT_FOR_LABELS = MARGIN_LEFT - 50;
const DEFAULT_DOT_RADIUS = 5;
const DEFAULT_FONT_SIZE = 16;
const FY_AXIS_LABEL_PADDING = 40;
const PLOT_FACET_HEIGHT = 80;
const PLOT_FACET_OFFSET = 30;
const EVENT_COLUMN_WIDTH = 40;

// *********************************************
// Component
// *********************************************/
export function TopicDiscussionSummaryOverviewChart(props: ThemeOverviewChartProps) {
  const { data, config, priorEvents, plotRefs } = props;
  const { themePlotRef, topicPlotRef, speakersPlotRef } = plotRefs;
  const { speakerChartConfig, themeChartConfig, topicChartConfig } = config;

  const xDomainValuesRef = useRef<Array<string>>([]);

  const { t } = useTranslation();

  useEffect(() => {
    if (themePlotRef.current) {
      themePlotRef.current.innerHTML = "";
    }
    if (topicPlotRef.current) {
      topicPlotRef.current.innerHTML = "";
    }
    if (speakersPlotRef.current) {
      speakersPlotRef.current.innerHTML = "";
    }

    const xDomainValues = Array.from(getXDomainValues()).sort((a, b) => d3.descending(new Date(a), new Date(b)));
    xDomainValuesRef.current = priorEvents.length > 0 ? xDomainValues.slice(0, 8) : xDomainValues;
    renderChart();
  }, [data, priorEvents, themePlotRef, topicPlotRef, speakersPlotRef]);

  return (
    <Box className="topic-discussion-summary-overview-chart">
      <label>{t("Components.TopicDiscussionSummaryOverview.ThemeDiscussionDepth")}</label>
      <div className="topic-discussion-summary-overview-chart__theme-plot" ref={themePlotRef}></div>
      <label>{t("Components.TopicDiscussionSummaryOverview.TopicDiscussionDepth")}</label>
      <div className="topic-discussion-summary-overview-chart__topics-plot" ref={topicPlotRef}></div>
      <label>{t("Components.TopicDiscussionSummaryOverview.Speakers")}</label>
      <div className="topic-discussion-summary-overview-chart__speakers-plot" ref={speakersPlotRef}></div>
    </Box>
  );

  // *********************************************
  // Private constants
  // *********************************************/
  /**
   * Get the x domain values from the data
   *
   * @returns X domain values
   */
  function getXDomainValues() {
    // @ts-expect-error TODO: check this
    return new Set(data.map((d) => d[themeChartConfig.xField]));
  }

  /**
   * Renders the chart
   */
  function renderChart() {
    plotRefs.themePlotRef.current?.appendChild(getThemePlot());
    plotRefs.topicPlotRef.current?.appendChild(getTopicsPlot());
    plotRefs.speakersPlotRef.current?.appendChild(getSpeakersPlot());
  }

  /**
   * Get the theme plot element for the data
   *
   * @returns Plot element for theme
   */
  function getThemePlot() {
    const { xField, fyField, dataFormatter, groupReducer } = themeChartConfig;
    const formattedData = dataFormatter ? dataFormatter(data) : data;
    let eventRollupData;
    if (groupReducer) {
      eventRollupData = d3.rollups(
        formattedData,
        groupReducer,
        // @ts-expect-error TODO: check this
        (d) => d[fyField || ""],
        // @ts-expect-error TODO: check this
        (d) => d[xField],
      );
    }

    let eventMaxValue;
    if (eventRollupData && eventRollupData?.length > 0) {
      eventMaxValue = d3.max(eventRollupData.flat(3).filter((value) => typeof value === "number"));
    }

    return Plot.plot({
      height: 100,
      marginLeft: MARGIN_LEFT,
      width: MARGIN_LEFT + INSET_LEFT + PLOT_WIDTH_OFFSET + xDomainValuesRef.current.length * EVENT_COLUMN_WIDTH,
      insetLeft: INSET_LEFT,
      x: {
        axis: "top",
        domain: xDomainValuesRef.current,
        label: t("Components.TopicDiscussionSummaryOverview.NumberOfDiscussions"),
        labelAnchor: "left",
        tickPadding: 15,
        tickFormat: (d) => getFormattedDate(d, t("Components.TopicDiscussionSummaryOverview.PlotEventDateFormat")),
        type: "band",
      },
      fy: {
        axis: "left",
        label: "",
        tickPadding: FY_AXIS_LABEL_PADDING,
        tickFormat: (d) => {
          return ChartUtils.formatOverflowAxisLabel(d, DEFAULT_FONT_SIZE, MARGIN_LEFT_FOR_LABELS, "wrap");
        },
      },
      y: {
        domain: [0, eventMaxValue],
        line: true,
        label: "",
        labelArrow: false,
        tickSize: 20,
        ticks: [0, eventMaxValue],
        tickFormat: (d) => d,
      },
      marks: [
        Plot.lineX(
          formattedData,
          Plot.groupX(
            { y: groupReducer },
            {
              x: xField,
              fy: fyField,
              sort: "eventDate",
              stroke: StandardHtmlColors.midnight,
              strokeWidth: 1,
            },
          ),
        ),
        Plot.dotX(
          formattedData,
          Plot.groupX(
            { y: groupReducer },
            {
              x: xField,
              fy: fyField,
              sort: "eventDate",
              fill: (d) => (priorEvents.includes(d[xField]) ? StandardHtmlColors.gray40 : StandardHtmlColors.pacific),
              r: DEFAULT_DOT_RADIUS,
              tip: {
                format: {
                  fill: false,
                  x: false,
                  fy: false,
                },
              },
            },
          ),
        ),
      ],
    });
  }

  /**
   * Get the topics plot element for the data
   *
   * @returns Plot element for topics
   */
  function getTopicsPlot() {
    const { xField, fyField, yField, dataFormatter, groupReducer } = topicChartConfig;
    const topicsData = dataFormatter ? dataFormatter(data) : data;

    // This is to add null values with 0 count for the missing dates
    // @ts-expect-error
    const topics = DataUtils.getGroupedKeys(topicsData, fyField);
    // @ts-expect-error
    const eventGroups = DataUtils.getGroupedKeys(topicsData, xField);
    let questionRollupData: any;

    if (groupReducer) {
      questionRollupData = d3.rollup(
        topicsData,
        groupReducer,
        // @ts-expect-error TODO: check this
        (d) => d[fyField],
        // @ts-expect-error TODO: check this
        (d) => d[xField],
      );
    }

    const formattedData: Array<Record<string, any>> = [];
    topics.forEach((topicGroup) => {
      eventGroups.forEach((event) => {
        const eventValue = questionRollupData.get(topicGroup)?.get(event) || 0;
        formattedData.push({
          eventDate: event,
          question: topicGroup,
          value: eventValue,
        });
      });
    });

    // Takes the max value
    const questionMaxValue = d3.max(formattedData, (d) => d.value);

    // Plot
    return Plot.plot({
      height: topics.length * PLOT_FACET_HEIGHT + PLOT_FACET_OFFSET,
      marginLeft: MARGIN_LEFT,
      width: MARGIN_LEFT + INSET_LEFT + PLOT_WIDTH_OFFSET + xDomainValuesRef.current.length * EVENT_COLUMN_WIDTH,
      insetLeft: INSET_LEFT,
      x: {
        axis: "top",
        domain: xDomainValuesRef.current,
        label: t("Components.TopicDiscussionSummaryOverview.NumberOfDiscussions"),
        labelAnchor: "left",
        tickPadding: 15,
        type: "band",
        tickFormat: (d) => getFormattedDate(d, t("Components.TopicDiscussionSummaryOverview.PlotEventDateFormat")),
      },
      fy: {
        axis: "left",
        label: "",
        tickPadding: FY_AXIS_LABEL_PADDING,
        tickFormat: (d) => {
          return ChartUtils.formatOverflowAxisLabel(d, DEFAULT_FONT_SIZE, MARGIN_LEFT_FOR_LABELS, "wrap");
        },
        padding: 0.4,
      },
      y: {
        domain: [0, questionMaxValue],
        line: true,
        label: "",
        labelArrow: false,
        ticks: [0, questionMaxValue],
        tickSize: 20,
        tickFormat: (d) => d,
      },
      marks: [
        Plot.lineX(formattedData, {
          x: xField,
          y: "value",
          fy: fyField,
          sort: "eventDate",
          stroke: StandardHtmlColors.midnight,
          strokeWidth: 1,
        }),
        Plot.dotX(formattedData, {
          x: xField,
          y: "value",
          fy: fyField,
          fill: (d) => (priorEvents.includes(d[xField]) ? StandardHtmlColors.gray40 : StandardHtmlColors.pacific),
          r: DEFAULT_DOT_RADIUS,
          title: (d) => d.value,
          tip: true,
        }),
      ],
    });
  }

  /**
   * Get the speakers plot element for the data
   *
   * @returns Plot element for speakers
   */
  function getSpeakersPlot() {
    // Format data
    const { xField, yField, fyField, dataFormatter = speakersFormatter } = speakerChartConfig;
    const formattedData = dataFormatter(data);
    const allSpeakers = Array.from(new Set(DataUtils.getGroupedKeys(formattedData, fyField)));

    return Plot.plot({
      height: allSpeakers.length * PLOT_FACET_HEIGHT + PLOT_FACET_OFFSET + (allSpeakers.length === 1 ? PLOT_FACET_OFFSET : 0),
      marginLeft: MARGIN_LEFT,
      width: MARGIN_LEFT + INSET_LEFT + PLOT_WIDTH_OFFSET + xDomainValuesRef.current.length * EVENT_COLUMN_WIDTH,
      insetLeft: INSET_LEFT,
      x: {
        axis: "top",
        domain: xDomainValuesRef.current,
        label: "",
        tickPadding: 15,
        type: "band",
        tickFormat: (d) => getFormattedDate(d, t("Components.TopicDiscussionSummaryOverview.PlotEventDateFormat")),
      },
      fy: { axis: "left", padding: 0.5, label: "", tickPadding: FY_AXIS_LABEL_PADDING },
      y: { line: true, label: "", domain: ["Y", "N"], inset: -25, tickSize: 20 },
      marks: [
        Plot.lineX(formattedData, {
          y: yField,
          x: xField,
          fy: fyField,
          sort: "eventDate",
          stroke: StandardHtmlColors.midnight,
          strokeWidth: 1,
        }),
        Plot.dotX(formattedData, {
          x: xField,
          y: yField,
          fy: fyField,
          r: DEFAULT_DOT_RADIUS,
          fill: (d) => (priorEvents.includes(d[xField]) ? StandardHtmlColors.gray40 : StandardHtmlColors.pacific),
        }),
      ],
    });
  }

  /**
   * Formats the data for the speakers plot
   *
   * @returns Formatted data for speakers
   */
  function speakersFormatter() {
    const speakersData: Array<Record<string, any>> = [];
    data.forEach((item) => {
      item.speakers?.forEach((speakerItem: Record<string, any>) => {
        speakersData.push({
          fullName: speakerItem.fullName,
          eventDate: item.eventDate,
          label: "Y",
        });
      });
    });
    const speakersGroup = DataUtils.getGroupedItems(speakersData, "fullName");
    const allSpeakers = [...speakersGroup.keys()];
    allSpeakers.forEach((key) => {
      const speakerEventDates = speakersGroup.get(key)?.map((speakerItem) => speakerItem.eventDate) || [];
      xDomainValuesRef.current.forEach((eventDate) => {
        if (!speakerEventDates.includes(eventDate)) {
          speakersData.push({
            fullName: key,
            eventDate,
            label: "N",
          });
        }
      });
    });
    return speakersData;
  }
}
