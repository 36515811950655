import { useReactiveVar } from "@apollo/client";
import { ICommandContext } from "@bigpi/cookbook";
import { Box } from "@mui/material";
import { useValue } from "@tldraw/tldraw";
import { Outlet } from "react-router-dom";

import { CommandContext } from "CommandContext";
import { AppRightSidebar } from "Components/AppRightSidebar/AppRightSidebar";
import { ChatPane } from "Chat/ChatPane/ChatPane";
import { useIsChatEnabled } from "Chat/Hooks";
import { NavigationRail } from "Components/NavigationRail/NavigationRail";
import { ChatMessageChannelType } from "GraphQL/Generated/Apollo";

export function MainLayout() {
  const commandContext = useReactiveVar<ICommandContext>(CommandContext.getCommandContext);
  const isChatEnabled = useIsChatEnabled();

  const workspaceRelatedIds = useValue(
    "workspaceId",
    () => {
      let workspaceId = "";
      let workspaceBoardId = "";
      commandContext.session.forEach((sessionItem) => {
        if (Object.keys(sessionItem).includes("workspaceId")) {
          workspaceId = sessionItem.workspaceId;
        }

        if (Object.keys(sessionItem).includes("workspaceBoardId")) {
          workspaceBoardId = sessionItem.workspaceBoardId;
        }
      });
      return { workspaceBoardId, workspaceId };
    },
    [commandContext],
  );

  return (
    <Box sx={{ display: "flex", position: "fixed", left: 0, right: 0, bottom: 0, top: 0 }}>
      <NavigationRail />
      <Box
        component="main"
        sx={{
          marginLeft: "50px",
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          overflow: "auto",
        }}
      >
        <Outlet />
      </Box>
      {isChatEnabled && workspaceRelatedIds.workspaceBoardId && (
        <AppRightSidebar>
          <ChatPane channelId={workspaceRelatedIds.workspaceId} channelType={ChatMessageChannelType.Workspace} />
        </AppRightSidebar>
      )}
    </Box>
  );
}
