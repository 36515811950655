import { useState, useEffect, useCallback } from "react";

export function useHashParams() {
  const [params, setParams] = useState(() => {
    const hash = window.location.hash.substring(1);
    return new URLSearchParams(hash);
  });

  useEffect(() => {
    // Update the hash in the URL when the hashParams change
    window.location.hash = params.toString();
  }, [params]);

  const setHashParam = useCallback((key: string, value: string) => {
    setParams((prevParams) => {
      prevParams.set(key, value);
      return new URLSearchParams(prevParams);
    });
  }, []);

  const getHashParam = useCallback(
    (key: string) => {
      return params.get(key);
    },
    [params],
  );

  const deleteHashParam = useCallback((key: string) => {
    setParams((prevParams) => {
      prevParams.delete(key);
      return new URLSearchParams(prevParams);
    });
  }, []);

  return { getHashParam, setHashParam, deleteHashParam };
}
