import { RawCommands } from "@tiptap/core";

import { HighlightColors } from "./TextHighlightExtension.js";

export const setHighlightColor: RawCommands["setHighlightColor"] =
  (color: HighlightColors) =>
  ({ chain }) => {
    return chain()
      .setMark("textHighlight", { class: `highlight-${color}` })
      .run();
  };
