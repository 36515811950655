import { IDateRange } from "@bigpi/cookbook";
import { useValue } from "@tldraw/tldraw";

import { IAnalysisFacets } from "BoardComponents/Types";
import { getOldestDate, getEarliestDate } from "Utils/DateUtils";

/**
 * Applies the bounds facets to limit the data upto the bounds facets.
 * This will make sure the toolbar facets will be within the limits of bounds facets.
 *
 * @param boundsFacets Limit the data upto these facets
 * @param facets User selected facets, which will override the bounds facets
 * @returns Bounds applied facets
 */
export function useApplyBounds<TFacets extends IAnalysisFacets>(boundsFacets: TFacets, facets: TFacets) {
  const mergedFacetValues = useValue(
    "mergedFacets",
    () => {
      return applyBounds<TFacets>(boundsFacets, facets);
    },
    [boundsFacets, facets],
  );
  return mergedFacetValues;
}

/****************************************
 * Utils
 ****************************************/
/**
 * Applies bounds to facets. This will make sure the facets are within the bounds.
 *
 * @param boundsFacets Bounds facets to limit the data upto
 * @param facets User applied facets which will fall within the bounds
 * @returns Facets with bounds applied facets
 */
export function applyBounds<TFacets extends IAnalysisFacets>(boundsFacets: TFacets, facets: TFacets) {
  const boundsFacetValues = { ...boundsFacets };
  for (const key in facets) {
    if (facets.hasOwnProperty(key)) {
      if (boundsFacetValues.hasOwnProperty(key)) {
        const boundsFacetValue = boundsFacetValues[key];
        const facetValue = facets[key];
        // Process array types
        if (Array.isArray(boundsFacetValue) && Array.isArray(facetValue)) {
          if (facetValue.length > 0 && boundsFacetValue.length > 0) {
            // Get the common values between facetValue and boundsFacetValue
            const commonValues = facetValue.filter((value) => boundsFacetValue.includes(value));
            if (commonValues.length > 0) {
              boundsFacetValues[key] = commonValues as TFacets[typeof key];
            } else {
              boundsFacetValues[key] = boundsFacetValue;
            }
          } else {
            boundsFacetValues[key] = facetValue.length > 0 ? facetValue : boundsFacetValue;
          }
          continue;
        } else if (Array.isArray(facetValue) && facetValue.length > 0) {
          boundsFacetValues[key] = facetValue;
          continue;
        } else if (
          boundsFacetValue &&
          facetValue &&
          (boundsFacetValue.hasOwnProperty("from") || boundsFacetValue.hasOwnProperty("to")) &&
          (facetValue.hasOwnProperty("from") || facetValue.hasOwnProperty("to"))
        ) {
          const from = getOldestDate((boundsFacetValue as IDateRange).from || "", (facetValue as IDateRange).from || "");
          const to = getEarliestDate((boundsFacetValue as IDateRange).to || "", (facetValue as IDateRange).to || "");
          // User selected facets will override the bounds facets for object types
          boundsFacetValues[key] = { from, to } as TFacets[typeof key];
          continue;
        } else if (facetValue && (facetValue.hasOwnProperty("from") || facetValue.hasOwnProperty("to"))) {
          boundsFacetValues[key] = facetValue;
          continue;
        }
        // Otherwise the bounds facet value will be used
      } else {
        boundsFacetValues[key] = facets[key];
      }
    }
  }
  return boundsFacetValues;
}
