import { IDateRange } from "@bigpi/cookbook";

export enum QuestionAnalysisFacetsTypeEnum {
  EventDate = "eventDate",
  EventDateShortcut = "eventDateShortcut",
  Firm = "firm",
  FirmType = "firmType",
  Segment = "segment",
  Topics = "topics",
}

export interface IQuestionAnalysisFacets {
  [QuestionAnalysisFacetsTypeEnum.EventDate]?: IDateRange;
  [QuestionAnalysisFacetsTypeEnum.EventDateShortcut]?: string;
  [QuestionAnalysisFacetsTypeEnum.Firm]?: Array<string>;
  [QuestionAnalysisFacetsTypeEnum.FirmType]?: Array<string>;
  [QuestionAnalysisFacetsTypeEnum.Segment]?: Array<string>;
  [QuestionAnalysisFacetsTypeEnum.Topics]?: Array<string>;
}
