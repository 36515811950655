import { Box } from "@mui/material";

export interface ICompanionHeaderProps {
  children: React.ReactNode;
}

export const CompanionHeader = (props: ICompanionHeaderProps) => {
  const { children } = props;

  return <Box sx={{ display: "flex", flexDirection: "row", padding: "20px 24px 0" }}>{children}</Box>;
};
