import Box from "@mui/material/Box";

import { UserGroupForm } from "./UserGroupForm";
import { useUserGroupQuery } from "GraphQL/Generated/Apollo";

export interface UserGroupEditProps {
  id: string;
}

export function UserGroupEdit(props: UserGroupEditProps) {
  const { id } = props;
  const { data, loading, error } = useUserGroupQuery({ variables: { id } });

  return (
    <>
      <Box sx={{ display: "flex", flex: 1, p: 3 }}>
        {error && <p>{String(error)}</p>}
        {!loading && !error && <UserGroupForm userGroup={data?.userGroup} />}
      </Box>
    </>
  );
}
