import { parseUrn, UrnObjectType } from "@bigpi/cookbook";

import { IWorkspaceBoardChatActionPlugInInputs } from "./IWorkspaceBoardChatActionPlugInInputs";
import { WorkspaceBoardChatActionPlugInBase } from "./WorkspaceBoardChatActionPlugInBase";
import { ChatMessageAction } from "Chat/ChatActionManagers/ChatMessageActions";
import { CancelCommandDocument, CancelCommandMutation, CancelCommandMutationVariables } from "GraphQL/Generated/Apollo";

export class CancelCommandPlugIn extends WorkspaceBoardChatActionPlugInBase {
  // *********************************************
  // Public properties
  // *********************************************/
  /**
   * @inheritdoc
   */
  name = ChatMessageAction.CancelCommand;

  // *********************************************
  // Public methods
  // *********************************************/
  /**
   * @inheritdoc
   */
  public async execute(input: IWorkspaceBoardChatActionPlugInInputs): Promise<undefined | JSX.Element> {
    const { apolloClient, app, data } = input;

    if (!app) {
      console.warn(`No app instance for chat action ${this.name}`);
      return;
    }

    if (!apolloClient) {
      console.warn(`No apollo client instance for chat action ${this.name}`);
      return;
    }

    if (!data) {
      console.warn(`No data for chat action ${this.name}`);
      return;
    }

    // Convert the given chat URN to a command request ID
    const chatUrnObject = parseUrn(data);
    if (chatUrnObject && chatUrnObject.objectType === UrnObjectType.Chat) {
      // Note: This must be kept in sync with the command request ID format in the Pantry/BotMessagePlugIn.ts Linecook/CommandExecutor.ts files
      const requestId = chatUrnObject.messageId;

      // Cancel the command
      try {
        await apolloClient.mutate<CancelCommandMutation, CancelCommandMutationVariables>({
          mutation: CancelCommandDocument,
          variables: {
            input: {
              id: requestId,
            },
          },
        });
      } catch (error) {
        console.error("Error cancelling command", error);
        // TODO: Show a dismissable dialog
      }
    }

    return undefined;
  }
}
