import { getNodeType, RawCommands } from "@tiptap/core";
import { Fragment, NodeType, NodeRange, Attrs, Slice } from "@tiptap/pm/model";
import { Transaction } from "@tiptap/pm/state";
import { findWrapping, ReplaceAroundStep } from "@tiptap/pm/transform";

import { getBaseClassWithoutIndent, getIndentClassFromIndentLevel, getIndentLevelByClass } from "../../Utils/CommandUtil.js";

// https://github.com/ProseMirror/prosemirror-schema-list/blob/master/src/schema-list.ts
// We have cloned "doWrapInList" method from prosemirror-schema-list
// and customized it for our own list schema.
const doWrapInList = (
  tr: Transaction,
  range: NodeRange,
  wrappers: { type: NodeType; attrs?: Attrs | null }[],
  joinBefore: boolean,
) => {
  let content = Fragment.empty;
  for (let i = wrappers.length - 1; i >= 0; i--) {
    content = Fragment.from(wrappers[i].type.create(wrappers[i].attrs, content));
  }

  tr.step(
    new ReplaceAroundStep(
      range.start - (joinBefore ? 2 : 0),
      range.end + (joinBefore ? 2 : 0),
      range.start,
      range.end,
      new Slice(content, 0, 0),
      wrappers.length,
      true,
    ),
  );

  const listPos = range.start - (joinBefore ? 2 : 0);
  const listNode = tr.doc.nodeAt(listPos);
  if (!listNode || (listNode.type.name !== "bulletList" && listNode.type.name !== "orderedList")) {
    return tr;
  }

  // Get indent level from the paragraph which is getting converted to list.
  // |<ol><li><p></p></li></ol>          ---> listPos
  // <ol>|<li><p></p></li></ol>          ---> listPos + 1
  // <ol><li>|<p></p></li></ol>          ---> listPos + 2
  // For more - libraries/editor-tiptap/docs/EDITOR-SELECTION-POSITION.md
  const paragraphPos = listPos + 2;
  const paragraphNode = tr.doc.nodeAt(paragraphPos);
  const indentLevel = joinBefore
    ? // Get the indent level from the list node if we are changing the list type from
      // orderedList to bulletList or vice versa.
      getIndentLevelByClass(listNode?.attrs.class)
    : // Get the indent level from the paragraph node if we are converting a paragraph to a list.
      paragraphNode?.attrs.indent ?? getIndentLevelByClass(paragraphNode?.attrs.class || listNode?.attrs.class);
  const indentClass = getIndentClassFromIndentLevel(indentLevel);

  let startValue = 1;
  tr.doc.forEach((node, offset, index) => {
    if (
      node.type.name === listNode.type.name &&
      node.attrs["data-list-id"] === listNode.attrs["data-list-id"] &&
      // This node falls before the currently selected list item
      offset < listPos
    ) {
      const nodeIndentLevel = getIndentLevelByClass(node.attrs.class);
      if (indentLevel === nodeIndentLevel) {
        // 1. first
        // 2. second
        //   a. third
        //   b. fourth               <--- We should increase the start value
        //     i. fifth
        //   c. sixth
        // 3. seventh
        startValue++;
      } else if (nodeIndentLevel < indentLevel) {
        // 1. first
        // 2. second
        //   a. third
        //   b. fourth
        //     i. fifth              <--- We should restarting the start value from 1
        //   c. sixth
        // 3. seventh
        startValue = 1;
      }
    }
  });

  // "start" attribute value is calculated based on the previous list item's
  // "start" value.
  tr.setNodeAttribute(listPos, "start", startValue);

  // Move indent from the paragraph to the list
  tr.setNodeAttribute(listPos, "class", joinBefore ? listNode?.attrs.class : indentClass);
  tr.setNodeAttribute(paragraphPos, "class", getBaseClassWithoutIndent(paragraphNode?.attrs.class));
  tr.setNodeAttribute(paragraphPos, "indent", 0);

  return tr;
};

// This command is used for wrapping valid (meaning valid child content
// for a list) non-list items in lists. We have cloned wrapInList command
// from prosemirror-schema-list (https://github.com/ProseMirror/prosemirror-schema-list/blob/master/src/schema-list.ts)
// and customized it for our list schema.
export const wrapInList: RawCommands["wrapInList"] =
  (typeOrName, attributes = {}) =>
  ({ state, dispatch, tr }) => {
    const listType = getNodeType(typeOrName, state.schema);
    const { from, to } = state.selection;

    let extraPosAfterTransform = 0;
    tr.doc.nodesBetween(from, to, (node, pos, parent) => {
      if (parent?.type.name !== "doc") {
        return false;
      }

      const start = pos + extraPosAfterTransform;
      const end = start + node.nodeSize;
      const $from = tr.doc.resolve(start);
      const $to = tr.doc.resolve(end);

      let range = $from.blockRange($to);
      if (!range) {
        return false;
      }

      let doJoin = false;
      let outerRange = range;

      let listAttrs: { start?: number; class?: string } = {};
      if (node.type.name === "bulletList" || node.type.name === "orderedList") {
        // We need to target paragraph node inside of the list item
        //
        // |<ol><li><p>text</p></li></ol>  ---> range.start
        // <ol>|<li><p>text</p></li></ol>  ---> range.start + 1
        // <ol><li>|<p>text</p></li></ol>  ---> range.start + 2
        //
        // For more - libraries/editor-tiptap/docs/EDITOR-SELECTION-POSITION.md
        let $insert = tr.doc.resolve(range.start + 2);

        // Range depth is 2, which is equal to the tree depth
        //
        // <ol>                     ---> depth = 0
        //  <li>                    ---> depth = 1
        //   <p>text</p>            ---> depth = 2
        //  </li>
        // </ol>
        range = new NodeRange($insert, tr.doc.resolve($insert.end(2)), 2);

        doJoin = true;
        listAttrs = { ...node.attrs };
        if (listType.name === "bulletList") {
          listAttrs.start = undefined;
        }
      }

      let wrap = findWrapping(outerRange!, listType, { ...listAttrs, ...attributes }, range);
      if (!wrap) {
        return false;
      }

      if (dispatch) {
        dispatch(doWrapInList(tr, range, wrap, doJoin));
        if (!doJoin) {
          // We should consider the extra positions added by the `doWrapInList` method
          // to avoid the wrong selection position when multiple nodes are selected.
          // For more - libraries/editor-tiptap/docs/EDITOR-SELECTION-POSITION.md
          //
          // <p>text</p>                                 ---> before
          // <ol><li><p>text</p></li></ol>               ---> after = before + 4
          extraPosAfterTransform += 4;
        }
      }
    });

    return true;
  };
