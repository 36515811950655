import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { DefaultDialogTransition } from "Components/DialogTransition/DefaultDialogTransition";
import { useBulkDeleteDocumentMutation, useDocumentNamesByIdsQuery } from "GraphQL/Generated/Apollo";

export interface IBulkDeleteDocumentDialogProps {
  onClose: () => void;
  open: boolean;
  documentIds: Array<string>;
}

export function BulkDeleteDocumentDialog(props: IBulkDeleteDocumentDialogProps) {
  const { open, documentIds } = props;
  const { t } = useTranslation();
  const [bulkDeleteDocument] = useBulkDeleteDocumentMutation();
  const { data, loading, error } = useDocumentNamesByIdsQuery({ variables: { ids: documentIds } });
  const [documentNames, setDocumentNames] = useState<Array<string>>([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [warningMessage, setWarningMessage] = useState("");

  function onClose() {
    setErrorMessage("");
    props.onClose();
  }

  async function onDelete(): Promise<void> {
    try {
      const result = await bulkDeleteDocument({
        variables: {
          input: {
            ids: documentIds,
          },
        },
        refetchQueries: ["Documents"],
      });

      if (result.data?.bulkDeleteDocument.count === documentIds.length) {
        props.onClose();
      } else {
        setWarningMessage(t("Components.BulkDeleteDocumentDialog.PartialError"));
      }
    } catch (error) {
      console.error(error);
      setErrorMessage(t("Components.BulkDeleteDocumentDialog.Error"));
    }
  }

  useEffect(() => {
    // Sort the documentNames alphabetically
    if (data?.documentsByIds) {
      setDocumentNames(
        data.documentsByIds
          .map((document) => document.name)
          .sort((a, b) => a.localeCompare(b, undefined, { sensitivity: "base" })),
      );
    }

    // Check if we have the same number of documents as we have documentNames
    if (data && data.documentsByIds && data.documentsByIds.length !== documentIds.length) {
      setWarningMessage(t("Components.BulkDeleteDocumentDialog.MismatchWarning"));
    }
  }, [data, documentIds, t]);

  return (
    <>
      <Dialog open={open} onClose={onClose} TransitionComponent={DefaultDialogTransition} fullWidth maxWidth="xs">
        <DialogTitle>{t("Components.BulkDeleteDocumentDialog.Title", { count: documentIds.length })}</DialogTitle>
        <DialogContent>
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
          {warningMessage && <Alert severity="warning">{warningMessage}</Alert>}
          <DialogContentText>{t("Components.BulkDeleteDocumentDialog.Tip")} </DialogContentText>
          <ul style={{ maxHeight: "300px", overflowY: "auto" }}>
            {documentNames.map((documentname, index) => (
              <li key={index}>{documentname}</li>
            ))}
          </ul>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{t("Global.Action.Close")}</Button>
          <Button onClick={onDelete} disabled={loading} color="danger">
            {t("Global.Action.DeletePermanently")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
