import { BoardSearchFieldTypes, IBoardSearchShapeMatch } from "@bigpi/cookbook";

import { getBoardSearchMatchesFromTextContent } from "Utils/BoardSearchUtils";
import { IBoardSearchPlugInInputs } from "../BoardSearchManager";
import { BoardSearchPlugInBase } from "./BoardSearchPlugInBase";

export class PlainTextFieldBoardSearchPlugIn extends BoardSearchPlugInBase {
  // *********************************************
  // Public properties
  // *********************************************/
  /**
   * @inheritdoc
   */
  name = BoardSearchFieldTypes.PlainText;

  // *********************************************
  // Public methods
  // *********************************************/
  /**
   * @inheritdoc
   */
  public async execute(input: IBoardSearchPlugInInputs): Promise<Array<IBoardSearchShapeMatch>> {
    const { searchField, searchTerm, shape } = input;
    const fieldData = this.getFieldDataFromShapeProps(shape, searchField);
    if (!fieldData) {
      return [];
    }

    return getBoardSearchMatchesFromTextContent(fieldData as string, searchTerm);
  }
}
