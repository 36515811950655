import { TabContext, TabPanel } from "@mui/lab";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Tab, Tabs } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { IAnalysisFacets } from "BoardComponents/Types";
import { AnalysisPreferencesBounds, AnalysisPreferencesBoundsProps } from "./AnalysisPreferencesBounds";
import {
  AnalysisPreferencesOptions,
  AnalysisPreferencesOptionsState,
  AnalysisPreferencesOptionsProps,
} from "./AnalysisPreferencesOptions";

/*********************************
 * Types/Interfaces
 *********************************/
// Type for the dialog state (bounds state + options state)
export interface IAnalysisPreferencesDialogState extends AnalysisPreferencesOptionsState {
  toolbarFields: Array<string>;
  toolbarBoundsFields: Array<string>;
}

type AnalysisPreferencesProps<TFacets> = Omit<
  AnalysisPreferencesBoundsProps<TFacets>,
  "rowSelectionModel" | "showInToolbarFields" | "onRowSelectionModelChange" | "onShowInToolbarFieldsChange"
> &
  AnalysisPreferencesOptionsState &
  Omit<AnalysisPreferencesOptionsProps, "onOptionsStateChange" | "optionsState"> & {
    fields: Array<keyof TFacets>;
    // On cancel of any changes on the dialog
    onCancelPreferences: () => void;
    // On close of the dialog
    onClose: () => void;

    // Open state of the dialog
    open: boolean;
    // Handles the save preferences
    onSavePreferences?: (preferences: IAnalysisPreferencesDialogState) => void;
    toolbarFields: Array<keyof TFacets>;
  };

const TAB_PANEL_MIN_HEIGHT = 500;

/*********************************
 * Component
 *********************************/
export function AnalysisPreferencesDialog<TFacets extends IAnalysisFacets>(props: AnalysisPreferencesProps<TFacets>) {
  const { selectedFacetValues, fields, onCancelPreferences, onClose, onSavePreferences, open, toolbarFields } = props;
  // State
  const [optionsState, setOptionsState] = useState<AnalysisPreferencesOptionsState>({
    isBackgroundEnabled: props.isBackgroundEnabled,
    startColor: props.startColor,
    isDataToDisplayInToolbarEnabled: props.isDataToDisplayInToolbarEnabled,
    isSubItemsEnabled: props.isSubItemsEnabled,
  });
  const [rowSelectionModel, setRowSelectionModel] = useState(fields || []);
  const [showInToolbarFields, setShowInToolbarFields] = useState(toolbarFields || Object.keys(selectedFacetValues || {}) || []);
  const [tabIndex, setTabIndex] = useState("0");
  const [tabHeight, setTabHeight] = useState<number>(0);

  // Refs
  const boundsTabRef = useRef<HTMLDivElement>();
  const optionsTabRef = useRef<HTMLDivElement>();

  const { t } = useTranslation();

  /******* Callbacks *******/
  const onTabChange = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      setTabIndex(newValue);
    },
    [setTabIndex],
  );

  useEffect(() => {
    setTabHeight(
      Math.max(boundsTabRef.current?.clientHeight || 0, optionsTabRef.current?.clientHeight || 0, TAB_PANEL_MIN_HEIGHT),
    );
  }, [boundsTabRef.current?.clientHeight, optionsTabRef.current?.clientHeight]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth={true}>
      <DialogTitle>{t("Components.Analyses.Common.PreferencesDialog.Preferences")}</DialogTitle>
      <DialogContent dividers={true}>
        <Box sx={{ height: "100%" }}>
          <TabContext value={tabIndex}>
            <Tabs
              value={tabIndex}
              onChange={onTabChange}
              sx={{
                "&.MuiTabs-root": {
                  borderBottom: "1px solid #e9e9e9",
                  height: "48px",
                },
                "& .MuiTabs-indicator": {
                  display: "none",
                },
              }}
            >
              <Tab label={t("Components.Analyses.Common.PreferencesDialog.Options")} value="0" />
              <Tab
                label={t("Components.Analyses.Common.PreferencesDialog.Bounds")}
                value="1"
                sx={{
                  "&.MuiTab-root": {
                    border: "1px solid #e9e9e9",
                    borderBottomWidth: 0,
                    "&:not(:first-of-type)": {
                      marginLeft: -1,
                    },
                    background: "#f7f7f7",
                    opacity: 1,
                  },
                  "&.Mui-selected": {
                    borderBottomWidth: 0,
                    background: "#ffffff",
                    "& $wrapper": {
                      opacity: 1,
                    },
                  },
                }}
              />
            </Tabs>
            <TabPanel value="0" ref={optionsTabRef} sx={{ minHeight: tabHeight }}>
              <AnalysisPreferencesOptions {...props} optionsState={optionsState} onOptionsStateChange={setOptionsState} />
            </TabPanel>
            <TabPanel value="1" ref={boundsTabRef} sx={{ minHeight: tabHeight }}>
              <AnalysisPreferencesBounds
                {...props}
                rowSelectionModel={rowSelectionModel}
                onRowSelectionModelChange={setRowSelectionModel}
                showInToolbarFields={showInToolbarFields}
                onShowInToolbarFieldsChange={setShowInToolbarFields}
              />
            </TabPanel>
          </TabContext>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            onClose();
            onSavePreferences &&
              onSavePreferences({
                ...optionsState,
                toolbarFields: showInToolbarFields as Array<string>,
                toolbarBoundsFields: rowSelectionModel as Array<string>,
              });
          }}
        >
          {t("Components.Analyses.Common.PreferencesDialog.Save")}
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            onClose();
            setRowSelectionModel(rowSelectionModel);
            onCancelPreferences && onCancelPreferences();
          }}
        >
          {t("Components.Analyses.Common.PreferencesDialog.Cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
