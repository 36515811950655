import { Level } from "@tiptap/extension-heading";
import { Paragraph as TipTapParagraph } from "@tiptap/extension-paragraph";
import { mergeAttributes } from "@tiptap/react";

import { getBaseClassWithoutIndent, getBaseClassWithoutTextAlignment } from "../../Utils/CommandUtil.js";
import { Heading } from "../Heading/HeadingExtension.js";
import { setParagraph } from "./SetParagraphCommand.js";

export const Paragraph = TipTapParagraph.extend({
  addAttributes() {
    return {
      class: {
        default: null,
        renderHTML(attributes) {
          return {
            class: attributes.class,
          };
        },
        parseHTML: (element: HTMLElement) => {
          const classAttr = element.hasAttribute("class") ? element.getAttribute("class") : null;

          // Indent is handled by the IndentExtension
          const baseClassWithoutIndent = getBaseClassWithoutIndent(classAttr);

          // Text alignment is handled by the TextAlignExtension
          const baseClassWithoutTextAlignmentAndIndent = getBaseClassWithoutTextAlignment(baseClassWithoutIndent);

          return baseClassWithoutTextAlignmentAndIndent;
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "p",
        getAttrs: (node) => {
          const headingClasses = Heading.options.levels.map((level: Level) => `heading-${level}`).concat("title");
          const includesHeadingClass = headingClasses.some((c) => (node as HTMLElement).className.includes(c));

          // We don't want to parse heading or title nodes as paragraphs,
          // HeadingExtension/ TitleExtension will parse these nodes.
          return !includesHeadingClass && null;
        },
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["p", mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },

  addCommands() {
    return {
      ...this.parent?.(),
      setParagraph,
    };
  },
});
