import {
  IInlineFrameShape,
  getInlineFrameShapeDefaultProps,
  inlineFrameShapeMigrations,
  inlineFrameShapeProps,
} from "@bigpi/tl-schema";
import { HTMLContainer, Migrations, useValue } from "@tldraw/tldraw";
import * as React from "react";

import { BoxBaseUtil } from "BoardComponents/BaseShapes/BoxBaseUtil";
import { useIsInteracting } from "BoardComponents/Tools";
import { useShapeEvents } from "BoardComponents/useShapeEvents";

// *********************************************
// Shape Util
// *********************************************/
/**
 * Generator for InlineFrame shapes.
 */
export class InlineFrameUtil extends BoxBaseUtil<IInlineFrameShape> {
  // *********************************************
  // Static fields
  // *********************************************/
  static type = "inlineFrame";

  static props = inlineFrameShapeProps;

  static migrations: Migrations = inlineFrameShapeMigrations;

  // *********************************************
  // Protected fields
  // *********************************************/
  /**
   * The minimum height of the shape.
   */
  protected minHeight = 256;

  /**
   * The minimum width of the shape.
   */
  protected minWidth = 320;

  // *********************************************
  // Override methods
  // *********************************************/
  /**
   * @inheritdoc
   */
  override canScroll = (shape: IInlineFrameShape) => {
    return shape.props.canScroll;
  };

  /**
   * @inheritdoc
   */
  override canBind = (shape: IInlineFrameShape) => true;

  /**
   * @inheritdoc
   */
  override canEdit = (shape: IInlineFrameShape) => true;

  /**
   * @inheritdoc
   */
  override canResize = (shape: IInlineFrameShape) => true;

  /**
   * @inheritdoc
   */
  override isAspectRatioLocked = (shape: IInlineFrameShape) => false;

  /**
   * @inheritdoc
   */
  override getDefaultProps(): IInlineFrameShape["props"] {
    return getInlineFrameShapeDefaultProps();
  }

  /**
   * @inheritdoc
   */
  component(shape: IInlineFrameShape) {
    const { id, props } = shape;
    const tldrawEditor = this.editor;

    const contentRef = React.useRef<HTMLDivElement | null>(null);

    const isEditing = useValue("isEditing", () => tldrawEditor.getCurrentPageState().editingShapeId === id, [tldrawEditor, id]);
    const isInteracting = useIsInteracting(id);

    const { handleInputPointerDown } = useShapeEvents(shape.id);

    return (
      <HTMLContainer
        id={shape.id}
        style={{
          background: "#fff",
          border: `1px solid ${isEditing ? "transparent" : "black"}`,
          display: "flex",
          pointerEvents: "all",
          justifyContent: "center",
        }}
      >
        <div
          ref={contentRef}
          style={{
            display: "flex",
            flex: 1,
            overflow: isEditing || isInteracting ? "hidden auto" : "hidden",
            pointerEvents: isEditing || isInteracting ? "auto" : "none",
          }}
        >
          <iframe src={props.sourceUrl} style={{ width: "100%" }} onPointerDown={handleInputPointerDown} />
        </div>
      </HTMLContainer>
    );
  }
}
