import { IDateRange } from "@bigpi/cookbook";

export enum TopicDiscussionAnalysisFieldsEnum {
  Category = "category",
  EventDate = "eventDate",
  EventDateShortcut = "eventDateShortcut",
  Section = "section",
  Ticker = "ticker",
  Topic = "topic",
  Type = "type",
}

export interface ITopicDiscussionAnalysisFacets {
  [TopicDiscussionAnalysisFieldsEnum.Category]?: Array<string>;
  [TopicDiscussionAnalysisFieldsEnum.EventDate]?: IDateRange;
  [TopicDiscussionAnalysisFieldsEnum.EventDateShortcut]?: string;
  [TopicDiscussionAnalysisFieldsEnum.Section]?: Array<string>;
  [TopicDiscussionAnalysisFieldsEnum.Ticker]?: Array<string>;
  [TopicDiscussionAnalysisFieldsEnum.Topic]?: Array<string>;
  [TopicDiscussionAnalysisFieldsEnum.Type]?: Array<string>;
}
