import resourcesToBackend from "i18next-resources-to-backend";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import i18n, { i18n as i18next } from "i18next";

/**
 * Initialize the i18n library. Used only when the parent application does not provide i18n.
 *
 * @parami i18n The i18n library to use. If not provided, the a new instance of the i18n library will be used.
 */
export async function initializeI18n(i18nInstance?: i18next): Promise<i18next> {
  let result;

  if (!i18nInstance) {
    // Create a new instance and don't call initReactI18next or we'll overwrite the parent application's i18n instance
    result = i18n.createInstance();
    result.use(detector).use(
      resourcesToBackend((language, namespace, callback) => {
        import(`./locales/${language}/${namespace}.json`)
          .then(({ default: resources }) => {
            callback(null, resources);
          })
          .catch((error) => {
            callback(error, null);
          });
      }),
    );

    await result.init({
      // Prefixes the namespace to the returned key when using `lng: "cimode"`
      appendNamespaceToCIMode: true,

      // Set the default namespace
      defaultNS: "editor",

      // Use this language if requested language is not found in our `supportedLngs` list
      fallbackLng: "en",

      interpolation: {
        // Not needed for React as it escapes by default
        escapeValue: false,
      },

      // Allows "en-US" and "en-UK" to be implcitly supported when "en" is supported
      nonExplicitSupportedLngs: false,

      // Set the available namespaces
      ns: ["editor"],

      parseMissingKeyHandler: (_key, defaultValue) => defaultValue ?? null,

      // Languages that can be loaded
      supportedLngs: ["en"],
    });
  } else {
    // Parent wants to overwrite/is responsible all translations
    result = i18nInstance;
  }

  return result;
}
