import { useContext } from "react";

import { BoardSearchExecutorContext } from "./BoardSearchExecutorContext";

export const useBoardSearchExecutor = () => {
  const result = useContext(BoardSearchExecutorContext);
  if (!result) {
    throw new Error("No BoardSearchExecutor found in context");
  }
  return result;
};
