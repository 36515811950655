import { Box, Checkbox, FormControlLabel } from "@mui/material";
import { useEffect, useState, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { BundleInputForm } from "Components/Bundle/BundleInputForm";
import { FilesUploadDialog } from "Components/Upload/FilesUploadDialog";
import { useLibraryFilesUploadBatch } from "./Hooks/useLibraryFilesUploadBatch";
import { BundleType, Bundle, useCreateBundleMutation } from "GraphQL/Generated/Apollo";

// *********************************************
// Types/Interfaces/Constants
// *********************************************/
export interface IFileWithId {
  id: string;
  file: File;
  failureReason?: string;
}

interface ILibraryUploadDialogProps {
  onClose: () => void;
  onRemoveFile: (id: string) => void;
  selectedFiles: Array<IFileWithId>;
}

// *********************************************
// Component
// *********************************************/
export function LibraryUploadDialog(props: ILibraryUploadDialogProps) {
  const { onClose, onRemoveFile, selectedFiles } = props;

  const { t } = useTranslation();
  const [createBundle] = useCreateBundleMutation();

  // State
  const [isBundle, setIsBundle] = useState<boolean>(false);
  const [bundleDetails, setBundleDetails] = useState<Partial<Bundle>>({ name: "", type: BundleType.Input });
  const [bundleId, setBundleId] = useState<string>();

  // Ref
  const selectedFilesRef = useRef<Array<IFileWithId>>([]);

  const { abortUpload, filesStatusMap, uploadFiles, progressingFiles } = useLibraryFilesUploadBatch();

  useEffect(() => {
    selectedFilesRef.current = selectedFiles;
  }, [selectedFiles]);

  // Create bundle callback
  const createBundleCallback = useCallback(async () => {
    if (bundleDetails.name && bundleDetails.type) {
      const response = await createBundle({
        variables: {
          input: {
            name: bundleDetails.name,
            type: bundleDetails.type as BundleType,
          },
        },
      });
      setBundleId(response.data?.createBundle?.id);

      uploadFiles(selectedFilesRef.current, response.data?.createBundle?.id);
    } else {
      toast.error(t("Components.LibraryUploadDialog.Errors.BundleDetailsRequired"));
    }
  }, [isBundle, bundleDetails, uploadFiles]);

  // Callback to retry uploading a file. This happens when a file is cancelled or failed
  const onRetryFile = useCallback(
    (file: IFileWithId) => {
      uploadFiles([file], bundleId);
    },
    [uploadFiles, bundleId],
  );

  return (
    <FilesUploadDialog
      files={selectedFiles}
      filesStatusMap={filesStatusMap}
      onAbort={abortUpload}
      onClose={onClose}
      onUpload={isBundle ? createBundleCallback : () => uploadFiles(selectedFilesRef.current)}
      onRemove={onRemoveFile}
      onRetry={onRetryFile}
      progressingFiles={progressingFiles}
      title={t("Components.LibraryUploadDialog.Title")}
    >
      <Box>
        <FormControlLabel
          label={t("Components.LibraryUploadDialog.UploadAsBundle")}
          control={<Checkbox checked={isBundle} onChange={(_, checked) => setIsBundle(checked)} disabled={!!bundleId} />}
        />
        {isBundle && <BundleInputForm bundleDetails={bundleDetails} onChangeBundleDetails={setBundleDetails} />}
      </Box>
    </FilesUploadDialog>
  );
}
