import { StateNode, TLEventHandlers } from "@tldraw/tldraw";

import { getHitShapeOnCanvasPointerDown } from "../Utils/getHitShapeOnCanvasPointerDown";

export class PointingShape extends StateNode {
  static override id = "pointing_shape";

  override onCancel: TLEventHandlers["onCancel"] = () => {
    this._cancel();
  };

  override onComplete: TLEventHandlers["onComplete"] = () => {
    this._cancel();
  };

  override onInterrupt = () => {
    this._cancel();
  };

  override onPointerUp: TLEventHandlers["onPointerUp"] = (info) => {
    const hitShape = getHitShapeOnCanvasPointerDown(this.editor);
    // Zoom to the shape bounds if Ctrl/ Cmd is pressed and a shape is hit
    //
    // this.editor.inputs.ctrlKey = e.metaKey || e.ctrlKey, based on the following code:
    // https://github.com/tldraw/tldraw/blob/b3a1db90ece907bae572158e174f0c1d9c27234f/packages/tldraw/src/lib/ui/hooks/useKeyboardShortcuts.ts#L115
    if (this.editor.inputs.ctrlKey && hitShape) {
      const bounds = this.editor.getShapePageBounds(hitShape);
      if (bounds) {
        this.editor.zoomToBounds(bounds, {
          targetZoom: 1,
          duration: 1000,
        });
      }
    }

    this.parent.transition("idle", info);
  };

  /***************************
   * Private methods
   ***************************/
  private _cancel() {
    this.parent.transition("idle", {});
  }
}
