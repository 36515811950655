import { TLShape, useIsToolSelected, useTools } from "@tldraw/tldraw";
import React from "react";

import { InteractTool } from "./Tools";

export function useShapeEvents<T extends TLShape>(id: T["id"]) {
  const handleInputPointerDown = React.useCallback(
    (e: React.PointerEvent) => {
      e.stopPropagation();
    },
    [id],
  );

  const handleInputPointerDownForInteractTool = React.useCallback(
    (e: React.PointerEvent) => {
      // this.editor.inputs.ctrlKey = e.metaKey || e.ctrlKey, based on the following code:
      // https://github.com/tldraw/tldraw/blob/b3a1db90ece907bae572158e174f0c1d9c27234f/packages/tldraw/src/lib/ui/hooks/useKeyboardShortcuts.ts#L115
      const tldrawCtrlKey = e.metaKey || e.ctrlKey;

      // Stop event propagation if interact tool is selected and Ctrl/ Cmd is not pressed
      if (!tldrawCtrlKey) {
        e.stopPropagation();
      }
    },
    [id],
  );

  const tools = useTools();
  const isInteractToolSelected = useIsToolSelected(tools[InteractTool.id]);

  return {
    handleInputPointerDown: isInteractToolSelected ? handleInputPointerDownForInteractTool : handleInputPointerDown,
  };
}
