export enum OrderedListStyleClassTypes {
  DECIMAL = "list-style-type-decimal",
  ALPHA = "list-style-type-lower-alpha",
  ROMAN = "list-style-type-lower-roman",
}

export enum BulletListStyleClassTypes {
  DISC = "list-style-type-disc",
  CIRCLE = "list-style-type-circle",
  SQUARE = "list-style-type-square",
}

export const ORDERED_LIST_STYLE_CLASS_ORDER = [
  OrderedListStyleClassTypes.DECIMAL,
  OrderedListStyleClassTypes.ALPHA,
  OrderedListStyleClassTypes.ROMAN,
];

export const BULLET_LIST_STYLE_CLASS_ORDER = [
  BulletListStyleClassTypes.DISC,
  BulletListStyleClassTypes.CIRCLE,
  BulletListStyleClassTypes.SQUARE,
];
