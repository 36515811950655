import { useApolloClient } from "@apollo/client";
import { FileUploadStatus } from "@bigpi/cookbook";
import { useCallback, useEffect, useState } from "react";

import { useUploadFilesBatch } from "Components/Upload/Hooks/useUploadFilesBatch";
import { useUploadWorkspaceFilesMutation } from "GraphQL/Generated/Apollo";
import { WORKSPACE_FILE_UPLOAD_SUBSCRIPTION } from "GraphQL/Upload/Subscription";
import { FileStatus, IFileWithId } from "Components/Upload/FilesList";

export function useWorkspaceFilesUploadBatch(workspaceId: string) {
  const [filesStatusMap, setFilesStatusMap] = useState<Record<string, FileStatus>>({});
  const [progressingFiles, setProgressingFiles] = useState<Record<string, number>>({});
  const client = useApolloClient();

  const onUpdateFilesStatus = useCallback((filesToUpdate: Record<string, FileStatus>) => {
    setFilesStatusMap((prev) => {
      return { ...prev, ...filesToUpdate };
    });
  }, []);

  useEffect(() => {
    // Subscribe to the uploading progress of the files
    const clientSubscribe = client
      .subscribe({ query: WORKSPACE_FILE_UPLOAD_SUBSCRIPTION, variables: { workspaceId } })
      .subscribe({
        next: (response) => {
          const addFileResponse = response.data.onWorkspaceFileAdded;
          if (addFileResponse) {
            const fileId = addFileResponse.id;
            if (addFileResponse.uploadStatus === FileUploadStatus.Running) {
              setProgressingFiles({
                [fileId]: addFileResponse.uploadedSize / addFileResponse.totalSize,
              });
            } else if (addFileResponse.uploadStatus === FileUploadStatus.Success) {
              onUpdateFilesStatus({ [fileId]: FileStatus.Success });
            } else if (addFileResponse.uploadStatus === FileUploadStatus.Failure) {
              // TODO: should we set the fail status
              onUpdateFilesStatus({ [fileId]: FileStatus.Failed });
            }
          }
        },
      });

    return () => {
      clientSubscribe.unsubscribe();
    };
  }, [workspaceId]);

  const [uploadWorkspaceFiles] = useUploadWorkspaceFilesMutation();
  const { abortUpload, uploadFilesBatch } = useUploadFilesBatch(
    onUpdateFilesStatus,
    (variables) => uploadWorkspaceFiles(variables),
    ["WorkspaceFiles", "WorkspaceFileAggregate"],
  );

  // Upload files in batch
  const uploadFiles = useCallback(
    async (files: Array<IFileWithId>) => {
      let currentSize = 0;
      const batchSize = 5;
      let batchFiles = files.slice(currentSize, batchSize);
      while (batchFiles.length > 0) {
        await uploadFilesBatch(batchFiles, { workspaceId });
        currentSize += batchSize;
        batchFiles = files.slice(currentSize, currentSize + batchSize);
      }
    },
    [uploadFilesBatch, workspaceId],
  );

  return {
    abortUpload,
    filesStatusMap,
    progressingFiles,
    uploadFiles,
  };
}
