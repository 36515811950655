import { TableRow as TipTapTableRow } from "@tiptap/extension-table-row";

import { getStyleAttribute } from "../../Utils/ExtensionUtil.js";

export const TableRow = TipTapTableRow.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      style: getStyleAttribute(),
    };
  },
});
