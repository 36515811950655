import { Box, Editor, TLShape, isShape, TLShapePartial, TLShapeProps, Vec, TLUnknownShape } from "@tldraw/tldraw";

const DEFAULT_MARGIN = 50;
const FOCUS_OFFSET = 100;

/**
 * Creates the given shapes at the first empty spot on the board near the given source.
 *
 * @param editor App instance from tldraw
 * @param shapesToInsert Shapes to be inserted
 * @param source Source shape/point/box/null to be used as reference. When null it checks for allShapesCommonBounds
 * @param select Select the inserted shapes
 * @param direction Direction of insertion
 * @param margin Margin to be added to the point
 */
export function createShapesAtEmptyPoint<T extends TLUnknownShape>(
  editor: Editor,
  shapesToInsert: Array<TLShapePartial<T>>,
  source: TLShape | Vec | Box | null,
  select: true | false = false,
  direction: "horizontal" | "vertical" = "horizontal",
  margin: number = DEFAULT_MARGIN,
  autoFocus: true | false = true,
) {
  // Get a free position
  let position = getEmptyPoint(editor, shapesToInsert[0], source, direction, margin);

  // Update all the shapes with new positions, so that they are placed next to each other
  shapesToInsert.forEach((shape) => {
    shape.x = position.x;
    shape.y = position.y;
    const possiblePoint = getPointFromBox(position, direction, margin);
    position = new Box(possiblePoint.x, possiblePoint.y);
  });

  editor.createShapes(shapesToInsert);
  if (select) {
    editor.setSelectedShapes(shapesToInsert.map((shape) => shape.id));
  }

  if (autoFocus) {
    editor.setCamera(
      {
        x: -(shapesToInsert[shapesToInsert.length - 1].x || 0) + FOCUS_OFFSET,
        y: -(shapesToInsert[shapesToInsert.length - 1].y || 0) + FOCUS_OFFSET,
        z: editor.getZoomLevel(),
      },
      { duration: 2000 },
    );
  }
}

export function getEmptyPoint<T extends TLUnknownShape>(
  editor: Editor,
  shape: TLShapePartial<T>,
  source: TLShape | Vec | Box | null,
  direction: "horizontal" | "vertical" = "horizontal",
  margin: number = DEFAULT_MARGIN,
) {
  let commonBox = new Box();
  const commonBoundsOfAllShapesOnCurrentPage = editor.getCurrentPageBounds();

  if (!source) {
    commonBox = commonBoundsOfAllShapesOnCurrentPage || new Box();
  } else if (source instanceof Vec) {
    commonBox = new Box(source.x, source.y);
  } else if (source instanceof Box) {
    commonBox = source;
  } else if (isShape(source)) {
    commonBox = editor.getShapeGeometry(source).getBounds();
  }

  // Narrow down to a point based on direction
  const possiblePoint = getPointFromBox(commonBox, direction, margin);
  commonBox = new Box(possiblePoint.x, possiblePoint.y);

  const shapeProps = shape.props as Partial<TLShapeProps>;
  const shapeBox = { w: shapeProps?.w || 0, h: shapeProps?.h || 0 };

  return findValidBoxPoint(editor, commonBox.clone(), shapeBox, direction, margin);
}

/**
 * Gives the right point from the box
 *
 * @param box Find the point from this box
 * @param direction Direction of the point
 * @param margin Margin to be added to the point
 * @returns Vec Point from the box
 */
function getPointFromBox(box: Box, direction: "horizontal" | "vertical", margin: number): Vec {
  return direction === "horizontal"
    ? box.getHandlePoint("top_right").add({ x: margin, y: 0 })
    : box.getHandlePoint("bottom_left").add({ x: 0, y: margin });
}

/**
 * Returns the shapes that collides with the given box
 *
 * @param tldrawEditor Editor instance from tldraw
 * @param box Box instance to be used to find the colliding shapes
 * @returns Array of shapes that collides with the given box
 */
function getBoxCollideShapes(tldrawEditor: Editor, box: Box) {
  const allShapes = tldrawEditor.getCurrentPageShapes();
  return allShapes.filter((shape) => {
    return box.includes(tldrawEditor.getShapePageBounds(shape) || new Box());
  });
}

/**
 * Expands the box with the given shapes bounds
 *
 * @param tldrawEditor Editor instance from tldraw
 * @param box Box instance
 * @param shapes Shapes to be used to expand the box
 * @returns Box Expanded box
 */
function expandBoxToGivenShapes(tldrawEditor: Editor, box: Box, shapes: Array<TLShape>) {
  shapes.forEach((shape) => {
    box.expand(tldrawEditor.getShapePageBounds(shape) || new Box());
  });
  return box;
}

/**
 * Self iterative function to find a valid point for the shape
 *
 * @param tldrawEditor Editor instance from tldraw
 * @param boxPoint Box instance
 * @param shape Shape to be inserted
 * @param direction Direction of insertion
 * @returns Box
 */
function findValidBoxPoint(
  tldrawEditor: Editor,
  boxPoint: Box,
  shapeProps: { w: number; h: number },
  direction: "horizontal" | "vertical",
  margin: number,
): Box {
  // Expands the point to the shape measurements
  boxPoint.width = shapeProps?.w || 0;
  boxPoint.height = shapeProps?.h || 0;

  // Verify if the new box collides with any existing shapes
  const collideShapes = getBoxCollideShapes(tldrawEditor, boxPoint);

  if (collideShapes.length > 0) {
    // Expands the box to the colliding shapes
    expandBoxToGivenShapes(tldrawEditor, boxPoint, collideShapes);

    // Get the new point from the expanded box
    const newBoxPoint = getPointFromBox(boxPoint, direction, margin);
    const newPoint = new Box(newBoxPoint.x, newBoxPoint.y);
    return findValidBoxPoint(tldrawEditor, newPoint, shapeProps, direction, margin);
  }

  return boxPoint;
}

/**
 * 1. Finds the empty point in the viewport vertically or horizontally
 * 2. If no empty point is found, it will be inserted at the center of the viewport
 *
 * @param tldrawEditor Tldraw editor instance
 * @param shape Shape to be inserted
 * @param select Select the shape after insertion
 * @param margin Margin to be added to the shape
 * @param autoFocus Auto focus the shape after insertion
 * @returns
 */
export function createShapesAtEmptyPointInViewport(
  tldrawEditor: Editor,
  shape: TLShapePartial,
  select: true | false = false,
  margin: number = DEFAULT_MARGIN,
  autoFocus: true | false = false,
) {
  const viewPortBox = tldrawEditor.getViewportPageBounds();

  const shapeProps = shape.props as Partial<TLShapeProps>;
  const shapeBox = { w: (shapeProps?.w || 0) / 2, h: (shapeProps?.h || 0) / 2 };

  // Narrow down to a point based on direction
  const commonBox = new Box(viewPortBox.x, viewPortBox.y);
  let insertPoint;
  const horizontalValidPoint = findValidBoxPoint(tldrawEditor, commonBox.clone(), shapeBox, "horizontal", margin);
  const verticalValidPoint = findValidBoxPoint(tldrawEditor, commonBox.clone(), shapeBox, "vertical", margin);
  if (viewPortBox.includes(verticalValidPoint)) {
    insertPoint = verticalValidPoint;
  } else if (viewPortBox.includes(horizontalValidPoint)) {
    insertPoint = horizontalValidPoint;
  } else {
    const viewportPageCenter = tldrawEditor.getViewportPageCenter();
    insertPoint = {
      x: viewportPageCenter.x,
      y: viewportPageCenter.y,
    };
  }

  shape.x = insertPoint.x;
  shape.y = insertPoint.y;
  tldrawEditor.createShape(shape);
  if (select) {
    tldrawEditor.setSelectedShapes([shape.id]);
  }

  if (autoFocus) {
    tldrawEditor.setCamera(
      { x: -(shape.x || 0) + FOCUS_OFFSET, y: -(shape.y || 0) + FOCUS_OFFSET, z: tldrawEditor.getZoomLevel() },
      { duration: 2000 },
    );
  }
  return;
}

/**
 * Finds the empty point in the viewport and inserts the shapes
 * Creates empty boxes from viewport
 * Reference: https://stackoverflow.com/questions/45681299/algorithm-locating-enough-space-to-draw-a-rectangle-given-the-x-and-y-axis-of
 *
 * @param tldrawEditor Tldraw editor instance
 * @param shapesToCreate Shapes to be inserted
 */
export function insertShapesAtViewportEmptyPoint(tldrawEditor: Editor, shapesToCreate: Array<TLShapePartial>) {
  const viewPortBox = tldrawEditor.getViewportPageBounds();
  const shapesCommonBox = new Box();
  shapesToCreate.forEach((shape) => {
    if (shape.props) {
      const shapeProps = shape.props as Partial<TLShapeProps>;
      const shapeBox = new Box(shape.x, shape.y, shapeProps.w || 0, shapeProps.h || 0);
      shapesCommonBox.expand(shapeBox);
    }
  });

  const spaceBoxes: Array<any> = [
    {
      ...viewPortBox,
      w: viewPortBox.w + shapesCommonBox.w / 2,
      h: viewPortBox.h + shapesCommonBox.h / 2,
    },
  ];
  const boxes: Array<any> = [];
  const space = 2;
  const minW = 4; // min width and height of boxes
  const minH = 4;

  function cutBox(box: Box, cutter: Box) {
    const b = [];
    // cut left
    if (cutter.x - box.x - space > minW) {
      b.push({
        x: box.x,
        y: box.y,
        w: cutter.x - box.x - space,
        h: box.h,
      });
    }
    // cut top
    if (cutter.y - box.y - space > minH) {
      b.push({
        x: box.x,
        y: box.y,
        w: box.w,
        h: cutter.y - box.y - space,
      });
    }
    // cut right
    if (box.x + box.w - (cutter.x + cutter.w + space) > space + minW) {
      b.push({
        x: cutter.x + cutter.w + space,
        y: box.y,
        w: box.x + box.w - (cutter.x + cutter.w + space),
        h: box.h,
      });
    }
    // cut bottom
    if (box.y + box.h - (cutter.y + cutter.h + space) > space + minH) {
      b.push({
        x: box.x,
        y: cutter.y + cutter.h + space,
        w: box.w,
        h: box.y + box.h - (cutter.y + cutter.h + space),
      });
    }
    return b;
  }

  function findBestFitBox(newBox: Box, checkPosition: boolean = false) {
    let smallest = Infinity;
    let boxFound;
    spaceBoxes.forEach((sbox: Box, index: number) => {
      if (sbox.w > newBox.w && sbox.h > newBox.h) {
        if (checkPosition ? sbox.x <= newBox.x && sbox.y <= newBox.y : true) {
          let area = sbox.w * sbox.h;
          if (area < smallest) {
            smallest = area;
            boxFound = index;
          }
        }
      }
    });
    return boxFound;
  }

  function locateSpace(newBox: Box) {
    if (boxes.length === 0) {
      // first box can go in top left
      boxes.push(newBox);
      let spaceBox = spaceBoxes.pop();
      spaceBoxes.push(...cutBox(spaceBox, newBox));
    } else {
      let bestFit = findBestFitBox(newBox, true); // get the best fit space
      if (bestFit !== undefined) {
        let spaceBox = spaceBoxes.splice(bestFit, 1)[0]; // remove the best fit spacer
        spaceBoxes.push(...cutBox(spaceBox, newBox)); // slice the spacer box and add slices back to spacer array
        boxes.push(newBox); // add the box
        let tb = getTouching(newBox); // find all touching spacer boxes
        while (tb.length > 0) {
          // and slice them if needed
          cutBox(tb.pop(), newBox).forEach((b: any) => addSpacerBox(b));
        }
      }
    }
  }

  function addSpacerBox(newBox: Box) {
    let dontAdd = false;
    // is to small?
    if (newBox.w < minW || newBox.h < minH) {
      return;
    }
    // is same or inside another
    spaceBoxes.forEach((sbox: Box) => {
      if (
        newBox.x >= sbox.x &&
        newBox.x + newBox.w <= sbox.x + sbox.w &&
        newBox.y >= sbox.y &&
        newBox.y + newBox.h <= sbox.y + sbox.h
      ) {
        dontAdd = true;
        return true;
      }
    });
    if (!dontAdd) {
      let join = false;
      // check if it can be joinded with another
      spaceBoxes.forEach((sbox: Box) => {
        if (newBox.x === sbox.x && newBox.w === sbox.w && !(newBox.y > sbox.y + sbox.h || newBox.y + newBox.h < sbox.y)) {
          join = true;
          let y = Math.min(sbox.y, newBox.y);
          let h = Math.max(sbox.y + sbox.h, newBox.y + newBox.h);
          sbox.y = y;
          sbox.h = h - y;
          return true;
        }
        if (newBox.y === sbox.y && newBox.h === sbox.h && !(newBox.x > sbox.x + sbox.w || newBox.x + newBox.w < sbox.x)) {
          join = true;
          let x = Math.min(sbox.x, newBox.x);
          let w = Math.max(sbox.x + sbox.w, newBox.x + newBox.w);
          sbox.x = x;
          sbox.w = w - x;
          return true;
        }
      });
      if (!join) {
        spaceBoxes.push(newBox);
      } // add to spacer array
    }
  }

  function getTouching(newBox: Box) {
    const b = [];
    for (let i = 0; i < spaceBoxes.length; i++) {
      let sbox = spaceBoxes[i];
      if (
        !(
          sbox.x > newBox.x + newBox.w + space ||
          sbox.x + sbox.w < newBox.x - space ||
          sbox.y > newBox.y + newBox.h + space ||
          sbox.y + sbox.h < newBox.y - space
        )
      ) {
        b.push(spaceBoxes.splice(i--, 1)[0]);
      }
    }
    return b;
  }

  const viewPortShapes = tldrawEditor.getCurrentPageShapesSorted().filter((shape) => {
    if (!shape.parentId.includes("page:")) {
      return false;
    }
    const bounds = tldrawEditor.getShapePageBounds(shape);
    return bounds && viewPortBox.includes(bounds);
  });

  const allBoxes = viewPortShapes.map((shape) => tldrawEditor.getShapePageBounds(shape));
  allBoxes.push(shapesCommonBox);
  allBoxes.forEach((box) => {
    if (box) {
      locateSpace(box);
    }
  });

  const emptyBoxIndex = findBestFitBox(shapesCommonBox, false);
  const viewportPageCenter = tldrawEditor.getViewportPageCenter();
  if (emptyBoxIndex) {
    const emptyBox = spaceBoxes[emptyBoxIndex];

    let touchesAnyExistingShapes = false;

    allBoxes.forEach((box) => {
      if (box?.contains(emptyBox)) {
        touchesAnyExistingShapes = true;
      }
    });

    if (touchesAnyExistingShapes) {
      shapesToCreate.forEach((shape: TLShapePartial) => {
        shape.x = (shape.x || 0) + viewportPageCenter.x;
        shape.y = (shape.y || 0) + viewportPageCenter.y;
      });
    } else {
      shapesToCreate.forEach((shape) => {
        shape.x = (shape.x || 0) + emptyBox.x;
        shape.y = (shape.y || 0) + emptyBox.y;
      });
    }
  } else {
    shapesToCreate.forEach((shape: TLShapePartial) => {
      shape.x = (shape.x || 0) + viewportPageCenter.x;
      shape.y = (shape.y || 0) + viewportPageCenter.y;
    });
  }

  // Creates shapes at empty space
  tldrawEditor.createShapes(shapesToCreate);

  tldrawEditor.setCamera(
    {
      x: -(shapesToCreate[shapesToCreate.length - 1].x || 0) + FOCUS_OFFSET,
      y: -(shapesToCreate[shapesToCreate.length - 1].y || 0) + FOCUS_OFFSET,
      z: tldrawEditor.getZoomLevel(),
    },
    { duration: 2000 },
  );
}
