import { gql } from "@apollo/client";

export const UPDATE_TOPIC_DISCUSSION_EXAMPLE = gql`
  mutation UpdateTopicDiscussionExample($input: UpdateTopicDiscussionExampleInput!) {
    updateTopicDiscussionExample(input: $input) {
      id
      topic
      question
      detail
      turn
      event
      eventType
      ticker
      category
      eventDate
      speakers {
        fullName
        role
      }
      segmentId
      section
      rating
      score
      explanation
      summary
      firm
      reportTitle
      type
    }
  }
`;
