import { ICommand, ICommandRequest, IPlugIn, PlugInManager } from "@bigpi/cookbook";
import type { Editor as TldrawEditor } from "@tldraw/tldraw";
import { TFunction } from "i18next";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ICommandResponse } from "GraphQL/Generated/Requests";
import { createCommandResponsePlugIns } from "./ResponsePlugIns";
import { useCommandExecutor } from "../useCommandExecutor";
import { createCommandRequestPlugIns } from "./RequestPlugIns";
import { OnCommandRequestEventArgs, OnCommandResponseEventArgs } from "../CommandExecutor";
import { BoardSearchExecutor } from "BoardComponents/BoardSearchManager/BoardSearchExecutor";
import { useBoardSearchExecutor } from "BoardComponents/BoardSearchManager/useBoardSearchExecutor";

export interface ICommandRequestPlugInInputs {
  app?: TldrawEditor;
  command: ICommand;
  commandRequest: ICommandRequest;
  t: TFunction;
}

export interface ICommandResponsePlugInInputs {
  app?: TldrawEditor;
  boardSearchExecutor: BoardSearchExecutor;
  commandResponse: ICommandResponse;
  onClose: () => void;
  t: TFunction;
}

/**
 * Props for the WorkspaceBoardCommandManager component.
 */
export interface IWorkspaceBoardCommandManagerProps {
  /**
   * The Tldraw app instance to add content to
   */
  app?: TldrawEditor;

  /**
   * The active workspace board ID.
   */
  workspaceBoardId: string;
}

/**
 * Handles command responses related to workspace boards.
 *
 * @param props The component props.
 *
 * @returns Component that may render children for certain commands.
 */
export function WorkspaceBoardCommandManager(props: IWorkspaceBoardCommandManagerProps) {
  const { app, workspaceBoardId } = props;
  const boardSearchExecutor = useBoardSearchExecutor();
  const commandExecutor = useCommandExecutor();
  const { t } = useTranslation();
  const [plugInChildren, setPlugInChildren] = useState<JSX.Element | undefined>(undefined);

  // Create and initialize plug-in manager to handle WorkspaceBoard supported command request (augmentations)
  const requestPlugInManager = new PlugInManager<ICommandRequestPlugInInputs, void, IPlugIn<ICommandRequestPlugInInputs, void>>();
  requestPlugInManager.registerPlugIns(createCommandRequestPlugIns());

  // Create and initialize plug-in manager to handle WorkspaceBoard supported command responses
  const responsePlugInManager = new PlugInManager<
    ICommandResponsePlugInInputs,
    undefined | JSX.Element,
    IPlugIn<ICommandResponsePlugInInputs, undefined | JSX.Element>
  >();
  responsePlugInManager.registerPlugIns(createCommandResponsePlugIns());

  const onPlugInChildCompleted = useCallback(() => {
    setPlugInChildren(undefined);
  }, []);

  const onBeforeCommandRequest = useCallback(
    async (event: OnCommandRequestEventArgs) => {
      const { command, commandRequest } = event;

      // Execute the plug-in that handles the command request
      await requestPlugInManager.executeIfRegistered(command.action.actionType, {
        app,
        command,
        commandRequest,
        t,
      });
    },
    [app],
  );

  const onCommandResponse = useCallback(
    async (event: OnCommandResponseEventArgs) => {
      const { commandResponse } = event;

      // Make sure the command response is targetting the current workspace board
      if (workspaceBoardId !== commandResponse.activeCommandContext.workspaceBoardId) {
        return;
      }

      // Execute the plug-in that handles the command response
      const callerData = JSON.parse(commandResponse.callerData || "{}");

      // Only call the plug-in if the command response has an output type
      if (callerData.outputType) {
        const result = await responsePlugInManager.executeIfRegistered(callerData.outputType, {
          app,
          boardSearchExecutor,
          commandResponse,
          onClose: onPlugInChildCompleted,
          t,
        });

        setPlugInChildren(result);
      }
    },
    [app],
  );

  useEffect(() => {
    commandExecutor.on("beforeCommandRequest", onBeforeCommandRequest);

    return () => {
      commandExecutor.off("beforeCommandRequest", onBeforeCommandRequest);
    };
  }, [commandExecutor, onBeforeCommandRequest]);

  useEffect(() => {
    commandExecutor.on("commandResponse", onCommandResponse);

    return () => {
      commandExecutor.off("commandResponse", onCommandResponse);
    };
  }, [commandExecutor, onCommandResponse]);

  return <>{plugInChildren}</>;
}
