import { Divider } from "@mui/material";
import type { Editor } from "@tiptap/react";
import React from "react";

import type { IEditorConfig } from "../../Editor/index.js";
import { ToolbarItemBase } from "../../Toolbars/ToolbarItemBase.js";

const ITEM_NAME = "|";

/**
 * A toolbar button divider without any editor functionality.
 */
export class DividerItem extends ToolbarItemBase {
  // *********************************************
  // Public properties
  // *********************************************/
  /**
   * @inheritdoc
   */
  readonly name = ITEM_NAME;

  // *********************************************
  // Public methods
  // *********************************************/
  /**
   * @inheritdoc
   */
  create(editor: Editor, config: IEditorConfig) {
    return <DividerButton key={ITEM_NAME} />;
  }
}

/**
 * A toolbar divider.
 */
export const DividerButton = () => {
  return <Divider orientation="vertical" sx={{ display: "inline", minHeight: "24px", margin: "0 1px" }} />;
};
