import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useDeleteFileMutation, useFileQuery } from "GraphQL/Generated/Apollo";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { RoutePaths } from "RoutePaths";
import { DefaultDialogTransition } from "Components/DialogTransition/DefaultDialogTransition";

export interface IDeleteFileDialogProps {
  onClose: () => void;
  open: boolean;
  fileId: string;
}

export function DeleteFileDialog(props: IDeleteFileDialogProps) {
  const { open, fileId } = props;
  const { t } = useTranslation();
  const [deleteFile] = useDeleteFileMutation();
  const { data, loading, error } = useFileQuery({ variables: { id: fileId } });
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = React.useState("");

  function onClose() {
    setErrorMessage("");
    props.onClose();
  }

  function onDelete(): void {
    deleteFile({
      variables: {
        input: {
          id: fileId,
        },
      },
      refetchQueries: ["Files", "FileBundleView"],
    })
      .then((result) => {
        const resultData = result.data!.deleteFile;
        if (resultData) {
          navigate(RoutePaths.library.path);
        }
      })
      .catch((error) => {
        console.error(error);
        setErrorMessage(t("Components.DeleteFileDialog.Error"));
      });
  }

  return (
    <>
      <Dialog open={open} onClose={onClose} TransitionComponent={DefaultDialogTransition} fullWidth maxWidth="xs">
        <DialogTitle>{t("Components.DeleteFileDialog.Title", { name: data?.file?.name || "" })}</DialogTitle>
        <DialogContent>
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
          <DialogContentText>{t("Components.DeleteFileDialog.Tip")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{t("Global.Action.Close")}</Button>
          <Button onClick={onDelete} color="danger">
            {t("Global.Action.DeletePermanently")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
