import { Box, Button, CircularProgress, Fade, Paper, Typography } from "@mui/material";
import { useValue } from "@tldraw/tldraw";
import html2canvas from "html2canvas";
import { useRef, useState } from "react";
import { createPortal } from "react-dom";
import Draggable from "react-draggable";
import { useTranslation } from "react-i18next";

import { COMPANION_OVERLAY_ITEM_CLASS } from "BoardComponents/Companion/CompanionWindow";
import { useUpdateWorkspaceBoardMutation } from "GraphQL/Generated/Apollo";
import { getCanvasToImageBase64 } from "Utils/DomToImageUtils";
import { useCollaborativeBoardEditor } from "TldrawBoard/useCollaborativeBoardEditor";
import "./ThumbnailFrameOverlay.css";

const DEFAULT_WIDTH = 800;
const DEFAULT_HEIGHT = 600;
const DEFAULT_BORDER_WIDTH = 4;
const OVERLAY_PARENT_QUERY_SELECTOR = ".tl-overlays .tl-html-layer";
const THUMBNAIL_CAPTURE_QUERY_SELECTOR = ".tl-html-layer.tl-shapes";
const THUMBNAIL_FRAME_OVERLAY_ITEM_CLASS = "thumbnail-frame-overlay__item";

export interface ThumbnailOverlayProps {
  onClose: () => void;
  boardId: string;
}

export const ThumbnailFrameOverlay = (props: ThumbnailOverlayProps) => {
  const { onClose, boardId } = props;
  const editor = useCollaborativeBoardEditor();
  const { t } = useTranslation();

  const [updateWorkspaceBoard] = useUpdateWorkspaceBoardMutation();
  const [updating, setUpdating] = useState(false);

  const zoomLevel = useValue("zoom", () => editor?.getZoomLevel() || 1, [editor]);
  const windowDimensions = useValue(
    "windowDimensions",
    () => {
      let width = DEFAULT_WIDTH;
      let height = DEFAULT_HEIGHT;
      if (editor?.getViewportPageBounds()) {
        width = editor.getViewportPageBounds().width * 0.6;
        height = (width * 3) / 4;
      }

      return { width, height };
    },
    [editor, zoomLevel],
  );
  const windowPosition = useValue(
    "windowPosition",
    () => {
      if (editor) {
        const center = editor.getViewportPageCenter();
        return { x: center.x - windowDimensions.width / 2, y: center.y - windowDimensions.height / 2 };
      } else {
        return { x: 0, y: 0 };
      }
    },
    [editor, windowDimensions],
  );
  const overlayPositions = useValue(
    "overlayPositions",
    () => {
      const pageBounds = editor?.getViewportPageBounds();

      return {
        right: {
          x: windowPosition.x + windowDimensions.width,
          y: windowPosition.y,
          width: pageBounds?.width || 0,
          height: windowDimensions.height,
        },
        left: {
          x: windowPosition.x - (pageBounds?.width || 0),
          y: windowPosition.y,
          width: pageBounds?.width || 0,
          height: windowDimensions.height,
        },
        top: {
          x: pageBounds?.x || 0,
          y: pageBounds?.y || 0,
          width: pageBounds?.width || 0,
          height: windowPosition.y - (pageBounds?.y || 0),
        },
        bottom: {
          x: pageBounds?.x || 0,
          y: (pageBounds?.y || 0) + windowDimensions.height + windowPosition.y - (pageBounds?.y || 0),
          width: pageBounds?.width || 0,
          height: windowPosition.y - (pageBounds?.y || 0),
        },
      };
    },
    [windowPosition, windowDimensions, editor],
  );
  const buttonStyles = useValue(
    "buttonStyles",
    () => ({
      fontSize: `${14 / zoomLevel}px`,
      padding: 1 / zoomLevel,
      minWidth: `${64 / zoomLevel}px`,
      borderRadius: `${4 / zoomLevel}px`,
    }),
    [zoomLevel],
  );

  // The parent element to render the overlay into
  const overlayParent = document.querySelector(OVERLAY_PARENT_QUERY_SELECTOR);

  // Reference to the frame element
  const thumbnailFrameRef = useRef<HTMLDivElement | null>(null);

  // Reference to the overlay elements
  const topOverlayFrameRef = useRef<HTMLDivElement | null>(null);
  const bottomOverlayFrameRef = useRef<HTMLDivElement | null>(null);
  const leftOverlayFrameRef = useRef<HTMLDivElement | null>(null);
  const rightOverlayFrameRef = useRef<HTMLDivElement | null>(null);

  const updateWorkspaceBoardThumbnail = async (thumbnail: string) => {
    try {
      const camera = editor?.getCamera();

      return updateWorkspaceBoard({
        variables: {
          input: {
            id: boardId,
            thumbnail,
            isThumbnailLocked: true,
            zoomLevel,
            cameraPosition: { x: camera?.x || 0, y: camera?.y || 0 },
          },
        },
        refetchQueries: ["Workspaces", "WorkspaceBoards"],
      });
    } catch (error) {
      console.error(error);
    }
  };

  const loadTldrawFonts = async (document: Document) => {
    const loadFont = async (document: Document, fontId: string, fontUrl: string) => {
      try {
        const fontInstance = new FontFace(fontId, `url("${fontUrl}")`, { style: "normal", weight: "500" });
        await fontInstance.load();

        // @ts-expect-error
        document.fonts.add(fontInstance);
      } catch (err) {
        console.error(err);
      }
    };

    await Promise.all([
      loadFont(document, "tldraw_draw", "/fonts/Shantell_Sans-Normal-SemiBold.woff2"),
      loadFont(document, "tldraw_serif", "/fonts/IBMPlexSerif-Medium.woff2"),
      loadFont(document, "tldraw_sans", "/fonts/IBMPlexSans-Medium.woff2"),
      loadFont(document, "tldraw_mono", "/fonts/IBMPlexMono-Medium.woff2"),
    ]);

    await document.fonts.ready;
  };

  const handleCapture = async () => {
    const tlHtmlLayerElement = document.querySelector(THUMBNAIL_CAPTURE_QUERY_SELECTOR)?.cloneNode(true) as HTMLElement;
    const companionOverylayItem = document.querySelector(`.${COMPANION_OVERLAY_ITEM_CLASS}`)?.cloneNode(true) as HTMLElement;
    if (tlHtmlLayerElement) {
      if (companionOverylayItem) {
        // To show the companion overlay on top of all the shapes
        companionOverylayItem.style.zIndex = "10000";
        companionOverylayItem.style.position = "absolute";

        // Append the companion overlay to the tlHtmlLayerElement
        tlHtmlLayerElement.appendChild(companionOverylayItem);
      }

      setUpdating(true);

      // Append the tlHtmlLayerElement to the body to capture the thumbnail
      // with the companion overlay, otherwise the html2canvas library will
      // throw error - https://stackoverflow.com/a/65632648/4941819
      const tempDivElem = document.createElement("div");
      tempDivElem.classList.add("tl-container");
      tempDivElem.style.position = "absolute";
      tempDivElem.style.top = "-10000px";
      tempDivElem.style.left = "-10000px";
      tempDivElem.appendChild(tlHtmlLayerElement);
      document.body.appendChild(tempDivElem);

      const tlHtmlLayerCanvas = await html2canvas(tlHtmlLayerElement, {
        // scale: zoomLevel -> This option doesn't work when zooming in and out in tldraw
        // board so we consider the zoom level when calculating the dimensions and position
        // of the thumbnail frame
        x: windowPosition.x * zoomLevel,
        y: windowPosition.y * zoomLevel,
        width: windowDimensions.width * zoomLevel,
        height: windowDimensions.height * zoomLevel,

        onclone: async (document: Document) => {
          await loadTldrawFonts(document);
        },

        useCORS: true,
      }).finally(() => {
        // Remove the tlHtmlLayerElement from the body
        document.body.removeChild(tempDivElem);
      });

      const thumbnailBase64 = await getCanvasToImageBase64(tlHtmlLayerCanvas);
      if (thumbnailBase64) {
        await updateWorkspaceBoardThumbnail(thumbnailBase64);
        setUpdating(false);
        onClose();
      } else {
        setUpdating(false);
      }
    }
  };

  return (
    <>
      {overlayParent &&
        createPortal(
          <>
            <Draggable
              defaultClassName={`tl-overlays__item ${THUMBNAIL_FRAME_OVERLAY_ITEM_CLASS}`}
              data-testid="thumbnail-frame-overlay"
              handle=".right-overlay-handle"
              nodeRef={rightOverlayFrameRef}
              onDrag={() => {}}
              position={overlayPositions.right}
              scale={zoomLevel}
            >
              <Fade in>
                <Paper
                  ref={rightOverlayFrameRef}
                  square
                  sx={{
                    position: "fixed",
                    width: `${overlayPositions.right.width}px`,
                    height: `${overlayPositions.right.height}px`,
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    border: "none",
                  }}
                ></Paper>
              </Fade>
            </Draggable>
            <Draggable
              defaultClassName={`tl-overlays__item ${THUMBNAIL_FRAME_OVERLAY_ITEM_CLASS}`}
              data-testid="thumbnail-frame-overlay"
              handle=".left-overlay-handle"
              nodeRef={leftOverlayFrameRef}
              onDrag={() => {}}
              position={overlayPositions.left}
              scale={zoomLevel}
            >
              <Fade in>
                <Paper
                  ref={leftOverlayFrameRef}
                  square
                  sx={{
                    position: "fixed",
                    width: `${overlayPositions.left.width}px`,
                    height: `${overlayPositions.left.height}px`,
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    border: "none",
                  }}
                ></Paper>
              </Fade>
            </Draggable>
            <Draggable
              defaultClassName={`tl-overlays__item ${THUMBNAIL_FRAME_OVERLAY_ITEM_CLASS}`}
              data-testid="thumbnail-frame-overlay"
              handle=".top-overlay-handle"
              nodeRef={topOverlayFrameRef}
              onDrag={() => {}}
              position={overlayPositions.top}
              scale={zoomLevel}
            >
              <Fade in>
                <Paper
                  ref={topOverlayFrameRef}
                  square
                  sx={{
                    position: "fixed",
                    width: `${overlayPositions.top.width}px`,
                    height: `${overlayPositions.top.height}px`,
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    border: "none",
                  }}
                ></Paper>
              </Fade>
            </Draggable>
            <Draggable
              defaultClassName={`tl-overlays__item ${THUMBNAIL_FRAME_OVERLAY_ITEM_CLASS}`}
              data-testid="thumbnail-frame-overlay"
              handle=".bottom-overlay-handle"
              nodeRef={bottomOverlayFrameRef}
              onDrag={() => {}}
              position={overlayPositions.bottom}
              scale={zoomLevel}
            >
              <Fade in>
                <Paper
                  ref={bottomOverlayFrameRef}
                  square
                  sx={{
                    position: "fixed",
                    width: `${overlayPositions.bottom.width}px`,
                    height: `${overlayPositions.bottom.height}px`,
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    border: "none",
                  }}
                ></Paper>
              </Fade>
            </Draggable>

            <Draggable
              defaultClassName={`tl-overlays__item ${THUMBNAIL_FRAME_OVERLAY_ITEM_CLASS}`}
              data-testid="thumbnail-frame-overlay"
              handle=".thumbnail-frame-handle"
              nodeRef={thumbnailFrameRef}
              onDrag={() => {}}
              position={windowPosition}
              scale={zoomLevel}
            >
              <Fade in>
                <Paper
                  ref={thumbnailFrameRef}
                  elevation={3}
                  square
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: `${windowDimensions.width}px`,
                    height: `${windowDimensions.height}px`,
                    backgroundColor: "transparent",
                    border: `${DEFAULT_BORDER_WIDTH / zoomLevel}px solid`,
                    borderColor: "primary.main",
                  }}
                >
                  {!updating ? (
                    <>
                      <Box sx={{ display: "flex", justifyContent: "center", height: 0 }}>
                        <Typography
                          paragraph
                          color="white"
                          fontSize={14 / zoomLevel}
                          sx={{ marginTop: -4 / zoomLevel, textWrap: "nowrap" }}
                        >
                          {t("Components.ThumbnailFrameOverlay.Hint")}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                        padding={1 / zoomLevel}
                      >
                        <Button
                          variant="contained"
                          onClick={onClose}
                          sx={{
                            marginRight: 2 / zoomLevel,
                            ...buttonStyles,
                          }}
                          color="warning"
                          disableRipple
                          disableElevation
                        >
                          {t("Global.Action.Cancel")}
                        </Button>
                        <Button variant="contained" onClick={handleCapture} sx={buttonStyles} disableRipple disableElevation>
                          {t("Components.ThumbnailFrameOverlay.Capture")}
                        </Button>
                      </Box>
                    </>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        position: "fixed",
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(255,255,255,0.5)",
                      }}
                    >
                      <CircularProgress sx={{ alignSelf: "center" }} size={40 / zoomLevel} />
                    </Box>
                  )}
                </Paper>
              </Fade>
            </Draggable>
          </>,
          overlayParent,
        )}
    </>
  );
};
