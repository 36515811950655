import { IAnalysisFieldConfig, AnalysisFieldTypeEnum } from "@bigpi/cookbook";

export const questionAnalysisFieldsConfig: Array<IAnalysisFieldConfig> = [
  {
    field: "eventDate",
    label: "Components.Analyses.QuestionAnalysis.Fields.EventDate",
    type: AnalysisFieldTypeEnum.Date,
  },
  {
    field: "firm",
    label: "Components.Analyses.QuestionAnalysis.Fields.Firm",
    type: AnalysisFieldTypeEnum.String,
    isSelectAllEnabled: true,
    isSortEnabled: true,
  },
  {
    field: "firmType",
    label: "Components.Analyses.QuestionAnalysis.Fields.FirmType",
    type: AnalysisFieldTypeEnum.String,
    isSelectAllEnabled: true,
    isSortEnabled: true,
  },
  {
    field: "segment",
    label: "Components.Analyses.QuestionAnalysis.Fields.Segment",
    type: AnalysisFieldTypeEnum.ArrayOfStrings,
    isSelectAllEnabled: false,
    isSortEnabled: true,
  },
  {
    field: "topics",
    label: "Components.Analyses.QuestionAnalysis.Fields.Topics",
    type: AnalysisFieldTypeEnum.ArrayOfStrings,
    isSelectAllEnabled: false,
    isSortEnabled: true,
  },
];
