import { BoardSearchFieldTypes, IBoardSearchShapeMatch } from "@bigpi/cookbook";

import { getBoardSearchMatchesFromTextContent } from "Utils/BoardSearchUtils";
import { IBoardSearchPlugInInputs } from "../BoardSearchManager";
import { BoardSearchPlugInBase } from "./BoardSearchPlugInBase";

export class ArrayOfStringsFieldBoardSearchPlugIn extends BoardSearchPlugInBase {
  // *********************************************
  // Public properties
  // *********************************************/
  /**
   * @inheritdoc
   */
  name = BoardSearchFieldTypes.ArrayOfStrings;

  // *********************************************
  // Public methods
  // *********************************************/
  /**
   * @inheritdoc
   */
  public async execute(input: IBoardSearchPlugInInputs): Promise<Array<IBoardSearchShapeMatch>> {
    const { searchField, searchTerm, shape } = input;
    const fieldData = this.getFieldDataFromShapeProps(shape, searchField);
    if (!fieldData) {
      return [];
    }

    const shapeSearchResults: Array<IBoardSearchShapeMatch> = [];
    (fieldData as Array<string>).forEach((fieldDataItem) => {
      shapeSearchResults.push(...getBoardSearchMatchesFromTextContent(fieldDataItem, searchTerm));
    });

    return shapeSearchResults;
  }
}
