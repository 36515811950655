import { FacetDisplaySortType } from "@bigpi/cookbook";
import { Checkbox, CheckboxProps, InputLabel, ListItemText, MenuItem, MenuItemProps } from "@mui/material";
import { Sort, SortByAlpha } from "@mui/icons-material";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";

type SelectAllMenuItemProps = MenuItemProps &
  Pick<CheckboxProps, "checked" | "indeterminate"> & {
    isSelectAllEnabled?: boolean;
    isSortingEnabled?: boolean;
    label: string;
    onSort?: (sortModel: FacetDisplaySortType) => void;
    sortOrder?: FacetDisplaySortType;
  };

export function SelectAllMenuItem(props: SelectAllMenuItemProps) {
  const { checked, isSelectAllEnabled = true, isSortingEnabled = true, indeterminate, label, onSort, sortOrder, ...rest } = props;
  const { t } = useTranslation();
  if (!isSelectAllEnabled && !isSortingEnabled) {
    return null;
  }
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        position: "sticky",
        top: "0px",
        zIndex: 2,
        backgroundColor: "#fff",
        "&:hover": {
          backgroundColor: "#fff",
        },
        borderBottom: "1px solid #e0e0e0",
      }}
    >
      {isSelectAllEnabled && (
        <MenuItem
          {...rest}
          sx={{
            backgroundColor: "#fff",
            "&:hover": {
              backgroundColor: "#fff",
            },
            "&:focus": {
              backgroundColor: "#fff",
            },
          }}
        >
          <Checkbox checked={checked} indeterminate={indeterminate} />
          <ListItemText primary={label} primaryTypographyProps={{ sx: { fontWeight: 500 } }} />
        </MenuItem>
      )}
      {/* Sort options */}
      {isSortingEnabled && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginRight: "20px",
            marginLeft: "20px",
            cursor: "pointer",
            minHeight: "56px",
          }}
        >
          <InputLabel sx={{ marginRight: "10px" }}>{t("Components.Charts.Sort")}:</InputLabel>
          <Sort
            onClick={() => {
              if (onSort) {
                onSort(FacetDisplaySortType.Count);
              }
            }}
            fontSize="small"
            color={sortOrder === FacetDisplaySortType.Count ? "primary" : undefined}
          />
          <SortByAlpha
            onClick={() => {
              if (onSort) {
                onSort(FacetDisplaySortType.Alphabetical);
              }
            }}
            fontSize="small"
            sx={{ marginLeft: "5px" }}
            color={sortOrder === FacetDisplaySortType.Alphabetical ? "primary" : undefined}
          />
        </Box>
      )}
    </Box>
  );
}
