import { IBoardSearchShapeFields, BoardSearchFieldTypes } from "../BoardSearch/index.js";
import { ShapeDatastoreType } from "../Enums/index.js";

export const TEXT_ELEMENT_QUERY_SELECTOR_SHAPE_ID_PLACEHOLDER = "{{__SHAPE_ID__}}";

/**
 * A constant with the field names and types to search in the shapes when board-level search is executed.
 */
export const BoardSearchShapeFields: Array<IBoardSearchShapeFields> = [
  {
    type: "htmlDocument",
    searchFields: [
      {
        name: "html",
        type: BoardSearchFieldTypes.Html,
      },
    ],
  },
  {
    type: "geo",
    searchFields: [
      {
        name: "text",
        type: BoardSearchFieldTypes.PlainText,
      },
    ],
    textElementQuerySelectorForSearchMatchAnimation: `#${TEXT_ELEMENT_QUERY_SELECTOR_SHAPE_ID_PLACEHOLDER} div.tl-text-label div.tl-text-label__inner div.tl-text-content`,
  },
  {
    type: "text",
    searchFields: [
      {
        name: "text",
        type: BoardSearchFieldTypes.PlainText,
      },
    ],
    textElementQuerySelectorForSearchMatchAnimation: `#${TEXT_ELEMENT_QUERY_SELECTOR_SHAPE_ID_PLACEHOLDER} div.tl-text-content`,
  },
  {
    type: "lockedText",
    searchFields: [
      {
        name: "text",
        type: BoardSearchFieldTypes.PlainText,
      },
    ],
    textElementQuerySelectorForSearchMatchAnimation: `#${TEXT_ELEMENT_QUERY_SELECTOR_SHAPE_ID_PLACEHOLDER} div.tl-text-content`,
  },
  {
    type: "arrow",
    searchFields: [
      {
        name: "text",
        type: BoardSearchFieldTypes.PlainText,
      },
    ],
    textElementQuerySelectorForSearchMatchAnimation: `#${TEXT_ELEMENT_QUERY_SELECTOR_SHAPE_ID_PLACEHOLDER} ~ div.tl-arrow-label div.tl-arrow-label__inner`,
  },
  {
    type: "dataGrid",
    searchFields: [
      {
        name: "datastoreType",
        matchValue: ShapeDatastoreType.ServerDatastore,
        type: BoardSearchFieldTypes.DataGridServerDatastore,
      },
      {
        name: "datastoreType",
        matchValue: ShapeDatastoreType.ParentDatastore,
        type: BoardSearchFieldTypes.DataGridParentDatastore,
      },
    ],
  },
];
