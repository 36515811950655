import { useIsPageVisible, useIsUserActive } from "@bigpi/cutlery";
import { useAuthUser } from "@frontegg/react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Config } from "Config";

// Reauthenticate for preview every 10 minutes
const DEFAULT_AUTH_FOR_PREVIEW_POLLING_TIME = 10 * 60000;

export function usePreviewAuth() {
  const user = useAuthUser();
  const accessToken = useMemo(() => user?.accessToken, [user]);

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [authFailed, setAuthFailed] = useState<boolean>(false);

  const pollingIntervalId = useRef<NodeJS.Timeout | null>(null);

  const { isPageVisible } = useIsPageVisible();
  const { isUserActive } = useIsUserActive();

  const reauthenticateForPreview = useCallback(() => {
    if (accessToken) {
      const headers = new Headers({
        authorization: `Bearer ${accessToken}`,
      });

      // Make sure we're authenticated against the collaboration backend
      fetch(`${Config.previewHttpUrl}/preview-auth`, {
        credentials: "include",
        headers,
      }).then(
        (result) => {
          if (result.ok) {
            setIsAuthenticated(true);
            setAuthFailed(false);
          } else {
            console.error("Error authenticating for preview", result.status, result.statusText);
            setAuthFailed(true);
          }
        },
        (e) => {
          console.error("Error authenticating for preview", e);
          setAuthFailed(true);
        },
      );
    }
  }, [accessToken]);

  // Call the preview endpoint to set a cookie there
  useEffect(() => {
    if (isPageVisible && isUserActive) {
      // Authenticate for preview
      reauthenticateForPreview();

      // Poll for authentication
      pollingIntervalId.current = setInterval(reauthenticateForPreview, DEFAULT_AUTH_FOR_PREVIEW_POLLING_TIME);

      // Cleanup, stop polling
      return () => {
        if (pollingIntervalId.current) {
          clearInterval(pollingIntervalId.current);
        }
      };
    }
  }, [isPageVisible, isUserActive, reauthenticateForPreview]);

  return { isAuthenticated, authFailed };
}
