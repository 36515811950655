import * as d3 from "d3";

/**
 * Utility functions for working with SVG elements.
 */
export class SvgUtils {
  /**
   * Get the text from an SVG element.
   *
   * @param element The SVG element.
   *
   * @returns The text from the SVG element.
   */
  public static getTextFromElement(element: SVGTextElement) {
    const dom = d3.select(element);

    return dom.text();
  }

  /**
   * Get the text from an SVS event target.
   *
   * @param target The event target.
   *
   * @returns The text from the event target.
   */
  public static getTextFromEventTarget(target: EventTarget | null) {
    let result: string | null = null;

    if (target && target instanceof SVGTextElement) {
      result = SvgUtils.getTextFromElement(target) || null;
    }

    return result;
  }
}
