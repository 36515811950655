import { DocumentType, IPlugIn } from "@bigpi/cookbook";
import type { Editor as TldrawEditor, TLShapeId } from "@tldraw/tldraw";
import { TFunction } from "i18next";

import { ICompanionTab } from "BoardComponents/Companion/ICompanionTab";
import { CommandExecutor } from "Components/CommandManagers/CommandExecutor";

export interface IHtmlDocumentCompanionPlugInInputs {
  app?: TldrawEditor;
  commandExecutor: CommandExecutor;
  organizationId: string;
  shapeId: TLShapeId;
  t: TFunction;
  workspaceBoardId: string;
}

export abstract class HtmlDocumentCompanionPlugInBase
  implements IPlugIn<IHtmlDocumentCompanionPlugInInputs, Array<ICompanionTab>>
{
  // *********************************************
  // Public properties
  // *********************************************/
  /**
   * @inheritdoc
   */
  abstract name: DocumentType;

  // *********************************************
  // Public methods
  // *********************************************/
  /**
   * @inheritdoc
   */
  public abstract execute(input: IHtmlDocumentCompanionPlugInInputs): Promise<Array<ICompanionTab>>;

  /**
   * @inheritdoc
   */
  public async destroy(): Promise<void> {
    // Do nothing in base class
  }
}
