import { Close } from "@mui/icons-material";
import { Dialog, DialogContent, DialogTitle, useTheme, Stack, IconButton } from "@mui/material";

import { Document } from "Components/Document/Document";
import { useDocumentQuery } from "GraphQL/Generated/Apollo";

interface IDocumentPreviewDialogProps {
  docId: string;
  onClose: () => void;
}

export function DocumentPreviewDialog(props: IDocumentPreviewDialogProps) {
  const { docId, onClose } = props;
  const { data: documnet } = useDocumentQuery({ variables: { id: docId } });
  const theme = useTheme();

  return (
    <Dialog open={true} onClose={onClose} maxWidth="md" fullWidth={true}>
      <DialogTitle sx={{ borderBottom: `solid 1px ${theme.palette.divider}` }}>
        <Stack direction="row" justifyContent="space-between">
          <span>{documnet?.document?.name}</span>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ height: "600px", p: 0 }}>
        <Document id={docId} name={documnet?.document?.name || ""} isReadOnly={true} />
      </DialogContent>
    </Dialog>
  );
}
