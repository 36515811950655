import { v4 as uuidv4 } from "uuid";

import { AllowedStyles, AllowedClassesRegExp, TagConversion, TagConversions } from "./ClipboardWhitelist";

export type ElementContent = {
  html: string;
  sourceHtml: string;
  textContent: string;
};

export function generateImageIds(): void {
  const images = document.querySelectorAll(".astra.document img");

  if (images && images.length > 0) {
    images.forEach((image) => {
      image.setAttribute("data-clipboard-id", uuidv4());
    });
  }
}

export function getRenderedContent(html: string, imageToken: string, removeClass: boolean = true): ElementContent {
  const result = {
    sourceHtml: html,
    html: "",
    textContent: "",
  };

  const elem = document.createElement("div");
  elem.classList.add("astra", "document");
  elem.style.cssText = "visibility:hidden;";
  elem.innerHTML = html;

  // Add the rendered content to the DOM and can get computed styles
  document.body.appendChild(elem);
  inlineAllComputedStyles(elem, imageToken, removeClass);

  result.html = elem.innerHTML;
  result.textContent = elem.textContent || "";

  // Remove from the DOM
  elem.remove();

  return result;
}

export function inlineAllComputedStyles(node: Node, imageToken: string, removeClass: boolean = true): void {
  // Inline computed styles for this node
  node = inlineNodeComputedStyles(node, imageToken);

  // Loop all children to inline their styles
  node.childNodes.forEach((child) => {
    inlineAllComputedStyles(child, imageToken, removeClass);
  });

  // Remove classes
  if (removeClass && node instanceof HTMLElement) {
    const removeClasses: Array<string> = [];
    node.classList.forEach((className) => {
      if (!AllowedClassesRegExp.test(className)) {
        removeClasses.push(className);
      }
    });
    node.classList.remove(...removeClasses);
    if (node.classList.length === 0) {
      node.removeAttribute("class");
    }
  }
}

export function inlineNodeComputedStyles(node: Node, imageToken: string): Node {
  if (node instanceof HTMLElement) {
    let element = node as HTMLElement;
    let tagName = element.tagName.toLowerCase();

    // Apply style filtering
    filterStyles(tagName, element);

    // Munge images
    wordifyImageStyles(tagName, element);

    // Check if we need to replace the tag type
    const newTagName = getReplacementTagName(TagConversions, tagName, element);

    if (newTagName !== null) {
      element = changeElementTagName(element, newTagName);
      tagName = newTagName;
    }

    adjustRelativeUrls(element);

    appendImageToken(tagName, element, imageToken);

    // Makes sure children of modified tags are iterated
    return element;
  } else {
    return node;
  }
}

export function filterStyles(tagName: string, element: HTMLElement): void {
  let cssText = "";

  // Check if we have rules for this tag name
  if (Object.prototype.hasOwnProperty.call(AllowedStyles, tagName)) {
    const computedStyles = window.getComputedStyle(element, null);

    // Loop all the allowed style items for this tag name
    AllowedStyles[tagName].forEach((styleName) => {
      const styleValue = computedStyles.getPropertyValue(styleName);
      if (styleValue) {
        // Note: We're relying on the browser to remove duplicates
        cssText += `${styleName}:${styleValue};`;
      }
    });
  }

  // Set allowed styles and/or clear unsupported styles
  if (cssText !== "") {
    element.style.cssText = cssText;
  } else {
    // Clear any styles that might be set on the element
    element.removeAttribute("style");
  }
}

export function adjustRelativeUrls(element: HTMLElement): void {
  // Add host to src attributes when address is relative
  const url = element.getAttribute("src");

  if (url && url.startsWith("/")) {
    element.setAttribute("src", `${document.location.protocol}//${document.location.host}${url}`);
  }
}

export function appendImageToken(tagName: string, element: HTMLElement, imageToken: string): void {
  if (tagName === "img") {
    const url = element.getAttribute("src");

    if (url) {
      try {
        const parsedUrl = new URL(url);

        parsedUrl.searchParams.set("t", imageToken);
        element.setAttribute("src", parsedUrl.href);
      } catch (e) {
        console.error(`Invalid URL encountered "${url}"`, e);
      }
    }
  }
}

/**
 * Convert custom image layout options and size to img tag attributes that Word understands
 *
 * @param      {string}       tagName  The tag name
 * @param      {HTMLElement}  element  The element
 */
export function wordifyImageStyles(tagName: string, element: HTMLElement): void {
  if (tagName === "img") {
    const parent = element.parentNode as HTMLElement;
    if (parent && parent.tagName.toLowerCase() === "figure") {
      // Convert alignment
      if (parent.classList.contains("image-style-align-left")) {
        element.setAttribute("align", "left");
      } else if (parent.classList.contains("image-style-align-right")) {
        element.setAttribute("align", "right");
      }

      // Attempt to get the margin around the image
      const computedParentStyles = window.getComputedStyle(parent, null);
      // Get the larger margin (should be same for top/bottom)
      const vspace = Math.max(
        parseInt(computedParentStyles.getPropertyValue("margin-top"), 10),
        parseInt(computedParentStyles.getPropertyValue("margin-bottom"), 10),
      ).toString();
      element.setAttribute("vspace", vspace);

      // Get larger margin. This will depend on float (one side should have zero)
      const hspace = Math.max(
        parseInt(computedParentStyles.getPropertyValue("margin-left"), 10),
        parseInt(computedParentStyles.getPropertyValue("margin-right"), 10),
      ).toString();
      element.setAttribute("hspace", hspace);
    }

    // Attempt to find the original image and set size
    const originalImage = getOriginalImage(element);

    if (originalImage) {
      const computedStyles = window.getComputedStyle(originalImage, null);
      if (computedStyles.getPropertyValue("width")) {
        element.setAttribute("width", parseInt(computedStyles.getPropertyValue("width"), 10).toString());
      }
      if (computedStyles.getPropertyValue("height")) {
        element.setAttribute("height", parseInt(computedStyles.getPropertyValue("height"), 10).toString());
      }
    }
  }
}

export function getOriginalImage(element: HTMLElement): HTMLElement | null {
  let result = null;

  // Attempt to find by custom data attribute
  const imageId = element.getAttribute("data-clipboard-id");

  if (imageId) {
    result = document.querySelector(`img[data-clipboard-id="${imageId}"]`);
  }

  // Fall back to getting the first image with the same URL
  if (!result) {
    const url = element.getAttribute("src");
    result = document.querySelector(`img[src="${url}"]`);
  }

  return result as HTMLElement;
}

export function getReplacementTagName(conversions: Array<TagConversion>, tagName: string, element: HTMLElement): string | null {
  let result: null | string = null;

  // Loop all conversion items to check for matches
  for (let conversionItem of conversions) {
    // Check if source tag name matches
    if (conversionItem.from === tagName) {
      // Check if element has all requested classes
      if (conversionItem.classMatches.every((className) => element.classList.contains(className))) {
        result = conversionItem.to;

        // Stop searching for other items
        break;
      }
    }
  }

  return result;
}

export function changeElementTagName(element: HTMLElement, newTagName: string): HTMLElement {
  const result = document.createElement(newTagName);

  // Copy all attributes
  for (let i = 0; i < element.attributes.length; ++i) {
    if (element.attributes.item(i) !== null) {
      const nodeName = element.attributes.item(i)!.nodeName;
      const nodeValue = element.attributes.item(i)!.nodeValue;

      if (nodeValue !== null) {
        result.setAttribute(nodeName, nodeValue);
      }
    }
  }

  // Move children
  result.append(...(element.childNodes as any));

  // Move element (if not a root node)
  if (element.parentNode !== null) {
    element.parentNode.replaceChild(result, element);
  }

  return result;
}
