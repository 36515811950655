import { ICommand, ICommandRequest } from "@bigpi/cookbook";
import EventEmitter from "emittery";
import { useMemo } from "react";
import { v4 as uuidV4 } from "uuid";

import { ICommandResponse } from "GraphQL/Generated/Requests";
import { CommandContext } from "CommandContext";

export type CommandExecutorEvents = {
  beforeCommandRequest: OnCommandRequestEventArgs;
  commandRequest: OnCommandRequestEventArgs;
  afterCommandRequest: OnCommandRequestEventArgs;

  beforeCommandResponse: OnCommandResponseEventArgs;
  commandResponse: OnCommandResponseEventArgs;
  afterCommandResponse: OnCommandResponseEventArgs;
};

export type OnCommandRequestEventArgs = {
  command: ICommand;
  commandRequest: ICommandRequest;
};
export type OnBeforeCommandRequestHandler = (event: OnCommandRequestEventArgs) => void;
export type OnCommandRequestHandler = (event: OnCommandRequestEventArgs) => void;
export type OnAfterCommandRequestHandler = (event: OnCommandRequestEventArgs) => void;

export type OnCommandResponseEventArgs = {
  commandResponse: ICommandResponse;
};

export type OnBeforeCommandResponseHandler = (event: OnCommandResponseEventArgs) => void;
export type OnCommandResponseHandler = (event: OnCommandResponseEventArgs) => void;
export type OnAfterCommandResponseHandler = (event: OnCommandResponseEventArgs) => void;

/**
 * Handles command execution and response lifecycle events.
 */
export class CommandExecutor extends EventEmitter<CommandExecutorEvents> {
  // *********************************************
  // Private fields
  // *********************************************/
  private _sessionId = uuidV4();

  // *********************************************
  // Public static methods
  // *********************************************/
  public static useNewCommandExecutor = () => {
    const result = useMemo(() => new CommandExecutor(), []);

    return result;
  };

  // *********************************************
  // Public properties
  // *********************************************/
  public get sessionId(): string {
    return this._sessionId;
  }

  // *********************************************
  // Public methods
  // *********************************************/
  public rotateSessionId() {
    this._sessionId = uuidV4();
  }

  public async executeCommand(command: ICommand, commandRequest: Partial<ICommandRequest>) {
    // Create the event data based on the original run command data
    const eventData: OnCommandRequestEventArgs = {
      command,
      commandRequest: {
        accessToken: commandRequest.accessToken ?? "",
        callerData: commandRequest.callerData ?? "",
        commandContext: commandRequest.commandContext ?? CommandContext.getCommandContext(),
        commandId: command.id,
        deduplicationId: commandRequest.deduplicationId,
        outputTemplate: commandRequest.outputTemplate ?? "",
        requestId: commandRequest.requestId ?? uuidV4(),
        sessionId: commandRequest.sessionId ?? this._sessionId,
      },
    };

    // Allow any plug-ins to handle the command before we send it to the server
    await this.raiseBeforeCommandRequest(eventData);

    // Execute the command request (call at least our event handler that's defined in this component)
    await this.raiseCommandRequest(eventData);

    // Allow any plug-ins to handle the command before we send it to the server
    await this.raiseAfterCommandRequest(eventData);
  }

  public async raiseBeforeCommandRequest(event: OnCommandRequestEventArgs) {
    await this.emitSerial("beforeCommandRequest", event);
  }

  public async raiseCommandRequest(event: OnCommandRequestEventArgs) {
    await this.emitSerial("commandRequest", event);
  }

  public async raiseAfterCommandRequest(event: OnCommandRequestEventArgs) {
    await this.emitSerial("afterCommandRequest", event);
  }

  public async raiseBeforeCommandResponse(event: OnCommandResponseEventArgs) {
    await this.emitSerial("beforeCommandResponse", event);
  }

  public async raiseCommandResponse(event: OnCommandResponseEventArgs) {
    await this.emitSerial("commandResponse", event);
  }
  public async raiseAfterCommandResponse(event: OnCommandResponseEventArgs) {
    await this.emitSerial("afterCommandResponse", event);
  }
}
