import { CreatedAtAscSortOption, CreatedAtAscSortOptionName } from "./CreatedAtAscSortOption.js";
import { CreatedAtDescSortOption, CreatedAtDescSortOptionName } from "./CreatedAtDescSortOption.js";
import { NameAscSortOption, NameAscSortOptionName } from "./NameAscSortOption.js";
import { NameDescSortOption, NameDescSortOptionName } from "./NameDescSortOption.js";
import { UpdatedAtAscSortOption, UpdatedAtAscSortOptionName } from "./UpdatedAtAscSortOption.js";
import { UpdatedAtDescSortOption, UpdatedAtDescSortOptionName } from "./UpdatedAtDescSortOption.js";

export type SortKeys =
  | typeof CreatedAtAscSortOptionName
  | typeof CreatedAtDescSortOptionName
  | typeof NameAscSortOptionName
  | typeof NameDescSortOptionName
  | typeof UpdatedAtAscSortOptionName
  | typeof UpdatedAtDescSortOptionName;

export function getSortOption(key: SortKeys) {
  switch (key) {
    case CreatedAtAscSortOptionName:
      return CreatedAtAscSortOption;
    case CreatedAtDescSortOptionName:
      return CreatedAtDescSortOption;
    case NameAscSortOptionName:
      return NameAscSortOption;
    case NameDescSortOptionName:
      return NameDescSortOption;
    case UpdatedAtAscSortOptionName:
      return UpdatedAtAscSortOption;
    case UpdatedAtDescSortOptionName:
      return UpdatedAtDescSortOption;
    default:
      return undefined;
  }
}
