import { ContentTypes } from "@bigpi/cookbook";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { BundleInputForm } from "Components/Bundle/BundleInputForm";
import { DefaultDialogTransition } from "Components/DialogTransition/DefaultDialogTransition";
import { FileIcon } from "Components/FileIcon/FileIcon";
import { Bundle, BundleType, usePublishWorkspaceFilesToLibraryMutation } from "GraphQL/Generated/Apollo";

const MIN_BUNDLE_NAME_LENGTH = 1;

export interface IWorkspaceFileDetails {
  id: string;
  filename: string;
  mimeType: ContentTypes;
}

export interface IPublishToLibraryDialogProps {
  onClose: () => void;
  open: boolean;
  selectedWorkspaceFiles: Array<IWorkspaceFileDetails>;
  workspaceId: string;
}

export function PublishToLibraryDialog(props: IPublishToLibraryDialogProps) {
  const { onClose, open, selectedWorkspaceFiles, workspaceId } = props;

  const { t } = useTranslation();

  const [createBundle, setCreateBundle] = useState(false);
  const [bundleName, setBundleName] = useState("");
  const [bundleType, setBundleType] = useState<BundleType>(BundleType.Output);

  const [publishWorkspaceFilesToLibrary] = usePublishWorkspaceFilesToLibraryMutation();

  const onPublish = async () => {
    try {
      // Publish the selected workspace files to the content library
      await publishWorkspaceFilesToLibrary({
        variables: {
          input: {
            workspaceId,
            workspaceFileIds: selectedWorkspaceFiles.map((file) => file.id),
            createBundle,
            bundleDetails: createBundle ? { name: bundleName, type: bundleType } : undefined,
          },
        },
        refetchQueries: ["Files"],
      });

      // Show success message
      toast.success(t("Components.PublishToLibraryDialog.Success", { count: selectedWorkspaceFiles.length }));

      // Close the dialog
      onClose();
    } catch (error) {
      console.error("Error publishing workspace files to the content library", error);

      // Show error message
      toast.error(t("Components.PublishToLibraryDialog.Error"));
    }
  };

  // Determine if the publish button should be disabled
  const canPublish =
    selectedWorkspaceFiles.length > 0 &&
    (!createBundle || (createBundle && bundleName && bundleName.trim().length > MIN_BUNDLE_NAME_LENGTH && bundleType));

  return (
    <Dialog open={open} onClose={onClose} TransitionComponent={DefaultDialogTransition} fullWidth maxWidth="sm">
      <DialogTitle variant="h5">
        {t("Components.PublishToLibraryDialog.Title", { count: selectedWorkspaceFiles.length })}
      </DialogTitle>
      <DialogContent style={{ paddingBottom: 0 }}>
        <List sx={{ width: "100%", bgcolor: "background.paper" }}>
          {selectedWorkspaceFiles.map((file) => {
            return (
              <ListItem key={file.id} sx={{ alignItems: "center" }} disablePadding>
                <ListItemIcon>
                  <FileIcon mimetype={file.mimeType} />
                </ListItemIcon>

                <ListItemText
                  sx={{ mt: 0.5, mb: 0.5 }}
                  primary={file.filename}
                  primaryTypographyProps={{ fontWeight: "500", mt: 0.5, mb: 0.5 }}
                />
              </ListItem>
            );
          })}
        </List>

        <Box sx={{ mb: 2 }}>
          <FormControlLabel
            control={<Checkbox checked={createBundle} onChange={(_, checked) => setCreateBundle(checked)} />}
            label={t("Components.PublishToLibraryDialog.CreateBundleLabel")}
          />

          {createBundle && (
            <BundleInputForm bundleDetails={{ name: bundleName, type: bundleType }} onChangeBundleDetails={setBundleDetails} />
          )}
        </Box>

        <DialogContentText variant="caption">{t("Components.PublishToLibraryDialog.Tip")}</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Box>
          <Button onClick={onClose}>{t("Global.Action.Cancel")}</Button>
          <Button onClick={onPublish} disabled={!canPublish}>
            {t("Components.PublishToLibraryDialog.Publish")}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );

  /**
   * Callback to set the bundle details in the state.
   *
   * @param bundleDetails Bundle details to set in the state.
   */
  function setBundleDetails(bundleDetails: Partial<Bundle>) {
    setBundleName(bundleDetails.name || "");
    setBundleType((bundleDetails.type as BundleType) || BundleType.Output);
  }
}
