import { Mark } from "@tiptap/core";
import { Plugin, PluginKey } from "@tiptap/pm/state";
import { Decoration, DecorationSet } from "@tiptap/pm/view";

import { getStringValueAttribute } from "../../Utils/ExtensionUtil.js";
import { scrollEditorNodeIntoView } from "../../Utils/ScrollEditorNodeIntoView.js";

// We need to declare our custom commands so that TS doesn't throw warning everywhere saying these commands do not exist.
declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    bookmark: {
      /**
       * Visually highlights the bookmark with the given id.
       * This doesn't add any persistent changes to the document.
       */
      activateBookmark: (id: string) => ReturnType;
      /**
       * Removes visual highlight from the bookmark with the given id.
       * This doesn't add any persistent changes to the document.
       */
      deactivateBookmark: (id: string) => ReturnType;
      /**
       * Removes visual highlight from all bookmarks.
       * This doesn't add any persistent changes to the document.
       */
      clearAllBookmarks: () => ReturnType;
      /**
       * Scrolls the bookmark with the given id and index into view.
       * The index here is used to handle multiple bookmarks with the same id.
       */
      scrollBookmarkIntoView: (id: string, index?: number) => ReturnType;
    };
  }
}

export interface BookmarkOptions {
  highlightClass: string;
}

export interface BookmarkStorage {
  activeBookmarkIds: Array<string>;
}

export const Bookmark = Mark.create<BookmarkOptions, BookmarkStorage>({
  name: "bookmark",

  addAttributes() {
    return {
      "data-bookmark-id": getStringValueAttribute("data-bookmark-id", null),
    };
  },

  addOptions() {
    return {
      highlightClass: "highlight-bookmark",
    };
  },

  addStorage() {
    return {
      activeBookmarkIds: [],
    };
  },

  parseHTML() {
    return [
      {
        tag: "bookmark[data-bookmark-id]",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["bookmark", HTMLAttributes, 0];
  },

  addCommands() {
    return {
      activateBookmark: (id: string) => () => {
        if (this.storage.activeBookmarkIds.indexOf(id) === -1) {
          this.storage.activeBookmarkIds.push(id);
        }

        return true;
      },
      deactivateBookmark: (id: string) => () => {
        this.storage.activeBookmarkIds = this.storage.activeBookmarkIds.filter((bookmarkId) => bookmarkId !== id);

        return true;
      },
      clearAllBookmarks: () => () => {
        this.storage.activeBookmarkIds = [];

        return true;
      },
      scrollBookmarkIntoView:
        (id: string, index: number = 0) =>
        ({ state, editor }) => {
          if (id.length === 0) {
            return true;
          }

          const { doc } = state;

          let scrolled = false;
          let currentIndex = -1;
          doc.descendants((node, pos) => {
            if (!scrolled) {
              node.marks.forEach((m) => {
                if (m.type.name === "bookmark") {
                  const bookmarkIdAttr: string = m.attrs["data-bookmark-id"] || "";
                  const bookmarkIds = bookmarkIdAttr.trim().split(",");
                  const hasMatchingBookmark = bookmarkIds.some((bookmarkId) => id === bookmarkId.trim());

                  if (hasMatchingBookmark) {
                    currentIndex++;
                  }

                  if (currentIndex === index) {
                    scrollEditorNodeIntoView(editor, pos);
                    scrolled = true;
                  }
                }
              });
            }
          });

          return true;
        },
    };
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey("bookmark"),
        props: {
          decorations: ({ doc }) => {
            const decorations: Decoration[] = [];
            doc.descendants((node, pos) => {
              // Check if the node has an active bookmark.
              const hasActiveBookmark = node.marks.some((m) => {
                if (m.type.name === "bookmark") {
                  const bookmarkIdAttr: string = m.attrs["data-bookmark-id"] || "";
                  const bookmarkIds = bookmarkIdAttr.trim().split(",");

                  return bookmarkIds.some((bookmarkId) => this.storage.activeBookmarkIds.includes(bookmarkId.trim()));
                }

                return false;
              });
              if (hasActiveBookmark) {
                // Add a decoration to highlight the bookmark.
                const decoration = Decoration.inline(pos, pos + node.nodeSize, {
                  class: this.options.highlightClass,
                });
                decorations.push(decoration);
              }
            });

            return DecorationSet.create(doc, decorations);
          },
        },
      }),
    ];
  },
});
