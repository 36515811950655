import { ApolloClient } from "@apollo/client";
import { BoardSearchFieldTypes } from "@bigpi/cookbook";
import {
  IAnalystQuestionAnalysisShape,
  IQuestionAnalysisShape,
  ITopicDiscussionAnalysisSelection,
  ITopicDiscussionAnalysisShape,
  ITopicDiscussionInNewsArticleAnalysisFacets,
  ITopicDiscussionInNewsArticleAnalysisSelection,
  ITopicDiscussionInNewsArticleAnalysisShape,
  ITopicDiscussionSummaryAnalysisSelection,
  ITopicDiscussionSummaryAnalysisShape,
} from "@bigpi/tl-schema";
import { TFunction } from "i18next";

import { AnalystQuestionAnalysisUtil } from "BoardComponents/Analyses/AnalystQuestionAnalysisShape/AnalystQuestionAnalysisShape";
import { getFilteredAnalystQuestionsData } from "BoardComponents/Analyses/AnalystQuestionAnalysisShape/analystQuestionsDataUtils";
import { analystQuestionAnalysisFieldsConfig } from "BoardComponents/Analyses/AnalystQuestionAnalysisShape/AnalystQuestionAnalysisFieldsConfig";
import { getCuratedFacetValues, getShortcutRelatedRanges, getCustomDateRanges } from "BoardComponents/Analyses/Hooks";
import { QuestionAnalysisUtil } from "BoardComponents/Analyses/QuestionAnalysisShape/QuestionAnalysisShape";
import { getGroupedAnalystQuestionsData } from "BoardComponents/Analyses/QuestionAnalysisShape/groupedAnalystQuestionsDataUtils";
import { questionAnalysisFieldsConfig } from "BoardComponents/Analyses/QuestionAnalysisShape/QuestionAnalysisFieldsConfig";
import { TopicDiscussionAnalysisUtil } from "BoardComponents/Analyses/TopicDiscussionAnalysisShape/TopicDiscussionAnalysisShape";
import { getFilteredTopicDiscussionExamplesData } from "BoardComponents/Analyses/TopicDiscussionAnalysisShape/topicDiscussionExamplesDataUtils";
import { topicDiscussionAnalysisFieldsConfig } from "BoardComponents/Analyses/TopicDiscussionAnalysisShape/TopicDiscussionAnalysisFieldsConfig";
import { TopicDiscussionInNewsArticleAnalysisUtil } from "BoardComponents/Analyses/TopicDiscussionsInNewsArticlesAnalysisShape/TopicDiscussionsInNewsArticlesAnalysisShape";
import { getFilteredTopicDiscussionInNewsArticlesData } from "BoardComponents/Analyses/TopicDiscussionsInNewsArticlesAnalysisShape/topicDiscussionInNewsArticlesDataUtils";
import { topicDiscussionInNewsArticlesAnalysisConfig } from "BoardComponents/Analyses/TopicDiscussionsInNewsArticlesAnalysisShape/TopicDiscussionInNewsArticlesAnalysisFieldsConfig";
import { TopicDiscussionSummaryAnalysisUtil } from "BoardComponents/Analyses/TopicDiscussionSummaryAnalysisShape/TopicDiscussionSummaryAnalysisShape";
import { getFilteredTopicDiscussionSummaryExamplesData } from "BoardComponents/Analyses/TopicDiscussionSummaryAnalysisShape/topicDiscussionSummaryExamplesDataUtils";
import { topicDiscussionSummaryAnalysisFieldsConfig } from "BoardComponents/Analyses/TopicDiscussionSummaryAnalysisShape/TopicDiscussionSummaryAnalysisFieldsConfig";
import {
  IAnalystQuestionResult,
  IGroupedAnalystQuestionResult,
  ITopicDiscussionExampleResult,
  ITopicDiscussionInNewsArticleResult,
  ITopicDiscussionSummaryExampleResult,
} from "BoardComponents/BoardDatastore";
import { IBoardSearchPlugInInputs } from "../BoardSearchManager";
import { DataGridBoardSearchPlugInBase } from "./DataGridBoardSearchPlugInBase";

export class DataGridParentDatastoreBoardSearchPlugIn extends DataGridBoardSearchPlugInBase {
  // *********************************************
  // Public properties
  // *********************************************/
  /**
   * @inheritdoc
   */
  name = BoardSearchFieldTypes.DataGridParentDatastore;

  // *********************************************
  // Private methods
  // *********************************************/
  /**
   * Get the filtered data for the TopicDiscussionAnalysisShape
   *
   * @param apolloClient The Apollo client instance
   * @param shape The TopicDiscussionAnalysisShape
   *
   * @returns The filtered TopicDiscussionAnalysisShape data
   */
  private async getTopicDiscussionAnalysisShapeData(
    apolloClient: ApolloClient<object>,
    shape: ITopicDiscussionAnalysisShape,
    organizationId: string,
    t: TFunction,
  ): Promise<Array<ITopicDiscussionExampleResult>> {
    const { selectedFacetValues, boundsFacetValues, selection } = shape.props;

    const customShortcutRanges = await getCustomDateRanges(apolloClient, organizationId);
    const shortcutRelatedRanges = getShortcutRelatedRanges(
      [selectedFacetValues.eventDateShortcut || "", boundsFacetValues.eventDateShortcut || ""],
      customShortcutRanges || [],
      t,
    );
    const curatedFacetValues = getCuratedFacetValues(
      selectedFacetValues,
      boundsFacetValues,
      topicDiscussionAnalysisFieldsConfig,
      shortcutRelatedRanges,
      "eventDate",
    );
    return getFilteredTopicDiscussionExamplesData(
      apolloClient,
      curatedFacetValues.mergedFacetValues,
      selection as ITopicDiscussionAnalysisSelection,
    );
  }

  /**
   * Get the filtered data for the TopicDiscussionSummaryAnalysisShape
   *
   * @param apolloClient The Apollo client instance
   * @param shape The TopicDiscussionSummaryAnalysisShape
   *
   * @returns The filtered TopicDiscussionSummaryAnalysisShape data
   */
  private async getTopicDiscussionSummaryAnalysisShapeData(
    apolloClient: ApolloClient<object>,
    shape: ITopicDiscussionSummaryAnalysisShape,
    organizationId: string,
    t: TFunction,
  ): Promise<Array<ITopicDiscussionSummaryExampleResult>> {
    const { selectedFacetValues, boundsFacetValues, selection } = shape.props;

    const customShortcutRanges = await getCustomDateRanges(apolloClient, organizationId);
    const shortcutRelatedRanges = getShortcutRelatedRanges(
      [selectedFacetValues.eventDateShortcut || "", boundsFacetValues.eventDateShortcut || ""],
      customShortcutRanges || [],
      t,
    );
    const curatedFacetValues = getCuratedFacetValues(
      selectedFacetValues,
      boundsFacetValues,
      topicDiscussionSummaryAnalysisFieldsConfig,
      shortcutRelatedRanges,
      "eventDate",
    );
    return getFilteredTopicDiscussionSummaryExamplesData(
      apolloClient,
      curatedFacetValues.mergedFacetValues,
      selection as ITopicDiscussionSummaryAnalysisSelection,
    );
  }

  /**
   * Get the filtered data for the TopicDiscussionInNewsArticleAnalysisShape
   *
   * @param apolloClient The Apollo client instance
   * @param shape The TopicDiscussionInNewsArticleAnalysisShape
   * @param organizationId The organization ID
   *
   * @returns The filtered TopicDiscussionInNewsArticleAnalysisShape data
   */
  private async getTopicDiscussionInNewsArticleAnalysisShapeData(
    apolloClient: ApolloClient<object>,
    shape: ITopicDiscussionInNewsArticleAnalysisShape,
    organizationId: string,
    t: TFunction,
  ): Promise<Array<ITopicDiscussionInNewsArticleResult>> {
    const { selectedFacetValues, boundsFacetValues, selection } = shape.props;

    const customShortcutRanges = await getCustomDateRanges(apolloClient, organizationId);
    const shortcutRelatedRanges = getShortcutRelatedRanges(
      [selectedFacetValues.dateShortcut || "", boundsFacetValues.dateShortcut || ""],
      customShortcutRanges || [],
      t,
    );
    const curatedFacetValues = getCuratedFacetValues(
      selectedFacetValues,
      boundsFacetValues,
      topicDiscussionInNewsArticlesAnalysisConfig,
      shortcutRelatedRanges,
      "date",
    );

    return getFilteredTopicDiscussionInNewsArticlesData(
      apolloClient,
      organizationId,
      curatedFacetValues.mergedFacetValues as ITopicDiscussionInNewsArticleAnalysisFacets,
      selection as ITopicDiscussionInNewsArticleAnalysisSelection,
    );
  }

  /**
   * Get the filtered data for the AnalystQuestionAnalysisShape
   *
   * @param apolloClient The Apollo client instance
   * @param shape The AnalystQuestionAnalysisShape
   * @param t The translation function
   *
   * @returns The filtered AnalystQuestionAnalysisShape data
   */
  private async getAnalystQuestionAnalysisShapeData(
    apolloClient: ApolloClient<object>,
    shape: IAnalystQuestionAnalysisShape,
    organizationId: string,
    t: TFunction,
  ): Promise<Array<IAnalystQuestionResult>> {
    const { selectedFacetValues } = shape.props;

    const customShortcutRanges = await getCustomDateRanges(apolloClient, organizationId);
    const shortcutRelatedRanges = getShortcutRelatedRanges(
      [selectedFacetValues.eventDateShortcut || ""],
      customShortcutRanges || [],
      t,
    );
    const curatedFacetValues = getCuratedFacetValues(
      {},
      selectedFacetValues,
      analystQuestionAnalysisFieldsConfig,
      shortcutRelatedRanges,
      "eventDate",
    );

    return getFilteredAnalystQuestionsData(apolloClient, curatedFacetValues.mergedFacetValues, t);
  }

  /**
   * Get the data for the QuestionAnalysisShape
   *
   * @param apolloClient The Apollo client instance
   * @param shape The QuestionAnalysisShape
   * @param organizationId The organization ID
   * @param t The translation function
   *
   * @returns The QuestionAnalysisShape data
   */
  private async getQuestionAnalysisShapeData(
    apolloClient: ApolloClient<object>,
    shape: IQuestionAnalysisShape,
    organizationId: string,
    t: TFunction,
  ): Promise<Array<IGroupedAnalystQuestionResult>> {
    const { selectedFacetValues, boundsFacetValues } = shape.props;

    const customShortcutRanges = await getCustomDateRanges(apolloClient, organizationId);
    const shortcutRelatedRanges = getShortcutRelatedRanges(
      [selectedFacetValues.eventDateShortcut || "", boundsFacetValues.eventDateShortcut || ""],
      customShortcutRanges || [],
      t,
    );
    const curatedFacetValues = getCuratedFacetValues(
      selectedFacetValues,
      boundsFacetValues,
      questionAnalysisFieldsConfig,
      shortcutRelatedRanges,
      "eventDate",
    );

    return getGroupedAnalystQuestionsData(apolloClient, curatedFacetValues.mergedFacetValues, organizationId, t);
  }

  // *********************************************
  // Public methods
  // *********************************************/
  /**
   * @inheritdoc
   */
  public async getData(
    input: IBoardSearchPlugInInputs,
  ): Promise<
    Array<
      | ITopicDiscussionExampleResult
      | ITopicDiscussionSummaryExampleResult
      | ITopicDiscussionInNewsArticleResult
      | IAnalystQuestionResult
      | IGroupedAnalystQuestionResult
    >
  > {
    const {
      apolloClient,
      editor,
      organizationId,
      shape: { parentId, type: shapeType },
      t,
    } = input;

    // Ensure that the parent shape exists
    const parentShape = editor.getShape(parentId);
    if (!parentShape) {
      return [];
    }

    // Ensure that the parent has the child of the shape type
    const childShapeTypes = editor.getSortedChildIdsForParent(parentId).map((id) => editor.getShape(id)!.type);
    if (!childShapeTypes.includes(shapeType)) {
      return [];
    }

    // Get the data based on the parent shape type
    switch (parentShape.type) {
      case TopicDiscussionAnalysisUtil.type:
        return this.getTopicDiscussionAnalysisShapeData(
          apolloClient,
          parentShape as ITopicDiscussionAnalysisShape,
          organizationId,
          t,
        );
      case TopicDiscussionSummaryAnalysisUtil.type:
        return this.getTopicDiscussionSummaryAnalysisShapeData(
          apolloClient,
          parentShape as ITopicDiscussionSummaryAnalysisShape,
          organizationId,
          t,
        );
      case TopicDiscussionInNewsArticleAnalysisUtil.type:
        return this.getTopicDiscussionInNewsArticleAnalysisShapeData(
          apolloClient,
          parentShape as ITopicDiscussionInNewsArticleAnalysisShape,
          organizationId,
          t,
        );
      case AnalystQuestionAnalysisUtil.type:
        return this.getAnalystQuestionAnalysisShapeData(
          apolloClient,
          parentShape as IAnalystQuestionAnalysisShape,
          organizationId,
          t,
        );
      case QuestionAnalysisUtil.type:
        return this.getQuestionAnalysisShapeData(apolloClient, parentShape as IQuestionAnalysisShape, organizationId, t);
      default:
        return [];
    }
  }
}
