import { Box, Grid, Typography, Skeleton } from "@mui/material";

/**
 * Gives a skeleton loader for a bar chart
 *
 * @returns Box with skeleton loaders
 */
export function BarChartLoader() {
  return (
    <Box>
      <Skeleton sx={{ width: 100 }} />
      <Grid container sx={{ padding: 4 }}>
        {Array.from({ length: 10 }).map((v, index) => {
          return (
            <Grid key={index} container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid item xs={2}>
                <Skeleton animation={false} />
              </Grid>
              <Grid item xs={10 - index}>
                <Typography variant="overline">
                  <Skeleton animation={false} />
                </Typography>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
