import { IPlugIn } from "@bigpi/cookbook";
import { IChatActionPlugInInputs } from "./IChatActionPlugInInputs";
import { ChatMessageAction } from "../../ChatMessageActions";

export abstract class ChatActionPlugInBase implements IPlugIn<IChatActionPlugInInputs, undefined | JSX.Element> {
  // *********************************************
  // Public properties
  // *********************************************/
  /**
   * @inheritdoc
   */
  abstract name: ChatMessageAction;

  // *********************************************
  // Public methods
  // *********************************************/
  /**
   * @inheritdoc
   */
  public abstract execute(input: IChatActionPlugInInputs): Promise<undefined | JSX.Element>;

  /**
   * @inheritdoc
   */
  public async destroy(): Promise<void> {
    // Do nothing in base class
  }
}
