import { WebSocketProvider } from "@bigpi/y-websocket";
import CollaborationCursor from "@tiptap/extension-collaboration-cursor";
import { EditorOptions } from "@tiptap/react";
import { DependencyList, useEffect, useState } from "react";

import { Collaboration } from "../Extensions/Collaboration/CollaborationExtension.js";
import { EditorContentError } from "./EditorContentError.js";
import { EditorUser } from "./useCollaborativeEditor.js";
import { useEditor } from "./useEditor.js";

export interface CollaborativeBoardDocumentEditorConfig {
  editorUser: EditorUser;
  provider: WebSocketProvider;
  yDocumentFragment: any;
}

export const useCollaborativeBoardDocumentEditor = (
  editorBaseOptions: Partial<EditorOptions> | undefined,
  collaborationConfig: CollaborativeBoardDocumentEditorConfig,
  deps: DependencyList = [],
) => {
  const { editorUser, provider, yDocumentFragment } = collaborationConfig;

  const [contentError, setContentError] = useState<EditorContentError | null>(null);
  const [editorOptions, setEditorOptions] = useState<Partial<EditorOptions>>();

  // Create the editor once the document is synced
  useEffect(() => {
    if (editorBaseOptions) {
      const user = {
        color: editorUser.color,
        name: editorUser.name,
      };

      // Set the editor options and trigger its (initial) creation/render
      setEditorOptions({
        ...editorBaseOptions,
        enableContentCheck: true,
        extensions: [
          ...(editorBaseOptions?.extensions || []),
          Collaboration.configure({
            fragment: yDocumentFragment,
          }),
          CollaborationCursor.configure({
            provider: provider,
            // VERY IMPORTANT: Do not add extra fields to the `user` property. They will get synced across all clients!
            user,
          }),
        ],
        onContentError: ({ editor, error, disableCollaboration }) => {
          // Disable collaboration to prevent syncing invalid content
          disableCollaboration();

          // Prevent emitting updates
          const emitUpdate = false;

          // Disable further user input
          editor.setEditable(false, emitUpdate);

          // Set the error message
          setContentError(EditorContentError.InvalidContent);
          console.error("Invalid HTML content", error);
        },
      });
    }
  }, [editorBaseOptions, editorUser, provider, yDocumentFragment]);

  const collaborativeEditor = useEditor(editorOptions, deps);

  return {
    collaborativeEditor,
    contentError,
  };
};
