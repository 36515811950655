import {
  DefaultQuickActions,
  TldrawUiMenuItem,
  useActions,
  useCanRedo,
  useCanUndo,
  useEditor,
  useReadonly,
  useValue,
} from "@tldraw/tldraw";

/**
 * Cloned from https://github.com/tldraw/tldraw/blob/9ffd7f15ee487a4c33b983f03e366475c908a91b/packages/tldraw/src/lib/ui/hooks/menu-hooks.ts#L120
 * without any modifications because this is not exported out from TLDraw.
 *
 * Returns true if the number of UNLOCKED selected shapes is at least min or at most max.
 */
function useUnlockedSelectedShapesCount(min?: number, max?: number) {
  const editor = useEditor();
  return useValue(
    "selectedShapes",
    () => {
      const len = editor.getSelectedShapes().filter((s) => !editor.isShapeOrAncestorLocked(s)).length;
      if (min === undefined) {
        if (max === undefined) {
          // just length
          return len;
        } else {
          // max but no min
          return len <= max;
        }
      } else {
        if (max === undefined) {
          // min but no max
          return len >= min;
        } else {
          // max and min
          return len >= min && len <= max;
        }
      }
    },
    [editor],
  );
}

/**
 * Cloned from https://github.com/tldraw/tldraw/blob/9ffd7f15ee487a4c33b983f03e366475c908a91b/packages/tldraw/src/lib/ui/components/QuickActions/DefaultQuickActionsContent.tsx#L8
 * and made some modifications. Changes -
 *
 * - Disable `undo` and `redo` buttons if an `htmlDocument` shape is being edited - https://app.asana.com/0/1206533986827299/1205470617445198/f
 */
function QuickActionsContent() {
  const actions = useActions();
  const editor = useEditor();
  const canUndo = useCanUndo();
  const canRedo = useCanRedo();
  const oneSelected = useUnlockedSelectedShapesCount(1);
  const editingShape = useValue("editingShapeIds", () => editor.getEditingShape(), [editor]);

  const isReadonlyMode = useReadonly();
  const isInAcceptableReadonlyState = useValue("should display quick actions", () => editor.isInAny("select", "hand", "zoom"), [
    editor,
  ]);
  if (isReadonlyMode && !isInAcceptableReadonlyState) {
    return;
  }

  const isEditingHtmlDocumentShape = editingShape && editingShape.type === "htmlDocument";

  return (
    <>
      {/* Modified by BigPi - Disable `undo` and `redo` buttons if an `htmlDocument` shape is being edited.
      https://app.asana.com/0/1206533986827299/1205470617445198/f */}
      <TldrawUiMenuItem {...actions["undo"]} disabled={!canUndo || isEditingHtmlDocumentShape} />
      <TldrawUiMenuItem {...actions["redo"]} disabled={!canRedo || isEditingHtmlDocumentShape} />

      <TldrawUiMenuItem {...actions["delete"]} disabled={!oneSelected} />
      <TldrawUiMenuItem {...actions["duplicate"]} disabled={!oneSelected} />
    </>
  );
}

export function QuickActions() {
  return (
    <DefaultQuickActions>
      <QuickActionsContent />
    </DefaultQuickActions>
  );
}
