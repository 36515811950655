import { Mark, ScaleFunctions, ChannelValues, Dimensions, Context } from "@observablehq/plot";
import { ScaleIdentity } from "d3";
import * as d3 from "d3";

export type RenderFunction = (
  /** The mark’s (filtered and transformed) index. */
  index: number[],
  /** The plot’s scale functions. */
  scales: ScaleFunctions,
  /** The mark’s (possibly scaled and transformed) channel values. */
  values: ChannelValues,
  /** The plot’s dimensions. */
  dimensions: Dimensions,
  /** The plot’s context. */
  context: Context,
) => SVGElement | null;

// This is the copy of "RenderableMark" from @observablehq/plot since that is not exported to use outisde.
export class RenderableMark extends Mark {
  data: any;
  render: RenderFunction = () => null;
}

/**
 * Source: https://observablehq.com/@fil/plot-onclick-experimental-plugin
 * @param mark
 * @param listeners
 * @returns
 */
export function on(mark: RenderableMark, listeners = {}) {
  const render = mark.render;
  mark.render = function (facet, values, channels) {
    const x: ScaleIdentity = values.x as ScaleIdentity;
    const y: ScaleIdentity = values.y as ScaleIdentity;
    const fx: ScaleIdentity = values.fx as ScaleIdentity;
    const fy: ScaleIdentity = values.fy as ScaleIdentity;

    const data = this.data;

    if (x && x.invert === undefined) x.invert = d3.scaleQuantize(x.range(), x.domain());
    if (y && y.invert === undefined) y.invert = d3.scaleQuantize(y.range(), y.domain());
    // @ts-expect-error TODO: Argument of type 'IArguments' is not assignable to parameter of type
    const g = render.apply(this, arguments);
    const r = d3.select(g).selectChildren();
    for (const [type, callback] of Object.entries(listeners)) {
      // @ts-ignore TODO: fix this
      r.on(type, (event: PointerEvent | MouseEvent, i: number) => {
        const p = d3.pointer(event, g);
        // @ts-ignore
        callback(event, {
          r,
          type,
          p,
          datum: data[i],
          i,
          facet,
          data,
          ...(x && { x: x.invert(p[0]) }),
          // @ts-ignore TODO: fix this
          ...(fx && { fx: facet.fx }),
          ...(y && { y: y.invert(p[1]) }),
          // @ts-ignore TODO: fix this
          ...(fy && { fy: facet.fy }),
          ...(x && channels.x1 && { x1: x.invert(channels.x1[i]) }),
          ...(x && channels.x2 && { x2: x.invert(channels.x2[i]) }),
          ...(y && channels.y2 && { y2: y.invert(channels.y2[i]) }),
        });
      });
    }
    return g;
  };
  return mark;
}
