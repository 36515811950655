import { BoldButtonItem } from "../Extensions/Bold/index.js";
import { BulletListButtonItem } from "../Extensions/BulletList/index.js";
import { DividerItem } from "../Extensions/Divider/index.js";
import { IndentButtonItem } from "../Extensions/Indent/index.js";
import { InsertImageButtonItem } from "../Extensions/Image/index.js";
import { InsertTableButtonItem } from "../Extensions/Table/index.js";
import { ItalicButtonItem } from "../Extensions/Italic/index.js";
import { OrderedListButtonItem } from "../Extensions/OrderedList/index.js";
import { OutdentButtonItem } from "../Extensions/Indent/index.js";
import { StrikethroughButtonItem } from "../Extensions/Strikethrough/index.js";
import { UnderlineButtonItem } from "../Extensions/Underline/index.js";
import { RedoButtonItem, UndoButtonItem } from "../Extensions/Collaboration/index.js";
import { CollapsibleBlockButtonItem } from "../Extensions/CollapsibleBlock/CollapsibleBlockToolbarItem.js";
import { LinkButtonItem } from "../Extensions/Link/LinkToolbarItem.js";
import { TextStyleSelectionItem } from "../Extensions/Heading/TextStyleSelectionToolbarItem.js";
import { HighlightButtonItem } from "../Extensions/TextStyle/HighlightToolbarItem.js";
import { TextColorButtonItem } from "../Extensions/TextStyle/TextColorToolbarItem.js";
import { TextAlignButtonItem } from "../Extensions/TextAlign/TextAlignToolbarItem.js";
import { SearchButtonItem } from "../Extensions/Search/SearchToolbarItem.js";
import { ToolbarFactory } from "./ToolbarFactory.js";

export function registerBuiltInToolbarItems() {
  ToolbarFactory.registerToolbarItems([
    new BoldButtonItem(),
    new BulletListButtonItem(),
    new CollapsibleBlockButtonItem(),
    new DividerItem(),
    new HighlightButtonItem(),
    new IndentButtonItem(),
    new InsertImageButtonItem(),
    new InsertTableButtonItem(),
    new ItalicButtonItem(),
    new LinkButtonItem(),
    new OrderedListButtonItem(),
    new OutdentButtonItem(),
    new RedoButtonItem(),
    new SearchButtonItem(),
    new StrikethroughButtonItem(),
    new TextAlignButtonItem(),
    new TextColorButtonItem(),
    new TextStyleSelectionItem(),
    new UnderlineButtonItem(),
    new UndoButtonItem(),
  ]);
}
