import { Box } from "@mui/material";
import type { Reducer } from "@observablehq/plot";

import { getBarChartPlot } from "Components/Charting/Elements/BarChartPlot";

import { useEffect, useRef } from "react";

// css
import "./BarChart.css";

interface BarChartProps {
  direction: "horizontal" | "vertical";
  data: Array<Record<string, any>>;
  facets: { selectedValues?: Array<string> };
  metadata: any;
  xField: string;
  yField: string;
  textField?: string;
  groupReducer?: Reducer | string | undefined;
  textReducer?: Reducer | string | undefined;
  barMarkOptions: any;
  textMarkOptions?: any;
  onBarClick: (e: PointerEvent, result: Record<string, any>) => void;
}

export function BarChart(props: BarChartProps) {
  const {
    direction,
    data,
    facets,
    metadata,
    xField,
    yField,
    textField,
    groupReducer,
    textReducer,
    barMarkOptions,
    textMarkOptions,
    onBarClick,
  } = props;
  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (chartRef.current && chartRef.current.innerHTML) {
      chartRef.current.innerHTML = "";
    }
    chartRef.current?.appendChild(_generateBarChart());
  }, [facets, metadata, data]);

  return (
    <Box>
      <div ref={chartRef} className="bar-chart"></div>
    </Box>
  );

  // *********************************************
  // Private functions
  // *********************************************/
  /**
   * Gives the bar chart plot element
   * @param facets Latest facets
   * @returns
   */
  function _generateBarChart(): HTMLElement {
    const chart = getBarChartPlot(
      direction,
      data,
      facets,
      metadata,
      xField,
      yField,
      textField,
      groupReducer,
      textReducer,
      barMarkOptions,
      textMarkOptions,
      onBarClick,
    );
    return chart as HTMLElement;
  }
}
