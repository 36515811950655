import React, { useEffect, useRef } from "react";

interface AutoResizingContainerProps {
  children: React.ReactNode;
  onResize?: (width: number, height: number) => void;
  style?: React.CSSProperties;
}

/**
 * A wrapper container component that raises a resize event when the size changes.
 * This allows notifying the parent if the size of the container and its children change.
 *
 * @param props The props for the component.
 *
 * @returns The rendered component with children.
 */
export const AutoResizingContainer: React.FC<AutoResizingContainerProps> = (props) => {
  const { children, onResize, style } = props;
  const containerRef = useRef<HTMLDivElement>(null);

  // Raise a resize event when the size of the container changes
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      // Delay processing to ensure we don't end up in an infinite loop
      window.requestAnimationFrame(() => {
        window.requestAnimationFrame(() => {
          if (!Array.isArray(entries) || !entries.length) {
            return;
          }
          for (const entry of entries) {
            if (entry.borderBoxSize) {
              const borderBoxSize = entry.borderBoxSize[0];
              if (onResize && containerRef.current) {
                onResize(borderBoxSize.inlineSize, borderBoxSize.blockSize);
              }
            }
          }
        });
      });
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, []);

  return (
    <div ref={containerRef} style={style}>
      {children}
    </div>
  );
};
