import { BoardSearchFieldTypes } from "@bigpi/cookbook";

import { SHAPE_DATA_QUERY } from "GraphQL/ShapeData";
import { DataUtils } from "Utils/DataUtils";
import { IBoardSearchPlugInInputs } from "../BoardSearchManager";
import { DataGridBoardSearchPlugInBase } from "./DataGridBoardSearchPlugInBase";

export class DataGridServerDatastoreBoardSearchPlugIn extends DataGridBoardSearchPlugInBase {
  // *********************************************
  // Public properties
  // *********************************************/
  /**
   * @inheritdoc
   */
  name = BoardSearchFieldTypes.DataGridServerDatastore;

  // *********************************************
  // Public methods
  // *********************************************/
  /**
   * @inheritdoc
   */
  public async getData(input: IBoardSearchPlugInInputs): Promise<Array<Record<string, any>>> {
    const {
      apolloClient,
      shape: { id: shapeId },
      workspaceBoardId,
    } = input;

    const apiResponse = await apolloClient.query({
      query: SHAPE_DATA_QUERY,
      variables: {
        shapeId,
        workspaceBoardId,
      },
    });

    return apiResponse.data?.shapeData?.data
      ? (JSON.parse(apiResponse.data.shapeData.data) as Array<Record<string, unknown>>)
      : DataUtils.getImmutableEmptyArray<Record<string, any>>();
  }
}
