import { ContentTypes } from "@bigpi/cookbook";
import ArticleIcon from "@mui/icons-material/Article";
import HtmlIcon from "@mui/icons-material/Html";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

import "./FileIcon.css";

export interface IFileIconProps {
  mimetype: string;
}

export function FileIcon(props: IFileIconProps) {
  const { mimetype } = props;

  switch (mimetype) {
    case ContentTypes.PDF:
      return <PictureAsPdfIcon className="file-avatar" />;

    case ContentTypes.DOC:
    case ContentTypes.DOCX:
      return <InsertDriveFileIcon className="file-avatar" />;

    case ContentTypes.PLAIN_TEXT:
    case ContentTypes.RTF:
      return <ArticleIcon className="file-avatar" />;

    case ContentTypes.HTML:
      return <HtmlIcon className="file-avatar" />;

    default:
      return <InsertDriveFileIcon className="file-avatar" />;
  }
}
