import { AddToBoardType, FileSourceType } from "@bigpi/cookbook";
import { Box, Typography } from "@mui/material";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { FileItemCompact } from "Components/FileItem/FileItemCompact";
import { LibraryBundleItemCompact } from "Components/LibraryBundleItem/LibraryBundleItemCompact";
import { SplitButton } from "Components/SplitButton/SplitButton";
import { FileBundleViewType, FileBundleViewQuery } from "GraphQL/Generated/Apollo";

interface ILibraryListProps {
  data: FileBundleViewQuery["fileBundleView"];
  onAddPreviewToBoard: (id: string, fileSourceType: FileSourceType) => void;
}

export function LibraryList(props: ILibraryListProps) {
  const { data, onAddPreviewToBoard } = props;
  const { t } = useTranslation();

  const onSplitButtonClick = useCallback(
    (option: string, id: string) => {
      switch (option) {
        case AddToBoardType.AddPreview:
          onAddPreviewToBoard(id, FileSourceType.Library);
          break;
        default:
          break;
      }
    },
    [onAddPreviewToBoard],
  );

  return (
    <Box>
      <Typography variant="subtitle2">{t("Components.AddToBoard.SectionHeader.File")}</Typography>
      {data.map((file) => {
        if (file.id && file.name && file.mimeType && file.type === FileBundleViewType.File) {
          return (
            <FileItemCompact
              fileCreatedAt={file.createdAt}
              fileId={file.id}
              fileMimeType={file.mimeType}
              fileName={file.name}
              fileSourceType={FileSourceType.Library}
              key={file.id}
              secondaryAction={getSecondaryAction}
            />
          );
        } else if (file.id && file.name && file.type === FileBundleViewType.Bundle) {
          return (
            <LibraryBundleItemCompact
              bundleCreatedAt={file.createdAt}
              bundleId={file.id}
              bundleName={file.name}
              key={file.id}
              listItemProps={{
                secondaryAction: getSecondaryAction,
              }}
            />
          );
        }
      })}
    </Box>
  );

  /**
   * Gets the secondary action with SplitButton.
   *
   * @param id Id of the file.
   *
   * @returns SplitButton component with AddPreview action.
   */
  function getSecondaryAction(id: string) {
    return (
      <SplitButton
        handleClick={(option) => onSplitButtonClick(option, id)}
        options={[{ value: AddToBoardType.AddPreview, label: t("Components.AddToBoard.Actions.AddPreview") }]}
      />
    );
  }
}
