import { AddOutlined } from "@mui/icons-material";
import { Alert, Box, Button, Divider, Grid, TextField, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { WorkspaceTagsList } from "Components/WorkspaceTagsList/WorkspaceTagsList";
import { useUpdateWorkspaceMutation, useWorkspaceQuery } from "GraphQL/Generated/Apollo";

export interface IEditWorkspaceMetadataProps {
  workspaceId: string;
  onClose: () => void;
}

export function EditWorkspaceMetadata(props: IEditWorkspaceMetadataProps) {
  const { workspaceId, onClose } = props;

  const { t } = useTranslation();

  const [errorMessage, setErrorMessage] = useState("");
  const [oldWorkspaceName, setOldWorkspaceName] = useState("");
  const [workspaceName, setWorkspaceName] = useState("");
  const [oldTagsList, setOldTagsList] = useState<Array<string>>([]);
  const [tagsList, setTagsList] = useState<Array<string>>([]);
  const [newTag, setNewTag] = useState("");
  const [workspaceDescription, setWorkspaceDescription] = useState("");
  const [oldWorkspaceDescription, setOldWorkspaceDescription] = useState("");

  const { data, loading, error } = useWorkspaceQuery({ variables: { id: workspaceId } });
  const [updateWorkspace] = useUpdateWorkspaceMutation();

  useEffect(() => {
    if (data?.workspace?.name) {
      setWorkspaceName(data.workspace?.name || "");
      setOldWorkspaceName(data.workspace?.name || "");
    }

    setTagsList(data?.workspace?.tags || []);
    setOldTagsList(data?.workspace?.tags || []);

    setWorkspaceDescription(data?.workspace?.description || "");
    setOldWorkspaceDescription(data?.workspace?.description || "");
  }, [data]);

  function onAddTag() {
    const newTagsList = [...tagsList, newTag];
    setTagsList(newTagsList);
    setNewTag("");
  }

  const onRemoveTag = (tagToRemove: string) => {
    const newTagsList = tagsList.filter((tag) => tag !== tagToRemove);
    setTagsList(newTagsList);
  };

  const isDataChanged = useMemo(() => {
    const isTagListChanged = tagsList.length !== oldTagsList.length || tagsList.some((tag) => !oldTagsList.includes(tag));
    return workspaceName !== oldWorkspaceName || isTagListChanged || workspaceDescription !== oldWorkspaceDescription;
  }, [workspaceName, oldWorkspaceName, tagsList, oldTagsList, workspaceDescription, oldWorkspaceDescription]);

  const onSave = () => {
    updateWorkspace({
      variables: {
        input: {
          id: workspaceId,
          name: workspaceName,
          tags: tagsList,
          description: workspaceDescription,
        },
      },
      refetchQueries: ["Workspace", "Workspaces"],
    })
      .then(() => {
        onClose();
      })
      .catch((error) => {
        console.error(error);
        setErrorMessage(t("Components.EditWorkspaceMetadata.Error"));
      });
  };

  return (
    <>
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}

      <Grid container spacing={2}>
        <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body1">{t("Components.EditWorkspaceMetadata.NameLabel")}</Typography>
        </Grid>

        <Grid item xs={6}>
          <TextField
            fullWidth
            autoFocus={true}
            margin="dense"
            type="text"
            variant="outlined"
            value={workspaceName}
            onChange={(e) => setWorkspaceName(e.target.value ?? "")}
          />
        </Grid>
      </Grid>

      <Divider sx={{ my: 2 }} />

      <Grid container spacing={2}>
        <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body1">{t("Components.EditWorkspaceMetadata.TagsLabel")}</Typography>
        </Grid>

        <Grid item xs={6} sx={{ display: "flex", flexDirection: "column" }}>
          <Grid item xs={12}>
            <WorkspaceTagsList tagsList={tagsList} onRemoveTag={onRemoveTag} containerSxProps={{ mb: 1 }} />
          </Grid>

          <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
            <Grid item xs={9}>
              <TextField
                fullWidth
                margin="dense"
                type="text"
                variant="outlined"
                value={newTag}
                onChange={(e) => setNewTag(e.target.value ?? "")}
                inputProps={{
                  maxLength: 30,
                }}
              />
            </Grid>

            <Grid item xs={3} sx={{ ml: 2 }}>
              <Button onClick={onAddTag} disabled={newTag === ""} startIcon={<AddOutlined />} fullWidth>
                {t("Components.EditWorkspaceMetadata.AddTag")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Divider sx={{ my: 2 }} />

      <Grid container spacing={2}>
        <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body1">{t("Components.EditWorkspaceMetadata.DescriptionLabel")}</Typography>
        </Grid>

        <Grid item xs={6}>
          <TextField
            fullWidth
            margin="dense"
            type="text"
            variant="outlined"
            value={workspaceDescription}
            onChange={(e) => setWorkspaceDescription(e.target.value ?? "")}
            multiline
          />
        </Grid>
      </Grid>

      <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
        <Button onClick={onClose}>{t("Global.Action.Close")}</Button>
        <Button onClick={onSave} disabled={!isDataChanged}>
          {t("Components.EditWorkspaceMetadata.SaveChanges")}
        </Button>
      </Box>
    </>
  );
}
