import { gql } from "@apollo/client";

export const ANALYST_QUESTION_QUERY = gql`
  query AnalystQuestion($id: ID!) {
    analystQuestion(id: $id) {
      id
      eventDate
      firm
      firmType
      question
      segment
      topics
    }
  }
`;

export const ANALYST_QUESTIONS_QUERY = gql`
  query AnalystQuestions(
    $facets: AnalystQuestionFacetsInput!
    $numberOfClusters: Int
    $otherGroupLabel: String!
    $version: String
  ) {
    analystQuestions(facets: $facets, numberOfClusters: $numberOfClusters, otherGroupLabel: $otherGroupLabel, version: $version) {
      id
      eventDate
      firm
      firmType
      question
      segment
      topics
      group
      analystQuestionClusterAssignment {
        analystQuestionCluster {
          name
        }
      }
    }
  }
`;

export const GROUPED_ANALYST_QUESTIONS_QUERY = gql`
  query GroupedAnalystQuestions(
    $facets: AnalystQuestionFacetsInput!
    $numberOfClusters: Int!
    $otherGroupLabel: String!
    $version: String!
  ) {
    groupedAnalystQuestions(
      facets: $facets
      numberOfClusters: $numberOfClusters
      otherGroupLabel: $otherGroupLabel
      version: $version
    ) {
      id
      eventDate
      firm
      firmType
      question
      segment
      topics
      group
    }
  }
`;
