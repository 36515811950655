import { DataGridDetailsPanelFormat } from "@bigpi/cookbook";
import { IDataGridConfig, IDataGridPreferences, IDataGridColumnDef } from "@bigpi/tl-schema";
import { Typography } from "@mui/material";
import {
  DataGridPremium,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridSortModel,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import { useValue } from "@tldraw/tldraw";
import { ForwardedRef, forwardRef, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";

import { ITopicDiscussionSummaryExampleResult, ITopicDiscussionExampleResult } from "BoardComponents/BoardDatastore";
import { DataGridDetailsPanelMapping, generateColumnsFromConfig } from "BoardComponents/DataFormatting/DataFormatting";
import { TopicDiscussionSummaryOverviewLabel } from "Components/TopicDiscussionSummaryOverviewLabel/TopicDiscussionSummaryOverviewLabel";

import "./TopicDiscussionSummaryMasterDetails.css";

export interface ITopicDiscussionSummaryMasterDetailsProps {
  columns: IDataGridConfig["columns"];
  preferences: IDataGridPreferences;
  topicDiscussionSummaryDetails: Array<ITopicDiscussionSummaryExampleResult | ITopicDiscussionExampleResult>;
}

export interface ITopicDiscussionSummaryMasterDetailsRef {
  exportDataAsExcel: () => void;
  selectedRowIds: Array<string>;
}

export const TopicDiscussionSummaryMasterDetails = forwardRef(function (
  props: ITopicDiscussionSummaryMasterDetailsProps,
  ref: ForwardedRef<ITopicDiscussionSummaryMasterDetailsRef>,
) {
  const { columns, preferences, topicDiscussionSummaryDetails } = props;
  const apiRef = useGridApiRef();
  const { t } = useTranslation();
  const [selectedRowIds, setSelectedRowIds] = useState<Array<string>>([]);

  const columnVisibility = useValue(
    "datagridShape.columnVisibility",
    () => {
      return getColumnsVisibilityModel(columns);
    },
    [columns],
  );

  useImperativeHandle(
    ref,
    () => {
      return {
        exportDataAsExcel: () => {
          apiRef.current?.exportDataAsExcel();
        },
        selectedRowIds,
      };
    },
    [apiRef, selectedRowIds],
  );

  if (topicDiscussionSummaryDetails.length === 0) {
    return <Typography variant="body1">{t("Components.TopicDiscussionSummaryMasterDetails.NoData")}</Typography>;
  }

  return (
    <>
      <TopicDiscussionSummaryOverviewLabel topicDiscussionSummaryDetails={topicDiscussionSummaryDetails} />
      <DataGridPremium
        apiRef={apiRef}
        sx={{ mt: 2 }}
        classes={{
          cell: "topic-discussion-summary-master-details-datagrid__cell",
          root: "topic-discussion-summary-master-details-datagrid",
          row: "topic-discussion-summary-master-details-datagrid__row",
        }}
        getRowId={(row) => row.id}
        getRowHeight={() => "auto"}
        rows={topicDiscussionSummaryDetails}
        columns={generateColumnsFromConfig(t, columns, false, true)}
        getDetailPanelHeight={() => "auto"}
        getDetailPanelContent={({ row }) =>
          DataGridDetailsPanelMapping[DataGridDetailsPanelFormat.TopicDiscussionSummaryMasterDetails](row)
        }
        initialState={{
          sorting: {
            sortModel: preferences.sortModel as GridSortModel | undefined,
          },
          pinnedColumns: preferences.pinnedColumns,
          filter: {
            filterModel: preferences.filterModel as GridFilterModel | undefined,
          },
          columns: {
            columnVisibilityModel: columnVisibility as GridColumnVisibilityModel | undefined,
          },
          rowGrouping: {
            model: preferences.rowGroupingModel,
          },
        }}
        disableColumnMenu
        disableColumnResize
        // Row selection
        checkboxSelection
        disableRowSelectionOnClick
        rowSelectionModel={selectedRowIds}
        onRowSelectionModelChange={(rowSelectionModel) => setSelectedRowIds(rowSelectionModel as Array<string>)}
      />
    </>
  );

  /**
   * Gives visibility model for columns
   *
   * @param columns Columns to get visibility model for
   * @returns
   */
  function getColumnsVisibilityModel(columns: IDataGridConfig["columns"]) {
    if (!columns || columns.length === 0) {
      return {};
    }
    const visibleColumns: GridColumnVisibilityModel = {};
    columns.forEach((column: IDataGridColumnDef) => {
      if (column.isVisible === false) {
        visibleColumns[column.field] = false;
      }
    });
    return visibleColumns;
  }
});
