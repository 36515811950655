import {
  IHistogramChartShape,
  getHistogramChartShapeDefaultProps,
  histogramChartShapeProps,
  histogramChartShapeMigrations,
} from "@bigpi/tl-schema";
import { Grid, FormControl, InputLabel, Select, MenuItem, ListItemText } from "@mui/material";
import { HTMLContainer, useValue, Migrations } from "@tldraw/tldraw";
import * as React from "react";
import { useTranslation } from "react-i18next";

import { IAnalysisShapeData, useBoardDatastore } from "BoardComponents/BoardDatastore";
import { BoxBaseUtil } from "BoardComponents/BaseShapes/BoxBaseUtil";
import { useIsInteracting } from "BoardComponents/Tools";
import { useShapeEvents } from "BoardComponents/useShapeEvents";
import { HistogramChart } from "Components/Charting/Charts/HistogramChart";
import { HistogramChartLoader } from "Components/Charting/Loaders/HistogramChartLoader";
import { ChartUtils } from "Utils/ChartUtils";
import { DataUtils } from "Utils/DataUtils";
import "./HistogramChartShape.css";

// *********************************************
// Shape Definition
// *********************************************/
const TIME_SCALE_OPTIONS = [
  { name: "Days", value: "utcDay" },
  { name: "Weeks", value: "utcWeek" },
  { name: "Months", value: "utcMonth" },
];

const DEFAULT_TIME_SCALE = "utcWeek";

/**
 * Padding to add around the chart to ensure that the warning message is visible and isn't on the edge of the chart.
 */
const WARNING_PADDING = 20;

// *********************************************
// Shape Util
// *********************************************/
/**
 * Generator for generic bubble chart shapes that support grouping.
 */
export class HistogramChartUtil extends BoxBaseUtil<IHistogramChartShape> {
  // *********************************************
  // Static fields
  // *********************************************/
  static type = "histogramChart";

  static props = histogramChartShapeProps;

  static migrations?: Migrations = histogramChartShapeMigrations;

  // *********************************************
  // Override methods
  // *********************************************/
  /**
   * @inheritdoc
   */
  override canBind = (shape: IHistogramChartShape) => true;

  /**
   * @inheritdoc
   */
  override canEdit = (shape: IHistogramChartShape) => true;

  /**
   * @inheritdoc
   */
  override canResize = (shape: IHistogramChartShape) => true;

  /**
   * @inheritdoc
   */
  override canScroll = (shape: IHistogramChartShape) => false;

  /**
   * @inheritdoc
   */
  override isAspectRatioLocked = (shape: IHistogramChartShape) => false;

  /**
   * @inheritdoc
   */
  override getDefaultProps(): IHistogramChartShape["props"] {
    return getHistogramChartShapeDefaultProps();
  }

  /**
   * @inheritdoc
   */
  override indicator(shape: IHistogramChartShape) {
    const { id } = shape;
    const isEditing = this.editor.getCurrentPageState().editingShapeId === id;
    return <rect width={shape.props.w} height={shape.props.h} stroke={isEditing ? "transparent" : ""} />;
  }

  /**
   * @inheritdoc
   */
  component(shape: IHistogramChartShape) {
    const tldrawEditor = this.editor;
    const { props } = shape;
    const isEditing = useValue("histogramChart.isEditing", () => tldrawEditor.getCurrentPageState().editingShapeId === shape.id, [
      tldrawEditor,
      shape.id,
    ]);
    const isInteracting = useIsInteracting(shape.id);
    const parentId = useValue("histogramChart.parentId", () => tldrawEditor.getShape(shape.id)!.parentId, [shape.id]);
    const datastore = useBoardDatastore();
    const widthRef = React.useRef<number>(shape.props.w);
    const facetsRef = React.useRef<Record<string, any>>({});
    const { t } = useTranslation();

    const { handleInputPointerDown } = useShapeEvents(shape.id);

    React.useEffect(() => {
      if (widthRef.current) {
        this.editor.updateShapes([
          {
            id: shape.id,
            type: shape.type,
            props: {
              w: widthRef.current,
            },
          },
        ]);
      }
    }, [widthRef.current]);

    // Get the parent data from the datastore (without strong types)
    const parentData = useValue(
      "histogramChart.parentData",
      () =>
        datastore.state.get()[parentId]?.get() as IAnalysisShapeData<
          Array<Record<string, any>>,
          Record<string, any>,
          Record<string, any>,
          Record<string, any>
        >,
      [datastore, parentId],
    );

    // Get the filtered data from the datastore
    const slotsData: Record<string, any> =
      parentData?.slotsData?.get() || DataUtils.getImmutableEmptyObject<Record<string, any>>();
    const dataLoading = parentData?.dataLoading?.get() || false;
    const configLoading = parentData?.configLoading?.get() || false;
    const onFieldFacetManagerFieldChange = parentData?.onFieldFacetManagerFieldChange?.get();
    const onSelectionChange = parentData?.onSelectionChange?.get();
    // Get the other required data, such as facets and config from the datastore
    const facets = parentData?.facets.get() || DataUtils.getImmutableEmptyObject<Record<string, any>>();
    const selection = parentData?.selection?.get() || DataUtils.getImmutableEmptyObject<Record<string, any>>();
    const config = parentData?.config.get() || DataUtils.getImmutableEmptyObject<Record<string, any>>();

    const chartMetadata = useValue(
      "chartMetadata",
      () => {
        if (!config || !config.components) {
          return {};
        }
        const fieldConfig = config.components[props.field];
        const fieldShapeConfig = fieldConfig.config;
        return {
          ...fieldShapeConfig.metadata,
          width: shape.props.w,
          height: shape.props.h - 100,
        };
      },
      [config, props.field, shape.props.w, shape.props.h],
    );

    const selectedFacets = useValue(
      "selectedFacets",
      () => {
        return {
          selectedValues: facetsRef.current[props.field]
            ? [ChartUtils.stringifyDateRange(facetsRef.current[props.field])]
            : undefined,
        };
      },
      [facets],
    );

    const chartMarkOptions = useValue(
      "chartMarkOptions",
      () => {
        if (!config || !config.components) {
          return {};
        }
        const fieldConfig = config.components[props.field];
        const fieldShapeConfig = fieldConfig.config;
        const fill = fieldShapeConfig.markOptions.fill;
        return {
          ...fieldShapeConfig.markOptions,
          interval: selection.selectedTimeScale || DEFAULT_TIME_SCALE,
          fill: typeof fill === "string" ? fill : (dataItem: any) => fill(facetsRef.current, dataItem),
        };
      },
      [config, selection, facets],
    );

    React.useEffect(() => {
      facetsRef.current = facets;
    }, [facets]);

    if (!parentData) {
      return (
        <HTMLContainer
          id={shape.id}
          style={{
            background: "#fff",
            display: "block",
            pointerEvents: "all",
            padding: WARNING_PADDING,
          }}
        >
          {t("Components.Charts.NoParent")}
        </HTMLContainer>
      );
    }

    if (dataLoading || configLoading) {
      return (
        <HTMLContainer
          id={shape.id}
          style={{
            background: "#fff",
            display: "block",
            pointerEvents: "all",
            overflow: "hidden",
          }}
        >
          <HistogramChartLoader />
        </HTMLContainer>
      );
    }

    if (!config.components) {
      return (
        <HTMLContainer
          id={shape.id}
          style={{
            background: "#fff",
            display: "block",
            pointerEvents: "all",
            padding: WARNING_PADDING,
          }}
        >
          {t("Components.Charts.NoConfigAvailable")}
        </HTMLContainer>
      );
    }

    const relatedData = slotsData[props.field] || DataUtils.getImmutableEmptyArray<Record<string, any>>();
    const fieldConfig = config.components[props.field];
    const fieldShapeConfig = fieldConfig.config;
    const fill = fieldShapeConfig.markOptions.fill;

    return (
      <HTMLContainer
        className={`histogram-chart-shape ${isEditing ? "" : "no-events"}`}
        id={shape.id}
        style={{
          background: "transparent",
          display: "block",
          pointerEvents: "all",
        }}
      >
        <div
          style={{
            pointerEvents: isEditing || isInteracting ? "auto" : "none",
          }}
          onPointerDown={handleInputPointerDown}
        >
          {!relatedData || relatedData.length === 0 ? (
            <div style={{ width: "100%", height: "100%", background: "#fff", padding: WARNING_PADDING }}>
              {t("Components.Charts.NoData")}
            </div>
          ) : (
            <>
              <Grid container direction="row" justifyContent="space-between">
                <Grid item sx={{ marginRight: 3, paddingRight: 3 }}>
                  <div className="histogram-chart-shape-label">
                    <label>{fieldConfig.label}</label>
                  </div>
                </Grid>
                <Grid item sx={{ marginRight: 3, paddingRight: 3 }}>
                  <FormControl sx={{ mt: 2, width: 150 }}>
                    <InputLabel id={`time-scale-${shape.id}`}>{t("Components.Charts.TimeScale")}</InputLabel>
                    <Select
                      sx={{ width: 150 }}
                      id={`topics-type-${shape.id}`}
                      label={t("Components.Charts.TimeScale")}
                      value={selection.selectedTimeScale || DEFAULT_TIME_SCALE}
                      onChange={(event) => {
                        onSelectionChange && onSelectionChange({ selectedTimeScale: event.target.value });
                      }}
                      inputProps={{
                        name: "topics",
                        id: "topics",
                      }}
                      renderValue={(selected) => TIME_SCALE_OPTIONS.find((scale) => scale.value === selected)?.name}
                      MenuProps={{
                        classes: { paper: "analysis-select-menu" },
                        MenuListProps: { classes: { root: "analysis-select-menu-list" } },
                      }}
                    >
                      {TIME_SCALE_OPTIONS.map((timeScale) => {
                        return (
                          <MenuItem
                            key={timeScale.value}
                            value={timeScale.value}
                            classes={{ selected: "analysis-select-menu-item" }}
                          >
                            <ListItemText primary={t(`Components.Charts.${timeScale.name}`)} />
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <HistogramChart
                markOptions={chartMarkOptions}
                data={relatedData}
                direction={fieldShapeConfig.direction}
                facets={selectedFacets}
                binReducer={fieldShapeConfig.binReducer}
                metadata={chartMetadata}
                onBrushSelection={(event, value) => {
                  onFieldFacetManagerFieldChange &&
                    onFieldFacetManagerFieldChange(props.field, {
                      dateRange: ChartUtils.parseStringToDateRange(value),
                    });
                }}
                xField={fieldShapeConfig.xField}
                yField={fieldShapeConfig.yField}
              />
            </>
          )}
        </div>
      </HTMLContainer>
    );
  }
}
