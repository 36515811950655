import {
  Badge,
  Box,
  Card,
  Grid,
  CardHeader,
  ButtonBase,
  Checkbox,
  CardMedia,
  CardActions,
  IconButton,
  Typography,
} from "@mui/material";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import { useValue } from "@tldraw/tldraw";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { formatFileCreatedAt } from "Utils/FileUtils";
import { FileBundleViewType, useFilesQuery } from "GraphQL/Generated/Apollo";
import { LibraryListMenu } from "./LibraryListMenu";

interface ILibraryGridBundleItemProps {
  bundleCreatedAt: string;
  bundleId: string;
  bundleName: string;
  onSelectionChange: (type: FileBundleViewType, id: string) => void;
  selectedFileIds: Array<string>;
  size: "small" | "medium" | "large";
}

export function LibraryGridBundleItem(props: ILibraryGridBundleItemProps) {
  const { bundleCreatedAt, bundleId, bundleName, onSelectionChange, selectedFileIds, size } = props;
  const { t } = useTranslation();
  const [activeGridElement, setActiveGridElement] = useState<HTMLElement | null>(null);
  const [isBundleExpanded, setIsBundleExpanded] = useState<boolean>(false);
  let itemImageComponent: JSX.Element | null = null;

  const { data: bundleFiles } = useFilesQuery({
    variables: {
      limit: 100,
      filters: {
        bundleId,
      },
    },
  });

  const handleMouseEnter = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setActiveGridElement(event.currentTarget);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setActiveGridElement(null);
  }, []);

  const isSelectionEnabled = Boolean(activeGridElement);

  const bundleFileIds = useValue(
    "bundleFileIds",
    () => {
      return bundleFiles?.files?.map((file) => file.id) || [];
    },
    [bundleFiles],
  );

  const onBundleSelectionChange = useCallback(() => {
    onSelectionChange(FileBundleViewType.Bundle, bundleId);
    bundleFileIds.forEach((fileId) => onSelectionChange(FileBundleViewType.File, fileId));
  }, [bundleId, bundleFileIds]);

  const isChecked = useValue(
    "isChecked",
    () => {
      return bundleFileIds.length > 0 && bundleFileIds.every((fileId) => selectedFileIds.includes(fileId));
    },
    [selectedFileIds, bundleFileIds],
  );

  if (isSelectionEnabled || isChecked) {
    itemImageComponent = <Checkbox checked={isChecked} onChange={onBundleSelectionChange} sx={{ padding: 0 }} />;
  } else {
    itemImageComponent = (
      <IconButton sx={{ padding: 0 }}>
        <Badge
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          badgeContent={bundleFiles?.fileAggregate.count}
          color="primary"
        >
          <FolderCopyIcon />
        </Badge>
      </IconButton>
    );
  }

  return (
    <Grid
      item
      xl={size === "small" ? 3 : size === "medium" ? 4 : 6}
      lg={size === "small" ? 2 : size === "medium" ? 3 : 4}
      md={size === "small" ? 3 : size === "medium" ? 4 : 6}
      xs={size === "small" ? 6 : size === "medium" ? 6 : 12}
      sx={{ display: "grid" }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Card
        variant="outlined"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          backgroundColor: isChecked ? "var(--astra-color-blue15)" : "var(--astra-color-gray7)",
          border: "none",
          ":hover": {
            backgroundColor: "var(--astra-color-gray15)",
          },
        }}
      >
        <CardHeader
          avatar={itemImageComponent}
          title={
            <ButtonBase sx={{ textAlign: "start" }} disableRipple onClick={() => setIsBundleExpanded(!isBundleExpanded)}>
              <Typography
                sx={{
                  textAlign: "start",
                  fontSize: size === "small" ? "1rem" : size === "medium" ? "1.1rem" : "1.2rem",
                  fontWeight: "500",
                  wordBreak: "break-word",
                }}
              >
                {bundleName}
              </Typography>
            </ButtonBase>
          }
          action={<LibraryListMenu fileId={bundleId} />}
        />
        <CardActions sx={{ display: "block", bottom: 0 }}>
          <Box sx={{ mt: 1 }}>
            <Typography variant="caption" color="text.secondary">
              {formatFileCreatedAt(bundleCreatedAt, t)}
            </Typography>
          </Box>
        </CardActions>
      </Card>
    </Grid>
  );
}
