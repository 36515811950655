import { CommandActionType } from "../CommandActionType.js";
import { CommandOutputType } from "../CommandOutputType.js";
import { ICommand } from "../ICommand.js";
import { DataType, EntityType } from "../ICommandInput.js";

export const COMMAND_GENERATE_DISTILLED_TRANSCRIPT_TEMPLATE: ICommand = {
  id: "d1c7606b-d75f-4b95-808f-9dbc22cf0a0a",
  name: "Generate Distilled Transcript",
  description: "",
  action: {
    actionType: CommandActionType.GenerateDistilledTranscriptAction,
    data: {},
  },
  inputs: [
    {
      dataType: {
        dataType: DataType.String,
        isArray: false,
        validation: "",
      },
      fieldName: "transcriptId",
      fieldNameAliases: [],
      entityType: EntityType.Transcript,
      defaultValue: "",
      label: "",
      visible: false,
      required: true,
    },
  ],
  inputTemplate: `{ "transcriptId": "{{ transcriptId }}" }`,
  isBlocking: false,
  outputTypes: [CommandOutputType.None],
};
