import { RawCommands } from "@tiptap/core";

import { getBaseClassWithoutHeadingAndTitle } from "../../Utils/CommandUtil.js";
import { ListItem } from "../ListItem/ListItemExtension.js";
import { Title } from "./TitleExtension.js";

// This command is used to set a title node.
export const setTitle: RawCommands["setTitle"] =
  () =>
  ({ chain, state }) => {
    const { $from } = state.selection;
    // "listItem" node represents the "li" tag which is present on
    // "-1" depth from the current selection i.e. the title node.
    //
    // <ol>
    //   <li>
    //     <p class="title">text</p>
    //   </li>
    // </ol>
    const listItem = $from.node(-1);
    if (listItem.type.name === ListItem.name) {
      const classAttr = getBaseClassWithoutHeadingAndTitle(listItem.attrs.class);
      const titleClass = `title`;
      const newClassAttr = classAttr ? `${classAttr} ${titleClass}`.trim() : titleClass;

      // This is required to apply the correct text style for the
      // list item marker.
      return chain().setNode(Title.name).selectParentNode().updateAttributes(ListItem.name, { class: newClassAttr }).run();
    }

    return chain().setNode(Title.name).run();
  };
