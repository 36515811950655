import { gql } from "@apollo/client";

export const UPDATE_TOPIC_DISCUSSION_IN_NEWS_ARTICLE = gql`
  mutation UpdateTopicDiscussionInNewsArticle($input: UpdateTopicDiscussionInNewsArticleInput!) {
    updateTopicDiscussionInNewsArticle(input: $input) {
      id
      theme
      topicDiscussions {
        topic
        summary
      }
      segmentId
      segmentType
      sourceType
      newSources {
        name
        url
      }
      date
      headline
      paragraph
      articleCount
    }
  }
`;
