import {
  IAnalysisFieldConfig,
  IDateRangeLimits,
  ISelectInputFieldOption,
  FacetDisplaySortType,
  IDateRange,
} from "@bigpi/cookbook";
import { Box } from "@mui/system";

import { AnalysisToolbarFormElements } from "Components/AnalysisToolbar/AnalysisToolbarFormElements";
import { DataDateRangePickerProps } from "Components/Charting/Elements/DataDateRangePicker";
import { ShapeToolbar } from "Components/AnalysisToolbar/ShapeToolbar";
import { IAnalysisFacets } from "BoardComponents/Types";

/****************************************
 * Constants/Enums/Types
 ****************************************/
interface AnalysisToolbarProps<TFacets> {
  children?: React.ReactNode;
  dateRangeShortcuts: DataDateRangePickerProps["shortcutItems"];
  distinctValues: Record<keyof TFacets, IDateRangeLimits | Array<ISelectInputFieldOption>>;
  selectedFacetValues: TFacets;
  fields: Array<keyof TFacets>;
  fieldsConfig: Array<IAnalysisFieldConfig>;
  fieldsSort: Record<string, FacetDisplaySortType>;
  onFieldChange: (
    field: string,
    value: Array<string> | string | { dateRange: IDateRange; shortcut?: string },
    inputValues?: Array<ISelectInputFieldOption> | IDateRangeLimits,
  ) => void;
  onSortChange: (field: string, value: FacetDisplaySortType) => void;
}

/****************************************
 * Component
 ****************************************/
// TODO: Accept TFacets as empty object
export function AnalysisToolbar<TFacets extends IAnalysisFacets>(props: AnalysisToolbarProps<TFacets>) {
  const { children, distinctValues, selectedFacetValues, ...otherProps } = props;

  /****** Render ***/
  return (
    <ShapeToolbar toolbarProps={{ sx: { display: "flex", alignItems: "flex-start" } }}>
      <Box sx={{ padding: "10px" }}>
        <AnalysisToolbarFormElements<TFacets>
          {...otherProps}
          fieldInputValues={distinctValues}
          fieldSelectedValues={selectedFacetValues}
        />
      </Box>
      {children}
    </ShapeToolbar>
  );
}
