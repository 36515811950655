import { Box, Grid, Skeleton } from "@mui/material";

import { customSeededRandomArray } from "Utils/MathUtils";

const BUBBLES_COUNT = 20;
/**
 * Gives a skeleton loader for a group bubble chart
 *
 * @returns Box with skeleton loaders
 */
export function GroupBubbleChartLoader() {
  return (
    <Box>
      <Grid container sx={{ padding: 4 }}>
        {Array.from({ length: 4 }).map((v, i) => {
          const randomNumbers = customSeededRandomArray(i, BUBBLES_COUNT, 40);
          return (
            <Grid item container columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ paddingBottom: 4, flexWrap: "nowrap" }} key={i}>
              <Grid item xs={2} sx={{ padding: 2 }}>
                <Skeleton animation={false} />
              </Grid>
              <Grid item container xs sx={{ paddingLeft: 4, flexWrap: "nowrap", display: "flex", alignItems: "center" }}>
                {Array.from({ length: BUBBLES_COUNT }).map((v, i) => (
                  <Grid item sx={{ paddingLeft: 2, paddingRight: 2 }} key={i}>
                    <Skeleton variant="circular" animation={false} width={randomNumbers[i]} height={randomNumbers[i]} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
