import { Box, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

import { Bundle, BundleType } from "GraphQL/Generated/Apollo";

// *********************************************
// Types/Interfaces/Constants
// *********************************************/
const BundleTypes = [BundleType.Input, BundleType.Output];

interface IBundleInputFormProps {
  bundleDetails: Partial<Bundle>;
  onChangeBundleDetails: (bundleDetails: Partial<Bundle>) => void;
}

// *********************************************
// Component
// *********************************************/
export function BundleInputForm(props: IBundleInputFormProps) {
  const { bundleDetails, onChangeBundleDetails } = props;
  const { name, type } = bundleDetails;
  const { t } = useTranslation();

  return (
    <Box sx={{ display: "flex", mt: 1 }}>
      <TextField
        id="bundle-name-input"
        placeholder={t("Components.BundleInputForm.Labels.BundleNameLabel")}
        label={t("Components.BundleInputForm.Labels.BundleNameLabel")}
        value={name}
        onChange={(event) => onChangeBundleDetails({ ...bundleDetails, name: event.target.value })}
        required
        fullWidth
      />

      <FormControl sx={{ minWidth: "200px", maxWidth: "200px", ml: 2 }}>
        <InputLabel id="select-bundle-type-label">{t("Components.BundleInputForm.Labels.BundleTypeLabel")}</InputLabel>
        <Select
          labelId="select-bundle-type-label"
          id="select-bundle-type"
          value={type}
          label={t("Components.BundleInputForm.Labels.BundleTypeLabel")}
          onChange={(event) => onChangeBundleDetails({ ...bundleDetails, type: event.target.value as BundleType })}
        >
          {BundleTypes.map((type) => {
            return (
              <MenuItem key={type} value={type}>
                {t(`Global.Bundle.Type.${type}`)}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
}
