import { AnalysisFieldTypeEnum, IAnalysisFieldConfig } from "@bigpi/cookbook";
import { gql } from "@apollo/client";

import { FieldQueryGeneratorBase } from "./FieldQueryGeneratorBase";

/**
 * Query generator for topic discussion summary analysis
 */
export class TopicDiscussionSummaryAnalysisDistinctValuesQuery extends FieldQueryGeneratorBase {
  constructor(fields: Array<string>, fieldsConfig: Array<IAnalysisFieldConfig>) {
    super(fields, fieldsConfig);
    this.validFieldTypes = [AnalysisFieldTypeEnum.String, AnalysisFieldTypeEnum.Date];
  }

  protected generateStringQuery(field: string) {
    return `
        ${field}: topicDiscussionSummaryExampleFieldDistinctValues(
            field: "${field}"
            facets: $${field}Facets
        ) {
            ${field}
        }
      `;
  }

  protected generateDateQuery(field: string) {
    return `
        ${field}: topicDiscussionSummaryExampleAggregate(
            field: "${field}"
            facets: $${field}Facets
        ) {
            min
            max
        }
      `;
  }

  protected generateNumberQuery(field: string) {
    return "";
  }

  protected generateQuery(fieldsQuery: string) {
    return gql`
        query TopicDiscussionSummaryExampleDistinctValues(
          ${this.validFields()
            .map((field) => `$${field}Facets: TopicDiscussionSummaryExampleFacetsInput!`)
            .join(", ")}
        ) {
            ${fieldsQuery}
        }
      `;
  }
}

/**
 * Query generator for question analysis
 */
export class QuestionAnalysisDistinctValuesQuery extends FieldQueryGeneratorBase {
  constructor(fields: Array<string>, fieldsConfig: Array<IAnalysisFieldConfig>) {
    super(fields, fieldsConfig);
    this.validFieldTypes = [AnalysisFieldTypeEnum.String, AnalysisFieldTypeEnum.ArrayOfStrings, AnalysisFieldTypeEnum.Date];
  }

  protected generateStringQuery(field: string) {
    return `
        ${field}: analystQuestionFieldDistinctValues(
            field: "${field}"
            facets: $${field}Facets
        ) {
            ${field}
        }
      `;
  }

  protected generateArrayOfStringsQuery(field: string) {
    return `
        ${field}: analystQuestionArrayFieldDistinctValues(
            field: "${field}"
            facets: $${field}Facets
        ) {
            ${field}
        }
      `;
  }

  protected generateDateQuery(field: string) {
    return `
        ${field}: analystQuestionAggregate(
            field: "${field}"
            facets: $${field}Facets
        ) {
            min
            max
        }
      `;
  }

  protected generateQuery(fieldsQuery: string) {
    return gql`
        query AnalystQuestionDistinctValues(
          ${this.validFields()
            .map((field) => `$${field}Facets: AnalystQuestionFacetsInput!`)
            .join(", ")}
        ) {
            ${fieldsQuery}
        }
      `;
  }
}

/**
 * Query generator for topic discussion in news article analysis
 */
export class TopicDiscussionInNewsArticleAnalysisDistinctValuesQuery extends FieldQueryGeneratorBase {
  constructor(fields: Array<string>, fieldsConfig: Array<IAnalysisFieldConfig>) {
    super(fields, fieldsConfig);
    this.validFieldTypes = [AnalysisFieldTypeEnum.String, AnalysisFieldTypeEnum.Date];
  }

  protected generateStringQuery(field: string) {
    return `
        ${field}: topicDiscussionInNewsArticleFieldDistinctValues(
            field: "${field}"
            facets: $${field}Facets
        ) {
            ${field}
        }
      `;
  }

  protected generateDateQuery(field: string) {
    return `
        ${field}: topicDiscussionInNewsArticleAggregate(
            field: "${field}"
            facets: $${field}Facets
        ) {
            min
            max
        }
      `;
  }

  protected generateQuery(fieldsQuery: string) {
    return gql`
        query TopicDiscussionInNewsArticlesDistinctValues(
          ${this.validFields()
            .map((field) => `$${field}Facets: TopicDiscussionInNewsArticleFacetsInput!`)
            .join(", ")}
        ) {
            ${fieldsQuery}
        }
      `;
  }
}

/**
 * Query generator for topic discussion analysis
 */
export class TopicDiscussionAnalysisDistinctValuesQuery extends FieldQueryGeneratorBase {
  constructor(fields: Array<string>, fieldsConfig: Array<IAnalysisFieldConfig>) {
    super(fields, fieldsConfig);
    this.validFieldTypes = [AnalysisFieldTypeEnum.String, AnalysisFieldTypeEnum.Date];
  }

  protected generateStringQuery(field: string) {
    return `
        ${field}: topicDiscussionExampleFieldDistinctValues(
            field: "${field}"
            facets: $${field}Facets
        ) {
            ${field}
        }
      `;
  }

  protected generateDateQuery(field: string) {
    return `
        ${field}: topicDiscussionExampleAggregate(
            field: "${field}"
            facets: $${field}Facets
        ) {
            min
            max
        }
      `;
  }

  protected generateQuery(fieldsQuery: string) {
    return gql`
        query TopicDiscussionExampleDistinctValues(
          ${this.validFields()
            .map((field) => `$${field}Facets: TopicDiscussionExampleFacetsInput!`)
            .join(", ")}
        ) {
            ${fieldsQuery}
        }
      `;
  }
}
