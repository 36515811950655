import { Permissions } from "@bigpi/permission";
import { Refresh, Delete } from "@mui/icons-material";
import { IconButton, Stack, Tooltip } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { AddToWorkspaceIcon } from "Components/AddToWorkspaceIcon/AddToWorkspaceIcon";
import { BulkDeleteBundleAndFileDialog } from "Components/BulkDeleteBundleAndFileDialog/BulkDeleteBundleAndFileDialog";
import { HasPermission } from "Components/HasPermission/HasPermission";
import { ObjectRole, useFileAccessControlListHasRoleQuery, useReindexFilesMutation } from "GraphQL/Generated/Apollo";
import { EditFilesInWorkspaceDialog } from "./EditFilesInWorkspaceDialog";

// *********************************************
// Types/Interfaces/Constants/Enums
// *********************************************/
export interface IFileActionBarButtonsProps {
  bundleIds: Array<string>;
  fileIds: Array<string>;
  onClose: () => void;
}

// *********************************************
// Component
// *********************************************/
export function FileActionBarButtons(props: IFileActionBarButtonsProps) {
  const { bundleIds, fileIds, onClose } = props;
  const { t } = useTranslation();

  const { data: hasAllDeletePermissions } = useFileAccessControlListHasRoleQuery({
    variables: {
      // User must have permissions for all the selected files
      fileIds,
      // We need one of the following roles
      roles: [ObjectRole.ContentManager, ObjectRole.Owner],
    },
  });

  const [editInWorkspaceOpen, setEditInWorkspaceOpen] = useState(false);
  const [bulkDeleteDialogOpen, setBulkDeleteDialogOpen] = useState(false);
  const [reindexEnabled, setReindexEnabled] = useState(fileIds.length > 0);

  const [ReindexFiles] = useReindexFilesMutation();

  useEffect(() => {
    setReindexEnabled(fileIds.length > 0);
  }, [fileIds]);

  const reindexFiles = useCallback(async () => {
    // Disable the button to prevent multiple clicks
    setReindexEnabled(false);

    try {
      const response = await ReindexFiles({
        variables: {
          input: {
            fileIds,
          },
        },
      });

      const reindexFileIds = response.data?.reindexFiles.fileIds || [];
      if (reindexFileIds.length > 0 && reindexFileIds.length === fileIds.length) {
        // Show success message
        toast.success(t("Components.FileActionBarButtons.Reindex.Success", { count: reindexFileIds.length }));
      } else if (reindexFileIds.length > 0) {
        // Show partial success message
        toast.warn(
          t("Components.FileActionBarButtons.Reindex.PartialSuccess", {
            count: reindexFileIds.length,
            total: fileIds.length,
          }),
        );
      } else {
        // Show error message
        toast.error(t("Components.FileActionBarButtons.Reindex.Error", { count: fileIds.length }));
      }
      onClose();
    } catch (error) {
      console.error(error);

      // Show error message
      toast.error(t("Components.FileActionBarButtons.Reindex.Error", { count: fileIds.length }));

      // Re-enable the button on failures, but not success
      setReindexEnabled(true);
      onClose();
    }
  }, [fileIds, onClose, t]);

  const onCloseEditInWorkspaceDialog = useCallback(() => {
    setEditInWorkspaceOpen(false);
    onClose();
  }, [onClose]);

  const onCloseBulkDeleteDialog = useCallback(() => {
    setBulkDeleteDialogOpen(false);
    onClose();
  }, [onClose]);

  return (
    <>
      <Stack direction="row" spacing={1} sx={{ ml: 1 }}>
        {
          // NOTE: Technically we shouldn't require write permissions in addition to file ACL delete permissions,
          // but currently they go hand in hand
        }
        <HasPermission permission={Permissions.PatronLibraryWrite}>
          <Tooltip title={t("Global.Action.Delete")} key={"delete"}>
            <IconButton
              onClick={() => setBulkDeleteDialogOpen(true)}
              disabled={!(hasAllDeletePermissions?.hasRoleForFiles === true)}
              size="small"
            >
              <Delete color="secondary" />
            </IconButton>
          </Tooltip>
        </HasPermission>

        <Tooltip title={t("Global.Action.AddToWorkspace")} key={"addToWorkspace"}>
          <IconButton onClick={() => setEditInWorkspaceOpen(true)} size="small">
            <AddToWorkspaceIcon />
          </IconButton>
        </Tooltip>

        <HasPermission permission={Permissions.PlatformDataWrite}>
          <Tooltip title={t("Global.Action.ReindexFiles", { count: fileIds.length })} key={"reindex"}>
            <IconButton disabled={!reindexEnabled} onClick={reindexFiles} size="small">
              <Refresh />
            </IconButton>
          </Tooltip>
        </HasPermission>
      </Stack>

      {editInWorkspaceOpen && (
        <EditFilesInWorkspaceDialog fileIds={fileIds} open={editInWorkspaceOpen} onClose={onCloseEditInWorkspaceDialog} />
      )}

      {bulkDeleteDialogOpen && (
        <BulkDeleteBundleAndFileDialog
          open={bulkDeleteDialogOpen}
          onClose={onCloseBulkDeleteDialog}
          fileIds={fileIds}
          bundleIds={bundleIds}
        />
      )}
    </>
  );
}
