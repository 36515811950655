export const MESSAGE_SYNC = 0;
export const MESSAGE_AWARENESS = 1;
export const MESSAGE_AUTH = 2;
export const MESSAGE_QUERY_AWARENESS = 3;
export const MESSAGE_UNAUTHORIZED = 4;
export const MESSAGE_ERROR = 5;
export const MESSAGE_INVALID_VERSION = 6;

export const MESSAGE_SUB_TYPE_SYNC1 = 0;
export const MESSAGE_SUB_TYPE_SYNC2 = 1;
export const MESSAGE_SUB_TYPE_UPDATE = 2;
