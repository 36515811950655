import { ChatMessagesQuery } from "GraphQL/Generated/Apollo";

import { ToolbarActionsEnum } from "Chat/ChatMessage/ChatMessageToolbar";

/**
 * Gives a list of applicable toolbar actions for a chat message.
 *
 * @param chatMessage Chat message to which toolbar actions to be displayed
 * @param userId User id who is viewing the chat message
 * @param view Where this chat message is displayed (list or thread)
 * @returns List of toolbar actions to be displayed for the chat message
 */
export function getChatMessageToolbarActions(
  chatMessage: ChatMessagesQuery["chatMessages"][0],
  userId: string,
  view: "list" | "thread" = "list",
) {
  const actions = [];

  const isSoftDeleted = chatMessage.deletedAt !== null && chatMessage.deletedBy !== null;

  // When Chat message is displayed in list view & not soft deleted
  if (chatMessage.parentId === null && !isSoftDeleted && view === "list") {
    actions.push(ToolbarActionsEnum.Reply);
  }
  // When logged in user viewing his own chat message & not soft deleted
  if (chatMessage.createdBy === userId && !isSoftDeleted) {
    actions.push(ToolbarActionsEnum.Delete);
  }
  // When logged in user viewing his own chat message, soft deleted & all child messages are also deleted
  if (chatMessage.createdBy === userId && isSoftDeleted && chatMessage.childCount === 0) {
    actions.push(ToolbarActionsEnum.Delete);
  }

  return actions;
}
