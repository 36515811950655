import React from "react";
import { RedoOutlined } from "@mui/icons-material";
import type { Editor } from "@tiptap/react";
import { useTranslation } from "react-i18next";

import type { IEditorConfig } from "../../Editor/index.js";
import { getEditorToolbarButtonTooltip } from "../../Utils/ToolbarItemUtils.js";
import { ToolbarItemBase } from "../../Toolbars/ToolbarItemBase.js";
import { ToolbarButton } from "../../Toolbars/ToolbarButton.js";

const ITEM_NAME = "redo";

/**
 * A toolbar button that reverts the last "undo" change.
 */
export class RedoButtonItem extends ToolbarItemBase {
  // *********************************************
  // Public properties
  // *********************************************/
  /**
   * @inheritdoc
   */
  readonly name = ITEM_NAME;

  // *********************************************
  // Public methods
  // *********************************************/
  /**
   * @inheritdoc
   */
  create(editor: Editor, config: IEditorConfig) {
    const { toolbarOptions } = config;
    return (
      <RedoButton
        key={ITEM_NAME}
        onClick={() => editor.chain().focus().redo().run()}
        disabled={!editor.can().redo()}
        toolbarOptions={toolbarOptions}
      />
    );
  }
}

/**
 * Props for RedoButton.
 */
export interface RedoButtonProps {
  onClick: () => void;
  disabled: boolean;
  toolbarOptions?: IEditorConfig["toolbarOptions"];
}

/**
 * A toolbar button for the `redo` command.
 */
export const RedoButton = (props: RedoButtonProps) => {
  const { onClick, disabled, toolbarOptions } = props;
  const tooltipPlacement = toolbarOptions?.tooltipPlacement;

  const { t } = useTranslation();

  return (
    <ToolbarButton
      Icon={RedoOutlined}
      tooltip={getEditorToolbarButtonTooltip(t, ITEM_NAME)}
      onClick={onClick}
      disabled={disabled}
      tooltipPlacement={tooltipPlacement}
    />
  );
};
