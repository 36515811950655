import { ApolloClient } from "@apollo/client";
import { ITopicDiscussionAnalysisFacets, ITopicDiscussionAnalysisSelection } from "@bigpi/tl-schema";

import { ITopicDiscussionExampleResult } from "BoardComponents/BoardDatastore";
import { TopicDiscussionExamplesQuery } from "GraphQL/Generated/Apollo";
import { TOPIC_DISCUSSION_EXAMPLES_QUERY } from "GraphQL/TopicDiscussionExample";
import { DataUtils } from "Utils/DataUtils";

/**
 * Fetches the data for the topic discussion analysis based on the selected facets.
 *
 * @param apolloClient - The Apollo client instance.
 * @param facets - The facets used for filtering.
 *
 * @returns The array of topic discussion example results.
 */
export const getTopicDiscussionExamplesData = async (
  apolloClient: ApolloClient<object>,
  facets: ITopicDiscussionAnalysisFacets,
): Promise<Array<ITopicDiscussionExampleResult>> => {
  const apiResponse = await apolloClient.query<TopicDiscussionExamplesQuery>({
    query: TOPIC_DISCUSSION_EXAMPLES_QUERY,
    variables: {
      facets,
    },
  });

  return apiResponse.data?.topicDiscussionExamples || DataUtils.getImmutableEmptyArray<ITopicDiscussionExampleResult>();
};

/**
 * Filters the data based on the selected facets with single value.
 *
 * @param data - The array of topic discussion example results.
 * @param selection - The selection used for filtering.
 *
 * @returns The filtered array of topic discussion example results.
 */
const applySingleValueFilterOnTopicDiscussionExamplesData = (
  data: Array<ITopicDiscussionExampleResult>,
  selection: ITopicDiscussionAnalysisSelection,
): Array<ITopicDiscussionExampleResult> => {
  return data.filter((item) => {
    if (!selection.selectedItem && !selection.selectedGroup && !selection.selectedXAxis) {
      return true;
    }

    // When only XAxis value selected
    if (
      selection.selectedXAxis &&
      !selection.selectedItem &&
      !selection.selectedGroup &&
      item.ticker === selection.selectedXAxis
    ) {
      return true;
    }

    // When only YGroup value selected
    if (
      selection.selectedGroup &&
      !selection.selectedXAxis &&
      !selection.selectedItem &&
      item.topic === selection.selectedGroup
    ) {
      return true;
    }

    // When only YItem value selected
    if (
      selection.selectedItem &&
      !selection.selectedXAxis &&
      !selection.selectedGroup &&
      item.question === selection.selectedItem
    ) {
      return true;
    }

    // When XAxis & YGroup value selected
    if (
      !selection.selectedItem &&
      selection.selectedGroup &&
      selection.selectedXAxis &&
      item.topic === selection.selectedGroup &&
      item.ticker === selection.selectedXAxis
    ) {
      return true;
    }

    // When XAxis, YGroup & YItem value selected
    if (
      selection.selectedItem &&
      selection.selectedGroup &&
      selection.selectedXAxis &&
      selection.selectedItem === item.question &&
      item.topic === selection.selectedGroup &&
      item.ticker === selection.selectedXAxis
    ) {
      return true;
    }

    return false;
  });
};

/**
 * Fetches the data for the topic discussion analysis based on the selected facets and applies the filters.
 *
 * @param apolloClient - The Apollo client instance.
 * @param facets - The facets used for filtering.
 * @param selection - The selection used for filtering.
 *
 * @returns The array of filtered topic discussion example results.
 */
export const getFilteredTopicDiscussionExamplesData = async (
  apolloClient: ApolloClient<object>,
  facets: ITopicDiscussionAnalysisFacets,
  selection: ITopicDiscussionAnalysisSelection,
): Promise<Array<ITopicDiscussionExampleResult>> => {
  const data = await getTopicDiscussionExamplesData(apolloClient, facets);
  return applySingleValueFilterOnTopicDiscussionExamplesData(data, selection);
};
