import { DataGridDescendentCountFormat } from "@bigpi/cookbook";
import { unstable_composeClasses as composeClasses } from "@mui/utils";
import Box from "@mui/material/Box";
import {
  useGridSelector,
  gridFilteredDescendantCountLookupSelector,
  getDataGridUtilityClass,
  GridRenderCellParams,
  GridGroupNode,
  useGridApiContext,
  useGridRootProps,
  DataGridPremiumProps,
  gridRowCountSelector,
} from "@mui/x-data-grid-premium";
import * as React from "react";

// Modified by BigPi - using `DataGridPremiumProps` instead of `DataGridPremiumProcessedProps`
// as the latter is not exported from `@mui/x-data-grid-premium`.
type OwnerState = { classes: DataGridPremiumProps["classes"] };

const useUtilityClasses = (ownerState: OwnerState) => {
  const { classes } = ownerState;

  const slots = {
    root: ["groupingCriteriaCell"],
    toggle: ["groupingCriteriaCellToggle"],
  };

  return composeClasses(slots, getDataGridUtilityClass, classes);
};

interface CustomGridGroupingCriteriaCellProps extends GridRenderCellParams<any, any, any, GridGroupNode> {
  hideDescendantCount?: boolean;
  // Added by BigPi - used to determine the descendent count text format.
  descendentCountFormat?: DataGridDescendentCountFormat;
}

/**
 * Cloned from https://github.com/mui/mui-x/blob/5cca1d9737fffd96c724dc6641d5fe3ef20032fb/packages/grid/x-data-grid-premium/src/components/GridGroupingCriteriaCell.tsx#L32
 * and modified to allow the component to determine the descendent count text format based on the prop value.
 */
export function CustomGridGroupingCriteriaCell(props: CustomGridGroupingCriteriaCellProps) {
  const {
    id,
    field,
    rowNode,
    hideDescendantCount = false,
    formattedValue,
    descendentCountFormat = DataGridDescendentCountFormat.RowCount,
  } = props;

  const rootProps = useGridRootProps();
  const apiRef = useGridApiContext();
  const ownerState: OwnerState = { classes: rootProps.classes };
  const classes = useUtilityClasses(ownerState);
  const filteredDescendantCountLookup = useGridSelector(apiRef, gridFilteredDescendantCountLookupSelector);
  // Added by BigPi
  const totalRowCount = useGridSelector(apiRef, gridRowCountSelector);
  const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0;

  const Icon = rowNode.childrenExpanded
    ? rootProps.slots.groupingCriteriaCollapseIcon
    : rootProps.slots.groupingCriteriaExpandIcon;

  const handleKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    if (event.key === " ") {
      // We call event.stopPropagation to avoid unfolding the row and also scrolling to bottom
      // TODO: Remove and add a check inside useGridKeyboardNavigation
      event.stopPropagation();
    }
    apiRef.current.publishEvent("cellKeyDown", props, event);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
    apiRef.current.setCellFocus(id, field);
    event.stopPropagation();
  };

  let cellContent: React.ReactNode;

  // Modified by BigPi
  const colDef = apiRef.current.getColumn(rowNode.groupingField!);
  if (colDef && colDef.renderCell && typeof colDef.renderCell === "function") {
    cellContent = colDef.renderCell(props);
  } else {
    cellContent = <span>{formattedValue}</span>;
  }

  // Added by BigPi
  let descendentCountText;
  switch (descendentCountFormat) {
    case DataGridDescendentCountFormat.RowCountPercentage:
      // Handle the case where the total row count is 0 to avoid division by zero.
      if (totalRowCount <= 0) {
        descendentCountText = ` (0%)`;
        break;
      }

      descendentCountText = ` (${Math.ceil((filteredDescendantCount / totalRowCount) * 100)}%)`;
      break;
    default:
      descendentCountText = ` (${filteredDescendantCount})`;
      break;
  }

  return (
    <Box
      className={classes.root}
      sx={{
        ml:
          rootProps.rowGroupingColumnMode === "multiple"
            ? 0
            : (theme) => `calc(var(--DataGrid-cellOffsetMultiplier) * ${theme.spacing(rowNode.depth)})`,
      }}
    >
      <div className={classes.toggle}>
        {filteredDescendantCount > 0 && (
          <rootProps.slots.baseIconButton
            size="small"
            onClick={handleClick}
            onKeyDown={handleKeyDown}
            tabIndex={-1}
            aria-label={
              rowNode.childrenExpanded
                ? apiRef.current.getLocaleText("treeDataCollapse")
                : apiRef.current.getLocaleText("treeDataExpand")
            }
            {...rootProps.slotProps?.baseIconButton}
          >
            <Icon fontSize="inherit" />
          </rootProps.slots.baseIconButton>
        )}
      </div>
      {cellContent}
      {!hideDescendantCount && filteredDescendantCount > 0 ? (
        // Modified by BigPi
        <span style={{ whiteSpace: "pre" }}>{descendentCountText}</span>
      ) : null}
    </Box>
  );
}
