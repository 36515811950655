import {
  DefaultKeyboardShortcutsDialog,
  TLUiKeyboardShortcutsDialogProps,
  TldrawUiMenuGroup,
  TldrawUiMenuItem,
  useActions,
  useEditor,
  useTools,
  useValue,
} from "@tldraw/tldraw";
import { memo } from "react";

/**
 * Cloned from https://github.com/tldraw/tldraw/blob/41b5fffa2ef17ff852c1efc227a5ad5c37dc5c7a/packages/tldraw/src/lib/ui/components/KeyboardShortcutsDialog/DefaultKeyboardShortcutsDialogContent.tsx#L7
 * and made some modifications. Changes -
 *
 * - Added `interact` and `htmlDocument` tools in `shortcuts-dialog.tools`.
 * - Removed `note` tool from `shortcuts-dialog.tools`.
 * - Removed `shortcuts-dialog.preferences` group.
 * - Disable `undo` and `redo` buttons if an `htmlDocument` shape is being edited - https://app.asana.com/0/1206533986827299/1205470617445198/f
 */
function KeyboardShortcutsDialogContent() {
  const editor = useEditor();
  const actions = useActions();
  const tools = useTools();

  const editingShape = useValue("editingShapeIds", () => editor.getEditingShape(), [editor]);
  const isEditingHtmlDocumentShape = editingShape && editingShape.type === "htmlDocument";

  return (
    <>
      <TldrawUiMenuGroup label="shortcuts-dialog.tools" id="tools">
        <TldrawUiMenuItem {...actions["toggle-tool-lock"]} />
        <TldrawUiMenuItem {...actions["insert-media"]} />
        <TldrawUiMenuItem {...tools["select"]} />
        <TldrawUiMenuItem {...tools["draw"]} />
        <TldrawUiMenuItem {...tools["interact"]} />
        <TldrawUiMenuItem {...tools["eraser"]} />
        <TldrawUiMenuItem {...tools["hand"]} />
        <TldrawUiMenuItem {...tools["rectangle"]} />
        <TldrawUiMenuItem {...tools["ellipse"]} />
        <TldrawUiMenuItem {...tools["arrow"]} />
        <TldrawUiMenuItem {...tools["line"]} />
        <TldrawUiMenuItem {...tools["text"]} />
        <TldrawUiMenuItem {...tools["frame"]} />
        {/* Modified by BigPi - Removed `note` tool from `shortcuts-dialog.tools`.
        <TldrawUiMenuItem {...tools["note"]} /> */}
        <TldrawUiMenuItem {...tools["laser"]} />

        {/* Modified by BigPi - Added `interact` and `htmlDocument` tools in `shortcuts-dialog.tools`. */}
        <TldrawUiMenuItem {...tools["interact"]} />
        <TldrawUiMenuItem {...tools["htmlDocument"]} />
      </TldrawUiMenuGroup>

      {/* Modified by BigPi - Removed `shortcuts-dialog.preferences` group.
      <TldrawUiMenuGroup label="shortcuts-dialog.preferences" id="preferences">
        <TldrawUiMenuItem {...actions["toggle-dark-mode"]} />
        <TldrawUiMenuItem {...actions["toggle-focus-mode"]} />
        <TldrawUiMenuItem {...actions["toggle-grid"]} />
      </TldrawUiMenuGroup> */}

      <TldrawUiMenuGroup label="shortcuts-dialog.edit" id="edit">
        {/* Modified by BigPi - Disable `undo` and `redo` buttons if an `htmlDocument` shape is being edited.
        https://app.asana.com/0/1206533986827299/1205470617445198/f */}
        <TldrawUiMenuItem {...actions["undo"]} disabled={isEditingHtmlDocumentShape} />
        <TldrawUiMenuItem {...actions["redo"]} disabled={isEditingHtmlDocumentShape} />

        <TldrawUiMenuItem {...actions["cut"]} />
        <TldrawUiMenuItem {...actions["copy"]} />
        <TldrawUiMenuItem {...actions["paste"]} />
        <TldrawUiMenuItem {...actions["select-all"]} />
        <TldrawUiMenuItem {...actions["delete"]} />
        <TldrawUiMenuItem {...actions["duplicate"]} />
      </TldrawUiMenuGroup>

      <TldrawUiMenuGroup label="shortcuts-dialog.view" id="view">
        <TldrawUiMenuItem {...actions["zoom-in"]} />
        <TldrawUiMenuItem {...actions["zoom-out"]} />
        <TldrawUiMenuItem {...actions["zoom-to-100"]} />
        <TldrawUiMenuItem {...actions["zoom-to-fit"]} />
        <TldrawUiMenuItem {...actions["zoom-to-selection"]} />
      </TldrawUiMenuGroup>

      <TldrawUiMenuGroup label="shortcuts-dialog.transform" id="transform">
        <TldrawUiMenuItem {...actions["bring-to-front"]} />
        <TldrawUiMenuItem {...actions["bring-forward"]} />
        <TldrawUiMenuItem {...actions["send-backward"]} />
        <TldrawUiMenuItem {...actions["send-to-back"]} />
        <TldrawUiMenuItem {...actions["group"]} />
        <TldrawUiMenuItem {...actions["ungroup"]} />
        <TldrawUiMenuItem {...actions["flip-horizontal"]} />
        <TldrawUiMenuItem {...actions["flip-vertical"]} />
        <TldrawUiMenuItem {...actions["align-top"]} />
        <TldrawUiMenuItem {...actions["align-center-vertical"]} />
        <TldrawUiMenuItem {...actions["align-bottom"]} />
        <TldrawUiMenuItem {...actions["align-left"]} />
        <TldrawUiMenuItem {...actions["align-center-horizontal"]} />
        <TldrawUiMenuItem {...actions["align-right"]} />
      </TldrawUiMenuGroup>
    </>
  );
}

export const KeyboardShortcutsDialog = memo(function CustomKeyboardShortcutsDialog(props: TLUiKeyboardShortcutsDialogProps) {
  return (
    <DefaultKeyboardShortcutsDialog {...props}>
      <KeyboardShortcutsDialogContent />
    </DefaultKeyboardShortcutsDialog>
  );
});
