import { SortOrder } from "./SortOrder.js";
import { ISortOption } from "./ISortOption.js";

export const CreatedAtAscSortOptionName = "CreatedAtAsc";

export const CreatedAtAscSortOption: ISortOption = {
  field: "createdAt",
  name: CreatedAtAscSortOptionName,
  sortOrder: SortOrder.Asc,
};
