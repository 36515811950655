import { TLHoveredShapeIndicatorProps, useEditor, useEditorComponents, useValue } from "@tldraw/tldraw";

/**
 * Cloned from https://github.com/tldraw/tldraw/blob/main/packages/tldraw/src/lib/canvas/TldrawHoveredShapeIndicator.tsx
 * and added `interact.idle` and `interact.pointing_shape` in `showHoveredShapeIndicator` logic because we want to add
 * hovered shape indicator for shapes in interact mode.
 *
 * https://discord.com/channels/859816885297741824/1138822084118724678/1139860556178796584
 */
export const HoveredShapeIndicator = ({ shapeId }: TLHoveredShapeIndicatorProps) => {
  const editor = useEditor();
  const showHoveredShapeIndicator = useValue(
    "show hovered",
    () => {
      // When the editor is editing a shape and hovering that shape,
      // don't show its indicator; but DO show other hover indicators
      if (editor.isIn("select.editing_shape")) {
        return editor.getHoveredShapeId() !== editor.getEditingShapeId();
      }

      // Otherwise, only show the hovered indicator when the editor
      // is in the idle state
      return editor.isInAny(
        "select.idle",
        "interact.idle", // Added by BigPi
        "interact.pointing_shape", // Added by BigPi
      );
    },
    [editor],
  );
  const { ShapeIndicator } = useEditorComponents();

  if (!showHoveredShapeIndicator || !ShapeIndicator) return null;
  return <ShapeIndicator className="tl-user-indicator__hovered" shapeId={shapeId} />;
};
