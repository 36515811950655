import { useAuthUser } from "@frontegg/react";
import { useValue } from "@tldraw/tldraw";
import { ObjectRole, useWorkspaceAccessControlListUserRolesQuery } from "GraphQL/Generated/Apollo";

/**
 * Get the editable permission for the current user against given workspace Id.
 *
 * @param workspaceId Workspace ID to check if the user has edit permissions
 * @returns true/false
 */
export function useIsWorkspaceEditable(workspaceId: string) {
  const user = useAuthUser();

  const {
    data: aclData,
    error: aclError,
    loading: aclLoading,
  } = useWorkspaceAccessControlListUserRolesQuery({
    variables: {
      userId: user.id,
      workspaceId,
    },
  });

  const isEditable = useValue(
    "isEditable",
    () => {
      return aclData?.workspaceAccessControlListUserRoles.roles.some((assignedRole) =>
        [ObjectRole.ContentManager, ObjectRole.Contributor, ObjectRole.Manager, ObjectRole.Owner].includes(assignedRole),
      );
    },
    [aclData, workspaceId],
  );

  return { isEditable, loading: aclLoading };
}
