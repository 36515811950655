import { useContext } from "react";

import { CommandExecutorContext } from "./CommandExecutorContext";

export const useCommandExecutor = () => {
  const result = useContext(CommandExecutorContext);
  if (!result) {
    throw new Error("No CommandExecutor found in context");
  }
  return result;
};
