import { Alert, Box, Fade } from "@mui/material";

const ALERT_DELAY = 2000;

export interface IAlertBarProps {
  delay?: number;
  isOnline: boolean;
  message: string;
  minWidth?: number;
  isModal?: boolean;
}

export function AlertBar(props: IAlertBarProps) {
  const { delay, isOnline, message, minWidth, isModal } = props;

  if (!isOnline) {
    // Display offline status if network is down longer than specified delay
    return (
      <Fade
        in={!isOnline}
        style={{
          transitionDelay: !isOnline ? `${delay ?? ALERT_DELAY}ms` : "0ms",
        }}
      >
        <Box>
          {isModal && (
            <Box
              sx={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0, zIndex: 9999, background: "rgba(0,0,0,0.25)" }}
            />
          )}
          <Box sx={{ position: "fixed", justifyContent: "center", display: "flex", top: 0, left: 0, right: 0, zIndex: 10000 }}>
            <Alert sx={{ minWidth: minWidth ?? 600 }} severity="error">
              {message}
            </Alert>
          </Box>
        </Box>
      </Fade>
    );
  } else {
    return null;
  }
}
