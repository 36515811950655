/**
 * The type of board search field.
 */
export enum BoardSearchFieldTypes {
  /**
   * The search field is plain text string.
   */
  PlainText = "PlainText",

  /**
   * The search field is an array of plain text strings.
   */
  ArrayOfStrings = "ArrayOfStrings",

  /**
   * The search field is HTML string.
   */
  Html = "Html",

  /**
   * The DataGrid shape's search field value should be fetched from server datastore.
   */
  DataGridServerDatastore = "DataGridServerDatastore",

  /**
   * The DataGrid shape's search field value should be fetched from parent shape's datastore.
   */
  DataGridParentDatastore = "DataGridParentDatastore",
}
