export enum PageOrientation {
  Custom = "Custom",
  Landscape = "Landscape",
  Portrait = "Portrait",
}

export function PageOrientationToClassName(pageOrientation: PageOrientation): string {
  switch (pageOrientation) {
    case PageOrientation.Custom:
      return "";
    case PageOrientation.Landscape:
      return "landscape";
    case PageOrientation.Portrait:
      return "portrait";
  }
}
