import {
  TopicDiscussionSummaryOverviewChartContainerProps,
  TopicDiscussionSummaryOverviewChartContainer,
} from "./TopicDiscussionSummaryOverviewChartContainer";

import { ITopicDiscussionSummaryExampleResult, ITopicDiscussionExampleResult } from "BoardComponents/BoardDatastore";
import { TopicDiscussionSummaryOverviewLabel } from "../TopicDiscussionSummaryOverviewLabel/TopicDiscussionSummaryOverviewLabel";

export interface ITopicDiscussionSummaryOverviewProps {
  config: TopicDiscussionSummaryOverviewChartContainerProps["config"];
  fetchData: TopicDiscussionSummaryOverviewChartContainerProps["fetchData"];
  plotRefs: TopicDiscussionSummaryOverviewChartContainerProps["plotRefs"];
  selectedTheme: string;
  selectedTicker: string;
  selectedDate: TopicDiscussionSummaryOverviewChartContainerProps["selectedDate"];
  topicDiscussionSummaryDetails: Array<ITopicDiscussionSummaryExampleResult | ITopicDiscussionExampleResult>;
}

export function TopicDiscussionSummaryOverview(props: ITopicDiscussionSummaryOverviewProps) {
  const { config, fetchData, topicDiscussionSummaryDetails, plotRefs, selectedDate, selectedTheme, selectedTicker } = props;

  return (
    <>
      <TopicDiscussionSummaryOverviewLabel topicDiscussionSummaryDetails={topicDiscussionSummaryDetails} />
      <TopicDiscussionSummaryOverviewChartContainer
        config={config}
        data={topicDiscussionSummaryDetails}
        fetchData={fetchData}
        plotRefs={plotRefs}
        selectedDate={selectedDate}
        selectedTheme={selectedTheme}
        selectedTicker={selectedTicker}
      />
    </>
  );
}
