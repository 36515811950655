import React from "react";
import { Typography, TypographyProps } from "@mui/material";

export interface ISelectableTextProps extends TypographyProps {}

export function SelectableText(props: ISelectableTextProps) {
  const { sx, children, ...rest } = props;

  return (
    <Typography sx={{ ...sx, pointerEvents: "all", userSelect: "text", cursor: "auto" }} {...rest}>
      {children}
    </Typography>
  );
}
