import { IBoardSearchShapeMatch, IPlugIn } from "@bigpi/cookbook";

import { IBoardSearchPlugInInputs } from "../BoardSearchManager";
import { ArrayOfStringsFieldBoardSearchPlugIn } from "./ArrayOfStringsFieldBoardSearchPlugIn";
import { DataGridParentDatastoreBoardSearchPlugIn } from "./DataGridParentDatastoreBoardSearchPlugIn";
import { DataGridServerDatastoreBoardSearchPlugIn } from "./DataGridServerDatastoreBoardSearchPlugIn";
import { HtmlFieldBoardSearchPlugIn } from "./HtmlFieldBoardSearchPlugIn";
import { PlainTextFieldBoardSearchPlugIn } from "./PlainTextFieldBoardSearchPlugIn";

/**
 * Creates the plug-ins.
 *
 * @param options Options for the plug-ins.
 *
 * @returns An array of plug-ins to be registered.
 */
export function createBoardSearchPlugIns(): Array<IPlugIn<IBoardSearchPlugInInputs, Array<IBoardSearchShapeMatch>>> {
  return [
    new ArrayOfStringsFieldBoardSearchPlugIn(),
    new DataGridParentDatastoreBoardSearchPlugIn(),
    new DataGridServerDatastoreBoardSearchPlugIn(),
    new HtmlFieldBoardSearchPlugIn(),
    new PlainTextFieldBoardSearchPlugIn(),
  ];
}
