import { FileSourceType } from "@bigpi/cookbook";
import { Box, ListItem, ListItemProps, ListItemText, ListItemIcon, ListItemButton } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { FileIcon } from "Components/FileIcon/FileIcon";
import { FilePreviewDialog } from "Components/FilePreviewDialog/FilePreviewDialog";
import { formatFileCreatedAt } from "Utils/FileUtils";

export interface IFileItemProps {
  checked: boolean;
  fileCreatedAt: string;
  fileId: string;
  fileMimeType: string;
  fileName: string;
  fileSourceType: FileSourceType;
  listItemIcon?: React.ReactNode;
  onMouseEnter?: (event: React.MouseEvent<HTMLElement>) => void;
  onMouseLeave?: () => void;
  secondaryAction?: ListItemProps["secondaryAction"];
}

export function FileItem(props: IFileItemProps) {
  const {
    checked,
    fileCreatedAt,
    fileId,
    fileSourceType,
    fileMimeType,
    fileName,
    listItemIcon,
    onMouseEnter,
    onMouseLeave,
    secondaryAction,
  } = props;
  const [openPreview, setOpenPreview] = useState(false);
  const { t } = useTranslation();

  if (!fileId || !fileName || !fileCreatedAt || !fileMimeType) {
    return null;
  }

  return (
    <ListItem
      disablePadding
      key={fileId}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      secondaryAction={secondaryAction}
      sx={{
        borderRadius: 1,
        mt: 1,
        mb: 1,
        ":hover": {
          backgroundColor: "var(--astra-color-gray15)",
        },
      }}
    >
      <ListItemButton
        sx={{
          backgroundColor: checked ? "var(--astra-color-blue15)" : "var(--astra-color-gray7)",
          borderRadius: 1,
          cursor: "default",
        }}
        disableRipple
      >
        <ListItemIcon>{listItemIcon || <FileIcon mimetype={fileMimeType} />}</ListItemIcon>

        <ListItemText
          primary={fileName}
          primaryTypographyProps={{ fontWeight: "500" }}
          secondary={
            <Box sx={{ display: "flex", flexDirection: "column" }} component="span">
              <Box sx={{ display: "flex", flexWrap: "wrap" }} component="span">
                {formatFileCreatedAt(fileCreatedAt, t)}
              </Box>
            </Box>
          }
          sx={{ cursor: "pointer" }}
          onClick={() => setOpenPreview(true)}
        />
      </ListItemButton>
      {openPreview && <FilePreviewDialog fileId={fileId} fileType={fileSourceType} onClose={() => setOpenPreview(false)} />}
    </ListItem>
  );
}
