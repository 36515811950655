import React from "react";

// *********************************************
// Types/Interfaces
// *********************************************/
export interface IAddToBoard {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

// *********************************************
// Context
// *********************************************/
const AddToBoard = React.createContext<IAddToBoard>({
  isOpen: false,
  setIsOpen: () => null,
});

export function AddToBoardProvider(props: any) {
  const { children } = props;
  const [isOpen, setIsOpen] = React.useState(false);

  return <AddToBoard.Provider value={{ isOpen, setIsOpen }}>{children}</AddToBoard.Provider>;
}

export function useAddToBoard() {
  return React.useContext(AddToBoard);
}
