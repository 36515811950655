/**
 * Available actions for the queued commands.
 */
export enum CommandQueueAction {
  /**
   * The command should be canceled.
   */
  Cancel = "Cancel",
}

/**
 * Actions published via the command queue Redis PubSub.
 */
export interface ICommandQueueActionRequest {
  /**
   * The action to take.
   */
  action: CommandQueueAction;

  /**
   * The command request ID. Same as the topic.
   */
  requestId: string;

  /**
   * The user ID of the user that initiated the action.
   */
  userId: string;
}
