import { RawCommands } from "@tiptap/core";

import { TextColors } from "./TextStyleExtension.js";

export const setTextColor: RawCommands["setTextColor"] =
  (color: TextColors) =>
  ({ chain }) => {
    return chain()
      .setMark("textStyle", { class: `text-${color}` })
      .run();
  };
