import {
  ILockedTextShape,
  getLockedTextShapeDefaultProps,
  lockedTextShapeMigrations,
  lockedTextShapeProps,
} from "@bigpi/tl-schema";
import { Migrations, TextShapeUtil } from "@tldraw/tldraw";

// *********************************************
// Shape Util
// *********************************************/
/**
 * Generator for LockedText shapes.
 */
export class LockedTextShapeUtil extends TextShapeUtil {
  // *********************************************
  // Static fields
  // *********************************************/
  static type = "lockedText" as any;

  static props = lockedTextShapeProps as any;

  static migrations: Migrations = lockedTextShapeMigrations as any;

  // *********************************************
  // Override methods
  // *********************************************/
  /**
   * @inheritdoc
   */
  override canEdit = () => false;

  /**
   * @inheritdoc
   */
  override getDefaultProps(): ILockedTextShape["props"] {
    return getLockedTextShapeDefaultProps();
  }
}
