import {
  ActionMenuFactory,
  registerBuiltInToolbarItems,
  ToolbarFactory,
  registerBuiltInActionMenuItems,
} from "@bigpi/editor-tiptap";

import { CommandButtonItem } from "Editor/Extensions/Command/CommandToolbarItem";
import { EditShapeMetaButtonItem } from "Editor/Extensions/EditShapeMeta/EditShapeMetaToolbarItem";
import { ExportToWordButtonItem } from "Editor/Extensions/ExportToWord/ExportToWordToolbarItem";

export function initializeToolbarFactory() {
  // Note: We're not passing in an i18n object here, so the default strings from the library will be used.
  ToolbarFactory.initialize();

  // Initialize editor toolbar factory with global defaults
  registerBuiltInToolbarItems();

  // Add toolbar items
  ToolbarFactory.registerToolbarItems([new CommandButtonItem(), new EditShapeMetaButtonItem(), new ExportToWordButtonItem()]);
}

export function initializeActionMenuFactory() {
  // Note: We're not passing in an i18n object here, so the default strings from the library will be used.
  ActionMenuFactory.initialize();

  // Initialize editor toolbar factory with global defaults
  registerBuiltInActionMenuItems();

  // Add action menu items
  ToolbarFactory.registerToolbarItems([]);
}
