import { Node } from "@tiptap/pm/model";
import { EditorState } from "@tiptap/pm/state";

import { IndentProps, IndentLevels } from "../Extensions/Indent/IndentExtension.js";
import {
  BulletListStyleClassTypes,
  BULLET_LIST_STYLE_CLASS_ORDER,
  OrderedListStyleClassTypes,
  ORDERED_LIST_STYLE_CLASS_ORDER,
} from "../Extensions/ListItem/ListConstants.js";

export function getIndentLevelByIndentClass(indentClass: string | null | undefined) {
  const indentLevel = indentClass ? parseInt(indentClass.split("indent-")[1]) : 0;
  if (!indentLevel || indentLevel < IndentProps.MIN || indentLevel > IndentProps.MAX || indentLevel === 0) {
    // If indentLevel is not valid, return default indent level i.e. 0.
    return 0;
  }

  return indentLevel;
}

export function getIndentClass(className: string | null | undefined) {
  const classes = className?.trim().split(/\s+/g);
  return (
    classes?.find(
      (c: string) => IndentLevels.map((level) => getIndentClassFromIndentLevel(level)).includes(c) && c !== "indent-0",
    ) ?? null
  );
}

export function getIndentClassFromIndentLevel(indentLevel: number | null | undefined) {
  if (!indentLevel || indentLevel < IndentProps.MIN || indentLevel > IndentProps.MAX || indentLevel === 0) {
    return null;
  }

  return `indent-${indentLevel}`;
}

export function getIndentLevelByClass(className: string) {
  const indentClass = getIndentClass(className);
  return getIndentLevelByIndentClass(indentClass);
}

const getNewListMarkerClass = (
  listItemClass: string | null,
  listType: "orderedList" | "bulletList",
  command: "split" | "sink" | "lift",
): OrderedListStyleClassTypes | BulletListStyleClassTypes | null => {
  if (listItemClass === null || listItemClass.trim().length === 0) {
    return null;
  }

  const listStyleClassOrder: Array<OrderedListStyleClassTypes> | Array<BulletListStyleClassTypes> =
    listType === "orderedList" ? ORDERED_LIST_STYLE_CLASS_ORDER : BULLET_LIST_STYLE_CLASS_ORDER;
  const classes = listItemClass?.trim().split(/\s+/g);
  const listStyleClass = classes ? classes.find((c: string) => c.startsWith("list-style-type-")) : null;
  if (!listStyleClass) {
    return listStyleClassOrder[0];
  }

  const listStyleTypeIndex = listStyleClassOrder.findIndex((i) => i === listStyleClass);
  if (listStyleTypeIndex === -1) {
    return null;
  }

  if (command === "split") {
    return listStyleClassOrder[listStyleTypeIndex];
  } else if (command === "lift") {
    const newIndex = listStyleTypeIndex - 1;
    return listStyleClassOrder[newIndex === -1 ? listStyleClassOrder.length - 1 : newIndex];
  } else {
    return listStyleClassOrder[(listStyleTypeIndex + 1) % listStyleClassOrder.length];
  }
};

export const getListItemMarkerClassByIndentLevel = (newListType: "orderedList" | "bulletList", indentLevel: number) => {
  const newListStyleClassOrder: Array<OrderedListStyleClassTypes> | Array<BulletListStyleClassTypes> =
    newListType === "orderedList" ? ORDERED_LIST_STYLE_CLASS_ORDER : BULLET_LIST_STYLE_CLASS_ORDER;
  return newListStyleClassOrder[indentLevel % newListStyleClassOrder.length];
};

export const getBaseClassWithoutIndent = (listItemClass: string | null) => {
  const classes = listItemClass?.trim().split(/\s+/g);
  return classes
    ? classes
        .filter((c: string) => !c.startsWith("indent-"))
        .join(" ")
        .trim()
    : null;
};

export const getListItemBaseClass = (listItemClass: string | null) => {
  const classes = listItemClass?.trim().split(/\s+/g);
  return classes
    ? classes
        .filter((c: string) => !c.startsWith("list-style-type-"))
        .join(" ")
        .trim()
    : null;
};

export const getNewListItemClass = (
  listItemClass: string | null,
  listType: "orderedList" | "bulletList",
  command: "split" | "sink" | "lift",
) => {
  const baseClass = getListItemBaseClass(listItemClass);
  const listTypeClass = getNewListMarkerClass(listItemClass, listType, command);

  return `${baseClass || ""} ${listTypeClass || ""}`.trim();
};

export const getSelectedText = (state?: EditorState) => {
  if (!state) {
    return "";
  }

  const { from, to } = state.selection;
  return state.doc.textBetween(from, to, " ").trim();
};

export interface ITextContentDetails {
  textContent: string;
  startPos: number | null;
  endPos: number | null;
}

const getFirstNodesTextForDetailsNode = (node: Node, offset: number, from: number): ITextContentDetails | null => {
  const currentNode = node.content.firstChild;

  if (!currentNode) {
    return null;
  }

  // Extract text from the text node
  if (currentNode.type.name !== "details" && currentNode.type.name !== "detailsContent") {
    const textContent = currentNode.textContent;
    const startPos = from + offset;
    const endPos = startPos + textContent.length;

    return { textContent, startPos, endPos };
  }

  // Need to go further deep to find a text node
  return getFirstNodesTextForDetailsNode(currentNode, offset, from);
};

export const getFirstNodesTextFromSelection = (state?: EditorState): ITextContentDetails => {
  let textContent: string = "";
  let foundTextNode: boolean = false;
  let startPos: number | null = null;
  let endPos: number | null = null;

  if (!state) {
    return { textContent, startPos, endPos };
  }

  const { from } = state.selection;
  const nodes = state.selection.content().content;
  nodes.forEach((node, offset) => {
    if (foundTextNode) {
      return;
    }

    if (node.type.name === "paragraph" || node.type.name === "heading" || node.type.name === "title") {
      textContent = node.textContent;
      startPos = from + offset;
      endPos = startPos + textContent.length;
      foundTextNode = true;
    } else if (node.type.name === "bulletList" || node.type.name === "orderedList") {
      // <ol>
      //   <li>
      //     <p>...</p>     <---- We need textContent of this node.
      //   </li>
      // </ol>
      const paragraph = node.content.firstChild!.firstChild;
      if (paragraph) {
        textContent = paragraph.textContent;
        startPos = from + offset;
        endPos = startPos + textContent.length;
        foundTextNode = true;
      }
    } else if (node.type.name === "details") {
      const details = getFirstNodesTextForDetailsNode(node, offset, from);
      if (details) {
        textContent = details.textContent;
        startPos = details.startPos;
        endPos = details.endPos;
      }
    }
  });

  return { textContent, startPos, endPos };
};

export function getTextAlignClass(className: string) {
  const classes = className?.trim().split(/\s+/g);
  return classes ? classes.find((c: string) => c.startsWith("horizontal-align-")) : null;
}

export function getTextAlignment(className: string) {
  const textAlignClass = getTextAlignClass(className);
  return textAlignClass ? textAlignClass.split("-")[2] : null;
}

export const getBaseClassWithoutTextAlignment = (className: string | null) => {
  const classes = className?.trim().split(/\s+/g);
  return classes
    ? classes
        .filter((c: string) => !c.startsWith("horizontal-align-"))
        .join(" ")
        .trim()
    : null;
};

export function getImageAlignClass(className: string) {
  const classes = className?.trim().split(/\s+/g);
  return classes ? classes.find((c: string) => c.startsWith("image-style-")) : null;
}

export function getImageAlignment(className: string) {
  const imageAlignClass = getImageAlignClass(className);
  return imageAlignClass ? imageAlignClass?.split("-").slice(2).join("-") : null;
}

export const getBaseClassWithoutImageAlignment = (className: string | null) => {
  const classes = className?.trim().split(/\s+/g);
  return classes
    ? classes
        .filter((c: string) => !c.startsWith("image-style-"))
        .join(" ")
        .trim()
    : null;
};

export const getBaseClassWithoutHeadingAndTitle = (listItemClass: string | null) => {
  const classes = listItemClass?.trim().split(/\s+/g);
  return classes
    ? classes
        .filter((c: string) => !c.startsWith("heading-") && !c.startsWith("title"))
        .join(" ")
        .trim()
    : null;
};
