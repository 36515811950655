/**
 * Available sizes.
 *
 * @createZodSchema
 */
export enum TLSizeStyle {
  /**
   * @description Small size.
   */
  s = "s",

  /**
   * @description Medium size.
   */
  m = "m",

  /**
   * @description Large size.
   */
  l = "l",

  /**
   * @description Extra large size.
   */
  xl = "xl",
}
