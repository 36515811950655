import React from "react";

import { SvgIcon, SvgIconProps } from "@mui/material";

export const ImageStyleAlignCenterIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path d="M 21 20 L 3 20 L 3 18 L 21 18 Z M 21 2 L 21 4 L 3 4 L 3 2 Z" />
    <path d="M 15 15 L 9 15 L 9 7 L 15 7 Z" />
  </SvgIcon>
);
