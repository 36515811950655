import { StandardHtmlColors } from "@bigpi/cookbook";
import { IGroupBubbleChartShape, getGroupBubbleChartShapeDefaultProps, groupBubbleChartShapeProps } from "@bigpi/tl-schema";
import { HTMLContainer, useValue } from "@tldraw/tldraw";
import * as React from "react";
import { useTranslation } from "react-i18next";

import { AutoResizingContainer } from "Components/AutoResizingContainer/AutoResizingContainer";
import { IAnalysisShapeData, useBoardDatastore } from "BoardComponents/BoardDatastore";
import { BoxBaseUtil } from "BoardComponents/BaseShapes/BoxBaseUtil";
import { useIsInteracting } from "BoardComponents/Tools";
import { useShapeEvents } from "BoardComponents/useShapeEvents";
import { GroupBubbleChart } from "Components/Charting/Charts/GroupBubbleChart";
import { GroupBubbleChartLoader } from "Components/Charting/Loaders/GroupBubbleChartLoader";
import { HistogramChartLoader } from "Components/Charting/Loaders/HistogramChartLoader";
import { ChartUtils } from "Utils/ChartUtils";
import { DataUtils } from "Utils/DataUtils";

// *********************************************
// Private constants
// *********************************************/
/**
 * The colors to use for the chart in the order they should be used.
 */
const ORDERED_COLORS = [
  StandardHtmlColors.sky,
  StandardHtmlColors.willow,
  StandardHtmlColors.wisteria,
  StandardHtmlColors.saffron,
  StandardHtmlColors.heather,
  StandardHtmlColors.coral,
  StandardHtmlColors.pine,
  StandardHtmlColors.pacific,
  StandardHtmlColors.berry,
  StandardHtmlColors.cayenne,
  StandardHtmlColors.jade,
  StandardHtmlColors.patina,
  StandardHtmlColors.midnight,
];

/**
 * Padding to add around the chart to ensure that the warning message is visible and isn't on the edge of the chart.
 */
const WARNING_PADDING = 20;

// *********************************************
// Shape Util
// *********************************************/
/**
 * Generator for generic bubble chart shapes that support grouping.
 */
export class GroupBubbleChartUtil extends BoxBaseUtil<IGroupBubbleChartShape> {
  // *********************************************
  // Static fields
  // *********************************************/
  static type = "groupBubbleChart";

  static props = groupBubbleChartShapeProps;

  // *********************************************
  // Override methods
  // *********************************************/
  /**
   * @inheritdoc
   */
  override canBind = (shape: IGroupBubbleChartShape) => true;

  /**
   * @inheritdoc
   */
  override canEdit = (shape: IGroupBubbleChartShape) => true;

  /**
   * @inheritdoc
   */
  override canResize = (shape: IGroupBubbleChartShape) => false;

  /**
   * @inheritdoc
   */
  override canScroll = (shape: IGroupBubbleChartShape) => false;

  /**
   * @inheritdoc
   */
  override isAspectRatioLocked = (shape: IGroupBubbleChartShape) => false;

  /**
   * @inheritdoc
   */
  override getDefaultProps(): IGroupBubbleChartShape["props"] {
    return getGroupBubbleChartShapeDefaultProps();
  }

  /**
   * @inheritdoc
   */
  override indicator(shape: IGroupBubbleChartShape) {
    const { id } = shape;
    const isEditing = this.editor.getCurrentPageState().editingShapeId === id;
    return <rect width={shape.props.w} height={shape.props.h} stroke={isEditing ? "transparent" : ""} />;
  }

  /**
   * @inheritdoc
   */
  component(shape: IGroupBubbleChartShape) {
    const tldrawEditor = this.editor;
    const isEditing = useValue(
      "groupBubbleChart.isEditing",
      () => tldrawEditor.getCurrentPageState().editingShapeId === shape.id,
      [tldrawEditor, shape.id],
    );
    const isInteracting = useIsInteracting(shape.id);
    const parentId = useValue("groupBubbleChart.parentId", () => tldrawEditor.getShape(shape.id)!.parentId, [shape.id]);
    const datastore = useBoardDatastore();
    const widthRef = React.useRef<number>(shape.props.w);
    const { t } = useTranslation();

    const { handleInputPointerDown } = useShapeEvents(shape.id);

    React.useEffect(() => {
      if (widthRef.current) {
        this.editor.updateShapes([
          {
            id: shape.id,
            type: shape.type,
            props: {
              w: widthRef.current,
            },
          },
        ]);
      }
    }, [widthRef.current]);

    // Get the parent data from the datastore (without strong types)
    const parentData = useValue(
      "groupBubbleChart.parentData",
      () =>
        datastore.state.get()[parentId]?.get() as IAnalysisShapeData<
          Array<Record<string, any>>,
          Record<string, any>,
          Record<string, any>,
          Record<string, any>
        >,
      [datastore, parentId],
    );

    // Slices the original colors to start from the preferred color
    const colorsInPreferredOrder = useValue(
      "preferredColors",
      () => {
        if (parentData) {
          const preferences = parentData?.preferences?.get() || DataUtils.getImmutableEmptyObject<Record<string, any>>();
          const analysisPreferences = preferences.analysis;
          const startColor = analysisPreferences?.startColor;
          if (startColor) {
            const index = ORDERED_COLORS.indexOf(startColor);
            return ORDERED_COLORS.slice(index).concat(ORDERED_COLORS.slice(0, index));
          }
          return ORDERED_COLORS;
        }
        return ORDERED_COLORS;
      },
      [parentData],
    );

    if (!parentData) {
      return (
        <HTMLContainer
          id={shape.id}
          style={{
            background: "#fff",
            display: "block",
            pointerEvents: "all",
            padding: WARNING_PADDING,
          }}
        >
          {t("Components.Charts.NoParent")}
        </HTMLContainer>
      );
    }

    // Get the other required data, such as facets and config from the datastore
    const selection = parentData?.selection?.get() || DataUtils.getImmutableEmptyObject<Record<string, any>>();
    const preferences = parentData?.preferences?.get() || DataUtils.getImmutableEmptyObject<Record<string, any>>();
    const allData = parentData?.allData.get() || DataUtils.getImmutableEmptyArray<Record<string, any>>();
    const configLoading = parentData?.configLoading?.get() || false;
    const dataLoading = parentData?.dataLoading?.get() || false;
    const config = parentData?.config.get() || DataUtils.getImmutableEmptyObject<Record<string, any>>();
    const scales = parentData?.scales?.get() || DataUtils.getImmutableEmptyObject<Record<string, any>>();
    const xDomainValues = parentData?.xDomainValues?.get() || [];
    const yAxisTickFormat = parentData?.yAxisTickFormat?.get();
    const plotReducers = parentData?.plotReducers?.get() || DataUtils.getImmutableEmptyObject<Record<string, any>>();
    const dataFormatters = parentData?.dataFormatters?.get();
    const onAxisSelection = parentData?.onGroupBubbleChartAxisSelection?.get();
    const onExpandedGroupsChange = parentData?.onGroupBubbleChartExpandedGroupsChange?.get();

    const { groupBubbleChart = {}, analysis = {} } = config;
    const { fields = {}, facetFields = {} } = groupBubbleChart;
    const analysisPreferences = preferences.analysis;

    widthRef.current = ChartUtils.getPlotWidth(analysis.approximateMarginLeft, xDomainValues, analysis.xAxisSingleLabelWidth);
    const axisFields: {
      xField: string;
      yGroupField: string;
      yItemField?: string;
      bubbleChartGroupReducerField: string;
      barChartGroupReducerField: string;
    } = {
      xField: fields.xField,
      yGroupField: fields.yGroupField,
      bubbleChartGroupReducerField: fields.bubbleChartGroupReducerField || fields.groupReducerField,
      barChartGroupReducerField: fields.barChartGroupReducerField || fields.groupReducerField,
    };

    if (analysisPreferences && analysisPreferences.showQuestions) {
      axisFields["yItemField"] = fields.yItemField;
    }

    return (
      <HTMLContainer
        id={shape.id}
        style={{
          background: "transparent",
          display: "block",
          pointerEvents: "all",
        }}
      >
        <div
          style={{
            pointerEvents: isEditing || isInteracting ? "auto" : "none",
          }}
          onPointerDown={handleInputPointerDown}
        >
          {dataLoading || configLoading ? (
            <>
              <HistogramChartLoader withLabel={false} sx={{ marginLeft: 10 }} />
              <GroupBubbleChartLoader />
            </>
          ) : !allData || allData.length === 0 || !config || Object.keys(config).length === 0 ? (
            <div style={{ width: "100%", height: "100%", background: "#fff", padding: WARNING_PADDING }}>
              {t("Components.Charts.NoData")}
            </div>
          ) : (
            <AutoResizingContainer onResize={this._onShapeResize.bind(this, shape)}>
              <GroupBubbleChart
                xDomainValues={xDomainValues}
                {...axisFields}
                barChartData={allData}
                colors={colorsInPreferredOrder}
                data={allData}
                marginLeft={analysis.approximateMarginLeft}
                plotWidth={widthRef.current}
                facetFields={facetFields}
                colorOpacityField={fields.colorOpacityField}
                facets={selection}
                fillReducer={plotReducers.fillReducer}
                groupReducers={plotReducers.groupReducers}
                dataFormatters={dataFormatters}
                onAxisSelection={onAxisSelection?.bind(undefined, shape.id) || (() => {})}
                onExpandedGroupsChange={onExpandedGroupsChange || (() => {})}
                scales={scales}
                yAxisTickFormat={yAxisTickFormat}
              />
            </AutoResizingContainer>
          )}
        </div>
      </HTMLContainer>
    );
  }

  // *********************************************
  // Private methods, event handlers
  // *********************************************/
  /**
   * Handles shape resize events.
   *
   * @param shape The shape that was resized.
   * @param width The new width.
   * @param height The new height.
   */
  private _onShapeResize(shape: IGroupBubbleChartShape, width: number, height: number) {
    const { id, type } = shape;
    this.editor.updateShapes([
      {
        id,
        type,
        props: {
          h: height || shape.props.h,
        },
      },
    ]);
  }
}
