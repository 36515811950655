import { ApolloClient, useApolloClient } from "@apollo/client";
import {
  BoardSearchFieldTypes,
  BoardSearchShapeFields,
  IBoardSearchField,
  IBoardSearchShapeMatch,
  IBoardSearchShapeResult,
  IPlugIn,
  PlugInManager,
} from "@bigpi/cookbook";
import { useAuthUser } from "@frontegg/react";
import { CloseOutlined, KeyboardArrowDownOutlined, KeyboardArrowUpOutlined } from "@mui/icons-material";
import { Card, IconButton, InputAdornment, Popper, TextField, Tooltip } from "@mui/material";
import { Editor, TLShape, createShapeId, useValue } from "@tldraw/tldraw";
import { TFunction } from "i18next";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";

import { ISearchableShape } from "BoardComponents/BoardDatastore/ISearchableShape";
import { useBoardDatastore } from "BoardComponents/BoardDatastore/useBoardDatastore";
import { useShapeLifecycleEventEmitter } from "BoardComponents/ShapeLifecycleManager/useShapeLifecycleEventEmitter";
import { OnShapeReadyEventArgs } from "BoardComponents/ShapeLifecycleManager/ShapeLifecycleEventEmitter";
import { useIsChatEnabled } from "Chat/Hooks";
import { useWorkspacesPageUserPreferencesQuery } from "GraphQL/Generated/Apollo";
import { useCollaborativeBoardEditor } from "TldrawBoard/useCollaborativeBoardEditor";
import { OnBoardSearchRequestEventArgs, OnBoardSearchResponseEventArgs } from "./BoardSearchExecutor";
import { createBoardSearchPlugIns } from "./BoardSearchPlugIns/BoardSearchPlugIns";
import { useBoardSearchExecutor } from "./useBoardSearchExecutor";
import { useBoardSearchForSearchableNativeShapes } from "./useBoardSearchForSearchableNativeShapes";

import "./BoardSearchManager.css";

const NAVIGATE_TO_SHAPE_DURATION = 1000;
const BOARD_SEARCH_DEBOUNCE_DELAY = 400;

/**
 * Interface for the board search shape result plug-ins.
 */
export interface IBoardSearchPlugInInputs {
  apolloClient: ApolloClient<object>;
  editor: Editor;
  organizationId: string;
  searchField: IBoardSearchField;
  searchTerm: string;
  shape: TLShape;
  t: TFunction;
  workspaceBoardId: string;
}

/**
 * Props for BoardSearchManager component.
 */
export interface IBoardSearchManagerProps {}

/**
 * Handles the top-level search functionality for the board.
 *
 * @param props The component props.
 *
 * @returns A component that manages the search functionality for the top-level board.
 */
export function BoardSearchManager(props: IBoardSearchManagerProps) {
  const { t } = useTranslation();

  const apolloClient = useApolloClient();
  const datastore = useBoardDatastore();
  const tldrawEditor = useCollaborativeBoardEditor();
  const boardSearchExecutor = useBoardSearchExecutor();
  const shapeLifecycleEventEmitter = useShapeLifecycleEventEmitter();
  const user = useAuthUser();
  const isChatEnabled = useIsChatEnabled();
  const location = useLocation();
  const { data: sidebarPreferences } = useWorkspacesPageUserPreferencesQuery({
    variables: {
      key: location.pathname,
    },
  });
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [open, setOpen] = useState(false);
  const [totalMatches, setTotalMatches] = useState(0);
  const [currentTopNavigationIndex, setCurrentTopNavigationIndex] = useState(0);
  const [currentNavigationState, setCurrentNavigationState] = useState<{ shapeId: string; match: string; index: number } | null>(
    null,
  );
  const [searchResults, setSearchResults] = useState<Array<IBoardSearchShapeResult>>([]);

  // Create and initialize plug-in manager to handle board search results
  const boardSearchResultPlugInManager = new PlugInManager<
    IBoardSearchPlugInInputs,
    Array<IBoardSearchShapeMatch>,
    IPlugIn<IBoardSearchPlugInInputs, Array<IBoardSearchShapeMatch>>
  >();
  boardSearchResultPlugInManager.registerPlugIns(createBoardSearchPlugIns());

  const workspaceBoardId = (tldrawEditor?.getInstanceState()?.meta?.workspaceBoardId as string) || "";

  useBoardSearchForSearchableNativeShapes();

  const searchManagerRight = useValue(
    "searchManagerRight",
    () => {
      // Calculate right based on the sidebar/chat
      return isChatEnabled && sidebarPreferences?.userPreference?.data?.open
        ? (sidebarPreferences?.userPreference?.data?.sidebarWidth || 0) + 150
        : 210;
    },
    [isChatEnabled, sidebarPreferences],
  );

  const onAfterShapeReady = useCallback(
    ({ shapeId }: OnShapeReadyEventArgs) => {
      // Highlight matches for the shape when it is ready
      const isShapePartOfSearchResults = searchResults.findIndex((r) => r.shapeId === shapeId) !== -1;
      if (isShapePartOfSearchResults) {
        const shapeData = datastore.state.get()[shapeId]?.get() as ISearchableShape | undefined;
        if (shapeData) {
          const highlightSearchMatches = shapeData.highlightSearchResults?.get();
          highlightSearchMatches?.(searchTerm);
        }
      }

      // Highlight the active search result in the current navigation shape when it is ready
      if (currentNavigationState && shapeId === currentNavigationState.shapeId) {
        const shapeData = datastore.state.get()[currentNavigationState.shapeId]?.get() as ISearchableShape | undefined;
        if (shapeData) {
          const highlightActiveSearchResultByIndex = shapeData.highlightActiveSearchResultByIndex?.get();
          highlightActiveSearchResultByIndex?.(currentNavigationState.match, currentNavigationState?.index);
        }
      }
    },
    [currentNavigationState, datastore, searchTerm],
  );

  useEffect(() => {
    shapeLifecycleEventEmitter.on("afterShapeReady", onAfterShapeReady);
    return () => {
      shapeLifecycleEventEmitter.off("afterShapeReady", onAfterShapeReady);
    };
  }, [onAfterShapeReady, shapeLifecycleEventEmitter]);

  // Navigate to the match when the current navigation state changes
  useEffect(() => {
    if (currentNavigationState === null || tldrawEditor === null) {
      return;
    }

    // Move to the shape
    const { shapeId } = currentNavigationState;
    const tlShapeId = createShapeId(shapeId.slice(6));
    const shape = tldrawEditor.getShape(tlShapeId);
    if (shape) {
      const bounds = tldrawEditor.getShapePageBounds(shape);
      if (bounds) {
        tldrawEditor.zoomToBounds(bounds, {
          targetZoom: 1,
          duration: NAVIGATE_TO_SHAPE_DURATION,
        });
      }
    }

    // Highlight the active match if the shape is already rendered
    const isCurrentNavigationShapeRendering =
      tldrawEditor.getRenderingShapes().findIndex((r) => r.id === currentNavigationState.shapeId) !== -1;
    if (isCurrentNavigationShapeRendering) {
      const shapeData = datastore.state.get()[currentNavigationState.shapeId]?.get() as ISearchableShape | undefined;
      if (shapeData) {
        const highlightActiveSearchResultByIndex = shapeData.highlightActiveSearchResultByIndex?.get();
        highlightActiveSearchResultByIndex?.(currentNavigationState.match, currentNavigationState?.index);
      }
    }
  }, [currentNavigationState, datastore, tldrawEditor]);

  const onExecuteBoardSearch = useCallback(
    async (newSearchTerm: string) => {
      // Rotate the session ID to prevent any old search results from being used
      boardSearchExecutor.rotateSessionId();

      // Call the executor to run the board search and trigger the lifecycle events
      await boardSearchExecutor.executeBoardSearch(newSearchTerm);
    },
    [boardSearchExecutor],
  );

  const onExecuteBoardSearchDebounced = useDebouncedCallback(onExecuteBoardSearch, BOARD_SEARCH_DEBOUNCE_DELAY);

  // Calculate search results when the search term changes
  useEffect(() => {
    onExecuteBoardSearchDebounced(searchTerm);
  }, [onExecuteBoardSearchDebounced, searchTerm]);

  const getBoardSearchResultsForShapeField = useCallback(
    async (fieldType: BoardSearchFieldTypes, inputs: IBoardSearchPlugInInputs): Promise<Array<IBoardSearchShapeMatch>> => {
      const searchResults = await boardSearchResultPlugInManager.executeIfRegistered(fieldType, inputs);
      if (!searchResults) {
        return [];
      }

      return searchResults;
    },
    [boardSearchResultPlugInManager],
  );

  const getSearchResultsFromShapes = useCallback(async (): Promise<Array<IBoardSearchShapeResult>> => {
    const shapeSearchResults: Array<IBoardSearchShapeResult> = [];

    // If the search term is empty or TLDraw editor is not available, return an empty array of search results.
    if (searchTerm === "" || !tldrawEditor) {
      return shapeSearchResults;
    }

    const allShapes = tldrawEditor.store.allRecords().filter((record) => record.typeName === "shape") as Array<TLShape>;
    await Promise.all(
      BoardSearchShapeFields.map(async (shapeMapping) => {
        // Find all shapes that match the shape type.
        const matchingShapes = allShapes.filter((shape) => shape.type === shapeMapping.type);
        if (matchingShapes.length === 0) {
          return;
        }

        const searchFields = shapeMapping.searchFields;
        await Promise.all(
          matchingShapes.map(async (matchingShape) => {
            // Find the search result for the shape, or create a new one if it doesn't exist.
            const shapeSearchResult: IBoardSearchShapeResult = shapeSearchResults.find(
              (shapeSearchResult) => shapeSearchResult.shapeId === matchingShape.id,
            ) || { shapeId: matchingShape.id, matches: [] };

            await Promise.all(
              searchFields.map(async (searchField) => {
                // Get the search matches for the field data based on the type of the field.
                const searchResultsForShapeField = await getBoardSearchResultsForShapeField(searchField.type, {
                  apolloClient,
                  editor: tldrawEditor,
                  organizationId: user.tenantId,
                  searchField,
                  searchTerm,
                  shape: matchingShape,
                  t,
                  workspaceBoardId,
                });
                shapeSearchResult.matches.push(...searchResultsForShapeField);
              }),
            );

            // Don't add the search result if there are no matches.
            if (shapeSearchResult.matches.length === 0) {
              return;
            }

            // Replace the search result for the shape, or add a new one if it doesn't exist.
            const shapeSearchResultIndex = shapeSearchResults.findIndex(
              (shapeSearchResult) => shapeSearchResult.shapeId === matchingShape.id,
            );
            shapeSearchResults.splice(
              shapeSearchResultIndex === -1 ? shapeSearchResults.length : shapeSearchResultIndex,
              shapeSearchResultIndex === -1 ? 0 : 1,
              shapeSearchResult,
            );
          }),
        );
      }),
    );

    return shapeSearchResults;
  }, [getBoardSearchResultsForShapeField, searchTerm, tldrawEditor]);

  const onBoardSearchRequest = useCallback(
    async (data: OnBoardSearchRequestEventArgs) => {
      const { searchTerm: newSearchTerm, sessionId: newSessionId } = data;
      if (!tldrawEditor) {
        boardSearchExecutor.raiseBeforeBoardSearchResponse({
          searchResults: [],
          sessionId: newSessionId,
          searchTerm: newSearchTerm,
        });

        boardSearchExecutor.raiseBoardSearchResponse({ searchResults: [], sessionId: newSessionId, searchTerm: newSearchTerm });

        boardSearchExecutor.raiseAfterBoardSearchResponse({
          searchResults: [],
          sessionId: newSessionId,
          searchTerm: newSearchTerm,
        });

        return;
      }

      let newSearchResults: Array<IBoardSearchShapeResult> = await getSearchResultsFromShapes();

      boardSearchExecutor.raiseBeforeBoardSearchResponse({
        searchResults: newSearchResults,
        sessionId: newSessionId,
        searchTerm: newSearchTerm,
      });

      boardSearchExecutor.raiseBoardSearchResponse({
        searchResults: newSearchResults,
        sessionId: newSessionId,
        searchTerm: newSearchTerm,
      });

      boardSearchExecutor.raiseAfterBoardSearchResponse({
        searchResults: newSearchResults,
        sessionId: newSessionId,
        searchTerm: newSearchTerm,
      });
    },
    [boardSearchExecutor, getSearchResultsFromShapes, tldrawEditor],
  );

  // Add our custom handler for the onBoardSearchRequest event
  useEffect(() => {
    boardSearchExecutor.on("boardSearchRequest", onBoardSearchRequest);
    return () => {
      boardSearchExecutor.off("boardSearchRequest", onBoardSearchRequest);
    };
  }, [boardSearchExecutor, onBoardSearchRequest]);

  const clearShapeHighlights = useCallback(async () => {
    // Reset the top navigation index
    setCurrentTopNavigationIndex(0);

    // Remove old search highlights
    searchResults.forEach((result) => {
      const shapeData = datastore.state.get()[result.shapeId]?.get() as ISearchableShape | undefined;
      if (shapeData) {
        const clearSearchHighlights = shapeData.clearSearchHighlights?.get();
        clearSearchHighlights?.();
      }
    });
  }, [datastore, searchResults]);

  // Add our custom handler for the onBeforeBoardSearchRequest event
  useEffect(() => {
    boardSearchExecutor.on("beforeBoardSearchRequest", clearShapeHighlights);
    return () => {
      boardSearchExecutor.off("beforeBoardSearchRequest", clearShapeHighlights);
    };
  }, [boardSearchExecutor, clearShapeHighlights]);

  const onBoardSearchResponse = useCallback(
    async (data: OnBoardSearchResponseEventArgs) => {
      // Drop the search results if the session ID does not match the current search request session ID
      if (data.sessionId !== boardSearchExecutor.sessionId) {
        return;
      }

      const { searchResults: newSearchResults } = data;
      setSearchResults(newSearchResults);
      setTotalMatches(newSearchResults.reduce((acc, result) => acc + result.matches.length, 0));

      // Highlight the search matches in the shapes that are currently rendered
      newSearchResults.forEach((result) => {
        const isShapeRendering =
          tldrawEditor && tldrawEditor.getRenderingShapes().findIndex((r) => r.id === result.shapeId) !== -1;
        if (isShapeRendering) {
          const shapeData = datastore.state.get()[result.shapeId]?.get() as ISearchableShape | undefined;
          if (shapeData) {
            const highlightSearchMatches = shapeData.highlightSearchResults?.get();
            highlightSearchMatches?.(searchTerm!);
          }
        }
      });

      // Set the current navigation state to the first match of the first shape
      if (newSearchResults.length > 0) {
        const { shapeId, matches } = newSearchResults[0];
        if (matches.length > 0) {
          const { match, index } = matches[0];

          // Set the current navigation state to the first match of the first shape
          setCurrentNavigationState({ shapeId, match, index });
        }
      }
    },
    [datastore, searchTerm, tldrawEditor],
  );

  // Add our custom handler for the onBeforeBoardSearchRequest event
  useEffect(() => {
    boardSearchExecutor.on("boardSearchResponse", onBoardSearchResponse);
    return () => {
      boardSearchExecutor.off("boardSearchResponse", onBoardSearchResponse);
    };
  }, [boardSearchExecutor, onBoardSearchResponse]);

  // TODO: Recalculate search results when the board contents change
  // useEffect(() => {
  //   if (tldrawEditor) {
  //     tldrawEditor.on("update", () => onExecuteBoardSearch(searchTerm));
  //   }

  //   return () => {
  //     if (tldrawEditor) {
  //       tldrawEditor.off("update", () => onExecuteBoardSearch(searchTerm));
  //     }
  //   };
  // }, [tldrawEditor, onExecuteBoardSearch, searchTerm]);

  const openSearchDialog = useCallback(() => {
    setOpen(true);

    // Focus the input field when the popper opens
    setTimeout(() => inputRef.current?.focus(), 0);
  }, []);

  useEffect(() => {
    function onKeyDown(e: KeyboardEvent) {
      // "Cmd + F" will open the search dialog.
      if (e.key === "f" && e.metaKey) {
        openSearchDialog();

        // Do not open the browser's search dialog
        e.preventDefault();
      }
    }

    // This event can be triggered by the "find-in-board" action in the TLDraw editor.
    // apps/patron/src/TldrawBoard/CollaborativeBoardEditor.tsx -> uiOverrides.actions -> schema["find-in-board"]
    window.document.addEventListener("keydown", onKeyDown);

    return () => {
      window.document.removeEventListener("keydown", onKeyDown);
    };
  }, [openSearchDialog]);

  const navigateSearchMatches = useCallback(
    (direction: "forward" | "backward") => {
      if (searchResults.length === 0 || tldrawEditor === null || currentNavigationState === null) {
        return;
      }

      const { shapeId: currentNavigationShapeId, index: currentShapeNavigationIndex } = currentNavigationState;
      const currentSearchResultIndex = searchResults.findIndex((r) => r.shapeId === currentNavigationState.shapeId);
      const currentSearchResult = currentSearchResultIndex !== -1 ? searchResults[currentSearchResultIndex] : null;
      if (currentSearchResult === null) {
        return;
      }

      const totalMatchesForCurrentShape = currentSearchResult.matches.length;

      let newNavigationIndex: number = currentShapeNavigationIndex;
      let newNavigationShapeId: string = currentNavigationShapeId;
      if (direction === "forward" && currentShapeNavigationIndex + 1 === totalMatchesForCurrentShape) {
        // Move to the next shape if we are at the end of the current shape's matches
        newNavigationShapeId =
          currentSearchResultIndex + 1 < searchResults.length
            ? searchResults[currentSearchResultIndex + 1].shapeId
            : searchResults[0].shapeId;
        // Go to the first match of the next shape
        newNavigationIndex = 0;

        setCurrentTopNavigationIndex(currentTopNavigationIndex + 1 < totalMatches ? currentTopNavigationIndex + 1 : 0);
      } else if (direction === "backward" && currentShapeNavigationIndex === 0) {
        // Move to the previous shape if we are at the beginning of the current shape's matches
        newNavigationShapeId =
          currentSearchResultIndex - 1 >= 0
            ? searchResults[currentSearchResultIndex - 1].shapeId
            : searchResults[searchResults.length - 1].shapeId;

        // Go to the last match of the previous shape
        const newShapeSearchMatches = searchResults.find((r) => r.shapeId === newNavigationShapeId);
        if (newShapeSearchMatches) {
          newNavigationIndex = newShapeSearchMatches.matches.length - 1;
        }

        setCurrentTopNavigationIndex(currentTopNavigationIndex - 1 >= 0 ? currentTopNavigationIndex - 1 : totalMatches - 1);
      } else {
        // Move to the next or previous match of the current shape
        newNavigationIndex = direction === "forward" ? currentShapeNavigationIndex + 1 : currentShapeNavigationIndex - 1;

        setCurrentTopNavigationIndex(direction === "forward" ? currentTopNavigationIndex + 1 : currentTopNavigationIndex - 1);
      }

      // Additional check to make sure the shape, that we are navigating to, exists in the search results
      const shapeSearchMatches = searchResults.find((r) => r.shapeId === newNavigationShapeId);
      if (!shapeSearchMatches) {
        return;
      }

      // Clear the old shape's highlights if we are navigating to a new shape
      // This is to remove the "orange" highlight from the old shape's match
      if (currentNavigationState.shapeId !== newNavigationShapeId) {
        const oldShapeData = datastore.state.get()[currentNavigationShapeId]?.get() as ISearchableShape | undefined;
        const highlightActiveSearchResultByIndex = oldShapeData?.highlightActiveSearchResultByIndex?.get();
        highlightActiveSearchResultByIndex?.(searchTerm, -1);
      }

      // Set the current navigation state
      setCurrentNavigationState({
        shapeId: newNavigationShapeId,
        match: shapeSearchMatches.matches[newNavigationIndex].match,
        index: newNavigationIndex,
      });
    },
    [currentNavigationState, datastore, searchResults, tldrawEditor],
  );

  const closeSearchDialog = useCallback(() => {
    // Clear highlights
    clearShapeHighlights();

    // Clear the search state
    setSearchTerm("");
    setTotalMatches(0);
    setCurrentNavigationState(null);
    setSearchResults([]);

    // Close the search dialog
    setOpen(false);
  }, [clearShapeHighlights]);

  const handleTextFieldKeyDown = (e: React.KeyboardEvent) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      // "Enter" will navigate to the next match.
      navigateSearchMatches("forward");
    } else if (e.keyCode === 13 && e.shiftKey === true) {
      // "Shift + Enter" will navigate to the previous match.
      navigateSearchMatches("backward");
    }
  };

  const isNavigationDisabled = totalMatches <= 1;

  const virtualElement = {
    getBoundingClientRect: () => new DOMRect(0, 0, 0, 0),
  };

  return (
    <Popper
      id={open ? "board-search-manager" : undefined}
      open={open}
      sx={{
        top: "5px !important",
        left: "auto !important",
        right: `${searchManagerRight}px !important`,
      }}
      anchorEl={virtualElement}
    >
      <Card sx={{ m: 1, p: 1 }}>
        <TextField
          id="board-search-term"
          variant="outlined"
          placeholder={t("Components.BoardSearchManager.SearchPlaceholder")}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {t("Components.BoardSearchManager.NavigationText", {
                  currentIndex: totalMatches > 0 ? currentTopNavigationIndex + 1 : 0,
                  totalMatches,
                })}
              </InputAdornment>
            ),
          }}
          size="small"
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
          inputRef={inputRef}
          onKeyDown={handleTextFieldKeyDown}
          autoComplete="off"
        />

        <IconButton onClick={() => navigateSearchMatches("backward")} disabled={isNavigationDisabled} disableRipple>
          <Tooltip title={t("Components.BoardSearchManager.NavigationTooltip.Previous")}>
            <KeyboardArrowUpOutlined />
          </Tooltip>
        </IconButton>

        <IconButton onClick={() => navigateSearchMatches("forward")} disabled={isNavigationDisabled} disableRipple>
          <Tooltip title={t("Components.BoardSearchManager.NavigationTooltip.Next")}>
            <KeyboardArrowDownOutlined />
          </Tooltip>
        </IconButton>

        <IconButton onClick={() => closeSearchDialog()} disableRipple>
          <Tooltip title={t("Components.BoardSearchManager.CloseTooltip")}>
            <CloseOutlined />
          </Tooltip>
        </IconButton>
      </Card>
    </Popper>
  );
}
