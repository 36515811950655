import { addHtmlDocumentToBoard } from "BoardComponents/Utils/AddToBoardUtils";
import { ChatMessageAction } from "Chat/ChatActionManagers/ChatMessageActions";
import { IWorkspaceBoardChatActionPlugInInputs } from "./IWorkspaceBoardChatActionPlugInInputs";
import { WorkspaceBoardChatActionPlugInBase } from "./WorkspaceBoardChatActionPlugInBase";

export class AddToBoardPlugIn extends WorkspaceBoardChatActionPlugInBase {
  // *********************************************
  // Public properties
  // *********************************************/
  /**
   * @inheritdoc
   */
  name = ChatMessageAction.AddToBoard;

  // *********************************************
  // Public methods
  // *********************************************/
  /**
   * @inheritdoc
   */
  public async execute(input: IWorkspaceBoardChatActionPlugInInputs): Promise<undefined | JSX.Element> {
    const { apolloClient, app, data } = input;

    if (!app) {
      console.warn(`No app instance for chat action ${this.name}`);
      return;
    }

    if (!apolloClient) {
      console.warn(`No apollo client instance for chat action ${this.name}`);
      return;
    }

    const html = (data && data.content) || "";
    await addHtmlDocumentToBoard(html, app, null, apolloClient);

    return undefined;
  }
}
