import { Image as TipTapImage, ImageOptions as TipTapImageOptions } from "@tiptap/extension-image";
import { Plugin } from "@tiptap/pm/state";
import { EditorView } from "@tiptap/pm/view";
import { mergeAttributes } from "@tiptap/react";

import { getNumericValueAttribute, getStringValueAttribute } from "../../Utils/ExtensionUtil.js";

const getNodeToInsert = (src: string, view: EditorView) => {
  const { schema } = view.state;

  const imageNode = schema.nodes.image.create({
    src: src,
    style: "max-width:100%",
  });
  return schema.nodes.figure.create({}, imageNode);
};

export const dropImagePlugin = (upload?: (image: File) => Promise<string>) => {
  return new Plugin({
    props: {
      handlePaste(view, event) {
        const items = Array.from(event.clipboardData?.items || []);

        items.forEach((item) => {
          const image = item.getAsFile();

          if (item.type.indexOf("image") === 0) {
            event.preventDefault();

            if (upload && image) {
              upload(image).then((src) => {
                const figureNode = getNodeToInsert(src, view);
                const transaction = view.state.tr.replaceSelectionWith(figureNode);
                view.dispatch(transaction);
              });
            }
          } else {
            const reader = new FileReader();
            reader.onload = (readerEvent) => {
              const figureNode = getNodeToInsert(readerEvent.target?.result as string, view);
              const transaction = view.state.tr.replaceSelectionWith(figureNode);
              view.dispatch(transaction);
            };

            if (!image) {
              return;
            }

            reader.readAsDataURL(image);
          }
        });

        return false;
      },
      handleDOMEvents: {
        drop: (view, event) => {
          const hasFiles = event.dataTransfer && event.dataTransfer.files && event.dataTransfer.files.length;

          if (!hasFiles) {
            return false;
          }

          const images = Array.from(event.dataTransfer?.files ?? []).filter((file) => /image/i.test(file.type));

          if (images.length === 0) {
            return false;
          }

          event.preventDefault();

          const coordinates = view.posAtCoords({
            left: event.clientX,
            top: event.clientY,
          });
          if (!coordinates) return false;

          images.forEach(async (image) => {
            const reader = new FileReader();

            if (upload) {
              const src = await upload(image);
              const figureNode = getNodeToInsert(src, view);
              const transaction = view.state.tr.insert(coordinates.pos, figureNode);
              view.dispatch(transaction);
            } else {
              reader.onload = (readerEvent) => {
                const figureNode = getNodeToInsert(readerEvent.target?.result as string, view);
                const transaction = view.state.tr.insert(coordinates.pos, figureNode);
                view.dispatch(transaction);
              };
              reader.readAsDataURL(image);
            }
          });

          return true;
        },
      },
    },
  });
};

export interface ImageOptions extends TipTapImageOptions {
  uploadImage?: (image: File) => Promise<string>;
}

export const Image = TipTapImage.extend<ImageOptions, any>({
  draggable: false,

  addAttributes() {
    return {
      ...this.parent?.(),
      height: getNumericValueAttribute("height", null),
      width: getNumericValueAttribute("width", null),
      alt: {
        default: "",
        parseHTML: (element) => {
          return element.hasAttribute("alt") && typeof element.getAttribute("alt") === "string"
            ? element.getAttribute("alt")
            : "";
        },
      },
      src: getStringValueAttribute("src", null),
      style: "max-width:100%",
    };
  },

  renderHTML({ HTMLAttributes }) {
    return ["img", mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)];
  },

  addProseMirrorPlugins() {
    return [dropImagePlugin(this.options.uploadImage)];
  },

  addOptions() {
    return {
      ...this.parent?.(),
      uploadImage: (image: File) => Promise.resolve(""),
    };
  },

  addCommands() {
    return {
      setImage:
        (options) =>
        ({ commands, tr }) => {
          return commands.insertContentAt(
            { from: tr.selection.from, to: tr.selection.to },
            {
              type: "figure",
              content: [
                {
                  type: this.name,
                  attrs: options,
                },
              ],
            },
          );
        },
    };
  },
});
