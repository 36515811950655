import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import { useTranslation } from "react-i18next";

import { UserAvatar } from "Components/UserAvatar/UserAvatar";
import { ObjectRole } from "GraphQL/Generated/Apollo";
import { Constants } from "@bigpi/permission";

export interface AccessControlListTableRow {
  id: string;
  userGroup?: {
    id: string;
    name: string;
    isSystem: boolean;
  } | null;
  user?: {
    id: string;
    name: string;
    profilePictureUrl?: string | null;
  } | null;
  role: ObjectRole;
}

export interface AccessControlListTableProps {
  rows: Array<AccessControlListTableRow>;
  showActions: boolean;
  onDelete?: (id: string) => void;
  canDeleteEveryone?: boolean;
  canDeleteSystemGroups?: boolean;
}

export function AccessControlListTable(props: AccessControlListTableProps) {
  const { canDeleteEveryone, canDeleteSystemGroups, onDelete, rows, showActions } = props;
  const { t } = useTranslation();

  return (
    <TableContainer component="div" sx={{ maxHeight: 400 }}>
      <Table stickyHeader sx={{ minWidth: 650 }} aria-label="people who have access">
        <TableHead>
          <TableRow>
            <TableCell>{t("Components.AccessControlListTable.Group")}</TableCell>
            <TableCell>{t("Components.AccessControlListTable.Role")}</TableCell>
            {showActions && <TableCell>{t("Components.AccessControlListTable.Actions")}</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell component="th" scope="row">
                <span style={{ display: "flex", alignItems: "center" }}>
                  <UserAvatar user={row.user} /> &nbsp;
                  {row.userGroup?.name || row.user?.name || t("Components.AccessControlListTable.RemovedRow")}
                </span>
              </TableCell>
              <TableCell>{t(`Global.AccessControlList.Role.${row.role}`)}</TableCell>
              {showActions && (
                <TableCell>
                  <Button
                    color="danger"
                    onClick={() => {
                      if (onDelete) {
                        onDelete(row.id);
                      }
                    }}
                    disabled={
                      (!canDeleteSystemGroups && row.userGroup?.isSystem === true) ||
                      (!canDeleteEveryone && row.user?.id === Constants.EVERYONE_USER_ID)
                    }
                  >
                    {t("Global.Action.Delete")}
                  </Button>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
