import { CommandOutputType } from "@bigpi/cookbook";
import React from "react";

// css
import "./DisplayOutputTemplates.css";

interface IDisplayOutputTemplatesProps {
  onSelect: (commandOutputValues: ICommandOutputValues) => void;
  outputTypes: Array<CommandOutputType>;
  selectedOutputValues: ICommandOutputValues | undefined;
}

export interface ICommandOutputValues {
  name: string;
  outputTemplate: string;
  outputType: CommandOutputType;
}

// TODO: Temporary hard-coded templates until we get the actual data from the configs (or elsewhere)
export const outputTypeMapping: Record<CommandOutputType, ICommandOutputValues> = {
  [CommandOutputType.AnalystQuestionAnalysis]: {
    name: "Add question analysis",
    outputTemplate: "",
    outputType: CommandOutputType.AnalystQuestionAnalysis,
  },
  [CommandOutputType.BoardSearchResults]: {
    name: "Show board search results",
    outputTemplate: "",
    outputType: CommandOutputType.BoardSearchResults,
  },
  [CommandOutputType.Compare]: {
    name: "Compare texts",
    outputTemplate: "",
    outputType: CommandOutputType.Compare,
  },
  [CommandOutputType.Feed]: {
    name: "Show data feed",
    outputTemplate: "",
    outputType: CommandOutputType.Feed,
  },
  [CommandOutputType.Html]: {
    name: "Insert text result",
    outputTemplate: "",
    outputType: CommandOutputType.Html,
  },
  [CommandOutputType.HeadlineSimulator]: {
    name: "Headline simulator",
    outputTemplate: "",
    outputType: CommandOutputType.HeadlineSimulator,
  },
  [CommandOutputType.None]: {
    name: "",
    outputTemplate: "",
    outputType: CommandOutputType.None,
  },
  [CommandOutputType.QuestionAnalysis]: {
    name: "Add (grouped) question analysis",
    outputTemplate: "",
    outputType: CommandOutputType.QuestionAnalysis,
  },
  [CommandOutputType.Summarize]: {
    name: "Summarize text",
    outputTemplate: "",
    outputType: CommandOutputType.Summarize,
  },
  [CommandOutputType.SearchResults]: {
    name: "Show search results",
    outputTemplate: "",
    outputType: CommandOutputType.SearchResults,
  },
  [CommandOutputType.TopicDiscussionAnalysis]: {
    name: "Add topic discussion analysis",
    outputTemplate: "",
    outputType: CommandOutputType.TopicDiscussionAnalysis,
  },
  [CommandOutputType.TopicDiscussionSummaryAnalysis]: {
    name: "Add topic discussion summary analysis",
    outputTemplate: "",
    outputType: CommandOutputType.TopicDiscussionSummaryAnalysis,
  },
  [CommandOutputType.TopicDiscussionInNewsArticleAnalysis]: {
    name: "Add Topic Discussions in the News",
    outputTemplate: "",
    outputType: CommandOutputType.TopicDiscussionInNewsArticleAnalysis,
  },
  [CommandOutputType.Table]: {
    name: "Insert table",
    outputTemplate: "",
    outputType: CommandOutputType.Table,
  },
};

export const DisplayOutputTemplates: React.FC<IDisplayOutputTemplatesProps> = (props) => {
  const { onSelect, outputTypes, selectedOutputValues } = props;

  return (
    <div className="output-options">
      <h3>Output Types</h3>
      {Object.keys(outputTypeMapping).map((mappingKey, index) => {
        if (outputTypes.includes(outputTypeMapping[mappingKey as CommandOutputType].outputType)) {
          return (
            <div
              onPointerUp={() => onSelect(outputTypeMapping[mappingKey as CommandOutputType])}
              key={index}
              className={`output-options__name ${
                selectedOutputValues?.name === outputTypeMapping[mappingKey as CommandOutputType].name ? "selected" : ""
              }`}
            >
              {outputTypeMapping[mappingKey as CommandOutputType].name}
            </div>
          );
        } else {
          return null;
        }
      })}
    </div>
  );
};
