export enum EditorAuthError {
  /**
   * User does not have permission to access the document.
   */
  Unauthorized = "Unauthorized",

  /**
   * Could not authenticate the user due to a server-side error or network error.
   */
  UnableToAuthenticate = "UnableToAuthenticate",
}
