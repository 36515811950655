import { Mark, mergeAttributes } from "@tiptap/core";

import { getClassAttribute } from "../../Utils/ExtensionUtil.js";
import { setHighlightColor } from "./SetHighlightColorCommand.js";
import { unsetHighlightColor } from "./UnsetHighlightColorCommand.js";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    textHighlight: {
      /**
       * Set the text highlight color
       */
      setHighlightColor: (color: HighlightColors) => ReturnType;
      /**
       * Unset the text highlight color
       */
      unsetHighlightColor: () => ReturnType;
    };
  }
}

export enum HighlightColors {
  YELLOW = "yellow",
  RED = "red",
  GREEN = "green",
  BLUE = "blue",
  WISTERIA = "wisteria",
  CAYENNE = "cayenne",
  SAFFRON = "saffron",
  WILLOW = "willow",
  BERRY = "berry",
}

export const DefaultHighlightColors: Array<HighlightColors> = [
  HighlightColors.YELLOW,
  HighlightColors.RED,
  HighlightColors.GREEN,
  HighlightColors.BLUE,
  HighlightColors.WISTERIA,
  HighlightColors.CAYENNE,
  HighlightColors.SAFFRON,
  HighlightColors.WILLOW,
  HighlightColors.BERRY,
];

export interface TextHighlightOptions {
  HTMLAttributes: Record<string, any>;
}

export const TextHighlight = Mark.create<TextHighlightOptions>({
  name: "textHighlight",

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  addAttributes() {
    return {
      class: getClassAttribute(),
    };
  },

  parseHTML() {
    return DefaultHighlightColors.map((color: HighlightColors) => ({
      tag: `span`,
      getAttrs: (node: string | HTMLElement) => (node as HTMLElement).className.includes(`highlight-${color}`) && null,
    })).concat({
      tag: "mark",
      getAttrs: () => null,
    });
  },

  renderHTML({ HTMLAttributes }) {
    return ["mark", mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },

  addCommands() {
    return {
      setHighlightColor,
      unsetHighlightColor,
    };
  },
});
