import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Typography, useTheme } from "@mui/material";

export interface ICompanionTitleProps {
  children: React.ReactNode;
  onClose: () => void;
}

export const CompanionTitle = (props: ICompanionTitleProps) => {
  const { children, onClose } = props;
  const theme = useTheme();

  return (
    <Box
      style={{ cursor: "move" }}
      id="companion-title"
      sx={{ backgroundColor: theme.palette.primary.main, display: "flex", flexDirection: "row" }}
    >
      <div style={{ margin: "auto" }}>
        <IconButton size="medium" sx={{ ml: 1 }} onClick={onClose}>
          <CloseIcon sx={{ color: "white" }} fontSize="medium" />
        </IconButton>
      </div>
      <Box sx={{ display: "flex", flex: "1", flexDirection: "row" }}>
        <Typography
          component="div"
          variant="h5"
          color="white"
          sx={{
            padding: "10px 16px 10px 4px",
            flexGrow: 1,
            maxWidth: "950px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            textWrap: "nowrap",
          }}
        >
          {children}
        </Typography>
      </Box>
    </Box>
  );
};
