import { Constants } from "@bigpi/permission";
import { Autocomplete, Box, Button, TextField } from "@mui/material";
import { ObjectRole, SortOrder } from "GraphQL/Generated/Apollo";
import React from "react";
import { useUsersQuery, useUserGroupsQuery } from "GraphQL/Generated/Apollo";
import { useTranslation } from "react-i18next";

export interface AccessControlListDropdownProps {
  onAdd: (subjectId: string, subjectType: string, role: ObjectRole) => void;
  allowEveryone: boolean;
}

export interface SubjectOptionType {
  label: string;
  value: string;
  type: "User" | "UserGroup";
}

export interface RoleOptionType {
  label: string;
  value: ObjectRole;
}

export function AccessControlListDropdown(props: AccessControlListDropdownProps) {
  const { onAdd } = props;
  const { t } = useTranslation();
  const {
    data: userData,
    loading: userLoading,
    error: userError,
  } = useUsersQuery({
    variables: {
      orderBy: {
        name: SortOrder.Asc,
      },
    },
  });
  const {
    data: userGroupData,
    loading: userGroupLoading,
    error: userGroupError,
  } = useUserGroupsQuery({
    variables: {
      orderBy: {
        name: SortOrder.Asc,
      },
    },
  });

  const roles: Array<RoleOptionType> = [
    // TODO: Uncomment when we have commenting features implemented
    // { label: t("Global.AccessControlList.Role.Commenter"), value: ObjectRole.Commenter },
    { label: t("Global.AccessControlList.Role.ContentManager"), value: ObjectRole.ContentManager },
    { label: t("Global.AccessControlList.Role.Contributor"), value: ObjectRole.Contributor },
    { label: t("Global.AccessControlList.Role.Manager"), value: ObjectRole.Manager },
    { label: t("Global.AccessControlList.Role.Owner"), value: ObjectRole.Owner },
    { label: t("Global.AccessControlList.Role.Viewer"), value: ObjectRole.Viewer },
  ];
  const [subject, setSubject] = React.useState<SubjectOptionType | null>(null);
  const [role, setRole] = React.useState<RoleOptionType | null>(null);

  const users = userData?.users || [];
  let subjects: Array<SubjectOptionType> = users.map((user) => ({ label: user.name, value: user.id, type: "User" }));

  if (!props.allowEveryone) {
    subjects = subjects.filter((user) => user.value !== Constants.EVERYONE_USER_ID);
  }

  if (userGroupData && userGroupData.userGroups) {
    subjects = [
      ...subjects,
      ...userGroupData.userGroups.map(
        (userGroup) => ({ label: userGroup.name, value: userGroup.id, type: "UserGroup" }) as SubjectOptionType,
      ),
    ];
  }

  function handleOnAdd() {
    if (subject && subject.value && subject.type && role && role.value) {
      onAdd(subject.value, subject.type, role.value);
    }
  }

  return (
    <Box sx={{ display: "flex", paddingTop: "6px" }}>
      <Autocomplete
        options={subjects}
        sx={{ width: 300 }}
        onChange={(event: any, newValue: SubjectOptionType | null) => {
          setSubject(newValue);
        }}
        isOptionEqualToValue={(option, value) => option.value == value.value}
        renderInput={(params) => <TextField {...params} label={t("Components.AccessControlDropdown.Placeholder")} />}
      />
      <Autocomplete
        options={roles}
        sx={{ width: 300, paddingLeft: "6px" }}
        onChange={(event: any, newValue: RoleOptionType | null) => {
          setRole(newValue);
        }}
        isOptionEqualToValue={(option, value) => option.value == value.value}
        renderInput={(params) => <TextField {...params} label="Role" />}
      />
      <Button sx={{ marginLeft: "6px" }} onClick={handleOnAdd}>
        {t("Global.Action.Add")}
      </Button>
    </Box>
  );
}
