import { useEffect, useState } from "react";

/**
 * Custom hook to check if the page is visible or not. It listens for the page visibility change events
 * and updates the visibility state accordingly.
 *
 * @returns A boolean value indicating whether the page is visible or not.
 */
export function useIsPageVisible() {
  const [isPageVisible, setIsPageVisible] = useState(true);

  useEffect(() => {
    const onPageVisibilityChange = () => {
      // If the page is not visible, mark the page as not visible
      if (document.hidden) {
        setIsPageVisible(false);
      } else {
        setIsPageVisible(true);
      }
    };

    // Listen for page visibility change events
    document.addEventListener("visibilitychange", onPageVisibilityChange);

    // Clear the event listener on unmount
    return () => {
      document.removeEventListener("visibilitychange", onPageVisibilityChange);
    };
  }, []);

  return { isPageVisible };
}
