import { Box } from "@mui/material";

export interface ICompanionContentProps {
  children: React.ReactNode;
}

export const CompanionContent = (props: ICompanionContentProps) => {
  const { children } = props;

  return (
    <Box
      onWheel={(event) => {
        event.stopPropagation();
      }}
      style={{
        flex: "1 1 auto",
        WebkitOverflowScrolling: "touch",
        overflowY: "auto",
        padding: "20px 24px",
        touchAction: "pan-x pan-y",
      }}
    >
      {children}
    </Box>
  );
};
