/**
 * UnauthorizedError that also sets the status code to 401.
 *
 * A non-dependency version of the Fastify/http-errors-enhanced UnauthorizedError.
 */
export class UnauthorizedError extends Error {
  // *********************************************
  // Static properties
  // *********************************************/
  static status: number = 401;
  static message: string = "Unauthorized";

  // *********************************************
  // Public properties
  // *********************************************/
  status: number;
  statusCode: number; // This always mirrors status

  // *********************************************
  // Constructors
  // *********************************************/
  constructor(message?: string) {
    super(message);

    this.name = "UnauthorizedError";
    this.status = UnauthorizedError.status;
    this.statusCode = UnauthorizedError.status;
  }
}
