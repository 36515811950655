import { IDataGridColumnDef } from "@bigpi/tl-schema";
import { FieldInfo, Parser } from "@json2csv/plainjs";
import { DownloadCsvColumnFormatMapping } from "BoardComponents/DataFormatting/DataFormatting";
import { TFunction } from "i18next";

/**
 * Returns a function that can be used to download the data grid data as a CSV.
 *
 * @param includeColumns The keys of the columns to include in the CSV.
 * @param columnConfig The grid column configuration.
 * @param data The data to download.
 * @param selectedIds The IDs of the rows to download. If an empty array, all rows will be downloaded.
 * @param filename The filename to use for the CSV.
 * @param t The translation function.
 */
export function downloadCsv(
  includeColumns: Array<string>,
  columnConfig: Array<IDataGridColumnDef>,
  data: Array<Record<string, any>>,
  selectedIds: Array<string>,
  filename: string,
  t: TFunction<"translation", undefined>,
) {
  // Filter the data to only include the selected rows
  const filteredData = selectedIds.length > 0 ? data.filter((item: Record<string, any>) => selectedIds.includes(item.id)) : data;

  try {
    // Create the configuration to use when exporting to CSV
    const fields: Array<FieldInfo<any, unknown>> = includeColumns.map((column) => {
      const columnConfigItem = columnConfig.find((configItem) => configItem.field === column);
      return {
        label: columnConfigItem?.headerName || column,
        value: (row: Record<string, any>) => {
          const format = columnConfigItem?.format;
          const fieldFormatter = format ? DownloadCsvColumnFormatMapping[format] : undefined;

          return fieldFormatter ? fieldFormatter(row[column], t, row) : row[column];
        },
      };
    });

    // Create the parser
    const parser = new Parser({
      fields,
      withBOM: true,
    });

    // Create the CSV file content
    const content = new Blob([parser.parse(filteredData)], { type: "text/csv" });

    const element = document.createElement("a");
    element.href = window.URL.createObjectURL(content);
    element.download = filename;
    element.click();
    element.remove();
  } catch (e) {
    console.error(e);
  }
}
