import { useCallback, useEffect, useState } from "react";

// User inactivity timeout is set to 5 minutes
const DEFAULT_USER_INACTIVITY_TIMEOUT = 5 * 60000;

// List of events that are considered as user activity
const USER_ACTIVITY_EVENTS = [
  // Scrolling events
  "wheel",
  "mousewheel",
  "DOMMouseScroll" /* FIREFOX */,

  // Mouse cursor move events
  "mousemove",

  // Mouse click events
  "mousedown",

  // Keyboard key press events
  "keydown",

  // Touch events
  "touchmove",
  "touchstart",
];

/**
 * Custom hook to determine whether the user is active or not. It listens for user activity events like mouse move, scroll, etc.
 * and updates the user activity state accordingly.
 *
 * @param userInactivityTimeout The time in milliseconds after which the user is considered inactive, defaults to 5 minutes.
 *
 * @returns A boolean value indicating whether the user is active or not
 */
export function useIsUserActive(userInactivityTimeout = DEFAULT_USER_INACTIVITY_TIMEOUT) {
  const [isUserActive, setIsUserActive] = useState(true);

  const onUserInactive = useCallback(() => {
    // Mark the user as inactive
    setIsUserActive(false);
  }, []);

  const onUserActive = useCallback(() => {
    // Mark the user as active
    setIsUserActive(true);
  }, []);

  useEffect(() => {
    // Start the inactivity timeout
    let inactivityTimeout = setTimeout(onUserInactive, userInactivityTimeout);

    // If the user is active, reset the inactivity timeout and mark the user as active
    const onUserActivity = () => {
      onUserActive();

      clearTimeout(inactivityTimeout);
      inactivityTimeout = setTimeout(onUserInactive, userInactivityTimeout);
    };

    // Listen for user activity events
    USER_ACTIVITY_EVENTS.forEach((event) => {
      document.body.addEventListener(event, onUserActivity);
    });

    // Clear the inactivity timeout and remove the event listeners on unmount
    return () => {
      clearTimeout(inactivityTimeout);

      USER_ACTIVITY_EVENTS.forEach((event) => {
        document.body.removeEventListener(event, onUserActivity);
      });
    };
  }, [onUserInactive, onUserActive]);

  return { isUserActive };
}
