import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useUpdateDocumentMutation, useDocumentQuery } from "GraphQL/Generated/Apollo";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { DefaultDialogTransition } from "Components/DialogTransition/DefaultDialogTransition";

export interface IRenameDocumentDialogProps {
  onClose: () => void;
  open: boolean;
  documentId: string;
}

export function RenameDocumentDialog(props: IRenameDocumentDialogProps) {
  const { open, documentId } = props;
  const { t } = useTranslation();
  const { data, loading, error } = useDocumentQuery({ variables: { id: documentId } });
  const [UpdateDocument] = useUpdateDocumentMutation();
  const [errorMessage, setErrorMessage] = React.useState("");
  const [oldName, setOldName] = React.useState("");
  const [name, setName] = React.useState("");
  const [nameFocused, setNameFocused] = React.useState(true);
  const nameRef = React.useRef<HTMLInputElement>(null);

  function onClose() {
    setErrorMessage("");
    setName("");
    props.onClose();
  }

  function onRename() {
    UpdateDocument({
      variables: { input: { id: documentId, name: name } },
      refetchQueries: ["Document", "Documents"],
    })
      .then(() => {
        onClose();
      })
      .catch((error) => {
        console.error(error);
        setErrorMessage(t("Components.RenameDocumentDialog.Error"));
      });
  }

  React.useEffect(() => {
    if (data?.document?.name) {
      setOldName(data.document?.name || "");
      setName(data.document?.name || "");
    }
  }, [data]);

  React.useEffect(() => {
    if (open && nameRef.current) {
      nameRef.current.focus();
    }
  }, [nameRef.current, open]);

  return (
    <>
      <Dialog open={open} onClose={onClose} TransitionComponent={DefaultDialogTransition} fullWidth maxWidth="xs">
        <DialogTitle>{t("Components.RenameDocumentDialog.Title", { name: oldName })}</DialogTitle>
        <DialogContent>
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
          <TextField
            autoFocus
            margin="dense"
            label={t("Components.RenameDocumentDialog.NameLabel")}
            type="text"
            fullWidth
            variant="standard"
            value={name}
            onBlur={() => setNameFocused(false)}
            onChange={(e) => setName(e.target.value ?? "")}
            onFocus={() => setNameFocused(true)}
            required
            inputRef={nameRef}
            error={name === "" && !nameFocused}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{t("Global.Action.Close")}</Button>
          <Button onClick={onRename} disabled={name === ""}>
            {t("Components.RenameDocumentDialog.Rename")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
