export enum DataGridColumnFormat {
  ArrayOfStrings = "ARRAY_OF_STRINGS",
  Boolean = "BOOLEAN",
  BulletList = "BULLET_LIST",
  Date = "DATE",
  DateRange = "DATE_RANGE",
  Decimal = "DECIMAL",
  HtmlString = "HTML_STRING",
  Integer = "INTEGER",
  Sources = "SOURCES",
  Speakers = "SPEAKERS",
  String = "STRING",
}
