import { getNodeType, RawCommands } from "@tiptap/core";
import { Node } from "@tiptap/pm/model";
import { TextSelection, Transaction } from "@tiptap/pm/state";

import {
  getIndentLevelByClass,
  getBaseClassWithoutIndent,
  getNewListItemClass,
  getIndentClassFromIndentLevel,
} from "../../Utils/CommandUtil.js";

const updateListMarkers = (
  tr: Transaction,
  parentList: Node,
  after: number,
  newIndentLevel: number,
  parentListIndentLevel: number,
) => {
  let ignoreIndentLevelsAbove: number | null = null;
  let newStartAttr: number | null = null;
  let childListMarker = 1;
  let ignoreChildListIndentLevels = false;

  tr.doc.forEach((node, offset, index) => {
    if (node.type.name === parentList.type.name && node.attrs["data-list-id"] === parentList.attrs["data-list-id"]) {
      const nodeIndentLevel = getIndentLevelByClass(node.attrs.class);

      // This node falls after the currently selected list item
      if (offset >= after) {
        // If the list lifts somewhere then we don't want to change numbering
        // for those indent levels.
        //
        // 1. first
        // 2. second
        //   a. third
        //   b. fourth
        //   c. fifth
        //     i. eighth|            <-- This item is getting lifted.
        //   d. sixth
        // 3. seventh                <-- Don't want to consider for change in numbering.
        if (nodeIndentLevel < newIndentLevel) {
          ignoreIndentLevelsAbove = ignoreIndentLevelsAbove ? Math.max(ignoreIndentLevelsAbove, newIndentLevel) : newIndentLevel;
        }

        if (nodeIndentLevel < parentListIndentLevel) {
          ignoreChildListIndentLevels = true;
        }

        // If list items are present right after at the same indent level as the
        // current selection, then we should convert it to child list of the
        // current selection.
        //
        // 1. first
        // 2. second
        //   a. third
        //   b. fourth               <-- This item is getting lifted.
        //   c. fifth                <-- "fifth" and "sixth" should be re-numbered to "a." and "b."
        //   d. sixth
        // 3. seventh
        if (!ignoreChildListIndentLevels && nodeIndentLevel === parentListIndentLevel) {
          tr.setNodeAttribute(offset, "start", childListMarker);
          childListMarker++;
        }

        const canChangeListMarker = ignoreIndentLevelsAbove === null ? true : nodeIndentLevel < ignoreIndentLevelsAbove;
        if (canChangeListMarker && nodeIndentLevel === newIndentLevel) {
          // List items on the new indent level should be re-numbered.
          //
          // 1. first
          // 2. second
          //   a. third
          //   b. fourth               <-- This item is getting lifted.
          //   c. fifth
          //   d. sixth
          // 3. seventh                <-- This should be re-numbered to "4.".
          tr.setNodeAttribute(offset, "start", node.attrs.start ? node.attrs.start + 1 : 1);
        }
      } else if (nodeIndentLevel === newIndentLevel) {
        // Calculate new start attribute for the current selection
        // from the list items falling before current selection.
        newStartAttr = (node.attrs.start || 1) + 1;
      } else if (nodeIndentLevel < newIndentLevel) {
        // Reset the list marker when the indent level is less than the
        // current selection.
        newStartAttr = 1;
      }
    }
  });

  return newStartAttr;
};

const liftOutOfList = (tr: Transaction, parentList: Node, after: number, before: number, paragraph: Node) => {
  tr.doc.forEach((node, offset, index) => {
    const nodeIndentLevel = getIndentLevelByClass(node.attrs.class);
    if (
      node.type.name === parentList.type.name &&
      node.attrs["data-list-id"] === parentList.attrs["data-list-id"] &&
      nodeIndentLevel === 0 &&
      offset >= after
    ) {
      // Re-numbering for list items falling after the current selection -
      //
      // 1. first
      // 2. second
      // 3. third|          <-- This item is getting lifted.
      // 4. fourth          <-- This will change to "3.".
      tr.setNodeAttribute(offset, "start", node.attrs.start ? Math.max(node.attrs.start - 1, 1) : 1);
    }
  });

  // Delete "ol" node
  tr.delete(before, after);
  // Insert paragraph at the same location
  tr.insert(before, paragraph);

  // Change cursor selection to the paragraph
  tr.setSelection(TextSelection.near(tr.doc.resolve(before)));
};

// This command is run when "Shift + Tab" key binding is invoked.
// We will try and decrease the indent for the current "listItem".
export const liftListItem: RawCommands["liftListItem"] =
  (typeOrName, nodePos?: number) =>
  ({ tr, state, dispatch }) => {
    const type = getNodeType(typeOrName, state.schema);
    let { $from, $to } = state.selection;
    if (nodePos) {
      $from = tr.doc.resolve(nodePos);
      $to = tr.doc.resolve(nodePos);
    }

    const range = $from.blockRange($to, (node) => node.childCount > 0 && node.firstChild!.type === type);
    if (!range) {
      return false;
    }

    const parentList = range.parent;
    if (parentList.type.name !== "orderedList" && parentList.type.name !== "bulletList") {
      return false;
    }

    const before = $from.before(-2);
    const after = $from.after(-2);
    const listItemNode = $from.node(-1);
    const listItemNodePos = $from.before(-1);

    if (dispatch) {
      // Lift the paragraph out of the list (meaning change "ol" to "p") if
      // the current "ol" is at base indent i.e. 0.
      const parentListIndentLevel = getIndentLevelByClass(parentList.attrs.class);
      if (parentListIndentLevel === 0) {
        const paragraph = $from.node();
        liftOutOfList(tr, parentList, after, before, paragraph);
        return true;
      }

      const newIndentLevel = Math.max(parentListIndentLevel - 1, 0);
      const newStartAttr = updateListMarkers(tr, parentList, after, newIndentLevel, parentListIndentLevel);
      const newIndentClass = getIndentClassFromIndentLevel(newIndentLevel);
      const newBaseClass = getBaseClassWithoutIndent(parentList.attrs.class);
      const newClass = `${newIndentClass ?? ""} ${newBaseClass ?? ""}`.trim();

      // Change "start" and "class" attributes for current "ol".
      tr.setNodeAttribute(before, "start", newStartAttr);
      tr.setNodeAttribute(before, "class", newClass);

      // Change "class" attribute for current "li".
      const newListItemClass = getNewListItemClass(listItemNode.attrs.class, parentList.type.name, "lift");
      tr.setNodeAttribute(listItemNodePos, "class", newListItemClass);
    }

    return true;
  };
