import { gql } from "@apollo/client";

export const SHAPE_DATA_QUERY = gql`
  query ShapeData($shapeId: String!, $workspaceBoardId: String!) {
    shapeData(shapeId: $shapeId, workspaceBoardId: $workspaceBoardId) {
      id
      data
      rowState
      shapeId
      workspaceBoardId
    }
  }
`;
