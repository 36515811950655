import { getNodeType, RawCommands } from "@tiptap/core";
import { TextSelection } from "@tiptap/pm/state";

// This command is run when "Backspace" key binding is invoked for "listItem".
export const listBackspace: RawCommands["listBackspace"] =
  (typeOrName) =>
  ({ state, dispatch, chain }) => {
    const type = getNodeType(typeOrName, state.schema);
    const { $from, $to } = state.selection;

    const range = $from.blockRange($to, (node) => node.childCount > 0 && node.firstChild!.type === type);
    if (!range) {
      return false;
    }

    // "list" node represents the "ol" tag which is present on
    // "-2" depth from the current selection i.e. the paragraph.
    //
    // <ol>
    //   <li>
    //     <p> text </p>
    //   </li>
    // </ol>
    const list = $from.node(-2);
    const listPos = $from.before(-2);
    if (list.type.name !== "orderedList" && list.type.name !== "bulletList") {
      return false;
    }

    if (dispatch) {
      const textLengthBeforeSelection = $from.parentOffset;
      if (textLengthBeforeSelection > 0) {
        // Let the default behavior (deleting the character) kick in if there
        // is text before the selection.
        //
        // 1. first
        // 2. second
        //   a. thi|rd         <-- "|" represents current selection.
        //   b. fourth
        // 3. fifth
        return false;
      }

      // Lift list item if there is no text right before the selection.
      //
      // 1. first
      // 2. second
      //   a. |third         <-- "|" represents current selection.
      //   b. fourth
      // 3. fifth
      return chain()
        .command(({ state, dispatch }) => {
          if (state.selection.empty) {
            return true;
          }

          // Delete the text selection, if any, before lifting the list item.
          if (dispatch) {
            // Select entire text from the start of the text node to selection tail.
            //
            // |<ol><li><p>test</p></li></ol>          ---> listPos
            // <ol>|<li><p>test</p></li></ol>          ---> listPos + 1
            // <ol><li>|<p>test</p></li></ol>          ---> listPos + 2
            // <ol><li><p>|test</p></li></ol>          ---> listPos + 3
            // For more - libraries/editor-tiptap/docs/EDITOR-SELECTION-POSITION.md
            const selection = new TextSelection(state.doc.resolve(listPos + 3), $to);
            state.tr.setSelection(selection);

            // Delete the selected text.
            dispatch(state.tr.deleteSelection());
          }

          return true;
        })
        .liftListItem(type)
        .run();
    }

    return true;
  };
