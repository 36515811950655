import { BoardSearchFieldTypes, IBoardSearchShapeMatch } from "@bigpi/cookbook";

import { getPlainTextFromHtml, getBoardSearchMatchesFromTextContent } from "Utils/BoardSearchUtils";
import { IBoardSearchPlugInInputs } from "../BoardSearchManager";
import { BoardSearchPlugInBase } from "./BoardSearchPlugInBase";

export class HtmlFieldBoardSearchPlugIn extends BoardSearchPlugInBase {
  // *********************************************
  // Public properties
  // *********************************************/
  /**
   * @inheritdoc
   */
  name = BoardSearchFieldTypes.Html;

  // *********************************************
  // Public methods
  // *********************************************/
  /**
   * @inheritdoc
   */
  public async execute(input: IBoardSearchPlugInInputs): Promise<Array<IBoardSearchShapeMatch>> {
    const { searchField, searchTerm, shape } = input;
    const fieldData = this.getFieldDataFromShapeProps(shape, searchField);
    if (!fieldData) {
      return [];
    }

    const plainText = getPlainTextFromHtml(fieldData as string);
    return getBoardSearchMatchesFromTextContent(plainText, searchTerm);
  }
}
