import { ContentTypes, parseStorageLocationUrl, workspaceBoardAssetStorageLocationToDownloadUrl } from "@bigpi/cookbook";
import { createShapeId, Editor, IndexKey, TLShapePartial, TLShapeProps } from "@tldraw/tldraw";
import React from "react";
import { v4 as uuidV4 } from "uuid";

import { insertShapesAtViewportEmptyPoint } from "BoardComponents/Utils/CreateShapeUtils";
import { Config } from "Config";
import { getMediaAssetFromFile } from "Utils/BoardAssetUtils";
import { getSvgElementDataUrl } from "Utils/DomToImageUtils";
import { dataUrltoFile } from "Utils/FileUtils";
import { getUploadWorkspaceBoardAssetFn } from "Utils/UploadUtil";

const SHAPE_PADDING = 50;

export async function addChartsToBoard(
  tldrawEditor: Editor,
  accessToken: string,
  boardId: string,
  titleLabel: string,
  elementsWithLabels: Array<{
    label: string;
    ref: React.MutableRefObject<HTMLDivElement | null>;
  }>,
) {
  let x = 50,
    y = 50,
    initialY = y,
    height = 1,
    width = 1;

  // Create group shape
  const groupShapeId = createShapeId(uuidV4());
  const rectangeShapeId = createShapeId(uuidV4());

  const shapesToCreate: Array<TLShapePartial> = [];
  y = y + SHAPE_PADDING * 2;

  for (const elementWithLabel of elementsWithLabels) {
    const { label, ref } = elementWithLabel;
    const svgElement = ref.current?.querySelector(`svg`);

    if (svgElement) {
      appendStyles(svgElement);
      const image = getSvgElementDataUrl(svgElement);
      const file = dataUrltoFile(image, "chart.svg", ContentTypes.SVG);

      const asset = await getMediaAssetFromFile(file);

      // Upload file to server
      const result = await getUploadWorkspaceBoardAssetFn(Config.apiGatewayHttpUrl, () => accessToken, boardId)(file);

      asset.props.src = workspaceBoardAssetStorageLocationToDownloadUrl(Config.assetHttpUrl, parseStorageLocationUrl(result));

      // Create asset
      tldrawEditor.createAssets([asset]);

      const assetProps = asset.props as Partial<TLShapeProps>;

      shapesToCreate.push({
        id: createShapeId(uuidV4()),
        type: "text",
        x,
        y,
        props: {
          text: label,
          align: "start",
          font: "sans",
          size: "m",
        },
      });
      shapesToCreate.push({
        id: createShapeId(uuidV4()),
        x,
        y: y + SHAPE_PADDING,
        type: "image",
        props: {
          w: assetProps.w,
          h: assetProps.h,
          assetId: asset.id,
        },
      });

      y = y + (assetProps.h || 0) + SHAPE_PADDING * 2;
      width = Math.max(width, assetProps.w || 0);
      height = Math.max(height, height + (assetProps.h || 0) + SHAPE_PADDING * 2);
    }
  }

  // Title label
  shapesToCreate.splice(0, 0, {
    id: createShapeId(uuidV4()),
    type: "text",
    x,
    y: initialY,
    props: {
      text: titleLabel,
      align: "start",
      font: "sans",
      size: "l",
      w: width,
      autoSize: false,
    },
  });

  // Rectangle shape
  shapesToCreate.push({
    id: rectangeShapeId,
    rotation: 0,
    type: "geo",
    typeName: "shape",
    index: "a0" as IndexKey,
    x: 0,
    y: 0,
    props: {
      align: "middle",
      fill: "semi",
      geo: "rectangle",
      h: height + SHAPE_PADDING * 2,
      w: width + SHAPE_PADDING * 2,
      verticalAlign: "middle",
      size: "m",
      color: "black",
    },
  });

  // Create shapes
  insertShapesAtViewportEmptyPoint(tldrawEditor, shapesToCreate);

  const shapesToUpdate: Array<TLShapePartial> = [];
  shapesToCreate.forEach((shape) => {
    shapesToUpdate.push({
      ...shape,
      parentId: groupShapeId,
    });
  });
  tldrawEditor.createShape({
    id: groupShapeId,
    type: "group",
    parentId: tldrawEditor.getCurrentPageId(),
  });
  tldrawEditor.updateShapes(shapesToUpdate);
}

/**
 * Appends missing styles to svg element
 *
 * @param svgElement SVG DOM Element
 */
function appendStyles(svgElement: SVGElement) {
  const xAxisTicksStyle = document.createTextNode(' g[aria-label="x-axis tick"] g { transform: translateY(-8px); }');
  const yAxisTicksStyle = document.createTextNode(' g[aria-label="y-axis tick"] { transform: translateX(10px); }');
  const fyAxisTicksStyle = document.createTextNode(' g[aria-label="fy-axis tick label"] { font-weight: bold; font-size: 16px; }');
  const xAxisLabelStyle = document.createTextNode(
    ' g[aria-label="x-axis label"] { font-size: 16px; transform: translate(-180px, -25px); }',
  );
  const style = svgElement.querySelector("style");
  style?.appendChild(xAxisTicksStyle);
  style?.appendChild(yAxisTicksStyle);
  style?.appendChild(fyAxisTicksStyle);
  style?.appendChild(xAxisLabelStyle);
}
