import { useReactiveVar } from "@apollo/client";
import { ICommandContext, ICommandResponseStatus } from "@bigpi/cookbook";
import { Box, Typography, Tooltip } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";

import { ChatMessageList } from "Chat/ChatMessageList/ChatMessageList";
import { ChatMessageActionState, ChatMessageEditor } from "Chat/ChatMessageEditor/ChatMessageEditor";
import { CommandContext } from "CommandContext";
import { APP_SIDEBAR_ICON_WIDTH } from "Components/AppRightSidebar/AppRightSidebar";
import { useCommandExecutor } from "Components/CommandManagers/useCommandExecutor";
import { COMMAND_STATUS_SUBSCRIPTION } from "GraphQL/CommandQueue/Subscription";
import {
  ChatMessageChannelType,
  useCancelCommandMutation,
  useCommandStatusLazyQuery,
  useCreateChatMessageMutation,
  useOnCommandStatusChangedSubscription,
  useWorkspaceQuery,
} from "GraphQL/Generated/Apollo";
import { RightSidebarTitle } from "Hooks/useAppRightSidebarComponents";

// *********************************************
// Type/Interface
// *********************************************
export interface ChatPaneProps {
  channelId: string;
  channelType: ChatMessageChannelType;
}

// *********************************************
// Component
// *********************************************
export function ChatPane(props: ChatPaneProps) {
  const { channelId, channelType } = props;
  const [parentId, setParentId] = useState<string | null>(null);
  const [isCancelRunning, setIsCancelRunning] = useState(false);
  const [isParentPrivate, setIsParentPrivate] = useState<boolean>(false);
  const [isSending, setIsSending] = useState(false);
  const [isBotWorking, setIsBotWorking] = useState(false);
  const [actionState, setActionState] = useState<ChatMessageActionState>(ChatMessageActionState.Send);
  const [commandStatus, setCommandStatus] = useState<ICommandResponseStatus | undefined>(undefined);
  const commandContext = useReactiveVar<ICommandContext>(CommandContext.getCommandContext);
  const commandContextRef = useRef<ICommandContext>(commandContext);
  const chatPaneRef = useRef<HTMLDivElement>(null);
  const commandExecutor = useCommandExecutor();

  const { data: workspaceData } = useWorkspaceQuery({
    variables: { id: channelId },
    skip: channelType !== ChatMessageChannelType.Workspace,
  });

  // Get lazy query to check (initial) command status
  const [fetchInitialCommandStatus, { data: initialCommandStatusData, loading: commandStatusLoading }] =
    useCommandStatusLazyQuery();

  // Get the hook to cancel a command
  const [cancelCommand, { data: cancelData, loading: cancelLoading }] = useCancelCommandMutation();

  // Get the hook to send a message
  const [createChatMessage] = useCreateChatMessageMutation();

  const onMessageParentSet = useCallback(
    (newParentId: string | null, isPrivate?: boolean) => {
      if (newParentId !== parentId) {
        // Reset the bot status since we're in a different thread/no thread
        setIsBotWorking(false);

        // Reset the command status when the parent ID changes
        setCommandStatus(undefined);
      }

      setParentId(newParentId);
      setIsParentPrivate(!!isPrivate);
    },
    [parentId],
  );

  const cancelBot = useCallback(() => {
    if (!parentId) {
      return;
    }

    // This only gets cleared when we receive a status update which tells us the cancel has completed
    setIsCancelRunning(true);

    cancelCommand({
      variables: {
        input: {
          id: parentId,
        },
      },
    });
  }, [cancelCommand, parentId]);

  const sendMessage = useCallback(
    (currentMessage: string) => {
      if (isSending || actionState !== ChatMessageActionState.Send) {
        return;
      }

      setIsSending(true);
      const message = currentMessage.trim();

      createChatMessage({
        variables: {
          input: {
            channelType,
            channelId,
            commandContext: {
              ...commandContextRef.current,
              user: {
                ...commandContextRef.current.user,
                commandSessionId: commandExecutor.sessionId,
              },
            },
            message: message,
            parentId,
          },
        },
        onCompleted: (data) => {
          // TODO: We need to have some some of buffer/status in place in place instead,
          // so that you can type real fast and not have to wait for the server to respond
          setIsSending(false);
        },
        onError: (error) => {
          console.error("Failed to send message", error);
          setIsSending(false);
        },
      });
    },
    [actionState, createChatMessage, channelId, parentId, channelType, isSending],
  );

  useEffect(() => {
    commandContextRef.current = { ...CommandContext.getCommandContext() };
  }, [commandContext]);

  useEffect(() => {
    const handleCopy = (event: ClipboardEvent) => {
      const target = event.target as HTMLElement;
      if (chatPaneRef.current?.contains(target)) {
        event.stopImmediatePropagation();
      }
    };
    if (chatPaneRef.current) {
      chatPaneRef.current.addEventListener("copy", handleCopy);
    }

    return () => {
      if (chatPaneRef.current) {
        chatPaneRef.current.removeEventListener("copy", handleCopy);
      }
    };
  }, [chatPaneRef]);

  useEffect(() => {
    if (parentId && commandStatus === undefined) {
      fetchInitialCommandStatus({
        fetchPolicy: "network-only",
        variables: {
          id: parentId,
        },
      });
    }
  }, [parentId, commandStatus, fetchInitialCommandStatus]);

  useOnCommandStatusChangedSubscription({
    variables: {
      id: parentId ?? "",
    },
    skip: !parentId,
    onData: (options) => {
      const commandStatus = options.data.data?.onCommandStatusChanged?.status as ICommandResponseStatus | undefined;

      if (commandStatus) {
        setCommandStatus(commandStatus);
      }
    },
  });

  useEffect(() => {
    const status: ICommandResponseStatus | undefined = initialCommandStatusData?.commandStatus?.status as ICommandResponseStatus;

    // Only set the command status if it is not already set (subscription didn't finish before the query)
    if (commandStatus === undefined) {
      if (initialCommandStatusData?.commandStatus === null) {
        // Command was not found, i.e. not running anymore
        setIsBotWorking(false);
        setIsCancelRunning(false);
      } else {
        setCommandStatus(status);
      }
    }
  }, [commandStatus, initialCommandStatusData]);

  useEffect(() => {
    const status: ICommandResponseStatus | undefined = cancelData?.cancelCommand?.status as ICommandResponseStatus;

    // Make sure the cancel button is available if cancel command has already completed/command was not found/is not running anymore
    if (cancelData?.cancelCommand === null || !(status === "queued" || status === "running" || status === "progress")) {
      setIsBotWorking(false);
      setIsCancelRunning(false);
    }
  }, [cancelData]);

  useEffect(() => {
    // Set send/cancel action state based on command status
    if (commandStatus === "queued" || commandStatus === "running" || commandStatus === "progress") {
      setIsBotWorking(true);
    } else {
      // Reset ability to cancel
      setIsCancelRunning(false);

      // Reset bot working status
      setIsBotWorking(false);
    }
  }, [commandStatus]);

  useEffect(() => {
    let result: ChatMessageActionState = ChatMessageActionState.Send;

    if (cancelLoading || isCancelRunning) {
      result = ChatMessageActionState.CancelDisabled;
    } else if (isBotWorking) {
      result = ChatMessageActionState.Cancel;
    } else if (isSending || commandStatusLoading) {
      result = ChatMessageActionState.SendDisabled;
    }

    setActionState(result);
  }, [cancelLoading, isCancelRunning, isBotWorking, isSending, commandStatusLoading]);

  return (
    <Box
      sx={{ display: "flex", flex: 1, flexDirection: "column", width: "100%", minHeight: 0 }}
      className="chat"
      ref={chatPaneRef}
    >
      {workspaceData?.workspace && (
        <RightSidebarTitle sx={{ backgroundColor: isParentPrivate ? "var(--bistro-private-mention-color)" : "" }}>
          <Typography
            noWrap={true}
            component="div"
            variant="subtitle2"
            sx={{ maxWidth: `calc(100% - ${APP_SIDEBAR_ICON_WIDTH * 2}px)`, height: "40px", pl: 2, pt: 1 }}
          >
            <Tooltip title={workspaceData.workspace.name}>
              <span>#{workspaceData.workspace.name}</span>
            </Tooltip>
          </Typography>
        </RightSidebarTitle>
      )}
      <ChatMessageList channelId={channelId} parentId={parentId} onMessageParentSet={onMessageParentSet} />
      <ChatMessageEditor
        actionState={actionState}
        onCancel={cancelBot}
        onSend={sendMessage}
        channelId={channelId}
        channelType={channelType}
      />
    </Box>
  );
}
