import { FileSourceType } from "@bigpi/cookbook";
import { useAuthUser } from "@frontegg/react";

import { useFileQuery, useWorkspaceFileQuery } from "GraphQL/Generated/Apollo";
import { getFilePreviewDownloadUrl } from "Utils/FileUtils";
import { getWorkspaceFilePreviewDownloadUrl } from "Utils/WorkspaceFileUtils";

/**
 * Hook to get the file preview details (file name & url).
 *
 * @param fileId File ID to fetch the details.
 * @param fileType Enum (Library/WorkspaceFile) to generate specific file preview.
 *
 * @returns File name & URL to preview the file.
 */
export function useFilePreviewData(fileId: string, fileType: FileSourceType) {
  const user = useAuthUser();

  const { data: fileData } = useFileQuery({
    variables: {
      id: fileId,
    },
    skip: fileType === FileSourceType.WorkspaceFile,
  });

  const { data: workspaceFileData } = useWorkspaceFileQuery({
    variables: {
      id: fileId,
    },
    skip: fileType === FileSourceType.Library,
  });

  if (fileType === FileSourceType.Library && fileData?.file) {
    return {
      fileName: fileData?.file?.name,
      url: getFilePreviewDownloadUrl(user.tenantId, fileId, "index.html"),
    };
  } else if (fileType === FileSourceType.WorkspaceFile && workspaceFileData?.workspaceFile) {
    return {
      fileName: workspaceFileData?.workspaceFile?.name,
      url: getWorkspaceFilePreviewDownloadUrl(user.tenantId, workspaceFileData?.workspaceFile?.workspaceId, fileId, "index.html"),
    };
  }
  return {
    fileName: "",
    url: "",
  };
}
