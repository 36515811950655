import type { Editor } from "@tiptap/react";
import type { default as i18next } from "i18next";
import React from "react";
import { I18nextProvider } from "react-i18next";

import { IEditorConfig } from "../Editor/index.js";
import { initializeI18n } from "../I18n/Config.js";
import type { ToolbarItem } from "./ToolbarItem.js";

/**
 * A singleton factory class that manages available toolbar items and creates new toolbar instances.
 */
export class ToolbarFactory {
  // *********************************************
  // Public static properties
  // *********************************************/
  /**
   * The i18n instance to use for translations. Set by calling initialize().
   */
  static i18n: typeof i18next;

  /**
   * The registered toolbar items. Do not modify this directly, use the registerToolbarItem() method instead.
   */
  static readonly registeredItems: Record<string, ToolbarItem> = {};

  // *********************************************
  // Public static methods
  // *********************************************/
  /**
   * Initializes the factory. This must be called before using the factory.
   *
   * @param i18n The i18n instance to use for translations. If not set, a new instance will be created.
   */
  static async initialize(i18n?: typeof i18next) {
    ToolbarFactory.i18n = await initializeI18n(i18n);
  }

  /**
   * Registers a ToolbarItem in the factory making it available for use in toolbars.
   *
   * @param toolbarItem The toolbar item to make available.
   */
  static registerToolbarItem(toolbarItem: ToolbarItem) {
    // Sanity check on input and current state
    if (!toolbarItem) {
      throw new Error("toolbarItem is required");
    }

    if (ToolbarFactory.registeredItems[toolbarItem.name] !== undefined) {
      throw new Error(`ToolbarItem "${toolbarItem.name}" already registered`);
    }

    // Register the ToolbarItem
    ToolbarFactory.registeredItems[toolbarItem.name] = toolbarItem;
  }

  /**
   * Registers the given array of ToolbarItems in the factory making them available for use in toolbars.
   *
   * @param toolbarItems The toolbar items to make available.
   */
  static registerToolbarItems(toolbarItems: Array<ToolbarItem>) {
    // Sanity check on input and current state
    if (!Array.isArray(toolbarItems)) {
      throw new Error("toolbarItems must be a valid array");
    }

    toolbarItems.forEach((toolbarItem) => this.registerToolbarItem(toolbarItem));
  }

  /**
   * Creates a toolbar with the requested toolbar items.
   *
   * @param editor Editor instance for the toolbar.
   * @param config The editor, extension, and toolbar configuration.
   * @param items A string array of toolbar items to add to the toolbar.
   *
   * @returns Array of instantiated toolbar items that can be added to the desired toolbar parent container.
   */
  static createToolbar(editor: Editor, config: IEditorConfig, items: Array<string>) {
    if (!editor) {
      return <></>;
    }

    return items.map((itemName: string, index: number) => {
      if (ToolbarFactory.registeredItems[itemName]) {
        const toolbarItem = ToolbarFactory.registeredItems[itemName];
        if (toolbarItem.isExternallyTranslated) {
          return toolbarItem.create(editor, config);
        } else {
          return (
            <I18nextProvider key={`itemName-${index}`} i18n={ToolbarFactory.i18n}>
              {toolbarItem.create(editor, config)}
            </I18nextProvider>
          );
        }
      } else {
        throw new Error(`Toolbar item "${itemName}" not registered.`);
      }
    });
  }
}
