import { DataGridColumnFormat, DataGridDescendentCountFormat, ShapeDatastoreType } from "@bigpi/cookbook";
import { defineMigrations } from "@tldraw/store";

import { IDataGridColumnDef, IDataGridConfig, IDataGridPreferences, IDataGridShape } from "./IDataGridShape.js";

export const DataGridShapeVersions = {
  Initial: 0,
  PreferencesFilterModelFromInput: 1,
  RowGroupingModel: 2,
  MakeDataGridStandalone: 3,
  AddAutoResize: 4,
  AddWidthField: 5,
  AddPaginationMaxHeight: 6,
  AddDescendentCountFormat: 7,
  AddManageColumns: 8,
  CleanOldFeaturesAddNewFeatures: 9,
  AddIsEditEnabledField: 10,
};

export const dataGridShapeMigrations = defineMigrations({
  firstVersion: DataGridShapeVersions.Initial,
  currentVersion: DataGridShapeVersions.AddIsEditEnabledField,
  migrators: {
    [DataGridShapeVersions.PreferencesFilterModelFromInput]: {
      up: (shape: IDataGridShape) => {
        const preferences: IDataGridPreferences = {
          ...shape.props.preferences,
        };
        if (shape.props.preferences?.filterModel && shape.props.preferences?.filterModel.items) {
          const items = shape.props.preferences.filterModel.items?.map((item) => {
            return { ...item, fromInput: "" };
          });
          preferences["filterModel"] = {
            items,
          };
        }
        return {
          ...shape,
          props: {
            ...shape.props,
            preferences,
          },
        };
      },
      down: (shape: IDataGridShape & { analysisType: string }) => {
        const { preferences, ...rest } = shape.props;
        return { ...shape, props: rest };
      },
    },
    [DataGridShapeVersions.RowGroupingModel]: {
      up: (shape: IDataGridShape) => {
        return {
          ...shape,
          props: { ...shape.props, preferences: { ...shape.props.preferences, rowGroupingModel: undefined } },
        };
      },
      down: (shape: IDataGridShape & { analysisType: string }) => {
        const { preferences, ...rest } = shape.props;
        return { ...shape, props: rest };
      },
    },
    [DataGridShapeVersions.MakeDataGridStandalone]: {
      up: (shape: IDataGridShape & { props: { config: IDataGridConfig } }) => {
        return {
          ...shape,
          props: {
            ...shape.props,
            config: {
              addToDocument: false,
              checkboxSelection: false,
              columns: [],
              documentItemTemplate: "",
              fontSize: 16,
            },
            datastoreId: undefined,
            datastoreType: ShapeDatastoreType.ParentDatastore,
            selectedIds: [],
          },
        };
      },
      down: (shape: IDataGridShape) => {
        const { config, datastoreId, datastoreType, selectedIds, ...rest } = shape.props;
        return { ...shape, props: rest };
      },
    },
    [DataGridShapeVersions.AddAutoResize]: {
      up: (shape: IDataGridShape & { props: { config: IDataGridConfig } }) => {
        // Set autoResize to true
        shape.props.config.autoResize = true;

        return shape;
      },
      down: (shape: IDataGridShape) => {
        // Drop "autoResize" from props.config
        const { config } = shape.props;
        const { autoResize, ...rest } = config;
        return {
          ...shape,
          props: {
            ...shape.props,
            config: {
              ...rest,
            },
          },
        };
      },
    },
    [DataGridShapeVersions.AddWidthField]: {
      up: (shape: IDataGridShape & { props: { config: IDataGridConfig } }) => {
        shape.props.config.columns.forEach((column) => {
          column.width = column.minWidth;
        });
        return shape;
      },
      down: (shape: IDataGridShape) => {
        // Drop "width" from props.config.columns[]
        const { config } = shape.props;
        const { columns, ...rest } = config;
        columns.forEach((column) => {
          delete column.width;
        });
        return {
          ...shape,
          props: {
            ...shape.props,
            config: {
              ...rest,
              columns,
            },
          },
        };
      },
    },
    [DataGridShapeVersions.AddPaginationMaxHeight]: {
      up: (shape: IDataGridShape & { props: { config: IDataGridConfig } }) => {
        shape.props.config.isPaginationEnabled = true;

        return shape;
      },
      down: (shape: IDataGridShape) => {
        // Drop "isPaginationEnabled" and "maxHeight" from props.config
        const { config } = shape.props;
        const { isPaginationEnabled, maxHeight, ...rest } = config;
        return {
          ...shape,
          props: {
            ...shape.props,
            config: {
              ...rest,
            },
          },
        };
      },
    },
    [DataGridShapeVersions.AddDescendentCountFormat]: {
      up: (shape: IDataGridShape & { props: { config: IDataGridConfig } }) => {
        shape.props.config.descendentCountFormat = DataGridDescendentCountFormat.RowCount;

        return shape;
      },
      down: (shape: IDataGridShape) => {
        // Drop "descendentCountFormat" from props.config
        const { config } = shape.props;
        const { descendentCountFormat, ...rest } = config;
        return {
          ...shape,
          props: {
            ...shape.props,
            config: {
              ...rest,
            },
          },
        };
      },
    },
    [DataGridShapeVersions.AddManageColumns]: {
      up: (shape: IDataGridShape & { props: { config: IDataGridConfig } }) => {
        shape.props.config.isManageColumnsEnabled = false;

        return shape;
      },
      down: (shape: IDataGridShape) => {
        // Drop "isManageColumnsEnabled" from props.config
        const { config } = shape.props;
        const { isManageColumnsEnabled, ...rest } = config;
        return {
          ...shape,
          props: {
            ...shape.props,
            config: {
              ...rest,
            },
          },
        };
      },
    },
    [DataGridShapeVersions.CleanOldFeaturesAddNewFeatures]: {
      up: (
        shape: IDataGridShape & { props: { preferences: IDataGridPreferences & { columnVisibility?: Record<string, unknown> } } },
      ) => {
        const { preferences } = shape.props;
        const columnVisibility = preferences?.columnVisibility;
        shape.props.config.columns.forEach(
          (
            column: IDataGridColumnDef & { visibility?: boolean; groupable?: boolean; sortable?: boolean; resizable?: boolean },
          ) => {
            // Remove old feature flags
            delete column.visibility;
            delete column.groupable;
            delete column.sortable;
            delete column.resizable;

            // Add new feature flags
            column.align = undefined;
            column.headerAlign = undefined;
            column.isColumnMenuEnabled = true;
            column.isColumnPinEnabled = true;
            column.isExportEnabled = true;
            column.isGroupEnabled = true;
            column.isHideEnabled = true;
            column.isReorderEnabled = true;
            column.isResizeEnabled = true;
            column.isSortEnabled = true;
            column.isVisible = columnVisibility ? (columnVisibility[column.field] as boolean) ?? true : true;
            delete column.maxWidth;
            column.showSortIcons = true;
            // Update column format for topic discussions topics and summary
            if (
              column.format === ("TOPIC_DISCUSSIONS_TOPICS" as DataGridColumnFormat) ||
              column.format === ("TOPIC_DISCUSSIONS_SUMMARY" as DataGridColumnFormat)
            ) {
              column.format = DataGridColumnFormat.BulletList;
            }
          },
        );

        // Add "isExportCsvEnabled" & "isExportExcelEnabled"
        shape.props.config.isExportCsvEnabled = false;
        shape.props.config.isExportExcelEnabled = false;
        shape.props.config.minHeight = shape.props.config.maxHeight;

        // Remove columnVisibility from preferences
        delete shape.props.preferences.columnVisibility;

        return shape;
      },
      down: (shape: IDataGridShape) => {
        const { config, preferences } = shape.props;
        const { columns, isExportCsvEnabled, isExportExcelEnabled, minHeight, ...rest } = config;
        const columnVisibility: Record<string, boolean> = {};

        columns.forEach(
          (
            column: IDataGridColumnDef & { visibility?: boolean; groupable?: boolean; sortable?: boolean; resizable?: boolean },
          ) => {
            // Remove new feature flags
            delete column.align;
            delete column.headerAlign;
            delete column.isColumnMenuEnabled;
            delete column.isColumnPinEnabled;
            delete column.isExportEnabled;
            delete column.isGroupEnabled;
            delete column.isHideEnabled;
            delete column.isReorderEnabled;
            delete column.isResizeEnabled;
            delete column.isSortEnabled;
            delete column.isVisible;
            delete column.maxWidth;
            delete column.showSortIcons;

            // Save column visibility
            if (column.isVisible === false) {
              columnVisibility[column.field] = false;
            }

            // Update column format for topic discussions topics and summary
            if (column.format === DataGridColumnFormat.BulletList && column.field === "topics") {
              column.format = "TOPIC_DISCUSSIONS_TOPICS" as DataGridColumnFormat;
            }
            if (column.format === DataGridColumnFormat.BulletList && column.field === "summary") {
              column.format = "TOPIC_DISCUSSIONS_SUMMARY" as DataGridColumnFormat;
            }
          },
        );

        const otherPreferences: { columnVisibility?: Record<string, boolean> } = {};

        if (Object.keys(otherPreferences).length > 0) {
          otherPreferences.columnVisibility = columnVisibility;
        }

        return {
          ...shape,
          props: {
            ...shape.props,
            config: {
              ...rest,
              columns,
            },
            preferences: {
              ...preferences,
              ...otherPreferences,
            },
          },
        };
      },
    },
    [DataGridShapeVersions.AddIsEditEnabledField]: {
      up: (shape: IDataGridShape & { props: { config: IDataGridConfig } }) => {
        shape.props.config.columns.forEach((column) => {
          // Add "isEditEnabled" to each column
          column.isEditEnabled = false;
        });

        return shape;
      },
      down: (shape: IDataGridShape) => {
        // Drop "isEditEnabled" from each column
        shape.props.config.columns.forEach((column) => {
          delete column.isEditEnabled;
        });

        return shape;
      },
    },
  },
});
