import { hasRolePermission, Permissions } from "@bigpi/permission";
import { useAuthUser } from "@frontegg/react";
import * as React from "react";

export interface HasPermissionProps {
  children: React.ReactNode;
  permission: Permissions;
}

export const HasPermission = (props: HasPermissionProps) => {
  const { children, permission } = props;
  const user = useAuthUser();

  if (hasRolePermission(user.permissions, permission)) {
    return <>{children}</>;
  }
  return <></>;
};
