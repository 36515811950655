import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";

import { LogoIcon } from "Assets/LogoIcon";

const mdTheme = createTheme({
  palette: {
    primary: {
      main: "#546e7a",
    },
    secondary: {
      main: "#90a4ae",
    },
  },
});

export function ErrorLayout() {
  const { t } = useTranslation();

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <Box component="main" sx={{ flexGrow: 1 }}>
          <Paper
            elevation={6}
            square={true}
            sx={{
              display: "flex",
              flexDirection: "column",
              m: 3,
              marginLeft: "auto",
              marginRight: "auto",
              width: "8.5in",
              height: "11in",
            }}
          >
            <Box sx={{ display: "flex", paddingLeft: "0.6in", paddingRight: "0.25in", paddingTop: "0.25in", opacity: 0.5 }}>
              <LogoIcon sx={{ width: 50, height: 50, marginTop: "0.25in" }} />
              <Typography variant="h5" sx={{ paddingLeft: 3, lineHeight: 2.25, marginTop: "0.25in" }}>
                {t("Global.General.ApplicationName")}
              </Typography>
              <Box sx={{ flex: 1, textAlign: "right" }}>{t("Pages.Errors.General.PageHeader")}</Box>
            </Box>
            <Box sx={{ flexGrow: 1, paddingLeft: "1in", paddingRight: "1in", paddingTop: "0.5in" }}>
              <Outlet />
            </Box>
            <Box
              sx={{
                paddingLeft: "1in",
                paddingRight: "1in",
                paddingTop: "0.5in",
                paddingBottom: "0.25in",
                textAlign: "center",
                opacity: 0.5,
              }}
            >
              {t("Pages.Errors.General.PageFooter")}
            </Box>
          </Paper>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
