export enum DocumentType {
  /**
   * None
   */
  None = "None",

  /**
   * Knowledge base article
   */
  KnowledgeBaseArticle = "KnowledgeBaseArticle",

  /**
   * Questionnaire
   */
  Questionnaire = "Questionnaire",
}
