import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

const DEFAULT_COPY_BUTTON_TEXT = "Copy details to clipboard";
const COPIED_INTERVAL = 2000;

interface IFatalErrorDialogProps extends React.PropsWithChildren {
  error?: any;
}

interface IFatalErrorDialogState {
  copyButtonText: string;
  error: Error | undefined;
  info: React.ErrorInfo | undefined;
  hasError: boolean;
}

export class FatalErrorDialog extends React.Component<IFatalErrorDialogProps, IFatalErrorDialogState> {
  constructor(props: IFatalErrorDialogProps, state: IFatalErrorDialogState) {
    super(props);
    this.state = {
      copyButtonText: DEFAULT_COPY_BUTTON_TEXT,
      error: props.error,
      hasError: props.error !== undefined,
      info: undefined,
    };
  }

  componentDidUpdate(
    prevProps: Readonly<IFatalErrorDialogProps>,
    prevState: Readonly<IFatalErrorDialogState>,
    snapshot?: any,
  ): void {
    let error = this.props.error;

    // Try to convert incorrectly constructed errors to Error objects
    if (typeof error === "string") {
      error = new Error(error);
    }
    if (prevProps.error !== error) {
      this.setState({
        error,
        hasError: error !== undefined,
      });
    }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    // Try to convert incorrectly constructed errors to Error objects
    if (typeof error === "string") {
      error = new Error(error);
    }
    this.setState({
      error,
      info: errorInfo,
      hasError: true,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <Dialog open={true} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">We've encountered a problem</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              An error we can't automatically recover from has occurred.
              <br />
              <br />
              Please reload the page to restart the application.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button disableRipple onClick={() => this.copyToClipboard(this.state.error)}>
              {this.state.copyButtonText}
            </Button>
            <Button onClick={() => window.location.reload()} autoFocus>
              Reload page
            </Button>
          </DialogActions>
        </Dialog>
      );
    } else {
      return this.props.children;
    }
  }

  private copyToClipboard(error?: Error) {
    let details = "Error details:\n";

    if (error) {
      try {
        const { message, stack } = error;
        details += `Error: ${message}\n${stack}`;
      } catch (err) {
        details = "Unable to get error details.";
      }
    } else {
      details = "No error details are available.";
    }

    if ("clipboard" in navigator) {
      navigator.clipboard.writeText(details);
    } else {
      document.execCommand("copy", true, details);
    }

    this.setState({ copyButtonText: "Copied" });
    window.setTimeout(() => this.setState({ copyButtonText: DEFAULT_COPY_BUTTON_TEXT }), COPIED_INTERVAL);
  }
}
