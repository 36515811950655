/**
 * Represents the font style options for text rendering.
 */
export enum TLFontStyle {
  /**
   * @description This font style is used for a handdrawn style.
   */
  Draw = "draw",

  /**
   * @description This font style is used for sans-serif fonts.
   */
  Sans = "sans",

  /**
   * @description This font style is used for serif fonts.
   */
  Serif = "serif",

  /**
   * @description This font style is used for monospaced fonts.
   */
  Mono = "mono",
}
