import { InsertLinkOutlined } from "@mui/icons-material";
import { Box, Button, Popover, Stack, TextField, Typography } from "@mui/material";
import type { Editor } from "@tiptap/react";
import React, { MouseEvent, useState } from "react";
import { useTranslation } from "react-i18next";

import type { IEditorConfig } from "../../Editor/index.js";
import { capitalizeFirstLetter, getEditorToolbarButtonTooltip } from "../../Utils/ToolbarItemUtils.js";
import { getFirstNodesTextFromSelection, ITextContentDetails } from "../../Utils/CommandUtil.js";
import { ToolbarItemBase } from "../../Toolbars/ToolbarItemBase.js";
import { ToolbarButton } from "../../Toolbars/ToolbarButton.js";

const ITEM_NAME = "link";

/**
 * A toolbar button that toggles link in the document.
 */
export class LinkButtonItem extends ToolbarItemBase {
  // *********************************************
  // Public properties
  // *********************************************/
  /**
   * @inheritdoc
   */
  readonly name = ITEM_NAME;

  // *********************************************
  // Public methods
  // *********************************************/
  /**
   * @inheritdoc
   */
  create(editor: Editor, config: IEditorConfig) {
    const { toolbarOptions } = config;
    return (
      <LinkButton
        key={ITEM_NAME}
        setLink={(textContentDetails: ITextContentDetails | null, link: string) =>
          editor.chain().focus().setLinkWithText(textContentDetails, link).run()
        }
        unsetLink={() => editor.chain().focus().extendMarkRange("link").unsetLink().run()}
        getFirstNodesTextFromSelection={() => getFirstNodesTextFromSelection(editor.state)}
        isActive={() => editor.isActive(ITEM_NAME)}
        toolbarOptions={toolbarOptions}
      />
    );
  }
}

/**
 * Props for LinkButton.
 */
export interface LinkButtonProps {
  isActive: (command: typeof ITEM_NAME) => boolean;
  setLink: (textContentDetails: ITextContentDetails | null, link: string) => void;
  unsetLink: () => void;
  getFirstNodesTextFromSelection: () => ITextContentDetails;
  toolbarOptions?: IEditorConfig["toolbarOptions"];
}

/**
 * A toolbar button for the `link` command.
 */
export const LinkButton = (props: LinkButtonProps) => {
  const { isActive, setLink, unsetLink, getFirstNodesTextFromSelection, toolbarOptions } = props;
  const tooltipPlacement = toolbarOptions?.tooltipPlacement;

  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [textContentDetails, setTextContentDetails] = useState<ITextContentDetails | null>(null);
  const [textValue, setTextValue] = useState<string>("");
  const [urlValue, setUrlValue] = useState<string>("");

  const openLinkPopover = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);

    const textContentDetails = getFirstNodesTextFromSelection();
    setTextContentDetails(textContentDetails);
    setTextValue(textContentDetails.textContent);
  };

  const closeLinkPopover = () => {
    setAnchorEl(null);
  };

  const resetState = () => {
    setTextContentDetails(null);
    setTextValue("");
    setUrlValue("");

    closeLinkPopover();
  };

  const onAddUrl = () => {
    setLink(
      {
        textContent: textValue,
        startPos: textContentDetails?.startPos || null,
        endPos: textContentDetails?.endPos || null,
      },
      urlValue,
    );
    resetState();
  };

  const onCancel = () => {
    resetState();
  };

  const open = Boolean(anchorEl);
  const id = open ? "link-button" : undefined;

  return (
    <>
      <Box component="span">
        <ToolbarButton
          Icon={InsertLinkOutlined}
          tooltip={getEditorToolbarButtonTooltip(t, ITEM_NAME)}
          onClick={(e) => (isActive(ITEM_NAME) ? unsetLink() : openLinkPopover(e))}
          isActive={isActive(ITEM_NAME)}
          tooltipPlacement={tooltipPlacement}
        />
      </Box>

      <Box component="span">
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={closeLinkPopover}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Stack
            component="form"
            sx={{
              m: 1,
              width: "40ch",
              padding: "1ch",
            }}
            spacing={1}
            noValidate
            autoComplete="off"
          >
            <Typography variant="h6">{t(`Editor.${capitalizeFirstLetter(ITEM_NAME)}.Dialog.Title`, { ns: "editor" })}</Typography>
            <TextField
              id="add-text-field"
              label={t(`Editor.${capitalizeFirstLetter(ITEM_NAME)}.Dialog.Text`, { ns: "editor" })}
              variant="standard"
              size="small"
              value={textValue}
              onChange={(e) => {
                setTextValue(e.target.value);
              }}
            />

            <TextField
              id="add-url-field"
              label={t(`Editor.${capitalizeFirstLetter(ITEM_NAME)}.Dialog.Url`, { ns: "editor" })}
              variant="standard"
              size="small"
              value={urlValue}
              onChange={(e) => {
                setUrlValue(e.target.value);
              }}
            />

            <Box component="span" style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button variant="text" onClick={onAddUrl}>
                {t(`Global.Action.OK`, { ns: "editor" })}
              </Button>
              <Button variant="text" onClick={onCancel} style={{ marginLeft: "10px" }}>
                {t(`Global.Action.Cancel`, { ns: "editor" })}
              </Button>
            </Box>
          </Stack>
        </Popover>
      </Box>
    </>
  );
};
