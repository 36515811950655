import { StyledComponent } from "@emotion/styled";
import { Tab, TabProps } from "@mui/material";
import { styled } from "@mui/material/styles";

export const CompanionTab: StyledComponent<TabProps> = styled((props: TabProps) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    "&.MuiTab-root": {
      border: "1px solid #e9e9e9",
      borderBottomWidth: 0,
      "&:not(:first-of-type)": {
        marginLeft: -1,
      },
      background: "#f7f7f7",
      opacity: 1,
    },
    "&.Mui-selected": {
      borderBottomWidth: 0,
      background: "#ffffff",
      "& $wrapper": {
        opacity: 1,
      },
    },
  }),
);
