import { makeVar, useReactiveVar } from "@apollo/client";
import {
  Box,
  Button,
  ButtonBase,
  Checkbox,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { Edit, FilterListOutlined, GridViewOutlined, SearchOutlined, ViewListOutlined } from "@mui/icons-material";
import cloneDeep from "lodash.clonedeep";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useDebounce } from "use-debounce";

import { CommandContext } from "CommandContext";
import { TagsSelectFilterButton } from "Components/TagsSelectFilterButton/TagsSelectFilterButton";
import {
  useUpsertWorkspacePageUserPreferencesMutation,
  useWorkspaceBoardTagsQuery,
  useWorkspacePageUserPreferencesQuery,
  useWorkspaceQuery,
} from "GraphQL/Generated/Apollo";
import { WORKSPACE_PAGE_USER_PREFERENCES_KEY } from "GraphQL/UserPreference";
import { RoutePaths } from "RoutePaths";
import { ManageWorkspaceDialog } from "./ManageWorkspaceDialog";
import { WorkspaceContentView, WorkspaceContentGridSize } from "./WorkspaceContentView";

import "./WorkspacePage.css";

const UNTAGGED_OPTION_KEY = "__UNTAGGED__";
const SEARCH_VALUE_DEBOUNCE_TIME = 500;

type WorkspaceViewType = "list" | "grid";

// We need to save filter values in a reactive variable to save the values between
// page navigation (WorkspacePage -> WorkspacesPage -> WorkspacePage )
const workspaceBoardsTagsFilterMapVar = makeVar<Record<string, Array<string>>>({});

interface IWorkspacePageUserPreferences {
  gridSize: WorkspaceContentGridSize;
  viewType: WorkspaceViewType;
}

export function WorkspacePage() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const params = useParams();
  const workspaceId = params.id || "";

  const [manageWorkspaceDialogOpen, setManageWorkspaceDialogOpen] = useState(false);
  const [preferences, setPreferences] = useState<IWorkspacePageUserPreferences>({ gridSize: "medium", viewType: "grid" });
  const [searchValue, setSearchValue] = useState<string>("");
  const [debouncedSearchValue] = useDebounce(searchValue, SEARCH_VALUE_DEBOUNCE_TIME);

  const tagsFilterMap = useReactiveVar<Record<string, Array<string>>>(workspaceBoardsTagsFilterMapVar);
  const tagsFilterValue = useMemo(() => tagsFilterMap[workspaceId] || [], [tagsFilterMap, workspaceId]);

  const { data: workspaceData } = useWorkspaceQuery({ variables: { id: workspaceId } });
  const pageTitle = workspaceData?.workspace?.name || t("Pages.Workspace.Title");
  const workspaceName = workspaceData?.workspace?.name || "";

  const { data: workspaceBoardTagsData } = useWorkspaceBoardTagsQuery({ variables: { workspaceId } });
  const boardTags = workspaceBoardTagsData?.workspaceBoardTags || [];

  const { data: persistedPreferences } = useWorkspacePageUserPreferencesQuery({
    variables: {
      key: WORKSPACE_PAGE_USER_PREFERENCES_KEY,
    },
  });

  useEffect(() => {
    // The preferences have changed, update the state
    setPreferences({
      gridSize: persistedPreferences?.userPreference?.data?.gridSize || "medium",
      viewType: persistedPreferences?.userPreference?.data?.viewType || "grid",
    });
  }, [persistedPreferences]);

  const [upsertUserPreference] = useUpsertWorkspacePageUserPreferencesMutation({
    // Update cache directly since the server will not return the fully cascaded data, just the user portion
    // This ensures any default and org-level preferences are not lost
    update: (cache, data) => {
      cache.modify({
        fields: {
          userPreference(existing = {}) {
            return {
              ...existing,
              data: {
                ...existing.data,
                ...data.data?.upsertUserPreference.data,
              },
            };
          },
        },
      });
    },
  });

  useEffect(() => {
    // Set current application session context
    CommandContext.replaceSessionContext([{ workspaceId }]);
  }, [workspaceId]);

  const setWorkspaceBoardsTagsFilterMapVar = (value: Array<string>) => {
    const newTagsFilterMap = cloneDeep(tagsFilterMap);
    newTagsFilterMap[workspaceId] = value;
    workspaceBoardsTagsFilterMapVar(newTagsFilterMap);
  };

  useEffect(() => {
    if (tagsFilterValue.length === 0 || (tagsFilterValue.length === 1 && tagsFilterValue[0] === UNTAGGED_OPTION_KEY)) {
      // All the tags are selected in the beginning
      setWorkspaceBoardsTagsFilterMapVar((workspaceBoardTagsData?.workspaceBoardTags || []).concat(UNTAGGED_OPTION_KEY));
    }
  }, [workspaceBoardTagsData?.workspaceBoardTags]);

  const handleTagSelection = (event: SelectChangeEvent<string>) => {
    const {
      target: { value },
    } = event;

    setWorkspaceBoardsTagsFilterMapVar(typeof value === "string" ? value.split(",") : value);
  };

  // Persists the user preferences. NOTE: This upserts with a patch, so we don't need to send all the preferences
  const updatePreferences = useCallback(
    (preferences: Partial<IWorkspacePageUserPreferences>) => {
      setPreferences((current) => {
        return { ...current, ...preferences };
      });
      upsertUserPreference({
        variables: {
          input: {
            key: WORKSPACE_PAGE_USER_PREFERENCES_KEY,
            data: preferences,
          },
        },
      });
    },
    [upsertUserPreference],
  );

  const navigateToWorkspaces = useCallback(() => {
    navigate(RoutePaths.workspaces.path);
  }, [navigate]);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          m: 3,
          mb: 0,
          alignSelf: "center",
          width: "90%",
          maxWidth: preferences.viewType === "list" ? "960px" : "1920px",
          minWidth: "400px",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <ButtonBase
            onClick={navigateToWorkspaces}
            sx={{
              fontSize: "inherit",
              "&:hover": {
                textDecoration: "underline",
                color: "primary.main",
              },
            }}
          >
            {t("Pages.Workspaces.HeadingLabel")}
          </ButtonBase>
          &nbsp;
          {`> ${workspaceName}`}
        </Typography>

        <Box sx={{ display: "flex" }}>
          <Button
            startIcon={<Edit />}
            variant="outlined"
            sx={{ width: "220px" }}
            onClick={() => setManageWorkspaceDialogOpen(true)}
          >
            {t("Pages.Workspace.ManageWorkspace")}
          </Button>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          m: 3,
          alignSelf: "center",
          flexDirection: "column",
          width: "90%",
          maxWidth: preferences.viewType === "list" ? "960px" : "1920px",
          minWidth: "400px",
          border: `1px solid #f1f1f1`,
          borderRadius: "16px",
          mb: 10,
        }}
      >
        <Box sx={{ display: "flex", p: 3, pb: 0, justifyContent: "center" }}>
          <FormControl fullWidth>
            <InputLabel htmlFor="find-boards-and-files-input">{t("Pages.Workspace.FindLabel")}</InputLabel>
            <OutlinedInput
              id="find-boards-and-files-input"
              startAdornment={
                <InputAdornment position="start">
                  <SearchOutlined />
                </InputAdornment>
              }
              label={t("Pages.Workspace.FindLabel")}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </FormControl>
        </Box>

        <Box sx={{ display: "flex", p: 3, pb: 0, justifyContent: "space-between", flexWrap: "wrap" }}>
          <Box sx={{ display: "flex", alignItems: "center", mb: 2, mr: 2 }}>
            {boardTags && boardTags.length > 0 && (
              <>
                <FilterListOutlined />

                <FormControl sx={{ width: "180px", ml: 2 }} size="small">
                  <InputLabel id="tags-label" shrink>
                    {t("Pages.Workspace.Tags.Label")}
                  </InputLabel>
                  <Select
                    labelId="tags-label"
                    id="tags"
                    multiple
                    value={tagsFilterValue as any}
                    onChange={handleTagSelection}
                    input={<OutlinedInput label={t("Pages.Workspace.Tags.Label")} />}
                    renderValue={(selected) => {
                      const selectedItemsLength = (selected as unknown as string[]).length;
                      const totalItemsLength = boardTags.length + 1;

                      if (selectedItemsLength === totalItemsLength) {
                        return t("Pages.Workspace.Tags.All");
                      } else if (selectedItemsLength === 0) {
                        return t("Pages.Workspace.Tags.None");
                      }
                      return t("Pages.Workspace.Tags.Some", {
                        selectedCount: selectedItemsLength,
                        totalCount: totalItemsLength,
                      });
                    }}
                    displayEmpty
                    notched
                  >
                    <div>
                      {tagsFilterValue.length === boardTags.length + 1 ? (
                        <TagsSelectFilterButton
                          onClick={() => {
                            setWorkspaceBoardsTagsFilterMapVar([]);
                          }}
                        >
                          {t("Pages.Workspace.Tags.DeselectAll")}
                        </TagsSelectFilterButton>
                      ) : (
                        <TagsSelectFilterButton
                          onClick={() => {
                            setWorkspaceBoardsTagsFilterMapVar(boardTags.concat(UNTAGGED_OPTION_KEY));
                          }}
                        >
                          {t("Pages.Workspace.Tags.SelectAll")}
                        </TagsSelectFilterButton>
                      )}
                    </div>

                    <MenuItem key={UNTAGGED_OPTION_KEY} value={UNTAGGED_OPTION_KEY}>
                      <Checkbox checked={tagsFilterValue.indexOf(UNTAGGED_OPTION_KEY) > -1} />
                      <ListItemText primary={t("Pages.Workspace.Tags.Untagged")} />
                    </MenuItem>
                    {boardTags.map((tag) => (
                      <MenuItem key={tag} value={tag}>
                        <Checkbox checked={tagsFilterValue.indexOf(tag) > -1} />
                        <ListItemText primary={tag} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <IconButton
              color={preferences.viewType === "list" ? "primary" : "default"}
              size="medium"
              onClick={() => updatePreferences({ viewType: "list" })}
            >
              <ViewListOutlined />
            </IconButton>

            <IconButton
              color={preferences.viewType === "grid" ? "primary" : "default"}
              size="medium"
              onClick={() => updatePreferences({ viewType: "grid" })}
            >
              <GridViewOutlined />
            </IconButton>
          </Box>
        </Box>

        <WorkspaceContentView
          gridSize={preferences.gridSize}
          onGridSizeChange={(gridSize) => updatePreferences({ gridSize })}
          filterTags={tagsFilterValue.filter((tag) => tag !== UNTAGGED_OPTION_KEY)}
          includeUntagged={tagsFilterValue.indexOf(UNTAGGED_OPTION_KEY) > -1}
          workspaceId={workspaceId}
          viewType={preferences.viewType}
          searchValue={debouncedSearchValue}
        />
      </Box>

      {manageWorkspaceDialogOpen && (
        <ManageWorkspaceDialog
          open={manageWorkspaceDialogOpen}
          onClose={() => setManageWorkspaceDialogOpen(false)}
          workspaceId={workspaceId}
        />
      )}
    </>
  );
}
