import { SelectableText } from "@bigpi/cutlery";
import { useTranslation } from "react-i18next";

import { ITopicDiscussionSummaryExampleResult, ITopicDiscussionExampleResult } from "BoardComponents/BoardDatastore";
import { getFormattedDate } from "Utils/DateUtils";
import { DataUtils } from "Utils/DataUtils";

export interface ITopicDiscussionSummaryOverviewLabelProps {
  topicDiscussionSummaryDetails: Array<ITopicDiscussionSummaryExampleResult | ITopicDiscussionExampleResult>;
}

const GROUP_FIELD = "eventDate";

export function TopicDiscussionSummaryOverviewLabel(props: ITopicDiscussionSummaryOverviewLabelProps) {
  const { topicDiscussionSummaryDetails } = props;

  const { t } = useTranslation();

  const eventsCount = DataUtils.getGroupedKeys(topicDiscussionSummaryDetails, GROUP_FIELD).length;
  const topicDiscussionSummaryDetailsSortedByDate = topicDiscussionSummaryDetails.slice().sort((a, b) => {
    return new Date(a.eventDate).getTime() - new Date(b.eventDate).getTime();
  });
  const formattedFromDate =
    topicDiscussionSummaryDetailsSortedByDate.length > 0
      ? getFormattedDate(
          topicDiscussionSummaryDetailsSortedByDate[0].eventDate,
          t("Components.TopicDiscussionSummaryOverview.EventDateFormat"),
        )
      : "-";
  const formattedToDate =
    topicDiscussionSummaryDetailsSortedByDate.length > 0
      ? getFormattedDate(
          topicDiscussionSummaryDetailsSortedByDate[topicDiscussionSummaryDetailsSortedByDate.length - 1].eventDate,
          t("Components.TopicDiscussionSummaryOverview.EventDateFormat"),
        )
      : "-";

  return (
    <SelectableText
      variant="body1"
      fontWeight="500"
      sx={{ flexGrow: 1, maxWidth: "870px", overflow: "hidden", textOverflow: "ellipsis", textWrap: "nowrap" }}
    >
      {`${t("Components.TopicDiscussionSummaryOverview.OverviewEventsLabel", {
        // "count" is required by the react-i18next library to decide whether to use plural translation or singular
        // translation - https://www.i18next.com/translation-function/plurals#singular-plural
        count: eventsCount,

        // Dynamic values to be used in the translation string
        numberOfEvents: eventsCount,
      })} ${t("Components.TopicDiscussionSummaryOverview.OverviewDateRangeLabel", {
        count: formattedFromDate === formattedToDate ? 1 : 2,

        // Dynamic values to be used in the translation string
        fromDate: formattedFromDate,
        toDate: formattedToDate,
      })}`}
    </SelectableText>
  );
}
