import { ContentTypes } from "@bigpi/cookbook";
import { Checkbox, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";

import { FileIcon } from "Components/FileIcon/FileIcon";
import { WorkspaceFilesQuery } from "GraphQL/Generated/Apollo";
import { WorkspaceFileListMenu } from "./WorkspaceFileListMenu";

export interface IWorkspaceFileListProps {
  isSelectionEnabled: boolean;
  loading: boolean;
  onClick: (fileId: string) => void;
  onSelectionChange: (file: WorkspaceFilesQuery["workspaceFiles"][number]) => void;
  rows: WorkspaceFilesQuery["workspaceFiles"];
  selectedIds: Array<string>;
}

export function WorkspaceFileList(props: IWorkspaceFileListProps) {
  const { isSelectionEnabled, loading, onClick, onSelectionChange, rows, selectedIds } = props;

  return (
    <>
      {!loading && rows.length > 0 && (
        <List component="nav" sx={{ maxWidth: "960px", display: "flex", flexDirection: "column", flex: 1 }}>
          {rows.map((row) => {
            return (
              <ListItem
                key={row.id}
                secondaryAction={
                  <WorkspaceFileListMenu
                    workspaceFileId={row.id}
                    workspaceId={row.workspaceId}
                    workspaceFileFilename={row.filename}
                    workspaceFileMimeType={row.mimeType as ContentTypes}
                  />
                }
                disablePadding
              >
                {isSelectionEnabled && (
                  <ListItemIcon>
                    <Checkbox checked={selectedIds.includes(row.id)} onChange={() => onSelectionChange(row)} />
                  </ListItemIcon>
                )}

                <ListItemButton sx={{ cursor: "pointer" }} disableRipple onClick={() => onClick(row.id)}>
                  <ListItemIcon>
                    <FileIcon mimetype={row.mimeType} />
                  </ListItemIcon>
                  <ListItemText primary={row.name} primaryTypographyProps={{ fontWeight: "500" }} />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      )}
    </>
  );
}
