/**
 * Represents the fill style options for a shape.
 */
export enum TLFillStyle {
  /**
   * No fill.
   */
  None = "none",

  /**
   * Semi-transparent fill.
   */
  Semi = "semi",

  /**
   * Solid fill.
   */
  Solid = "solid",

  /**
   * Fill with a pattern.
   */
  Pattern = "pattern",
}
