import { SortOrder } from "./SortOrder.js";
import { ISortOption } from "./ISortOption.js";

export const NameDescSortOptionName = "NameDesc";

export const NameDescSortOption: ISortOption = {
  field: "name",
  name: NameDescSortOptionName,
  sortOrder: SortOrder.Desc,
};
