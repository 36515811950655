import { defineMigrations } from "@tldraw/store";

export const LockedTextShapeVersions = {
  Initial: 0,
  RemoveJustify: 1,
};

export const lockedTextShapeMigrations = defineMigrations({
  firstVersion: LockedTextShapeVersions.Initial,
  currentVersion: LockedTextShapeVersions.RemoveJustify,
  migrators: {
    [LockedTextShapeVersions.RemoveJustify]: {
      up: (shape) => {
        let newAlign = shape.props.align;
        if (newAlign === "justify") {
          newAlign = "start";
        }

        return {
          ...shape,
          props: {
            ...shape.props,
            align: newAlign,
          },
        };
      },
      down: (shape) => {
        return { ...shape };
      },
    },
  },
});
