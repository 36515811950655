import { defineMigrations } from "@tldraw/store";

import { IFeedShape } from "./IFeedShape.js";

export const FeedShapeVersions = {
  Initial: 0,
  AddCanScroll: 1,
};

export const feedShapeMigrations = defineMigrations({
  firstVersion: FeedShapeVersions.Initial,
  currentVersion: FeedShapeVersions.AddCanScroll,
  migrators: {
    [FeedShapeVersions.AddCanScroll]: {
      up: (shape: IFeedShape) => {
        return {
          ...shape,
          props: { ...shape.props, canScroll: true },
        };
      },
      down: (shape: IFeedShape & { canScroll: boolean }) => {
        const { canScroll, ...rest } = shape.props;
        return { ...shape, props: rest };
      },
    },
  },
});
