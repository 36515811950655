import { CommandActionType } from "../CommandActionType.js";
import { CommandOutputType } from "../CommandOutputType.js";
import { ICommand } from "../ICommand.js";
import { DataType, EntityType } from "../ICommandInput.js";

export const COMMAND_EXTRACT_QUESTIONS_TEMPLATE: ICommand = {
  id: "702aef9d-0a71-414d-bc20-011ca5d7594a",
  name: "Extract Questions",
  description: "",
  action: {
    actionType: CommandActionType.ExtractQuestionsAction,
    data: {},
  },
  inputs: [
    {
      dataType: {
        dataType: DataType.String,
        isArray: false,
        validation: "",
      },
      fieldName: "shapeId",
      fieldNameAliases: [],
      entityType: EntityType.String,
      defaultValue: "",
      label: "",
      visible: false,
      required: true,
    },
    {
      dataType: {
        dataType: DataType.String,
        isArray: false,
        validation: "",
      },
      fieldName: "workspaceBoardId",
      fieldNameAliases: [],
      entityType: EntityType.WorkspaceBoard,
      defaultValue: "",
      label: "",
      visible: false,
      required: true,
    },
  ],
  inputTemplate: `{ "shapeId": "{{ shapeId }}", "workspaceBoardId": "{{ workspaceBoardId }}" }`,
  isBlocking: false,
  outputTypes: [CommandOutputType.None],
};
