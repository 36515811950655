import { FileSourceType } from "@bigpi/cookbook";
import { Permissions } from "@bigpi/permission";
import { getFilePreviewShapeDefaultProps, getHtmlDocumentShapeDefaultProps } from "@bigpi/tl-schema";
import { Box } from "@mui/system";
import { createShapeId } from "@tldraw/tldraw";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidV4 } from "uuid";

import { createShapesAtEmptyPoint } from "BoardComponents/Utils/CreateShapeUtils";
import { HasPermission } from "Components/HasPermission/HasPermission";
import { useCollaborativeBoardEditor } from "TldrawBoard/useCollaborativeBoardEditor";
import { FilesAndDocumentsQuery } from "GraphQL/Generated/Apollo";
import { DocumentList } from "./DocumentList";
import { LibraryList } from "./LibraryList";
import { WorkspaceFileList } from "./WorkspaceFileList";

interface ISourceListProps {
  data: FilesAndDocumentsQuery;
  onClose: () => void;
}

export function SourceList(props: ISourceListProps) {
  const { data, onClose } = props;

  const { documents, fileBundleView, workspaceFiles } = data;

  const tldrawEditor = useCollaborativeBoardEditor();
  const { t } = useTranslation();

  const onAddFilePreviewShape = useCallback(
    (fileId: string, fileSourceType: FileSourceType) => {
      if (tldrawEditor) {
        // Add to board
        createShapesAtEmptyPoint(
          tldrawEditor,
          [
            {
              id: createShapeId(uuidV4()),
              type: "filePreview",
              props: {
                ...getFilePreviewShapeDefaultProps(),
                fileId: fileId,
                fileSourceType: fileSourceType,
              },
            },
          ],
          null,
        );
        // Close
        onClose();
      }
    },
    [onClose, tldrawEditor],
  );

  const onAddDocumentShape = useCallback(
    (html: string) => {
      if (tldrawEditor) {
        // Add to board
        createShapesAtEmptyPoint(
          tldrawEditor,
          [
            {
              id: createShapeId(uuidV4()),
              type: "htmlDocument",
              props: {
                ...getHtmlDocumentShapeDefaultProps(),
                asyncUpdateLock: false,
                html: html || "",
              },
            },
          ],
          null,
        );
        // Close
        onClose();
      }
    },
    [onClose, tldrawEditor],
  );

  if (documents.length === 0 && fileBundleView.length === 0 && workspaceFiles.length === 0) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "200px",
        }}
      >
        {t("Components.AddToBoard.NoResults")}
      </Box>
    );
  }

  return (
    <Box>
      {workspaceFiles.length > 0 && <WorkspaceFileList data={workspaceFiles} onAddPreviewToBoard={onAddFilePreviewShape} />}
      {fileBundleView.length > 0 && (
        <HasPermission permission={Permissions.PatronLibraryRead}>
          <LibraryList data={fileBundleView} onAddPreviewToBoard={onAddFilePreviewShape} />
        </HasPermission>
      )}
      {documents.length > 0 && (
        <HasPermission permission={Permissions.PatronDocumentsRead}>
          <DocumentList data={documents} onAddToBoard={onAddDocumentShape} />
        </HasPermission>
      )}
    </Box>
  );
}
