// Generated by ts-to-zod
import { z } from "zod";

export const htmlDocumentShapePropsZodSchema = z.object({
  w: z.number().describe("The width of the HTML document."),
  h: z.number().describe("The height of the HTML document."),
  html: z.string().describe("The HTML content of the document."),
  scale: z
    .number()
    .describe("The scale of the HTML document shape on the canvas. A number between 0 and 1 where 1 is 100% scale."),
  asyncUpdateLock: z
    .boolean()
    .describe(
      "A boolean value indicating whether the HTML document shape is locked in the user interface document editor. If true, the shape cannot be edited.",
    ),
  canScroll: z
    .boolean()
    .describe(
      "A boolean value indicating whether the HTML document shape can currently be scrolled. This value is set automatically in the application based on the content length and size of the HTML document.",
    ),
});
