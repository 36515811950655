import { ApolloClient } from "@apollo/client";
import { ITopicDiscussionInNewsArticleAnalysisFacets, ITopicDiscussionInNewsArticleAnalysisSelection } from "@bigpi/tl-schema";

import { ITopicDiscussionInNewsArticleResult } from "BoardComponents/BoardDatastore";
import { GET_CONFIG_DATA } from "GraphQL/Configs/Query";
import { GetConfigDataQuery, TopicDiscussionInNewsArticlesQuery } from "GraphQL/Generated/Apollo";
import { TOPIC_DISCUSSION_IN_NEWS_ARTICLES_QUERY } from "GraphQL/TopicDiscussionInNewsArticle";
import { DataUtils } from "Utils/DataUtils";
import { ITopicDiscussionInNewsArticleAnalysisConfig, TopicDiscussionInNewsArticleResultKeys } from "../DataFrameConfigs";
import { TOPIC_DISCUSSION_IN_NEWS_ARTICLE_ANALYSIS_CONFIG_KEY } from "./TopicDiscussionsInNewsArticlesAnalysisShape";

/**
 * Fetches the data for the topic discussion in news articles analysis.
 *
 * @param apolloClient - The Apollo client instance.
 * @param facets - The facets.
 *
 * @returns The array of topic discussion in news articles results.
 */
export const getTopicDiscussionInNewsArticlesData = async (
  apolloClient: ApolloClient<object>,
  facets: ITopicDiscussionInNewsArticleAnalysisFacets,
): Promise<Array<ITopicDiscussionInNewsArticleResult>> => {
  const apiResponse = await apolloClient.query<TopicDiscussionInNewsArticlesQuery>({
    query: TOPIC_DISCUSSION_IN_NEWS_ARTICLES_QUERY,
    variables: {
      facets,
    },
  });

  return (
    (apiResponse.data?.topicDiscussionInNewsArticles as Array<ITopicDiscussionInNewsArticleResult>) ||
    DataUtils.getImmutableEmptyArray<ITopicDiscussionInNewsArticleResult>()
  );
};

/**
 * Fetches the topic discussion in news article analysis config.
 *
 * @param apolloClient - The Apollo client instance.
 * @param organizationId - The organization ID.
 *
 * @returns The topic discussion in news article analysis config.
 */
export const getTopicDiscussionInNewsArticleAnalysisConfig = async (
  apolloClient: ApolloClient<object>,
  organizationId: string,
): Promise<ITopicDiscussionInNewsArticleAnalysisConfig> => {
  const apiResponse = await apolloClient.query<GetConfigDataQuery>({
    query: GET_CONFIG_DATA,
    variables: {
      key: TOPIC_DISCUSSION_IN_NEWS_ARTICLE_ANALYSIS_CONFIG_KEY,
      organizationId,
    },
  });

  return JSON.parse(apiResponse.data?.Config?.data || "{}") as ITopicDiscussionInNewsArticleAnalysisConfig;
};

/**
 * Formats the topic discussion in news articles data in the required format.
 * This function splits `topicDiscussions` into separate records.
 *
 * @param topicDiscussionInNewsArticleResults The topic discussion in news articles data.
 *
 * @returns The data in the required format.
 */
const formatTopicDiscussionInNewsArticleData = (
  topicDiscussionInNewsArticleResults: Array<ITopicDiscussionInNewsArticleResult>,
) => {
  const formattedData: Array<ITopicDiscussionInNewsArticleResult> = [];
  topicDiscussionInNewsArticleResults.forEach((topicDiscussionInNewsArticleResult) => {
    const { topicDiscussions, ...otherItems } = topicDiscussionInNewsArticleResult;
    topicDiscussions.forEach((topicDiscussion) => {
      formattedData.push({
        ...otherItems,
        date: otherItems.date ? new Date(otherItems.date) : otherItems.date,
        topicDiscussions,
        topic: topicDiscussion.topic,
        summary: topicDiscussion.summary,
        topics: topicDiscussions.map((topicDiscussion) => topicDiscussion.topic),
        summaries: topicDiscussions.map((topicDiscussion) => topicDiscussion.summary),
      });
    });
  });

  return formattedData;
};

/**
 * Fetches the data for the topic discussion in news articles analysis and formats it.
 *
 * @param apolloClient - The Apollo client instance.
 * @param facets - The facets.
 *
 * @returns The array of formatted topic discussion in news articles results.
 */
export const getFormattedTopicDiscussionInNewsArticlesData = async (
  apolloClient: ApolloClient<object>,
  facets: ITopicDiscussionInNewsArticleAnalysisFacets,
): Promise<Array<ITopicDiscussionInNewsArticleResult>> => {
  const data = await getTopicDiscussionInNewsArticlesData(apolloClient, facets);
  return formatTopicDiscussionInNewsArticleData(data);
};

/**
 * Filters the data based on the selected facets with multiple values.
 *
 * @param data Data to be filtered.
 * @param facets Facets applied.
 *
 * @returns Data after applying the filters.
 */
const applyMultiValueFiltersOnTopicDiscussionInNewsArticlesData = (
  data: Array<ITopicDiscussionInNewsArticleResult>,
  facets: ITopicDiscussionInNewsArticleAnalysisFacets,
) => {
  const { topic = [] } = facets;
  return data.filter((item: ITopicDiscussionInNewsArticleResult) => {
    return topic.length === 0 || topic.includes(item.topic);
  });
};

/**
 * Fetches the multi-facet filtered topic discussion in news articles data based on the facets.
 *
 * @param apolloClient - The Apollo client instance.
 * @param facets - The facets.
 *
 * @returns The array of multi-facet filtered topic discussion in news articles results.
 */
export const getMultiFacetFilteredTopicDiscussionInNewsArticlesData = async (
  apolloClient: ApolloClient<object>,
  facets: ITopicDiscussionInNewsArticleAnalysisFacets,
): Promise<Array<ITopicDiscussionInNewsArticleResult>> => {
  const formattedData = await getFormattedTopicDiscussionInNewsArticlesData(apolloClient, facets);
  return applyMultiValueFiltersOnTopicDiscussionInNewsArticlesData(formattedData, facets);
};

/**
 * Checks if the x axis is selected.
 *
 * @param channelData Channel data
 * @param selectedXAxis Selected x axis values
 * @param field Field to check
 *
 * @returns True if the x axis is selected, false otherwise.
 */
export const isXAxisSelected = (
  channelData: Array<ITopicDiscussionInNewsArticleResult>,
  selectedXAxis: Array<string> | undefined,
  field: TopicDiscussionInNewsArticleResultKeys,
) => {
  if (!selectedXAxis) {
    return true;
  }

  for (let dataItem of channelData) {
    // Bins/rect range end gives the next bin start value. Need to use less than operator to check if the value is in the bin.
    const startDate = new Date(selectedXAxis[0]).valueOf();
    const endDate = new Date(selectedXAxis[1]).valueOf();
    const dateToVerify = new Date(dataItem[field]).valueOf();
    const inRange = dateToVerify >= startDate && dateToVerify <= endDate;
    if (inRange) {
      return true;
    }
  }

  return false;
};

/**
 * Checks if the y group is selected.
 *
 * @param channelData Channel data
 * @param selectedYGroup Selected y group value
 * @param field Field to check
 *
 * @returns True if the y group is selected, false otherwise.
 */
export const isYGroupSelected = (
  channelData: Array<ITopicDiscussionInNewsArticleResult>,
  selectedYGroup: string | undefined,
  field: TopicDiscussionInNewsArticleResultKeys,
) => {
  if (!selectedYGroup) {
    return true;
  }

  for (let dataItem of channelData) {
    const match = dataItem[field] === selectedYGroup;
    if (match) {
      return true;
    }
  }

  return false;
};

/**
 * Checks if the y item is selected.
 *
 * @param channelData Channel data
 * @param selectedYItem Selected y item value
 * @param field Field to check
 *
 * @returns True if the y item is selected, false otherwise.
 */
export const isYItemSelected = (
  channelData: Array<ITopicDiscussionInNewsArticleResult>,
  selectedYItem: string | undefined,
  field: TopicDiscussionInNewsArticleResultKeys,
) => {
  if (!selectedYItem) {
    return true;
  }

  for (let dataItem of channelData) {
    const match = dataItem[field] === selectedYItem;
    if (match) {
      return true;
    }
  }

  return false;
};

/**
 * Reverts the data format to the original format.
 *
 * @param data The data.
 * @param fields The fields.
 *
 * @returns The data in the original format.
 */
export const revertDataFormat = (
  data: Array<ITopicDiscussionInNewsArticleResult>,
  fields: ITopicDiscussionInNewsArticleAnalysisConfig["groupHistogramChart"]["fields"],
): Array<ITopicDiscussionInNewsArticleResult> => {
  if (!fields) {
    return [];
  }

  return DataUtils.getUniqueValues<ITopicDiscussionInNewsArticleResult>(data, fields.uniqueField);
};

/**
 * Applies the single value filter on the topic discussion in news articles data.
 *
 * @param data The topic discussion in news articles data.
 * @param selection The analysis selection.
 * @param config The analysis config.
 *
 * @returns The filtered topic discussion in news articles data.
 */
export const applySingleValueFilterOnTopicDiscussionInNewsArticlesData = (
  data: Array<ITopicDiscussionInNewsArticleResult>,
  selection: ITopicDiscussionInNewsArticleAnalysisSelection,
  config: ITopicDiscussionInNewsArticleAnalysisConfig,
): Array<ITopicDiscussionInNewsArticleResult> => {
  if (!config || !config.groupHistogramChart) {
    return [];
  }

  const { fields, facetFields } = config.groupHistogramChart;
  const axisSelection = selection[
    facetFields.axisSelectionFacetField as keyof ITopicDiscussionInNewsArticleAnalysisSelection
  ] as ITopicDiscussionInNewsArticleAnalysisSelection["axisSelection"];
  if (!axisSelection || axisSelection.length === 0) {
    return data;
  }

  const { xField, yGroupField, yItemField } = fields;
  return data.filter((item) => {
    for (let selection of axisSelection) {
      const xAxisSelected = isXAxisSelected([item], selection.selectedXAxis, xField);
      const yGroupSelected = isYGroupSelected([item], selection.selectedYGroup, yGroupField);
      const yItemSelected = isYItemSelected([item], selection.selectedYItem, yItemField);
      if (xAxisSelected && yGroupSelected && yItemSelected) {
        return true;
      }
    }

    return false;
  });
};

/**
 * Fetches the multi-facet filtered data and config for the topic discussion in news articles
 * analysis based on the analysis type and facets, filters the data and reverts the data format.
 *
 * @param apolloClient - The Apollo client instance.
 * @param organizationId - The organization ID.
 * @param facets - The facets.
 * @param selection - The selection.
 *
 * @returns The array of filtered topic discussion in news articles results.
 */
export const getFilteredTopicDiscussionInNewsArticlesData = async (
  apolloClient: ApolloClient<object>,
  organizationId: string,
  facets: ITopicDiscussionInNewsArticleAnalysisFacets,
  selection: ITopicDiscussionInNewsArticleAnalysisSelection,
): Promise<Array<ITopicDiscussionInNewsArticleResult>> => {
  const multiFacetFilteredData = await getMultiFacetFilteredTopicDiscussionInNewsArticlesData(apolloClient, facets);
  const config = await getTopicDiscussionInNewsArticleAnalysisConfig(apolloClient, organizationId);
  const filteredData = applySingleValueFilterOnTopicDiscussionInNewsArticlesData(multiFacetFilteredData, selection, config);

  // Since we are formatting the original database data in _formatData, we need to get the unique values from the original data
  return revertDataFormat(filteredData, config.groupHistogramChart?.fields || {});
};
