import { AnyExtension } from "@tiptap/core";
import { Blockquote } from "@tiptap/extension-blockquote";
import { Bold } from "@tiptap/extension-bold";
import { Code } from "@tiptap/extension-code";
import { CodeBlock } from "@tiptap/extension-code-block";
import { isChangeOrigin } from "@tiptap/extension-collaboration";
import { Color } from "@tiptap/extension-color";
import { Details } from "@tiptap-pro/extension-details";
import { DetailsContent } from "@tiptap-pro/extension-details-content";
import { DetailsSummary } from "@tiptap-pro/extension-details-summary";
import { Document } from "@tiptap/extension-document";
import { Dropcursor } from "@tiptap/extension-dropcursor";
import { Gapcursor } from "@tiptap/extension-gapcursor";
import { HardBreak } from "@tiptap/extension-hard-break";
import { History } from "@tiptap/extension-history";
import { HorizontalRule } from "@tiptap/extension-horizontal-rule";
import { Italic } from "@tiptap/extension-italic";
import { Mention } from "@tiptap/extension-mention";
import { Placeholder } from "@tiptap/extension-placeholder";
import { Strike } from "@tiptap/extension-strike";
import { TableHeader } from "@tiptap/extension-table-header";
import { Text } from "@tiptap/extension-text";
import { Underline } from "@tiptap/extension-underline";
import { UniqueID } from "@tiptap-pro/extension-unique-id";

import type { IExtensionOptions } from "./IExtensionOptions.js";

// NOTE: These needs a direct import of just the extension, not the index.ts file. Otherwise it may break consumers that can't process CSS files from toolbar items.
import { Bookmark } from "./Bookmark/BookmarkExtension.js";
import { BulletList } from "./BulletList/BulletListExtension.js";
import { Figure } from "./Image/FigureExtension.js";
import { Heading } from "./Heading/HeadingExtension.js";
import { Image } from "./Image/ImageExtension.js";
import { Indent } from "./Indent/IndentExtension.js";
import { Link } from "./Link/LinkExtension.js";
import { ListItem } from "./ListItem/ListItemExtension.js";
import { UserSuggestions } from "./Mention/UserSuggestions.js";
import { OrderedList } from "./OrderedList/OrderedListExtension.js";
import { Paragraph } from "./Paragraph/ParagraphExtension.js";
import { PrivateMention, PrivateMentionPluginKey } from "./PrivateMention/PrivateMention.js";
import { Search } from "./Search/SearchExtension.js";
import { Table } from "./Table/TableExtension.js";
import { TableCell } from "./Table/TableCellExtension.js";
import { TableRow } from "./Table/TableRowExtension.js";
import { TextAlign } from "./TextAlign/TextAlignExtension.js";
import { TextHighlight } from "./TextStyle/TextHighlightExtension.js";
import { TextStyle } from "./TextStyle/TextStyleExtension.js";
import { Title } from "./Heading/TitleExtension.js";

import { extractMentionsPlugin, ExtractMentionsPluginKey } from "./Mention/ExtractMentionsPlugin.js";

export const getCommonEditorExtensions = (
  extensionOptions: IExtensionOptions = {},
  excludeExtensions: Array<string> = [],
): Array<AnyExtension> => {
  let result: Array<AnyExtension> = [
    Bookmark.configure({
      highlightClass: "highlight-yellow",
    }),
    BulletList,
    Figure,
    Heading,
    History,
    Image.configure({
      uploadImage: extensionOptions.Image?.uploadImage,
    }),
    Indent,
    Link.configure({
      protocols: ["mailto"],
      autolink: true,
      LinkActions: extensionOptions.Link?.LinkActions,
    }),
    ListItem,
    OrderedList,
    Paragraph,
    Search.configure({
      caseInsensitive: true,
      matchHighlightClass: "highlight-yellow",
      navigationHighlightClass: "highlight-orange",
    }),
    Table.configure({
      resizable: true,
    }),
    TableCell,
    TableRow,
    TextAlign,
    TextHighlight,
    TextStyle,
    Title,

    Bold,
    Blockquote,
    Code,
    CodeBlock,
    Color,
    Details.configure({
      persist: true,
      HTMLAttributes: {
        class: "details",
      },
    }),
    DetailsContent,
    DetailsSummary,
    Document,
    Dropcursor,
    Gapcursor,
    HardBreak,
    HorizontalRule,
    Italic,
    Placeholder.configure({
      includeChildren: true,
      placeholder: extensionOptions.Placeholder?.placeholder,
    }),
    Strike,
    TableHeader,
    Text,
    Underline,
    UniqueID.configure({
      // Ignore some mutations, for example applied from other users through the collaboration plugin.
      // filterTransaction: (transaction) => !isChangeOrigin(transaction),
      types: ["details", "heading", "heading", "figure", "listItem", "paragraph", "tableCell", "title"],
    }),
  ];

  // Filter out any extensions that are in the exclude list by name
  result = result.filter((extension) => !excludeExtensions.includes(extension.name));

  return result;
};

export {
  Blockquote,
  Bold,
  Code,
  CodeBlock,
  Color,
  Details,
  DetailsContent,
  DetailsSummary,
  Document,
  Dropcursor,
  Gapcursor,
  HardBreak,
  History,
  HorizontalRule,
  Italic,
  Mention,
  Placeholder,
  PrivateMention,
  Strike,
  TableHeader,
  Text,
  Underline,
  UniqueID,
  Bookmark,
  BulletList,
  Figure,
  Heading,
  Image,
  Indent,
  Link,
  ListItem,
  UserSuggestions,
  OrderedList,
  Paragraph,
  Search,
  Table,
  TableCell,
  TableRow,
  TextAlign,
  TextHighlight,
  TextStyle,
  Title,

  // Plugins
  extractMentionsPlugin,
  ExtractMentionsPluginKey,
  PrivateMentionPluginKey,
};
