// *********************************************
// Public constants
// *********************************************/
export const USER_COLORS = [
  "#958DF1",
  "#F98181",
  "#FBBC88",
  "#FAF594",
  "#70CFF8",
  "#94FADB",
  "#B9F18D",
  "#FF802B",
  "#EC5E41",
  "#F2555A",
  "#F04F88",
  "#E34BA9",
  "#BD54C6",
  "#9D5BD2",
  "#7B66DC",
  "#02B1CC",
  "#11B3A3",
  "#39B178",
  "#55B467",
] as const;

// *********************************************
// Public methods
// *********************************************/
/**
 * Gets a random element from the given list.
 *
 * @param list The list to pull an element from.
 *
 * @returns A random element from the given list.
 */
const getRandomElement = (list: ReadonlyArray<string>) => list[Math.floor(Math.random() * list.length)];

export const getRandomColor = () => getRandomElement(USER_COLORS);
