import { RawCommands, SingleCommands } from "@tiptap/core";
import { Node } from "@tiptap/pm/model";
import { AllSelection, TextSelection, Transaction } from "@tiptap/pm/state";

import { IndentProps } from "./IndentExtension.js";

function clamp(val: number, min: number, max: number): number {
  if (val < min) {
    return min;
  }
  if (val > max) {
    return max;
  }
  return val;
}

function isBulletListNode(node: Node): boolean {
  return node.type.name === "bulletList";
}

function isOrderedListNode(node: Node): boolean {
  return node.type.name === "orderedList";
}

function isListNode(node: Node): boolean {
  return isBulletListNode(node) || isOrderedListNode(node);
}

function setNodeIndentMarkup(tr: Transaction, pos: number, delta: number): Transaction {
  if (!tr.doc) {
    return tr;
  }

  const node = tr.doc.nodeAt(pos);
  if (!node) {
    return tr;
  }

  const indentValue = node.attrs.indent;
  const indent = clamp((indentValue || 0) + delta, IndentProps.MIN, IndentProps.MAX);
  if (indent === indentValue) {
    return tr;
  }

  const nodeAttrs = {
    ...node.attrs,
    indent,
  };

  return tr.setNodeMarkup(pos, node.type, nodeAttrs, node.marks);
}

export function updateIndentLevel(tr: Transaction, delta: number, commands: SingleCommands): Transaction {
  const { doc, selection } = tr;

  if (!doc || !selection) {
    return tr;
  }

  if (!(selection instanceof TextSelection || selection instanceof AllSelection)) {
    return tr;
  }

  const { from, to } = selection;

  doc.nodesBetween(from, to, (node: Node, position: number, parent) => {
    const nodeType = node.type;

    if (parent?.type.name === "listItem") {
      return false;
    }

    if (nodeType.name === "paragraph" || nodeType.name === "heading" || nodeType.name === "title") {
      tr = setNodeIndentMarkup(tr, position, delta);
      return false;
    }

    if (isListNode(node)) {
      // |<ol><li><p>test</p></li></ol>          ---> listPos
      // <ol>|<li><p>test</p></li></ol>          ---> listPos + 1
      // <ol><li>|<p>test</p></li></ol>          ---> listPos + 2
      // <ol><li><p>|test</p></li></ol>          ---> listPos + 3
      // For more - libraries/editor-tiptap/docs/EDITOR-SELECTION-POSITION.md
      const textNodePos = position + 3;

      if (delta === IndentProps.LESS) {
        return commands.liftListItem("listItem", textNodePos);
      }
      return commands.sinkListItem("listItem", textNodePos);
    }
    return true;
  });

  return tr;
}

export const indent: RawCommands["indent"] =
  () =>
  ({ tr, state, dispatch, commands }) => {
    const { selection } = state;
    tr = tr.setSelection(selection);
    tr = updateIndentLevel(tr, IndentProps.MORE, commands);

    if (tr.docChanged) {
      // eslint-disable-next-line no-unused-expressions
      dispatch && dispatch(tr);
      return true;
    }

    return false;
  };

export const outdent: RawCommands["outdent"] =
  () =>
  ({ tr, state, dispatch, commands }) => {
    const { selection } = state;
    tr = tr.setSelection(selection);
    tr = updateIndentLevel(tr, IndentProps.LESS, commands);

    if (tr.docChanged) {
      // eslint-disable-next-line no-unused-expressions
      dispatch && dispatch(tr);
      return true;
    }

    return false;
  };
