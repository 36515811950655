import { getPrimaryOccupationTitle } from "@bigpi/cookbook";
import { OccupationTitle } from "GraphQL/Generated/Apollo";
import { TFunction } from "i18next";

export const getNameWithOccupationTitle = (
  t: TFunction,
  fullName: string,
  occupationTitles: OccupationTitle[],
  description?: string,
) => {
  if (occupationTitles.length === 0) {
    return fullName;
  }

  const primaryOccupation = getPrimaryOccupationTitle(occupationTitles);
  if (primaryOccupation === OccupationTitle.Other) {
    return description && description.trim().length > 0 ? `${fullName} (${description})` : fullName;
  }

  return `${fullName} (${t(`Schema.OccupationTitle.${primaryOccupation}.Abbreviation`)})`;
};
