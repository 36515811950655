import { IHtmlDocumentShape, TLShapeId } from "@bigpi/tl-schema";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
} from "@mui/material";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { DefaultDialogTransition } from "Components/DialogTransition/DefaultDialogTransition";

const MIN_CHANGE_DESCRIPTION_LENGTH = 1;
const MIN_NAME_LENGTH = 1;

export interface IHtmlDocumentShapeDetailsToPublish {
  changeDescription: string;
  name: string;
  shapeId: TLShapeId;
}

export interface IPublishToDocumentsDialogProps {
  allKnowledgeBaseArticleShapes: Array<IHtmlDocumentShape>;
  onClose: () => void;
  onPublish: (shapeDetails: Array<IHtmlDocumentShapeDetailsToPublish>) => void;
  open: boolean;
  selectedKnowledgeBaseArticleShapes: Array<IHtmlDocumentShape>;
}

export function PublishToDocumentsDialog(props: IPublishToDocumentsDialogProps) {
  const { onClose, open, selectedKnowledgeBaseArticleShapes } = props;

  const { t } = useTranslation();

  const [allDocumentDetails, setAllDocumentDetails] = useState<Array<IHtmlDocumentShapeDetailsToPublish>>(
    props.allKnowledgeBaseArticleShapes.map((shape) => ({
      shapeId: shape.id,
      name: shape.meta.name || "",
      changeDescription: "",
    })),
  );
  const [selectedDocumentIds, setSelectedDocumentIds] = useState<Array<TLShapeId>>(
    selectedKnowledgeBaseArticleShapes.map((shape) => shape.id),
  );

  const handleSelectionToggle = (documentId: TLShapeId) => () => {
    const selectedDocument = allDocumentDetails.find((details) => details.shapeId === documentId);
    if (!selectedDocument) {
      return;
    }

    const currentIndex = selectedDocumentIds.findIndex((id) => id === documentId);
    const newSelectedDocuments = [...selectedDocumentIds];
    if (currentIndex === -1) {
      newSelectedDocuments.push(selectedDocument.shapeId);
    } else {
      newSelectedDocuments.splice(currentIndex, 1);
    }

    setSelectedDocumentIds(newSelectedDocuments);
  };

  const handleChangeDescriptionChange = useCallback(
    (documentId: TLShapeId, changeDescription: string) => {
      const documentDetails = allDocumentDetails.find((details) => details.shapeId === documentId);
      if (!documentDetails) {
        return;
      }

      const newAllDocumentDetails = allDocumentDetails.map((details) => {
        if (details.shapeId === documentId) {
          return {
            ...details,
            changeDescription,
          };
        }

        return details;
      });

      setAllDocumentDetails(newAllDocumentDetails);
    },
    [allDocumentDetails],
  );

  const handleNameChange = useCallback(
    (documentId: TLShapeId, name: string) => {
      const documentDetails = allDocumentDetails.find((details) => details.shapeId === documentId);
      if (!documentDetails) {
        return;
      }

      const newAllDocumentDetails = allDocumentDetails.map((details) => {
        if (details.shapeId === documentId) {
          return {
            ...details,
            name,
          };
        }

        return details;
      });

      setAllDocumentDetails(newAllDocumentDetails);
    },
    [allDocumentDetails],
  );

  const onPublish = () => {
    const selectedDocumentDetails = allDocumentDetails.filter((details) => selectedDocumentIds.includes(details.shapeId));
    return props.onPublish(selectedDocumentDetails);
  };

  // Can publish if some articles are selected for publishing and all selected articles have change description and name
  const canPublish =
    selectedDocumentIds.length > 0 &&
    selectedDocumentIds.every((id) => {
      const details = allDocumentDetails.find((details) => details.shapeId === id);
      if (!details) {
        return false;
      }

      return details.changeDescription.length >= MIN_CHANGE_DESCRIPTION_LENGTH && details.name.length >= MIN_NAME_LENGTH;
    });

  return (
    <Dialog open={open} onClose={onClose} TransitionComponent={DefaultDialogTransition} fullWidth maxWidth="sm">
      <DialogTitle variant="h5">{t("Components.PublishToDocumentsDialog.Title")}</DialogTitle>

      {allDocumentDetails.length > 0 ? (
        <DialogContent style={{ paddingBottom: 0 }}>
          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            {allDocumentDetails.map((details) => {
              const isDocumentSelected = selectedDocumentIds.includes(details.shapeId);
              return (
                <ListItem key={details.shapeId} sx={{ alignItems: "flex-start" }} disablePadding>
                  <ListItemIcon sx={{ minWidth: "auto" }}>
                    <Checkbox
                      edge="start"
                      checked={isDocumentSelected}
                      onChange={handleSelectionToggle(details.shapeId)}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ "aria-labelledby": `checkbox-list-label-${details.shapeId}` }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    sx={{ mt: 0.5, mb: 0.5 }}
                    primary={
                      isDocumentSelected ? (
                        <Box sx={{ display: "flex", flexDirection: "column", mt: 1 }}>
                          <TextField
                            id={`name-input-${details.shapeId}`}
                            placeholder={t("Components.PublishToDocumentsDialog.NameInputPlaceholder")}
                            value={details.name}
                            onChange={(e) => handleNameChange(details.shapeId, e.target.value)}
                            fullWidth
                          />
                        </Box>
                      ) : (
                        details.name || t("Components.PublishToDocumentsDialog.UntitledDocument")
                      )
                    }
                    primaryTypographyProps={{ fontWeight: "500", mt: 0.5, mb: 0.5 }}
                    secondary={
                      isDocumentSelected && (
                        <Box sx={{ display: "flex", flexDirection: "column", mt: 1 }}>
                          <TextField
                            id={`change-description-input-${details.shapeId}`}
                            placeholder={t("Components.PublishToDocumentsDialog.ChangeDescriptionInputPlaceholder")}
                            value={details.changeDescription}
                            onChange={(e) => handleChangeDescriptionChange(details.shapeId, e.target.value)}
                            multiline
                            rows={3}
                            fullWidth
                          />
                        </Box>
                      )
                    }
                    secondaryTypographyProps={{ component: "span" }}
                  />
                </ListItem>
              );
            })}
          </List>

          <DialogContentText variant="caption">{t("Components.PublishToDocumentsDialog.Tip")}</DialogContentText>
        </DialogContent>
      ) : (
        <DialogContent>
          <DialogContentText variant="caption">{t("Components.PublishToDocumentsDialog.NoDocuments")}</DialogContentText>
        </DialogContent>
      )}

      <DialogActions>
        <Button onClick={onClose}>{t("Global.Action.Cancel")}</Button>
        <Button onClick={onPublish} disabled={!canPublish}>
          {t("Components.PublishToDocumentsDialog.Publish")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
