import { AnalysisFieldTypeEnum, IAnalysisFieldConfig, ISelectInputFieldOption } from "@bigpi/cookbook";

import { MinMaxAggregate, TopicDiscussionSummaryExampleFieldGroupType } from "GraphQL/Generated/Apollo";

import { IAnalysisType } from "BoardComponents/Types";

/*******************************
 * Types
 *******************************/
type IAnalysisFieldGroupType = TopicDiscussionSummaryExampleFieldGroupType;

type IAnalysisFieldGroupFieldsType = keyof IAnalysisFieldGroupType;

type DistinctResultType = Record<IAnalysisFieldGroupFieldsType, Array<Partial<IAnalysisFieldGroupType>>>;

/*********************************
 * Formatting
 *********************************/
/**
 * Data formatting to be used in the toolbar
 */
const AnalysisToolbarDataFormatting = {
  [AnalysisFieldTypeEnum.String]: (
    value: Partial<IAnalysisFieldGroupType>,
    field: IAnalysisFieldGroupFieldsType,
    formatter: (value: string) => string,
  ): ISelectInputFieldOption => {
    const fieldValue = value[field] as string;
    const count = value.count;
    return {
      key: fieldValue as string,
      label: formatter ? formatter(fieldValue) : fieldValue,
      count: count,
    };
  },
  [AnalysisFieldTypeEnum.Date]: (value: MinMaxAggregate, field: IAnalysisFieldGroupFieldsType, formatter: (value: any) => any) =>
    value,
  [AnalysisFieldTypeEnum.Number]: (
    value: MinMaxAggregate,
    field: IAnalysisFieldGroupFieldsType,
    formatter: (value: any) => any,
  ) => value,
};

/**
 * Specific formatters for each analysis type & respective fields
 */
const AnalysisFormatter: Record<IAnalysisType, Record<string, any>> = {
  topicDiscussionSummaryAnalysis: {
    category: (value: string) => {
      return value.split(" - ")[1];
    },
  },
  topicDiscussionAnalysis: {},
  analystQuestionAnalysis: {},
  questionAnalysis: {},
  topicDiscussionInNewsArticleAnalysis: {},
};

/*********************************
 * Utils
 *********************************/
/**
 * Formats distinct values to be used in the toolbar select
 * String: { key: string, label: string, count: number }
 * Date: Will keep the min and max values
 *
 * @param analysisType Analysis type to which the distinct values belong
 * @param disctinctValues Distinct values to format
 * @param fieldsConfig Fields config to use for formatting
 * @returns Formatted distinct values
 */
export function transformToToolbarSelectOptions(
  analysisType: IAnalysisType,
  disctinctValues: DistinctResultType,
  fieldsConfig: Array<IAnalysisFieldConfig>,
) {
  const clonedDistinctValues = { ...disctinctValues };
  Object.keys(disctinctValues).forEach((field) => {
    const fieldConfig = fieldsConfig.find((config) => config.field === field);
    // Skip if field config is not found (this happens for any custom aggregations)
    if (!fieldConfig) {
      return;
    }

    if (fieldConfig.type === AnalysisFieldTypeEnum.String || fieldConfig.type === AnalysisFieldTypeEnum.ArrayOfStrings) {
      const dataFormatter = AnalysisToolbarDataFormatting[AnalysisFieldTypeEnum.String];
      const fieldFormatter = AnalysisFormatter[analysisType]?.[field];
      clonedDistinctValues[field as IAnalysisFieldGroupFieldsType] = clonedDistinctValues[
        field as IAnalysisFieldGroupFieldsType
      ].map((distinctValue) => {
        return dataFormatter(distinctValue, field as IAnalysisFieldGroupFieldsType, fieldFormatter);
      });
    }
  });

  return clonedDistinctValues;
}
