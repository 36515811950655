import { defineMigrations } from "@tldraw/store";

import { IBarChartShape } from "./IBarChartShape.js";

export const BarChartShapeVersions = {
  Initial: 0,
  // Removes `BarChart` from the field
  UpdateFieldProp: 1,
};

export const barChartShapeMigrations = defineMigrations({
  firstVersion: BarChartShapeVersions.Initial,
  currentVersion: BarChartShapeVersions.UpdateFieldProp,
  migrators: {
    [BarChartShapeVersions.UpdateFieldProp]: {
      up: (shape: IBarChartShape) => {
        const { field, ...rest } = shape.props;
        const formattedField = (field || "").replace("BarChart", "");
        return {
          ...shape,
          props: {
            ...rest,
            field: formattedField,
          },
        };
      },
      down: (shape: IBarChartShape) => {
        const { field, ...rest } = shape.props;

        return {
          ...shape,
          props: {
            ...rest,
            field: `${field || ""}BarChart`,
          },
        };
      },
    },
  },
});
