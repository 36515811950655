import { useContext } from "react";

import { BoardDatastoreContext } from "./BoardDatastoreContext";

export const useBoardDatastore = () => {
  const datastore = useContext(BoardDatastoreContext);
  if (!datastore) {
    throw new Error("No BoardDatastore found in context");
  }
  return datastore;
};
