import { getSchema } from "@tiptap/core";
import { generateHTML as generateHtml, generateJSON as generateJson } from "@tiptap/html";
import { prosemirrorJSONToYDoc, yDocToProsemirrorJSON } from "y-prosemirror";
import * as Y from "yjs";

import * as DocumentConstants from "../DocumentEditor/DocumentConstants.js";
// NOTE: This needs a direct import of EditorExtensions.ts, not the index.ts file. Otherwise it may break consumers that can't process CSS files from toolbar items.
import { getCommonEditorExtensions } from "../Extensions/EditorExtensions.js";

export function documentHtmlToJson(html: string): Record<string, any> {
  return generateJson(html, getCommonEditorExtensions());
}

export function documentHtmlToYDoc(html: string, documentField = DocumentConstants.DOCUMENT_YDOC_FIELD_KEY): Y.Doc {
  return prosemirrorJSONToYDoc(getSchema(getCommonEditorExtensions()), documentHtmlToJson(html), documentField);
}

export function yDocToDocumentHtml(yDoc: Y.Doc): string {
  return yDocKeyToDocumentHtml(yDoc, DocumentConstants.DOCUMENT_YDOC_FIELD_KEY);
}

export function yDocKeyToDocumentHtml(yDoc: Y.Doc, key: string): string {
  return generateHtml(yDocToProsemirrorJSON(yDoc, key), getCommonEditorExtensions());
}
