import { defineMigrations } from "@tldraw/store";

import { IHtmlDocumentShape } from "./IHtmlDocumentShape.js";

export const HtmlDocumentShapeVersions = {
  Initial: 0,
  RemoveOpacity: 1,
  AddCanScroll: 2,
  AddAsyncUpdateLock: 3,
};

export const htmlDocumentShapeMigrations = defineMigrations({
  firstVersion: HtmlDocumentShapeVersions.Initial,
  currentVersion: HtmlDocumentShapeVersions.AddAsyncUpdateLock,
  migrators: {
    [HtmlDocumentShapeVersions.RemoveOpacity]: {
      up: (shape: IHtmlDocumentShape & { props: { opacity: number } }) => {
        const { opacity, ...rest } = shape.props;
        return { ...shape, props: rest };
      },
      down: (shape: IHtmlDocumentShape) => {
        return {
          ...shape,
          props: { ...shape.props, opacity: true },
        };
      },
    },

    [HtmlDocumentShapeVersions.AddCanScroll]: {
      up: (shape: IHtmlDocumentShape) => {
        return {
          ...shape,
          props: { ...shape.props, canScroll: true },
        };
      },
      down: (shape: IHtmlDocumentShape & { canScroll: boolean }) => {
        const { canScroll, ...rest } = shape.props;
        return { ...shape, props: rest };
      },
    },

    [HtmlDocumentShapeVersions.AddAsyncUpdateLock]: {
      up: (shape: IHtmlDocumentShape) => {
        return {
          ...shape,
          props: { ...shape.props, asyncUpdateLock: false },
        };
      },
      down: (shape: IHtmlDocumentShape & { asyncUpdateLock: boolean }) => {
        const { asyncUpdateLock, ...rest } = shape.props;
        return { ...shape, props: rest };
      },
    },
  },
});
