import {
  ArrowDownToolbarItem,
  ArrowLeftToolbarItem,
  ArrowRightToolbarItem,
  ArrowToolbarItem,
  ArrowUpToolbarItem,
  AssetToolbarItem,
  CheckBoxToolbarItem,
  CloudToolbarItem,
  DefaultToolbar,
  DiamondToolbarItem,
  DrawToolbarItem,
  EllipseToolbarItem,
  EraserToolbarItem,
  FrameToolbarItem,
  HandToolbarItem,
  HexagonToolbarItem,
  HighlightToolbarItem,
  LaserToolbarItem,
  LineToolbarItem,
  OvalToolbarItem,
  RectangleToolbarItem,
  RhombusToolbarItem,
  SelectToolbarItem,
  StarToolbarItem,
  TextToolbarItem,
  TldrawUiMenuItem,
  TrapezoidToolbarItem,
  TriangleToolbarItem,
  XBoxToolbarItem,
  useIsToolSelected,
  useTools,
} from "@tldraw/tldraw";
import { memo } from "react";

import { useAddToBoard } from "BoardComponents/AddToBoard/useAddToBoard";

export function InteractToolbarItem() {
  const tools = useTools();
  const isSelected = useIsToolSelected(tools["interact"]);
  return <TldrawUiMenuItem {...tools["interact"]} isSelected={isSelected} />;
}

export function HtmlDocumentToolbarItem() {
  const tools = useTools();
  const isSelected = useIsToolSelected(tools["htmlDocument"]);
  return <TldrawUiMenuItem {...tools["htmlDocument"]} isSelected={isSelected} />;
}

export function AddToBoardToolbarItem(props: any) {
  const { setIsOpen } = useAddToBoard();
  return (
    <TldrawUiMenuItem
      id=""
      icon="tool-add-to-board"
      onSelect={() => {
        setIsOpen(true);
      }}
      label="tool.addToBoard"
    />
  );
}

/**
 * Cloned from https://github.com/tldraw/tldraw/blob/41b5fffa2ef17ff852c1efc227a5ad5c37dc5c7a/packages/tldraw/src/lib/ui/components/Toolbar/DefaultToolbarContent.tsx#L6
 * and made some modifications. Changes -
 *
 * - Added `InteractToolbarItem` and `HtmlDocumentToolbarItem` in the toolbar.
 * - Removed `NoteToolbarItem` from the toolbar.
 */
function ToolbarContent() {
  return (
    <>
      <SelectToolbarItem />
      <HandToolbarItem />

      {/* Modified by BigPi - Added `InteractToolbarItem` in the toolbar. */}
      <InteractToolbarItem />

      <DrawToolbarItem />
      <EraserToolbarItem />

      {/* Modified by BigPi - Added `HtmlDocumentToolbarItem` in the toolbar. */}
      <HtmlDocumentToolbarItem />

      <ArrowToolbarItem />
      <TextToolbarItem />
      <AddToBoardToolbarItem />
      {/* Modified by BigPi - Removed `NoteToolbarItem` from the toolbar.
      <NoteToolbarItem /> */}
      <AssetToolbarItem />
      <RectangleToolbarItem />
      <EllipseToolbarItem />
      <DiamondToolbarItem />
      <TriangleToolbarItem />
      <TrapezoidToolbarItem />
      <RhombusToolbarItem />
      <HexagonToolbarItem />
      <CloudToolbarItem />
      <StarToolbarItem />
      <OvalToolbarItem />
      <XBoxToolbarItem />
      <CheckBoxToolbarItem />
      <ArrowLeftToolbarItem />
      <ArrowUpToolbarItem />
      <ArrowDownToolbarItem />
      <ArrowRightToolbarItem />
      <LineToolbarItem />
      <HighlightToolbarItem />
      <FrameToolbarItem />
      <LaserToolbarItem />
    </>
  );
}

export const Toolbar = memo(function CustomToolbar() {
  return (
    <DefaultToolbar>
      <ToolbarContent />
    </DefaultToolbar>
  );
});
