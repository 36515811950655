import { IDateRange } from "@bigpi/cookbook";
import { defineMigrations } from "@tldraw/store";

import { IDataGridPreferences } from "../DataGrid/index.js";
import { IAnalystQuestionAnalysisSelection, IAnalystQuestionAnalysisShape } from "./IAnalystQuestionAnalysisShape.js";
import { IAnalystQuestionAnalysisFacets } from "./IAnalystQuestionAnalysisFacets.js";

export const AnalystQuestionAnalysisShapeVersions = {
  Initial: 0,
  AddBackgroundColorField: 1,
  AddPreferencesField: 2,
  RemoveDataGridPreferencesColumnVisibility: 3,
  AddPreferencesFacetSortModel: 4,
  AddStandardisedFacetsAndBounds: 5,
};

export const analystQuestionAnalysisShapeMigrations = defineMigrations({
  firstVersion: AnalystQuestionAnalysisShapeVersions.Initial,
  currentVersion: AnalystQuestionAnalysisShapeVersions.AddStandardisedFacetsAndBounds,
  migrators: {
    [AnalystQuestionAnalysisShapeVersions.AddBackgroundColorField]: {
      up: (shape: IAnalystQuestionAnalysisShape) => {
        return {
          ...shape,
          props: { ...shape.props, enableBackground: false },
        };
      },
      down: (shape: IAnalystQuestionAnalysisShape & { analysisType: string }) => {
        const { enableBackground, ...rest } = shape.props;
        return { ...shape, props: rest };
      },
    },
    [AnalystQuestionAnalysisShapeVersions.AddPreferencesField]: {
      up: (shape: IAnalystQuestionAnalysisShape) => {
        return {
          ...shape,
          props: { ...shape.props, preferences: {} },
        };
      },
      down: (shape: IAnalystQuestionAnalysisShape) => {
        const { preferences, ...rest } = shape.props;
        return { ...shape, props: rest };
      },
    },
    [AnalystQuestionAnalysisShapeVersions.RemoveDataGridPreferencesColumnVisibility]: {
      up: (
        shape: IAnalystQuestionAnalysisShape & { preferences: { dataGrid: { columnVisibility: Record<string, boolean> } } },
      ) => {
        const { dataGrid, ...rest } = shape.props.preferences;
        const dataGridPreferences = dataGrid as IDataGridPreferences & { columnVisibility?: Record<string, boolean> };
        if (dataGridPreferences) {
          delete dataGridPreferences.columnVisibility;
        }

        return {
          ...shape,
          props: {
            ...shape.props,
            preferences: {
              ...shape.props.preferences,
              dataGrid: dataGridPreferences,
            },
          },
        };
      },
      down: (shape: IAnalystQuestionAnalysisShape) => {
        return {
          ...shape,
          props: {
            ...shape.props,
            preferences: {
              ...shape.props.preferences,
              dataGrid: {
                ...shape.props.preferences.dataGrid,
                columnVisibility: {},
              },
            },
          },
        };
      },
    },
    [AnalystQuestionAnalysisShapeVersions.AddPreferencesFacetSortModel]: {
      up: (shape: IAnalystQuestionAnalysisShape) => {
        const { preferences, ...rest } = shape.props;
        const analysisPreferences = preferences.analysis || {};
        analysisPreferences.facetSort = [];
        return {
          ...shape,
          props: {
            ...rest,
            preferences: {
              ...preferences,
              analysis: analysisPreferences,
            },
          },
        };
      },
      down: (shape: IAnalystQuestionAnalysisShape) => {
        const { preferences, ...rest } = shape.props;
        delete preferences.analysis;
        return {
          ...shape,
          props: {
            ...rest,
            preferences,
          },
        };
      },
    },
    [AnalystQuestionAnalysisShapeVersions.AddStandardisedFacetsAndBounds]: {
      up: (
        shape: IAnalystQuestionAnalysisShape & {
          props: {
            facets: {
              eventDateBarChart?: IDateRange;
              firmBarChart?: Array<string>;
              firmTypeBarChart?: Array<string>;
              segmentBarChart?: Array<string>;
              topicsBarChart?: Array<string>;
              timeScale?: string;
            };
          };
        },
      ) => {
        const { facets, ...rest } = shape.props;

        let selectedFacetValues: IAnalystQuestionAnalysisFacets = {};
        let selection: IAnalystQuestionAnalysisSelection = {};

        if (facets) {
          if (facets.eventDateBarChart) {
            selectedFacetValues.eventDate = facets.eventDateBarChart;
          }

          if (facets.firmBarChart) {
            selectedFacetValues.firm = facets.firmBarChart;
          }

          if (facets.firmTypeBarChart) {
            selectedFacetValues.firmType = facets.firmTypeBarChart;
          }

          if (facets.segmentBarChart) {
            selectedFacetValues.segment = facets.segmentBarChart;
          }

          if (facets.topicsBarChart) {
            selectedFacetValues.topics = facets.topicsBarChart;
          }

          // Selection
          if (facets.timeScale) {
            selection.selectedTimeScale = facets.timeScale;
          }
        }

        return {
          ...shape,
          props: {
            ...rest,
            selectedFacetValues,
            toolbar: {
              availableFields: ["eventDate", "firm", "firmType", "segment", "topics"],
            },
            selection,
          },
        };
      },
      down: (shape: IAnalystQuestionAnalysisShape) => {
        const { preferences, selectedFacetValues, toolbar, selection, ...rest } = shape.props;

        let facets: {
          eventDateBarChart?: IDateRange;
          firmBarChart?: Array<string>;
          firmTypeBarChart?: Array<string>;
          segmentBarChart?: Array<string>;
          topicsBarChart?: Array<string>;
          timeScale?: string;
        } = {};

        // Reverse the latest selectedFacetValues to old facets
        if (selectedFacetValues) {
          if (selectedFacetValues.eventDate) {
            facets.eventDateBarChart = selectedFacetValues.eventDate;
          }

          if (selectedFacetValues.topics) {
            facets.topicsBarChart = selectedFacetValues.topics;
          }

          if (selectedFacetValues.firm) {
            facets.firmBarChart = selectedFacetValues.firm;
          }

          if (selectedFacetValues.firmType) {
            facets.firmTypeBarChart = selectedFacetValues.firmType;
          }

          if (selectedFacetValues.segment) {
            facets.segmentBarChart = selectedFacetValues.segment;
          }
        }

        if (preferences && preferences.analysis && preferences.analysis.showDataToDisplayInToolbar !== undefined) {
          delete preferences.analysis.showDataToDisplayInToolbar;
        }

        if (selection && selection.selectedTimeScale) {
          facets.timeScale = selection.selectedTimeScale;
        }

        return {
          ...shape,
          props: {
            ...rest,
            facets,
            preferences,
          },
        };
      },
    },
  },
});
