import { Box, Grid, Typography, Skeleton } from "@mui/material";

/**
 * Gives a skeleton loader for a data grid
 *
 * @returns Box with skeleton loaders
 */
export function DataGridLoader() {
  return (
    <Box>
      <Grid container sx={{ padding: 4 }}>
        {Array.from({ length: 10 }).map((v, i) => {
          return (
            <Grid container key={i}>
              {Array.from({ length: 10 }).map((v, i) => {
                return (
                  <Grid item xs={1} sx={{ padding: 1 }} key={i}>
                    <Typography variant="overline">
                      <Skeleton animation={false} />
                    </Typography>
                  </Grid>
                );
              })}
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
