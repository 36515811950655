import { IDocumentListItemProps, DocumentListItem } from "./DocumentListItem";

export type IDocumentListItemCompactProps = {
  secondaryAction?: (id: string) => IDocumentListItemProps["secondaryAction"];
} & Omit<IDocumentListItemProps, "checked" | "listItemIcon" | "onMouseEnter" | "onMouseLeave" | "secondaryAction">;

export function DocumentListItemCompact(props: IDocumentListItemCompactProps) {
  const { row, secondaryAction } = props;

  return <DocumentListItem {...props} checked={false} secondaryAction={secondaryAction ? secondaryAction(row.id) : undefined} />;
}
