import { WORKSPACE_TAG_MAX_LENGTH } from "@bigpi/cookbook";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormGroup from "@mui/material/FormGroup";
import TextField from "@mui/material/TextField";
import * as React from "react";
import { useTranslation } from "react-i18next";

import { DefaultDialogTransition } from "Components/DialogTransition/DefaultDialogTransition";
import { WorkspaceTagsList } from "Components/WorkspaceTagsList/WorkspaceTagsList";
import { useUpdateWorkspaceBoardMutation, useWorkspaceBoardQuery } from "GraphQL/Generated/Apollo";

export interface IUpdateWorkspaceBoardTagsDialogProps {
  onClose: () => void;
  open: boolean;
  workspaceBoardId: string;
}

export function UpdateWorkspaceBoardTagsDialog(props: IUpdateWorkspaceBoardTagsDialogProps) {
  const { open, workspaceBoardId } = props;

  const { t } = useTranslation();

  const { data: workspaceBoardData } = useWorkspaceBoardQuery({ variables: { id: workspaceBoardId } });
  const [updateWorkspaceBoard] = useUpdateWorkspaceBoardMutation();

  const [errorMessage, setErrorMessage] = React.useState("");
  const [workspaceName, setWorkspaceName] = React.useState("");
  const [tagsList, setTagsList] = React.useState<Array<string>>([]);
  const [newTag, setNewTag] = React.useState("");
  const [nameFocused, setNameFocused] = React.useState(true);

  const nameRef = React.useRef<HTMLInputElement>(null);

  function onClose() {
    setErrorMessage("");
    setWorkspaceName("");
    props.onClose();
  }

  function updateTags(updatedTagsList: Array<string>) {
    updateWorkspaceBoard({
      variables: {
        input: {
          id: workspaceBoardId,
          tags: updatedTagsList,
        },
      },
      refetchQueries: ["WorkspaceBoardTags", "Workspaces"],
    }).catch((error) => {
      console.error(error);
      setErrorMessage(t("Components.UpdateWorkspaceBoardTagsDialog.Error"));
    });
  }

  function onAddTag() {
    const newTagsList = [...tagsList, newTag];
    updateTags(newTagsList);
    setTagsList(newTagsList);
    setNewTag("");
  }

  const onRemoveTag = (tagToRemove: string) => {
    const newTagsList = tagsList.filter((tag) => tag !== tagToRemove);
    updateTags(newTagsList);
    setTagsList(newTagsList);
  };

  React.useEffect(() => {
    if (workspaceBoardData?.workspaceBoard?.name) {
      setWorkspaceName(workspaceBoardData.workspaceBoard.name);
    }
    setTagsList(workspaceBoardData?.workspaceBoard?.tags || []);
  }, [workspaceBoardData]);

  React.useEffect(() => {
    if (open && nameRef.current) {
      nameRef.current.focus();
    }
  }, [nameRef.current, open]);

  return (
    <>
      <Dialog open={open} onClose={onClose} TransitionComponent={DefaultDialogTransition} fullWidth maxWidth="xs">
        <DialogTitle variant="h5">{t("Components.UpdateWorkspaceBoardTagsDialog.Title", { name: workspaceName })}</DialogTitle>
        <DialogContent>
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
          <WorkspaceTagsList
            tagsList={tagsList}
            onRemoveTag={onRemoveTag}
            containerSxProps={{ mb: 1 }}
            highlightTemplateTag={false}
          />
          <FormGroup>
            <TextField
              autoFocus
              margin="dense"
              label={t("Components.UpdateWorkspaceBoardTagsDialog.NewTagLabel")}
              type="text"
              fullWidth
              variant="standard"
              value={newTag}
              onBlur={() => setNameFocused(false)}
              onChange={(e) => setNewTag(e.target.value ?? "")}
              onFocus={() => setNameFocused(true)}
              required
              inputRef={nameRef}
              error={workspaceName === "" && !nameFocused}
              inputProps={{ maxLength: WORKSPACE_TAG_MAX_LENGTH }}
            />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{t("Global.Action.Close")}</Button>
          <Button onClick={onAddTag} disabled={newTag === ""}>
            {t("Components.UpdateWorkspaceBoardTagsDialog.AddTag")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
