import { getFilePreviewShapeDefaultProps } from "@bigpi/tl-schema";
import { createShapeId } from "@tldraw/tldraw";
import { v4 as uuidV4 } from "uuid";

import { createShapesAtEmptyPoint } from "BoardComponents/Utils/CreateShapeUtils";
import { ChatMessageAction } from "Chat/ChatActionManagers/ChatMessageActions";
import { IWorkspaceBoardChatActionPlugInInputs } from "./IWorkspaceBoardChatActionPlugInInputs";
import { WorkspaceBoardChatActionPlugInBase } from "./WorkspaceBoardChatActionPlugInBase";

export class AddFilePreviewToBoardPlugIn extends WorkspaceBoardChatActionPlugInBase {
  // *********************************************
  // Public properties
  // *********************************************/
  /**
   * @inheritdoc
   */
  name = ChatMessageAction.AddFilePreviewToBoard;

  // *********************************************
  // Public methods
  // *********************************************/
  /**
   * @inheritdoc
   */
  public async execute(input: IWorkspaceBoardChatActionPlugInInputs): Promise<undefined | JSX.Element> {
    const { app, data } = input;

    if (!app) {
      console.warn(`No app instance for chat action ${this.name}`);
      return;
    }

    createShapesAtEmptyPoint(
      app,
      [
        {
          id: createShapeId(uuidV4()),
          type: "filePreview",
          props: {
            ...getFilePreviewShapeDefaultProps(),
            fileId: data.fileId,
            fileSourceType: data.fileSourceType,
          },
        },
      ],
      null,
    );

    return undefined;
  }
}
