import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { Level } from "@tiptap/extension-heading";
import type { Editor } from "@tiptap/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import type { IEditorConfig } from "../../Editor/index.js";
import { ToolbarItemBase } from "../../Toolbars/ToolbarItemBase.js";
import { capitalizeFirstLetter } from "../../Utils/ToolbarItemUtils.js";

const ITEM_NAME = "textStyle";

/**
 * A toolbar button that displays and sets text style.
 */
export class TextStyleSelectionItem extends ToolbarItemBase {
  // *********************************************
  // Public properties
  // *********************************************/
  /**
   * @inheritdoc
   */
  readonly name = ITEM_NAME;

  // *********************************************
  // Public methods
  // *********************************************/
  /**
   * @inheritdoc
   */
  create(editor: Editor, config: IEditorConfig) {
    return (
      <TextStyleSelection
        key={ITEM_NAME}
        setHeading={(level: Level) => {
          editor.chain().focus().setHeading({ level }).run();
        }}
        setTitle={() => {
          editor.chain().focus().setTitle().run();
        }}
        setParagraph={() => {
          editor.chain().focus().setParagraph().run();
        }}
        isActive={(command: "heading" | "title" | "paragraph", attributes?: {}) => editor.isActive(command, attributes)}
      />
    );
  }
}

enum TextStyle {
  TITLE = "Title",
  HEADING_1 = "Heading1",
  HEADING_2 = "Heading2",
  HEADING_3 = "Heading3",
  HEADING_4 = "Heading4",
  HEADING_5 = "Heading5",
  HEADING_6 = "Heading6",
  NORMAL = "Normal",
}

interface IAvailableTextStyle {
  style: TextStyle;
  label: string;
  className?: string;
}

const AvailableTextStyles: Array<IAvailableTextStyle> = [
  {
    style: TextStyle.NORMAL,
    label: `Editor.${capitalizeFirstLetter(ITEM_NAME)}.Style.${TextStyle.NORMAL}`,
  },
  {
    style: TextStyle.TITLE,
    label: `Editor.${capitalizeFirstLetter(ITEM_NAME)}.Style.${TextStyle.TITLE}`,
    className: "title",
  },
  {
    style: TextStyle.HEADING_1,
    label: `Editor.${capitalizeFirstLetter(ITEM_NAME)}.Style.${TextStyle.HEADING_1}`,
    className: "heading-1",
  },
  {
    style: TextStyle.HEADING_2,
    label: `Editor.${capitalizeFirstLetter(ITEM_NAME)}.Style.${TextStyle.HEADING_2}`,
    className: "heading-2",
  },
  {
    style: TextStyle.HEADING_3,
    label: `Editor.${capitalizeFirstLetter(ITEM_NAME)}.Style.${TextStyle.HEADING_3}`,
    className: "heading-3",
  },
  {
    style: TextStyle.HEADING_4,
    label: `Editor.${capitalizeFirstLetter(ITEM_NAME)}.Style.${TextStyle.HEADING_4}`,
    className: "heading-4",
  },
  {
    style: TextStyle.HEADING_5,
    label: `Editor.${capitalizeFirstLetter(ITEM_NAME)}.Style.${TextStyle.HEADING_5}`,
    className: "heading-5",
  },
  {
    style: TextStyle.HEADING_6,
    label: `Editor.${capitalizeFirstLetter(ITEM_NAME)}.Style.${TextStyle.HEADING_6}`,
    className: "heading-6",
  },
];

/**
 * Props for TextTypeSelection.
 */
export interface TextStyleSelectionProps {
  isActive: (command: "heading" | "title" | "paragraph", attributes?: {}) => boolean;
  setHeading: (level: Level) => void;
  setParagraph: () => void;
  setTitle: () => void;
}

/**
 * A toolbar selection for setting the text style.
 */
export const TextStyleSelection = (props: TextStyleSelectionProps) => {
  const { setHeading, setParagraph, setTitle, isActive } = props;

  const { t } = useTranslation();

  const [selectedTextType, setSelectedTextType] = useState<TextStyle>(TextStyle.NORMAL);

  if (isActive("heading", { level: 1 }) && selectedTextType !== TextStyle.HEADING_1) {
    setSelectedTextType(TextStyle.HEADING_1);
  } else if (isActive("heading", { level: 2 }) && selectedTextType !== TextStyle.HEADING_2) {
    setSelectedTextType(TextStyle.HEADING_2);
  } else if (isActive("heading", { level: 3 }) && selectedTextType !== TextStyle.HEADING_3) {
    setSelectedTextType(TextStyle.HEADING_3);
  } else if (isActive("heading", { level: 4 }) && selectedTextType !== TextStyle.HEADING_4) {
    setSelectedTextType(TextStyle.HEADING_4);
  } else if (isActive("heading", { level: 5 }) && selectedTextType !== TextStyle.HEADING_5) {
    setSelectedTextType(TextStyle.HEADING_5);
  } else if (isActive("heading", { level: 6 }) && selectedTextType !== TextStyle.HEADING_6) {
    setSelectedTextType(TextStyle.HEADING_6);
  } else if (isActive("paragraph") && selectedTextType !== TextStyle.NORMAL) {
    setSelectedTextType(TextStyle.NORMAL);
  } else if (isActive("title") && selectedTextType !== TextStyle.TITLE) {
    setSelectedTextType(TextStyle.TITLE);
  }

  const handleSelectionChange = (e: SelectChangeEvent<TextStyle>) => {
    const newTextType = e.target.value as TextStyle;
    setSelectedTextType(newTextType);

    switch (newTextType) {
      case TextStyle.TITLE:
        setTitle();
        break;
      case TextStyle.HEADING_1:
        setHeading(1);
        break;
      case TextStyle.HEADING_2:
        setHeading(2);
        break;
      case TextStyle.HEADING_3:
        setHeading(3);
        break;
      case TextStyle.HEADING_4:
        setHeading(4);
        break;
      case TextStyle.HEADING_5:
        setHeading(5);
        break;
      case TextStyle.HEADING_6:
        setHeading(6);
        break;
      default:
        setParagraph();
        break;
    }
  };

  return (
    <Box component="span">
      <FormControl variant="standard" sx={{ pl: 1, pr: 1, minWidth: 130 }}>
        <Select
          sx={{ fontSize: 14 }}
          inputProps={{ MenuProps: { disableScrollLock: true } }}
          id="text-type-select"
          disableUnderline
          value={selectedTextType}
          onChange={handleSelectionChange}
        >
          {AvailableTextStyles.map((ts) => (
            <MenuItem key={ts.style} value={ts.style} style={{ margin: 0 }} className={`editor toolbar ${ts.className}`}>
              <span style={{ paddingLeft: "4px" }}>{t(ts.label, { ns: "editor" })}</span>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
