import { escapeForRegEx } from "@tiptap/core";
import { ReactRenderer } from "@tiptap/react";
import { SuggestionProps, SuggestionKeyDownProps, SuggestionMatch, Trigger } from "@tiptap/suggestion";
import tippy, { Instance, GetReferenceClientRect } from "tippy.js";

import MentionList from "./MentionList.js";

export const UserSuggestions = {
  items: [],
  allowSpaces: true,
  findSuggestionMatch,
  render: () => {
    let component: ReactRenderer;
    let popup: Instance[];

    return {
      onStart: (props: SuggestionProps) => {
        component = new ReactRenderer(MentionList, {
          props,
          editor: props.editor,
        });

        if (!props.clientRect) {
          return;
        }

        // @ts-expect-error: See https://arethetypeswrong.github.io/?p=tippy.js%406.3.7
        popup = tippy("body", {
          getReferenceClientRect: props.clientRect as GetReferenceClientRect,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: "manual",
          placement: "bottom-start",
        });
      },

      onUpdate(props: SuggestionProps) {
        component.updateProps(props);

        if (!props.clientRect) {
          return;
        }

        popup[0].setProps({
          getReferenceClientRect: props.clientRect as GetReferenceClientRect,
        });
      },

      onKeyDown(props: SuggestionKeyDownProps) {
        if (props.event.key === "Escape") {
          popup[0].hide();

          return true;
        }
        // @ts-expect-error TODO: fix this
        return component.ref?.onKeyDown(props);
      },

      onExit() {
        if (!popup[0].state.isDestroyed) {
          popup[0].destroy();
        }

        if (component) {
          component.destroy();
        }
      },
    };
  },
};

// This following function overrides the default findSuggestionMatch function from here:
// https://github.com/ueberdosis/tiptap/blob/main/packages/suggestion/src/findSuggestionMatch.ts
/**
 * Finds suggestion match based on the given match.
 *
 * @param config Suggestions trigger configuration
 * @returns Suggestions match or null
 */
function findSuggestionMatch(config: Trigger): SuggestionMatch {
  const { char, allowSpaces, allowedPrefixes, startOfLine, $position } = config;

  const escapedChar = escapeForRegEx(char);
  const suffix = new RegExp(`\\s${escapedChar}$`);
  const prefix = startOfLine ? "^" : "";
  const regexp = allowSpaces
    ? new RegExp(`${prefix}${escapedChar}(?!${escapedChar}).*?(?=\\s${escapedChar}|$)`, "gm")
    : new RegExp(`${prefix}(?:^)?${escapedChar}(?!${escapedChar})[^\\s${escapedChar}]*`, "gm");

  const text = $position.nodeBefore?.isText && $position.nodeBefore.text;

  if (!text) {
    return null;
  }

  const textFrom = $position.pos - text.length;
  const match = Array.from(text.matchAll(regexp)).pop();

  if (!match || match.input === undefined || match.index === undefined) {
    return null;
  }

  // Ensure we don't match double characters
  if (match[0].startsWith(char + char)) {
    return null;
  }

  // JavaScript doesn't have lookbehinds. This hacks a check that first character
  // is a space or the start of the line
  const matchPrefix = match.input.slice(Math.max(0, match.index - 1), match.index);
  const matchPrefixIsAllowed = new RegExp(`^[${allowedPrefixes?.join("")}\0]?$`).test(matchPrefix);

  if (allowedPrefixes !== null && !matchPrefixIsAllowed) {
    return null;
  }

  // The absolute position of the match in the document
  const from = textFrom + match.index;
  let to = from + match[0].length;

  // Edge case handling; if spaces are allowed and we're directly in between
  // two triggers
  if (allowSpaces && suffix.test(text.slice(to - 1, to + 1))) {
    match[0] += " ";
    to += 1;
  }

  // If the $position is located within the matched substring, return that range
  if (from < $position.pos && to >= $position.pos) {
    return {
      range: {
        from,
        to,
      },
      query: match[0].slice(char.length),
      text: match[0],
    };
  }

  return null;
}
