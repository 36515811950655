import { ICommand } from "@bigpi/cookbook";
import { DoubtfirePresetsEnum } from "@bigpi/cookbook";
import {
  Editor,
  EditorContent,
  EditorOptions,
  EditorView,
  Extension,
  extractMentionsPlugin,
  ExtractMentionsPluginKey,
  MentionPluginKey,
  PrivateMentionPluginKey,
  ToolbarFactory,
  useEditor,
} from "@bigpi/editor-tiptap";
import { User } from "@bigpi/permission";
import { useAuthUser } from "@frontegg/react";
import CancelIcon from "@mui/icons-material/StopCircle";
import SendIcon from "@mui/icons-material/Send";
import { Box, Button, Tooltip, useTheme } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { ChatMessageChannelType, useGetLatestConfigsByPrefixQuery } from "GraphQL/Generated/Apollo";
import { useChatUsers } from "Chat/Hooks";
import ChatCommandsList from "../ChatPane/ChatCommandsList";
import { useCommandExecutor } from "Components/CommandManagers/useCommandExecutor";
import { outputTypeMapping } from "Components/Commands/DisplayOutputTemplates";
import {
  BistroCommandExtension,
  BistroCommandPluginKey,
  getBistroCommandSuggestionOptions,
} from "Editor/Extensions/BistroCommand";
import { getEditorExtensionsForChat } from "Editor/Extensions/Config";
import { useDoubtfireCleanup } from "Editor/Extensions/PasteContent/useDoubtfireCleanup";
import { useChatActionExecutor } from "Chat/ChatActionManagers/useChatActionExecutor";

// *********************************************
// Type/Interface, Constants, Enums
// *********************************************
export enum ChatMessageActionState {
  Cancel = "Cancel",
  CancelDisabled = "CancelDisabled",
  Send = "Send",
  SendDisabled = "SendDisabled",
}
export interface ChatMessageEditorProps {
  actionState: ChatMessageActionState;
  channelId: string;
  channelType: ChatMessageChannelType;
  onSend: (message: string) => void;
  onCancel: () => void;
}

/**
 * Custom extension to register Shift-Enter & runs enter command
 * From TipTap extensions we have "hardBreak" extension registered for Shift-Enter
 * We are excluding that in getCommonEditorExtensions and adding our custom extension
 */
const ShiftEnterShortCutExtension = Extension.create({
  addKeyboardShortcuts() {
    return {
      "Shift-Enter": ({ editor }) => {
        editor.commands.enter();
        return true;
      },
    };
  },
});

// *********************************************
// Component
// *********************************************
export function ChatMessageEditor(props: ChatMessageEditorProps) {
  const { channelId, channelType, actionState, onCancel, onSend } = props;
  const chatActionExecutor = useChatActionExecutor();
  const [message, setMessage] = useState<string>("");
  const [commands, setCommands] = useState<Array<ICommand>>([]);
  const [editorOptions, setEditorOptions] = useState<Partial<EditorOptions>>();
  const editor = useEditor(editorOptions);
  const theme = useTheme();
  const { t } = useTranslation();
  const editorRef = useRef<Editor | null>(editor);
  // Ref to keep track of number of user mention items appear in the suggestion list
  const userMentionItemsCountRef = useRef<number>(0);
  const commandExecutor = useCommandExecutor();

  /************ Data Queries ************/
  const user = useAuthUser();
  const allowedUsers = useChatUsers(channelId, channelType);
  const {
    data: commandsQueryResult,
    loading,
    error,
  } = useGetLatestConfigsByPrefixQuery({
    variables: {
      keyPrefix: "bistro-command-",
      organizationId: user.tenantId,
    },
    skip: !channelId,
  });

  /********** Hooks **********/
  useDoubtfireCleanup(editor, DoubtfirePresetsEnum.ChatMessageEditor);

  useEffect(() => {
    chatActionExecutor.chatEditor = editor || undefined;
  }, [editor]);

  const getUsers = useCallback(
    ({ query }: Partial<{ query: string }>): Promise<Array<any>> => {
      // Replace all whitespace characters with regular spaces
      const cleanedQuery = query?.replace(/\s/g, " ");
      return new Promise((resolve) => {
        const userNames = allowedUsers?.map((user) => user?.name || "") || [];
        const filteredUsers = userNames.filter((name) => name.toLowerCase().includes(cleanedQuery?.toLowerCase() || ""));
        userMentionItemsCountRef.current = filteredUsers.length;
        resolve(filteredUsers);
      });
    },
    [allowedUsers],
  );

  const getCommands = useCallback(
    ({ query }: Partial<{ query: string }>): Promise<Array<any>> => {
      // Replace all whitespace characters with regular spaces
      const cleanedQuery = query?.replace(/\s/g, " ");
      return new Promise((resolve) => {
        resolve((commands || []).filter((command) => command.name.toLowerCase().includes(cleanedQuery?.toLowerCase() || "")));
      });
    },
    [commands],
  );

  useEffect(() => {
    if (commandsQueryResult?.LatestConfigsByPrefix) {
      const commands: Array<ICommand> = [];
      commandsQueryResult.LatestConfigsByPrefix.forEach((config) => {
        // Handles in case of empty command
        const configData = config.data ? JSON.parse(config.data) : undefined;
        if (configData && !configData.disabled) {
          commands.push(configData);
        }
      });

      // Sort the commands by name
      commands.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
      setCommands(commands);
    }
  }, [commandsQueryResult]);

  useEffect(() => {
    editor?.on("update", ({ editor }: { editor: Editor }) => {
      setMessage(editor.getText());
    });
    editorRef.current = editor;

    // Register plugin
    if (editor && editor.view && !editor.isDestroyed && allowedUsers && allowedUsers.length > 0) {
      editor.registerPlugin(extractMentionsPlugin(allowedUsers as Array<User>));
    }

    return () => {
      if (editor && !editor.isDestroyed) {
        editor.off("update");
        editor.unregisterPlugin(ExtractMentionsPluginKey);
      }
    };
  }, [editor, allowedUsers]);

  const onSendMessage = useCallback(() => {
    const message = editorRef.current?.getHTML();
    if (message) {
      onSend(message);
      setMessage("");
      editorRef.current?.commands.clearContent();
    }
  }, [onSend]);

  const isSendingMessageAllowed = useCallback((view: EditorView) => {
    // Check if mention or bistrocommand is active
    if (
      (MentionPluginKey.getState(view.state).active && userMentionItemsCountRef.current > 0) ||
      BistroCommandPluginKey.getState(view.state).active ||
      (PrivateMentionPluginKey.getState(view.state).active && userMentionItemsCountRef.current > 0)
    ) {
      return false;
    }
    return true;
  }, []);

  const onRunCommand = useCallback(async () => {
    const message = editorRef.current?.getText();
    setMessage("");
    editorRef.current?.commands.clearContent();
    const commandName = message?.replace("/", "").trim();
    const command = commands.find((command) => command.name === commandName);
    // Call the executor to run the command and trigger the lifecycle events
    if (command) {
      const selectedOutputValues = outputTypeMapping[command.outputTypes[0]];
      await commandExecutor.executeCommand(command, {
        callerData: JSON.stringify({ outputType: selectedOutputValues.outputType }),
        outputTemplate: selectedOutputValues.outputTemplate,
      });
    }
  }, [commands]);

  const onSendClick = useCallback(() => {
    if (editorRef.current?.getText().startsWith("/")) {
      onRunCommand();
    } else {
      onSendMessage();
    }
  }, [onSendMessage, onRunCommand]);

  const onKeyDown = useCallback(
    (view: EditorView, event: KeyboardEvent) => {
      const canSendMessage = actionState === ChatMessageActionState.Send && isSendingMessageAllowed(view);
      if (canSendMessage && event.key === "Enter" && !event.shiftKey) {
        // Disable default "Enter" behavior and send message
        onSendClick();
        event.stopPropagation();
        event.preventDefault();
      }
    },
    [actionState, isSendingMessageAllowed, onSendClick],
  );

  useEffect(() => {
    setEditorOptions({
      // Disabled: Built-in autofocus goes to end of document due to async load of data
      autofocus: true,
      editable: true,
      extensions: [
        ShiftEnterShortCutExtension,
        BistroCommandExtension.configure({
          HTMLAttributes: {
            class: "bistro-command",
          },
          suggestion: {
            ...getBistroCommandSuggestionOptions({
              component: ChatCommandsList,
            }),
            items: channelId ? getCommands : undefined,
          },
        }),
        ...getEditorExtensionsForChat(
          {
            Mention: {
              items: getUsers,
            },
          },
          [],
        ),
      ],
      editorProps: {
        handleDOMEvents: {
          keydown: onKeyDown,
        },
      },
    });
  }, [channelId, getUsers, getCommands, onKeyDown]);

  const cancelDisabled = actionState !== ChatMessageActionState.Cancel;
  const isMessageEmpty = !message || message.trim().length === 0;
  const sendDisabled = actionState !== ChatMessageActionState.Send || isMessageEmpty;

  if (editor && editorOptions) {
    const fixedToolbar = ToolbarFactory.createToolbar(
      editor,
      { editorOptions, extensionOptions: {}, toolbarOptions: { tooltipPlacement: "top" } },
      ["bold", "italic", "strike", "underline", "|", "bulletList", "orderedList", "|", "link"],
    );

    return (
      <Box
        sx={{
          backgroundColor: `${theme.palette.background.paper}`,
          marginLeft: 2,
          marginRight: 2,
          marginBottom: 2,
          height: "auto",
          display: "flex",
          flexDirection: "column",
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: 2,
          cursor: "text",
          zIndex: 2,
        }}
        onMouseDown={_onMouseDown}
      >
        <Box sx={{ backgroundColor: `${theme.palette.grey[200]}` }}>{fixedToolbar}</Box>
        <EditorContent
          editor={editor}
          className="astra document"
          style={{ minHeight: "50px", width: "100%", padding: "10px 15px", maxHeight: "300px", overflowY: "auto" }}
        />
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
          <Button
            disabled={message?.length > 0}
            sx={{ border: "solid 1px", minWidth: "20px", marginLeft: 1, minHeight: "10px", height: "20px", width: "20px" }}
            onClick={_onSlash}
          >
            /
          </Button>
          {(actionState === ChatMessageActionState.Send || actionState === ChatMessageActionState.SendDisabled) && (
            <Tooltip
              title={isMessageEmpty ? t("Components.Chat.MessageEditor.EmptyMessage") : t("Components.Chat.MessageEditor.Send")}
            >
              {/* span is required to enable tooltip on disabled button */}
              <span>
                <Button disabled={sendDisabled} onClick={onSendClick}>
                  <SendIcon fontSize="small" color={sendDisabled ? "disabled" : "primary"} />
                </Button>
              </span>
            </Tooltip>
          )}
          {(actionState === ChatMessageActionState.Cancel || actionState === ChatMessageActionState.CancelDisabled) && (
            <Tooltip
              title={
                actionState === ChatMessageActionState.CancelDisabled
                  ? t("Components.Chat.MessageEditor.Busy")
                  : t("Components.Chat.MessageEditor.Cancel")
              }
            >
              {/* span is required to enable tooltip on disabled button */}
              <span>
                <Button disabled={cancelDisabled} onClick={onCancel}>
                  <CancelIcon fontSize="small" color={cancelDisabled ? "disabled" : "primary"} />
                </Button>
              </span>
            </Tooltip>
          )}
        </Box>
      </Box>
    );
  } else {
    return <></>;
  }

  // *********************************************
  // Callbacks/Event handlers
  // *********************************************
  /**
   * Auto focus on editor when click anywhere on editor region
   */
  function _onMouseDown() {
    if (!editor?.isFocused && message?.length === 0) {
      editor?.commands.focus("start");
    }
  }

  function _onSlash() {
    editor?.commands.setContent("/", true);
    editor?.commands.focus("end");
  }
}
