import { IAnalysisFieldConfig, AnalysisFieldTypeEnum } from "@bigpi/cookbook";

export const topicDiscussionInNewsArticlesAnalysisConfig: Array<IAnalysisFieldConfig> = [
  {
    field: "date",
    label: "Components.Analyses.TopicDiscussionInNewsArticleAnalysis.Fields.Date",
    type: AnalysisFieldTypeEnum.Date,
  },
  {
    field: "theme",
    label: "Components.Analyses.TopicDiscussionInNewsArticleAnalysis.Fields.Theme",
    type: AnalysisFieldTypeEnum.String,
    isSelectAllEnabled: true,
    isSortEnabled: true,
    isBoundsEnabled: true,
    isShowInToolbarEnabled: false,
  },
  {
    field: "topic",
    label: "Components.Analyses.TopicDiscussionInNewsArticleAnalysis.Fields.Topic",
    type: AnalysisFieldTypeEnum.String,
    isSelectAllEnabled: true,
    isSortEnabled: true,
    isClientSide: true,
  },
];
