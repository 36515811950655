import { StateNode, TLEventHandlers } from "@tldraw/tldraw";

export class PointingCanvas extends StateNode {
  static override id = "pointing_canvas";

  override onPointerUp: TLEventHandlers["onPointerUp"] = () => {
    this._complete();
  };

  override onCancel: TLEventHandlers["onCancel"] = () => {
    this._complete();
  };

  override onComplete: TLEventHandlers["onComplete"] = () => {
    this._complete();
  };

  override onInterrupt = () => {
    this._complete();
  };

  private _complete() {
    this.parent.transition("idle", {});
  }
}
