import { Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { DefaultDialogTransition } from "Components/DialogTransition/DefaultDialogTransition";
import {
  useBulkDeleteFileMutation,
  useBulkDeleteBundleMutation,
  useBundlesByIdsQuery,
  useFilesByIdsQuery,
} from "GraphQL/Generated/Apollo";

export interface IBulkDeleteBundleAndFileDialogProps {
  bundleIds: Array<string>;
  fileIds: Array<string>;
  onClose: () => void;
  open: boolean;
}

/**
 * Dialog for bulk deleting files & bundles.
 */
export function BulkDeleteBundleAndFileDialog(props: IBulkDeleteBundleAndFileDialogProps) {
  const { bundleIds, open, fileIds } = props;
  const { t } = useTranslation();

  const [bulkDeleteFile] = useBulkDeleteFileMutation();
  const [bulkDeleteBundle] = useBulkDeleteBundleMutation();
  const { data, loading, error } = useFilesByIdsQuery({ variables: { ids: fileIds } });
  const { data: bundleNames, loading: bundleNamesLoading } = useBundlesByIdsQuery({ variables: { ids: bundleIds } });

  // State
  const [filteredFileIds, setFilteredFileIds] = useState<Array<string>>([]);
  const [fileNames, setFileNames] = useState<Array<string>>([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [warningMessage, setWarningMessage] = useState("");

  function onClose() {
    setErrorMessage("");
    props.onClose();
  }

  const onDelete = useCallback(async () => {
    try {
      if (filteredFileIds.length > 0) {
        const result = await bulkDeleteFile({
          variables: {
            input: {
              ids: filteredFileIds,
            },
          },
          refetchQueries: ["Files", "FileBundleView"],
        });

        if (result.data?.bulkDeleteFile.count === filteredFileIds.length) {
          props.onClose();
        } else {
          setWarningMessage(t("Components.BulkDeleteBundleAndFileDialog.FilePartialError"));
        }
      }

      if (bundleIds.length > 0) {
        const result = await bulkDeleteBundle({
          variables: {
            input: {
              ids: bundleIds,
            },
          },
          refetchQueries: ["Files", "FileBundleView"],
        });

        if (result.data?.bulkDeleteBundle.count === bundleIds.length) {
          props.onClose();
        } else {
          setWarningMessage(t("Components.BulkDeleteBundleAndFileDialog.BundlePartialError"));
        }
      }
    } catch (error) {
      console.error(error);
      setErrorMessage(t("Components.BulkDeleteBundleAndFileDialog.Error"));
    }
  }, [bundleIds, bulkDeleteFile, bulkDeleteBundle, filteredFileIds, props.onClose]);

  useEffect(() => {
    // Sort the filenames alphabetically
    if (data?.filesByIds) {
      // Filter out files that are part of the bundle (let bundle display them)
      const filteredFiles =
        data?.filesByIds?.filter((file) => !(file.bundleFile.length > 0 && bundleIds.includes(file.bundleFile[0].bundleId))) ||
        [];
      setFileNames(filteredFiles.map((file) => file.name).sort((a, b) => a.localeCompare(b, undefined, { sensitivity: "base" })));

      setFilteredFileIds(filteredFiles.map((file) => file.id));
    }

    // Check if we have the same number of files as we have filenames
    if (data && data.filesByIds && data.filesByIds.length !== fileIds.length) {
      setWarningMessage(t("Components.BulkDeleteBundleAndFileDialog.MismatchWarning"));
    }
  }, [bundleIds, data, fileIds, t]);

  let title = "";
  if (bundleIds.length > 0 && fileIds.length > 0) {
    title = t("Components.BulkDeleteBundleAndFileDialog.FileAndBundleTitle", {
      fileCount: fileIds.length,
      bundleCount: bundleIds.length,
    });
  } else if (bundleIds.length > 0) {
    title = t("Components.BulkDeleteBundleAndFileDialog.BundleTitle", { count: bundleIds.length });
  } else {
    title = t("Components.BulkDeleteBundleAndFileDialog.FileTitle", { count: fileIds.length });
  }

  let tip = "";
  if (bundleIds.length > 0 && fileIds.length > 0) {
    tip = t("Components.BulkDeleteBundleAndFileDialog.FileAndBundleTip");
  } else if (bundleIds.length > 0) {
    tip = t("Components.BulkDeleteBundleAndFileDialog.BundleTip");
  } else {
    tip = t("Components.BulkDeleteBundleAndFileDialog.FileTip");
  }

  return (
    <>
      <Dialog open={open} onClose={onClose} TransitionComponent={DefaultDialogTransition} fullWidth maxWidth="sm">
        <DialogTitle>{title}</DialogTitle>
        <DialogContent dividers>
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
          {warningMessage && <Alert severity="warning">{warningMessage}</Alert>}
          <DialogContentText>{tip} </DialogContentText>
          {bundleIds.length > 0 && (
            <>
              <Typography variant="subtitle2">{t("Components.BulkDeleteBundleAndFileDialog.BundleHeader")}</Typography>
              <ul>
                {bundleNames?.bundlesByIds?.map((bundle, index) => {
                  return (
                    <li key={index}>
                      <Typography variant="subtitle2">{bundle.name}</Typography>
                      <ul>
                        {bundle.bundleFile?.map((bundleFile) => {
                          return <li>{bundleFile.file.name}</li>;
                        })}
                      </ul>
                    </li>
                  );
                })}
              </ul>
            </>
          )}
          {fileNames.length > 0 && (
            <>
              <Typography sx={{ mt: 1 }} variant="subtitle2">
                {t("Components.BulkDeleteBundleAndFileDialog.FileHeader")}
              </Typography>
              <ul>
                {fileNames.map((filename, index) => (
                  <li key={index}>{filename}</li>
                ))}
              </ul>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{t("Global.Action.Close")}</Button>
          <Button onClick={onDelete} disabled={loading || bundleNamesLoading} color="danger">
            {t("Global.Action.DeletePermanently")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
