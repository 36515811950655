import { WORKSPACE_TEMPLATE_TAG } from "@bigpi/cookbook";
import { Button, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useWorkspacesQuery } from "GraphQL/Generated/Apollo";
import { WorkspaceTemplateGridItem } from "./WorkspaceTemplateGridItem";
import { WorkspaceBlankGridItem } from "./WorkspaceBlankGridItem";

const DEFAULT_PAGE_SIZE = 20;

export interface IWorkspaceTemplateGridProps {
  selectedTemplateId: string | null;
  onSelectTemplate: (templateId: string | null) => void;
  blankEnabled: boolean;
}

export function WorkspaceTemplateGrid(props: IWorkspaceTemplateGridProps) {
  const { selectedTemplateId, onSelectTemplate, blankEnabled } = props;

  const { t } = useTranslation();
  const [offset, setOffset] = useState(0);
  const [orderBy, setOrderBy] = useState<Record<string, string>>({ updatedAt: "desc" });
  const { data, loading, fetchMore, error } = useWorkspacesQuery({
    variables: {
      limit: DEFAULT_PAGE_SIZE,
      offset,
      orderBy,
      filters: {
        tags: [WORKSPACE_TEMPLATE_TAG],
      },
    },
    fetchPolicy: "network-only",
  });

  const rows = data?.workspaces || [];
  const count = data?.workspaceAggregate.count || 0;

  return (
    <>
      <Typography variant="body1" sx={{ mt: 2, mb: 2 }}>
        {t("Components.WorkspaceTemplateGrid.StartWithTemplate")}
      </Typography>

      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        <Box sx={{ display: "flex", flexGrow: 1, p: 2, flexDirection: "column", justifyContent: "center" }}>
          {loading && <Typography variant="caption">{t("Global.Status.Loading")}</Typography>}
          {!loading && (
            <Grid container spacing={{ xs: 1, sm: 2, md: 3 }} alignItems="stretch">
              {blankEnabled && (
                <WorkspaceBlankGridItem selectedTemplateId={selectedTemplateId} onSelectTemplate={onSelectTemplate} />
              )}
              {rows.map((row) => {
                return (
                  <WorkspaceTemplateGridItem
                    key={row.id}
                    selectedTemplateId={selectedTemplateId}
                    onSelectTemplate={onSelectTemplate}
                    workspace={row}
                  />
                );
              })}
            </Grid>
          )}
          {count > rows.length && (
            <Button
              sx={{ mt: 2, alignSelf: "center", maxWidth: "150px" }}
              onClick={() => {
                fetchMore({
                  variables: {
                    offset: rows.length,
                  },
                });
              }}
            >
              {t("Components.WorkspaceTemplateGrid.ShowMore")}
            </Button>
          )}
          {!blankEnabled && data?.workspaces.length === 0 && !error && (
            <Typography variant="caption" color="textSecondary">
              {t("Components.WorkspaceTemplateGrid.NoItems")}
            </Typography>
          )}
          {error && (
            <Typography variant="caption" color="error.main">
              {t("Components.WorkspaceTemplateGrid.LoadError")}
            </Typography>
          )}
        </Box>
      </Box>
    </>
  );
}
