import { Box, CircularProgress, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useDistilledTranscriptQuery } from "GraphQL/Generated/Apollo";
import { QuestionsMasterDetails } from "./QuestionsMasterDetails";
import { useEffect } from "react";

const AUTO_REFRESH_TIMEOUT = 10000;

export interface ITranscriptQuestionsGridProps {
  transcriptId: string;
}

export function TranscriptQuestionsGrid(props: ITranscriptQuestionsGridProps) {
  const { transcriptId } = props;

  const {
    data: distilledTranscriptData,
    error: distilledTranscriptError,
    loading: distilledTranscriptLoading,
    startPolling,
    stopPolling,
  } = useDistilledTranscriptQuery({
    variables: {
      transcriptId,
    },
  });
  const { t } = useTranslation();

  // Manage polling depending on whether data is available or not
  useEffect(() => {
    if (distilledTranscriptData?.distilledTranscript === null) {
      startPolling(AUTO_REFRESH_TIMEOUT);
    } else {
      stopPolling();
    }
  }, [distilledTranscriptData]);

  return (
    <>
      {distilledTranscriptLoading ? (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : distilledTranscriptError ? (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6" gutterBottom>
            {t("Components.QuestionsView.FetchFailureMessage")}
          </Typography>
        </Box>
      ) : (
        <Box>
          <QuestionsMasterDetails
            transcriptId={transcriptId}
            distilledTranscriptData={distilledTranscriptData?.distilledTranscript}
          />
        </Box>
      )}
    </>
  );
}
