import { ApolloError } from "@apollo/client";
import { Star, ViewCozy } from "@mui/icons-material";
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { Box } from "@mui/material";
import { Typography } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { WorkspaceBoardListMenu } from "./WorkspaceBoardListMenu";
import { WorkspaceTagsList } from "Components/WorkspaceTagsList/WorkspaceTagsList";
import { WorkspaceBoardsQuery, useWorkspaceQuery } from "GraphQL/Generated/Apollo";

export interface IWorkspaceBoardListProps {
  error?: ApolloError;
  loading: boolean;
  rows: WorkspaceBoardsQuery["workspaceBoards"];
  workspaceId: string;
}

export function WorkspaceBoardList(props: IWorkspaceBoardListProps) {
  const { loading, rows, error, workspaceId } = props;

  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {}, []);

  const { data: workspaceData } = useWorkspaceQuery({
    variables: { id: workspaceId },
  });
  const defaultWorkspaceBoardId = workspaceData?.workspace?.defaultWorkspaceBoard?.id;

  return (
    <>
      {loading && <Typography variant="caption">{t("Global.Status.Loading")}</Typography>}
      {!loading && rows.length > 0 && (
        <List component="nav" sx={{ maxWidth: "960px", display: "flex", flexDirection: "column", flex: 1 }}>
          {rows.map((row) => {
            return (
              <ListItem
                key={row.id}
                secondaryAction={<WorkspaceBoardListMenu workspaceBoardId={row.id} workspaceId={row.workspaceId} />}
                disablePadding
              >
                <ListItemButton
                  onClick={() => {
                    navigate(`/workspaces/${row.workspaceId}/boards/${row.id}`, { replace: true });
                  }}
                >
                  <ListItemIcon>
                    {defaultWorkspaceBoardId && defaultWorkspaceBoardId === row.id ? (
                      <Star className="workspace-board-card-avatar" />
                    ) : (
                      <ViewCozy className="workspace-board-card-avatar" />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={row.name}
                    primaryTypographyProps={{ fontWeight: "500" }}
                    secondary={
                      <Box sx={{ display: "flex", flexDirection: "column" }} component="span">
                        <Box sx={{ display: "flex", flexWrap: "wrap" }} component="span">
                          <WorkspaceTagsList tagsList={row.tags} highlightTemplateTag={false} />
                        </Box>
                      </Box>
                    }
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      )}
      {!loading && !error && rows.length === 0 && (
        <Typography variant="caption" color="textSecondary">
          {t("Components.WorkspaceBoardList.NoItems")}
        </Typography>
      )}
      {error && (
        <Typography variant="caption" color="error.main">
          {t("Components.WorkspaceBoardList.LoadError")}
        </Typography>
      )}
    </>
  );
}
