import { Extension } from "@tiptap/core";
import { TextAlign as TipTapTextAlign, TextAlignOptions } from "@tiptap/extension-text-align";

import { getTextAlignment } from "../../Utils/CommandUtil.js";

export const TextAlign = TipTapTextAlign.extend({
  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          textAlign: {
            default: this.options.defaultAlignment,
            parseHTML: (element) => {
              // Class name are in pattern of "horizontal-align-{alignment}"
              const textAlign = getTextAlignment(element.className);
              return textAlign || element.style.textAlign || this.options.defaultAlignment;
            },
            renderHTML: (attributes) => {
              if (attributes.textAlign === this.options.defaultAlignment) {
                return {};
              }

              return { class: `horizontal-align-${attributes.textAlign}` };
            },
          },
        },
      },
    ];
  },
}).configure({
  // textAlign is a global attribute, so it can be applied to any node.
  // We are configuring it to be applied to heading, paragraph and title nodes.
  types: ["heading", "paragraph", "title"],
  // We need the type assertion here because the `configure` method is not typed correctly and it
  // always returns `Extension<any, any>`.
}) as Extension<TextAlignOptions>;
