import { EditorAuthError, EditorContentError } from "@bigpi/editor-tiptap";
import { Box } from "@mui/material";
import type { Editor } from "@tiptap/react";
import * as React from "react";
import { useTranslation } from "react-i18next";

import { PageMargin, PageOrientation, PageSize, getPageClassNames } from "../Editor/index.js";
import { DocumentEditor } from "./DocumentEditor.js";

import "./Collaboration.css";

export interface CollaborativeDocumentEditorProps {
  authError: EditorAuthError | null;
  contentError: EditorContentError | null;
  collaborativeEditor: Editor | null;
  containerClassName?: string;
  documentClassName?: string;
  isAuthLoading: boolean;
  pageMargin: PageMargin;
  pageOrientation: PageOrientation;
  pageSize: PageSize;
}

export const CollaborativeDocumentEditor = (props: CollaborativeDocumentEditorProps) => {
  const {
    authError,
    contentError,
    collaborativeEditor,
    containerClassName,
    documentClassName,
    isAuthLoading,
    pageMargin,
    pageOrientation,
    pageSize,
  } = props;

  const { t } = useTranslation();

  // Get the classes that are applied to the page by the editor in case we need to show a similar layout without the editor
  const pageClassNames = getPageClassNames(pageSize, pageOrientation, pageMargin, documentClassName);
  if (!collaborativeEditor) {
    // TODO: Show a loading indicator here or use translation for "Loading..."
    return (
      <Box className={`${containerClassName || ""}`}>
        <Box className={`${pageClassNames}`}>
          {isAuthLoading && !authError && <p>...</p>}
          {authError && <p style={{ color: "red" }}>{t(`Editor.AuthError.${authError}`)}</p>}
          {contentError && <p style={{ color: "red" }}>{t(`Editor.ContentError.${contentError}`)}</p>}
        </Box>
      </Box>
    );
  } else {
    if (authError) {
      return (
        <Box className={`${containerClassName || ""}`}>
          <Box className={`${pageClassNames}`}>
            <p style={{ color: "red" }}>{t(`Editor.AuthError.${authError}`)}</p>
          </Box>
        </Box>
      );
    } else if (contentError) {
      return (
        <Box className={`${containerClassName || ""}`}>
          <Box className={`${pageClassNames}`}>
            <p style={{ color: "red" }}>{t(`Editor.ContentError.${contentError}`)}</p>
          </Box>
        </Box>
      );
    } else {
      return (
        <DocumentEditor
          containerClassName={containerClassName}
          editor={collaborativeEditor}
          documentClassName={documentClassName}
          pageMargin={pageMargin}
          pageOrientation={pageOrientation}
          pageSize={pageSize}
        />
      );
    }
  }
};

export default CollaborativeDocumentEditor;
