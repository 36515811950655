import { SortKeys, ViewType } from "@bigpi/cookbook";
import { Box, FormControl, IconButton, InputAdornment, OutlinedInput } from "@mui/material";
import { SearchOutlined, List, GridViewOutlined } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";

import { PaginationOptions } from "./PaginationOptions";
import { SortOptionList } from "./SortOptionList";
import { Tags } from "./Tags";

// *********************************************
// Types/Interfaces/Constants/Enums
// *********************************************/
export interface IContentFilterPanelProps {
  isViewTypeEnabled?: boolean;
  onPaginationValueChange: (value: number) => void;
  onSearchValueChange: (value: string) => void;
  onSortOptionChange: (sortOption: SortKeys) => void;
  onTagsChange: (tags: Array<string>) => void;
  onViewTypeChange: (viewType: ViewType) => void;
  pageSize: number;
  searchLabel: string;
  selectedSortOption: SortKeys;
  selectedTags: Array<string>;
  sortOptions: Array<SortKeys>;
  tags: Array<string>;
  viewType: ViewType;
}

const CONTENT_SEARCH_DEBOUNCE_DELAY = 400;

// *********************************************
// Component
// *********************************************/
export function ContentFilterPanel(props: IContentFilterPanelProps) {
  const {
    isViewTypeEnabled,
    onPaginationValueChange,
    onSearchValueChange,
    onSortOptionChange,
    onTagsChange,
    onViewTypeChange,
    pageSize,
    searchLabel,
    selectedSortOption,
    selectedTags,
    sortOptions,
    tags,
    viewType,
  } = props;
  const [searchTerm, setSearchTerm] = useState<string>("");

  const onExecuteContentSearchDebounced = useDebouncedCallback(onSearchValueChange, CONTENT_SEARCH_DEBOUNCE_DELAY);

  // Debounce search input
  useEffect(() => {
    onExecuteContentSearchDebounced(searchTerm);
  }, [onExecuteContentSearchDebounced, searchTerm]);

  return (
    <Box>
      {/* Search */}
      <Box sx={{ display: "flex", pb: 3, justifyContent: "center" }}>
        <FormControl fullWidth sx={{ p: 0 }}>
          <OutlinedInput
            startAdornment={
              <InputAdornment position="start">
                <SearchOutlined />
              </InputAdornment>
            }
            placeholder={searchLabel}
            onChange={(e) => setSearchTerm(e.target.value)}
            slotProps={{
              input: { style: { paddingTop: 8, paddingBottom: 8 } },
            }}
          />
        </FormControl>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
        {/* Sort & Tags */}
        <Box>
          <SortOptionList
            onSortOptionChange={onSortOptionChange}
            sortOptions={sortOptions}
            selectedSortOption={selectedSortOption}
          />
          {/* <Tags onTagsChange={onTagsChange} selectedTags={selectedTags} tags={tags} /> */}
        </Box>
        {/* Pagination & View Type */}
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <PaginationOptions pageSize={pageSize} onPaginationValueChange={onPaginationValueChange} />
          {isViewTypeEnabled && (
            <Box>
              <IconButton
                color={viewType === ViewType.List ? "primary" : "default"}
                size="medium"
                onClick={() => onViewTypeChange(ViewType.List)}
              >
                <List />
              </IconButton>

              <IconButton
                color={viewType === ViewType.Grid ? "primary" : "default"}
                size="medium"
                onClick={() => onViewTypeChange(ViewType.Grid)}
              >
                <GridViewOutlined />
              </IconButton>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}
