import { ApolloClient } from "@apollo/client";
import { RawCommands } from "@tiptap/core";
import { TFunction } from "i18next";
import { toast } from "react-toastify";

import { IMAGE_TOKEN_QUERY } from "GraphQL/Token/Query";
import { getRenderedContent } from "Utils/ClipboardUtils";

// We need to declare our custom commands so that TS doesn't throw warning everywhere saying these commands do not exist.
declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    exportToWord: {
      exportToWord: (
        t: TFunction,
        apolloClient: ApolloClient<object>,
        exportHtmlToWord?: (htmlContent: string) => Promise<{ documentName: string; blob: Blob }>,
      ) => ReturnType;
    };
  }
}

function createLink(content: Blob, filename: string) {
  const element = document.createElement("a");

  element.href = window.URL.createObjectURL(content);
  element.download = filename;
  element.click();
  element.remove();
}

export const exportToWord: RawCommands["exportToWord"] =
  (
    t: TFunction,
    apolloClient: ApolloClient<object>,
    exportHtmlToWord?: (htmlContent: string) => Promise<{ documentName: string; blob: Blob }>,
  ) =>
  ({ editor }) => {
    if (exportHtmlToWord) {
      const { imageToken } = apolloClient.readQuery({ query: IMAGE_TOKEN_QUERY });
      if (!imageToken.token) {
        toast.error(t("Components.Document.DataPreparationError") as string);
        return false;
      }

      const editorContent = editor.getHTML();
      let formattedHtmlContent = `<html><body>${getRenderedContent(editorContent, imageToken.token, false).html}</body></html>`;

      // TODO: HOTFIX: Temporary fix for missing paragraph tags in exported document
      formattedHtmlContent = formattedHtmlContent
        .replace(/<\/summary>/g, "</summary><p>&nbsp;</p>")
        .replace(/<\/details>/g, "</details><p>&nbsp;</p>");

      exportHtmlToWord(formattedHtmlContent)
        .then(({ documentName, blob }) => {
          createLink(blob, `${documentName}.docx`);
        })
        .catch(() => {
          toast.error(t("Components.Document.ExportError") as string);
        });
    }

    return true;
  };
