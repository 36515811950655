import { T } from "@tldraw/validate";

import { IDataGridPreferences } from "./IDataGridShape.js";

export const dataGridPreferencesValidatable: T.Validatable<IDataGridPreferences | undefined> = T.object({
  sortModel: T.arrayOf(
    T.object({
      field: T.string.optional(),
      sort: T.string.optional(),
    }),
  ).optional(),
  pinnedColumns: T.object({
    left: T.arrayOf(T.string).optional(),
    right: T.arrayOf(T.string).optional(),
  }).optional(),
  filterModel: T.object({
    items: T.arrayOf(
      T.object({
        field: T.string.optional(),
        fromInput: T.string.optional(),
        id: T.number.optional(),
        operator: T.string.optional(),
        value: T.string.optional(),
      }),
    ).optional(),
  }).optional(),
  rowGroupingModel: T.arrayOf(T.string).optional(),
  rowsPerPage: T.number.optional(),
}).optional();
