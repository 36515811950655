import { ApolloClient } from "@apollo/client";
import { ContentTypes } from "@bigpi/cookbook";
import { Editor } from "@bigpi/editor-tiptap";
import { generateHTML, getNodeType } from "@tiptap/core";
import { Plugin, PluginKey, TextSelection } from "@tiptap/pm/state";
import { EditorView } from "@tiptap/pm/view";
import { TFunction } from "i18next";
import { toast } from "react-toastify";

import { IMAGE_TOKEN_QUERY } from "GraphQL/Token/Query";
import { getPlainTextFromHtml } from "Utils/BoardSearchUtils";
import { getRenderedContent } from "Utils/ClipboardUtils";

export enum ClipboardDataType {
  Document = "DOCUMENT",
}

const removeWrappingTag = (html: string, tag: string): string => {
  let result = html;
  const startTag = `<${tag}>`;
  const endTag = `</${tag}>`;
  const startIndex = html.indexOf(startTag);
  const endIndex = html.lastIndexOf(endTag);
  if (startIndex == 0 && endIndex !== -1) {
    result = html.substring(startIndex + startTag.length, endIndex);
  }

  return result;
};

const getApplicableSelection = (editor: Editor) => {
  const { $from, $to, anchor, head } = editor.state.selection;

  // Swap "head" and "anchor" position if the selection is backward so that
  // the code below doesn't need special handling in both "forward" and "backward"
  // scenarios.
  const isBackwardSelection = anchor > head;
  const newHead = isBackwardSelection ? anchor : head;
  const newAnchor = isBackwardSelection ? head : anchor;

  const { lastChild: lastChildInSelection, firstChild: firstChildInSelection } = editor.state.selection.content().content;

  // Check if the last node from the selected content is "details" node
  // (i.e. selection's end position is falling somewhere in the "details" node),
  // new selection's end position ($head) will move to the end of the "details"
  // node if that's the case.
  const isLastChildDetailsNode = lastChildInSelection?.type.name === "details";
  const endPos = isLastChildDetailsNode ? $to.after(1) - 2 : newHead;

  // Check if the first node from the selected content is "details" node
  // (i.e. selection's start position is falling somewhere in the "details" node),
  // new selection's start position ($anchor) will move to the start of the "details"
  // node if that's the case.
  const isFirstChildDetailsNode = firstChildInSelection?.type.name === "details";
  const startPos = isFirstChildDetailsNode ? $from.before(1) + 1 : newAnchor;

  // We need to create a new selection object if the final end position or
  // start position is different from the original selection.
  return startPos !== newAnchor || endPos !== newHead
    ? TextSelection.create(editor.state.doc, startPos, endPos)
    : editor.state.selection;
};

const handleDocumentContentCopy = (editor: Editor, apolloClient: ApolloClient<object>) => {
  return (_view: EditorView, event: ClipboardEvent) => {
    if (!event.clipboardData) {
      return false;
    }

    event.preventDefault();

    const applicableSelection = getApplicableSelection(editor);
    const docNodeType = getNodeType("doc", editor.schema);
    const doc = docNodeType.create(null, applicableSelection.content().content).toJSON();

    let htmlContent = generateHTML(doc, editor.extensionManager.extensions);
    let htmlContentWithImageToken;

    const { imageToken } = apolloClient.readQuery({ query: IMAGE_TOKEN_QUERY });
    if (imageToken.token) {
      htmlContentWithImageToken = getRenderedContent(htmlContent, imageToken.token).html;
      // Patch the clipboard content
      event.clipboardData!.setData(ContentTypes.HTML, htmlContentWithImageToken);
    }

    // Add/update plain text data
    event.clipboardData!.setData(ContentTypes.PLAIN_TEXT, getPlainTextFromHtml(htmlContentWithImageToken || htmlContent));

    // Add/update JSON data
    event.clipboardData!.setData(
      ContentTypes.JSON,
      JSON.stringify({
        dataType: ClipboardDataType.Document,
        htmlContent: `<document>${htmlContent}</document>`,
      }),
    );

    if (event.type === "cut") {
      editor.commands.deleteSelection();
    }

    return true;
  };
};

const handlePasteInDocument = (processHtml: (html: string) => Promise<string>, t: TFunction) => {
  return (view: EditorView, event: ClipboardEvent) => {
    if (!event.clipboardData) {
      return;
    }

    const jsonContent = event.clipboardData.getData(ContentTypes.JSON);
    const clipboardData = jsonContent ? JSON.parse(jsonContent) : {};
    const html = event.clipboardData.getData(ContentTypes.HTML);
    const plainText = event.clipboardData.getData(ContentTypes.PLAIN_TEXT);

    if (clipboardData && clipboardData.dataType === ClipboardDataType.Document) {
      // Use original HTML content copied from Astra/Bistro
      event.preventDefault();

      // Remove <document> and </document> tags
      const htmlContent = removeWrappingTag(clipboardData.htmlContent, "document");
      view.pasteHTML(htmlContent, event);
    } else if (html) {
      event.preventDefault();

      processHtml(html).then((doubtfireResult) => {
        if (!doubtfireResult) {
          toast.error(t("Components.Document.PasteError") as string);
        } else {
          // Remove <document> and </document> tags
          const htmlContent = removeWrappingTag(doubtfireResult, "document");
          view.pasteHTML(htmlContent, event);
        }
      });
    } else if (plainText) {
      event.preventDefault();

      view.pasteText(plainText, event);
    }
  };
};

export const pasteContentPlugin = (
  processHtml: (content: string) => Promise<string>,
  editor: Editor,
  apolloClient: ApolloClient<object>,
  t: TFunction,
) => {
  return new Plugin({
    key: new PluginKey("pasteContentPlugin"),
    props: {
      handleDOMEvents: {
        copy: handleDocumentContentCopy(editor, apolloClient),
        cut: handleDocumentContentCopy(editor, apolloClient),
        paste: handlePasteInDocument(processHtml, t),
      },
    },
  });
};
