import { getNodeType, RawCommands } from "@tiptap/core";
import { Node, NodeRange } from "@tiptap/pm/model";
import { Transaction } from "@tiptap/pm/state";

import {
  getIndentLevelByClass,
  getBaseClassWithoutIndent,
  getNewListItemClass,
  getIndentClassFromIndentLevel,
} from "../../Utils/CommandUtil.js";
import { IndentProps } from "../Indent/IndentExtension.js";

const updateListMarkers = (range: NodeRange, tr: Transaction, parent: Node, previousIndentLevel: number) => {
  const after = range.end;
  let ignoreIndentLevelsAbove: number | null = null;
  let isNextElementSubList: boolean | null = null;
  let newStart: number | null = null;
  let previousStart: number = 1;

  tr.doc.forEach((node, offset) => {
    if (node.type.name === parent.type.name && node.attrs["data-list-id"] === parent.attrs["data-list-id"]) {
      // This node falls after the currently selected list item
      if (offset > after) {
        const nodeIndentLevel = getIndentLevelByClass(node.attrs.class);

        // Check if the very next item to the item is getting sink is child list
        // of the list item.
        //
        // 1. first
        // 2. second
        //   a. third
        //   b. fourth|               <-- This item is getting sink.
        //     i. fifth
        //   c. sixth
        // 3. seventh
        if (isNextElementSubList === null && nodeIndentLevel >= previousIndentLevel + 1) {
          isNextElementSubList = true;
        } else if (isNextElementSubList === null) {
          isNextElementSubList = false;
        }

        // If the list lifts somewhere then we don't want to change numbering
        // for those indent levels.
        //
        // 1. first
        // 2. second
        //   a. third
        //   b. fourth|              <-- This item is getting sink.
        //   c. fifth
        //     i. eighth             <-- Don't want to consider for change in numbering.
        //   d. sixth
        // 3. seventh
        if (nodeIndentLevel < previousIndentLevel) {
          ignoreIndentLevelsAbove = ignoreIndentLevelsAbove
            ? Math.max(ignoreIndentLevelsAbove, previousIndentLevel)
            : previousIndentLevel;
        }

        const canChangeListMarker = ignoreIndentLevelsAbove === null ? true : nodeIndentLevel < ignoreIndentLevelsAbove;
        if (canChangeListMarker && nodeIndentLevel === previousIndentLevel) {
          // If on the same indent level, decrease the numbering.
          tr.setNodeAttribute(offset, "start", node.attrs.start ? Math.max(node.attrs.start - 1, 1) : 1);
        } else if (canChangeListMarker && isNextElementSubList && nodeIndentLevel === previousIndentLevel + 1) {
          tr.setNodeAttribute(offset, "start", ++previousStart);
        }
      } else {
        const nodeIndentLevel = getIndentLevelByClass(node.attrs.class);
        if (nodeIndentLevel === previousIndentLevel + 1) {
          newStart = (node.attrs.start || 1) + 1;
        } else if (!node.eq(parent) && nodeIndentLevel < previousIndentLevel + 1) {
          newStart = 1;
        }

        previousStart = newStart || 1;
      }
    }
  });

  return newStart || 1;
};

// This command is run when "Tab" key binding is invoked for "listItem".
// We will try and increase the indent level for the current "listItem".
export const sinkListItem: RawCommands["sinkListItem"] =
  (typeOrName, nodePos?: number) =>
  ({ tr, state, dispatch }) => {
    const type = getNodeType(typeOrName, state.schema);
    let { $from, $to } = state.selection;
    if (nodePos) {
      $from = tr.doc.resolve(nodePos);
      $to = tr.doc.resolve(nodePos);
    }

    const range = $from.blockRange($to, (node) => node.childCount > 0 && node.firstChild!.type === type);
    if (!range) {
      return false;
    }

    // "list" node represents the "ol" tag which is present on
    // "-2" depth from the current selection i.e. the paragraph.
    //
    // <ol>
    //   <li>
    //     <p> text </p>
    //   </li>
    // </ol>
    const list = $from.node(-2);
    if (list.type.name !== "orderedList" && list.type.name !== "bulletList") {
      return false;
    }

    const listNodePos = $from.before(-2);
    const listItemNode = $from.node(-1);
    const listItemNodePos = $from.before(-1);

    if (dispatch) {
      const previousIndentLevel = getIndentLevelByClass(list.attrs.class);
      const newIndentLevel = Math.min(previousIndentLevel + 1, IndentProps.MAX);
      // If the new indent level is same as the previous indent level then do not do anything.
      if (newIndentLevel === previousIndentLevel) {
        return true;
      }

      // Update list markers for the "listItem" which are right after the currently
      // selected "listItem"
      const newStart = updateListMarkers(range, tr, list, previousIndentLevel);

      const newIndentClass = getIndentClassFromIndentLevel(newIndentLevel);
      const newBaseClass = getBaseClassWithoutIndent(list.attrs.class);
      const newClass = `${newIndentClass ?? ""} ${newBaseClass ?? ""}`.trim();

      // Change "start" and "class" attributes for current "list" i.e. "ol"
      tr.setNodeAttribute(listNodePos, "start", newStart);
      tr.setNodeAttribute(listNodePos, "class", newClass);

      // Change "class" attribute for current "listItem" i.e. "li"
      const newListItemClass = getNewListItemClass(listItemNode.attrs.class, list.type.name, "sink");
      tr.setNodeAttribute(listItemNodePos, "class", newListItemClass);
    }

    return true;
  };
