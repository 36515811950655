import { WORKSPACE_TEMPLATE_TAG } from "@bigpi/cookbook";
import { Chip } from "@mui/material";
import { Box, SxProps, Theme } from "@mui/system";

export interface IWorkspaceTagsListProps {
  tagsList: Array<string>;
  onRemoveTag?: (tag: string) => void;
  containerSxProps?: SxProps<Theme>;
  chipSxProps?: SxProps<Theme>;
  highlightTemplateTag?: boolean;
}

export function WorkspaceTagsList(props: IWorkspaceTagsListProps) {
  const { tagsList, onRemoveTag, containerSxProps, chipSxProps, highlightTemplateTag = true } = props;

  return (
    <Box component="span" sx={{ display: "flex", flexWrap: "wrap", ...containerSxProps }}>
      {tagsList.map((tag, i) => {
        return (
          <Chip
            key={tag}
            component="span"
            label={tag}
            variant="outlined"
            color={highlightTemplateTag && tag === WORKSPACE_TEMPLATE_TAG ? "primary" : "default"}
            onDelete={onRemoveTag ? () => onRemoveTag(tag) : undefined}
            sx={{ mr: 1, mb: 1, ...chipSxProps }}
          />
        );
      })}
    </Box>
  );
}
