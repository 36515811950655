import { SortOrder } from "./SortOrder.js";
import { ISortOption } from "./ISortOption.js";

export const CreatedAtDescSortOptionName = "CreatedAtDesc";

export const CreatedAtDescSortOption: ISortOption = {
  field: "createdAt",
  name: CreatedAtDescSortOptionName,
  sortOrder: SortOrder.Desc,
};
