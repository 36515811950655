import { Optional } from "@bigpi/cookbook";
import { Mark } from "@tiptap/pm/model";
import type { Editor } from "@tiptap/react";
import React, { useEffect, useState } from "react";

import { ActionMenuItemBase } from "../../ActionMenus/ActionMenuItemBase.js";
import { IEditorConfig } from "../../Editor/IEditorConfig.js";
import { ToolbarButton } from "../../Toolbars/ToolbarButton.js";
import { LinkActionsConfig } from "../IExtensionOptions.js";
import { Link } from "../Link/LinkExtension.js";
import { EditLinkMenuActionButton, LinkEditingMenu, OpenInNewTabLinkMenuActionButton } from "./DefaultLinkActionButtons.js";
import { LinkActionsPlugin, LinkActionsPluginProps } from "./LinkActionsPlugin.js";

const ITEM_NAME = "linkActions";

/**
 * An action menu for links.
 */
export class LinkActionsMenuItem extends ActionMenuItemBase {
  // *********************************************
  // Public properties
  // *********************************************/
  /**
   * @inheritdoc
   */
  readonly name = ITEM_NAME;

  // *********************************************
  // Public methods
  // *********************************************/
  /**
   * @inheritdoc
   */
  create(editor: Editor, config: IEditorConfig) {
    return <LinkActionsMenu key={ITEM_NAME} editor={editor} />;
  }
}

interface LinkActionsMenuButtonProps {
  linkHref: string | null;
  linkActionsConfig: LinkActionsConfig;
}

const LinkActionsMenuButton = (props: LinkActionsMenuButtonProps) => {
  const { linkHref, linkActionsConfig } = props;

  const [disabled, setDisabled] = useState<boolean>(linkActionsConfig.defaultDisabled || false);

  useEffect(() => {
    if (linkHref && linkActionsConfig.shouldBeDisabled) {
      linkActionsConfig
        .shouldBeDisabled(linkHref)
        .then((shouldDisable) => {
          setDisabled(shouldDisable);
        })
        .catch(() => {
          setDisabled(true);
        });
    }

    return () => {
      setDisabled(linkActionsConfig.defaultDisabled || false);
    };
  }, [linkHref, linkActionsConfig.shouldBeDisabled]);

  return (
    <ToolbarButton
      tooltip={linkActionsConfig.tooltip}
      Icon={linkActionsConfig.icon}
      onClick={() => {
        if (linkHref) {
          linkActionsConfig.onClick(linkHref);
        }
      }}
      disabled={disabled}
    />
  );
};

export type LinkActionsProps = Omit<Optional<LinkActionsPluginProps, "pluginKey">, "element"> & {
  className?: string;
  updateDelay?: number;
};

export const LinkActionsMenu = (props: LinkActionsProps) => {
  const { editor, pluginKey = "linkActions", tippyOptions = {}, updateDelay, shouldShow = null, className } = props;

  const [element, setElement] = useState<HTMLDivElement | null>(null);
  const [linkHref, setLinkHref] = useState<string | null>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [linkUrl, setLinkUrl] = useState<string>("");

  useEffect(() => {
    if (!element) {
      return;
    }

    if (editor.isDestroyed) {
      return;
    }

    const plugin = LinkActionsPlugin({
      updateDelay,
      editor,
      element,
      pluginKey,
      shouldShow,
      tippyOptions,
    });

    editor.registerPlugin(plugin);
    return () => {
      editor.unregisterPlugin(pluginKey);
    };
    // Register plugin when the editor is ready or the element changes.
    // We don't want to consider other props changes as they are not
    // coming from a react state or ref, which makes the menu rendering
    // unstable and non-ending process.
  }, [editor, element]);

  useEffect(() => {
    const { $from } = editor.state.selection;
    const linkMark = $from.marks().find((mark: Mark) => mark.type.name === "link");

    if (!linkMark) {
      setLinkHref(null);
    } else {
      setLinkHref(linkMark.attrs.href as string);
    }

    return () => {
      setLinkUrl("");
      setLinkHref(null);
      setIsEditing(false);
    };
  }, [editor.state.selection]);

  if (isEditing) {
    return (
      <div ref={setElement} className={className} style={{ visibility: "hidden" }}>
        <LinkEditingMenu editor={editor} linkUrl={linkUrl} setLinkUrl={setLinkUrl} setIsEditing={setIsEditing} />
      </div>
    );
  }

  const linkExtension = editor.extensionManager.extensions.find((extension) => extension.name === "link") as typeof Link;
  const linkActionConfig = linkExtension?.options.LinkActions;

  return (
    <div ref={setElement} className={className} style={{ visibility: "hidden" }}>
      <EditLinkMenuActionButton linkHref={linkHref} setLinkUrl={setLinkUrl} setIsEditing={setIsEditing} />
      <OpenInNewTabLinkMenuActionButton linkHref={linkHref} />

      {linkActionConfig?.map((linkAction) => (
        <LinkActionsMenuButton key={`link-action-${linkAction.action}`} linkHref={linkHref} linkActionsConfig={linkAction} />
      ))}
    </div>
  );
};
