import { SelectableText } from "@bigpi/cutlery";
import { Box } from "@mui/material";

import { TranscriptItemView } from "./TranscriptItemView";
import { TranscriptItem } from "GraphQL/Generated/Apollo";

export interface IFullTranscriptSessionProps {
  title: string;
  transcriptItems?: Array<TranscriptItem>;
}

export function FullTranscriptSession(props: IFullTranscriptSessionProps) {
  const { title, transcriptItems } = props;

  if (!transcriptItems || transcriptItems.length === 0) {
    return <></>;
  }

  return (
    <>
      <SelectableText variant="h6" mt={2} mb={1}>
        {title}
      </SelectableText>
      {transcriptItems.map((transcriptItem) => {
        return (
          <Box
            key={transcriptItem.itemIndex}
            sx={{
              border: "2px dashed transparent",
              p: 1,
              pt: 0,
            }}
          >
            <TranscriptItemView transcriptItem={transcriptItem} />
          </Box>
        );
      })}
    </>
  );
}
