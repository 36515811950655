import { FacetDisplaySortType, ISelectInputFieldOption } from "@bigpi/cookbook";
import { Checkbox, MenuItem, ListItemText } from "@mui/material";

import { SelectAllMenuItem } from "./SelectAllMenuItem";

/******************************
 * Constants
 ******************************/
const SELECT_ALL = "all";

export interface MenuItemListProps {
  isSelectAllEnabled?: boolean;
  isSortingEnabled?: boolean;
  onSort?: (sortModel: FacetDisplaySortType) => void;
  options: Array<string | ISelectInputFieldOption>;
  selected: Array<string>;
  sortOrder?: FacetDisplaySortType;
}

/**
 * <MenuItem> has to be direct children to <Select> then it can access the value
 * So creating as function instead of component
 *
 * @param props
 * @returns
 */
export function renderMenuItems(props: MenuItemListProps) {
  let { isSelectAllEnabled, isSortingEnabled, selected, sortOrder = FacetDisplaySortType.Alphabetical } = props;
  let options = [...props.options];
  if (typeof options[0] === "string") {
    options = options.sort();
  } else {
    options = options.sort((a, b) => {
      if (typeof a !== "string" && typeof b !== "string") {
        if (sortOrder === FacetDisplaySortType.Alphabetical) {
          return (a?.label || a?.key || "").localeCompare(b?.label || b?.key || "");
        } else {
          return (b.count || 0) - (a.count || 0);
        }
      }
      return 0;
    });
  }

  // Elements
  const elements = [
    <SelectAllMenuItem
      key={SELECT_ALL}
      value={SELECT_ALL}
      checked={selected.length === options.length}
      indeterminate={selected.length !== options.length && selected.length > 0}
      label={"All"}
      onSort={props.onSort}
      sortOrder={sortOrder}
      isSortingEnabled={isSortingEnabled}
      isSelectAllEnabled={isSelectAllEnabled}
    />,
  ];
  options.forEach((option) => {
    const key = typeof option === "string" ? option : option.key;
    const label = typeof option === "string" ? option : option.label || option.key;
    const count = typeof option === "string" ? undefined : option.count;
    const disabled = typeof count !== "undefined" && count === 0 ? true : false;
    elements.push(
      <MenuItem key={key} value={key} classes={{ selected: "analysis-select-menu-item" }}>
        <Checkbox checked={selected.includes(key)} disabled={disabled} />
        <ListItemText
          primary={`${label} ${typeof count !== "undefined" ? `(${count})` : ""}`}
          sx={{ color: disabled ? "gray" : "" }}
        />
      </MenuItem>,
    );
  });
  return elements;
}
