import React, { useState } from "react";
import {
  Button,
  Modal,
  Paper,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
  Alert,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  DataGridPremium,
  GridColDef,
  GridRenderCellParams,
  GridPaginationModel,
  GridRowSelectionModel,
} from "@mui/x-data-grid-premium";
import { useTranslation } from "react-i18next";

import { DataUtils } from "Utils/DataUtils";

import "./SearchResultDialog.css";
import { DefaultDialogTransition } from "Components/DialogTransition/DefaultDialogTransition";

interface SearchResultDialogProps {
  data?: string | null;
  onAdd: (selectedData: any) => void;
  onCancel: () => void;
  open: boolean;
}

const DEFAULT_PAGE_SIZE = 10;

export const SearchResultDialog: React.FC<SearchResultDialogProps> = (props) => {
  const { data, onAdd, onCancel, open } = props;
  const [selectedData, setSelectedData] = useState<Record<string, Array<any>>>({});
  const [page, setPage] = useState<number>(0);
  const { t } = useTranslation();

  const columns: GridColDef[] = [
    {
      field: "title",
      headerName: t("Components.SearchResultDialog.Table.Title"),
    },
    {
      field: "source",
      headerName: t("Components.SearchResultDialog.Table.Source"),
    },
    {
      field: "excerpt",
      headerName: t("Components.SearchResultDialog.Table.Excerpt"),
      minWidth: 300,
      renderCell: (params: GridRenderCellParams<String>) => {
        return <span dangerouslySetInnerHTML={{ __html: params.value || "" }} />;
      },
    },
    {
      field: "content",
      headerName: t("Components.SearchResultDialog.Table.Content"),
      minWidth: 300,
    },
  ];

  const dataJson: { sushi?: any } = JSON.parse(data || "{}");

  // TODO
  const errorMessage = "";

  if (!dataJson?.sushi?.results?.length) {
    return (
      <Dialog open={open} onClose={onCancel} TransitionComponent={DefaultDialogTransition} fullWidth maxWidth="xs">
        <DialogTitle>{t("Components.CommandSearchResultsDialog.Title")}</DialogTitle>
        <DialogContent>
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
          <DialogContentText>{t("Components.CommandSearchResultsDialog.NoResults")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel}>{t("Global.Action.Close")}</Button>
        </DialogActions>
      </Dialog>
    );
  } else {
    const sourceMap = groupResults(dataJson.sushi?.results);
    return (
      <Modal open={true} onClose={() => {}} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Paper elevation={2} sx={{ flex: 1, m: 3, p: 3 }} className="search-results-paper">
          <div className="search-results-dialog">
            {Object.keys(sourceMap).map((source) => {
              return getAccordion(source, sourceMap[source]);
            })}
          </div>
          <div className="search-results-dialog__actions">
            <Button onClick={onCancel}>{t("Global.Action.Cancel")}</Button>
            <Button onClick={onAddClick}>{t("Global.Action.Add")}</Button>
          </div>
        </Paper>
      </Modal>
    );
  }

  function onAddClick() {
    const selectedItems: Array<any> = [];
    Object.values(selectedData).forEach((value: Array<any>) => {
      selectedItems.push.apply(selectedItems, value);
    });
    onAdd(selectedItems);
  }

  function groupResults(results: Array<any>) {
    return DataUtils.groupData(results, "source");
  }

  function getAccordion(source: string, sourceResults: Array<any>) {
    return (
      <Accordion key={source} className="search-results-dialog__accordion">
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography>{source}</Typography>
        </AccordionSummary>
        <AccordionDetails>{getDataGrid(source, sourceResults)}</AccordionDetails>
      </Accordion>
    );
  }

  function getDataGrid(source: string, sourceResults: Array<any>) {
    return (
      <DataGridPremium
        key={source}
        autoHeight
        checkboxSelection
        loading={false}
        rows={sourceResults}
        rowCount={sourceResults.length}
        columns={columns}
        pagination={true}
        paginationModel={{ page, pageSize: DEFAULT_PAGE_SIZE }}
        onPaginationModelChange={({ page }: GridPaginationModel) => {
          setPage(page);
        }}
        pageSizeOptions={[DEFAULT_PAGE_SIZE]}
        onRowSelectionModelChange={(selectedIds: GridRowSelectionModel) => {
          const idRelatedData = sourceResults.filter((result) => selectedIds.includes(result.id as string));
          setSelectedData({
            ...selectedData,
            [source]: idRelatedData,
          });
        }}
      />
    );
  }
};
