import { FileSourceType, ONE_MB, WORKSPACE_FILE_UPLOAD_MAX_FILE_SIZE } from "@bigpi/cookbook";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Box, Button, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { FilePreviewDialog } from "Components/FilePreviewDialog/FilePreviewDialog";
import { useWorkspaceFilesQuery, WorkspaceFilesQuery } from "GraphQL/Generated/Apollo";
import { WorkspaceFileGrid } from "./WorkspaceFileGrid";
import { WorkspaceFileList } from "./WorkspaceFileList";
import { WorkspaceContentGridSize } from "./WorkspaceContentView";
import { TFunction } from "i18next";

const DEFAULT_PAGE_SIZE = 20;

export interface IWorkspaceFileViewProps {
  gridSize: WorkspaceContentGridSize;
  isSelectionEnabled: boolean;
  onGridSizeChange: (gridSize: WorkspaceContentGridSize) => void;
  onSelectionChange: (file: WorkspaceFilesQuery["workspaceFiles"][number]) => void;
  searchValue: string;
  selectedIds: Array<string>;
  viewType: "list" | "grid";
  workspaceId: string;
}

export function WorkspaceFileView(props: IWorkspaceFileViewProps) {
  const { gridSize, isSelectionEnabled, onGridSizeChange, onSelectionChange, searchValue, selectedIds, viewType, workspaceId } =
    props;

  const { t } = useTranslation();

  const [offset, setOffset] = useState(0);
  const [orderBy, setOrderBy] = useState<Record<string, string>>({ updatedAt: "desc" });
  const [previewId, setPreviewId] = useState<string | null>(null);

  const {
    data: workspaceFilesData,
    loading: workspaceFilesLoading,
    fetchMore: fetchMoreWorkspaceFiles,
    error: workspaceFilesError,
  } = useWorkspaceFilesQuery({
    variables: {
      workspaceId,
      limit: DEFAULT_PAGE_SIZE,
      offset,
      orderBy,
      filters: {
        searchTerm: searchValue,
      },
    },
  });
  const files = workspaceFilesData?.workspaceFiles || [];
  const fileCount = workspaceFilesData?.workspaceFileAggregate.count || 0;

  if (workspaceFilesLoading || workspaceFilesError || (searchValue && fileCount === 0)) {
    return renderStatusMessage(t, workspaceFilesLoading, workspaceFilesError, searchValue, fileCount);
  } else if (fileCount === 0) {
    return renderDefaultView(t);
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", minHeight: "200px" }}>
      <Box sx={{ display: "flex", p: 3, justifyContent: "center" }}>
        {viewType === "list" ? (
          <WorkspaceFileList
            isSelectionEnabled={isSelectionEnabled}
            loading={workspaceFilesLoading}
            onClick={setPreviewId}
            onSelectionChange={onSelectionChange}
            rows={files}
            selectedIds={selectedIds}
          />
        ) : (
          <WorkspaceFileGrid
            gridSize={gridSize}
            isSelectionEnabled={isSelectionEnabled}
            loading={workspaceFilesLoading}
            onGridSizeChange={onGridSizeChange}
            onClick={setPreviewId}
            onSelectionChange={onSelectionChange}
            rows={files}
            selectedIds={selectedIds}
          />
        )}
      </Box>

      {fileCount > files.length && (
        <Button
          sx={{ alignSelf: "center", maxWidth: "150px" }}
          onClick={() => {
            fetchMoreWorkspaceFiles({
              variables: {
                offset: files.length,
                filters: {
                  searchTerm: searchValue,
                },
              },
            });
          }}
        >
          {t("Components.WorkspaceFileView.ShowMore")}
        </Button>
      )}

      {previewId && (
        <FilePreviewDialog fileId={previewId} fileType={FileSourceType.WorkspaceFile} onClose={() => setPreviewId(null)} />
      )}
    </Box>
  );
}

/**
 * Renders the loading, error, or no items message based on the state.
 *
 * @returns Loading, error, or no items message.
 */
function renderStatusMessage(
  t: TFunction,
  workspaceFilesLoading: boolean,
  workspaceFilesError: any,
  searchValue: string,
  fileCount: number,
) {
  let message = "";
  if (workspaceFilesLoading) {
    message = t("Global.Status.Loading");
  } else if (workspaceFilesError) {
    message = t("Components.WorkspaceFileView.LoadError");
  } else if (searchValue && fileCount === 0) {
    message = t("Components.WorkspaceFileView.NoItems");
  }

  return (
    <Typography variant="caption" color="textSecondary" sx={{ display: "flex", p: 3, justifyContent: "center" }}>
      {message}
    </Typography>
  );
}

/**
 * Renders file upload message.
 *
 * @returns Upload file message.
 */
function renderDefaultView(t: TFunction) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        minHeight: "300px",
      }}
    >
      <UploadFileIcon sx={{ fontSize: "xx-large", mb: 2, color: "#0b3d62" }} />
      {t("Components.WorkspaceFileView.DragAndDrop")}
      <Typography variant="body1" color="GrayText">
        {t("Components.WorkspaceFileView.FileTypeAndSize", {
          maxInMBs: WORKSPACE_FILE_UPLOAD_MAX_FILE_SIZE / ONE_MB,
        })}
      </Typography>
    </Box>
  );
}
