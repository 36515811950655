/**
 * Gets all coordinates of the shape.
 *
 * @param shape Tldraw shape.
 *
 * @returns Coordinates of the given shape.
 */
export function getShapeCoordinates(shape: { x: number; y: number; props: { h: number; w: number } }) {
  const { x, y, props } = shape;
  const { h, w } = props;
  return {
    topLeft: { x, y },
    topRight: { x: x + w, y },
    bottomRight: { x: x + w, y: y + h },
    bottomLeft: { x, y: y + h },
    center: { x: x + w / 2, y: y + h / 2 },
  };
}
