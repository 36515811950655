export enum CommandResponseType {
  /**
   * Response for an embedded client action.
   */
  ClientAction = "ClientAction",

  /**
   * Response for GenerateDistilledTranscript.
   */
  GenerateDistilledTranscript = "GenerateDistilledTranscript",

  /**
   * Response for GenerateThemeDiscussionAnalysis.
   */
  GenerateThemeDiscussionAnalysis = "GenerateThemeDiscussionAnalysis",

  /**
   * Response data for simulated headlines.
   */
  HeadlineSimulator = "HeadlineSimulator",

  /**
   * Response data for HTML prompt.
   */
  HtmlPrompter = "HtmlPrompter",

  /**
   * Response to a text processing action.
   */
  ProcessText = "ProcessText",
}
