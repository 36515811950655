import React from "react";

import { SvgIcon, SvgIconProps } from "@mui/material";

export const ImageStyleWrapAlignLeftIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path d="M 3 20 L 21 20 L 21 18 L 3 18 Z M 12 16 L 21 16 L 21 14 L 12 14 Z M 3 2 L 3 4 L 21 4 L 21 2 Z M 12 8 L 21 8 L 21 6 L 12 6 Z M 12 12 L 21 12 L 21 10 L 12 10 Z" />
    <path d="M 4 15 L 9 15 L 9 7 L 4 7 Z" />
  </SvgIcon>
);
