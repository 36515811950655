import { ChatMessageAction } from "Chat/ChatActionManagers/ChatMessageActions";
import { ChatActionPlugInBase } from "./ChatActionPlugInBase";
import { IChatActionPlugInInputs } from "./IChatActionPlugInInputs";

export class SetChatMessagePlugIn extends ChatActionPlugInBase {
  // *********************************************
  // Public properties
  // *********************************************/
  /**
   * @inheritdoc
   */
  name = ChatMessageAction.SetChatMessage;

  // *********************************************
  // Public methods
  // *********************************************/
  /**
   * @inheritdoc
   */
  public async execute(input: IChatActionPlugInInputs): Promise<undefined | JSX.Element> {
    const { chatEditor, data } = input;

    chatEditor?.commands.setContent(data, true);
    chatEditor?.commands.focus("end");

    return undefined;
  }
}
