import objectHash from "object-hash";

export const getObjectHash = (obj: Object) => {
  return objectHash(obj, {
    algorithm: "md5",
    encoding: "base64",
  });
};

export const getStringHash = (str: string) => {
  return objectHash(str, {
    algorithm: "md5",
    encoding: "base64",
  });
};
