import * as React from "react";
import { ObjectRole } from "GraphQL/Generated/Apollo";
import { useAuthUser } from "@frontegg/react";
import { useDocumentAccessControlListUserRolesQuery } from "GraphQL/Generated/Apollo";

export interface HasDocumentRoleProps {
  children: React.ReactNode;
  roles: Array<ObjectRole>;
  documentId: string;
}

export const HasDocumentRole = (props: HasDocumentRoleProps) => {
  const { children, roles, documentId } = props;
  const user = useAuthUser();
  const { data, error, loading } = useDocumentAccessControlListUserRolesQuery({
    variables: {
      userId: user.id,
      documentId,
    },
  });

  if (data?.documentAccessControlListUserRoles.roles.some((assignedRole) => roles.includes(assignedRole as ObjectRole))) {
    return <>{children}</>;
  }
  return <></>;
};
