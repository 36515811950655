import { useOnlineStatus } from "Hooks/useOnlineStatus";
import { useTranslation } from "react-i18next";
import { AlertBar } from "Components/AlertBar/AlertBar";

export function OnlineStatus() {
  const { t } = useTranslation();
  const isOnline = useOnlineStatus();

  return <AlertBar isOnline={isOnline} message={t("Global.Status.Offline")} />;
}
