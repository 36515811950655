import { SortOrder } from "./SortOrder.js";
import { ISortOption } from "./ISortOption.js";

export const UpdatedAtAscSortOptionName = "UpdatedAtAsc";

export const UpdatedAtAscSortOption: ISortOption = {
  field: "updatedAt",
  name: UpdatedAtAscSortOptionName,
  sortOrder: SortOrder.Asc,
};
