import { SxProps } from "@mui/material";
import { useValue } from "@tldraw/tldraw";
import { useEffect, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { IFileWithId } from "Components/Upload/FilesList";
import { FilesUploadDialog } from "Components/Upload/FilesUploadDialog";
import { useWorkspaceFilesUploadBatch } from "./Hooks/useWorkspaceFilesUploadBatch";

// *********************************************
// Types/Interfaces/Constants
// *********************************************/
interface IWorkspaceFileUploadDialogProps {
  onClose: () => void;
  onRemoveFile: (id: string) => void;
  selectedFiles: Array<IFileWithId>;
  sx?: SxProps;
  workspaceId: string;
}

// *********************************************
// Component
// *********************************************/
export function WorkspaceFileUploadDialog(props: IWorkspaceFileUploadDialogProps) {
  const { onClose, onRemoveFile, selectedFiles, sx, workspaceId } = props;

  const { t } = useTranslation();

  // Ref
  const selectedFilesRef = useRef<Array<IFileWithId>>([]);

  const { abortUpload, filesStatusMap, uploadFiles, progressingFiles } = useWorkspaceFilesUploadBatch(workspaceId);

  useEffect(() => {
    selectedFilesRef.current = selectedFiles;
  }, [selectedFiles]);

  // Callback to retry uploading a file. This happens when a file is cancelled or failed
  const onRetryFile = useCallback(
    (file: IFileWithId) => {
      uploadFiles([file]);
    },
    [uploadFiles],
  );

  const filesStatus = useValue(
    "filesStatus",
    () => {
      return new Set(Object.values(filesStatusMap));
    },
    [filesStatusMap],
  );

  return (
    <FilesUploadDialog
      files={selectedFiles}
      filesStatusMap={filesStatusMap}
      onAbort={abortUpload}
      onClose={onClose}
      onUpload={() => uploadFiles(selectedFilesRef.current)}
      onRemove={onRemoveFile}
      onRetry={onRetryFile}
      progressingFiles={progressingFiles}
      title={t("Components.WorkspaceFileUploadDialog.Title")}
    />
  );
}
