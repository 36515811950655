import { TransitionProps } from "@mui/material/transitions";
import Zoom from "@mui/material/Zoom";
import * as React from "react";

export const DefaultDialogTransition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Zoom ref={ref} {...props} />;
});
