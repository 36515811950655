import { IBoardSearchShapeResult } from "@bigpi/cookbook";
import EventEmitter from "emittery";
import { useMemo } from "react";
import { v4 as uuidV4 } from "uuid";

export type BoardSearchExecutorEvents = {
  beforeBoardSearchRequest: OnBoardSearchRequestEventArgs;
  boardSearchRequest: OnBoardSearchRequestEventArgs;
  afterBoardSearchRequest: OnBoardSearchRequestEventArgs;

  beforeBoardSearchResponse: OnBoardSearchResponseEventArgs;
  boardSearchResponse: OnBoardSearchResponseEventArgs;
  afterBoardSearchResponse: OnBoardSearchResponseEventArgs;
};

export type OnBoardSearchRequestEventArgs = {
  searchTerm: string;
  sessionId: string;
};
export type OnBeforeBoardSearchRequestHandler = (event: OnBoardSearchRequestEventArgs) => void;
export type OnBoardSearchRequestHandler = (event: OnBoardSearchRequestEventArgs) => void;
export type OnAfterBoardSearchRequestHandler = (event: OnBoardSearchRequestEventArgs) => void;

export type OnBoardSearchResponseEventArgs = {
  searchTerm: string;
  sessionId: string;
  searchResults: Array<IBoardSearchShapeResult>;
};
export type OnBeforeBoardSearchResponseHandler = (event: OnBoardSearchResponseEventArgs) => void;
export type OnBoardSearchResponseHandler = (event: OnBoardSearchResponseEventArgs) => void;
export type OnAfterBoardSearchResponseHandler = (event: OnBoardSearchResponseEventArgs) => void;

/**
 * Handles board level search execution and response lifecycle events.
 */
export class BoardSearchExecutor extends EventEmitter<BoardSearchExecutorEvents> {
  // *********************************************
  // Private fields
  // *********************************************/
  private _sessionId = uuidV4();

  // *********************************************
  // Public static methods
  // *********************************************/
  public static useNewBoardSearchExecutor = () => {
    const result = useMemo(() => new BoardSearchExecutor(), []);

    return result;
  };

  // *********************************************
  // Public properties
  // *********************************************/
  public get sessionId(): string {
    return this._sessionId;
  }

  // *********************************************
  // Public methods
  // *********************************************/
  public rotateSessionId() {
    this._sessionId = uuidV4();
  }

  public async executeBoardSearch(searchTerm: string, sessionId?: string) {
    // Allow any plug-ins to handle the board search before requesting
    await this.raiseBeforeBoardSearchRequest({ searchTerm, sessionId: sessionId ?? this._sessionId });

    // Execute the command request (call at least our event handler that's defined in this component)
    await this.raiseBoardSearchRequest({ searchTerm, sessionId: sessionId ?? this._sessionId });

    // Allow any plug-ins to handle the board search before requesting
    await this.raiseAfterBoardSearchRequest({ searchTerm, sessionId: sessionId ?? this._sessionId });
  }

  public async raiseBeforeBoardSearchRequest(event: OnBoardSearchRequestEventArgs) {
    await this.emitSerial("beforeBoardSearchRequest", event);
  }

  public async raiseBoardSearchRequest(event: OnBoardSearchRequestEventArgs) {
    await this.emitSerial("boardSearchRequest", event);
  }

  public async raiseAfterBoardSearchRequest(event: OnBoardSearchRequestEventArgs) {
    await this.emitSerial("afterBoardSearchRequest", event);
  }

  public async raiseBeforeBoardSearchResponse(event: OnBoardSearchResponseEventArgs) {
    await this.emitSerial("beforeBoardSearchResponse", event);
  }

  public async raiseBoardSearchResponse(event: OnBoardSearchResponseEventArgs) {
    await this.emitSerial("boardSearchResponse", event);
  }

  public async raiseAfterBoardSearchResponse(event: OnBoardSearchResponseEventArgs) {
    await this.emitSerial("afterBoardSearchResponse", event);
  }
}
