import type { Editor } from "@tiptap/react";
import type { default as i18next } from "i18next";
import React from "react";
import { I18nextProvider } from "react-i18next";

import type { IEditorConfig } from "../Editor/index.js";
import { initializeI18n } from "../I18n/Config.js";
import type { ActionMenuItem } from "./ActionMenuItem.js";

/**
 * A singleton factory class that manages available action menu items and creates new action menu instances.
 */
export class ActionMenuFactory {
  // *********************************************
  // Public static properties
  // *********************************************/
  /**
   * The i18n instance to use for translations. Set by calling initialize().
   */
  static i18n: typeof i18next;

  /**
   * The registered action menu items. Do not modify this directly, use the registerActionMenuItem() method instead.
   */
  static readonly registeredItems: Record<string, ActionMenuItem> = {};

  // *********************************************
  // Public static methods
  // *********************************************/
  /**
   * Initializes the factory. This must be called before using the factory.
   *
   * @param i18n The i18n instance to use for translations. If not set, a new instance will be created.
   */
  static async initialize(i18n?: typeof i18next) {
    ActionMenuFactory.i18n = await initializeI18n(i18n);
  }

  /**
   * Registers an ActionMenuItem in the factory making it available for use in action menus.
   *
   * @param actionMenuItem The action menu item to make available.
   */
  static registerActionMenuItem(actionMenuItem: ActionMenuItem) {
    // Sanity check on input and current state
    if (!actionMenuItem) {
      throw new Error("actionMenuItem is required");
    }

    if (ActionMenuFactory.registeredItems[actionMenuItem.name] !== undefined) {
      throw new Error(`ActionMenuItem "${actionMenuItem.name}" already registered`);
    }

    // Register the ActionMenuItem
    ActionMenuFactory.registeredItems[actionMenuItem.name] = actionMenuItem;
  }

  /**
   * Registers the given array of ActionMenuItems in the factory making them available for use in action menus.
   *
   * @param actionMenuItems The action menu items to make available.
   */
  static registerActionMenuItems(actionMenuItems: Array<ActionMenuItem>) {
    // Sanity check on input and current state
    if (!Array.isArray(actionMenuItems)) {
      throw new Error("actionMenuItems must be a valid array");
    }

    actionMenuItems.forEach((actionMenuItem) => this.registerActionMenuItem(actionMenuItem));
  }

  /**
   * Creates an action menu with the requested action menu items.
   *
   * @param editor Editor instance for the action menu.
   * @param config The editor, extension, and action menu configuration.
   * @param items A string array of action menu items to add to the action menu.
   *
   * @returns Array of instantiated action menu items that can be added to the desired action menu parent container.
   */
  static createActionMenu(editor: Editor, config: IEditorConfig, items: Array<string>) {
    if (!editor) {
      return null;
    }

    return items.map((itemName: string, index: number) => {
      if (ActionMenuFactory.registeredItems[itemName]) {
        const actionMenuItem = ActionMenuFactory.registeredItems[itemName];
        if (actionMenuItem.isExternallyTranslated) {
          return actionMenuItem.create(editor, config);
        } else {
          return (
            <I18nextProvider key={`itemName-${index}`} i18n={ActionMenuFactory.i18n}>
              {actionMenuItem.create(editor, config)}
            </I18nextProvider>
          );
        }
      } else {
        throw new Error(`Action menu item "${itemName}" not registered.`);
      }
    });
  }
}
