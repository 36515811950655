import { TOptions } from "i18next";
import { TFunction } from "i18next";

function getPlatformName(): string {
  return typeof navigator !== "undefined" && /Mac/.test(navigator.platform) ? "Mac" : "Windows";
}

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function getEditorToolbarButtonTooltip(t: TFunction, toolbarItemName: string, options: TOptions = { ns: "editor" }) {
  const tooltipText = t(`Editor.${capitalizeFirstLetter(toolbarItemName)}.Tooltip`, options);
  const shortcut = t(`Editor.${capitalizeFirstLetter(toolbarItemName)}.Shortcut.${getPlatformName()}`, options);

  if (shortcut) {
    return `${tooltipText} (${shortcut})`;
  }

  return tooltipText;
}
