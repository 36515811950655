import { useReactiveVar } from "@apollo/client";
import { ICommand, ICommandContext, ICommandRequest } from "@bigpi/cookbook";
import { useAuthUser } from "@frontegg/react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Fab, Typography } from "@mui/material";
import { useGetLatestConfigsByPrefixQuery } from "GraphQL/Generated/Apollo";
import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { DefaultDialogTransition } from "Components/DialogTransition/DefaultDialogTransition";
import { CommandAutocomplete } from "./CommandAutocomplete";
import { CommandContext } from "CommandContext";
import { ICommandOutputValues, outputTypeMapping } from "./DisplayOutputTemplates";
import { CommandConnectionActiveVar } from "GraphQL/AuthenticatedApolloProvider";
import { useOnlineStatus } from "Hooks/useOnlineStatus";

// css
import "./CommandsDialog.css";

interface ICommandsDialogProps {
  isFatalConnectionError: boolean;
  onRunCommand: (command: ICommand, commandRequest: Partial<ICommandRequest>) => void;
}

export const CommandsDialog: React.FC<ICommandsDialogProps> = (props) => {
  const { isFatalConnectionError, onRunCommand } = props;
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [selectedCommand, setSelectedCommand] = useState<ICommand | undefined>();
  const [selectedOutputValues, setSelectedOutputValues] = useState<ICommandOutputValues>();
  const { t } = useTranslation();
  const commandConnectionActive = useReactiveVar(CommandConnectionActiveVar);
  const isOnline = useOnlineStatus();
  const location = useLocation();
  const commandContext = useReactiveVar<ICommandContext>(CommandContext.getCommandContext);

  const user = useAuthUser();
  const { data, loading, error } = useGetLatestConfigsByPrefixQuery({
    variables: {
      keyPrefix: "bistro-command-",
      organizationId: user.tenantId,
    },
  });

  let commands: Array<ICommand> = [];

  if (data?.LatestConfigsByPrefix) {
    data.LatestConfigsByPrefix.forEach((config) => {
      // Handles in case of empty command
      const configData = config.data ? JSON.parse(config.data) : undefined;
      if (configData && !configData.disabled) {
        commands.push(configData);
      }
    });
  }

  useEffect(() => {
    if (selectedCommand && selectedCommand && selectedCommand.outputTypes) {
      setSelectedOutputValues(outputTypeMapping[selectedCommand.outputTypes[0]]);
    }
  }, [selectedCommand]);

  const onClose = useCallback(() => {
    setShowDialog(false);
    setSelectedCommand(undefined);
    setSelectedOutputValues(undefined);
  }, []);

  function onCommandSelected(command: ICommand) {
    // This needs to be first
    setSelectedOutputValues(undefined);
    setSelectedCommand(command);
  }

  const commandsAvailable = useCallback(() => {
    // Don't show the dialog if the network is down
    let result = !isFatalConnectionError && isOnline;

    // Temporary check to see if we should show the dialog
    if (!commandContext?.session?.[0]?.workspaceId || !commandContext?.session?.[1]?.workspaceBoardId) {
      result = false;
    }

    return result;
  }, [isFatalConnectionError, commandConnectionActive, isOnline, commandContext]);

  useEffect(() => {
    window.document.addEventListener("keydown", onKeyDown);
    window.document.addEventListener("show-command-dialog", showCommandDialog);

    function showCommandDialog() {
      if (commandsAvailable()) {
        setShowDialog(true);
      }
    }

    function onKeyDown(e: KeyboardEvent) {
      if (e.keyCode === 191 && e.metaKey) {
        if (commandsAvailable()) {
          setShowDialog(true);
        }
      }

      if (showDialog && e.keyCode === 27) {
        onClose();
      }
    }

    return () => {
      window.document.removeEventListener("keydown", onKeyDown);
      window.document.removeEventListener("show-command-dialog", showCommandDialog);
    };
  }, [onClose, commandsAvailable]);

  return (
    <>
      <Dialog open={showDialog} onClose={onClose} TransitionComponent={DefaultDialogTransition} fullWidth maxWidth="sm">
        <DialogTitle>{t("Components.CommandsDialog.Title")}</DialogTitle>
        <DialogContent>
          {isFatalConnectionError && (
            <>
              <Box>
                <Typography variant="caption" color="error.main">
                  {t("Components.CommandsDialog.NotAvailableFatalTitle")}
                </Typography>
              </Box>
              <Box>
                <Typography variant="caption">{t("Components.CommandsDialog.NotAvailableFatalTip")}</Typography>
              </Box>
            </>
          )}
          {!isFatalConnectionError && (!commandConnectionActive || !isOnline) && (
            <Typography variant="caption" color="warning.main">
              {t("Components.CommandsDialog.NotAvailable")}
            </Typography>
          )}
          <CommandAutocomplete onCommandSelected={(command: ICommand) => onCommandSelected(command)} />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{t("Global.Action.Close")}</Button>
          <Button onClick={onRun} disabled={!selectedCommand || !selectedOutputValues || isFatalConnectionError || !isOnline}>
            {t("Components.CommandsDialog.Run")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );

  function onRun() {
    if (!selectedCommand || !selectedOutputValues) {
      return;
    }

    onRunCommand(selectedCommand, {
      callerData: JSON.stringify({ outputType: selectedOutputValues.outputType }),
      outputTemplate: selectedOutputValues.outputTemplate,
    });
    setSelectedCommand(undefined);
    setSelectedOutputValues(undefined);
    setShowDialog(false);
  }
};
