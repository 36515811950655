import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";

export interface IHeadlineDataGridMenuProps {
  anchorPosition: { top: number; left: number } | undefined;
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
}

export function HeadlineDataGridMenu(props: IHeadlineDataGridMenuProps) {
  const { anchorPosition, open, onClose, onDelete } = props;
  const { t } = useTranslation();

  return (
    <Menu
      open={open}
      anchorReference="anchorPosition"
      anchorPosition={anchorPosition}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      keepMounted
      onClose={onClose}
    >
      <MenuItem
        onClick={(event) => {
          onDelete();
          onClose();
          event.stopPropagation();
        }}
      >
        {t("Global.Action.Delete")}
      </MenuItem>
    </Menu>
  );
}
