import { useCallback, useState } from "react";

import { FileItemStandard } from "Components/FileItem/FileItemStandard";
import { ILibraryBundleItemProps, LibraryBundleItem } from "./LibraryBundleItem";

export type ILibraryBundleItemStandardProps = Omit<
  ILibraryBundleItemProps,
  "isSelectable" | "listItem" | "onMouseEnter" | "onMouseLeave"
>;

export function LibraryBundleItemStandard(props: ILibraryBundleItemStandardProps) {
  const [listEl, setListEl] = useState<HTMLElement | null>(null);

  const handleMouseEnter = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setListEl(event.currentTarget);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setListEl(null);
  }, []);

  const isSelectionEnabled = Boolean(listEl);

  return (
    <LibraryBundleItem
      {...props}
      isSelectable={isSelectionEnabled ? true : false}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      listItem={FileItemStandard}
    />
  );
}
