/**
 * Available colors.
 *
 * @createZodSchema
 */
export enum TLColorStyle {
  black = "black",
  grey = "grey",
  "light-violet" = "light-violet",
  violet = "violet",
  blue = "blue",
  "light-blue" = "light-blue",
  yellow = "yellow",
  orange = "orange",
  green = "green",
  "light-green" = "light-green",
  "light-red" = "light-red",
  red = "red",
}
