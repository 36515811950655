import { List } from "@mui/material";
import { useTranslation } from "react-i18next";

import { DocumentListItemStandard } from "Components/DocumentListItem/DocumentListItemStandard";
import { DocumentsQuery } from "GraphQL/Generated/Apollo";
import { DocumentsListMenu } from "./DocumentsListMenu";

interface IDocumentsListProps {
  loading: boolean;
  rows: DocumentsQuery["documents"];
  selectedIds: Array<string>;
  onSelectionChange: (id: string) => void;
}

export function DocumentsList(props: IDocumentsListProps) {
  const { loading, rows, selectedIds, onSelectionChange } = props;
  const { t } = useTranslation();

  return (
    <>
      {!loading && rows.length > 0 && (
        <List
          component="nav"
          aria-label={t("Pages.Documents.List.AvailableDocuments")}
          sx={{ display: "flex", flexDirection: "column", flex: 1 }}
        >
          {rows.map((row) => (
            <DocumentListItemStandard
              key={row.id}
              checked={selectedIds.includes(row.id)}
              onSelectionChange={onSelectionChange}
              row={row}
              secondaryAction={(documentId) => <DocumentsListMenu documentId={documentId} />}
            />
          ))}
        </List>
      )}
    </>
  );
}
