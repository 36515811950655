import { ApolloClient } from "@apollo/client";
import { Box, Editor, TLShape, Vec, createShapeId } from "@tldraw/tldraw";
import Handlebars from "handlebars";
import { TFunction } from "i18next";
import { v4 as uuidV4 } from "uuid";

import { AddItemsColumnFormatMapping } from "BoardComponents/DataFormatting/DataFormatting";
import { ProcessHtmlDocument, ProcessHtmlMutation, ProcessHtmlMutationVariables } from "GraphQL/Generated/Apollo";
import { createShapesAtEmptyPoint } from "./CreateShapeUtils";

const DEFAULT_SHAPE_MARGIN = 100;

/**
 * Adds an HTML document to the board from the given HTML.
 *
 * @param html The HTML document to add.
 * @param editor TLDraw editor instance.
 * @param position Source position to be used as reference.
 * @param apolloClient Apollo client to use.
 */
export async function addHtmlDocumentToBoard(
  html: string,
  editor: Editor,
  position: TLShape | Vec | Box | null,
  apolloClient: ApolloClient<object>,
) {
  const htmlDocumentShapeUtils = editor.shapeUtils.htmlDocument;

  // Call Doubtfire mutation to process html
  let processedHtml = html;
  try {
    if (html.trim().length > 0) {
      const res = await apolloClient.mutate<ProcessHtmlMutation, ProcessHtmlMutationVariables>({
        mutation: ProcessHtmlDocument,
        variables: {
          sourceDocument: html,
        },
      });
      processedHtml = res.data?.processHtml.resultDocument || "";
    }
  } catch (e) {
    console.error(e);
  }

  createShapesAtEmptyPoint(
    editor,
    [
      {
        id: createShapeId(uuidV4()),
        type: "htmlDocument",
        props: {
          ...htmlDocumentShapeUtils?.getDefaultProps(),
          html: processedHtml,
        },
      },
    ],
    position,
    true,
    "horizontal",
    DEFAULT_SHAPE_MARGIN,
  );
}

/**
 * Adds an HTML document to the board from the given data context and template.
 *
 * @param editor TLDraw editor instance.
 * @param template The Handlebars template to use.
 * @param dataContext The data context for the template.
 * @param position Source position to be used as reference.
 * @param t Translation function.
 * @param apolloClient Apollo client to use.
 */
export async function addTemplatedDocumentToBoard(
  editor: Editor,
  template: string,
  dataContext: any,
  position: TLShape | Vec | Box | null,
  t: TFunction<"translation", undefined>,
  apolloClient: ApolloClient<object>,
) {
  const html = runTemplate(dataContext, template, t);
  await addHtmlDocumentToBoard(html, editor, position, apolloClient);
}

/**
 * Generates HTML with the given template and data.
 *
 * @param dataContext The data context for the template.
 * @param template The Handlebars template to use.
 * @param t Translation function.
 *
 * @returns The HTML created by the template.
 */
export function runTemplate(dataContext: any, template: string, t: TFunction<"translation", undefined>) {
  const templateRun = Handlebars.compile(template);

  // Add helpers to Handlebars
  Object.keys(AddItemsColumnFormatMapping).forEach((format: any) => {
    Handlebars.registerHelper(format, (value: any) => {
      return AddItemsColumnFormatMapping[format as keyof typeof AddItemsColumnFormatMapping](value, t);
    });
  });

  // Generate the HTML
  return templateRun(dataContext);
}
