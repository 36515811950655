import { useAuthUser } from "@frontegg/react";
import { Dialog, DialogTitle, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useTranslation } from "react-i18next";

import { Organization, SortOrder } from "GraphQL/Generated/Apollo";
import { useOrganizationsQuery } from "GraphQL/Generated/Apollo";
import { CheckSharp } from "@mui/icons-material";

export interface OrganizationSelectDialogProps {
  open: boolean;
  selectedValue: string;
  onClose: (value: string) => void;
}

export function OrganizationSelectDialog(props: OrganizationSelectDialogProps) {
  const { t } = useTranslation();
  const user = useAuthUser();
  const { onClose, selectedValue, open } = props;
  const {
    data: organizationData,
    loading: organizationLoading,
    error: organizationError,
  } = useOrganizationsQuery({
    variables: {
      orderBy: {
        name: SortOrder.Asc,
      },
    },
  });

  const organizations = organizationData?.organizations ?? [];

  // Filter out organizations the user may be able to read (platform admins) but is not a member of and therefore cannot switch to
  const memberOrganizations = organizations.filter((organization) => user?.tenantIds?.includes(organization.id));

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value: Organization) => {
    onClose(value.id);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>{t("Components.OrganizationSelectDialog.Title")}</DialogTitle>

      <List sx={{ pt: 0 }}>
        {memberOrganizations.map((organization) => (
          <ListItem disableGutters disablePadding onClick={() => handleListItemClick(organization)} key={organization.id}>
            <ListItemButton>
              {user.tenantId === organization.id && (
                <>
                  <ListItemIcon>
                    <CheckSharp />
                  </ListItemIcon>
                  <ListItemText primary={organization.name} />
                </>
              )}
              {user.tenantId !== organization.id && (
                <>
                  <ListItemText inset primary={organization.name} />
                </>
              )}
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}
