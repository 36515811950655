import { Extension } from "@tiptap/core";

import { getIndentClassFromIndentLevel, getIndentLevelByClass } from "../../Utils/CommandUtil.js";
import { indent, outdent } from "./IndentCommand.js";

type IndentOptions = {
  types: Array<string>;
  indentLevels: Array<number>;
  defaultIndentLevel: number;
};

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    indent: {
      indent: () => ReturnType;
      outdent: () => ReturnType;
    };
  }
}

export const IndentLevels: Array<number> = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

export enum IndentProps {
  MIN = 0,
  MAX = 9,

  MORE = 1,
  LESS = -1,
}

export const Indent = Extension.create<IndentOptions>({
  name: "indent",

  addOptions() {
    return {
      types: ["heading", "paragraph", "title"],
      indentLevels: IndentLevels,
      defaultIndentLevel: IndentLevels[0],
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          indent: {
            default: this.options.defaultIndentLevel,
            renderHTML: (attributes) => {
              const indentClass = getIndentClassFromIndentLevel(attributes.indent);
              return { class: indentClass };
            },
            parseHTML: (element) => {
              const indentLevel = getIndentLevelByClass(element.className);
              if (!indentLevel || indentLevel < IndentProps.MIN || indentLevel > IndentProps.MAX) {
                return this.options.defaultIndentLevel;
              }

              return indentLevel;
            },
          },
        },
      },
    ];
  },

  addCommands() {
    return {
      indent,
      outdent,
    };
  },

  addKeyboardShortcuts() {
    return {
      Tab: () => this.editor.commands.indent(),
      "Shift-Tab": () => this.editor.commands.outdent(),
    };
  },
});
