import { CommandActionType } from "@bigpi/cookbook";
import { createShapeId } from "@tldraw/tldraw";
import { v4 as uuidV4 } from "uuid";

import { RequestPlugInBase } from "./RequestPlugInBase";
import { ICommandRequestPlugInInputs } from "Components/CommandManagers/WorkspaceBoard/WorkspaceBoardCommandManager";

export class ProcessTextRequestPlugIn extends RequestPlugInBase {
  // *********************************************
  // Public properties
  // *********************************************/
  /**
   * @inheritdoc
   */
  name = CommandActionType.ProcessTextAction;

  // *********************************************
  // Public methods
  // *********************************************/
  /**
   * @inheritdoc
   */
  public async execute(input: ICommandRequestPlugInInputs): Promise<void> {
    const { app, commandRequest } = input;

    if (!app) {
      console.warn(`No app instance for command Request ${commandRequest.requestId}`);
      return;
    }

    // Create source and target IDs and binding information when possible
    const callerData = JSON.parse(commandRequest.callerData || "{}");

    // Find the source shape ID(s) from the context, if set
    const sourceIds = commandRequest.commandContext.selection?.shapeIds;

    if (Array.isArray(sourceIds) && sourceIds.length > 0) {
      callerData.sourceIds = sourceIds;

      // Add a request to bind source and target shapes
      callerData.binding = {
        // Use defaults (or override by changing the values below)
        arrowProps: {},
      };
    }

    // Create a target ID for the new shape that will hold the result(s)
    callerData.targetId = createShapeId(uuidV4());

    // Update the event data
    commandRequest.callerData = JSON.stringify(callerData);
  }
}
