import { SortKeys } from "@bigpi/cookbook";
import { FormControl, InputAdornment, MenuItem, OutlinedInput, Select } from "@mui/material";
import { Sort } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

// *********************************************
// Types/Interfaces/Constants/Enums
// *********************************************/
interface ISortOptionListProps {
  onSortOptionChange: (SortKeys: SortKeys) => void;
  selectedSortOption: SortKeys;
  sortOptions: Array<SortKeys>;
}

// *********************************************
// Component
// *********************************************/
export function SortOptionList(props: ISortOptionListProps) {
  const { onSortOptionChange, selectedSortOption, sortOptions } = props;
  const { t } = useTranslation();

  return (
    <FormControl size="small">
      <Select
        displayEmpty
        id="sort-option"
        notched
        onChange={(e) => onSortOptionChange(e.target.value as SortKeys)}
        sx={{ maxWidth: "240px" }}
        input={
          <OutlinedInput
            startAdornment={
              <InputAdornment position="start">
                <Sort />
              </InputAdornment>
            }
          />
        }
        value={selectedSortOption}
        renderValue={(value) => t(`Components.ContentFilterPanel.SortOptionList.${value}`)}
        autoWidth
      >
        {sortOptions.map((sortOption) => (
          <MenuItem key={sortOption} value={sortOption}>
            {t(`Components.ContentFilterPanel.SortOptionList.${sortOption}`)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
