import { gql } from "@apollo/client";

export const CREATE_USER_GROUP = gql`
  mutation CreateUserGroup($input: CreateUserGroupInput!) {
    createUserGroup(input: $input) {
      id
      name

      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const UPDATE_USER_GROUP = gql`
  mutation UpdateUserGroup($input: UpdateUserGroupInput!) {
    updateUserGroup(input: $input) {
      id
      name

      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const DELETE_USER_GROUP = gql`
  mutation DeleteUserGroup($input: DeleteUserGroupInput!) {
    deleteUserGroup(input: $input) {
      id
    }
  }
`;
