import { Box, Button } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { CommandContext } from "CommandContext";
import { useDeleteUserGroupMutation, useUserGroupQuery } from "GraphQL/Generated/Apollo";
import { RoutePaths } from "RoutePaths";
import { UserGroupEdit } from "./UserGroupEdit";
import { UserGroupMemberList } from "./UserGroupMemberList";
import { UserGroupNew } from "./UserGroupNew";

export function UserGroupPage() {
  const { t } = useTranslation();
  const params = useParams();
  const [deleteUserGroup] = useDeleteUserGroupMutation();
  const { data, loading, error } = useUserGroupQuery({ variables: { id: params.id || "" } });
  const navigate = useNavigate();

  // Set current application session context
  CommandContext.replaceSessionContext([{ userGroupId: params.id || "" }]);

  function deleteCurrentItem() {
    deleteUserGroup({
      variables: {
        input: {
          id: params.id || "",
        },
      },
    }).then(() => {
      navigate(RoutePaths.userGroups.path);
    });
  }

  // User group can be deleted if it is not a system group
  const canDeleteGroup = data?.userGroup?.isSystem === false;

  return (
    <>
      <Helmet>
        <title>{t("Pages.UserGroups.Title")}</title>
      </Helmet>
      <Box sx={{ flex: 1, m: 3, p: 3 }}>
        {params.id === "new" && <UserGroupNew />}
        {params.id !== "new" && params.id && (
          <>
            <Button sx={{ float: "right" }} variant="outlined" onClick={deleteCurrentItem} disabled={!canDeleteGroup}>
              {t("Global.Action.Delete")}
            </Button>
            <UserGroupEdit id={params.id} />
            <UserGroupMemberList userGroupId={params.id} />
          </>
        )}
      </Box>
    </>
  );
}
