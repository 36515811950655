import { WebSocketProvider } from "@bigpi/y-websocket";
import CollaborationCursor from "@tiptap/extension-collaboration-cursor";
import { EditorOptions } from "@tiptap/react";
import { pino } from "pino";
import { DependencyList, useEffect, useState } from "react";
import * as Y from "yjs";

import { Collaboration } from "../Extensions/Collaboration/CollaborationExtension.js";
import * as DocumentConstants from "../DocumentEditor/DocumentConstants.js";
import { EditorAuthError } from "./EditorAuthError.js";
import { EditorContentError } from "./EditorContentError.js";
import { useEditor } from "./useEditor.js";

export interface EditorUser {
  accessToken: string;
  name: string;
  color: string;
}

export interface CollaborativeDocumentEditorConfig {
  documentId: string;
  editorUser: EditorUser;
  runnerHttpUrl: string;
  runnerWsUrl: string;
  runnerPath: string;
}

export const useCollaborativeEditor = (
  editorBaseOptions: Partial<EditorOptions> | undefined,
  collaborationConfig: CollaborativeDocumentEditorConfig,
  deps: DependencyList = [],
) => {
  const { documentId, editorUser, runnerHttpUrl, runnerPath, runnerWsUrl } = collaborationConfig;

  const [isAuthLoading, setAuthIsLoading] = useState<boolean>(true);
  const [isSynced, setIsSynced] = useState<boolean>(false);
  const [authError, setAuthError] = useState<EditorAuthError | null>(null);
  const [contentError, setContentError] = useState<EditorContentError | null>(null);
  const [provider, setProvider] = useState<WebSocketProvider | null>(null);
  const [editorOptions, setEditorOptions] = useState<Partial<EditorOptions>>();
  const [yDocument, setYDocument] = useState<Y.Doc | null>(null);

  // Create document and provider once (without auto-connect)
  useEffect(() => {
    const newYDoc = new Y.Doc();
    let logger: pino.BaseLogger | undefined;

    // Optional logger. Uncomment to use
    // logger = pino({ browser: { asObject: true }, level: "trace", timestamp: pino.stdTimeFunctions.isoTime });

    const newProvider = new WebSocketProvider(`${runnerWsUrl}/${runnerPath}`, documentId, newYDoc, {
      connect: false,
      logger,
    });

    newProvider.on("synced", () => {
      setIsSynced(true);
    });

    newProvider.on("unauthorized", () => {
      console.error("Unauthorized");
      setAuthError(EditorAuthError.Unauthorized);
    });

    // Set the provider
    setYDocument(newYDoc);
    setProvider(newProvider);

    // Destroy provider when component is disposed
    return () => {
      newProvider.destroy();
    };
  }, []);

  // Call authentication endpoint
  useEffect(() => {
    if (isAuthLoading) {
      const headers = new Headers({
        authorization: `Bearer ${editorUser.accessToken}`,
      });

      // Make sure we're authenticated
      fetch(`${runnerHttpUrl}/auth`, {
        credentials: "include",
        headers,
      }).then(
        (result) => {
          if (result.ok) {
            setAuthIsLoading(false);
          } else {
            setAuthError(EditorAuthError.UnableToAuthenticate);
            console.error("Error authenticating", result.status, result.statusText);
          }
        },
        (e) => {
          setAuthError(EditorAuthError.UnableToAuthenticate);
          console.error("Error authenticating", e);
        },
      );
    }
  }, [isAuthLoading]);

  // Once we've got the authentication info and provider, connect and wait until document is synced
  useEffect(() => {
    if (provider !== null && isAuthLoading === false && !authError) {
      // Start connection to server
      provider.connect();
    }
  }, [authError, isAuthLoading, provider]);

  // Create the editor once the document is synced
  useEffect(() => {
    if (editorBaseOptions && isSynced) {
      // Create "sanitized" user object for awareness
      const user = {
        color: editorUser.color,
        name: editorUser.name,
      };

      // Set the editor options and trigger its (initial) creation/render
      setEditorOptions({
        ...editorBaseOptions,
        enableContentCheck: true,
        extensions: [
          ...(editorBaseOptions?.extensions || []),
          Collaboration.configure({
            document: yDocument,
            field: DocumentConstants.DOCUMENT_YDOC_FIELD_KEY,
          }),
          CollaborationCursor.configure({
            provider: provider,
            // VERY IMPORTANT: Do not add extra fields to the `user` property. They will get synced across all clients!
            user,
          }),
        ],
        onContentError: ({ editor, error, disableCollaboration }) => {
          // Disable collaboration to prevent syncing invalid content
          disableCollaboration();

          // Prevent emitting updates
          const emitUpdate = false;

          // Disable further user input
          editor.setEditable(false, emitUpdate);

          // Set the error message
          setContentError(EditorContentError.InvalidContent);
          console.error("Invalid HTML content", error);
        },
      });
    }
  }, [isSynced, provider, yDocument, editorUser, editorBaseOptions]);

  const optionsToUse = isSynced ? editorOptions : undefined;
  const collaborativeEditor = useEditor(optionsToUse, deps);

  return {
    collaborativeEditor,
    authError,
    contentError,
    isAuthLoading,
    isSynced,
  };
};
