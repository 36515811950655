import { mergeAttributes, Node } from "@tiptap/core";

import { getBaseClassWithoutIndent, getBaseClassWithoutTextAlignment } from "../../Utils/CommandUtil.js";
import { setTitle } from "./SetTitleCommand.js";
import { toggleTitle } from "./ToggleTitleCommand.js";

export interface TitleOptions {
  HTMLAttributes: Record<string, any>;
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    title: {
      /**
       * Set a title node
       */
      setTitle: () => ReturnType;
      /**
       * Toggle a title node
       */
      toggleTitle: () => ReturnType;
    };
  }
}

export const Title = Node.create<TitleOptions>({
  name: "title",

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  addAttributes() {
    return {
      class: {
        default: null,
        renderHTML(attributes) {
          return {
            class: attributes.class,
          };
        },
        parseHTML: (element: HTMLElement) => {
          const classAttr = element.hasAttribute("class") ? element.getAttribute("class") : null;

          // Indent is handled by the IndentExtension
          const baseClassWithoutIndent = getBaseClassWithoutIndent(classAttr);

          // Text alignment is handled by the TextAlignExtension
          const baseClassWithoutTextAlignmentAndIndent = getBaseClassWithoutTextAlignment(baseClassWithoutIndent);

          return baseClassWithoutTextAlignmentAndIndent;
        },
      },
    };
  },

  content: "inline*",

  group: "block",

  parseHTML() {
    // Title node = A paragraph tag with "title" class, for e.g., <p class="title"></p>
    return [
      {
        tag: `p`,
        getAttrs: (node) => (node as HTMLElement).className.includes("title") && null,
      },
    ];
  },

  renderHTML({ node, HTMLAttributes }) {
    if (HTMLAttributes.class && !HTMLAttributes.class.includes("title")) {
      HTMLAttributes.class += " title";
    } else if (!HTMLAttributes.class) {
      HTMLAttributes.class = "title";
    }

    return [`p`, mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },

  addCommands() {
    return {
      ...this.parent?.(),
      setTitle,
      toggleTitle,
    };
  },
});
