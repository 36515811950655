import { IAnalysisFieldConfig, AnalysisFieldTypeEnum } from "@bigpi/cookbook";

export const topicDiscussionAnalysisFieldsConfig: Array<IAnalysisFieldConfig> = [
  {
    field: "category",
    label: "Components.Analyses.TopicDiscussionAnalysis.Fields.Category",
    type: AnalysisFieldTypeEnum.String,
    isSelectAllEnabled: true,
    isSortEnabled: true,
  },
  {
    field: "ticker",
    label: "Components.Analyses.TopicDiscussionAnalysis.Fields.Ticker",
    type: AnalysisFieldTypeEnum.String,
    isSortEnabled: true,
    isSelectAllEnabled: true,
  },
  {
    field: "section",
    label: "Components.Analyses.TopicDiscussionAnalysis.Fields.Section",
    type: AnalysisFieldTypeEnum.String,
    isSortEnabled: true,
    isSelectAllEnabled: true,
  },
  {
    field: "eventDate",
    label: "Components.Analyses.TopicDiscussionAnalysis.Fields.EventDate",
    type: AnalysisFieldTypeEnum.Date,
  },
  {
    field: "topic",
    label: "Components.Analyses.TopicDiscussionAnalysis.Fields.Topic",
    type: AnalysisFieldTypeEnum.String,
    isSortEnabled: true,
    isSelectAllEnabled: true,
  },
];
