import { DefaultHelpMenu, KeyboardShortcutsMenuItem, TldrawUiMenuGroup } from "@tldraw/tldraw";
import { memo } from "react";

/**
 * Cloned from https://github.com/tldraw/tldraw/blob/41b5fffa2ef17ff852c1efc227a5ad5c37dc5c7a/packages/tldraw/src/lib/ui/components/HelpMenu/DefaultHelpMenuContent.tsx#L7
 * and made some modifications. Changes -
 *
 * - Removed `LanguageMenu` from the help menu.
 */
function HelpMenuContent() {
  return (
    <>
      {/* Modified by BigPi - Removed `LanguageMenu` from the help menu.
			<LanguageMenu /> */}
      <KeyboardShortcutsMenuItem />
    </>
  );
}

export const HelpMenu = memo(function CustomHelpMenu() {
  return (
    <DefaultHelpMenu>
      <HelpMenuContent />
    </DefaultHelpMenu>
  );
});
