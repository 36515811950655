import { RawCommands } from "@tiptap/core";

import { getBaseClassWithoutHeadingAndTitle } from "../../Utils/CommandUtil.js";
import { ListItem } from "../ListItem/ListItemExtension.js";
import { Paragraph } from "./ParagraphExtension.js";

// This command is used to set a paragraph node.
export const setParagraph: RawCommands["setParagraph"] =
  () =>
  ({ chain, state }) => {
    const { $from } = state.selection;
    // "listItem" node represents the "li" tag which is present on
    // "-1" depth from the current selection i.e. the paragraph node.
    //
    // <ol>
    //   <li>
    //     <p>text</p>
    //   </li>
    // </ol>
    const listItem = $from.node(-1);
    if (listItem.type.name === ListItem.name) {
      const classAttr = getBaseClassWithoutHeadingAndTitle(listItem.attrs.class);

      // This is required to apply the correct text style for the
      // list item marker.
      return chain().setNode(Paragraph.name).selectParentNode().updateAttributes(ListItem.name, { class: classAttr }).run();
    }

    return chain().setNode(Paragraph.name).run();
  };
