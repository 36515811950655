import { Mark } from "@tiptap/core";
import { Link as TipTapLink, LinkOptions as TipTapLinkOptions } from "@tiptap/extension-link";

import { ITextContentDetails } from "../../Utils/CommandUtil.js";
import { LinkActionsConfig } from "../IExtensionOptions.js";
import { setLinkWithText } from "./SetLinkWithTextCommand.js";

// We need to declare our custom commands so that TS doesn't throw warning everywhere saying these commands do not exist.
declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    customLinkCommands: {
      setLinkWithText: (textContentDetails: ITextContentDetails | null, link: string) => ReturnType;
    };
  }
}

export interface LinkOptions extends TipTapLinkOptions {
  LinkActions?: Array<LinkActionsConfig>;
}

export const Link = TipTapLink.extend<LinkOptions>({
  addOptions() {
    return {
      ...this.parent?.(),
      LinkActions: [],
    };
  },

  addCommands() {
    // Registering our custom commands for "link". These commands
    // are now callable from everywhere.
    return {
      ...this.parent?.(),
      setLinkWithText,
    };
  },
}).configure({
  // We have a LinkActionsMenu that opens on click of a link, so we don't want to open the link on click.
  openOnClick: false,
  // We need the type assertion here because the `configure` method is not typed correctly and it
  // always returns `Mark<any, any>`.
}) as Mark<LinkOptions>;
