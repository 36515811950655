import { ContentTypes } from "@bigpi/cookbook";
import { Permissions } from "@bigpi/permission";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import MoreIcon from "@mui/icons-material/MoreHoriz";
import PublishOutlinedIcon from "@mui/icons-material/PublishOutlined";
import { Box, IconButton, Menu, MenuItem, ListItemIcon, ListItemText, Tooltip, Divider, Link } from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";

import { HasPermission } from "Components/HasPermission/HasPermission";
import { HasWorkspaceRole } from "Components/HasWorkspaceRole/HasWorkspaceRole";
import { ObjectRole } from "GraphQL/Generated/Apollo";
import { getWorkspaceFileDownloadUrl } from "Utils/WorkspaceFileUtils";
import { DeleteWorkspaceFileDialog } from "./DeleteWorkspaceFileDialog";
import { PublishToLibraryDialog } from "./PublishToLibraryDialog";
import { RenameWorkspaceFileDialog } from "./RenameWorkspaceFileDialog";

export interface IWorkspaceFileListMenuProps {
  workspaceFileFilename: string;
  workspaceFileId: string;
  workspaceFileMimeType: ContentTypes;
  workspaceId: string;
}

export function WorkspaceFileListMenu(props: IWorkspaceFileListMenuProps) {
  const { workspaceFileFilename, workspaceFileId, workspaceId, workspaceFileMimeType } = props;

  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [renameDialogOpen, setRenameDialogOpen] = React.useState(false);
  const [publishToLibraryDialogOpen, setPublishToLibraryDialogOpen] = React.useState(false);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title={t("Global.Action.Actions") || ""}>
          <IconButton
            onClick={handleClick}
            size="small"
            aria-controls={open ? "workspace-file-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <MoreIcon />
          </IconButton>
        </Tooltip>
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 24,
              height: 24,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {/* Anyone with the view access or above can download */}
        <MenuItem
          component={Link}
          href={getWorkspaceFileDownloadUrl(workspaceId, workspaceFileId, workspaceFileFilename)}
          target="_blank"
        >
          <ListItemIcon>
            <DownloadIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={t("Global.Action.Download")} />
        </MenuItem>

        <HasWorkspaceRole workspaceId={workspaceId} roles={[ObjectRole.Manager, ObjectRole.Owner]}>
          <MenuItem onClick={() => setRenameDialogOpen(true)}>
            <ListItemIcon>
              <DriveFileRenameOutlineIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={t("Global.Action.Rename")} />
          </MenuItem>

          <HasPermission permission={Permissions.PatronLibraryWrite}>
            <MenuItem onClick={() => setPublishToLibraryDialogOpen(true)}>
              <ListItemIcon>
                <PublishOutlinedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={t("Global.Action.PublishToLibrary")} />
            </MenuItem>
          </HasPermission>

          <Divider />

          <MenuItem onClick={() => setDeleteDialogOpen(true)}>
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ color: "danger.main" }} primary={t("Global.Action.Delete")} />
          </MenuItem>
        </HasWorkspaceRole>
      </Menu>

      {deleteDialogOpen && (
        <DeleteWorkspaceFileDialog
          workspaceFileId={workspaceFileId}
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
        />
      )}

      {renameDialogOpen && (
        <RenameWorkspaceFileDialog
          workspaceFileId={workspaceFileId}
          open={renameDialogOpen}
          onClose={() => setRenameDialogOpen(false)}
        />
      )}

      {publishToLibraryDialogOpen && (
        <PublishToLibraryDialog
          open={publishToLibraryDialogOpen}
          onClose={() => setPublishToLibraryDialogOpen(false)}
          selectedWorkspaceFiles={[{ id: workspaceFileId, filename: workspaceFileFilename, mimeType: workspaceFileMimeType }]}
          workspaceId={workspaceId}
        />
      )}
    </React.Fragment>
  );
}
