import { gql } from "@apollo/client";
import { COMMAND_GENERATE_THEME_DISCUSSION_ANALYSIS_TEMPLATE, getObjectHash, ICommandRequest } from "@bigpi/cookbook";
import { useAuthUser } from "@frontegg/react";
import dayjs from "dayjs";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { CommandContext } from "CommandContext";
import { useCommandExecutor } from "Components/CommandManagers/useCommandExecutor";
import {
  SortOrder,
  ThemeDiscussionAnalysesQuery,
  ThemeDiscussionAnalysis,
  useThemeDiscussionAnalysesLazyQuery,
  useThemeDiscussionAnalysesQuery,
  useThemeDiscussionAnalysisParentTranscriptQuery,
  useThemeDiscussionAnalysisParentTranscriptsQuery,
} from "GraphQL/Generated/Apollo";
import { DataUtils } from "Utils/DataUtils";
import { ThemeDiscussionAnalysisChart } from "./ThemeDiscussionAnalysisChart";

interface ThemeDiscussionAnalysisContainerProps {
  transcriptId: string;
}

// *********************************************
// Private constants
// *********************************************/
const AUTO_REFRESH_TIMEOUT = 10000;

const PARENT_TRANSCRIPT_QUERY = gql`
  query ThemeDiscussionAnalysisParentTranscript($id: ID!) {
    transcript(id: $id) {
      id
      displaySymbol
      eventType
      itemDate
    }
  }
`;

const PARENT_TRANSCRIPT_SEARCH_QUERY = gql`
  query ThemeDiscussionAnalysisParentTranscripts(
    $filters: TranscriptFilters
    $limit: Int
    $offset: Int
    $orderBy: [TranscriptOrderBy]
  ) {
    transcripts(offset: $offset, limit: $limit, orderBy: $orderBy, filters: $filters) {
      results {
        id
        displaySymbol
        eventType
        itemDate
      }
    }
  }
`;

// This comes from config in future
const THEME_DISCUSSION_CHART_CONFIG: Record<string, keyof UsedAnalysis> = {
  xField: "discussionDepth",
  yField: "themeName",
  fillColorField: "itemId",
};

const TOPICS_ANALYSES_CHART_CONFIG: Record<string, keyof UsedAnalysis | keyof ThemeDiscussionTopicsAnalyses> = {
  xField: "discussionDepth",
  yField: "topic",
  fillColorField: "itemId",
};

export interface UsedAnalysis extends Omit<ThemeDiscussionAnalysis, "createdAt" | "createdBy" | "updatedAt" | "updatedBy"> {}
export type ThemeDiscussionTopicsAnalyses = Partial<ThemeDiscussionAnalysis["topicsAnalysisItems"][0]> & { itemId: string };

// *********************************************
// Component
// *********************************************/
export function ThemeDiscussionAnalysisContainer(props: ThemeDiscussionAnalysisContainerProps) {
  const { transcriptId: datasetAParentItemId } = props;

  const user = useAuthUser();
  const commandExecutor = useCommandExecutor();
  const { t } = useTranslation();

  // State
  const [selectedTheme, setSelectedTheme] = useState<string>("");

  const [
    getThemeDiscussionAnalysisA,
    {
      loading: datasetAResultLoading,
      error: datasetAResultError,
      data: datasetAResult,
      startPolling: startPollingA,
      stopPolling: stopPollingA,
    },
  ] = useThemeDiscussionAnalysesLazyQuery();
  const [
    getThemeDiscussionAnalysisB,
    {
      loading: datasetBResultLoading,
      error: datasetBResultError,
      data: datasetBResult,
      startPolling: startPollingB,
      stopPolling: stopPollingB,
    },
  ] = useThemeDiscussionAnalysesLazyQuery();

  /* Data fetching  */
  // Get datasetA parent item
  const { data: datasetAParentItem } = useThemeDiscussionAnalysisParentTranscriptQuery({
    variables: {
      id: datasetAParentItemId,
    },
  });

  // Get datasetB parent item
  const { data: datasetBParentItem } = useThemeDiscussionAnalysisParentTranscriptsQuery({
    variables: {
      limit: 1,
      offset: 0,
      orderBy: [{ itemDate: SortOrder.Desc }],
      filters: {
        // Use the same event type as dataset A
        eventType: datasetAParentItem?.transcript?.eventType,
        // On server it uses "lte" operator, so subtract 1 day from dataset A item date
        endDate: dayjs(datasetAParentItem?.transcript?.itemDate)
          .add(-1, "day")
          .toISOString(),

        // Limit to same ticker symbol
        symbols: [datasetAParentItem?.transcript?.displaySymbol || ""],
      },
    },
  });

  // Fetch datasetA
  useEffect(() => {
    if (datasetAParentItemId) {
      getThemeDiscussionAnalysisA({
        variables: {
          filters: {
            itemId: datasetAParentItemId,
          },
        },
      });
    }
  }, [datasetAParentItemId]);

  const datasetBParentItemId = datasetBParentItem?.transcripts?.results?.[0]?.id;

  // Fetch datasetB
  useEffect(() => {
    if (datasetBParentItemId) {
      getThemeDiscussionAnalysisB({
        variables: {
          filters: {
            itemId: datasetBParentItemId,
          },
        },
      });
    }
  }, [datasetBParentItemId]);

  // datasetA & datasetB
  const datasetA = datasetAParentItemId ? datasetAResult?.themeDiscussionAnalyses?.results || [] : [];
  const datasetB = datasetBParentItemId ? datasetBResult?.themeDiscussionAnalyses?.results || [] : [];

  // Kick off a request for data generation for dataset A if it doesn't exist yet
  useEffect(() => {
    if (datasetAResult?.themeDiscussionAnalyses?.results?.length === 0) {
      const command = {
        ...COMMAND_GENERATE_THEME_DISCUSSION_ANALYSIS_TEMPLATE,
      };

      // Create the overrides/values that are not set automatically by CommandExecutor
      const commandRequest: Partial<ICommandRequest> = {
        // Create deduplicationId
        deduplicationId: getObjectHash({
          commandId: command.id,
          organizationId: user?.tenantId,
          transcriptId: datasetAParentItemId,
        }),
      };

      // Set the selected transcript into the context to be picked up from there
      CommandContext.patchCommandContext({
        selection: {
          transcriptId: datasetAParentItemId,
        },
      });

      commandExecutor.executeCommand(command, commandRequest);
    }
  }, [datasetAResult]);

  // Kick off a request for data generation for dataset B if it doesn't exist yet
  useEffect(() => {
    if (datasetBResult?.themeDiscussionAnalyses?.results?.length === 0) {
      const command = {
        ...COMMAND_GENERATE_THEME_DISCUSSION_ANALYSIS_TEMPLATE,
      };

      // Create the overrides/values that are not set automatically by CommandExecutor
      const commandRequest: Partial<ICommandRequest> = {
        // Create deduplicationId
        deduplicationId: getObjectHash({
          commandId: command.id,
          organizationId: user?.tenantId,
          transcriptId: datasetBParentItemId,
        }),
      };

      // Set the selected transcript into the context to be picked up from there
      CommandContext.patchCommandContext({
        selection: {
          transcriptId: datasetBParentItemId,
        },
      });

      commandExecutor.executeCommand(command, commandRequest);
    }
  }, [datasetBResult]);

  useEffect(() => {
    setSelectedTheme("");
  }, [datasetAParentItem]);

  // Turn off auto-refresh when data has loaded
  const isUpdatingA: boolean = datasetAParentItemId ? datasetAResult?.themeDiscussionAnalyses?.results?.length === 0 : false;
  const isUpdatingB: boolean = datasetBParentItemId ? datasetBResult?.themeDiscussionAnalyses?.results?.length === 0 : false;

  useEffect(() => {
    if (isUpdatingA) {
      startPollingA(AUTO_REFRESH_TIMEOUT);
    } else {
      stopPollingA();
    }
  }, [isUpdatingA]);

  useEffect(() => {
    if (isUpdatingB) {
      startPollingB(AUTO_REFRESH_TIMEOUT);
    } else {
      stopPollingB();
    }
  }, [isUpdatingB]);

  const selectedThemeSummaries = getSelectedThemeSummariesData();
  return (
    <div className="theme-discussion-analysis-container">
      {/* Themes chart */}
      <ThemeDiscussionAnalysisChart
        data={selectedTheme ? getSelectedThemeData() || [] : [...datasetB, ...datasetA]}
        datasetAParentItemId={datasetAParentItemId}
        datasetBParentItemId={datasetBParentItemId}
        enableReturnToSummary={!!selectedTheme}
        xField={THEME_DISCUSSION_CHART_CONFIG.xField}
        yField={THEME_DISCUSSION_CHART_CONFIG.yField}
        fillColorField={THEME_DISCUSSION_CHART_CONFIG.fillColorField}
        isUpdating={isUpdatingA || isUpdatingB || datasetAResultLoading || datasetBResultLoading}
        onExpand={onExpandTheme}
        title={
          selectedTheme
            ? t("Components.Analyses.ThemeDiscussionAnalysis.ThemeDiscussionDetails", { theme: selectedTheme })
            : t("Components.Analyses.ThemeDiscussionAnalysis.Title")
        }
      />

      {/* Selected theme summaries */}
      {selectedThemeSummaries.length > 0 ? (
        <>
          <h3>{t("Components.Analyses.ThemeDiscussionAnalysis.Summary")}</h3>
          <ul>
            {selectedThemeSummaries.map((summary, index) => {
              return <li key={index}>{summary}</li>;
            })}
          </ul>
        </>
      ) : null}

      {/* Topics analyses chart */}
      {selectedTheme ? (
        <ThemeDiscussionAnalysisChart
          data={getTopicsAnalysesData()}
          datasetAParentItemId={datasetAParentItemId}
          datasetBParentItemId={datasetBParentItemId}
          xField={TOPICS_ANALYSES_CHART_CONFIG.xField}
          yField={TOPICS_ANALYSES_CHART_CONFIG.yField}
          fillColorField={TOPICS_ANALYSES_CHART_CONFIG.fillColorField}
          isUpdating={false}
          onExpand={() => {}}
          title={t("Components.Analyses.ThemeDiscussionAnalysis.TopicsDiscussedTitle")}
        />
      ) : null}
    </div>
  );

  // *********************************************
  // Event handlers/ callbacks
  // *********************************************/
  function getTopicsAnalysesData() {
    const selectedThemeData = getSelectedThemeData();
    let topicsAnalyses: any = [];
    if (selectedThemeData) {
      selectedThemeData.forEach((item) => {
        const updatedTopicAnlayses = item.topicsAnalysisItems.map((topicAnalysis) => {
          return {
            ...topicAnalysis,
            itemId: item.itemId,
          };
        });
        topicsAnalyses.push(...updatedTopicAnlayses);
      });
    }
    return topicsAnalyses;
  }

  function getSelectedThemeSummariesData() {
    const selectedThemeData = getSelectedThemeData();
    const datasetAData = selectedThemeData ? selectedThemeData.filter((item) => item.itemId === datasetAParentItemId) : [];
    return datasetAData ? datasetAData.map((item) => item.summaries).flat() : [];
  }

  function getSelectedThemeData() {
    const groupedData = DataUtils.getGroupedItems<UsedAnalysis>([...datasetB, ...datasetA], THEME_DISCUSSION_CHART_CONFIG.yField);
    return groupedData.get(selectedTheme);
  }

  function onExpandTheme(themeName: string) {
    setSelectedTheme(themeName);
  }
}
