import { IWorkspaceBoardChatActionPlugInInputs } from "./IWorkspaceBoardChatActionPlugInInputs";
import { ChatActionPlugInBase } from "../../Common/PlugIns/ChatActionPlugInBase";

export abstract class WorkspaceBoardChatActionPlugInBase extends ChatActionPlugInBase {
  // *********************************************
  // Public methods
  // *********************************************/
  /**
   * @inheritdoc
   */
  public abstract execute(input: IWorkspaceBoardChatActionPlugInInputs): Promise<undefined | JSX.Element>;
}
