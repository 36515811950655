import { Dialog, DialogContent, DialogTitle, DialogActions, Button, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";

import { WorkspaceAccessControlListEditor } from "Components/AccessControlList/WorkspaceAccessControlListEditor";
import { useWorkspaceQuery } from "GraphQL/Generated/Apollo";

export interface WorkspaceAccessControlListDialogProps {
  open: boolean;
  onClose: () => void;
  workspaceId: string;
}

export function WorkspaceAccessControlListDialog(props: WorkspaceAccessControlListDialogProps) {
  const { t } = useTranslation();
  const { onClose, open, workspaceId } = props;
  const { data, loading, error } = useWorkspaceQuery({ variables: { id: workspaceId } });
  const name = data?.workspace?.name || "";

  return (
    <Dialog onClose={onClose} open={open} maxWidth="md" fullWidth={true} disableEscapeKeyDown={false}>
      <DialogTitle variant="h5">
        <Trans i18nKey="Components.WorkspaceAccessControlListDialog.Title">Share "{{ name }}"</Trans>
      </DialogTitle>
      <DialogContent>
        <WorkspaceAccessControlListEditor workspaceId={workspaceId} />
      </DialogContent>
      <DialogActions sx={{ mb: 2, mr: 2 }}>
        <Button onClick={onClose}>{t("Global.Action.Close")}</Button>
      </DialogActions>
    </Dialog>
  );
}
