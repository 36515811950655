import {
  CONFIG_KEY_BASE_DOCUMENT_STYLESHEET,
  CONFIG_KEY_ORGANIZATION_DOCUMENT_STYLESHEET,
  CONFIG_KEY_ORGANIZATION_PREFERENCES,
  CONFIG_KEY_ORGANIZATION_TRANSLATIONS_PREFIX,
  IOrganizationPreferences,
} from "@bigpi/cookbook";
import { useAuthUser } from "@frontegg/react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import i18n from "i18next";
import React, { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { AppRoutes } from "./AppRoutes";
import { BoardSearchExecutor } from "BoardComponents/BoardSearchManager/BoardSearchExecutor";
import { BoardSearchExecutorContext } from "BoardComponents/BoardSearchManager/BoardSearchExecutorContext";
import { ShapeLifecycleEventEmitter } from "BoardComponents/ShapeLifecycleManager/ShapeLifecycleEventEmitter";
import { ShapeLifecycleEventEmitterContext } from "BoardComponents/ShapeLifecycleManager/ShapeLifecycleEventEmitterContext";
import { ChatActionExecutor } from "Chat/ChatActionManagers/ChatActionExecutor";
import { ChatActionExecutorContext } from "Chat/ChatActionManagers/ChatActionExecutorContext";
import { CommandExecutor } from "Components/CommandManagers/CommandExecutor";
import { CommandExecutorContext } from "Components/CommandManagers/CommandExecutorContext";
import { CommandManager } from "Components/CommandManagers/CommandManager";
import { FatalErrorDialog } from "Components/FatalErrorBoundary/FatalErrorDialog";
import { OnlineStatus } from "Components/OnlineStatus/OnlineStatus";
import { CommandContext } from "CommandContext";
import { Config } from "Config";
import { useGetAppConfigDataQuery, useGetConfigDataQuery } from "GraphQL/Generated/Apollo";
import { AppRightSidebarProvider } from "Hooks/useAppRightSidebarComponents";
import { OnlineStatusProvider } from "Hooks/useOnlineStatus";
import { Theme } from "Theme";

export function App() {
  const user = useAuthUser();
  const accessToken = user?.accessToken;
  const commandExecutor = CommandExecutor.useNewCommandExecutor();
  const chatActionExecutor = ChatActionExecutor.useNewChatActionExecutor();
  const boardSearchExecutor = BoardSearchExecutor.useNewBoardSearchExecutor();
  const shapeLifecycleEventEmitter = ShapeLifecycleEventEmitter.useNewShapeLifecycleEventEmitter();

  const {
    i18n: { language },
  } = useTranslation();

  // Call configs to get app config
  const {
    loading: appConfigLoading,
    data: appConfigData,
    error: appConfigError,
  } = useGetAppConfigDataQuery({
    variables: {
      organizationId: user.tenantId,
      baseDocumentStylesheetKey: CONFIG_KEY_BASE_DOCUMENT_STYLESHEET,
      organizationDocumentStylesheetKey: CONFIG_KEY_ORGANIZATION_DOCUMENT_STYLESHEET,
      organizationPreferencesKey: CONFIG_KEY_ORGANIZATION_PREFERENCES,
    },
  });

  const { data: orgTranslationsData } = useGetConfigDataQuery({
    variables: {
      key: `${CONFIG_KEY_ORGANIZATION_TRANSLATIONS_PREFIX}-${language}`,
      organizationId: user?.tenantId,
    },
  });

  // Call the asset endpoint to set a cookie there
  useEffect(() => {
    if (accessToken) {
      const headers = new Headers({
        authorization: `Bearer ${accessToken}`,
      });

      // Make sure we're authenticated against the collaboration backend
      fetch(`${Config.assetHttpUrl}/auth`, {
        credentials: "include",
        headers,
      }).then(
        (result) => {
          if (!result.ok) {
            console.error("Error authenticating for assets", result.status, result.statusText);
          }
        },
        (e) => {
          console.error("Error authenticating for assets", e);
        },
      );
    }
  }, [accessToken]);

  useEffect(() => {
    if (appConfigData?.OrganizationPreferences?.data) {
      try {
        const config = JSON.parse(appConfigData.OrganizationPreferences.data) as IOrganizationPreferences;
        // Set the command context with relevant values
        const commandContext = CommandContext.getCommandContext();
        CommandContext.patchCommandContext({
          organization: {
            ...commandContext.organization,
            ontologyName: config.ontologyName,
            tickerSymbol: config.tickerSymbol,
          },
        });
      } catch (e) {
        console.error("Error parsing config data", e);
      }
    }
  }, [appConfigData]);

  // Set the organization translations
  useEffect(() => {
    if (orgTranslationsData?.Config?.data) {
      try {
        const defaultTranslations = i18n.getResourceBundle("en", "translation");
        const orgTranslations = JSON.parse(orgTranslationsData.Config.data);
        // Merge org translations with default translations
        const mergedTranslations = { ...defaultTranslations, ...orgTranslations };
        i18n.addResourceBundle("en", "translation", mergedTranslations, true, true);
      } catch (e) {
        console.error("Error parsing org translations data", e);
      }
    }
  }, [orgTranslationsData]);

  return (
    <React.Fragment>
      <ThemeProvider theme={Theme}>
        <OnlineStatusProvider>
          <AppRightSidebarProvider>
            <CssBaseline />
            <FatalErrorDialog>
              <HelmetProvider>
                <Helmet>
                  {appConfigData?.BaseDocumentStylesheet?.data && (
                    <style type="text/css">{appConfigData.BaseDocumentStylesheet.data}</style>
                  )}
                  {appConfigData?.OrganizationDocumentStylesheet?.data && (
                    <style type="text/css">{appConfigData.OrganizationDocumentStylesheet.data}</style>
                  )}
                </Helmet>
                <CommandExecutorContext.Provider value={commandExecutor}>
                  <ChatActionExecutorContext.Provider value={chatActionExecutor}>
                    <ShapeLifecycleEventEmitterContext.Provider value={shapeLifecycleEventEmitter}>
                      <BoardSearchExecutorContext.Provider value={boardSearchExecutor}>
                        <CommandManager>
                          <AppRoutes />
                        </CommandManager>
                      </BoardSearchExecutorContext.Provider>
                    </ShapeLifecycleEventEmitterContext.Provider>
                  </ChatActionExecutorContext.Provider>
                </CommandExecutorContext.Provider>
                <ToastContainer newestOnTop limit={4} pauseOnHover autoClose={10000} closeOnClick={false} hideProgressBar />
              </HelmetProvider>
            </FatalErrorDialog>
            <OnlineStatus />
          </AppRightSidebarProvider>
        </OnlineStatusProvider>
      </ThemeProvider>
    </React.Fragment>
  );
}
