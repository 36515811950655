import { defineMigrations } from "@tldraw/store";

import { IInlineFrameShape } from "./IInlineFrameShape.js";

export const InlineFrameShapeVersions = {
  Initial: 0,
  RemoveOpacity: 1,
  AddCanScroll: 2,
};

export const inlineFrameShapeMigrations = defineMigrations({
  firstVersion: InlineFrameShapeVersions.Initial,
  currentVersion: InlineFrameShapeVersions.AddCanScroll,
  migrators: {
    [InlineFrameShapeVersions.RemoveOpacity]: {
      up: (shape: IInlineFrameShape & { props: { opacity: number } }) => {
        const { opacity, ...rest } = shape.props;
        return { ...shape, props: rest };
      },
      down: (shape: IInlineFrameShape) => {
        return {
          ...shape,
          props: { ...shape.props, opacity: true },
        };
      },
    },
    [InlineFrameShapeVersions.AddCanScroll]: {
      up: (shape: IInlineFrameShape) => {
        return {
          ...shape,
          props: { ...shape.props, canScroll: true },
        };
      },
      down: (shape: IInlineFrameShape & { canScroll: boolean }) => {
        const { canScroll, ...rest } = shape.props;
        return { ...shape, props: rest };
      },
    },
  },
});
