import { useContext } from "react";

import { CompanionManagerContext } from "./CompanionManagerContext";

export const useCompanionManager = () => {
  const result = useContext(CompanionManagerContext);
  if (!result) {
    throw new Error("No CompanionManager found in context");
  }
  return result;
};
