import { BoardSearchFieldTypes, IBoardSearchField, IBoardSearchShapeMatch, IPlugIn } from "@bigpi/cookbook";
import get from "lodash.get";

import { IBoardSearchPlugInInputs } from "../BoardSearchManager";
import { TLShape } from "@tldraw/tldraw";

export abstract class BoardSearchPlugInBase implements IPlugIn<IBoardSearchPlugInInputs, Array<IBoardSearchShapeMatch>> {
  // *********************************************
  // Public properties
  // *********************************************/
  /**
   * @inheritdoc
   */
  abstract name: BoardSearchFieldTypes;

  // *********************************************
  // Public methods
  // *********************************************/
  /**
   * Get the data for the search field from the shape props. Returns null if the
   * field data is empty or does not match the "matchValue" of the search field.
   *
   * @param shape The shape to get the data from.
   * @param searchField The search field to get the data for.
   *
   * @returns The data for the search field.
   */
  public getFieldDataFromShapeProps(shape: TLShape, searchField: IBoardSearchField): string | Array<string> | null {
    // Get the data for the search field. "searchField.name" is a dot-separated path to the field in the shape.
    const fieldData = get(shape.props, searchField.name);

    // If the field data is empty, skip it.
    if (fieldData === undefined || fieldData === null || fieldData === "") {
      return null;
    }

    // If the field data does not match the "matchValue" of the search field, skip it.
    if (searchField.matchValue && fieldData !== searchField.matchValue) {
      return null;
    }

    return fieldData;
  }

  /**
   * @inheritdoc
   */
  public abstract execute(input: IBoardSearchPlugInInputs): Promise<Array<IBoardSearchShapeMatch>>;

  /**
   * @inheritdoc
   */
  public async destroy(): Promise<void> {
    // Do nothing in base class
  }
}
