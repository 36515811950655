import type { EditorOptions } from "@tiptap/core";
import { Editor } from "@tiptap/react";
import { DependencyList, useEffect, useRef, useState } from "react";

// NOTE: This file is mostly copied from https://github.com/ueberdosis/tiptap/blob/4474d056daf9280ebb10b31f98bb000e953132e5/packages/react/src/useEditor.ts#L11
// Primary modification is to not create an editor instance if no options are provided.

function useForceUpdate() {
  const [, setValue] = useState(0);

  return () => setValue((value) => value + 1);
}

export const useEditor = (options: Partial<EditorOptions> | undefined, deps: DependencyList = []) => {
  const editorRef = useRef<Editor | null>(null);

  const forceUpdate = useForceUpdate();

  const { onBeforeCreate, onBlur, onContentError, onCreate, onDestroy, onFocus, onSelectionUpdate, onTransaction, onUpdate } =
    options || {};

  const onBeforeCreateRef = useRef(onBeforeCreate);
  const onBlurRef = useRef(onBlur);
  const onContentErrorRef = useRef(onContentError);
  const onCreateRef = useRef(onCreate);
  const onDestroyRef = useRef(onDestroy);
  const onFocusRef = useRef(onFocus);
  const onSelectionUpdateRef = useRef(onSelectionUpdate);
  const onTransactionRef = useRef(onTransaction);
  const onUpdateRef = useRef(onUpdate);

  // This effect will handle updating the editor instance
  // when the event handlers change.
  useEffect(() => {
    if (!editorRef.current) {
      return;
    }

    if (onBeforeCreate) {
      editorRef.current.off("beforeCreate", onBeforeCreateRef.current);
      editorRef.current.on("beforeCreate", onBeforeCreate);

      onBeforeCreateRef.current = onBeforeCreate;
    }

    if (onBlur) {
      editorRef.current.off("blur", onBlurRef.current);
      editorRef.current.on("blur", onBlur);

      onBlurRef.current = onBlur;
    }

    if (onContentError) {
      editorRef.current.off("contentError", onContentErrorRef.current);
      editorRef.current.on("contentError", onContentError);

      onContentErrorRef.current = onContentError;
    }

    if (onCreate) {
      editorRef.current.off("create", onCreateRef.current);
      editorRef.current.on("create", onCreate);

      onCreateRef.current = onCreate;
    }

    if (onDestroy) {
      editorRef.current.off("destroy", onDestroyRef.current);
      editorRef.current.on("destroy", onDestroy);

      onDestroyRef.current = onDestroy;
    }

    if (onFocus) {
      editorRef.current.off("focus", onFocusRef.current);
      editorRef.current.on("focus", onFocus);

      onFocusRef.current = onFocus;
    }

    if (onSelectionUpdate) {
      editorRef.current.off("selectionUpdate", onSelectionUpdateRef.current);
      editorRef.current.on("selectionUpdate", onSelectionUpdate);

      onSelectionUpdateRef.current = onSelectionUpdate;
    }

    if (onTransaction) {
      editorRef.current.off("transaction", onTransactionRef.current);
      editorRef.current.on("transaction", onTransaction);

      onTransactionRef.current = onTransaction;
    }

    if (onUpdate) {
      editorRef.current.off("update", onUpdateRef.current);
      editorRef.current.on("update", onUpdate);

      onUpdateRef.current = onUpdate;
    }
  }, [onBeforeCreate, onBlur, onCreate, onDestroy, onFocus, onSelectionUpdate, onTransaction, onUpdate, editorRef.current]);

  useEffect(() => {
    // Modified by BigPi - added options check
    if (options) {
      let isMounted = true;

      editorRef.current = new Editor(options);

      editorRef.current.on("transaction", () => {
        requestAnimationFrame(() => {
          requestAnimationFrame(() => {
            if (isMounted) {
              forceUpdate();
            }
          });
        });
      });

      return () => {
        editorRef.current?.destroy();
        isMounted = false;
      };
    }
  }, [options, ...deps]);

  return editorRef.current;
};
