import { ISelectInputFieldOption, StandardHtmlColors } from "@bigpi/cookbook";
import { Box, Stack, Checkbox, FormControl, Select, MenuItem, ListItemText, InputLabel } from "@mui/material";
import { useTranslation } from "react-i18next";

/**********************************
 * Types/Interfaces
 **********************************/
export interface AnalysisPreferencesOptionsState {
  isBackgroundEnabled: boolean;
  startColor?: string;
  isDataToDisplayInToolbarEnabled?: boolean;
  isSubItemsEnabled?: boolean;
}

export interface AnalysisPreferencesOptionsProps {
  availableColors?: Array<ISelectInputFieldOption & { color?: string }>;
  // To hide start color, this is used only for topics & bulletin analysis
  hideStartColor?: boolean;
  // To hide sub items, this is used only for topics & bulletin analysis
  hideSubItems?: boolean;
  onOptionsStateChange: (options: AnalysisPreferencesOptionsState) => void;
  optionsState: AnalysisPreferencesOptionsState;
  subItemsLabel?: string;
}

/**********************************
 * Component
 **********************************/
export function AnalysisPreferencesOptions(props: AnalysisPreferencesOptionsProps) {
  const { availableColors, hideSubItems, hideStartColor, onOptionsStateChange, optionsState, subItemsLabel } = props;
  const { t } = useTranslation();
  return (
    <Box>
      <Stack>
        <Box>
          <Checkbox
            onChange={(event, checked) => {
              onOptionsStateChange({
                ...optionsState,
                isBackgroundEnabled: checked,
              });
            }}
            checked={optionsState.isBackgroundEnabled}
          />
          {t("Components.Analyses.Common.PreferencesDialog.ShowBackground")}{" "}
        </Box>
        {!hideSubItems && (
          <Box>
            <Checkbox
              onChange={(event, checked) => {
                onOptionsStateChange({
                  ...optionsState,
                  isSubItemsEnabled: checked,
                });
              }}
              checked={optionsState.isSubItemsEnabled}
            />
            {subItemsLabel}{" "}
          </Box>
        )}
        <Box>
          <Checkbox
            onChange={(event, checked) => {
              onOptionsStateChange({
                ...optionsState,
                isDataToDisplayInToolbarEnabled: checked,
              });
            }}
            checked={optionsState.isDataToDisplayInToolbarEnabled}
          />
          {t("Components.Analyses.Common.PreferencesDialog.ShowDataToDisplayInToolbar")}{" "}
        </Box>
        {!hideStartColor && (
          <Box>
            <FormControl sx={{ width: 200, ml: "12px", mt: "10px" }} size="small">
              <InputLabel>{t("Components.Analyses.Common.PreferencesDialog.StartingColor")}</InputLabel>
              <Select
                sx={{ width: 150, display: "flex" }}
                id="starting-color"
                label={t("Components.Analyses.Common.PreferencesDialog.StartingColor")}
                value={optionsState.startColor || ""}
                onChange={(event) => {
                  onOptionsStateChange({
                    ...optionsState,
                    startColor: event.target.value as string,
                  });
                }}
                slotProps={{ input: { sx: { display: "flex" } } }}
              >
                {(availableColors || []).map((color) => {
                  return (
                    <MenuItem key={color.key} value={color.key} sx={{ display: "flex" }}>
                      <Box
                        sx={{
                          width: 20,
                          height: 20,
                          backgroundColor: color.color || color.key,
                          border: "1px solid #ccc",
                        }}
                      />
                      <InputLabel sx={{ ml: "10px" }}>
                        {"   "}
                        {color.label}
                      </InputLabel>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        )}
      </Stack>
    </Box>
  );
}
