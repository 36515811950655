import { ApolloClient } from "@apollo/client";
import {
  CONFIG_KEY_ORGANIZATION_PREFERENCES,
  IDateRangeOption,
  DateRangeShortcut,
  IOrganizationPreferences,
} from "@bigpi/cookbook";
import { useAuthUser } from "@frontegg/react";
import { TFunction } from "i18next";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { getDateRangeFromShortcut } from "Utils/DateUtils";
import { useGetConfigDataQuery, GetConfigDataQuery } from "GraphQL/Generated/Apollo";
import { GET_CONFIG_DATA } from "GraphQL/Configs/Query";

/**
 * Takes shortcut ids and returns the date range for the shortcuts.
 * These shortcuts can be from user defined custom ranges. Or from the predefined DateRangeShortcut.
 *
 * @param dateRangeIds Date range ids which are custom defined in org preferences or from DateRangeShortcut
 * @returns Shortcut related date ranges
 */
export function useShortcutRelatedRanges(dateRangeIds: Array<IDateRangeOption["id"]> | undefined) {
  const [dateRangeShortcuts, setDateRangeShortcuts] = useState<Array<IDateRangeOption>>([]);

  const { data: appConfigDataResult } = useGetConfigDataQuery({
    variables: {
      key: CONFIG_KEY_ORGANIZATION_PREFERENCES,
      organizationId: useAuthUser()?.tenantId,
    },
  });
  const { t } = useTranslation();

  useEffect(() => {
    if (dateRangeIds) {
      let customDateRanges: Array<IDateRangeOption> = [];
      try {
        customDateRanges = appConfigDataResult?.Config?.data
          ? JSON.parse(appConfigDataResult?.Config?.data).customDateRanges
          : undefined;
      } catch (e) {
        console.error("Error parsing custom date ranges", e);
      }

      const validDateRangeShortcuts = getShortcutRelatedRanges(dateRangeIds, customDateRanges, t);
      setDateRangeShortcuts(validDateRangeShortcuts);
    } else {
      setDateRangeShortcuts([]);
    }
  }, [dateRangeIds, appConfigDataResult, t]);

  return dateRangeShortcuts;
}

/********************************************
 * Utils
 ********************************************/
/**
 *
 *
 * @param dateRangeIds
 * @param customDateRanges
 * @param t
 * @returns
 */
export function getShortcutRelatedRanges(
  dateRangeIds: Array<IDateRangeOption["id"]>,
  customDateRanges: Array<IDateRangeOption>,
  t: TFunction,
) {
  const validDateRangeShortcuts: Array<IDateRangeOption> = [];

  dateRangeIds.forEach((dateRangeId) => {
    const dateRange = customDateRanges?.find((dateRangeOption: IDateRangeOption) => dateRangeOption.id === dateRangeId);
    if (dateRange) {
      validDateRangeShortcuts.push(dateRange);
    } else {
      const dateRangeShortcut = getDateRangeFromShortcut(dateRangeId as keyof typeof DateRangeShortcut);
      if (dateRangeShortcut) {
        validDateRangeShortcuts.push({
          id: dateRangeId,
          title: t(`Components.Charts.DateShortcuts.${dateRangeId}`),
          startDate: dateRangeShortcut[0],
          endDate: dateRangeShortcut[1],
        });
      }
    }
  });

  return validDateRangeShortcuts;
}

/**
 * Calls GraphQL to get the custom date ranges for the organization
 *
 * @param apolloClient Appollo client to make the query
 * @param organizationId Organization id to get the custom date ranges
 * @returns Organization related customDateRanges
 */
export const getCustomDateRanges = async (
  apolloClient: ApolloClient<object>,
  organizationId: string,
): Promise<IOrganizationPreferences["customDateRanges"]> => {
  const apiResponse = await apolloClient.query<GetConfigDataQuery>({
    query: GET_CONFIG_DATA,
    variables: {
      key: CONFIG_KEY_ORGANIZATION_PREFERENCES,
      organizationId,
    },
  });

  return JSON.parse(apiResponse.data?.Config?.data || "{}").customDateRanges as IOrganizationPreferences["customDateRanges"];
};
