import {
  DiscussionNature,
  getSortedOccupationTitles,
  getPrimaryOccupationTitle,
  OccupationTitle,
  StandardHtmlColors,
} from "@bigpi/cookbook";
import {
  Button,
  ClickAwayListener,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import * as Plot from "@observablehq/plot";
import * as d3 from "d3";
import { TFunction } from "i18next";
import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { UsedAnalysis, ThemeDiscussionTopicsAnalyses } from "./ThemeDiscussionAnalysisContainer";
import { ChartIcons } from "Components/Charting/Icons/ChartIcons";
import { getChartIcon } from "Components/Charting/Icons";
import { TranscriptItem } from "GraphQL/Generated/Apollo";
import { ChartUtils } from "Utils/ChartUtils";
import { DataUtils } from "Utils/DataUtils";
import { SvgUtils } from "Utils/SvgUtils";

// Icons
import NumberIcon from "Assets/Icons/123.svg";
import ChartLineIcon from "Assets/Icons/chart-line-up-down-light.svg";
import ChartSimpleSolidIcon from "Assets/Icons/chart-simple-solid.svg";
import DollarSignSolidIcon from "Assets/Icons/dollar-sign-solid.svg";
import SackDollarSolidIcon from "Assets/Icons/sack-dollar-solid.svg";
import ScrollSolidIcon from "Assets/Icons/scroll-solid.svg";

// css
import "./ThemeDiscussionAnalysisChart.css";

// *********************************************
// Private constants
// *********************************************/
const DISCUSSION_DOMAIN_VALUES = Object.values(DiscussionNature);

interface ThemeDiscussionAnalysisChartProps {
  data: Array<UsedAnalysis | ThemeDiscussionTopicsAnalyses>;
  datasetAParentItemId: string;
  datasetBParentItemId: string | undefined;
  // Need to rethink about this naming
  enableReturnToSummary?: boolean;
  fillColorField: keyof UsedAnalysis | keyof ThemeDiscussionTopicsAnalyses;
  isUpdating: boolean;
  onExpand: (itemId: string) => void;
  title: string;
  xField: keyof UsedAnalysis | keyof ThemeDiscussionTopicsAnalyses;
  yField: keyof UsedAnalysis | keyof ThemeDiscussionTopicsAnalyses;
}

const DEFAULT_FONT_SIZE = 16;
const DEFAULT_STROKE_WIDTH = 3;
const DEFAULT_DOT_RADIUS = 8;

const plotMetadata: Record<string, any> = {
  style: { background: "transparent", fontSize: `${DEFAULT_FONT_SIZE}px`, fontWeight: 500, color: StandardHtmlColors.midnight },
  height: 45,
};

const widthMap = {
  discussionDepthPlot: 480,
  discussionsPlot: 220,
};

const SPEAKER_PLOT_WIDTH_MULTIPLIER = 75;

// *********************************************
// Component
// *********************************************/
export function ThemeDiscussionAnalysisChart(props: ThemeDiscussionAnalysisChartProps) {
  const {
    data = [],
    datasetAParentItemId,
    datasetBParentItemId,
    enableReturnToSummary,
    fillColorField,
    isUpdating,
    onExpand,
    xField,
    yField,
    title,
  } = props;
  const chartRef = useRef<HTMLDivElement>(null);
  const legendsElementRef = useRef<HTMLDivElement>(null);
  const discussionsTickLabelsRef = useRef<HTMLDivElement>(null);

  // State
  const [openTooltip, setOpenTooltip] = useState<boolean>(false);

  const { t } = useTranslation();

  const eventPeriodDomainValues = [
    t("Components.Analyses.ThemeDiscussionAnalysis.DatasetALabel"),
    t("Components.Analyses.ThemeDiscussionAnalysis.DatasetBLabel"),
  ];
  const colorScale = d3
    .scaleOrdinal()
    .domain(eventPeriodDomainValues)
    .range([StandardHtmlColors.pacific, StandardHtmlColors.gray30]);

  // Render the theme chart
  useEffect(() => {
    // Clear any previous results
    if (chartRef.current) {
      chartRef.current.innerHTML = "";
    }

    let plotElements: Array<HTMLElement> = [];
    if (data.length > 0) {
      const allOccupationTitles = getAllOccupationTitles(data);

      // Chart elements
      // @ts-expect-error TODO: Fix this
      const groupedData = DataUtils.getGroupedItems<UsedAnalysis | ThemeDiscussionTopicsAnalyses>(data, yField);

      [...groupedData.keys()].sort().forEach((groupKey, index) => {
        const itemData = groupedData.get(groupKey);
        const plotElement = document.createElement("div");
        plotElement.style.display = "flex";
        if (itemData) {
          plotElement.append(getDiscussionDepthPlot(itemData, { xField, yField }, index));
          const dataA = itemData.find((item) => item.itemId === datasetAParentItemId);
          const dataB = itemData.find((item) => item.itemId === datasetBParentItemId);
          plotElement.append(
            getDiscussionsPlot(
              dataA && dataA.discussionNature ? dataA.discussionNature : [],
              dataB && dataB.discussionNature ? dataB.discussionNature : [],
              index,
            ),
          );
          plotElement.append(
            getSpeakerPlot(
              dataA && dataA.participants ? dataA.participants : [],
              dataB && dataB.participants ? dataB.participants : [],
              allOccupationTitles,
              index,
              t,
            ),
          );
        }
        plotElements.push(plotElement);
      });

      plotElements.forEach((plotElement) => {
        chartRef.current?.append(plotElement);
      });
    }
  }, [t, xField, yField, datasetAParentItemId, datasetBParentItemId, data]);

  // Renders the legend plot
  useEffect(() => {
    if (legendsElementRef.current) {
      legendsElementRef.current.innerHTML = "";
    }
    legendsElementRef.current?.append(getLegendPlot());
  }, [legendsElementRef, data]);

  useEffect(() => {
    if (discussionsTickLabelsRef.current) {
      discussionsTickLabelsRef.current.innerHTML = "";
    }
    discussionsTickLabelsRef.current?.append(getDiscussionsPlotTickSymbols());
  }, [discussionsTickLabelsRef, data]);

  return (
    <div className="theme-discussion-analysis-chart">
      <Grid container>
        <Grid item>
          <Typography variant="h6" sx={{ color: StandardHtmlColors.blue80 }}>
            {title}
          </Typography>
          {enableReturnToSummary ? (
            <div onClick={() => onExpand("")} className="theme-discussion-analysis-chart__return-to-summary">
              {t("Components.Analyses.ThemeDiscussionAnalysis.ReturnToSummary")}
            </div>
          ) : null}
        </Grid>
        <Grid item flexGrow={1} display="flex" justifyContent={"flex-end"} marginTop={1}>
          <div ref={legendsElementRef} className="theme-discussion-analysis-chart__legend"></div>
        </Grid>
      </Grid>
      {isUpdating ? (
        <Typography variant="body1" gutterBottom>
          {t("Components.Analyses.ThemeDiscussionAnalysis.Generating")}
          <span className="ellipsis-anim">
            <span>.</span>
            <span>.</span>
            <span>.</span>
          </span>
        </Typography>
      ) : (
        <>
          {getPlotLabels()}
          <div ref={chartRef}></div>
        </>
      )}
    </div>
  );

  // *********************************************
  // Private methods
  // *********************************************/
  /**
   * Labels for the discussion depth, nature of discussion & participants plots.
   *
   * @returns Grid labels for the plots
   */
  function getPlotLabels() {
    return (
      <Grid container alignItems="center" marginBottom={2}>
        <Grid item width={widthMap.discussionDepthPlot} textAlign="end" paddingRight={7} fontSize={12} fontWeight={500}>
          Discussion depth
        </Grid>
        <Grid
          item
          alignItems={"center"}
          display="flex"
          fontSize={12}
          fontWeight={500}
          justifyContent="center"
          textAlign="center"
          width={widthMap.discussionsPlot}
        >
          <Grid container justifyContent="end">
            <Grid item display="flex" alignItems={"center"} justifyContent="center">
              Nature of discussion
              <ClickAwayListener onClickAway={onTooltipClickAway}>
                <div>
                  <LightTooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    open={openTooltip}
                    title={getTooltipContent(t)}
                    placement="top"
                  >
                    <Button onClick={onTooltipButtonClick} sx={{ width: 10, paddingLeft: 0 }}>
                      <InfoOutlinedIcon fontSize={"small"} />
                    </Button>
                  </LightTooltip>
                </div>
              </ClickAwayListener>
            </Grid>
            <Grid item position={"absolute"} marginTop="25px">
              <div ref={discussionsTickLabelsRef}></div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item minWidth={SPEAKER_PLOT_WIDTH_MULTIPLIER} textAlign="center" fontSize={12} flexGrow={1} fontWeight={500}>
          Speaker
        </Grid>
      </Grid>
    );
  }

  /**
   * Gets the label for the discussion depth plot.
   *
   * @returns Label for discussion depth plot.
   */
  function getLegendPlot() {
    return Plot.plot({
      className: "discussion-depth-plot-label",
      height: 40,
      marginTop: 20,
      style: { backgroundColor: "transparent" },
      width: 300,
      x: {
        domain: eventPeriodDomainValues,
        tickSize: 0,
        inset: 5,
      },
      y: { ticks: 0, tickSize: 0 },
      marks: [
        Plot.dotX(eventPeriodDomainValues, {
          fill: (d) => (d === eventPeriodDomainValues[1] ? colorScale(d) : "transparent"),
          stroke: (d) => colorScale(d),
          r: DEFAULT_DOT_RADIUS,
          strokeWidth: DEFAULT_STROKE_WIDTH,
        }),
      ],
    });
  }

  /**
   * Gives the discussion depth plot
   *
   * @param data Data to plot
   * @returns
   */
  function getDiscussionDepthPlot(data: Array<Record<string, any>>, fields: Record<string, any>, index: number) {
    const { xField, yField } = fields;
    const otherXAxisAttributes: Record<string, any> = {};
    const otherPlotMetadata: Record<string, any> = {};
    // For the first plot, ticks are shown
    if (index === 0) {
      otherXAxisAttributes["ticks"] = 1;
      otherXAxisAttributes["axis"] = "top";
      otherPlotMetadata["height"] = 50;
    } else {
      otherXAxisAttributes["axis"] = null;
    }
    const chart = Plot.plot({
      ...plotMetadata,
      ...otherPlotMetadata,
      marginLeft: 300,
      marginRight: 20,
      width: widthMap.discussionDepthPlot,
      x: {
        ...otherXAxisAttributes,
        domain: [0, 100],
        label: "",
        labelArrow: null,
        tickPadding: 17,
        transform: (d) => d * 100,
      },
      y: {
        grid: true,
        label: "",
        tickSize: 0,
        tickPadding: 20,
        insetLeft: 80,
        tickFormat: (d) => ChartUtils.formatOverflowAxisLabel(d, DEFAULT_FONT_SIZE, 270, "ellipsis"),
      },
      marks: [
        Plot.dotX(data, {
          x: xField,
          y: yField,
          r: DEFAULT_DOT_RADIUS,
          fill: (d) => (d[fillColorField] === datasetBParentItemId ? colorScale(eventPeriodDomainValues[1]) : "transparent"),
          stroke: (d) =>
            d[fillColorField] === datasetAParentItemId
              ? colorScale(eventPeriodDomainValues[0])
              : colorScale(eventPeriodDomainValues[1]),
          strokeWidth: DEFAULT_STROKE_WIDTH,
        }),
      ],
    });
    d3.select(chart).on("pointerup", onYAxisLabelClick).style("cursor", "default");
    return chart;
  }

  /**
   *
   * @returns
   */
  function getDiscussionsPlotTickSymbols() {
    return Plot.plot({
      ...plotMetadata,
      marginLeft: 20,
      width: widthMap.discussionsPlot,
      x: {
        axis: null,
        domain: DISCUSSION_DOMAIN_VALUES,
        insetBottom: 20,
      },
      // Since ruleY is used, there is y ticks appearing
      y: { ticks: 0, tickSize: 0 },
      marks: [
        Plot.dotX(DISCUSSION_DOMAIN_VALUES, {
          fill: "#000",
          symbol: (value: string) => {
            switch (value) {
              case DiscussionNature.Initiatives:
                return getChartIcon(ChartIcons.ScrollSolid);
              case DiscussionNature.Investments:
                return getChartIcon(ChartIcons.SackDollarSolid);
              case DiscussionNature.FinancialDetails:
                return getChartIcon(ChartIcons.DollarSignSolid);
              case DiscussionNature.QuantitativeMetrics:
                return getChartIcon(ChartIcons.Quantative);
              case DiscussionNature.StateOfMarket:
                return getChartIcon(ChartIcons.ChartSimpleSolid);
              case DiscussionNature.ForwardLooking:
                return getChartIcon(ChartIcons.ChartLineUpDown);
            }
          },
          dy: -5,
          dx: -12,
        }),
      ],
    });
  }

  /**
   * Gives the discussion plot
   *
   * @param data Discussions data
   * @returns
   */
  function getDiscussionsPlot(
    datatestADiscussions: Array<DiscussionNature> = [],
    datatestBDiscussions: Array<DiscussionNature>,
    index: number,
  ) {
    const otherPlotMetadata: Record<string, any> = {};
    if (index === 0) {
      // To align this chart with other charts horizontally
      otherPlotMetadata["marginTop"] = 30;
      otherPlotMetadata["height"] = 60;
    }
    return Plot.plot({
      ...plotMetadata,
      ...otherPlotMetadata,
      marginLeft: 20,
      width: widthMap.discussionsPlot,
      x: {
        axis: null,
        domain: DISCUSSION_DOMAIN_VALUES,
      },
      // Since ruleY is used, there is y ticks appearing
      y: { ticks: 0, tickSize: 0 },
      marks: [
        Plot.dotX(datatestBDiscussions, {
          fill: colorScale(eventPeriodDomainValues[1]) as Plot.ChannelValueSpec,
          stroke: colorScale(eventPeriodDomainValues[1]) as Plot.ChannelValueSpec,
          strokeWidth: DEFAULT_STROKE_WIDTH,
          r: DEFAULT_DOT_RADIUS + 1,
        }),
        Plot.dotX(datatestADiscussions, {
          fill: colorScale(eventPeriodDomainValues[0]) as Plot.ChannelValueSpec,
          symbol: (value: string) => {
            switch (value) {
              case DiscussionNature.Initiatives:
                return getChartIcon(ChartIcons.ScrollSolid);
              case DiscussionNature.Investments:
                return getChartIcon(ChartIcons.SackDollarSolid);
              case DiscussionNature.FinancialDetails:
                return getChartIcon(ChartIcons.DollarSignSolid);
              case DiscussionNature.QuantitativeMetrics:
                return getChartIcon(ChartIcons.Quantative);
              case DiscussionNature.StateOfMarket:
                return getChartIcon(ChartIcons.ChartSimpleSolid);
              case DiscussionNature.ForwardLooking:
                return getChartIcon(ChartIcons.ChartLineUpDown);
            }
          },
          dy: -12,
          dx: -12,
        }),
        Plot.ruleY([0], { stroke: "#bbc0c3", strokeDasharray: "2,2" }),
      ],
    });
  }

  /**
   * Gives the speaker plot
   *
   * @param data Speakers data to plot
   * @returns
   */
  function getSpeakerPlot(
    datasetAParticipants: Array<Pick<TranscriptItem, "occupationTitles">>,
    datasetBParticipants: Array<Pick<TranscriptItem, "occupationTitles">>,
    occupationTitles: Array<OccupationTitle>,
    index: number,
    t: TFunction,
  ) {
    const sortedOccupationTitles = getSortedOccupationTitles(occupationTitles);
    const datasetAOccupationTitles = datasetAParticipants.map((participant) =>
      getPrimaryOccupationTitle(participant.occupationTitles),
    );
    const datasetBOccupationTitles = datasetBParticipants.map((participant) =>
      getPrimaryOccupationTitle(participant.occupationTitles),
    );
    const otherXAxisAttributes: Record<string, any> = {};
    const otherPlotMetadata: Record<string, any> = {};
    // For the first plot, ticks are shown
    if (index === 0) {
      otherXAxisAttributes["axis"] = "top";
      otherPlotMetadata["height"] = 60;
    } else {
      otherXAxisAttributes["axis"] = null;
    }
    return Plot.plot({
      ...plotMetadata,
      ...otherPlotMetadata,
      marginRight: 0,
      width: sortedOccupationTitles.length * SPEAKER_PLOT_WIDTH_MULTIPLIER,
      x: {
        ...otherXAxisAttributes,
        domain: sortedOccupationTitles,
        tickFormat: (value: string) => t(`Schema.OccupationTitle.${value}.Abbreviation`),
        tickPadding: 18,
        tickSize: 0,
      },
      // Since ruleY is used, there is y ticks appearing
      y: { ticks: 0, tickSize: 0 },
      marks: [
        Plot.dotX(datasetBOccupationTitles, {
          fill: colorScale(eventPeriodDomainValues[1]) as Plot.ChannelValueSpec,
          stroke: colorScale(eventPeriodDomainValues[1]) as Plot.ChannelValueSpec,
          strokeWidth: DEFAULT_STROKE_WIDTH,
          r: DEFAULT_DOT_RADIUS,
        }),
        Plot.dotX(datasetAOccupationTitles, {
          stroke: colorScale(eventPeriodDomainValues[0]) as Plot.ChannelValueSpec,
          strokeWidth: DEFAULT_STROKE_WIDTH,
          r: DEFAULT_DOT_RADIUS,
        }),
        Plot.ruleY([0.3], { stroke: "#bbc0c3", strokeDasharray: "2,2" }),
      ],
    });
  }

  /**
   *
   * @returns Tooltip content
   */
  function getTooltipContent(t: TFunction) {
    return (
      <List>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <img
                src={ScrollSolidIcon}
                height={20}
                width={20}
                alt={t("Components.Analyses.ThemeDiscussionAnalysis.DiscussionNature.Initiatives")}
              />
            </ListItemIcon>
            <ListItemText primary={t("Components.Analyses.ThemeDiscussionAnalysis.DiscussionNature.Initiatives")} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <img
                src={SackDollarSolidIcon}
                height={20}
                width={20}
                alt={t("Components.Analyses.ThemeDiscussionAnalysis.DiscussionNature.Investments")}
              />
            </ListItemIcon>
            <ListItemText primary={t("Components.Analyses.ThemeDiscussionAnalysis.DiscussionNature.Investments")} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <img
                src={DollarSignSolidIcon}
                height={20}
                width={20}
                alt={t("Components.Analyses.ThemeDiscussionAnalysis.DiscussionNature.FinancialDetails")}
              />
            </ListItemIcon>
            <ListItemText primary={t("Components.Analyses.ThemeDiscussionAnalysis.DiscussionNature.FinancialDetails")} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <img
                src={NumberIcon}
                height={20}
                width={20}
                alt={t("Components.Analyses.ThemeDiscussionAnalysis.DiscussionNature.QuantitativeMetrics")}
              />
            </ListItemIcon>
            <ListItemText primary={t("Components.Analyses.ThemeDiscussionAnalysis.DiscussionNature.QuantitativeMetrics")} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <img
                src={ChartSimpleSolidIcon}
                height={20}
                width={20}
                alt={t("Components.Analyses.ThemeDiscussionAnalysis.DiscussionNature.CurrentStateMarker")}
              />
            </ListItemIcon>
            <ListItemText primary={t("Components.Analyses.ThemeDiscussionAnalysis.DiscussionNature.CurrentStateMarker")} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <img
                src={ChartLineIcon}
                height={20}
                width={20}
                alt={t("Components.Analyses.ThemeDiscussionAnalysis.DiscussionNature.ForwardLookingStatement")}
              />
            </ListItemIcon>
            <ListItemText primary={t("Components.Analyses.ThemeDiscussionAnalysis.DiscussionNature.ForwardLookingStatement")} />
          </ListItemButton>
        </ListItem>
      </List>
    );
  }

  /**
   *
   * @param data Analysis data to get the top occupation titles from
   * @returns
   */
  function getAllOccupationTitles(data: Array<UsedAnalysis | ThemeDiscussionTopicsAnalyses>) {
    const allOccupationTitles = data.flatMap((analysis) =>
      (analysis.participants || [])?.flatMap((participant) => getPrimaryOccupationTitle(participant.occupationTitles)),
    );
    return [...new Set(allOccupationTitles)];
  }

  // *********************************************
  // Event handlers
  // *********************************************/
  /**
   * Handles theme click event & updates the selected theme
   * @param e Pointer event
   */
  function onYAxisLabelClick(e: PointerEvent) {
    const value = SvgUtils.getTextFromEventTarget(e.target);
    onExpand(value || "");
  }

  /**
   * Handles tooltip click away & closes the tooltip
   * @param attributeName Attribute name
   */
  function onTooltipClickAway() {
    setOpenTooltip(false);
  }

  /**
   * Hanles tooltip button click & opens the tooltip
   * @param attributeName Attribute name
   */
  function onTooltipButtonClick() {
    setOpenTooltip(true);
  }
}

/**
 * Tooltip component
 */
const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} sx={{ width: 40 }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
  },
  [`& .${tooltipClasses.arrow}::before`]: {
    border: `1px solid ${theme.palette.grey[500]}`,
  },
}));
