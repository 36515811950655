/**
 * Options for search regex.
 */
export interface SearchRegexOptions {
  /**
   * Whether the search should be case insensitive. Defaults to true.
   */
  caseInsensitive?: boolean;

  /**
   * Whether to disable the regex search. Defaults to true.
   */
  disableRegexSearch?: boolean;
}

/**
 * @param searchTerm The search term to create a regex for.
 * @param options The options for the search regex.
 * @returns A regex for the search term.
 */
export function getRegexForSearchTerm(searchTerm: string, options?: SearchRegexOptions): RegExp {
  if (!options) {
    options = {};
  }

  const { caseInsensitive = true, disableRegexSearch = true } = options;

  // Escape the search term to avoid special characters in the search term.
  //
  // RegExp "/[.*+?^${}()|[\]\\]/g" matches any character that has special meaning in a regular
  // expression. These characters are ., *, +, ?, ^, $, {, }, (, ), |, [, ], \.
  // The replacement is "\\$&", which means each matched character will be replaced by itself
  // but with a preceding backslash.
  //
  // E.g., if the search term is "a.b", the escaped search term will be "a\.b".
  return new RegExp(
    disableRegexSearch ? searchTerm.replace(/[.*+?^${}()|[\]\\]/g, "\\$&") : searchTerm,
    caseInsensitive ? "gui" : "gu",
  );
}
