import type { Editor } from "@tiptap/react";
import {
  FormatAlignCenterOutlined,
  FormatAlignLeftOutlined,
  FormatAlignRightOutlined,
  SubjectOutlined,
} from "@mui/icons-material";
import { Menu, MenuItem, SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent.js";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import type { IEditorConfig } from "../../Editor/index.js";
import { capitalizeFirstLetter, getEditorToolbarButtonTooltip } from "../../Utils/ToolbarItemUtils.js";
import { ToolbarItemBase } from "../../Toolbars/ToolbarItemBase.js";
import { ToolbarButton } from "../../Toolbars/ToolbarButton.js";

const ITEM_NAME = "textAlign";

/**
 * A toolbar button for text alignment.
 */
export class TextAlignButtonItem extends ToolbarItemBase {
  // *********************************************
  // Public properties
  // *********************************************/
  /**
   * @inheritdoc
   */
  readonly name = ITEM_NAME;

  // *********************************************
  // Public methods
  // *********************************************/
  /**
   * @inheritdoc
   */
  create(editor: Editor, config: IEditorConfig) {
    const { toolbarOptions } = config;
    return (
      <TextAlignButton
        key={ITEM_NAME}
        setTextAlign={(alignment: TextAlignType) => editor.chain().focus().setTextAlign(alignment).run()}
        isActive={(attributes: { textAlign: TextAlignType }) => editor.isActive(attributes)}
        toolbarOptions={toolbarOptions}
      />
    );
  }
}

export enum TextAlignType {
  LEFT = "left",
  RIGHT = "right",
  CENTER = "center",
  JUSTIFY = "justify",
}

const ApplicableTextAlignTypes: Array<TextAlignType> = [
  TextAlignType.LEFT,
  TextAlignType.RIGHT,
  TextAlignType.CENTER,
  TextAlignType.JUSTIFY,
];

const TextAlignMenuButtonIconMap: Record<
  TextAlignType,
  OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
    muiName: string;
  }
> = {
  [TextAlignType.LEFT]: FormatAlignLeftOutlined,
  [TextAlignType.RIGHT]: FormatAlignRightOutlined,
  [TextAlignType.CENTER]: FormatAlignCenterOutlined,
  [TextAlignType.JUSTIFY]: SubjectOutlined,
};

/**
 * Props for TextAlignButton.
 */
export interface TextAlignButtonProps {
  setTextAlign: (alignment: TextAlignType) => void;
  isActive: (attributes: { textAlign: TextAlignType }) => boolean;
  toolbarOptions?: IEditorConfig["toolbarOptions"];
}

/**
 * A toolbar button for the `textAlign` command.
 */
export const TextAlignButton = (props: TextAlignButtonProps) => {
  const { setTextAlign, isActive, toolbarOptions } = props;
  const tooltipPlacement = toolbarOptions?.tooltipPlacement;

  const [selectedTextAlignType, setSelectedTextAlignType] = useState<TextAlignType>(TextAlignType.LEFT);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { t } = useTranslation();

  if (isActive({ textAlign: TextAlignType.LEFT }) && selectedTextAlignType !== TextAlignType.LEFT) {
    setSelectedTextAlignType(TextAlignType.LEFT);
  } else if (isActive({ textAlign: TextAlignType.RIGHT }) && selectedTextAlignType !== TextAlignType.RIGHT) {
    setSelectedTextAlignType(TextAlignType.RIGHT);
  } else if (isActive({ textAlign: TextAlignType.CENTER }) && selectedTextAlignType !== TextAlignType.CENTER) {
    setSelectedTextAlignType(TextAlignType.CENTER);
  } else if (isActive({ textAlign: TextAlignType.JUSTIFY }) && selectedTextAlignType !== TextAlignType.JUSTIFY) {
    setSelectedTextAlignType(TextAlignType.JUSTIFY);
  }

  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <ToolbarButton
        Icon={FormatAlignLeftOutlined}
        tooltip={getEditorToolbarButtonTooltip(t, ITEM_NAME)}
        onClick={openMenu}
        tooltipPlacement={tooltipPlacement}
      />

      <Menu
        anchorEl={anchorEl}
        id="text-alignment-menu"
        open={!!anchorEl}
        onClose={closeMenu}
        onClick={closeMenu}
        PaperProps={{
          elevation: 0,
          sx: {
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
          },
        }}
        MenuListProps={{
          style: { display: "inline-flex", padding: 0 },
        }}
      >
        {ApplicableTextAlignTypes.map((alignment) => {
          return (
            <MenuItem
              key={`text-align-${alignment}-menu-button`}
              onClick={() => {
                setTextAlign(alignment);
              }}
              sx={{ padding: "6px" }}
              disableRipple
            >
              <ToolbarButton
                Icon={TextAlignMenuButtonIconMap[alignment]}
                tooltip={t(`Editor.${capitalizeFirstLetter(ITEM_NAME)}.AlignmentMenuTooltip.${capitalizeFirstLetter(alignment)}`)}
                isActive={selectedTextAlignType === alignment}
              />
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};
