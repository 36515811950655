import { IDeprecatedShape } from "./IDeprecatedShape.js";

// *********************************************
// Private constants
// *********************************************/
/**
 * The default minimum width of the shape.
 */
const DEFAULT_MIN_WIDTH = 200;

/**
 * The default minimum height of the shape.
 */
const DEFAULT_MIN_HEIGHT = 200;

// *********************************************
// Public constants
// *********************************************/
/**
 * Gets the default props for the shape.
 *
 * @returns The default props.
 */
export const getDeprecatedShapeDefaultProps = (): IDeprecatedShape["props"] => {
  return {
    w: DEFAULT_MIN_WIDTH,
    h: DEFAULT_MIN_HEIGHT,
    originalShapeType: "",
  };
};
