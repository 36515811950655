/**
 * Defines where the shape data is stored.
 */
export enum ShapeDatastoreType {
  /**
   * The shape data is stored in the server datastore.
   */
  ServerDatastore = "ServerDatastore",

  /**
   * The shape data is stored in the parent shape datastore on the client.
   */
  ParentDatastore = "ParentDatastore",
}
