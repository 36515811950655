import { Editor } from "@tiptap/react";
import { Box } from "@mui/material";
import React from "react";

import { EditorBase, PageMargin, PageOrientation, PageSize } from "../Editor/index.js";

import "./DocumentEditor.css";

export interface DocumentEditorProps {
  containerClassName?: string;
  documentClassName?: string;
  editor: Editor | null;
  pageMargin: PageMargin;
  pageOrientation: PageOrientation;
  pageSize: PageSize;
}

export const DocumentEditor = (props: DocumentEditorProps) => {
  const { editor, containerClassName, documentClassName, pageMargin, pageOrientation, pageSize } = props;

  if (!editor) {
    return <></>;
  }

  return (
    <Box className={containerClassName || ""}>
      <EditorBase
        editor={editor}
        documentClassName={documentClassName}
        pageMargin={pageMargin}
        pageOrientation={pageOrientation}
        pageSize={pageSize}
      />
    </Box>
  );
};

export default DocumentEditor;
