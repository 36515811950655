export enum DataType {
  String = "String",
  Number = "Number",
  Boolean = "Boolean",
  Date = "Date",
  DateTime = "DateTime",
  Time = "Time",
  Duration = "Duration",
  Object = "Object",
  // TODO:
  // Selection/SelectedText/SelectedShape(s)
}

export enum EntityType {
  String = "String",
  Concept = "Concept",
  Organization = "Organization",
  Symbol = "Symbol",
  Theme = "Theme",
  Topic = "Topic",
  Transcript = "Transcript",
  Workspace = "Workspace",
  WorkspaceBoard = "WorkspaceBoard",
  Document = "Document",
  Analyst = "Analyst",
  Person = "Person",
  Company = "Company",
  Country = "Country",
  Industry = "Industry",
  Region = "Region",
  Sector = "Sector",
  Executive = "Executive",
  Role = "Role",
  FiscalQuarter = "FiscalQuarter",
  CalendarQuarter = "CalendarQuarter",
}
export interface CommandInputDataType {
  dataType: DataType;
  isArray: boolean;
  // RegExp?
  validation: string;
}

export interface ICommandInput {
  dataType: CommandInputDataType;
  fieldName: string;
  fieldNameAliases: Array<string>;
  entityType: EntityType;
  defaultValue: string | undefined;
  label: string;
  visible: boolean;
  required: boolean;
}
