import { gql, useQuery } from "@apollo/client";
import { IAnalysisFieldConfig } from "@bigpi/cookbook";
import { useValue } from "@tldraw/tldraw";

import { IAnalysisType, AnalysisTypeEnum } from "BoardComponents/Types";

import {
  QuestionAnalysisFieldGroupsQuery,
  TopicDiscussionAnalysisFieldGroupsQuery,
  TopicDiscussionInNewsArticleAnalysisFieldGroupsQuery,
  TopicDiscussionSummaryAnalysisFieldGroupsQuery,
} from "./FieldQueryGenerators";

const EMPTY_QUERY = gql`
  query EmptyQuery {
    # Placeholder operation to avoid syntax error
    __typename
  }
`;

/**
 * Based on analysis type, generates dynamic query with fields & runs the dynamic query gives results
 *
 * @param analysisType Analysis type to decide the dynamic query
 * @param fields Fields to query for field groups
 * @param fieldFacets Each field specific facets. It means in the field value, that field specific will be off.
 * @param fieldsConfig Fields config to identify type of field
 * @returns Runs dynamic query to each field & gives results
 */
export function useFieldGroupsQuery<TFacets>(
  analysisType: IAnalysisType,
  fields: Array<string> | undefined = [],
  fieldFacets: Record<string, TFacets>,
  fieldsConfig: Array<IAnalysisFieldConfig>,
) {
  // Analysis specific query
  const query = useValue(
    "query",
    () => {
      if (!fields || (Array.isArray(fields) && fields.length === 0)) {
        return EMPTY_QUERY;
      }
      return getQuery(analysisType, fields, fieldsConfig);
    },
    [fields],
  );

  // Runs the analysis type specific dynamic query, skipping if no valid fields
  const queryResult = useQuery(query || EMPTY_QUERY, {
    variables: {
      ...fields?.reduce(
        (acc, field) => {
          acc[`${field}Facets`] = fieldFacets[field] || ({} as TFacets);
          return acc;
        },
        {} as Record<string, TFacets>,
      ),
    },
    skip: fields.length === 0,
  });

  return queryResult;
}

/**
 * Analysis type specific query generator to fetch field groups values for the fields
 *
 * @param analysisType Analysis type to decide the dynamic query
 * @param fields Fields to run the query for
 * @returns Dynamic analysis specific fields included query
 */
function getQuery(analysisType: IAnalysisType, fields: Array<string>, fieldsConfig: Array<IAnalysisFieldConfig>) {
  switch (analysisType) {
    case AnalysisTypeEnum.TopicDiscussionAnalysis:
      return new TopicDiscussionAnalysisFieldGroupsQuery(fields, fieldsConfig).getQuery();
    case AnalysisTypeEnum.TopicDiscussionInNewsArticleAnalysis:
      return new TopicDiscussionInNewsArticleAnalysisFieldGroupsQuery(fields, fieldsConfig).getQuery();
    case AnalysisTypeEnum.TopicDiscussionSummaryAnalysis:
      return new TopicDiscussionSummaryAnalysisFieldGroupsQuery(fields, fieldsConfig).getQuery();
    case AnalysisTypeEnum.QuestionAnalysis:
      return new QuestionAnalysisFieldGroupsQuery(fields, fieldsConfig).getQuery();
    default:
      throw new Error(`Analysis type ${analysisType} is not supported`);
  }
}
