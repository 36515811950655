import { DataGridColumnFormat, DataGridDetailsPanelFormat, IBoardSearchShapeMatch } from "@bigpi/cookbook";
import { IDataGridColumnDef, IDataGridFilterModel, IDataGridPreferences, IDataGridShape } from "@bigpi/tl-schema";
import { TFunction } from "i18next";

import {
  DataGridColumnFormatMapping,
  DataGridDetailsPanelMapping,
  generateColumnsFromConfig,
} from "BoardComponents/DataFormatting/DataFormatting";
import { getBoardSearchMatchesFromTextContent, getPlainTextFromJsxElement } from "Utils/BoardSearchUtils";
import { IBoardSearchPlugInInputs } from "../BoardSearchManager";
import { BoardSearchPlugInBase } from "./BoardSearchPlugInBase";
import {
  GridCellParams,
  GridColDef,
  GridFilterItem,
  GridValueGetterParams,
  getGridBooleanOperators,
  getGridDateOperators,
  getGridNumericOperators,
  getGridStringOperators,
} from "@mui/x-data-grid-premium";

export abstract class DataGridBoardSearchPlugInBase extends BoardSearchPlugInBase {
  // *********************************************
  // Private methods
  // *********************************************/
  /**
   * Get the formatted field data string for the shape data row.
   *
   * @param column The column definition of the data grid.
   * @param field The field to get the data for.
   * @param row The row data to get the field data from.
   * @param t The translation function.
   *
   * @returns The formatted field data string for the shape data row.
   */
  private getFormattedCellData = (column: IDataGridColumnDef, field: string, row: Record<string, any>, t: TFunction) => {
    // Get the rendered cell data
    const columnFormat = DataGridColumnFormatMapping[column.format as DataGridColumnFormat];
    const formattedCellData = columnFormat(row[field], t);

    // If the cell data is a string, use it as is.
    if (typeof formattedCellData === "string") {
      return formattedCellData;
    }

    // If the cell data is a JSX element, get the plain text from it.
    return getPlainTextFromJsxElement(formattedCellData);
  };

  /**
   * Get the plain text for the grouped columns.
   *
   * @param columns The column definition of the data grid.
   * @param rowGroupingModel The row grouping model of the data grid.
   * @param shapeData The shape data to extract text from.
   * @param t The translation function.
   *
   * @returns The plain text for the grouped columns.
   */
  private getPlainTextForGroupedColumns = (
    columns: Array<IDataGridColumnDef>,
    rowGroupingModel: Array<string>,
    shapeData: Array<Record<string, any>>,
    t: TFunction,
  ) => {
    let plainTextForGroupedColumns = "";
    if (rowGroupingModel.length === 0) {
      return plainTextForGroupedColumns;
    }

    // get the primary group field column
    const primaryGroupField = rowGroupingModel[0];
    const primaryGroupFieldColumn = columns.find((col) => col.field === primaryGroupField);
    if (!primaryGroupFieldColumn) {
      return plainTextForGroupedColumns;
    }

    // get unique values for the column from the shape data
    const uniqueValuesForTheField = Array.from(
      new Set(shapeData.map((row) => this.getFormattedCellData(primaryGroupFieldColumn, primaryGroupField, row, t))),
    );
    plainTextForGroupedColumns += uniqueValuesForTheField.join("||");

    // No need to go further deep if there are no more columns to group by
    if (rowGroupingModel.length === 1) {
      return plainTextForGroupedColumns;
    }

    // get the modified row grouping model for the next iteration
    const modifiedRowGroupingModel = rowGroupingModel.slice(1);

    // get the plain text for the next level of grouped columns
    uniqueValuesForTheField.forEach((value) => {
      const matchingRows = shapeData.filter((row) => {
        const cellData = this.getFormattedCellData(primaryGroupFieldColumn, primaryGroupField, row, t);
        return cellData === value;
      });

      plainTextForGroupedColumns += "||";
      plainTextForGroupedColumns += this.getPlainTextForGroupedColumns(columns, modifiedRowGroupingModel, matchingRows, t);
    });

    return plainTextForGroupedColumns;
  };

  /**
   * Apply the filter model to the shape data.
   *
   * @param shapeData The shape data to filter.
   * @param filterModel The filter model to apply.
   * @param columns The column definition of the data grid.
   * @param t The translation function.
   *
   * @returns The filtered shape data.
   */
  private applyFilterModelToDataGridShapeData = (
    shapeData: Array<Record<string, any>>,
    filterModel: IDataGridFilterModel,
    columns: Array<IDataGridColumnDef>,
    t: TFunction,
  ): Array<Record<string, any>> => {
    const colDef = generateColumnsFromConfig(t, columns, false, false);

    return shapeData.filter((row) => {
      return filterModel.items?.every((filterItem) => {
        const column = columns.find((col) => col.field === filterItem.field);
        if (!column) {
          return true;
        }

        const { type: columnType, valueGetter: columnValueGetter } = colDef.find(
          (col) => col.field === filterItem.field,
        ) as GridColDef;
        const value = columnValueGetter
          ? columnValueGetter({ value: row[filterItem.field!] } as GridValueGetterParams)
          : row[filterItem.field!];

        // Get the filter operator for the column type
        let filterOperatorsForColumnType;
        if (columnType === "date") {
          filterOperatorsForColumnType = getGridDateOperators();
        } else if (columnType === "number") {
          filterOperatorsForColumnType = getGridNumericOperators();
        } else if (columnType === "boolean") {
          filterOperatorsForColumnType = getGridBooleanOperators();
        } else {
          filterOperatorsForColumnType = getGridStringOperators();
        }

        // Get the filter operator for the filter item
        const filterOperatorForFilterItem = filterOperatorsForColumnType.find(
          (operator) => operator.value === filterItem.operator,
        );
        if (!filterOperatorForFilterItem) {
          return true;
        }

        // Get the apply filter function for the filter operator
        const applyFilterFn = filterOperatorForFilterItem.getApplyFilterFn(filterItem as GridFilterItem, column);
        if (!applyFilterFn) {
          return true;
        }

        // Apply the filter function to the row data, this function only uses the value
        // and the row object to apply the filter from the GridCellParams so we have used
        // the typecast
        return applyFilterFn({
          value,
          row,
        } as GridCellParams<any, any, any>);
      });
    });
  };

  /**
   * Get the filtered data grid shape data.
   *
   * @param shapeData The shape data to filter.
   * @param preferences The preferences for the data grid.
   * @param columns The column definition of the data grid.
   * @param t The translation function.
   *
   * @returns The filtered data grid shape data.
   */
  private getFilteredDataGridShapeData = (
    shapeData: Array<Record<string, any>>,
    preferences: IDataGridPreferences | undefined,
    columns: Array<IDataGridColumnDef>,
    t: TFunction,
  ): Array<Record<string, any>> => {
    // If there are no filter model, return the shape data as is
    if (!preferences || !preferences.filterModel || !preferences.filterModel.items) {
      return shapeData;
    }

    // Apply the filter model to the shape data
    return this.applyFilterModelToDataGridShapeData(shapeData, preferences.filterModel, columns, t);
  };

  /**
   * Get plain text from the data grid shape data.
   *
   * @param columns The column definition of the data grid.
   * @param preferences The preferences for the data grid.
   * @param detailsPanelFormat The details panel format for the data grid.
   * @param shapeData The shape data to extract text from.
   * @param t The translation function.
   *
   * @returns The plain text extracted from the data grid shape data.
   */
  private getPlainTextFromDataGridShapeData = (
    columns: Array<IDataGridColumnDef>,
    preferences: IDataGridPreferences | undefined,
    detailsPanelFormat: DataGridDetailsPanelFormat | undefined,
    shapeData: Array<Record<string, any>>,
    t: TFunction,
  ) => {
    // Get the filtered shape data based on the filter model
    const filteredShapeData = this.getFilteredDataGridShapeData(shapeData, preferences, columns, t);

    // Get the plain text for the row data
    const plainTextForRowData = filteredShapeData
      .map((row) => {
        const plainText = columns.reduce((acc: Array<string>, column: IDataGridColumnDef) => {
          // Ignore the column if it is not visible.
          if (column.isVisible === false) {
            return acc;
          }

          acc.push(this.getFormattedCellData(column, column.field, row, t));
          return acc;
        }, []);

        // If there is no details panel format, return the plain text.
        if (!detailsPanelFormat) {
          return plainText;
        }

        // Get the formatted details panel data and get the plain text from it.
        const formattedDetailsPanelData = DataGridDetailsPanelMapping[detailsPanelFormat](row);
        if (typeof formattedDetailsPanelData === "string") {
          plainText.push(formattedDetailsPanelData);
          return plainText;
        }

        plainText.push(getPlainTextFromJsxElement(formattedDetailsPanelData));
        return plainText;
      })
      .join("||");

    // If there are no grouped columns, return the plain text for the row data.
    if (!preferences || !preferences.rowGroupingModel || preferences.rowGroupingModel.length === 0) {
      return plainTextForRowData;
    }

    // Get the plain text for the grouped columns
    const plainTextForGroupedColumns = this.getPlainTextForGroupedColumns(
      columns,
      preferences.rowGroupingModel,
      filteredShapeData,
      t,
    );

    // Return the plain text for the row data and the grouped columns
    return plainTextForRowData.concat(plainTextForGroupedColumns);
  };

  /**
   * Get plain text from the shape data.
   *
   * @param input The input data for the plug-in.
   *
   * @returns The plain text extracted from the shape data.
   */
  private async getPlainTextFromShapeData(input: IBoardSearchPlugInInputs) {
    const { shape, t } = input;
    const { columns, detailsPanelFormat } = (shape as IDataGridShape).props.config;
    const preferences = (shape as IDataGridShape).props.preferences;

    const shapeData = await this.getData(input);
    return this.getPlainTextFromDataGridShapeData(columns, preferences, detailsPanelFormat, shapeData, t);
  }

  // *********************************************
  // Public methods
  // *********************************************/
  /**
   * Get the data for the DataGrid shape, which will be used to search for matches.
   *
   * @param input The input data for the plug-in.
   *
   * @returns The data for the DataGrid shape.
   */
  public abstract getData(input: IBoardSearchPlugInInputs): Promise<Array<Record<string, any>>>;

  /**
   * @inheritdoc
   */
  public async execute(input: IBoardSearchPlugInInputs): Promise<Array<IBoardSearchShapeMatch>> {
    const { searchField, searchTerm, shape } = input;
    const fieldData = this.getFieldDataFromShapeProps(shape, searchField);
    if (!fieldData) {
      return [];
    }

    const plainText = await this.getPlainTextFromShapeData(input);
    return getBoardSearchMatchesFromTextContent(plainText, searchTerm);
  }
}
