import { Permissions } from "@bigpi/permission";
import { Delete, Refresh } from "@mui/icons-material";
import { IconButton, Stack, Tooltip } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { AddToWorkspaceIcon } from "Components/AddToWorkspaceIcon/AddToWorkspaceIcon";
import { BulkDeleteDocumentDialog } from "Components/BulkDeleteDocumentDialog/BulkDeleteDocumentDialog";
import { HasPermission } from "Components/HasPermission/HasPermission";
import { ObjectRole, useDocumentAccessControlListHasRoleQuery, useReindexDocumentsMutation } from "GraphQL/Generated/Apollo";
import { EditDocumentsInWorkspaceDialog } from "./EditDocumentsInWorkspaceDialog";

// *********************************************
// Types/Interfaces/Constants/Enums
// *********************************************/
export interface IDocumentActionBarButtonsProps {
  onClose: () => void;
  selectedIds: Array<string>;
}

enum ActionButtonType {
  CopyToWorkspace = "CopyToWorkspace",
  Delete = "Delete",
  NewWorkspace = "NewWorkspace",
  Reindex = "Reindex",
}

// *********************************************
// Component
// *********************************************/
export function DocumentActionBarButtons(props: IDocumentActionBarButtonsProps) {
  const { onClose, selectedIds } = props;

  const { t } = useTranslation();
  const { data: hasAllDeletePermissions } = useDocumentAccessControlListHasRoleQuery({
    variables: {
      // User must have permissions for all the selected files
      documentIds: selectedIds,
      // We need one of the following roles
      roles: [ObjectRole.ContentManager, ObjectRole.Owner],
    },
  });

  const [editInWorkspaceOpen, setEditInWorkspaceOpen] = useState(false);
  const [bulkDeleteDialogOpen, setBulkDeleteDialogOpen] = useState(false);
  const [reindexEnabled, setReindexEnabled] = useState(selectedIds.length > 0);

  const [ReindexDocuments] = useReindexDocumentsMutation();

  useEffect(() => {
    setReindexEnabled(selectedIds.length > 0);
  }, [selectedIds]);

  const actionButtons = [];

  const reindexDocuments = useCallback(async () => {
    // Disable the button to prevent multiple clicks
    setReindexEnabled(false);

    try {
      const response = await ReindexDocuments({
        variables: {
          input: {
            documentIds: selectedIds,
          },
        },
      });

      const documentIds = response.data?.reindexDocuments.documentIds || [];
      if (documentIds.length > 0 && documentIds.length === selectedIds.length) {
        // Show success message
        toast.success(t("Components.DocumentMultiSelectActionButton.Reindex.Success", { count: documentIds.length }));
      } else if (documentIds.length > 0) {
        // Show partial success message
        toast.warn(
          t("Components.DocumentMultiSelectActionButton.Reindex.PartialSuccess", {
            count: documentIds.length,
            total: selectedIds.length,
          }),
        );
      } else {
        // Show error message
        toast.error(t("Components.DocumentMultiSelectActionButton.Reindex.Error", { count: selectedIds.length }));
      }
      onClose();
    } catch (error) {
      console.error(error);

      // Show error message
      toast.error(t("Components.DocumentMultiSelectActionButton.Reindex.Error", { count: selectedIds.length }));

      // Re-enable the button on failures, but not success
      setReindexEnabled(true);
      onClose();
    }
  }, [onClose, selectedIds, t]);

  const onActionButtonClick = useCallback(
    (action: string) => {
      switch (action as ActionButtonType) {
        case ActionButtonType.CopyToWorkspace:
          // TODO: Open dialog for user to search/choose workspace to copy the selected items to
          break;
        case ActionButtonType.Delete:
          setBulkDeleteDialogOpen(true);
          break;
        case ActionButtonType.NewWorkspace:
          setEditInWorkspaceOpen(true);
          break;
        case ActionButtonType.Reindex:
          reindexDocuments();
          break;
        default:
          console.warn("Unknown action", action);
          break;
      }
    },
    [reindexDocuments],
  );

  const onCloseEditInWorkspaceDialog = useCallback(() => {
    setEditInWorkspaceOpen(false);
    onClose();
  }, [onClose]);

  const onCloseBulkDeleteDialog = useCallback(() => {
    setBulkDeleteDialogOpen(false);
    onClose();
  }, [onClose]);

  return (
    <>
      <Stack direction="row" spacing={1} sx={{ ml: 1 }}>
        {
          // NOTE: Technically we shouldn't require write permissions in addition to file ACL delete permissions,
          // but currently they go hand in hand
        }
        <HasPermission permission={Permissions.PatronLibraryWrite}>
          <Tooltip title={t("Global.Action.Delete")} key="delete">
            <IconButton
              onClick={() => onActionButtonClick(ActionButtonType.Delete)}
              disabled={!(hasAllDeletePermissions?.hasRoleForDocuments === true)}
              size="small"
            >
              <Delete color="secondary" />
            </IconButton>
          </Tooltip>
        </HasPermission>
        <Tooltip title={t("Global.Action.NewWorkspaceFrom")} key="addToWorkspace">
          <IconButton onClick={() => setEditInWorkspaceOpen(true)} size="small">
            <AddToWorkspaceIcon />
          </IconButton>
        </Tooltip>
        <HasPermission permission={Permissions.PlatformDataWrite}>
          <Tooltip title={t("Global.Action.ReindexFiles", { count: selectedIds.length })} key="reindex">
            <IconButton disabled={!reindexEnabled} onClick={() => onActionButtonClick(ActionButtonType.Reindex)} size="small">
              <Refresh />
            </IconButton>
          </Tooltip>
        </HasPermission>
      </Stack>

      {editInWorkspaceOpen && (
        <EditDocumentsInWorkspaceDialog
          open={editInWorkspaceOpen}
          onClose={onCloseEditInWorkspaceDialog}
          documentIds={selectedIds}
        />
      )}

      {bulkDeleteDialogOpen && (
        <BulkDeleteDocumentDialog open={bulkDeleteDialogOpen} onClose={onCloseBulkDeleteDialog} documentIds={selectedIds} />
      )}
    </>
  );
}
