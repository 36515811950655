import { Box } from "@mui/system";
import { useCallback, useEffect, useRef, useState } from "react";

/************************************
 * Types/Interfaces
 ************************************/
interface ResizePanelProps {
  children: React.ReactNode;
  defaultWidth: number;
  maxWidth: number;
  minWidth: number;
  onWidthChange: (width: number) => void;
  width: number;
}

/************************************
 * Component
 ************************************/
export function ResizePanel(props: ResizePanelProps) {
  const { children, defaultWidth, maxWidth, minWidth, onWidthChange, width } = props;
  const sidebarRef = useRef<HTMLDivElement>(null);
  const [isResizing, setIsResizing] = useState(false);
  const [sidebarWidth, setSidebarWidth] = useState(width);

  const startResizing = useCallback((mouseDownEvent: React.MouseEvent) => {
    setIsResizing(true);
    // To avoid the selection of text while resizing
    mouseDownEvent.preventDefault();
  }, []);

  const stopResizing = useCallback(() => {
    setIsResizing(false);
  }, []);

  const resize = useCallback(
    (mouseMoveEvent: any) => {
      if (isResizing && sidebarRef.current) {
        const parentPanel = sidebarRef.current;
        const { left: anchorLeft, right: anchorRight } = parentPanel
          ? parentPanel.getBoundingClientRect()
          : { left: 0, right: 0 };
        const resizedWidth = anchorRight - mouseMoveEvent.clientX;
        const safeWidth = Math.min(maxWidth, Math.max(minWidth, resizedWidth));
        setSidebarWidth(safeWidth);
        onWidthChange && onWidthChange(safeWidth);
      }
    },
    [isResizing, onWidthChange],
  );

  useEffect(() => {
    window.addEventListener("mousemove", resize);
    window.addEventListener("mouseup", stopResizing);
    return () => {
      window.removeEventListener("mousemove", resize);
      window.removeEventListener("mouseup", stopResizing);
    };
  }, [resize, stopResizing]);

  return (
    <Box
      ref={sidebarRef}
      className="resizer"
      sx={{
        width: sidebarWidth,
        display: "flex",
        height: "100%",
      }}
    >
      <Box
        className="app-sidebar-resizer"
        onMouseDown={startResizing}
        onDoubleClick={() => {
          setSidebarWidth(defaultWidth);
          onWidthChange && onWidthChange(defaultWidth);
        }}
        sx={{
          cursor: "col-resize",
          flexGrow: 0,
          flexShrink: 0,
          height: "100%",
          justifySelf: "flex-end",
          position: "absolute",
          resize: "horizontal",
          width: "3px",
          zIndex: 2,
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.1)",
          },
        }}
      />
      {children}
    </Box>
  );
}
