import { useContext } from "react";

import { BoardProviderContext } from "./BoardProviderContext";

export const useBoardProvider = () => {
  const result = useContext(BoardProviderContext);
  if (!result) {
    throw new Error("No BoardProvider found in context");
  }
  return result;
};
