import { Editor, IEditorConfig, ToolbarButton, ToolbarItemBase, getEditorToolbarButtonTooltip } from "@bigpi/editor-tiptap";
import { AutoAwesome } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const ITEM_NAME = "command";

/**
 * A toolbar button that invokes command execution popup.
 */
export class CommandButtonItem extends ToolbarItemBase {
  // *********************************************
  // Public properties
  // *********************************************/
  /**
   * @inheritdoc
   */
  isExternallyTranslated: boolean = true;

  /**
   * @inheritdoc
   */
  readonly name = ITEM_NAME;

  // *********************************************
  // Private methods
  // *********************************************/
  private invokeCommand() {
    window.document.dispatchEvent(new Event("show-command-dialog"));
  }

  // *********************************************
  // Public methods
  // *********************************************/
  /**
   * @inheritdoc
   */
  create(editor: Editor, config: IEditorConfig) {
    const { toolbarOptions } = config;
    return <CommandButton key={ITEM_NAME} invokeCommand={this.invokeCommand} toolbarOptions={toolbarOptions} />;
  }
}

/**
 * Props for CommandButton.
 */
export interface CommandButtonProps {
  invokeCommand: () => void;
  toolbarOptions?: IEditorConfig["toolbarOptions"];
}

/**
 * A toolbar button for the `command` popup.
 */
export const CommandButton = (props: CommandButtonProps) => {
  const { invokeCommand, toolbarOptions } = props;
  const tooltipPlacement = toolbarOptions?.tooltipPlacement;

  const { t } = useTranslation();

  return (
    <ToolbarButton
      Icon={AutoAwesome}
      tooltip={getEditorToolbarButtonTooltip(t, ITEM_NAME, {})}
      onClick={invokeCommand}
      style={{ rotate: "180deg" }}
      tooltipPlacement={tooltipPlacement}
    />
  );
};
