import { useAuthUser } from "@frontegg/react";

import { ObjectRole, useWorkspaceAccessControlListUserRolesQuery } from "GraphQL/Generated/Apollo";

export function useHasWorkspaceRole(workspaceId: string, roles: Array<ObjectRole>) {
  const user = useAuthUser();
  const { data } = useWorkspaceAccessControlListUserRolesQuery({
    variables: {
      userId: user.id,
      workspaceId,
    },
  });

  const hasRole = data?.workspaceAccessControlListUserRoles.roles.some((assignedRole) =>
    roles.includes(assignedRole as ObjectRole),
  );

  return hasRole || false;
}
