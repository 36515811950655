import { useApolloClient } from "@apollo/client";
import type { Editor } from "@bigpi/editor-tiptap";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { pasteContentPlugin } from "Editor/Extensions/PasteContent/PasteContentExtension";
import { useProcessHtmlMutation } from "GraphQL/Generated/Apollo";

export const useDoubtfireCleanup = (editor: Editor | null, preset?: string) => {
  const apolloClient = useApolloClient();

  const { t } = useTranslation();

  const [processHtmlMutation] = useProcessHtmlMutation();

  const processHtml = async (sourceDocument: string) => {
    const res = await processHtmlMutation({
      variables: {
        sourceDocument,
        preset,
      },
    });

    if (!res.data?.processHtml.resultDocument) {
      return "";
    }
    return res.data.processHtml.resultDocument;
  };

  useEffect(() => {
    if (editor && !editor.isDestroyed) {
      editor.registerPlugin(pasteContentPlugin(processHtml, editor, apolloClient, t));
    }

    return () => {
      if (editor && !editor.isDestroyed) {
        editor.unregisterPlugin("pasteContentPlugin");
      }
    };
  }, [editor]);
};
