import { FormatColorResetOutlined, FormatColorTextOutlined, Square } from "@mui/icons-material";
import { Menu, MenuItem } from "@mui/material";
import type { Editor } from "@tiptap/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import type { IEditorConfig } from "../../Editor/index.js";
import { capitalizeFirstLetter, getEditorToolbarButtonTooltip } from "../../Utils/ToolbarItemUtils.js";
import { ToolbarItemBase } from "../../Toolbars/ToolbarItemBase.js";
import { ToolbarButton } from "../../Toolbars/ToolbarButton.js";
import { DefaultTextColors, TextColors } from "./TextStyleExtension.js";

const ITEM_NAME = "textColor";

/**
 * A toolbar button for changing text color.
 */
export class TextColorButtonItem extends ToolbarItemBase {
  // *********************************************
  // Public properties
  // *********************************************/
  /**
   * @inheritdoc
   */
  readonly name = ITEM_NAME;

  // *********************************************
  // Public methods
  // *********************************************/
  /**
   * @inheritdoc
   */
  create(editor: Editor, config: IEditorConfig) {
    const { toolbarOptions } = config;
    return (
      <TextColorButton
        key={ITEM_NAME}
        setTextColor={(color: TextColors) => editor.chain().focus().setTextColor(color).run()}
        unsetTextColor={() => editor.chain().focus().unsetTextColor().run()}
        toolbarOptions={toolbarOptions}
      />
    );
  }
}

/**
 * Props for TextColorButton.
 */
export interface TextColorButtonProps {
  setTextColor: (color: TextColors) => void;
  unsetTextColor: () => void;
  toolbarOptions?: IEditorConfig["toolbarOptions"];
}

/**
 * A toolbar button for the `textColor` command.
 */
export const TextColorButton = (props: TextColorButtonProps) => {
  const { setTextColor, unsetTextColor, toolbarOptions } = props;
  const tooltipPlacement = toolbarOptions?.tooltipPlacement;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { t } = useTranslation();

  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <ToolbarButton
        Icon={FormatColorTextOutlined}
        tooltip={getEditorToolbarButtonTooltip(t, ITEM_NAME)}
        onClick={openMenu}
        tooltipPlacement={tooltipPlacement}
      />

      <Menu
        anchorEl={anchorEl}
        id="text-color-menu"
        open={!!anchorEl}
        onClose={closeMenu}
        onClick={closeMenu}
        PaperProps={{
          elevation: 0,
          sx: {
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
          },
        }}
        MenuListProps={{
          style: { display: "inline-flex", padding: 0 },
        }}
      >
        {DefaultTextColors.map((color) => {
          return (
            <MenuItem
              key={`text-color-${color}-menu-button`}
              onClick={() => {
                setTextColor(color);
              }}
              sx={{ padding: "6px" }}
              disableRipple
            >
              <ToolbarButton
                Icon={Square}
                className={`editor toolbar text-${color}`}
                style={{ border: "1px solid rgba(0,0,0,0.32)" }}
                tooltip={t(`Editor.${capitalizeFirstLetter(ITEM_NAME)}.ColorMenuTooltip.${capitalizeFirstLetter(color)}`)}
              />
            </MenuItem>
          );
        })}

        <MenuItem key={`text-color-reset-menu-button`} onClick={unsetTextColor} sx={{ padding: "6px" }} disableRipple>
          <ToolbarButton Icon={FormatColorResetOutlined} tooltip={t(`Editor.${capitalizeFirstLetter(ITEM_NAME)}.ClearTooltip`)} />
        </MenuItem>
      </Menu>
    </>
  );
};
