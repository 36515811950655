import { RawCommands } from "@tiptap/core";

import { Title } from "./TitleExtension.js";

// This command is used to toggle title node.
export const toggleTitle: RawCommands["toggleTitle"] =
  () =>
  ({ commands, state }) => {
    const { $from } = state.selection;
    const selectedNode = $from.node();
    if (selectedNode.type.name === Title.name) {
      return commands.setParagraph();
    }

    return commands.setTitle();
  };
