import { ShapeProps } from "@tldraw/tlschema";
import { T } from "@tldraw/validate";

import { IHtmlDocumentShape } from "./IHtmlDocumentShape.js";

// *********************************************
// Private constants
// *********************************************/
/**
 * The default width of the shape.
 */
const DEFAULT_WIDTH = 816;

/**
 * The default height of the shape.
 */
const DEFAULT_HEIGHT = 1056;

// *********************************************
// Public constants
// *********************************************/
/**
 * Gets the default props for the shape.
 *
 * @returns The default props.
 */
export const getHtmlDocumentShapeDefaultProps = (): IHtmlDocumentShape["props"] => {
  return {
    w: DEFAULT_WIDTH,
    h: DEFAULT_HEIGHT,
    html: "",
    scale: 1,

    asyncUpdateLock: false,
    canScroll: false,
  };
};

/**
 * The shape props schema definition.
 */
export const htmlDocumentShapeProps: ShapeProps<IHtmlDocumentShape> = {
  asyncUpdateLock: T.boolean,
  canScroll: T.boolean,
  h: T.nonZeroNumber,
  html: T.string,
  scale: T.nonZeroNumber,
  w: T.nonZeroNumber,
};
