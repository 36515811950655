import { getRegexForSearchTerm } from "@bigpi/cookbook";
import { Extension, Range } from "@tiptap/core";
import { Plugin, PluginKey, Transaction } from "@tiptap/pm/state";
import { Decoration, DecorationSet, EditorView } from "@tiptap/pm/view";
import { Node as ProsemirrorNode } from "@tiptap/pm/model";

import { scrollEditorNodeIntoView } from "../../Utils/ScrollEditorNodeIntoView.js";

// We need to declare our custom commands so that TS doesn't throw warning everywhere saying these commands do not exist.
declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    search: {
      /**
       * Clears all highlights from the document.
       */
      clearSearch: () => ReturnType;
      /**
       * Navigates to the next or previous search match.
       */
      navigateSearchMatches: (direction: "forward" | "backward") => ReturnType;
      /**
       * Navigates to a specific match index.
       */
      navigateToMatchIndex: (matchIndex: number) => ReturnType;
      /**
       * Searches the document for the given term and highlights all matches.
       */
      search: (searchTerm: string) => ReturnType;
    };
  }
}

export interface SearchOptions {
  caseInsensitive: boolean;
  matchHighlightClass: string;
  navigationHighlightClass: string;
}

export interface SearchStorage {
  searchTerm: string | null;
  currentNavigationIndex: number;
  matchingRanges: Array<Range>;
}

export const DEFAULT_SEARCH_TERM = null;
export const DEFAULT_CURRENT_NAVIGATION_INDEX = -1;
export const DEFAULT_MATCHING_RANGES = [];

function getMatchingRanges(doc: ProsemirrorNode, searchRegex: RegExp): Array<Range> {
  const ranges: Array<Range> = [];

  let textNodesWithPos: Array<{ text: string; pos: number }> = [];
  let textIndex = 0;
  doc?.descendants((node, pos) => {
    if (node.isText) {
      // Merge text nodes that are next to each other. This is required to handle the case
      // where a search term is split between two text nodes.
      if (textNodesWithPos[textIndex]) {
        textNodesWithPos[textIndex] = {
          text: textNodesWithPos[textIndex].text + node.text,
          pos: textNodesWithPos[textIndex].pos,
        };
      } else {
        textNodesWithPos[textIndex] = {
          text: `${node.text}`,
          pos,
        };
      }
    } else {
      textIndex += 1;
    }
  });

  // Remove objects with false "text" or "pos" values
  textNodesWithPos = textNodesWithPos.filter(Boolean);

  for (const element of textNodesWithPos) {
    const { text, pos } = element;
    const matches = Array.from(text.matchAll(searchRegex)).filter(([matchText]) => matchText.trim());

    for (const m of matches) {
      if (m[0] === "") {
        break;
      }

      if (m.index !== undefined) {
        const from = pos + m.index;
        const to = from + m[0].length;
        ranges.push({ from, to });
      }
    }
  }

  return ranges;
}

function getDecorationsForMatchingRanges(
  ranges: Array<Range>,
  currentNavigationIndex: number,
  navigationHighlightClass: string,
  matchHighlightClass: string,
): Array<Decoration> {
  return ranges.map((range, index) => {
    const { from, to } = range;
    const highlightClass = index === currentNavigationIndex ? navigationHighlightClass : matchHighlightClass;

    return Decoration.inline(from, to, {
      class: highlightClass,
    });
  });
}

function expandDetailsNodesBetweenMatchingRange(tr: Transaction, range: Range, view: EditorView) {
  const { from, to } = range;

  try {
    // Iterate through all nodes between the matching range
    tr.doc.nodesBetween(from, to, (node, pos) => {
      // We are getting the DOM node of the details node and clicking the button inside it
      // to expand the details node and also using transaction to update the "open" attribute
      // of the details node to "true".
      //
      // We have used an unusual approach to expand details nodes because the details
      // node (and other related nodes - detailsContent and detailsSummary nodes) use
      // NodeViews to render the "div" and "button" HTML elements. Directly manipulating
      // the editor nodes and updating their attributes is not changing anything _visually_
      // unless the editor content is re-rendered. So, we have to manually click the
      // button inside the details node to expand it immediately. Details extension has
      // hooked button click events to manipulate other HTML elements like the
      // DetailsContent NodeView. This event also toggles the "open" attribute value.
      //
      // We are using the transaction to update the "open" attribute of the details node
      // to "true", this is required to update the editor state.
      if (node.type.name === "details") {
        // Get the DOM node of the details node
        const detailsNodeDom = view.nodeDOM(pos);

        // Check if the details node is already expanded, if so, skip it
        const detailsNodeClassNames = (detailsNodeDom as any)?.getAttribute("class");
        if (node.attrs.open === true || (detailsNodeClassNames && detailsNodeClassNames.includes("is-open"))) {
          return;
        }

        // Update the "open" attribute of the details node to true
        tr.setNodeAttribute(pos, "open", true);
      }
    });
  } catch (error) {
    // No-Op
  }
}

export const Search = Extension.create<SearchOptions, SearchStorage>({
  name: "search",

  addOptions() {
    return {
      caseInsensitive: false,
      matchHighlightClass: "highlight-search",
      navigationHighlightClass: "highlight-search-navigation",
    };
  },

  addStorage() {
    return {
      searchTerm: DEFAULT_SEARCH_TERM,
      currentNavigationIndex: DEFAULT_CURRENT_NAVIGATION_INDEX,
      matchingRanges: DEFAULT_MATCHING_RANGES,
    };
  },

  addCommands() {
    return {
      clearSearch:
        () =>
        ({ dispatch, tr }) => {
          if (dispatch) {
            this.storage.searchTerm = DEFAULT_SEARCH_TERM;
            this.storage.currentNavigationIndex = DEFAULT_CURRENT_NAVIGATION_INDEX;
            this.storage.matchingRanges = DEFAULT_MATCHING_RANGES;

            dispatch(tr);
          }
          return true;
        },
      navigateSearchMatches:
        (direction: "forward" | "backward") =>
        ({ dispatch, editor, tr, view }) => {
          if (this.storage.matchingRanges.length === 0) {
            return false;
          }

          if (dispatch) {
            if (direction === "forward" && this.storage.currentNavigationIndex === this.storage.matchingRanges.length - 1) {
              this.storage.currentNavigationIndex = 0;
            } else if (direction === "backward" && this.storage.currentNavigationIndex === 0) {
              this.storage.currentNavigationIndex = this.storage.matchingRanges.length - 1;
            } else if (direction === "forward") {
              this.storage.currentNavigationIndex++;
            } else {
              this.storage.currentNavigationIndex--;
            }

            // Get the matching range from current navigation index
            const matchingRange = this.storage.matchingRanges[this.storage.currentNavigationIndex];

            // Expand the details node if the matching range is inside the node
            expandDetailsNodesBetweenMatchingRange(tr, matchingRange, view);

            // Scroll the editor to the matching range
            scrollEditorNodeIntoView(editor, matchingRange.from);

            dispatch(tr);
          }
          return true;
        },
      navigateToMatchIndex:
        (matchIndex: number) =>
        ({ dispatch, editor, tr, view }) => {
          if (matchIndex < 0 || matchIndex >= this.storage.matchingRanges.length) {
            return false;
          }

          if (dispatch) {
            this.storage.currentNavigationIndex = matchIndex;

            // Get the matching range from current navigation index
            const matchingRange = this.storage.matchingRanges[this.storage.currentNavigationIndex];

            // Expand the details node if the matching range is inside the node
            expandDetailsNodesBetweenMatchingRange(tr, matchingRange, view);

            // Scroll the editor to the matching range
            scrollEditorNodeIntoView(editor, matchingRange.from);

            dispatch(tr);
          }
          return true;
        },
      search:
        (searchTerm: string) =>
        ({ state, dispatch }) => {
          if (dispatch) {
            this.storage.searchTerm = searchTerm;
            this.storage.currentNavigationIndex = DEFAULT_CURRENT_NAVIGATION_INDEX;

            if (typeof searchTerm === "string" && searchTerm.length > 0) {
              const regex = getRegexForSearchTerm(this.storage.searchTerm, {
                caseInsensitive: this.options.caseInsensitive,
                disableRegexSearch: true,
              });

              this.storage.matchingRanges = getMatchingRanges(state.tr.doc, regex);
            } else {
              this.storage.matchingRanges = DEFAULT_MATCHING_RANGES;
            }

            dispatch(state.tr);
          }

          return true;
        },
    };
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey("highlight-matching-ranges"),
        state: {
          init: () => DecorationSet.empty,
          apply: (tr) => {
            if (typeof this.storage.searchTerm !== "string" || this.storage.searchTerm.length === 0) {
              return DecorationSet.empty;
            }

            const regex = getRegexForSearchTerm(this.storage.searchTerm, {
              caseInsensitive: this.options.caseInsensitive,
              disableRegexSearch: true,
            });

            this.storage.matchingRanges = getMatchingRanges(tr.doc, regex);

            const decorations: Array<Decoration> = getDecorationsForMatchingRanges(
              this.storage.matchingRanges,
              this.storage.currentNavigationIndex,
              this.options.navigationHighlightClass,
              this.options.matchHighlightClass,
            );

            return DecorationSet.create(tr.doc, decorations);
          },
        },
        props: {
          decorations(state) {
            return this.getState(state);
          },
        },
      }),
    ];
  },
});
