import { BulletList as TipTapBulletList } from "@tiptap/extension-bullet-list";
import { Slice } from "@tiptap/pm/model";
import { EditorState, Plugin, PluginKey } from "@tiptap/pm/state";
import { wrappingInputRule } from "@tiptap/react";
import { v4 as uuidv4 } from "uuid";

import { getIndentLevelByClass } from "../../Utils/CommandUtil.js";
import { getClassAttribute, getStringValueAttribute } from "../../Utils/ExtensionUtil.js";
import { IndentLevels } from "../Indent/IndentExtension.js";

function isInBulletList(state: EditorState): boolean {
  const $head = state.selection.$head;
  for (let d = $head.depth; d > 0; d--) {
    if ($head.node(d).type.name === "bulletList") {
      return true;
    }
  }
  return false;
}

function getBulletListId(state: EditorState): string | null {
  const $head = state.selection.$head;
  for (let d = $head.depth; d > 0; d--) {
    const node = $head.node(d);
    if (node.type.name === "bulletList") {
      return node.attrs["data-list-id"];
    }
  }
  return null;
}

export const bulletListInputRegex = /^\s*([-+*])\s$/;

export const BulletList = TipTapBulletList.extend({
  // Our custom schema support only a single "listItem" inside of
  // an "ul" tag, "itemTypeName" option below basically has value
  // "listItem" which comes from the original "BulletList" extension.
  //
  // <ul> <li></li> </ul> - SUPPORTED
  // <ul> <li></li> <li></li> </ul> - IS NOT SUPPORTED
  content() {
    return `${this.options.itemTypeName}`;
  },

  addAttributes() {
    return {
      ...this.parent?.(),
      class: getClassAttribute(),
      "data-list-id": getStringValueAttribute("data-list-id", null),
    };
  },

  addCommands() {
    return {
      toggleBulletList:
        () =>
        ({ commands }) => {
          return commands.toggleList(this.name, this.options.itemTypeName, true, { "data-list-id": uuidv4() });
        },
    };
  },

  addInputRules() {
    return [
      wrappingInputRule({
        find: bulletListInputRegex,
        type: this.type,
        keepMarks: true,
        keepAttributes: true,
        getAttributes: (match) => {
          // Adding "data-list-id" attribute when a new list is created.
          return { "data-list-id": uuidv4() };
        },
        editor: this.editor,
      }),
    ];
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey("bulletListPasteHandler"),
        props: {
          // Handle pasting inside of a bulletList.
          handlePaste: (view, _event, slice) => {
            // Proceed only if the content is being pasted inside of an bulletList.
            // Let the default paste handler do it its thing otherwise.
            if (!isInBulletList(view.state)) {
              return false;
            }

            // Get the "data-list-id" attribute of the parent bulletList where the content is being pasted.
            const parentListId = getBulletListId(view.state);
            const uniqueListId = uuidv4();

            // "slice" has the pasted content.
            slice.content.descendants((pastedNode, _pos, _parent, index) => {
              if (pastedNode.type.name === "orderedList") {
                // Use unique ID as the "data-list-id" attribute for the pasted orderedList.
                slice = new Slice(
                  slice.content.replaceChild(
                    index,
                    pastedNode.type.create(
                      { ...pastedNode.attrs, "data-list-id": uniqueListId },
                      pastedNode.content,
                      pastedNode.marks,
                    ),
                  ),
                  slice.openStart,
                  slice.openEnd,
                );
              } else if (pastedNode.type.name === "bulletList") {
                // Use the parent bulletList's "data-list-id" attribute for the pasted bulletList.
                slice = new Slice(
                  slice.content.replaceChild(
                    index,
                    pastedNode.type.create(
                      { ...pastedNode.attrs, "data-list-id": parentListId },
                      pastedNode.content,
                      pastedNode.marks,
                    ),
                  ),
                  slice.openStart,
                  slice.openEnd,
                );
              }
            });

            // Replace the selection with the pasted content.
            let tr = view.state.tr.replaceSelection(slice).scrollIntoView().setMeta("paste", true).setMeta("uiEvent", "paste");

            // Re-number the ordered list items after pasting.
            let previousListIndentLevel: number | null = null;
            const indentLevelWiseStartValue = Array(IndentLevels.length).fill(1);
            tr.doc.descendants((node, pos) => {
              if (node.type.name === "orderedList" && node.attrs["data-list-id"] === uniqueListId) {
                let start = 1;

                const nodeIndentLevel = getIndentLevelByClass(node.attrs.class);
                if (previousListIndentLevel === null) {
                  // The first list item should always start from 1.
                  // 1. First               <---- This is the first item.
                  // 2. Second
                  // ...
                  // ...
                  start = 1;
                } else if (nodeIndentLevel > previousListIndentLevel) {
                  // The list is sinking here.
                  // 1. First
                  // 2. Second
                  //   a. Third             <---- Restarts from 1.
                  //     i. Fourth          <---- Restarts from 1.
                  // 3. Fifth
                  // ...
                  // ...
                  start = 1;
                } else if (nodeIndentLevel <= previousListIndentLevel) {
                  // The list is either lifting (nodeIndentLevel < previousListIndentLevel) or
                  // splitting (nodeIndentLevel === previousListIndentLevel) here.
                  // 1. First
                  // 2. Second
                  //   a. Third
                  //     i. Fourth
                  // 3. Fifth               <---- Last "start" value at the same indent level + 1.
                  // 4. Sixth               <---- Last "start" value at the same indent level + 1.
                  // ...
                  // ...
                  start = indentLevelWiseStartValue[nodeIndentLevel];

                  // Reset the start value for the indent levels greater than current indent level.
                  // 1. First
                  // 2. Second
                  //   a. Third
                  //     i. Fourth
                  // 3. Fifth                <---- Lifts here.
                  //   a. Sixth              <---- Restarts from 1, reset.
                  //     i. Seventh          <---- Restarts from 1, reset.
                  // 4. Eighth
                  // ...
                  // ...
                  for (let i = nodeIndentLevel + 1; i < IndentLevels.length; i++) {
                    indentLevelWiseStartValue[i] = 1;
                  }
                }

                // Update previousListIndentLevel and indentLevelWiseStartValue.
                previousListIndentLevel = nodeIndentLevel;
                indentLevelWiseStartValue[nodeIndentLevel]++;

                // Set the calculated "start" value for the current list item.
                tr = tr.setNodeMarkup(pos, node.type, { ...node.attrs, start });
              }
            });

            // Dispatch the transaction.
            view.dispatch(tr);

            return true;
          },
          // Add a unique list ID to bulletList item if it is NOT being pasted inside another bulletList.
          // This is to prevent unnecessary grouping of the bulletList items.
          transformPasted: (slice, view) => {
            // Proceed only if the content is NOT being pasted inside of an bulletList.
            // Let the default paste handler do it its thing otherwise.
            if (isInBulletList(view.state)) {
              return slice;
            }

            const uniqueListId = uuidv4();

            // "slice" has the pasted content.
            slice.content.descendants((pastedNode, _pos, _parent, index) => {
              if (pastedNode.type.name === "bulletList") {
                // Use unique ID as the "data-list-id" attribute for the pasted bulletList.
                slice = new Slice(
                  slice.content.replaceChild(
                    index,
                    pastedNode.type.create(
                      { ...pastedNode.attrs, "data-list-id": uniqueListId },
                      pastedNode.content,
                      pastedNode.marks,
                    ),
                  ),
                  slice.openStart,
                  slice.openEnd,
                );
              }
            });

            return slice;
          },
        },
      }),
    ];
  },
});
