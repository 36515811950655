/**
 * Standard permissions available to users.
 */
export enum Permissions {
  // Allows user group management, including creation, deletion, and managing group users
  UserGroupAdmin = "bigpi.userGroup.admin",

  PatronDocumentsRead = "bigpi.patron.documents.read",
  PatronDocumentsWrite = "bigpi.patron.documents.write",

  PatronLibraryRead = "bigpi.patron.library.read",
  PatronLibraryWrite = "bigpi.patron.library.write",

  PlatformConfigsRead = "bigpi.platform.configs.read",
  PlatformConfigsWrite = "bigpi.platform.configs.write",
  PlatformConfigsDelete = "bigpi.platform.configs.delete",

  PlatformDataWrite = "bigpi.platform.data.write",
  PlatformDataDelete = "bigpi.platform.data.delete",

  PlatformJobsRead = "bigpi.platform.jobs.read",
  PlatformJobsWrite = "bigpi.platform.jobs.write",
  PlatformJobsDelete = "bigpi.platform.jobs.delete",

  PlatformTenantsRead = "bigpi.platform.tenants.read",
}
