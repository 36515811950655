import { useUserQuery } from "GraphQL/Generated/Apollo";
import { useState } from "react";

export interface IChatUser {
  id: string;
  name: string;
  profilePictureUrl?: string | null;
}

const EMPTY_USER: IChatUser = {
  id: "",
  name: "",
  profilePictureUrl: undefined,
};

/**
 * Gets chat user details or a valid, empty user object which can be used to show user information in chat.
 *
 * @param userId User ID of the user.
 *
 * @returns A user object valid for chat.
 */
export function useChatUser(userId: string) {
  const [user, setUser] = useState<IChatUser>({
    ...EMPTY_USER,
    id: userId,
  });

  const { data, loading, error } = useUserQuery({
    variables: {
      id: userId,
    },
  });

  if (data && data.user) {
    if (data.user.name !== user.name || data.user.profilePictureUrl !== user.profilePictureUrl) {
      setUser(data.user);
    }
  }

  return { user, loading, error };
}
