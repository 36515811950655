import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import * as React from "react";
import { useTranslation } from "react-i18next";

import { DefaultDialogTransition } from "Components/DialogTransition/DefaultDialogTransition";
import { useUpdateWorkspaceMutation, useWorkspaceQuery } from "GraphQL/Generated/Apollo";

export interface IRenameWorkspaceDialogProps {
  onClose: () => void;
  open: boolean;
  workspaceId: string;
}

export function RenameWorkspaceDialog(props: IRenameWorkspaceDialogProps) {
  const { open, workspaceId } = props;
  const { t } = useTranslation();
  const { data, loading, error } = useWorkspaceQuery({ variables: { id: workspaceId } });
  const [UpdateWorkspace] = useUpdateWorkspaceMutation();
  const [errorMessage, setErrorMessage] = React.useState("");
  const [oldName, setOldName] = React.useState("");
  const [name, setName] = React.useState("");
  const [nameFocused, setNameFocused] = React.useState(true);
  const nameRef = React.useRef<HTMLInputElement>(null);

  function onClose() {
    setErrorMessage("");
    setName("");
    props.onClose();
  }

  function onRename() {
    UpdateWorkspace({
      variables: { input: { id: workspaceId, name: name } },
      refetchQueries: ["Workspace", "Workspaces"],
    })
      .then(() => {
        onClose();
      })
      .catch((error) => {
        console.error(error);
        setErrorMessage(t("Components.RenameWorkspaceDialog.Error"));
      });
  }

  React.useEffect(() => {
    if (data?.workspace?.name) {
      setOldName(data.workspace?.name || "");
      setName(data.workspace?.name || "");
    }
  }, [data]);

  React.useEffect(() => {
    if (open && nameRef.current) {
      nameRef.current.focus();
    }
  }, [nameRef.current, open]);

  return (
    <>
      <Dialog open={open} onClose={onClose} TransitionComponent={DefaultDialogTransition} fullWidth maxWidth="xs">
        <DialogTitle variant="h5">{t("Components.RenameWorkspaceDialog.Title", { name: oldName })}</DialogTitle>
        <DialogContent>
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
          <TextField
            autoFocus
            margin="dense"
            label={t("Components.RenameWorkspaceDialog.NameLabel")}
            type="text"
            fullWidth
            variant="standard"
            value={name}
            onBlur={() => setNameFocused(false)}
            onChange={(e) => setName(e.target.value ?? "")}
            onFocus={() => setNameFocused(true)}
            required
            inputRef={nameRef}
            error={name === "" && !nameFocused}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{t("Global.Action.Close")}</Button>
          <Button onClick={onRename} disabled={name === ""}>
            {t("Components.RenameWorkspaceDialog.Rename")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
